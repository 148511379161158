import {Box, Grid, Typography} from "@mui/material"

export default function PropertyInfoCard(props) {
    return (
        <Grid
            container
            spacing={1}
            sx={{
                padding: "20px",
                width: {xs: "100%", md: "90%"},
                backgroundColor: "#fff",
                borderRadius: "10px",
                minHeight: "240px",
                margin: "20px auto",
                boxShadow: "0 4px 12px 0 rgba(0,0,0,0.25)",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    margin: "auto 20px",
                    width: "100%",
                    height: "fit-content",
                }}
            >
                <Typography
                    sx={{
                        fontWeight: 800,
                        fontSize: "18px",
                    }}
                >
                    Property Information
                </Typography>
            </Box>

            <Box
                sx={{
                    display: "flex",
                    flexDirection: {lg: "row", xs: "column"},
                    justifyContent: "space-around",
                    margin: "0 20px",
                    width: "100%",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        width: {lg: "33.3%", md: "100%", xs: "100%"},
                        padding: "10px",
                    }}
                >
                    <Typography
                        sx={{
                            fontWeight: 800,
                            fontSize: "16px",
                            textAlign: "start",
                            color: "#037171",
                            marginBottom: "20px",
                        }}
                    >
                        Basic Information
                    </Typography>

                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "nowrap",
                            justifyContent: "space-between",
                            width: "80%",
                            margin: "10px auto",
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: "14px",
                                fontWeight: 700,
                                textAlign: "start",
                                width: {xs: "50%", md: "100%"},
                            }}
                        >
                            Name
                        </Typography>
                        <Typography
                            sx={{fontSize: "14px", color: "#037171", textAlign: "end"}}
                        >
                            Property
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "nowrap",
                            justifyContent: "space-between",
                            width: "80%",
                            margin: "10px auto",
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: "14px",
                                fontWeight: 700,
                                textAlign: "start",
                                width: {xs: "50%", md: "100%"},
                            }}
                        >
                            Number of Units
                        </Typography>
                        <Typography
                            sx={{fontSize: "14px", color: "#037171", textAlign: "end"}}
                        >
                            Property
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "nowrap",
                            justifyContent: "space-between",
                            width: "80%",
                            margin: "10px auto",
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: "14px",
                                fontWeight: 700,
                                textAlign: "start",
                                width: {xs: "50%", md: "100%"},
                            }}
                        >
                            Occupancy (%)
                        </Typography>
                        <Typography
                            sx={{fontSize: "14px", color: "#037171", textAlign: "end"}}
                        >
                            Property
                        </Typography>
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        width: {lg: "33.3%", md: "100%", xs: "100%"},
                        padding: "10px",
                    }}
                >
                    <Typography
                        sx={{
                            fontWeight: 800,
                            fontSize: "16px",
                            textAlign: "start",
                            color: "#037171",
                            marginBottom: "20px",
                        }}
                    >
                        MPESA Information
                    </Typography>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "nowrap",
                            justifyContent: "space-between",
                            width: "80%",
                            margin: "10px auto",
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: "14px",
                                fontWeight: 700,
                                textAlign: "start",
                                width: {xs: "50%", md: "100%"},
                            }}
                        >
                            MPESA Paybill
                        </Typography>
                        <Typography
                            sx={{fontSize: "14px", color: "#037171", textAlign: "end"}}
                        >
                            Property
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "nowrap",
                            justifyContent: "space-between",
                            width: "80%",
                            margin: "10px auto",
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: "14px",
                                fontWeight: 700,
                                textAlign: "start",
                                width: {xs: "50%", md: "100%"},
                            }}
                        >
                            MPESA Shortcode
                        </Typography>
                        <Typography
                            sx={{fontSize: "14px", color: "#037171", textAlign: "end"}}
                        >
                            Property
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "nowrap",
                            justifyContent: "space-between",
                            width: "80%",
                            margin: "10px auto",
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: "14px",
                                fontWeight: 700,
                                textAlign: "start",
                                width: {xs: "50%", md: "100%"},
                            }}
                        >
                            Address
                        </Typography>
                        <Typography
                            sx={{fontSize: "14px", color: "#037171", textAlign: "end"}}
                        >
                            Property
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "nowrap",
                            justifyContent: "space-between",
                            width: "80%",
                            margin: "10px auto",
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: "14px",
                                fontWeight: 700,
                                textAlign: "start",
                                width: {xs: "50%", md: "100%"},
                            }}
                        >
                            Tenant
                        </Typography>
                        <Typography
                            sx={{fontSize: "14px", color: "#037171", textAlign: "end"}}
                        >
                            Property
                        </Typography>
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        width: {lg: "33.3%", md: "100%", xs: "100%"},
                        padding: "10px",
                    }}
                >
                    <Typography
                        sx={{
                            fontWeight: 800,
                            fontSize: "16px",
                            textAlign: "start",
                            color: "#037171",
                            marginBottom: "20px",
                        }}
                    >
                        Payment Information
                    </Typography>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "nowrap",
                            justifyContent: "space-between",
                            width: "80%",
                            margin: "10px auto",
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: "14px",
                                fontWeight: 700,
                                textAlign: "start",
                                width: {xs: "50%", md: "100%"},
                            }}
                        >
                            Rent Increment Percentage
                        </Typography>
                        <Typography
                            sx={{fontSize: "14px", color: "#037171", textAlign: "end"}}
                        >
                            Property
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "nowrap",
                            justifyContent: "space-between",
                            width: "80%",
                            margin: "10px auto",
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: "14px",
                                fontWeight: 700,
                                textAlign: "start",
                                width: {xs: "50%", md: "100%"},
                            }}
                        >
                            Rent Frequency
                        </Typography>
                        <Typography
                            sx={{fontSize: "14px", color: "#037171", textAlign: "end"}}
                        >
                            Property
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "nowrap",
                            justifyContent: "space-between",
                            width: "80%",
                            margin: "10px auto",
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: "14px",
                                fontWeight: 700,
                                textAlign: "start",
                                width: {xs: "50%", md: "100%"},
                            }}
                        >
                            Periodic Change
                        </Typography>
                        <Typography
                            sx={{fontSize: "14px", color: "#037171", textAlign: "end"}}
                        >
                            Property
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "nowrap",
                            justifyContent: "space-between",
                            width: "80%",
                            margin: "10px auto",
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: "14px",
                                fontWeight: 700,
                                textAlign: "start",
                                width: {xs: "50%", md: "100%"},
                            }}
                        >
                            Rend Day
                        </Typography>
                        <Typography
                            sx={{fontSize: "14px", color: "#037171", textAlign: "end"}}
                        >
                            Property
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Grid>
    )
}
