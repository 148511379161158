import {
    ACTIVATE_METER_URL,
    ARCHIVE_METER_URL,
    ASS_MT_TYPE_URL,
    BLK_MT_TYPE_URL,
    BULK_EDIT_METER_URL,
    DELETE_READING_URL,
    EDIT_READING_URL,
    EXPORT_UTILITIES_URL,
    INVOICE_READING_URL,
    LIST_ALL_METERS_URL,
    LIST_ARCHIVED_METERS_URL,
    LIST_METERS_URL,
    LIST_TOTAL_METERS_URL,
    METER_FINANCIALS_URL, METER_READINGS_BULK_UPLOAD_TEMPLATE_URL,
    METER_READINGS_URL,
    METER_REVENUE_URL,
    MT_RD_PRD_URL,
    MT_TYPE_URL, REPORT_FIXED_METER_URL,
    SAVE_METER_URL,
    SAVE_READING_BULK_URL,
    SAVE_READING_URL,
    UPLOAD_READINGS_URL,
    VIEW_UTILITY_IMAGE_URL,
    BULK_INVOICE_READINGS_URL,
} from "../constants/url.constants"
import {serviceAPI, serviceAPIDownloadFile} from "./service"
import {handleErrorResponse} from "../actions/message"

export const getMeters = (name, pageNo, rowsPerPage) => {
    let url = LIST_METERS_URL + name + "&pageNo=" + pageNo + "&rowsPerPage=" + rowsPerPage
    return serviceAPI.get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getAllMeters = (name) => {
    let url = LIST_ALL_METERS_URL + name
    return serviceAPI.get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getTotalMeters = (name) => {
    let url = LIST_TOTAL_METERS_URL + name
    return serviceAPI.get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getArchivedMeters = (name, pageNo, rowsPerPage) => {
    let url = LIST_ARCHIVED_METERS_URL + name + "&pageNo=" + pageNo + "&rowsPerPage=" + rowsPerPage
    return serviceAPI.get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getMeterReadingPeriods = () => {
    return serviceAPI.get(MT_RD_PRD_URL)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getMeterTypes = () => {
    return serviceAPI.get(MT_TYPE_URL)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getAssetMeterTypes = () => {
    return serviceAPI.get(ASS_MT_TYPE_URL)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getBulkWaterMeterTypes = () => {
    return serviceAPI.get(BLK_MT_TYPE_URL)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getMeterFinancials = (meterId, pageNo) => {
    let url = METER_FINANCIALS_URL + meterId + "&pageNo=" + pageNo
    return serviceAPI.get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getMeterRevenue = (meterId) => {
    let url = METER_REVENUE_URL + meterId
    return serviceAPI.get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getMeterReadings = (meterId) => {
    let url = METER_READINGS_URL + meterId
    return serviceAPI.get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const downloadMeterReadingsBulkUploadTemplate = () => {
    return serviceAPIDownloadFile.get(METER_READINGS_BULK_UPLOAD_TEMPLATE_URL)
}

//POST
export const saveNewMeter = (data) => {
    return serviceAPI.post(SAVE_METER_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const saveReadingBulk = (data) => {
    return serviceAPI.post(SAVE_READING_BULK_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const archiveMeter = (data) => {
    return serviceAPI.post(ARCHIVE_METER_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const reportFixedMeter = (data) => {
    return serviceAPI.post(REPORT_FIXED_METER_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const activateMeter = (meterId) => {
    let url = ACTIVATE_METER_URL + meterId
    return serviceAPI.put(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const bulkEditMeter = (data) => {
    return serviceAPI.post(BULK_EDIT_METER_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const invoiceReading = (data) => {
    return serviceAPI.post(INVOICE_READING_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const bulkInvoiceMeterReadings = (data) => {
    return serviceAPI.post(BULK_INVOICE_READINGS_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const saveReading = (data) => {
    return serviceAPI.post(SAVE_READING_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const editReadings = (data) => {
    return serviceAPI.post(EDIT_READING_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const deleteReading = (data) => {
    return serviceAPI.post(DELETE_READING_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const uploadReadings = (data) => {
    return serviceAPI.post(UPLOAD_READINGS_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const exportUtilities = (includeImages, data) => {
    let url = EXPORT_UTILITIES_URL + includeImages
    return serviceAPIDownloadFile.post(url, data)
}

export const downloadUtilityImage = (filename) => {
    return serviceAPIDownloadFile.get(VIEW_UTILITY_IMAGE_URL + filename)
}
