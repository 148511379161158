import {IconButton, Typography} from "@mui/material";
import {number} from "prop-types";

import {ResponsiveRow} from "../../../constants/layout.constants";

import {Visibility} from "@mui/icons-material";
import ViewWorkOrderNotes from "../../modals/dashboard/buildingDirector/viewWorkOrderNotes";
import {useEffect, useState} from "react";
import {getAssignedWorkOrders} from "../../../services/dashboard.service";
import {isNullUndefined, sortJSONArray} from "../../../util/util";
import MuiDataGrid from "../MuiDataGrid";
import {useSelector} from "react-redux";

/**
 *
 * REQUIRED PROPS
 *
 * customHeight: string
 * customWidth: string
 * data: {
 *    id: number
 *    date: string
 *    status: string
 *    amount: number
 *    description: string
 *    vendor: string
 *    notes: [{
 *      id: number
 *      createdAt: string
 *      note: string
 *      createdBy: string
 *    }]
 *  }
 */

export default function AssignedWorkOrders({
                                               customHeight,
                                               customWidth,
                                               data,
                                               currentlyLoading,
                                           }) {
    const [dataGridRows, setDataGridRows] = useState([]);
    const [selectedWorkOrderNotes, setSelectedWorkOrderNotes] = useState([]);
    const [openViewModal, setOpenViewModal] = useState(false);
    const [loading, setIsLoading] = useState(false);
    const {user} = useSelector((state) => state.auth);

    function handleOpenViewModal(data) {
        setSelectedWorkOrderNotes(data.notes);

        setOpenViewModal(true);
    }

    function handleCloseViewModal() {
        setSelectedWorkOrderNotes([]);

        setOpenViewModal(false);
    }

    const DataGridColumns = [
        {
            field: "dateAssigned",
            headerName: "Time Assigned",
            width: 200,
            editable: true,
        },
        {
            field: "status",
            headerName: "Status",
            minWidth: 150,
            flex: 0.75,
            editable: true,
        },
        {
            field: "amount",
            headerName: "Amount",
            type: number,
            minWidth: 150,
            flex: 0.75,
        },
        {
            field: "description",
            headerName: "Description",
            minWidth: 400,
            flex: 2,
            editable: true,
        },
        {
            field: "vendor",
            headerName: "Vendor",
            minWidth: 300,
            flex: 1.5,
            editable: true,
        },
        {
            field: "notes",
            headerName: "Notes",
            sortable: false,
            minWidth: 150,
            flex: 0.75,
            renderCell: (params) => (
                <ResponsiveRow
                    sx={{
                        width: "100%",
                        flexDirection: "row",
                        flexWrap: "nowrap",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        padding: 0,
                    }}
                >
                    <IconButton onClick={() => handleOpenViewModal(params.row)}>
                        <Visibility sx={{color: "#037171"}}/>
                    </IconButton>
                </ResponsiveRow>
            ),
        },
    ];

    useEffect(() => {
        setIsLoading(true);
        getAssignedWorkOrders()
            .then((response) => {
                if (!isNullUndefined(response) && response.data) {
                    setDataGridRows(
                        response.data.sort(sortJSONArray("dateAssigned", false))
                    );
                }
            })
            .finally(() => setIsLoading(false));
        return () => setDataGridRows([]);
    }, [user.proxyFacility]);

    // useEffect(() => {
    //   setIsLoading(currentlyLoading);
    // }, [currentlyLoading]);

    return (
        <ResponsiveRow
            sx={{
                flexDirection: "column",
                flexWrap: "nowrap",

                alignItems: "flex-start",

                padding: "20px",

                height: {xs: "100%", lg: customHeight},
                width: {xs: "100%", lg: customWidth},

                margin: "10px",

                backgroundColor: "#fff",
                borderRadius: "20px",
            }}
        >
            <Typography
                sx={{
                    width: "100%",
                }}
                textAlign="start"
                variant="h5"
            >
                Assigned Work Orders
            </Typography>

            <MuiDataGrid
                alt={true}
                noSelection={true}
                dataGridColumns={DataGridColumns}
                dataGridRows={dataGridRows}
                serverPagination={false}
                loading={loading}
                height="90%"
                totalRows={dataGridRows.length}
            />

            <ViewWorkOrderNotes
                handleClose={handleCloseViewModal}
                open={openViewModal}
                data={selectedWorkOrderNotes}
            />
        </ResponsiveRow>
    );
}
