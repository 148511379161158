import {Alert, AlertTitle, FormControl, FormLabel, Switch, TextField,} from "@mui/material"
import {ResponsiveRow} from "../../../../constants/layout.constants"
import ModalContainerModule from "../../../modules/modalContainer"
import {DatePicker} from '@mui/x-date-pickers/DatePicker'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'

export default function DownloadMeterBillingModal(props) {
    /**
     *
     * REQUIRED PROPS
     *
     * DownloadModal
     *
     * handleDownloadClose
     * handleIncludeMeterBillingDetailToggle
     * handleStatementYearChange
     *
     * setDownloadEmailModal
     *
     * downloadMeterBillingDocs
     * includeMeterBillingDetail
     * selectionModel
     * statementYear
     *
     */

    return (
        <ModalContainerModule
            size="specialDownloadMeterBilling"
            accept={props.downloadMeterBillingDocs}
            cancel={props.handleDownloadClose}
            openModal={props.DownloadModal}
            modalTitle={
                props.selectionModel.length > 1
                    ? "Download " + props.selectionModel.length + " Meter Billings"
                    : "Download Meter Billing"
            }
            acceptButtonText="Export Via Email"
        >
            <Alert sx={{width: "100%", height: "auto"}} severity="info">
                <AlertTitle>
                    Note: Processing the selected meter billings may take some time.
                </AlertTitle>
                Therefore, the exported data will be emailed to you when it is finished
                being packaged. Please ensure the email set for your account is valid,
                working and that you have access to it in order to receive the export
                data.
            </Alert>
            <br/>
            <ResponsiveRow
                sx={{
                    width: "100%",
                    height: "auto",
                    flexWrap: {xs: "wrap", lg: "nowrap"},
                }}
                container
            >
                <FormControl
                    item
                    sx={{width: {xs: "100%", lg: "50%"}, margin: "5px"}}
                >
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                        <DatePicker
                            label="Statement Year"
                            sx={{width: {xs: "100%", lg: "90%"}}}
                            id={"statementYear"}
                            value={props.statementYear}
                            disableFuture={true}
                            disablePast={false}
                            openTo="year"
                            views={["year"]}
                            isrequired={true}
                            onChange={(newDate) => props.handleStatementYearChange(newDate)}

                        />
                    </LocalizationProvider>
                </FormControl>

                <FormControl
                    item
                    sx={{width: {xs: "100%", lg: "50%"}, margin: "5px"}}
                >
                    <FormLabel>Include Meter Billing Detail Documents</FormLabel>
                    <Switch
                        label="Include Meter Billing Detail Documents"
                        checked={props.includeMeterBillingDetail}
                        onChange={(event) =>
                            props.handleIncludeMeterBillingDetailToggle(event)
                        }
                        inputProps={{"aria-label": "controlled"}}
                    />
                </FormControl>
            </ResponsiveRow>
        </ModalContainerModule>
    )
}
