import {
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { ResponsiveRow } from "../../../../constants/layout.constants";
import ModalContainerModule from "../../../modules/modalContainer";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ModalAcceptButton } from "../../../../constants/component.constants";
import { useState } from "react";
import dayjs from "dayjs";

export default function CreateJournalEntry(props) {
  /**
   *
   * REQUIRED PROPS
   *
   * AddDiv
   * saveEntry
   *
   * handleDateChange
   * handleUnitChange
   * handleComplexChange
   * handleAssetChange
   * handleLeaseChange
   * handleTieredItemAdd
   *
   * setAddDiv
   * setSelectedEntry
   * setSelection
   *
   * selectedEntry
   * date
   * setMemo
   *
   * asset
   * assetOptions
   * bulkFile
   * complex
   * complexOptions
   * lease
   * leaseOptions
   * memo
   * tieredItemsTableRows
   * unit
   * unitOptions
   *
   */

  const [selection, setSelection] = useState();


  return (
    <ModalContainerModule
      size="md"
      accept={props.saveEntry}
      cancel={props.closeAddModal}
      openModal={props.AddDiv}
      modalTitle=" New Journal Entry"
      acceptButtonText="Save"
    >
      <ResponsiveRow container sx={{ height: "auto" }}>
        <FormControl
          item
          sx={{
            width: { xs: "100%", lg: "50%" },
            marginBottom: "10px",
          }}
        >
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale="en-gb"
          >
            <DatePicker
              label="Date"
              sx={{ width: { xs: "100%", lg: "90%" } }}
              id={"readingDate"}
              value={
                props.selectedEntry
                  ? dayjs(
                      props.selectedEntry && props.selectedEntry.postingDate
                    )
                  : dayjs(props.date)
              }
              onChange={(newDate) => props.handleDateChange(newDate)}
            />
          </LocalizationProvider>
        </FormControl>
        <FormControl
          item
          sx={{
            width: "50%",
            marginBottom: "10px",
            marginTop: "20px",
          }}
        >
          <FormLabel
            id="demo-radio-buttons-group-label"
            sx={{ color: "#037171", fontWeight: "700" }}
          >
            Select the property or Asset this entry relates to:
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="female"
            name="radio-buttons-group"
            value={selection}
            onChange={(event) => setSelection(event.target.value)}
          >
            <FormControlLabel
              value="property"
              control={<Radio />}
              label="Property/Title/Unit"
            />
            <FormControlLabel
              value="complex"
              control={<Radio />}
              label="Complex"
            />
            <FormControlLabel value="asset" control={<Radio />} label="Asset" />
            <FormControlLabel
              value="lease"
              control={<Radio />}
              label="Active Lease"
            />
          </RadioGroup>
        </FormControl>{" "}
        <FormControl
          item
          sx={{
            width: { xs: "100%", lg: "50%" },
            marginBottom: "10px",
          }}
        >
          <InputLabel>Memo</InputLabel>
          <OutlinedInput
            label="Memo"
            id="memo"
            sx={{
              width: { xs: "100%", md: "90%" },
            }}
            onChange={(event) => {
              const value = event.target.value;
              props.setMemo(value);
              let obj = props.selectedEntry;
              obj.description = value;
              props.setSelectedEntry(obj);
            }}
            value={
              props.selectedEntry
                ? props.selectedEntry && props.selectedEntry.description
                : props.memo
            }
            multiline
            rows={4}
          />
        </FormControl>
        {selection === "property" && (
          <FormControl
            item
            sx={{
              width: { xs: "100%", lg: "50%" },
              marginBottom: "10px",
            }}
          >
            <InputLabel>Property</InputLabel>
            <Select
              label="Property"
              sx={{ width: { xs: "100%", md: "90%" } }}
              id={"property"}
              value={
                props.selectedEntry
                  ? props.selectedEntry && props.selectedEntry.facility.id
                  : props.property
              }
              onChange={(event) => props.handlePropertyChange(event)}
            >
              {props.facilityOptions && props.facilityOptions.length > 0 ? (
                props.facilityOptions.map(function (facility, i) {
                  return (
                    <MenuItem key={i} value={facility.value}>
                      {facility.label}
                    </MenuItem>
                  );
                }, this)
              ) : (
                <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
              )}
            </Select>
          </FormControl>
        )}
        {selection === "property" && (
          <FormControl
            item
            sx={{
              width: { xs: "100%", lg: "50%" },
              marginBottom: "10px",
            }}
          >
            <InputLabel>Unit</InputLabel>
            <Select
              label="Unit"
              sx={{ width: { xs: "100%", md: "90%" } }}
              id={"unit"}
              value={
                props.selectedEntry
                  ? props.selectedEntry && props.selectedEntry.unit.id
                  : props.unit
              }
              onChange={(event) => props.handleUnitChange(event)}
            >
              {props.unitOptions && props.unitOptions.length > 0 ? (
                props.unitOptions.map(function (unit, i) {
                  return (
                    <MenuItem key={i} value={unit.value}>
                      {unit.label}
                    </MenuItem>
                  );
                }, this)
              ) : (
                <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
              )}
            </Select>
          </FormControl>
        )}
        {selection === "complex" && (
          <FormControl
            item
            sx={{
              width: { xs: "100%", lg: "50%" },
              marginBottom: "10px",
            }}
          >
            <InputLabel>Complex</InputLabel>
            <Select
              label="Complex"
              sx={{ width: { xs: "100%", md: "90%" } }}
              id={"complex"}
              value={
                props.selectedEntry
                  ? props.selectedEntry && props.selectedEntry.complex.id
                  : props.complex
              }
              onChange={(event) => props.handleComplexChange(event)}
            >
              {props.complexOptions && props.complexOptions.length > 0 ? (
                props.complexOptions.map(function (complex, i) {
                  return (
                    <MenuItem key={i} value={complex.value}>
                      {complex.label}
                    </MenuItem>
                  );
                }, this)
              ) : (
                <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
              )}
            </Select>
          </FormControl>
        )}
        {selection === "asset" && (
          <FormControl
            item
            sx={{
              width: { xs: "100%", lg: "50%" },
              marginBottom: "10px",
            }}
          >
            <InputLabel>Asset</InputLabel>
            <Select
              label="Asset"
              sx={{ width: { xs: "100%", md: "90%" } }}
              id={"asset"}
              value={
                props.selectedEntry
                  ? props.selectedEntry && props.selectedEntry.asset.id
                  : props.asset
              }
              onChange={(event) => props.handleAssetChange(event)}
            >
              {props.assetOptions && props.assetOptions.length > 0 ? (
                props.assetOptions.map(function (asset, i) {
                  return (
                    <MenuItem key={i} value={asset.value}>
                      {asset.label}
                    </MenuItem>
                  );
                }, this)
              ) : (
                <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
              )}
            </Select>
          </FormControl>
        )}
        {selection === "lease" && (
          <FormControl
            item
            sx={{
              width: { xs: "100%", lg: "50%" },
              marginBottom: "10px",
            }}
          >
            <InputLabel>Lease</InputLabel>
            <Select
              label="Lease"
              sx={{ width: { xs: "100%", md: "90%" } }}
              id={"lease"}
              value={
                props.selectedEntry
                  ? props.selectedEntry && props.selectedEntry.lease.id
                  : props.lease
              }
              onChange={(event) => props.handleLeaseChange(event)}
            >
              {props.leaseOptions && props.leaseOptions.length > 0 ? (
                props.leaseOptions.map(function (lease, i) {
                  return (
                    <MenuItem key={i} value={lease.value}>
                      {lease.label}
                    </MenuItem>
                  );
                }, this)
              ) : (
                <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
              )}
            </Select>
          </FormControl>
        )}
        <br />
      </ResponsiveRow>

      <ResponsiveRow sx={{ height: "auto" }}>
        <Typography
          sx={{ marginBottom: "5px" }}
          id="modal-modal-title"
          variant="h5"
          component="h4"
        >
          Debits and Credits
        </Typography>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: "30%" }}>Debit Account</TableCell>
                <TableCell sx={{ width: "30%" }}>Credit Account</TableCell>
                <TableCell sx={{ width: "25%" }}>Description</TableCell>
                <TableCell sx={{ width: "15%" }}>Amount</TableCell>
                <TableCell sx={{ width: "5%" }}>Delete</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{props.tieredItemsTableRows}</TableBody>
          </Table>
        </TableContainer>
        <ResponsiveRow
          sx={{ justifyContent: { xs: "center", lg: "flex-end" } }}
          container
        >
          <ModalAcceptButton
            variant="contained"
            type="submit"
            onClick={props.handleTieredItemAdd}
            sx={{ minWidth: { xs: "100%", lg: "175px" } }}
          >
            Add row
          </ModalAcceptButton>
        </ResponsiveRow>
      </ResponsiveRow>
    </ModalContainerModule>
  );
}
