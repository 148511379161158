/* eslint-disable react-hooks/exhaustive-deps */
import {
    Alert,
    AlertTitle,
    Box,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    Typography,
} from "@mui/material";
import {isEmptyString, isNullUndefined} from "../../util/util";
import {useEffect, useMemo, useState} from "react";
import {getAllUnitOptions, getCashPaymentAccounts, getPaymentVoucherMethods,} from "../../services/list.service";
import {ResponsiveRow} from "../../constants/layout.constants";
import MuiDataGrid from "../../components/modules/MuiDataGrid";

export default function WorkOrderVendorCharge(props) {
    const [unitOptions, setUnitOptions] = useState([]);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [cashPaymentAccounts, setCashPaymentAccounts] = useState([]);
    const [paymentAmount, setPaymentAmount] = useState("");
    const [paymentChequeNumber, setPaymentChequeNumber] = useState("");
    const [paymentMethodCode, setPaymentMethodCode] = useState("");
    const [paymentCashAccount, setPaymentCashAccount] = useState("");
    const [charges, setCharges] = useState([]);
    const [hasNoBillableCharges, setHasNoBillableCharges] = useState(false);
    const [hasUnit, setHasUnit] = useState(false);
    const [rowSelectionModel, setRowSelectionModel] = useState([]);
    const [bankRefNumber, setBankRefNumber] = useState("")

    useEffect(() => {
        if (
            !isNullUndefined(props.selectedWorkOrder) &&
            !isNullUndefined(props.selectedWorkOrder.id)
        ) {
            getPaymentVoucherMethods().then((paymentMethods) =>
                setPaymentMethods(paymentMethods)
            );

            getCashPaymentAccounts().then((cashPaymentAccounts) =>
                setCashPaymentAccounts(cashPaymentAccounts)
            );

            if (!isNullUndefined(props.selectedWorkOrder.facilityId)) {
                getAllUnitOptions(props.selectedWorkOrder.facilityId).then(
                    (unitOptions) => {
                        setUnitOptions(unitOptions);
                    }
                );
            }
        }
    }, [props.selectedWorkOrder]);

    useEffect(() => {
        populateCharges();
    }, []);

    useEffect(() => {
        let itemsToBill = props.itemsToBill;
        itemsToBill[props.vendorIndex].charges = rowSelectionModel;
        props.itemsToBillChanged(itemsToBill);
    }, [rowSelectionModel]);

    function handlePaymentMethodChange(event) {
        let value = event.target.value;
        let itemsToBill = props.itemsToBill;
        itemsToBill[props.vendorIndex].paymentMethodCd = value;
        if (value === "CHQ") {
            itemsToBill[props.vendorIndex].cashAccount = null;
            itemsToBill[props.vendorIndex].bankRefNumber = null;
        }
        if (value === "CSH") {
            itemsToBill[props.vendorIndex].chequeNumber = null;
            itemsToBill[props.vendorIndex].bankRefNumber = null;
        }
        if (value === "RTGS") {
            itemsToBill[props.vendorIndex].cashAccount = null;
            itemsToBill[props.vendorIndex].chequeNumber = null;
        }
        props.itemsToBillChanged(itemsToBill);
        setPaymentMethodCode(value);
    }

    function handleCashAccountChange(event) {
        let value = event.target.value;
        let itemsToBill = props.itemsToBill;
        itemsToBill[props.vendorIndex].cashAccount = value;
        props.itemsToBillChanged(itemsToBill);
        setPaymentCashAccount(value);
    }

    function onTextChange(event) {
        const id = event.target.id;
        const value = event.target.value;
        if (id === "paymentAmount") {
            let itemsToBill = props.itemsToBill;
            itemsToBill[props.vendorIndex].paymentAmount = value;
            props.itemsToBillChanged(itemsToBill);
            setPaymentAmount(value);
        }
        if (id === "paymentChequeNumber") {
            let itemsToBill = props.itemsToBill;
            itemsToBill[props.vendorIndex].chequeNumber = value;
            props.itemsToBillChanged(itemsToBill);
            setPaymentChequeNumber(value);
        }
        if (id === "bankRefNo") {
            let itemsToBill = props.itemsToBill;
            itemsToBill[props.vendorIndex].bankRefNumber = value;
            props.itemsToBillChanged(itemsToBill);
            setBankRefNumber(value);
        }
    }

    function populateCharges() {
        let tempCharges = [];
        props.vendor.charges &&
        props.vendor.charges.length > 0 &&
        props.vendor.charges.forEach(function (chargeItem, i) {
            if (!chargeItem.billed) {
                tempCharges.push(chargeItem);
                if (!isNullUndefined(chargeItem.unitId)) {
                    setHasUnit(true);
                }
            }
        }, this);

        if (tempCharges.length === 0) {
            setHasNoBillableCharges(true);
        }

        setCharges(tempCharges);
    }

    const columns = useMemo(() => {
        let columnArray = [];
        let memoWidth = 280;
        let memoWidthFlex = 2.8;
        let priceWidth = 200;
        let priceWidthFlex = 2.0;
        let subWidth = 200;
        let subWidthFlex = 2.0;
        let quantityWidth = 130;
        let quantityWidthFlex = 1.3;
        if (hasUnit) {
            columnArray.push({
                field: "unitId",
                headerName: "Unit",
                minWidth: 200,
                flex: 2,
                valueFormatter: (row) => {
                    if (!isNullUndefined(row.value)) {
                        let unitName = "";
                        for (let unit of unitOptions) {
                            if (unit.value === row.value) {
                                unitName = unit.label;
                            }
                        }
                        return unitName;
                    }
                },
            });
            memoWidth = 200;
            memoWidthFlex = 2.0;
            priceWidth = 150;
            priceWidthFlex = 1.5;
            subWidth = 150;
            subWidthFlex = 1.5;
            quantityWidth = 100;
            quantityWidthFlex = 1.0;
        }
        columnArray.push({
            field: "description",
            headerName: "Memo",
            minWidth: memoWidth,
            flex: memoWidthFlex,
        });
        columnArray.push({
            field: "unitPrice",
            headerName: "Unit Price",
            minWidth: priceWidth,
            flex: priceWidthFlex,
        });
        columnArray.push({
            field: "quantity",
            headerName: "Quantity",
            minWidth: quantityWidth,
            flex: quantityWidthFlex,
        });
        columnArray.push({
            field: "subTotal",
            headerName: "Sub Total",
            minWidth: subWidth,
            flex: subWidthFlex,
        });
        columnArray.push({
            field: "applyVAT",
            headerName: "Tax (%)",
            minWidth: 150,
            flex: 1.5,
            valueFormatter: (row) => {
                if (!isNullUndefined(row.value)) {
                    return row.value ? "16" : "0";
                }
            },
        });
        columnArray.push({
            field: "otherCharges",
            headerName: "Additional Charges",
            minWidth: 180,
            flex: 1.8,
            valueFormatter: (row) => {
                if (!isNullUndefined(row.value)) {
                    return row.value;
                } else {
                    return "0";
                }
            },
        });
        columnArray.push({
            field: "total",
            headerName: "Total",
            minWidth: 185,
            flex: 1.85,
        });
        return columnArray;
    });

    return (
      <ResponsiveRow
        sx={{
          height: "fit-content",
          width: "100%",

          flexDirection: "column",
          padding: 0,
        }}
      >
        {!hasNoBillableCharges && (
          <Grid
            container
            spacing={1}
            sx={{
              padding: "10px",
              width: { xs: "100%", md: "100%" },
              backgroundColor: "#fff",
              borderRadius: "10px",
              minHeight: "240px",
              margin: "10px auto",
              boxShadow: props.disableShadow
                ? "none"
                : "0 4px 12px 0 rgba(0,0,0,0.25)",
              height: "fit-content",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                margin: "auto 10px",
                width: "100%",
                height: "fit-content",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "100%",
                  height: "fit-content",
                }}
              >
                <ResponsiveRow
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    justifyContent: { xs: "center", lg: "flex-start" },
                    alignItems: "center",
                    padding: "0 20px",
                    height: "fit-content",
                  }}
                >
                  <ResponsiveRow
                    sx={{
                      flexDirection: "column",
                      width: "100%",
                      justifyContent: { xs: "center", lg: "space-between" },
                      padding: "10px",
                      height: "fit-content",
                      textAlign: { xs: "center", lg: "start" },
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: 800,
                        width: "100%",
                      }}
                    >
                      Vendor Name
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "1.2em",
                        fontWeight: 800,
                        color: "#009F93",
                        width: "100%",
                      }}
                    >
                      {props.vendor.vendorName}
                    </Typography>
                  </ResponsiveRow>

                  <ResponsiveRow
                    sx={{
                      flexDirection: { xs: "column", lg: "row" },
                      width: "100%",
                      justifyContent: { xs: "center", lg: "flex-start" },
                      padding: "5px",
                      margin: { xs: "5px auto", lg: "auto 5px" },
                      backgroundColor: "#fff",
                      borderRadius: "10px",
                      height: "fit-content",
                      textAlign: { xs: "center", lg: "start" },
                    }}
                  >
                    {props.issuePaymentVoucher && !hasNoBillableCharges && (
                      <FormControl
                        item
                        sx={{
                          width: { xs: "100%", lg: "33.3%" },
                          marginBottom: "10px",
                        }}
                      >
                        <InputLabel>Payment Method</InputLabel>
                        <Select
                          label="Payment Method"
                          sx={{ width: "90%" }}
                          value={paymentMethodCode}
                          onChange={(event) => handlePaymentMethodChange(event)}
                        >
                          {paymentMethods && paymentMethods.length > 0 ? (
                            paymentMethods.map(function (paymentMethod, i) {
                              return (
                                <MenuItem
                                  key={paymentMethod.value}
                                  value={paymentMethod.value}
                                >
                                  {paymentMethod.label}
                                </MenuItem>
                              );
                            }, this)
                          ) : (
                            <MenuItem sx={{ width: "100%" }}>
                              No Results Found
                            </MenuItem>
                          )}
                        </Select>
                      </FormControl>
                    )}

                    {props.issuePaymentVoucher &&
                      paymentMethodCode === "CHQ" && (
                        <FormControl
                          sx={{
                            width: { xs: "100%", lg: "33.3%" },
                            marginBottom: "10px",
                          }}
                          item
                        >
                          <InputLabel>Cheque Number</InputLabel>
                          <OutlinedInput
                            sx={{ width: "90%" }}
                            label="Cheque Number"
                            id={"paymentChequeNumber"}
                            value={paymentChequeNumber}
                            onChange={(event) => onTextChange(event)}
                          />
                        </FormControl>
                      )}

                      {props.issuePaymentVoucher &&
                          paymentMethodCode === "RTGS" && (
                              <FormControl
                                  sx={{
                                      width: { xs: "100%", lg: "33.3%" },
                                      marginBottom: "10px",
                                  }}
                                  item
                              >
                                  <InputLabel>Bank Reference Number</InputLabel>
                                  <OutlinedInput
                                      sx={{ width: "90%" }}
                                      label="Bank Reference Number"
                                      id={"bankRefNo"}
                                      value={bankRefNumber}
                                      onChange={(event) => onTextChange(event)}
                                  />
                              </FormControl>
                          )}

                    {props.issuePaymentVoucher &&
                      paymentMethodCode === "CSH" && (
                        <FormControl
                          sx={{
                            width: { xs: "100%", lg: "33.3%" },
                            marginBottom: "10px",
                          }}
                          item
                        >
                          <InputLabel>Cash Account</InputLabel>
                          <Select
                            sx={{ width: "90%" }}
                            label="Cash Account"
                            value={paymentCashAccount}
                            onChange={(event) => handleCashAccountChange(event)}
                          >
                            {cashPaymentAccounts &&
                            cashPaymentAccounts.length > 0 ? (
                              cashPaymentAccounts.map(function (account, i) {
                                return (
                                  <MenuItem
                                    key={account.value}
                                    value={account.value}
                                  >
                                    {account.label}
                                  </MenuItem>
                                );
                              }, this)
                            ) : (
                              <MenuItem sx={{ width: "100%" }}>
                                No Results Found
                              </MenuItem>
                            )}
                          </Select>
                        </FormControl>
                      )}


                    {props.issuePaymentVoucher &&
                      !isNullUndefined(paymentMethodCode) &&
                      !isEmptyString(paymentMethodCode) && (
                        <FormControl
                          sx={{
                            width: { xs: "100%", lg: "33.3%" },
                            marginBottom: "10px",
                          }}
                          item
                        >
                          <InputLabel>Payment Amount</InputLabel>
                          <OutlinedInput
                            sx={{ width: "90%" }}
                            type={"number"}
                            label="Payment Amount"
                            id={"paymentAmount"}
                            value={paymentAmount}
                            onChange={(event) => onTextChange(event)}
                          />
                        </FormControl>
                      )}
                  </ResponsiveRow>
                </ResponsiveRow>
              </Box>
            </Box>

            {!hasNoBillableCharges && (
              <ResponsiveRow
                sx={{
                  margin: "0 20px",
                  width: "100%",
                  height: "fit-content",
                }}
              >
                <MuiDataGrid
                  loading={false}
                  alt={true}
                  noBoxShadow={true}
                  serverPagination={false}
                  currentSelectionModel={rowSelectionModel}
                  handleSelectedRows={setRowSelectionModel}
                  dataGridColumns={columns}
                  dataGridRows={charges}
                  height="300px"
                  totalRows={charges.length}
                />
              </ResponsiveRow>
            )}
          </Grid>
        )}

        {hasNoBillableCharges && (
          <Grid>
            <br />
            <Alert severity="info">
              <AlertTitle>
                {"The vendor " +
                  props.vendor.vendorName +
                  " does not have any billable items on this work order. All items have already been billed and may be awaiting payment."}
              </AlertTitle>
            </Alert>
            <br />
          </Grid>
        )}
      </ResponsiveRow>
    );
}
