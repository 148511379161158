import {Alert, Grid, InputLabel, Paper, Switch, TextField,} from "@mui/material"
import {DatePicker} from '@mui/x-date-pickers/DatePicker'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'

import {ResponsiveRow} from "../../../../constants/layout.constants"
import ModalContainerModule from "../../../modules/modalContainer"

export default function DownloadTerminatedLeaseModal(props) {
    /**
     *
     * REQUIRED PROPS
     *
     * handleDownloadClose
     * handleStatementYearChange
     * handleIncludeLeaseDetailToggle
     *
     * downloadLeaseDocs
     * downloadModal
     * includeLeaseDetail
     * selectionModel
     * statementYear
     *
     */
    return (
        <ModalContainerModule
            size="xs"
            accept={props.downloadLeaseDocs}
            cancel={props.handleDownloadClose}
            openModal={props.downloadModal}
            modalTitle={
                props.selectionModel.length > 1
                    ? "Download " + props.selectionModel.length + " Leases"
                    : "Download Lease"
            }
            acceptButtonText="Export Via Email"
        >
            <Paper
                variant=""
                sx={{height: "auto", margin: "auto", padding: "10px"}}
            >
                <Alert severity="info">
                    Note: Processing the selected Leases may take some time. Therefore,
                    the exported data will be emailed to you when it is finished being
                    packaged. Please ensure the email set for your account is valid,
                    working and that you have access to it in order to receive the export
                    data.
                </Alert>
                <br/>
                <ResponsiveRow container>
                    <Grid item sx={{width: {xs: "100%", lg: "50%"}}}>
                        <InputLabel>Statement Year</InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                            <DatePicker
                                sx={{width: {xs: "100%", lg: "90%"}}}
                                id={"statementYear"}
                                value={props.statementYear}
                                disableFuture={true}
                                disablePast={false}
                                openTo="year"
                                views={["year"]}
                                isrequired={true}
                                onChange={(newDate) => props.handleStatementYearChange(newDate)}

                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item sx={{width: {xs: "100%", lg: "50%"}}}>
                        <InputLabel>Include Lease Details Documents</InputLabel>
                        <Switch
                            checked={props.includeLeaseDetail}
                            onChange={(event) => props.handleIncludeLeaseDetailToggle(event)}
                            inputProps={{"aria-label": "controlled"}}
                        />
                    </Grid>
                </ResponsiveRow>
                <br/>
            </Paper>
        </ModalContainerModule>
    )
}
