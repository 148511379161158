import {Button, Card, CardActionArea, CardContent, Grid, TextField, Typography,} from "@mui/material"
import {Box} from "@mui/system"
import {useEffect, useState} from "react"
import {formatDateTime, isNullUndefined} from "../../util/util"
import {getWorkOrderNotes, saveWorkOrderNote,} from "../../services/workOrder.service"
import {setMessage} from "../../actions/message"
import {useDispatch} from "react-redux";

export default function WorkOrderNotesWideCard(props) {
    const [notes, setNotes] = useState([])
    const [note, setNote] = useState("")
    const dispatch = useDispatch()

    useEffect(() => {
        if (
            !isNullUndefined(props.selectedWorkOrder) &&
            !isNullUndefined(props.selectedWorkOrder.id)
        ) {
            getWorkOrderNotes(props.selectedWorkOrder.id)
                .then((notes) => setNotes(notes))
                .catch((error) => {
                    console.log("getNotes Error", error)
                })
        }
    }, [props.selectedWorkOrder])

    function onTextChange(event) {
        console.log("note.length", note.length)
        if (note.length < 500) {
            setNote(event.target.value)
        } else {
            dispatch(
                setMessage({
                    type: "warning",
                    message: "Note should be less that 500 characters",
                })
            )
        }
    }

    function saveNote() {
        let data = {
            workOrderId: props.selectedWorkOrder.id,
            note: note,
        }
        saveWorkOrderNote(data)
            .then((response) => {
                dispatch(
                    setMessage({
                        type: response.status,
                        message: response.message,
                    })
                )
                getWorkOrderNotes(props.selectedWorkOrder.id)
                    .then((notes) => setNotes(notes))
                    .catch((error) => {
                        console.log("getNotes Error", error)
                    })
                setNote("")
            })
            .catch((error) => {
                console.log("saveNote Error", error)
            })
    }

    return (
        <Grid
            container
            sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: {xs: "wrap", lg: "nowrap"},
                minHeight: props.height ? props.height : "340px",
                width: "100%",
                backgroundColor: "#fff",
                borderRadius: "10px",
                margin: "20px auto",
                padding: 0,
                boxShadow: "0 4px 12px 0 rgba(0,0,0,0.25)",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: {xs: "100%", lg: "50%"},
                }}
            >
                <Typography
                    sx={{
                        fontSize: "18px",
                        color: "#037171",
                        fontWeight: "800",
                        textAlign: "start",
                        marginBottom: "5px",
                        padding: "20px 20px 5px 20px",
                    }}
                >
                    Work Order Notes
                </Typography>
                {props.selectedWorkOrder.status.code === "INP" && (
                    <Typography
                        sx={{
                            fontSize: "16px",
                            fontWeight: "700",
                            textAlign: "start",
                            padding: "5px 25px 0px 25px",
                        }}
                    >
                        Add Notes
                    </Typography>
                )}
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: {xs: "column", lg: "row"},
                        width: {xs: "100%", lg: "50% 50%"},
                        padding: "20px",
                    }}
                >
                    {props.selectedWorkOrder.status.code === "INP" && (
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-around",
                                alignItems: "center",
                                padding: "20px",
                                width: "100%",
                            }}
                        >
                            <TextField
                                sx={{
                                    backgroundColor: "#efefef",
                                    borderColor: "#efefef",
                                    width: "100%",
                                }}
                                id="addNoteInput"
                                label="Add Note"
                                variant="outlined"
                                size="small"
                                onChange={(event) => onTextChange(event)}
                                value={note}
                                multiline
                                rows={4}
                            />
                            <Button
                                onClick={saveNote}
                                // disabled={note.length < 2}
                                sx={{
                                    backgroundColor: "#037171",
                                    color: "#fff",
                                    "&:hover": {
                                        backgroundColor: "#fff",
                                        color: "#037171",
                                    },
                                    margin: "10px 5px",
                                    width: "100%",
                                }}
                            >
                                Add Note
                            </Button>
                        </Box>
                    )}
                </Box>
            </Box>
            <Box
                sx={{
                    backgroundColor: "#EFEFEF",
                    borderRadius: "10px",
                    // minHeight: "100%",
                    width: "100%",
                    padding: "0",
                    // margin: "5px 0 0 0",
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                        flexWrap: "nowrap",
                        width: "100%",
                        padding: "10px",
                    }}
                >
                    <Typography
                        variant="h5"
                        sx={{
                            marginBottom: "10px",
                            marginTop: "10px",
                        }}
                    >
                        Recent Notes
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        minWidth: "100%",
                        height: "100%",
                        padding: "10px",
                    }}
                >
                    <Typography
                        sx={{
                            color: "#C6C5C5",
                            fontSize: "11px",
                            fontWeight: 700,
                            width: "100%",
                            textAlign: "center",
                        }}
                    >
                        {notes && notes.length < 1 && (
                            <Typography
                                sx={{
                                    color: "#C6C5C5",
                                    fontSize: "11px",
                                    fontWeight: 700,
                                    width: "100%",
                                    textAlign: "center",
                                }}
                            >
                                There are no notes for this Work Order
                            </Typography>
                        )}
                        {notes &&
                            notes.length > 0 &&
                            notes.map(function (note, i) {
                                return (
                                    <Card key={i} sx={{width: "100%", marginBottom: "5px"}}>
                                        <CardActionArea>
                                            <CardContent
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent: "space-between",
                                                    alignItems: "start",
                                                }}
                                            >
                                                <Typography
                                                    gutterBottom
                                                    variant="body2"
                                                    sx={{fontWeight: 800}}
                                                    component="div"
                                                >
                                                    {formatDateTime(note.createdAt)}
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary">
                                                    {note.note}
                                                </Typography>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                )
                            }, this)}
                    </Typography>
                </Box>
            </Box>
        </Grid>
    )
}
