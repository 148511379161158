/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import FileUpload from "react-material-file-upload";
import {
  Alert,
  AlertTitle,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";

import {
  adjustForTimezone,
  getDayStart,
  isArray,
  isEmptyArray,
  isEmptyString,
  isNullUndefined,
  isValidDate,
  titleCase,
  tryParseFloat,
  tryParseInt,
} from "../../util/util";
import {
  getActiveComplexForOrgOptions,
  getAllUnitOptions,
  getAssetOptions,
  getPersonsWithAdminRoles,
  getPriorityOptions,
  getProxyFacilityOptions,
  getVendorOptions,
} from "../../services/list.service";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/en-gb";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Delete from "@mui/icons-material/Delete";
import { saveWorkOrderData } from "../../services/workOrder.service";
import { ResponsiveRow } from "../../constants/layout.constants";
import ModalContainerModule from "../../components/modules/modalContainer";
import { ModalAcceptButton } from "../../constants/component.constants";
import { issueResponseMessage, issueWarnMessage } from "../../actions/message";
import dayjs from "dayjs";

export default function CreateEditWorkOrderForm(props) {
  const appMessage = useSelector((state) => state.message);
  const [selectedWorkOrder, setSelectedWorkOrder] = useState(
    props.selectedWorkOrder
  );
  const [applyTo, setApplyTo] = useState("");
  const [unitOptions, setUnitOptions] = useState([]);
  const [facilityOptions, setFacilityOptions] = useState([]);
  const [complexOptions, setComplexOptions] = useState([]);
  const [assetOptions, setAssetOptions] = useState([]);
  const [priorityOptions, setPriorityOptions] = useState([]);
  const [priority, setPriority] = useState("");
  const [assignTo, setAssignTo] = useState("");
  const [expectedCompletionDate, setExpectedCompletionDate] = useState("");
  const [description, setDescription] = useState("");
  const [workOrderDetailTableRows, setWorkOrderDetailTableRows] = useState("");
  const [vendorOptions, setVendorOptions] = useState([]);
  const [admins, setAdmins] = useState([]);
  const [files, setFiles] = useState([]);

  useEffect(() => {
    let tmp = props.selectedWorkOrder;
    if (!isNullUndefined(tmp.facilityId) && !isEmptyString(tmp.facilityId)) {
      setApplyTo("Property");
      setUnitOptions([]);
      getAllUnitOptions(tmp.facilityId).then((unitOptions) => {
        setUnitOptions(unitOptions);
      });
    }

    if (!isNullUndefined(tmp.assetId) && !isEmptyString(tmp.assetId)) {
      setApplyTo("Asset");
    }

    if (!isNullUndefined(tmp.complexId) && !isEmptyString(tmp.complexId)) {
      setApplyTo("Complex");
    }

    if (!isNullUndefined(tmp.priority) && !isEmptyString(tmp.priority)) {
      setPriority(tmp.priority);
    }

    if (!isNullUndefined(tmp.description) && !isEmptyString(tmp.description)) {
      setDescription(tmp.description);
    }

    if (
      !isNullUndefined(tmp.expectedCompletion) &&
      !isEmptyString(tmp.expectedCompletion)
    ) {
      let date = dayjs(new Date(tmp.expectedCompletion));
      tmp.expectedCompletion = date;
      setExpectedCompletionDate(date);
    }

    getPriorityOptions().then((priorityOptions) => {
      setPriorityOptions(priorityOptions);
    });

    getVendorOptions().then((vendorOptions) => {
      setVendorOptions(vendorOptions);
    });

    getPersonsWithAdminRoles().then((data) => {
      setAdmins(data);
    });
    setSelectedWorkOrder(tmp);
  }, [props.selectedWorkOrder]);

  useEffect(() => {
    populateDetailItems();
  }, [selectedWorkOrder, vendorOptions, unitOptions]);

  useEffect(() => {
    if (applyTo === "Property") {
      getProxyFacilityOptions().then((facilityOptions) => {
        setFacilityOptions(facilityOptions);
      });
    }
    if (applyTo === "Asset") {
      getAssetOptions().then((assetOptions) => {
        setAssetOptions(assetOptions);
      });
    }
    if (applyTo === "Complex") {
      getActiveComplexForOrgOptions().then((complexOptions) => {
        setComplexOptions(complexOptions);
      });
    }
    populateDetailItems();
  }, [applyTo]);

  function handleItemAdd() {
    let data = {
      id: null,
      name: "",
      vendorId: "",
      unitId: "",
      workOrderId: selectedWorkOrder.id,
      memo: "",
      unitPrice: "",
      quantity: "",
      applyVAT: false,
      subTotal: "",
      otherCharges: "",
      total: "",
    };
    let tmp = selectedWorkOrder;
    tmp.vendors.push(data);
    setSelectedWorkOrder(tmp);
    populateDetailItems();
  }

  function handleItemRemove(index) {
    let tmp = selectedWorkOrder;
    if (tmp.vendors.length > 1) {
      tmp.vendors.splice(index, 1);
      setSelectedWorkOrder(tmp);
      populateDetailItems();
    }
  }

  function unitChanged(event, index) {
    let tmp = selectedWorkOrder;
    tmp.vendors[index].unitId = event.target.value;
    setSelectedWorkOrder(tmp);
    populateDetailItems();
  }

  function handleVendorChanged(event, index) {
    let tmp = selectedWorkOrder;
    tmp.vendors[index].vendorId = event.target.value;
    setSelectedWorkOrder(tmp);
    populateDetailItems();
  }

  function populateDetailItems() {
    let rows = "";
    selectedWorkOrder &&
      selectedWorkOrder.vendors &&
      selectedWorkOrder.vendors.length > 0 &&
      (rows = selectedWorkOrder.vendors.map(function (detail, i) {
        return (
          <TableRow key={i}>
            {vendorOptions && vendorOptions.length > 0 && (
              <TableCell>
                <Select
                  sx={{ width: { xs: "100%", lg: "90%" } }}
                  id={"vendor" + i}
                  value={selectedWorkOrder.vendors[i].vendorId}
                  onChange={(event) => handleVendorChanged(event, i)}
                >
                  {vendorOptions && vendorOptions.length > 0 ? (
                    vendorOptions.map(function (vendor, i) {
                      return (
                        <MenuItem key={vendor.value} value={vendor.value}>
                          {vendor.label}
                        </MenuItem>
                      );
                    }, this)
                  ) : (
                    <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
                  )}
                </Select>
              </TableCell>
            )}

            {applyTo === "Property" &&
              unitOptions &&
              unitOptions.length > 0 && (
                <TableCell>
                  <Select
                    sx={{ width: { xs: "100%", lg: "90%" } }}
                    id={"unitId" + i}
                    value={selectedWorkOrder.vendors[i].unitId}
                    onChange={(event) => unitChanged(event, i)}
                  >
                    {unitOptions && unitOptions.length > 0 ? (
                      unitOptions.map(function (unit, i) {
                        return (
                          <MenuItem key={unit.value} value={unit.value}>
                            {unit.label}
                          </MenuItem>
                        );
                      }, this)
                    ) : (
                      <MenuItem sx={{ width: "100%" }}>
                        No Results Found
                      </MenuItem>
                    )}
                  </Select>
                </TableCell>
              )}
            <TableCell>
              <TextField
                sx={{ width: { xs: "100%", lg: "90%" } }}
                id={"memo" + i}
                value={selectedWorkOrder.vendors[i].memo}
                onChange={(event) => onDetailItemTextChange(event, i)}
              />
            </TableCell>
            <TableCell>
              <TextField
                sx={{ width: { xs: "100%", lg: "90%" } }}
                id={"unitPrice" + i}
                value={selectedWorkOrder.vendors[i].unitPrice}
                onChange={(event) => onDetailItemTextChange(event, i)}
              />
            </TableCell>
            <TableCell>
              <TextField
                sx={{ width: { xs: "100%", lg: "90%" } }}
                id={"quantity" + i}
                value={selectedWorkOrder.vendors[i].quantity}
                onChange={(event) => onDetailItemTextChange(event, i)}
              />
            </TableCell>
            <TableCell>
              <TextField
                sx={{ width: { xs: "100%", lg: "90%" } }}
                id={"subTotal"}
                value={selectedWorkOrder.vendors[i].subTotal}
                disabled
                onChange={(event) => onDetailItemTextChange(event, i)}
              />
            </TableCell>
            <TableCell>
              <TextField
                sx={{ width: { xs: "100%", lg: "90%" } }}
                id={"otherCharges" + i}
                value={selectedWorkOrder.vendors[i].otherCharges}
                onChange={(event) => onDetailItemTextChange(event, i)}
              />
            </TableCell>
            <TableCell>
              <TextField
                sx={{ width: { xs: "100%", lg: "90%" } }}
                id={"total"}
                value={selectedWorkOrder.vendors[i].total}
                disabled
                onChange={(event) => onDetailItemTextChange(event, i)}
              />
            </TableCell>
            <TableCell>
              <Checkbox
                checked={selectedWorkOrder.vendors[i].applyVAT}
                disabled={
                  isNullUndefined(selectedWorkOrder.vendors[i].total) ||
                  isEmptyString(selectedWorkOrder.vendors[i].total) ||
                  selectedWorkOrder.vendors[i].total === 0
                }
                onChange={(event) => handleDetailTaxableToggle(event, i)}
                inputProps={{ "aria-label": "controlled" }}
              />
            </TableCell>
            {selectedWorkOrder &&
              selectedWorkOrder.vendors &&
              selectedWorkOrder.vendors.length > 1 && (
                <TableCell>
                  <IconButton
                    onClick={() => handleItemRemove(i)}
                    sx={{
                      marginLeft: "-25px",
                      color: "red",
                      fontSize: "5rem",
                      textAlign: "center",
                      fontWeight: 800,
                    }}
                  >
                    <Delete />
                  </IconButton>
                </TableCell>
              )}
          </TableRow>
        );
      }, this));
    setWorkOrderDetailTableRows(rows);
  }

  function onRadioChange(radio) {
    const value = radio.target.value;
    if (radio) {
      setApplyTo(value);
      populateDetailItems();
    }
  }

  function facilityChanged(event) {
    let tmp = selectedWorkOrder;
    tmp.complexId = null;
    tmp.assetId = null;
    tmp.facilityId = event.target.value;
    setSelectedWorkOrder(tmp);
    setUnitOptions([]);
    getAllUnitOptions(event.target.value).then((unitOptions) => {
      setUnitOptions(unitOptions);
    });
    populateDetailItems();
  }

  function assetChanged(event) {
    let tmp = selectedWorkOrder;
    tmp.complexId = null;
    tmp.facilityId = null;
    tmp.assetId = event.target.value;
    setSelectedWorkOrder(tmp);
    setUnitOptions([]);
    populateDetailItems();
  }

  function complexChanged(event) {
    let tmp = selectedWorkOrder;
    tmp.complexId = event.target.value;
    tmp.assetId = null;
    tmp.facilityId = null;
    setSelectedWorkOrder(tmp);
    setUnitOptions([]);
    populateDetailItems();
  }

  function priorityChanged(event) {
    let value = event.target.value;
    let tmp = selectedWorkOrder;
    tmp.priority = value;
    setSelectedWorkOrder(tmp);
    setPriority(value);
  }

  function assignToChanged(event) {
    let value = event.target.value;
    let tmp = selectedWorkOrder;
    tmp.assigneeId = value;
    setSelectedWorkOrder(tmp);
    setAssignTo(value);
  }

  function handleExpectedCompletionDateChange(value) {
    let date = new Date(value);
    let tmp = selectedWorkOrder;
    tmp.expectedCompletion = date;
    setSelectedWorkOrder(tmp);
    setExpectedCompletionDate(date);
  }

  function onTextChange(event) {
    let tmp = selectedWorkOrder;
    tmp.description = event.target.value;
    setSelectedWorkOrder(tmp);
    setDescription(event.target.value);
  }

  function onDetailItemTextChange(event, index) {
    const id = event.target.id;
    let value = event.target.value;
    let tmp = selectedWorkOrder;
    if (id === "unitPrice" + index) {
      value = tryParseFloat(event.target.value, 0);
      tmp.vendors[index].unitPrice = value;
      setSelectedWorkOrder(tmp);
      updateTotal(index);
    }

    if (id === "quantity" + index) {
      value = tryParseInt(event.target.value, 0);
      tmp.vendors[index].quantity = value;
      setSelectedWorkOrder(tmp);
      updateTotal(index);
    }

    if (id === "otherCharges" + index) {
      value = tryParseFloat(event.target.value, 0);
      tmp.vendors[index].otherCharges = value;
      setSelectedWorkOrder(tmp);
      updateTotal(index);
    }

    if (id === "memo" + index) {
      tmp.vendors[index].memo = value;
      setSelectedWorkOrder(tmp);
      populateDetailItems();
    }
  }

  function handleDetailTaxableToggle(event, index) {
    let value = event.target.checked;
    let tmp = selectedWorkOrder;
    tmp.vendors[index].applyVAT = value;
    setSelectedWorkOrder(tmp);
    updateTotal(index);
  }

  function updateTotal(index) {
    let tmp = selectedWorkOrder;
    let vendor = tmp.vendors[index];
    let total =
      tryParseFloat(vendor.unitPrice, 0) *
        tryParseInt(vendor.quantity, 0) *
        (vendor.applyVAT ? 0.16 : 0) +
      tryParseFloat(vendor.otherCharges, 0) +
      tryParseFloat(vendor.unitPrice, 0) * tryParseInt(vendor.quantity, 0);
    let subTotal =
      tryParseFloat(vendor.unitPrice, 0) * tryParseInt(vendor.quantity, 0);
    vendor.subTotal = subTotal;
    vendor.total = total;
    setSelectedWorkOrder(tmp);
    populateDetailItems();
  }

  function validateFields() {
    if (
      (isNullUndefined(selectedWorkOrder.facilityId) ||
        isEmptyString(selectedWorkOrder.facilityId)) &&
      (isNullUndefined(selectedWorkOrder.assetId) ||
        isEmptyString(selectedWorkOrder.assetId)) &&
      (isNullUndefined(selectedWorkOrder.complexId) ||
        isEmptyString(selectedWorkOrder.complexId))
    ) {
      issueWarnMessage("Select a property, asset or complex to proceed");
      return false;
    }

    if (
      isNullUndefined(selectedWorkOrder.priority) ||
      isEmptyString(selectedWorkOrder.priority)
    ) {
      issueWarnMessage("A priority must be selected");
      return false;
    }
    if (
      isNullUndefined(selectedWorkOrder.description) ||
      isEmptyString(selectedWorkOrder.description)
    ) {
      issueWarnMessage("A description must be provided");
      return false;
    }
    if (selectedWorkOrder.description.length > 500) {
      issueWarnMessage("Description cannot be more than 500 characters");
      return false;
    }

    if (
      isNullUndefined(selectedWorkOrder.expectedCompletion) ||
      !isValidDate(selectedWorkOrder.expectedCompletion)
    ) {
      issueWarnMessage("Expected Completion is required");
      return false;
    }

    if (
      !isNullUndefined(selectedWorkOrder.expectedCompletion) &&
      isValidDate(selectedWorkOrder.expectedCompletion)
    ) {
      if (selectedWorkOrder.expectedCompletion <= getDayStart(new Date())) {
        issueWarnMessage("Expected Completion Date cannot be before today");
        return false;
      }
      let tmp = selectedWorkOrder;
      tmp.expectedCompletion = adjustForTimezone(
        selectedWorkOrder.expectedCompletion
      );
      setSelectedWorkOrder(tmp);
    }

    if (isArray(selectedWorkOrder.vendors)) {
      if (selectedWorkOrder.vendors.length < 1) {
        issueWarnMessage(
          "You must add at least one vendor to save this Work Order."
        );
        return false;
      }
      for (let vendor of selectedWorkOrder.vendors) {
        if (
          isNullUndefined(vendor.unitPrice) ||
          isNullUndefined(vendor.quantity) ||
          tryParseFloat(vendor.unitPrice.toString(), -1) < 1 ||
          tryParseFloat(vendor.quantity.toString(), -1) < 1
        ) {
          issueWarnMessage(
            "unitPrice or quantity is invalid. Cannot be below 0"
          );
          return false;
        }

        if (
          isNullUndefined(vendor.memo) ||
          isEmptyString(vendor.memo) ||
          isNullUndefined(vendor.vendorId) ||
          isEmptyString(vendor.vendorId)
        ) {
          issueWarnMessage("Vendor or Memo is invalid. And is required field");
          return false;
        }
      }
    } else {
      issueWarnMessage("Vendor details is required");
      return false;
    }
    return true;
  }

  function saveWorkOrder() {
    let formData = new FormData();
    if (!isNullUndefined(files) && isArray(files) && !isEmptyArray(files)) {
      for (let i = 0; i < files.length; i++) {
        formData.append("file" + i, files[i]);
      }
    }
    formData.append("selectedWorkOrder", JSON.stringify(selectedWorkOrder));
    if (validateFields()) {
      saveWorkOrderData(formData).then((response) => {
        issueResponseMessage(response);
        props.reloadWorkOrders();
      });
    }
  }

  return (
    <ModalContainerModule
      size="md"
      accept={saveWorkOrder}
      cancel={props.handleCreateEditWorkOrderClose}
      openModal={props.task === "Create" || props.task === "Edit"}
      modalTitle={`${props.task} Work Order`}
      acceptButtonText={
        props.task === "Create"
          ? "Create"
          : props.task === "Edit"
          ? "Update"
          : "Create"
      }
    >
      <ResponsiveRow
        sx={{
          alignItems: { xs: "center", lg: "flex-start" },
          height: "auto",
        }}
        container
      >
        <FormControl sx={{ width: "100%", marginBottom: "10px" }}>
          <FormLabel>
            Select the Property/Asset/Complex that this Work Order Request shall
            be attached to. *
          </FormLabel>
          <div className="ui toggle checkbox">
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="use-radio-group"
            >
              <FormControlLabel
                name="radio"
                control={
                  <Radio
                    checked={applyTo === "Property"}
                    value="Property"
                    onClick={onRadioChange}
                  />
                }
                label="Property/Unit"
              />
              <FormControlLabel
                name="radio"
                control={
                  <Radio
                    checked={applyTo === "Asset"}
                    value="Asset"
                    onClick={onRadioChange}
                  />
                }
                label="Asset"
              />
              <FormControlLabel
                name="radio"
                control={
                  <Radio
                    checked={applyTo === "Complex"}
                    value="Complex"
                    onClick={onRadioChange}
                  />
                }
                label="Complex"
              />
            </RadioGroup>
          </div>
        </FormControl>

        {applyTo === "Property" && facilityOptions.length > 0 && (
          <FormControl
            item
            sx={{ width: { xs: "100%", lg: "33.3%" }, marginBottom: "10px" }}
          >
            <InputLabel>Property</InputLabel>
            <Select
              label="Property"
              sx={{ width: { xs: "100%", lg: "90%" } }}
              id={"property"}
              value={selectedWorkOrder.facilityId}
              onChange={(event) => facilityChanged(event)}
            >
              {facilityOptions && facilityOptions.length > 0 ? (
                facilityOptions.map(function (facility, i) {
                  return (
                    <MenuItem key={facility.value} value={facility.value}>
                      {facility.label}
                    </MenuItem>
                  );
                }, this)
              ) : (
                <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
              )}
            </Select>
          </FormControl>
        )}

        {applyTo === "Property" && facilityOptions.length < 1 && (
          <Grid item sx={{ width: "100%", marginBottom: "10px" }}>
            <Alert severity="error">No Facilities to select</Alert>
          </Grid>
        )}

        {applyTo === "Asset" && assetOptions.length < 1 && (
          <Grid item sx={{ width: "100%", marginBottom: "10px" }}>
            <Alert severity="error">No Assets to select</Alert>
          </Grid>
        )}

        {applyTo === "Complex" && complexOptions.length < 1 && (
          <Grid item sx={{ width: "100%", marginBottom: "10px" }}>
            <Alert severity="error">No Complexes to select</Alert>
          </Grid>
        )}

        {applyTo === "Asset" && assetOptions.length > 0 && (
          <FormControl
            item
            sx={{ width: { xs: "100%", lg: "33.3%" }, marginBottom: "10px" }}
          >
            <InputLabel>Asset</InputLabel>
            <Grid item sx={{ width: { xs: "100%", lg: "90%" } }}>
              <Select
                label="Asset"
                sx={{ width: { xs: "100%", lg: "90%" } }}
                id={"asset"}
                value={selectedWorkOrder.assetId}
                onChange={(event) => assetChanged(event)}
              >
                {assetOptions && assetOptions.length > 0 ? (
                  assetOptions.map(function (asset, i) {
                    return (
                      <MenuItem key={asset.value} value={asset.value}>
                        {asset.label}
                      </MenuItem>
                    );
                  }, this)
                ) : (
                  <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
                )}
              </Select>
            </Grid>
          </FormControl>
        )}

        {applyTo === "Complex" && complexOptions.length > 0 && (
          <FormControl
            item
            sx={{ width: { xs: "100%", lg: "33.3%" }, marginBottom: "10px" }}
          >
            <InputLabel>Complex</InputLabel>

            <Select
              label="Complex"
              sx={{ width: { xs: "100%", lg: "90%" }, marginBottom: "10px" }}
              id={"complex"}
              value={selectedWorkOrder.complexId}
              onChange={(event) => complexChanged(event)}
            >
              {complexOptions && complexOptions.length > 0 ? (
                complexOptions.map(function (complex, i) {
                  return (
                    <MenuItem key={complex.value} value={complex.value}>
                      {complex.label}
                    </MenuItem>
                  );
                }, this)
              ) : (
                <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
              )}
            </Select>
          </FormControl>
        )}

        <FormControl
          item
          sx={{ width: { xs: "100%", lg: "33.3%" }, marginBottom: "10px" }}
        >
          <InputLabel>Priority</InputLabel>
          <Select
            label="Priority"
            sx={{ width: { xs: "100%", lg: "90%" } }}
            id={"priority"}
            value={priority}
            onChange={(event) => priorityChanged(event)}
          >
            {priorityOptions && priorityOptions.length > 0 ? (
              priorityOptions.map(function (priority, i) {
                return (
                  <MenuItem key={priority.value} value={priority.value}>
                    {priority.label}
                  </MenuItem>
                );
              }, this)
            ) : (
              <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
            )}
          </Select>
        </FormControl>

        <FormControl
          item
          sx={{ width: { xs: "100%", lg: "33.3%" }, marginBottom: "10px" }}
        >
          <InputLabel>Assign To</InputLabel>
          <Select
            label="Assign To"
            sx={{ width: { xs: "100%", lg: "90%" } }}
            id={"assignTo"}
            value={
              !isNullUndefined(selectedWorkOrder.assigneeId) ||
              !isEmptyString(selectedWorkOrder.assigneeId)
                ? selectedWorkOrder.assigneeId
                : assignTo
            }
            onChange={(event) => assignToChanged(event)}
          >
            {admins && admins.length > 0 ? (
              admins.map(function (entry, i) {
                return (
                  <MenuItem key={entry.value} value={entry.value}>
                    {entry.label}
                  </MenuItem>
                );
              }, this)
            ) : (
              <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
            )}
          </Select>
        </FormControl>

        <FormControl
          item
          sx={{ width: { xs: "100%", lg: "33.3%" }, marginBottom: "10px" }}
        >
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale="en-gb"
          >
            <DatePicker
              label="Expected Completion Date *"
              sx={{ width: { xs: "100%", lg: "90%" } }}
              id={"expectedCompletionDate"}
              value={dayjs(expectedCompletionDate)}
              onChange={(newDate) =>
                handleExpectedCompletionDateChange(newDate)
              }
            />
          </LocalizationProvider>
        </FormControl>
        <FormControl
          item
          sx={{ width: { xs: "100%", lg: "33.3%" }, marginBottom: "10px" }}
        >
          <InputLabel>Description/Work order Title*</InputLabel>
          <OutlinedInput
            sx={{
              width: { xs: "100%", lg: "90%" },
            }}
            label="Description/Work order Title*"
            id="description"
            placeholder={"Specify what the problem is in detail"}
            variant="outlined"
            size="small"
            onChange={(event) => onTextChange(event)}
            value={description}
            multiline
            rows={5}
          />
        </FormControl>

        {props.task === "Create" && (
          <FormControl
            item
            sx={{ width: { xs: "100%", lg: "100%" }, marginBottom: "10px" }}
          >
            <InputLabel>
              Upload Work Order Documents (Max 10 MB Each)
            </InputLabel>
            <FileUpload
              value={files}
              onChange={setFiles}
              accept=".xlsx,.pdf,.jpeg,.png,.doc, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />
          </FormControl>
        )}
      </ResponsiveRow>

      <br />

      {appMessage.display && (
        <Alert severity={appMessage.type}>
          <AlertTitle>{titleCase(appMessage.type)}</AlertTitle>
          {appMessage.message}
        </Alert>
      )}

      <Typography
        // sx={{ marginBottom: "5px" }}
        id="modal-modal-title"
        variant="h5"
        component="h4"
      >
        <Typography id="modal-modal-title" variant="h5" component="h2">
          Work Order Details
        </Typography>
      </Typography>

      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {vendorOptions && vendorOptions.length > 0 && (
                <TableCell sx={{ width: "15%" }}>Vendor</TableCell>
              )}
              {applyTo === "Property" && unitOptions.length > 0 && (
                <TableCell sx={{ width: "10%" }}>Unit</TableCell>
              )}
              <TableCell sx={{ width: "15%" }}>Memo</TableCell>
              <TableCell sx={{ width: "15%" }}>Unit Price</TableCell>
              <TableCell sx={{ width: "10%" }}>Quantity</TableCell>
              <TableCell sx={{ width: "13%" }}>Sub-Total</TableCell>
              <TableCell sx={{ width: "10%" }}>Additional Charges</TableCell>
              <TableCell sx={{ width: "30%" }}>Total</TableCell>
              <TableCell sx={{ width: "5%" }}>Tax (16% VAT)</TableCell>
              {selectedWorkOrder &&
                selectedWorkOrder.vendors &&
                selectedWorkOrder.vendors.length > 1 && (
                  <TableCell sx={{ width: "2%" }}></TableCell>
                )}
            </TableRow>
          </TableHead>
          <TableBody>{workOrderDetailTableRows}</TableBody>
        </Table>
      </TableContainer>

      <ResponsiveRow
        sx={{
          justifyContent: { xs: "center", lg: "flex-end" },
          width: "100%",
          padding: "10px",
          height: "auto",
        }}
      >
        <ModalAcceptButton
          onClick={handleItemAdd}
          sx={{ width: { xs: "100%", lg: "200px" } }}
        >
          Add Row
        </ModalAcceptButton>
      </ResponsiveRow>
    </ModalContainerModule>
  );
}
