import {Alert, FormControl, InputLabel, OutlinedInput, TextField,} from "@mui/material"
import {ResponsiveRow} from "../../../../constants/layout.constants"
import ModalContainerModule from "../../../modules/modalContainer"

export default function AddNoteModal(props) {
    /**
     * REQUIRED PROPS
     *
     *
     * handleNoteClose
     * onTextChange
     *
     * noteModal
     *
     * saveNote
     * selectedLease
     * note
     *
     */
    return (
        <ModalContainerModule
            size="xs"
            accept={props.saveNote}
            cancel={props.handleNoteClose}
            openModal={props.noteModal}
            modalTitle={`Add Note To Lease ${
                props.selectedLease && props.selectedLease.billingCode
            } for  ${props.selectedLease && props.selectedLease.tenant}`}
            acceptButtonText="Save Note"
        >
            <Alert severity="info">
                Add note to lease will log important notes/information pertaining this
                lease. All the notes added on this lease will be displayed
                chronologically, from newest first to oldest. Notes will be limited to
                500 words.
            </Alert>
            <br/>
            <ResponsiveRow container sx={{width: "100%", height: "auto"}}>
                <FormControl
                    item
                    sx={{
                        width: "100%",
                    }}
                >
                    <InputLabel>Note (500)</InputLabel>
                    <OutlinedInput
                        label="Note (500)"
                        sx={{width: "100%"}}
                        id={"note"}
                        name={"note"}
                        isrequired
                        value={props.note}
                        onChange={(event) => props.onTextChange(event)}
                        multiline
                        rows={5}
                        inputProps={<TextField sx={{width: "100%"}}/>}
                    />
                </FormControl>
            </ResponsiveRow>
            <br/>
        </ModalContainerModule>
    )
}
