/* eslint-disable react-hooks/exhaustive-deps */
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid";
import BillingTabBar from "../bars/billingTabBar";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  deleteBillData,
  fetchBill,
  getBills,
  getTotalBills,
  saveBillData,
} from "../../services/billAndPayment.service";
import {
  adjustForTimezone,
  formatCurrency,
  formatDate,
  getRefNumber,
  isEmptyArray,
  isEmptyString,
  isNullUndefined,
  isValidDate,
  titleCase,
} from "../../util/util";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/en-gb";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Delete, LocalPrintshop, Visibility } from "@mui/icons-material";
import ViewBill from "../modals/viewBill";
import ConfirmationModal from "../modals/confirmation";
import CreateBill from "../modals/createBill";
import { printBill } from "../../services/print.service";
import { renderCellExpand } from "../../util/renderCellExpand";
import { useSelector } from "react-redux";
import { ResponsiveRow } from "../../constants/layout.constants";
import {
  ModalAcceptButton,
  ModalCancelButton,
} from "../../constants/component.constants";
import dayjs from "dayjs";
import MuiDataGrid from "./MuiDataGrid";
import { issueResponseMessage, issueWarnMessage } from "../../actions/message";
import {
  getVendorOptions,
  getWithholdingTaxAccount,
} from "../../services/list.service";

export default function BillTab() {
  const [expanded, setExpanded] = useState(false);
  const [searchResult, setSearchResult] = useState(false);
  const [loading, setLoading] = useState(true);
  const user = useSelector((state) => state.auth.user);
  const [task, setTask] = useState("");
  const [totalBills, setTotalBills] = useState(0);
  const [bills, setBills] = useState();
  const [description, setDescription] = useState("");
  const [reference, setReference] = useState("");
  const [payees, setPayees] = useState([]);
  const [payeeOptions, setPayeeOptions] = useState([]);
  const [dueDateFrom, setDueDateFrom] = useState("");
  const [dueDateTo, setDueDateTo] = useState("");
  const [showViewBillModal, setShowViewBillModal] = useState(false);
  const [showAddBillModal, setShowAddBillModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectedBill, setSelectedBill] = useState(null);
  const withholdingTaxAccountId = useRef("");
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 50,
  });

  let disableSearch =
    isEmptyString(description) &&
    isEmptyArray(payees) &&
    isEmptyString(reference) &&
    isEmptyString(dueDateFrom) &&
    isEmptyString(dueDateTo);

  const loadBills = useCallback((paginationModel) => {
    setLoading(true);
    getVendorOptions().then((vendorOptions) => {
      setPayeeOptions(vendorOptions);
    });

    let searchObject = {
      payees: payees,
      reference: reference,
      description: description,
      dueDateFrom: "",
      dueDateTo: "",
      pageNo: paginationModel.page,
      rowsPerPage: paginationModel.pageSize,
    };
    if (!isNullUndefined(dueDateFrom) && isValidDate(dueDateFrom)) {
      searchObject.dueDateFrom = adjustForTimezone(new Date(dueDateFrom));
    }
    if (!isNullUndefined(dueDateTo) && isValidDate(dueDateTo)) {
      searchObject.dueDateTo = adjustForTimezone(new Date(dueDateTo));
    }

    getTotalBills(searchObject).then((response) => {
      setTotalBills(response.totalBills);
    });

    getBills(searchObject)
      .then((bills) => {
        setBills(bills);
        setExpanded(false);
        setTask("Result");
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    handleSearchClicked()
  }, [paginationModel])

  useEffect(() => {
    setLoading(true);
    getVendorOptions().then((vendorOptions) => {
      setPayeeOptions(vendorOptions);
    });
    let searchObject = {
      payees: payees,
      reference: reference,
      description: description,
      dueDateFrom: "",
      dueDateTo: "",
      pageNo: paginationModel.page,
      rowsPerPage: paginationModel.pageSize,
    };
    if (!isNullUndefined(dueDateFrom) && isValidDate(dueDateFrom)) {
      searchObject.dueDateFrom = adjustForTimezone(new Date(dueDateFrom));
    }
    if (!isNullUndefined(dueDateTo) && isValidDate(dueDateTo)) {
      searchObject.dueDateTo = adjustForTimezone(new Date(dueDateTo));
    }

    getWithholdingTaxAccount().then((account) => {
      if (account !== undefined) {
        withholdingTaxAccountId.current = account.id;
      }
    });

    getTotalBills(searchObject).then((response) => {
      setTotalBills(response.totalBills);
    });

    getBills(searchObject)
      .then((bills) => {
        setBills(bills);
        setExpanded(false);
        setTask("Result");
      })
      .finally(() => {
        setLoading(false);
      });
  }, [user.proxySubscription]);

  function onTextChange(event) {
    const id = event.target.id;
    const value = event.target.value;
    if (id === "reference") {
      setReference(value);
    }
    if (id === "description") {
      setDescription(value);
    }
  }

  function handleExpandClick() {
    setExpanded(true);
    setTask("Search");
  }

  const handleConfirmationClose = () => {
    setShowConfirmationModal(false);
    setSelectedBill(null);
  };

  function handleClearSearch() {
    setReference("");
    setDescription("");
    setPayees([]);
    setDueDateFrom("");
    setDueDateTo("");
  }

  function handleCloseSearch() {
    handleClearSearch();
    loadBills(paginationModel);
    setExpanded(false);
    setTask("Result");
    setSearchResult(false);
  }

  function handleViewBillClose() {
    setShowViewBillModal(false);
    setSelectedBill(null);
  }

  function handleViewClick(selectedBill) {
    fetchBill(selectedBill.id).then((bill) => {
      setShowViewBillModal(true);
      setSelectedBill(bill);
    });
  }

  function handleAddBillClick() {
    const refNumber = getRefNumber();
    const data = {
      id: null,
      referenceNumber: refNumber,
      vendorId: null,
      amount: null,
      dueDate: null,
      facility: { id: null, name: null },
      unit: { id: null, name: null },
      asset: { id: null, name: null },
      description: null,
      vendorRefNo: null,
      billItems: [],
    };
    setSelectedBill(data);
    setShowAddBillModal(true);
  }

  function saveBill(selectedBill) {
    setSelectedBill(selectedBill);
    if (
      isNullUndefined(selectedBill.billItems) ||
      selectedBill.billItems.length === 0
    ) {
      issueWarnMessage("You must add at least one item to the bill");
      return;
    }
    if (selectedBill.billItems.filter((x) => x.accountId === withholdingTaxAccountId.current).length > 1) {
      issueWarnMessage("There should be only one withholding tax line item.")
      return;
    }

    let amountError = false;
    let errorMessage = "";
    selectedBill.billItems.forEach(function (item, i) {
      const isWithholdingTax =
        withholdingTaxAccountId.current === item.accountId;
      if (item.amount < 1) {
        amountError = true;
        errorMessage =
          "Bill item '" + item.notes + "' amount should be greater than zero.";
      }
      if (isWithholdingTax && selectedBill.billItems.length === 1) {
        amountError = true;
        errorMessage =
          "Bill item '" + item.notes + "' There should be at least one service bill item to support withholding tax line item.";
      }
    });
    if (amountError) {
      issueWarnMessage(errorMessage);
      return;
    }
    saveBillData(selectedBill).then((response) => {
      issueResponseMessage(response);
      handleSearchClicked();
      handleAddBillClose();
    });
  }

  function handleAddBillClose() {
    setShowAddBillModal(false);
    setSelectedBill(null);
    setTask("Result");
    setExpanded(false);
  }

  function handleDeleteClick(selectedBill) {
    setShowConfirmationModal(true);
    setSelectedBill(selectedBill);
  }

  function deleteBill() {
    deleteBillData(selectedBill).then((response) => {
      handleConfirmationClose();
      handleSearchClicked();
      issueResponseMessage(response);
    });
  }

  function handleSearchClicked() {
    setLoading(true);
    let searchObject = {
      payees: payees,
      reference: reference,
      description: description,
      dueDateFrom: "",
      dueDateTo: "",
      pageNo: paginationModel.page,
      rowsPerPage: paginationModel.pageSize,
    };
    if (!isNullUndefined(dueDateFrom) && isValidDate(dueDateFrom)) {
      searchObject.dueDateFrom = adjustForTimezone(new Date(dueDateFrom));
    }
    if (!isNullUndefined(dueDateTo) && isValidDate(dueDateTo)) {
      searchObject.dueDateTo = adjustForTimezone(new Date(dueDateTo));
    }
    getTotalBills(searchObject).then((response) => {
      setTotalBills(response.totalBills);
    });

    getBills(searchObject)
      .then((bills) => {
        setBills(bills);
        setTask("Result");
        if(expanded === true) {
          setSearchResult(true);
        }
        setExpanded(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function handleDueDateToChange(dateString) {
    let date = new Date(dateString);
    setDueDateTo(date);
  }

  function handleDueDateFromChange(dateString) {
    let date = new Date(dateString);
    setDueDateFrom(date);
  }

  function handlePayeeChange(event) {
    setPayees(event.target.value);
  }

  const columns = useMemo(
    () => [
      {
        field: "referenceNumber",
        headerName: "Reference",
        width: 100,
      },
      {
        field: "vendorName",
        headerName: "Payee",
        minWidth: 260,
        flex: 2.6,
        renderCell: renderCellExpand,
      },
      {
        field: "costedTo",
        headerName: "Charged To",
        minWidth: 160,
        fleX: 1.6,
        renderCell: renderCellExpand,
      },
      {
        field: "status",
        headerName: "Status",
        width: 100,
        valueFormatter: (row) => {
          return titleCase(row.value.name);
        },
      },
      {
        field: "dueDate",
        headerName: "Due Date",
        minWidth: 100,
        flex: 1,
        valueFormatter: (row) => {
          if (!isNullUndefined(row.value)) {
            return formatDate(row.value);
          }
        },
      },
      {
        field: "description",
        headerName: "Description",
        minWidth: 260,
        flex: 2.6,
        renderCell: renderCellExpand,
      },
      {
        field: "workOrder",
        headerName: "Work Order",
        minWidth: 220,
        flex: 2.2,
        renderCell: renderCellExpand,
      },
      {
        field: "amount",
        headerName: "Amount",
        minWidth: 100,
        fleX: 1,
        valueFormatter: (row) => {
          return formatCurrency(row.value);
        },
      },
      {
        field: "actions",
        headerName: "Actions",
        minWidth: 70,
        flex: 0.7,
        type: "actions",
        getActions: (params) => {
          let arr = [];
          arr.push(
            <GridActionsCellItem
              icon={<Visibility />}
              onClick={() => handleViewClick(params.row)}
              label="View"
              showInMenu
            />
          );

          arr.push(
            <GridActionsCellItem
              icon={<LocalPrintshop />}
              onClick={() => {
                printBill(params.row.id);
              }}
              label="Print"
              showInMenu
            />
          );

          if (params.row.statusCd && params.row.statusCd === "UNPD") {
            arr.push(
              <GridActionsCellItem
                icon={<Delete />}
                label="Delete"
                onClick={() => handleDeleteClick(params.row)}
                showInMenu
              />
            );
          }
          return arr;
        },
      },
    ],
    []
  );

  return (
    <Grid container sx={{}}>
      <BillingTabBar
        expanded={expanded}
        handleExpandClick={handleExpandClick}
        handleCloseSearch={handleCloseSearch}
        handleAddBillClick={handleAddBillClick}
        searchResult={searchResult}
      />

      {task !== "Result" && expanded === true && (
        <ResponsiveRow
          sx={{ width: "100%", height: "auto", backgroundColor: "#fff" }}
        >
          <FormControl
            item
            sx={{ width: { xs: "100%", lg: "33.3%" } }}
            style={{ marginBottom: 10 }}
          >
            <InputLabel>Reference</InputLabel>
            <OutlinedInput
              label="Reference"
              placeholder={"Ref Number"}
              sx={{ width: { xs: "100%", lg: "90%" } }}
              id={"reference"}
              onChange={(event) => onTextChange(event)}
              value={reference}
              inputPtops={(params) => <TextField {...params} />}
            />
          </FormControl>
          <FormControl
            item
            sx={{ width: { xs: "100%", lg: "33.3%" } }}
            style={{ marginBottom: 10 }}
          >
            <InputLabel>Description</InputLabel>
            <OutlinedInput
              label={"Description"}
              sx={{ width: { xs: "100%", lg: "90%" } }}
              id={"description"}
              onChange={(event) => onTextChange(event)}
              value={description}
            />
          </FormControl>

          <FormControl
            item
            sx={{ width: { xs: "100%", lg: "33.3%" } }}
            style={{ marginBottom: 10 }}
          >
            <InputLabel>Payee(s)</InputLabel>
            <Select
              label="Payee(s)"
              multiple
              sx={{ width: { xs: "100%", lg: "90%" } }}
              id={"payees"}
              value={payees}
              onChange={(event) => handlePayeeChange(event)}
            >
              {payeeOptions && payeeOptions.length > 0 ? (
                payeeOptions.map(function (unit, i) {
                  return (
                    <MenuItem key={i} value={unit.value}>
                      {unit.label}
                    </MenuItem>
                  );
                }, this)
              ) : (
                <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
              )}
            </Select>
          </FormControl>

          <FormControl
            item
            sx={{ width: { xs: "100%", lg: "33.3%" } }}
            style={{ marginBottom: 10 }}
          >
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale="en-gb"
            >
              <DatePicker
                label="Due Date From"
                sx={{ width: { xs: "100%", lg: "90%" } }}
                value={isEmptyString(dueDateFrom) ? null : dayjs(dueDateFrom)}
                onChange={(newDate) => handleDueDateFromChange(newDate)}
              />
            </LocalizationProvider>
          </FormControl>

          <FormControl
            item
            sx={{ width: { xs: "100%", lg: "33.3%" } }}
            style={{ marginBottom: 10 }}
          >
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale="en-gb"
            >
              <DatePicker
                sx={{ width: { xs: "100%", lg: "90%" } }}
                label="Due Date To"
                value={isEmptyString(dueDateTo) ? null : dayjs(dueDateTo)}
                onChange={(newDate) => handleDueDateToChange(newDate)}
              />
            </LocalizationProvider>
          </FormControl>

          <ResponsiveRow
            item
            sx={{
              justifyContent: "flex-end",
              marginBottom: 10,
              width: "100%",
              height: "auto",
            }}
          >
            <ModalCancelButton
              onClick={handleClearSearch}
              sx={{ width: { xs: "100%", lg: "200px" } }}
            >
              Reset
            </ModalCancelButton>
            <ModalAcceptButton
              onClick={handleSearchClicked}
              disabled={disableSearch}
              sx={{
                width: { xs: "100%", lg: "200px" },
              }}
            >
              Search
            </ModalAcceptButton>
          </ResponsiveRow>
        </ResponsiveRow>
      )}

      {task === "Result" && (
        <MuiDataGrid
          loading={loading}
          noBoxShadow={true}
          noSelection={true}
          serverPagination={true}
          handlePageLoad={setPaginationModel}
          dataGridColumns={columns}
          dataGridRows={bills}
          height="50vh"
          totalRows={totalBills}
        />
      )}

      {/*VIEW BILL MODAL*/}
      {showViewBillModal && (
        <ViewBill
          openModal={showViewBillModal}
          handleClose={handleViewBillClose}
          withholdingTaxAccountId={withholdingTaxAccountId.current}
          selectedBill={selectedBill}
        />
      )}

      {/*CREATE BILL MODAL*/}
      {showAddBillModal && (
        <CreateBill
          openModal={showAddBillModal}
          handleClose={handleAddBillClose}
          saveBill={saveBill}
          selectedBill={selectedBill}
        />
      )}

      {/*CONFIRMATION MODAL*/}
      {showConfirmationModal && (
        <ConfirmationModal
          showConfirmationModal={showConfirmationModal}
          handleConfirmationClose={handleConfirmationClose}
          title={"Delete Bill"}
          severity={"error"}
          body={
            "Are you sure you want to delete this bill (Bill Ref No. #" +
            selectedBill.referenceNumber +
            ")?"
          }
          positiveText={"Yes"}
          negativeText={"No"}
          positiveAction={deleteBill}
          negativeAction={handleConfirmationClose}
        />
      )}
    </Grid>
  );
}
