import { List } from "@mui/material";
import { Box } from "@mui/system";

import { MobileNavBar } from "../../constants/component.constants";
import { ResponsiveRow } from "../../constants/layout.constants";
import { Fragment, useEffect, useState } from "react";
import { isNullUndefined } from "../../util/util";

import MobileReportsMenuItem from "../navigation/mobileReportsMenuItem";
import MobileParentMenuItem from "../navigation/mobileParentMenuItem";
import MobileChildlessMenuItem from "../navigation/mobileChildlessMenuItem";

export default function MobileNavigation({
  closeDrawer,
  drawer,
  openDrawer,
  menus,
  reports,
}) {
  const [drawerState, setDrawerState] = useState(false);

  useEffect(() => {
    if (drawer === true || drawer === false) {
      setDrawerState(drawer);
    }

    return () => setDrawerState(false);
  }, [drawer]);

  return (
    menus && (
      <Fragment key={"left"}>
        <MobileNavBar
          anchor={"left"}
          open={drawerState}
          onClose={closeDrawer}
          onOpen={openDrawer}
        >
          <List
            sx={{ width: "100%", bgcolor: "transparent" }}
            component="nav"
            aria-labelledby="nested-list-subheader"
          >
            <ResponsiveRow
              sx={{
                width: "100%",
                justifyContent: "center",
                height: "auto",
                marginTop: 0,
                marginBottom: "30px",
              }}
            >
              <Box
                component="img"
                src={require("../../images/kodi_logov2.png")}
                sx={{
                  objectFit: "contain",
                  width: "250px",
                  height: "auto",
                  margin: "auto 0",
                }}
                alt=""
              />
            </ResponsiveRow>
            {menus.map((item, index) =>
              isNullUndefined(item.children) ? (
                <MobileChildlessMenuItem
                  key={index}
                  item={item}
                  closeDrawer={closeDrawer}
                />
              ) : (
                <MobileParentMenuItem
                  key={index}
                  item={item}
                  closeDrawer={closeDrawer}
                />
              )
            )}
            <MobileReportsMenuItem reports={reports} />
          </List>
        </MobileNavBar>
      </Fragment>
    )
  );
}
