import {
    GET_UNIT_PROPERTIES_URL,
    LIST_FACILITY_UNITS_URL,
    LIST_LISTING_TYPES_URL,
    LIST_PROPERTY_TYPES_URL,
    LIST_USER_FACILITIES_URL
} from "../../constants/url.constants"
import {serviceAPI} from "../service"
import {handleErrorResponse} from "../../actions/message"

export const loadListingTypes = () => {
    return serviceAPI
        .get(LIST_LISTING_TYPES_URL)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const loadPropertyTypes = () => {
    return serviceAPI
        .get(LIST_PROPERTY_TYPES_URL)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const loadUserFacilities = () => {
    return serviceAPI
        .get(LIST_USER_FACILITIES_URL)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}
export const loadFacilityUnits = (facilityId) => {
    return serviceAPI
        .get(LIST_FACILITY_UNITS_URL + facilityId)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}
export const unitProperties = (unitId) => {
    return serviceAPI
        .get(GET_UNIT_PROPERTIES_URL + unitId)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}