import { useEffect, useMemo, useState } from "react";
import { GridActionsCellItem } from "@mui/x-data-grid";
import {
  Add,
  Cancel,
  CreditCard,
  LocalAtm,
  NearMe,
  Visibility,
} from "@mui/icons-material";
import {
  getMeterBillingNotes,
  getOutstandingMeterBillingInvoicesCharges,
  getTerminatedMeterBillings,
  saveMeterBillingNote,
  saveMeterBillingPayment,
} from "../services/meterbilling.service";
import { saveCreditNote } from "../services/shared.service";
import {getCreditAccounts} from "../services/list.service";
import Grid from "@mui/material/Grid";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Divider,
  FilledInput,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/en-gb";
import {
  formatDate,
  formatDateTime,
  getRefNumber,
  isEmptyString,
  isNullUndefined,
  isValidDate,
  tryParseFloat,
} from "../util/util";
import TransactionHistory from "../components/transactionHistory";
import ReceivePayment from "../components/modals/receivePayment";
import {
  issueResponseMessage,
  issueWarnMessage,
} from "../actions/message";
import {
  AddContainer,
  ColumnContainer,
  InnerModal,
  MainContainer,
  TopBarContainedBrandButton,
  TopBarContainer,
} from "../constants/component.constants";
import {
  printMeterBilling,
  printMeterBillingStatement,
} from "../services/print.service";
import { ResponsiveRow } from "../constants/layout.constants";
import Permit from "../util/permit";
import MuiDataGrid from "../components/modules/MuiDataGrid";
import dayjs from "dayjs";
import DebitNote from "../components/modals/debitNote";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: "auto",
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: "4px 4px 12px #333",
  p: 4,
};

export default function TerminatedMeterBilling() {
  const [searchReference, setSearchReference] = useState("");
  const [customer, setcustomer] = useState("");
  const [isLoading, setLoading] = useState(true);
  const [pageNo] = useState(0);
  const [rows, setRows] = useState([]);
  const [statementYear, setStatementYear] = useState(new Date());
  const [ViewDiv, setViewDiv] = useState(false);
  const [PaymentModal, setPaymentModal] = useState(false);
  const handlePaymentClose = () => setPaymentModal(false);
  const [CreditModal, setCreditModal] = useState(false);
  const [NoteModal, setNoteModal] = useState(false);
  const [ChargeModal, setChargeModal] = useState(false);
  const [note, setNote] = useState("");
  const [creditItems, setCreditItems] = useState([]);
  const [creditItemsTotal, setCreditItemsTotal] = useState(0);
  const [creditItemsTableRows, setCreditItemsTableRows] = useState("");
  const [creditMemo, setCreditNote] = useState("");
  const [creditAccounts, setCreditAccounts] = useState([]);
  const [items, setItems] = useState([]);
  const [creditDate, setCreditDate] = useState(new Date());
  const [selectedMeterbilling, setselectedMeterbilling] = useState(null);
  const [outstandingInvoicesCharges, setOutstandingInvoicesCharges] = useState(
    []
  );
  const [meterNotes, setmeterNotes] = useState([]);

  const handleCreditClose = () => {
    setItems([]); //Clear items
    setCreditItems([]); //Clear items
    setCreditModal(false);
  };
  const handleChargeClose = () => {
    setChargeModal(false);
    setLoading(true)
    getTerminatedMeterBillings(searchReference, customer).then((meterBillings) => {
      setRows(meterBillings)
      setLoading(false)
    });
  };

  useEffect(() => {
    setLoading(true);
    getTerminatedMeterBillings(searchReference, customer).then(
      (meterBillings) => setRows(meterBillings)
    );

    getCreditAccounts().then((creditAccounts) =>
      setCreditAccounts(creditAccounts)
    );

    setLoading(false);
  }, [searchReference, customer]);

  useEffect(() => {
    if (!isNullUndefined(selectedMeterbilling)) {
      setLoading(true);
      getOutstandingMeterBillingInvoicesCharges(selectedMeterbilling.id).then(
        (outstandingInvoicesCharges) =>
          setOutstandingInvoicesCharges(outstandingInvoicesCharges)
      );
      getMeterBillingNotes(selectedMeterbilling.id, pageNo).then((meterNotes) =>
        setmeterNotes(meterNotes)
      );
      setLoading(false);
    }
  }, [selectedMeterbilling, pageNo]);

  useEffect(() => {
    populateCreditItems();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creditItems]);


  function onTextChange(searchText) {
    const id = searchText.target.id;
    const value = searchText.target.value;
    if (id === "searchCode") {
      setSearchReference(value);
    }
    if (id === "searchCustomer") {
      setcustomer(value);
    }
    if (id === "note") {
      setNote(value);
    }
    if (id === "creditNote") {
      setCreditNote(value);
    }
  }

  function savePayment(data) {
    saveMeterBillingPayment(data).then((response) => {
      issueResponseMessage(response);
      if (!isNullUndefined(response.meterBilling)) {
        getTerminatedMeterBillings(searchReference, customer).then(
          (meterBillings) => setRows(meterBillings)
        );
        handlePaymentClose();
      }
    });
  }

  function saveNote() {
    let data = {
      meterBillingId: selectedMeterbilling.id,
      note: note,
    };
    saveMeterBillingNote(data).then((response) => {
      issueResponseMessage(response);
      setNoteModal(false);
      getMeterBillingNotes(selectedMeterbilling.id, pageNo).then((meterNotes) =>
        setmeterNotes(meterNotes)
      );
    });
  }

  function saveCredit() {
    let refNum = getRefNumber();
    let creditNote = {
      id: null,
      referenceNumber: refNum,
      date: creditDate,
      amount: creditItemsTotal,
      memo: creditMemo,
      meterBillingId: selectedMeterbilling.id,
      invoiceRef: null,
    };
    let data = {
      creditNote: creditNote,
      creditItems: items,
    };

    if (validateCreditForm(data)) {
      saveCreditNote(data).then((response) => {
        issueResponseMessage(response);
        getTerminatedMeterBillings(searchReference, customer).then(
          (meterBillings) => setRows(meterBillings)
        );
        handleCreditClose();
      });
    }
  }

  function handleCreditDateChange(dateValue) {
    let date = new Date(dateValue);
    setCreditDate(date);
  }

  function handleStatementYearChange(dateValue) {
    let date = new Date(dateValue);
    setStatementYear(date);
  }

  function handleViewClick(selectedMeterbilling) {
    setselectedMeterbilling(selectedMeterbilling);
    setViewDiv(true);
  }

  function handlePaymentClick(selectedMeterbilling) {
    setselectedMeterbilling(selectedMeterbilling);
    setPaymentModal(true);
  }

  function handleCreditClick(selectedMeterbilling) {
    setItems((items) => [...items, { accountId: null, amount: null }]);
    setCreditItems(items);
    setselectedMeterbilling(selectedMeterbilling);
    setCreditModal(true);
  }

  function handleChargeClick(selectedMeterbilling) {
    setItems((items) => [
      ...items,
      {
        accountId: null,
        notes: null,
        amount: null,
        applyVAT: false,
        displayVATRadio: true,
      },
    ]);
    setselectedMeterbilling(selectedMeterbilling);
    setChargeModal(true);
  }

  function handleNoteClick(selectedMeterbilling) {
    setselectedMeterbilling(selectedMeterbilling);
    setNoteModal(true);
  }

  function handleCreditAccountChange(event, index) {
    let value = event.target.value;
    let newArr = [...items]; // copying the old items array
    newArr[index].accountId = value;
    setItems(newArr);
    populateCreditItems();
  }

  function creditAmountChange(event, index) {
    const value = event.target.value;
    let newArr = [...items]; // copying the old items array
    newArr[index].amount = value;
    setItems(newArr);
    populateCreditItems();
  }

  function handleCreditItemAdd() {
    setItems((items) => [...items, { accountId: null, amount: null }]);
    setCreditItems((creditItems) => [
      ...creditItems,
      { accountId: null, amount: null },
    ]);
  }

  function handleCreditItemRemove(index) {
    let newArr = [...items]; // copying the old items array
    if (newArr.length > 1) {
      newArr.splice(index, 1);
      setItems(newArr);
      setCreditItems(newArr);
    } else {
      issueWarnMessage("At least one credit item is required.");
    }
  }

  function validateCreditForm(data) {
    if (isNullUndefined(data.creditNote)) {
      issueWarnMessage(
        "Something went wrong with credit data, please refresh the page "
      );
      return false;
    }
    if (!isValidDate(data.creditNote.date)) {
      issueWarnMessage("Select a valid credit note date");
      return false;
    }
    if (
      !isNullUndefined(data.creditNote.memo) &&
      !isEmptyString(data.creditNote.memo) &&
      data.creditNote.memo.length > 400
    ) {
      issueWarnMessage("The credit memo cannot be greater than 400 characters");
      return false;
    }
    let amount = tryParseFloat(data.creditNote.amount, -1);
    if (amount < 1) {
      issueWarnMessage(
        "Invalid Amount. The amount entered must be more than 0"
      );
      return false;
    }
    if (data.creditItems.length <= 0) {
      issueWarnMessage(
        "You must select at lease one account to apply the credit to"
      );
      return false;
    }
    data?.creditItems?.forEach((creditItem) => {
      if (
        isNullUndefined(creditItem.accountId) ||
        isEmptyString(creditItem.accountId)
      ) {
        issueWarnMessage("You must select an account for each row");
        return false;
      } else if (isNullUndefined(creditItem.amount) || creditItem.amount < 1) {
        issueWarnMessage(
          "You must enter an amount greater than 0 for each row"
        );
        return false;
      }
    });
    return true;
  }

  function populateCreditItems() {
    let rows = "";
    let total = 0;
    items &&
      items.length > 0 &&
      (rows = items.map(function (creditItem, i) {
        let amount = tryParseFloat(creditItem.amount, -1);
        if (amount > 0) {
          total = total + amount;
        }
        return (
          <TableRow key={i}>
            <TableCell>
              <Select
                sx={{ width: "90%" }}
                id={"creditAccount" + i}
                name={"creditAccount" + i}
                isrequired={true}
                value={items && items[i] && items[i].accountId}
                onChange={(event) => handleCreditAccountChange(event, i)}
              >
                {creditAccounts && creditAccounts.length > 0 ? (
                  creditAccounts.map(function (creditAccount, i) {
                    return (
                      <MenuItem
                        key={creditAccount.value}
                        value={creditAccount.value}
                      >
                        {creditAccount.label}
                      </MenuItem>
                    );
                  }, this)
                ) : (
                  <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
                )}
              </Select>
            </TableCell>
            <TableCell>
              <TextField
                sx={{ width: "90%" }}
                id={"creditAmount" + i}
                name={"creditAmount" + i}
                isrequired={true}
                value={items && items[i] && items[i].amount}
                onChange={(event) => creditAmountChange(event, i)}
              />
            </TableCell>
            <TableCell>
              <Button
                variant="contained"
                type="submit"
                sx={{ marginLeft: "10px" }}
                onClick={(event) => handleCreditItemRemove(i)}
                color="error"
              >
                <Cancel />
              </Button>
            </TableCell>
          </TableRow>
        );
      }, this));
    setCreditItemsTableRows(rows);
    setCreditItemsTotal(total);
  }

  function handlePrintMeterBillingStatement() {
    printMeterBillingStatement(
      selectedMeterbilling.id,
      statementYear.getFullYear()
    );
  }

  function handlePrintMeterBilling() {
    printMeterBilling(selectedMeterbilling.id);
  }

  const columns = useMemo(() => [
    {
      headerName: "Billing Code",
      field: "billingCode",
      minWidth: 140,
      flex: 1.4,
    },
    { headerName: "Meter(s)", field: "meters", minWidth: 220, flex: 2.2 },
    {
      headerName: "Billing Frequency",
      field: "billingFrequency",
      minWidth: 190,
      flex: 1.9,
    },
    { headerName: "Taxable", field: "taxable", minWidth: 120, flex: 1.2 }, //taxable ? "Yes" : "No"
    {
      headerName: "Account Holder",
      field: "customerName",
      minWidth: 390,
      flex: 3.9,
    },
    {
      headerName: "Balance",
      field: "meterBillingBalance",
      minWidth: 170,
      flex: 1.7,
    },
    {
      headerName: "Actions",
      field: "actions",
      width: 100,
      type: "actions",
      getActions: (params) => {
        let bal = tryParseFloat(params.row.meterBillingBalance, 0);
        let arr = [];
        arr.push(
          <GridActionsCellItem
            icon={<Visibility />}
            label="View"
            onClick={() => handleViewClick(params.row)}
            showInMenu
          />
        );

        arr.push(
          <GridActionsCellItem
            icon={<Add />}
            label="Add Note"
            onClick={() => handleNoteClick(params.row)}
            showInMenu
          />
        );

        if (bal > 0) {
          arr.push(
            <GridActionsCellItem
              icon={<LocalAtm />}
              label="Receive Payment"
              onClick={() => handlePaymentClick(params.row)}
              showInMenu
            />
          );
        }

        arr.push(
          <GridActionsCellItem
            icon={<CreditCard />}
            label="Issue Credit"
            onClick={() => handleCreditClick(params.row)}
            showInMenu
          />
        );

        arr.push(
          <GridActionsCellItem
            icon={<NearMe />}
            label="Issue Charge"
            onClick={() => handleChargeClick(params.row)}
            showInMenu
          />
        );
        return arr;
      },
    },
  ]);

  return (
    <Permit
      roles="BLDIR,BLDMG,CS"
      noPermittedServiceMessage={{
        title: "Access Denied",
        body: (
          <>
            Your current role is not permitted to view this page. <br /> Please
            contact your system admin if you feel this is an error.
          </>
        ),
      }}
    >
      <MainContainer>
        <TopBarContainer
          container
          sx={{
            width: { xs: "100%", lg: "80%" },
            boxShadow: "4px 4px 12px #aaa",
          }}
        >
          <ResponsiveRow
            item
            sx={{ width: { xs: "100%", lg: "50%" }, padding: 0 }}
          >
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale="en-gb"
            >
              <DatePicker
                  sx={{ width: { xs: "90%", lg: "75%" } }}
                label="Statement Year"
                id={"statementYear"}
                onChange={(newDate) => handleStatementYearChange(newDate)}
                value={dayjs(statementYear)}
                isrequired
              />
            </LocalizationProvider>
          </ResponsiveRow>

          <ResponsiveRow
            item
            sx={{
              justifyContent: { xs: "center", lg: "flex-end" },
              width: { xs: "100%", lg: "50%" },
              padding: 0,
            }}
          >
            <TopBarContainedBrandButton
              // type="button"
              onClick={handlePrintMeterBillingStatement}
            >
              {" "}
              Print Meter Billing Statement{" "}
            </TopBarContainedBrandButton>

            <TopBarContainedBrandButton
              // type="button"
              onClick={handlePrintMeterBilling}
            >
              {" "}
              Print Billing{" "}
            </TopBarContainedBrandButton>
          </ResponsiveRow>
        </TopBarContainer>

        <br />

        {!ViewDiv && (
          <MuiDataGrid
            dataGridColumns={columns}
            dataGridRows={rows}
            serverPagination={false}
            loading={isLoading}
            height="60vh"
            totalRows={rows.length}
          />
        )}

        {ViewDiv ? (
          <AddContainer>
            <br />

            <Paper
              variant="outlined"
              sx={{
                height: "auto",
                margin: "auto",
                padding: "10px",
                textAlign: "left",
              }}
            >
              <Typography id="modal-modal-title" variant="h3" component="h2">
                Billing Information for{" "}
                {selectedMeterbilling && selectedMeterbilling.customerName}
              </Typography>
              <br />

              <Paper
                variant="outlined"
                sx={{
                  height: "auto",
                  margin: "auto",
                  padding: "10px",
                  textAlign: "left",
                }}
              >
                <InnerModal container>
                  <Grid item xs={4}>
                    <ColumnContainer container>
                      <Typography
                        sx={{ marginBottom: "5px" }}
                        id="modal-modal-title"
                        variant="h5"
                        component="h4"
                      >
                        Meter Information
                      </Typography>
                      <InputLabel>
                        Property:{" "}
                        {selectedMeterbilling &&
                          selectedMeterbilling.facilityName}
                      </InputLabel>
                      <InputLabel>
                        Meters:{" "}
                        {selectedMeterbilling && selectedMeterbilling.meters}
                      </InputLabel>
                      <InputLabel>
                        Customer Name:{" "}
                        {selectedMeterbilling &&
                          selectedMeterbilling.customerName}
                      </InputLabel>
                    </ColumnContainer>
                  </Grid>
                  <Grid item xs={4}>
                    <ColumnContainer container>
                      <Typography
                        sx={{ marginBottom: "5px" }}
                        id="modal-modal-title"
                        variant="h5"
                        component="h4"
                      >
                        Status Information
                      </Typography>
                      <InputLabel>
                        Status:{" "}
                        {selectedMeterbilling && selectedMeterbilling.statusCd}
                      </InputLabel>
                      <InputLabel>
                        Effective Date:{" "}
                        {formatDate(
                          selectedMeterbilling &&
                            selectedMeterbilling.effectiveDate
                        )}
                      </InputLabel>
                      <InputLabel>
                        Expiration Date:{" "}
                        {selectedMeterbilling &&
                          selectedMeterbilling.expirationDate}
                      </InputLabel>
                    </ColumnContainer>
                  </Grid>
                  <Grid item xs={4}>
                    <ColumnContainer container>
                      <Typography
                        sx={{ marginBottom: "5px" }}
                        id="modal-modal-title"
                        variant="h5"
                        component="h4"
                      >
                        Billing Information
                      </Typography>
                      <InputLabel>
                        Billing Code:{" "}
                        {selectedMeterbilling &&
                          selectedMeterbilling.billingCode}
                      </InputLabel>
                      <InputLabel>
                        Billing Frequency:{" "}
                        {selectedMeterbilling &&
                          selectedMeterbilling.billingFrequency}
                      </InputLabel>
                      <InputLabel>
                        Billing Day/Date:{" "}
                        {selectedMeterbilling &&
                          (selectedMeterbilling.billingDay ||
                            selectedMeterbilling.billingDate)}
                      </InputLabel>
                    </ColumnContainer>
                  </Grid>
                </InnerModal>
              </Paper>

              <InnerModal container>
                <Grid item xs={4}>
                  <Paper
                    variant="outlined"
                    sx={{
                      height: "auto",
                      margin: "auto",
                      padding: "10px",
                      minHeight: "300px",
                    }}
                  >
                    <Typography
                      sx={{ marginBottom: "5px" }}
                      id="modal-modal-title"
                      variant="h5"
                      component="h4"
                    >
                      Note Logs
                    </Typography>

                    <ColumnContainer
                      container
                      sx={{
                        minHeight: "300px",
                        maxHeight: "300px",
                        backgroundColor: "rgba(235,235,235,0.7)",
                      }}
                    >
                      {meterNotes &&
                        meterNotes.length > 0 &&
                        meterNotes.map(function (note, i) {
                          return (
                            <Card sx={{ width: "95%", margin: "10px" }}>
                              <CardActionArea>
                                <CardContent>
                                  <Typography
                                    gutterBottom
                                    variant="h6"
                                    component="div"
                                  >
                                    {formatDateTime(note.dateCreated)}
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    color="text.secondary"
                                  >
                                    {note.note}
                                  </Typography>
                                </CardContent>
                              </CardActionArea>
                            </Card>
                          );
                        }, this)}
                    </ColumnContainer>
                  </Paper>
                </Grid>
                <Grid item xs={4}>
                  <Paper
                    variant="outlined"
                    sx={{
                      height: "auto",
                      margin: "10px",
                      padding: "10px",
                      minHeight: "300px",
                    }}
                  >
                    <Typography
                      sx={{ marginBottom: "5px" }}
                      id="modal-modal-title"
                      variant="h5"
                      component="h4"
                    >
                      Meter Charges
                    </Typography>

                    <ColumnContainer
                      container
                      sx={{
                        minHeight: "300px",
                        maxHeight: "300px",
                        backgroundColor: "rgba(235,235,235,0.7)",
                      }}
                    >
                      {selectedMeterbilling &&
                        selectedMeterbilling.billingChargeItems.length > 0 &&
                        selectedMeterbilling.billingChargeItems.map(function (
                          billItem,
                          i
                        ) {
                          return (
                            <Card sx={{ width: "95%", margin: "10px" }}>
                              <CardActionArea>
                                <CardContent sx={{ flex: "row" }}>
                                  <InnerModal container>
                                    <Grid item xs={3}>
                                      {billItem.meter.name}
                                    </Grid>
                                    <Grid item xs={6}>
                                      {billItem.charge.name}
                                    </Grid>
                                    <Grid item xs={3}>
                                      {billItem.total}
                                    </Grid>
                                  </InnerModal>
                                </CardContent>
                              </CardActionArea>
                            </Card>
                          );
                        },
                        this)}
                    </ColumnContainer>
                  </Paper>
                </Grid>
                <Grid item xs={4}>
                  <Paper
                    variant="outlined"
                    sx={{
                      height: "auto",
                      margin: "auto",
                      padding: "10px",
                      minHeight: "300px",
                    }}
                  >
                    <Typography
                      sx={{ marginBottom: "5px" }}
                      id="modal-modal-title"
                      variant="h5"
                      component="h4"
                    >
                      Documents
                    </Typography>
                    <Grid item xs={12}>
                      <InputLabel>Upload Document (Max 10MB)</InputLabel>
                      <FilledInput
                        sx={{ width: "70%" }}
                        type={"file"}
                        name={"bulkFileInput"}
                        id="bulkFileInput"
                        htmlFor={"bulkFileInput"}
                        accept=".xlsx,.pdf,.jpeg,.png,.doc, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      />
                      <Button
                        variant="contained"
                        type="submit"
                        sx={{ marginLeft: "15px" }}
                      >
                        {" "}
                        Upload{" "}
                      </Button>
                    </Grid>
                    <br />
                    <ColumnContainer
                      container
                      sx={{
                        minHeight: "197px",
                        maxHeight: "197px",
                        backgroundColor: "rgba(235,235,235,0.7)",
                      }}
                    ></ColumnContainer>
                  </Paper>
                </Grid>
              </InnerModal>
              <br />

              {/* Shared Transaction History */}
              <TransactionHistory
                transactionMode={"Meter Billing"}
                contract={selectedMeterbilling}
              />
              <br />

              {/*FOOTER WITH BUTTONS*/}
              <Paper
                variant="outlined"
                sx={{
                  height: "auto",
                  margin: "auto",
                  padding: "10px",
                  alignItems: "left",
                }}
              >
                <Button
                  variant="contained"
                  type="submit"
                  color="error"
                  onClick={() => setViewDiv(false)}
                >
                  {" "}
                  Close{" "}
                </Button>
              </Paper>
            </Paper>
            <br />
          </AddContainer>
        ) : (
          ""
        )}

        {/*RECEIVE PAYMENT MODAL*/}
        <ReceivePayment
          showPaymentModal={PaymentModal}
          handlePaymentClose={handlePaymentClose}
          paymentMode={"Meter Billing"}
          contract={selectedMeterbilling}
          savePayment={savePayment}
        />

        {/*CREATE CREDIT MODAL*/}
        <Modal
          open={CreditModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Paper
              elevation="0"
              sx={{ height: "auto", margin: "auto", padding: "10px" }}
            >
              <Typography id="modal-modal-title" variant="h3" component="h2">
                Create a Credit
              </Typography>
            </Paper>
            <Divider />
            <br />
            <Paper
              variant="outlined"
              sx={{ height: "auto", margin: "auto", padding: "10px" }}
            >
              <InnerModal container>
                <Grid item xs={4}>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale="en-gb"
                  >
                    <DatePicker
                      label="Date"
                      sx={{ width: "90%" }}
                      id={"creditDate"}
                      name={"creditDate"}
                      value={dayjs(creditDate)}
                      onChange={(newDate) =>
                          handleCreditDateChange(newDate)
                      }
                      isrequired
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={8}>
                  <InputLabel>Credit Note</InputLabel>
                  <TextField
                    sx={{ width: "100%" }}
                    id={"creditMemo"}
                    value={creditMemo}
                    onChange={(searchText) => onTextChange(searchText)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <p>
                    Select Account to Credit
                    <Button
                      variant="contained"
                      type="submit"
                      onClick={handleCreditItemAdd}
                      sx={{ backgroundColor: "#037171", float: "right" }}
                    >
                      {" "}
                      Add row{" "}
                    </Button>
                  </p>
                  <TableContainer style={{ maxHeight: 200 }}>
                    <Table
                      sx={{ minWidth: 150 }}
                      aria-label="simple table"
                      stickyHeader
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ width: "50%" }}>Account</TableCell>
                          <TableCell sx={{ width: "40%" }}>Amount</TableCell>
                          <TableCell sx={{ width: "10%" }}>Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>{creditItemsTableRows}</TableBody>
                    </Table>
                  </TableContainer>
                  <br />
                  <InnerModal container>
                    <Grid item xs={8}></Grid>
                    <Grid item xs={4}>
                      <Alert severity="info">
                        <AlertTitle>
                          Credit Total: {creditItemsTotal}
                        </AlertTitle>
                      </Alert>
                    </Grid>
                  </InnerModal>
                  <br />

                  <Button
                    variant="contained"
                    type="submit"
                    onClick={saveCredit}
                    sx={{ backgroundColor: "#037171" }}
                  >
                    {" "}
                    Save Credit{" "}
                  </Button>
                  <Button
                    variant="contained"
                    type="submit"
                    sx={{ marginLeft: "10px" }}
                    onClick={handleCreditClose}
                    color="error"
                  >
                    {" "}
                    Cancel{" "}
                  </Button>
                </Grid>
              </InnerModal>
              <br />
            </Paper>{" "}
            <br />
            <Paper
              variant="outlined"
              sx={{ height: "auto", margin: "auto", padding: "10px" }}
            >
              <Grid item xs={12}>
                <p>Outstanding Invoices/Charges</p>
                <TableContainer>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Ref No.</TableCell>
                        <TableCell>Date</TableCell>
                        <TableCell>Due date</TableCell>
                        <TableCell>Invoicee</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Amount</TableCell>
                        <TableCell>Outstanding Balance</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {outstandingInvoicesCharges &&
                        outstandingInvoicesCharges.length > 0 &&
                        outstandingInvoicesCharges.map(function (
                          outstanding,
                          i
                        ) {
                          return (
                            <TableRow key={outstanding.id}>
                              <TableCell>
                                {outstanding.referenceNumber}
                              </TableCell>
                              <TableCell>
                                {formatDateTime(outstanding.date)}
                              </TableCell>
                              <TableCell>
                                {formatDateTime(outstanding.date)}
                              </TableCell>
                              <TableCell>
                                {selectedMeterbilling.customerName}
                              </TableCell>
                              <TableCell>{outstanding.description}</TableCell>
                              <TableCell>{outstanding.status}</TableCell>
                              <TableCell>{outstanding.amount}</TableCell>
                              <TableCell>
                                {outstanding.outstandingBalance}
                              </TableCell>
                            </TableRow>
                          );
                        },
                        this)}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Paper>
          </Box>
        </Modal>

        {/*CREATE CHARGE MODAL*/}
        {ChargeModal && (
          <DebitNote
            open={ChargeModal}
            onClose={handleChargeClose}
            meterbillingid={selectedMeterbilling?.id}
          />
        )}

        {/*ADD NOTE MODAL*/}
        <Modal
          open={NoteModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h3" component="h2">
              Add Note To Meter Billing{" "}
              {selectedMeterbilling && selectedMeterbilling.billingCode} for{" "}
              {selectedMeterbilling && selectedMeterbilling.customerName}
            </Typography>
            <Divider />
            <br />

            <Paper
              variant="outlined"
              sx={{ height: "auto", margin: "auto", padding: "10px" }}
            >
              <Alert severity="info">
                Add note to meter billing will log important notes/information
                pertaining this meter billing. All the notes added on this meter
                billing will be displayed chronologically, from newest first to
                oldest. Notes will be limited to 500 words.
              </Alert>
              <br />
              <InnerModal container>
                <Grid item xs={12}>
                  <InputLabel>Note (500)</InputLabel>
                  <TextField
                    sx={{ width: "100%" }}
                    id={"note"}
                    name={"note"}
                    value={note}
                    isrequired
                    onChange={(event) => onTextChange(event)}
                  />
                </Grid>
              </InnerModal>
              <br />
            </Paper>
            <br />

            {/*FOOTER WITH BUTTONS*/}
            <Paper
              variant="outlined"
              sx={{ height: "auto", margin: "auto", padding: "10px" }}
            >
              <Button
                variant="contained"
                type="submit"
                onClick={saveNote}
                sx={{ backgroundColor: "#037171" }}
              >
                {" "}
                Save Note{" "}
              </Button>
              <Button
                variant="contained"
                type="submit"
                onClick={() => setNoteModal(false)}
                sx={{ marginLeft: "10px" }}
                color="error"
              >
                {" "}
                Cancel{" "}
              </Button>
            </Paper>
          </Box>
        </Modal>
      </MainContainer>{" "}
    </Permit>
  );
}
