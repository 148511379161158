import {Alert, AlertTitle, Grid, InputLabel, Paper, Switch, TextField, Typography,} from "@mui/material";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {ResponsiveRow} from "../../../../constants/layout.constants";
import ModalContainerModule from "../../../modules/modalContainer";
import dayjs from "dayjs";

export default function ExportMeterUtilitiesModal(props) {
    /**
     *
     * REQUIRED PROPS
     *
     * ExportUtilitiesModal
     *
     * utilitiesExport
     *
     * handleFromDateChange
     * handleIncludeImagesToggle
     * handleToDateChange
     *
     * setExportUtilitiesModal
     * setExportToDate
     * setExportFromDate
     * setincludeImages
     *
     * exportFromDate
     * includeImages
     *
     */
    return (
        <ModalContainerModule
            size="specialConfirmationModal"
            accept={props.utilitiesExport}
            cancel={() => {
                props.setExportUtilitiesModal(false);
                props.setExportToDate("");
                props.setExportFromDate("");
                props.setincludeImages(false);
            }}
            openModal={props.ExportUtilitiesModal}
            modalTitle="Export Utilities"
            acceptButtonText="Export"
        >
            <Paper
                variant=""
                sx={{height: "auto", margin: "auto", padding: "10px"}}
            >
                <Typography variant="body" color="#037171">
                    Select a date range for the daily readings export. Maximum is 90 days.
                    Currently selected 30 days
                </Typography>
                <ResponsiveRow container>
                    <Grid item sx={{width: {xs: "100%", lg: "50%"}}}>
                        <InputLabel>From</InputLabel>
                        <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale="en-gb"
                        >
                            <DatePicker
                                sx={{width: "90%"}}
                                id={"from"}
                                value={dayjs(props.exportFromDate)}
                                isrequired={true}
                                disabled={false}
                                onChange={(newDate) => props.handleFromDateChange(newDate)}
                                
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item sx={{width: {xs: "100%", lg: "50%"}}}>
                        <InputLabel>To</InputLabel>
                        <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale="en-gb"
                        >
                            <DatePicker
                                sx={{width: "90%"}}
                                id={"to"}
                                value={dayjs(props.exportToDate)}
                                isrequired={true}
                                disabled={false}
                                onChange={(newDate) => props.handleToDateChange(newDate)}
                                
                            />
                        </LocalizationProvider>
                    </Grid>
                    <br/>
                    <Grid item sx={{width: "100%"}}>
                        <InputLabel>Include Meter Reading Images</InputLabel>
                        <Switch
                            checked={props.includeImages}
                            onChange={(event) => props.handleIncludeImagesToggle(event)}
                            inputProps={{"aria-label": "controlled"}}
                        />
                    </Grid>
                    {props.includeImages && (
                        <Grid item xs={12}>
                            <Alert severity="info">
                                <AlertTitle>
                                    Note: When including media for export the resulting export
                                    size may be large.
                                </AlertTitle>
                                Therefore, the exported data will be emailed to you when it is
                                finished being packaged. Please ensure the email set for your
                                account is valid, working and that you have access to it in
                                order to receive the exported data.
                            </Alert>
                        </Grid>
                    )}
                </ResponsiveRow>
                <br/>
            </Paper>
        </ModalContainerModule>
    );
}
