/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from "react";
import Grid from "@mui/material/Grid";
import { GridActionsCellItem } from "@mui/x-data-grid";
import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import {
  AddCard,
  Clear,
  CloudUpload,
  Edit,
  Send,
  Visibility,
} from "@mui/icons-material";
import {
  formatCurrency,
  formatDate,
  isEmptyString,
  isNullUndefined,
} from "../../util/util";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/en-gb";
import { useSelector } from "react-redux";
import Permit from "../../util/permit";
import WorkOrderView from "./workOrderView";
import {
  MainContainer,
  ModalAcceptButton,
  ModalCancelButton,
  TopBarContainedBrandButton,
  TopBarContainedCancelButton,
  TopBarContainer,
} from "../../constants/component.constants";
import {
  fetchPerson,
  fetchWorkOrder,
  getTotalWorkOrders,
  getWorkOrders,
  rejectWorkOrderData,
} from "../../services/workOrder.service";
import { issueResponseMessage, issueWarnMessage } from "../../actions/message";
import ConfirmationModal from "../../components/modals/confirmation";
import CreateBillPaymentForm from "./createBillPaymentForm";
import CreateEditWorkOrderForm from "./createEditWorkOrderForm";
import { renderCellExpand } from "../../util/renderCellExpand";
import { amber, green, red } from "@mui/material/colors";
import { ResponsiveRow } from "../../constants/layout.constants";
import { useLocation } from "react-router";
import Inspections from "../../components/modals/inspections";
import ViewInspections from "../../components/modals/viewInspections";
import { listWorkOrderInspection } from "../../services/inspections.service";
import { TabContext, TabPanel } from "@mui/lab";
import RequestedTab from "../../components/modules/workOrders/requestedTab";
import PendingTab from "../../components/modules/workOrders/pendingTab";
import InProgressTab from "../../components/modules/workOrders/inProgressTab";
import CompletedTab from "../../components/modules/workOrders/completedTab";
import RejectedTab from "../../components/modules/workOrders/rejectedTab";
import dayjs from "dayjs";
import ApproveRejectWorkOrderModal from "./approveRejectWorkOrderModal";

export default function WorkOrders() {
  function useQuery() {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  }

  let statusParams;
  const routeParams = useQuery();

  statusParams = routeParams.get("status");
  if (isNullUndefined(statusParams)) {
    statusParams = "INP";
  } else {
    switch (statusParams) {
      case "requested":
        statusParams = "SUB";
        break;

      case "pending":
        statusParams = "PEN";
        break;

      case "in-progress":
        statusParams = "INP";
        break;

      case "overdue":
        statusParams = "INP";
        break;

      case "completed":
        statusParams = "COMP";
        break;

      default:
        statusParams = "INP";
        break;
    }
  }

  const [workOrders, setWorkOrders] = useState([]);
  const [reference, setReference] = useState("");
  const [description, setDescription] = useState("");
  const [statusCd, setStatusCd] = useState(statusParams);
  const [dateRequested, setDateRequested] = useState("");
  const [dateApproved, setDateApproved] = useState("");
  const [requestedBy, setRequestedBy] = useState("");
  const [approvedBy, setApprovedBy] = useState("");
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  });
  const [loading, setLoading] = useState(true);
  const [totalWorkOrders, setTotalWorkOrders] = useState(0);
  const [selectedWorkOrder, setSelectedWorkOrder] = useState(null);
  const [task, setTask] = useState("");
  const user = useSelector((state) => state.auth.user);
  const [reason, setReason] = useState("");
  const [titleText, setTitleText] = useState("");
  const [severityText, setSeverityText] = useState("");
  const [bodyText, setBodyText] = useState("");
  const [positiveText, setPositiveText] = useState("");
  const [negativeText, setNegativeText] = useState("");
  const [selectionModel, setSelectionModel] = useState([]);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [approveRejectModal, setApproveRejectModal] = useState(false);
  const [inspectModal, setInspectModal] = useState(false);
  const [viewInspectionsModal, setViewInspectionsModal] = useState(false);
  const [inspectionRecords, setInspectionRecords] = useState([]);
  const [currentTab, setCurrentTab] = useState("in_progress");
  const [searchResults, setSearchResults] = useState(false);

  const workOrderTabs = [
    { id: 0, name: "Requested", value: "requested", statusCD: "SUB" },
    { id: 1, name: "Pending", value: "pending", statusCD: "PEN" },
    { id: 2, name: "In Progress", value: "in_progress", statusCD: "INP" },
    { id: 3, name: "Completed", value: "completed", statusCD: "COMP" },
    { id: 4, name: "Rejected", value: "rejected", statusCD: "REJ" },
  ];

  function handleTabChange(tab) {
    setLoading(true);
    setCurrentTab(tab.value);
    setStatusCd(tab.statusCD);
  }

  function populateTab(currentTab) {
    switch (currentTab) {
      case 0:
        return (
          <RequestedTab
            workOrders={workOrders}
            columns={columns}
            rowsPerPage={paginationModel.pageSize}
            statusCd={statusCd}
            loading={loading}
            totalWorkOrders={totalWorkOrders}
            loadPage={setPaginationModel}
            setSelectionModel={setSelectionModel}
            selectionModel={selectionModel}
          />
        );
      case 1:
        return (
          <PendingTab
            workOrders={workOrders}
            columns={columns}
            rowsPerPage={paginationModel.pageSize}
            statusCd={statusCd}
            loading={loading}
            totalWorkOrders={totalWorkOrders}
            loadPage={setPaginationModel}
            setSelectionModel={setSelectionModel}
            selectionModel={selectionModel}
          />
        );
      case 2:
        return (
          <InProgressTab
            workOrders={workOrders}
            columns={columns}
            rowsPerPage={paginationModel.pageSize}
            statusCd={statusCd}
            loading={loading}
            totalWorkOrders={totalWorkOrders}
            loadPage={setPaginationModel}
            setSelectionModel={setSelectionModel}
            selectionModel={selectionModel}
          />
        );
      case 3:
        return (
          <CompletedTab
            workOrders={workOrders}
            columns={columns}
            rowsPerPage={paginationModel.pageSize}
            statusCd={statusCd}
            loading={loading}
            totalWorkOrders={totalWorkOrders}
            loadPage={setPaginationModel}
            setSelectionModel={setSelectionModel}
            selectionModel={selectionModel}
          />
        );
      case 4:
        return (
          <RejectedTab
            workOrders={workOrders}
            columns={columns}
            rowsPerPage={paginationModel.pageSize}
            statusCd={statusCd}
            loading={loading}
            totalWorkOrders={totalWorkOrders}
            loadPage={setPaginationModel}
            setSelectionModel={setSelectionModel}
            selectionModel={selectionModel}
          />
        );
      default:
        return (
          <InProgressTab
            workOrders={workOrders}
            columns={columns}
            rowsPerPage={paginationModel.pageSize}
            statusCd={statusCd}
            loading={loading}
            totalWorkOrders={totalWorkOrders}
            loadPage={setPaginationModel}
            setSelectionModel={setSelectionModel}
            selectionModel={selectionModel}
          />
        );
    }
  }

  let disableSearch =
    isEmptyString(description) &&
    isEmptyString(statusCd) &&
    isEmptyString(reference) &&
    isEmptyString(dateRequested) &&
    isEmptyString(dateApproved) &&
    isEmptyString(requestedBy) &&
    isEmptyString(approvedBy);

  const [statusOptions] = useState([
    { label: "Requested Work Orders", value: "SUB" },
    { label: "Pending Work Orders", value: "PEN" },
    { label: "Work Orders In Progress", value: "INP" },
    { label: "Completed Work Orders", value: "COMP" },
    { label: "Rejected Work Orders", value: "REJ" },
  ]);

  useEffect(() => {
    if (statusParams) {
      switch (statusParams) {
        case "requested":
          setStatusCd("SUB");
          break;

        case "pending":
          setStatusCd("PEN");
          break;

        case "in-progress":
          setStatusCd("INP");
          break;

        case "overdue":
          setStatusCd("INP");
          break;

        case "completed":
          setStatusCd("COMP");
          break;

        default:
          break;
      }

      handleSearch();
    }
  }, []);

  useEffect(() => {
    if(!searchResults) {
      setLoading(true);
      getTotalWorkOrders(
        statusCd,
        reference,
        requestedBy,
        description,
        approvedBy,
        dateApproved,
        dateRequested
      ).then((response) => {
        setTotalWorkOrders(response.totalWorkOrders);
      });

      getWorkOrders(
        statusCd,
        reference,
        requestedBy,
        description,
        approvedBy,
        dateApproved,
        dateRequested,
        paginationModel.page | 0,
        paginationModel.pageSize | 25
      )
        .then((workOrders) => {
          setWorkOrders(workOrders);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [paginationModel.page, paginationModel.pageSize, statusCd, searchResults]);

  useEffect(() => {
    setLoading(true);
    getTotalWorkOrders(
      statusCd,
      reference,
      requestedBy,
      description,
      approvedBy,
      dateApproved,
      dateRequested
    ).then((response) => {
      setTotalWorkOrders(response.totalWorkOrders);
    });

    getWorkOrders(
      statusCd,
      reference,
      requestedBy,
      description,
      approvedBy,
      dateApproved,
      dateRequested,
      paginationModel.page | 0,
      paginationModel.pageSize | 25
    )
      .then((workOrders) => {
        setWorkOrders(workOrders);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [user.proxyFacility]);

  function handleViewClick(selectedWorkOrder) {
    fetchWorkOrder(selectedWorkOrder.id).then((workOrder) => {
      setSelectedWorkOrder(workOrder);
      setTask("View");
    });
  }

  function refreshDetails() {
    fetchWorkOrder(selectedWorkOrder.id).then((workOrder) => {
      setSelectedWorkOrder(workOrder);
      setTask("View");
    });
  }

  function reloadWorkOrders() {
    handleSearch();
  }

  function handleViewClose() {
    setSelectedWorkOrder(null);
    setTask("Result");
  }

  function handleCreateBillClose() {
    setSelectedWorkOrder(null);
    setTask("Result");
  }

  function handleCreateEditWorkOrderClose() {
    setSelectedWorkOrder(null);
    setTask("Result");
  }

  function handleApproveRejectClick(selectedWorkOrder) {
    setSelectedWorkOrder(selectedWorkOrder);
    setReason(""); //Clear reason
    setApproveRejectModal(true);
    setTask("ApproveReject");
  }

  function handleApproveRejectClose() {
    setSelectedWorkOrder(null);
    setReason(""); //Clear reason
    setApproveRejectModal(false);
    setTask("Result");
  }

  function handleBulkApproveRejectClick() {
    setReason(""); //Clear reason
    setApproveRejectModal(true);
  }

  function deleteWorkOrder() {
    //NEW DISCUSSED DELETE WORK ORDER FLOW IS SAME AS REJECTING THE WORK ORDER
    fetchPerson(user.userId).then((response) => {
      let name = response.firstName + " " + response.lastName;
      let deleteReason =
        "Work order deleted by " + name + " (" + user.userId + ")";
      if (valid(deleteReason)) {
        let id = selectedWorkOrder.id;
        let sendData = {
          id: id,
          actionNotes: deleteReason,
        };

        rejectWorkOrderData(sendData).then((response) => {
          issueResponseMessage(response);
          reloadWorkOrders();
          handleConfirmationClose();
        });
      }
    });
  }

  function handleCreateBillClick(selectedWorkOrder) {
    fetchWorkOrder(selectedWorkOrder.id).then((workOrder) => {
      setSelectedWorkOrder(workOrder);
      setTask("CreateBillPayment");
    });
  }

  function handleEditClick(selectedWorkOrder) {
    setSelectedWorkOrder(selectedWorkOrder);
    setTask("Edit");
  }

  function handleDeleteClick(selectedWorkOrder) {
    setSelectedWorkOrder(selectedWorkOrder);
    let title = "Confirm Delete Work Order " + selectedWorkOrder.refNumber;
    let body =
      "Are you sure you want to permanently delete this Work Order along with any other associated data such as details, notes, and documents?";
    setTitleText(title);
    setSeverityText("warning");
    setBodyText(body);
    setPositiveText("Delete Work Order");
    setNegativeText("Cancel");
    setShowConfirmationModal(true);
  }

  function handleCreateClick() {
    let data = {
      id: null,
      priority: null,
      expectedCompletion: "", // validate so it is after today
      description: "",
      facilityId: null,
      unitId: null,
      assetId: null,
      complexId: null,
      vendors: [],
    };
    setSelectedWorkOrder(data);
    setTask("Create");
  }

  function handleClearSearch() {
    setReference("");
    setDescription("");
    setDateRequested("");
    setDateApproved("");
    setRequestedBy("");
    setApprovedBy("");
    setTask("");
  }

  function handleCloseSearch() {
    setSearchResults(false);
    handleClearSearch()
    setTask("Result")
  }

  function handleStatusChange(event) {
    let value = event.target.value;
    setStatusCd(value);
  }

  const handleConfirmationClose = () => {
    setShowConfirmationModal(false);
    setTitleText("");
    setTask("Result");
    setSeverityText("");
    setBodyText("");
    setPositiveText("");
    setNegativeText("");
    setSelectedWorkOrder(null);
  };

  function valid(reason) {
    if (
      task === "ApproveReject" &&
      (isNullUndefined(selectedWorkOrder) ||
        isNullUndefined(selectedWorkOrder.id) ||
        isEmptyString(selectedWorkOrder.id))
    ) {
      issueWarnMessage(
        "Couldn't determine the identifier for approving/rejecting the work order. Try refresh the page."
      );
      return false;
    }
    if (isNullUndefined(reason) || isEmptyString(reason)) {
      issueWarnMessage(
        "A reason must be provided before approving/rejecting the work order"
      );
      return false;
    }
    return true;
  }

  function handleShowSearchClicked() {
    setTask("search");
  }

  function handleSearchClicked() {
    handleSearch();
    setSearchResults(true);
  }

  function handleSearch() {
    getWorkOrders(
      statusCd,
      reference,
      requestedBy,
      description,
      approvedBy,
      dateApproved,
      dateRequested,
      paginationModel.page,
      paginationModel.pageSize
    )
      .then((workOrders) => {
        setWorkOrders(workOrders);
        setTask("Result");
      })
      .finally(() => {
        setLoading(false);
      });

    getTotalWorkOrders(
      statusCd,
      reference,
      requestedBy,
      description,
      approvedBy,
      dateApproved,
      dateRequested
    ).then((response) => {
      setTotalWorkOrders(response.totalWorkOrders);
    });

    switch (statusCd) {
      case "SUB":
        setCurrentTab(workOrderTabs[0].value);
        break;
      case "PEN":
        setCurrentTab(workOrderTabs[1].value);
        break;
      case "INP":
        setCurrentTab(workOrderTabs[2].value);
        break;
      case "COMP":
        setCurrentTab(workOrderTabs[3].value);
        break;
      case "REJ":
        setCurrentTab(workOrderTabs[4].value);
        break;
      default:
        break;
    }
  }

  function onTextChange(event) {
    const id = event.target.id;
    const value = event.target.value;
    if (id === "reference") {
      setReference(value);
    }
    if (id === "description") {
      setDescription(value);
    }
    if (id === "requestedBy") {
      setRequestedBy(value);
    }
    if (id === "approvedBy") {
      setApprovedBy(value);
    }
    if (id === "reason") {
      setReason(value);
    }
  }

  const addWorkOrderInspection = (workOrder) => {
    setSelectedWorkOrder(workOrder);
    setInspectModal(true);
  };

  function handleViewInspectionsClick(workOrder) {
    setSelectedWorkOrder(workOrder);
    setViewInspectionsModal(true);
    listWorkOrderInspection(workOrder.id, new Date()).then((data) =>
      setInspectionRecords(data)
    );
  }

  function getChipProps(params) {
    if (!isNullUndefined(params.value)) {
      if (params.value === "low") {
        return {
          label: "Low",
          style: {
            borderColor: green[500],
          },
        };
      } else if (params.value === "medium") {
        return {
          label: "Medium",
          style: {
            borderColor: amber[500],
          },
        };
      } else if (params.value === "high") {
        return {
          label: "High",
          style: {
            borderColor: red[500],
          },
        };
      } else {
        return "N/A";
      }
    }
  }

  const columns = useMemo(() => {
    let columnArray = [];
    let descWidth = 150;
    let descWidthFlex = 1.5;
    let vendWidth = 150;
    let vendWidthFlex = 1.5;
    let appTo = 118;
    let appToFlex = 1.1;
    let dateReq = 110;
    let dateReqFlex = 1.1;
    let reqBy = 130;
    let reqByFlex = 1.3;
    if (statusCd === "SUB" || statusCd === "REJ" || statusCd === "PEN") {
      descWidth = 300;
      descWidthFlex = 3.0;
      vendWidth = 200;
      vendWidthFlex = 2.0;
      appTo = 200;
      appToFlex = 2.0;
      dateReq = 150;
      dateReqFlex = 1.5;
      reqBy = 180;
      reqByFlex = 1.8;
    }
    if (statusCd === "REJ") {
      descWidth = 400;
      vendWidthFlex = 4.0;
      appTo = 300;
      appToFlex = 3.0;
      dateReq = 184;
      dateReqFlex = 1.8;
      reqBy = 200;
      reqByFlex = 2.0;
    }
    columnArray.push({
      headerName: "Reference",
      field: "refNumber",
      width: "100",
    });
    columnArray.push({
      headerName: "Description",
      field: "description",
      minWidth: descWidth,
      flex: descWidthFlex,
      renderCell: renderCellExpand,
    });
    if (statusCd !== "REJ") {
      columnArray.push({
        headerName: "Vendors",
        field: "vendorNames",
        minWidth: vendWidth,
        flex: vendWidthFlex,
        renderCell: renderCellExpand,
      });
    }
    columnArray.push({
      headerName: "Cost",
      field: "totalCost",
      minWidth: 80,
      flex: 0.8,
      valueFormatter: (row) => {
        return formatCurrency(row.value);
      },
    });
    if (statusCd === "INP" || statusCd === "COMP") {
      columnArray.push({
        headerName: "Billed",
        field: "totalBilled",
        minWidth: 80,
        flex: 0.8,
        valueFormatter: (row) => {
          return formatCurrency(row.value);
        },
      });
      columnArray.push({
        headerName: "Paid",
        field: "totalPaid",
        minWidth: 95,
        flex: 0.95,
        valueFormatter: (row) => {
          return formatCurrency(row.value);
        },
      });
    }

    columnArray.push({
      headerName: "Priority",
      field: "priority",
      minWidth: 80,
      flex: 0.8,
      renderCell: (params) => {
        return (
          <Chip variant="outlined" size="small" {...getChipProps(params)} />
        );
      },
    });

    columnArray.push({
      headerName: "Applied To",
      field: "appliedToFull",
      minWidth: appTo,
      flex: appToFlex,
      renderCell: renderCellExpand,
    });
    if (statusCd === "INP" || statusCd === "COMP") {
      columnArray.push({
        headerName: "Date Approved",
        field: "approvalDate",
        width: "110",
        valueFormatter: (row) => {
          if (!isNullUndefined(row.value)) {
            return formatDate(row.value);
          }
        },
      });
      columnArray.push({
        headerName: "Approved By",
        field: "approvedBy",
        width: "130",
        valueFormatter: (row) => {
          if (!isNullUndefined(row.value)) {
            return row.value.name;
          }
        },
      });
    }
    columnArray.push({
      headerName: "Date Requested",
      field: "requestDate",
      minWidth: dateReq,
      flex: dateReqFlex,
      valueFormatter: (row) => {
        if (!isNullUndefined(row.value)) {
          return formatDate(row.value);
        }
      },
    });
    columnArray.push({
      headerName: "Requested By",
      field: "requestedBy",
      minWidth: reqBy,
      flex: reqByFlex,
      valueFormatter: (row) => {
        if (!isNullUndefined(row.value)) {
          return row.value.name;
        }
      },
    });

    columnArray.push({
      headerName: "Actions",
      field: "actions",
      minWidth: 80,
      flex: 0.8,
      type: "actions",
      getActions: (params) => {
        let arr = [];
        arr.push(
          <GridActionsCellItem
            icon={<Visibility />}
            onClick={() => handleViewClick(params.row)}
            label="Work Order details"
            showInMenu
          />
        );
        if (user.proxyRole === "SYSAD" || user.proxyRole === "BLDIR") {
          if (params.row.status && params.row.status.code === "PEN") {
            arr.push(
              <GridActionsCellItem
                icon={<Send />}
                label="Approve / Reject"
                onClick={() => handleApproveRejectClick(params.row)}
                showInMenu
              />
            );
          }
        }

        if (
          user.proxyRole === "SYSAD" ||
          user.proxyRole === "BLDIR" ||
          user.proxyRole === "CS" ||
          user.proxyRole === "BLDMG"
        ) {
          if (
            params.row.status &&
            (params.row.status.code === "INP" ||
              params.row.status.code === "COMP")
          ) {
            arr.push(
              <GridActionsCellItem
                icon={<AddCard />}
                onClick={() => handleCreateBillClick(params.row)}
                label="Create a Bill"
                showInMenu
              />
            );
          }
        }

        if (
          user.proxyRole === "SYSAD" ||
          user.proxyRole === "BLDIR" ||
          user.proxyRole === "BLDMG" ||
          user.proxyRole === "CS"
        ) {
          if (
            params.row.status &&
            (params.row.status.code === "PEN" ||
              params.row.status.code === "SUB")
          ) {
            arr.push(
              <GridActionsCellItem
                icon={<Edit />}
                onClick={() => handleEditClick(params.row)}
                label="Edit Work Order"
                showInMenu
              />
            );
          }
        }

        if (user.proxyRole === "SYSAD" || user.proxyRole === "BLDIR") {
          if (
            params.row.status &&
            (params.row.status.code === "PEN" ||
              params.row.status.code === "SUB")
          ) {
            arr.push(
              <GridActionsCellItem
                icon={<Clear />}
                onClick={() => handleDeleteClick(params.row)}
                label="Delete Work Order"
                showInMenu
              />
            );
          }
        }

        if (
          user.proxyRole === "BLDMG" ||
          user.proxyRole === "BLDIR" ||
          user.proxyRole === "CS"
        ) {
          if (params.row.status && params.row.status.code === "INP") {
            arr.push(
              <GridActionsCellItem
                icon={<CloudUpload />}
                label="Add Inspection Record"
                onClick={() => addWorkOrderInspection(params.row)}
                showInMenu
              />
            );
          }
        }
        if (
          user.proxyRole === "BLDMG" ||
          user.proxyRole === "BLDIR" ||
          user.proxyRole === "CS"
        ) {
          if (params.row.status && params.row.status.code === "INP") {
            arr.push(
              <GridActionsCellItem
                icon={<Visibility />}
                label="View Inspection Records"
                onClick={() => handleViewInspectionsClick(params.row)}
                showInMenu
              />
            );
          }
        }
        return arr;
      },
    });
    return columnArray;
  });

  return (
    <Permit
      roles="BLDIR,BLDMG,REC,ACC,CS"
      services={(!isNullUndefined(user.proxyFacility) && !isEmptyString(user.proxyFacility) && user.proxyFacility !== "null") ? "PREMIUM,HOA,MAINT" : null}
      noPermittedServiceMessage={{
        title: "Access Denied",
        body: (
          <>
            Your current role and/or facility is not permitted to view this
            page. <br /> Please contact your system admin if you feel this is an
            error.
          </>
        ),
      }}
    >
      <MainContainer>
        {(task === "" || task === "Result" || task === "search") && (
          <TopBarContainer
            sx={{
              flexDirection: { xs: "column", lg: "row" },
              flexWrap: { xs: "wrap", lg: "nowrap" },

              justifyContent: { xs: "center", lg: "space-between" },
              alignItems: "center",

              width: "100%",
              height: "auto",

              backgroundColor: "white",
              borderRadius: "10px",

              marginBottom: "40px",
              padding: 0,
            }}
          >
            <Typography
              variant="h4"
              sx={{
                width: { xs: "100%", lg: "25%" },
                margin: { xs: "20px auto 10px auto", lg: "auto 20px" },
                textAlign: { xs: "center", lg: "start" },
              }}
            >
              Work Orders
            </Typography>
            <ResponsiveRow
              sx={{
                width: { xs: "100%", lg: "75%" },
                height: "auto",
                justifyContent: { xs: "center", lg: "flex-end" },
                alignItems: "center",
              }}
            >
              {selectionModel.length > 1 && (
                <Permit roles="BLDIR">
                  <TopBarContainedBrandButton
                    sx={{
                      width: { xs: "80%", lg: "auto" },
                      height: "50px",
                      padding: "5px 10px",
                      marginBottom: "10px",
                    }}
                    onClick={handleBulkApproveRejectClick}
                  >
                    Approve/Reject {selectionModel.length} Work Orders
                  </TopBarContainedBrandButton>
                </Permit>
              )}
              <Permit roles="BLDIR,BLDMG,CS">
                <TopBarContainedBrandButton
                  sx={{
                    width: { xs: "80%", lg: "auto" },
                    height: "50px",
                    padding: "5px 10px",
                    marginBottom: "10px",
                  }}
                  onClick={handleCreateClick}
                >
                  Create a Work Order Request
                </TopBarContainedBrandButton>
              </Permit>
              {task !== "search" && (
                <TopBarContainedBrandButton
                  sx={{
                    width: { xs: "80%", lg: "200px" },
                    height: "50px",
                    padding: "5px 10px",
                    marginBottom: "10px",
                  }}
                  onClick={handleShowSearchClicked}
                >
                  {
                    task === "search" ? "Close Search" : "Show Search"
                  }
                </TopBarContainedBrandButton>
              )}
              {(task === "search" || searchResults) && (
                <TopBarContainedCancelButton
                  sx={{
                    width: { xs: "80%", lg: "200px" },
                    height: "50px",
                    padding: "5px 10px",
                    marginBottom: "10px",
                  }}
                  color="error"
                  onClick={handleCloseSearch}
                >
                  {
                    searchResults ? "Clear Search" : "Close Search"
                  }
                </TopBarContainedCancelButton>
              )}
            </ResponsiveRow>
          </TopBarContainer>
        )}
        {task === "search" && (
          <ResponsiveRow
            sx={{
              width: "100%",
              margin: "auto",
              backgroundColor: "white",
              padding: "10px",
              borderRadius: "10px",
            }}
          >
            <ResponsiveRow
              sx={{
                justifyContent: { xs: "center", lg: "space-between" },
                textAlign: "start",
              }}
              container
            >
              <FormControl
                item
                sx={{
                  width: { xs: "100%", lg: "33.3%" },
                  marginBottom: "10px",
                }}
              >
                <InputLabel>Reference</InputLabel>
                <OutlinedInput
                  label={"Ref Number"}
                  sx={{ width: { xs: "100%", lg: "90%" } }}
                  id={"reference"}
                  onChange={(event) => onTextChange(event)}
                  value={reference}
                />
              </FormControl>
              <FormControl
                item
                sx={{
                  width: { xs: "100%", lg: "33.3%" },
                  marginBottom: "10px",
                }}
              >
                <InputLabel>Status</InputLabel>
                <Select
                  label="Status"
                  sx={{ width: { xs: "100%", lg: "90%" } }}
                  value={statusCd}
                  onChange={(event) => handleStatusChange(event)}
                >
                  {statusOptions && statusOptions.length > 0 ? (
                    // eslint-disable-next-line array-callback-return
                    statusOptions.map(function (status, i) {
                      if (status.value === "PEN") {
                        if (
                          user.proxyRole === "SYSAD" ||
                          user.proxyRole === "BLDIR"
                        ) {
                          return (
                            <MenuItem key={status.value} value={status.value}>
                              {status.label}
                            </MenuItem>
                          );
                        }
                      } else {
                        return (
                          <MenuItem key={status.value} value={status.value}>
                            {status.label}
                          </MenuItem>
                        );
                      }
                    }, this)
                  ) : (
                    <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
                  )}
                </Select>
              </FormControl>

              <FormControl
                sx={{
                  width: { xs: "100%", lg: "33.3%" },
                  marginBottom: "10px",
                }}
              >
                <InputLabel>Description</InputLabel>
                <OutlinedInput
                  label={"Description"}
                  sx={{ width: { xs: "100%", lg: "90%" } }}
                  id={"description"}
                  onChange={(event) => onTextChange(event)}
                  value={description}
                />
              </FormControl>

              <FormControl
                item
                sx={{
                  width: { xs: "100%", lg: "33.3%" },
                  marginBottom: "10px",
                }}
              >
                <InputLabel>Requested By</InputLabel>
                <OutlinedInput
                  label={"Requested By"}
                  sx={{ width: { xs: "100%", lg: "90%" } }}
                  id={"requestedBy"}
                  onChange={(event) => onTextChange(event)}
                  value={requestedBy}
                />
              </FormControl>

              <FormControl
                item
                sx={{
                  width: { xs: "100%", lg: "33.3%" },
                  marginBottom: "10px",
                }}
              >
                <InputLabel>Approved By</InputLabel>
                <OutlinedInput
                  label={"Approved By"}
                  sx={{ width: { xs: "100%", lg: "90%" } }}
                  id={"approvedBy"}
                  onChange={(event) => onTextChange(event)}
                  value={approvedBy}
                />
              </FormControl>

              <FormControl
                item
                sx={{
                  width: { xs: "100%", lg: "33.3%" },
                  marginBottom: "10px",
                }}
              >
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale="en-gb"
                >
                  <DatePicker
                    label="Date Requested"
                    sx={{ width: { xs: "100%", lg: "90%" } }}
                    value={
                      isEmptyString(dateRequested) ? null : dayjs(dateRequested)
                    }
                    onChange={(newDate) => {
                      let date = new Date(newDate);
                      setDateRequested(date);
                    }}
                  />
                </LocalizationProvider>
              </FormControl>

              <FormControl
                item
                sx={{
                  width: { xs: "100%", lg: "33.3%" },
                  marginBottom: "10px",
                }}
              >
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale="en-gb"
                >
                  <DatePicker
                    label="Date Approved"
                    sx={{ width: { xs: "100%", lg: "90%" } }}
                    value={
                      isEmptyString(dateApproved) ? null : dayjs(dateApproved)
                    }
                    onChange={(newDate) => {
                      let date = new Date(newDate);
                      setDateApproved(date);
                    }}
                  />
                </LocalizationProvider>
              </FormControl>
            </ResponsiveRow>
            <ResponsiveRow
              sx={{
                width: "100%",
                justifyContent: { xs: "center", lg: "flex-end" },
                alignItems: "center",
              }}
            >
              <ModalAcceptButton
                onClick={handleSearchClicked}
                disabled={disableSearch}
                sx={{ width: { xs: "100%", lg: "200px" } }}
              >
                Search
              </ModalAcceptButton>
              <ModalCancelButton
                color="error"
                onClick={handleClearSearch}
                sx={{
                  marginLeft: "10px",
                  width: { xs: "100%", lg: "200px" },
                }}
              >
                Clear
              </ModalCancelButton>
            </ResponsiveRow>
          </ResponsiveRow>
        )}

        {task === "View" && (
          <WorkOrderView
            selectedWorkOrder={selectedWorkOrder}
            handleViewClose={handleViewClose}
            refreshDetails={refreshDetails}
            reloadWorkOrders={reloadWorkOrders}
          />
        )}

        {task === "CreateBillPayment" && (
          <CreateBillPaymentForm
            selectedWorkOrder={selectedWorkOrder}
            handleCreateBillClose={handleCreateBillClose}
            reloadWorkOrders={reloadWorkOrders}
            task={task}
          />
        )}

        {(task === "Create" || task === "Edit") && (
          <CreateEditWorkOrderForm
            task={task}
            selectedWorkOrder={selectedWorkOrder}
            handleCreateEditWorkOrderClose={handleCreateEditWorkOrderClose}
            reloadWorkOrders={reloadWorkOrders}
          />
        )}

        {task === "Result" && (
          <Grid
            container
            sx={{
              display: "flex",
              flexDirection: "column",

              height: "100%",
              width: "100%",

              backgroundColor: "#fff",
              borderRadius: "20px",

              boxShadow: "0 4px 5px 0 rgba(0,0,0,0.3)",
              padding: "20px",
            }}
          >
            <TabContext value={currentTab}>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",

                  width: "100%",

                  overflowX: "auto",
                }}
              >
                <Tabs
                  value={currentTab}
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="basic tabs example"
                  sx={{ marginTop: "20px" }}
                >
                  {workOrderTabs.map((tab) => {
                    return (
                      <Tab
                        key={tab.id}
                        label={tab.name}
                        id={tab.id}
                        value={tab.value}
                        aria-controls={`tabPanel-${currentTab}`}
                        onClick={() => handleTabChange(tab)}
                      />
                    );
                  })}
                </Tabs>
              </Box>
              {workOrderTabs.map((tab) => {
                return (
                  <TabPanel
                    key={tab.id}
                    value={tab.value}
                    index={tab.id}
                    sx={{ width: "100%", padding: 0, margin: "5px 0 0 0" }}
                  >
                    {populateTab(tab.id)}
                  </TabPanel>
                );
              })}
            </TabContext>
          </Grid>
        )}

        {/*APPROVE/REJECT MODAL*/}
        {/*{!isNullUndefined(selectedWorkOrder) && (*/}

        <ApproveRejectWorkOrderModal
          isOpen={approveRejectModal}
          task={task}
          reason={reason}
          selectionModel={selectionModel}
          selectedWorkOrder={selectedWorkOrder}
          reloadWorkOrders={reloadWorkOrders}
          handleApproveRejectClose={handleApproveRejectClose}
          onTextChange={onTextChange}
        />

        {/*)}*/}

        {/*CONFIRMATION MODAL*/}
        {showConfirmationModal && (
          <ConfirmationModal
            showConfirmationModal={showConfirmationModal}
            handleConfirmationClose={handleConfirmationClose}
            title={titleText}
            severity={severityText}
            body={bodyText}
            positiveText={positiveText}
            negativeText={negativeText}
            positiveAction={deleteWorkOrder}
            negativeAction={handleConfirmationClose}
          />
        )}

        {/*INSPECT MODAL*/}
        <Inspections
          openModal={inspectModal}
          closeModal={() => setInspectModal(false)}
          contract={selectedWorkOrder}
          inspectionItem={"Work Order"}
        />

        <ViewInspections
          openModal={viewInspectionsModal}
          closeModal={() => {
            setViewInspectionsModal(false);
            setInspectionRecords([]);
          }}
          contract={selectedWorkOrder}
          inspectionItem={"Work Order"}
          inspectionRecords={inspectionRecords}
        />
      </MainContainer>
    </Permit>
  );
}
