import {Box, Button, Grid, TextField, Typography,} from "@mui/material"
import {useState} from "react"

export default function JournalEntriesBar() {
    const [search] = useState("")

    function handleChange(e) {
    }

    return (
        <Grid
            container
            sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: {lg: "nowrap", md: "wrap"},
                justifyContent: {md: "space-between", xs: "space-between"},
                width: {xs: "100%", md: "80%"},
                maxHeight: {xs: "100%", md: "fit-content", lg: "75px"},
                backgroundColor: "white",
                padding: {md: "1vh", xs: "1vh"},
                borderRadius: "1vh",
                margin: "20px auto",
                boxShadow: "0 4px 5px 0 rgba(0,0,0,0.5)",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: {xl: "row", xs: "column"},
                    justifyContent: {md: "space-between", xs: "center"},
                    width: {lg: "33.3%", md: "50%", sm: "100%"},
                }}
                component="form"
            >
                <Typography
                    sx={{
                        fontSize: "16px",
                        fontWeight: 800,
                        margin: "auto",
                        width: "100%",
                        textAlign: "start",
                        padding: "20px",
                    }}
                >
                    Journal Entries
                </Typography>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: {xl: "row", xs: "column"},
                    justifyContent: {md: "space-between", xs: "center"},
                    alignItems: "center",
                    width: {md: "50%", xs: "100%"},
                    padding: "10px",
                }}
            >
                <TextField
                    id="outlined-basic"
                    label="Search Authors"
                    variant="outlined"
                    value={search}
                    sx={{margin: "5px auto", fontSize: "12px", width: "100%"}}
                    onChange={handleChange}
                />

                <Button
                    variant="contained"
                    sx={{
                        backgroundColor: "#037171",
                        color: "#fff",
                        "&:hover": {
                            backgroundColor: "#FFF",
                            color: "#037171",
                        },
                        fontSize: "12px",
                        padding: "5px 7px",
                        margin: {xs: "10px 0", md: "5px 10px", lg: "0 5px"},
                        textTransform: "full-width",
                        width: "100%",
                    }}
                >
                    Add Journal Entry
                </Button>
            </Box>
        </Grid>
    )
}
