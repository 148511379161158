import {Typography} from "@mui/material"
import {ResponsiveRow} from "../../../constants/layout.constants"
import RecommendedListing from "../../cards/listings/recommendedListing"

export default function ListingFeaturedListings() {
    return (
        <ResponsiveRow
            container
            sx={{
                flexDirection: "column",
                textAlign: "start",
                padding: "20px",
                margin: "auto",
                width: {xs: "100%", lg: "80%"},
                marginTop: "20px",
            }}
        >
            <Typography variant="h1">Featured Listings</Typography>

            <ResponsiveRow sx={{padding: {xs: "10px", lg: "20px"}}}>
                <RecommendedListing/>
                <RecommendedListing/>
                <RecommendedListing/>
                <RecommendedListing/>
            </ResponsiveRow>
        </ResponsiveRow>
    )
}
