import { Alert } from '@mui/material';
import ModalContainerModule from '../../../modules/modalContainer';
import { reverseTopUp } from '../../../../services/pettyCash.service';
import { handleInfoResponse } from '../../../../actions/message';

const ReverseTopupModal = (props) => {
	const reverseTopup = () => {
		reverseTopUp(props.topUp.id).then((response) => {
			handleInfoResponse(response);
			props.close(false);
		});
	};

	return (
		<ModalContainerModule
			size="specialReverseJournalEntry"
			accept={reverseTopup}
			cancel={() => {
				props.close(false);
			}}
			openModal={props.open}
			modalTitle="Reverse Petty Cash Topup"
			acceptButtonText="Reverse Topup"
		>
			{props.topUp && (
				<Alert severity="error" sx={{ fontSize: '1rem', width: '100%' }}>
					Are you sure you wish to Reverse the Petty Cash Topup 
					{' ' +props.topUp.description}?
				</Alert>
			)}
		</ModalContainerModule>
	);
};

export default ReverseTopupModal;
