import { ResponsiveRow } from '../constants/layout.constants';
import {
	DetailsColumn,
	DetailsContainer,
	DetailsLabel,
	DetailsRow,
	DetailsText,
} from '../constants/component.constants';
import { formatDate } from '../util/util';
import ModalContainerModule from '../components/modules/modalContainer';
import { useSelector } from 'react-redux';
import { printCredit } from '../services/print.service';

export default function CreditNoteView(props) {
	const { user } = useSelector((state) => state.auth);
	const handlePrint = () => {
		printCredit(props.creditItem && props.creditItem.id);
	};

	return (
		<ModalContainerModule
			size="sm"
			accept={user.proxyRole === 'BLDIR' ? props.approveDebitNote : false}
			reject={user.proxyRole === 'BLDIR' ? props.rejectDebitNote : false}
			cancel={props.closeCreditModal}
			openModal={props.openCreditModal}
			modalTitle={'Review Credit'}
			acceptButtonText={'Approve'}
			rejectButtonText={'Reject'}
			acceptDisabled={user.proxyRole === 'BLDIR' ? false : true}
			printAction={handlePrint}
		>
			<ResponsiveRow sx={{ paddingTop: 0, height: 'auto' }}>
				<DetailsContainer sx={{ width: '100%' }}>
					<DetailsColumn
						sx={{
							width: { lg: '50%', md: '100%', xs: '100%' },
						}}
					>
						<DetailsRow>
							<DetailsLabel>Credit To:</DetailsLabel>
							<DetailsText>{props.creditItem?.tenant?.name}</DetailsText>
						</DetailsRow>

						<DetailsRow>
							<DetailsLabel>Date:</DetailsLabel>
							<DetailsText>{formatDate(props.creditItem?.date)}</DetailsText>
						</DetailsRow>
						<DetailsRow>
							<DetailsLabel>Reference No:</DetailsLabel>
							<DetailsText>{props.creditItem?.referenceNumber}</DetailsText>
						</DetailsRow>
						<DetailsRow>
							<DetailsLabel>Status:</DetailsLabel>
							<DetailsText>{props.creditItem?.status?.name}</DetailsText>
						</DetailsRow>

						<DetailsRow>
							<DetailsLabel>Amount:</DetailsLabel>
							<DetailsText>{props.creditItem?.amount}</DetailsText>
						</DetailsRow>
						<DetailsRow>
							<DetailsLabel>Applied To:</DetailsLabel>
							<DetailsText>
								{props.creditItem?.appliedTo?.replace(/[()]/g, '')}
							</DetailsText>
						</DetailsRow>
					</DetailsColumn>

					<DetailsColumn
						sx={{
							width: { lg: '50%', md: '100%', xs: '100%' },
						}}
					>
						<DetailsRow>
							<DetailsLabel>Date Created:</DetailsLabel>
							<DetailsText>
								{formatDate(props.creditItem?.dateCreated)}
							</DetailsText>
						</DetailsRow>
						<DetailsRow>
							<DetailsLabel>Created By:</DetailsLabel>
							<DetailsText>{props.creditItem?.createdBy?.name}</DetailsText>
						</DetailsRow>
						<DetailsRow>
							<DetailsLabel>Date Approved:</DetailsLabel>
							<DetailsText>
								{formatDate(props.creditItem?.dateApproved)}
							</DetailsText>
						</DetailsRow>

						<DetailsRow>
							<DetailsLabel>Approved By:</DetailsLabel>
							<DetailsText>{props.creditItem?.approvedBy?.name}</DetailsText>
						</DetailsRow>

						<DetailsRow>
							<DetailsLabel>Memo:</DetailsLabel>
							<DetailsText>{props.creditItem?.memo}</DetailsText>
						</DetailsRow>
					</DetailsColumn>
				</DetailsContainer>
			</ResponsiveRow>
		</ModalContainerModule>
	);
}
