import {
	CLEAR_PROXY,
	LOGIN_FAIL,
	LOGIN_SUCCESS,
	LOGOUT,
	PROXY,
	SET_MESSAGE,
} from './type';
import { isEmptyString, isNullUndefined } from '../util/util';
import authService from '../services/auth.service';

export const login = (username, password) => (dispatch) => {
	return authService.login(username, password).then((data) => {
		if (!isNullUndefined(data) && !isNullUndefined(data.user)) {
			// console.log("data.user", data.user)
			dispatch({
				type: LOGIN_SUCCESS,
				payload: { user: data.user, loginType: 'main' },
			});
			dispatch({
				type: SET_MESSAGE,
				payload: { type: data.type, message: data.message },
			});
			return Promise.resolve();
		} else {
			dispatch({
				type: LOGIN_FAIL,
			});
			return Promise.reject();
		}
	});
};

export const listingsLogin = (username, password) => (dispatch) => {
	return authService.listingsLogin(username, password).then((data) => {
		if (!isNullUndefined(data)) {
			dispatch({
				type: LOGIN_SUCCESS,
				payload: { user: data.user, loginType: 'listings' },
			});
			dispatch({
				type: SET_MESSAGE,
				payload: { type: data.type, message: data.message },
			});

			return Promise.resolve();
		} else {
			dispatch({
				type: LOGIN_FAIL,
			});
			return Promise.reject();
		}
	});
};

export const fetchFacebookUser = (id) => (dispatch) => {
	return authService.fetchFacebookUser(id).then((data) => {
		if (!isNullUndefined(data)) {
			dispatch({
				type: LOGIN_SUCCESS,
				payload: { user: data.user, loginType: 'listings' },
			});
			dispatch({
				type: SET_MESSAGE,
				payload: { type: data.type, message: data.message },
			});
			return Promise.resolve();
		} else {
			dispatch({
				type: LOGIN_FAIL,
			});
			return Promise.reject();
		}
	});
};

export const fetchGoogleUser = (id) => (dispatch) => {
	return authService.fetchGoogleUser(id).then((data) => {
		if (!isNullUndefined(data)) {
			dispatch({
				type: LOGIN_SUCCESS,
				payload: { user: data.user, loginType: 'listings' },
			});
			dispatch({
				type: SET_MESSAGE,
				payload: { type: data.type, message: data.message },
			});

			return Promise.resolve();
		} else {
			dispatch({
				type: LOGIN_FAIL,
			});
			return Promise.reject();
		}
	});
};

export const listingsLogout = () => (dispatch) => {
	authService.listingsLogout().then(() => {
		dispatch({
			type: LOGOUT,
		});
	});
};

export const logout = () => (dispatch) => {
	authService.logout().then(() => {
		dispatch({
			type: LOGOUT,
		});
	});
};

export const clearProxy = () => (dispatch) => {
	dispatch({
		type: CLEAR_PROXY,
	});
	authService.clearProxy();
};

export const executeProxy =
	(selectedProxyRole, selectedProxyOrganization, selectedProxyFacility) =>
	(dispatch) => {
		//only look for the role if the user is a sys admin
		//otherwise the selection of a facility or organization
		// is sufficient to make the proxy call

		if (
			(!isEmptyString(selectedProxyRole) &&
				!isEmptyString(selectedProxyOrganization)) ||
			(!isEmptyString(selectedProxyRole) &&
				!isEmptyString(selectedProxyFacility)) ||
			(!isEmptyString(selectedProxyOrganization) &&
				!isEmptyString(selectedProxyFacility)) ||
			!isEmptyString(selectedProxyFacility)
		) {
			let paramArray = {};
			paramArray.selectedProxyFacility = selectedProxyFacility;
			paramArray.selectedProxyRole = selectedProxyRole;
			paramArray.selectedProxyOrganization = selectedProxyOrganization;
			let params = JSON.stringify(paramArray);
			authService
				.executeProxy(params)
				.then((data) => {
					dispatch({
						type: PROXY,
						payload: data,
					});
				})
				.catch((error) => {
					const message =
						(error &&
							error.response &&
							error.response.data &&
							error.response.data.message) ||
						(error && error.message) ||
						error.toString();

					dispatch({
						type: SET_MESSAGE,
						payload: message,
					});

					return Promise.reject();
				});
		}
	};
