import {Alert, Typography} from "@mui/material";
import {
    DetailsCardContainer,
    DetailsColumn,
    DetailsContainer,
    DetailsLabel,
    DetailsRow,
    DetailsText,
} from "../../../../constants/component.constants";
import {ResponsiveRow} from "../../../../constants/layout.constants";
import {formatDate} from "../../../../util/util";
import {DocumentStore} from "../../../documentStore";
import ModalContainerModule from "../../../modules/modalContainer";

export default function ViewSummaryModal(props) {
    /**
     * REQUIRED PROPS:
     *
     * handleCloseView
     * viewForm
     * itemToView
     *
     */

    return (
        <ModalContainerModule
            size="sm"
            cancel={props.handleCloseView}
            openModal={props.viewForm}
            modalTitle={`Mobile Money Summary For: ${
                props.itemToView && props.itemToView.facilityName
            }`}
        >
            <ResponsiveRow
                sx={{
                    justifyContent: {xs: "center", lg: "space-between"},
                    flexWrap: {xs: "wrap", lg: "nowrap"},
                    padding: {xs: "0", lg: "auto"},
                }}
            >
                <ResponsiveRow
                    sx={{
                        padding: "0 10px",
                        height: "fit-content",
                        width: {xs: "100%", lg: "70%"},
                    }}
                >
                    <DetailsCardContainer
                        container
                        sx={{boxShadow: 0, padding: "0 10px", height: "auto"}}
                    >
                        <Typography
                            variant="h4"
                            sx={{color: "#037171", padding: "0 10px", marginBottom: 0}}
                        >
                            Summary Details
                        </Typography>
                        <DetailsContainer
                            sx={{width: "100%", padding: 0, marginBottom: 0}}
                        >
                            <DetailsColumn
                                sx={{
                                    width: {xs: "100%", lg: "50%"},
                                    height: "auto",
                                    marginBottom: 0,
                                }}
                            >
                                <DetailsRow>
                                    <DetailsLabel sx={{fontSize: ".9rem", fontWeight: 700}}>
                                        Subscriber :
                                    </DetailsLabel>
                                    <DetailsText
                                        sx={{
                                            marginLeft: 2,
                                            fontSize: "14px",
                                            color: "#037171",
                                            textAlign: "left",
                                        }}
                                    >
                                        {props.itemToView && props.itemToView.parentOrg}
                                    </DetailsText>
                                </DetailsRow>
                                <DetailsRow>
                                    <DetailsLabel sx={{fontSize: ".9rem", fontWeight: 700}}>
                                        MPESA Subscription:
                                    </DetailsLabel>
                                    <DetailsText
                                        sx={{
                                            marginLeft: 2,
                                            fontSize: "14px",
                                            color: "#037171",
                                            textAlign: "left",
                                        }}
                                    >
                                        {props.itemToView && props.itemToView.mpesaSubscription}
                                    </DetailsText>
                                </DetailsRow>
                                <DetailsRow>
                                    <DetailsLabel sx={{fontSize: ".9rem", fontWeight: 700}}>
                                        Total Amount Transacted:
                                    </DetailsLabel>
                                    <DetailsText
                                        sx={{
                                            marginLeft: 2,
                                            fontSize: "14px",
                                            color: "#037171",
                                            textAlign: "left",
                                        }}
                                    >
                                        {props.itemToView && props.itemToView.totalAmountTransacted}
                                    </DetailsText>
                                </DetailsRow>
                                <DetailsRow>
                                    <DetailsLabel sx={{fontSize: ".9rem", fontWeight: 700}}>
                                        Last Transfer Date:
                                    </DetailsLabel>
                                    <DetailsText
                                        sx={{
                                            marginLeft: 2,
                                            fontSize: "14px",
                                            color: "#037171",
                                            textAlign: "left",
                                        }}
                                    >
                                        {props.itemToView &&
                                            props.itemToView.lastTransferDate &&
                                            formatDate(props.itemToView.lastTransferDate)}
                                    </DetailsText>
                                </DetailsRow>
                                <DetailsRow>
                                    <DetailsLabel sx={{fontSize: ".9rem", fontWeight: 700}}>
                                        Subscription Service:
                                    </DetailsLabel>
                                    <DetailsText
                                        sx={{
                                            marginLeft: 2,
                                            fontSize: "14px",
                                            color: "#037171",
                                            textAlign: "left",
                                        }}
                                    >
                                        {props.itemToView && props.itemToView.subscription}
                                    </DetailsText>
                                </DetailsRow>
                                <DetailsRow>
                                    <DetailsLabel sx={{fontSize: ".9rem", fontWeight: 700}}>
                                        Amount Transacted:
                                    </DetailsLabel>
                                    <DetailsText
                                        sx={{
                                            marginLeft: 2,
                                            fontSize: "14px",
                                            color: "#037171",
                                            textAlign: "left",
                                        }}
                                    >
                                        {props.itemToView &&
                                            props.itemToView.amountTransactedUnInvoiced}
                                    </DetailsText>
                                </DetailsRow>
                            </DetailsColumn>
                            <DetailsColumn
                                sx={{
                                    width: {xs: "100%", lg: "50%"},
                                    height: "auto",
                                    marginBottom: 0,
                                }}
                            >
                                <DetailsRow>
                                    <DetailsLabel sx={{fontSize: ".9rem", fontWeight: 700}}>
                                        Total MPESA Fee:
                                    </DetailsLabel>
                                    <DetailsText
                                        sx={{
                                            marginLeft: 2,
                                            fontSize: "14px",
                                            color: "#037171",
                                            textAlign: "left",
                                        }}
                                    >
                                        {props.itemToView && props.itemToView.totalMpesaFee}
                                    </DetailsText>
                                </DetailsRow>
                                <DetailsRow>
                                    <DetailsLabel sx={{fontSize: ".9rem", fontWeight: 700}}>
                                        Amount Transfered :
                                    </DetailsLabel>
                                    <DetailsText
                                        sx={{
                                            marginLeft: 2,
                                            fontSize: "14px",
                                            color: "#037171",
                                            textAlign: "left",
                                        }}
                                    >
                                        {props.itemToView && props.itemToView.amountTransfered}
                                    </DetailsText>
                                </DetailsRow>
                                <DetailsRow>
                                    <DetailsLabel sx={{fontSize: ".9rem", fontWeight: 700}}>
                                        Number of Units:
                                    </DetailsLabel>
                                    <DetailsText
                                        sx={{
                                            marginLeft: 2,
                                            fontSize: "14px",
                                            color: "#037171",
                                            textAlign: "left",
                                        }}
                                    >
                                        {props.itemToView && props.itemToView.noOfUnits}
                                    </DetailsText>
                                </DetailsRow>
                                <DetailsRow>
                                    <DetailsLabel sx={{fontSize: ".9rem", fontWeight: 700}}>
                                        MPESA Service Fee:
                                    </DetailsLabel>
                                    <DetailsText
                                        sx={{
                                            marginLeft: 2,
                                            fontSize: "14px",
                                            color: "#037171",
                                            textAlign: "left",
                                        }}
                                    >
                                        {props.itemToView && props.itemToView.mpesaFee}
                                    </DetailsText>
                                </DetailsRow>
                                <DetailsRow>
                                    <DetailsLabel sx={{fontSize: ".9rem", fontWeight: 700}}>
                                        Transfer Frequency:
                                    </DetailsLabel>
                                    <DetailsText
                                        sx={{
                                            marginLeft: 2,
                                            fontSize: "14px",
                                            color: "#037171",
                                            textAlign: "left",
                                        }}
                                    >
                                        {props.itemToView && props.itemToView.transferFrequency}
                                    </DetailsText>
                                </DetailsRow>
                            </DetailsColumn>
                        </DetailsContainer>
                    </DetailsCardContainer>

                    {props.itemToView.facilityPayInfo && (
                        <ResponsiveRow
                            sx={{
                                height: "fit-content",
                                width: "100%",
                                padding: 0,
                            }}
                        >
                            <Alert
                                sx={{width: "100%", height: "fit-content"}}
                                severity={"info"}
                            >
                                This payment information will be sent out on the invoices so
                                that customers can make payments using the appropriate service.
                            </Alert>
                            {props.itemToView.facilityPayInfo.useMpesa && (
                                <ResponsiveRow
                                    sx={{
                                        textAlign: "start",
                                        justifyContent: "flex-start",
                                        alignItems: "flex-start",
                                        flexDirection: {xs: "column", lg: "row"},
                                        flexWrap: "nowrap",
                                        height: "auto",
                                    }}
                                >
                                    <ResponsiveRow
                                        sx={{
                                            flexWrap: "nowrap",
                                            justifyContent: "space-between",
                                            width: {xs: "100%", lg: "50%"},
                                        }}
                                    >
                                        <DetailsLabel sx={{fontSize: ".9rem", fontWeight: 700}}>
                                            M-PESA Paybill:
                                        </DetailsLabel>
                                        <Typography
                                            sx={{
                                                marginLeft: 2,
                                                fontSize: "14px",
                                                color: "#037171",
                                                textAlign: "left",
                                            }}
                                        >
                                            {props.itemToView &&
                                                props.itemToView.facilityPayInfo.mpesaPaybill}
                                        </Typography>
                                    </ResponsiveRow>
                                    <ResponsiveRow
                                        sx={{
                                            width: {xs: "100%", lg: "50%"},
                                            height: "auto",
                                            flexWrap: "nowrap",
                                        }}
                                    >
                                        <DetailsLabel sx={{fontSize: ".9rem", fontWeight: 700}}>
                                            M-PESA Business Shortcode:
                                        </DetailsLabel>
                                        <Typography
                                            sx={{
                                                marginLeft: 2,
                                                fontSize: "14px",
                                                color: "#037171",
                                                textAlign: "left",
                                            }}
                                        >
                                            {props.itemToView &&
                                                props.itemToView.facilityPayInfo.mpesaBusinessShortcode}
                                        </Typography>
                                    </ResponsiveRow>
                                </ResponsiveRow>
                            )}

                            {props.itemToView.facilityPayInfo.useBankAccount && (
                                <ResponsiveRow
                                    sx={{
                                        textAlign: "start",
                                        justifyContent: "flex-start",
                                        alignItems: "flex-start",
                                        flexWrap: "nowrap",
                                        height: "auto",
                                    }}
                                >
                                    <ResponsiveRow
                                        sx={{
                                            flexWrap: "nowrap",
                                            width: {xs: "100%", lg: "50%"},
                                        }}
                                    >
                                        <DetailsLabel sx={{fontSize: ".9rem", fontWeight: 700}}>
                                            Bank Account Name:
                                        </DetailsLabel>
                                        <Typography
                                            sx={{
                                                marginLeft: 2,
                                                fontSize: "14px",
                                                color: "#037171",
                                                textAlign: "left",
                                            }}
                                        >
                                            {props.itemToView &&
                                                props.itemToView.facilityPayInfo.bankAccountName}
                                        </Typography>
                                    </ResponsiveRow>

                                    <ResponsiveRow
                                        sx={{
                                            flexWrap: "nowrap",
                                            width: {xs: "100%", lg: "50%"},
                                        }}
                                    >
                                        <DetailsLabel sx={{fontSize: ".9rem", fontWeight: 700}}>
                                            Account Number:
                                        </DetailsLabel>
                                        <Typography
                                            sx={{
                                                marginLeft: 2,
                                                fontSize: "14px",
                                                color: "#037171",
                                                textAlign: "left",
                                            }}
                                        >
                                            {props.itemToView &&
                                                props.itemToView.facilityPayInfo.bankAccountNumber}
                                        </Typography>
                                    </ResponsiveRow>
                                </ResponsiveRow>
                            )}
                        </ResponsiveRow>
                    )}
                </ResponsiveRow>

                <ResponsiveRow
                    sx={{
                        flexWrap: "nowrap",
                        flexDirection: {xs: "column", lg: "row"},
                        padding: "0 10px",
                        width: {xs: "100%", lg: "30%"},
                    }}
                >
                    <DocumentStore
                        files={[
                            ...props.itemToView.imageFiles,
                            ...props.itemToView.otherDocuments,
                        ]}
                        item={props.itemToView}
                        type={"Mobile Money Summary"}
                        closeView={props.handleCloseView}
                        disableShadow
                        height={"500px"}
                    />
                </ResponsiveRow>
            </ResponsiveRow>
        </ModalContainerModule>
    );
}
