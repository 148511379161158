import {Alert, Grid, InputLabel, Paper, Switch} from "@mui/material"
import {ResponsiveRow} from "../../../../constants/layout.constants"
import ModalContainerModule from "../../../modules/modalContainer"

export default function ArchiveMeterModal(props) {
    /**
     *
     * REQUIRED PROPS
     *
     * ArchiveModal
     *
     * handleArchiveClose
     * handleInvoiceLastReadingToggle
     *
     * archiveSelectedMeter
     * invoiceLastReading
     * selectedMeter
     */
    return (
        <ModalContainerModule
            size="xs"
            accept={props.archiveSelectedMeter}
            cancel={props.handleArchiveClose}
            openModal={props.ArchiveModal}
            modalTitle="Archive Meter"
            acceptButtonText="Archive Meter"
        >
            <Paper
                variant=""
                sx={{
                    width: "100%",
                    height: "auto",
                    margin: "auto",
                    padding: "10px",
                }}
            >
                <Alert severity="error" sx={{padding: "20px"}}>
                    Are you sure you wish to Archive Meter{" "}
                    {props.selectedMeter && props.selectedMeter.name}?
                </Alert>
                <br/>
                <ResponsiveRow container sx={{padding: 0}}>
                    <Grid item sx={{width: "100%"}}>
                        <InputLabel>Invoice Last Reading?</InputLabel>
                        <Switch
                            checked={props.invoiceLastReading}
                            onChange={(event) => props.handleInvoiceLastReadingToggle(event)}
                            inputProps={{"aria-label": "controlled"}}
                        />
                    </Grid>
                </ResponsiveRow>
            </Paper>
        </ModalContainerModule>
    )
}
