/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { GridActionsCellItem } from "@mui/x-data-grid";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TableCell,
  TableRow,
} from "@mui/material";
import {
    bulkUploadVendors,
    deleteVendor,
    fetchVendor,
    listVendorCategories,
    loadVendors,
    saveVendor,
    totalVendors,
} from "../services/vendors.service";
import {Cancel, Contacts, Delete, Edit} from "@mui/icons-material";
import PersonOrgSearch from "../components/modals/personOrgSearch";
import AddNewUser from "../components/modals/addNewUser";
import AddNewOrganisation from "../components/modals/addNewOrganisation";
import {
  getCountries,
  getExpenseAccounts,
  getIdTypes,
} from "../services/list.service";
import {
  isEmptyObject,
  isEmptyString,
  isNullUndefined,
  sortJSONArray,
} from "../util/util";
import { issueResponseMessage, issueWarnMessage } from "../actions/message";
import ConfirmationModal from "../components/modals/confirmation";
import {ResponsiveRow} from "../constants/layout.constants";
import {MainContainer, TopBarContainedBrandButton, TopBarContainer,} from "../constants/component.constants";
import {useSelector} from "react-redux";
import AddIndividualVendorModal from "../components/modals/maintenance/vendors/addIndividualVendorModal";
import AddCompanyVendorModal from "../components/modals/maintenance/vendors/addCompanyVendorModal";
import ChangeVendorContactPerson from "../components/modals/maintenance/vendors/changeVendorContactPerson";
import BulkUploadVendorsModal from "../components/modals/bulkUploadVendors";
import Permit from "../util/permit";
import EditUserModal from "../components/modules/users/editUserModal";
import {getUserRoles, saveUser} from "../services/users.service";
import MuiDataGrid from "../components/modules/MuiDataGrid";

export default function Vendors() {
    const user = useSelector((state) => state.auth.user);
    const [vendorName, setVendorName] = useState("");
    const [vendorList, setVendorList] = useState("");
    const [task, setTask] = useState("");
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 50,
    });
    const [total, setTotalVendors] = useState(0);
    const [vendorDetailForm, setVendorDetailForm] = useState(false);
    const [selectedVendor, setSelectedVendor] = useState(null);
    const [expenseCategories, setExpenseCategories] = useState([]);
    const [vendorModal, setVendorModal] = useState(false);
    const [addOrganizationModal, setAddOrganizationModal] = useState(false);
    const [addPersonModal, setAddPersonModal] = useState(false);
    const [firstName, setFirstName] = useState("");
    const [middleName, setMiddleName] = useState("");
    const [lastName, setLastName] = useState("");
    const [mobilePhone, setMobilePhone] = useState("");
    const [officePhone, setOfficePhone] = useState("");
    const [email, setEmail] = useState("");
    const [idNumber, setIdNumber] = useState("");
    const [idType, setIdType] = useState("");
    const [idTypeOptions, setIdTypeOptions] = useState([]);
    const [vendorCategories, setVendorCategories] = useState([]);
    const [expenseAccountsList, setExpenseAccountsList] = useState([]);
    const [deleteVendorModal, setDeleteVendorModal] = useState(false);
    const [editCompanyContactModal, setEditCompanyContactModal] = useState(false);
    const [titleText, setTitleText] = useState("");
    const [bodyText, setBodyText] = useState("");
    const [positiveText, setPositiveText] = useState("");
    const [negativeText, setNegativeText] = useState("");
    const [type, setType] = useState("");
    const [countriesList, setCountriesList] = useState([]);
    const [personSearchModal, setPersonSearchModal] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [bulkFile, setBulkFile] = useState(null);
    const [bulkFileProgress, setBulkFileProgress] = useState(0);
    const [bulkUploadModal, setBulkUploadModal] = useState(false);
    const [selectedCompanyContact, setSelectedCompanyContact] = useState();
    const [idTypes, setIdTypes] = useState([]);
    const [userRoles, setUserRoles] = useState([]);

    useEffect(() => {
        loadVendors(
            vendorName,
            paginationModel.page | 0,
            paginationModel.pageSize | 50
        ).then((data) => {
        setVendorList(data.sort(sortJSONArray("name", true)))});
        totalVendors(vendorName).then((data) => setTotalVendors(data.totalVendors));
        getIdTypes().then((idTypes) => setIdTypeOptions(idTypes));
        listVendorCategories().then((data) => setVendorCategories(data));
        getExpenseAccounts().then((data) => setExpenseAccountsList(data));
        getCountries().then((data) => setCountriesList(data));
    }, [vendorName, paginationModel.page, paginationModel.pageSize]);

    useEffect(() => {
        setLoading(true);
        loadVendors(vendorName, paginationModel.page, paginationModel.pageSize)
            .then((data) => setVendorList(data.sort(sortJSONArray("name", true))))
            .finally(() => {
                setLoading(false);
            });
        totalVendors(vendorName).then((data) => setTotalVendors(data.totalVendors));
    }, [user.proxyFacility]);

    function handleCloseDeleteVendorModal() {
        setDeleteVendorModal(false);
        setSelectedVendor(null);
        setTitleText("");
        setTask("");
        setBodyText("");
        setPositiveText("");
        setNegativeText("");
    }

    const fileValueChanged = (event) => {
        setBulkFile(event.target.files[0]);
    };

    const bulkUploadCancelClicked = () => {
        setBulkFile(null);
        setBulkUploadModal(false);
        setBulkFileProgress(0);
    };

    const validateBulkUploadForm = () => {
        if (isNullUndefined(bulkFile)) {
            issueWarnMessage(
                "Could not determine uploaded file. Please Upload a file first."
            );
            return false;
        }
        return true;
    };

    const handleSubmitBulkUploadFile = () => {
        let uploadData = new FormData();
        uploadData.append("file", bulkFile);

        if (validateBulkUploadForm()) {
            bulkUploadVendors(uploadData).then((response) => {
                issueResponseMessage(response);
                loadVendors(
                    vendorName,
                    paginationModel.page | 0,
                    paginationModel.pageSize | 50
                ).then((data) => setVendorList(data.sort(sortJSONArray("name", true))));
                totalVendors(vendorName).then((data) =>
                    setTotalVendors(data.totalVendors)
                );
                bulkUploadCancelClicked();
            });
        }
    };

  function handleEditCompanyContactClicked(item) {
    setEditCompanyContactModal(true);
    setSelectedCompanyContact(item.contact);
  }

  function validateUserForm(sendData) {
    if (isNullUndefined(sendData)) {
      issueWarnMessage(
        "Could not find user data, please refresh the page and try again."
      );
      return false;
    }
    if (isEmptyString(sendData.firstName) || isEmptyString(sendData.lastName)) {
      issueWarnMessage("First name and Last name are required.");
      return false;
    }
    if (
      isNullUndefined(sendData.mobilePhone) ||
      isEmptyString(sendData.mobilePhone)
    ) {
      issueWarnMessage("Mobile Phone number is required.");
      return false;
    }
    if (
      isNullUndefined(sendData.idNumber) ||
      isEmptyString(sendData.idNumber) ||
      isNullUndefined(sendData.idTypeCd) ||
      isEmptyString(sendData.idTypeCd)
    ) {
      issueWarnMessage("Must provide and ID number and ID type.");
      return false;
    }
    if (isNullUndefined(sendData.email) || isEmptyString(sendData.email)) {
      issueWarnMessage("Must provide an email.");
      return false;
    }
    if (
      isNullUndefined(sendData.credentialId) ||
      isEmptyString(sendData.credentialId)
    ) {
      issueWarnMessage("Must provide a username.");
      return false;
    }

    return true;
  }

  function handleSaveCompanyContactClicked() {
    let params = selectedCompanyContact;
    if (validateUserForm(params)) {
      saveUser(params).then((response) => {
        issueResponseMessage(response);
        loadVendors(
          vendorName,
          paginationModel.page | 0,
          paginationModel.pageSize | 50
        ).then((data) => setVendorList(data.sort(sortJSONArray("name", true))));
        setEditCompanyContactModal(false);
        setSelectedCompanyContact();
      });
    }
  }

  function handleDeleteVendorClicked(item) {
    let vendorName = item.name;
    setDeleteVendorModal(true);
    setSelectedVendor(item);
    let title = "Confirm Delete Vendor";
    let body =
      "Are you sure you want to delete " +
      vendorName +
      "?" +
      "You will no longer be able to make payments or raise bills for this vendor.";
    setTitleText(title);
    setTask("Delete");
    setBodyText(body);
    setPositiveText("Delete Vendor");
    setNegativeText("Cancel");
  }

  function positiveAction() {
    if (task === "Delete") {
      deleteSelectedVendor();
    }
  }

  function deleteSelectedVendor() {
    if (
      isNullUndefined(selectedVendor) ||
      isNullUndefined(selectedVendor.id) ||
      isEmptyString(selectedVendor.id)
    ) {
      issueWarnMessage(
        "Could not determine Vendor ID for deletion. Try to refresh the page or contact support."
      );
      return;
    }

    deleteVendor(selectedVendor).then((response) => {
      issueResponseMessage(response);
      loadVendors(
        vendorName,
        paginationModel.page | 0,
        paginationModel.pageSize | 50
      ).then((data) => setVendorList(data.sort(sortJSONArray("name", true))));
      totalVendors(vendorName).then((data) =>
        setTotalVendors(data.totalVendors)
      );
      handleCloseDeleteVendorModal();
    });
  }

  function searchVendorName(event) {
    let value = event.target.value;
    setVendorName(value);
  }

  function addVendorClicked() {
    // setTask("Create")
    setVendorModal(true);
    // setVendorDetailForm(true)  //show this after successful person or org search
  }

  function editVendorClicked(item) {
    setType(item.type);
    setTask("Edit");
    setSelectedVendor(item);
    setExpenseCategories(item.expenseCategories);
    setVendorDetailForm(true);
  }

  function handlePersonOrgClose() {
    setVendorModal(false);
  }

  function handlePersonOrgSelect(result) {
    setVendorModal(false);
    setTask("Create");
    setVendorDetailForm(true);
    fetchSelection(result.id);
  }

    function fetchSelection(vendorId) {
        if (isNullUndefined(vendorId) || isEmptyString(vendorId)) {
            issueWarnMessage(
                "could not determine person id, this is required to fetch vendor data"
            );
            return;
        }

        fetchVendor(vendorId).then((data) => {
            setSelectedVendor(data);
            setType(data.type);
            setExpenseCategories(data.expenseCategories);
        });
    }

    function validateForm(sendData) {
        if (
            isNullUndefined(sendData.expenseCategories) ||
            sendData.expenseCategories.length === 0
        ) {
            issueWarnMessage(
                "You must add at least one Category and Expense account for this vendor"
            );
            return false;
        }
        //make sure that the expense categories have values selected
        let message = null;
        for (let i = 0; i < sendData.expenseCategories.length; i++) {
            if (isNullUndefined(sendData.expenseCategories[i].category.code)) {
                message = "You must select a category for each item";
                break;
            }
            if (isNullUndefined(sendData.expenseCategories[i].expenseAccount.id)) {
                message = "You must select an expense account for each item";
                break;
            }
        }
        if (!isNullUndefined(message)) {
            issueWarnMessage(message);
            return false;
        }

        return true;
    }

    function saveVendorClicked() {
        let obj = {};
        if (selectedVendor !== undefined) {
            obj = selectedVendor;
        }
        obj.expenseCategories = expenseCategories;
        setSelectedVendor(obj);
        let sendData = selectedVendor;

        if (validateForm(sendData)) {
            saveVendor(sendData).then((response) => {
                loadVendors(
                    vendorName,
                    paginationModel.page | 0,
                    paginationModel.pageSize | 50
                ).then((data) => setVendorList(data.sort(sortJSONArray("name", true))));
                totalVendors(vendorName).then((data) =>
                    setTotalVendors(data.totalVendors)
                );
                issueResponseMessage(response);
                handleCancelClicked();
            });
        }
    }

    function showAddNewPersonForm() {
        // setAddPersonModal(true)
        let data = {
            id: null,
            firstName: "",
            middleName: null,
            lastName: "",
            mobilePhone: "",
            officePhone: null,
            email: null,
            idNumber: "",
            idTypeCd: null,
            categoryNames: null,
            type: "Individual",
            expenseCategories: [
                {
                    category: {code: null},
                    expenseAccount: {id: null},
                },
            ],
        };
        setVendorModal(false);
        setSelectedVendor(data);
        setVendorDetailForm(true);
        setTask("Create");
        setType("Individual");
    }

    function showAddNewOrgForm() {
        // setAddOrganizationModal(true)
        let data = {
            id: null,
            name: "",
            description: "",
            statusCd: "",
            addressId: "",
            dateCreated: null,
            taxId: "",
            address: {
                id: null,
                address1: "",
                address2: null,
                city: "",
                countryCd: "",
            },
            contact: {
                id: null,
                firstName: null,
                middleName: null,
                lastName: null,
                mobilePhone: null,
                officePhone: null,
                email: null,
                idNumber: null,
                idTypeCd: null,
            },
            expenseAccountNames: null,
            categoryNames: null,
            type: "Company",
            expenseCategories: [
                {
                    category: {code: null},
                    expenseAccount: {id: null},
                },
            ],
        };
        setVendorModal(false);
        setSelectedVendor(data);
        setVendorDetailForm(true);
        setTask("Create");
        setType("Company");
    }

    function handleAddUserClose() {
        setAddPersonModal(false);
    }

    function handleAddOrgClose() {
        setAddOrganizationModal(false);
    }

    const columns = [
        {headerName: "Name", field: "name", minWidth: 350, flex: 1.75},
        {headerName: "Type", field: "type", minWidth: 200, flex: 1},
        {
            headerName: "Vendor Categories",
            field: "categoryNames",
            minWidth: 300,
            flex: 1.5,
        },
        {
            headerName: "Expense Account(s) - Sub Category",
            field: "expenseAccountNames",
            minWidth: 450,
            flex: 2.25,
        },
        {
            headerName: "Actions",
            field: "actions",
            width: 100,
            fleX: 0.5,
            type: "actions",
            getActions: (params) => {
                let arr = [];
                arr.push(
                    <GridActionsCellItem
                        icon={<Edit/>}
                        onClick={() => editVendorClicked(params.row)}
                        label="Edit Vendor"
                        showInMenu
                    />
                );

                if (params.row.type === "Company") {
                    arr.push(
                        <GridActionsCellItem
                            icon={<Contacts/>}
                            onClick={() => handleEditCompanyContactClicked(params.row)}
                            label="Edit Company Contact"
                            showInMenu
                            disabled={!!isEmptyObject(params.row.contact)}
                        />
                    );
                }

        arr.push(
          <GridActionsCellItem
            icon={<Delete />}
            onClick={() => handleDeleteVendorClicked(params.row)}
            label="Delete"
            showInMenu
          />
        );
        return arr;
      },
    },
  ];

    function categoryChanged(event, index) {
        let value = event.target.value;
        let itemsArray = [...expenseCategories]; // copying the old items array
        itemsArray[index].category.code = value;
        setExpenseCategories(itemsArray);
        // let tmp = selectedVendor
        // tmp.expenseCategories = itemsArray
        // setSelectedVendor(tmp)
    }

    function expenseAccountChanged(event, index) {
        let value = event.target.value;
        let itemsArray = [...expenseCategories]; // copying the old items array
        itemsArray[index].expenseAccount.id = value;
        setExpenseCategories(itemsArray);
        // let tmp = selectedVendor
        // tmp.expenseCategories = itemsArray
        // setSelectedVendor(tmp)
    }

    function handleAddItems() {
        let item = {
            category: {code: null},
            expenseAccount: {id: null},
        };
        setExpenseCategories((expenseCategories) => [...expenseCategories, item]);
    }

    function handleRemoveItem(index) {
        let itemsArray = [...expenseCategories]; // copying the old items array
        itemsArray.splice(index, 1);
        setExpenseCategories(itemsArray);
    }

    let categoryAccountItems = null;

    expenseCategories &&
    expenseCategories.length > 0 &&
    (categoryAccountItems = expenseCategories.map(function (
        categoryAccountItem,
        i
    ) {
        return (
            <TableRow key={i}>
                <TableCell>
                    <Select
                        sx={{width: "90%"}}
                        id={"creditAccount" + i}
                        value={
                            categoryAccountItem &&
                            categoryAccountItem.category &&
                            categoryAccountItem.category.code
                        }
                        onChange={(event) => categoryChanged(event, i)}
                    >
                        {vendorCategories && vendorCategories.length > 0 ? (
                            vendorCategories.map(function (item, i) {
                                let disabled = false
                                if(type === "Individual" && item.value === "TAX"){
                                    disabled = true
                                }
                                return (
                                    <MenuItem key={item.value}
                                              value={item.value}
                                              disabled={disabled}
                                    >
                                        {item.label}
                                    </MenuItem>
                                );
                            }, this)
                        ) : (
                            <MenuItem sx={{width: "100%"}}>No Results Found</MenuItem>
                        )}
                    </Select>
                </TableCell>

                <TableCell>
                    <Select
                        sx={{width: "90%"}}
                        id={"creditAccount" + i}
                        value={
                            categoryAccountItem &&
                            categoryAccountItem.expenseAccount &&
                            categoryAccountItem.expenseAccount.id
                        }
                        onChange={(event) => expenseAccountChanged(event, i)}
                    >
                        {expenseAccountsList && expenseAccountsList.length > 0 ? (
                            expenseAccountsList.map(function (item, i) {
                                return (
                                    <MenuItem key={item.value} value={item.value}>
                                        {item.label}
                                    </MenuItem>
                                );
                            }, this)
                        ) : (
                            <MenuItem sx={{width: "100%"}}>No Results Found</MenuItem>
                        )}
                    </Select>
                </TableCell>

                <TableCell>
                    <Button
                        variant="contained"
                        type="submit"
                        sx={{marginLeft: "10px"}}
                        onClick={() => handleRemoveItem(i)}
                        color="error"
                    >
                        <Cancel/>
                    </Button>
                </TableCell>
            </TableRow>
        );
    }));

    function handleCancelClicked() {
        setTask("");
        setSelectedVendor(null);
        setExpenseCategories([]);
        setFirstName("");
        setMiddleName("");
        setLastName("");
        setIdType("");
        setIdNumber("");
        setMobilePhone("");
        setOfficePhone("");
        setEmail("");
        setVendorDetailForm(false);
        setType("");
    }

    // Shared Component for expense category items.

    function handlePersonSelect(result) {
        let obj = {};
        if (selectedVendor) {
            obj = selectedVendor;
        }
        obj.contact = result;
        setSelectedVendor(obj);
        setPersonSearchModal(false);
    }

    function handleCreateUserClicked(result) {
        let obj = selectedVendor;
        obj.contact = result;
        obj.contact.name = result.firstName + "" + result.lastName;
        setSelectedVendor(obj);
        setAddPersonModal(false);
    }

    useEffect(() => {
        getIdTypes().then((idTypes) => setIdTypes(idTypes));
        getUserRoles().then((userRoles) => setUserRoles(userRoles));
    }, []);
    return (
        <Permit
            roles="BLDIR,BLDMG,CS"
            services="PREMIUM,HOA,MAINT"
            noPermittedServiceMessage={{
                title: "Access Denied",
                body: (
                    <>
                        Your current role and/or facility is not permitted to view this
                        page. <br/> Please contact your system admin if you feel this is an
                        error.
                    </>
                ),
            }}
        >
            <MainContainer>
                <TopBarContainer
                    container
                    sx={{
                        width: {xs: "100%", md: "80%"},
                        boxShadow: "0 4px 5px 0 rgba(0,0,0,0.5)",
                        padding: "20px auto",
                    }}
                >
                    <ResponsiveRow
                        sx={{
                            justifyContent: {md: "space-between", xs: "center"},
                            width: {xs: "100%", lg: "50%"},
                            padding: 0,
                        }}
                    >
                        <FormControl
                            fullWidth
                            sx={{
                                width: {xs: "100%", lg: "50%"},
                            }}
                        >
                            <InputLabel sx={{fontSize: ".9rem"}} id="vendorsLabel">
                                Search vendor's name
                            </InputLabel>
                            <OutlinedInput
                                id="vendorNameSearch"
                                value={vendorName}
                                label={`Search vendors name`}
                                sx={{width: "100%"}}
                                onChange={(event) => searchVendorName(event)}

                            />
                        </FormControl>
                    </ResponsiveRow>

          <ResponsiveRow
            sx={{
              justifyContent: { xs: "center", lg: "flex-end" },
              width: { lg: "50%", xs: "100%" },
              padding: 0,
            }}
          >
            <TopBarContainedBrandButton
              sx={{ width: { xs: "100%", lg: "200px" }, height: "60px" }}
              onClick={() => setBulkUploadModal(true)}
            >
              Bulk Upload Vendors
            </TopBarContainedBrandButton>
            <TopBarContainedBrandButton
              sx={{ width: { xs: "100%", lg: "200px" }, height: "60px" }}
              onClick={addVendorClicked}
            >
              Add Vendor
            </TopBarContainedBrandButton>
          </ResponsiveRow>
        </TopBarContainer>
        <br />
        <br />

                <MuiDataGrid
                    noSelection={true}
                    dataGridColumns={columns}
                    dataGridRows={vendorList}
                    handlePageLoad={setPaginationModel}
          serverPagination={true}
                    loading={isLoading}
                    height="60vh"
                    totalRows={total}
                />

                {/*PERSON/ORG SEARCH MODAL*/}
                <PersonOrgSearch
                    contractType={'vendor'}
                    showPersonOrgModal={vendorModal}
                    handlePersonOrgClose={handlePersonOrgClose}
                    handlePersonOrgSelect={handlePersonOrgSelect}
                    showAddNewUserForm={showAddNewPersonForm}
                    showAddNewOrgForm={showAddNewOrgForm}
                />

                {/*ADD NEW USER MODAL*/}
                <AddNewUser
                    openModal={addPersonModal}
                    closeModal={handleAddUserClose}
                    saveUser={
                        type === "Individual"
                            ? handleCreateUserClicked
                            : handlePersonOrgSelect
                    }
                    manageUsers={false}
                />

                {/*ADD NEW ORGANISATION MODAL*/}
                <AddNewOrganisation
                    openModal={addOrganizationModal}
                    closeModal={handleAddOrgClose}
                />

                {vendorDetailForm &&
                    (task === "Create" || task === "Edit") &&
                    type === "Individual" && (
                        <AddIndividualVendorModal
                            vendorDetailForm={vendorDetailForm}
                            saveVendorClicked={saveVendorClicked}
                            task={task}
                            handleAddItems={handleAddItems}
                            handleCancelClicked={handleCancelClicked}
                            setEmail={setEmail}
                            setFirstName={setFirstName}
                            setIdType={setIdType}
                            setIdNumber={setIdNumber}
                            setLastName={setLastName}
                            setMiddleName={setMiddleName}
                            setMobilePhone={setMobilePhone}
                            setOfficePhone={setOfficePhone}
                            setSelectedVendor={setSelectedVendor}
                            categoryAccountItems={categoryAccountItems}
                            email={email}
                            firstName={firstName}
                            idType={idType}
                            idTypeOptions={idTypeOptions}
                            idNumber={idNumber}
                            lastName={lastName}
                            middleName={middleName}
                            mobilePhone={mobilePhone}
                            officePhone={officePhone}
                            selectedVendor={selectedVendor}
                        />
                    )}

                {vendorDetailForm &&
                    (task === "Create" || task === "Edit") &&
                    type === "Company" && (
                        <AddCompanyVendorModal
                            setSelectedVendor={setSelectedVendor}
                            selectedVendor={selectedVendor}
                            vendorDetailForm={vendorDetailForm}
                            saveVendorClicked={saveVendorClicked}
                            handleCancelClicked={handleCancelClicked}
                            handleAddItems={handleAddItems}
                            setPersonSearchModal={setPersonSearchModal}
                            categoryAccountItems={categoryAccountItems}
                            countriesList={countriesList}
                        />
                    )}

                <ChangeVendorContactPerson
                    openModal={personSearchModal}
                    handlePersonSelect={handlePersonSelect}
                    setAddPersonModal={setAddPersonModal}
                    setPersonSearchModal={setPersonSearchModal}
                    setSelectedVendor={setSelectedVendor}
                />

                <EditUserModal
                    showAddNewUserForm={editCompanyContactModal}
                    handleAddUserClose={() => {
                        setEditCompanyContactModal(false);
                        setSelectedCompanyContact();
                    }}
                    selectedUser={selectedCompanyContact}
                    setSelectedUser={(userUpdate) =>
                        setSelectedCompanyContact(userUpdate)
                    }
                    handleSaveUserClicked={handleSaveCompanyContactClicked}
                    idTypes={idTypes}
                    userRoles={userRoles}
                    isCompanyContact={true}
                />

                <ConfirmationModal
                    showConfirmationModal={deleteVendorModal}
                    handleConfirmationClose={handleCloseDeleteVendorModal}
                    title={titleText}
                    severity={"error"}
                    body={bodyText}
                    positiveText={positiveText}
                    negativeText={negativeText}
                    positiveAction={positiveAction}
                    negativeAction={handleCloseDeleteVendorModal}
                />

                {bulkUploadModal && (
                    <BulkUploadVendorsModal
                        showVendorBulkUploadModal={bulkUploadModal}
                        bulkFile={bulkFile}
                        bulkFileProgress={bulkFileProgress}
                        fileValueChanged={fileValueChanged}
                        handleCancelClicked={bulkUploadCancelClicked}
                        handleSubmitClicked={handleSubmitBulkUploadFile}
                    />
                )}
            </MainContainer>
        </Permit>
    );
}
