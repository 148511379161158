import {
    DetailsColumn,
    DetailsContainer,
    DetailsLabel,
    DetailsRow,
    DetailsText,
} from "../../../../constants/component.constants"
import ModalContainerModule from "../../../modules/modalContainer"

export default function ViewReminderModal(props) {
    /**
     *
     * REQUIRED PROPS:
     *
     * handleClose: func()
     * open: boolean
     *  notificationData: {
     *    date: string
     *    description: string
     *    type: string
     *  }
     */

    return (
        <ModalContainerModule
            size="specialViewOrganizationNotification"
            cancel={props.handleClose}
            openModal={props.open}
            cancelText="Close"
            modalTitle="View Organization Notification"
        >
            <DetailsContainer sx={{width: "100%", height: "100%", margin: 0}}>
                <DetailsColumn sx={{width: "100%", height: "100%", margin: 0}}>
                    <DetailsRow>
                        <DetailsLabel>Type:</DetailsLabel>
                        <DetailsText>
                            {props.notificationData ? props.notificationData.type : "--"}
                        </DetailsText>
                    </DetailsRow>
                    <DetailsRow>
                        <DetailsLabel>Message:</DetailsLabel>
                        <DetailsText>
                            {props.notificationData
                                ? props.notificationData.description
                                : "--"}
                        </DetailsText>
                    </DetailsRow>
                    <DetailsRow>
                        <DetailsLabel>Date:</DetailsLabel>
                        <DetailsText>
                            {props.notificationData ? props.notificationData.date : "--"}
                        </DetailsText>
                    </DetailsRow>
                </DetailsColumn>
            </DetailsContainer>
        </ModalContainerModule>
    )
}
