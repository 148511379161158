import {isEmptyString, isNullUndefined, isValidDate} from "../util/util"
import {EXPORT_DEBIT_NOTES_URL, GET_TOTAL_DEBIT_NOTES_URL, LIST_DEBIT_NOTES_URL} from "../constants/url.constants"
import {serviceAPI} from "./service"
import {handleErrorResponse} from "../actions/message"

export const listDebitNotes = (referenceNo, dateCreatedFrom, dateCreatedTo, dateApprovedFrom, dateApprovedTo, status, chargedTo, pageNo, rowsPerPage) => {
    let dateCreatedFromValue = ''
    let dateCreatedToValue = ''
    let dateApprovedFromValue = ''
    let dateApprovedToValue = ''

    if (!isNullUndefined(dateCreatedFrom) && isValidDate(dateCreatedFrom) && (!isNullUndefined(dateCreatedTo) || !isEmptyString(dateCreatedTo))) {
        let date = new Date(dateCreatedFrom)
        dateCreatedFromValue = date.toISOString()
    }
    if (!isNullUndefined(dateCreatedTo) && isValidDate(dateCreatedTo) && (!isNullUndefined(dateCreatedFrom) || !isEmptyString(dateCreatedFrom))) {
        let date = new Date(dateCreatedTo)
        dateCreatedToValue = date.toISOString()
    }
    if (!isNullUndefined(dateApprovedFrom) && isValidDate(dateApprovedFrom) && (!isNullUndefined(dateApprovedTo) || !isEmptyString(dateApprovedTo))) {
        let date = new Date(dateApprovedFrom)
        dateApprovedFromValue = date.toISOString()
    }
    if (!isNullUndefined(dateApprovedTo) && isValidDate(dateApprovedTo) && (!isNullUndefined(dateApprovedFrom) || !isEmptyString(dateApprovedFrom))) {
        let date = new Date(dateApprovedTo)
        dateApprovedToValue = date.toISOString()
    }
    let url = LIST_DEBIT_NOTES_URL + "?referenceNo=" + referenceNo + "&dateCreatedFrom=" + dateCreatedFromValue + "&dateCreatedTo=" + dateCreatedToValue + "&dateApprovedFrom=" + dateApprovedFromValue + "&dateApprovedTo=" + dateApprovedToValue + "&status=" + status + "&chargeTo=" + chargedTo + "&pageNo=" + pageNo + "&rowsPerPage=" + rowsPerPage
    return serviceAPI
        .get(url)
        .then((response) => {
            return response.data
        })
        .catch(error => {
            handleErrorResponse(error)
        })
}

export const getTotalDebitNotes = (referenceNo, dateCreatedFrom, dateCreatedTo, dateApprovedFrom, dateApprovedTo, status, chargedTo) => {
    let dateCreatedFromValue = ''
    let dateCreatedToValue = ''
    let dateApprovedFromValue = ''
    let dateApprovedToValue = ''

    if (!isNullUndefined(dateCreatedFrom) && isValidDate(dateCreatedFrom) && (!isNullUndefined(dateCreatedTo) || !isEmptyString(dateCreatedTo))) {
        let date = new Date(dateCreatedFrom)
        dateCreatedFromValue = date.toISOString()
    }
    if (!isNullUndefined(dateCreatedTo) && isValidDate(dateCreatedTo) && (!isNullUndefined(dateCreatedFrom) || !isEmptyString(dateCreatedFrom))) {
        let date = new Date(dateCreatedTo)
        dateCreatedToValue = date.toISOString()
    }
    if (!isNullUndefined(dateApprovedFrom) && isValidDate(dateApprovedFrom) && (!isNullUndefined(dateApprovedTo) || !isEmptyString(dateApprovedTo))) {
        let date = new Date(dateApprovedFrom)
        dateApprovedFromValue = date.toISOString()
    }
    if (!isNullUndefined(dateApprovedTo) && isValidDate(dateApprovedTo) && (!isNullUndefined(dateApprovedFrom) || !isEmptyString(dateApprovedFrom))) {
        let date = new Date(dateApprovedTo)
        dateApprovedToValue = date.toISOString()
    }
    let url = GET_TOTAL_DEBIT_NOTES_URL + "?referenceNo=" + referenceNo + "&dateCreatedFrom=" + dateCreatedFromValue + "&dateCreatedTo=" + dateCreatedToValue + "&dateApprovedFrom=" + dateApprovedFromValue + "&dateApprovedTo=" + dateApprovedToValue + "&status=" + status + "&chargeTo=" + chargedTo
    return serviceAPI
        .get(url)
        .then((response) => {
            return response.data
        })
        .catch(error => {
            handleErrorResponse(error)
        })
}

export const exportDebitNotes = (data) => {
    return serviceAPI
        .post(EXPORT_DEBIT_NOTES_URL, data)
        .then((response) => {
            return response.data
        })
        .catch(error => {
            handleErrorResponse(error)
        })
}