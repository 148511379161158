import {Grid, MenuItem, TextField, Typography} from "@mui/material"
import {ModalAcceptButton, ModalCancelButton,} from "../../../constants/component.constants"
import {ModalContainer, ModalInnerContainer, ResponsiveRow,} from "../../../constants/layout.constants"
import {useEffect, useState} from "react";
import { DateTimePicker} from "@mui/x-date-pickers";
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider"
import {adjustForTimezone, isEmptyString, isNullUndefined} from "../../../util/util";
import {setMessage} from "../../../actions/message";
import {useDispatch, useSelector} from "react-redux";
import {saveAppointment} from "../../../services/listingsServices/listing.service";
import {getIdTypes} from "../../../services/list.service";
import dayjs from "dayjs";
import {fetchPerson} from "../../../services/workOrder.service";

export default function ListingBookingModal(props) {
    const user = useSelector((state) => state.auth.user);
    const [firstName, setFirstName] = useState("")
    const [middleName, setMiddleName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [phoneNo, setPhoneNo] = useState("")
    const [appointmentDate, setAppointmentDate] = useState(null)
    const dispatch = useDispatch()
    const [idNo, setIdNo] = useState("")
    const [idTypes, setIdTypes] = useState([])
    const [idType, setIdType] = useState("")
    const [openModal, setOpenModal] = useState(false)

    useEffect(() => {
        fetchUser()
        getIdTypes()
            .then((data) => setIdTypes(data))
        setOpenModal(true)
    }, [])

    const handleDateChange = (value) => {
        let date = adjustForTimezone(new Date(value))
        setAppointmentDate(date)
    };

    const validateFormData = () => {
        if (isEmptyString(firstName)) {
            dispatch(
                setMessage({
                    type: "warning",
                    message: "First Name is required.",
                })
            )
            return false
        }

        if (isEmptyString(lastName)) {
            dispatch(
                setMessage({
                    type: "warning",
                    message: "Last Name is required.",
                })
            )
            return false
        }

        if (isEmptyString(email)) {
            dispatch(
                setMessage({
                    type: "warning",
                    message: "Email is required.",
                })
            )
            return false
        }

        if (isEmptyString(phoneNo)) {
            dispatch(
                setMessage({
                    type: "warning",
                    message: "Phone Number is required.",
                })
            )
            return false
        }

        if (isEmptyString(appointmentDate)) {
            dispatch(
                setMessage({
                    type: "warning",
                    message: "Appointment date is required.",
                })
            )
            return false
        }

        return true
    }

    const fetchUser = () => {
        if (!isNullUndefined(user)) {
            fetchPerson(user.userId)
                .then((response) => {
                    setFirstName(response?.firstName)
                    setMiddleName(response?.middleName)
                    setLastName(response?.lastName)
                    setEmail(response?.email)
                    setPhoneNo(response?.mobilePhone)
                    setIdType(response?.idTypeCd)
                    setIdNo(response?.idNumber)
                })
        }
    }

    const handleSubmit = () => {
        let sendData = {
            id: null,
            listingId: props.listing.id,
            firstName: firstName,
            middleName: middleName,
            lastName: lastName,
            email: email,
            phoneNumber: phoneNo,
            idNo: idNo,
            idType: idType,
            appointmentDate: appointmentDate.toISOString(true),
        }
        validateFormData() && saveAppointment(sendData)
            .then((response) => {
                props.refetchTourBooking()
                if(props.action === "edit") {
                    dispatch(
                        setMessage({
                            type: response.type,
                            message: "Booking updated successfully"
                        })
                    )
                }
                dispatch(
                    setMessage({
                        type: response.type,
                        message: response.message,
                    })
                )
                closeModal()
            })
    }

    const closeModal = () => {
        setAppointmentDate(null)
        setFirstName(null)
        setMiddleName(null)
        setLastName(null)
        setEmail(null)
        setPhoneNo(null)
        setIdType(null)
        setIdNo(null)
        setOpenModal(false)
        props.closeModal()
    };

    return (
        <ModalContainer open={openModal} onClose={closeModal}>
            <ModalInnerContainer
                sx={{
                    maxHeight: {xs: "50hv", lg: "70vh"},
                    width: {xs: "80%", lg: "70%"},
                }}
            >
                <Typography variant="body1" sx={{color: "#037171", fontWeight: 600}}>
                    Book Viewing Tour
                </Typography>
                <Typography
                    variant="h2"
                    sx={{marginBottom: "20px", marginTop: "10px", fontWeight: 900}}
                >
                    {props.listing && props.listing.propertyName}
                </Typography>

                <ResponsiveRow
                    sx={{
                        width: "100%",
                        justifyContent: {xs: "center", lg: "space-between"},
                        marginTop: "20px",
                    }}
                >
                    <Grid
                        item
                        sx={{width: {xs: "100%", lg: "33.3%"}, marginBottom: "10px"}}
                    >
                        <TextField
                            variant="outlined"
                            sx={{
                                width: {xs: "100%", lg: "90%"},
                                backgroundColor: "#efefef",
                                borderRadius: "10px",
                            }}
                            label="First Name"
                            value={firstName}
                            onChange={(event) => {
                                let value = event.target.value
                                setFirstName(value)
                            }}
                        />
                    </Grid>{" "}
                    <Grid
                        item
                        sx={{width: {xs: "100%", lg: "33.3%"}, marginBottom: "10px"}}
                    >
                        <TextField
                            variant="outlined"
                            sx={{
                                width: {xs: "100%", lg: "90%"},
                                backgroundColor: "#efefef",
                                borderRadius: "10px",
                            }}
                            label="Middle Name (Optional)"
                            value={middleName}
                            onChange={(event) => {
                                let value = event.target.value
                                setMiddleName(value)
                            }}
                        />
                    </Grid>{" "}
                    <Grid
                        item
                        sx={{width: {xs: "100%", lg: "33.3%"}, marginBottom: "10px"}}
                    >
                        <TextField
                            variant="outlined"
                            sx={{
                                width: {xs: "100%", lg: "90%"},
                                backgroundColor: "#efefef",
                                borderRadius: "10px",
                            }}
                            label="Last Name"
                            value={lastName}
                            onChange={(event) => {
                                let value = event.target.value
                                setLastName(value)
                            }}
                        />
                    </Grid>{" "}
                    <Grid
                        item
                        sx={{width: {xs: "100%", lg: "33.3%"}, marginBottom: "10px"}}
                    >
                        <TextField
                            variant="outlined"
                            sx={{
                                width: {xs: "100%", lg: "90%"},
                                backgroundColor: "#efefef",
                                borderRadius: "10px",
                            }}
                            label="Email"
                            type="email"
                            value={email}
                            onChange={(event) => {
                                let value = event.target.value
                                setEmail(value)
                            }}
                        />
                    </Grid>{" "}
                    <Grid
                        item
                        sx={{width: {xs: "100%", lg: "33.3%"}, marginBottom: "10px"}}
                    >
                        <TextField
                            variant="outlined"
                            sx={{
                                width: {xs: "100%", lg: "90%"},
                                backgroundColor: "#efefef",
                                borderRadius: "10px",
                            }}
                            label="Phone Number"
                            type="tel"
                            value={phoneNo}
                            onChange={(event) => {
                                let value = event.target.value
                                setPhoneNo(value)
                            }}
                        />
                    </Grid>{" "}
                    <Grid
                        item
                        sx={{width: {xs: "100%", lg: "33.3%"}, marginBottom: "10px"}}
                    ></Grid>
                    <Grid
                        item
                        sx={{width: {xs: "100%", lg: "33.3%"}, marginBottom: "10px"}}
                    >
                        <TextField
                            variant="outlined"
                            sx={{
                                width: {xs: "100%", lg: "90%"},
                                backgroundColor: "#efefef",
                                borderRadius: "10px",
                            }}
                            select
                            label="ID Type"
                            value={idType}
                            onChange={(event) => {
                                let value = event.target.value
                                setIdType(value)
                            }}
                        >
                            {idTypes && idTypes.length > 0 && idTypes.map(function (idType, i) {
                                return (
                                    <MenuItem key={i} value={idType.value}>
                                        {idType.label}
                                    </MenuItem>
                                )
                            }, this)}
                        </TextField>
                    </Grid>{" "}
                    <Grid
                        item
                        sx={{width: {xs: "100%", lg: "33.3%"}, marginBottom: "10px"}}
                    >
                        <TextField
                            variant="outlined"
                            sx={{
                                width: {xs: "100%", lg: "90%"},
                                backgroundColor: "#efefef",
                                borderRadius: "10px",
                            }}
                            label="ID Number"
                            value={idNo}
                            onChange={(event) => {
                                let value = event.target.value
                                setIdNo(value)
                            }}
                        />
                    </Grid>{" "}
                    <Grid
                        item
                        sx={{width: {xs: "100%", lg: "33.3%"}, marginBottom: "10px"}}
                    ></Grid>
                    <Grid
                        item
                        sx={{width: {xs: "100%", lg: "33.3%"}, marginBottom: "10px"}}
                    >
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                            <DateTimePicker
                                sx={{width: "90%"}}
                                id={"appointmentDate"}
                                value={dayjs(appointmentDate)}
                                onChange={(newDate) => handleDateChange(newDate)}

                            />
                        </LocalizationProvider>
                    </Grid>{" "}
                </ResponsiveRow>
                <ResponsiveRow
                    sx={{
                        width: "100%",
                        justifyContent: {xs: "center", lg: "flex-end"},
                    }}
                >
                    <ModalAcceptButton
                        onClick={() => handleSubmit()}
                        sx={{width: {xs: "100%", lg: "200px"}}}
                    >
                        Submit
                    </ModalAcceptButton>
                    <ModalCancelButton
                        onClick={() => closeModal()}
                        sx={{width: {xs: "100%", lg: "200px"}}}
                    >
                        Cancel
                    </ModalCancelButton>
                </ResponsiveRow>
            </ModalInnerContainer>
        </ModalContainer>
    )
}
