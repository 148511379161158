// import SmartKodiMap from "../../components/map"

import {ResponsiveRow} from "../../constants/layout.constants";

import VacantUnits from "../../components/modules/dashboard/vacantUnits";
import ExpiringLeases from "../../components/modules/dashboard/expiringLeases";

import IncomeVsExpenses from "../../components/modules/dashboard/incomeVsExpenses";
import AssetsIncomeVsExpenses from "../../components/modules/dashboard/assetsIncomeVsExpenses";
import UtilitiesIncomeVsExpenses from "../../components/modules/dashboard/utilitesIncomevsExpenses";
import UtilitiesReadVsUnread from "../../components/modules/dashboard/utilitesReadVsUnread";
import UtilitiesWorkingVsBroken from "../../components/modules/dashboard/utilitiesWorkingVsBroken";

import Permit from "../../util/permit";
import BudgetVsExpenses from "../../components/modules/dashboard/budgetVsExpenses";
import BalanceOverdueByDays from "../../components/modules/dashboard/balanceOverdueByDays";
import HomeOwnersDues from "../../components/modules/dashboard/homeOwnersDues";
import HomeOwnerPendingApproval from "../../components/modules/dashboard/owner/homeOwnerPendingApproval";
import {useEffect, useState} from "react";
import UpcomingAssetMaintenance from "../../components/modules/dashboard/upcomingAssetMaintenance";

export default function DashboardOwner() {
    const [homeOwnersPendingApprovalData, setHomeOwnersPendingApprovalData] =
        useState([]);
    useEffect(() => {
        return () => {
            setHomeOwnersPendingApprovalData([]);
        };
    }, []);

    return (
        <Permit
            roles="OWN"
            services="PREMIUM,CORE,HOA"
            noPermittedServiceMessage={{
                title: "Access Denied",
                body: (
                    <>
                        Your current role and/or facility is not permitted to view this
                        page. <br/> Please contact your system admin if you feel this is an
                        error.
                    </>
                ),
            }}
        >
            <ResponsiveRow
                sx={{flexDirection: "column", width: "100%", alignItems: "center"}}
            >
                <ResponsiveRow
                    sx={{
                        width: "100%",
                        height: "auto",
                        flexWrap: "wrap",
                        flexDirection: "row",

                        justifyContent: "space-evenly",
                    }}
                >
                    <ResponsiveRow
                        sx={{
                            width: "100%",
                            height: "auto",

                            flexDirection: {xs: "column", lg: "row"},
                            flexWrap: "nowrap",

                            justifyContent: {xs: "center", lg: "space-between"},
                            alignItems: "center",

                            padding: 0,
                        }}
                    >
                        <Permit services="PREMIUM,LEAN,CORE">
                            <VacantUnits customHeight="500px" customWidth="49%"/>
                        </Permit>{" "}
                        <Permit services="PREMIUM,CORE,HOA,LEAN,METER">
                            <BalanceOverdueByDays customHeight="500px" customWidth="49%"/>
                        </Permit>
                    </ResponsiveRow>
                    <ResponsiveRow
                        sx={{
                            width: "100%",
                            height: "auto",

                            flexDirection: {xs: "column", lg: "row"},
                            flexWrap: "nowrap",

                            justifyContent: {xs: "center", lg: "space-between"},
                            alignItems: "center",

                            padding: 0,
                        }}
                    >
                        <Permit services="PREMIUM,CORE,LEAN">
                            <ExpiringLeases customHeight="500px" customWidth="49%"/>
                        </Permit>
                        <Permit services="PREMIUM,CORE,HOA,LEAN,METER">
                            <IncomeVsExpenses customWidth="49%" customHeight="500px"/>
                        </Permit>
                    </ResponsiveRow>
                    <ResponsiveRow
                        sx={{
                            width: "100%",
                            height: "auto",

                            flexDirection: {xs: "column", lg: "row"},
                            flexWrap: "nowrap",

                            justifyContent: {xs: "center", lg: "space-between"},
                            alignItems: "center",

                            padding: 0,
                        }}
                    >
                        <Permit services="PREMIUM,CORE,LEAN,METER">
                            <AssetsIncomeVsExpenses customWidth="49%" customHeight="500px"/>
                        </Permit>{" "}
                        <Permit services="PREMIUM,CORE,LEAN,METER">
                            <UpcomingAssetMaintenance
                                customWidth="49%"
                                customHeight="500px"
                            />
                        </Permit>{" "}
                    </ResponsiveRow>
                    <ResponsiveRow
                        sx={{
                            width: "100%",
                            height: "auto",

                            flexDirection: {xs: "column", lg: "row"},
                            flexWrap: "nowrap",

                            justifyContent: {xs: "center", lg: "space-between"},
                            alignItems: "center",

                            padding: 0,
                        }}
                    >
                        <Permit services="PREMIUM,CORE,LEAN,METER">
                            <UtilitiesIncomeVsExpenses
                                customWidth="49%"
                                customHeight="500px"
                            />
                        </Permit>
                        <Permit services="PREMIUM,CORE,LEAN,METER">
                            <UtilitiesReadVsUnread customWidth="49%" customHeight="500px"/>
                        </Permit>
                    </ResponsiveRow>
                    <ResponsiveRow
                        sx={{
                            width: "100%",
                            height: "auto",

                            flexDirection: {xs: "column", lg: "row"},
                            flexWrap: "nowrap",

                            justifyContent: {xs: "center", lg: "space-between"},
                            alignItems: "center",

                            padding: 0,
                        }}
                    >
                        <Permit services="PREMIUM,CORE,LEAN,METER">
                            <UtilitiesWorkingVsBroken
                                customWidth="49%"
                                customHeight="500px"
                            />
                        </Permit>
                        <Permit services="PREMIUM,CORE,HOA">
                            <BudgetVsExpenses
                                customWidth="49%"
                                customHeight="500px"
                            />
                        </Permit>{" "}
                    </ResponsiveRow>
                    <ResponsiveRow
                        sx={{
                            width: "100%",
                            height: "auto",

                            flexDirection: {xs: "column", lg: "row"},
                            flexWrap: "nowrap",

                            justifyContent: {xs: "center", lg: "space-between"},
                            alignItems: "center",

                            padding: 0,
                        }}
                    >
                        <Permit services="HOA">
                            <HomeOwnerPendingApproval
                                customWidth="49%"
                                customHeight="500px"
                                data={homeOwnersPendingApprovalData}
                            />
                        </Permit>
                        <Permit services="HOA">
                            <HomeOwnersDues customWidth="49%" customHeight="500px"/>
                        </Permit>
                    </ResponsiveRow>
                </ResponsiveRow>
            </ResponsiveRow>
        </Permit>
    );
}
