import {
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import { ResponsiveRow } from "../../../../constants/layout.constants";
import ModalContainerModule from "../../../modules/modalContainer";
import VendorExpenseItems from "./vendorExpenseItems";
import { useState } from "react";

export default function AddIndividualVendorModal(props) {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [idType, setIdType] = useState("");

  const [idNumber, setIdNumber] = useState("");
  const [lastName, setMiddleName] = useState("");
  const [middleName, setLastName] = useState("");
  const [mobilePhone, setMobilePhone] = useState("");
  const [officePhone, setOfficePhone] = useState("");

  const [formStage, setFormStage] = useState(1);

  /**
   *
   * REQUIRED PROPS
   *
   * vendorDetailForm
   *
   * saveVendorClicked
   * task
   *
   * handleAddItems
   * handleCancelClicked
   *
   *
   *
   *
   *
   *
   *
   *
   *
   * setSelectedVendor
   *
   * categoryAccountItems
   *
   *
   *
   *
   *
   *
   *
   *
   * officePhone
   * selectedVendor
   *
   */

  function toggleStage() {
    formStage === 1 ? setFormStage(2) : setFormStage(1);
  }

  return (
    <ModalContainerModule
      size="specialAddVendorDetails"
      accept={formStage === 1 ? toggleStage : props.saveVendorClicked}
      cancel={props.handleCancelClicked}
      cancel2={true}
      openModal={props.vendorDetailForm}
      modalTitle="Vendor's Details"
      acceptButtonText={
        formStage === 1 ? "Next" : props.task === "Create" ? "Save" : "Update"
      }
      cancel2Text={"Back"}
      handleCancel2={toggleStage}
      cancelDisabled2={formStage === 1 }
    >
      {formStage === 1 ? (
        <ResponsiveRow
          container
          sx={{
            width: "100%",
            height: "auto",

            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{ marginBottom: "5px", width: "100%" }}
            id="vendors"
            variant="h6"
            component="h6"
            Expense
            Categories
          >
            Vendor Information
          </Typography>
          <FormControl
            item
            sx={{
              width: { xs: "100%", lg: "33.3%" },
              marginBottom: "10px",
            }}
          >
            <InputLabel>First Name *</InputLabel>
            <OutlinedInput
              label={"First Name"}
              sx={{ width: "90%" }}
              id={"firstName"}
              onChange={(event) => {
                let value = event.target.value;
                setFirstName(value);
                let obj = props.selectedVendor;
                obj.firstName = value;
                props.setSelectedVendor(obj);
              }}
              value={
                props.selectedVendor
                  ? props.selectedVendor.firstName
                  : firstName
              }
            />
          </FormControl>

          <FormControl
            item
            sx={{
              width: { xs: "100%", lg: "33.3%" },
              marginBottom: "10px",
            }}
          >
            <InputLabel>Middle Name *</InputLabel>
            <OutlinedInput
              label={"Middle Name"}
              sx={{ width: "90%" }}
              id={"middleName"}
              onChange={(event) => {
                let value = event.target.value;
                setMiddleName(value);
                let obj = props.selectedVendor;
                obj.middleName = value;
                props.setSelectedVendor(obj);
              }}
              value={
                props.selectedVendor
                  ? props.selectedVendor.middleName
                  : middleName
              }
            />
          </FormControl>

          <FormControl
            item
            sx={{
              width: { xs: "100%", lg: "33.3%" },
              marginBottom: "10px",
            }}
          >
            <InputLabel>Last Name *</InputLabel>
            <OutlinedInput
              label={"Last Name"}
              sx={{ width: "90%" }}
              id={"lastName"}
              onChange={(event) => {
                let value = event.target.value;
                setLastName(value);
                let obj = props.selectedVendor;
                obj.lastName = value;
                props.setSelectedVendor(obj);
              }}
              value={
                props.selectedVendor ? props.selectedVendor.lastName : lastName
              }
            />
          </FormControl>

          <FormControl
            item
            sx={{
              width: { xs: "100%", lg: "33.3%" },
              marginBottom: "10px",
            }}
          >
            <InputLabel>Mobile Phone * (Primary Number)</InputLabel>
            <OutlinedInput
              label={"Mobile Phone * (Primary Number)"}
              sx={{ width: "90%" }}
              id={"mobilePhone"}
              onChange={(event) => {
                let value = event.target.value;
                setMobilePhone(value);
                let obj = props.selectedVendor;
                obj.mobilePhone = value;
                props.setSelectedVendor(obj);
              }}
              value={
                props.selectedVendor
                  ? props.selectedVendor.mobilePhone
                  : mobilePhone
              }
            />
          </FormControl>

          <FormControl
            sx={{
              width: { xs: "100%", lg: "33.3%" },
              marginBottom: "10px",
            }}
          >
            <InputLabel>Office Phone</InputLabel>
            <OutlinedInput
              label={"Office Phone"}
              sx={{ width: "90%" }}
              id={"officePhone"}
              onChange={(event) => {
                let value = event.target.value;
                setOfficePhone(value);
                let obj = props.selectedVendor;
                obj.officePhone = value;
                props.setSelectedVendor(obj);
              }}
              value={
                props.selectedVendor
                  ? props.selectedVendor.officePhone
                  : officePhone
              }
            />
          </FormControl>

          <FormControl
            sx={{
              width: { xs: "100%", lg: "33.3%" },
              marginBottom: "10px",
            }}
          >
            <InputLabel>Email *</InputLabel>
            <OutlinedInput
              label={"Email"}
              sx={{ width: "90%" }}
              id={"email"}
              onChange={(event) => {
                let value = event.target.value;
                setEmail(value);
                let obj = props.selectedVendor;
                obj.email = value;
                props.setSelectedVendor(obj);
              }}
              value={props.selectedVendor ? props.selectedVendor.email : email}
            />
          </FormControl>

          <FormControl
            item
            sx={{
              width: { xs: "100%", lg: "33.3%" },
              marginBottom: "10px",
            }}
          >
            <InputLabel>ID Type</InputLabel>
            <Select
              label="ID Type"
              sx={{ width: "90%" }}
              id={"idTypeSearch"}
              value={
                props.selectedVendor
                  ? props.selectedVendor.idType || props.selectedVendor.idTypeCd
                  : idType
              }
              onChange={(event) => {
                let value = event.target.value;
                setIdType(value);
                let obj = props.selectedVendor;
                obj.idType = value;
                obj.idTypeCd = value;
                props.setSelectedVendor(obj);
              }}
            >
              {props.idTypeOptions && props.idTypeOptions.length > 0 ? (
                props.idTypeOptions.map(function (idType, i) {
                  return (
                    <MenuItem key={i} value={idType.value}>
                      {idType.label}
                    </MenuItem>
                  );
                }, this)
              ) : (
                <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
              )}
            </Select>
          </FormControl>

          <FormControl
            item
            sx={{
              width: { xs: "100%", lg: "33.3%" },
              marginBottom: "10px",
            }}
          >
            <InputLabel>ID Number *</InputLabel>
            <OutlinedInput
              label={"ID Number"}
              sx={{ width: "90%" }}
              id={"idNumber"}
              onChange={(event) => {
                let value = event.target.value;
                setIdNumber(value);
                let obj = props.selectedVendor;
                obj.idNumber = value;
                props.setSelectedVendor(obj);
              }}
              value={
                props.selectedVendor ? props.selectedVendor.idNumber : idNumber
              }
            />
          </FormControl>
        </ResponsiveRow>
      ) : (
        <VendorExpenseItems
          categoryAccountItems={props.categoryAccountItems}
          handleAddItems={props.handleAddItems}
        />
      )}
    </ModalContainerModule>
  );
}
