import {Typography} from "@mui/material"
import {useEffect, useState} from "react"

import FeaturedListing from "../../../components/cards/listings/featuredListing"
import {ResponsiveRow} from "../../../constants/layout.constants"
import {getListingsSecure, getPersonFavoritesList} from "../../../services/listingsServices/listing.service"
import ListingView from "../listingView"

export default function ListingFavourites() {
    const [listings, setListings] = useState([])
    const [favListings, setFavListings] = useState([])
    const [selectedListing, setSelectedListing] = useState(null)
    const [task, setTask] = useState("")
    const [filterByPropertyName] = useState("")
    const [selectedPropertyType] = useState("")
    const [selectedListingType] = useState("")
    const [noOfBeds] = useState("")
    const [noOfBaths] = useState("")
    const [sqftMin] = useState("")
    const [sqftMax] = useState("")
    const [priceMin] = useState("")
    const [priceMax] = useState("")
    const [pageNo] = useState(0)
    
    useEffect(() => {
        fetchFavListings()
        getListingsSecure(
            selectedPropertyType,
            selectedListingType,
            filterByPropertyName,
            noOfBeds,
            noOfBaths,
            sqftMin,
            sqftMax,
            priceMin,
            priceMax,
            pageNo,
            12
        )
            .then((data) => setListings(data))
    }, [selectedPropertyType,
        selectedListingType,
        filterByPropertyName,
        noOfBeds,
        noOfBaths,
        sqftMin,
        sqftMax,
        priceMin,
        priceMax,
        pageNo])

    function fetchFavListings() {
        getPersonFavoritesList()
            .then((data) => {
                setFavListings(data)
            })
    }

    function viewClicked(item) {
        setSelectedListing(item)
        setTask("View")
    }

    function backClicked() {
        setTask("")
        setSelectedListing(null)
    }

    return (
        <ResponsiveRow
            sx={{flexDirection: "column", width: "100%", alignItems: "center"}}
        >
            {task === "" &&
                <>
                    <ResponsiveRow
                        sx={{
                            width: {xs: "100%", lg: "80%"},
                            marginTop: "100px",
                            padding: "10px auto",
                        }}
                    >
                        <Typography variant="h4" sx={{width: "100%", textAlign: "start"}}>
                            Favorite Listings
                        </Typography>
                    </ResponsiveRow>
                    <ResponsiveRow
                        sx={{
                            flexDirection: "row",
                            width: "80%",
                            justifyContent: "center",
                        }}
                    >
                        {favListings && favListings.length > 0 && favListings.map((item, i) => {
                            return (
                                <FeaturedListing
                                    key={i}
                                    listing={item}
                                    fetchFavListings={fetchFavListings}
                                    viewClicked={viewClicked}/>
                            )
                        })

                        }
                    </ResponsiveRow>
                </>
            }
            {task === "View" &&
                <ListingView
                    listing={selectedListing}
                    backClicked={backClicked}
                    listings={listings}
                />
            }
        </ResponsiveRow>
    )
}
