import {Box} from "@mui/system"
import {ResponsiveRow} from "../../../constants/layout.constants"
import {Typography} from "@mui/material"
import Location from "@mui/icons-material/LocationOn"
import Straighten from "@mui/icons-material/Straighten"
import Bed from "@mui/icons-material/AirlineSeatFlat"
import Bath from "@mui/icons-material/Bathtub"
import {ModalAcceptButton} from "../../../constants/component.constants"
import Favorite from "@mui/icons-material/Favorite"
import {isEmptyArray} from "../../../util/util"
import {saveFavoriteListing} from "../../../services/listingsServices/listing.service"
import {issueResponseMessage} from "../../../actions/message"
import {GET_LISTING_IMAGE_URL} from "../../../constants/url.constants";

export default function FeaturedListing(props) {

    const handleFavClicked = () => {

        let sendData = {
            listingId: props.listing.id,
        }

        /**
         * Params to pass to backend:
         * -listingId
         * -isFavorite(true/false)
         */
        saveFavoriteListing(sendData)
            .then((response) => {
                issueResponseMessage(response)
                props.fetchFavListings();
            })
    }

    return (
        <ResponsiveRow
            sx={{
                width: {xs: "100%", lg: "350px"},
                height: "575px",
                borderRadius: "20px",
                alignItems: "flex-start",
                backgroundColor: "#fff",
                padding: 0,
                margin: "10px 5px",
            }}
        >
            <Box
                component="img"
                src={
                    props.listing &&
                    props.listing.imageFiles &&
                    !isEmptyArray(props.listing.imageFiles)
                        ? GET_LISTING_IMAGE_URL + "?filename=" + (
                        props.listing &&
                        props.listing.imageFiles &&
                        props.listing.imageFiles[0] &&
                        props.listing.imageFiles[0].systemFileName
                    )
                        : ""
                }
                sx={{
                    width: "100%",
                    height: "55%",
                    objectFit: "cover",
                    borderRadius: "20px 20px 0 0",
                }}
            />
            <ResponsiveRow
                sx={{
                    flexDirection: "column",
                    flexWrap: "nowrap",
                    width: "100%",
                    height: "40%",
                    textAlign: "start",
                    padding: "10px 20px",
                }}
            >
                <ResponsiveRow
                    sx={{
                        alignItems: "center",
                        justifyContent: "space-between",
                        padding: 0,
                    }}
                >
                    <Typography
                        variant="body2"
                        sx={{color: "#afafaf", fontWeight: 600, marginBottom: "5px"}}
                    >
                        KES {props.listing.price}
                    </Typography>
                    <ResponsiveRow
                        sx={{
                            flexWrap: "nowrap",
                            width: "50%",
                            justifyContent: "flex-end",
                            alignItems: "center",
                        }}
                    >
                        <Favorite
                            sx={{margin: "auto 5px", color: "red", zIndex: 3}}
                            onClick={() => {
                                handleFavClicked()
                            }}
                        />
                        <Typography
                            variant="body2"
                            sx={{
                                color: "#fff",
                                backgroundColor: "#00B9AE",
                                padding: "5px 10px",
                                fontWeight: 600,
                                marginBottom: "5px",
                                marginLeft: 0,
                                borderRadius: "10px",
                            }}
                        >
                            {props.listing.listingType.name}
                        </Typography>
                    </ResponsiveRow>
                </ResponsiveRow>
                <Typography variant="h4" sx={{fontWeight: 700, marginBottom: "10px"}}>
                    {props.listing.propertyName}
                </Typography>
                <Typography
                    variant="body2"
                    sx={{marginBottom: "5px", color: "#00B9AE"}}
                >
                    <Location sx={{fontSize: "1.2rem"}}/> {props.listing.plusCode}
                </Typography>
                <Typography sx={{fontSize: "1rem", marginBottom: "10px"}}>
                    <Straighten sx={{fontSize: "1.2rem"}}/> {props.listing.sqft}
                </Typography>
                <ResponsiveRow
                    sx={{
                        flexWrap: "nowrap",
                        width: "80%",
                        padding: 0,
                    }}
                >
                    <Typography
                        variant="body1"
                        sx={{margin: "0 10px 0 0", fontSize: "1rem"}}
                    >
                        <Bed sx={{fontSize: "1.2rem"}}/> {props.listing.beds}
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{margin: "0 10px 0 0", fontSize: "1rem"}}
                    >
                        <Bath sx={{fontSize: "1.2rem"}}/> {props.listing.baths}
                    </Typography>
                    {/*<Typography*/}
                    {/*  variant="body1"*/}
                    {/*  sx={{ margin: "0 10px 0 0", fontSize: "1rem" }}*/}
                    {/*>*/}
                    {/*  <Car sx={{ fontSize: "1.2rem" }} /> 1*/}
                    {/*</Typography>*/}
                </ResponsiveRow>
                <ModalAcceptButton
                    onClick={() => props.viewClicked(props.listing)}
                    sx={{width: "100%", margin: "10px auto"}}
                >
                    View Listing
                </ModalAcceptButton>
            </ResponsiveRow>
        </ResponsiveRow>
    )
}
