/* eslint-disable react-hooks/exhaustive-deps */
import {
    Checkbox,
    FormControlLabel,
    Grid,
    IconButton,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";
import {useEffect, useState} from "react";
import {
    getIdRefNumber,
    isArray,
    isEmptyArray,
    isEmptyString,
    isNullUndefined,
    tryParseFloat,
    tryParseInt,
} from "../../util/util";
import {ResponsiveRow} from "../../constants/layout.constants";
import {ModalAcceptButton, ModalCancelButton,} from "../../constants/component.constants";
import {Cancel} from "@mui/icons-material";
import {getUnitTypes} from "../../services/property.service";
import {issueWarnMessage} from "../../actions/message";

export default function SignOnUnitDetails(props) {
    const [units, setUnits] = useState([]);
    const [unitStatuses, setUnitStatuses] = useState([]);
    const [utilities, setUtilities] = useState([]);
    const [leases, setLeases] = useState([]);
    const [ownerships, setOwnerships] = useState([]);
    const [meterBillings, setMeterBillings] = useState([]);
    const [unitTypeOptions, setUnitTypeOptions] = useState([]);
    const [unitsRows, setUnitsRows] = useState("");
    const [facilityId, setFacilityId] = useState("");
    const [addUnitType, setAddUnitType] = useState(false);
    const [unitType, setUnitType] = useState("");
    const [addSquareFeet, setAddSquareFeet] = useState(false);
    const [squareFeet, setSquareFeet] = useState("");
    const [addRentPerSquareFoot, setAddRentPerSquareFoot] = useState(false);
    const [rentPerSquareFoot, setRentPerSquareFoot] = useState("");
    const [addBaths, setAddBaths] = useState(false);
    const [baths, setBaths] = useState("");
    const [addBeds, setAddBeds] = useState(false);
    const [beds, setBeds] = useState("");
    const [addRent, setAddRent] = useState(false);
    const [rent, setRent] = useState("");

    useEffect(() => {
        getUnitTypes().then((unitTypeOptions) =>
            setUnitTypeOptions(unitTypeOptions)
        );
        if (
            !isNullUndefined(props) &&
            !isNullUndefined(props.signUp) &&
            !isNullUndefined(props.signUp.signUpJson) &&
            !isNullUndefined(props.signUp.signUpJson.subscriptionService) &&
            !isNullUndefined(
                props.signUp.signUpJson.subscriptionService.serviceCode
            ) &&
            !isEmptyString(props.signUp.signUpJson.subscriptionService.serviceCode) &&
            (props.signUp.signUpJson.subscriptionService.serviceCode === "METER" ||
                props.signUp.signUpJson.subscriptionService.serviceCode === "BLWTR")
        ) {
            let signUp;
            let buttonIndex = "2.2";
            let displayMessage = "Please proceed to the Utilities section.";
            if (props.signUp.signUpJson.currentStep === 3) {
                buttonIndex = "1.1";
                displayMessage = "Please go back to the property section.";
            }
            signUp = props.signUp;
            signUp.buttonIndex = buttonIndex;
            issueWarnMessage(displayMessage);
            props.saveSignUpData(signUp);
        }
    }, []);

    useEffect(() => {
        if (
            !isNullUndefined(props) &&
            !isNullUndefined(props.signUp) &&
            !isNullUndefined(props.signUp.signUpJson) &&
            !isNullUndefined(props.signUp.signUpJson.facility)
        ) {
            setFacilityId(props.signUp.signUpJson.facility.id);
            if (
                !isNullUndefined(props) &&
                !isNullUndefined(props.signUp) &&
                !isNullUndefined(props.signUp.signUpJson) &&
                !isNullUndefined(props.signUp.signUpJson.facility) &&
                !isNullUndefined(props.signUp.signUpJson.facility.units) &&
                isArray(props.signUp.signUpJson.facility.units)
            ) {
                setUnits(props.signUp.signUpJson.facility.units);
            }
            if (
                !isNullUndefined(props) &&
                !isNullUndefined(props.signUp) &&
                !isNullUndefined(props.signUp.signUpJson) &&
                !isNullUndefined(props.signUp.signUpJson.facility) &&
                isArray(props.signUp.signUpJson.utilities)
            ) {
                setUtilities(props.signUp.signUpJson.utilities);
            }
        }

        if (
            !isNullUndefined(props) &&
            !isNullUndefined(props.signUp) &&
            !isNullUndefined(props.signUp.signUpJson) &&
            !isNullUndefined(props.signUp.signUpJson.subscriptionService) &&
            !isNullUndefined(
                props.signUp.signUpJson.subscriptionService.serviceCode
            ) &&
            !isEmptyString(props.signUp.signUpJson.subscriptionService.serviceCode)
        ) {
            if (
                props.signUp.signUpJson.subscriptionService.serviceCode === "CORE" ||
                props.signUp.signUpJson.subscriptionService.serviceCode === "LEAN" ||
                props.signUp.signUpJson.subscriptionService.serviceCode === "PREMIUM"
            ) {
                //set leases
                if (
                    !isNullUndefined(props) &&
                    !isNullUndefined(props.signUp) &&
                    !isNullUndefined(props.signUp.signUpJson) &&
                    !isNullUndefined(props.signUp.signUpJson.leases) &&
                    isArray(props.signUp.signUpJson.leases)
                ) {
                    setLeases(props.signUp.signUpJson.leases);
                }
                //set unit statuses
                if (
                    !isNullUndefined(props) &&
                    !isNullUndefined(props.signUp) &&
                    !isNullUndefined(props.signUp.signUpJson) &&
                    !isNullUndefined(props.signUp.signUpJson.unitStatuses) &&
                    isArray(props.signUp.signUpJson.unitStatuses)
                ) {
                    setUnitStatuses(props.signUp.signUpJson.unitStatuses);
                }
            }

            if (props.signUp.signUpJson.subscriptionService.serviceCode === "METER") {
                //set meter billing
                if (
                    !isNullUndefined(props) &&
                    !isNullUndefined(props.signUp) &&
                    !isNullUndefined(props.signUp.signUpJson) &&
                    !isNullUndefined(props.signUp.signUpJson.meterBillings) &&
                    isArray(props.signUp.signUpJson.meterBillings)
                ) {
                    setMeterBillings(props.signUp.signUpJson.meterBillings);
                }
            }

            if (props.signUp.signUpJson.subscriptionService.serviceCode === "HOA") {
                //set ownerships
                if (
                    !isNullUndefined(props) &&
                    !isNullUndefined(props.signUp) &&
                    !isNullUndefined(props.signUp.signUpJson) &&
                    !isNullUndefined(props.signUp.signUpJson.ownerships) &&
                    isArray(props.signUp.signUpJson.ownerships)
                ) {
                    setOwnerships(props.signUp.signUpJson.ownerships);
                }
                //set unit statuses
                if (
                    !isNullUndefined(props) &&
                    !isNullUndefined(props.signUp) &&
                    !isNullUndefined(props.signUp.signUpJson) &&
                    !isNullUndefined(props.signUp.signUpJson.unitStatuses) &&
                    isArray(props.signUp.signUpJson.unitStatuses)
                ) {
                    setUnitStatuses(props.signUp.signUpJson.unitStatuses);
                }
            }
        }
    }, [props.signUp]);

    useEffect(() => {
        populateUnits();
    }, [
        units,
        unitTypeOptions,
        addUnitType,
        addSquareFeet,
        addRentPerSquareFoot,
        addBaths,
        addBeds,
        addRent,
    ]);

    function populateUnits() {
        let rows =
            units &&
            units.length > 0 &&
            units.map(function (unit, i) {
                return (
                  <TableRow
                    key={i}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="left" sx={{ width: "15%" }}>
                      <TextField
                        variant="outlined"
                        placeholder="Unit Name"
                        id={"name" + i}
                        name={"name" + i}
                        isrequired={true}
                        sx={{
                          backgroundColor: "#efefef",
                          borderRadius: "10px",
                          minWidth: "100px",
                        }}
                        value={units && units[i] && units[i].name}
                        onChange={(event) => onTextChanged(event, i)}
                      />
                    </TableCell>

                    {!addUnitType && (
                      <TableCell align="left" sx={{ width: "13%" }}>
                        <Select
                          sx={{ width: { xs: "100%", md: "90%" } }}
                          id={"facilityUnitTypeCd" + i}
                          value={
                            units && units[i] && units[i].facilityUnitTypeCd
                          }
                          onChange={(event) => handleUnitTypeChange(event, i)}
                        >
                          {unitTypeOptions && unitTypeOptions.length > 0 ? (
                            unitTypeOptions.map(function (unitType, i) {
                              return (
                                <MenuItem
                                  key={unitType.value}
                                  value={unitType.value}
                                >
                                  {unitType.label}
                                </MenuItem>
                              );
                            }, this)
                          ) : (
                            <MenuItem sx={{ width: "100%" }}>
                              No Results Found
                            </MenuItem>
                          )}
                        </Select>
                      </TableCell>
                    )}

                    {!addSquareFeet && (
                      <TableCell align="left">
                        <TextField
                          variant="outlined"
                          placeholder="Square Feet"
                          id={"squareFeet" + i}
                          name={"squareFeet" + i}
                          value={
                            units &&
                            units[i] &&
                            units[i].unitProperties &&
                            units[i].unitProperties.squareFeet
                          }
                          onChange={(event) => onTextChanged(event, i)}
                          sx={{
                            backgroundColor: "#efefef",
                            borderRadius: "10px",
                            minWidth: "100px",
                          }}
                        />
                      </TableCell>
                    )}

                    {!addRentPerSquareFoot && (
                      <TableCell align="left">
                        <TextField
                          variant="outlined"
                          placeholder="Rent/Sq Ft"
                          id={"rentPerSquareFoot" + i}
                          name={"rentPerSquareFoot" + i}
                          value={
                            units &&
                            units[i] &&
                            units[i].unitProperties &&
                            units[i].unitProperties.rentPerSquareFoot
                          }
                          onChange={(event) => onTextChanged(event, i)}
                          sx={{
                            backgroundColor: "#efefef",
                            borderRadius: "10px",
                            minWidth: "100px",
                          }}
                        />
                      </TableCell>
                    )}

                    {!addBaths && (
                      <TableCell align="left">
                        <TextField
                          variant="outlined"
                          placeholder="Baths"
                          id={"numberOfBaths" + i}
                          name={"numberOfBaths" + i}
                          value={
                            units &&
                            units[i] &&
                            units[i].unitProperties &&
                            units[i].unitProperties.numberOfBaths
                          }
                          onChange={(event) => onTextChanged(event, i)}
                          sx={{
                            backgroundColor: "#efefef",
                            borderRadius: "10px",
                            minWidth: "100px",
                          }}
                        />
                      </TableCell>
                    )}

                    {!addBeds && (
                      <TableCell align="left">
                        <TextField
                          variant="outlined"
                          placeholder="Beds"
                          id={"numberOfBeds" + i}
                          name={"numberOfBeds" + i}
                          value={
                            units &&
                            units[i] &&
                            units[i].unitProperties &&
                            units[i].unitProperties.numberOfBeds
                          }
                          onChange={(event) => onTextChanged(event, i)}
                          sx={{
                            backgroundColor: "#efefef",
                            borderRadius: "10px",
                            minWidth: "100px",
                          }}
                        />
                      </TableCell>
                    )}

                    {!addRent && (
                      <TableCell align="left">
                        <TextField
                          variant="outlined"
                          placeholder="Rent"
                          id={"rent" + i}
                          name={"rent" + i}
                          value={
                            units &&
                            units[i] &&
                            units[i].unitProperties &&
                            units[i].unitProperties.rent
                          }
                          onChange={(event) => onTextChanged(event, i)}
                          sx={{
                            backgroundColor: "#efefef",
                            borderRadius: "10px",
                            minWidth: "100px",
                          }}
                        />
                      </TableCell>
                    )}

                    <TableCell align="left">
                      <IconButton
                        variant="outlined"
                        onClick={() => handleUnitRemove(i)}
                        placeholder="Remove Unit"
                        sx={{
                          backgroundColor: "#efefef",
                          borderRadius: "10px",
                          padding: "15px",
                        }}
                      >
                        <Cancel color="error" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
            });
        setUnitsRows(rows);
    }

    function handleUnitAdd() {
        let uType,
            sFeet,
            rPerSquareFoot,
            bathsA,
            bedsA,
            rentA = "";
        if (addUnitType) {
            uType = unitType;
        }
        if (addSquareFeet) {
            sFeet = squareFeet;
        }
        if (addRentPerSquareFoot) {
            rPerSquareFoot = rentPerSquareFoot;
        }
        if (addBaths) {
            bathsA = baths;
        }
        if (addBeds) {
            bedsA = beds;
        }
        if (addRent) {
            rentA = rent;
        }

        let unitId = getIdRefNumber();
        setUnits([
            ...units,
            {
                id: unitId,
                name: "",
                description: "",
                statusCd: "ACT",
                facilityId: facilityId,
                facilityUnitTypeCd: uType,
                dateCreated: new Date(),
                unitProperties: {
                    expirationDate: null,
                    squareFeet: sFeet,
                    rentPerSquareFoot: rPerSquareFoot,
                    numberOfBaths: bathsA,
                    unitId: unitId,
                    effectiveDate: new Date(),
                    numberOfBeds: bedsA,
                    rent: rentA,
                    id: getIdRefNumber(),
                },
            },
        ]);
    }

    function handleUnitRemove(index) {
        let unitToRemove = units.filter((unit, i) => i === index)[0];
        if (isArray(unitStatuses) && unitStatuses.length > 0) {
            setUnitStatuses(
                unitStatuses.filter((status, i) => status.unitId !== unitToRemove.id)
            );
        }
        if (isArray(utilities) && utilities.length > 0) {
            let updatedUtilities = [];
            utilities &&
            utilities.length > 0 &&
            utilities.forEach(function (utility, i) {
                if (utility.unitId === unitToRemove.id) {
                    let updatedUtility = utility;
                    updatedUtility.unitId = "";
                    updatedUtilities.push(updatedUtility);
                } else {
                    updatedUtilities.push(utility);
                }
            });
            setUtilities(updatedUtilities);
        }

        if (isArray(leases) && leases.length > 0) {
            let leaseTmp = leases;
            leases.forEach(function (lease, i) {
                let leaseCharges = lease.leaseCharges.filter(
                    (lc) => lc.unitId === unitToRemove.id
                );
                if (isArray(leaseCharges) && leaseCharges.length > 0) {
                    leaseTmp = leaseTmp.filter(
                        (l, i) => l.id !== leaseCharges[0].leaseId
                    );
                }
            });
            setLeases(leaseTmp);
        }

        if (isArray(ownerships) && ownerships.length > 0) {
            let ownershipsTmp = [];
            ownerships.forEach(function (ownership, i) {
                let tmp = ownership;
                if (ownership.unitId === unitToRemove.id) {
                    tmp.unitId = null;
                    tmp.unitName = "";
                }
                ownershipsTmp.push(tmp);
            });
            setOwnerships(ownershipsTmp);
        }

        setUnits(units.filter((unit, i) => i !== index));
    }

    function handleUnitTypeChange(event, index) {
        let value = event.target.value;
        let newArr = [...units];
        newArr[index].facilityUnitTypeCd = value;
        setUnits(newArr);
    }

    function onTextChanged(event, index) {
        let newArr = [...units];
        let fieldName = event.target.name;
        let value = event.target.value;
        if (fieldName === "name" + index) {
            newArr[index].name = value;
        }
        if (fieldName === "squareFeet" + index) {
            newArr[index].unitProperties.squareFeet = value;
        }

        if (fieldName === "rentPerSquareFoot" + index) {
            newArr[index].unitProperties.rentPerSquareFoot = value;
        }

        if (fieldName === "numberOfBaths" + index) {
            newArr[index].unitProperties.numberOfBaths = value;
        }

        if (fieldName === "rent" + index) {
            newArr[index].unitProperties.rent = value;
        }

        if (fieldName === "numberOfBeds" + index) {
            newArr[index].unitProperties.numberOfBeds = value;
        }

        setUnits(newArr);
    }

    function saveAndFinishLater() {
        let signUp;
        const buttonIndex = "2.1";
        signUp = props.signUp;
        signUp.buttonIndex = buttonIndex;
        signUp.signUpJson.facility.units = units;
        signUp.signUpJson.unitStatuses = unitStatuses;
        signUp.signUpJson.utilities = utilities;
        signUp.signUpJson.leases = leases;
        signUp.signUpJson.meterBillings = meterBillings;
        signUp.signUpJson.ownerships = ownerships;
        if (isNullUndefined(signUp)) {
            issueWarnMessage(`Please fill in the form.`);
        } else {
            if (validateSaveAndFinishLater(signUp)) {
                props.saveSignUpData(signUp);
            }
        }
    }

    function validateSaveAndFinishLater(signUp) {
        //console.log("validateSaveAndFinishLater signUp: ", signUp)
        // Validate
        if (isNullUndefined(signUp)) {
            issueWarnMessage("Could not determine signUp.");
            return false;
        }

        if (isNullUndefined(signUp.signUpJson.facility)) {
            issueWarnMessage("Could not determine facility.");
            return false;
        }

        if (
            isNullUndefined(signUp.signUpJson.facility.units) ||
            !isArray(signUp.signUpJson.facility.units) ||
            isEmptyArray(signUp.signUpJson.facility.units)
        ) {
            issueWarnMessage("Could not determine units.");
            return false;
        }

        if (
            isArray(signUp.signUpJson.facility.units) &&
            signUp.signUpJson.facility.units.length < 1
        ) {
            issueWarnMessage("There should be at least one unit.");
            return false;
        }

        if (isArray(signUp.signUpJson.facility.units)) {
            for (let unit of signUp.signUpJson.facility.units) {
                if (isNullUndefined(unit.name) || isEmptyString(unit.name)) {
                    let message = "Unit name is invalid. Cannot be blank.";
                    issueWarnMessage(message);
                    return false;
                }

                if (
                    isNullUndefined(unit.facilityUnitTypeCd) ||
                    isEmptyString(unit.facilityUnitTypeCd)
                ) {
                    let message = "Unit type is invalid. Cannot be blank.";
                    issueWarnMessage(message);
                    return false;
                }

                if (
                    signUp.signUpJson.facility.units.filter(
                        (unitFilter, i) => unit.name === unitFilter.name
                    ).length > 1
                ) {
                    let message = "There is duplicated Unit name.";
                    issueWarnMessage(message);
                    return false;
                }
            }
        }
        return true;
    }

    function validateSaveAndContinue(signUp) {
        // Validate
        if (validateSaveAndFinishLater(signUp)) {
            if (isArray(signUp.signUpJson.facility.units)) {
                for (let unit of signUp.signUpJson.facility.units) {
                    let squareFeet = tryParseFloat(unit.unitProperties.squareFeet, -1);
                    let rentPerSquareFoot = tryParseFloat(
                        unit.unitProperties.rentPerSquareFoot,
                        -1
                    );
                    let numberOfBaths = tryParseInt(
                        unit.unitProperties.numberOfBaths,
                        -1
                    );
                    let numberOfBeds = tryParseInt(unit.unitProperties.numberOfBeds, -1);
                    let rent = tryParseFloat(unit.unitProperties.rent, -1);

                    if (
                        !isNullUndefined(unit.unitProperties.rent) &&
                        !isEmptyString(unit.unitProperties.rent) &&
                        rent < 0
                    ) {
                        let message = "Unit  rent  is invalid. Should be a number.";
                        issueWarnMessage(message);
                        return false;
                    }

                    if (
                        !isNullUndefined(unit.unitProperties.numberOfBeds) &&
                        !isEmptyString(unit.unitProperties.numberOfBeds) &&
                        numberOfBeds < 0
                    ) {
                        let message =
                            "Unit  number Of Beds  is invalid. Should be a number.";
                        issueWarnMessage(message);
                        return false;
                    }

                    if (
                        !isNullUndefined(unit.unitProperties.numberOfBaths) &&
                        !isEmptyString(unit.unitProperties.numberOfBaths) &&
                        numberOfBaths < 0
                    ) {
                        let message =
                            "Unit number Of Baths is invalid. Should be a number.";
                        issueWarnMessage(message);
                        return false;
                    }

                    if (
                        !isNullUndefined(unit.unitProperties.rentPerSquareFoot) &&
                        !isEmptyString(unit.unitProperties.rentPerSquareFoot) &&
                        rentPerSquareFoot < 0
                    ) {
                        let message =
                            "Unit rent Per Square Foot is invalid. Should be a number.";
                        issueWarnMessage(message);
                        return false;
                    }

                    if (
                        !isNullUndefined(unit.unitProperties.squareFeet) &&
                        !isEmptyString(unit.unitProperties.squareFeet) &&
                        squareFeet < 0
                    ) {
                        let message = "Unit square Feet is invalid. Should be a number.";
                        issueWarnMessage(message);
                        return false;
                    }
                }
            }
            return true;
        }
    }

    function saveAndContinue() {
        let signUp;
        const buttonIndex = "2.2";
        signUp = props.signUp;
        signUp.buttonIndex = buttonIndex;
        signUp.signUpJson.facility.units = units;
        signUp.signUpJson.unitStatuses = unitStatuses;
        signUp.signUpJson.utilities = utilities;
        signUp.signUpJson.leases = leases;
        signUp.signUpJson.meterBillings = meterBillings;
        signUp.signUpJson.ownerships = ownerships;
        if (isNullUndefined(signUp)) {
            issueWarnMessage(`Please fill in the form.`);
        } else {
            if (validateSaveAndContinue(signUp)) {
                props.saveSignUpData(signUp);
            }
        }
    }

    function handleHeadersSelectChange(event) {
        let value = event.target.value;
        let fieldName = event.target.name;
        let newArr = [...units];
        if (fieldName === "unitType") {
            setUnitType(value);
            if (addUnitType) {
                newArr &&
                newArr.length > 0 &&
                newArr.forEach(function (utility, i) {
                    newArr[i].facilityUnitTypeCd = value;
                });
            }
        }
        setUnits(newArr);
    }

    function onHeadersTextChanged(event) {
        let fieldName = event.target.name;
        let value = event.target.value;
        let newArr = [...units];
        if (fieldName === "squareFeet") {
            setSquareFeet(value);
            if (addSquareFeet) {
                newArr &&
                newArr.length > 0 &&
                newArr.forEach(function (utility, i) {
                    newArr[i].unitProperties.squareFeet = value;
                });
            }
        }

        if (fieldName === "rentPerSquareFoot") {
            setRentPerSquareFoot(value);
            if (addRentPerSquareFoot) {
                newArr &&
                newArr.length > 0 &&
                newArr.forEach(function (utility, i) {
                    newArr[i].unitProperties.rentPerSquareFoot = value;
                });
            }
        }

        if (fieldName === "baths") {
            setBaths(value);
            if (addBaths) {
                newArr &&
                newArr.length > 0 &&
                newArr.forEach(function (utility, i) {
                    newArr[i].unitProperties.numberOfBaths = value;
                });
            }
        }

        if (fieldName === "beds") {
            setBeds(value);
            if (addBeds) {
                newArr &&
                newArr.length > 0 &&
                newArr.forEach(function (utility, i) {
                    newArr[i].unitProperties.numberOfBeds = value;
                });
            }
        }

        if (fieldName === "rent") {
            setRent(value);
            if (addRent) {
                newArr &&
                newArr.length > 0 &&
                newArr.forEach(function (utility, i) {
                    newArr[i].unitProperties.rent = value;
                });
            }
        }
        setUnits(newArr);
    }

    return (
      <ResponsiveRow container sx={{ width: "100%", height: "auto" }}>
        {/* PROPERTY FORM */}

        <ResponsiveRow
          sx={{
            justifyContent: "flex-start",
            alignItems: "center",
            padding: "20px",
            height: "fit-content",
            marginBottom: "-20px",
          }}
        >
          <Typography
            sx={{ marginBottom: "5px" }}
            id="addUser"
            variant="h6"
            component="h6"
          >
            Unit Information
          </Typography>
          <ResponsiveRow
            sx={{
              justifyContent: "flex-start",
              alignItems: "flex-start",
              height: "auto",
            }}
          >
            <Grid
              item
              sx={{
                width: { xs: "100%", lg: "25%" },
                textAlign: "start",
                marginBottom: "10px",
              }}
            >
              <FormControlLabel
                sx={{ fontSize: "1.2rem", fontWeight: 600 }}
                label="Unit Type"
                control={
                  <Checkbox
                    value={addUnitType}
                    checked={addUnitType}
                    onChange={() => setAddUnitType(!addUnitType)}
                    color="primary"
                  />
                }
              />
              {addUnitType && (
                <Select
                  sx={{ width: { xs: "100%", md: "90%" } }}
                  id={"unitType"}
                  name={"unitType"}
                  value={unitType}
                  disabled={!addUnitType}
                  onChange={(event) => handleHeadersSelectChange(event)}
                >
                  {unitTypeOptions && unitTypeOptions.length > 0 ? (
                    unitTypeOptions.map(function (type, i) {
                      return (
                        <MenuItem key={type.value} value={type.value}>
                          {type.label}
                        </MenuItem>
                      );
                    }, this)
                  ) : (
                    <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
                  )}
                </Select>
              )}
            </Grid>

            <Grid
              item
              sx={{
                width: { xs: "100%", lg: "25%" },
                textAlign: "start",
                marginBottom: "10px",
              }}
            >
              <FormControlLabel
                sx={{ fontSize: "1.2rem", fontWeight: 600 }}
                label="Square Feet"
                control={
                  <Checkbox
                    value={addSquareFeet}
                    checked={addSquareFeet}
                    onChange={() => setAddSquareFeet(!addSquareFeet)}
                    color="primary"
                  />
                }
              />
              {addSquareFeet && (
                <TextField
                  sx={{ width: "90%" }}
                  disabled={!addSquareFeet}
                  value={squareFeet}
                  name={"squareFeet"}
                  onChange={(event) => onHeadersTextChanged(event)}
                  label="Value"
                />
              )}
            </Grid>

            <Grid
              item
              sx={{
                width: { xs: "100%", lg: "25%" },
                textAlign: "start",
                marginBottom: "10px",
              }}
            >
              <FormControlLabel
                sx={{ fontSize: "1.2rem", fontWeight: 600 }}
                label="Rent Per Square Foot"
                control={
                  <Checkbox
                    value={addRentPerSquareFoot}
                    checked={addRentPerSquareFoot}
                    onChange={() =>
                      setAddRentPerSquareFoot(!addRentPerSquareFoot)
                    }
                    color="primary"
                  />
                }
              />
              {addRentPerSquareFoot && (
                <TextField
                  sx={{ width: "90%" }}
                  disabled={!addRentPerSquareFoot}
                  value={rentPerSquareFoot}
                  name={"rentPerSquareFoot"}
                  onChange={(event) => onHeadersTextChanged(event)}
                  label="Amount Ksh"
                />
              )}
            </Grid>

            <Grid
              item
              sx={{
                width: { xs: "100%", lg: "25%" },
                textAlign: "start",
                marginBottom: "10px",
              }}
            >
              <FormControlLabel
                sx={{ fontSize: "1.2rem", fontWeight: 600 }}
                label="Baths"
                control={
                  <Checkbox
                    value={addBaths}
                    checked={addBaths}
                    onChange={() => setAddBaths(!addBaths)}
                    color="primary"
                  />
                }
              />
              {addBaths && (
                <TextField
                  sx={{ width: "90%" }}
                  disabled={!addBaths}
                  value={baths}
                  name={"baths"}
                  onChange={(event) => onHeadersTextChanged(event)}
                  label="Number"
                />
              )}
            </Grid>

            <Grid
              item
              sx={{
                width: { xs: "100%", lg: "25%" },
                textAlign: "start",
                marginBottom: "10px",
              }}
            >
              <FormControlLabel
                sx={{ fontSize: "1.2rem", fontWeight: 600 }}
                label="Beds"
                control={
                  <Checkbox
                    value={addBeds}
                    checked={addBeds}
                    onChange={() => setAddBeds(!addBeds)}
                    color="primary"
                  />
                }
              />
              {addBeds && (
                <TextField
                  sx={{ width: "90%" }}
                  disabled={!addBeds}
                  value={beds}
                  name={"beds"}
                  onChange={(event) => onHeadersTextChanged(event)}
                  label="Number"
                />
              )}
            </Grid>

            <Grid
              item
              sx={{
                width: { xs: "100%", lg: "25%" },
                textAlign: "start",
                marginBottom: "10px",
              }}
            >
              <FormControlLabel
                sx={{ fontSize: "1.2rem", fontWeight: 600 }}
                label="Rent"
                control={
                  <Checkbox
                    value={addRent}
                    checked={addRent}
                    onChange={() => setAddRent(!addRent)}
                    color="primary"
                  />
                }
              />
              {addRent && (
                <TextField
                  sx={{ width: "90%" }}
                  disabled={!addRent}
                  value={rent}
                  name={"rent"}
                  onChange={(event) => onHeadersTextChanged(event)}
                  label="Amount Ksh"
                />
              )}
            </Grid>
          </ResponsiveRow>
        </ResponsiveRow>

        <ResponsiveRow
          sx={{
            justifyContent: "flex-start",
            alignItems: "flex-start",
            borderRadius: "10px",
            height: "auto",
          }}
        >
          <Typography
            sx={{ marginBottom: "5px" }}
            id="addUser"
            variant="h6"
            component="h6"
          >
            Units Details
          </Typography>
          <TableContainer>
            <Table sx={{ minWidth: 1050 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Name</TableCell>
                  {!addUnitType && <TableCell align="left">Type</TableCell>}
                  {!addSquareFeet && (
                    <TableCell align="left">Square Feet </TableCell>
                  )}
                  {!addRentPerSquareFoot && (
                    <TableCell align="left">Rent /Square Foot</TableCell>
                  )}
                  {!addBaths && <TableCell align="left">Baths </TableCell>}
                  {!addBeds && <TableCell align="left">Beds </TableCell>}
                  {!addRent && <TableCell align="left">Rent </TableCell>}
                  <TableCell align="left"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{unitsRows}</TableBody>
            </Table>
          </TableContainer>
          <ResponsiveRow
            sx={{
              justifyContent: { xs: "center", lg: "flex-end" },
              alignItems: "center",
              width: "100%",
              height: "auto",
            }}
          >
            <ModalAcceptButton
              onClick={handleUnitAdd}
              sx={{
                width: { xs: "100%", lg: "200px" },
                height: "50px",
              }}
            >
              Add Unit
            </ModalAcceptButton>
          </ResponsiveRow>
        </ResponsiveRow>

        <ResponsiveRow
          sx={{
            justifyContent: { xs: "center", lg: "flex-end" },
            height: "auto",
          }}
        >
          <ModalCancelButton
            onClick={props.handleBackButtonClicked}
            sx={{
              width: { xs: "100%", lg: "200px" },
            }}
          >
            Go Back
          </ModalCancelButton>
          <ModalAcceptButton
            onClick={saveAndFinishLater}
            sx={{
              width: { xs: "100%", lg: "200px" },
            }}
          >
            Save & Finish Later
          </ModalAcceptButton>

          <ModalAcceptButton
            onClick={saveAndContinue}
            sx={{
              width: { xs: "100%", lg: "200px" },
            }}
          >
            Save & Continue
          </ModalAcceptButton>
        </ResponsiveRow>
      </ResponsiveRow>
    );
}
