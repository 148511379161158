import React, { useState } from "react";
import { isNullUndefined } from "../../util/util";
import {
  MobileNavItem,
  MobileNavParentText,
  MobileNavSubItem,
  MobileNavSubMenu,
} from "../../constants/component.constants";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Collapse } from "@mui/material";
import Permit from "../../util/permit";
import { NavLink } from "react-router-dom";

export default function MobileParentMenuItem({ item, closeDrawer }) {
  const [open, setOpen] = useState(false);

  function toggleMenu(e) {
    setOpen(!open);
  }

  function closeSubMenu(e) {
    setOpen(false);
    closeDrawer();
  }

  return isNullUndefined(item.roles) && isNullUndefined(item.services) ? (
    <>
      <MobileNavItem id="dropDownTrigger" onClick={toggleMenu}>
        <MobileNavParentText
          primary={item.name}
          primaryTypographyProps={{ color: "#fff" }}
        />
        {open ? <ExpandLess /> : <ExpandMore />}
      </MobileNavItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <MobileNavSubMenu
          component="div"
          sx={{
            backgroundColor: "white",
            borderRadius: "10px",
          }}
        >
          {item.children &&
            item.children.map((child, i) =>
              child.roles !== false && child.services === false ? (
                <Permit
                  key={i}
                  roles={child.roles}
                  services={child.services}
                  permissions={child.permissions}
                >
                  <MobileNavSubItem
                    key={i}
                    onClick={closeSubMenu}
                    component={NavLink}
                    href={child.href}
                  >
                    {child.name}
                  </MobileNavSubItem>
                </Permit>
              ) : (
                <MobileNavSubItem
                  key={i}
                  onClick={closeSubMenu}
                  component={NavLink}
                  href={child.href}
                >
                  {child.name}
                </MobileNavSubItem>
              )
            )}
        </MobileNavSubMenu>
      </Collapse>
    </>
  ) : (
    <>
      <Permit
        roles={item.roles}
        services={item.services}
        permissions={item.permissions}
        key={item.id}
      >
        <MobileNavItem onClick={toggleMenu}>
          <MobileNavParentText
            primary={item.name}
            primaryTypographyProps={{ color: "#fff" }}
          />
          {open ? <ExpandLess /> : <ExpandMore />}
        </MobileNavItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <MobileNavSubMenu component="div">
            {item.children &&
              item.children.map((child, i) =>
                child.roles !== false && child.services === false ? (
                  <Permit
                    roles={child.roles}
                    services={child.services}
                    permissions={child.permissions}
                    key={i}
                  >
                    <MobileNavSubItem
                      key={i}
                      onClick={closeSubMenu}
                      component={NavLink}
                      to={child.href}
                    >
                      {child.name}
                    </MobileNavSubItem>
                  </Permit>
                ) : (
                  <MobileNavSubItem
                    key={i}
                    onClick={closeSubMenu}
                    component={NavLink}
                    to={child.href}
                  >
                    {child.name}
                  </MobileNavSubItem>
                )
              )}
          </MobileNavSubMenu>
        </Collapse>
      </Permit>
    </>
  );
}
