import {Container, Typography} from "@mui/material"
import {Box} from "@mui/system"
import StatsCard from "./cards/statsCard"
import MeterBillingViewInfoCard from "./cards/meterBillingInformationCard"
import MeterChargesCard from "./cards/meterChargesCard"
import NoteLogsCard from "./cards/noteLogsCard"
import WorkOrderLongCard from "./cards/workOrderLongCard"
import WorkOrderNotesWideCard from "./cards/workOrderNotesWideCard"
import WorkOrderVendorDetailCard from "./cards/workOrderVendorDetailCard"
import PropertyInfoCard from "./cards/propertyInformationCard"

const dummyRows = [
    {
        id: 1,
        unit: "Unit 432",
        memo: "The memos",
        price: "212.95",
        subtotal: "21,323.098",
        tax: "0.01",
        additional_charges: "10,00.09",
        total: "33,908.09",
        action: "",
    },
    {
        id: 2,
        unit: "Unit 432",
        memo: "The memos",
        price: "212.95",
        subtotal: "21,323.098",
        tax: "0.01",
        additional_charges: "10,00.09",
        total: "33,908.09",
        action: "",
    },
    {
        id: 3,
        unit: "Unit 432",
        memo: "The memos",
        price: "212.95",
        subtotal: "21,323.098",
        tax: "0.01",
        additional_charges: "10,00.09",
        total: "33,908.09",
        action: "",
    },
    {
        id: 4,
        unit: "Unit 432",
        memo: "The memos",
        price: "212.95",
        subtotal: "21,323.098",
        tax: "0.01",
        additional_charges: "10,00.09",
        total: "33,908.09",
        action: "",
    },
    {
        id: 5,
        unit: "Unit 432",
        memo: "The memos",
        price: "212.95",
        subtotal: "21,323.098",
        tax: "0.01",
        additional_charges: "10,00.09",
        total: "33,908.09",
        action: "",
    },
]

export default function CardComponents() {
    return (
        <Container disableGutters maxWidth="xxl" sx={{padding: "10px"}}>
            <Box
                sx={{
                    padding: "20px 15px",
                    width: "100%",
                    border: "1px #02CBCE solid",
                    borderRadius: "10px",
                    margin: "20px auto",
                }}
            >
                <Typography
                    variant="h4"
                    textAlign={"center"}
                    sx={{margin: "20px 0", color: "#037171"}}
                >
                    Meter Billing Information Card
                </Typography>
                <MeterBillingViewInfoCard/>
            </Box>
            <Box
                sx={{
                    padding: "20px 15px",
                    width: "100%",
                    border: "1px #02CBCE solid",
                    borderRadius: "10px",
                    margin: "20px auto",
                }}
            >
                <Typography
                    variant="h4"
                    textAlign={"center"}
                    sx={{margin: "20px 0", color: "#037171"}}
                >
                    Work Order Documents
                </Typography>
                <WorkOrderLongCard/>
            </Box>
            <Box
                sx={{
                    padding: "20px 15px",
                    width: "100%",
                    border: "1px #02CBCE solid",
                    borderRadius: "10px",
                    margin: "20px auto",
                }}
            >
                <Typography
                    variant="h4"
                    textAlign={"center"}
                    sx={{margin: "20px 0", color: "#037171"}}
                >
                    Meter Charges Card
                </Typography>
                <MeterChargesCard/>
            </Box>
            <Box
                sx={{
                    padding: "20px 15px",
                    width: "100%",
                    border: "1px #02CBCE solid",
                    borderRadius: "10px",
                    margin: "20px auto",
                }}
            >
                <Typography
                    variant="h4"
                    textAlign={"center"}
                    sx={{margin: "20px 0", color: "#037171"}}
                >
                    Note Logs Card
                </Typography>
                <NoteLogsCard/>
            </Box>
            <Box
                sx={{
                    padding: "20px 15px",
                    width: "100%",
                    border: "1px #02CBCE solid",
                    borderRadius: "10px",
                    margin: "20px auto",
                }}
            >
                <Typography
                    variant="h4"
                    textAlign={"center"}
                    sx={{margin: "20px 0", color: "#037171"}}
                >
                    Lease Information
                </Typography>
                {/* <LeaseInfoCard /> */}
            </Box>
            <Box
                sx={{
                    padding: "20px 15px",
                    width: "100%",
                    border: "1px #02CBCE solid",
                    borderRadius: "10px",
                    margin: "20px auto",
                }}
            >
                <Typography
                    variant="h4"
                    textAlign={"center"}
                    sx={{margin: "20px 0", color: "#037171"}}
                >
                    Wide Documents Card
                </Typography>
                <StatsCard link='/'
                           title='Stats Card'
                           data={[{'Item 1': {'count': 5, 'color': '#ffae42'}},
                               {'Item 1': {'count': 6, 'color': '#FFAE42'}}]}/>
            </Box>
            <Box
                sx={{
                    padding: "20px 15px",
                    width: "100%",
                    border: "1px #02CBCE solid",
                    borderRadius: "10px",
                    margin: "20px auto",
                }}
            >
                <Typography
                    variant="h4"
                    textAlign={"center"}
                    sx={{margin: "20px 0", color: "#037171"}}
                >
                    Wide Documents Card
                </Typography>
                {/* <WorkOrderInfoCard /> */}
            </Box>
            <Box
                sx={{
                    padding: "20px 15px",
                    width: "100%",
                    border: "1px #02CBCE solid",
                    borderRadius: "10px",
                    margin: "20px auto",
                }}
            >
                <Typography
                    variant="h4"
                    textAlign={"center"}
                    sx={{margin: "20px 0", color: "#037171"}}
                >
                    Work Order Notes (Wide) Card
                </Typography>
                <WorkOrderNotesWideCard/>
            </Box>
            <Box
                sx={{
                    padding: "20px 15px",
                    width: "100%",
                    border: "1px #02CBCE solid",
                    borderRadius: "10px",
                    margin: "20px auto",
                }}
            >
                <Typography
                    variant="h4"
                    textAlign={"center"}
                    sx={{margin: "20px 0", color: "#037171"}}
                >
                    Work Order Vendor Details
                </Typography>
                <WorkOrderVendorDetailCard rows={dummyRows}/>
            </Box>
            <Box
                sx={{
                    padding: "20px 15px",
                    width: "100%",
                    border: "1px #02CBCE solid",
                    borderRadius: "10px",
                    margin: "20px auto",
                }}
            >
                <Typography
                    variant="h4"
                    textAlign={"center"}
                    sx={{margin: "20px 0", color: "#037171"}}
                >
                    Property Information
                </Typography>
                <PropertyInfoCard/>
            </Box>
        </Container>
    )
}
