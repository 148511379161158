import React, { useEffect, useState } from 'react'
import { MainContainer } from '../../../constants/component.constants'
import UserDetailsCard from '../../../components/cards/userDetailsCard'
import { getLoggedInUser, getLoggedInUserEmailNotifications, getLoggedInUserNotifications, updateUserData } from '../../../services/profile.service';
import { isEmptyString, isNullUndefined } from '../../../util/util';
import { useSelector } from 'react-redux';
import { issueResponseMessage } from '../../../actions/message';
import { resetProfilePassword } from '../../../services/passwords.service';
import { ResponsiveRow } from '../../../constants/layout.constants';

export default function ListProfile() {

    const [loading, setLoading] = useState(true);
    const [selectedUser, setSelectedUser] = useState(null);
    const [notification, setNotification] = useState(null);
    const user = useSelector((state) => state.auth.user);
    const [emailNotification, setEmailNotification] = useState(null)

    useEffect(() => {
        setLoading(true)
        getLoggedInUser()
            .then((user) => {
                setSelectedUser(user)
            })
        getLoggedInUserNotifications()
            .then((notification) => {
                setNotification(notification)
            })
            .finally(() => {
                setLoading(false)
            })
        if (!isEmptyString(user.proxyFacility)) {
            getLoggedInUserEmailNotifications()
                .then((notification) => {
                    setEmailNotification(notification)
                })
        }
    }, [])


    function handleUpdatePersonDetailsClick(selectedUser) {
        setSelectedUser(selectedUser)
        let user = selectedUser
        let userName = user.username.replace(/"/g, "")
        delete user.username
        user.credentialId = userName
        updateUserData(user)
            .then((response) => {
                issueResponseMessage(response)
                getUser()
            })
    }

    function getUser() {
        setLoading(true)
        getLoggedInUser()
            .then((user) => {
                setSelectedUser(user)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    function handleUpdatePasswordClick(paramsValue) {
        let user = selectedUser
        let params = paramsValue
        let userName = user.username.replace(/"/g, "")
        params.username = userName
        resetProfilePassword(params)
            .then((response) => {
                issueResponseMessage(response)
            })
    }


  return (
    <MainContainer >
        <ResponsiveRow id='oned' item sx={{width: { xs: "98%", lg: "60%" }, margin: "5rem auto", justifyContent: "center" }}>    
            {!isNullUndefined(selectedUser) && !isNullUndefined(notification) && 
            <UserDetailsCard
                userExpanded = {true}
                selectedUser={selectedUser}
                notification={notification}
                hideEmail = {true}
                hideReport = {true}
                loading={loading}
                handleUpdatePersonDetailsClick={handleUpdatePersonDetailsClick}
                handleUpdatePasswordClick={handleUpdatePasswordClick}
            /> }
        </ResponsiveRow>
    </MainContainer>
  )
}
