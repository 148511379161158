import {useEffect, useState} from "react"
import {SmartKodiMap, createMarkers} from "../../components/map"
import {ResponsiveRow} from "../../constants/layout.constants"
import ExploreListing from "../../components/cards/listings/exploreListing"
import LandingExploreBar from "../../components/modules/listings/landingExploreBar"
import {useSelector} from "react-redux"
import {getListingsSecure, getListingsUnsecure,} from "../../services/listingsServices/listing.service"
import {loadListingTypes, loadPropertyTypes,} from "../../services/listingsServices/list.service"
import ListingView from "./listingView"

export default function ListingExplore() {
    const {isLoggedIn} = useSelector((state) => state.auth)
    const [listings, setListings] = useState([])
    const [filteredListings, setFilteredListings] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [isLocationFilterEnabled, setIsLocationFilterEnabled] = useState(false)
    const [center, setCenter] = useState({})
    const [pageNo] = useState(0)
    const [filterByPropertyName] = useState("")
    const [listingTypes, setListingTypes] = useState([])
    const [propertyTypes, setPropertyTypes] = useState([])
    const [selectedPropertyType, setSelectedPropertyType] = useState("")
    const [selectedListingType, setSelectedListingType] = useState("")
    const [noOfBeds, setNoOfBeds] = useState("")
    const [noOfBaths, setNoOfBaths] = useState("")
    const [sqftMin, setSqftMin] = useState("")
    const [sqftMax, setSqftMax] = useState("")
    const [priceMin, setPriceMin] = useState("")
    const [priceMax, setPriceMax] = useState("")
    const [openFilterModal, setOpenFilterModal] = useState(false)
    const [task, setTask] = useState("")
    const [selectedListing, setSelectedListing] = useState(null)
    const [listingMarkers, setListingMarkers] = useState([])
    const [reLocate, setReLocate] = useState(false)

    function handleFilterModal() {
        setOpenFilterModal(!openFilterModal)
    }

    function clearFilter() {
        setSelectedPropertyType("")
        setSelectedListingType("")
        setNoOfBaths("")
        setNoOfBeds("")
        setSqftMin("")
        setSqftMax("")
        setPriceMin("")
        setPriceMax("")
    }

    function success(pos) {
        const crd = pos.coords
        setCenter({lat: crd.latitude, lng: crd.longitude})
        setReLocate(false)
    }

    function error(err) {
        console.warn(`ERROR(${err.code}): ${err.message}`)
    }

    useEffect(() => {
        navigator.geolocation.getCurrentPosition(success, error)
        setIsLocationFilterEnabled(false)
        loadListingTypes()
            .then((data) => setListingTypes(data))
            .catch((error) => {
                console.log("loadListingTypes Error: ", error)
            })
        loadPropertyTypes()
            .then((data) => setPropertyTypes(data))
            .catch((error) => {
                console.log("loadPropertyTypes Error: ", error)
            })
    }, [reLocate])

    useEffect(() => {
        createMarkers(setListingMarkers, listings)
    }, [listings]);

    useEffect(() => {
        !isLoggedIn
            ? getListingsUnsecure(
                selectedPropertyType,
                selectedListingType,
                filterByPropertyName,
                noOfBeds,
                noOfBaths,
                sqftMin,
                sqftMax,
                priceMin,
                priceMax,
                pageNo,
                12
            )
                .then((data) => setListings(data))
            : getListingsSecure(
                selectedPropertyType,
                selectedListingType,
                filterByPropertyName,
                noOfBeds,
                noOfBaths,
                sqftMin,
                sqftMax,
                priceMin,
                priceMax,
                pageNo,
                12
            )
                .then((data) => setListings(data))
    }, [
        isLoggedIn,
        selectedPropertyType,
        selectedListingType,
        filterByPropertyName,
        noOfBeds,
        noOfBaths,
        sqftMin,
        sqftMax,
        priceMin,
        priceMax,
        pageNo
    ])

    function applyFilterClicked() {
        setOpenFilterModal(!openFilterModal)
        !isLoggedIn
            ? getListingsUnsecure(
                selectedPropertyType,
                selectedListingType,
                filterByPropertyName,
                noOfBeds,
                noOfBaths,
                sqftMin,
                sqftMax,
                priceMin,
                priceMax,
                pageNo,
                12
            )
                .then((data) => setListings(data))
            : getListingsSecure(
                selectedPropertyType,
                selectedListingType,
                filterByPropertyName,
                noOfBeds,
                noOfBaths,
                sqftMin,
                sqftMax,
                priceMin,
                priceMax,
                pageNo,
                12
            )
                .then((data) => setListings(data))
    }

    function onPropertyTypeChange(event) {
        let value = event.target.value
        setSelectedPropertyType(value)
    }

    function onListingTypeChange(event) {
        let value = event.target.value
        setSelectedListingType(value)
    }

    function noOfBedsChanged(event) {
        let value = event.target.value
        console.log("value is", value)
        setNoOfBeds(value)
    }

    function noOfBathsChanged(event) {
        let value = event.target.value
        setNoOfBaths(value)
    }

    function sqftMinChanged(event) {
        let value = event.target.value
        setSqftMin(value)
    }

    function sqftMaxChanged(event) {
        let value = event.target.value
        setSqftMax(value)
    }

    function priceMinChanged(event) {
        let value = event.target.value
        setPriceMin(value)
    }

    function priceMaxChanged(event) {
        let value = event.target.value
        setPriceMax(value)
    }

    function listingSelected(item) {
        setSelectedListing(item)
        setTask("View")
    }

    function backClicked() {
        setTask("")
        setSelectedListing(null)
    }

    return (
        <ResponsiveRow sx={{flexDirection: "column", height: "100%", padding: 0}}>
            {task === "" && (
                <ResponsiveRow
                    container
                    sx={{
                        width: "100%",
                        textAlign: "start",
                        margin: "100px 0 0 0",
                        padding: 0,
                        maxHeight: {xs: "100%", lg: "85vh"},
                        overflowY: {xs: "auto", lg: "hidden"},
                        //   margin: 0,
                    }}
                >
                    <ResponsiveRow
                        item
                        sx={{
                            display: {xs: "none", lg: "flex"},
                            width: {xs: "100%", lg: "60%"},
                            height: {xs: "100%", lg: "87vh"},
                            padding: 0,
                        }}
                    >
                        <SmartKodiMap
                            view={"listings"}
                            containerStyle={{width: "100%", height: "100%"}}
                            center={center}
                            children={listingMarkers}
                            listingCollection={listings}
                            setFilteredListings={setFilteredListings}
                            setIsLocationFilterEnabled={setIsLocationFilterEnabled}
                            setIsLoading={setIsLoading}
                        />
                    </ResponsiveRow>
                    <ResponsiveRow
                        item
                        sx={{
                            width: {xs: "100%", lg: "40%"},
                            height: {xs: "100%", lg: "85vh"},
                            padding: 0,
                            margin: 0,
                            marginBottom: "0px",
                            justifyContent: "center",
                        }}
                    >
                        <LandingExploreBar
                            noOfBedsChanged={noOfBedsChanged}
                            noOfBathsChanged={noOfBathsChanged}
                            sqftMinChanged={sqftMinChanged}
                            sqftMaxChanged={sqftMaxChanged}
                            priceMinChanged={priceMinChanged}
                            priceMaxChanged={priceMaxChanged}
                            onPropertyTypeChange={onPropertyTypeChange}
                            onListingTypeChange={onListingTypeChange}
                            listingTypes={listingTypes}
                            propertyTypes={propertyTypes}
                            noOfBeds={noOfBeds}
                            noOfBaths={noOfBaths}
                            sqftMin={sqftMin}
                            sqftMax={sqftMax}
                            priceMin={priceMin}
                            priceMax={priceMax}
                            selectedPropertyType={selectedPropertyType}
                            selectedListingType={selectedListingType}
                            applyFilterClicked={applyFilterClicked}
                            handleFilterModal={handleFilterModal}
                            openFilterModal={openFilterModal}
                            clearFilter={clearFilter}
                            setCenter={setCenter}
                            setReLocate={setReLocate}
                        />
                        <ResponsiveRow
                            sx={{
                                height: "70%",
                                padding: "0 20px",
                                justifyContent: "center",
                                alignItems: "flex-start",
                                overflowY: "auto",
                                marginBottom: "30px",
                            }}
                        >
                            {!isLoading && !isLocationFilterEnabled && listings &&
                                listings.map((item, i) => (
                                    <ExploreListing
                                        key={i}
                                        listing={item}
                                        listingSelected={listingSelected}
                                    />
                                ))
                            }

                            {!isLoading && isLocationFilterEnabled &&
                                filteredListings.map((item, i) => (
                                    <ExploreListing
                                    key={i}
                                    listing={item}
                                    listingSelected={listingSelected}
                                />
                                ))
                            }
                        </ResponsiveRow>
                    </ResponsiveRow>
                </ResponsiveRow>
            )}

            {task === "View" && (
                <ListingView
                    listing={selectedListing}
                    backClicked={backClicked}
                    listings={listings}
                />
            )}
        </ResponsiveRow>
    )
}
