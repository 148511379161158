import {CorporateFare} from "@mui/icons-material";
// import React from "react";
import BillsQuadrant from "../../components/modules/dashboard/accountant/billsQuadrant";
import PaymentsAwaitingApproval from "../../components/modules/dashboard/accountant/paymentsAwaitingApproval";

import AssetsIncomeVsExpenses from "../../components/modules/dashboard/assetsIncomeVsExpenses";
import BalanceOverdueByDays from "../../components/modules/dashboard/balanceOverdueByDays";
import UpcomingAssetMaintenance from "../../components/modules/dashboard/upcomingAssetMaintenance";
import UtilitiesIncomeVsExpenses from "../../components/modules/dashboard/utilitesIncomevsExpenses";
import UtilitiesReadVsUnread from "../../components/modules/dashboard/utilitesReadVsUnread";
import UtilitiesWorkingVsBroken from "../../components/modules/dashboard/utilitiesWorkingVsBroken";
import {ResponsiveRow} from "../../constants/layout.constants";

import Permit from "../../util/permit";
import ExpensesByVendor from "../../components/modules/dashboard/expensesByVendor";

export default function DashboardAccountant() {
    return (
        <Permit
            services="PREMIUM,CORE,HOA,MAINT,METER"
            roles="ACC"
            noPermittedServiceMessage={{
                title: "No Facility Selected",
                body: (
                    <>
                        Please select a facility by clicking on the building icon (
                        <CorporateFare
                            sx={{
                                fontSize: "1.5rem",
                                color: "#037171",
                                display: "inline-block",
                                margin: "auto 0",
                            }}
                        />
                        ) from the top right <br/> If you are having issues, please reach
                        out to the system admin.
                    </>
                ),
            }}
        >
            <ResponsiveRow
                sx={{flexDirection: "column", width: "100%", alignItems: "center"}}
            >
                <ResponsiveRow
                    sx={{
                        width: "100%",
                        height: "auto",
                        flexWrap: "wrap",
                        flexDirection: "row",
                        justifyContent: "space-evenly",
                    }}
                >
                    <ResponsiveRow
                        sx={{
                            width: "100%",
                            height: "auto",
                            flexDirection: {xs: "column", lg: "row"},
                            flexWrap: "nowrap",
                            justifyContent: {xs: "center", lg: "space-between"},
                            alignItems: "center",
                            padding: 0,
                        }}
                    >
                        <Permit services="PREMIUM,CORE,HOA,LEAN,METER,MAINT">
                            <PaymentsAwaitingApproval
                                customHeight="500px"
                                customWidth="49%"
                            />
                        </Permit>
                        <Permit services="PREMIUM,CORE,HOA,LEAN,METER,MAINT">
                            <BillsQuadrant customHeight="500px" customWidth="49%"/>
                        </Permit>
                    </ResponsiveRow>
                    <ResponsiveRow
                        sx={{
                            width: "100%",
                            height: "auto",
                            flexDirection: {xs: "column", lg: "row"},
                            flexWrap: "nowrap",
                            justifyContent: {xs: "center", lg: "space-between"},
                            alignItems: "center",
                            padding: 0,
                        }}
                    >
                        <Permit services="PREMIUM,CORE,HOA,LEAN,METER">
                            <BalanceOverdueByDays customHeight="500px" customWidth="49%"/>
                        </Permit>
                        <Permit services="PREMIUM,CORE,HOA,LEAN,METER">
                            <AssetsIncomeVsExpenses customWidth="49%" customHeight="500px"/>
                        </Permit>{" "}
                    </ResponsiveRow>
                    <ResponsiveRow
                        sx={{
                            width: "100%",
                            height: "auto",
                            flexDirection: {xs: "column", lg: "row"},
                            flexWrap: "nowrap",
                            justifyContent: {xs: "center", lg: "space-between"},
                            alignItems: "center",
                            padding: 0,
                        }}
                    >
                        <Permit services="PREMIUM,CORE,HOA,LEAN,METER">
                            <UpcomingAssetMaintenance
                                customWidth="49%"
                                customHeight="500px"
                            />
                        </Permit>
                        <Permit services="PREMIUM,CORE,HOA,LEAN,METER">
                            <UtilitiesIncomeVsExpenses
                                customWidth="49%"
                                customHeight="500px"
                            />
                        </Permit>
                    </ResponsiveRow>
                    <ResponsiveRow
                        sx={{
                            width: "100%",
                            height: "auto",
                            flexDirection: {xs: "column", lg: "row"},
                            flexWrap: "nowrap",
                            justifyContent: {xs: "center", lg: "space-between"},
                            alignItems: "center",
                            padding: 0,
                        }}
                    >
                        <Permit services="PREMIUM,CORE,HOA,LEAN,METER">
                            <UtilitiesReadVsUnread customWidth="49%" customHeight="500px"/>
                        </Permit>
                        <Permit services="PREMIUM,CORE,HOA,LEAN,METER,MAINT">
                            <UtilitiesWorkingVsBroken customWidth="49%" customHeight="500px"/>
                        </Permit>
                    </ResponsiveRow>
                    <ResponsiveRow
                        sx={{
                            width: "100%",
                            height: "auto",
                            flexDirection: {xs: "column", lg: "row"},
                            flexWrap: "nowrap",
                            justifyContent: {xs: "center", lg: "space-between"},
                            alignItems: "center",
                            padding: 0,
                        }}
                    >
                        <Permit services="PREMIUM,HOA,MAINT">
                            <ExpensesByVendor customHeight="500px" customWidth="100%"/>
                        </Permit>
                    </ResponsiveRow>
                </ResponsiveRow>
            </ResponsiveRow>
        </Permit>
    );
}
