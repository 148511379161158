// import React from "react";
import MuiDataGrid from "../MuiDataGrid";

/**
 *
 * REQUIRED PROPS
 *
 * workOrders
 * columns
 * rowsPerPage
 * rowsPerPage
 * statusCd
 * loading
 * totalWorkOrders
 * loadPage
 * setSelectionModel
 * selectionModel
 *
 */

export default function RequestedTab(props) {
    return (
        // <StyledDataGrid
        //   sx={{ boxShadow: 0 }}
        //   rows={props.workOrders}
        //   columns={props.columns}
        //   pageSize={props.rowsPerPage}
        //   rowsPerPageOptions={[props.rowsPerPage]}
        //   checkboxSelection={props.statusCd === "PEN"}
        //   loading={props.loading}
        //   pagination
        //   rowCount={props.totalWorkOrders}
        //   onPageChange={(page) => props.loadPage(page)}
        //   paginationMode="server"
        //   disableSelectionOnClick
        //   onSelectionModelChange={props.setSelectionModel}
        //   selectionModel={props.selectionModel}
        //   autoHeight
        //   components={{
        //     LoadingOverlay: LinearProgress,
        //     NoRowsOverlay: EmptyOverlay,
        //   }}
        // />

        <MuiDataGrid
            noSelection={true}
            noBoxShadow={true}
            dataGridColumns={props.columns}
            dataGridRows={props.workOrders}
            serverPagination={true}
            handlePageLoad={props.loadPage}
            loading={props.loading}
            height="65vh"
            totalRows={props.totalWorkOrders}
        />
    );
}
