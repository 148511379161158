import {serviceAPI} from "./service"
import {LIST_ACCOUNTS, SAVE_ACCOUNT, TOTAL_ACCOUNTS} from "../constants/url.constants"
import {handleErrorResponse} from "../actions/message"

export const loadAccountsList = (name) => {
    return serviceAPI
        .get(LIST_ACCOUNTS + "?name=" + name)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const totalAccounts = (name) => {
    return serviceAPI
        .get(TOTAL_ACCOUNTS + "?name=" + name)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const saveAccount = (data) => {
    return serviceAPI
        .post(SAVE_ACCOUNT, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}
