import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Grid from "@mui/material/Grid";

import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Paper,
  Typography,
} from "@mui/material";

import { login, logout } from "../actions/auth";

import loginImage from "../images/login-image.png";
import { ResponsiveRow } from "../constants/layout.constants";
import { handleErrorResponse } from "../actions/message";
import kodi_theme from "../themes/kodiThemeProvider";

function Login() {
  /*
   * STATES SECTION:
   * This is where all the pages state will be defined.
   * Namely:
   *  - Remember Me Radio Button => isRemembered
   *  - Email Address Input => emailAddr
   *  - Password Input => password
   * They use the useState hook and mainly keep track of the form inputs.
   * They can also be used for form validation, if needed, through their setState callbacks
   */

  const [isRemembered, setRemembered] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);

  const { isLoggedIn, loginType } = useSelector((state) => state.auth);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  function toggleVisibility() {
    setPasswordVisible(!passwordVisible);
  }

  useEffect(() => {
    if (isLoggedIn && loginType === "main") {
      navigate("/dashboard");
    } else if (isLoggedIn && loginType === "listings") {
      console.log("not logged in, isLoggedIn is ", isLoggedIn);

      dispatch(logout());
    }
  }, [dispatch, isLoggedIn, loginType, navigate]);

  const onChangeUsername = (e) => {
    const username = e.target.value;
    setUsername(username);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const onRememberChange = (e) => {
    setRemembered(!isRemembered);
  };

  const forgotPass = (e) => {
    navigate("/forgotPassword");
  };

  const signUp = (e) => {
    navigate("/signUp");
  };

  /**
   * FORM SUBMIT: onLoginSubmit
   * This is the form submit function that takes in the event
   * and reads the values of the states
   */

  const onLoginSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    dispatch(login(username, password))
      .then(() => {
        // console.log('completed login submit')
        // setLoading(false)
        navigate("/dashboard");
      })
      .catch((error) => {
        handleErrorResponse(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Grid
      sx={{
        height: "100vh",
        width: "100wv",
        margin: "0",
      }}
      container
    >
      <Grid
        item
        xs={false}
        sm={false}
        md={5}
        lg={8}
        sx={{
          backgroundImage: `url(${loginImage})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          width: "100%",
        }}
      />
      <Grid item sm={12} md={7} lg={4}>
        <Paper
          sx={{
            height: "100vh",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box sx={{ width: "100%", margin: "10px auto 20px auto" }}>
            <img
              src={require("../images/kodi-logo.png")}
              alt=""
              width={"50%"}
            />
          </Box>

          <Box sx={{ width: "80%", margin: "15px auto" }}>
            <Typography
              variant="h1"
              color="primary.dark"
              sx={{ fontWeight: 700 }}
            >
              Welcome Back
            </Typography>
          </Box>

          <Box sx={{ width: "80%", margin: "10px auto" }}>
            <Typography variant="body2">
              Property managers and owners, please log in with your
              username/email and password to continue to your properties.
            </Typography>
          </Box>

          <Box sx={{ width: "80%", margin: "20px auto" }}>
            <form onSubmit={onLoginSubmit}>
              <FormControl sx={{ width: "80%", marginBottom: "10px" }}>
                <InputLabel htmlFor="username">
                  <Typography
                    variant="body2"
                    sx={{ fontWeight: "medium" }}
                    color="primary.light"
                  >
                    Username
                  </Typography>
                </InputLabel>
                <OutlinedInput
                  id="username"
                  label="Username"
                  required
                  aria-describedby="email-helper-text"
                  color="secondary"
                  onChange={onChangeUsername}
                  value={username}
                />
                <FormHelperText id="email-helper-text" color="primary">
                  We'll never share your email.
                </FormHelperText>
              </FormControl>
              <FormControl sx={{ width: "80%", marginBottom: "10px" }}>
                <InputLabel htmlFor="username">
                  <Typography
                    variant="body2"
                    sx={{ fontWeight: "medium" }}
                    color="primary.light"
                  >
                    Password
                  </Typography>
                </InputLabel>
                <OutlinedInput
                  type={passwordVisible ? "text" : "password"}
                  id="password"
                  label="Password"
                  required
                  autoComplete="current-password"
                  aria-describedby="password-helper-text"
                  color="secondary"
                  onChange={onChangePassword}
                  value={password}
                  endAdornment={
                    passwordVisible ? (
                      <VisibilityOff
                        onClick={toggleVisibility}
                        sx={{ color: kodi_theme.palette.primary.dark }}
                      />
                    ) : (
                      <Visibility
                        onClick={toggleVisibility}
                        sx={{ color: kodi_theme.palette.primary.dark }}
                      />
                    )
                  }
                />
                <FormHelperText id="password-helper-text" color="primary">
                  Ensure your password has at least 8 characters
                </FormHelperText>
              </FormControl>
              <Box
                sx={{
                  width: "80%",
                  margin: "10px auto",
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "nowrap",
                  alignItems: "center",
                  justifyContent: "start",
                }}
              >
                <Checkbox
                  checked={isRemembered}
                  onChange={onRememberChange}
                  inputProps={{ "aria-label": "controlled" }}
                />
                <Typography
                  variant="body2"
                  color="primary.dark"
                  textAlign={"start"}
                  sx={{ fontWeight: "bold" }}
                >
                  Remember Me
                </Typography>
              </Box>
              <LoadingButton
                variant="contained"
                type="submit"
                loading={loading}
                onClick={onLoginSubmit}
                sx={{
                  width: "80%",
                  backgroundColor: "#037171",
                  margin: "10px auto",
                }}
              >
                Login
              </LoadingButton>
              <ResponsiveRow
                sx={{
                  width: "80%",
                  margin: "10px auto",
                  flexWrap: "nowrap",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  variant="body2"
                  color="primary.dark"
                  textAlign={"center"}
                  sx={{
                    fontWeight: "bold",
                    border: 0,
                    width: "auto",
                    padding: "10px auto",
                    backgroundColor: "transparent",
                  }}
                  onClick={forgotPass}
                  // onFocus={"bold"}
                  component={Button}
                >
                  Forgot Password?
                </Typography>

                <Typography
                  variant="body2"
                  color="primary.dark"
                  textAlign={"right"}
                  sx={{
                    fontWeight: "bold",
                    border: 0,
                    width: "auto",
                    padding: "10px auto",
                    backgroundColor: "transparent",
                  }}
                  onClick={signUp}
                  // onFocus={"bold"}
                  component={Button}
                >
                  Sign Up?
                </Typography>
              </ResponsiveRow>
            </form>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default Login;
