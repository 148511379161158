import {
  Alert,
  AlertTitle,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import FileUpload from "react-material-file-upload";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import { useState } from "react";
import {deleteVacateNoteDocument, downloadByDocumentId, uploadVacateNoticeDocument} from "../services/shared.service";
import {
  bytesToMegabytes,
  isArray,
  isEmptyArray,
  isEmptyString,
  isNullUndefined,
  titleCase,
} from "../util/util";
import { issueResponseMessage, issueWarnMessage } from "../actions/message";
import {
  deleteWorkOrderDoc,
  fetchWorkOrder,
  uploadWorkOrderDocument,
} from "../services/workOrder.service";
import {
  deleteMMTransferDoc,
  uploadTransferDocuments,
  viewTransfer,
} from "../services/mobileMoneyTransfer.service";
import {
  deleteMpesaDoc,
  uploadMpesaDocument,
  viewMobileMoneyClient,
} from "../services/mobileMoneySummary.service";
import { useSelector } from "react-redux";
import {
  deleteTitleDoc,
  getTitleDetail,
  uploadTitleDoc,
} from "../services/ownership.service";
import {
  deleteBillingDocument,
  uploadBillingDocument,
} from "../services/meterbilling.service";
import {
  deleteLeaseDocument,
  getLeaseDetails,
  uploadLeaseDocument,
} from "../services/lease.service";
import {
  deleteBillDocument,
  deletePaymentDocument,
  uploadBillDocument,
  uploadPaymentDocument,
} from "../services/billAndPayment.service";
import {
  deleteInvoiceDocument,
  uploadInvoiceDocument,
} from "../services/invoices.service";
import {
  deleteCustomerDoc,
  fetchCustomers,
} from "../services/customers.service";
import { ResponsiveRow } from "../constants/layout.constants";
import { ModalAcceptButton } from "../constants/component.constants";
import {
  deleteListingDocument,
  uploadListingDocument,
} from "../services/listingsServices/listing.service";
import {
  deleteQuotationDoc,
  fetchQuotation,
  uploadQuotationDocument,
} from "../services/quotation.service";
import {
  deleteSubscriptionDoc,
  uploadSubscriptionDoc,
} from "../services/subscription.service";
import kodi_theme from "../themes/kodiThemeProvider";

function FileItem(props) {
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  function download(e) {
    e.preventDefault();
    setIsDownloading(true);
    // console.log("props ", props)
    downloadByDocumentId(props.fileItem.documentId)
      .then(() => {
        setIsDownloading(false);
      })
      .catch(() => {
        setIsDownloading(false);
      });
  }

  function handleDeleteClicked() {
    setConfirmDelete(true);
  }

  function handleCancelClicked() {
    setConfirmDelete(false);
  }

  function deleteClicked() {
    props.deleteFile(props.fileItem.documentId);
    setConfirmDelete(false);
  }

  return (
    <ResponsiveRow
      sx={{
        backgroundColor: "#ffffff",
        borderRadius: "10px",
        margin: "5px ",
        width: "100%",
        maxWidth: {
          xs: "100%",
          lg: props.horizontalLayout !== true ? "100%" : "300px",
        },
        height: "auto",
        flexWrap: "nowrap",
        justifyContent: "flex-start",
      }}
    >
      <ResponsiveRow
        item
        container
        sx={{
          flexDirection: "column",
          flexWrap: "nowrap",
          justifyContent: "center",
          alignItems: { xs: "center", lg: "flex-start" },
          width: "45%",
          height: "auto",
          margin: 0,
          padding: 0,
          overflowY: "hidden",
        }}
      >
        <img
          src="https://via.placeholder.com/75"
          style={{ borderRadius: "10px", height: "100%" }}
          sizes="75px"
          height="75px"
          alt=""
        />
      </ResponsiveRow>
      <Grid
        container
        sx={{
          flexDirection: "column",
          alignItems: { xs: "center", lg: "flex-end" },
          justifyContent: "center",
          width: { xs: "100%", lg: "60%" },
        }}
      >
        <Grid
          item
          sx={{
            marginTop: "10px",
            width: "100%",
            paddingRight: "10px",
          }}
        >
          <Typography
            variant="body2"
            color="text.secondary"
            textAlign={"end"}
            sx={{
              whiteSpace: "pre-wrap",
              textOverflow: "ellipsis",
              width: "100%",
            }}
          >
            {props.fileItem.fileName}
          </Typography>
        </Grid>
        <Grid
          item
          container
          sx={{ flexDirection: "row", justifyContent: "flex-end" }}
        >
          <Grid item>
            <IconButton onClick={download}>
              {isDownloading === true ? (
                <CircularProgress
                  size={20}
                  sx={{
                    "&.MuiCircularProgress-root": {
                      color: kodi_theme.palette.primary.main,
                      margin: "auto 0",
                    },
                  }}
                />
              ) : (
                <DownloadIcon sx={{ color: "#037171" }} />
              )}
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton onClick={handleDeleteClicked}>
              <DeleteIcon color="error" />
            </IconButton>
          </Grid>
        </Grid>
        {confirmDelete && (
          <Grid item>
            <Button
              variant="contained"
              type="submit"
              onClick={deleteClicked}
              sx={{
                backgroundColor: "#037171",
                "&:hover": {
                  backgroundColor: "#FFF",
                  color: "#037171",
                },
              }}
            >
              Confirm Delete
            </Button>
            <Button
              variant="contained"
              type="submit"
              color="error"
              onClick={handleCancelClicked}
              sx={{
                marginLeft: "10px",
                "&:hover": {
                  backgroundColor: "#FFF",
                  color: "#E53935",
                },
              }}
            >
              Cancel
            </Button>
          </Grid>
        )}
      </Grid>
    </ResponsiveRow>
  );
}

/**
 * This is a component enhanced over the file upload to also include a list of files
 */
export function DocumentStore(props) {
  const [files, setFiles] = useState([]);
  const MAX_FILE_UPLOAD_MB = 10;
  const user = useSelector((state) => state.auth.user);
  const appMessage = useSelector((state) => state.message);
  const [filesCollection, setFilesCollection] = useState(props.files);
  const { horizontalLayout } = props;

  function uploadDocument() {
    if (props.type === "Work Order" || props.type === "Mobile Money Transfer") {
      if (
        isNullUndefined(props.item) ||
        isNullUndefined(props.item.id) ||
        isEmptyString(props.item.id)
      ) {
        issueWarnMessage(
          "Couldn't determine the identifier for uploading the document for this " +
            props.type +
            ". Try refresh the page."
        );
        return;
      }
    } else if (props.type === "Mobile Money Summary") {
      if (
        isNullUndefined(props.item) ||
        isNullUndefined(props.item.facilityId) ||
        isEmptyString(props.item.facilityId)
      ) {
        issueWarnMessage(
          "Couldn't determine the identifier for uploading the document for this " +
            props.type +
            ". Try refresh the page."
        );
        return;
      }
    } else {
      if (
        isNullUndefined(props.item) ||
        isNullUndefined(props.item.id) ||
        isEmptyString(props.item.id)
      ) {
        issueWarnMessage(
          "Couldn't determine the identifier for uploading the document for this " +
            props.type +
            ". Try refresh the page."
        );
        return;
      }
    }

    let id = props.item.id;

    if (isNullUndefined(files) || !isArray(files) || isEmptyArray(files)) {
      issueWarnMessage("No file selected for upload!");
      return;
    }

    for (let i = 0; i < files.length; i++) {
      let fileSizeMb = bytesToMegabytes(files[i].size);
      if (fileSizeMb > MAX_FILE_UPLOAD_MB) {
        issueWarnMessage(
          "The file selected has a size (" +
            fileSizeMb.toFixed(2) +
            " MB) which is larger than the upload limit (" +
            MAX_FILE_UPLOAD_MB +
            ") MB"
        );
        return;
      }
    }

    let formData = new FormData();

    if (props.type === "Work Order") {
      for (let i = 0; i < files.length; i++) {
        formData.append("file" + i, files[i]);
      }
      // console.log("workDocUpload: ", formData.get('workDocUpload'))
      uploadWorkOrderDocument(formData, id).then((response) => {
        issueResponseMessage(response);
        setFiles([]);
        fetchWorkOrder(props.item.id).then((data) => {
          setFilesCollection([...data.imageFiles, ...data.otherDocuments]);
        });
      });
    } else if (props.type === "Mobile Money Transfer") {
      for (let i = 0; i < files.length; i++) {
        formData.append("file" + i, files[i]);
      }
      uploadTransferDocuments(formData, id).then((response) => {
        issueResponseMessage(response);
        setFiles([]);
        viewTransfer(props.item.id).then((data) => {
          setFilesCollection([...data.imageFiles, ...data.otherDocuments]);
        });
      });
    } else if (props.type === "Mobile Money Summary") {
      let facilityId = props.item.facilityId;
      for (let i = 0; i < files.length; i++) {
        formData.append("file" + i, files[i]);
      }
      uploadMpesaDocument(formData, facilityId).then((response) => {
        issueResponseMessage(response);
        setFiles([]);
        viewMobileMoneyClient(props.item.facilityId).then((data) => {
          setFilesCollection([...data.imageFiles, ...data.otherDocuments]);
        });
        // props.closeView
      });
    } else if (props.type === "Meter Billing") {
      for (let i = 0; i < files.length; i++) {
        formData.append("file" + i, files[i]);
      }
      uploadBillingDocument(id, formData).then((response) => {
        issueResponseMessage(response);
        setFiles([]);
        setFilesCollection([
          ...response.meterBilling.imageFiles,
          ...response.meterBilling.otherDocuments,
        ]);
        // props.closeView
      });
    } else if (props.type === "Ownership") {
      for (let i = 0; i < files.length; i++) {
        formData.append("file" + i, files[i]);
      }
      uploadTitleDoc(id, formData).then((response) => {
        issueResponseMessage(response);
        setFiles([]);
        setFilesCollection([
          ...response.title.imageFiles,
          ...response.title.otherDocuments,
        ]);
        // props.closeView
      });
    } else if (props.type === "Subscription") {
      for (let i = 0; i < files.length; i++) {
        formData.append("file" + i, files[i]);
      }
      uploadSubscriptionDoc(id, formData).then((response) => {
        issueResponseMessage(response);
        setFiles([]);
        setFilesCollection([
          ...response.subscription.imageFiles,
          ...response.subscription.otherDocuments,
        ]);
        // props.closeView
      });
    } else if (props.type === "Lease") {
      for (let i = 0; i < files.length; i++) {
        formData.append("file" + i, files[i]);
      }
      uploadLeaseDocument(formData, id).then((response) => {
        issueResponseMessage(response);
        setFiles([]);
        setFilesCollection([
          ...response.lease.imageFiles,
          ...response.lease.otherDocuments,
        ]);
      });
    } else if (props.type === "Quotation") {
      for (let i = 0; i < files.length; i++) {
        formData.append("file" + i, files[i]);
      }
      uploadQuotationDocument(formData, id)
        .then((response) => {
          issueResponseMessage(response);
          setFiles([]);
          setFilesCollection([
            ...response.quotation.imageFiles,
            ...response.quotation.otherDocuments,
          ]);
        })
        .catch((error) => {});
    } else if (props.type === "Bill") {
      for (let i = 0; i < files.length; i++) {
        formData.append("file" + i, files[i]);
      }
      uploadBillDocument(formData, id).then((response) => {
        setFiles([]);
        setFilesCollection([
          ...response.bill.imageFiles,
          ...response.bill.otherDocuments,
        ]);
        issueResponseMessage(response);
      });
    } else if (props.type === "Payment") {
      for (let i = 0; i < files.length; i++) {
        formData.append("file" + i, files[i]);
      }
      uploadPaymentDocument(formData, id).then((response) => {
        setFiles([]);
        setFilesCollection([
          ...response.payment.imageFiles,
          ...response.payment.otherDocuments,
        ]);
        issueResponseMessage(response);
      });
    } else if (props.type === "Invoice") {
      for (let i = 0; i < files.length; i++) {
        formData.append("file" + i, files[i]);
      }
      uploadInvoiceDocument(formData, id).then((response) => {
        issueResponseMessage(response);
        setFiles([]);
        setFilesCollection([
          ...response.invoice.imageFiles,
          ...response.invoice.otherDocuments,
        ]);
      });
    } else if (props.type === "Listing") {
      for (let i = 0; i < files.length; i++) {
        formData.append("file" + i, files[i]);
      }
      uploadListingDocument(id, formData).then((response) => {
        issueResponseMessage(response);
        setFiles([]);
        setFilesCollection([
          ...response.listing.imageFiles,
          ...response.listing.otherDocuments,
        ]);
      });
    } else if (props.type === "Vacate Notice") {
      for (let i = 0; i < files.length; i++) {
        formData.append("file" + i, files[i]);
      }
      uploadVacateNoticeDocument(formData, id).then((response) => {
        setFiles([]);
        setFilesCollection([
          ...response.vacateNotice.imageFiles,
          ...response.vacateNotice.otherDocuments,
        ]);
        issueResponseMessage(response);
      });
    }
  }

  function deleteDocument(documentId) {
    // console.log("documentId", documentId)

    if (props.type === "Bill") {
      if (
        isNullUndefined(props.item) ||
        isNullUndefined(props.item.id) ||
        isEmptyString(props.item.id)
      ) {
        issueWarnMessage(
          "Couldn't determine the Bill identifier for uploading the document. Try refresh the page."
        );
        return;
      }

      const billId = props.item.id;

      if (isNullUndefined(documentId) || isEmptyString(documentId)) {
        issueWarnMessage(
          "Couldn't determine the document identifier while trying to deleting this document. Try refresh the page."
        );
        return;
      }

      let sendData = {
        billId: billId,
        documentId: documentId,
      };

      if (
        !isNullUndefined(documentId) ||
        !isEmptyString(documentId) ||
        !isNullUndefined(props.item) ||
        !isNullUndefined(props.item.id) ||
        !isEmptyString(props.item.id)
      ) {
        deleteBillDocument(sendData).then((response) => {
          setFiles([]);
          setFilesCollection([
            ...response.bill.imageFiles,
            ...response.bill.otherDocuments,
          ]);
          issueResponseMessage(response);
        });
      }
    }
    if (props.type === "Payment") {
      if (
        isNullUndefined(props.item) ||
        isNullUndefined(props.item.id) ||
        isEmptyString(props.item.id)
      ) {
        issueWarnMessage(
          "Couldn't determine the Payment identifier for uploading the document. Try refresh the page."
        );
        return;
      }

      const paymentId = props.item.id;

      if (isNullUndefined(documentId) || isEmptyString(documentId)) {
        issueWarnMessage(
          "Couldn't determine the document identifier while trying to deleting this document. Try refresh the page."
        );
        return;
      }

      let sendData = {
        paymentId: paymentId,
        documentId: documentId,
      };

      deletePaymentDocument(sendData).then((response) => {
        setFiles([]);
        setFilesCollection([
          ...response.payment.imageFiles,
          ...response.payment.otherDocuments,
        ]);
        issueResponseMessage(response);
      });
    }

    if (props.type === "Vacate Notice") {
      if (
          isNullUndefined(props.item) ||
          isNullUndefined(props.item.id) ||
          isEmptyString(props.item.id)
      ) {
        issueWarnMessage(
            "Couldn't determine the vacate Notice identifier for uploading the document. Try refresh the page."
        );
        return;
      }

      const vacateNoticeId = props.item.id;

      if (isNullUndefined(documentId) || isEmptyString(documentId)) {
        issueWarnMessage(
            "Couldn't determine the document identifier while trying to deleting this document. Try refresh the page."
        );
        return;
      }

      let sendData = {
        vacateNoticeId: vacateNoticeId,
        documentId: documentId,
      };

      deleteVacateNoteDocument(sendData).then((response) => {
        setFiles([]);
        setFilesCollection([
          ...response.vacateNotice.imageFiles,
          ...response.vacateNotice.otherDocuments,
        ]);
        issueResponseMessage(response);
      });
    }

    if (props.type === "Mobile Money Summary") {
      let facilityId = null;
      if (user.proxyFacility != null) {
        facilityId = user.proxyFacility;
      }
      let sendData = {
        facilityId: facilityId,
        documentId: documentId,
      };

      if (isNullUndefined(documentId) || isEmptyString(documentId)) {
        issueWarnMessage(
          "Couldn't determine the document identifier while trying to deleting this document. Try refresh the page."
        );
        return;
      }

      deleteMpesaDoc(sendData, facilityId).then((response) => {
        issueResponseMessage(response);
        viewMobileMoneyClient(props.item.facilityId).then((data) => {
          setFilesCollection([...data.imageFiles, ...data.otherDocuments]);
        });
      });
    }

    if (props.type === "Mobile Money Transfer") {
      if (
        isNullUndefined(props.item) ||
        isNullUndefined(props.item.id) ||
        isEmptyString(props.item.id)
      ) {
        issueWarnMessage(
          "Couldn't determine the Transfer identifier for uploading the document. Try refresh the page."
        );
        return;
      }

      const transferId = props.item.id;

      if (isNullUndefined(documentId) || isEmptyString(documentId)) {
        issueWarnMessage(
          "Couldn't determine the document identifier while trying to deleting this document. Try refresh the page."
        );
        return;
      }

      let sendData = {
        transferId: transferId,
        documentId: documentId,
      };

      deleteMMTransferDoc(sendData).then((response) => {
        issueResponseMessage(response);
        viewTransfer(props.item.id).then((data) => {
          setFilesCollection([...data.imageFiles, ...data.otherDocuments]);
        });
        setFiles([]);
      });
    }
    if (props.type === "Invoice") {
      if (
        isNullUndefined(props.item) ||
        isNullUndefined(props.item.id) ||
        isEmptyString(props.item.id)
      ) {
        issueWarnMessage(
          "Couldn't determine the Invoice identifier for uploading the document. Try refresh the page."
        );
        return;
      }

      const invoiceId = props.item.id;

      if (isNullUndefined(documentId) || isEmptyString(documentId)) {
        issueWarnMessage(
          "Couldn't determine the document identifier while trying to deleting this document. Try refresh the page."
        );
        return;
      }

      let sendData = {
        invoiceId: invoiceId,
        documentId: documentId,
      };

      deleteInvoiceDocument(sendData, invoiceId).then((response) => {
        issueResponseMessage(response);
        setFiles([]);
        setFilesCollection([
          ...response.invoice.imageFiles,
          ...response.invoice.otherDocuments,
        ]);
      });
    }

    if (props.type === "Work Order") {
      if (
        isNullUndefined(props.item) ||
        isNullUndefined(props.item.id) ||
        isEmptyString(props.item.id)
      ) {
        issueWarnMessage(
          "Couldn't determine the Work Order identifier for uploading the document. Try refresh the page."
        );
        return;
      }

      if (isNullUndefined(documentId) || isEmptyString(documentId)) {
        issueWarnMessage(
          "Couldn't determine the document identifier while trying to deleting this document. Try refresh the page."
        );
        return;
      }

      deleteWorkOrderDoc(documentId).then((response) => {
        issueResponseMessage(response);
        setFiles([]);
        fetchWorkOrder(props.item.id).then((data) => {
          setFilesCollection([...data.imageFiles, ...data.otherDocuments]);
        });
      });
    }
    if (props.type === "Meter Billing") {
      if (
        isNullUndefined(props.item) ||
        isNullUndefined(props.item.id) ||
        isEmptyString(props.item.id)
      ) {
        issueWarnMessage(
          "Couldn't determine the Meter Billing identifier for uploading the document. Try refresh the page."
        );
        return;
      }

      const meterBillingId = props.item.id;

      if (isNullUndefined(documentId) || isEmptyString(documentId)) {
        issueWarnMessage(
          "Couldn't determine the document identifier while trying to deleting this document. Try refresh the page."
        );
        return;
      }

      let sendData = {
        meterBillingId: meterBillingId,
        documentId: documentId,
      };

      deleteBillingDocument(sendData, meterBillingId).then((response) => {
        issueResponseMessage(response);
        setFiles([]);
        setFilesCollection([
          ...response.meterBilling.imageFiles,
          ...response.meterBilling.otherDocuments,
        ]);
      });
    }
    if (props.type === "Lease") {
      if (
        isNullUndefined(props.item) ||
        isNullUndefined(props.item.id) ||
        isEmptyString(props.item.id)
      ) {
        issueWarnMessage(
          "Couldn't determine the Lease identifier for uploading the document. Try refresh the page."
        );
        return;
      }

      const leaseId = props.item.id;

      if (isNullUndefined(documentId) || isEmptyString(documentId)) {
        issueWarnMessage(
          "Couldn't determine the document identifier while trying to deleting this document. Try refresh the page."
        );
        return;
      }

      let sendData = {
        leaseId: leaseId,
        documentId: documentId,
      };

      deleteLeaseDocument(sendData, leaseId).then((response) => {
        issueResponseMessage(response);
        setFiles([]);
        getLeaseDetails(props.item.id).then((data) => {
          setFilesCollection([...data.imageFiles, ...data.otherDocuments]);
        });
      });
    }

    if (props.type === "Ownership") {
      if (
        isNullUndefined(props.item) ||
        isNullUndefined(props.item.id) ||
        isEmptyString(props.item.id)
      ) {
        issueWarnMessage(
          "Couldn't determine the Title identifier for uploading the document. Try refresh the page."
        );
        return;
      }

      const titleId = props.item.id;

      if (isNullUndefined(documentId) || isEmptyString(documentId)) {
        issueWarnMessage(
          "Couldn't determine the document identifier while trying to deleting this document. Try refresh the page."
        );
        return;
      }

      let sendData = {
        titleId: titleId,
        documentId: documentId,
      };

      deleteTitleDoc(titleId, sendData).then((response) => {
        issueResponseMessage(response);
        setFiles([]);
        getTitleDetail(props.item.id).then((data) => {
          setFilesCollection([...data.imageFiles, ...data.otherDocuments]);
        });
      });
    }

    if (props.type === "Subscription") {
      if (
        isNullUndefined(props.item) ||
        isNullUndefined(props.item.id) ||
        isEmptyString(props.item.id)
      ) {
        issueWarnMessage(
          "Couldn't determine the Subscription identifier for deleting the document. Try refresh the page."
        );
        return;
      }

      const subscriptionId = props.item.id;

      if (isNullUndefined(documentId) || isEmptyString(documentId)) {
        issueWarnMessage(
          "Couldn't determine the document identifier while trying to deleting this document. Try refresh the page."
        );
        return;
      }

      let sendData = {
        subscriptionId: subscriptionId,
        documentId: documentId,
      };

      deleteSubscriptionDoc(subscriptionId, sendData).then((response) => {
        issueResponseMessage(response);
        setFiles([]);
        if (
          !isNullUndefined(response) &&
          !isNullUndefined(response.subscription)
        ) {
          setFilesCollection([
            ...response.subscription.imageFiles,
            ...response.subscription.otherDocuments,
          ]);
        }
      });
    }

    if (props.type === "Customer") {
      if (
        isNullUndefined(props.item) ||
        isNullUndefined(props.item.billingId) ||
        isEmptyString(props.item.billingId)
      ) {
        issueWarnMessage(
          "Couldn't determine the Customer identifier for uploading the document. Try refresh the page."
        );
        return;
      }

      if (isNullUndefined(documentId) || isEmptyString(documentId)) {
        issueWarnMessage(
          "Couldn't determine the document identifier while trying to deleting this document. Try refresh the page."
        );
        return;
      }

      let sendData = {
        meterBillingId: props.item.billingId,
        documentId: documentId,
      };

      deleteCustomerDoc(props.item.billingId, sendData).then((response) => {
        issueResponseMessage(response);
        setFiles(null);
        fetchCustomers(props.item.billingId).then((data) => {
          setFilesCollection([...data.imageFiles, ...data.otherDocuments]);
        });
      });
    } else if (props.type === "Listing") {
      if (
        isNullUndefined(props.item) ||
        isNullUndefined(props.item.id) ||
        isEmptyString(props.item.id)
      ) {
        issueWarnMessage(
          "Couldn't determine the Listing identifier. Try refresh the page."
        );
        return;
      }

      if (isNullUndefined(documentId) || isEmptyString(documentId)) {
        issueWarnMessage(
          "Couldn't determine the document identifier while trying to deleting this document. Try refresh the page."
        );
        return;
      }

      let sendData = {
        listingId: props.item.id,
        documentId: documentId,
      };

      deleteListingDocument(props.item.id, sendData).then((response) => {
        issueResponseMessage(response);
        setFiles([]);
        setFilesCollection([
          ...response.listing.imageFiles,
          ...response.listing.otherDocuments,
        ]);
      });
    }

    if (props.type === "Quotation") {
      if (
        isNullUndefined(props.item) ||
        isNullUndefined(props.item.id) ||
        isEmptyString(props.item.id)
      ) {
        issueWarnMessage(
          "Couldn't determine the quotation identifier for uploading the document. Try refresh the page."
        );
        return;
      }

      if (isNullUndefined(documentId) || isEmptyString(documentId)) {
        issueWarnMessage(
          "Couldn't determine the document identifier while trying to deleting this document. Try refresh the page."
        );
        return;
      }

      deleteQuotationDoc(documentId).then((response) => {
        issueResponseMessage(response);
        setFiles([]);
        fetchQuotation(props.item.id).then((data) => {
          setFilesCollection([...data.imageFiles, ...data.otherDocuments]);
        });
      });
    }
  }

  return (
    <ResponsiveRow
      container
      sx={{
        flexWrap: "nowrap",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        width: { xs: "100%", lg: props.maxWidth ? props.maxWidth : "100%" },
        height: {
          xs: "auto",
          lg: props.height
            ? props.height
            : // : horizontalLayout === true
              // ? "300px"
              "300px",
        },

        backgroundColor: "#fff",
        borderRadius: "10px",
        margin: {
          xs: "10px 0",
          lg: props.disableShadow && horizontalLayout ? 0 : "10px",
        },
        padding: 0,
        boxShadow: props.disableShadow
          ? "none"
          : "0 4px 12px 0 rgba(0,0,0,0.25)",

        overflow: "hidden",
      }}
    >
      <ResponsiveRow
        sx={{
          flexDirection: {
            xs: "column",
            lg: horizontalLayout === true ? "row" : "column",
          },
          flexWrap: "nowrap",
          justifyContent: "center",
          alignItems: "center",
          height: { xs: "100%", lg: "100%" },
          maxHeight: {
            xs: "100%",
            lg: horizontalLayout === true ? "300px" : "100%",
          },
          overflow: "hidden",
        }}
      >
        <ResponsiveRow
          sx={{
            flexWrap: "nowrap",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: horizontalLayout === true ? "100%" : "60%",
            width: "100%",
          }}
        >
          <Typography
            sx={{
              width: "100%",
              fontSize: "18px",
              color: "#037171",
              fontWeight: "800",
              textAlign: "start",
              marginBottom: "5px",
              padding: "20px auto 5px auto",
            }}
          >
            Documents
          </Typography>
          <Typography
            sx={{
              width: "100%",
              fontSize: "16px",
              fontWeight: "700",
              textAlign: "start",
              marginBottom: "5px",
              padding: "20px auto 10px auto",
            }}
          >
            Max 10MB
          </Typography>
          {appMessage.display && (
            <Alert severity={appMessage.type}>
              <AlertTitle>{titleCase(appMessage.type)}</AlertTitle>
              {appMessage.message}
            </Alert>
          )}
          <FileUpload
            id={"fileInput"}
            title={
              "Drag and drop files, or click to select files (Max 10MB each)"
            }
            buttonText={"Select"}
            value={files}
            onChange={setFiles}
            accept=".xlsx,.pdf,.jpeg,.png,.doc, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            sx={{
              height: "auto",
              maxHeight: "150px",
              width: "100%",
              padding: "1px",
              overflowY: "auto",
            }}
            buttonProps={{
              style: {
                display: files.length > 0 ? "none" : "inherit",
              },
            }}
            typographyProps={{
              style: {
                display: files.length > 0 ? "none" : "inherit",
              },
            }}
          />
          <ModalAcceptButton
            sx={{
              margin: { xs: "10px", lg: "10px 5px" },
              width: "70%",
            }}
            onClick={uploadDocument}
          >
            Upload
          </ModalAcceptButton>
        </ResponsiveRow>
        <ResponsiveRow
          sx={{
            backgroundColor: "#EFEFEF",
            borderRadius: "10px",
            height: horizontalLayout === true ? "100%" : "38%",
            width: "100%",
            margin: "15px 0 10px 0",
            padding: "15px 10px",
            flexDirection: "column",
            flexWrap: "nowrap",
          }}
        >
          <Typography
            variant="body2"
            fontWeight={700}
            textAlign="start"
            sx={{
              width: "100%",
            }}
          >
            Uploaded Documents
          </Typography>

          <ResponsiveRow
            sx={{
              flexDirection: horizontalLayout !== true ? "row" : "column",
              flexWrap: horizontalLayout !== true ? "wrap" : "nowrap",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              width: "100%",
              height: { xs: "200px", lg: "100%" },
              padding: "10px",
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            {filesCollection && filesCollection.length > 0 ? (
              filesCollection.map((fileItem, i) => (
                <FileItem
                  key={i}
                  fileItem={fileItem}
                  deleteFile={() => deleteDocument(fileItem.documentId)}
                  mode={props.type}
                  horizontalLayout={horizontalLayout}
                />
              ))
            ) : (
              <Typography
                variant="caption"
                fontWeight={700}
                sx={{
                  color: "#C6C5C5",
                  width: "100%",
                  height: "auto",
                  margin: "auto",
                  textAlign: "center",
                }}
              >
                There are no documents for this {props.type}
              </Typography>
            )}
          </ResponsiveRow>
        </ResponsiveRow>
      </ResponsiveRow>
    </ResponsiveRow>
  );
}
