import {
    CREATE_COMPLEX_URL,
    FAC_COMP_ORG_URL,
    LIST_ORG_COMPLEX_URL,
    UPDATE_COMPLEX_URL
} from "../constants/url.constants"
import {serviceAPI} from "./service"
import {handleErrorResponse} from "../actions/message"

export const getComplexes = () => {
    return serviceAPI.get(LIST_ORG_COMPLEX_URL)
        .then((response) => {
            return response.data.complexes
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getFacilityComplexOrganisation = (complexId) => {
    return serviceAPI.get(FAC_COMP_ORG_URL + complexId)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const createComplex = (data) => {
    return serviceAPI.post(CREATE_COMPLEX_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const updateComplex = (data) => {
    return serviceAPI.post(UPDATE_COMPLEX_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}
