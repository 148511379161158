import Grid from "@mui/material/Grid"
import loginImage from "../images/login-image.png"
import {Alert, Box, FormControl, InputLabel, OutlinedInput, Paper, Typography} from "@mui/material"
import LoadingButton from "@mui/lab/LoadingButton"
import {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {useNavigate} from "react-router-dom"
import {isEmptyObject, isEmptyString, isNullUndefined} from "../util/util"
import {handleErrorResponse, setMessage} from "../actions/message"
import {changePassword, resetPassword} from "../services/passwords.service"
import {useLocation} from "react-router";

export default function Password() {
    /*
 * STATES SECTION:
 * This is where all the pages state will be defined.
 * Namely:
 *  - Remember Me Radio Button => isRemembered
 *  - Email Address Input => emailAddr
 *  - Password Input => password
 * They use the useState hook and mainly keep track of the form inputs.
 * They can also be used for form validation, if needed, through their setState callbacks
 */

    const [userPassword, setPassword] = useState("")
    const [userPassword2, setPassword2] = useState("")
    const [user, setUser] = useState(null)
    const param = new URLSearchParams(useLocation().search)
    const [loading, setLoading] = useState(false)
    const {isLoggedIn} = useSelector((state) => state.auth)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        if (isLoggedIn) {
            navigate("/dashboard")
        }else{
            let token = param.get('token')
            if (isNullUndefined(token) || isEmptyString(token)) {
                navigate("/dashboard")
            } else {
                if (isNullUndefined(user)) {
                    changePassword(token)
                        .then(user => {
                            setUser(user)
                        })
                        .catch((error) => {
                            handleErrorResponse(error)
                            console.log('Error: ', error)
                            setUser({})
                        })
                }
            }
        }

        // changePassword(param.get('token'))
        //     .then(user => setUser(user))
        //     .catch((error) => {
        //         handleErrorResponse(error)
        //         console.log('Error: ', error)
        //     })
    }, [isLoggedIn, param])

    /**
     * FORM SUBMIT: onSubmit
     * This is the form submit function that takes in the event
     * and reads the values of the states
     */

    const onSubmit = (e) => {
        e.preventDefault()
        if (isEmptyString(userPassword)) {
            dispatch(setMessage({
                type: "warning",
                message: "Please input the new password"
            }))
            return false
        }
        if (isEmptyString(userPassword2)) {
            dispatch(setMessage({
                type: "warning",
                message: "Please confirm the new password"
            }))
            return false
        }
        if (userPassword !== userPassword2) {
            dispatch(setMessage({
                type: "warning",
                message: "Please ensure the 2 passwords match!"
            }))
            return false
        }

        let sendData = {
            token: param.get('token'),
            password: userPassword,
            confirmPassword: userPassword2
        }

        console.log(sendData)
        resetPassword(JSON.stringify(sendData))
            .then(response => {
                let type = response.status
                if (type === "warn") {
                    type = "warning"
                }
                dispatch(setMessage({
                    type: type,
                    message: response.message
                }))
                setLoading(false)
                setPassword("")
                setPassword2("")
                navigate("/")
            })
            .catch((error) => {
                setLoading(false)
                console.log('error', error)
            })

        setLoading(true)
    }

    return (
        <Grid
            sx={{
                height: "100vh",
                width: "100wv",
                margin: "0",
            }}
            container
        >
            <Grid
                item
                xs={false}
                sm={false}
                md={5}
                lg={8}
                sx={{
                    backgroundImage: `url(${loginImage})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    width: "100%",
                }}
            />
            <Grid item sm={12} md={7} lg={4}>
                <Paper
                    sx={{
                        height: "100vh",
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "space-between",
                        justifyContent: "center",
                        margin: "auto",
                    }}
                >
                    <Box sx={{width: "100%", margin: "10px auto 10px auto"}}>
                        <img
                            src={require("../images/kodi-logo.png")}
                            alt=""
                            width={"30%"}
                        />
                    </Box>

                    <Box sx={{width: "80%", margin: "15px auto"}}>
                        <Typography
                            variant="h2"
                            color="primary.dark"
                            sx={{fontWeight: 700}}
                        >
                            Set Password
                        </Typography>
                    </Box>
                    {isEmptyObject(user) ? (
                        <Alert severity="error"
                               sx={{marginLeft:'10%', marginRight:'10%'}}>
                            This link appears to have expired. Please click <a href = "/forgotPassword">here</a> to have a new link sent to your email
                        </Alert>
                    ) : (
                        <>
                            <Box sx={{width: "80%", margin: "10px auto"}}>
                                <Typography variant="body2">
                                    Welcome to SmartKodi, <b>{user && user.firstName} {user && user.lastName}</b>.
                                </Typography>
                                <Typography variant="body2">
                                    Use the fields below to set your password. You will then be redirected to the login page to
                                    log in with your new password.
                                </Typography>
                            </Box>

                            <Box sx={{width: "80%", margin: "20px auto"}}>
                                <form onSubmit={onSubmit}>
                                    <FormControl sx={{width: "80%", marginBottom: "20px"}}>
                                        <InputLabel htmlFor="userEmail">
                                            <Typography
                                                variant="body2"
                                                sx={{fontWeight: "medium"}}
                                                color="primary.light"
                                            >
                                                Enter New Password
                                            </Typography>
                                        </InputLabel>
                                        <OutlinedInput
                                            id="password"
                                            label="Enter New Password"
                                            type="password"
                                            required
                                            aria-describedby="email-helper-text"
                                            color="secondary"
                                            onChange={(event) => setPassword(event.target.value)}
                                            value={userPassword}
                                        />
                                    </FormControl>{" "}
                                    <FormControl sx={{width: "80%"}}>
                                        <InputLabel htmlFor="userEmail">
                                            <Typography
                                                variant="body2"
                                                sx={{fontWeight: "medium"}}
                                                color="primary.light"
                                            >
                                                Confirm New Password
                                            </Typography>
                                        </InputLabel>
                                        <OutlinedInput
                                            id="password2"
                                            label="Confirm Password"
                                            type="password"
                                            required
                                            aria-describedby="email-helper-text"
                                            color="secondary"
                                            onChange={(event) => setPassword2(event.target.value)}
                                            value={userPassword2}
                                        />
                                    </FormControl>
                                    <LoadingButton
                                        variant="contained"
                                        type="submit"
                                        loading={loading}
                                        onClick={onSubmit}
                                        sx={{
                                            width: "80%",
                                            backgroundColor: "#037171",
                                            margin: "10px auto",
                                        }}
                                    >
                                        Set Password
                                    </LoadingButton>
                                </form>
                            </Box>
                        </>
                    )}
                </Paper>
            </Grid>
        </Grid>
    )
}
