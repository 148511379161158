import {serviceAPI, serviceAPIDownloadFile} from "./service"
import {EXPORT_TENANTS, LOAD_OWNERS, SAVE_OWNERS, TOTAL_OWNERS} from "../constants/url.constants"
import {handleErrorResponse} from "../actions/message"

export const loadOwners = (name, pageNo) => {
    let url = LOAD_OWNERS + name + "&pageNo=" + pageNo
    return serviceAPI
        .get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const totalOwners = (name) => {
    let url = TOTAL_OWNERS + name
    return serviceAPI
        .get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const saveOwner = (data) => {
    return serviceAPI
        .post(SAVE_OWNERS, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const exportTenants = (tenantType) => {
    return serviceAPIDownloadFile.get(EXPORT_TENANTS + tenantType)
}