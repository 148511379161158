import { useEffect, useState } from "react";
import { ResponsiveRow } from "../../../constants/layout.constants";
import Permit from "../../../util/permit";
import {
  TopBarContainedBrandButton,
  TopBarContainedWarningButton,
  TopBarContainer,
} from "../../../constants/component.constants";
import { FormControl } from "@mui/material";
import {
  deleteProperty,
  fetchSubscription,
} from "../../../services/subscription.service";
import SubscriberCard from "./subscriberCard";
import TransactionHistory from "../../transactionHistory";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { printClientStatement } from "../../../services/print.service";
import PropertyModal from "../rentals/properties/propertyModal";
import { getFacility } from "../../../services/property.service";
import NoteLogsCard from "../../cards/noteLogsCard";
import DeletePropertyModal from "./deleteProperty";
import { handleInfoResponse } from "../../../actions/message";
import SubscriptionPropertyCard from "./subscriptionPropertyCard";
import {isEmptyString, sortJSONArray} from "../../../util/util";
import SubscriptionLog from "./subscriptionLog";
import {DocumentStore} from "../../documentStore";

export default function SubscriptionView(props) {
  const [statementYear, setStatementYear] = useState(new Date());
  const [subscription, setSubscription] = useState(props.subscription);
  const [editProperty, setEditProperty] = useState(false);
  const [propertyDelete, setPropertyDelete] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState(null);

  useEffect(() => {
    fetchSubscription(props.subscription.id).then((response) => {
      setSubscription(response.data)
    })
  }, [props.subscription]);

  const printStatement = () => {
    printClientStatement(subscription.id, dayjs(statementYear).year());
  };

  const handleVoidClick = (item) => {
    //void a transaction item
  };

  const addProperty = () => {
    setSelectedProperty({});
    setEditProperty(true);
  };

  const handleEditProperty = (property) => {
    getFacility(property.id, true).then((facility) => {
      setSelectedProperty(facility);
      setEditProperty(true);
    });
  };

  const handlePropertyModalClose = (facilityId) => {
    setEditProperty(false);
    setSelectedProperty(null);
    //reload the subscription
    fetchSubscription(props.subscription.id).then((response) => {
      let data = response.data;
      setSubscription(data);
    });
  };

  const confirmPropertyDelete = () => {
    deleteProperty(selectedProperty.id).then((data) => {
      setSelectedProperty(null);
      setPropertyDelete(false);
      handleInfoResponse(data);
      fetchSubscription(props.subscription.id).then((response) => {
        let data = response.data;
        setSubscription(data);
      });
    });
  };

  const handleDeleteProperty = (property) => {
    setSelectedProperty(property);
    setPropertyDelete(true);
  };

  return (
    <Permit
      roles="SYSAD,CS"
      noPermittedServiceMessage={{
        title: "Access Denied",
        body: (
          <>
            Your current role and/or facility is not permitted to view this
            page. <br /> Please contact your system admin if you feel this is an
            error.
          </>
        ),
      }}
    >
      {editProperty && (
        <PropertyModal
          handlePropertyModalClose={handlePropertyModalClose}
          propertyModal={editProperty}
          selectedProperty={selectedProperty}
          subscriptionId={subscription.id}
          subscriptionOp={true}
        />
      )}
      {propertyDelete && (
        <DeletePropertyModal
          confirmDelete={confirmPropertyDelete}
          setDeleteModal={setPropertyDelete}
          deleteModal={propertyDelete}
          selectedProperty={selectedProperty}
        />
      )}
      <ResponsiveRow
        container
        sx={{
          flexDirection: "column",
          flexWrap: "nowrap",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          width: "100%",
          height: "auto",
          marginBottom: "20px",
        }}
      >
        <TopBarContainer
          container
          sx={{
            flexDirection: { xs: "column", lg: "row" },
            flexWrap: "nowrap",
            width: { xs: "100%", lg: "80%" },
            height: "auto",
            boxShadow: "0 4px 5px 0 rgba(0,0,0,0.5)",
          }}
        >
          <ResponsiveRow
            sx={{
              width: { xs: "100%", slg: "20%" },
              justifyContent: { xs: "center", lg: "space-between" },
              alignItems: "center",
            }}
          >
            <FormControl
              sx={{
                width: { xs: "100%", lg: "25%" },
                marginBottom: "10px",
              }}
            >
                <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale="en-gb"
                >
                    <DatePicker
                        sx={{ width: { xs: "100%", lg: "90%" } }}
                        id={"statementYear"}
                        value={
                            isEmptyString(statementYear)
                                ? null
                                : dayjs(statementYear)
                        }
                        label="Statement Year"
                        isRequired={false}
                        views={["year"]}
                        minDate={dayjs(new Date("2017"))}
                        maxDate={dayjs(new Date())}
                        onChange={(newDate) => {
                            // console.log("date Value ", newDate)
                            let date = new Date(newDate);
                            setStatementYear(date);
                        }}
                    />
                </LocalizationProvider>
            </FormControl>
            <ResponsiveRow
              sx={{
                flexDirection: { xs: "column", lg: "row" },
                flexWrap: "nowrap",
                justifyContent: { xs: "center", lg: "flex-end" },
                alignItems: "center",
                padding: 0,
                width: { xs: "100%", lg: "75%" },
              }}
            >
              <TopBarContainedBrandButton
                variant="contained"
                type="submit"
                onClick={() => printStatement()}
                sx={{
                  width: { xs: "100%", lg: "auto" },
                }}
              >
                Print statement for Subscription
              </TopBarContainedBrandButton>

              <Permit roles='SYSAD,CS'>
                <TopBarContainedBrandButton
                  variant="contained"
                  type="submit"
                  onClick={() => addProperty()}
                  sx={{
                    width: { xs: "100%", lg: "200px" },
                  }}
                >
                  Add a Property
                </TopBarContainedBrandButton>
              </Permit>

              <TopBarContainedWarningButton
                variant="contained"
                type="submit"
                onClick={() => props.closeView()}
                sx={{
                  width: { xs: "100%", lg: "200px" },
                }}
              >
                Close
              </TopBarContainedWarningButton>
            </ResponsiveRow>
          </ResponsiveRow>
        </TopBarContainer>

        <ResponsiveRow
          sx={{
            flexDirection: "row",
            flexWrap: "nowrap",
            height: "auto",
            width: "100%",
            justifyContent: { xs: "center", alignItems: "flex-end" },
            alignItems: "center",
            padding: 0,
          }}
        >
          <SubscriberCard subscription={subscription} />
        </ResponsiveRow>
          <ResponsiveRow
              sx={{
                  flexDirection: { xs: "column", lg: "row" },
                  flexWrap: { xs: "nowrap", lg: "wrap" },
                  height: "auto",
                  width: "100%",
                  padding: 0,
                  margin: 0,
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
              }}
          >
              <TransactionHistory
                  transactionMode={"Client"}
                  contract={subscription}
                  handleVoidClick={handleVoidClick}
                  hideVoid={true}
                  width={"66%"}
                  height="610px"
              />
              <DocumentStore
                  height="610px"
                  maxWidth="32%"
                  files={[
                      ...subscription.imageFiles,
                      ...subscription.otherDocuments,
                  ]}
                  item={subscription}
                  type={"Subscription"}
              />
          </ResponsiveRow>
        <ResponsiveRow
          sx={{
            flexDirection: { xs: "column", lg: "row" },
            flexWrap: "nowrap",
            height: "auto",
            width: "100%",
            padding: 0,
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
            <SubscriptionLog
                subscription={subscription}
                width={"66%"}
                height="500px"
            />

          <NoteLogsCard
            notesMode={"Client"}
            contract={subscription}
            width={"32%"}
            height="500px"
          />
        </ResponsiveRow>
        <ResponsiveRow
          sx={{
            flexDirection: { xs: "column", lg: "row" },
            flexWrap: { xs: "nowrap", lg: "wrap" },
            height: "auto",
            width: "100%",
            padding: 0,
            margin: 0,
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          {subscription?.properties?.length > 0 &&
            subscription?.properties?.sort(sortJSONArray("name", true)).map((property, i) => (
              <SubscriptionPropertyCard
                property={property}
                key={i}
                handleEditProperty={handleEditProperty}
                handleDeleteProperty={handleDeleteProperty}
              />
            ))}
        </ResponsiveRow>
      </ResponsiveRow>
    </Permit>
  );
}
