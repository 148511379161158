// import React, {useEffect, useState} from "react"
import {useEffect, useState} from "react";
import {
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import {ResponsiveRow} from "../../../../constants/layout.constants";
import ModalContainerModule from "../../../modules/modalContainer";
import {Delete} from "@mui/icons-material";
import {ModalAcceptButton} from "../../../../constants/component.constants";
import {isArray, isEmptyArray, isEmptyString, isNullUndefined, tryParseFloat,} from "../../../../util/util";
import {getVendorOptions, getWorkOrdersForQuotation,} from "../../../../services/list.service";
import {issueWarnMessage} from "../../../../actions/message";

import FileUpload from "react-material-file-upload";
import MUIAutoCompleteForm from "../../../inputs/MUIautoCompleteForm";

export default function CreateQuotationModal(props) {
    const [workOrderOptions, setWorkOrderOptions] = useState([]);
    const [vendorOptions, setVendorOptions] = useState([]);
    const [quotationItems, setQuotationItems] = useState([]);
    const [workOrderId, setWorkOrderId] = useState("");
    const [description, setDescription] = useState("");
    const [vendorId, setVendorId] = useState("");
    const [files, setFiles] = useState([]);

    useEffect(() => {
        // fetch work order
        getWorkOrdersForQuotation().then((workOrders) =>
            setWorkOrderOptions(workOrders)
        );
        // fetch vendors
        getVendorOptions().then((vendorOptions) => {
            setVendorOptions(vendorOptions);
        });

        if (!isNullUndefined(props.selectedQuotation)) {
            setDescription(props.selectedQuotation.description);
            setWorkOrderId(props.selectedQuotation.workOrderId);
            if (
                !isNullUndefined(props.selectedQuotation.personId) &&
                !isEmptyString(props.selectedQuotation.personId)
            ) {
                setVendorId(props.selectedQuotation.personId + "_P");
            }
            if (
                !isNullUndefined(props.selectedQuotation.organizationId) &&
                !isEmptyString(props.selectedQuotation.organizationId)
            ) {
                setVendorId(props.selectedQuotation.organizationId + "_O");
            }
            setQuotationItems(props.selectedQuotation.quotationItems);
        }
    }, [props]);

    function handleSaveQuotation() {
        let selectedQuotation = props.selectedQuotation;
        selectedQuotation.workOrderId = workOrderId;
        selectedQuotation.description = description;
        selectedQuotation.quotationItems = quotationItems;
        if (vendorId.endsWith("_P")) {
            selectedQuotation.organizationId = "";
            selectedQuotation.personId = vendorId.replace("_P", "");
        }
        if (vendorId.endsWith("_O")) {
            selectedQuotation.organizationId = vendorId.replace("_O", "");
            selectedQuotation.personId = "";
        }
        if (validateQuotation(selectedQuotation)) {
            let formData = new FormData();
            if (!isNullUndefined(files) && isArray(files) && !isEmptyArray(files)) {
                for (let i = 0; i < files.length; i++) {
                    formData.append("file" + i, files[i]);
                }
            }
            formData.append("selectedQuotation", JSON.stringify(selectedQuotation));
            props.saveQuotation(selectedQuotation, formData);
            setFiles([]);
        }
    }

    function validateQuotation(selectedQuotation) {
        if (
            (isNullUndefined(selectedQuotation.personId) &&
                isNullUndefined(selectedQuotation.organizationId)) ||
            (isEmptyString(selectedQuotation.organizationId) &&
                isEmptyString(selectedQuotation.personId))
        ) {
            issueWarnMessage("A vendor must be selected");
            return false;
        }
        if (
            isNullUndefined(selectedQuotation.description) ||
            isEmptyString(selectedQuotation.description)
        ) {
            issueWarnMessage("A description must be provided");
            return false;
        }
        if (selectedQuotation.description.length > 400) {
            issueWarnMessage("Description cannot be more than 400 characters");
            return false;
        }

        if (isArray(selectedQuotation.quotationItems)) {
            if (selectedQuotation.quotationItems.length < 1) {
                issueWarnMessage(
                    "You must add at least one quotation item to save this Quotation."
                );
                return false;
            }
            for (let quotationItem of selectedQuotation.quotationItems) {
                if (
                    isNullUndefined(quotationItem.unitPrice) ||
                    isNullUndefined(quotationItem.quantity) ||
                    tryParseFloat(quotationItem.unitPrice.toString(), -1) < 1 ||
                    tryParseFloat(quotationItem.quantity.toString(), -1) < 1
                ) {
                    issueWarnMessage(
                        "unitPrice or quantity is invalid. Cannot be below 0"
                    );
                    return false;
                }

                if (
                    !isNullUndefined(quotationItem.otherCharges) &&
                    !isEmptyString(quotationItem.otherCharges)
                ) {
                    if (tryParseFloat(quotationItem.otherCharges.toString(), 0) < 0) {
                        issueWarnMessage("other Charges is invalid. Cannot be below 0");
                        return false;
                    }
                }

                if (
                    isNullUndefined(quotationItem.memo) ||
                    isEmptyString(quotationItem.memo)
                ) {
                    issueWarnMessage("Memo is invalid. And is required field");
                    return false;
                }

                if (quotationItem.memo.length > 400) {
                    issueWarnMessage("Memo cannot be more than 400 characters");
                    return false;
                }
            }
        } else {
            issueWarnMessage("Quotation Items is required");
            return false;
        }
        return true;
    }

    function handleAddQuotationItem() {
        const newItem = {
            id: null,
            quotationId: null,
            workOrderDetailId: null,
            unitPrice: "",
            quantity: "",
            subTotal: "",
            applyVAT: false,
            otherCharges: "",
            total: 0,
            memo: "",
        };
        setQuotationItems([...quotationItems, newItem]);
    }

    function handleMemoChange(ev, index) {
        const items = quotationItems.map((item, i) => {
            if (i === index) {
                item.memo = ev.target.value;
            }
            return item;
        });

        setQuotationItems(items);
    }

    function handleUnitPriceChange(ev, index) {
        const items = quotationItems.map((item, i) => {
            if (i === index) {
                let quantity = tryParseFloat(item.quantity, 0);
                let unitPrice = tryParseFloat(ev.target.value, 0);
                let otherCharges = tryParseFloat(item.otherCharges, 0);
                item.unitPrice = unitPrice;
                item.total = unitPrice * quantity + otherCharges;
            }
            return item;
        });

        setQuotationItems(items);
    }

    function handleQuantityChange(ev, index) {
        const items = quotationItems.map((item, i) => {
            if (i === index) {
                let quantity = tryParseFloat(ev.target.value, 0);
                let unitPrice = tryParseFloat(item.unitPrice, 0);
                let otherCharges = tryParseFloat(item.otherCharges, 0);
                let subTotal = unitPrice * quantity;
                item.subTotal = subTotal;
                item.quantity = quantity;
                item.otherCharges = otherCharges;
                item.total = subTotal + otherCharges;
            }
            return item;
        });

        setQuotationItems(items);
    }

    function handleTaxedChange(index) {
        const items = quotationItems.map((item, i) => {
            if (i === index) {
                item.applyVAT = !item.applyVAT;
            }
            return item;
        });

        setQuotationItems(items);
    }

    function handleOtherChargesChange(ev, index) {
        const items = quotationItems.map((item, i) => {
            if (i === index) {
                let quantity = tryParseFloat(item.quantity, 0);
                let unitPrice = tryParseFloat(item.unitPrice, 0);
                let otherCharges = tryParseFloat(ev.target.value, 0);
                let subTotal = unitPrice * quantity;
                item.subTotal = subTotal;
                item.otherCharges = otherCharges;
                item.total = subTotal + otherCharges;
            }
            return item;
        });

        setQuotationItems(items);
    }

    function handleRemoveQuotationItem(index) {
        const currentList = quotationItems;
        const updatedList = currentList.filter((item, i) => {
            return i !== index;
        });

        setQuotationItems(updatedList);
    }

    const quotationItemRows = () => {
        return quotationItems.map((item, i) => {
            let disabled =
                isNullUndefined(quotationItems[i].workOrderDetailId) ||
                isEmptyString(quotationItems[i].workOrderDetailId);
            return (
                <TableRow key={i}>
                    <TableCell>
                        <FormControl sx={{width: "100%"}}>
                            <InputLabel>Memo</InputLabel>
                            <OutlinedInput
                                label="Memo"
                                sx={{width: "90%"}}
                                id={"memo"}
                                disabled={!disabled}
                                value={
                                    quotationItems && quotationItems[i] && quotationItems[i].memo
                                }
                                onChange={(event) => handleMemoChange(event, i)}
                            />
                        </FormControl>
                    </TableCell>{" "}
                    <TableCell>
                        <FormControl sx={{width: "100%"}}>
                            <InputLabel>Unit Price</InputLabel>
                            <OutlinedInput
                                label="Unit Price"
                                sx={{width: "90%"}}
                                id={"unitPrice"}
                                type="number"
                                disabled={!disabled}
                                value={
                                    quotationItems &&
                                    quotationItems[i] &&
                                    quotationItems[i].unitPrice
                                }
                                onChange={(event) => handleUnitPriceChange(event, i)}
                            />
                        </FormControl>
                    </TableCell>
                    <TableCell>
                        <FormControl sx={{width: "100%"}}>
                            <InputLabel>Quantity</InputLabel>
                            <OutlinedInput
                                label="Quantity"
                                sx={{width: "90%"}}
                                id={"quantity"}
                                type="number"
                                disabled={!disabled}
                                value={
                                    quotationItems &&
                                    quotationItems[i] &&
                                    quotationItems[i].quantity
                                }
                                onChange={(event) => handleQuantityChange(event, i)}
                            />
                        </FormControl>
                    </TableCell>
                    <TableCell>
                        <FormControl sx={{width: "100%"}}>
                            {/* <FormLabel>Apply Tax</FormLabel> */}
                            <Switch
                                // label="Apply Tax"
                                disabled={!disabled}
                                checked={
                                    quotationItems &&
                                    quotationItems[i] &&
                                    quotationItems[i].applyVAT
                                }
                                onChange={() => handleTaxedChange(i)}
                                inputProps={{"aria-label": "controlled"}}
                            />
                        </FormControl>
                    </TableCell>
                    <TableCell>
                        <FormControl sx={{width: "100%"}}>
                            <InputLabel>Other Charges</InputLabel>
                            <OutlinedInput
                                label="Other Charges"
                                sx={{width: "90%"}}
                                id={"otherCharges"}
                                type="number"
                                disabled={!disabled}
                                value={
                                    quotationItems &&
                                    quotationItems[i] &&
                                    quotationItems[i].otherCharges
                                }
                                onChange={(event) => handleOtherChargesChange(event, i)}
                            />
                        </FormControl>
                    </TableCell>
                    <TableCell>
                        <Typography variant="h6">
                            {quotationItems && quotationItems[i] && quotationItems[i].total
                                ? quotationItems[i].total
                                : 0}
                        </Typography>
                    </TableCell>{" "}
                    <TableCell>
                        <IconButton
                            disabled={!disabled}
                            onClick={() => handleRemoveQuotationItem(i)}
                            sx={{margin: "auto 0"}}
                        >
                            <Delete sx={{color: "red"}}/>
                        </IconButton>
                    </TableCell>
                </TableRow>
            );
        }, this);
    };

    return (
      <ModalContainerModule
        size="sm"
        accept={handleSaveQuotation}
        cancel={props.closeModal}
        openModal={props.openModal}
        modalTitle={
          props.task === "Edit" ? "Edit Quotation" : "Create Quotation"
        }
        acceptButtonText={props.task === "Edit" ? "Edit" : "Create"}
      >
        <ResponsiveRow
          sx={{ width: "100%", height: "auto", flexDirection: "column" }}
        >
          <Typography
            variant="h5"
            color="#037171"
            sx={{ marginBottom: "20px" }}
          >
            Quotation Information
          </Typography>

          <ResponsiveRow>
            <FormControl
              sx={{
                width: { xs: "100%", lg: "30%" },
                marginBottom: "20px",
              }}
            >
              <InputLabel>Vendor</InputLabel>
              <Select
                label="Vendor"
                value={vendorId}
                disabled={
                  props &&
                  props.selectedQuotation &&
                  !props.selectedQuotation.editable
                }
                onChange={(ev) => setVendorId(ev.target.value)}
                sx={{ width: "90%" }}
              >
                {vendorOptions && vendorOptions.length > 0 ? (
                  vendorOptions.map(function (v, i) {
                    return (
                      <MenuItem key={i} value={v.value}>
                        {v.label}
                      </MenuItem>
                    );
                  }, this)
                ) : (
                  <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
                )}
              </Select>
            </FormControl>

            {props &&
              props.selectedQuotation &&
              !props.selectedQuotation.editable && (
                <FormControl
                  sx={{
                    width: { xs: "100%", lg: "30%" },
                    marginBottom: "20px",
                  }}
                >
                  <InputLabel>Work Order</InputLabel>
                  <OutlinedInput
                    label="Work Order"
                    sx={{ width: "90%" }}
                    id={"workOrder"}
                    disabled={true}
                    value={
                      props &&
                      props.selectedQuotation &&
                      props.selectedQuotation.workOrder &&
                      props.selectedQuotation.workOrder.refNumber
                    }
                  />
                </FormControl>
              )}

            {props &&
              props.selectedQuotation &&
              props.selectedQuotation.editable && (
                <FormControl
                  sx={{
                    width: { xs: "100%", lg: "30%" },
                    marginBottom: "20px",
                  }}
                >
                  {/* <AutoCompleteInput
                  menuId="workOrderSelector"
                  componentLabel="Work Order"
                  componentDataset={workOrderOptions}
                  selectFunction={(ev) => setWorkOrderId(ev.value)}
                  selectedOption={workOrderId}
                /> */}

                  <MUIAutoCompleteForm
                    label="Work Order"
                    itemsData={workOrderOptions}
                    currentlySelected={workOrderId}
                    updateSelected={(ev) => setWorkOrderId(ev.value)}
                    maxListHeight={"200px"}
                  />
                </FormControl>
              )}

            <FormControl
              sx={{ width: { xs: "100%", lg: "40%" }, marginBottom: "20px" }}
            >
              <InputLabel>Description</InputLabel>
              <OutlinedInput
                label="description"
                value={description}
                onChange={(ev) => setDescription(ev.target.value)}
                sx={{ width: "95%" }}
                multiline
                rows={5}
              />
            </FormControl>

            {props &&
              props.selectedQuotation &&
              (isNullUndefined(props.selectedQuotation.id) ||
                isEmptyString(props.selectedQuotation.id)) && (
                <FormControl
                  item
                  sx={{
                    width: { xs: "100%", lg: "100%" },
                    marginBottom: "10px",
                  }}
                >
                  <InputLabel>
                    Upload Quotation Documents (Max 10 MB Each)
                  </InputLabel>
                  <FileUpload
                    value={files}
                    onChange={setFiles}
                    accept=".xlsx,.pdf,.jpeg,.png,.doc, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  />
                </FormControl>
              )}
          </ResponsiveRow>
          <Typography
            variant="h5"
            color="#037171"
            sx={{ marginBottom: "20px" }}
          >
            Quotation Items
          </Typography>
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{ width: "15%", color: "#037171", fontWeight: "bold" }}
                  >
                    Memo
                  </TableCell>{" "}
                  <TableCell
                    sx={{ width: "10%", color: "#037171", fontWeight: "bold" }}
                  >
                    Unit Price
                  </TableCell>
                  <TableCell
                    sx={{ width: "10%", color: "#037171", fontWeight: "bold" }}
                  >
                    Quantity
                  </TableCell>
                  <TableCell
                    sx={{ width: "7%", color: "#037171", fontWeight: "bold" }}
                  >
                    Taxed
                  </TableCell>
                  <TableCell
                    sx={{ width: "10%", color: "#037171", fontWeight: "bold" }}
                  >
                    Other Charges
                  </TableCell>
                  <TableCell
                    sx={{ width: "10%", color: "#037171", fontWeight: "bold" }}
                  >
                    Total
                  </TableCell>
                  <TableCell
                    sx={{ width: "10%", color: "#037171", fontWeight: "bold" }}
                  >
                    Delete
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{quotationItemRows()}</TableBody>
            </Table>
          </TableContainer>
          <ResponsiveRow
            sx={{
              flexDirection: "row",
              justifyContent: "flex-end",
              width: "100%",
              height: "auto",
            }}
          >
            <ModalAcceptButton
              onClick={handleAddQuotationItem}
              sx={{ width: "200px" }}
            >
              Add Row
            </ModalAcceptButton>
          </ResponsiveRow>
        </ResponsiveRow>
      </ModalContainerModule>
    );
}
