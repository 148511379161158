import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid";
import BillingRemindersTabBar from "../bars/billingRemindersTabBar";
import { useCallback, useEffect, useState } from "react";
import {
  formatCurrency,
  isEmptyArray,
  isEmptyString,
  titleCase,
} from "../../util/util";
import {
  deleteBillReminderData,
  getBillReminders,
  saveBillReminderData,
} from "../../services/billAndPayment.service";
import { Delete, Edit } from "@mui/icons-material";
import CreateBillReminder from "../modals/createBillReminder";
import ConfirmationModal from "../modals/confirmation";
import { useSelector } from "react-redux";
import { ResponsiveRow } from "../../constants/layout.constants";
import {
  ModalAcceptButton,
  ModalCancelButton,
} from "../../constants/component.constants";
import MuiDataGrid from "./MuiDataGrid";
import { issueResponseMessage } from "../../actions/message";
import { getVendorOptions } from "../../services/list.service";

export default function BillRemindersTab() {
  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(true);
  const [task, setTask] = useState("");
  const [payees, setPayees] = useState([]);
  const [selectedReminder, setSelectedReminder] = useState(null);
  const [showAddReminderModal, setShowAddReminderModal] = useState(false);
  const [reminders, setReminders] = useState([]);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const user = useSelector((state) => state.auth.user);
  const [payeeOptions, setPayeeOptions] = useState([]);
  const [searchResult, setSearchResult] = useState(false);
  const [description, setDescription] = useState("");
  let disableSearch = isEmptyString(description) && isEmptyArray(payees);

  function handleExpandClick() {
    setExpanded(true);
    setTask("Search");
  }

  function handleClearSearch() {
    setPayees([]);
    setDescription("");
    setExpanded(true);
    setTask("");
  }

  function handleAddReminderClick() {
    let facilityId = "";
    if (user.proxyFacility != null) {
      facilityId = user.proxyFacility;
    }
    let data = {
      id: null,
      vendorId: "",
      amount: "",
      recurring: false,
      billDay: "",
      billDate: "",
      billingFrequency: "",
      description: "",
      facilityId: facilityId,
      assetId: "",
      unitId: "",
      applyVAT: false,
    };
    setTask("Create");
    setSelectedReminder(data);
    setShowAddReminderModal(true);
  }

  function handleSearchClicked() {
    setLoading(true);
    let searchObject = {
      payees: payees,
      description: description,
    };
    getBillReminders(searchObject).then((reminders) => {
      setReminders(reminders);
      if(expanded === true) {
        setSearchResult(true);
      }
      setExpanded(false);
      setTask("Result");
    });
    setLoading(false);
  }

  function handleCloseSearch() {
    handleClearSearch();
    loadBillReminders();
    setExpanded(false);
    setTask("Result");
    setSearchResult(false);
  }

  const loadBillReminders = useCallback(() => {
    getVendorOptions().then((vendorOptions) => {
      setPayeeOptions(vendorOptions);
    });

    let searchObject = {
      payees: payees,
      description: description,
    };
    getBillReminders(searchObject).then((reminders) => {
      setReminders(reminders);
      setExpanded(false);
      setTask("Result");
    });
  }, [description, payees]);

  useEffect(() => {
    setLoading(true);
    getVendorOptions().then((vendorOptions) => {
      setPayeeOptions(vendorOptions);
    });
    let searchObject = {
      payees: payees,
      description: description,
    };

    getBillReminders(searchObject).then((reminders) => {
      setReminders(reminders);
      setExpanded(false);
      setTask("Result");
    });

    setLoading(false);
  }, [user.proxySubscription, user.proxyFacility, payees, description]);

  function onTextChange(event) {
    setDescription(event.target.value);
  }

  function handlePayeeChange(event) {
    setPayees(event.target.value);
  }

  function handleEditClick(selectedReminder) {
    setSelectedReminder(selectedReminder);
    setTask("Edit");
    setShowAddReminderModal(true);
  }

  function handleDeleteClick(selectedReminder) {
    setSelectedReminder(selectedReminder);
    setShowConfirmationModal(true);
  }

  const handleConfirmationClose = () => {
    setShowConfirmationModal(false);
    setSelectedReminder(null);
  };

  function deleteBillReminder() {
    deleteBillReminderData(selectedReminder).then((response) => {
      handleConfirmationClose();
      handleSearchClicked();
      issueResponseMessage(response);
    });
  }

  const dataGridColumns = [
    {
      field: "payee",
      headerName: "Payee",
      minWidth: 300,
      flex: 3,
      valueFormatter: (row) => {
        return titleCase(row.value);
      },
    },
    {
      field: "costedTo",
      headerName: "Costed To",
      minWidth: 250,
      flex: 2.5,
    },
    {
      field: "description",
      headerName: "Description",
      minWidth: 280,
      flex: 2.8,
    },
    {
      field: "amount",
      headerName: "Amount",
      minWidth: 130,
      flex: 1.3,
      valueFormatter: (row) => {
        return formatCurrency(row.value);
      },
    },
    {
      field: "recurring",
      headerName: "Recurring",
      minWidth: 100,
      flex: 1,
      valueFormatter: (row) => {
        let label = row.value ? "Yes" : "No";
        return label;
      },
    },
    {
      field: "billingFrequencyLabel",
      headerName: "Frequency",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "nextDueDate",
      headerName: "Next Due Date",
      minWidth: 130,
      flex: 1.3,
    },
    {
      field: "actions",
      headerName: "Actions",
      minWidth: 80,
      flex: 0.8,
      type: "actions",
      getActions: (params) => {
        let arr = [];
        arr.push(
          <GridActionsCellItem
            icon={<Edit />}
            onClick={() => handleEditClick(params.row)}
            label="Edit"
            showInMenu
          />
        );

        arr.push(
          <GridActionsCellItem
            icon={<Delete />}
            onClick={() => handleDeleteClick(params.row)}
            label="Delete"
            showInMenu
          />
        );
        return arr;
      },
    },
  ];

  function handleAddReminderClose() {
    setShowAddReminderModal(false);
    setSelectedReminder(null);
    setTask("Result");
    setExpanded(false);
  }

  function saveReminder(selectedReminder) {
    setSelectedReminder(selectedReminder);
    saveBillReminderData(selectedReminder).then((response) => {
      issueResponseMessage(response);
      handleAddReminderClose();
      handleSearchClicked();
    });
  }

  return (
    <ResponsiveRow
      container
      sx={{
        flexDirection: "column",
        flexWrap: "nowrap",

        justifyContent: "flex-start",
        alignItems: "flex-start",

        with: "100%",
        height: "auto",
      }}
    >
      <BillingRemindersTabBar
        expanded={expanded}
        handleCloseSearch={handleCloseSearch}
        handleExpandClick={handleExpandClick}
        handleAddReminderClick={handleAddReminderClick}
        searchResult={searchResult}
      />
      {task !== "Result" && expanded === true && (
        <ResponsiveRow sx={{ width: "100%", height: "auto" }} container>
          <FormControl
            item
            sx={{
              width: { xs: "100%", lg: "50%" },
              marginBottom: "10px",
            }}
          >
            <InputLabel>Description</InputLabel>
            <OutlinedInput
              label={"Description"}
              sx={{ width: { xs: "100%", lg: "90%" } }}
              id={"description"}
              onChange={(event) => onTextChange(event)}
              value={description}
            />
          </FormControl>

          <FormControl
            item
            sx={{
              width: { xs: "100%", lg: "50%" },
              marginBottom: "10px",
            }}
          >
            <InputLabel>Payee(s)</InputLabel>
            <Select
              label="Payee(s)"
              multiple
              sx={{ width: { xs: "100%", lg: "90%" } }}
              id={"payees"}
              value={payees}
              onChange={(event) => handlePayeeChange(event)}
            >
              {payeeOptions && payeeOptions.length > 0 ? (
                payeeOptions.map(function (unit, i) {
                  return (
                    <MenuItem key={i} value={unit.value}>
                      {unit.label}
                    </MenuItem>
                  );
                }, this)
              ) : (
                <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
              )}
            </Select>
          </FormControl>

          <ResponsiveRow
            item
            sx={{
              marginBottom: "10px",
              width: "100%",
              height: "auto",
              justifyContent: "flex-end",
            }}
          >
            <ModalCancelButton
              onClick={handleClearSearch}
              sx={{
                width: { xs: "100%", lg: "200px" },
              }}
            >
              Reset
            </ModalCancelButton>
            <ModalAcceptButton
              disabled={disableSearch}
              onClick={handleSearchClicked}
              sx={{
                width: { xs: "100%", lg: "200px" },
              }}
            >
              Search
            </ModalAcceptButton>
          </ResponsiveRow>
        </ResponsiveRow>
      )}
      {task === "Result" && (
        <Box sx={{ maxHeight: "75vh", width: "100%" }}>
          <MuiDataGrid
            loading={loading}
            noBoxShadow={true}
            noSelection={true}
            serverPagination={false}
            dataGridColumns={dataGridColumns}
            dataGridRows={reminders}
            height="50vh"
            totalRows={reminders.length}
          />
        </Box>
      )}

      {/*ADD REMINDER MODAL*/}
      {showAddReminderModal && (
        <CreateBillReminder
          openModal={showAddReminderModal}
          handleClose={handleAddReminderClose}
          saveReminder={saveReminder}
          task={task}
          selectedReminder={selectedReminder}
        />
      )}

      {/*CONFIRMATION MODAL*/}
      {showConfirmationModal && (
        <ConfirmationModal
          showConfirmationModal={showConfirmationModal}
          handleConfirmationClose={handleConfirmationClose}
          title={"Delete Reminder"}
          severity={"warning"}
          body={
            "Are you sure you wish to Delete (" +
            selectedReminder.description +
            ")?"
          }
          positiveText={"Yes"}
          negativeText={"No"}
          positiveAction={deleteBillReminder}
          negativeAction={handleConfirmationClose}
        />
      )}
    </ResponsiveRow>
  );
}
