import { serviceAPI } from './service';
import {
	ALL_ORG_BANK_ACC_URL,
	COUNTRY_BANKS,
	DELETE_BANK_ACCOUNT,
	LIST_LINKED_FACILITIES,
	LOAD_BANK_ACCOUNTS,
	ORG_BANK_ACC_URL,
	SAVE_BANK_ACCOUNT,
	TOTAL_BANK_ACCOUNTS,
} from '../constants/url.constants';
import { handleErrorResponse } from '../actions/message';

export const loadBankAccs = (pageNo) => {
	let url = LOAD_BANK_ACCOUNTS + pageNo;
	return serviceAPI
		.get(url)
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			handleErrorResponse(error);
		});
};

export const totalBankAccs = () => {
	return serviceAPI
		.get(TOTAL_BANK_ACCOUNTS)
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			handleErrorResponse(error);
		});
};

export const countryBanksList = () => {
	return serviceAPI
		.get(COUNTRY_BANKS)
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			handleErrorResponse(error);
		});
};

export const orgBankAccounts = () => {
	return serviceAPI
		.get(ORG_BANK_ACC_URL)
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			handleErrorResponse(error);
		});
};

export const allOrgBankAccountsList = () => {
	return serviceAPI
		.get(ALL_ORG_BANK_ACC_URL)
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			handleErrorResponse(error);
		});
};

export const saveBankAccountDetails = (data) => {
	return serviceAPI
		.post(SAVE_BANK_ACCOUNT, data)
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			handleErrorResponse(error);
		});
};

export const deleteBankAccount = (data) => {
	return serviceAPI
		.post(DELETE_BANK_ACCOUNT, data)
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			handleErrorResponse(error);
		});
};

export const getLinkedFacilities = (id) => {
	let url = LIST_LINKED_FACILITIES + id;
	return serviceAPI
		.get(url)
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			handleErrorResponse(error);
		});
};
