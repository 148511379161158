import {Alert, AlertTitle, InputLabel, Switch, } from "@mui/material"
import {isNullUndefined, titleCase} from "../../util/util"
import {useSelector} from "react-redux"
import Grid from "@mui/material/Grid"
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider"
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/en-gb'
import {DatePicker} from '@mui/x-date-pickers/DatePicker'
import {ResponsiveRow} from "../../constants/layout.constants"
import ModalContainerModule from "../modules/modalContainer"
import dayjs from "dayjs";

/**
 * This is a shared Export Documents Via Email component
 *
 * Required props are:
 * - showExportDocumentsModal: Show or hide the modal
 * - handleExportDocumentsClose: Close the modal view
 * - title: This is the title to display
 * - severity: Either warning, info or error
 * - body: This is the body message
 * - handleExportDocuments: Action for positive button
 *
 * @param props
 * @constructor
 * @author rcheruiyot - 15/03/22
 */

export default function ExportDocumentsModal(props) {
    const appMessage = useSelector((state) => state.message)

    return (
        <ModalContainerModule
            size="xs"
            accept={props.handleExportDocuments}
            cancel={props.handleExportDocumentsClose}
            openModal={props.showExportDocumentsModal}
            modalTitle={props.modalTitle ? props.modalTitle : " "}
            acceptButtonText=" Export Via Email"
        >
            <Alert severity={props.severity}>
                {props.body}{" "}
                {appMessage.display && (
                    <Alert severity={appMessage.type}>
                        <AlertTitle>{titleCase(appMessage.type)}</AlertTitle>
                        {appMessage.message}
                    </Alert>
                )}
            </Alert>{" "}
            <br/>
            {!isNullUndefined(props.contract) && (
                <ResponsiveRow container>
                    <Grid item sx={{width: {xs: "100%", lg: "50%"}}}>
                        <InputLabel>Statement Year</InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                            <DatePicker
                                sx={{width: "90%"}}
                                id={"statementYear"}
                                value={dayjs(props.statementYear)}
                                disableFuture={true}
                                disablePast={false}
                                openTo="year"
                                views={["year"]}
                                isrequired={true}
                                onChange={(date) => props.handleStatementYearChange(date)}

                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item sx={{width: {xs: "100%", lg: "50%"}}}>
                        <InputLabel>Include {props.contract} Details Documents</InputLabel>
                        <Switch
                            checked={props.includeDetails}
                            onChange={(event) => props.handleIncludeDetailToggle(event)}
                            inputProps={{"aria-label": "controlled"}}
                        />
                    </Grid>
                </ResponsiveRow>
            )}
        </ModalContainerModule>
    )
}
