import {serviceAPI} from "./service"
import {
    CUSTOMER_FINANCIALS_URL,
    DELETE_CUSTOMER_DOC_URL,
    FETCH_CUSTOMER_URL,
    LIST_CUSTOMERS_URL, LIST_MAINTENANCE_CUSTOMERS_URL,
    PENDING_TRANSACTIONS_URL,
    SAVE_CUSTOMER_URL, SAVE_MAINTENANCE_CUSTOMER_URL,
    UPLOAD_CUSTOMER_DOC_URL
} from "../constants/url.constants"
import {handleErrorResponse} from "../actions/message"

export const getCustomers = () => {
    return serviceAPI.get(LIST_CUSTOMERS_URL)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const fetchCustomers = (billingId) => {
    return serviceAPI.get(FETCH_CUSTOMER_URL + billingId)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getPendingTransactions = (billingId) => {
    return serviceAPI.get(PENDING_TRANSACTIONS_URL + billingId)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const saveCustomer = (data) => {
    return serviceAPI.post(SAVE_CUSTOMER_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const uploadCustomerDoc = (billingId) => {
    return serviceAPI.post(UPLOAD_CUSTOMER_DOC_URL + billingId)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const deleteCustomerDoc = (billingId) => {
    return serviceAPI.post(DELETE_CUSTOMER_DOC_URL + billingId)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const saveFacilityCustomer = (data) => {
    return serviceAPI.post(SAVE_MAINTENANCE_CUSTOMER_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getFacilityCustomers = () => {
    return serviceAPI.get(LIST_MAINTENANCE_CUSTOMERS_URL)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getCustomerFinancials = (customerId, pageNo) => {
    return serviceAPI.get(CUSTOMER_FINANCIALS_URL + customerId + "&pageNo=" + pageNo)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}


