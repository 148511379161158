/* eslint-disable react-hooks/exhaustive-deps */
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useEffect, useRef, useState } from "react";
import LoadingOverlay from "../overlays/loadingOverlay";
import EmptyOverlay from "../overlays/emptyOverlay";

export default function StackedBarChartCard({ dataObj, currentlyLoading }) {
  const [chartDataLabels, setChartDataLabels] = useState(["", "", "", ""]);
  const [chartData, setChartData] = useState([]);
  const [loading, setIsLoading] = useState(false);
  const [isEmpty, setIsEmpty] = useState(true);
  const chartRef = useRef();

  ChartJS.defaults.backgroundColor = "rgb(255,255,255)";
  ChartJS.defaults.color = "#000";
  ChartJS.defaults.fontWeight = "700";
  ChartJS.defaults.borderColor = "rgba(255,255,2551)";
  ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip);

  useEffect(() => {
    const chartColors = [
      "rgba(114, 168, 170, 1)",
      "rgba(3, 113, 113, 1)",
      "rgba(114, 168, 170, .5)",
      "rgba(3,113,113, .5)",
    ];

    setChartDataLabels(dataObj.dataLabels);
    setChartData([]);
    let tempData = [];
    tempData = dataObj.dataset.map((datasetItem, index) => {
      const data = datasetItem.datasetData;

      return {
        lineTension: 0.25,
        label: datasetItem.datasetLabel,
        data,
        backgroundColor: chartColors[index],
        borderColor: chartColors[index],
        borderWidth: 2,
      };
    });

    setIsEmpty(tempData.length === 0);
    setChartData(tempData);

    return () => {
      setChartDataLabels([]);
      setChartData([]);
    };
  }, [dataObj]);

  useEffect(() => {
    setIsLoading(currentlyLoading);
  }, [currentlyLoading]);

  return loading ? (
    <LoadingOverlay />
  ) : isEmpty ? (
    <EmptyOverlay />
  ) : (
    <Bar
      ref={chartRef}
      data={{
        type: "line",
        labels: chartDataLabels,
        datasets: chartData,
      }}
      options={{
        indexAxis: "y",
        responsive: true,
        maintainAspectRatio: false,
        color: "#000",
        scales: {
          x: {
            stacked: true,
            padding: "20px",
            ticks: {
              // Include a dollar sign in the ticks
              callback: function (value, index, ticks) {
                if (value > 999) {
                  const textValue = value.toString();
                  const textLength = textValue.length;
                  return textValue.substring(0, textLength - 3) + "K";
                } else {
                  return +value;
                }
              },
            },
            grid: {
              color: "#efefef",
            },
          },
          y: {
            stacked: true,
            padding: "20px",
            grid: {
              display: false,
            },
          },
        },
        plugins: {
          legend: {
            fullSize: false,
            position: "bottom",
            align: "end",
            labels: {
              color: "rgb(0,0,0)",
              fontWeight: 700,
            },
          },
        },
      }}
    />
  );
}
