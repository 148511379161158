import { Chip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { ModalAcceptButton } from "../../constants/component.constants";
import { ResponsiveRow } from "../../constants/layout.constants";
import kodi_theme from "../../themes/kodiThemeProvider";

export default function MessageBody(props) {
  const { message } = props;
  return (
    <ResponsiveRow
      sx={{
        position: "relative",

        flexDirection: "column",
        flexWrap: "nowrap",

        alignItems: "flex-start",

        width: "100%",
        height: "100%",

        margin: "auto",
        padding: "40px",

        backgroundColor: "#fff",
        borderRadius: "10px",
      }}
    >
      <Typography variant="h5" fontWeight={800} sx={{ marginBottom: "10px" }}>
        From:{" "}
        <span style={{ color: kodi_theme.palette.primary.dark }}>
          {message.senderName}
        </span>
      </Typography>
      {message && message.to && (
        <ResponsiveRow
          sx={{
            flexDirection: message.to.length > 1 ? "column" : "row",
            flexWrap: "nowrap",

            width: "100%",
            height: message.to.length > 1 ? "auto" : "40px",

            justifyContent: "flex-start",
            alignItems: message.to.length > 1 ? "flex-start" : "center",

            padding: 0,
            margin: "0 0 10px 0",
          }}
        >
          <Typography
            variant="h5"
            fontWeight={700}
            textAlign="start"
            sx={{ width: "auto" }}
          >
            To: {""}
          </Typography>
          <ResponsiveRow
            sx={{
              width: "100%",
              height: "auto",

              justifyContent: "flex-start",
              alignItems: "flex-start",

              maxHeight: "100px",
              margin: message.to.length > 1 ? "auto 5px" : "0 5px",
              padding: 0,

              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            {message.to.map(function (recipient, i) {
              return (
                <Chip
                  label={` ${recipient.label}`}
                  sx={{
                    color: kodi_theme.palette.primary.dark,
                    margin: "5px",
                  }}
                  key={i}
                />
              );
            }, this)}
          </ResponsiveRow>
        </ResponsiveRow>
      )}
      <Typography sx={{ fontSize: "1.2rem", fontWeight: 700 }}>
        Subject: {message.subject}
      </Typography>
      <Box
        sx={{
          padding: "20px",
          width: "100%",
          height: {
            xs: "30vh",
            lg: message.to && message.to.length > 1 ? "40vh" : "50vh",
          },

          borderRadius: "10px",
          backgroundColor: "#efefef",
        }}
      >
        <Typography
          sx={{ fontSize: ".9rem", fontWeight: 500, textAlign: "start" }}
        >
          {message.message}
        </Typography>
      </Box>
      <ResponsiveRow
        sx={{
          position: { xs: "relative", lg: "absolute" },
          bottom: { xs: 0, lg: "20px" },
          right: { xs: 0, lg: "10px" },

          justifyContent: "flex-end",
          alignItems: "center",

          height: "auto",
          width: "100%",
        }}
      >
        <ModalAcceptButton
          sx={{
            width: { xs: "100%", lg: "200px" },
            height: "50px",
          }}
          onClick={() =>
            props.composeMessage({
              status: true,
              newMessage: false,
              message,
            })
          }
        >
          compose reply
        </ModalAcceptButton>
      </ResponsiveRow>
    </ResponsiveRow>
  );
}
