/* eslint-disable react-hooks/exhaustive-deps */
import {MainContainer} from "../constants/component.constants"
import UserDetailsCard from "../components/cards/userDetailsCard"
import {
    changeNotification,
    fetchSubscriber,
    generateAccounts,
    getLoggedInUser,
    getLoggedInUserEmailNotifications,
    getLoggedInUserNotifications,
    updateEmailNotification,
    updateUserData,
    uploadOrgLogo,
} from "../services/profile.service"
import {useEffect, useState} from "react"
import {isEmptyString, isNullUndefined} from "../util/util"
import {useSelector} from "react-redux"
import {resetProfilePassword} from "../services/passwords.service"
import TransactionHistory from "../components/transactionHistory"
import {Box} from "@mui/material"
import PropertyInfoCard from "../components/cards/propertyInfoCard"
import {getFacilityDetails} from "../services/list.service"
import Permit from "../util/permit"
import AddNewOrganisation from "../components/modals/addNewOrganisation"
import {issueResponseMessage, issueWarnMessage} from "../actions/message"
import {fetchOrganization} from "../services/organisation.service"

export default function Profile() {
    const [selectedUser, setSelectedUser] = useState(null)
    const [selectedOrganization, setSelectedOrganization] = useState(null)
    const [selectedSubscriber, setSelectedSubscriber] = useState(null)
    const [selectedSubscription, setSelectedSubscription] = useState(null)
    const [selectedProperties, setSelectedProperties] = useState(null)
    const [notification, setNotification] = useState(null)
    const [loading, setLoading] = useState(true)
    const user = useSelector((state) => state.auth.user)
    const [emailNotification, setEmailNotification] = useState(null)
    const [showOrganizationModal, setShowOrganizationModal] = useState(false)
    const [task, setTask] = useState("")
    useEffect(() => {
        setLoading(true)
        getLoggedInUser()
            .then((user) => {
                setSelectedUser(user)
            })
        getLoggedInUserNotifications()
            .then((notification) => {
                setNotification(notification)
            })
            .finally(() => {
                setLoading(false)
            })
        if (!isEmptyString(user.proxyFacility)) {
            getLoggedInUserEmailNotifications()
                .then((notification) => {
                    setEmailNotification(notification)
                })
        }
    }, [])

    useEffect(() => {
        if (!isEmptyString(user.proxyFacility)) {
            setLoading(true)
            getLoggedInUserEmailNotifications()
                .then((notification) => {
                    setEmailNotification(notification)
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }, [user.proxyFacility])

    useEffect(() => {
        if (!isEmptyString(user.proxySubscription)) {
            setLoading(true)
            getFacilityDetails(user.proxySubscription)
                .then((response) => {
                    setSelectedProperties(response.properties)
                    setSelectedSubscriber(response.subscriber)
                    setSelectedSubscription(response.subscription)
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }, [user.proxySubscription])

    function getUser() {
        setLoading(true)
        getLoggedInUser()
            .then((user) => {
                setSelectedUser(user)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    function getEmailNotification() {
        if (!isNullUndefined(user.proxyFacility)) {
            setLoading(true)
            getLoggedInUserEmailNotifications()
                .then((notification) => {
                    setEmailNotification(notification)
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }

    function getNotification() {
        setLoading(true)
        getLoggedInUserNotifications()
            .then((notification) => {
                setNotification(notification)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    function handleUpdatePersonDetailsClick(selectedUser) {
        setSelectedUser(selectedUser)
        let user = selectedUser
        let userName = user.username.replace(/"/g, "")
        delete user.username
        user.credentialId = userName
        updateUserData(user)
            .then((response) => {
                issueResponseMessage(response)
                getUser()
            })
    }

    function handleGenerateAccountClick() {
        let params = {}
        params.subscriptionId = selectedSubscription.id
        generateAccounts(params)
            .then((response) => {
                issueResponseMessage(response)
                let personOrgId = ""
                if (!isNullUndefined(selectedSubscriber) && selectedSubscriber.type === "Company") {
                    personOrgId = selectedSubscriber.id + "_O"
                } else {
                    personOrgId = selectedSubscriber.id + "_P"
                }
                fetchSubscriber(personOrgId)
                    .then((response) => {
                        setSelectedSubscriber(response.subscriber)
                        setSelectedSubscription(response.subscription)
                    })
            })
    }

    function handleUploadLogoClick(file) {
        fetchOrganization(selectedSubscriber.id)
            .then((organization) => {
                if (isNullUndefined(organization.id)) {
                    issueWarnMessage("Couldn't determine the identifier for uploading the logo for this organization. Try refresh the page.")
                    return
                }
                let formData = new FormData()
                formData.append("orgLogoUpload", file)
                uploadOrgLogo(formData, organization.id)
                    .then((response) => {
                        issueResponseMessage(response)
                        let personOrgId = ""
                        if (!isNullUndefined(selectedSubscriber) && selectedSubscriber.type === "Company") {
                            personOrgId = selectedSubscriber.id + "_O"
                        } else {
                            personOrgId = selectedSubscriber.id + "_P"
                        }
                        fetchSubscriber(personOrgId)
                            .then((response) => {
                                setSelectedSubscriber(response.subscriber)
                                setSelectedSubscription(response.subscription)
                                setShowOrganizationModal(false)
                                setTask("")
                                setSelectedOrganization(null)
                            })
                    })
            })
    }

    function handleUpdatePasswordClick(paramsValue) {
        let user = selectedUser
        let params = paramsValue
        let userName = user.username.replace(/"/g, "")
        params.username = userName
        resetProfilePassword(params)
            .then((response) => {
                issueResponseMessage(response)
            })
    }

    function handleEditOrganizationClick() {
        fetchOrganization(selectedSubscriber.id)
            .then((organization) => {
                setSelectedOrganization(organization)
                setTask("Edit")
                setShowOrganizationModal(true)
            })
    }

    function handleOrgModalClose() {
        let personOrgId = ""
        if (!isNullUndefined(selectedSubscriber) && selectedSubscriber.type === "Company") {
            personOrgId = selectedSubscriber.id + "_O"
        } else {
            personOrgId = selectedSubscriber.id + "_P"
        }
        fetchSubscriber(personOrgId)
            .then((response) => {
                setSelectedSubscriber(response.subscriber)
                setShowOrganizationModal(false)
                setTask("")
                setSelectedOrganization(null)
            })
    }

    function handleUpdateNotificationsClick(notification) {
        changeNotification(notification)
            .then((response) => {
                issueResponseMessage(response)
                getNotification()
            })
    }

    function handleUpdateReportClick(emailNotification) {
        updateEmailNotification(emailNotification)
            .then((response) => {
                issueResponseMessage(response)
                getEmailNotification()
            })
    }

    return (
        <MainContainer>
            {/*ORGANISATION MODAL*/}
            {showOrganizationModal && (
                <AddNewOrganisation
                    openModal={showOrganizationModal}
                    closeModal={handleOrgModalClose}
                    task={task}
                    selectedOrg={selectedOrganization}
                />
            )}

            {!isNullUndefined(selectedUser) && !isNullUndefined(notification) && (
                <UserDetailsCard
                    selectedUser={selectedUser}
                    notification={notification}
                    emailNotification={emailNotification}
                    loading={loading}
                    handleUpdatePersonDetailsClick={handleUpdatePersonDetailsClick}
                    handleUpdateNotificationsClick={handleUpdateNotificationsClick}
                    handleUpdatePasswordClick={handleUpdatePasswordClick}
                    handleUpdateReportClick={handleUpdateReportClick}
                />
            )}

            <Permit roles="BLDIR">
                {!isNullUndefined(selectedSubscriber) && (
                    <PropertyInfoCard
                        selectedSubscriber={selectedSubscriber}
                        selectedSubscription={selectedSubscription}
                        selectedProperties={selectedProperties}
                        handleGenerateAccountClick={handleGenerateAccountClick}
                        handleUploadLogoClick={handleUploadLogoClick}
                        handleEditOrganizationClick={handleEditOrganizationClick}
                    />
                )}

                {!isNullUndefined(selectedSubscription) && (
                    <Box
                        sx={{
                            width: "100%",
                            marginBottom: "40px",
                        }}
                    >
                        <TransactionHistory
                            transactionMode={"Client"}
                            contract={selectedSubscription}
                            hideVoid={true}
                            height="55vh"
                        />
                    </Box>
                )}
            </Permit>
        </MainContainer>
    )
}
