import React, { useCallback, useEffect, useState } from "react";
import {
  ColumnContainer,
  MainContainer,
  TopBarContainedBrandButton,
  TopBarContainer,
} from "../constants/component.constants";
import { ResponsiveRow } from "../constants/layout.constants";
import { Alert, AlertTitle, FormControl, InputLabel, OutlinedInput, Typography } from "@mui/material";
import kodi_theme from "../themes/kodiThemeProvider";
import MuiDataGrid from "../components/modules/MuiDataGrid";
import {GridActionsCellItem} from "@mui/x-data-grid"
import { Visibility } from "@mui/icons-material";
import PendingTaxPaymentModal from "../components/modals/accounting/taxes/pendingTaxPaymentModal";
import { formatCurrency } from "../util/util";
import { getKRAPaymentVouchers, getTaxesTotals, saveKraPaymentVoucher } from "../services/taxes.service";
import ViewKRAPaymentModal from "../components/modals/accounting/taxes/viewKraPaymentModal";
import { issueSuccessMessage, issueWarnMessage } from "../actions/message";
import { getCashPaymentAccounts, getPaymentVoucherMethods } from "../services/list.service";
import { fetchPayment } from "../services/billAndPayment.service";
import { useSelector } from "react-redux";

export default function Taxes() {
  const [taxRows, setTaxRows] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const [pendingVATTaxes, setPendingVATTaxes] = useState();
  const [vatCredit, setVatCredit] = useState();
  const [pendingWithholdingTax, setPendingWithholdingTax] = useState();
  const [withholdingCredit, setWithholdingCredit] = useState();
  const [hasTaxVendor, setHasTaxVendor] = useState(true);

  const [modalOpen, setModalOpen] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [selectedTaxType, setSelectedTaxType] = useState("");
  const [PRNNumber, setPRNNumber] = useState("");
  const [selectionModel, setSelectionModel] = useState([]);
  const [taxTypes, setTaxTypes] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState("");
  const [kraPaymentViewModal, setKraPaymentViewModal] = useState(false);
  const [selectedVoucher, setSelectedVoucher] = useState(null);
  const [cashPaymentAccounts, setCashPaymentAccounts] = useState([]);
  const [paymentTotal, setPaymentTotal] = useState(0);
  const [filteredTaxRows, setFilteredTaxRows] = useState(taxRows);
  const [searchTerm, setSearchTerm] = useState("");
  const {user} = useSelector((state) => state.auth);

  function createKraPaymentVoucher(newPayment) {
    const { bulkFile } = newPayment;
    const file = bulkFile;
    const data = {
      PRNNumber: PRNNumber,
      paymentTotal: paymentTotal,
      taxType: selectedTaxType,
      taxTransactionIds: selectionModel,
      paymentMethod: paymentMethod,
      ...newPayment
    };

    if(validateVoucher(data)) {
      const uploadData = new FormData();
      uploadData.append("file", file);
      uploadData.append("newPayment", JSON.stringify(data));

      saveKraPaymentVoucher(uploadData)
      .then((response) => {
        issueSuccessMessage("Payment Voucher Created Successfully!");
        fetchData();
      })
      .finally(() => handleCloseModal())
    }
  }

  function validateVoucher(data) {
    if (!data.paymentMethod || !data.PRNNumber || !data.paymentTotal || !data.taxType || !data.taxTransactionIds) {
      issueWarnMessage("Please fill in all the fields");
      return false;
    }

    if(data.bulkFile && data.bulkFile.name.length > 200) {
      issueWarnMessage("The filename cannot be more than 200 characters");
      return false;
    }

    if(data.bulkFile && data.bulkFile.size > 10 * 1024 * 1024) {
      issueWarnMessage("The file size cannot be more than 10MB");
      return false;
    }

    if(data.paymentMethod === "CSH" && !data.cashAccount) {
      return false
    }
    if(data.paymentMethod === "CHQ" && !data.chequeNumber) {
      return false
    }
    if(data.paymentMethod === "RTGS" && !data.bankRefNumber) {
      return false
    }

    return true
  }

  function handleOpenTaxPaymentModal() {
    setModalOpen(true);
  }

  function handleOpenTaxPaymentViewModal(row) {
    fetchPayment(row.id).then((payment) => {
      setSelectedVoucher(payment);
      setKraPaymentViewModal(true);
    });
  }

  function handleSearch(e) {
    const search = e.target.value
    setSearchTerm(search)

    if(search) {
      setFilteredTaxRows(
        taxRows.filter((row) => row.referenceNumber.toLowerCase().includes(search.toLowerCase()) ||
        row.taxType.toLowerCase().includes(search.toLowerCase()) ||
        row.amount.toString().toLowerCase().includes(search.toLowerCase()) ||
        row.status.name.toLowerCase().includes(search.toLowerCase()))
      )
    } else {
      setFilteredTaxRows(taxRows)
    }
  }

  function handleCloseModal() {
    setPaymentMethod("");
    setPRNNumber("");
    setSelectionModel([])
    setPaymentTotal(0);
    setSelectedTaxType("")
    setModalOpen(false);
  }

  function closeKraPaymentViewModal() {
    setKraPaymentViewModal(false);
    setSelectedVoucher(null);
  }

  const taxPaymentColumns = [
    {
      field: "referenceNumber",
      headerName: "Voucher Number",
      width: 200,
    },
    {
      field: "date",
      headerName: "Voucher Date",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "taxType",
      headerName: "Tax Type ",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "amount",
      headerName: "Amount",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "status",
      headerName: "Payment Status",
      minWidth: 200,
      flex: 1,
      valueFormatter: (params) => params.value.name,
    },
    {
      headerName: "Actions",
      field: "actions",
      width: 100,
      type: "actions",
      getActions: (params) => {
        let arr = []
        arr.push(
          <GridActionsCellItem
              icon={<Visibility/>}
              onClick={() => handleOpenTaxPaymentViewModal(params.row)}
              label="View Voucher"
              showInMenu
          />
        )
        return arr;
      }
    },
  ];

  const fetchData = useCallback(async function () {
    setIsLoading(true);
    getTaxesTotals()
      .then((response) => {
        if(response.error && response.error.includes("Missing vendor Organization with a tax category")) {
          setHasTaxVendor(false)
        }
        setPendingVATTaxes(response.pendingVAT);
        setVatCredit(response.vatCredit);
        setPendingWithholdingTax(response.pendingWithholding);
        setWithholdingCredit(response.withholdingCredit);
      })
      .finally(() => setIsLoading(false));

    getKRAPaymentVouchers()
      .then((response) => {
        setTaxRows(response);
        setFilteredTaxRows(response);
      })
      .finally(() => setIsLoading(false));

    getCashPaymentAccounts().then((cashPaymentAccounts) =>
      setCashPaymentAccounts(cashPaymentAccounts)
    );

    getPaymentVoucherMethods()
      .then((paymentVoucherMethods) => setPaymentMethods(paymentVoucherMethods));

    setTaxTypes([
      {
        label: "Withholding Tax",
        value: "withholding",
      },
      {
        label: "Value Added Tax (VAT)",
        value: "vat",
      },
    ]);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData, user.proxyFacility]);

  return (
    <MainContainer
      sx={{
        width: "100%",
        height: "auto",

        flexWrap: "nowrap",
        flexDirection: "column",

        justifyContent: "flex-start",
        alignItems: "flex-start",

        padding: 0,
        paddingBottom: "40px",
        marginBottom: "40px",
      }}
    >
      {!hasTaxVendor && (
          <Alert severity="info" sx={{ mb: 2 }}>
            <AlertTitle>This facility is missing a vendor with a tax category.</AlertTitle>
            Please create a vendor with a tax category to have full functionality.
          </Alert>
        )}
      <ResponsiveRow
        item
        sx={{
          width: { xs: "100%", lg: "100%" },
          flexWrap: { xs: "wrap", lg: "nowrap" },
          justifyContent: "space-between",
          gap: "10px",
          padding: 0,
          margin: "40px auto 20px auto",
        }}
      >
        <ResponsiveRow
          sx={{
            width: {
              xs: "100%",
              lg: "50%",
            },
            alignItems: "flex-start",
            justifyContent: "space-between",
            backgroundColor: kodi_theme.palette.common.white,
            borderRadius: "10px",
            padding: "20px",
            boxShadow: "4px 4px 12px #aaa",
          }}
        >
          <ColumnContainer>
            <Typography
              width="100%"
              textAlign="start"
              color="black"
              fontWeight={900}
              variant="h1"
            >
              {!isLoading ? formatCurrency(pendingVATTaxes) : "--"}
            </Typography>
            <Typography
              width="100%"
              textAlign="start"
              color="primary.dark"
              fontWeight={900}
              variant="h6"
            >
              Total VAT to be paid
            </Typography>
          </ColumnContainer>
          <ColumnContainer>
            <Typography
              width="100%"
              textAlign="start"
              color="black"
              fontWeight={900}
              variant="h1"
            >
              {!isLoading ? formatCurrency(vatCredit) : "--"}
            </Typography>
            <Typography
              width="100%"
              textAlign="start"
              color="primary.dark"
              fontWeight={900}
              variant="h6"
            >
              VAT Credit
            </Typography>
          </ColumnContainer>
          
        </ResponsiveRow>

        <ResponsiveRow
          sx={{
            width: {
              xs: "100%",
              lg: "50%",
            },
            alignItems: "flex-start",
            justifyContent: "space-between",
            backgroundColor: kodi_theme.palette.common.white,
            borderRadius: "10px",
            padding: "20px",
            boxShadow: "4px 4px 12px #aaa",
          }}
        >
          <ColumnContainer>
            <Typography
              width="100%"
              textAlign="start"
              color="black"
              fontWeight={900}
              variant="h1"
            >
              {!isLoading ? formatCurrency(pendingWithholdingTax) : "--"}
            </Typography>
            <Typography
              width="100%"
              textAlign="start"
              color="primary.dark"
              fontWeight={900}
              variant="h6"
            >
              Total Withholding Tax to be paid
            </Typography>
          </ColumnContainer>
          <ColumnContainer>
            <Typography
              width="100%"
              textAlign="start"
              color="black"
              fontWeight={900}
              variant="h1"
            >
              {!isLoading ? formatCurrency(withholdingCredit) : "--"}
            </Typography>
            <Typography
              width="100%"
              textAlign="start"
              color="primary.dark"
              fontWeight={900}
              variant="h6"
            >
              Withholding Tax Credit
            </Typography>
          </ColumnContainer>
        </ResponsiveRow>
      </ResponsiveRow>
      <TopBarContainer
        sx={{
          justifyContent: { xs: "center", lg: "space-between" },
          width: { xs: "100%", lg: "90%" },
          marginBottom: "20px",
          minHeight: "80px",
        }}
        container
      >
        <ResponsiveRow
          item
          sx={{ width: { xs: "100%", lg: "50%" }, padding: 0 }}
        >
          <Typography
            variant="h5"
            sx={{
              width: "100%",
              height: "auto",

              textAlign: { xs: "center", lg: "start" },
            }}
            fontWeight={"black"}
          >
            Taxes Payments
          </Typography>
        </ResponsiveRow>
        <ResponsiveRow
          item
          sx={{
            width: { xs: "100%", lg: "50%" },
            padding: 0,
            justifyContent: { xs: "center", lg: "flex-end" },
          }}
          container
        >
          <ResponsiveRow
            item
            sx={{
              width: "100%",
              justifyContent: { xs: "center", lg: "flex-end" },
              flexWrap: { xs: "wrap", lg: "nowrap" },
            }}
          >
            <FormControl
              sx={{
                width: { xs: "100%", lg: "300px" },
                margin: { xs: "10px 0", lg: "0 20px" },
                height: "60px",
              }}
            > 
              <InputLabel id="demo-simple-select-label">Search Payments</InputLabel>
              <OutlinedInput
                id="searchPayments"
                value={searchTerm}
                onChange={(e) => handleSearch(e)}
                label="Payment Method"  
              />
            </FormControl>

            <TopBarContainedBrandButton
              onClick={handleOpenTaxPaymentModal}
              sx={{ width: { xs: "100%", lg: "300px" }, height: "60px" }}
              disabled={!hasTaxVendor}
            >
              Add New Voucher
            </TopBarContainedBrandButton>
          </ResponsiveRow>
        </ResponsiveRow>
      </TopBarContainer>
      <MuiDataGrid
        loading={isLoading}
        dataGridColumns={taxPaymentColumns}
        dataGridRows={filteredTaxRows}
        height="60vh"
        noSelection={true}
      />

      <br />

      <PendingTaxPaymentModal
        modalOpen={modalOpen}
        handleCloseModal={handleCloseModal}
        handlePayment={createKraPaymentVoucher}
        paymentMethod={paymentMethod}
        setPaymentMethod={setPaymentMethod}
        PRNNumber={PRNNumber}
        setPRNNumber={setPRNNumber}
        cashPaymentAccounts={cashPaymentAccounts}
        paymentTotal={paymentTotal}
        selectedTaxType={selectedTaxType}
        setSelectedTaxType={setSelectedTaxType}
        paymentMethods={paymentMethods}
        taxTypes={taxTypes}
        setPaymentTotal={setPaymentTotal}
      />

      <ViewKRAPaymentModal
        openModal={kraPaymentViewModal}
        handleCloseView={closeKraPaymentViewModal}
        voucher={selectedVoucher}
      />
    </MainContainer>
  );
}
