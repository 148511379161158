// import SmartKodiMap from "../../components/map"
import {ResponsiveRow} from "../../constants/layout.constants";
import WorkOrderStatusBar from "../../components/modules/dashboard/workOrderStatusBar";
import VacantUnits from "../../components/modules/dashboard/vacantUnits";
import ExpiringLeases from "../../components/modules/dashboard/expiringLeases";
import IncomeVsExpenses from "../../components/modules/dashboard/incomeVsExpenses";
import AssetsIncomeVsExpenses from "../../components/modules/dashboard/assetsIncomeVsExpenses";
import UtilitiesIncomeVsExpenses from "../../components/modules/dashboard/utilitesIncomevsExpenses";
import UtilitiesReadVsUnread from "../../components/modules/dashboard/utilitesReadVsUnread";
import UtilitiesWorkingVsBroken from "../../components/modules/dashboard/utilitiesWorkingVsBroken";
import Permit from "../../util/permit";
import UpcomingAssetMaintenance from "../../components/modules/dashboard/upcomingAssetMaintenance";
import BalanceOverdueByDays from "../../components/modules/dashboard/balanceOverdueByDays";
import Reminders from "../../components/modules/dashboard/reminders";
import BudgetVsExpenses from "../../components/modules/dashboard/budgetVsExpenses";
import HomeOwnersDues from "../../components/modules/dashboard/homeOwnersDues";
import AssignedWorkOrders from "../../components/modules/dashboard/assignedWorkOrders";
import {CorporateFare} from "@mui/icons-material";
import ExpensesByVendor from "../../components/modules/dashboard/expensesByVendor";
import MetersBulkSales from "../../components/modules/dashboard/metersBulkSales";
import MetersRetailSales from "../../components/modules/dashboard/metersRetailSales";
import MetersVolumesSold from "../../components/modules/dashboard/metersVolumesSold";
import PendingApprovals from "../../components/modules/dashboard/pendingApprovals";
import {isEmptyString, isNullUndefined} from "../../util/util";
import VacateNotices from "../../components/modules/dashboard/vacateNotices";
import {useSelector} from "react-redux";

export default function DashboardBuildingManager() {
    const {user} = useSelector((state) => state.auth)
    return (
        <Permit
            roles="BLDMG,CS"
            services="PREMIUM,CORE,LEAN,METER,HOA,MAINT,BLWTR"
            noPermittedServiceMessage={{
                title: "No Facility Selected",
                body: (
                    <>
                        Please select a facility by clicking on the building icon (
                        <CorporateFare
                            sx={{
                                fontSize: "1.5rem",
                                color: "#037171",
                                display: "inline-block",
                                margin: "auto 0",
                            }}
                        />
                        ) from the top right <br/> If you are having issues, please reach
                        out to the system admin.
                    </>
                ),
            }}
        >
            <ResponsiveRow
                sx={{flexDirection: "column", width: "100%", alignItems: "center"}}
            >
                <ResponsiveRow
                    sx={{
                        width: "100%",
                        height: "auto",
                        flexWrap: "nowrap",
                    }}
                >
                    <Permit services="PREMIUM,HOA,MAINT">
                        <WorkOrderStatusBar/>
                    </Permit>
                </ResponsiveRow>
                <ResponsiveRow
                    sx={{
                        width: "100%",
                        height: "auto",
                        flexWrap: "wrap",
                        flexDirection: "row",
                        justifyContent: "space-evenly",
                    }}
                >

                    <ResponsiveRow
                        sx={{
                            width: "100%",
                            height: "auto",
                            flexDirection: {xs: "column", lg: "row"},
                            flexWrap: "nowrap",
                            justifyContent: {xs: "center", lg: "space-between"},
                            alignItems: "center",
                            padding: 0,
                        }}
                    >
                    <Permit services="PREMIUM,CORE,HOA,BLWTR,METER,MAINT">
                        <PendingApprovals customHeight="500px" customWidth="59%" />
                    </Permit>
                    </ResponsiveRow>

                    <ResponsiveRow
                        sx={{
                            width: "100%",
                            height: "auto",
                            flexDirection: {xs: "column", lg: "row"},
                            flexWrap: "nowrap",
                            justifyContent: {xs: "center", lg: "space-between"},
                            alignItems: "center",
                            padding: 0,
                        }}
                    >
                        <Permit services="PREMIUM,HOA,MAINT">
                            <AssignedWorkOrders customWidth="49%" customHeight="500px"/>
                        </Permit>
                        <Permit services="PREMIUM,HOA,MAINT">
                            <Reminders customHeight="500px" customWidth="49%"/>
                        </Permit>
                    </ResponsiveRow>
                    <ResponsiveRow
                        sx={{
                            width: "100%",
                            height: "auto",
                            flexDirection: {xs: "column", lg: "row"},
                            flexWrap: "nowrap",
                            justifyContent: {xs: "center", lg: "space-between"},
                            alignItems: "center",
                            padding: 0,
                        }}
                    >
                        <Permit services="PREMIUM,CORE,LEAN">
                            <VacantUnits customHeight="500px" customWidth="49%"/>
                        </Permit>
                        <Permit services="PREMIUM,CORE,LEAN,METER">
                            <BalanceOverdueByDays customHeight="500px" customWidth="49%"/>
                        </Permit>
                    </ResponsiveRow>
                    <ResponsiveRow
                        sx={{
                            width: "100%",
                            height: "auto",
                            flexDirection: {xs: "column", lg: "row"},
                            flexWrap: "nowrap",
                            justifyContent: {xs: "center", lg: "space-between"},
                            alignItems: "center",
                            padding: 0,
                        }}
                    >
                        <Permit services="PREMIUM,HOA">
                            <IncomeVsExpenses customWidth="49%" customHeight="500px"/>
                        </Permit>
                        <Permit services="PREMIUM,CORE,LEAN">
                            <ExpiringLeases customHeight="500px" customWidth="49%"/>
                        </Permit>
                        <Permit services="HOA">
                            <HomeOwnersDues customWidth="49%" customHeight="500px"/>
                        </Permit>
                    </ResponsiveRow>
                    <ResponsiveRow
                        sx={{
                            width: "100%",
                            height: "auto",
                            flexDirection: {xs: "column", lg: "row"},
                            flexWrap: "nowrap",
                            justifyContent: {xs: "center", lg: "space-between"},
                            alignItems: "center",
                            padding: 0,
                        }}
                    >
                        <Permit services="PREMIUM,HOA">
                            <AssetsIncomeVsExpenses customWidth="49%" customHeight="500px"/>
                        </Permit>
                        <Permit services="PREMIUM,HOA,CORE,LEAN,METER,MAINT">
                            <UpcomingAssetMaintenance
                                customWidth="49%"
                                customHeight="500px"
                            />
                        </Permit>
                    </ResponsiveRow>
                    <ResponsiveRow
                        sx={{
                            width: "100%",
                            height: "auto",
                            flexDirection: {xs: "column", lg: "row"},
                            flexWrap: "nowrap",
                            justifyContent: {xs: "center", lg: "space-between"},
                            alignItems: "center",
                            padding: 0,
                        }}
                    >
                        <Permit services="PREMIUM,CORE,HOA,METER">
                            <UtilitiesReadVsUnread customWidth="49%" customHeight="500px"/>
                        </Permit>{" "}
                        <Permit services="PREMIUM,CORE,HOA,METER,MAINT">
                            <UtilitiesWorkingVsBroken
                                customWidth="49%"
                                customHeight="500px"
                            />
                        </Permit>
                    </ResponsiveRow>
                    <ResponsiveRow
                        sx={{
                            width: "100%",
                            height: "auto",
                            flexDirection: {xs: "column", lg: "row"},
                            flexWrap: "nowrap",
                            justifyContent: {xs: "center", lg: "space-between"},
                            alignItems: "center",
                            padding: 0,
                        }}
                    >
                        <Permit services="PREMIUM,HOA">
                            <UtilitiesIncomeVsExpenses
                                customWidth="49%"
                                customHeight="500px"
                            />
                        </Permit>
                        <Permit services="PREMIUM,HOA,MAINT">
                            <ExpensesByVendor customHeight="500px" customWidth="49%"/>
                        </Permit>
                    </ResponsiveRow>
                    <ResponsiveRow
                        sx={{
                            width: "100%",
                            height: "auto",
                            flexDirection: {xs: "column", lg: "row"},
                            flexWrap: "nowrap",
                            justifyContent: {xs: "center", lg: "space-between"},
                            alignItems: "center",
                            padding: 0,
                        }}
                    >
                        <Permit services="HOA">
                            <BudgetVsExpenses
                                customWidth="100%"
                                customHeight="500px"
                            />
                        </Permit>
                    </ResponsiveRow>

                    <ResponsiveRow
                        sx={{
                            width: "100%",
                            height: "auto",
                            flexDirection: { xs: "column", lg: "row" },
                            flexWrap: "nowrap",
                            justifyContent: { xs: "center", lg: "space-between" },
                            alignItems: "center",
                            padding: 0,
                        }}
                    >
                        <Permit services={(!isNullUndefined(user.proxyFacility) && !isEmptyString(user.proxyFacility) && user.proxyFacility !== "null") ? "PREMIUM,CORE,LEAN" : null}>
                            <VacateNotices customHeight="500px" customWidth="100%" />
                        </Permit>
                    </ResponsiveRow>

                    <ResponsiveRow
                        sx={{
                            width: "100%",
                            height: "auto",
                            flexDirection: {xs: "column", lg: "row"},
                            flexWrap: "nowrap",
                            justifyContent: {xs: "center", lg: "space-between"},
                            alignItems: "center",
                            padding: 0,
                        }}
                    >
                        <Permit services="BLWTR">
                            <MetersBulkSales customWidth="49%" customHeight="500px"/>
                        </Permit>
                        <Permit services="BLWTR">
                            <MetersRetailSales customWidth="49%" customHeight="500px"/>
                        </Permit>
                    </ResponsiveRow>
                    <ResponsiveRow
                        sx={{
                            width: "100%",
                            height: "auto",
                            flexDirection: {xs: "column", lg: "row"},
                            flexWrap: "nowrap",
                            justifyContent: {xs: "center", lg: "space-between"},
                            alignItems: "center",
                            padding: 0,
                        }}
                    >
                        <Permit services="BLWTR">
                            <MetersVolumesSold customWidth="99%" customHeight="500px"/>
                        </Permit>
                    </ResponsiveRow>
                </ResponsiveRow>
            </ResponsiveRow>
        </Permit>
    );
}
