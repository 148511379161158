import { FormControl, InputLabel, OutlinedInput } from "@mui/material";
import { useState } from "react";
import ModalContainerModule from "../../modules/modalContainer";
import { ResponsiveRow } from "../../../constants/layout.constants";
import { saveSubscriptionNote } from "../../../services/subscription.service";

export default function AddNoteModal(props) {
  const wordLimit = 500;
  const [note, setNote] = useState("");

  /**
   * REQUIRED PROPS
   *
   * handleNoteClose
   * noteModal
   * note
   * subscription
   */
  const saveNote = () => {
    let sendData = {
      subscriptionId: props.subscription.id,
      note: note,
    };
    saveSubscriptionNote(sendData).then((response) => {
      props.handleNoteClose();
    });
  };
  const onTextChange = (event) => {
    const trimmedText = event.target.value.trim();
    const totalWords = trimmedText.split(" ").length;

    if (totalWords <= wordLimit) {
      setNote(event.target.value);
    } else if (totalWords > wordLimit) {
      setNote(trimmedText.split(" ").splice(0, wordLimit).join(" "));
    }
  };

  return (
    <ModalContainerModule
      size="specialAddNoteSize"
      accept={saveNote}
      cancel={props.handleNoteClose}
      openModal={props.noteModal}
      modalTitle={`Add Note To Subscription for ${props.subscription?.name}`}
      acceptButtonText="Save Note"
    >
      {/*{console.log('props.subscription ', props.subscription)}*/}
      <ResponsiveRow container sx={{ height: "auto" }}>
        <FormControl sx={{ width: "100%" }}>
          <InputLabel>Note ({wordLimit - note.length}))</InputLabel>
          <OutlinedInput
            label="Note (500)"
            sx={{ width: "100%" }}
            id={"note"}
            name={"note"}
            multiline
            rows={5}
            isRequired
            value={note}
            onChange={(event) => onTextChange(event)}
          />
        </FormControl>
      </ResponsiveRow>
    </ModalContainerModule>
  );
}
