import {Card, CardActionArea, CardContent, Grid, Typography,} from "@mui/material"
import {Box} from "@mui/system"
import {InnerModal} from "../../constants/component.constants"

/**
 Required props are:
 - contract: This is the data to be displayed
 - notesMode: This is actual page you are implementing this from.
 */

export default function MeterChargesCard(props) {
    return (
        <Grid
            container
            sx={{
                display: "flex",
                flexDirection: "column",
                maxWidth: "420px",
                height: {xs: "100%", lg: props.height ? props.height : "500px"},
                backgroundColor: "#fff",
                borderRadius: "10px",
                margin: "20px auto",
                padding: 0,
                boxShadow: "0 4px 12px 0 rgba(0,0,0,0.25)",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    flexWrap: "nowrap",
                    width: "100%",
                    padding: "20px",
                }}
            >
                <Typography
                    sx={{
                        fontWeight: "bold",
                        marginBottom: "10px",
                        fontSize: "18px",
                        color: "#037171",
                    }}
                >
                    Meter Charges
                </Typography>
            </Box>

            <Box
                sx={{
                    backgroundColor: "#EFEFEF",
                    borderRadius: "10px",
                    minHeight: props.height ? "45%" : "415px",
                    width: "100%",
                    padding: "10px",
                    margin: "5px 0 0 0",
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        flexWrap: "wrap",
                        width: "auto",
                        maxHeight: "fit-content",
                        padding: "5px",
                        backgroundColor: "#efefef",
                        overflowY: "auto",
                    }}
                >
                    {props.contract && props.contract.billingChargeItems.length < 1 && (
                        <Typography
                            sx={{
                                color: "#C6C5C5",
                                fontSize: "11px",
                                fontWeight: 700,
                                width: "100%",
                                textAlign: "center",
                            }}
                        >
                            There are no Meter Charges
                        </Typography>
                    )}
                    {props.contract &&
                        props.contract.billingChargeItems.length > 0 &&
                        props.contract.billingChargeItems.map(function (billItem, i) {
                            return (
                                <Card sx={{width: "95%", margin: "10px"}}>
                                    <CardActionArea>
                                        <CardContent sx={{flex: "row"}}>
                                            <InnerModal container>
                                                <Grid item xs={3}>
                                                    {billItem.meter.name}
                                                </Grid>
                                                <Grid item xs={6}>
                                                    {billItem.charge.name}
                                                </Grid>
                                                <Grid item xs={3}>
                                                    {billItem.total}
                                                </Grid>
                                            </InnerModal>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            )
                        }, this)}
                </Box>
            </Box>
        </Grid>
    )
}
