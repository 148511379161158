import {Typography} from "@mui/material"
import {ResponsiveRow} from "../../../constants/layout.constants"

export default function AppointmentItem(props) {
    // console.log("appointment", props.appointment);
    return (
        <ResponsiveRow
            sx={{
                flexDirection: "column",
                flexWrap: "nowrap",
                height: "auto",
                width: "100%",
                backgroundColor: "#efefef",
                borderRadius: "10px",
                marginBottom: "10px",
            }}
        >
            <ResponsiveRow
                sx={{
                    flexDirection: "row",
                    flexWrap: "nowrap",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                }}
            >
                <Typography variant="h5" sx={{color: "#037171"}}>
                    {props.appointment && props.appointment.appointments && props.appointment.appointments.name}
                </Typography>
                {/*<Typography variant="body" sx={{fontWeight: 700, fontSize: ".95rem"}}>*/}
                {/*	{props.appointment && formatDate(props.appointment.appointmentDate)}*/}
                {/*</Typography>*/}
            </ResponsiveRow>
            <ResponsiveRow
                sx={{
                    flexDirection: "column",
                    flexWrap: "nowrap",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    width: "100%",
                    padding: "0 10px",
                }}
            >
                <Typography variant="body1">
                    {props.appointment && props.appointment.appointments && props.appointment.appointments.phone}
                </Typography>
                <Typography variant="body1">
                    {props.appointment && props.appointment.appointments && props.appointment.appointments.email}
                </Typography>
            </ResponsiveRow>
            <ResponsiveRow
                sx={{
                    flexDirection: "row",
                    flexWrap: "nowrap",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                }}
            >
                <Typography variant="h5" sx={{color: "#037171"}}>
                    {props.appointment && props.appointment.listing && props.appointment.listing.propertyName}
                </Typography>
            </ResponsiveRow>
            {/*<ResponsiveRow*/}
            {/*	sx={{*/}
            {/*		flexDirection: "row",*/}
            {/*		flexWrap: "nowrap",*/}
            {/*		justifyContent: "flex-end",*/}
            {/*		width: "100%",*/}
            {/*	}}*/}
            {/*>*/}
            {/*	<ModalAcceptButton sx={{width: "60%", fontSize: ".75rem"}}>*/}
            {/*		Contacts*/}
            {/*	</ModalAcceptButton>*/}
            {/*</ResponsiveRow>*/}
        </ResponsiveRow>
    )
}
