/* eslint-disable react-hooks/exhaustive-deps */
import { Divider, Typography } from "@mui/material";

import {
  formatCurrency,
  formatDate,
  formatDateTime,
  isArray,
  isEmptyArray,
  isEmptyString,
  isNullUndefined,
  tryParseFloat,
} from "../../util/util";
import { useEffect, useState } from "react";
import { ResponsiveRow } from "../../constants/layout.constants";
import Permit from "../../util/permit";
import { ModalAcceptButton } from "../../constants/component.constants";
import ModalContainerModule from "../modules/modalContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";

import { setMessage } from "../../actions/message";
import { updateLeaseDeposit } from "../../services/lease.service";
import { useDispatch } from "react-redux";

export default function ChargesCard(props) {
  const [chargeItems, setChargeItems] = useState([]);
  const [depositCharges, setDepositCharges] = useState([]);
  const [depositChargesModal, setDepositChargesModal] = useState(false);
  const dispatch = useDispatch();
  let rows;
  let depositsRows = [];

  useEffect(() => {
    props.charges && props.charges.length > 0 && setChargeItems(props.charges);

    //This updates depositCharges array with Security Deposit and utility deposit charges
    props.charges &&
      props.charges.length > 0 &&
      props.charges.forEach((chargeItem) => {
        if (
          chargeItem.chargeCode.code === "SECDP" ||
          chargeItem.chargeCode.code === "UTIDP"
        ) {
          setDepositCharges((items) => [...items, chargeItem]);
        }
      });
  }, [props.charges]);

  rows =
    chargeItems &&
    chargeItems.length > 0 &&
    chargeItems.map(function (charge, i) {
      return (
        <ResponsiveRow
          sx={{
            width: "100%",
            height: "auto",

            margin: { xs: "5px 0", lg: "10px 10px" },
            padding: "0 0 10px 0",

            flexDirection: "row",
            flexWrap: "nowrap",

            justifyContent: "flex-start",
            textAlign: "start",
          }}
          key={i}
        >
          <ResponsiveRow
            sx={{ width: "150px", padding: 0, margin: 0, height: "auto" }}
          >
            <Typography
              variant="body2"
              fontWeight={600}
              textAlign="start"
              sx={{
                minWidth: "90px",

                margin: "auto 10px",

                textOverflow: "ellipsis",
                whiteSpace: "wrap",
              }}
            >
              {charge.unit.name}
            </Typography>
          </ResponsiveRow>
          <ResponsiveRow
            sx={{ width: "200px", padding: 0, margin: 0, height: "auto" }}
          >
            <Typography
              variant="body2"
              fontWeight={600}
              textAlign="start"
              sx={{
                minWidth: "200px",
                width: "100%",

                margin: "auto 10px",

                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {charge.chargeCode.name}
            </Typography>
          </ResponsiveRow>

          <ResponsiveRow
            sx={{ width: "90px", padding: 0, margin: 0, height: "auto" }}
          >
            <Typography
              variant="body2"
              fontWeight={600}
              textAlign="start"
              sx={{
                minWidth: "100px",
                width: "100%",

                margin: "auto 10px",

                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {formatCurrency(charge.amount)}
            </Typography>
          </ResponsiveRow>

          <ResponsiveRow
            sx={{ width: "175px", padding: 0, margin: 0, height: "auto" }}
          >
            <Typography
              variant="body2"
              fontWeight={600}
              textAlign="center"
              sx={{
                margin: "auto 10px",

                minWidth: "175px",
                width: "100%",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {formatDateTime(charge.effectiveDate)}
            </Typography>
          </ResponsiveRow>
        </ResponsiveRow>
      );
    });

  function handleEditDepositClicked() {
    setDepositChargesModal(true);
  }

  function validateFormData(leaseId) {
    if (isEmptyString(leaseId)) {
      dispatch(
        setMessage({
          type: "warning",
          message:
            "Could not determine the lease identifier. Please refresh the page.",
        })
      );
      return false;
    }

    if (isEmptyArray(depositCharges) || isNullUndefined(depositCharges)) {
      dispatch(
        setMessage({
          type: "warning",
          message: "Could not determine the deposit to update.",
        })
      );
      return false;
    }

    if (isArray(depositCharges)) {
      depositCharges.forEach((charge) => {
        if (isEmptyString(charge.amount)) {
          dispatch(
            setMessage({
              type: "warning",
              message: "Amount cannot be empty.",
            })
          );
          return false;
        } else {
          let amount = tryParseFloat(charge.amount.toString(), -1);
          if (amount < 0) {
            dispatch(
              setMessage({
                type: "warning",
                message: "Amount is invalid, enter amount greater than 0.",
              })
            );
            return false;
          }
        }
      });
    }

    return true;
  }

  function handleUpdateClicked() {
    let leaseId =
      props.selectedLease && props.selectedLease.id
        ? props.selectedLease.id
        : "";
    let sendData = {
      leaseId: leaseId,
      depositCharges: depositCharges,
    };
    console.log("sendData :", sendData);
    validateFormData(leaseId) &&
      updateLeaseDeposit(sendData)
        .then((response) => {
          dispatch(
            setMessage({
              type: response.type,
              message: response.message,
            })
          );
          handleCancelClicked();
        })
        .catch((error) => {
          console.log("updateLeaseDeposit error", error);
        });
  }

  function handleCancelClicked() {
    setDepositChargesModal(false);
  }

  function handleAmountChanged(event, index) {
    let value = event.target.value;
    let newArr = [...depositCharges]; // copying the old items array
    newArr[index].amount = value;
    setDepositCharges(newArr);
  }

  function handleNoteChanged(event, index) {
    let value = event.target.value;
    let newArr = [...depositCharges]; // copying the old items array
    newArr[index].note = value;
    setDepositCharges(newArr);
  }

  depositCharges &&
    depositCharges.length > 0 &&
    (depositsRows = depositCharges.map((item, i) => {
      return (
        <TableRow sx={{ width: "100%" }}>
          <TableCell
            sx={{
              width: { lg: "25%", xs: "100px" },
            }}
          >
            <Typography
              sx={{ fontSize: "12px", fontWeight: 400, align: "left" }}
            >
              {item.unit.name}
            </Typography>
          </TableCell>
          <TableCell
            align="left"
            sx={{
              width: { lg: "25%", xs: "100px" },
            }}
          >
            <Typography
              sx={{ fontSize: "12px", fontWeight: 400, align: "left" }}
            >
              {item.chargeCode.name}
            </Typography>
          </TableCell>
          <TableCell
            sx={{
              width: { lg: "25%", xs: "100px" },
            }}
          >
            <TextField
              id={"depositAmount" + i}
              label="Amount"
              variant="outlined"
              size="medium"
              value={item.amount}
              onChange={(event) => handleAmountChanged(event, i)}
            />
          </TableCell>
          <TableCell
            sx={{
              width: { lg: "20%", xs: "100%" },
            }}
          >
            <TextField
              id={"depositNote" + i}
              label="Note"
              variant="outlined"
              size="medium"
              value={item.note}
              onChange={(event) => handleNoteChanged(event, i)}
            />
          </TableCell>
          <TableCell
            sx={{
              width: { lg: "20%", xs: "100%" },
            }}
          >
            <Typography
              sx={{ fontSize: "12px", fontWeight: 400, align: "left" }}
            >
              {formatDate(item.effectiveDate)}
            </Typography>
          </TableCell>
        </TableRow>
      );
    }));

  return (
    <>
      <ResponsiveRow
        sx={{
          flexDirection: "column",
          flexWrap: "nowrap",

          width: { xs: "100%", lg: "50%" },
          height: { xs: "100%", lg: "500px" },

          backgroundColor: "#fff",
          borderRadius: "10px",

          margin: "20px auto",
          padding: 0,

          overflow: "hidden",
          boxShadow: "0 4px 12px 0 rgba(0,0,0,0.25)",
        }}
      >
        <ResponsiveRow
          sx={{
            justifyContent: "space-between",
            alignItems: "flex-start",
            padding: "20px",
            height: "auto",
          }}
        >
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: 800,
              textAlign: "start",
              color: "#037171",
            }}
          >
            {props.chargesMode} Charges
          </Typography>

          <Permit roles="BLDMG,CS">
            <ModalAcceptButton
              sx={{
                margin: { xs: "auto 10px", lg: "0 5px" },
                width: { xs: "40%", lg: "120px" },
              }}
              onClick={handleEditDepositClicked}
            >
              Edit Deposits
            </ModalAcceptButton>
          </Permit>
        </ResponsiveRow>

        <ResponsiveRow
          sx={{
            flexDirection: "column",
            flexWrap: "nowrap",

            justifyContent: "flex-start",
            alignItems: "center",

            width: "auto",
            height: "100%",

            overflow: { xs: "auto", lg: "hidden" },
            backgroundColor: "#EFEFEF",

            margin: 0,
            padding: 0,
          }}
        >
          <ResponsiveRow
            sx={{
              flexDirection: "row",
              flexWrap: "nowrap",

              minWidth: "100%",
              height: "auto",

              margin: "5px 0 0 0",

              //   backgroundColor: "#fff",
              borderRadius: "5px",

              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <ResponsiveRow sx={{ width: "150px", padding: 0, margin: 0 }}>
              <Typography
                fontWeight={700}
                variant="body1"
                color="primary.dark"
                textAlign="start"
                sx={{
                  minWidth: "90px",
                  whiteSpace: "nowrap",
                  margin: "auto 10px",
                }}
              >
                Unit
              </Typography>
            </ResponsiveRow>
            <ResponsiveRow sx={{ width: "200px", padding: 0, margin: 0 }}>
              <Typography
                fontWeight={700}
                variant="body1"
                color="#FFAE42"
                textAlign="start"
                sx={{
                  minWidth: "200px",
                  maxWidth: "200px",
                  whiteSpace: "nowrap",
                  margin: "auto 10px",
                }}
              >
                Charge Type
              </Typography>
            </ResponsiveRow>
            <ResponsiveRow sx={{ width: "100px", padding: 0, margin: 0 }}>
              <Typography
                fontWeight={700}
                variant="body1"
                color="#E53935"
                textAlign="start"
                sx={{
                  minWidth: "90px",
                  maxWidth: "90px",
                  whiteSpace: "nowrap",
                  margin: "auto 10px",
                }}
              >
                Amount
              </Typography>
            </ResponsiveRow>
            <ResponsiveRow sx={{ width: "175px", padding: 0, margin: 0 }}>
              <Typography
                fontWeight={700}
                variant="body1"
                color="primary.dark"
                textAlign="start"
                sx={{
                  minWidth: "175px",
                  maxWidth: "175px",
                  whiteSpace: "nowrap",
                  margin: "auto 10px",
                }}
              >
                Effective Date
              </Typography>
            </ResponsiveRow>

            <Divider sx={{ width: "auto", height: "10px" }} color="secondary" />
          </ResponsiveRow>

          <ResponsiveRow
            sx={{
              height: "380px",
              width: "100%",

              margin: "10px 0",

              backgroundColor: "#EFEFEF",
              borderRadius: "5px",

              flexDirection: "column",
              flexWrap: "nowrap",

              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            {rows}
          </ResponsiveRow>
        </ResponsiveRow>
      </ResponsiveRow>
      {depositChargesModal && (
        <ModalContainerModule
          // size="xs"
          accept={handleUpdateClicked}
          cancel={handleCancelClicked}
          openModal={depositChargesModal}
          modalTitle={
            "Edit Security/Utility Deposit Amount on lease " +
            props.selectedLease.billingCode +
            " for " +
            props.selectedLease.tenant.name
          }
          acceptButtonText={"Update"}
          rejectButtonText={"Cancel"}
        >
          <ResponsiveRow sx={{ paddingTop: 0 }}>
            <Alert severity="info">
              Deposits notice: You can modify Security deposit or Utility
              deposit amount of an active lease from this form. But they will be
              pending awaiting building director's approval.
            </Alert>
            <br />
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        width: { lg: "20%", xs: "100%" },
                      }}
                    >
                      Unit
                    </TableCell>
                    <TableCell
                      sx={{
                        width: { lg: "20%", xs: "100%" },
                      }}
                    >
                      Charge Type
                    </TableCell>
                    <TableCell
                      sx={{
                        width: { lg: "20%", xs: "100%" },
                      }}
                    >
                      Amount
                    </TableCell>
                    <TableCell
                      sx={{
                        width: { lg: "20%", xs: "100%" },
                      }}
                    >
                      Note
                    </TableCell>
                    <TableCell
                      sx={{
                        width: { lg: "20%", xs: "100%" },
                      }}
                    >
                      Effective Date
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{depositsRows}</TableBody>
              </Table>
            </TableContainer>
          </ResponsiveRow>
        </ModalContainerModule>
      )}
    </>
  );
}
