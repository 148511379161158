/**
 * This component checks the rights and
 * displays the child component if the rights are correct
 */
import {isArray, isNullUndefined} from "./util"
import PropTypes from "prop-types"
import {useSelector} from "react-redux"
import $ from "jquery"
import Unauthorized from "../pages/unauthorized"

Permit.propTypes = {
    roles: PropTypes.string,
    permissions: PropTypes.string,
    services: PropTypes.string,
    proxy: PropTypes.bool,
}

export default function Permit(props) {
    const {user} = useSelector((state) => state.auth)
    const hasRole = () => {
        if (isNullUndefined(props.roles)) {
            //return true as default as role is not a constraint
            return true
        }

        if (
            !isNullUndefined(user) &&
            !isNullUndefined(user.roles) &&
            user.roles.length > 0
        ) {
            //confirm the user has at least one role
            const componentRoleArray = props.roles.split(",").map((item, i) => {
                return item.trim()
            }) // make sure spaces don't matter
            const isSysAdmin = user.roles.indexOf("SYSAD") >= 0

            if (!user.isProxy && isSysAdmin) {
                //if not proxied and you are a sysadmin, allow for components marked for sysadmin
                return componentRoleArray.indexOf("SYSAD") >= 0
            } else if (user.isProxy) {
                if (isSysAdmin && componentRoleArray.length === 1 && componentRoleArray.indexOf("SYSAD") >= 0) {
                    //this is a proxied sys admin so hide the componenet if it only permits sys admin
                    return false
                } else {
                    //if proxied, check for a match on the proxied role
                    return (
                        !isNullUndefined(user.proxyRole) &&
                        $.inArray(user.proxyRole, componentRoleArray) >= 0
                    )
                }

            } else {
                // default is to check available roles against the
                let match = false
                for (let i = 0; i < user.roles.length; i++) {
                    let result = $.inArray(user.roles[i], componentRoleArray)
                    if (result >= 0) {
                        match = true
                        break
                    }
                }
                return match
            }
        } else {
            return false
        }
    }

    const hasPermission = () => {
        if (isNullUndefined(props.permissions)) {
            //return true as default as permission is not a constraint
            return true
        }
        if (
            !isNullUndefined(user) &&
            !isNullUndefined(user.permissions) &&
            user.permissions.length > 0
        ) {
            let match = false
            for (let i = 0; i < user.permissions.length; i++) {
                let permissionArray = props.permissions.split(",").map((item, i) => {
                    return item.trim()
                }) // make sure spaces don't matter
                if ($.inArray(user.permissions[i], permissionArray) >= 0) {
                    match = true
                    break
                }
            }
            return match
        }
    }

    const hasService = () => {
        // console.log('user is ', user)
        if (isNullUndefined(props.services)) {
            //return true as default as service is not a constraint
            return true
        }
        const serviceList = props.services.split(",").map((item, i) => {
            return item.trim()
        }) // make sure spaces don't matter

        // if the user is a sys admin and is not proxied and the service list is empty, return true
        if (user.isProxy && user.roles.indexOf("SYSAD") >= 0 && serviceList.length === 0) {
            return true
        }

        if (serviceList.length === 0) {
            // services string given but no codes given, this is invalid so don't permit
            return false
        }

        const services =
            isArray(user.services)
                ? new Set(user.services)
                : new Set()
        if (services.size === 0) {
            // user has no services, no subscription or no proxy
            return false
        }

        for (let i = 0; i < serviceList.length; i++) {
            let permittedCode = serviceList[i]
            const negation = permittedCode.startsWith("!")
            if (negation) {
                permittedCode = permittedCode.substring(1)
            }
            if (services.has(permittedCode)) {
                return !negation
            }
        }
        return false
    }

    const isProxy = () => {
        return isNullUndefined(props.proxy) ? true : props.proxy === user.isProxy
    }

    /**
     @returns
     *This is returned when props.noPermittedServiceMessage is defined
     */
    const serviceNotPermitted = (message) => {
        return <Unauthorized message={message}/>
    }

    const display = hasRole() && hasPermission() && hasService() && isProxy()
    return display
        ? props.children
        : props.noPermittedServiceMessage !== undefined
            ? serviceNotPermitted(props.noPermittedServiceMessage)
            : null
}
