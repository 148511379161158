import {Alert, Grid, InputLabel, Paper, Switch} from "@mui/material"
import {ModalAcceptButton} from "../../../../constants/component.constants"
import {ResponsiveRow} from "../../../../constants/layout.constants"
import {FileUpload} from "../../../inputs/fileUpload"
import ModalContainerModule from "../../../modules/modalContainer"

export default function UploadMeterReadingModal(props) {
    /**
     *
     * REQUIRED PROPS
     *
     * readingsUpload
     * UploadReadingsModal
     *
     * handleInvoiceLastReadingToggle
     *
     * setBulkFile
     * setBulkFileProgress
     * setUploadReadingsModal
     * setInvoiceLastReading
     *
     * bulkFile
     * bulkFileProgress
     * fileValueChanged
     * getTemplate
     * invoiceLastReading
     *
     */
    return (
        <ModalContainerModule
            size="xs"
            accept={props.readingsUpload}
            cancel={() => {
                props.setUploadReadingsModal(false)
                props.setInvoiceLastReading(false)
                props.setBulkFile(null)
                props.setBulkFileProgress(0)
            }}
            openModal={props.UploadReadingsModal}
            modalTitle="Reading Upload"
            acceptButtonText="Upload"
        >
            <Paper
                variant=""
                sx={{
                    width: "100%",
                    height: "auto",
                    margin: "auto",
                    padding: "10px",
                }}
            >
                <Alert severity="warning">
                    If you are unsure of the format to use in your document, click on the
                    "Download Template" to obtain an example document.
                </Alert>
                <br/>
                <ResponsiveRow container>
                    <Grid item sx={{width: {xs: "100%", lg: "50%"}}}>
                        <FileUpload
                            id={"bulkFileInput"}
                            label={"Attach Excel File"}
                            currentFile={props.bulkFile}
                            progress={props.bulkFileProgress}
                            onChange={props.fileValueChanged}
                            accept={
                                ".xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            }
                        />
                    </Grid>
                    <Grid item sx={{width: {xs: "100%", lg: "50%"}}}>
                        <InputLabel>Mark readings as Invoiced?</InputLabel>
                        <Switch
                            checked={props.invoiceLastReading}
                            onChange={(event) => props.handleInvoiceLastReadingToggle(event)}
                            inputProps={{"aria-label": "controlled"}}
                        />
                    </Grid>
                </ResponsiveRow>
                <br/>
            </Paper>
            <ResponsiveRow sx={{justifyContent: {xs: "center", lg: "flex-end"}}}>
                <ModalAcceptButton
                    onClick={props.getTemplate}
                    sx={{
                        width: {xs: "100%", md: "200px"},
                    }}
                >
                    {" "}
                    Download Template{" "}
                </ModalAcceptButton>
            </ResponsiveRow>
        </ModalContainerModule>
    )
}
