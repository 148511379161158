import ModalContainerModule from '../../../modules/modalContainer';
import { ResponsiveRow } from '../../../../constants/layout.constants';
import { Typography } from '@mui/material';
import { formatCurrency, isEmptyString } from '../../../../util/util';
import {
	DetailsColumn,
	DetailsContainer,
	DetailsLabel,
	DetailsRow,
	DetailsText,
} from '../../../../constants/component.constants';
import { useState } from 'react';
import MuiDataGrid from '../../../modules/MuiDataGrid';
import { useSelector } from 'react-redux';
import { printPaymentVoucher } from '../../../../services/print.service';

export default function ViewPendingPaymentInfo(props) {
	const { user } = useSelector((state) => state.auth);
	const [loading, setLoading] = useState(false);
	const columns = [
		{ headerName: 'Debit Account', field: 'debitAccount', width: 150 },
		{
			headerName: 'Credit Account',
			field: 'creditAccount',
			minWidth: 150,
			flex: 1,
		},

		{
			headerName: 'Amount',
			field: 'amount',
			minWidth: 150,
			flex: 1,
			valueFormatter: (params) => formatCurrency(params.value),
		},
		{ headerName: 'Notes', field: 'notes', minWidth: 550, flex: 3.75 },
	];

	const handlePrint = () => {
		printPaymentVoucher(props.selectedPayment?.id);
	};

	return (
		<ModalContainerModule
			size="sm"
			accept={user.proxyRole === 'BLDIR' ? props.handleApprove : false}
			reject={user.proxyRole === 'BLDIR' ? props.handleReject : false}
			cancel={props.handleClose}
			openModal={props.openModal}
			canceltDisabled={false}
			modalTitle="Approve/Reject Payment"
			acceptButtonText="Approve"
			cancelText="Cancel"
			rejectButtonText="Reject"
			acceptDisabled={user.proxyRole !== 'BLDIR'}
			printAction={handlePrint}
		>
			<ResponsiveRow sx={{ height: 'auto', padding: 0 }}>
				<Typography
					id="modal-modal-description"
					sx={{ mt: 2, fontWeight: 700, width: '100%', height: 'auto' }}
				>
					Payment Details {props.selectedPayment?.referenceNumber}
				</Typography>
				<Typography
					id="modal-modal-description"
					sx={{
						mt: 0,
						color: '#037171',
						fontSize: '.9rem',
						width: '100%',
						height: 'auto',
					}}
				>
					Payment Total:
					{props.selectedPayment?.amount ?? ''}
				</Typography>
			</ResponsiveRow>
			<ResponsiveRow
				container
				sx={{
					flexWrap: 'nowrap',
					alignItem: 'flex-start',
					justifyContent: 'center',
					width: '100%',
					height: 'auto',
				}}
			>
				<DetailsContainer sx={{ width: { xs: '100%', lg: '100%' } }}>
					<DetailsColumn sx={{ width: { xs: '100%', lg: '50%' } }}>
						<DetailsRow>
							<DetailsLabel id="bill-payee-label">Date:</DetailsLabel>
							<DetailsText>{props.selectedPayment?.date ?? '--'}</DetailsText>
						</DetailsRow>
						<DetailsRow>
							<DetailsLabel>Paid To</DetailsLabel>

							<DetailsText>{props.selectedPayment?.paidTo}</DetailsText>
						</DetailsRow>
						<DetailsRow>
							<DetailsLabel>Status</DetailsLabel>
							<DetailsText>
								{props.selectedPayment?.status ?? 'Pending'}
							</DetailsText>
						</DetailsRow>
						<DetailsRow>
							<DetailsLabel>Memo</DetailsLabel>
							<DetailsText>{props.selectedPayment?.description}</DetailsText>
						</DetailsRow>
					</DetailsColumn>
					<DetailsColumn
						sx={{
							width: { xs: '100%', lg: '50%' },
						}}
					>
						<DetailsRow>
							<DetailsLabel id="bill-payee-label">Reference</DetailsLabel>
							<DetailsText>
								{props.selectedPayment?.referenceNumber}
							</DetailsText>
						</DetailsRow>
						<DetailsRow>
							<DetailsLabel>Paid From</DetailsLabel>

							<DetailsText>
								{props.selectedPayment?.paymentAccount?.name ??
									'Company Current Account'}
							</DetailsText>
						</DetailsRow>

						<DetailsRow>
							<DetailsLabel id="bill-payee-label">Payment Method:</DetailsLabel>
							<DetailsText>{props.selectedPayment?.paymentMethod}</DetailsText>
						</DetailsRow>
						{!isEmptyString(props.selectedPayment?.chequeNumber) && (
							<DetailsRow>
								<DetailsLabel>Cheque Number:</DetailsLabel>
								<DetailsText>{props.selectedPayment.chequeNumber}</DetailsText>
							</DetailsRow>
						)}
						<DetailsRow>
							<DetailsLabel>Applied To</DetailsLabel>
							<DetailsText>{props.selectedPayment?.appliedTo}</DetailsText>
						</DetailsRow>
					</DetailsColumn>
				</DetailsContainer>
			</ResponsiveRow>
			<ResponsiveRow
				sx={{
					width: '100%',
					boxShadow: 0,
					padding: '10px',
					margin: 0,
					alignItems: 'flex-start',
					height: 'auto',
				}}
			>
				<Typography
					id="modal-modal-description"
					sx={{
						mt: 2,
						mb: 2,
						fontWeight: 700,
						width: '100%',
						height: 'auto',
					}}
				>
					Payment Items ({props.selectedPayment?.paymentItems?.length ?? 0})
				</Typography>
				<MuiDataGrid
					alt={true}
					noSelection={true}
					noBoxShadow={true}
					dataGridColumns={columns}
					dataGridRows={props.selectedPayment?.paymentItems ?? []}
					loading={loading}
					height="350px"
					totalRows={props.selectedPayment?.paymentItems?.length ?? 0}
					serverPagination={false}
				/>
			</ResponsiveRow>
		</ModalContainerModule>
	);
}
