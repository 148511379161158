import {serviceAPI} from "./service"
import {
    CHANGE_PASSWORD_URL, CONFIRM_FACEBOOK_DELETE_URL,
    FORGOT_PASSWORD_URL,
    RESET_PASSWORD_URL,
    RESET_PROFILE_PASSWORD_URL
} from "../constants/url.constants"
import {handleErrorResponse} from "../actions/message"

export const forgotPassword = (data) => {
    return serviceAPI.post(FORGOT_PASSWORD_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const changePassword = (token) => {
    return serviceAPI.get(CHANGE_PASSWORD_URL + token)
        .then((response) => {
            if(response.status === 200){
                return response.data
            }else{
                handleErrorResponse(response.data)
                return {}//return an empty object
            }
        }).catch(error => {
            return error
        })
}

export const confirmFacebookDelete = (confirmation_code) => {
    return serviceAPI.get(CONFIRM_FACEBOOK_DELETE_URL + confirmation_code)
        .then((response) => {
            if(response.status === 200){
                return response.data
            }else{
                handleErrorResponse(response.data)
                return {}//return an empty object
            }
        }).catch(error => {
            return error
        })
}

export const resetPassword = (data) => {
    return serviceAPI.post(RESET_PASSWORD_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const resetProfilePassword = (data) => {
    return serviceAPI.post(RESET_PROFILE_PASSWORD_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}
