import {Typography} from "@mui/material";
import {ResponsiveRow} from "../../../constants/layout.constants";
import PieChartsCard from "../../charts/PieChartCard";
import {useEffect, useState} from "react";
import {getWorkingVsBrokenMeters} from "../../../services/dashboard.service";
import {useSelector} from "react-redux";

/**
 *
 * REQUIRED PROPS
 *
 * customWidth: string
 * customHeight: string
 * data: {
 *  workingData: number
 *  brokenData: number
 *  }
 *
 */

export default function UtilitiesWorkingVsBroken({
                                                     customWidth,
                                                     customHeight,
                                                 }) {
    const [chartData, setChartData] = useState([]);
    const [loading, setLoading] = useState(true);
    const {user} = useSelector((state) => state.auth);
    useEffect(() => {
        setLoading(true);
        getWorkingVsBrokenMeters()
            .then((response) => {
                if (response && response.data) {
                    setChartData(response.data);
                }
            })
            .finally(() => {
                setLoading(false);
            });
        return () => {
            setChartData([]);
        };
    }, [user.proxyFacility]);

    return (
        <ResponsiveRow
            sx={{
                flexDirection: "column",
                flexWrap: "nowrap",

                height: {xs: "100%", lg: customHeight},
                minWidth: {xs: "100%", lg: customWidth},
                width: "100%",

                margin: "10px",

                backgroundColor: "#fff",
                borderRadius: "20px",
            }}
        >
            <ResponsiveRow
                sx={{
                    margin: "0",
                    height: "20px",

                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
                <Typography variant="h5">Utilities: Working vs Broken</Typography>
            </ResponsiveRow>

            <ResponsiveRow
                sx={{
                    margin: "30px auto",
                    height: "80%",
                    width: "100%",

                    flexWrap: "nowrap",

                    flexDirection: "row",
                    alignItem: "flex-end",
                    justifyContent: "center",
                }}
            >
                <PieChartsCard currentlyLoading={loading} labelDataObj={chartData}/>
            </ResponsiveRow>
        </ResponsiveRow>
    );
}
