/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useMemo, useState} from "react";
import {GridActionsCellItem} from "@mui/x-data-grid";
import {Button, MenuItem, Select, Switch, TableCell, TableRow, TextField,} from "@mui/material";
import {Add, Cancel, CreditCard, LocalAtm, NearMe, Visibility} from "@mui/icons-material";
import {personOrgSearch} from "../services/shared.service";
import {getIdTypes, getUnitOptions} from "../services/list.service";
import {
    formatCurrency,
    isArray,
    isEmptyArray,
    isEmptyString,
    isNullUndefined,
    tryParseFloat
} from "../util/util";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/en-gb";
import {useSelector} from "react-redux";
import Permit from "../util/permit";
import ReceivePayment from "../components/modals/receivePayment";
import {
    addLeaseContact,
    addOrDeleteUnit,
    downloadLeases,
    exportLeases,
    getEditUnitDetails,
    getLeaseChargeCodes,
    getLeaseContacts,
    getLeaseDetails,
    getTerminatedLeases,
    getTotalTerminatedLeases,
    getUnitMeters,
    leaseUnitCharges,
    removeLeaseContact,
    saveLeaseNote,
    saveLeasePayment,
    suspendReactivateLease,
} from "../services/lease.service";
import {MainContainer, TopBarContainedBrandButton, TopBarContainer,} from "../constants/component.constants";
import PersonOrgSearch from "../components/modals/personOrgSearch";
import AddNewUser from "../components/modals/addNewUser";
import AddNewOrganisation from "../components/modals/addNewOrganisation";
import {saveReadingBulk} from "../services/meters.service";
import LeaseView from "./leaseView";
import TerminatedContactModal from "../components/modals/rentals/terminated leases/terminatedContactModal";
import AddTerminatedNoteModal from "../components/modals/rentals/terminated leases/addTerminatedNoteModal";
import DownloadTerminatedLeaseModal from "../components/modals/rentals/terminated leases/downloadTerminatedLeaseModal";
import TerminatedSwapUnitModal from "../components/modals/rentals/terminated leases/terminatedSwapUnitModal";
import SuspendTerminatedLeaseModal from "../components/modals/rentals/terminated leases/suspendTerminatedLeaseModal";
import {ResponsiveRow} from "../constants/layout.constants";
import {issueInfoMessage, issueResponseMessage, issueWarnMessage,} from "../actions/message";
import dayjs from "dayjs";
import MuiDataGrid from "../components/modules/MuiDataGrid";
import CreditNote from "../components/modals/creditNote";
import DebitNote from "../components/modals/debitNote";

export default function TerminatedLeases() {
    const [terminatedLeases, setTerminatedLeases] = useState([]);
    const [searchReference, setSearchReference] = useState("");
    const [searchTenant, setSearchTenant] = useState("");
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 50,
        page: 0,
    });
    const [loading, setLoading] = useState(true);
    const [totalTerminatedLeases, setTotalTerminatedLeases] = useState(0);
    const [selectedLease, setSelectedLease] = useState(null);
    const [selectedUnitId, setSelectedUnitId] = useState("");
    const [includeLeaseDetail, setIncludeLeaseDetail] = useState(false);
    const [suspendModal, setSuspendModal] = useState(false);
    const [statusChangeReason, setStatusChangeReason] = useState("");
    const [addOrganizationModal, setAddOrganizationModal] = useState(false);
    const [addUserModal, setAddUserModal] = useState(false);
    const [paymentModal, setPaymentModal] = useState(false);
    const [creditModal, setCreditModal] = useState(false);
    const [statementYear, setStatementYear] = useState();
    const [chargeItemsTotal, setChargeItemsTotal] = useState(0);
    const [items, setItems] = useState([]);
    const [chargeItems, setChargeItems] = useState([]);
    const [contactTableRows, setContactTableRows] = useState("");
    const [leaseChargesTableRows, setLeaseChargesTableRows] = useState("");
    const [swapUnitsTableRows, setSwapUnitsTableRows] = useState("");
    const [chargeModal, setChargeModal] = useState(false);
    const [noteModal, setNoteModal] = useState(false);
    const [downloadModal, setDownloadModal] = useState(false);
    const [note, setNote] = useState("");
    const [task, setTask] = useState("");
    const [innerTask, setInnerTask] = useState("");
    const [leaseChargeCodes, setLeaseChargeCodes] = useState([]);
    const [idTypes, setIdTypes] = useState([]);
    const [unitOptions, setUnitOptions] = useState([]);
    const [chargeUnitsOptions, setChargeUnitsOptions] = useState([]);
    const [tenantModal, setTenantModal] = useState(false);
    const [contactModal, setContactModal] = useState(false);
    const [isTenantSelect, setIsTenantSelect] = useState(false);
    const [isContactSelect, setIsContactSelect] = useState(false);
    const [showSelectUnit, setShowSelectUnit] = useState(false);
    const [idTypeSearch, setIdType] = useState("");
    const [idNoSearch, setIdNo] = useState("");
    const [companySearch, setCompanySearch] = useState("");
    const [searchBy, setSearchBy] = useState("");
    const [foundTenant, setFoundTenant] = useState([]);
    const user = useSelector((state) => state.auth.user);
    const [selectionModel, setSelectionModel] = useState([]);
    const [leaseContacts, setLeaseContacts] = useState([]);
    const [noOfUnitsBeforeUpdate, setNoOfUnitsBeforeUpdate] = useState(0);
    const [meterReadings, setMeterReadings] = useState(0);
    const [units, setUnits] = useState([]);
    const [addedUnitsMeterReadings, setAddedUnitsMeterReadings] = useState([]);
    const [prevUnits, setPrevUnits] = useState([]);
    const [deletedUnitMeterReadings, setDeletedUnitMeterReadings] = useState([]);
    const [voidItem, setVoidItem] = useState(null)
    const handlePaymentClose = () => setPaymentModal(false);
    const handleCreditClose = () => {
        setVoidItem(null)
        setCreditModal(false);
        getTerminatedLeases(
            searchReference,
            searchTenant,
            paginationModel.page,
            paginationModel.pageSize
        ).then((response) => {
            if (response !== undefined) setTerminatedLeases(response.leases);
        });
    };

    const handleChargeClose = () => {
        setVoidItem(null)
        setChargeModal(false);
        getTerminatedLeases(
            searchReference,
            searchTenant,
            paginationModel.page,
            paginationModel.pageSize
        ).then((response) => {
            if (response !== undefined) setTerminatedLeases(response.leases);
        });
    };

    const handlePersonOrgClose = () => {
        setIsTenantSelect(false);
        setTenantModal(false);
    };
    const handleUnitSwapClose = () => {
        setTask("");
        setNoOfUnitsBeforeUpdate(0);
        setMeterReadings(0);
        setUnits([]);
        setSelectedUnitId("");
        setInnerTask("");
        setShowSelectUnit(false);
    };
    const handleLeaseChargeClose = () => {
        setTask("");
        setInnerTask("Lease Units");
        setShowSelectUnit(false);
        setSelectedUnitId("");
        setChargeUnitsOptions([]);
        setChargeItems([]);
    };
    const handleContactClose = () => {
        setIsContactSelect(false);
        setSearchBy("");
        setIdType("");
        setIdNo("");
        setFoundTenant([]);
        setContactModal(false);
        setLeaseContacts([]);
    };
    const handleSuspendReactivateClose = () => {
        setTask("");
        setSuspendModal(false);
    };
    const handleContactClear = () => {
        setIdType("");
        setIdNo("");
        setFoundTenant([]);
    };
    const handleNoteClose = () => {
        setNote(""); //Clear note
        setNoteModal(false);
    };
    const handleDownloadClose = () => {
        setSelectionModel([]); //Clear selection
        setStatementYear(new Date());
        setIncludeLeaseDetail(false);
        setDownloadModal(false);
    };

    useEffect(() => {
        setLoading(true);
        getTotalTerminatedLeases(searchReference, searchTenant).then((response) => {
            if (response !== undefined && response.totalLeases !== undefined)
                setTotalTerminatedLeases(response.totalLeases);
        });
        getTerminatedLeases(
            searchReference,
            searchTenant,
            paginationModel.page,
            paginationModel.pageSize
        )
            .then((response) => {
                if (response !== undefined && response.leases !== undefined)
                    setTerminatedLeases(response.leases);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [searchReference, searchTenant, paginationModel.page, paginationModel.pageSize]);

    useEffect(() => {
        setLoading(true);
        getTotalTerminatedLeases(searchReference, searchTenant).then((response) => {
            if (response !== undefined && response.totalLeases !== undefined)
                setTotalTerminatedLeases(response.totalLeases);
        });
        getTerminatedLeases(
            searchReference,
            searchTenant,
            paginationModel.page,
            paginationModel.pageSize
        )
            .then((response) => {
                if (response !== undefined && response.leases !== undefined)
                    setTerminatedLeases(response.leases);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [user.proxyFacility]);

    useEffect(() => {
        if (!isNullUndefined(selectedLease) && !isNullUndefined(selectedLease.id)) {
            setLoading(true);
            getLeaseContacts(selectedLease.id)
                .then((leaseContacts) => setLeaseContacts(leaseContacts))
                .finally(() => {
                    setLoading(false);
                });
        }

        if (
            !isNullUndefined(selectedLease) &&
            !isNullUndefined(selectedLease.facilityId)
        ) {
            getUnitOptions(selectedLease.facilityId).then((unitOptions) =>
                setUnitOptions(unitOptions)
            );
        }
    }, [selectedLease]);

    useEffect(() => {
        setLoading(true);
        getLeaseChargeCodes().then((leaseChargeCodes) =>
            setLeaseChargeCodes(leaseChargeCodes)
        );
        getIdTypes().then((idTypes) => setIdTypes(idTypes));
    }, []);

    useEffect(() => {
        populateLeaseContacts();
    }, [leaseContacts]);

    useEffect(() => {
        if (!chargeModal) {
            populateLeaseChargeItems();
        }
    }, [chargeItems]);

    useEffect(() => {
        populateUnitSwapItems();
    }, [units]);

    function searchPersonOrg() {
        let data = {
            searchType: searchBy,
            idType: idTypeSearch,
            idNumber: idNoSearch,
            organizationName: companySearch,
        };
        personOrgSearch(data).then((response) => setFoundTenant(response));
    }

    function showAddNewUserForm(idNoSearch, idTypeSearch) {
        setIdType(idTypeSearch);
        setIdNo(idNoSearch);
        setTenantModal(false);
        setAddUserModal(true);
    }

    function showAddNewOrgForm(companySearch) {
        setCompanySearch(companySearch);
        setTenantModal(false);
        setAddOrganizationModal(true);
    }

    function handleAddUserClose() {
        setAddUserModal(false);
    }

    function handleAddOrgClose() {
        setAddOrganizationModal(false);
    }

    function handlePersonOrgSelect(result) {
        if (isTenantSelect) {
            let obj = selectedLease;
            obj.tenant = result;
            setSelectedLease(obj);
            setTenantModal(false);
            setIsTenantSelect(false);
            setIsContactSelect(false);
            setSearchBy("");
            setIdType("");
            setIdNo("");
            setCompanySearch("");
            setFoundTenant([]);
        }

        if (isContactSelect) {
            setTenantModal(false);
            setIsTenantSelect(false);
            setIsContactSelect(false);
            setCompanySearch("");
            let data = {
                leaseId: selectedLease.id,
                personId: result.id,
            };
            if (validateAddLeaseContact(data)) {
                addLeaseContact(data).then((response) => {
                    issueResponseMessage(response);
                    getLeaseContacts(selectedLease.id).then((leaseContacts) =>
                        setLeaseContacts(leaseContacts)
                    );
                    handleContactClear();
                });
            }
        }
    }

    function handleIdTypeChange(event) {
        let value = event.target.value;
        setIdType(value);
    }

    function onTextChange(event) {
        const id = event.target.id;
        const value = event.target.value;
        if (id === "searchRef") {
            setSearchReference(value);
        }
        if (id === "searchTenant") {
            setSearchTenant(value);
        }
        if (id === "note") {
            setNote(value);
        }
        if (id === "idNoSearch") {
            setIdNo(value);
        }
        if (id === "companySearch") {
            setCompanySearch(value);
        }
        if (id === "statusChangeReason") {
            setStatusChangeReason(value);
        }
    }

    function suspendReActivateLeaseForm() {
        let data = {
            leaseId: selectedLease.id,
            statusChangeReason: statusChangeReason, //CASH/CHEQUE - only needed if final balance < 0
            currentStatus: selectedLease.status,
        };
        if (isEmptyString(data.statusChangeReason)) {
            issueWarnMessage("Cannot submit an without a reason for suspension");
            return false;
        }
        suspendReactivateLease(data).then((response) => {
            issueResponseMessage(response);
            if (!isNullUndefined(response.lease)) {
                getTerminatedLeases(
                    searchReference,
                    searchTenant,
                    paginationModel.page,
                    paginationModel.pageSize
                ).then((response) => {
                    if (response !== undefined) setTerminatedLeases(response.leases);
                });
                handleSuspendReactivateClose();
            }
        });
    }

    function handleChargeStartDateChange(value, index) {
        let date = new Date(value);
        let newArr = [...chargeItems]; // copying the old items array
        newArr[index].effectiveDate = date;
        setChargeItems(newArr);
        populateLeaseChargeItems();
    }

    function handleChargeTaxableToggle(event, index) {
        let value = event.target.checked;
        let newArr = [...chargeItems]; // copying the old items array
        newArr[index].taxable = value;
        setChargeItems(newArr);
        populateLeaseChargeItems();
    }

    function handleLeaseChargeItemRemove(index) {
        let newArr = [...chargeItems]; // copying the old items array
        newArr.splice(index, 1);
        setChargeItems(newArr);
        populateLeaseChargeItems();
    }

    function handleUnitDelete(index) {
        let unitToDelete = units[index]
        let deleteUnit = true;
        if (
            !isNullUndefined(unitToDelete.meters) &&
            isArray(unitToDelete.meters) &&
            unitToDelete.meters.length > 0
        ) {
            unitToDelete.meters.forEach((meter) => {
                if (meter.newMeterReading != null) {
                    if (meter.newMeterReading <= meter.lastMeterReading) {
                        issueWarnMessage(
                            `Last Meter reading for meter ${meter.name} cannot be less than or equal to the previous reading.`
                        );
                        deleteUnit = false;
                    }
                }
            });
        }
        if (deleteUnit) {
            dontIssueCharge(index);
        }
    }

    function dontIssueCharge(deleteUnitIndex) {
        let unitToDelete = units[deleteUnitIndex];
        let meterReadings = deletedUnitMeterReadings;
        if (isArray(unitToDelete.meters) && unitToDelete.meters.length > 0) {
            unitToDelete.meters.forEach((meter) => {
                meterReadings.push({
                    meterId: meter.id,
                    amount: meter.newMeterReading,
                });
            });
        }
        let tmpUnits = units;
        tmpUnits.splice(deleteUnitIndex, 1);
        let tmpLease = selectedLease;
        tmpLease.leaseChargeItems = tmpLease.leaseChargeItems.filter(
            (charge) => charge.unit.id !== unitToDelete.id
        );
        let noOfUnits = noOfUnitsBeforeUpdate - 1;
        let thePrevUnits = prevUnits;
        if (!unitToDelete.dateCreated) {
            let myIndex = thePrevUnits.findIndex((e) => e.value === unitToDelete.id);
            thePrevUnits.splice(myIndex, 1);
        }
        setUnits(tmpUnits);
        setSelectedLease(tmpLease);
        setNoOfUnitsBeforeUpdate(noOfUnits);
        setPrevUnits(thePrevUnits);
        setDeletedUnitMeterReadings(meterReadings);
        populateUnitSwapItems();
    }

    function handleChargeCodeChange(event, index) {
        let value = event.target.value;
        let newArr = [...chargeItems]; // copying the old items array
        newArr[index].chargeCode.code = value;
        setChargeItems(newArr);
        populateLeaseChargeItems();
    }

    function handleChargeUnitChange(event, index) {
        let value = event.target.value;
        let newArr = [...chargeItems]; // copying the old items array
        newArr[index].unit.id = value;
        setChargeItems(newArr);
        populateLeaseChargeItems();
    }

    function leaseChargeAmountChange(event, index) {
        let value = event.target.value;
        let newArr = [...chargeItems]; // copying the old items array
        newArr[index].amount = value;
        setChargeItems(newArr);
        populateLeaseChargeItems();
    }

    function editLastMeterReading(event, unitIndex, meterIndex) {
        let tmp = units;
        tmp[unitIndex].meters[meterIndex].newMeterReading = parseFloat(
            event.target.value
        );
        setUnits(tmp);
        populateUnitSwapItems();
    }

    function leaseChargeNoteChange(event, index) {
        let value = event.target.value;
        let newArr = [...chargeItems]; // copying the old items array
        newArr[index].note = value;
        setChargeItems(newArr);
        populateLeaseChargeItems();
    }

    function handleLeaseChargeItemAdd() {
        let charge = {
            unit: {
                id: "",
                name: "",
            },
            amount: "",
            chargeCode: {code: "", name: ""},
            taxable: false,
            effectiveDate: new Date(),
            note: "",
        };
        setChargeItems((chargeItems) => [...chargeItems, charge]);
    }

    function findAndRemoveChargeVAT(detailItem) {
        let vatAmount = getVATAmount(detailItem.amount);
        for (let i = 0; i < items.length; i++) {
            if (
                items[i].amount === vatAmount &&
                items[i].notes.includes(detailItem.notes)
            ) {
                handleChargeItemRemove(i);
            }
        }
    }

    function getVATAmount(amount) {
        if (isNaN(amount)) {
            issueWarnMessage("Only numeric amounts are allowed for VAT calculation");
            return 0;
        } else {
            return (amount * 0.16).toFixed(2);
        }
    }


    function handleIncludeLeaseDetailToggle(event) {
        setIncludeLeaseDetail(event.target.checked);
    }

    function handleChargeItemRemove(index) {
        let newArr = [...items]; // copying the old items array
        if (newArr.length > 1) {
            findAndRemoveChargeVAT(items[index]);
            newArr.splice(index, 1);
            setItems(newArr);
            setChargeItems(items);
        } else {
            issueWarnMessage("There must be at least one charge");
        }
    }

    function handleLeaseContactRemove(personId) {
        if (
            isNullUndefined(selectedLease) ||
            isNullUndefined(selectedLease.id) ||
            isEmptyString(selectedLease.id)
        ) {
            issueWarnMessage(
                "Something went wrong: couldn't find the title to retrieve the contacts. Try refresh the page."
            );
            return;
        }
        if (isNullUndefined(personId) || isEmptyString(personId)) {
            issueWarnMessage(
                "Couldn't find the person identifier. Try refresh the page"
            );
            return;
        }
        let data = {
            leaseId: selectedLease.id,
            personId: personId,
        };
        removeLeaseContact(data).then((response) => {
            issueResponseMessage(response);
            getLeaseContacts(selectedLease.id).then((leaseContacts) =>
                setLeaseContacts(leaseContacts)
            );
            handleContactClear();
        });
    }

    function handlePaymentClick(selectedLease) {
        setSelectedLease(selectedLease)
        setPaymentModal(true)
    }

    function handleNoteClick(selectedLease) {
        setSelectedLease(selectedLease);
        setNoteModal(true);
    }

    function handleCreditClick(selectedLease) {
        setSelectedLease(selectedLease)
        setCreditModal(true)
    }

    function handleChargeClick(selectedLease) {
        setSelectedLease(selectedLease)
        setChargeModal(true)
    }

    function handleSwapUnitChange(event) {
        let value = event.target.value;
        setSelectedUnitId(value);
        let obj = selectedLease;
        obj.unitIds.push(value);
        let unitIds = [];
        unitIds.push(value);
        setSelectedLease(obj);
        let currentItems = [];
        let options = [];
        for (let unit of unitOptions) {
            if (unit.value === value) {
                options.push(unit);
            }
        }
        setChargeUnitsOptions(options);
        leaseUnitCharges(unitIds)
            .then((response) => {
                for (let item of response) {
                    currentItems.push({
                        unit: {id: item.id, name: ""},
                        amount: item.rent,
                        chargeCode: {code: item.code, name: ""},
                        taxable: false,
                        effectiveDate: new Date(),
                        note: "",
                    });
                }
                setInnerTask("Lease Charges");
                setChargeItems(currentItems);
            })
        populateLeaseChargeItems();
    }

    function handleStatementYearChange(value) {
        let date = new Date(value);
        setStatementYear(date);
    }

    function handleViewClick(selectedLease) {
        getLeaseDetails(selectedLease.id)
            .then((response) => {
                if (!isNullUndefined(response.id)) {
                    setSelectedLease(response);
                    setTask("View");
                }
            })
    }

    function handleVoidClick(item) {
        if (item.type === "Charge" || item.type === "Invoice") {
            setCreditModal(true)
            //set the id of the transaction being voided
            setVoidItem(item)
        } else if (item.type === "Receipt" || item.type === "Credit") {
            setChargeModal(true)
            setVoidItem(item)
        }
    }

    function handleUnitSwapClick() {
        getEditUnitDetails(selectedLease.id)
            .then((data) => {
                if (isArray(data) && !isEmptyArray(data)) {
                    let noOfReadings = data.map((e) =>
                        e.meters.length === 0
                            ? null
                            : e.meters.map((e) => e.readings.length)
                    );
                    data = data.map((item) => {
                        if (item.meters) {
                            item.meters = item.meters.map((meter) => {
                                meter.newMeterReading = null;
                                return meter;
                            });
                        }
                        return item;
                    });
                    // console.log("data: ", data)
                    setNoOfUnitsBeforeUpdate(data.length);
                    setMeterReadings(noOfReadings);
                    setUnits(data);
                    setTask("Unit Swap");
                    setInnerTask("Lease Units");
                }
            })
    }

    function savePayment(data) {
        data.leaseId = selectedLease.id
        saveLeasePayment(data).then((response) => {
            issueResponseMessage(response);
            if (!isNullUndefined(response.lease)) {
                getTerminatedLeases(
                    searchReference,
                    searchTenant,
                    paginationModel.page,
                    paginationModel.pageSize
                ).then((response) => {
                    if (response !== undefined) setTerminatedLeases(response.leases);
                });
                handlePaymentClose();
            }
        });
    }

    function saveEditUnit() {
        // check that for a unit that has meters and was added to the lease
        // the initial meter readings were provided for all meter.
        let readyToSendData = true;
        let addedUnitsMeterReadings = [];
        units.forEach((unit) => {
            if (unit.meters) {
                unit.meters.forEach((meter) => {
                    if (meter.newMeterReading != null) {
                        if (meter.newMeterReading <= meter.lastMeterReading) {
                            issueWarnMessage(
                                `Initial Meter reading for meter ${meter.name} cannot be less than or equal to the previous reading.`
                            );
                            readyToSendData = false;
                        } else {
                            addedUnitsMeterReadings.push({
                                meterId: meter.id,
                                newReading: meter.newMeterReading,
                            });
                        }
                    }
                });
            }
        });
        if (readyToSendData) {
            setAddedUnitsMeterReadings(addedUnitsMeterReadings);
            saveDeletedUnitsMeterReadings();
        }
    }

    function saveDeletedUnitsMeterReadings() {
        let params = deletedUnitMeterReadings;
        let data = {readingDetails: params};
        if (Object.keys(params).length !== 0) {
            saveReadingBulk(data).then((response) => {
                issueResponseMessage(response);
                saveSwap();
            });
        } else {
            saveSwap();
        }
    }

    function saveSwap() {
        let sendData = selectedLease;
        sendData.meterReadings = addedUnitsMeterReadings;
        addOrDeleteUnit(sendData).then((response) => {
            issueResponseMessage(response);
            getTerminatedLeases(
                searchReference,
                searchTenant,
                paginationModel.page,
                paginationModel.pageSize
            ).then((response) => {
                if (response !== undefined) setTerminatedLeases(response.leases);
            });
            handleUnitSwapClose();
        });
    }

    function saveLeaseCharges() {
        if (innerTask === "Lease Charges") {
            let tmp = selectedLease;
            tmp.leaseChargeItems = tmp.leaseChargeItems.concat(chargeItems);
            tmp.unitIds.push(selectedUnitId);
            let prevUnitsTemp = prevUnits;
            let unitSelectedIndex = unitOptions.findIndex(
                (e) => e.value === selectedUnitId
            );
            prevUnitsTemp.push(unitOptions[unitSelectedIndex]);
            let noOfUnits = noOfUnitsBeforeUpdate + 1;
            setSelectedLease(tmp);
            setPrevUnits(prevUnitsTemp);
            setNoOfUnitsBeforeUpdate(noOfUnits);
            let periodicCharge = 0;
            let balance = 0;
            chargeItems.forEach((current) => {
                let amount = parseFloat(current.amount);
                let code = current.chargeCode.code;
                if (
                    code === "SECDP" ||
                    code === "LFEE" ||
                    code === "MISC" ||
                    code === "UTIDP"
                ) {
                    balance += amount;
                } else periodicCharge += amount;
            });
            getUnitMeters(selectedUnitId)
                .then((data) => {
                    let unit = {
                        balance: balance,
                        id: unitOptions[unitSelectedIndex].value,
                        name: unitOptions[unitSelectedIndex].label,
                        periodicCharge: periodicCharge,
                        tenant: units[0].tenant,
                        meters: data,
                    };
                    let noOfReadingsPerMeter = [];
                    if (data.length > 0) {
                        noOfReadingsPerMeter = data.map((item) => item.readings.length);
                    }
                    let tmpReadings = meterReadings;
                    tmpReadings.push(noOfReadingsPerMeter);
                    let tmp = units;
                    tmp.push(unit);
                    setMeterReadings(tmpReadings);
                    setUnits(tmp);
                    populateUnitSwapItems();
                    handleLeaseChargeClose();
                })
        }
    }

    function exportLeaseData() {
        exportLeases();
    }

    function handleDownloadClick() {
        setDownloadModal(true);
    }

    function downloadLeaseDocs() {
        if (isNullUndefined(selectionModel) || selectionModel.length < 1) {
            issueWarnMessage(
                "Could not determine the download items. Please refresh the page and try again."
            );
            return;
        }
        let data = {
            leaseIds: selectionModel,
            includeLeaseDetails: includeLeaseDetail,
            statementYear: statementYear.getFullYear(), // e.g. 2022
        };

        downloadLeases(data).then((response) => {
            issueResponseMessage(response);
            handleDownloadClose();
        });
    }


    function populateLeaseContacts() {
        let rows = "";
        leaseContacts &&
        leaseContacts.length > 0 &&
        (rows = leaseContacts.map(function (contact, i) {
            let phone = contact.mobilePhone;
            return (
                <TableRow key={i}>
                    <TableCell>{contact.name}</TableCell>
                    <TableCell>{phone}</TableCell>
                    <TableCell>
                        <Button
                            variant="contained"
                            type="submit"
                            sx={{marginLeft: "10px"}}
                            onClick={(event) => handleLeaseContactRemove(contact.personId)}
                            color="error"
                        >
                            <Cancel/>
                        </Button>
                    </TableCell>
                </TableRow>
            );
        }, this));
        setContactTableRows(rows);
    }

    function populateLeaseChargeItems() {
        let rows = "";
        let total = 0;
        chargeItems &&
        chargeItems.length > 0 &&
        (rows = chargeItems.map(function (charge, i) {
            let amount = tryParseFloat(charge.amount, -1);
            if (amount > 0) {
                total = total + amount;
            }
            return (
                <TableRow key={i}>
                    <TableCell>
                        <Select
                            sx={{width: "90%"}}
                            id={"unit"}
                            value={charge.unit.id}
                            disabled={task === "ActiveLeaseEdit"}
                            onChange={(event) => handleChargeUnitChange(event, i)}
                        >
                            {chargeUnitsOptions && chargeUnitsOptions.length > 0 ? (
                                chargeUnitsOptions.map(function (unit, i) {
                                    return (
                                        <MenuItem key={unit.value} value={unit.value}>
                                            {unit.label}
                                        </MenuItem>
                                    );
                                }, this)
                            ) : (
                                <MenuItem sx={{width: "100%"}}>No Results Found</MenuItem>
                            )}
                        </Select>
                    </TableCell>
                    <TableCell>
                        <Select
                            sx={{width: "90%"}}
                            id={"chargeCode" + i}
                            value={charge.chargeCode.code}
                            disabled={task === "ActiveLeaseEdit"}
                            onChange={(event) => handleChargeCodeChange(event, i)}
                        >
                            {leaseChargeCodes && leaseChargeCodes.length > 0 ? (
                                leaseChargeCodes.map(function (chargeCode, i) {
                                    let disabled = false;
                                    for (let chargeValue of chargeItems) {
                                        if (
                                            charge.unit.id === chargeValue.unit.id &&
                                            chargeValue.chargeCode.code === chargeCode.value
                                        ) {
                                            disabled = true;
                                        }
                                    }
                                    return (
                                        <MenuItem
                                            key={chargeCode.value}
                                            disabled={disabled}
                                            value={chargeCode.value}
                                        >
                                            {chargeCode.label}
                                        </MenuItem>
                                    );
                                }, this)
                            ) : (
                                <MenuItem sx={{width: "100%"}}>No Results Found</MenuItem>
                            )}
                        </Select>
                    </TableCell>
                    <TableCell>
                        <TextField
                            sx={{width: "90%"}}
                            id={"chargeAmount" + i}
                            name={"chargeAmount" + i}
                            isrequired={true}
                            disabled={false}
                            value={charge.amount}
                            onChange={(event) => leaseChargeAmountChange(event, i)}
                        />
                    </TableCell>
                    <TableCell>
                        <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale="en-gb"
                        >
                            <DatePicker
                                sx={{width: "90%"}}
                                id={"chargeStartDate"}
                                value={dayjs(charge.effectiveDate)}
                                disabled={true}
                                onChange={(newDate) =>
                                    handleChargeStartDateChange(newDate, i)
                                }

                            />
                        </LocalizationProvider>
                    </TableCell>
                    <TableCell>
                        {(charge.chargeCode.code === "SERCH" ||
                            charge.chargeCode.code === "REN") && (
                            <Switch
                                checked={charge.taxable}
                                onChange={(event) => handleChargeTaxableToggle(event, i)}
                                inputProps={{"aria-label": "controlled"}}
                            />
                        )}
                        {(charge.chargeCode.code === "LFEE" ||
                            charge.chargeCode.code === "MISC") && (
                            <TextField
                                sx={{width: "90%"}}
                                id={"chargeNote" + i}
                                name={"chargeNote" + i}
                                isrequired={true}
                                disabled={false}
                                value={charge.note}
                                onChange={(event) => leaseChargeNoteChange(event, i)}
                            />
                        )}
                    </TableCell>
                    <TableCell>
                        <Button
                            variant="contained"
                            type="submit"
                            sx={{marginLeft: "10px"}}
                            onClick={(event) => handleLeaseChargeItemRemove(i)}
                            color="error"
                        >
                            <Cancel/>
                        </Button>
                    </TableCell>
                </TableRow>
            );
        }, this));
        setLeaseChargesTableRows(rows);
        setChargeItemsTotal(total);
    }

    function populateUnitSwapItems() {
        let rows = "";
        let meterIndex = 0;
        let selectedMeter = null;
        units &&
        units.length > 0 &&
        (rows = units.map(function (unit, i) {
            let meterOptions = unit.meters
                ? unit.meters.map((meter) => {
                    return {value: meter.id, label: meter.name};
                })
                : [];
            let placeholder = "No Meter attached to Unit.";
            return (
                <TableRow key={i}>
                    <TableCell>{unit.name}</TableCell>
                    <TableCell>{unit.tenant}</TableCell>
                    <TableCell>{formatCurrency(unit.periodicCharge)}</TableCell>
                    <TableCell>
                        {meterOptions.length > 0 ? (
                            <Select
                                sx={{width: "90%"}}
                                id={"meter"}
                                placeholder="Select Meter"
                                value={unit.meters[meterIndex].id}
                                onChange={(value) => {
                                    // console.log("value: ", value)
                                    let index = meterOptions.findIndex(
                                        (e) => e.value === value.value
                                    );
                                    meterIndex = index;
                                    selectedMeter = unit.meters[index];
                                }}
                            >
                                {meterOptions && meterOptions.length > 0 ? (
                                    meterOptions.map(function (meter, i) {
                                        return (
                                            <MenuItem key={meter.value} value={meter.value}>
                                                {meter.label}
                                            </MenuItem>
                                        );
                                    }, this)
                                ) : (
                                    <MenuItem sx={{width: "100%"}}>No Results Found</MenuItem>
                                )}
                            </Select>
                        ) : (
                            placeholder
                        )}
                    </TableCell>
                    <TableCell>
                        {meterOptions.length > 0 ? (
                            <TextField
                                sx={{width: "90%"}}
                                id={"reading" + i}
                                name={"reading" + i}
                                placeholder={unit.meters[meterIndex].lastMeterReading}
                                onChange={(event) => {
                                    editLastMeterReading(event, i, meterIndex);
                                }}
                                value={
                                    isNullUndefined(unit.meters[meterIndex].newMeterReading)
                                        ? ""
                                        : unit.meters[meterIndex].newMeterReading
                                }
                            />
                        ) : (
                            placeholder
                        )}
                    </TableCell>
                    <TableCell>
                        {units.length > 1 && (
                            <Button
                                variant="contained"
                                type="submit"
                                sx={{marginLeft: "10px"}}
                                onClick={() => handleUnitDelete(i)}
                                color="error"
                            >
                                <Cancel/>
                            </Button>
                        )}
                    </TableCell>
                </TableRow>
            );
        }, this));
        setSwapUnitsTableRows(rows);
    }

    function validateAddLeaseContact(data) {
        if (isNullUndefined(data.leaseId) || isEmptyString(data.leaseId)) {
            issueWarnMessage(
                "Something went wrong: couldn't find the ownership to retrieve the contacts. Try refresh the page."
            );
            return false;
        }
        if (isNullUndefined(data.personId) || isEmptyString(data.personId)) {
            issueWarnMessage(
                "Couldn't find the person identifier. Try refresh the page"
            );
            return false;
        }
        let personId = data.personId.replace("_P", "");
        let duplicateContact = leaseContacts.find((contact) => {
            return contact.personId === personId;
        });
        if (!isNullUndefined(duplicateContact)) {
            issueInfoMessage(
                "Cannot add a contact that has already been linked to this ownership"
            );
            return false;
        }
        return true;
    }

    function saveNote() {
        let data = {
            leaseId: selectedLease.id,
            note: note,
        };
        saveLeaseNote(data).then((response) => {
            issueResponseMessage(response);
            handleNoteClose();
        });
    }

    const columns = useMemo(
        () => [
            {
                headerName: "Billing Code",
                field: "billingCode",
                minWidth: 200,
                flex: 2,
            },
            {headerName: "Unit(s)", field: "unitNames", minWidth: 323, flex: 3.23},
            {
                headerName: "Rent Frequency",
                field: "billingPeriod",
                minWidth: 150,
                flex: 1.5,
                valueFormatter: (row) => {
                    if (row.value === "WEEK") {
                        return "Weekly";
                    } else if (row.value === "MNTH") {
                        return "Monthly";
                    } else if (row.value === "ANU") {
                        return "Annually";
                    } else if (row.value === "QT") {
                        return "Quarterly";
                    } else return "N/A";
                },
            },
            {
                headerName: "Type",
                field: "leaseType",
                minWidth: 150,
                flex: 1.5,
                valueFormatter: (row) => {
                    if (row.value === "COM") {
                        return "Commercial";
                    } else if (row.value === "RES") {
                        return "Residential";
                    }
                },
            },
            {headerName: "Tenant", field: "tenantName", minWidth: 300, flex: 3},
            {
                headerName: "Balance",
                field: "leaseBalance",
                minWidth: 180,
                flex: 1.8,
                valueFormatter: (row) => {
                    return formatCurrency(row.value);
                },
            },
            {
                headerName: "Actions",
                field: "actions",
                width: 100,
                type: "actions",
                getActions: (params) => {
                    let arr = [];
                    if (
                        user.proxyRole === "BLDIR" ||
                        user.proxyRole === "BLDMG" ||
                        user.proxyRole === "CS" ||
                        user.proxyRole === "ACC"
                    ) {
                        arr.push(
                            <GridActionsCellItem
                                icon={<Visibility/>}
                                onClick={() => handleViewClick(params.row)}
                                label="View"
                                showInMenu
                            />
                        );
                        arr.push(
                            <GridActionsCellItem
                                icon={<Add/>}
                                label="Add Note"
                                onClick={() => handleNoteClick(params.row)}
                                showInMenu
                            />
                        );
                        let leaseBalance = tryParseFloat(params.row.leaseBalance, -1);
                        if (leaseBalance > 0) {
                            arr.push(
                                <GridActionsCellItem
                                    icon={<LocalAtm/>}
                                    label="Receive Payment"
                                    onClick={() => handlePaymentClick(params.row)}
                                    showInMenu
                                />
                            );
                        }
                        arr.push(
                            <GridActionsCellItem
                                icon={<CreditCard/>}
                                label="Issue Credit"
                                onClick={() => handleCreditClick(params.row)}
                                showInMenu
                            />
                        );
                        arr.push(
                            <GridActionsCellItem
                                icon={<NearMe/>}
                                label="Issue Charge"
                                onClick={() => handleChargeClick(params.row)}
                                showInMenu
                            />
                        );
                    }
                    return arr;
                },
            },
        ]
        //     [],
    );

    return (
        <Permit
            roles="BLDIR,BLDMG,REC,OWN,CS"
            services="PREMIUM,CORE,LEAN"
            noPermittedServiceMessage={{
                title: "Access Denied",
                body: (
                    <>
                        Your current role and/or facility is not permitted to view this
                        page. <br/> Please contact your system admin if you feel this is an
                        error.
                    </>
                ),
            }}
        >
            <MainContainer>
                {task === "" && (
                    <TopBarContainer sx={{width: {xs: "100%", lg: "80%"}}} container>
                        <ResponsiveRow
                            item
                            sx={{
                                justifyContent: {xs: "center", lg: "flex-end"},
                                flexWrap: {xs: "wrap", lg: "nowrap"},
                                width: {xs: "100%", lg: "50%"},
                                padding: "0 10px",
                                margin: "auto 0",
                            }}
                        >
                            <TextField
                                id="searchRef"
                                label="Search Billing Code"
                                sx={{
                                    width: {xs: "100%", lg: "80%"},
                                    // margin: "5px",
                                }}
                                onChange={(event) => onTextChange(event)}
                            />

                            <TextField
                                id="searchTenant"
                                label="Search Tenant"
                                sx={{
                                    width: {xs: "100%", lg: "80%"},
                                    margin: "5px",
                                }}
                                onChange={(event) => onTextChange(event)}
                            />
                        </ResponsiveRow>

                        <Permit roles="BLDIR,BLDMG,ACC,CS">
                            <ResponsiveRow
                                item
                                sx={{
                                    justifyContent: {xs: "center", lg: "flex-end"},
                                    width: {xs: "100%", lg: "50%"},
                                    padding: 0,
                                }}
                            >
                                <TopBarContainedBrandButton
                                    onClick={exportLeaseData}
                                    sx={{
                                        margin: "5px",
                                        minWidth: {xs: "auto", lg: "150px"},
                                        width: {xs: "100%", lg: "auto"},
                                    }}
                                >
                                    Export Leases
                                </TopBarContainedBrandButton>

                                <TopBarContainedBrandButton
                                    onClick={handleDownloadClick}
                                    disabled={selectionModel.length < 1}
                                    sx={{
                                        minWidth: {xs: "auto", lg: "150px"},
                                        width: {xs: "100%", lg: "auto"},
                                        margin: "5px",
                                    }}
                                >
                                    {selectionModel.length > 1
                                        ? "Download " + selectionModel.length + " Leases"
                                        : "Download Lease"}
                                </TopBarContainedBrandButton>
                            </ResponsiveRow>
                        </Permit>
                    </TopBarContainer>
                )}
                {task === "" && <br/>}
                {task === "Unit Swap" && (
                    <TerminatedSwapUnitModal
                        saveEditUnit={saveEditUnit}
                        saveLeaseCharges={saveLeaseCharges}
                        handleLeaseChargeClose={handleLeaseChargeClose}
                        handleLeaseChargeItemAdd={handleLeaseChargeItemAdd}
                        handleUnitSwapClose={handleUnitSwapClose}
                        handleSwapUnitChange={handleSwapUnitChange}
                        setShowSelectUnit={setShowSelectUnit}
                        chargeItemsTotal={chargeItemsTotal}
                        innerTask={innerTask}
                        leaseChargesTableRows={leaseChargesTableRows}
                        selectedLease={selectedLease}
                        selectedUnitId={selectedUnitId}
                        showSelectUnit={showSelectUnit}
                        swapUnitsTableRows={swapUnitsTableRows}
                        task={task}
                        unitOptions={unitOptions}
                    />
                )}
                {task === "View" && (
                    <LeaseView
                        handleStatementYearChange={handleStatementYearChange}
                        statementYear={statementYear}
                        handleUnitSwapClick={handleUnitSwapClick}
                        selectedLease={selectedLease}
                        handleVoidClick={handleVoidClick}
                        setTask={setTask}
                    />
                )}
                {task === "" && (
                    <MuiDataGrid
                        dataGridColumns={columns}
                        dataGridRows={terminatedLeases}
                        serverPagination={true}
                        handlePageLoad={setPaginationModel}
                        handleSelectedRows={setSelectionModel}
                        currentSelectionModel={selectionModel}
                        loading={loading}
                        height="60vh"
                        totalRows={totalTerminatedLeases}
                    />
                )}
                {/*RECEIVE PAYMENT MODAL*/}
                <ReceivePayment
                    showPaymentModal={paymentModal}
                    handlePaymentClose={handlePaymentClose}
                    paymentMode={"Lease"}
                    contract={selectedLease}
                    savePayment={savePayment}
                />
                {/*CREATE CREDIT MODAL*/}
                {creditModal && (
                    <CreditNote
                        open={creditModal}
                        onClose={handleCreditClose}
                        voiditem={voidItem}
                        leaseid={selectedLease && selectedLease.id}
                    />
                )}

                {/*CREATE CHARGE MODAL*/}
                {chargeModal && (
                    <DebitNote
                        open={chargeModal}
                        onClose={handleChargeClose}
                        voiditem={voidItem}
                        leaseid={selectedLease && selectedLease.id}
                    />
                )}

                {/*ADD NEW USER MODAL*/}
                <AddNewUser
                    openModal={addUserModal}
                    closeModal={handleAddUserClose}
                    searchedIdType={idTypeSearch}
                    searchedIdNo={idNoSearch}
                    saveUser={handlePersonOrgSelect}
                    manageUsers={false}
                />
                {/*ADD NEW ORGANISATION MODAL*/}
                <AddNewOrganisation
                    openModal={addOrganizationModal}
                    searchedName={companySearch}
                    closeModal={handleAddOrgClose}
                />
                {/*ADD NOTE MODAL*/}
                <AddTerminatedNoteModal
                    saveNote={saveNote}
                    handleNoteClose={handleNoteClose}
                    onTextChange={onTextChange}
                    selectedLease={selectedLease}
                    note={note}
                    noteModal={noteModal}
                />
                {/*DOWNLOAD MODAL*/}
                <DownloadTerminatedLeaseModal
                    handleDownloadClose={handleDownloadClose}
                    handleStatementYearChange={handleStatementYearChange}
                    handleIncludeLeaseDetailToggle={handleIncludeLeaseDetailToggle}
                    downloadLeaseDocs={downloadLeaseDocs}
                    downloadModal={downloadModal}
                    includeLeaseDetail={includeLeaseDetail}
                    selectionModel={selectionModel}
                    statementYear={statementYear}
                />
                {/*SUSPEND MODAL*/}
                <SuspendTerminatedLeaseModal
                    suspendModal={suspendModal}
                    handleSuspendReactivateClose={handleSuspendReactivateClose}
                    onTextChange={onTextChange}
                    selectedLease={selectedLease}
                    statusChangeReason={statusChangeReason}
                    suspendReActivateLeaseForm={suspendReActivateLeaseForm}
                    task={task}
                />
                {/*PERSON/ORG SEARCH MODAL*/}
                <PersonOrgSearch
                    contractType={'lease'}
                    showPersonOrgModal={tenantModal}
                    handlePersonOrgClose={handlePersonOrgClose}
                    handlePersonOrgSelect={handlePersonOrgSelect}
                    showAddNewUserForm={showAddNewUserForm}
                    showAddNewOrgForm={showAddNewOrgForm}
                />
                {/*LEASE CONTACT MODAL*/}
                <TerminatedContactModal
                    contactModal={contactModal}
                    handleContactClear={handleContactClear}
                    handleContactClose={handleContactClose}
                    handleIdTypeChange={handleIdTypeChange}
                    handlePersonOrgSelect={handlePersonOrgSelect}
                    onTextChange={onTextChange}
                    contactTableRows={contactTableRows}
                    foundTenant={foundTenant}
                    idNoSearch={idNoSearch}
                    idTypes={idTypes}
                    idTypeSearch={idTypeSearch}
                    searchPersonOrg={searchPersonOrg}
                    selectedLease={selectedLease}
                />
            </MainContainer>
        </Permit>
    );
}
