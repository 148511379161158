import {serviceAPI, serviceAPIDownloadFile, serviceAPIJSON} from "./service"
import {
    ASSET_INCOME_VS_EXPENSE_URL,
    ASSIGNED_WORK_ORDERS_URL,
    BALANCE_BY_DAYS_URL,
    BILLS_INVOICES_URL,
    BUDGET_VS_EXPENSE_URL,
    BULK_METER_SALES_INCOME_URL,
    DASHBOARD_REMINDERS_URL,
    DATA_CHARGES_INVOICE_URL,
    EXPENSES_BY_VENDOR_URL,
    HOMEOWNER_DUES_URL,
    INCOME_VS_EXPENSE_URL,
    LEASE_ENDING_LIST_URL,
    METER_VOLUME_SALES_URL,
    OWNER_PENDING_APPROVALS_URL,
    PENDING_APPROVALS_URL,
    PENDING_PAYMENTS_URL,
    RETAIL_METER_SALES_INCOME_URL,
    UNIT_VACANCY_LIST_URL,
    UPCOMING_ASSET_MAINT_URL,
    UTILITIES_READ_VS_UNREAD_URL,
    UTILITIES_WORKING_VS_BROKEN_URL,
    UTILITY_INCOME_VS_EXPENSE_URL,
    SYS_ADMIN_MAP_FACILITIES_URL,
    WO_TILE_URL, SUSPENDED_LEASES_URL, VACATE_NOTICES_URL
} from "../constants/url.constants"
import {handleErrorResponse} from "../actions/message"


export const runSimulationJob = (url, data) => {
    return serviceAPI.post(url, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getSysAdminMapFacilities = () => {
    return serviceAPIJSON.get(SYS_ADMIN_MAP_FACILITIES_URL)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getIncomeVsExpense = (year) => {
    return serviceAPIJSON.get(INCOME_VS_EXPENSE_URL + year)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getExpensesByVendor = (year) => {
    return serviceAPIJSON.get(EXPENSES_BY_VENDOR_URL + year)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getAssetIncomeVsExpense = (year) => {
    return serviceAPIJSON.get(ASSET_INCOME_VS_EXPENSE_URL + year)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getUtilitiesReadVsUnread = (data) => {
    return serviceAPIJSON.get(UTILITIES_READ_VS_UNREAD_URL + "?from=" + data.from + "&to=" + data.to)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}


export const getWorkingVsBrokenMeters = () => {
    return serviceAPIJSON.get(UTILITIES_WORKING_VS_BROKEN_URL)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getUtilityIncomeVsExpense = (year) => {
    return serviceAPIJSON.get(UTILITY_INCOME_VS_EXPENSE_URL + year)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}


export const getBudgetVsExpenses = (year) => {
    return serviceAPIJSON.get(BUDGET_VS_EXPENSE_URL + year)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getRetailMeterSales = (year) => {
    return serviceAPIJSON.get(RETAIL_METER_SALES_INCOME_URL + year)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getBulkMeterSales = (year) => {
    return serviceAPIJSON.get(BULK_METER_SALES_INCOME_URL + year)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}
export const getHomeOwnerDues = () => {
    return serviceAPIJSON.get(HOMEOWNER_DUES_URL)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getHomeOwnerPendingApprovals = () => {
    return serviceAPIJSON.get(OWNER_PENDING_APPROVALS_URL)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getPaymentsAwaitingApproval = () => {
    return serviceAPIJSON.get(PENDING_PAYMENTS_URL)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}


export const getUpcomingAssetMaintenance = () => {
    return serviceAPIJSON.get(UPCOMING_ASSET_MAINT_URL)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}


export const getBillsStats = () => {
    return serviceAPIJSON.get(BILLS_INVOICES_URL)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getWorkOrderTile = () => {
    return serviceAPIJSON.get(WO_TILE_URL)
        .then((response) => {
            return response.data
        })
}

export const getAssignedWorkOrders = () => {
    return serviceAPIJSON.get(ASSIGNED_WORK_ORDERS_URL)
        .then((response) => {
            // console.log('getAssignedWorkOrders is response.data ', response.data)
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getReminders = () => {
    return serviceAPIJSON.get(DASHBOARD_REMINDERS_URL)
        .then((response) => {
            return response.data
        })
}

export const getVacancies = () => {
    return serviceAPIJSON.get(UNIT_VACANCY_LIST_URL)
        .then((response) => {
            return response.data
        })
}

export const getLeasesEnding = () => {
    return serviceAPIJSON.get(LEASE_ENDING_LIST_URL)
        .then((response) => {
            return response.data
        })
}

export const getBalanceOverdue = (filter, chargeType) => {
    return serviceAPIJSON.get(BALANCE_BY_DAYS_URL + "?filter=" + filter + "&chargeType=" + chargeType)
        .then((response) => {
            return response.data
        })
}

export const getPendingApprovals = () => {
    return serviceAPIJSON.get(PENDING_APPROVALS_URL)
        .then((response) => {
            return response.data
        })
}

export const getVacateNotices = () => {
    return serviceAPIJSON.get(VACATE_NOTICES_URL)
        .then((response) => {
            return response.data
        })
}

export const getMeterVolumeSales = (year, selectedMeter) => {
    return serviceAPIJSON.get(METER_VOLUME_SALES_URL + "?year=" + year + "&selectedMeter=" + selectedMeter)
        .then((response) => {
            return response.data
        })
}

//TODO:Convert this guy to a get call
export const runSysAdminReport = (url, data) => {
    return serviceAPIDownloadFile.post(url, data)
}

export const getDataForChargesAndInvoices = (data) => {
    return serviceAPI.post(DATA_CHARGES_INVOICE_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getSuspendedLeases = () => {
    return serviceAPIJSON.get(SUSPENDED_LEASES_URL)
        .then((response) => {
            return response.data
        })
}
