import {
    CardActions,
    CardContent,
    CardHeader,
    FormControl,
    Grid,
    Paper,
    TextField,
    Typography,
} from "@mui/material"
import {useEffect, useState} from "react"
import {formatDate, isNullUndefined, sortJSONArray} from "../../util/util"
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs"
import "dayjs/locale/en-gb"
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider"
import {DatePicker} from "@mui/x-date-pickers/DatePicker"
import {useDispatch} from "react-redux"
import {SET_MESSAGE} from "../../actions/type"
import {
    getSysAdminMapFacilities,
    runSimulationJob,
    runSysAdminReport,
} from "../../services/dashboard.service"
import {
    EMAIL_REPORT_URL,
    MPESA_REPORT_URL, RUN_AST_MAINT_JOB_URL,
    RUN_BILL_RMD_URL,
    RUN_CLT_BILL_URL,
    RUN_HOA_INV_JOB_URL,
    RUN_INV_JOB_URL,
    RUN_MT_BILL_URL,
    RUN_UTL_INV_JOB_URL,
    SUBS_REPORT_URL,
    UNIT_REPORT_URL,
} from "../../constants/url.constants"
import {PropertyMarker, SmartKodiMap} from "../../components/map"
import {
    AdminReportCard,
    ModalAcceptButton,
} from "../../constants/component.constants"
import dayjs from "dayjs"
import {ResponsiveRow} from "../../constants/layout.constants"
import kodi_theme from "../../themes/kodiThemeProvider"

function ReportCard(props) {
    return (
        <AdminReportCard
            sx={{
                marginBottom: "10px",
                padding: "5px",
                width: "100%",
                height: "auto",
                backgroundColor: kodi_theme.palette.grey[200],
                boxShadow: 0,
            }}
        >
            <CardHeader title={props.title} subheader={props.description}/>
            <CardContent>
                <ResponsiveRow
                    sx={{
                        width: "100%",
                        height: "auto",
                        padding: 0,
                        margin: "0 auto",
                        flexWrap: {xs: "wrap", lg: "nowrap"},
                    }}
                    container
                >
                    {props.asOf && (
                        <Typography
                            variant="body1"
                            sx={{
                                width: "100%",
                                height: "auto",
                                margin: "0 auto",
                                fontSize: ".95rem",
                            }}
                            textAlign="start"
                        >
                            {props.title + " as of " + formatDate(props.asOf)}
                        </Typography>
                    )}
                    {props.fromDate && (
                        <FormControl
                            sx={{
                                width: {xs: "100%", lg: "50%"},
                                height: "auto",
                                marginBottom: "5px",
                            }}
                        >
                            <LocalizationProvider
                                dateAdapter={AdapterDayjs}
                                adapterLocale="en-gb"
                            >
                                <DatePicker
                                    id={"fromDate"}
                                    value={props.fromDate ? dayjs(props.fromDate) : dayjs()}
                                    isrequired
                                    disabled={false}
                                    onChange={(newDate) => {
                                        props.setFromDate(newDate)
                                    }}
                                    sx={{
                                        width: "90%",
                                        backgroundColor: kodi_theme.palette.common.white,
                                        borderRadius: "10px",
                                    }}
                                />
                            </LocalizationProvider>
                        </FormControl>
                    )}
                    {props.toDate && (
                        <FormControl
                            sx={{
                                width: {xs: "100%", lg: "50%"},
                                height: "auto",
                                marginBottom: "5px",
                            }}
                        >
                            <LocalizationProvider
                                dateAdapter={AdapterDayjs}
                                adapterLocale="en-gb"
                            >
                                <DatePicker
                                    id={"toDate"}
                                    value={props.toDate ? dayjs(props.toDate) : dayjs()}
                                    isrequired
                                    disabled={false}
                                    onChange={(newDate) => {
                                        props.setToDate(newDate)
                                    }}
                                    sx={{
                                        width: "90%",
                                        backgroundColor: kodi_theme.palette.common.white,
                                        borderRadius: "10px",
                                    }}
                                />
                            </LocalizationProvider>
                        </FormControl>
                    )}
                </ResponsiveRow>
            </CardContent>
            <CardActions
                disableSpacing
                sx={{
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    width: "100%",
                    height: "auto",
                }}
            >
                <ModalAcceptButton
                    variant="contained"
                    onClick={props.executeReport}
                    sx={{
                        width: {xs: "100%", lg: "200px"},
                        height: {xs: "40px", lg: "50px"},
                    }}
                >
                    Run Report
                </ModalAcceptButton>
            </CardActions>
        </AdminReportCard>
    )
}

function SimulationsReports() {
    const [today] = useState(new Date())
    const [rentInvoiceDate, setRentInvoiceDate] = useState(new Date())
    const [assetMaintenanceDate, setAssetMaintenanceDate] = useState(new Date())
    const [associationInvoiceDate, setAssociationInvoiceDate] = useState(new Date())
    const [clientBillingDate, setClientBillingDate] = useState(new Date())
    const [billReminderDate, setBillReminderDate] = useState(new Date())
    const [meterBillingDate, setMeterBillingDate] = useState(new Date())
    const [utilityInvoicingDate, setUtilityInvoicingDate] = useState(new Date())
    const [emailReportsDate, setEmailReportsDate] = useState(new Date())
    const [mpesaFromDate, setMpesaFromDate] = useState(new Date())
    const [mpesaToDate, setMpesaToDate] = useState(new Date())
    const dispatch = useDispatch()

    const executeMpesaReport = () => {
        let sendData = {fromDate: mpesaFromDate, toDate: mpesaToDate}
        runSysAdminReport(MPESA_REPORT_URL, sendData)
    }

    const executeSubscriptionsReport = () => {
        let sendData = {date: today}
        runSysAdminReport(SUBS_REPORT_URL, sendData)
    }

    const executeUnitReport = () => {
        let sendData = {date: today}
        runSysAdminReport(UNIT_REPORT_URL, sendData)
    }

    const runAssetMaintenanceJob = () => {
        if (isNullUndefined(assetMaintenanceDate)) {
            dispatch({
                type: SET_MESSAGE,
                payload: {
                    type: "error",
                    message: "Asset Maintenance Job Simulation requires a date",
                },
            })
            return
        }
        let sendData = {assetMaintenanceDate: assetMaintenanceDate}
        runSimulationJob(RUN_AST_MAINT_JOB_URL, sendData).then((response) => {
            console.log('calling the asset maintenance job ', response)
            dispatch({
                type: SET_MESSAGE,
                payload: {
                    type: response.type,
                    message: response.message,
                },
            })
        })
    }

    const runInvoiceJob = () => {
        if (isNullUndefined(rentInvoiceDate)) {
            dispatch({
                type: SET_MESSAGE,
                payload: {
                    type: "error",
                    message: "Rent Invoice Job Simulation requires a date",
                },
            })
            return
        }
        let sendData = {rentInvoiceDate: rentInvoiceDate}
        runSimulationJob(RUN_INV_JOB_URL, sendData).then((response) => {
            dispatch({
                type: SET_MESSAGE,
                payload: {
                    type: response.type,
                    message: response.message,
                },
            })
        })
    }

    const runAssocInvoiceJob = () => {
        if (isNullUndefined(associationInvoiceDate)) {
            dispatch({
                type: SET_MESSAGE,
                payload: {
                    type: "error",
                    message: "Association Dues Job Simulation requires a date",
                },
            })
            return
        }
        let sendData = {associationInvoiceDate: associationInvoiceDate}
        runSimulationJob(RUN_HOA_INV_JOB_URL, sendData).then((response) => {
            dispatch({
                type: SET_MESSAGE,
                payload: {
                    type: response.type,
                    message: response.message,
                },
            })
        })
    }

    const runClientBillingJob = () => {
        if (isNullUndefined(clientBillingDate)) {
            dispatch({
                type: SET_MESSAGE,
                payload: {
                    type: "error",
                    message: "Client Billing Job Simulation requires a date",
                },
            })
            return
        }
        let sendData = {clientBillingDate: clientBillingDate}
        runSimulationJob(RUN_CLT_BILL_URL, sendData).then((response) => {
            dispatch({
                type: SET_MESSAGE,
                payload: {
                    type: response.type,
                    message: response.message,
                },
            })
        })
    }

    const runBillReminderJob = () => {
        if (isNullUndefined(billReminderDate)) {
            dispatch({
                type: SET_MESSAGE,
                payload: {
                    type: "error",
                    message: "Bill Reminder Job Simulation requires a date",
                },
            })
            return
        }
        let sendData = {billReminderDate: billReminderDate}
        runSimulationJob(RUN_BILL_RMD_URL, sendData).then((response) => {
            dispatch({
                type: SET_MESSAGE,
                payload: {
                    type: response.type,
                    message: response.message,
                },
            })
        })
    }

    const runMeterBillingJob = () => {
        if (isNullUndefined(meterBillingDate)) {
            dispatch({
                type: SET_MESSAGE,
                payload: {
                    type: "error",
                    message: "Meter Billing Job Simulation requires a date",
                },
            })
            return
        }
        let sendData = {meterBillingDate: meterBillingDate}
        runSimulationJob(RUN_MT_BILL_URL, sendData).then((response) => {
            dispatch({
                type: SET_MESSAGE,
                payload: {
                    type: response.type,
                    message: response.message,
                },
            })
        })
    }

    const runUtilityInvoiceJob = () => {
        if (isNullUndefined(utilityInvoicingDate)) {
            dispatch({
                type: SET_MESSAGE,
                payload: {
                    type: "error",
                    message: "Utility Invoice Job Simulation requires a date",
                },
            })
            return
        }
        let sendData = {utilityInvoicingDate: utilityInvoicingDate}
        runSimulationJob(RUN_UTL_INV_JOB_URL, sendData).then((response) => {
            dispatch({
                type: SET_MESSAGE,
                payload: {
                    type: response.type,
                    message: response.message,
                },
            })
        })
    }

    // const runBulkWaterInvoiceJob = () => {
    // 	if (isNullUndefined(bulkWaterInvoiceDate)) {
    // 		dispatch({
    // 			type: SET_MESSAGE,
    // 			payload: {
    // 				type: "error",
    // 				message: "Bulk Water Invoice Job Simulation requires a date",
    // 			},
    // 		})
    // 		return
    // 	}
    // 	let sendData = {bulkWaterInvoiceDate: bulkWaterInvoiceDate}
    // 	runSimulationJob(RUN_BLK_WTR_URL, sendData)
    // 		.then((response) => {
    // 			dispatch({
    // 				type: SET_MESSAGE,
    // 				payload: {
    // 					type: response.type,
    // 					message: response.message,
    // 				},
    // 			})
    // 		})
    // }

    const runEmailReportsJob = () => {
        if (isNullUndefined(emailReportsDate)) {
            dispatch({
                type: SET_MESSAGE,
                payload: {
                    type: "error",
                    message: "Email Reports Job Simulation requires a date",
                },
            })
            return
        }
        let sendData = {emailReportsDate: emailReportsDate}
        runSimulationJob(EMAIL_REPORT_URL, sendData).then((response) => {
            dispatch({
                type: SET_MESSAGE,
                payload: {
                    type: response.type,
                    message: response.message,
                },
            })
        })
    }

    return (
        <ResponsiveRow
            sx={{
                flexWrap: {xs: "wrap", lg: "nowrap"},
                width: "100%",
                height: "auto",
                marginTop: "10px",
                padding: "20px",
                backgroundColor: kodi_theme.palette.common.white,
                border: `1px solid ${kodi_theme.palette.grey[300]}`,
                borderRadius: "10px",
                justifyContent: "center",
                alignItems: "flex-start",
            }}
        >
            <ResponsiveRow
                sx={{
                    flexDirection: "column",
                    flexWrp: "nowrap",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    width: {xs: "100%", lg: "70%"},
                    height: "auto",
                    padding: "10px",
                    marginBottom: "20px",
                }}
            >
                <Typography
                    id="sys-admin-sim-title"
                    variant="h5"
                    color="primary.dark"
                    textAlign="start"
                    marginBottom="10px"
                    sx={{width: "100%"}}
                >
                    System Admin Simulations
                </Typography>
                <ResponsiveRow
                    sx={{
                        flexWrap: {xs: "wrap", lg: "nowrap"},
                        width: "100%",
                        height: "auto",
                        padding: {xs: "5px 0", lg: "10px"},
                        marginBottom: "10px",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <ResponsiveRow
                        sx={{
                            flexWrap: {xs: "wrap", lg: "nowrap"},
                            width: {xs: "100%", lg: "75%"},
                            height: "auto",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: 0,
                        }}
                    >
                        <Typography
                            variant="body1"
                            fontWeight={700}
                            textAlign="start"
                            marginRight="10px"
                            sx={{
                                width: {xs: "100%", lg: "50%"},
                                fontSize: {xs: ".9rem", lg: "1rem"},
                            }}
                        >
                            Rent Invoice Job Simulation
                        </Typography>
                        <FormControl
                            sx={{width: {xs: "100%", lg: "50%"}, height: "auto"}}
                        >
                            <LocalizationProvider
                                dateAdapter={AdapterDayjs}
                                adapterLocale="en-gb"
                            >
                                <DatePicker
                                    id={"rentInvoiceDate"}
                                    value={dayjs(rentInvoiceDate)}
                                    isrequired
                                    disabled={false}
                                    onChange={(newDate) => {
                                        setRentInvoiceDate(newDate)
                                    }}
                                    sx={{width: "100%"}}
                                />
                            </LocalizationProvider>
                        </FormControl>
                    </ResponsiveRow>

                    <ModalAcceptButton
                        variant="contained"
                        onClick={runInvoiceJob}
                        sx={{
                            width: {xs: "100%", lg: "200px"},
                            height: {xs: "40px", lg: "50px"},
                        }}
                    >
                        Run Job
                    </ModalAcceptButton>
                </ResponsiveRow>
                <ResponsiveRow
                    sx={{
                        flexWrap: {xs: "wrap", lg: "nowrap"},
                        width: "100%",
                        height: "auto",
                        padding: {xs: "5px 0", lg: "10px"},
                        marginBottom: "10px",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <ResponsiveRow
                        sx={{
                            flexWrap: {xs: "wrap", lg: "nowrap"},
                            width: {xs: "100%", lg: "75%"},
                            height: "auto",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: 0,
                        }}
                    >
                        <Typography
                            variant="body1"
                            fontWeight={700}
                            textAlign="start"
                            marginRight="10px"
                            sx={{
                                width: {xs: "100%", lg: "50%"},
                                fontSize: {xs: ".9rem", lg: "1rem"},
                            }}
                        >
                            Association Dues Invoice Job Simulation
                        </Typography>
                        <FormControl
                            sx={{width: {xs: "100%", lg: "50%"}, height: "auto"}}
                        >
                            <LocalizationProvider
                                dateAdapter={AdapterDayjs}
                                adapterLocale="en-gb"
                            >
                                <DatePicker
                                    id={"associationInvoiceDate"}
                                    value={dayjs(associationInvoiceDate)}
                                    isrequired
                                    disabled={false}
                                    onChange={(newDate) => {
                                        setAssociationInvoiceDate(newDate)
                                    }}
                                    sx={{width: "100%"}}
                                />
                            </LocalizationProvider>
                        </FormControl>
                    </ResponsiveRow>

                    <ModalAcceptButton
                        variant="contained"
                        onClick={runAssocInvoiceJob}
                        sx={{
                            width: {xs: "100%", lg: "200px"},
                            height: {xs: "40px", lg: "50px"},
                        }}
                    >
                        Run Job
                    </ModalAcceptButton>
                </ResponsiveRow>
                <ResponsiveRow
                    sx={{
                        flexWrap: {xs: "wrap", lg: "nowrap"},
                        width: "100%",
                        height: "auto",
                        padding: {xs: "5px 0", lg: "10px"},
                        marginBottom: "10px",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <ResponsiveRow
                        sx={{
                            flexWrap: {xs: "wrap", lg: "nowrap"},
                            width: {xs: "100%", lg: "75%"},
                            height: "auto",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: 0,
                        }}
                    >
                        <Typography
                            variant="body1"
                            fontWeight={700}
                            textAlign="start"
                            marginRight="10px"
                            sx={{
                                width: {xs: "100%", lg: "50%"},
                                fontSize: {xs: ".9rem", lg: "1rem"},
                            }}
                        >
                            Client Billing Job Simulation
                        </Typography>
                        <FormControl
                            sx={{width: {xs: "100%", lg: "50%"}, height: "auto"}}
                        >
                            <LocalizationProvider
                                dateAdapter={AdapterDayjs}
                                adapterLocale="en-gb"
                            >
                                <DatePicker
                                    id={"clientBillingDate"}
                                    value={dayjs(clientBillingDate)}
                                    isrequired
                                    disabled={false}
                                    onChange={(newDate) => {
                                        setClientBillingDate(newDate)
                                    }}
                                    sx={{width: "100%"}}
                                />
                            </LocalizationProvider>
                        </FormControl>
                    </ResponsiveRow>

                    <ModalAcceptButton
                        variant="contained"
                        onClick={runClientBillingJob}
                        sx={{
                            width: {xs: "100%", lg: "200px"},
                            height: {xs: "40px", lg: "50px"},
                        }}
                    >
                        Run Job
                    </ModalAcceptButton>
                </ResponsiveRow>
                <ResponsiveRow
                    sx={{
                        flexWrap: {xs: "wrap", lg: "nowrap"},
                        width: "100%",
                        height: "auto",
                        padding: {xs: "5px 0", lg: "10px"},
                        marginBottom: "10px",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <ResponsiveRow
                        sx={{
                            flexWrap: {xs: "wrap", lg: "nowrap"},
                            width: {xs: "100%", lg: "75%"},
                            height: "auto",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: 0,
                        }}
                    >
                        <Typography
                            variant="body1"
                            fontWeight={700}
                            textAlign="start"
                            marginRight="10px"
                            sx={{
                                width: {xs: "100%", lg: "50%"},
                                fontSize: {xs: ".9rem", lg: "1rem"},
                            }}
                        >
                            Bill Reminder Job Simulation
                        </Typography>
                        <FormControl
                            sx={{width: {xs: "100%", lg: "50%"}, height: "auto"}}
                        >
                            <LocalizationProvider
                                dateAdapter={AdapterDayjs}
                                adapterLocale="en-gb"
                            >
                                <DatePicker
                                    id={"billReminderDate"}
                                    value={dayjs(billReminderDate)}
                                    isrequired
                                    disabled={false}
                                    onChange={(newDate) => {
                                        setBillReminderDate(newDate)
                                    }}
                                    sx={{width: "100%"}}
                                />
                            </LocalizationProvider>
                        </FormControl>
                    </ResponsiveRow>

                    <ModalAcceptButton
                        variant="contained"
                        onClick={runBillReminderJob}
                        sx={{
                            width: {xs: "100%", lg: "200px"},
                            height: {xs: "40px", lg: "50px"},
                        }}
                    >
                        Run Job
                    </ModalAcceptButton>
                </ResponsiveRow>
                <ResponsiveRow
                    sx={{
                        flexWrap: {xs: "wrap", lg: "nowrap"},
                        width: "100%",
                        height: "auto",
                        padding: {xs: "5px 0", lg: "10px"},
                        marginBottom: "10px",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <ResponsiveRow
                        sx={{
                            flexWrap: {xs: "wrap", lg: "nowrap"},
                            width: {xs: "100%", lg: "75%"},
                            height: "auto",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: 0,
                        }}
                    >
                        <Typography
                            variant="body1"
                            fontWeight={700}
                            textAlign="start"
                            marginRight="10px"
                            sx={{
                                width: {xs: "100%", lg: "50%"},
                                fontSize: {xs: ".9rem", lg: "1rem"},
                            }}
                        >
                            Meter Billing Invoice Job Simulation
                        </Typography>
                        <FormControl
                            sx={{width: {xs: "100%", lg: "50%"}, height: "auto"}}
                        >
                            <LocalizationProvider
                                dateAdapter={AdapterDayjs}
                                adapterLocale="en-gb"
                            >
                                <DatePicker
                                    id={"meterBillingDate"}
                                    value={dayjs(meterBillingDate)}
                                    isrequired
                                    disabled={false}
                                    onChange={(newDate) => {
                                        setMeterBillingDate(newDate)
                                    }}
                                    sx={{width: "100%"}}
                                />
                            </LocalizationProvider>
                        </FormControl>
                    </ResponsiveRow>

                    <ModalAcceptButton
                        variant="contained"
                        onClick={runMeterBillingJob}
                        sx={{
                            width: {xs: "100%", lg: "200px"},
                            height: {xs: "40px", lg: "50px"},
                        }}
                    >
                        Run Job
                    </ModalAcceptButton>
                </ResponsiveRow>
                <ResponsiveRow
                    sx={{
                        flexWrap: {xs: "wrap", lg: "nowrap"},
                        width: "100%",
                        height: "auto",
                        padding: {xs: "5px 0", lg: "10px"},
                        marginBottom: "10px",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <ResponsiveRow
                        sx={{
                            flexWrap: {xs: "wrap", lg: "nowrap"},
                            width: {xs: "100%", lg: "75%"},
                            height: "auto",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: 0,
                        }}
                    >
                        <Typography
                            variant="body1"
                            fontWeight={700}
                            textAlign="start"
                            marginRight="10px"
                            sx={{
                                width: {xs: "100%", lg: "50%"},
                                fontSize: {xs: ".9rem", lg: "1rem"},
                            }}
                        >
                            Utility Invoicing Job Simulation
                        </Typography>
                        <FormControl
                            sx={{width: {xs: "100%", lg: "50%"}, height: "auto"}}
                        >
                            <LocalizationProvider
                                dateAdapter={AdapterDayjs}
                                adapterLocale="en-gb"
                            >
                                <DatePicker
                                    id={"utilityInvoicingDate"}
                                    value={dayjs(utilityInvoicingDate)}
                                    isrequired
                                    disabled={false}
                                    onChange={(newDate) => {
                                        setUtilityInvoicingDate(newDate)
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </FormControl>{" "}
                    </ResponsiveRow>

                    <ModalAcceptButton
                        variant="contained"
                        onClick={runUtilityInvoiceJob}
                        sx={{
                            width: {xs: "100%", lg: "200px"},
                            height: {xs: "40px", lg: "50px"},
                        }}
                    >
                        Run Job
                    </ModalAcceptButton>
                </ResponsiveRow>
                {/*<Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              Bulk Water Invoice Job Simulation
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale="en-gb"
              >
                <DatePicker
                  id={"bulkWaterInvoiceDate"}
                  value={dayjs(bulkWaterInvoiceDate)}
                  isrequired
                  disabled={false}
                  onChange={(newDate) => {
                    setBulkWaterInvoiceDate(newDate)
                  }}

                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
              <Button
                variant="contained"

                onClick={runBulkWaterInvoiceJob}
                sx={{ backgroundColor: "#037171" }}
              >
                Run Job
              </Button>
            </Grid>
          </Grid>*/}
                <ResponsiveRow
                    sx={{
                        flexWrap: {xs: "wrap", lg: "nowrap"},
                        width: "100%",
                        height: "auto",
                        padding: {xs: "5px 0", lg: "10px"},
                        marginBottom: "10px",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <ResponsiveRow
                        sx={{
                            flexWrap: {xs: "wrap", lg: "nowrap"},
                            width: {xs: "100%", lg: "75%"},
                            height: "auto",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: 0,
                        }}
                    >
                        <Typography
                            variant="body1"
                            fontWeight={700}
                            textAlign="start"
                            marginRight="10px"
                            sx={{
                                width: {xs: "100%", lg: "50%"},
                                fontSize: {xs: ".9rem", lg: "1rem"},
                            }}
                        >
                            Email Reports Job Simulation
                        </Typography>
                        <FormControl
                            i
                            sx={{width: {xs: "100%", lg: "50%"}, height: "auto"}}
                        >
                            <LocalizationProvider
                                dateAdapter={AdapterDayjs}
                                adapterLocale="en-gb"
                            >
                                <DatePicker
                                    id={"emailReportsDate"}
                                    value={dayjs(emailReportsDate)}
                                    isrequired
                                    disabled={false}
                                    onChange={(newDate) => {
                                        setEmailReportsDate(newDate)
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </FormControl>
                    </ResponsiveRow>

                    <ModalAcceptButton
                        variant="contained"
                        onClick={runEmailReportsJob}
                        sx={{
                            width: {xs: "100%", lg: "200px"},
                            height: {xs: "40px", lg: "50px"},
                        }}
                    >
                        Run Job
                    </ModalAcceptButton>
                </ResponsiveRow>
                <ResponsiveRow
                    sx={{
                        flexWrap: {xs: "wrap", lg: "nowrap"},
                        width: "100%",
                        height: "auto",
                        padding: {xs: "5px 0", lg: "10px"},
                        marginBottom: "10px",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <ResponsiveRow
                        sx={{
                            flexWrap: {xs: "wrap", lg: "nowrap"},
                            width: {xs: "100%", lg: "75%"},
                            height: "auto",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: 0,
                        }}
                    >
                        <Typography
                            variant="body1"
                            fontWeight={700}
                            textAlign="start"
                            marginRight="10px"
                            sx={{
                                width: {xs: "100%", lg: "50%"},
                                fontSize: {xs: ".9rem", lg: "1rem"},
                            }}
                        >
                            Asset Maintenance Job Simulation
                        </Typography>
                        <FormControl
                            i
                            sx={{width: {xs: "100%", lg: "50%"}, height: "auto"}}
                        >
                            <LocalizationProvider
                                dateAdapter={AdapterDayjs}
                                adapterLocale="en-gb"
                            >
                                <DatePicker
                                    id={"assetMaintenanceDate"}
                                    value={dayjs(assetMaintenanceDate)}
                                    isrequired
                                    disabled={false}
                                    onChange={(newDate) => {
                                        setAssetMaintenanceDate(newDate)
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </FormControl>
                    </ResponsiveRow>

                    <ModalAcceptButton
                        variant="contained"
                        onClick={runAssetMaintenanceJob}
                        sx={{
                            width: {xs: "100%", lg: "200px"},
                            height: {xs: "40px", lg: "50px"},
                        }}
                    >
                        Run Job
                    </ModalAcceptButton>
                </ResponsiveRow>
            </ResponsiveRow>
            <ResponsiveRow
                sx={{
                    flexDirection: "column",
                    flexWrap: "nowrap",
                    width: {xs: "100%", lg: "30%"},
                    height: "auto",
                    justifyContent: "center",
                    alignItems: "flex-start",
                }}
            >
                <Typography
                    sx={{textAlign: "start"}}
                    id="sys-admin-reports-title"
                    marginBottom="20px"
                    variant="h5"
                    color="primary.dark"
                    component="h5"
                >
                    System Admin Reports
                </Typography>
                <Grid container>
                    <ReportCard
                        title={"SmartKodi Mpesa Report"}
                        fromDate={mpesaFromDate}
                        toDate={mpesaToDate}
                        setFromDate={setMpesaFromDate}
                        setToDate={setMpesaToDate}
                        description={"SmartKodi Mpesa Report as of today"}
                        executeReport={executeMpesaReport}
                    />
                    <ReportCard
                        title={"SmartKodi Subscriptions Overview"}
                        asOf={today}
                        description={"SmartKodi Subscriptions Overview as of today"}
                        executeReport={executeSubscriptionsReport}
                    />
                    <ReportCard
                        title={"SmartKodi Units Report"}
                        asOf={today}
                        description={"SmartKodi Units Report as of today"}
                        executeReport={executeUnitReport}
                    />
                </Grid>
            </ResponsiveRow>
        </ResponsiveRow>
    )
}

function SysAdminMap() {
    const [facilities, setFacilities] = useState([])
    const [markers, setMarkers] = useState([])
    const [center, setCenter] = useState({lat: -1.286563, lng: 36.817312})
    const [zoom] = useState(10)
    const [bounds, setBounds] = useState(null)

    useEffect(() => {
        getSysAdminMapFacilities()
            .then((response) => {
                if (response && response.data) {
                    setFacilities(response.data.sort(sortJSONArray("name")));
                }
            })
    }, [])

    useEffect(() => {
        let retBounds = {
            north: 0,
                south: 0,
                west: 0,
                east: 0,
        }
        let updatedMarkers = facilities.map((facility) => {
            if(!isNullUndefined(facility.longitude) &&
                !isNullUndefined(facility.latitude)){
                if(retBounds.north === 0 &&
                    retBounds.south === 0 &&
                    retBounds.west === 0 &&
                    retBounds.east === 0){
                    //initialize the bounds
                    retBounds.north = facility.latitude
                    retBounds.south = facility.latitude
                    retBounds.west = facility.longitude
                    retBounds.east = facility.longitude
                }else{
                    //else update them
                    if(retBounds.north < facility.latitude) retBounds.north = facility.latitude
                    if(facility.latitude < retBounds.south) retBounds.south = facility.latitude
                    if(retBounds.east < facility.longitude) retBounds.east = facility.longitude
                    if(facility.longitude < retBounds.west) retBounds.west = facility.longitude
                }
            }
            return (
                <PropertyMarker
                    key={facility.id}
                    facility={facility}/>
            )
        })
        if(retBounds.north !== 0 ||
            retBounds.south !== 0 ||
            retBounds.west !== 0 ||
            retBounds.east !== 0){
            //slightly bump up the north and south for padding
            retBounds.north = retBounds.north + 0.2
            retBounds.south = retBounds.south - 0.2
            setBounds(retBounds)
            //setCenter(getCenterFromBounds(retBounds))
        }
        if(updatedMarkers.length > 0){
            setMarkers(updatedMarkers)
        }
    }, [facilities])

    return (
        <Paper
            variant="outlined"
            sx={{
                height: "auto",
                marginTop: "1vh",
                padding: "1%",
                textAlign: "left",
            }}
        >
            <SmartKodiMap
                containerStyle={{width: "100%", height: "40vh"}}
                center={center}
                zoom={zoom}
                children={markers}
                bounds={bounds}
            />
        </Paper>
    )
}

export default function DashboardSysAdmin() {
    return (
        <div style={{paddingBottom: "5%"}}>
            <ResponsiveRow
                sx={{
                    backgroundColor: kodi_theme.palette.common.white,
                    borderRadius: "10px",
                    border: `1px solid ${kodi_theme.palette.grey[300]}`,
                    width: "100%",
                    height: "60px",
                    marginTop: "1vh",
                    padding: "5px 20px",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: {xs: "center", lg: "start"},
                }}
            >
                <Typography
                    sx={{width: "100%", height: "auto"}}
                    id="sys-admin-title"
                    variant="h5"
                    component="h4"
                >
                    System Admin Dashboard
                </Typography>
            </ResponsiveRow>
            <SysAdminMap/>
            <SimulationsReports/>
        </div>
    )
}
