/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {
    Alert,
    AlertTitle,
    Backdrop,
    Box,
    CircularProgress,
    Grid,
    Step,
    StepLabel,
    Stepper,
    Typography,
} from "@mui/material";
import {TabContext} from "@mui/lab";
import PersonalDetails from "../../components/modules/personalDetails";
import {MainContainer} from "../../constants/component.constants";
import SignUpBar from "../../components/bars/signUpBar";
import {isNullUndefined, titleCase, tryParseInt} from "../../util/util";
import {issueResponseMessage} from "../../actions/message";
import {fetchSignUp, getStartedData, saveSignUp, uploadTemplate,} from "../../services/signUp.service";
import PropertyDetails from "../../components/modules/propertyDetails";
import SignOnUtilityDetails from "../../components/modules/signOnUtilityDetails";
import SignOnResidentDetails from "../../components/modules/signOnResidentDetails";
import SignOnUnitDetails from "../../components/modules/signOnUnitDetails";
import SignOnReviewDetails from "../../components/modules/signOnReviewDetails";
import CompletedDetails from "../../components/modules/completedDetails";
import {ResponsiveRow} from "../../constants/layout.constants";

//Self sign up with all its steps
export default function SignUp() {
    const appMessage = useSelector((state) => state.message);

    const steps = [
        {step: 0, value: "Personal Details"},
        {step: 1, value: "Property Details"},
        {step: 2, value: "Units Details"},
        {step: 3, value: "Utilities Details"},
        {step: 4, value: "Resident Details"},
        {step: 5, value: "Review Details"},
        {step: 6, value: "Completed"},
    ];

    const [currentStep, setCurrentStep] = useState(0);
    const [signUp, setSignUp] = useState(null);
    const [loading, setIsLoading] = useState(false);

    function showLoader() {
        setIsLoading(() => true);
    }

    function hideLoader() {
        setIsLoading(() => false);
    }

    const handleBackButtonClicked = () => {
        setCurrentStep(currentStep - 1);
    };

    function populateStep(currentStep) {
        switch (currentStep) {
            case 0:
                return (
                    <PersonalDetails
                        signUp={signUp}
                        fetchSignUpData={fetchSignUpData}
                        saveSignUpData={saveSignUpData}
                    />
                );
            case 1:
                return (
                    <PropertyDetails
                        signUp={signUp}
                        saveSignUpData={saveSignUpData}
                        uploadTemplateDocument={uploadTemplateDocument}
                        handleBackButtonClicked={handleBackButtonClicked}
                    />
                );
            case 2:
                return (
                    <SignOnUnitDetails
                        signUp={signUp}
                        saveSignUpData={saveSignUpData}
                        handleBackButtonClicked={handleBackButtonClicked}
                    />
                );
            case 3:
                return (
                    <SignOnUtilityDetails
                        signUp={signUp}
                        saveSignUpData={saveSignUpData}
                        handleBackButtonClicked={handleBackButtonClicked}
                    />
                );
            case 4:
                return (
                    <SignOnResidentDetails
                        signUp={signUp}
                        saveSignUpData={saveSignUpData}
                        handleBackButtonClicked={handleBackButtonClicked}
                    />
                );
            case 5:
                return (
                    <SignOnReviewDetails
                        signUp={signUp}
                        saveSignUpData={saveSignUpData}
                        getStarted={getStarted}
                        showLoader={showLoader}
                        hideLoader={hideLoader}
                        handleBackButtonClicked={handleBackButtonClicked}
                    />
                );
            case 6:
                return (
                    <CompletedDetails
                        signUp={signUp}
                        fetchSignUpData={fetchSignUpData}
                        saveSignUpData={saveSignUpData}
                    />
                );
            default:
                return (
                    <PersonalDetails
                        signUp={signUp}
                        fetchSignUpData={fetchSignUpData}
                        saveSignUpData={saveSignUpData}
                    />
                );
        }
    }

    useEffect(() => {
        setCurrentStep(0);
    }, []);

    useEffect(() => {
        if (
            !isNullUndefined(signUp) &&
            !isNullUndefined(signUp.signUpJson) &&
            !isNullUndefined(signUp.signUpJson.currentStep)
        ) {
            setCurrentStep(tryParseInt(signUp.signUpJson.currentStep, 0));
        } else {
            setCurrentStep(0);
        }
    }, [signUp]);

    function fetchSignUpData(data) {
        showLoader();
        fetchSignUp(data)
            .then((response) => {
                issueResponseMessage(response);
                if (!isNullUndefined(response.signUp)) {
                    setSignUp(response.signUp);
                }
            })
            .finally(() => {
                hideLoader();
            });
    }

    function uploadTemplateDocument(formData) {
        showLoader();
        uploadTemplate(formData)
            .then((response) => {
                issueResponseMessage(response);
                if (!isNullUndefined(response.signUp)) {
                    setSignUp(response.signUp);
                }
            })
            .finally(() => {
                hideLoader();
            });
    }

    function saveSignUpData(sendData) {
        showLoader();
        saveSignUp(sendData)
            .then((response) => {
                issueResponseMessage(response);
                if (!isNullUndefined(response.signUp)) {
                    setSignUp(response.signUp);
                }
            })
            .finally(() => {
                hideLoader();
            });
    }

    function getStarted(sendData) {
        showLoader();
        getStartedData(sendData)
            .then((response) => {
                issueResponseMessage(response);
                if (!isNullUndefined(response.signUp)) {
                    setSignUp(response.signUp);
                }
            })
            .finally(() => {
                hideLoader();
            });
    }

    return (
        <>
            <SignUpBar/>
            <MainContainer
                sx={{
                    width: {xs: "100%", lg: "80%"},
                }}
            >
                <Grid
                    container
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        padding: "20px",
                        borderRadius: {xs: 0, lg: "20px"},
                        marginBottom: "20px",
                        height: "100%",
                    }}
                >
                    <Typography
                        sx={{
                            color: "#037171",
                            textAlign: "left",
                            marginBottom: "20px",
                        }}
                        variant="h1"
                        component="h2"
                    >
                        Sign Up{" "}
                    </Typography>

                    <Typography sx={{mt: 0, fontWeight: 700, textAlign: "left"}}>
                        Welcome to smartKodi and thank you for choosing to start an account
                        with us.
                    </Typography>
                    <Typography
                        sx={{
                            mt: 0,
                            fontWeight: 200,
                            marginBottom: "5px",
                            textAlign: "left",
                        }}
                    >
                        Please complete the following steps by filling in the forms. Feel
                        free to take as much time as you need, every step completed will be
                        saved and stored for you to resume from where you leave off
                    </Typography>
                </Grid>
                <Grid
                    container
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        backgroundColor: "#fff",
                        padding: "20px",
                        borderRadius: {xs: 0, lg: "20px"},
                        boxShadow: {xs: 0, lg: "0 4px 5px 0 rgba(0,0,0,0.3)"},
                        height: "65vh",
                    }}
                >
                    <TabContext value={currentStep}>
                        <Box
                            sx={{
                                borderBottom: 1,
                                borderColor: "divider",
                                display: {xs: "flex", lg: "none"},
                                height: "auto",
                            }}
                        >
                            <ResponsiveRow sx={{height: "auto"}}>
                                <Typography variant="h4">{steps[currentStep].value}</Typography>
                            </ResponsiveRow>
                        </Box>

                        {!appMessage.display && (
                            <ResponsiveRow
                                sx={{
                                    flexDirection: "row",
                                    borderBottom: 1,
                                    borderColor: "divider",
                                    display: {xs: "none", lg: "flex"},
                                    height: "19%",
                                    width: "100%",
                                    padding: 0,
                                }}
                            >
                                <Stepper
                                    activeStep={currentStep}
                                    sx={{width: "100%"}}
                                    alternativeLabel
                                >
                                    {steps.map((step) => (
                                        <Step key={step.step}>
                                            <StepLabel>{step.value}</StepLabel>
                                        </Step>
                                    ))}
                                </Stepper>
                            </ResponsiveRow>
                        )}
                        {appMessage.display && (
                            <ResponsiveRow
                                sx={{
                                    width: "100%",
                                    height: "15%",
                                    position: "relative",
                                    zIndex: 200,
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <Alert
                                    sx={{width: "100%", height: "auto"}}
                                    severity={appMessage.type}
                                >
                                    <AlertTitle>{titleCase(appMessage.type)}</AlertTitle>
                                    {appMessage.message}
                                </Alert>
                            </ResponsiveRow>
                        )}
                        <ResponsiveRow
                            sx={{
                                flexDirection: "column",
                                width: "100%",
                                maxHeight: "84%",
                                height: "80%",
                                overflowY: "auto",
                            }}
                        >
                            {populateStep(currentStep)}

                            <ResponsiveRow
                                component={Backdrop}
                                sx={{
                                    flexDirection: "column",
                                    color: "#fff",
                                    zIndex: (theme) => theme.zIndex.drawer + 1,
                                }}
                                open={loading}
                            >
                                <CircularProgress color="inherit"/>{" "}
                                <Typography
                                    variant="h3"
                                    color="white"
                                    fontWeight={700}
                                    sx={{marginTop: "20px"}}
                                >
                                    Processing
                                </Typography>
                            </ResponsiveRow>
                        </ResponsiveRow>
                    </TabContext>
                </Grid>
            </MainContainer>
        </>
    );
}
