import React from "react";
import { NavMenuItem } from "../../constants/component.constants";
import { NavLink } from "react-router-dom";
import Permit from "../../util/permit";
import { isNullUndefined } from "../../util/util";

export default function DesktopChildlessMenuItem({ item }) {
  return isNullUndefined(item.roles) && isNullUndefined(item.services) ? (
    <NavMenuItem
      id={item.id}
      component={NavLink}
      to={item.href}
      sx={{ textDecoration: "none" }}
    >
      {item.name}
    </NavMenuItem>
  ) : (
    <Permit
      roles={item.roles}
      services={item.services}
      permissions={item.permissions}
      key={item.id}
    >
      <NavMenuItem
        component={NavLink}
        id={item.id}
        to={item.href}
        sx={{ textDecoration: "none" }}
      >
        {item.name}
      </NavMenuItem>
    </Permit>
  );
}
