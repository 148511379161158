import {serviceAPI, serviceAPIDownloadFile} from "./service";
import {
    DELETE_PROPERTY, DELETE_SUBSCRIPTION_DOC_URL, FETCH_SUBSCRIPTION, GET_SUBSCRIPTION_DAYS_OVERDUE,
    GET_SUBSCRIPTION_NOTES, LIST_SUBSCRIPTION_LOGS,
    LIST_SUBSCRIPTIONS,
    PRINT_CLIENT_STATEMENT_URL,
    SAVE_CLIENT_CREDIT,
    SAVE_CLIENT_DEBIT,
    SAVE_CLIENT_PAYMENT,
    SAVE_SUBSCRIPTION,
    SAVE_SUBSCRIPTION_NOTE, TOTAL_SUBSCRIPTION_LOGS_URL,
    TOTAL_SUBSCRIPTIONS_URL, UPLOAD_SUBSCRIPTION_DOC_URL
} from "../constants/url.constants";
import {handleErrorResponse, handleInfoResponse} from "../actions/message";
import {isNullUndefined} from "../util/util";


export const listSubscriptions = (name, pageNo, rowsPerPage) => {
    return serviceAPI
        .get(LIST_SUBSCRIPTIONS + '?name=' + name + '&pageNo=' + pageNo + "&rowsPerPage=" + rowsPerPage)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getTotalSubscriptions = (name) => {
    let url = TOTAL_SUBSCRIPTIONS_URL + name
    return serviceAPI.get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const listSubscriptionLogs = (subscriptionId, pageNo, rowsPerPage) => {
    return serviceAPI
        .get(LIST_SUBSCRIPTION_LOGS + subscriptionId + '&pageNo=' + pageNo + "&rowsPerPage=" + rowsPerPage)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getTotalSubscriptionLogs = (subscriptionId) => {
    return serviceAPI.get(TOTAL_SUBSCRIPTION_LOGS_URL + subscriptionId)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const saveSubscription = (data) => {
    return serviceAPI.post(SAVE_SUBSCRIPTION, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}


export const saveSubscriptionNote = (data) => {
    return serviceAPI.post(SAVE_SUBSCRIPTION_NOTE, data)
        .then((response) => {
            handleInfoResponse(response)
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const saveClientCreditNote = (data) => {
    return serviceAPI.post(SAVE_CLIENT_CREDIT, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const saveClientDebitNote = (data) => {
    return serviceAPI.post(SAVE_CLIENT_DEBIT, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}


export const saveSubscriptionPayment = (data) => {
    return serviceAPI.post(SAVE_CLIENT_PAYMENT, data)
        .then((response) => {
            handleInfoResponse(response.data)
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getSubscriptionNotes = (subscriptionId, pageNo) => {
    let url = ''
    if (isNullUndefined(pageNo)) {
        url = GET_SUBSCRIPTION_NOTES + '?subscriptionId=' + subscriptionId
    } else {
        url = GET_SUBSCRIPTION_NOTES + '?subscriptionId=' + subscriptionId + '&pageNo=' + pageNo
    }
    return serviceAPI.get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const fetchSubscription = (subscriptionId) => {
    return serviceAPI
        .get(FETCH_SUBSCRIPTION + '?subscriptionId=' + subscriptionId)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const deleteSubscriptionDoc = (subscriptionId, data) => {
    return serviceAPI.post(DELETE_SUBSCRIPTION_DOC_URL + subscriptionId, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}


export const deleteProperty = (propertyId) => {
    return serviceAPI
        .get(DELETE_PROPERTY + '?facilityId=' + propertyId)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const printStatement = (subscriptionId, year) => {
    return serviceAPIDownloadFile.get(PRINT_CLIENT_STATEMENT_URL + subscriptionId + "&year=" + year)
}

export const uploadSubscriptionDoc = (subscriptionId, data) => {
    return serviceAPI.post(UPLOAD_SUBSCRIPTION_DOC_URL + subscriptionId, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const loadDaysOverdue = (data) => {
    return serviceAPI.post(GET_SUBSCRIPTION_DAYS_OVERDUE, data)
        .then((response) => {
            handleInfoResponse(response.data)
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}
