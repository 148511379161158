/* eslint-disable react-hooks/exhaustive-deps */

import {useEffect, useMemo, useState} from "react";
import {GridActionsCellItem} from "@mui/x-data-grid";
import {
    Alert,
    AlertTitle,
    Button,
    Checkbox,
    Divider,
    MenuItem,
    Paper,
    Select,
    TableCell,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";
import {
    Add,
    Cancel,
    Clear,
    CreditCard,
    Done,
    Edit,
    LocalAtm,
    NearMe,
    Pause,
    PlayArrow,
    Visibility,
} from "@mui/icons-material";
import {
    activateMeterBilling,
    deleteDraftMeterBilling,
    downloadMeterBillings,
    exportMeterBillings,
    getMeterBilling,
    getMeterBillingPeriods,
    getMetersByFacility,
    getMetersByMeterBilling,
    getTotalMeterBillings,
    resendLastMeterBillingInvoice,
    resendLastMeterBillingReceipt,
    resendMeterBillingStatement,
    saveMeterBillingNote,
    saveMeterBillingPayment,
    saveNewMeterBilling,
    suspendMeterBilling,
    terminateMeterBilling,
    updateMeterBilling,
} from "../services/meterbilling.service";
import {
    getChargeAccounts,
    getCreditAccounts,
    getMeterBillingChargeCodes,
    getRentDays,
    getVATAccount,
} from "../services/list.service";
import {
    getBillingRefNumber,
    getVATAmount,
    isArray,
    isEmptyString,
    isNullUndefined,
    isValidDate,
    titleCase,
    tryParseFloat,
} from "../util/util";
import Permit from "../util/permit";
import {issueResponseMessage, issueWarnMessage} from "../actions/message";
import {useSelector} from "react-redux";
import ReceivePayment from "../components/modals/receivePayment";
import ContractTermination from "../components/contractTermination";
import PersonOrgSearch from "../components/modals/personOrgSearch";
import AddNewUser from "../components/modals/addNewUser";
import AddNewOrganisation from "../components/modals/addNewOrganisation";
import {
    InnerModal,
    MainContainer,
    ModalAcceptButton,
    ModalCancelButton,
    TopBarContainedBrandButton,
    TopBarContainer,
} from "../constants/component.constants";
import ExportDocumentsModal from "../components/modals/exportDocuments";
import BulkUpload from "../components/modals/bulkUpload";
import CreditNote from "../components/modals/creditNote";
import DebitNote from "../components/modals/debitNote";
import {ModalContainer, ModalInnerContainer, ResponsiveRow,} from "../constants/layout.constants";
import AddMeterBilling from "../components/modals/utilities/meterBilling/addMeterBillingModal";
import AddMeterBillingNote from "../components/modals/utilities/meterBilling/addMeterNote";
import MeterBillingSuspendModal from "../components/modals/utilities/meterBilling/suspendModal";
import DownloadMeterBillingModal from "../components/modals/utilities/meterBilling/downloadMeterBillingModal";
import ViewMeterBilling from "../components/modules/meterBilling/viewMeterBilling";
import MuiDataGrid from "../components/modules/MuiDataGrid";
import dayjs from "dayjs";
import SplitButton from "../components/splitButton";
import ConfirmationModal from "../components/modals/confirmation";

export default function MeterBilling() {
    const [BulkModal, setBulkModal] = useState(false);
    const [PaymentModal, setPaymentModal] = useState(false);
    const [NoteModal, setNoteModal] = useState(false);
    const [SuspendModal, setSuspendModal] = useState(false);
    const [TerminateModal, setTerminateModal] = useState(false);
    const [CustomerModal, setCustomerModal] = useState(false);
    const [DownloadModal, setDownloadModal] = useState(false);
    const [DownloadEmailModal, setDownloadEmailModal] = useState(false);
    const [DeleteModal, setDeleteModal] = useState(false);
    const [ActivateModal, setActivateModal] = useState(false);
    const [AddOrganizationModal, setAddOrganizationModal] = useState(false);
    const [AddUserModal, setAddUserModal] = useState(false);
    const [AddDiv, setAddDiv] = useState(false);
    const [ViewDiv, setViewDiv] = useState(false);
    const [EditDiv, setEditDiv] = useState(false);
    const [loading, setLoading] = useState(true);
    const [includeMeterBillingDetail, setMeterBillingDetail] = useState(false);
    const [rows, setRows] = useState([]);
    const [searchReference, setSearchReference] = useState("");
    const [customerSearch, setCustomerSearch] = useState("");
    const [totalMeterbillings, setTotalMeterbillings] = useState(0);
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 25,
        page: 0,
    });
    const [selectedMeterbilling, setselectedMeterbilling] = useState({});
    const [facilityMeters, setFacilityMeters] = useState([]);
    const [rentDays, setRentDays] = useState([]);
    const [billingPeriods, setBillingPeriods] = useState([]);
    const [statementYear, setStatementYear] = useState(dayjs(new Date()));
    const [note, setNote] = useState("");
    const [suspendReason, setSuspendReason] = useState("");
    const [idTypeSearch, setIdType] = useState("");
    const [idNoSearch, setIdNo] = useState("");
    const [companySearch, setCompanySearch] = useState("");
    const [creditItems, setCreditItems] = useState([]);
    const [chargeItems, setChargeItems] = useState([]);
    const [billingItems, setBillingItems] = useState([]);
    const [billingItemsTotal, setBillingItemsTotal] = useState(0);
    const [billingItemsTableRows, setBillingItemsTableRows] = useState("");
    const [creditAccounts, setCreditAccounts] = useState([]);
    const [chargeAccounts, setChargeAccounts] = useState([]);
    const [chargeCodes, setChargeCodes] = useState([]);
    const [vatAccount, setVATAccount] = useState(null);
    const [meterBillingMeters, setMeterBillingMeters] = useState([]);
    const [items, setItems] = useState([]);
    const [rowSelectionModel, setRowSelectionModel] = useState([]);
    const user = useSelector((state) => state.auth.user);
    const appMessage = useSelector((state) => state.message);
    const [terminateItem, setTerminateItem] = useState(null);
    const [titleText, setTitleText] = useState("");
    const [severityText, setSeverityText] = useState("");
    const [bodyText, setBodyText] = useState("");
    const [positiveText, setPositiveText] = useState("");
    const [negativeText, setNegativeText] = useState("");
    const [voiditem, setVoiditem] = useState(null);
    const [creditModal, setCreditModal] = useState(false);
    const [chargeModal, setChargeModal] = useState(false);
    const [task, setTask] = useState("");
    const [selectedBillingPeriod, setSelectedBillingPeriod] = useState("");
    const [selectedBillingDay, setSelectedBillingDay] = useState("");
    const [selectedBillingDate, setSelectedBillingDate] = useState("");
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);

    const handlePaymentClose = () => setPaymentModal(false);

    const handleCreditClose = () => {
        setVoiditem(null);
        setCreditModal(false);
    };
    const handleChargeClose = () => {
        setVoiditem(null);
        setChargeModal(false);
    };
    const handleBillingClose = () => {
        setselectedMeterbilling(null);
        setItems([]); //Clear items
        setBillingItems([]); //Clear items
        setBillingItemsTotal(0);
        setAddDiv(false);
        setEditDiv(false);
        setTask("");
    };
    const handleTerminateClose = () => {
        setItems([]); //Clear items
        setChargeItems([]); //Clear items
        setTerminateModal(false);
    };
    const handleDownloadClose = () => {
        setRowSelectionModel([]); //Clear selection
        setStatementYear(dayjs(new Date()));
        setMeterBillingDetail(false);
        setDownloadModal(false);
        setTitleText("");
        setSeverityText("");
        setBodyText("");
    };

    useEffect(() => {
        setLoading(true);
        let facilityId = null;
        if (user.proxyFacility != null) {
            facilityId = user.proxyFacility;
        }
        getTotalMeterBillings(searchReference, customerSearch).then(
            (meterBillings) => {
                if (meterBillings !== undefined)
                    setTotalMeterbillings(meterBillings.totalBillings);
            }
        );

        getMeterBillingPeriods().then((billingPeriods) =>
            setBillingPeriods(billingPeriods)
        );
        getCreditAccounts().then((creditAccounts) =>
            setCreditAccounts(creditAccounts)
        );
        getRentDays().then((rentDays) => setRentDays(rentDays));
        getMeterBillingChargeCodes().then((chargeCodes) =>
            setChargeCodes(chargeCodes)
        );
        getMetersByFacility(facilityId).then((facilityMeters) =>
            setFacilityMeters(facilityMeters)
        );
        getChargeAccounts().then((chargeAccounts) =>
            setChargeAccounts(chargeAccounts)
        );
        getVATAccount().then((vatAccount) => setVATAccount(vatAccount));
        getMeterBilling(
            searchReference,
            customerSearch,
            paginationModel.page,
            paginationModel.pageSize
        )
            .then((meterBillings) => {
                setRows(meterBillings);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [
        searchReference,
        customerSearch,
        paginationModel.page,
        paginationModel.pageSize,
        user.proxyFacility,
    ]);

    useEffect(() => {
        if (
            !isNullUndefined(selectedMeterbilling) &&
            !isNullUndefined(selectedMeterbilling.id)
        ) {
            setLoading(true);
            getMetersByMeterBilling(selectedMeterbilling.id)
                .then((meterBillingMeters) => setMeterBillingMeters(meterBillingMeters))
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [selectedMeterbilling]);

    useEffect(() => {
        populateCreditItems();
    }, [creditItems]);

    useEffect(() => {
        populateChargeItems();
    }, [chargeItems]);

    useEffect(() => {
        populateMeterBillingItems();
    }, [billingItems, meterBillingMeters]);

    useEffect(() => {
        populateChargeItems();
    }, [chargeItems]);

    function onTextChange(searchText) {
        const id = searchText.target.id;
        const value = searchText.target.value;
        if (id === "searchCode") {
            setSearchReference(value);
        }
        if (id === "searchCustomer") {
            setCustomerSearch(value);
        }
        if (id === "note") {
            setNote(value);
        }
        if (id === "suspendReason") {
            setSuspendReason(value);
        }
    }

    function handleMeterBillingDateChange(value) {
        // let value = event.target.value
        let date = new Date(value);
        let obj = selectedMeterbilling;
        obj.effectiveDate = date;
        setselectedMeterbilling(obj);
    }

    function handleNextBillingDateChange(value) {
        let date = new Date(value);
        setSelectedBillingDate(date);
        let obj = selectedMeterbilling;
        obj.billingDate = date;
        setselectedMeterbilling(obj);
    }

    function validateMeterBillingForm() {
        let data = selectedMeterbilling;
        if (isNullUndefined(data)) {
            issueWarnMessage("Could not determine Meter Billing to save");
            return false;
        }
        let startDate = new Date(data.effectiveDate);
        if (!isValidDate(startDate)) {
            issueWarnMessage("Start date is not a valid date!!");
            return false;
        }
        if (isNullUndefined(data.customer.id)) {
            issueWarnMessage("A customer must be selected!!");
            return false;
        }
        if (isNullUndefined(data.meters)) {
            issueWarnMessage("Meters must be selected!!");
            return false;
        }
        if (isEmptyString(data.billingPeriod)) {
            issueWarnMessage("Billing frequency must be selected!!");
            return false;
        }
        if (data.billingPeriod === "MNTH") {
            if (isEmptyString(data.billingDay)) {
                issueWarnMessage("Billing day must be selected!!");
                return false;
            }
        }
        if (data.billingPeriod === "ANU" || data.billingPeriod === "QT") {
            if (isEmptyString(data.billingDate)) {
                issueWarnMessage("Next billing date must be selected!!");
                return false;
            }
        }
        if (data.billingChargeItems.length < 1) {
            issueWarnMessage(
                "You must add at least one charge to save the meterbilling."
            );
            return false;
        }
        if (isArray(data.billingChargeItems)) {
            for (let charge of data.billingChargeItems) {
                if (isEmptyString(charge.chargeCode)) {
                    issueWarnMessage(
                        "Could not determine the charge type for one of the charges."
                    );
                    return false;
                }
                if (isNullUndefined(charge.amount)) {
                    issueWarnMessage("A charge must have an amount.");
                    return false;
                }
                let amount = tryParseFloat(charge.amount.toString(), -1);
                if (amount < 0) {
                    let message =
                        "Amount '" +
                        charge.amount +
                        "' for charge " +
                        charge.chargeCode.name +
                        " is invalid. Cannot be below 0";
                    if (
                        !isNullUndefined(charge.chargeCode) &&
                        !isEmptyString(charge.chargeCode.name)
                    ) {
                        message =
                            "Amount for charge " +
                            charge.chargeCode.name +
                            " cannot be below 0";
                    }
                    issueWarnMessage(message);
                    return false;
                }
            }
        }
        setselectedMeterbilling(data);
        return true;
    }

    function savePayment(data) {
        data.meterBillingId = selectedMeterbilling.id;
        saveMeterBillingPayment(data).then((response) => {
            issueResponseMessage(response);
            if (!isNullUndefined(response.meterBilling)) {
                getMeterBilling(
                    searchReference,
                    customerSearch,
                    paginationModel.page,
                    paginationModel.pageSize
                ).then((meterBillings) => {
                    if (meterBillings !== undefined) setRows(meterBillings);
                });
                handlePaymentClose();
            }
        });
    }

    function saveMeterBilling() {
        selectedMeterbilling.billingChargeItems = items;
        setselectedMeterbilling(selectedMeterbilling);
        let data = {
            meterIds: selectedMeterbilling.meters,
            selectedBilling: selectedMeterbilling,
        };

        if (data.meterIds.length < 1) {
            issueWarnMessage("You must select at least one meter");
            return false;
        }
        if (validateMeterBillingForm()) {
            saveNewMeterBilling(data).then((response) => {
                issueResponseMessage(response);
                setAddDiv(false);
                handleBillingClose();
                getMeterBilling(
                    searchReference,
                    customerSearch,
                    paginationModel.page,
                    paginationModel.pageSize
                ).then((meterBillings) => {
                    if (meterBillings !== undefined) setRows(meterBillings);
                });
            });
        }
    }

    function saveNote() {
        let data = {
            meterBillingId: selectedMeterbilling.id,
            note: note,
        };
        if (isEmptyString(data.note)) {
            issueWarnMessage("Cannot submit an empty note");
            return false;
        }
        saveMeterBillingNote(data).then((response) => {
            issueResponseMessage(response);
            setNoteModal(false);
            setNote("");
        });
    }

    function suspendBilling() {
        let data = {
            meterBillingId: selectedMeterbilling.id,
            statusChangeReason: suspendReason,
            currentStatus: selectedMeterbilling.statusCd,
        };
        if (isEmptyString(data.statusChangeReason)) {
            issueWarnMessage("Cannot submit an without a reason for suspension");
            return false;
        }
        suspendMeterBilling(data).then((response) => {
            issueResponseMessage(response);
            if (!isNullUndefined(response.meterBilling)) {
                getMeterBilling(
                    searchReference,
                    customerSearch,
                    paginationModel.page,
                    paginationModel.pageSize
                ).then((meterBillings) => {
                    if (meterBillings !== undefined) setRows(meterBillings);
                });
                setSuspendModal(false);
            }
        });
    }

    function terminateBilling(data) {
        terminateMeterBilling(data).then((response) => {
            issueResponseMessage(response);
            handleTerminateClose();
            getMeterBilling(
                searchReference,
                customerSearch,
                paginationModel.page,
                paginationModel.pageSize
            ).then((meterBillings) => {
                if (meterBillings !== undefined) setRows(meterBillings);
            });
            setAddDiv(false);
        });
    }

    function activateDraft() {
        if (isNullUndefined(selectedMeterbilling)) {
            issueWarnMessage(
                "Could not determine Meter Billing ID for activation. Try refresh the page or contact support."
            );
        }
        let data = {
            id: selectedMeterbilling.id,
        };

        activateMeterBilling(data).then((response) => {
            issueResponseMessage(response);
            getMeterBilling(
                searchReference,
                customerSearch,
                paginationModel.page,
                paginationModel.pageSize
            ).then((meterBillings) => {
                if (meterBillings !== undefined) setRows(meterBillings);
            });
            setActivateModal(false);
            setselectedMeterbilling(null);
        });
    }

    function deleteDraft() {
        if (isNullUndefined(selectedMeterbilling)) {
            issueWarnMessage(
                "Could not determine Meter Billing ID for deletion. Try refresh the page or contact support."
            );
        }
        let data = selectedMeterbilling.id;
        deleteDraftMeterBilling(data).then((response) => {
            issueResponseMessage(response);
            getMeterBilling(
                searchReference,
                customerSearch,
                paginationModel.page,
                paginationModel.pageSize
            ).then((meterBillings) => {
                if (meterBillings !== undefined) setRows(meterBillings);
            });
            setDeleteModal(false);
            setselectedMeterbilling(null);
        });
    }

    function updateBilling() {
        selectedMeterbilling.billingChargeItems = items;
        let data = {
            id: selectedMeterbilling.id,
            billingPeriod: selectedMeterbilling.billingPeriod,
            billingDay: selectedMeterbilling.billingDay,
            billingDate: selectedMeterbilling.billingDate,
            billingChargeItems: selectedMeterbilling.billingChargeItems,
        };
        if (validateMeterBillingForm()) {
            updateMeterBilling(data).then((response) => {
                issueResponseMessage(response);
                handleBillingClose();
                if (!isNullUndefined(response.meterBilling)) {
                    getMeterBilling(
                        searchReference,
                        customerSearch,
                        paginationModel.page,
                        paginationModel.pageSize
                    ).then((meterBillings) => {
                        if (meterBillings !== undefined) setRows(meterBillings);
                    });
                }
            });
        }
    }

    function handleViewClick(selectedMeterbilling) {
        setselectedMeterbilling(selectedMeterbilling);
        setViewDiv(true);
    }

    function handlePaymentClick(selectedMeterbilling) {
        setselectedMeterbilling(selectedMeterbilling);
        setPaymentModal(true);
    }

    function handleCreditClick(selectedMeterbilling) {
        setItems((items) => [...items, {accountId: null, amount: null}]);
        setCreditItems(items);
        setselectedMeterbilling(selectedMeterbilling);
        setCreditModal(true);
    }

    function handleChargeClick(selectedMeterbilling) {
        setItems((items) => [
            ...items,
            {
                accountId: null,
                notes: null,
                amount: null,
                applyVAT: false,
                displayVATRadio: true,
            },
        ]);
        setChargeItems(items);
        setselectedMeterbilling(selectedMeterbilling);
        setChargeModal(true);
    }

    function handleAddBillingClick() {
        let facilityId = null;
        const user = JSON.parse(localStorage.getItem("user"));
        if (user.proxyFacility != null) {
            facilityId = user.proxyFacility;
        }
        let data = {
            id: null,
            effectiveDate: "",
            customer: {
                id: null,
                firstName: null,
                name: null,
            },
            billingPeriod: "",
            billingDay: "",
            billingDate: "",
            billingCode: getBillingRefNumber(),
            billingChargeItems: [],
            facilityId: facilityId,
            meters: "",
        };
        setselectedMeterbilling(data);
        setAddDiv(true);
        setEditDiv(false);
    }

    function handleEditClick(selectedMeterbilling) {
        let data = {
            id: selectedMeterbilling.id,
            billingCode: selectedMeterbilling.billingCode,
            effectiveDate: selectedMeterbilling.effectiveDate,
            meters: selectedMeterbilling.meters,
            billingPeriod: selectedMeterbilling.billingPeriod,
            billingDay: selectedMeterbilling.billingDay,
            billingDate: selectedMeterbilling.billingDate,
            billingChargeItems: selectedMeterbilling.billingChargeItems,
            customer: selectedMeterbilling.customer,
        };
        setselectedMeterbilling(data);
        setItems(selectedMeterbilling.billingChargeItems);
        setBillingItems(selectedMeterbilling.billingChargeItems);
        setAddDiv(true);
        setEditDiv(true);
        setTask("Edit");
    }

    function handleNoteClick(selectedMeterbilling) {
        setselectedMeterbilling(selectedMeterbilling);
        setNoteModal(true);
    }

    function handleSuspendClick(selectedMeterbilling) {
        setselectedMeterbilling(selectedMeterbilling);
        setSuspendModal(true);
    }

    function handleTerminateClick(selectedMeterbilling) {
        let data = {
            meterBillingId: selectedMeterbilling.id,
            paymentMethod: "", //CASH/CHEQUE - only needed if final balance < 0
            cashAccountId: "", //When Cash, must select which account
            chequeNumber: "", // When Cheque
            bankRefNumber: "", // For RTGS payments
            charges: [],
        };
        setTerminateItem(data);
        setItems([]);
        setChargeItems([]);
        setselectedMeterbilling(selectedMeterbilling);
        setTerminateModal(true);
    }

    function handleActivateClick(selectedMeterbilling) {
        setselectedMeterbilling(selectedMeterbilling);
        setActivateModal(true);
    }

    function handleDeleteClick(selectedMeterbilling) {
        setselectedMeterbilling(selectedMeterbilling);
        setDeleteModal(true);
    }

    function handleBillingPeriodChange(event) {
        let value = event.target.value;
        setSelectedBillingPeriod(value);
        let obj = selectedMeterbilling;
        obj.billingPeriod = value;
        setselectedMeterbilling(obj);
    }

    function handleMetersChange(event) {
        let value = event.target.value;
        let obj = selectedMeterbilling;
        obj.meters = value;
        setselectedMeterbilling(obj);
    }

    function handleBillingDayChange(event) {
        let value = event.target.value;
        setSelectedBillingDay(value);
        let obj = selectedMeterbilling;
        obj.billingDay = value;
        setselectedMeterbilling(obj);
    }

    function handleCreditAccountChange(event, index) {
        let value = event.target.value;
        let newArr = [...items]; // copying the old items array
        newArr[index].accountId = value;
        setItems(newArr);
        populateCreditItems();
    }

    function handleChargeAccountChange(event, index) {
        let value = event.target.value;
        let newArr = [...items]; // copying the old items array
        newArr[index].accountId = value;
        setItems(newArr);
        populateChargeItems();
    }

    function handleBillingAccountChange(event, index) {
        let value = event.target.value;
        let name = event.target.label;
        let newArr = [...items]; // copying the old items array
        newArr[index].chargeCode.code = value;
        newArr[index].chargeCode.name = name;
        setItems(newArr);
        populateMeterBillingItems();
    }

    function handleBillingMeterChange(event, index) {
        let value = event.target.value;
        let name = event.target.label;
        let newArr = [...items]; // copying the old items array
        newArr[index].meter.id = value;
        newArr[index].meter.name = name;
        setItems(newArr);
        populateMeterBillingItems();
    }

    function creditAmountChange(event, index) {
        const value = event.target.value;
        let newArr = [...items]; // copying the old items array
        newArr[index].amount = value;
        setItems(newArr);
        populateCreditItems();
    }

    function chargeAmountChange(event, index) {
        const value = event.target.value;
        let newArr = [...items]; // copying the old items array
        newArr[index].amount = value;
        setItems(newArr);
        populateChargeItems();
    }

    function billingAmountChange(event, index) {
        const value = event.target.value;
        let newArr = [...items]; // copying the old items array
        newArr[index].amount = value;
        setItems(newArr);
        populateMeterBillingItems();
    }

    function handleStatementYearChange(value) {
        let date = dayjs(new Date(value));
        setStatementYear(date);
    }

    function handleBillingItemAdd() {
        setItems((items) => [
            ...items,
            {
                meter: {name: "", id: ""},
                chargeCode: {code: "", name: ""},
                amount: null,
                date: null,
                taxable: false,
            },
        ]);
        setBillingItems((billingItems) => [
            ...billingItems,
            {
                meter: null,
                chargeCode: null,
                setDownloadModal,
                amount: null,
                date: null,
                taxable: false,
            },
        ]);
    }

    function handleCreditItemRemove(index) {
        let newArr = [...items]; // copying the old items array
        if (newArr.length > 1) {
            newArr.splice(index, 1);
            setItems(newArr);
            setCreditItems(newArr);
        } else {
            issueWarnMessage("There must be at least one credit item");
        }
    }

    function handleChargeItemRemove(index) {
        let newArr = [...items]; // copying the old items array
        if (newArr.length > 1) {
            findAndRemoveChargeVAT(items[index]);
            newArr.splice(index, 1);
            setItems(newArr);
            setChargeItems(items);
        } else {
            issueWarnMessage("There must be at least one credit item");
        }
    }

    function handleBillingItemRemove(index) {
        let newArr = [...items]; // copying the old items array
        if (newArr.length > 1) {
            findAndRemoveBillingVAT(items[index]);
            newArr.splice(index, 1);
            setItems(newArr);
            setBillingItems(items);
        } else {
            issueWarnMessage("There must be at least one credit item");
        }
    }

    function populateCreditItems() {
        items &&
        items.length > 0 &&
        items.map(function (creditItem, i) {
            return (
                <TableRow key={i}>
                    <TableCell>
                        <Select
                            sx={{width: "90%"}}
                            id={"creditAccount" + i}
                            name={"creditAccount" + i}
                            isrequired={true}
                            value={items && items[i] && items[i].accountId}
                            onChange={(event) => handleCreditAccountChange(event, i)}
                        >
                            {creditAccounts && creditAccounts.length > 0 ? (
                                creditAccounts.map(function (creditAccount) {
                                    return (
                                        <MenuItem
                                            key={creditAccount.value}
                                            value={creditAccount.value}
                                        >
                                            {creditAccount.label}
                                        </MenuItem>
                                    );
                                }, this)
                            ) : (
                                <MenuItem sx={{width: "100%"}}>No Results Found</MenuItem>
                            )}
                        </Select>
                    </TableCell>
                    <TableCell>
                        <TextField
                            sx={{width: "90%"}}
                            id={"creditAmount" + i}
                            name={"creditAmount" + i}
                            isrequired={true}
                            value={items && items[i] && items[i].amount}
                            onChange={(event) => creditAmountChange(event, i)}
                        />
                    </TableCell>
                    <TableCell>
                        <Button
                            variant="contained"
                            type="submit"
                            sx={{marginLeft: "10px"}}
                            onClick={(event) => handleCreditItemRemove(i)}
                            color="error"
                        >
                            <Cancel/>
                        </Button>
                    </TableCell>
                </TableRow>
            );
        }, this);
    }

    function populateChargeItems() {
        items &&
        items.length > 0 &&
        items.map(function (chargeItem, i) {
            return (
                <TableRow key={i}>
                    <TableCell>
                        <Select
                            sx={{width: "90%"}}
                            id={"chargeAccount" + i}
                            name={"chargeAccount" + i}
                            isrequired={true}
                            disabled={items && items[i] && !items[i].displayVATRadio}
                            value={items && items[i] && items[i].accountId}
                            onChange={(event) => handleChargeAccountChange(event, i)}
                        >
                            {chargeAccounts && chargeAccounts.length > 0 ? (
                                chargeAccounts.map(function (chargeAccount, i) {
                                    return (
                                        <MenuItem
                                            key={chargeAccount.value}
                                            value={chargeAccount.value}
                                        >
                                            {chargeAccount.label}
                                        </MenuItem>
                                    );
                                }, this)
                            ) : (
                                <MenuItem sx={{width: "100%"}}>No Results Found</MenuItem>
                            )}
                        </Select>
                    </TableCell>
                    <TableCell>
                        <TextField
                            sx={{width: "90%"}}
                            id={"chargeItemMemo" + i}
                            name={"chargeItemMemo" + i}
                            isrequired={true}
                            disabled={items && items[i] && !items[i].displayVATRadio}
                            value={items && items[i] && items[i].notes}
                            onChange={(event) => chargeItemMemoChange(event, i)}
                        />
                    </TableCell>
                    <TableCell>
                        <TextField
                            sx={{width: "90%"}}
                            id={"chargeAmount" + i}
                            name={"chargeAmount" + i}
                            isrequired={true}
                            disabled={items && items[i] && !items[i].displayVATRadio}
                            value={items && items[i] && items[i].amount}
                            onChange={(event) => chargeAmountChange(event, i)}
                        />
                    </TableCell>
                    <TableCell>
                        {items && items[i] && items[i].displayVATRadio && (
                            <Checkbox
                                checked={items && items[i] && items[i].applyVAT}
                                onChange={(event) => handleChargeCheckboxToggle(event, i)}
                                inputProps={{"aria-label": "controlled"}}
                            />
                        )}
                    </TableCell>
                    <TableCell>
                        {items && items[i] && items[i].displayVATRadio && (
                            <Button
                                variant="contained"
                                type="submit"
                                sx={{marginLeft: "10px"}}
                                onClick={(event) => handleChargeItemRemove(i)}
                                color="error"
                            >
                                <Cancel/>
                            </Button>
                        )}
                    </TableCell>
                </TableRow>
            );
        }, this);
    }

    function populateMeterBillingItems() {
        let rows = "";
        let total = 0;
        let today = new Date();
        items &&
        items.length > 0 &&
        (rows = items.map(function (billingItem, i) {
            let disabled =
                task === "Edit" &&
                billingItem.chargeCode &&
                !isEmptyString(billingItem.chargeCode) &&
                !isNullUndefined(billingItem.id);
            let amount = tryParseFloat(billingItem.amount, -1);
            if (amount > 0) {
                total = total + amount;
            }
            return (
                <TableRow key={i}>
                    {EditDiv && (
                        <TableCell>
                            <Select
                                sx={{width: "90%"}}
                                id={"meter"}
                                disabled={false}
                                value={items && items[i] && items[i].meter.id}
                                onChange={(event) => handleBillingMeterChange(event, i)}
                                defaultValue={[""]}
                            >
                                {meterBillingMeters && meterBillingMeters.length > 0 ? (
                                    meterBillingMeters.map(function (meterOption, i) {
                                        let disabled = false;
                                        for (let chargeValue of items) {
                                            if (chargeValue.meter.id === meterOption.value) {
                                                disabled = true;
                                            }
                                        }
                                        return (
                                            <MenuItem
                                                key={meterOption.value}
                                                value={meterOption.value}
                                                disabled={disabled}
                                            >
                                                {meterOption.label}
                                            </MenuItem>
                                        );
                                    }, this)
                                ) : (
                                    <MenuItem sx={{width: "100%"}}>No Results Found</MenuItem>
                                )}
                            </Select>
                        </TableCell>
                    )}
                    <TableCell>
                        <Select
                            sx={{width: "90%"}}
                            id={"billingAccount" + i}
                            name={"billingAccount" + i}
                            isrequired={true}
                            disabled={disabled}
                            value={items && items[i].chargeCode && items[i].chargeCode.code}
                            onChange={(event) => handleBillingAccountChange(event, i)}
                        >
                            {chargeCodes && chargeCodes.length > 0 ? (
                                chargeCodes.map(function (chargeCode, i) {
                                    let disabled = false;
                                    for (let chargeValue of items) {
                                        if (
                                            billingItem.meter &&
                                            billingItem.meter.id === chargeValue.meter.id &&
                                            chargeValue.chargeCode.code === chargeCode.value
                                        ) {
                                            disabled = true;
                                        }
                                    }
                                    return (
                                        <MenuItem
                                            key={chargeCode.value}
                                            disabled={disabled}
                                            value={chargeCode.value}
                                        >
                                            {chargeCode.label}
                                        </MenuItem>
                                    );
                                }, this)
                            ) : (
                                <MenuItem sx={{width: "100%"}}>No Results Found</MenuItem>
                            )}
                        </Select>
                    </TableCell>
                    <TableCell>
                        <TextField
                            sx={{width: "90%"}}
                            id={"billingItemAmount" + i}
                            name={"billingItemAmount" + i}
                            isrequired={true}
                            // disabled={items && items[i] && !items[i].displayVATRadio}
                            value={items && items[i] && items[i].amount}
                            onChange={(event) => billingAmountChange(event, i)}
                        />
                    </TableCell>
                    <TableCell>
                        <TextField
                            id={"date" + i}
                            disabled
                            placeholder={today.toString()}
                            value={items && items[i] && items[i].effectiveDate}
                        />
                    </TableCell>
                    <TableCell>
                        {billingItem &&
                            billingItem.chargeCode &&
                            billingItem.chargeCode.code &&
                            billingItem.chargeCode.code === "UTSCH" && (
                                <Checkbox
                                    checked={items && items[i] && items[i].taxable}
                                    onChange={(event) => handleBillingCheckboxToggle(event, i)}
                                    inputProps={{"aria-label": "controlled"}}
                                />
                            )}
                    </TableCell>
                    <TableCell>
                        {!disabled && (
                            <Button
                                variant="contained"
                                type="submit"
                                sx={{marginLeft: "10px"}}
                                onClick={(event) => handleBillingItemRemove(i)}
                                color="error"
                            >
                                <Cancel/>
                            </Button>
                        )}
                    </TableCell>
                </TableRow>
            );
        }, this));
        setBillingItemsTableRows(rows);
        setBillingItemsTotal(total);
    }

    function findAndRemoveChargeVAT(detailItem) {
        let vatAmount = getVATAmount(detailItem.amount);
        for (let i = 0; i < items.length; i++) {
            if (
                items[i].amount === vatAmount &&
                items[i].notes.includes(detailItem.notes)
            ) {
                handleChargeItemRemove(i);
            }
        }
        populateChargeItems();
    }

    function findAndRemoveBillingVAT(detailItem) {
        let vatAmount = getVATAmount(detailItem.amount);
        for (let i = 0; i < items.length; i++) {
            if (items[i].amount === vatAmount) {
                handleBillingItemRemove(i);
            }
        }
        populateMeterBillingItems();
    }

    function handleChargeCheckboxToggle(event, index) {
        let newArr = [...items]; // copying the old items array
        let detailItem = newArr[index];
        if (isNullUndefined(detailItem.amount) || detailItem.amount === "0") {
            issueWarnMessage("You must enter an amount before applying VAT");
        } else {
            if (event.target.checked) {
                let vatNotes = "VAT (16%) - " + detailItem.notes;
                let vatAmount = getVATAmount(detailItem.amount);
                detailItem.applyVAT = true;
                setItems(newArr);
                setItems((items) => [
                    ...items,
                    {
                        accountId: vatAccount.id,
                        notes: vatNotes,
                        amount: vatAmount,
                        applyVAT: false,
                        displayVATRadio: false,
                    },
                ]);
                setChargeItems((chargeItems) => [
                    ...chargeItems,
                    {
                        accountId: vatAccount.id,
                        notes: vatNotes,
                        amount: vatAmount,
                        applyVAT: false,
                        displayVATRadio: false,
                    },
                ]);
            } else {
                detailItem.applyVAT = false;
                setItems(newArr);
                setChargeItems(newArr);
                findAndRemoveChargeVAT(detailItem);
            }
        }
    }

    function handleBillingCheckboxToggle(event, index) {
        const value = event.target.checked;
        let newArr = [...items]; // copying the old items array
        newArr[index].taxable = value;
        setItems(newArr);
        populateMeterBillingItems();
    }

    function handleIncludeMeterBillingDetailToggle(event) {
        setMeterBillingDetail(event.target.checked);
    }

    function chargeItemMemoChange(event, index) {
        const value = event.target.value;
        let newArr = [...items]; // copying the old items array
        newArr[index].notes = value;
        setItems(newArr);
        populateChargeItems();
    }

    const handlePersonOrgClose = () => {
        setCustomerModal(false);
    };

    function handlePersonOrgSelect(result) {
        let obj = selectedMeterbilling;
        obj.customer = result;
        setselectedMeterbilling(obj);
        setCustomerModal(false);
    }

    function showAddNewUserForm(idNoSearch, idTypeSearch) {
        setIdType(idTypeSearch);
        setIdNo(idNoSearch);
        setCustomerModal(false);
        setAddUserModal(true);
    }

    function showAddNewOrgForm(companySearch) {
        setCompanySearch(companySearch);
        setCustomerModal(false);
        setAddOrganizationModal(true);
    }

    function handleAddUserClose() {
        setAddUserModal(false);
    }

    function handleAddOrgClose() {
        setAddOrganizationModal(false);
    }

    function exportMeterbillingAccounts() {
        exportMeterBillings();
    }

    function handleDownloadClick() {
        setDownloadModal(true);
        let title =
            rowSelectionModel.length > 1
                ? "Download " + rowSelectionModel.length + " Meter Billings"
                : "Download Meter Billing";
        let body =
            "Note: Processing the selected meter billings may take some time." +
            " Therefore, the exported data will be emailed to you when it is finished being packaged." +
            " Please ensure the email set for your account is valid, working and that you have access to it in order to receive the export data.";
        setTitleText(title);
        setSeverityText("info");
        setBodyText(body);
    }

    function positiveExportDocsAction() {
        downloadMeterBillingDocs();
    }

    function downloadMeterBillingDocs() {
        if (isNullUndefined(rowSelectionModel) || rowSelectionModel.length < 1) {
            issueWarnMessage(
                "Could not determine the download items. Please refresh the page and try again."
            );
            return;
        }
        let data = {
            meterBillingIds: rowSelectionModel,
            includeMeterBillingDetails: includeMeterBillingDetail,
            statementYear: dayjs(statementYear).year(), // e.g. 2022
        };
        downloadMeterBillings(data).then((response) => {
            issueResponseMessage(response);
            handleDownloadClose();
        });
    }

    function handleSendMeterBillingDocClick(docType) {
        let title = "Confirm Send " + docType;
        let body =
            "Are you sure you want to resend the last " +
            docType +
            " for " +
            rowSelectionModel.length +
            " selected meter billing(s)?";
        setTitleText(title);
        setTask("Resend " + docType);
        setSeverityText("warning");
        setBodyText(body);
        setPositiveText("Send");
        setNegativeText("Cancel");
        setShowConfirmationModal(true);
    }

    const handleConfirmationClose = () => {
        setShowConfirmationModal(false);
        setTitleText("");
        setTask("");
        setSeverityText("");
        setBodyText("");
        setPositiveText("");
        setNegativeText("");
        setselectedMeterbilling(null);
        setRowSelectionModel([]);
    };

    function resendLastMeterBillingInvoiceClicked() {
        let sendData = {bulkEditIds: rowSelectionModel};
        resendLastMeterBillingInvoice(sendData).then((response) => {
            issueResponseMessage(response);
            handleConfirmationClose();
        });
    }

    function resendLastMeterBillingReceiptClicked() {
        let sendData = {bulkEditIds: rowSelectionModel};
        resendLastMeterBillingReceipt(sendData).then((response) => {
            issueResponseMessage(response);
            handleConfirmationClose();
        });
    }

    function resendMeterBillingStatementClicked() {
        let sendData = {bulkEditIds: rowSelectionModel};
        resendMeterBillingStatement(sendData).then((response) => {
            issueResponseMessage(response);
            handleConfirmationClose();
        });
    }

    function positiveAction() {
        if (task === "Resend Invoice") {
            resendLastMeterBillingInvoiceClicked();
        } else if (task === "Resend Receipt") {
            resendLastMeterBillingReceiptClicked();
        } else if (task === "Resend Statement") {
            resendMeterBillingStatementClicked();
        }
    }

    const meterBillingButtons = [
        {
            label: "METER BILLING ACTIONS",
            roles: false,
            services: false,
        },
        {
            action: () => handleDownloadClick(),
            label:
                rowSelectionModel.length > 1
                    ? "Download " + rowSelectionModel.length + " Meter Billings"
                    : "Download Meter Billing",
            roles: "BLDIR,ACC,SYSAD,BLDMG,CS",
            services: false,
            disabled: rowSelectionModel.length <= 0,
        },
        {
            action: () => handleSendMeterBillingDocClick("Invoice"),
            label:
                rowSelectionModel.length > 1
                    ? "Resend " + rowSelectionModel.length + " Invoices"
                    : "Resend Last Invoice",
            roles: "BLDIR,BLDMG,CS",
            services: false,
            disabled: rowSelectionModel.length <= 0,
        },
        {
            action: () => handleSendMeterBillingDocClick("Receipt"),
            label:
                rowSelectionModel.length > 1
                    ? "Resend " + rowSelectionModel.length + " Receipt"
                    : "Resend Last Receipt",
            roles: "BLDIR,BLDMG,CS",
            services: false,
            disabled: rowSelectionModel.length <= 0,
        },
        {
            action: () => handleSendMeterBillingDocClick("Statement"),
            label:
                rowSelectionModel.length > 1
                    ? "Resend " + rowSelectionModel.length + " Statements"
                    : "Resend Statement",
            roles: "BLDIR,BLDMG,CS",
            services: false,
            disabled: rowSelectionModel.length <= 0,
        },
    ];

    const columns = useMemo(() => [
        {
            headerName: "Billing Code",
            field: "billingCode",
            minWidth: 120,
            flex: 1.2,
        },
        {headerName: "Meter(s)", field: "meters", minWidth: 200, flex: 2},
        {
            headerName: "Billing Frequency",
            field: "billingFrequency",
            minWidth: 170,
            flex: 1.7,
            valueFormatter: (row) => {
                if (row.value === "MNTH") {
                    return "Monthly";
                } else if (row.value === "ANU") {
                    return "Annually";
                } else if (row.value === "QT") {
                    return "Quarterly";
                } else return "N/A";
            },
        },
        {headerName: "Taxable", field: "taxable", minWidth: 100, flex: 1},
        {
            headerName: "Account Holder",
            field: "customerName",
            minWidth: 370,
            flex: 3.7,
        },
        {
            headerName: "Balance",
            field: "meterBillingBalance",
            minWidth: 150,
            flex: 1.5,
        },
        {
            headerName: "Status",
            field: "statusCd",
            minWidth: 140,
            flex: 1.2,
            valueFormatter: (row) => {
                if (row.value === "DRF") {
                    return "Draft";
                } else if (row.value === "ACT") {
                    return "Active";
                } else if (row.value === "SUP") {
                    return "Suspended";
                } else if (row.value === "TER") {
                    return "Terminated";
                } else return "N/A";
            },
        },
        {
            headerName: "Actions",
            field: "actions",
            width: 100,
            type: "actions",
            getActions: (params) => {
                let arr = [];
                if (params.row.statusCd === "ACT") {
                    if (
                        user.proxyRole === "REC" ||
                        user.proxyRole === "BLDIR" ||
                        user.proxyRole === "BLDMG" ||
                        user.proxyRole === "CS"
                    ) {
                        arr.push(
                            <GridActionsCellItem
                                icon={<Visibility/>}
                                label="View"
                                onClick={() => handleViewClick(params.row)}
                                showInMenu
                            />
                        );
                    }
                    if (
                        user.proxyRole === "BLDIR" ||
                        user.proxyRole === "BLDMG" ||
                        user.proxyRole === "CS"
                    ) {
                        arr.push(
                            <GridActionsCellItem
                                icon={<LocalAtm/>}
                                label="Receive Payment"
                                onClick={() => handlePaymentClick(params.row)}
                                showInMenu
                            />
                        );
                        arr.push(
                            <GridActionsCellItem
                                icon={<CreditCard/>}
                                label="Issue Credit"
                                onClick={() => handleCreditClick(params.row)}
                                showInMenu
                            />
                        );
                        arr.push(
                            <GridActionsCellItem
                                icon={<NearMe/>}
                                label="Issue Charge"
                                onClick={() => handleChargeClick(params.row)}
                                showInMenu
                            />
                        );
                    }
                    if (user.proxyRole === "BLDIR") {
                        arr.push(
                            <GridActionsCellItem
                                icon={<Edit/>}
                                label="Edit"
                                onClick={() => handleEditClick(params.row)}
                                showInMenu
                            />
                        );
                    }
                    if (
                        user.proxyRole === "REC" ||
                        user.proxyRole === "BLDIR" ||
                        user.proxyRole === "BLDMG" ||
                        user.proxyRole === "CS"
                    ) {
                        arr.push(
                            <GridActionsCellItem
                                icon={<Add/>}
                                label="Add Note"
                                onClick={() => handleNoteClick(params.row)}
                                showInMenu
                            />
                        );
                    }
                    if (user.proxyRole === "BLDIR" || user.proxyRole === "SYSAD") {
                        arr.push(
                            <GridActionsCellItem
                                icon={<Pause/>}
                                label="Suspend"
                                onClick={() => handleSuspendClick(params.row)}
                                showInMenu
                            />
                        );
                    }
                    if (
                        user.proxyRole === "BLDIR" ||
                        user.proxyRole === "BLDMG" ||
                        user.proxyRole === "CS" ||
                        user.proxyRole === "ACC"
                    ) {
                        arr.push(
                            <GridActionsCellItem
                                icon={<Clear/>}
                                label="Terminate"
                                onClick={() => handleTerminateClick(params.row)}
                                showInMenu
                            />
                        );
                    }
                }
                if (params.row.statusCd === "DRF") {
                    if (
                        user.proxyRole === "BLDIR" ||
                        user.proxyRole === "BLDMG" ||
                        user.proxyRole === "CS" ||
                        user.proxyRole === "ACC"
                    ) {
                        arr.push(
                            <GridActionsCellItem
                                icon={<LocalAtm/>}
                                label="Receive Payment"
                                onClick={() => handlePaymentClick(params.row)}
                                showInMenu
                            />
                        );
                        arr.push(
                            <GridActionsCellItem
                                icon={<Done/>}
                                label="Activate"
                                onClick={() => handleActivateClick(params.row)}
                                showInMenu
                            />
                        );
                    }
                    if (
                        user.proxyRole === "REC" ||
                        user.proxyRole === "BLDIR" ||
                        user.proxyRole === "BLDMG" ||
                        user.proxyRole === "CS"
                    ) {
                        arr.push(
                            <GridActionsCellItem
                                icon={<Clear/>}
                                label="Delete"
                                onClick={() => handleDeleteClick(params.row)}
                                showInMenu
                            />
                        );
                    }
                }
                if (params.row.statusCd === "SUP") {
                    if (
                        user.proxyRole === "REC" ||
                        user.proxyRole === "BLDIR" ||
                        user.proxyRole === "BLDMG" ||
                        user.proxyRole === "CS"
                    ) {
                        arr.push(
                            <GridActionsCellItem
                                icon={<Visibility/>}
                                label="View"
                                onClick={() => handleViewClick(params.row)}
                                showInMenu
                            />
                        );
                    }
                    if (
                        user.proxyRole === "BLDIR" ||
                        user.proxyRole === "BLDMG" ||
                        user.proxyRole === "CS" ||
                        user.proxyRole === "ACC"
                    ) {
                        arr.push(
                            <GridActionsCellItem
                                icon={<LocalAtm/>}
                                label="Receive Payment"
                                onClick={() => handlePaymentClick(params.row)}
                                showInMenu
                            />
                        );
                        arr.push(
                            <GridActionsCellItem
                                icon={<CreditCard/>}
                                label="Issue Credit"
                                onClick={() => handleCreditClick(params.row)}
                                showInMenu
                            />
                        );
                        arr.push(
                            <GridActionsCellItem
                                icon={<NearMe/>}
                                label="Issue Charge"
                                onClick={() => handleChargeClick(params.row)}
                                showInMenu
                            />
                        );
                    }
                    if (user.proxyRole === "BLDIR" || user.proxyRole === "SYSAD") {
                        arr.push(
                            <GridActionsCellItem
                                icon={<PlayArrow/>}
                                label="Reactivate"
                                onClick={() => handleActivateClick(params.row)}
                                showInMenu
                            />
                        );
                    }
                    if (
                        user.proxyRole === "REC" ||
                        user.proxyRole === "BLDIR" ||
                        user.proxyRole === "BLDMG" ||
                        user.proxyRole === "CS"
                    ) {
                        arr.push(
                            <GridActionsCellItem
                                icon={<Add/>}
                                label="Add Note"
                                onClick={() => handleNoteClick(params.row)}
                                showInMenu
                            />
                        );
                    }
                }
                return arr;
            },
        },
    ]);

    return (
        <Permit
            roles="BLDIR,BLDMG,REC,CS"
            noPermittedServiceMessage={{
                title: "Access Denied",
                body: (
                    <>
                        Your current role is not permitted to view this page. <br/> Please
                        contact your system admin if you feel this is an error.
                    </>
                ),
            }}
        >
            <MainContainer>
                {!ViewDiv && (
                    <TopBarContainer
                        container
                        sx={{
                            width: "100%",

                            // borderRadius: "10px",
                            boxShadow: "4px 4px 12px #aaa",
                        }}
                    >
                        <ResponsiveRow
                            item
                            sx={{
                                flexWrap: "nowrap",
                                justifyContent: "flex-start",
                                width: {xs: "100%", lg: "30%"},
                                padding: 0,
                                textAlign: "start",
                            }}
                        >
                            <TextField
                                id="searchCode"
                                label="Search billing code"
                                sx={{
                                    width: {xs: "100%", md: "90%"},
                                    margin: "5px",
                                }}
                                onChange={(searchText) => onTextChange(searchText)}
                            />
                            <TextField
                                id="searchCustomer"
                                label="Search Customer"
                                sx={{
                                    width: {xs: "100%", md: "90%"},
                                    margin: "5px",
                                }}
                                onChange={(searchText) => onTextChange(searchText)}
                            />
                        </ResponsiveRow>
                        <ResponsiveRow
                            item
                            sx={{
                                flexWrap: "nowrap",
                                justifyContent: "flex-end",
                                width: {xs: "100%", lg: "70%"},
                                padding: 0,
                            }}
                        >
                            <TopBarContainedBrandButton
                                onClick={handleAddBillingClick}
                                sx={{
                                    width: {xs: "100%", md: "200px"},
                                }}
                            >
                                Add
                            </TopBarContainedBrandButton>

                            <Permit roles="BLDIR,ACC,SYSAD">
                                <TopBarContainedBrandButton
                                    variant="contained"
                                    onClick={exportMeterbillingAccounts}
                                    sx={{
                                        width: {xs: "100%", md: "200px"},
                                        padding: "7px",
                                    }}
                                >
                                    Export Meter Billings
                                </TopBarContainedBrandButton>
                            </Permit>

                            <SplitButton buttons={meterBillingButtons}/>

                            <Permit roles="BLDIR,SYSAD">
                                <TopBarContainedBrandButton
                                    onClick={() => setBulkModal("true")}
                                    sx={{
                                        width: {xs: "100%", md: "200px"},
                                        padding: "7px",
                                    }}
                                >
                                    Bulk Upload
                                </TopBarContainedBrandButton>
                            </Permit>
                        </ResponsiveRow>
                    </TopBarContainer>
                )}

                <br/>

                {AddDiv && (
                    <AddMeterBilling
                        AddDiv={AddDiv}
                        EditDiv={EditDiv}
                        handleBillingClose={handleBillingClose}
                        handleBillingDayChange={handleBillingDayChange}
                        handleBillingItemAdd={handleBillingItemAdd}
                        handleBillingPeriodChange={handleBillingPeriodChange}
                        handleMeterBillingDateChange={handleMeterBillingDateChange}
                        handleMetersChange={handleMetersChange}
                        handleNextBillingDateChange={handleNextBillingDateChange}
                        setCustomerModal={setCustomerModal}
                        updateBilling={updateBilling}
                        billingItemsTableRows={billingItemsTableRows}
                        billingItemsTotal={billingItemsTotal}
                        billingPeriods={billingPeriods}
                        facilityMeters={facilityMeters}
                        rentDays={rentDays}
                        saveMeterBilling={saveMeterBilling}
                        selectedMeterbilling={selectedMeterbilling}
                        selectedBillingPeriod={selectedBillingPeriod}
                        selectedBillingDate={selectedBillingDate}
                        selectedBillingDay={selectedBillingDay}
                    />
                )}

                {ViewDiv && (
                    <ViewMeterBilling
                        selectedMeterBilling={selectedMeterbilling}
                        handleClose={() => setViewDiv(false)}
                        openCreditModal={() => setCreditModal(true)}
                        openChargeModel={() => setChargeModal(true)}
                        updateVoidItem={(value) => setVoiditem(value)}
                    />
                )}

                {/*ADD NEW USER MODAL*/}
                <AddNewUser
                    openModal={AddUserModal}
                    closeModal={handleAddUserClose}
                    searchedIdType={idTypeSearch}
                    searchedIdNo={idNoSearch}
                    saveUser={handlePersonOrgSelect}
                    manageUsers={false}
                />

                {/*ADD NEW ORGANISATION MODAL*/}
                <AddNewOrganisation
                    openModal={AddOrganizationModal}
                    searchedName={companySearch}
                    closeModal={handleAddOrgClose}
                />

                {!ViewDiv && (
                    // <StyledDataGrid
                    //   rows={rows}
                    //   columns={columns}
                    //   pageSize={rowsPerPage}
                    //   loading={loading}
                    //   rowCount={totalMeterbillings}
                    //   rowsPerPageOptions={[rowsPerPage]}
                    //   onPageChange={(page) => loadPage(page)}
                    //   checkboxSelection
                    //   pagination
                    //   paginationMode="server"
                    //   disableSelectionOnClick
                    //   onSelectionModelChange={setSelectionModel}
                    //   selectionModel={selectionModel}
                    //   autoHeight
                    //   components={{
                    //     LoadingOverlay: LinearProgress,
                    //     NoRowsOverlay: EmptyOverlay,
                    //   }}
                    // />

                    <MuiDataGrid
                        dataGridColumns={columns}
                        dataGridRows={rows}
                        serverPagination={true}
                        handlePageLoad={setPaginationModel}
                        currentSelectionModel={rowSelectionModel}
                        handleSelectedRows={setRowSelectionModel}
                        loading={loading}
                        height="60vh"
                        totalRows={totalMeterbillings}
                    />
                )}
                {/*PERSON/ORG SEARCH MODAL*/}
                <PersonOrgSearch
                    contractType={'meterBilling'}
                    showPersonOrgModal={CustomerModal}
                    handlePersonOrgClose={handlePersonOrgClose}
                    handlePersonOrgSelect={handlePersonOrgSelect}
                    showAddNewUserForm={showAddNewUserForm}
                    showAddNewOrgForm={showAddNewOrgForm}
                />

                {/*BULK UPLOAD MODAL*/}
                <BulkUpload
                    showBulkUploadModal={BulkModal}
                    handleBulkUploadClose={() => setBulkModal(false)}
                />

                {/*RECEIVE PAYMENT MODAL*/}
                <ReceivePayment
                    showPaymentModal={PaymentModal}
                    handlePaymentClose={handlePaymentClose}
                    paymentMode={"Meter Billing"}
                    contract={selectedMeterbilling}
                    savePayment={savePayment}
                />

                {/*CREATE CREDIT MODAL*/}
                {creditModal && (
                    <CreditNote
                        open={creditModal}
                        onClose={handleCreditClose}
                        voiditem={voiditem}
                        meterbillingid={selectedMeterbilling && selectedMeterbilling.id}
                    />
                )}

                {/*CREATE CHARGE MODAL*/}
                {chargeModal && (
                    <DebitNote
                        open={chargeModal}
                        onClose={handleChargeClose}
                        voiditem={voiditem}
                        meterbillingid={selectedMeterbilling && selectedMeterbilling.id}
                    />
                )}

                {/*ADD NOTE MODAL*/}
                <AddMeterBillingNote
                    NoteModal={NoteModal}
                    onTextChange={onTextChange}
                    setNoteModal={setNoteModal}
                    setNote={setNote}
                    note={note}
                    saveNote={saveNote}
                    selectedMeterbilling={selectedMeterbilling}
                />

                {/*SUSPEND MODAL*/}
                <MeterBillingSuspendModal
                    SuspendModal={SuspendModal}
                    setSuspendModal={setSuspendModal}
                    onTextChange={onTextChange}
                    selectedMeterbilling={selectedMeterbilling}
                    suspendBilling={suspendBilling}
                    suspendReason={suspendReason}
                />

                {/*TERMINATE MODAL*/}
                <ContractTermination
                    showTerminateModal={TerminateModal}
                    handleTerminateClose={handleTerminateClose}
                    terminationMode={"Meter Billing"}
                    terminateItem={terminateItem}
                    contract={selectedMeterbilling}
                    terminateContract={terminateBilling}
                />

                {/*DOWNLOAD MODAL*/}
                <DownloadMeterBillingModal
                    DownloadModal={DownloadModal}
                    handleDownloadClose={handleDownloadClose}
                    handleIncludeMeterBillingDetailToggle={
                        handleIncludeMeterBillingDetailToggle
                    }
                    handleStatementYearChange={handleStatementYearChange}
                    setDownloadEmailModal={setDownloadEmailModal}
                    downloadMeterBillingDocs={downloadMeterBillingDocs}
                    includeMeterBillingDetail={includeMeterBillingDetail}
                    selectionModel={rowSelectionModel}
                    statementYear={statementYear}
                />

                {/*Download/Export Document via Email... Shared Modal*/}
                {DownloadEmailModal && (
                    <ExportDocumentsModal
                        showExportDocumentsModal={DownloadEmailModal}
                        handleExportDocumentsClose={handleDownloadClose}
                        title={titleText}
                        severity={severityText}
                        body={bodyText}
                        contract={"Meter Billing"}
                        handleIncludeDetailToggle={handleIncludeMeterBillingDetailToggle}
                        handleStatementYearChange={handleStatementYearChange}
                        includeDetails={includeMeterBillingDetail}
                        statementYear={statementYear}
                        handleExportDocuments={positiveExportDocsAction}
                        handleCancel={() => {
                            setDownloadEmailModal(false);
                        }}
                    />
                )}

                {/*CONFIRMATION MODAL*/}
                {showConfirmationModal && (
                    <ConfirmationModal
                        showConfirmationModal={showConfirmationModal}
                        handleConfirmationClose={handleConfirmationClose}
                        title={titleText}
                        severity={severityText}
                        body={bodyText}
                        positiveText={positiveText}
                        negativeText={negativeText}
                        positiveAction={positiveAction}
                        negativeAction={handleConfirmationClose}
                    />
                )}

                {/*ACTIVATE DRAFT METER BILLING MODAL*/}
                <ModalContainer
                    open={ActivateModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <ModalInnerContainer sx={{maxHeight: {xs: "50vh", lg: "70vh"}}}>
                        <Paper
                            elevation="0"
                            sx={{height: "auto", margin: "auto", padding: "10px"}}
                        >
                            <Typography id="modal-modal-title" variant="h3" component="h2">
                                Activate Billing?
                            </Typography>
                        </Paper>
                        <Divider/>
                        <br/>

                        {appMessage.display && (
                            <Alert severity={appMessage.type}>
                                <AlertTitle>{titleCase(appMessage.type)}</AlertTitle>
                                {appMessage.message}
                            </Alert>
                        )}

                        <Paper
                            variant=""
                            sx={{height: "auto", margin: "auto", padding: "10px"}}
                        >
                            <InnerModal container>
                                Are you sure you wish to Activate this Billing?
                            </InnerModal>
                            <br/>
                        </Paper>
                        <br/>

                        {/*FOOTER WITH BUTTONS*/}
                        <Paper
                            variant=""
                            sx={{height: "auto", margin: "auto", padding: "10px"}}
                        >
                            <ModalAcceptButton
                                // type="submit"
                                onClick={activateDraft}
                                sx={{
                                    width: {xs: "100%", lg: "200px"},
                                }}
                            >
                                Yes, Activate
                            </ModalAcceptButton>
                            <ModalCancelButton
                                // type="submit"
                                onClick={() => {
                                    setActivateModal(false);
                                    setselectedMeterbilling(null);
                                }}
                                sx={{
                                    width: {xs: "100%", lg: "200px"},
                                }}
                            >
                                Cancel
                            </ModalCancelButton>
                        </Paper>
                    </ModalInnerContainer>
                </ModalContainer>

                {/*DELETE DRAFT METER BILLING MODAL*/}
                <ModalContainer
                    open={DeleteModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <ModalInnerContainer sx={{maxHeight: {xs: "50vh", lg: "70vh"}}}>
                        <Paper
                            elevation="0"
                            sx={{height: "auto", margin: "auto", padding: "10px"}}
                        >
                            <Typography id="modal-modal-title" variant="h3" component="h2">
                                Confirm Delete Billing?
                            </Typography>
                        </Paper>
                        <Divider/>
                        <br/>

                        {appMessage.display && (
                            <Alert severity={appMessage.type}>
                                <AlertTitle>{titleCase(appMessage.type)}</AlertTitle>
                                {appMessage.message}
                            </Alert>
                        )}

                        <Paper
                            variant=""
                            sx={{height: "auto", margin: "auto", padding: "10px"}}
                        >
                            <ResponsiveRow container>
                                Are you sure you want to permanently delete this Meter Billing
                                along with any other associated data such as charges, contacts,
                                and documents?
                            </ResponsiveRow>
                            <br/>
                        </Paper>
                        <br/>

                        {/*FOOTER WITH BUTTONS*/}
                        <ResponsiveRow
                            sx={{justifyContent: {xs: "center", lg: "flex-end"}}}
                        >
                            <ModalAcceptButton
                                // type="submit"
                                onClick={deleteDraft}
                                sx={{
                                    width: {xs: "100%", lg: "200px"},
                                }}
                            >
                                Delete Billing
                            </ModalAcceptButton>
                            <ModalCancelButton
                                // type="submit"
                                onClick={() => setDeleteModal(false)}
                                sx={{
                                    width: {xs: "100%", lg: "flex-end"},
                                }}
                            >
                                Cancel
                            </ModalCancelButton>
                        </ResponsiveRow>
                    </ModalInnerContainer>
                </ModalContainer>
            </MainContainer>
        </Permit>
    );
}
