import React, { useState } from "react";
import ModalContainerModule from "../../../modules/modalContainer";
import { ResponsiveRow } from "../../../../constants/layout.constants";
import {
  Alert,
  AlertTitle,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import { formatCurrency } from "../../../../util/util";
import { FileUpload } from "../../../inputs/fileUpload";

export default function PendingTaxPaymentModal({
  modalOpen,
  handleCloseModal,
  handlePayment,
  paymentMethod,
  setPaymentMethod,
  PRNNumber,
  setPRNNumber,
  cashPaymentAccounts,
  paymentTotal,
  selectedTaxType,
  setSelectedTaxType,
  paymentMethods,
  taxTypes,
  setPaymentTotal,
}) {

  const [cashAccount, setCashAccount] = useState("");
  const [chequeNumber, setChequeNumber] = useState("");
  const [bankRefNumber, setBankRefNumber] = useState("");
  const [bulkFile, setBulkFile] = useState(null);
  const [bulkFileProgress] = useState(0);

  function handlePaymentMethodOnChange(ev) {
    setPaymentMethod(ev.target.value);
  }
  
  function handleTaxTypeOnChange(ev) {
    setSelectedTaxType(ev.target.value);
  }

  function handlePRNNumberOnChange(ev) {
    setPRNNumber(ev.target.value);
  }

  function processPayment() {
    const newPayment = {
      cashAccount: cashAccount,
      chequeNumber: chequeNumber,
      bankRefNumber: bankRefNumber,
      bulkFile: bulkFile,
    };

    handlePayment(newPayment);
  }

  function fileValueChanged(event) {
    setBulkFile(event.target.files[0]);
  }

  return (
    <ModalContainerModule
      size={"lg"}
      accept={processPayment}
      cancel={handleCloseModal}
      openModal={modalOpen}
      modalTitle={`Add Tax Voucher Payment`}
      acceptButtonText={"Add Tax Payment"}
    >
      <ResponsiveRow
        sx={{
          flexDirection: "column",
          flexWrap: "nowrap",

          justifyContent: "flex-start",
          alignItems: "flex-start",
        }}
      >
        <ResponsiveRow
          sx={{
            flexWrap: "nowrap",
            justifyContent: "space-between",
            alignItems: "center",

            width: "100%",
            height: "auto",

            marginBottom: "10px",
            padding: "0px 10px",
          }}
        >
          <Typography variant="h5" color="black" fontWeight="bold">
            Total Voucher Amount
          </Typography>
          <Typography variant="h5" color="primary.dark" fontWeight="bold">
            {formatCurrency(paymentTotal)} KES
          </Typography>
        </ResponsiveRow>

        <ResponsiveRow
          sx={{
            width: "100%",
            height: "auto",
            flexWrap: "nowrap",
            gap: "10px",
          }}
        >
          <FormControl
            sx={{ width: { xs: "100%", lg: "50%" }, marginBottom: "10px" }}
          >
            <InputLabel>Select Tax Type</InputLabel>
            <Select
              label="Select Tax Type"
              value={selectedTaxType}
              sx={{ width: "100%" }}
              defaultValue=""
              onChange={handleTaxTypeOnChange}
              MenuProps={{
                style: {
                  maxHeight: "300px",
                },
              }}
            >
              {taxTypes && taxTypes.length > 0 ? (
                taxTypes.map((item, index) => (
                  <MenuItem key={index} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))
              ) : (
                <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
              )}
            </Select>
          </FormControl>
          <FormControl
            sx={{ width: { xs: "100%", lg: "50%" }, marginBottom: "10px" }}
          >
            <InputLabel>Enter Payment Method</InputLabel>
            <Select
              label="Enter Payment Method"
              value={paymentMethod}
              sx={{ width: "100%" }}
              defaultValue=""
              onChange={handlePaymentMethodOnChange}
              MenuProps={{
                style: {
                  maxHeight: "300px",
                },
              }}
            >
              {paymentMethods && paymentMethods.length > 0 ? (
                paymentMethods.map((item, index) => (
                  <MenuItem key={index} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))
              ) : (
                <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
              )}
            </Select>
          </FormControl>{" "}
          <FormControl
            sx={{ width: { xs: "100%", lg: "50%" }, marginBottom: "10px" }}
          >
            <InputLabel>Enter PRN Number</InputLabel>
            <OutlinedInput
              label="Enter PRN Number"
              sx={{ width: "100%" }}
              type="number"
              value={PRNNumber}
              onChange={handlePRNNumberOnChange}
            />
          </FormControl>
        </ResponsiveRow>

        <ResponsiveRow
          sx={{
            width: "100%",
            height: "auto",
            flexWrap: "nowrap",
            gap: "10px",
          }}
        >
          {paymentMethod === "CSH" && (
            <FormControl
              sx={{
                width: "100%",
                padding: "auto 10px",
              }}
            >
              <InputLabel id="cash-account-label">Cash Account</InputLabel>
              <Select
                labelId="cash-account-label"
                id="cash-account"
                value={cashAccount}
                label="Cash Account"
                onChange={(e) => {
                  setCashAccount(e.target.value);
                  setChequeNumber("");
                  setBankRefNumber("");
                }}
                sx={{ width: { xs: "100%", lg: "95%" }, height: "auto" }}
              >
                {cashPaymentAccounts && cashPaymentAccounts.length > 0 ? (
                  cashPaymentAccounts.map(function (cashPaymentAccount, i) {
                    return (
                      <MenuItem
                        key={cashPaymentAccount.value}
                        value={cashPaymentAccount.value}
                      >
                        {cashPaymentAccount.label}
                      </MenuItem>
                    );
                  }, this)
                ) : (
                  <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
                )}
              </Select>
            </FormControl>
          )}
          {paymentMethod === "CHQ" && (
            <FormControl
              sx={{
                width: "100%",
                padding: "auto 10px",
              }}
            >
              <InputLabel id="cheque-label">Cheque Number</InputLabel>
              <OutlinedInput
                labelId="cheque-label"
                id="cheque"
                value={chequeNumber}
                label="Cheque Number"
                onChange={(e) => {
                  setChequeNumber(e.target.value);
                  setCashAccount("");
                  setBankRefNumber("");
                }}
                sx={{ width: { xs: "100%", lg: "95%" }, height: "auto" }}
              />
            </FormControl>
          )}
          {paymentMethod === "RTGS" && (
            <FormControl
              sx={{
                width: "100%",
                padding: "auto 10px",
              }}
            >
              <InputLabel id="cheque-label">Bank Ref Number</InputLabel>
              <OutlinedInput
                labelId="bank-ref-label"
                id="bankRefNo"
                value={bankRefNumber}
                label="Bank Ref Number"
                onChange={(e) => {
                  setBankRefNumber(e.target.value);
                  setCashAccount("");
                  setChequeNumber("");
                }}
              />
            </FormControl>
          )}
          <FormControl
            sx={{ width: { xs: "100%", lg: "100%" }, marginBottom: "10px" }}
          >
            <InputLabel>Enter Amount to Pay</InputLabel>
            <OutlinedInput
              label="Enter Amount to Pay"
              placeholder={paymentTotal}
              sx={{ width: "100%" }}
              type="number"
              value={paymentTotal}
              onChange={(e) => setPaymentTotal(e.target.value)}
            />
          </FormControl>
        </ResponsiveRow>
        <>
          <Alert severity="info" sx={{marginBottom: "10px"}}>
            <AlertTitle>You can attach a payment slip here</AlertTitle>
            You can upload a payment slip as evidence of payment. Please note that this is not a requirement but is recommended.
          </Alert>
          <FormControl
            sx={{
              width: { xs: "100%", lg: "50%" },
              height: "auto",
              marginBottom: "10px",
            }}
          >
            <FileUpload
              id={"bulkFileInput"}
              multiple
              currentFiles={bulkFile}
              progress={bulkFileProgress}
              onChange={fileValueChanged}
              accept={
                ".xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              }
              width="100%"
            />
          </FormControl>
        </>
      </ResponsiveRow>

    </ModalContainerModule>
  );
}

