import {Box} from "@mui/system"
import {useEffect, useState} from "react"

import {ResponsiveRow} from "../../../constants/layout.constants"
import {Stack, Typography} from "@mui/material"

import {ModalAcceptButton} from "../../../constants/component.constants"

import {HouseRounded} from "@mui/icons-material"

const propertyImages = [
    "https://images.unsplash.com/photo-1600585154340-be6161a56a0c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
    "https://images.unsplash.com/photo-1600047509807-ba8f99d2cdde?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=992&q=80",
    "https://images.unsplash.com/photo-1600585154526-990dced4db0d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80",
    "https://images.unsplash.com/photo-1600585153490-76fb20a32601?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
    "https://images.unsplash.com/photo-1600566753376-12c8ab7fb75b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
]

export default function MyListItem() {
    const [listPropertyImages, setListPropertyImages] = useState([])

    useEffect(() => {
        if (propertyImages.length !== 0) {
            setListPropertyImages(propertyImages)
        }
    }, [])

    return (
        <ResponsiveRow
            sx={{
                width: {xs: "100%", lg: "450px"},
                height: "auto",
                borderRadius: "20px",
                alignItems: "flex-start",
                backgroundColor: "#fff",
                padding: 0,
                margin: {xs: "10px 0", lg: "10px"},
            }}
        >
            <ResponsiveRow
                sx={{
                    flexDirection: "column",
                    flexWrap: "nowrap",
                    width: "100%",
                    height: "40%",
                    textAlign: "start",
                    padding: "10px 20px",
                }}
            >
                <ResponsiveRow
                    sx={{
                        alignItems: "center",
                        flexWrap: {xs: "wrap", lg: "nowrap"},
                        justifyContent: "space-between",
                        padding: 0,
                    }}
                >
                    <ResponsiveRow sx={{height: "auto"}}>
                        <Typography
                            variant="h4"
                            sx={{color: "#000", fontWeight: 900, marginBottom: "5px"}}
                        >
                            List Name
                        </Typography>
                        <ResponsiveRow
                            component={Typography}
                            color={"primary.dark"}
                            variant="body1"
                            sx={{
                                margin: "0 10px 0 0",
                                alignItems: "center",
                                padding: 0,
                                flexWrp: "nowrap",
                                flexDirection: "row",
                            }}
                        >
                            <HouseRounded sx={{margin: "0 3px 0 0"}}/>
                            {listPropertyImages.length}
                        </ResponsiveRow>
                    </ResponsiveRow>
                    <ResponsiveRow
                        sx={{
                            flexWrap: "nowrap",
                            width: "50%",
                            height: "auto",
                            justifyContent: "flex-end",
                            alignItems: "center",
                        }}
                    >
                        <Stack
                            direction="row"
                            spacing={-2}
                            sx={{width: {xs: "100%", lg: "auto"}}}
                        >
                            {listPropertyImages.map((item) => (
                                <Box
                                    component="img"
                                    sx={{
                                        height: "40px",
                                        width: "40px",
                                        objectFit: "cover",
                                        objectPosition: "center center",
                                        borderRadius: "50%",
                                    }}
                                    src={item}
                                    alt="property"
                                />
                            ))}
                        </Stack>
                    </ResponsiveRow>
                </ResponsiveRow>

                <Typography
                    variant="body2"
                    component="div"
                    sx={{
                        margin: {xs: "20px 10px", lg: "10px 10px 50px 10px"},
                        fontSize: "1rem",
                        color: "#9f9f9f",
                        width: "100%",
                        height: {xs: "auto", lg: "50px"},
                        textOverflow: "ellipsis",
                    }}
                >
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                    minim veniam, quis nostrud
                </Typography>
                <ResponsiveRow
                    sx={{
                        width: "100%",
                        height: "auto",
                        justifyContent: {xs: "center", lg: "flex-end"},
                        padding: 0,
                    }}
                >
                    <ModalAcceptButton
                        href="/listings/dashboard/favorites"
                        sx={{width: {xs: "100%", lg: "200px"}}}
                    >
                        View List
                    </ModalAcceptButton>
                </ResponsiveRow>
            </ResponsiveRow>
        </ResponsiveRow>
    )
}
