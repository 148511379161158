/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-lone-blocks */
import {useEffect, useState} from "react";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  styled,
  TableCell,
  TableRow,
  TextField
} from "@mui/material";
import Grid from "@mui/material/Grid";
import {GridActionsCellItem} from "@mui/x-data-grid";
import {
  deleteCapitalFund,
  downloadCapitalFunds,
  exportCapitalFunds,
  listCapitalFundOwnerships,
  listCapitalFunds,
  loadTotalCapitalFunds, saveCapitalFund, updateCapitalFund,
} from "../services/capitalFunds.service";
import {Cancel, Delete, Edit, Visibility} from "@mui/icons-material";
import {
  formatCurrency,
  formatDate,
  isArray,
  isEmptyString,
  isNullUndefined,
  sortJSONArray,
  tryParseFloat,
} from "../util/util";
import {useSelector} from "react-redux";
import {getFacilityOptions} from "../services/list.service";
import Permit from "../util/permit";
import {issueResponseMessage, issueWarnMessage} from "../actions/message";
import ConfirmationModal from "../components/modals/confirmation";
import ExportDocumentsModal from "../components/modals/exportDocuments";
import {printCapitalFunds} from "../services/print.service";
import {ResponsiveRow} from "../constants/layout.constants";
import {TopBarContainedBrandButton, TopBarContainer,} from "../constants/component.constants";
import CreateCapitalFund from "../components/modals/homes/capital funds/createCapitalFund";
import ViewCapitalFundModal from "../components/modals/homes/capital funds/viewCapitalFundModal";
import dayjs from "dayjs";
import MuiDataGrid from "../components/modules/MuiDataGrid";

const MainContainer = styled(Grid)(({ theme: kodi_theme }) => ({
  width: "100%",
  minHeight: "75vh",
  margin: "auto",
  marginTop: "30px",
  flexDirection: "row",
  flexWrap: "nowrap",
  justifyContent: "center",
  alignItems: "center",
}));

export default function CapitalFund() {
  const user = useSelector((state) => state.auth.user);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 50,
    page: 0,
  })
  const [rowSelectionModel, setRowSelectionModel] = useState([])
  const [totalCapitalFunds, setTotalCapitalFunds] = useState(0);
  const [approvalTotal, setApprovalTotal] = useState(0);
  const [capitalFundsList, setCapitalFundsList] = useState([]);
  const [capitalFundName, setCapitalFundName] = useState("");
  const [name, setName] = useState("");
  const [status] = useState("");
  const [task, setTask] = useState("");
  const [capitalFundForm, setCapitalFundForm] = useState(false);
  const [view, setView] = useState(false);
  const [capitalFundsApprovals, setCapitalFundsApprovals] = useState([]);
  const [selectedCapitalFund, setSelectedCapitalFund] = useState(null);
  const [description, setDescription] = useState("");
  const [selectedFacility, setSelectedFacility] = useState("");
  const [facilityOptions, setFacilityOptions] = useState([]);
  const [amount, setAmount] = useState(0);
  const [distributeEvenly, setDistributeEvenly] = useState(true);
  const [approvalRequired, setApprovalRequired] = useState(false);
  const [approvalThreshold, setApprovalThreshold] = useState("");
  const [dueDate, setDueDate] = useState(null);
  const [capitalFundOwnerships, setCapitalFundOwnerships] = useState([]);
  const [capitalFundContributionList, setCapitalFundContributionList] = useState([]);
  const [capitalFundContributionTableRows, setCapitalFundContributionTableRows] = useState([]);
  const [downloadCapitalFundsModal, setDownloadCapitalFundsModal] = useState(false);
  const [deleteCapitalFundsModal, setDeleteCapitalFundsModal] = useState(false);
  const [titleText, setTitleText] = useState("");
  const [severityText, setSeverityText] = useState("");
  const [bodyText, setBodyText] = useState("");
  const [positiveText, setPositiveText] = useState("");
  const [negativeText, setNegativeText] = useState("");
  const [loading, setLoading] = useState(true)
  const [includeAll, setIncludeAll] = useState(false)
  const [clearAll, setClearAll] = useState(false)

  useEffect(() => {
    setLoading(true)
    loadTotalCapitalFunds(status, capitalFundName).then((data) =>
        setTotalCapitalFunds(data.totalCapitalFunds)
    );
    listCapitalFunds(status, capitalFundName, paginationModel.page, paginationModel.pageSize).then(
      (data) => {
        setCapitalFundsList(data);
      }
    ) .finally(() => {
      setLoading(false)
    })
  }, [status, capitalFundName, paginationModel.page, paginationModel.pageSize]);

  useEffect(() => {
    setLoading(true)
    if (!isNullUndefined(user.proxyFacility) && !isEmptyString(user.proxyFacility)) {
      listCapitalFundOwnerships(user.proxyFacility).then((data) => {
        if (!isNullUndefined(data)) {
          setCapitalFundOwnerships(data.sort(sortJSONArray("label", true)))
        }
      })
    }
    getFacilityOptions().then((facilityOptions) =>
        setFacilityOptions(facilityOptions)
    )
    loadTotalCapitalFunds(status, capitalFundName)
      .then((data) => setTotalCapitalFunds(data.totalCapitalFunds))
    listCapitalFunds(status, capitalFundName, paginationModel.page, paginationModel.pageSize).then(
        (data) => {
          setCapitalFundsList(data);
        }
    ) .finally(() => {
      setLoading(false)
    })
  }, [user.proxyFacility]);

  useEffect(() => {
    populateCapitalApprovals()
  }, [selectedCapitalFund]);

  useEffect(() => {
    populateCapitalFundDistribution()
  }, [capitalFundContributionList])

  function handleSearchCapitalFundName(event) {
    let value = event.target.value;
    setCapitalFundName(value);
  }

  function handleDeleteCapitalFund(item) {
    setDeleteCapitalFundsModal(true)
    setSelectedCapitalFund(item)
    let title = "Confirm Delete Capital Fund";
    let body =
      "Are you sure you want to permanently delete this capitalFund along with any other associated data such as capital fund approvals?";
    setTitleText(title);
    setTask("Delete");
    setSeverityText("warning");
    setBodyText(body);
    setPositiveText("Delete Capital Fund");
    setNegativeText("Cancel");
  }

  function handleCloseDeleteCapitalFundModal() {
    setDeleteCapitalFundsModal(false);
    setSelectedCapitalFund(null);
    setTitleText("");
    setTask("");
    setSeverityText("");
    setBodyText("");
    setPositiveText("");
    setNegativeText("");
  }

  function positiveAction() {
    if (task === "Delete") {
      deleteSelectedCapitalFund();
    }
  }

  function deleteSelectedCapitalFund() {
    if (
      isNullUndefined(selectedCapitalFund) ||
      isNullUndefined(selectedCapitalFund.id) ||
      isEmptyString(selectedCapitalFund.id)
    ) {
      issueWarnMessage(
        "Could not determine Capital Fund ID for deletion. Try to refresh the page or contact support."
      );
      return;
    }

    let sendData = { capitalFundId: selectedCapitalFund.id };

    deleteCapitalFund(sendData).then((response) => {
      issueResponseMessage(response)
      setLoading(true)
      loadTotalCapitalFunds(status, capitalFundName).then((data) =>
          setTotalCapitalFunds(data.totalCapitalFunds)
      )
      listCapitalFunds(status, capitalFundName, paginationModel.page, paginationModel.pageSize).then(
        (data) => setCapitalFundsList(data)
      ) .finally(() => {
        setLoading(false)
      })
      handleCloseDeleteCapitalFundModal();
    });
  }

  const columns = [
    { headerName: "Name", field: "name", minWidth: 250, flex: 2.5 },
    { headerName: "Amount", field: "amount", minWidth: 200, flex: 2,
      valueFormatter: (row) => {
        return formatCurrency(row.value)
      } },
    { headerName: "Due Date", field: "dueDate", minWidth: 150, flex: 1.5,
      valueFormatter: (row) => {
        return formatDate(row.value)
      }
      },
    {
      headerName: "Threshold(%)",
      field: "approvalThresholdPercentage",
      minWidth: 150,
      flex: 1.5,
    },
    {
      headerName: "Distributed Evenly",
      field: "distributeEvenly",
      minWidth: 150,
      flex: 1.5,
      valueFormatter: (row) => {
        if (row.value) {
          return "Yes"
        } else {
          return "No"
        }
      }
    },
    {
      headerName: "Approval Required",
      field: "approvalRequired",
      minWidth: 150,
      flex: 1.5,
      valueFormatter: (row) => {
        if (row.value) {
          return "Yes"
        } else {
          return "No"
        }
      }
    },
    { headerName: "Date Created", field: "dateCreated", minWidth: 150, flex: 1.5,
      valueFormatter: (row) => {
        return formatDate(row.value)
      }},
    { headerName: "Status", field: "statusName", minWidth: 124, flex: 1.24 },
    {
      headerName: "Actions",
      field: "actions",
      width: 100,
      type: "actions",
      getActions: (params) => {
        let arr = [];
        arr.push(
          <GridActionsCellItem
            icon={<Visibility sx={{ fontSize: "1.4rem" }} />}
            onClick={() => CapitalFundsView(params.row)}
            label="View"
            showInMenu
          />
        );
        arr.push(
          <GridActionsCellItem
            icon={<Edit sx={{ fontSize: "1.4rem" }} />}
            onClick={() => {
              handleEditClick(params.row);
            }}
            label="Edit"
            showInMenu
          />
        );
        arr.push(
          <GridActionsCellItem
            icon={<Delete sx={{ fontSize: "1.4rem" }} />}
            onClick={() => handleDeleteCapitalFund(params.row)}
            label="Delete"
            showInMenu
          />
        );

        return arr;
      },
    },
  ];

  function CapitalFundsView(cf) {
    setTask("View")
    setSelectedCapitalFund(cf)
    setView(true)
  }

  function handleEditClick(data) {
    setTask("Edit")
    setDistributeEvenly(data.distributeEvenly)
    setApprovalRequired(data.approvalRequired)
    setSelectedCapitalFund(data)
    setCapitalFundContributionList(data.capitalFundApprovals)
    setCapitalFundForm(true)
  }

  function populateCapitalApprovals() {
    let rows = "";
    selectedCapitalFund && selectedCapitalFund.capitalFundApprovals &&
    selectedCapitalFund.capitalFundApprovals.length > 0 &&
      (rows = selectedCapitalFund.capitalFundApprovals.map(function (contribution, i) {
        return (
          <TableRow key={i}>
            <TableCell>{contribution.ownerNames}</TableCell>
            <TableCell>{contribution.unitName}</TableCell>
            <TableCell>{contribution.status.name}</TableCell>
            <TableCell>{contribution.amount}</TableCell>
          </TableRow>
        );
      }, this));
    setCapitalFundsApprovals(rows);
  }

  function onCheckboxChange(event) {
    const checked = event.target.checked;
    const value = event.target.value;
    if (event) {
      if (value === "distributeEvenly") {
        setDistributeEvenly(checked);
        let obj = selectedCapitalFund;
        obj.distributeEvenly = checked;
        setSelectedCapitalFund(obj);
        setCapitalFundContributionList(obj.capitalFundApprovals)
        populateCapitalFundDistribution()
      }

      if (value === "approvalRequired") {
        setApprovalRequired(checked);
        let obj = selectedCapitalFund;
        obj.approvalRequired = checked;
        setSelectedCapitalFund(obj);
      }

      if (value === "includeAll") {
        setIncludeAll(checked)
        let tmp = capitalFundContributionList
        let selectedCapitalFundTmp = selectedCapitalFund
        let itemAmount = 0
        if(distributeEvenly && selectedCapitalFund.amount > 0){
          itemAmount = selectedCapitalFund.amount / capitalFundOwnerships.length
        }
        capitalFundOwnerships.forEach((capitalFundOwnership, i) => {
          if(tmp.filter((cf) => cf.titleId === capitalFundOwnership.value).length < 1){
            tmp.push(
                {
                  id: null,
                  amount: itemAmount,
                  titleId: capitalFundOwnership.value,
                  unitName: capitalFundOwnership.label,
                  ownerNames: capitalFundOwnership.ownerNames
                })
          }
        })
        selectedCapitalFundTmp.capitalFundApprovals = tmp
        setSelectedCapitalFund(selectedCapitalFundTmp)
        setCapitalFundContributionList(tmp)
        if(checked){
          setClearAll(false)
        }
        populateCapitalFundDistribution()
      }

      if (value === "clearAll") {
        setClearAll(checked)
        let selectedCapitalFundTmp = selectedCapitalFund
        selectedCapitalFundTmp.capitalFundApprovals = []
        setSelectedCapitalFund(selectedCapitalFundTmp)
        setCapitalFundContributionList([])
        if(checked){
          setIncludeAll(false)
        }
      }

    }
  }

  function handleAddClicked() {
    if (!isNullUndefined(user.proxyFacility)) {
      setDistributeEvenly(true)
      setApprovalRequired(false)
      let data = {
        id: null,
        name: null,
        dueDate: null,
        description: "",
        facilityId: user.proxyFacility,
        titleIds: [],
        capitalFundApprovals: [],
        statusCd: "CRT",
        distributeEvenly: distributeEvenly,
        approvalRequired: approvalRequired,
        approvalThresholdPercentage: null,
        amount: null,
      }
      setSelectedCapitalFund(data)
      setTask("Create")
      setCapitalFundForm(true)
    }
  }

  function populateCapitalFundDistribution() {
    let rows = ""
    let total = 0
    capitalFundContributionList &&
    capitalFundContributionList.length > 0 &&
      (rows = capitalFundContributionList.map(function (
        capitalFundContributionItem,
        i
      ) {
            let amount = tryParseFloat(capitalFundContributionItem.amount, 0)
            if (amount > 0) {
              total += amount;
            }
        return (
          <TableRow key={i}>
            <TableCell sx={{ width: { xs: "30%", lg: "auto" } }}>
              <Select
                  label="Unit"
                  sx={{ width: { xs: "100%", lg: "90%" } }}
                  id={"unit"+i}
                  disabled={task === "Edit" && !isNullUndefined(capitalFundContributionItem.id)}
                  value={capitalFundContributionItem && capitalFundContributionItem.titleId}
                  onChange={(event) => capitalFundApprovalItemUnitChanged(event, i)}
              >
                {capitalFundOwnerships && capitalFundOwnerships.length > 0 ? (
                    capitalFundOwnerships.map(function (cfo, i) {
                      let disabled = false
                      for (let cfd of capitalFundContributionList) {
                        if(cfd.titleId === cfo.value){
                          disabled = true
                        }
                      }
                      return (
                          <MenuItem
                              key={i}
                              disabled={disabled}
                              value={cfo.value}>
                            {cfo.label}
                          </MenuItem>
                      );
                    }, this)
                ) : (
                    <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
                )}
              </Select>
            </TableCell>

            <TableCell sx={{ width: { xs: "30%", lg: "auto" } }}>
              <TextField
                size="small"
                placeholder={"Owner"}
                sx={{ width: "100%" }}
                id={"owner"}
                disabled={true}
                value={capitalFundContributionItem && capitalFundContributionItem.ownerNames}
              />
            </TableCell>

            <TableCell sx={{ width: { xs: "30%", lg: "auto" } }}>
              <TextField
                size="small"
                placeholder={"Amount"}
                sx={{ width: "100%" }}
                id={"amount"}
                disabled={selectedCapitalFund.distributeEvenly}
                onChange={(event) => {
                  capitalFundApprovalItemAmountChanged(event, i);
                }}
                value={capitalFundContributionItem && capitalFundContributionItem.amount}
              />
            </TableCell>
            <TableCell sx={{ width: { xs: "10%", lg: "auto" } }}>
              <Button
                variant="contained"
                type="submit"
                sx={{ marginLeft: "10px" }}
                onClick={() => handleItemDelete(i)}
                color="error"
              >
                <Cancel />
              </Button>
            </TableCell>
          </TableRow>
        );
      },
      this));
    setCapitalFundContributionTableRows(rows)
    setApprovalTotal(total)
  }

  function amountChanged(value) {
    if (distributeEvenly) {
      let floatAmount = tryParseFloat(value, -1)
      setAmount(floatAmount);
      let obj = selectedCapitalFund;
      obj.amount = floatAmount;

      let amount = floatAmount / selectedCapitalFund.capitalFundApprovals.length;
      obj.capitalFundApprovals.forEach((capitalFundApproval, i) => {
        obj.capitalFundApprovals[i].amount = amount;
      });
      if (!isEmptyString(approvalThreshold)) {
        if (approvalThreshold > 100 || approvalThreshold < 0) {
          obj.approvalThresholdPercentage = "";
          issueWarnMessage(
              "Approval Threshold Percentage should be between 0-100."
          );
          return;
        }
      }
      setCapitalFundContributionList(obj.capitalFundApprovals)
      setSelectedCapitalFund(obj)
      populateCapitalFundDistribution()
    }
  }

  function handleItemAdd() {
    let obj = selectedCapitalFund
    obj.capitalFundApprovals.push(
        {
          id: null,
          amount: 0,
          titleId: "",
          unitName: "",
          ownerNames: ""
        })
    setCapitalFundContributionList(obj.capitalFundApprovals)
    setSelectedCapitalFund(obj)
    populateCapitalFundDistribution()
  }

  function handleItemDelete(index) {
    let tmpSelectedCapitalFund = selectedCapitalFund
    tmpSelectedCapitalFund.capitalFundApprovals.splice(index, 1)
    if(tmpSelectedCapitalFund.distributeEvenly){
      let distributionSum = tryParseFloat(tmpSelectedCapitalFund.amount, 0) / tmpSelectedCapitalFund.capitalFundApprovals.length
      tmpSelectedCapitalFund.capitalFundApprovals.forEach((capitalFundApproval, i) => {
        tmpSelectedCapitalFund.capitalFundApprovals[i].amount = distributionSum
      })
    }else{
      let total = 0
      tmpSelectedCapitalFund.capitalFundApprovals.forEach((capitalFundApproval, i) => {
        total += tryParseFloat(capitalFundApproval.amount, 0)
      })
      tmpSelectedCapitalFund.amount = total
    }
    setCapitalFundContributionList(tmpSelectedCapitalFund.capitalFundApprovals)
    setSelectedCapitalFund(tmpSelectedCapitalFund);
    populateCapitalFundDistribution()
  }

  function capitalFundApprovalItemAmountChanged(event, index) {
    let obj = selectedCapitalFund;
    let inputAmount = tryParseFloat(event.target.value, -1);
    let capitalFundAmount = 0
    if (!obj.distributeEvenly) {
      obj.capitalFundApprovals[index].amount = inputAmount
      obj.capitalFundApprovals.forEach((capitalFundApproval, i) => {
        capitalFundAmount += capitalFundApproval.amount
      })
      obj.amount = capitalFundAmount;
      setAmount(capitalFundAmount)
      setSelectedCapitalFund(obj);
      setCapitalFundContributionList(obj.capitalFundApprovals)
      populateCapitalFundDistribution()
    }
  }

  function capitalFundApprovalItemUnitChanged(event, index) {
    let obj = selectedCapitalFund
    obj.capitalFundApprovals[index].titleId = event.target.value
    let foundCapitalFund = capitalFundOwnerships.filter((cf) => cf.value === event.target.value)[0]
    if(distributeEvenly){
      let distributedAmount = obj.amount / obj.capitalFundApprovals.length
      obj.capitalFundApprovals.forEach((capitalFundApproval, i) => {
        obj.capitalFundApprovals[i].amount = distributedAmount
      })
    }
    obj.capitalFundApprovals[index].ownerNames = foundCapitalFund.ownerNames
    obj.capitalFundApprovals[index].unitName = foundCapitalFund.label
    setCapitalFundContributionList(obj.capitalFundApprovals)
    setSelectedCapitalFund(obj)
    populateCapitalFundDistribution()
  }

  function handleCancelClicked() {
    setCapitalFundForm(false)
    setTask("");
    setCapitalFundContributionList([])
    setCapitalFundContributionTableRows([])
    setApprovalRequired(false);
    setSelectedCapitalFund(null);
    setDistributeEvenly(true)
    setIncludeAll(false)
    setClearAll(false)
  }

  function handleExportCapitalFundsClicked() {
    exportCapitalFunds();
  }

  function handleCloseDownloadCapitalFundsModal() {
    setDownloadCapitalFundsModal(false);
    setRowSelectionModel([]);
    setTitleText("");
    setTask("");
    setSeverityText("");
    setBodyText("");
  }

  function downloadSelectedCapitalFunds() {
    if (isNullUndefined(rowSelectionModel) || rowSelectionModel.size < 1) {
      issueWarnMessage(
        "Could not determine the download items. Please refresh the page and try again."
      )
    }else{
      let sendData = {
        capitalFundIds: rowSelectionModel,
      };
      downloadCapitalFunds(sendData).then((response) => {
        issueResponseMessage(response);
        handleCloseDownloadCapitalFundsModal();
      });
    }
  }

  function handlePrintCapitalFund() {
    printCapitalFunds(selectedCapitalFund.id);
  }

  function validateForm(sendData) {
    if (isNullUndefined(sendData)) {
      issueWarnMessage(
        "Something went wrong with the form. Please refresh the page or contact support"
      );
      return false;
    }
    if (sendData.capitalFundApprovals.length < 1) {
      issueWarnMessage(
        "You must add at least one home owner to save a capital fund."
      );
      return false;
    }
    if (sendData.amount < 1) {
      issueWarnMessage("Capital Fund Amount should be greater than zero.");
      return false;
    }
    if (sendData.dueDate < dayjs(new Date())) {
      issueWarnMessage(
        "Capital Fund Due Date is required and should be greater than today."
      );
      return false;
    }
    if (isNullUndefined(sendData.name) || isEmptyString(sendData.name)) {
      issueWarnMessage("Please input Capital Fund Name it's required.");
      return false;
    }

    if (isNullUndefined(sendData.description) || isEmptyString(sendData.description)) {
      issueWarnMessage("Please input Capital Fund description.");
      return false;
    }

    if(!isNullUndefined(sendData.approvalRequired) && sendData.approvalRequired){
      if(isNullUndefined(sendData.approvalThresholdPercentage) || sendData.approvalThresholdPercentage < 1){
        issueWarnMessage("Please input Capital Fund approval threshold percentage.");
        return false;
      }
    }

    if (isArray(sendData.capitalFundApprovals)) {
      for (let cfa of sendData.capitalFundApprovals) {
        let amount = tryParseFloat(cfa.amount.toString(), -1)
        if (amount < 1) {
          let message =
              "Amount '" + cfa.amount + "' on unit '" +cfa.unitName + "' is invalid. Cannot be below 0"
          issueWarnMessage(message)
          return false
        }
        if (isNullUndefined(cfa.titleId) || isEmptyString(cfa.titleId)) {
          let message = "Ownership/unit should be selected for amount to be distributed."
          issueWarnMessage(message)
          return false
        }
      }
    }
    return true;
  }

  function handleSaveClicked() {
    if (validateForm(selectedCapitalFund)) {
      if (
        isNullUndefined(selectedCapitalFund.id) ||
        isEmptyString(selectedCapitalFund.id)
      ) {
        //This is a new capital Fund so save new
        saveCapitalFund(selectedCapitalFund).then((response) => {
          issueResponseMessage(response)
          setLoading(true)
          loadTotalCapitalFunds(status, capitalFundName).then((data) => {
            if (data !== undefined) {
              setTotalCapitalFunds(data.totalCapitalFunds);
            }
          })
          listCapitalFunds(status, capitalFundName, paginationModel.page, paginationModel.pageSize).then(
            (data) => setCapitalFundsList(data)
          ) .finally(() => {
            setLoading(false)
          })
          handleCancelClicked();
        })
      } else {
        //This is an update so update the selected Capital Fund.
       updateCapitalFund(selectedCapitalFund).then((response) => {
          issueResponseMessage(response)
          setLoading(true)
          loadTotalCapitalFunds(status, capitalFundName).then((data) => {
            if (data !== undefined) {
              setTotalCapitalFunds(data.totalCapitalFunds)
            }
          })
          listCapitalFunds(status, capitalFundName, paginationModel.page, paginationModel.pageSize).then(
            (data) => setCapitalFundsList(data)
          ) .finally(() => {
            setLoading(false)
          })
          handleCancelClicked()
        })
      }
    }
  }

  function handleExportCapitalFund() {
    if (isNullUndefined(rowSelectionModel) || rowSelectionModel.length < 1) {
      issueWarnMessage(
          "Could not determine the download items. Please refresh the page and try again."
      )
    }else{
      setDownloadCapitalFundsModal(true)
      let title =
          rowSelectionModel.length > 1
              ? "Download " + rowSelectionModel.length + " Capital Funds"
              : "Capital Fund";
      let body =
          "Note: Processing the selected Capital Fund" +
          " may take some time. Therefore, the exported data will be emailed to you when it is finished being packaged." +
          " Please ensure the email set for your account is valid, working and that you have access to it in order to receive the export data.";
      setTitleText(title);
      setSeverityText("info");
      setBodyText(body);
      setRowSelectionModel(rowSelectionModel)
    }
  }

  function positiveExportDocsAction() {
    downloadSelectedCapitalFunds();
  }

  return (
    <Permit
      roles="BLDIR,BLDMG,ACC,CS"
      noPermittedServiceMessage={{
        title: "Access Denied",
        body: (
          <>
            Your current role is not permitted to view this page. <br /> Please
            contact your system admin if you feel this is an error.
          </>
        ),
      }}
    >
      <MainContainer>
        <TopBarContainer
          container
          sx={{
            width: { xs: "100%", md: "80%" },
            padding: 0,
            boxShadow: "0 4px 5px 0 rgba(0,0,0,0.5)",
          }}
        >
          <ResponsiveRow
            sx={{
              justifyContent: { md: "space-between", xs: "center" },
              alignItems: "flex-start",

              width: { lg: "50%", xs: "100%" },
            }}
          >
            <FormControl
              sx={{
                width: { xs: "100%", lg: "90%" },
              }}
            >
              <InputLabel id="capitalFundLabel">Search Name</InputLabel>
              <OutlinedInput
                label="Search Name"
                id="capitalFundSearch"
                value={capitalFundName}
                sx={{ width: "100%" }}
                onChange={(event) => handleSearchCapitalFundName(event)}
                inputProps={(params) => <TextField {...params} />}
              />
            </FormControl>
          </ResponsiveRow>

          <ResponsiveRow
            sx={{
              justifyContent: { lg: "flex-end", xs: "center" },
              width: { xs: "100%", lg: "50%" },
              padding: 0,
            }}
          >
            <TopBarContainedBrandButton
              sx={{
                width: { xs: "100%", lg: "150px" },
              }}
              onClick={handleAddClicked}
            >
              Add
            </TopBarContainedBrandButton>

            <Permit roles="BLDIR,ACC,SYSAD">
              <TopBarContainedBrandButton
                sx={{
                  width: { xs: "100%", lg: "200px" },
                }}
                onClick={handleExportCapitalFundsClicked}
              >
                Export Capital Funds
              </TopBarContainedBrandButton>
            </Permit>

            <Permit roles="BLDIR,ACC,SYSAD">
              <TopBarContainedBrandButton
                sx={{
                  width: { xs: "100%", lg: "200px" },
                }}
                disabled={isNullUndefined(rowSelectionModel) || rowSelectionModel.length < 1}
                onClick={handleExportCapitalFund}
              >
                {rowSelectionModel.length > 1
                  ? "Download " + rowSelectionModel.length + " Capital Funds"
                  : "Download Capital Fund"}
              </TopBarContainedBrandButton>
            </Permit>
          </ResponsiveRow>
        </TopBarContainer>
        <br />
        <MuiDataGrid
          dataGridColumns={columns}
          dataGridRows={capitalFundsList}
          serverPagination={true}
          handlePageLoad={setPaginationModel}
          currentSelectionModel={rowSelectionModel}
          handleSelectedRows={setRowSelectionModel}
          loading={loading}
          height="60vh"
          totalRows={totalCapitalFunds}
        />

        {view && !capitalFundForm && task === "View" && (
          <ViewCapitalFundModal
            handlePrintCapitalFund={handlePrintCapitalFund}
            setView={setView}
            setTask={setTask}
            capitalFundsApprovals={capitalFundsApprovals}
            selectedCapitalFund={selectedCapitalFund}
            view={view}
          />
        )}

        {/*Download/Export Document via Email... Shared Modal*/}
          <ExportDocumentsModal
            showExportDocumentsModal={downloadCapitalFundsModal}
            handleExportDocumentsClose={handleCloseDownloadCapitalFundsModal}
            title={titleText}
            severity={severityText}
            body={bodyText}
            handleExportDocuments={positiveExportDocsAction}
          />

        {capitalFundForm && (task === "Create" || task === "Edit") && (
          <CreateCapitalFund
            handleItemAdd={handleItemAdd}
            handleCancelClicked={handleCancelClicked}
            handleSaveClicked={handleSaveClicked}
            capitalFundForm={capitalFundForm}
            task={task}
            includeAll={includeAll}
            clearAll={clearAll}
            onCheckboxChange={onCheckboxChange}
            setApprovalThreshold={setApprovalThreshold}
            setDescription={setDescription}
            setDueDate={setDueDate}
            setSelectedCapitalFund={setSelectedCapitalFund}
            setSelectedFacility={setSelectedFacility}
            setName={setName}
            amount={amount}
            capitalFundOwnerships={capitalFundOwnerships}
            amountChanged={amountChanged}
            approvalRequired={approvalRequired}
            approvalThreshold={approvalThreshold}
            approvalTotal={approvalTotal}
            capitalFundContributionTableRows={capitalFundContributionTableRows}
            capitalFundContributionList={capitalFundContributionList}
            description={description}
            distributeEvenly={distributeEvenly}
            dueDate={dueDate}
            facilityOptions={facilityOptions}
            name={name}
            selectedCapitalFund={selectedCapitalFund}
            selectedFacility={selectedFacility}
          />
        )}

        {deleteCapitalFundsModal && (
          <ConfirmationModal
            showConfirmationModal={deleteCapitalFundsModal}
            handleConfirmationClose={handleCloseDeleteCapitalFundModal}
            title={titleText}
            severity={severityText}
            body={bodyText}
            positiveText={positiveText}
            negativeText={negativeText}
            positiveAction={positiveAction}
            negativeAction={handleCloseDeleteCapitalFundModal}
          />
        )}
      </MainContainer>
    </Permit>
  );
}
