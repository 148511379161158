import {
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { AddCircle, Cached } from "@mui/icons-material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/en-gb";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import ModalContainerModule from "../../../modules/modalContainer";
import { ModalAcceptButton } from "../../../../constants/component.constants";
import { ResponsiveRow } from "../../../../constants/layout.constants";
import { useEffect, useState } from "react";
import {
  isArray,
  isEmptyString,
  isNullUndefined,
  tryParseFloat,
} from "../../../../util/util";
import { saveAsset } from "../../../../services/assets.service";
import {
  issueResponseMessage,
  issueWarnMessage,
} from "../../../../actions/message";
import dayjs from "dayjs";
import kodi_theme from "../../../../themes/kodiThemeProvider";

export default function AddAssetModal(props) {
  /**
   *
   * REQUIRED PROPS
   *
   * handleTieredItemAdd
   *
   * AddDiv
   * EditDiv
   *
   * setAddDiv
   * setAssets
   * setEditDiv
   * setCustomerModal
   *
   * assetAccountOptions
   * assetSearch
   * complexOptions
   * depreciationTypeOptions
   * incomeAccounts
   * incomeSwitch
   * facilityOptions
   * maintenanceAccountOptions
   * maintenancePeriodOptions
   * ownerSearch
   * rentDays
   * tieredItemsTableRows
   *
   */

  const [selectedAsset, setSelectedAsset] = useState();
  const [description, setDescription] = useState("");
  const [facilityId, setFacilityId] = useState("");
  const [complexId, setComplexId] = useState("");
  const [name, setName] = useState("");
  const [manufacturer, setManufacturer] = useState("");
  const [model, setModel] = useState("");
  const [serialNumber, setSerialNumber] = useState("");
  const [maintenanceSwitch, setMaintenanceSwitch] = useState(false);
  const [meteredSwitch, setMeteredSwitch] = useState(false);
  const [proRatedSwitch, setProRatedSwitch] = useState(false);
  const [incomeSwitch, setIncomeSwitch] = useState(false);
  const [purchasePrice, setPurchasePrice] = useState("");
  const [currentValue, setCurrentValue] = useState("");
  const [depreciationRate, setDepreciationRate] = useState("");
  const [depreciationType, setDepreciationType] = useState("");
  const [dateAcquired, setDateAcquired] = useState("");
  const [categoryCode, setCategoryCode] = useState("");
  const [complexSwitch, setComplexSwitch] = useState(false);
  const [items, setItems] = useState([]);

  useEffect(() => {
    if (!isNullUndefined(props.selectedAsset)) {
      setSelectedAsset(props.selectedAsset);
      setName(props.selectedAsset.name);
      setFacilityId(props.selectedAsset.facilityId);
      setComplexId(props.selectedAsset.complexId);
      setDescription(props.selectedAsset.description);
      setManufacturer(props.selectedAsset.manufacturer);
      setSerialNumber(props.selectedAsset.serialNumber);
      setModel(props.selectedAsset.serialNumber);
      setPurchasePrice(props.selectedAsset.purchasePrice);
      setCurrentValue(props.selectedAsset.currentValue);
      setDepreciationRate(props.selectedAsset.depreciationRate);
      setDepreciationType(props.selectedAsset.depreciationType);
      setCategoryCode(props.selectedAsset.categoryCode);
      setDateAcquired(props.selectedAsset.dateAcquired);
    }
  }, [props.selectedAsset]);

  function validateAssetForm() {
    let data = selectedAsset;
    if (isNullUndefined(data)) {
      issueWarnMessage("Could not determine Asset to save");
      return false;
    }
    if (isEmptyString(data.name)) {
      issueWarnMessage("The Asset must have a name!!");
      return false;
    }
    if (isEmptyString(data.categoryCode)) {
      issueWarnMessage("The Asset must have a category!!");
      return false;
    }
    if (isEmptyString(data.manufacturer)) {
      issueWarnMessage("The Asset must have a manufacturer!!");
      return false;
    }
    if (isEmptyString(data.model)) {
      issueWarnMessage("The Asset must have a model!!");
      return false;
    }
    if (isEmptyString(data.serialNumber)) {
      issueWarnMessage("The Asset must have a serial number!!");
      return false;
    }
    if (isEmptyString(data.depreciationType)) {
      issueWarnMessage("Depreciation Type must be selected!!");
      return false;
    }
    if (isEmptyString(data.depreciationRate)) {
      issueWarnMessage("Please input a depreciation Rate!!");
      return false;
    }
    if (
      isNullUndefined(data.owner) ||
      isNullUndefined(data.owner.name) ||
      isEmptyString(data.owner.name)
    ) {
      issueWarnMessage("Please select an owner!!");
      return false;
    }
    if (isNullUndefined(data.facilityId)) {
      issueWarnMessage("Please select a facility!!");
      return false;
    }
    if (isEmptyString(data.dateAcquired)) {
      issueWarnMessage("Date acquired must be selected!!");
      return false;
    }
    if (isEmptyString(data.purchasePrice)) {
      issueWarnMessage("Set a purchase price!!");
      return false;
    }
    if (isEmptyString(data.currentValue)) {
      issueWarnMessage("Set the current value!!");
      return false;
    }
    let purchasePrice = tryParseFloat(data.purchasePrice.toString(), -1);
    if (purchasePrice === -1 || purchasePrice > 9999999999999.99) {
      issueWarnMessage(
        "Asset's purchase Price should be a number between 0 - 9,999,999,999,999.99!"
      );
      return false;
    }
    let currentValue = tryParseFloat(data.currentValue.toString(), -1);
    if (currentValue === -1 || currentValue > 9999999999999.99) {
      issueWarnMessage(
        "Asset's current value should be a number between 0 - 9,999,999,999,999.99!"
      );
      return false;
    }

    if (isArray(data.meterItems)) {
      for (let item of data.meterItems) {
        if (isEmptyString(item.name)) {
          issueWarnMessage(
            "Could not determine the name for one of the items."
          );
          return false;
        }
        if (isNullUndefined(item.readingFrequency)) {
          issueWarnMessage("A item must have a reading frequency.");
          return false;
        }
        if (isNullUndefined(item.meterTypeCode)) {
          issueWarnMessage("A item must have a type.");
          return false;
        }
      }
    }

    setSelectedAsset(data);
    return true;
  }

  function handleNextMaintenanceDateChange(value) {
    let date = new Date(value);
    let obj = selectedAsset;
    obj.maintenanceDate = date;
    setSelectedAsset(obj);
  }

  function handleMaintenanceAccountChange(event) {
    let value = event.target.value;
    let obj = selectedAsset;
    obj.maintenanceAccountId = value;
    setSelectedAsset(obj);
  }

  function handleMaintenanceDayChange(event) {
    let value = event.target.value;
    let obj = selectedAsset;
    obj.maintenanceDay = value;
    setSelectedAsset(obj);
  }

  function handleMaintenancePeriodChange(event) {
    let value = event.target.value;
    let obj = selectedAsset;
    obj.maintenancePeriodCd = value;
    setSelectedAsset(obj);
  }

  function handleIncomeAccountChange(event) {
    let value = event.target.value;
    let obj = selectedAsset;
    obj.incomeAccountId = value;
    setSelectedAsset(obj);
  }

  function handleFacilityOptionChange(event) {
    let value = event.target.value;
    let obj = selectedAsset;
    obj.facilityId = value;
    setSelectedAsset(obj);
    setFacilityId(value);
  }

  function handleDepreciationTypeChange(event) {
    let value = event.target.value;
    setDepreciationType(value);
    let obj = selectedAsset;
    obj.depreciationType = value;
    setSelectedAsset(obj);
  }

  function handleDateAcquiredChange(value) {
    let date = new Date(value);
    let obj = selectedAsset;
    obj.dateAcquired = date;
    setSelectedAsset(obj);
    setDateAcquired(date);
  }

  function handleComplexOptionChange(event) {
    let value = event.target.value;
    let obj = selectedAsset;
    obj.complexId = value;
    setSelectedAsset(obj);
    setComplexId(value);
  }

  function handleAssetCategoryChange(event) {
    let value = event.target.value;
    setCategoryCode(value);
    let obj = selectedAsset;
    obj.categoryCode = value;
    setSelectedAsset(obj);
  }

  function handleMeterAdd() {
    let data = selectedAsset;
    data.meterItems = [];
    if (isNullUndefined(data.facilityId)) {
      issueWarnMessage("Must select a facility before adding a meter!!");
      return false;
    }
    if (complexSwitch) {
      if (isNullUndefined(data.complexId)) {
        issueWarnMessage("Must select a complex before adding a meter!!");
        return false;
      }
    }

    let meter = {
      id: null,
      name: "",
      readingFrequency: "",
      meterTypeCode: "",
      facilityId: data.facilityId,
      unitId: "",
      complexId: data.complexId,
      rateTypeCode: "",
      rate: "",
      rates: [],
      standingCharge: "",
      invoiced: false,
      readDay: null,
      readDate: "",
    };
    data.meterItems.push(meter);
    setSelectedAsset(data);
    props.handleTieredItemAdd();
  }

  function saveNewAsset() {
    let obj = selectedAsset;
    obj.meterItems = items;
    obj.prorated = proRatedSwitch;
    obj.metered = meteredSwitch;
    obj.maintenance = maintenanceSwitch;
    obj.income = incomeSwitch;
    setSelectedAsset(obj);
    if (validateAssetForm()) {
      saveAsset(selectedAsset).then((response) => {
        issueResponseMessage(response);
        handleAssetModalClose();
        props.reloadAssets();
      });
    }
  }

  const handleAssetModalClose = () => {
    setSelectedAsset(null);
    setIncomeSwitch(false);
    setComplexSwitch(false);
    setMeteredSwitch(false);
    setMaintenanceSwitch(false);
    setProRatedSwitch(false);
    setItems([]);
    props.handleAssetModalClose();
  };
  if (selectedAsset && selectedAsset.owner) {
    console.log("asset owner", selectedAsset);
  }

  return (
    <ModalContainerModule
      size="md"
      accept={saveNewAsset}
      cancel={handleAssetModalClose}
      openModal={props.AddDiv}
      modalTitle={props.EditDiv ? "Edit Asset" : "Add Asset"}
      acceptButtonText={props.EditDiv ? "Update" : "Create"}
    >
      <Typography variant="h6">Asset Details</Typography>
      <ResponsiveRow
        sx={{
          display: "flex",
          alignItems: { xs: "center", lg: "flex-start" },
          width: "100%",
          height: "auto",
        }}
        container
      >
        <FormControl
          item
          sx={{
            width: { xs: "100%", lg: "33.3%" },
            marginBottom: props.EditDiv ? "20px" : "10px",
          }}
        >
          <InputLabel>Asset Name *</InputLabel>
          <OutlinedInput
            id="name"
            label="Asset Name *"
            sx={{
              width: { xs: "100%", md: "90%" },
            }}
            onChange={(event) => {
              const value = event.target.value;
              setName(value);
              let obj = selectedAsset;
              obj.name = value;
            }}
            value={name}
          />
        </FormControl>
        <FormControl
          sx={{
            width: { xs: "100%", lg: "33.3%" },
            marginBottom: props.EditDiv ? "20px" : "10px",
          }}
        >
          <InputLabel>Asset Category *</InputLabel>

          <Select
            sx={{ width: { xs: "100%", md: "90%" } }}
            id={"assetCategory"}
            label="Asset Category *"
            fullWidth
            value={categoryCode}
            onChange={(event) => handleAssetCategoryChange(event)}
          >
            <MenuItem value="" disabled>
              Select Asset Category *
            </MenuItem>
            {props.assetAccountOptions &&
            props.assetAccountOptions.length > 0 ? (
              props.assetAccountOptions.map(function (assetAccount, i) {
                return (
                  <MenuItem key={i} value={assetAccount.value}>
                    {assetAccount.label}
                  </MenuItem>
                );
              }, this)
            ) : (
              <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
            )}
          </Select>
        </FormControl>
        <FormControl
          sx={{
            width: { xs: "100%", lg: "33.3%" },
            marginBottom: props.EditDiv ? "20px" : "10px",
          }}
        >
          <InputLabel>Description</InputLabel>
          <OutlinedInput
            id="description"
            label="Description"
            sx={{
              width: { xs: "100%", md: "90%" },
            }}
            onChange={(event) => {
              const value = event.target.value;
              setDescription(value);
              let obj = selectedAsset;
              obj.description = value;
            }}
            value={description}
          />
        </FormControl>
        <FormControl
          sx={{
            width: { xs: "100%", lg: "33.3%" },
            marginBottom: props.EditDiv ? "20px" : "10px",
          }}
        >
          <InputLabel>Manufacture</InputLabel>
          <OutlinedInput
            id="manufacturer"
            label="Manufacturer"
            sx={{
              width: { xs: "100%", md: "90%" },
            }}
            onChange={(event) => {
              const value = event.target.value;
              setManufacturer(value);
              let obj = selectedAsset;
              obj.manufacturer = value;
            }}
            value={manufacturer}
          />
        </FormControl>
        <FormControl
          sx={{
            width: { xs: "100%", lg: "33.3%" },
            marginBottom: props.EditDiv ? "20px" : "10px",
          }}
        >
          <InputLabel>Model</InputLabel>
          <OutlinedInput
            id="model"
            label="Model"
            sx={{
              width: { xs: "100%", md: "90%" },
            }}
            onChange={(event) => {
              const value = event.target.value;
              setModel(value);
              let obj = selectedAsset;
              obj.model = value;
            }}
            value={model}
          />
        </FormControl>
        <FormControl
          sx={{
            width: { xs: "100%", lg: "33.3%" },
            marginBottom: props.EditDiv ? "20px" : "10px",
          }}
        >
          <InputLabel>Serial Number</InputLabel>
          <OutlinedInput
            id="serialNumber"
            label="Serial number"
            sx={{
              width: { xs: "100%", md: "90%" },
            }}
            onChange={(event) => {
              const value = event.target.value;
              setSerialNumber(value);
              let obj = selectedAsset;
              obj.serialNumber = value;
            }}
            value={serialNumber}
          />
        </FormControl>
        <FormControl
          sx={{
            width: { xs: "100%", lg: "33.3%" },
            marginBottom: props.EditDiv ? "20px" : "10px",
          }}
        >
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale="en-gb"
          >
            <DatePicker
              label="Date acquired"
              sx={{ width: { xs: "100%", lg: "90%" } }}
              id={"readingDate"}
              value={dayjs(dateAcquired)}
              onChange={(newDate) => handleDateAcquiredChange(newDate)}
            />
          </LocalizationProvider>
        </FormControl>
        <FormControl
          sx={{
            width: { xs: "100%", lg: "33.3%" },
            marginBottom: props.EditDiv ? "20px" : "10px",
          }}
        >
          <InputLabel id="depreciationType">Depreciation Type</InputLabel>
          <Select
            sx={{ width: { xs: "100%", md: "90%" } }}
            id={"depType"}
            label="Depreciation Type"
            labelId="depreciationType"
            value={depreciationType}
            onChange={(event) => handleDepreciationTypeChange(event)}
          >
            {props.depreciationTypeOptions &&
            props.depreciationTypeOptions.length > 0 ? (
              props.depreciationTypeOptions.map(function (depType, i) {
                return (
                  <MenuItem key={i} value={depType.value}>
                    {depType.label}
                  </MenuItem>
                );
              }, this)
            ) : (
              <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
            )}
          </Select>
        </FormControl>
        <FormControl
          sx={{
            width: { xs: "100%", lg: "33.3%" },
            marginBottom: props.EditDiv ? "20px" : "10px",
          }}
        >
          <InputLabel>Depreciation Rate (%)</InputLabel>
          <OutlinedInput
            label="Depreciation Rate (%)"
            id="depRate"
            sx={{
              width: { xs: "100%", md: "90%" },
            }}
            onChange={(event) => {
              const value = event.target.value;
              setDepreciationRate(value);
              let obj = selectedAsset;
              obj.depreciationRate = value;
            }}
            value={depreciationRate}
          />
        </FormControl>
        <FormControl
          sx={{
            width: { xs: "100%", lg: "33.3%" },
            marginBottom: props.EditDiv ? "20px" : "10px",
          }}
        >
          <InputLabel>Purchase Price</InputLabel>
          <OutlinedInput
            label="Purchase Price"
            id="purchasePrice"
            sx={{
              width: { xs: "100%", md: "90%" },
            }}
            onChange={(event) => {
              const value = event.target.value;
              setPurchasePrice(value);
              let obj = selectedAsset;
              obj.purchasePrice = value;
            }}
            value={purchasePrice}
          />
        </FormControl>
        <FormControl
          sx={{
            width: { xs: "100%", lg: "33.3%" },
            marginBottom: props.EditDiv ? "20px" : "10px",
          }}
        >
          <InputLabel>Current Value</InputLabel>
          <OutlinedInput
            label="Current Value"
            id="currentValue"
            sx={{
              width: { xs: "100%", md: "90%" },
            }}
            onChange={(event) => {
              const value = event.target.value;
              setCurrentValue(value);
              let obj = selectedAsset;
              obj.currentValue = value;
            }}
            value={currentValue}
          />
        </FormControl>
        <FormControl
          sx={{
            width: { xs: "100%", lg: "33.3%" },
            marginBottom: props.EditDiv ? "20px" : "10px",
          }}
        >
          <InputLabel>Select Facility</InputLabel>
          <Select
            label="Select Facility"
            sx={{ width: { xs: "100%", md: "90%" } }}
            id={"facility"}
            value={facilityId}
            onChange={(event) => handleFacilityOptionChange(event)}
          >
            {props.facilityOptions && props.facilityOptions.length > 0 ? (
              props.facilityOptions.map(function (facility, i) {
                return (
                  <MenuItem key={i} value={facility.value}>
                    {facility.label}
                  </MenuItem>
                );
              }, this)
            ) : (
              <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
            )}
          </Select>
        </FormControl>

        <ResponsiveRow
          item
          sx={{
            width: "100%",
            flexDirection: "row",
            flexWrap: "nowrap",
            justifyContent: { xs: "center", lg: "flex-start" },
            padding: 0,
            marginBottom: "20px",
          }}
        >
          <FormControl
            sx={{
              width: "100%",
            }}
          >
            {selectedAsset && selectedAsset.owner === null && (
              <InputLabel>Owner</InputLabel>
            )}
            <OutlinedInput
              label="Owner"
              sx={{ width: "100%" }}
              id={"owner"}
              value={
                selectedAsset && selectedAsset.owner && selectedAsset.owner.name
                  ? selectedAsset.owner.name
                  : ""
              }
              disabled
            />
          </FormControl>
          <IconButton
            onClick={() => props.setCustomerModal("true")}
            sx={{
              width: "auto",
              height: "auto",

              backgroundColor: kodi_theme.palette.common.white,
              borderRadius: "50%",

              padding: 0,
              margin: "auto 15px",
            }}
          >
            {props.EditDiv ? (
              <Cached
                sx={{
                  fontSize: "40px",
                  color: kodi_theme.palette.primary.dark,
                }}
              />
            ) : (
              <AddCircle
                sx={{
                  fontSize: "40px",
                  color: kodi_theme.palette.primary.dark,
                }}
              />
            )}
          </IconButton>
        </ResponsiveRow>
        <Grid item sx={{ width: { xs: "100%", lg: "33.3%" } }}>
          <Switch
            checked={proRatedSwitch}
            onChange={(event) => setProRatedSwitch(event.target.checked)}
            inputProps={{ "aria-label": "controlled" }}
          />{" "}
          Is Depreciation Pro-rated?
        </Grid>
        <Grid item sx={{ width: { xs: "100%", lg: "33.3%" } }}>
          <Switch
            checked={meteredSwitch}
            onChange={(event) => {
              setMeteredSwitch(event.target.checked);
              if (!meteredSwitch) {
                setItems([]);
              }
            }}
            inputProps={{ "aria-label": "controlled" }}
          />{" "}
          Is this a metered Asset?
        </Grid>
        {meteredSwitch && (
          <ResponsiveRow sx={{ width: "100%", height: "auto" }}>
            <Typography
              sx={{ marginBottom: "5px" }}
              id="modal-modal-title"
              variant="h5"
              component="h4"
            >
              Meter Table
            </Typography>
            <TableContainer>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ width: "15%" }}>Name</TableCell>
                    <TableCell sx={{ width: "15%" }}>Meter Type</TableCell>
                    <TableCell sx={{ width: "15%" }}>Rate Type</TableCell>
                    <TableCell sx={{ width: "15%" }}>Invoiced?</TableCell>
                    <TableCell sx={{ width: "15%" }}>Next Read Date</TableCell>
                    <TableCell sx={{ width: "10%" }}>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{props.tieredItemsTableRows}</TableBody>
              </Table>
            </TableContainer>
            <ResponsiveRow
              sx={{
                justifyContent: { xs: "center", lg: "flex-end" },
                width: "100%",
                marginTop: { xs: "10px", lg: "15px" },
                height: "auto",
              }}
              container
            >
              <ModalAcceptButton
                variant="contained"
                type="submit"
                onClick={handleMeterAdd}
                sx={{
                  width: { xs: "100%", lg: "200px" },
                  height: "50px",
                  margin: "10px",
                }}
              >
                Add row
              </ModalAcceptButton>
            </ResponsiveRow>
          </ResponsiveRow>
        )}
        <br />
        <Grid item sx={{ width: { xs: "100%", lg: "33.3%" } }}>
          <Switch
            checked={maintenanceSwitch}
            onChange={(event) => setMaintenanceSwitch(event.target.checked)}
            inputProps={{ "aria-label": "controlled" }}
          />{" "}
          Does this asset require maintenance?
        </Grid>
        <ResponsiveRow
          sx={{
            width: "100%",
            marginBottom: props.EditDiv ? "20px" : "10px",
          }}
        >
          {maintenanceSwitch && (
            <FormControl
              sx={{ width: { xs: "100%", lg: "33.3%" }, marginBottom: "10px" }}
            >
              <InputLabel>Maintenance Period</InputLabel>
              <Select
                label="Maintenance Period"
                sx={{ width: { xs: "100%", md: "90%" } }}
                id={"depType"}
                value={selectedAsset && selectedAsset.maintenancePeriodCd}
                onChange={(event) => handleMaintenancePeriodChange(event)}
              >
                {props.maintenancePeriodOptions &&
                props.maintenancePeriodOptions.length > 0 ? (
                  props.maintenancePeriodOptions.map(function (mPeriod, i) {
                    return (
                      <MenuItem key={i} value={mPeriod.value}>
                        {mPeriod.label}
                      </MenuItem>
                    );
                  }, this)
                ) : (
                  <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
                )}
              </Select>
            </FormControl>
          )}

          {maintenanceSwitch &&
            selectedAsset &&
            selectedAsset.maintenancePeriodCd === "MNTH" && (
              <FormControl
                sx={{
                  width: { xs: "100%", lg: "33.3%" },
                  marginBottom: "10px",
                }}
              >
                <InputLabel>Maintenance Day</InputLabel>
                <Select
                  label="Maintenance Day"
                  sx={{ width: "90%" }}
                  id={"billingDay"}
                  value={selectedAsset && selectedAsset.maintenanceDay}
                  onChange={(event) => handleMaintenanceDayChange(event)}
                  defaultValue={[""]}
                >
                  {props.rentDays && props.rentDays.length > 0 ? (
                    props.rentDays.map(function (weekDay, i) {
                      return (
                        <MenuItem key={i} value={weekDay.value}>
                          {weekDay.label}
                        </MenuItem>
                      );
                    }, this)
                  ) : (
                    <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
                  )}
                </Select>
              </FormControl>
            )}

          {maintenanceSwitch &&
            selectedAsset &&
            (selectedAsset.maintenancePeriodCd === "ANU" ||
              selectedAsset.maintenancePeriodCd === "QT") && (
              <FormControl
                sx={{
                  width: { xs: "100%", lg: "33.3%" },
                  marginBottom: "10px",
                }}
              >
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale="en-gb"
                >
                  <DatePicker
                    label="Next Maintenance Date"
                    sx={{ width: "90%" }}
                    id={"nextBillingDate"}
                    value={
                      selectedAsset && dayjs(selectedAsset.maintenanceDate)
                    }
                    onChange={(newDate) =>
                      handleNextMaintenanceDateChange(newDate)
                    }
                  />
                </LocalizationProvider>
              </FormControl>
            )}
          {maintenanceSwitch && (
            <FormControl
              sx={{ width: { xs: "100%", lg: "33.3%" }, marginBottom: "10px" }}
            >
              <InputLabel>Maintenance Account</InputLabel>
              <Select
                label="Maintenance Account"
                sx={{ width: { xs: "100%", md: "90%" } }}
                id={"depType"}
                value={selectedAsset && selectedAsset.maintenanceAccountId}
                onChange={(event) => handleMaintenanceAccountChange(event)}
              >
                {props.maintenanceAccountOptions &&
                props.maintenanceAccountOptions.length > 0 ? (
                  props.maintenanceAccountOptions.map(function (mAcc, i) {
                    return (
                      <MenuItem key={i} value={mAcc.value}>
                        {mAcc.label}
                      </MenuItem>
                    );
                  }, this)
                ) : (
                  <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
                )}
              </Select>
            </FormControl>
          )}
        </ResponsiveRow>
        <Grid item sx={{ width: { xs: "100%", lg: "33.3%" } }}>
          <Switch
            checked={complexSwitch}
            onChange={(event) => setComplexSwitch(event.target.checked)}
            inputProps={{ "aria-label": "controlled" }}
          />{" "}
          Is this asset attached to a complex?
        </Grid>
        {complexSwitch && (
          <FormControl
            item
            sx={{
              width: "100%",
            }}
          >
            <InputLabel>Complex</InputLabel>
            <Select
              label="Complex"
              sx={{
                width: { xs: "100%", lg: "33.3%" },
                marginBottom: props.EditDiv ? "20px" : "10px",
              }}
              id={"depType"}
              value={
                selectedAsset
                  ? selectedAsset && selectedAsset.complexId
                  : complexId
              }
              onChange={(event) => handleComplexOptionChange(event)}
            >
              {props.complexOptions && props.complexOptions.length > 0 ? (
                props.complexOptions.map(function (complex, i) {
                  return (
                    <MenuItem key={i} value={complex.value}>
                      of 'Complex, Property, Unit, Asset' {complex.label}
                    </MenuItem>
                  );
                }, this)
              ) : (
                <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
              )}
            </Select>
          </FormControl>
        )}
        <Grid item sx={{ width: { xs: "100%", lg: "33.3%" } }}>
          <Switch
            checked={incomeSwitch}
            onChange={(event) => setIncomeSwitch(event.target.checked)}
            inputProps={{ "aria-label": "controlled" }}
          />{" "}
          Does this asset generate income?
        </Grid>

        {incomeSwitch && (
          <FormControl sx={{ width: "100%" }}>
            <InputLabel>Income Account</InputLabel>
            <Select
              label="Income Account"
              sx={{ width: { xs: "100%", lg: "33.3%" } }}
              id={"depType"}
              value={selectedAsset && selectedAsset.incomeAccountId}
              onChange={(event) => handleIncomeAccountChange(event)}
            >
              {props.incomeAccounts && props.incomeAccounts.length > 0 ? (
                props.incomeAccounts.map(function (incAcc, i) {
                  return (
                    <MenuItem key={i} value={incAcc.value}>
                      {incAcc.label}
                    </MenuItem>
                  );
                }, this)
              ) : (
                <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
              )}
            </Select>
          </FormControl>
        )}
        <br />
      </ResponsiveRow>

      <br />
    </ModalContainerModule>
  );
}
