/* eslint-disable react-hooks/exhaustive-deps */
import {Box, Grid, Typography} from "@mui/material";
import {GridActionsCellItem} from "@mui/x-data-grid";
import {formatCurrency, isEmptyString, isNullUndefined,} from "../../util/util";
import {Clear, Send} from "@mui/icons-material";
import {useEffect, useMemo, useState} from "react";
import {allUnitOptions} from "../../services/list.service";
import ConfirmationModal from "../modals/confirmation";
import {issueResponseMessage, issueWarnMessage} from "../../actions/message";
import {approveWorkOrderCharge, removeWorkOrderCharge,} from "../../services/workOrder.service";
import {printLPO} from "../../services/print.service";
import {ResponsiveRow} from "../../constants/layout.constants";
import SplitButton from "../splitButton";
import Permit from "../../util/permit";
import MuiDataGrid from "../modules/MuiDataGrid";

export default function WorkOrderVendorDetailCard(props) {
    const VenderDetailButtons = [
        {
            label: "VENDOR ACTIONS",
            roles: false,
            services: false,
        },

        // {
        //   action: handleCompleteClick,
        //   label: "Complete",
        //   roles: false,
        //   services: false,
        // },
        {
            action: handlePrintLPO,
            label: "Print LPO",
            roles: false,
            services: false,
        },
        {
            action: handleAddChargeClick,
            label: "Add Charge",
            roles: false,
            services: false,
        },
    ];

    const [unitOptions, setUnitOptions] = useState([]);
    const [titleText, setTitleText] = useState("");
    const [innerTask, setInnerTask] = useState("");
    const [severityText, setSeverityText] = useState("");
    const [bodyText, setBodyText] = useState("");
    const [positiveText, setPositiveText] = useState("");
    const [negativeText, setNegativeText] = useState("");
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [selectedWorkOrderDetail, setSelectedWorkOrderDetail] = useState(null);
    const [hasUnit, setHasUnit] = useState(false);

    useEffect(() => {
        if (
            !isNullUndefined(props.selectedWorkOrder) &&
            !isNullUndefined(props.selectedWorkOrder.facilityId)
        ) {
            allUnitOptions(props.selectedWorkOrder.facilityId).then((unitOptions) => {
                setUnitOptions(unitOptions);
            });
        }
    }, [props.selectedWorkOrder]);

    useEffect(() => {
        props.vendor.charges &&
        props.vendor.charges.length > 0 &&
        props.vendor.charges.forEach(function (chargeItem, i) {
            if (!isNullUndefined(chargeItem.unitId)) {
                setHasUnit(true);
            }
        }, this);
    }, []);

    useEffect(() => {
        if (
            !isNullUndefined(props.selectedWorkOrder) &&
            !isNullUndefined(props.selectedWorkOrder.facilityId)
        ) {
            allUnitOptions(props.selectedWorkOrder.facilityId).then((unitOptions) => {
                setUnitOptions(unitOptions);
            });
        }
    }, [props.selectedWorkOrder]);

    function handleApproveClick(selectedDetail) {
        let title = "Confirm Approve Charge";
        let body = "Are you sure you want to Approve this charge?";
        setTitleText(title);
        setInnerTask("Approve Charge");
        setSeverityText("success");
        setBodyText(body);
        setPositiveText("Approve Charge");
        setNegativeText("Cancel");
        setShowConfirmationModal(true);
        setSelectedWorkOrderDetail(selectedDetail);
    }

    function handleAddChargeClick() {
        props.handleAddChargeClick(props.vendor);
    }

    function handlePrintLPO() {
        printLPO(props.selectedWorkOrder.id, props.vendor.vendorId);
    }

    const handleConfirmationClose = () => {
        setShowConfirmationModal(false);
        setTitleText("");
        setInnerTask("");
        setSeverityText("");
        setBodyText("");
        setPositiveText("");
        setNegativeText("");
        setSelectedWorkOrderDetail(null);
    };

    function positiveAction() {
        if (innerTask === "Remove Charge") {
            if (
                isNullUndefined(selectedWorkOrderDetail) ||
                isNullUndefined(selectedWorkOrderDetail.id) ||
                isEmptyString(selectedWorkOrderDetail.id)
            ) {
                issueWarnMessage(
                    "Could not determine Charge ID for deletion. Try refresh the page or contact support."
                );
                return;
            }
            removeWorkOrderCharge(selectedWorkOrderDetail.id).then((response) => {
                props.refreshDetails();
                issueResponseMessage(response);
                handleConfirmationClose();
            });
        }

        if (innerTask === "Approve Charge") {
            if (
                isNullUndefined(selectedWorkOrderDetail) ||
                isNullUndefined(selectedWorkOrderDetail.id) ||
                isEmptyString(selectedWorkOrderDetail.id)
            ) {
                issueWarnMessage(
                    "Could not determine Charge ID for approval. Try refresh the page or contact support."
                );
                return;
            }
            approveWorkOrderCharge(selectedWorkOrderDetail.id).then((response) => {
                props.refreshDetails();
                issueResponseMessage(response);
                handleConfirmationClose();
            });
        }
    }

    function handleDeleteClick(selectedDetail) {
        let title = "Confirm Remove Charge";
        let body = "Are you sure you want to remove this charge?";
        setTitleText(title);
        setInnerTask("Remove Charge");
        setSeverityText("warning");
        setBodyText(body);
        setPositiveText("Remove Charge");
        setNegativeText("Cancel");
        setShowConfirmationModal(true);
        setSelectedWorkOrderDetail(selectedDetail);
    }

    const columns = useMemo(() => {
        let columnArray = [];
        let memoWidth = 300;
        let priceWidth = 200;
        let subWidth = 200;
        if (hasUnit) {
            columnArray.push({
                field: "unitId",
                headerName: "Unit",
                width: 200,
                valueFormatter: (row) => {
                    if (!isNullUndefined(row.value)) {
                        let unitName = "";
                        for (let unit of unitOptions) {
                            if (unit.value === row.value) {
                                unitName = unit.label;
                            }
                        }
                        return unitName;
                    }
                },
            });
            memoWidth = 200;
            priceWidth = 150;
            subWidth = 150;
        }
        columnArray.push({
            field: "description",
            headerName: "Memo",
            minWidth: memoWidth,
            flex: hasUnit ? 1.5 : 1,
        });
        columnArray.push({
            field: "unitPrice",
            headerName: "Unit Price",
            minWidth: priceWidth,
            flex: hasUnit ? 0.75 : 1,
        });
        columnArray.push({
            field: "subTotal",
            headerName: "Sub Total",
            minWidth: subWidth,
            flex: hasUnit ? 0.75 : 1,
        });
        columnArray.push({
            field: "applyVAT",
            headerName: "Tax (%)",
            minWidth: 150,
            flex: 0.75,
            valueFormatter: (row) => {
                if (!isNullUndefined(row.value)) {
                    return row.value ? "16" : "0";
                }
            },
        });
        columnArray.push({
            field: "otherCharges",
            headerName: "Additional Charges",
            minWidth: 182,
            flex: 0.8,
            valueFormatter: (row) => {
                if (!isNullUndefined(row.value)) {
                    return row.value;
                } else {
                    return "0";
                }
            },
        });
        columnArray.push({
            field: "total",
            headerName: "Total",
            minWidth: 200,
            flex: 1,
        });
        columnArray.push({
            headerName: "Actions",
            field: "actions",
            minWidth: 100,
            flex: 0.5,
            type: "actions",
            getActions: (params) => {
                let arr = [];
                if (
                    !isNullUndefined(params.row.status) &&
                    params.row.status.code === "PEN"
                ) {
                    arr.push(
                        <GridActionsCellItem
                            icon={<Send/>}
                            label="Approve Item"
                            onClick={() => handleApproveClick(params.row)}
                            showInMenu
                        />
                    );
                    arr.push(
                        <GridActionsCellItem
                            icon={<Clear/>}
                            onClick={() => handleDeleteClick(params.row)}
                            label="Remove Item"
                            showInMenu
                        />
                    );
                } else {
                    if (!params.row.billed) {
                        arr.push(
                            <GridActionsCellItem
                                icon={<Clear/>}
                                onClick={() => handleDeleteClick(params.row)}
                                label="Remove Item"
                                showInMenu
                            />
                        );
                    }
                }
                return arr;
            },
        });
        return columnArray;
    });

    return (
        <Grid
            container
            spacing={1}
            sx={{
                padding: "20px",
                width: {xs: "100%", md: "100%"},
                backgroundColor: "#fff",
                borderRadius: "10px",
                minHeight: "240px",
                margin: "20px auto",
                boxShadow: "0 4px 12px 0 rgba(0,0,0,0.25)",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    // margin: "auto 20px",
                    width: "100%",
                    height: "fit-content",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        width: "100%",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: {xs: "column", lg: "row"},
                            width: "100%",
                            justifyContent: {xs: "center", lg: "flex-start"},
                            alignItems: "center",
                            padding: "10px",
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                width: "100%",
                                height: "auto",
                                justifyContent: {xs: "center", lg: "space-between"},
                                padding: "10px",
                                textAlign: {xs: "center", lg: "start"},
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: "14px",
                                    fontWeight: 800,
                                    width: "100%",
                                }}
                            >
                                Vendor Name
                            </Typography>

                            <Typography
                                sx={{
                                    fontSize: "1.2em",
                                    fontWeight: 800,
                                    color: "#009F93",
                                    width: "100%",
                                }}
                            >
                                {props.vendor.vendorName}
                            </Typography>
                        </Box>
                        <ResponsiveRow
                            sx={{
                                flexDirection: "row",
                                flexWrap: "nowrap",
                                width: {xs: "100%", lg: "50%"},
                                justifyContent: {xs: "center", lg: "space-between"},
                                alignItems: "center",
                                padding: "5px",
                                margin: {xs: "5px 0", lg: "auto 5px"},
                                backgroundColor: "#EFEFEF",
                                borderRadius: "10px",
                                textAlign: {xs: "center", lg: "center"},
                                height: "auto",
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: "14px",
                                    fontWeight: 800,

                                    margin: "auto",
                                    padding: "5px",
                                    textAlign: {xs: "center", lg: "center"},
                                    width: "100%",
                                }}
                            >
                                Total:
                            </Typography>
                            <Typography
                                sx={{
                                    fontWeight: 500,
                                    color: "#037171",
                                    fontSize: "14px",
                                    margin: {xs: "5px auto", lg: "auto 10px"},
                                    width: "100%",
                                    height: "auto",
                                }}
                            >
                                Ksh {formatCurrency(props.vendor.vendorSum)}
                            </Typography>
                        </ResponsiveRow>

                        <ResponsiveRow
                            sx={{
                                flexDirection: "row",
                                flexWrap: "nowrap",
                                width: {xs: "100%", lg: "50%"},
                                justifyContent: {xs: "center", lg: "space-between"},
                                alignItems: "center",
                                backgroundColor: "#EFEFEF",
                                borderRadius: "10px",
                                textAlign: {xs: "center", lg: "center"},
                                height: "auto",
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: "14px",
                                    fontWeight: 800,
                                    margin: {xs: "5px auto", lg: "auto 10px"},
                                    textAlign: {xs: "center", lg: "center"},
                                    width: "100%",
                                    height: "auto",
                                }}
                            >
                                Balance:
                            </Typography>
                            <Typography
                                sx={{
                                    fontWeight: 500,
                                    color: "#E53935",
                                    fontSize: "14px",
                                    margin: {xs: "5px auto", lg: "auto 10px"},
                                    width: "100%",
                                }}
                            >
                                Ksh {formatCurrency(props.vendor.vendorBalance)}
                            </Typography>
                        </ResponsiveRow>
                        <ResponsiveRow
                            sx={{
                                width: {xs: "100%", lg: "60%"},
                                padding: {xs: "10px 0", lg: "auto"},
                            }}
                        >
                            <Permit roles={"SYSAD,BLDIR,BLDMG,CS"}>
                                <SplitButton buttons={VenderDetailButtons}/>
                            </Permit>
                        </ResponsiveRow>
                    </Box>
                </Box>
            </Box>

            <Box
                sx={{
                    display: "flex",
                    flexDirection: {lg: "row", xs: "column"},
                    justifyContent: {xs: "center", lg: "space-between"},
                    margin: "0 10px",
                    width: "100%",
                    maxHeight: {xs: "300px", lg: "25vw"},
                }}
            >
                {/* <DataGrid
					rows={props.vendor.charges}
					columns={columns}
					pageSize={20}
					rowsPerPageOptions={[12]}
					disableSelectionOnClick
					autoHeight
					sx={{
						backgroundColor: "#fff",
						"& .MuiDataGrid-root": {
							padding: "20px 10px",
							borderWidth: "#fff",
						},
						"& .MuiDataGrid-columnHeaders": {
							"& .MuiDataGrid-checkboxInput": {
								color: "#fff",
							},
							backgroundColor: "#037171",
							color: "#fff",
							fontSize: "16px",
							fontWeight: 800,
							borderRadius: "10px 10px 0 0",
						},
					}}
				/> */}
                <MuiDataGrid
                    loading={false}
                    alt={true}
                    noBoxShadow={true}
                    noSelection={true}
                    dataGridColumns={columns}
                    dataGridRows={props.vendor.charges}
                    serverPagination={false}
                    totalRows={props.vendor.charges ? props.vendor.charges.length : 0}
                    height="40vh"
                />
            </Box>
            {/*CONFIRMATION MODAL*/}
            {showConfirmationModal && (
                <ConfirmationModal
                    showConfirmationModal={showConfirmationModal}
                    handleConfirmationClose={handleConfirmationClose}
                    title={titleText}
                    severity={severityText}
                    body={bodyText}
                    positiveText={positiveText}
                    negativeText={negativeText}
                    positiveAction={positiveAction}
                    negativeAction={handleConfirmationClose}
                />
            )}
        </Grid>
    );
}
