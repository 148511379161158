import { ResponsiveRow } from '../constants/layout.constants';
import {
	Alert,
	Grid,
	IconButton,
	InputLabel,
	TextField,
	Typography,
} from '@mui/material';
import {
	DetailsColumn,
	DetailsContainer,
	DetailsLabel,
	DetailsRow,
	DetailsText,
} from '../constants/component.constants';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import { isEmptyArray } from '../util/util';
import TableHead from '@mui/material/TableHead';
import ModalContainerModule from '../components/modules/modalContainer';
import { useSelector } from 'react-redux';
import Permit from '../util/permit';
import { printWorkOrder } from '../services/print.service';
import { Print } from '@mui/icons-material';

export default function WorkOrderReview(props) {
	const { user } = useSelector((state) => state.auth);
	let rows = [];
	let workOrderItems = props.workOrderItem && props.workOrderItem.items;
	workOrderItems &&
		!isEmptyArray(workOrderItems) &&
		(rows = workOrderItems.map((item, i) => {
			return (
				<TableRow>
					<TableCell>{item.vendorName}</TableCell>
					<TableCell>{item.description}</TableCell>
					<TableCell>{item.total}</TableCell>
					<TableCell>{item.status}</TableCell>
				</TableRow>
			);
		}));

	const handlePrint = () => {
		printWorkOrder(props.workOrderItem && props.workOrderItem.id);
	};

	return (
		<ModalContainerModule
			size="sm"
			accept={user.proxyRole === 'BLDIR' ? props.approveWorkOrder : false}
			reject={user.proxyRole === 'BLDIR' ? props.rejectWorkOrder : false}
			cancel={props.closeWorkOrderModal}
			openModal={props.openWorkOrderReviewModal}
			modalTitle={'Review Work Order'}
			acceptButtonText={'Approve'}
			rejectButtonText={'Reject'}
			acceptDisabled={user.proxyRole === 'BLDIR' ? false : true}
			printAction={handlePrint}
		>
			<ResponsiveRow sx={{ paddingTop: 0 }}>
				<DetailsContainer sx={{ width: '100%' }}>
					<DetailsColumn
						sx={{
							width: { lg: '50%', md: '100%', xs: '100%' },
						}}
					>
						<DetailsRow>
							<DetailsLabel>Requested by:</DetailsLabel>
							<DetailsText>
								{props.workOrderItem &&
									props.workOrderItem.requestedBy &&
									props.workOrderItem.requestedBy.name}
							</DetailsText>
						</DetailsRow>
						<DetailsRow>
							<DetailsLabel>Priority Level:</DetailsLabel>
							<DetailsText>
								{props.workOrderItem && props.workOrderItem.priority}
							</DetailsText>
						</DetailsRow>

						<DetailsRow>
							<DetailsLabel>Completion Date:</DetailsLabel>
							<DetailsText>
								{props.workOrderItem && props.workOrderItem.expectedCompletion}
							</DetailsText>
						</DetailsRow>
						<DetailsRow>
							<DetailsLabel>Reference Number:</DetailsLabel>
							<DetailsText>
								{props.workOrderItem && props.workOrderItem.refNumber}
							</DetailsText>
						</DetailsRow>
					</DetailsColumn>

					<DetailsColumn
						sx={{
							width: { lg: '50%', md: '100%', xs: '100%' },
						}}
					>
						<DetailsRow>
							<DetailsLabel>Date Created:</DetailsLabel>
							<DetailsText>
								{props.workOrderItem && props.workOrderItem.requestDate}
							</DetailsText>
						</DetailsRow>

						<DetailsRow>
							<DetailsLabel>Status:</DetailsLabel>
							<DetailsText>
								{props.workOrderItem &&
									props.workOrderItem.status &&
									props.workOrderItem.status.name}
							</DetailsText>
						</DetailsRow>
						<DetailsRow>
							<DetailsLabel>Total Cost:</DetailsLabel>
							<DetailsText>
								{props.workOrderItem && props.workOrderItem.totalCost}
							</DetailsText>
						</DetailsRow>
						<DetailsRow>
							<DetailsLabel>Applied To:</DetailsLabel>
							<DetailsText>
								{props.workOrderItem && props.workOrderItem.appliedTo}
							</DetailsText>
						</DetailsRow>
					</DetailsColumn>
					<DetailsRow>
						<DetailsLabel>Description:</DetailsLabel>
						<DetailsText>
							{props.workOrderItem && props.workOrderItem.description}
						</DetailsText>
					</DetailsRow>
				</DetailsContainer>
				<Permit roles={'BLDIR'}>
					<Alert severity="info">
						Please give a reason for Approving/Rejecting this work order
					</Alert>
					<ResponsiveRow container sx={{ height: 'auto' }}>
						<Grid
							item
							sx={{
								display: 'flex',
								flexDirection: 'column',
								width: '100%',
							}}
						>
							<InputLabel>Reason* ({500 - props.woNote.length})</InputLabel>
							<TextField
								sx={{ width: '100%' }}
								id={'note'}
								name={'note'}
								isrequired
								value={props.woNote}
								onChange={(event) => props.handleWONotesChaged(event)}
								multiline
								rows={2}
							/>
						</Grid>
					</ResponsiveRow>
				</Permit>
				<TableContainer component={Paper}>
					<Table aria-label="simple table">
						<TableHead>
							<TableRow>
								<TableCell>Vendor Name</TableCell>
								<TableCell>Description</TableCell>
								<TableCell>Total</TableCell>
								<TableCell>Status</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>{rows}</TableBody>
					</Table>
				</TableContainer>
			</ResponsiveRow>
		</ModalContainerModule>
	);
}
