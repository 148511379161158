import React from 'react'
import ModalContainerModule from '../modules/modalContainer'
import { Alert, AlertTitle, Grid, InputLabel, Switch } from '@mui/material'
import { useSelector } from 'react-redux'
import { isNullUndefined, titleCase } from '../../util/util'
import { ResponsiveRow } from '../../constants/layout.constants'

/**
 * This is a component that confirms bulk invoicing of meter readings
 *
 * Required props are:
 * handleAccept
 * closeModal
 * showModal
 * modalTitle
 * body
 * communicateInvoices
 * setCommunicateInvoices
 *
 * @param props
 * @constructor
 * @author Benson - 01/10/24
 */

function BulkInvoiceReadingsModal(props) {
  return (
    <ModalContainerModule
        size="specialConfirmationModal"
        accept={props.handleAccept}
        cancel={props.closeModal}
        openModal={props.showModal}
        modalTitle={props.modalTitle ? props.modalTitle : " "}
        acceptButtonText="Bulk Invoice"
    >
        <Alert severity={props.severity}>
            {props.body}{" "}
        </Alert>{" "}
        <br/>
        
        <ResponsiveRow container>
            <Grid item sx={{width: {xs: "100%", lg: "50%"}}}>
              <InputLabel>Communicate the invoices?</InputLabel>
              <Switch
                  checked={props.communicateInvoices}
                  onChange={(event) => props.setCommunicateInvoices(event.target.checked)}
                  inputProps={{"aria-label": "controlled"}}
              />
            </Grid>
        </ResponsiveRow>
    </ModalContainerModule>
  )
}

export default BulkInvoiceReadingsModal