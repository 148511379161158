import { useEffect, useState } from "react";
import {
  bulkFacility,
  bulkFacilityHOA,
  bulkFacilityMeterBilling,
  getPropertyManagers,
} from "../../services/meterbilling.service";
import {
  Alert,
  AlertTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import { isEmptyString, isNullUndefined } from "../../util/util";
import { useDispatch, useSelector } from "react-redux";
import { issueResponseMessage, setMessage } from "../../actions/message";
import { ResponsiveRow } from "../../constants/layout.constants";
import ModalContainerModule from "../modules/modalContainer";
import {
  bulkUploadMaintenanceFacilities,
  downloadBulkUploadTemplate,
} from "../../services/shared.service";
import { getSubscriptionBankAccounts } from "../../services/list.service";

/**
 *Required props are:
 - showBulkUploadModal: True or false to show or hide modal respectively.
 - handleBulkUploadClose: Function to close the modal ie set showTerminateModal to false.
 */

export default function BulkUpload(props) {
  const [, setLoading] = useState(true);
  const [BankAcc, setBankAcc] = useState(false);
  const [Mpesa, setMpesa] = useState(false);
  const [bankDetails, setBankDetails] = useState("");
  const [paybill, setPaybill] = useState("");
  const [businessCode, setBusinessCode] = useState("");
  const [mpesaKey, setKey] = useState("");
  const [mpesaSecret, setSecret] = useState("");
  const [mpesaPassKey, setPassKey] = useState("");

  const [propertyManagers, setPropertyManagers] = useState([]);
  const [orgBankAccounts, setorgBankAccounts] = useState([]);
  const [propertyManagerChoice, setPropertyManagerChoice] = useState("");
  const [subscriptionTypeChoice, setSubscriptionTypeChoice] = useState("");
  useSelector((state) => state.message);
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    getPropertyManagers()
      .then((propertyManagers) => setPropertyManagers(propertyManagers))
      .catch((error) => {
        console.log("getPropertyManagers Error: ", error);
      });

    getSubscriptionBankAccounts()
      .then((orgBankAccounts) => setorgBankAccounts(orgBankAccounts))
      .catch((error) => {
        console.log("error", error);
      });

    setLoading(false);
  }, []);

  const onTextChange = (searchText) => {
    const id = searchText.target.id;
    const value = searchText.target.value;
    if (id === "bankAcc") {
      setBankAcc(value);
    }
    if (id === "paybill") {
      setPaybill(value);
    }
    if (id === "businessCode") {
      setBusinessCode(value);
    }
    if (id === "key") {
      setKey(value);
    }
    if (id === "secret") {
      setSecret(value);
    }
    if (id === "passKey") {
      setPassKey(value);
    }
  };

  const handlePaymentManagerChange = (event) => {
    let value = event.target.value;
    setPropertyManagerChoice(value);
  };

  const handleBankDetailsChange = (event) => {
    let value = event.target.value;
    setBankDetails(value);
  };

  const handleSubscriptionTypeChange = (event) => {
    let value = event.target.value;
    setSubscriptionTypeChoice(value);
  };

  const getTemplate = () => {
    if (subscriptionTypeChoice === "") {
      dispatch(
        setMessage({
          type: "warning",
          message:
            "Please select either Residential, HOA or METER BILLING subscription type for the template.",
        })
      );
      return false;
    }
    // console.log("subscriptionType: ", subscriptionTypeChoice)

    downloadBulkUploadTemplate(subscriptionTypeChoice)
      .then(() => {
        dispatch(
          setMessage({
            type: "success",
            message: "Template downloaded",
          })
        );
      })
      .catch((error) => {
        dispatch(
          setMessage({
            type: "error",
            message: "Error occured while getting the template!",
          })
        );
      });
  };

  const bulkUpload = () => {
    let facilityPayInfo = {
      useMpesa: Mpesa,
      mpesaPaybill: paybill,
      mpesaBusinessShortcode: businessCode,
      mpesaKey: mpesaKey,
      mpesaSecret: mpesaSecret,
      mpesaPassKey: mpesaPassKey,
      useBankAccount: BankAcc,
      bankAccountId: bankDetails,
    };

    if (isEmptyString(propertyManagerChoice)) {
      dispatch(
        setMessage({
          type: "warning",
          message: "Please select a property manager",
        })
      );
      return false;
    }
    if (isEmptyString(subscriptionTypeChoice)) {
      dispatch(
        setMessage({
          type: "warning",
          message: "Please select a subscription choice",
        })
      );
      return false;
    }

    let data = new FormData();
    data.append("propertyManager", propertyManagerChoice);
    data.append("facilityPayInfo", JSON.stringify(facilityPayInfo));

    if (validateFacilityPayInfo(facilityPayInfo)) {
      if (subscriptionTypeChoice === "RES") {
        console.log("calling meter bulk upload");
        bulkFacility(data).then((response) => {
          closeModal();
          issueResponseMessage(response.data);
        });
      }
      if (subscriptionTypeChoice === "HOA") {
        console.log("calling HOA bulk upload");
        bulkFacilityHOA(data).then((response) => {
          closeModal();
          issueResponseMessage(response.data);
        });
      }
      if (subscriptionTypeChoice === "MEB") {
        // console.log('calling meter bulk upload')
        bulkFacilityMeterBilling(data).then((response) => {
          closeModal();
          issueResponseMessage(response.data);
        });
      }
      if (subscriptionTypeChoice === "MAINT") {
        // console.log('calling maintenance facilities bulk upload')
        bulkUploadMaintenanceFacilities(data).then((response) => {
          closeModal();
          issueResponseMessage(response.data);
        });
      }
    }
  };

  const closeModal = () => {
    props.handleBulkUploadClose();
    setMpesa(false);
    setBankAcc(false);
    setPropertyManagerChoice("");
    setSubscriptionTypeChoice("");
  };

  const validateFacilityPayInfo = (facilityPayInfo) => {
    if (isNullUndefined(facilityPayInfo)) {
      return "Something went wrong with the Property Payment Information. Try refresh the page";
    }
    if (Mpesa === false && BankAcc === false) {
      dispatch(
        setMessage({
          type: "warning",
          message: "Must select either Mpesa or Bank account and add details",
        })
      );
      return false;
    }
    if (Mpesa) {
      // if paybill given then short code must be there & vice versa
      if (
        isEmptyString(facilityPayInfo.mpesaPaybill) &&
        !isEmptyString(facilityPayInfo.mpesaBusinessShortcode)
      ) {
        return "Must provide a paybill number when a shortcode is given";
      }
      if (
        !isEmptyString(facilityPayInfo.mpesaPaybill) &&
        isEmptyString(facilityPayInfo.mpesaBusinessShortcode)
      ) {
        return "Must provide a business short code when a paybill is already provided";
      }
      if (
        !isEmptyString(facilityPayInfo.mpesaPaybill) &&
        facilityPayInfo.mpesaPaybill.length > 10
      ) {
        return "MPESA Paybill cannot be more than 10 characters";
      }
      if (
        !isEmptyString(facilityPayInfo.mpesaBusinessShortcode) &&
        facilityPayInfo.mpesaBusinessShortcode.length > 10
      ) {
        return "MPESA Business Shortcode cannot be more than 10 characters";
      }
    }

    if (BankAcc) {
      if (isEmptyString(facilityPayInfo.bankAccountId)) {
        return "Must selected a bank account when 'use a bank account' is checked";
      }
    }

    return true; // only true after everything is valid
  };

  let propertyUploadOptions = [
    { value: "RES", label: "Residential" },
    { value: "HOA", label: "Home Owners Association" },
    { value: "MEB", label: "Meter Billing" },
    { value: "MAINT", label: "Maintenance" },
  ];

  return (
    <ModalContainerModule
      size="sm"
      accept={bulkUpload}
      accept2={getTemplate}
      cancel={closeModal}
      openModal={props.showBulkUploadModal}
      modalTitle="Property Bulk Upload"
      acceptButtonText="Submit"
      acceptButtonText2="Download Template"
    >
      <Alert sx={{ width: "100%" }} severity="info">
        <AlertTitle>Property Payment Information</AlertTitle>
        This payment information will be sent out on the invoices so that
        customers can make payments using the appropriate service. Leave it
        blank if you want no information to appear on the invoice.
      </Alert>
      <br />
      <ResponsiveRow container sx={{ height: "auto" }}>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
        >
          <FormControlLabel
            value="useMpesa"
            control={
              <Radio
                onChange={() => {
                  setMpesa(true);
                  setBankAcc(false);
                }}
              />
            }
            label="Use M-PESA"
          />
          <FormControlLabel
            value="useBankAccount"
            control={
              <Radio
                onChange={() => {
                  setBankAcc(true);
                  setMpesa(false);
                }}
              />
            }
            label="Use Bank Account"
          />
        </RadioGroup>
        <br />
        {BankAcc ? (
          <ResponsiveRow
            sx={{
              width: "100%",
              height: "auto",
              flexDirection: "row",
              flexWrap: "nowrap",
            }}
          >
            <FormControl
              item
              sx={{ width: { xs: "100%", lg: "50%" }, marginBottom: "10px" }}
            >
              <InputLabel>Select Bank Account</InputLabel>
              <Select
                label="Select Bank Account"
                sx={{ width: { xs: "100%", llg: "90%" } }}
                id={"bankdetails"}
                name={"bankdetails"}
                value={bankDetails}
                onChange={(event) => handleBankDetailsChange(event)}
              >
                {orgBankAccounts && orgBankAccounts.length > 0 ? (
                  orgBankAccounts.map((bankAcc, i) => {
                    return (
                      <MenuItem key={i} value={bankAcc.value}>
                        {bankAcc.label}
                      </MenuItem>
                    );
                  }, this)
                ) : (
                  <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
                )}
              </Select>
            </FormControl>
          </ResponsiveRow>
        ) : (
          ""
        )}
        {Mpesa ? (
          <>
            <ResponsiveRow
              item
              sx={{
                width: "100%",
                height: "auto",
                marginBottom: "10px",
                padding: 0,
              }}
            >
              <Alert severity="warning" sx={{ width: "100%", padding: "20px" }}>
                If you want to use M-PESA and don't provide a Paybill & Business
                Shortcode, you will be defaulted to using the Smartkodi paybill
                (931342)
              </Alert>
            </ResponsiveRow>
            <ResponsiveRow
              sx={{
                width: "100%",
                height: "auto",

                flexDirection: "row",
                flexWrap: "nowrap",
              }}
              container
            >
              <FormControl
                item
                sx={{
                  width: { xs: "100%", lg: "50%" },
                  marginBottom: "10px",
                }}
              >
                <InputLabel>M-PESA Paybill</InputLabel>
                <OutlinedInput
                  label="M-PESA Paybill"
                  sx={{ width: { xs: "100%", lg: "90%" } }}
                  type="paybill"
                  id="paybill"
                  color="secondary"
                  onChange={(searchText) => onTextChange(searchText)}
                  inputProps={
                    <TextField sx={{ width: { xs: "100%", llg: "90%" } }} />
                  }
                />
              </FormControl>
              <FormControl
                item
                sx={{
                  width: { xs: "100%", lg: "50%" },
                  marginBottom: "10px",
                }}
              >
                <InputLabel>M-PESA Business Code</InputLabel>
                <OutlinedInput
                  label="M-PESA Business Code"
                  sx={{ width: { xs: "100%", lg: "90%" } }}
                  type="businessCode"
                  id="businessCode"
                  color="secondary"
                  onChange={(searchText) => onTextChange(searchText)}
                  inputProps={
                    <TextField sx={{ width: { xs: "100%", llg: "90%" } }} />
                  }
                />
              </FormControl>
              {user.proxyRole === "SYSAD" && (
                <FormControl
                  item
                  sx={{
                    width: { xs: "100%", lg: "50%" },
                    marginBottom: "10px",
                  }}
                >
                  <InputLabel>M-PESA Key</InputLabel>
                  <TextField
                    label="M-PESA Key"
                    sx={{ width: { xs: "100%", lg: "90%" } }}
                    type="key"
                    id="key"
                    color="secondary"
                    onChange={(searchText) => onTextChange(searchText)}
                    inputProps={
                      <TextField sx={{ width: { xs: "100%", llg: "90%" } }} />
                    }
                  />
                </FormControl>
              )}
              {user.proxyRole === "SYSAD" && (
                <FormControl
                  item
                  sx={{
                    width: { xs: "100%", lg: "50%" },
                    marginBottom: "10px",
                  }}
                >
                  <InputLabel>M-PESA Secret</InputLabel>
                  <OutlinedInput
                    label="M-PESA Service"
                    sx={{ width: { xs: "100%", lg: "90%" } }}
                    type="secret"
                    id="secret"
                    color="secondary"
                    onChange={(searchText) => onTextChange(searchText)}
                    inputProps={
                      <TextField sx={{ width: { xs: "100%", llg: "90%" } }} />
                    }
                  />
                </FormControl>
              )}
              {user.proxyRole === "SYSAD" && (
                <FormControl
                  item
                  sx={{
                    width: { xs: "100%", lg: "50%" },
                    marginBottom: "10px",
                  }}
                >
                  <InputLabel>
                    M-PESA Pass Key (for STK and Transaction Query)
                  </InputLabel>
                  <OutlinedInput
                    label="M-PESA Key (for STK and Transaction Query"
                    sx={{ width: { xs: "100%", lg: "90%" } }}
                    type="passKey"
                    id="passKey"
                    color="secondary"
                    onChange={(searchText) => onTextChange(searchText)}
                    inputProps={
                      <TextField sx={{ width: { xs: "100%", llg: "90%" } }} />
                    }
                  />
                </FormControl>
              )}
            </ResponsiveRow>
          </>
        ) : (
          ""
        )}
      </ResponsiveRow>
      <br />

      {/*BULK UPLOAD FORM*/}

      <Alert sx={{ width: "100%", marginBottom: "20px" }} severity="info">
        <AlertTitle>
          Upload the Excel file with the details of your Facilities and Units
          and select "Submit" to upload.
        </AlertTitle>
        If you are unsure of the format to use in your document, please select
        the subscription type below and then click on the "Download Template" to
        obtain an example document.
      </Alert>
      <br />
      <ResponsiveRow
        container
        sx={{
          width: "100%",
          height: "auto",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >

        <FormControl
          item
          sx={{ width: { xs: "100%", lg: "50%" }, marginBottom: "10px" }}
        >
          <InputLabel>Property Manager</InputLabel>
          <Select
            label="Property Manager"
            sx={{
              width: { xs: "100%", lg: "90%" },
            }}
            id={"propertyManagerChoice"}
            value={propertyManagerChoice}
            onChange={(event) => handlePaymentManagerChange(event)}
          >
            {propertyManagers && propertyManagers.length > 0 ? (
              propertyManagers.map((propertyManager, i) => {
                return (
                  <MenuItem key={i} value={propertyManager.value}>
                    {propertyManager.label}
                  </MenuItem>
                );
              }, this)
            ) : (
              <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
            )}
          </Select>
        </FormControl>
        <FormControl
          item
          sx={{ width: { xs: "100%", lg: "50%" }, marginBottom: "10px" }}
        >
          <InputLabel>Is property Residential, HOA or Utilities?</InputLabel>
          <Select
            label="Is Property Residential, HOA or Utilities?"
            sx={{
              width: { xs: "100%", lg: "90%" },
            }}
            id={"subscriptionType"}
            name={"subscriptionType"}
            onChange={(event) => handleSubscriptionTypeChange(event)}
          >
            {propertyUploadOptions && propertyUploadOptions.length > 0 ? (
              propertyUploadOptions.map((uploadOptions, i) => {
                return (
                  <MenuItem key={i} value={uploadOptions.value}>
                    {uploadOptions.label}
                  </MenuItem>
                );
              }, this)
            ) : (
              <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
            )}
          </Select>
        </FormControl>
      </ResponsiveRow>
      <br />
    </ModalContainerModule>
  );
}
