import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  PROXY_FAC_URL,
  PROXY_ORG_URL,
  PROXY_ROLE_URL,
} from "../../constants/url.constants";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { fetchOptionsGet } from "../../services/list.service";
import { clearProxy, executeProxy } from "../../actions/auth";
import { PROXY_FAC, PROXY_ORG, PROXY_ROLE } from "../../actions/type";
import { useNavigate } from "react-router-dom";
import { isEmptyString } from "../../util/util";
import { ResponsiveRow } from "../../constants/layout.constants";
import MUIAutoCompleteForm from "../inputs/MUIautoCompleteForm";

const useStyles = makeStyles((theme) => ({
  proxySelect: {
    "& .MuiSelect-select": {
      paddingTop: "8px!important",
      paddingBottom: "16px!important",
    },
  },
}));

export default function AdminProxyBar() {
  const classes = useStyles();
  const { user } = useSelector((state) => state.auth);
  const [roleList, setRoleList] = useState([]);
  const [facilityList, setFacilityList] = useState([]);
  const [subscriptionList, setSubscriptionList] = useState([]);
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedFacility, setSelectedFacility] = useState({
    label: "--",
    value: "--",
  });
  const [selectedSubscription, setSelectedSubscription] = useState({
    label: "--",
    value: "--",
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    fetchOptionsGet(PROXY_ROLE_URL).then((data) => {
      if (data) {
        setRoleList(data);
      }
    });
    fetchOptionsGet(PROXY_ORG_URL).then((data) => {
      if (data) {
        setSubscriptionList(data);
      }
    });
    fetchOptionsGet(PROXY_FAC_URL).then((data) => {
      if (data) {
        setFacilityList(data);
      }
    });
  }, []);

  useEffect(() => {
    if (user.proxySubscription !== "") {
      subscriptionList.forEach((item) => {
        if (String(item.value).includes(user.proxySubscription)) {
          setSelectedSubscription(item);
        }
      });
    } else {
      setSelectedSubscription({
        label: "--",
        value: "--",
      });
    }
  }, [user, selectedFacility, subscriptionList]);

  useEffect(() => {
    if (user.proxyFacility !== "") {
      facilityList.forEach((item) => {
        if (String(item.value).includes(user.proxyFacility)) {
          setSelectedFacility(item);
        }
      });
    } else {
      setSelectedFacility({
        label: "--",
        value: "--",
      });
    }
  }, [user, facilityList]);

  useEffect(() => {
    dispatch(
      executeProxy(user.proxyRole, user.proxySubscription, user.proxyFacility)
    );
    fetchOptionsGet(PROXY_FAC_URL).then((data) => {
      if (data) {
        setFacilityList(data);
      }
    });
    setSelectedSubscription({ label: "", value: "" });
    setSelectedFacility({ label: "", value: "" });
  }, [user?.proxyRole, user?.proxySubscription, user?.proxyFacility, dispatch]);

  useEffect(() => {
    if (!isEmptyString(user.proxySubscription)) {
      fetchOptionsGet(
        PROXY_FAC_URL + "?subscriptionId=" + user.proxySubscription
      ).then((data) => {
        if (data) {
          setFacilityList(data);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSubscription]);

  const handleRoleChange = (event) => {
    setSelectedRole(event.target.value);
    dispatch({
      type: PROXY_ROLE,
      payload: event.target.value,
    });
  };

  const handleFacilityChange = (selection) => {
    if (selection) {
      dispatch({
        type: PROXY_FAC,
        payload: selection.value,
      });
    }
    setSelectedFacility(selection);
  };

  const handleSubscriptionChange = (selection) => {
    if (selection) {
      if (selectedRole !== "") {
        dispatch({
          type: PROXY_ROLE,
          payload: selectedRole,
        });
      }
      dispatch({
        type: PROXY_ORG,
        payload: selection.value,
      });
    }
    setSelectedSubscription(selection);
  };

  const handleClearProxy = () => {
    setSelectedSubscription({
      label: "--",
      value: "--",
    });
    setSelectedFacility({
      label: "--",
      value: "--",
    });
    dispatch(clearProxy());
    navigate("/dashboard");
  };

  return (
    <Grid
      container
      sx={{
        display: "flex",
        flexDirection: "row",
        flexWrap: { md: "nowrap", sx: "wrap" },
        justifyContent: { md: "center", xs: "space-between" },
        width: { xs: "100%", md: "80%" },
        backgroundColor: "white",
        padding: { md: "1vh", xs: "1vh" },
        borderRadius: "1vh",
        margin: "20px auto",
        boxShadow: "0 4px 5px 0 rgba(0,0,0,0.5)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { md: "row", xs: "column" },
          justifyContent: { md: "space-between", xs: "center" },
          width: "100%",
        }}
        component="form"
      >
        <ResponsiveRow
          sx={{
            flexWrap: { xs: "wrap", lg: "nowrap" },
            justifyContent: "flex-start",
            width: { xs: "100%", lg: "75%" },
          }}
        >
          <FormControl
            sx={{
              margin: { md: "auto 20px", xs: "10px auto " },
              width: {
                lg: "33.3%",
                md: "25%",
                xs: "100%",
              },
            }}
          >
            <InputLabel id="roleSelectorLabel">Role</InputLabel>
            <Select
              defaultValue=""
              className={classes.proxySelect}
              id="roleSelector"
              value={user.proxyRole}
              label="Role"
              sx={{
                width: "90%",
                height: "5.7vh",
              }}
              onChange={handleRoleChange}
              MenuProps={{
                style: {
                  maxHeight: "300px",
                },
              }}
            >
              {roleList && roleList.length > 0 ? (
                roleList.map((item, index) => (
                  <MenuItem key={index} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))
              ) : (
                <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
              )}
            </Select>
          </FormControl>

          <FormControl
            sx={{
              margin: { md: "auto 20px", xs: "10px auto " },
              width: {
                lg: "33.3%",
                md: "25%",
                xs: "100%",
              },
            }}
          >
            <MUIAutoCompleteForm
              itemsData={subscriptionList}
              label="Subscription"
              updateSelected={handleSubscriptionChange}
              currentlySelected={selectedSubscription}
              menuId="subscriptionSelector"
              width="100%"
              disableClearable={true}
              emptyMessage="No subscriptions to show"
            />
          </FormControl>

          <FormControl
            sx={{
              margin: { md: "auto 10px", xs: "10px auto " },
              width: { lg: "33.3%", md: "25%", xs: "100%" },
            }}
          >
            {facilityList && (
              <MUIAutoCompleteForm
                itemsData={facilityList}
                label="Facility"
                updateSelected={handleFacilityChange}
                currentlySelected={selectedFacility}
                menuId="facilitySelector"
                width="100%"
                disableClearable={true}
                emptyMessage="No facilities to show."
              />
            )}
          </FormControl>
        </ResponsiveRow>

        <Button
          sx={{
            width: { xs: "100%", lg: "200px" },
            height: "60px",
            display: "flex",
            flexDirection: "row",
            flexWrap: "nowrap",
            backgroundColor: "#E53935",
            color: "#fff",
            margin: { md: "auto 10px", xs: "auto" },
          }}
          fullWidth
          onClick={handleClearProxy}
        >
          <Typography
            sx={{
              color: "#fff",
              width: "100%",
              fontWeight: "medium",
            }}
            variant="body2"
          >
            Clear Proxy
          </Typography>
        </Button>
      </Box>
    </Grid>
  );
}
