import {Add, Delete, Done, Edit, Visibility} from "@mui/icons-material";
import {useEffect, useState} from "react";
import QuotationTopBar from "../components/bars/quotationTopBar";
import ApproveQuotationModal from "../components/modals/maintenance/quotations/approveQuotationModal";
import CreateQuotationModal from "../components/modals/maintenance/quotations/createQuotationModal";
import DeleteQuotationModal from "../components/modals/maintenance/quotations/deleteQuotationModel";
import {MainContainer} from "../constants/component.constants";
import Permit from "../util/permit";
import {
    approveQuotationData,
    deleteQuotationData,
    fetchQuotation,
    generateWorkOrderDetailsData,
    getQuotations,
    getTotalQuotations,
    rejectQuotationData,
    saveQuotationData,
} from "../services/quotation.service";
import {issueResponseMessage} from "../actions/message";
import {formatCurrency, formatDate, isNullUndefined} from "../util/util";
import {GridActionsCellItem} from "@mui/x-data-grid";
import {useSelector} from "react-redux";
import GenerateWorkOrderDetailsModal from "../components/modals/maintenance/quotations/generateWorkOrderDetailsModal";
import ViewQuotation from "../components/modals/viewQuotation";
import MuiDataGrid from "../components/modules/MuiDataGrid";

export default function Quotations() {
    const [addQuotationModal, setAddQuotationModal] = useState(false);
    const [viewQuotationModal, setViewQuotationModal] = useState(false);
    const [quotations, setQuotations] = useState([]);
    const [selectedQuotation, setSelectedQuotation] = useState(null);
    const [approveQuotationModal, setApproveQuotationModal] = useState(false);
    const [deleteQuotationModal, setDeleteQuotationModal] = useState(false);
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageCount: 25,
    });
    const [loading, setLoading] = useState(true);
    const [totalQuotations, setTotalQuotations] = useState(0);
    const [workOrderReferenceNo, setWorkOrderReferenceNo] = useState("");
    const [description] = useState(""); //this can be used for search criteria
    const [statusCd] = useState(""); //this can be used for search criteria
    const [addedOn] = useState(""); //this can be used for search criteria
    const [task, setTask] = useState("");
    const user = useSelector((state) => state.auth.user);
    const [generateWorkOrderDetailsModal, setGenerateWorkOrderDetailsModal] =
        useState(false);

    useEffect(() => {
        setLoading(true);
        getTotalQuotations(
            statusCd,
            workOrderReferenceNo,
            description,
            addedOn
        ).then((response) => {
            if (!isNullUndefined(response)) {
                setTotalQuotations(response.totalQuotations);
            }
        });

        getQuotations(
            statusCd,
            workOrderReferenceNo,
            description,
            addedOn,
            paginationModel.page,
            paginationModel.pageCount
        )
            .then((quotations) => {
                setQuotations(quotations);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [
        paginationModel.page,
        workOrderReferenceNo,
        description,
        addedOn,
        paginationModel.pageCount,
    ]);

    function handleQuotationEdit(quotation) {
        setSelectedQuotation(quotation);
        setAddQuotationModal(true);
        setTask("Edit");
    }

    function handleQuotationDone(quotation) {
        setSelectedQuotation(quotation);
        setApproveQuotationModal(true);
    }

    function handleGenerateDetails(quotation) {
        setSelectedQuotation(quotation);
        setGenerateWorkOrderDetailsModal(true);
    }

    function handleQuotationDelete(quotation) {
        setSelectedQuotation(quotation);
        setDeleteQuotationModal(true);
    }

    function handleViewQuotationClose() {
        setViewQuotationModal(false);
        setSelectedQuotation(null);
    }

    function handleViewClick(selectedQuotation) {
        fetchQuotation(selectedQuotation.id).then((quotation) => {
            setSelectedQuotation(quotation);
        });
        if (!isNullUndefined(selectedQuotation)) setViewQuotationModal(true);
    }

    const quotationColumns = [
        {
            field: "vendorName",
            headerName: "Vendor",
            width: 200,
            valueFormatter: (row) => {
                return row.value.name;
            },
        },
        {field: "description", headerName: "Description", width: 200},
        {
            field: "addedOn",
            headerName: "Added On",
            minWidth: 200,
            flex: 1,
            valueFormatter: (row) => {
                return formatDate(row.value);
            },
        },
        {
            field: "total",
            headerName: "Amount",
            minWidth: 200,
            flex: 1,
            valueFormatter: (row) => {
                return formatCurrency(row.value);
            },
        },
        {
            field: "workOrder",
            headerName: "Work Order Ref No",
            minWidth: 200,
            flex: 1,
            valueFormatter: (row) => {
                if (
                    !isNullUndefined(row.value) &&
                    !isNullUndefined(row.value.refNumber)
                ) {
                    return row.value.refNumber;
                } else {
                    return "N/A";
                }
            },
        },
        {
            field: "status",
            headerName: "Status",
            minWidth: 200,
            flex: 1,
            valueFormatter: (row) => {
                return row.value.name;
            },
        },
        {
            headerName: "Actions",
            field: "actions",
            minWidth: 100,
            flex: 0.5,
            type: "actions",
            getActions: (params) => {
                let arr = [];
                arr.push(
                    <GridActionsCellItem
                        icon={<Visibility/>}
                        onClick={() => handleViewClick(params.row)}
                        label="View"
                        showInMenu
                    />
                );
                if (
                    user.proxyRole === "BLDIR" ||
                    user.proxyRole === "SYSAD" ||
                    user.proxyRole === "BLDMG" ||
                    user.proxyRole === "CS"
                ) {
                    arr.push(
                        <GridActionsCellItem
                            sx={{color: "#037171"}}
                            icon={<Edit sx={{margin: "0 4px", color: "#037171"}}/>}
                            label="Edit"
                            onClick={() => handleQuotationEdit(params.row)}
                            showInMenu
                        />
                    );
                    if (params.row.statusCd === "PEN") {
                        arr.push(
                            <GridActionsCellItem
                                sx={{color: "#037171"}}
                                icon={<Done sx={{margin: "0 4px", color: "#037171"}}/>}
                                label="Approve / Reject"
                                onClick={() => handleQuotationDone(params.row)}
                                showInMenu
                            />
                        );
                    }

                    if (params.row.statusCd === "APP") {
                        arr.push(
                            <GridActionsCellItem
                                sx={{color: "#037171"}}
                                icon={<Add sx={{margin: "0 4px", color: "#037171"}}/>}
                                label="Generate Details"
                                onClick={() => handleGenerateDetails(params.row)}
                                showInMenu
                            />
                        );
                    }

                    if (params.row.editable) {
                        arr.push(
                            <GridActionsCellItem
                                sx={{color: "red"}}
                                icon={<Delete sx={{margin: "0 4px", color: "red"}}/>}
                                label="Delete"
                                onClick={() => handleQuotationDelete(params.row)}
                                showInMenu
                            />
                        );
                    }
                }
                return arr;
            },
        },
    ];

    function handleCreateQuotation() {
        let data = {
            id: null,
            workOrderId: "",
            description: "",
            organizationId: "",
            personId: "",
            editable: true,
            quotationItems: [],
        };
        setSelectedQuotation(data);
        setAddQuotationModal(true);
        setTask("Create");
    }

    function saveQuotation(selectedQuotation, formData) {
        setSelectedQuotation(selectedQuotation);
        saveQuotationData(formData)
            .then((response) => {
                setLoading(true);
                getTotalQuotations(
                    statusCd,
                    workOrderReferenceNo,
                    description,
                    addedOn
                ).then((response) => {
                    if (!isNullUndefined(response)) {
                        setTotalQuotations(response.totalQuotations);
                    }
                });

                getQuotations(
                    statusCd,
                    workOrderReferenceNo,
                    description,
                    addedOn,
                    paginationModel.page,
                    paginationModel.pageCount
                ).then((quotations) => {
                    setQuotations(quotations);
                });

                issueResponseMessage(response);
                handleAddQuotationClose();
            })
            .finally(() => {
                setLoading(false);
            });
    }

    function handleApproveQuotation() {
        // approve quotation endpoint
        approveQuotationData({id: selectedQuotation.id})
            .then((response) => {
                setLoading(true);
                getQuotations(
                    statusCd,
                    workOrderReferenceNo,
                    description,
                    addedOn,
                    paginationModel.page,
                    paginationModel.pageCount
                ).then((quotations) => {
                    setQuotations(quotations);
                });

                issueResponseMessage(response);
                handleApproveRejectClose();
            })
            .finally(() => {
                setLoading(false);
            });
    }

    function handleRejectQuotation() {
        // reject quotation endpoint
        rejectQuotationData({id: selectedQuotation.id})
            .then((response) => {
                setLoading(true);
                getQuotations(
                    statusCd,
                    workOrderReferenceNo,
                    description,
                    addedOn,
                    paginationModel.page,
                    paginationModel.pageCount
                ).then((quotations) => {
                    setQuotations(quotations);
                });

                issueResponseMessage(response);
                handleApproveRejectClose();
            })
            .finally(() => {
                setLoading(false);
            });
    }

    function handleGenerateWorkOrderDetails(quotationItemIds) {
        // Generate Work Order Details endpoint
        generateWorkOrderDetailsData({quotationItemIds: quotationItemIds})
            .then((response) => {
                setLoading(true);
                getQuotations(
                    statusCd,
                    workOrderReferenceNo,
                    description,
                    addedOn,
                    paginationModel.page,
                    paginationModel.pageCount
                ).then((quotations) => {
                    setQuotations(quotations);
                });
                issueResponseMessage(response);
                handleGenerateWorkOrderDetailsModalClose();
            })
            .finally(() => {
                setLoading(false);
            });
    }

    function handleDeleteQuotation() {
        // delete quotation endpoint
        deleteQuotationData({id: selectedQuotation.id})
            .then((response) => {
                setLoading(true);
                getTotalQuotations(
                    statusCd,
                    workOrderReferenceNo,
                    description,
                    addedOn
                ).then((response) => {
                    if (!isNullUndefined(response)) {
                        setTotalQuotations(response.totalQuotations);
                    }
                });

                getQuotations(
                    statusCd,
                    workOrderReferenceNo,
                    description,
                    addedOn,
                    paginationModel.page,
                    paginationModel.pageCount
                ).then((quotations) => {
                    setQuotations(quotations);
                });

                issueResponseMessage(response);
                handleDeleteClose();
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const handleAddQuotationClose = () => {
        setTask("");
        setAddQuotationModal(false);
        setSelectedQuotation(null);
    };

    const handleApproveRejectClose = () => {
        setApproveQuotationModal(false);
        setSelectedQuotation(null);
    };

    const handleDeleteClose = () => {
        setDeleteQuotationModal(false);
        setSelectedQuotation(null);
    };

    const handleGenerateWorkOrderDetailsModalClose = () => {
        setGenerateWorkOrderDetailsModal(false);
        setSelectedQuotation(null);
    };

    return (
        <Permit
            roles="BLDIR,BLDMG,ACC,CS"
            services="MAINT,PREMIUM"
            noPermittedServiceMessage={{
                title: "Access Denied",
                body: (
                    <>
                        Your current role and/or facility is not permitted to view this
                        page. <br/> Please contact your system admin if you feel this is an
                        error.
                    </>
                ),
            }}
        >
            <MainContainer>
                <QuotationTopBar
                    handleCreateQuotation={handleCreateQuotation}
                    workOrderReferenceNo={workOrderReferenceNo}
                    setWorkOrderReferenceNo={setWorkOrderReferenceNo}
                />

                <MuiDataGrid
                    noSelection={true}
                    dataGridColumns={quotationColumns}
                    dataGridRows={quotations}
                    serverPagination={true}
                    handlePageLoad={setPaginationModel}
                    loading={loading}
                    height="60vh"
                    totalRows={totalQuotations}
                />

                <CreateQuotationModal
                    openModal={addQuotationModal}
                    closeModal={handleAddQuotationClose}
                    selectedQuotation={selectedQuotation}
                    task={task}
                    saveQuotation={saveQuotation}
                />
                {viewQuotationModal && !isNullUndefined(selectedQuotation) && (
                    <ViewQuotation
                        openModal={viewQuotationModal}
                        handleClose={handleViewQuotationClose}
                        selectedQuotation={selectedQuotation}
                    />
                )}
                <ApproveQuotationModal
                    openModal={approveQuotationModal}
                    closeModal={handleApproveRejectClose}
                    selectedQuotation={selectedQuotation}
                    handleApproveQuotation={handleApproveQuotation}
                    handleRejectQuotation={handleRejectQuotation}
                />
                <DeleteQuotationModal
                    openModal={deleteQuotationModal}
                    closeModal={handleDeleteClose}
                    selectedQuotation={selectedQuotation}
                    handleDeleteQuotation={handleDeleteQuotation}
                />
                <GenerateWorkOrderDetailsModal
                    openModal={generateWorkOrderDetailsModal}
                    closeModal={handleGenerateWorkOrderDetailsModalClose}
                    selectedQuotation={selectedQuotation}
                    handleGenerateWorkOrderDetails={handleGenerateWorkOrderDetails}
                />
            </MainContainer>
        </Permit>
    );
}
