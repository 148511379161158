import {ResponsiveRow} from "../constants/layout.constants"
import ModalContainerModule from "../components/modules/modalContainer"
import {DetailsColumn, DetailsContainer, DetailsLabel, DetailsRow, DetailsText} from "../constants/component.constants"
import {formatCurrency, formatDate} from "../util/util"
import {useSelector} from "react-redux";

export default function WorkOrderDetailReview(props) {
    const {user} = useSelector((state) => state.auth);
    return (
        <ModalContainerModule
            size="xs"
            accept={user.proxyRole === "BLDIR" ? props.approveWorkOrderDetail : false}
            reject={user.proxyRole === "BLDIR" ? props.rejectWorkOrderDetail : false}
            cancel={props.closeWODetailReviewModal}
            openModal={props.openWODetailReviewModal}
            modalTitle={"Review Work Order Charge Detail"}
            acceptButtonText={"Approve"}
            rejectButtonText={"Reject"}
            acceptDisabled={user.proxyRole === "BLDIR" ? false : true}
        >
            <ResponsiveRow sx={{paddingTop: 0}}>
                <DetailsContainer sx={{width: "100%"}}>
                    <DetailsColumn
                        sx={{
                            width: {lg: "33.3%", md: "100%", xs: "100%"},
                        }}
                    >
                        <DetailsRow>
                            <DetailsLabel>Added by:</DetailsLabel>
                            <DetailsText>
                                {props.woDetailItem && props.woDetailItem.addedBy && props.woDetailItem.addedBy.name}
                            </DetailsText>
                        </DetailsRow>
                        <DetailsRow>
                            <DetailsLabel>Date Added:</DetailsLabel>
                            <DetailsText>
                                {props.woDetailItem && props.woDetailItem.addedOn && formatDate(props.woDetailItem.addedOn)}
                            </DetailsText>
                        </DetailsRow>
                        <DetailsRow>
                            <DetailsLabel>Description:</DetailsLabel>
                            <DetailsText>
                                {props.woDetailItem && props.woDetailItem.description}
                            </DetailsText>
                        </DetailsRow>
                    </DetailsColumn>

                    <DetailsColumn
                        sx={{
                            width: {lg: "33.3%", md: "100%", xs: "100%"},
                        }}
                    >
                        <DetailsRow>
                            <DetailsLabel>Unit Price:</DetailsLabel>
                            <DetailsText>
                                {props.woDetailItem && props.woDetailItem.unitPrice && formatCurrency(props.woDetailItem.unitPrice)}
                            </DetailsText>
                        </DetailsRow>
                        <DetailsRow>
                            <DetailsLabel>Quantity:</DetailsLabel>
                            <DetailsText>
                                {props.woDetailItem && props.woDetailItem.quantity}
                            </DetailsText>
                        </DetailsRow>
                        <DetailsRow>
                            <DetailsLabel>Taxed (VAT 16%):</DetailsLabel>
                            <DetailsText>
                                {props.woDetailItem && props.woDetailItem.applyVAT && props.woDetailItem.applyVAT === true ? 'Yes' : 'No'}
                            </DetailsText>
                        </DetailsRow>
                    </DetailsColumn>
                    <DetailsColumn
                        sx={{
                            width: {lg: "33.3%", md: "100%", xs: "100%"},
                        }}
                    >
                        <DetailsRow>
                            <DetailsLabel>Sub-Total:</DetailsLabel>
                            <DetailsText>
                                {props.woDetailItem && props.woDetailItem.subTotal && formatCurrency(props.woDetailItem.subTotal)}
                            </DetailsText>
                        </DetailsRow>
                        <DetailsRow>
                            <DetailsLabel>Other Charges:</DetailsLabel>
                            <DetailsText>
                                {props.woDetailItem && props.woDetailItem.otherCharges && formatCurrency(props.woDetailItem.otherCharges)}
                            </DetailsText>
                        </DetailsRow>
                        <DetailsRow>
                            <DetailsLabel>Total:</DetailsLabel>
                            <DetailsText>
                                {props.woDetailItem && props.woDetailItem.total && formatCurrency(props.woDetailItem.total)}
                            </DetailsText>
                        </DetailsRow>
                    </DetailsColumn>
                </DetailsContainer>
            </ResponsiveRow>
        </ModalContainerModule>
    )
}
