import {
    Box,
    Checkbox,
    FormControlLabel,
    Grid,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from "@mui/material"
import HomeWork from "@mui/icons-material/HomeWork"

import {ResponsiveRow} from "../../constants/layout.constants"
import {ModalAcceptButton} from "../../constants/component.constants"

import Cancel from "@mui/icons-material/Cancel"
import {FileUpload} from "../inputs/fileUpload"
import {useState} from "react"

export default function SignOnPropertyDetails() {
    const [manualForm, setManualForm] = useState(false)

    return (
        <>
            <ResponsiveRow
                sx={{
                    justifyContent: "flex-start",
                    alignItems: "center",
                    padding: "20px",
                    height: "fit-content",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "#efefef",
                        borderRadius: "50%",
                        height: "70px",
                        width: "70px",
                    }}
                >
                    <HomeWork
                        sx={{margin: "auto", color: "#009F93", fontSize: "2.5rem"}}
                    />
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        paddingLeft: "20px",
                    }}
                >
                    <Typography
                        sx={{fontSize: "1.15rem", fontWeight: 600, marginTop: "5px"}}
                    >
                        Property Details
                    </Typography>
                </Box>
            </ResponsiveRow>
            <ResponsiveRow
                sx={{
                    justifyContent: "flex-start",
                    alignItems: "center",
                    height: "fit-content",
                    marginTop: "10px",
                    padding: "0 40px",
                    paddingBottom: 0,
                    margin: "auto",
                }}
            >
                <Typography variant="h6" fontWeight={600}>
                    Property Information
                </Typography>
            </ResponsiveRow>
            <ResponsiveRow
                sx={{
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    height: "fit-content",
                    marginTop: "10px",
                    padding: {xs: "5px", lg: "20px 40px"},
                }}
            >
                <Grid
                    item
                    sx={{width: {xs: "100%", lg: "33.3%"}, marginBottom: "10px"}}
                >
                    <TextField sx={{width: "90%"}} label="Property Name"/>
                </Grid>
                <Grid
                    item
                    sx={{width: {xs: "100%", lg: "33.3%"}, marginBottom: "10px"}}
                >
                    <TextField sx={{width: "90%"}} label="Address"/>
                </Grid>
                <Grid
                    item
                    sx={{width: {xs: "100%", lg: "33.3%"}, marginBottom: "10px"}}
                >
                    <TextField sx={{width: "90%"}} label="City"/>
                </Grid>
                <Grid
                    item
                    sx={{width: {xs: "100%", lg: "33.3%"}, marginBottom: "10px"}}
                >
                    <TextField sx={{width: "90%"}} label="Country"/>
                </Grid>
                <Grid
                    item
                    sx={{width: {xs: "100%", lg: "33.3%"}, marginBottom: "10px"}}
                >
                    <TextField sx={{width: "90%"}} label="LR Number"/>
                </Grid>
                <ResponsiveRow
                    item
                    sx={{
                        width: {xs: "100%", lg: "33.3%"},
                        flexWrap: {xs: "wrap", lg: "nowrap"},
                    }}
                >
                    <TextField sx={{width: "auto", margin: "5px"}} label="Longitude"/>
                    <TextField sx={{width: "auto", margin: "5px"}} label="Latitude"/>
                </ResponsiveRow>
                <Grid
                    item
                    sx={{
                        width: "100%",
                        fontWeight: 700,
                        textAlign: "start",
                        margin: "auto 40px",
                        marginTop: "5px",
                    }}
                >
                    <FormControlLabel
                        label="Enter residential details manually ?"
                        control={
                            <Checkbox
                                value={manualForm}
                                checked={manualForm}
                                onChange={() => setManualForm(!manualForm)}
                                color="primary"
                            />
                        }
                    />
                </Grid>
            </ResponsiveRow>
            {manualForm && (
                <ResponsiveRow
                    sx={{
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        // height: "100%",
                        marginTop: "10px",
                        margin: "auto 20px",
                        padding: "10px",
                        borderRadius: "10px",
                    }}
                >
                    <TableContainer>
                        <Table sx={{minWidth: 650}} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell align="left">Type</TableCell>
                                    <TableCell align="left">Rent</TableCell>
                                    <TableCell align="left">Sq/Ft</TableCell>
                                    <TableCell align="left">Rent/SqFt</TableCell>
                                    <TableCell align="left">Bed</TableCell>
                                    <TableCell align="left">Bath</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {" "}
                                <TableRow
                                    // key={row.name}
                                    sx={{"&:last-child td, &:last-child th": {border: 0}}}
                                >
                                    <TableCell component="th" scope="row">
                                        <TextField
                                            variant="outlined"
                                            placeholder="Type here..."
                                            sx={{backgroundColor: "#efefef", borderRadius: "10px"}}
                                        />
                                    </TableCell>
                                    <TableCell align="left">
                                        <TextField
                                            variant="outlined"
                                            placeholder="Type here..."
                                            sx={{backgroundColor: "#efefef", borderRadius: "10px"}}
                                        />
                                    </TableCell>
                                    <TableCell align="left">
                                        <TextField
                                            variant="outlined"
                                            placeholder="Type here..."
                                            sx={{backgroundColor: "#efefef", borderRadius: "10px"}}
                                        />
                                    </TableCell>
                                    <TableCell align="left">
                                        <TextField
                                            variant="outlined"
                                            placeholder="Type here..."
                                            sx={{backgroundColor: "#efefef", borderRadius: "10px"}}
                                        />
                                    </TableCell>
                                    <TableCell align="left">
                                        <TextField
                                            variant="outlined"
                                            placeholder="Type here..."
                                            sx={{backgroundColor: "#efefef", borderRadius: "10px"}}
                                        />
                                    </TableCell>{" "}
                                    <TableCell align="left">
                                        <TextField
                                            variant="outlined"
                                            placeholder="Type here..."
                                            sx={{backgroundColor: "#efefef", borderRadius: "10px"}}
                                        />
                                    </TableCell>
                                    <TableCell align="left">
                                        <TextField
                                            variant="outlined"
                                            placeholder="Type here..."
                                            sx={{backgroundColor: "#efefef", borderRadius: "10px"}}
                                        />
                                    </TableCell>
                                    <TableCell align="left">
                                        <IconButton
                                            // onClick={}
                                            color="error"
                                            sx={{
                                                backgroundColor: "#fff",
                                                borderRadius: "10px",
                                                width: "100%",
                                                height: "100%",
                                            }}
                                        >
                                            <Cancel
                                                sx={{
                                                    fontSize: "2.5rem",
                                                }}
                                            />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <ResponsiveRow
                        sx={{
                            justifyContent: {xs: "center", lg: "flex-end"},
                            height: "100%",
                        }}
                    >
                        <ModalAcceptButton
                            sx={{width: {xs: "100%", lg: "200px"}, height: "fit-content"}}
                        >
                            Add Row
                        </ModalAcceptButton>
                    </ResponsiveRow>
                </ResponsiveRow>
            )}
            {!manualForm && (
                <ResponsiveRow
                    sx={{
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        width: "95%",
                        // height: "100px",
                        marginTop: "10px",
                        margin: "auto",
                        padding: {xs: "5px", lg: "20px"},
                        borderRadius: "10px",
                        backgroundColor: "#efefef",
                    }}
                >
                    <Typography
                        variant="h5"
                        sx={{marginBottom: "20px", padding: "10px"}}
                    >
                        Residential Details Template (XSML)
                    </Typography>
                    <ResponsiveRow
                        sx={{
                            justifyContent: {xs: "center", lg: "flex-start"},
                            alignItems: "center",
                            flexWrap: {xs: "wrap", lg: "nowrap"},
                            padding: 0,
                            width: "100%",
                        }}
                    >
                        <FileUpload bg_color="#fff" width="400px"/>
                        <ModalAcceptButton
                            sx={{width: {xs: "100%", lg: "300px"}, padding: "15px"}}
                        >
                            Upload Completed Template
                        </ModalAcceptButton>
                    </ResponsiveRow>
                    <ResponsiveRow
                        sx={{
                            justifyContent: {xs: "center", lg: "flex-end"},
                            width: "100%",
                        }}
                    >
                        <ModalAcceptButton sx={{width: {xs: "100%", lg: "300px"}}}>
                            Download Completed Template
                        </ModalAcceptButton>
                        <ModalAcceptButton
                            disabled
                            sx={{width: {xs: "100%", lg: "300px"}}}
                        >
                            Save Upload
                        </ModalAcceptButton>
                    </ResponsiveRow>
                </ResponsiveRow>
            )}
        </>
    )
}
