import { Typography } from "@mui/material";
import {
  TopBarContainedWarningButton,
  TopBarContainer,
} from "../../constants/component.constants";

export default function SignUpAnalysisViewBar(props) {
  return (
    <TopBarContainer
      container
      sx={{
        flexDirection: { xs: "column", lg: "row" },
        flexWrap: "nowrap",

        width: { xs: "100%", lg: "80%" },
        boxShadow: "0 4px 5px 0 rgba(0,0,0,0.5)",
      }}
    >
      <Typography
        variant="h5"
        sx={{
          width: { xs: "100%", lg: "30%" },
          textAlign: { xs: "center", lg: "start" },
          marginBottom: "10px",
        }}
      >
        {props.selectedSignUp && props.selectedSignUp.personName}
      </Typography>

      <TopBarContainedWarningButton
        variant="contained"
        type="submit"
        onClick={() => props.setTask("")}
        sx={{
          width: { xs: "100%", lg: "250px" },
        }}
      >
        Close
      </TopBarContainedWarningButton>
    </TopBarContainer>
  );
}
