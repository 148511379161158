/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import {
  Alert,
  Divider,
  FormControl,
  FormLabel,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material"
import Grid from "@mui/material/Grid"
import { useEffect, useState } from "react"
import { getIdTypes, getRentDays } from "../../services/list.service"
import { getUserRoles } from "../../services/users.service"
import { issueErrorMessage, issueWarnMessage } from "../../actions/message"
import {
  isEmptyObject,
  isEmptyString,
  isNullUndefined,
  tryParseFloat,
} from "../../util/util"
import Permit from "../../util/permit"
import "dayjs/locale/en-gb"
import { getMeterBillingPeriods } from "../../services/meterbilling.service"
import { ResponsiveRow } from "../../constants/layout.constants"
import ModalContainerModule from "../modules/modalContainer"

/**
 *Required props are:
 - openModal: Function to close the modal ie set showTerminateModal to false.
 - closeModal: The data for the selected user being added or viewed.
 - saveUser: Function that handles the user/customer addition.
 - searchedIdType: To preload ID search type name when adding a new user
 - searchedIdNo: To preload ID number name when adding a new user
 - selectedPerson: User data to populate form.
 - manageUsers: Boolean. To render add role if using modal to manage users.
 - customer: Boolean. To render additional credit limit component.
 - task: Edit
 */

export default function AddNewUser(props) {
  const [firstName, setFirstName] = useState("")
  const [middleName, setMiddleName] = useState("")
  const [lastName, setLastName] = useState("")
  const [mobilePhone, setMobilePhone] = useState("")
  const [officePhone, setOfficePhone] = useState("")
  const [email, setEmail] = useState("")
  const [idNoSearch, setIdNo] = useState("")
  const [idTypeSearch] = useState("")
  const [idTypes, setIdTypes] = useState([])
  const [userRoles, setUserRoles] = useState([])
  const [selectedUser, setSelectedUser] = useState()
  const [selectedRole, setSelectedRole] = useState("")
  const [approveCapitalFunds, setApproveCapitalFunds] = useState(false)
  const [viewOnlyAccess, setViewOnlyAccess] = useState(false)
  const [approveBudgets, setApproveBudgets] = useState(false)
  const [billingPeriods, setBillingPeriods] = useState([])
  const [rentDays, setRentDays] = useState([])
  const [billingDate, setBillingDate] = useState("")

  useEffect(() => {
    let data = {
      id: null,
      firstName: "",
      middleName: "",
      lastName: "",
      mobilePhone: "",
      officePhone: "",
      email: "",
      credentialId: "",
      idNumber: props.searchedIdNo,
      idTypeCd: props.searchedIdType,
      photoFile: "",
      name: "",
      organization: null,
      username: "",
      roleCode: "",
      roleName: "", // not really used in the form but keeping this field to maintain consistency between server json and client side json
      permissions: [],
      absoluteCreditLimit: "",
      billingPeriod: "",
      billingDate: "",
      billingDay: "",
      type: "Individual",
    }
    getIdTypes().then((idTypes) => setIdTypes(idTypes))
    getUserRoles().then((userRoles) => setUserRoles(userRoles))
    getMeterBillingPeriods().then((billingPeriods) =>
      setBillingPeriods(billingPeriods)
    )
    getRentDays().then((rentDays) => setRentDays(rentDays))

    if (isEmptyObject(props.selectedPerson)) {
      setSelectedUser(data)
    }
  }, [props.searchedIdType])

  useEffect(() => {
    if (!isNullUndefined(props.task) && props.task === "Edit") {
      setSelectedUser(props.selectedPerson)
      if (selectedUser && selectedUser.permissions) {
        if (selectedUser.permissions.indexOf("view only access") >= 0) {
          setViewOnlyAccess(true)
        }
        if (selectedUser.permissions.indexOf("capital fund") >= 0) {
          setApproveCapitalFunds(true)
        }
        if (selectedUser.permissions.indexOf("budget") >= 0) {
          setApproveBudgets(true)
        }
      }
    }
  }, [selectedUser])

  useEffect(() => {
    if (!isNullUndefined(props.selectedPerson)) {
      setSelectedUser(props.selectedPerson)
    }
  }, [])

  const handleRoleChange = (event) => {
    let value = event.target.value
    setSelectedRole(value)
    selectedUser.roleCode = value
  }

  function handleIdTypeChange(event) {
    let value = event.target.value
    setSelectedRole(value)
    selectedUser.idTypeCd = value
  }

  function handleSaveUserClicked() {
    if (validateForm()) {
      props.saveUser(selectedUser)
      closeModal()
    }
  }

  function handleInputChange(e) {
    let obj = selectedUser

    switch (e.target.name) {
      case "firstName":
        setFirstName(e.target.value)
        obj = selectedUser
        obj.firstName = e.target.value
        setSelectedUser(obj)

        break

      case "middleName":
        setMiddleName(e.target.value)
        obj = selectedUser
        obj.middleName = e.target.value
        setSelectedUser(obj)

        break

      case "lastName":
        setLastName(e.target.value)
        obj = selectedUser
        obj.lastName = e.target.value
        setSelectedUser(obj)

        break

      case "mobilePhone":
        setMobilePhone(e.target.value)
        obj = selectedUser
        obj.mobilePhone = e.target.value
        setSelectedUser(obj)

        break

      case "idNo":
        setIdNo(e.target.value)
        obj = selectedUser
        obj.idNumber = e.target.value
        setSelectedUser(obj)

        break

      case "officePhone":
        setOfficePhone(e.target.value)
        obj = selectedUser
        obj.officePhone = e.target.value
        setSelectedUser(obj)

        break

      case "email":
        setEmail(e.target.value)
        obj = selectedUser
        obj.email = e.target.value
        setSelectedUser(obj)

        break

      case "absoluteCreditLimit":
        obj = selectedUser
        obj.absoluteCreditLimit = e.target.value
        setSelectedUser(obj)
        break

      default:
        setSelectedUser(obj)
        break
    }
  }

  function validateForm() {
    let sendData = selectedUser
    if (isNullUndefined(sendData)) {
      issueErrorMessage(
        "Could not find user data, please refresh the page and try again."
      )
      return false
    }
    if (isEmptyString(sendData.firstName) || isEmptyString(sendData.lastName)) {
      issueWarnMessage("First name and Last name are required.")
      return false
    }
    sendData.name = sendData.firstName.concat(" ").concat(sendData.lastName)
    if (
      isNullUndefined(sendData.mobilePhone) ||
      isEmptyString(sendData.mobilePhone)
    ) {
      issueWarnMessage("Mobile Phone number is required.")
      return false
    }
    if (
      isNullUndefined(sendData.idNumber) ||
      isEmptyString(sendData.idNumber) ||
      isNullUndefined(sendData.idTypeCd) ||
      isEmptyString(sendData.idTypeCd)
    ) {
      issueWarnMessage("Must provide and ID number and ID type.")
      return false
    }
    if (props.manageUsers) {
      if (isNullUndefined(sendData.email) || isEmptyString(sendData.email)) {
        issueWarnMessage("Must provide an email.")
        return false
      }

      if (
        isNullUndefined(sendData.roleCode) ||
        isEmptyString(sendData.roleCode)
      ) {
        issueWarnMessage("Must select a role to assign to this user.")
        return false
      }
    }
        if(props.organizationContact){
            if (isNullUndefined(sendData.email) || isEmptyString(sendData.email)) {
                issueWarnMessage("Must provide an email.")
                return false
            }
        }

    if (props.customer) {
      let creditLimit = tryParseFloat(sendData.absoluteCreditLimit, -1)
      // if (
      //   isNullUndefined(sendData.billingPeriod) ||
      //   isEmptyString(sendData.billingPeriod)
      // ) {
      //   issueWarnMessage("Must provide a billing Frequency.")
      //   return false
      // }

      if (creditLimit < 0) {
        issueWarnMessage("Must provide a valid credit Limit.")
        return false
      }
      sendData.absoluteCreditLimit = creditLimit

      // if (sendData.billingPeriod === "MNTH") {
      //   if (
      //     isNullUndefined(sendData.billingDay) ||
      //     isEmptyString(sendData.billingDay)
      //   ) {
      //     issueWarnMessage("Must provide a billing day.")
      //     return false
      //   }
      //   let billingDay = tryParseInt(sendData.billingDay, -1)
      //   if (billingDay < 0) {
      //     issueWarnMessage("Must provide a valid billing day.")
      //     return false
      //   }
      //   sendData.billingDay = billingDay
      // }

      // if (sendData.billingPeriod === "QT" || sendData.billingPeriod === "ANU") {
      //   if (
      //     isNullUndefined(sendData.billingDate) ||
      //     isEmptyString(sendData.billingDate)
      //   ) {
      //     issueWarnMessage("Must provide a billing date.")
      //     return false
      //   }
      //   let date = new Date(sendData.billingDate)
      //   if (!isValidDate(date)) {
      //     issueWarnMessage("Must provide a valid date.")
      //     return false
      //   }
      //   sendData.billingDate = date
      // }
    }
    setSelectedUser(sendData)
    return true
  }

  // function handleNextBillingDateChange(value) {
  //   let date = new Date(value)
  //   let obj = selectedUser
  //   obj.billingDate = date
  //   setSelectedUser(obj)
  //   setBillingDate(date)
  // }
  //
  // function handleBillingPeriodChange(event) {
  //   let value = event.target.value
  //   let obj = selectedUser
  //   obj.billingPeriod = value
  //   setSelectedUser(obj)
  // }
  //
  // function handleBillingDayChange(event) {
  //   let value = event.target.value
  //   let obj = selectedUser
  //   obj.billingDay = value
  //   setSelectedUser(obj)
  // }

  function closeModal() {
    props.closeModal()
    setSelectedUser(null)
    setSelectedRole("")
  }

  function handleSwitchToggleChanged(event, permission) {
    let obj = selectedUser
    //console.log("event.target.checked", event.target.checked)
    if (permission === "capital fund") {
      if (event.target.checked) {
        obj.permissions.push(permission)
        setApproveCapitalFunds(true)
      } else {
        obj.permissions.splice(obj.permissions.indexOf("capital fund"))
        setApproveCapitalFunds(false)
      }
    } else if (permission === "budget") {
      if (event.target.checked) {
        obj.permissions.push(permission)
        setApproveBudgets(true)
      } else {
        obj.permissions.splice(obj.permissions.indexOf("budget"))
        setApproveBudgets(false)
      }
    } else if (permission === "view only access") {
      if (event.target.checked) {
        obj.permissions.push(permission)
        setViewOnlyAccess(true)
      } else {
        obj.permissions.splice(obj.permissions.indexOf("view only access"))
        setViewOnlyAccess(false)
      }
    }
    setSelectedUser(obj)
  }

  // console.log("selectedUser permissions",selectedUser && selectedUser.permissions)

  return (
    <>
      <ModalContainerModule
        size="specialAddNewUser"
        accept={handleSaveUserClicked}
        cancel={() => props.closeModal()}
        openModal={props.openModal}
        modalTitle={
          !isNullUndefined(props.task) && props.task === "Edit"
            ? "Edit User"
            : "Add User"
        }
        acceptButtonText={
          !isNullUndefined(props.task) && props.task === "Edit"
            ? "Update"
            : "Create User"
        }
      >
        <Typography
          id="addUser"
          variant="h5"
          component="h6"
          sx={{ marginBottom: "20px" }}
        >
          User's Details
        </Typography>
        <ResponsiveRow
          container
          sx={{ height: "auto", margin: 0, paddingTop: 0 }}
        >
          <FormControl
            component={FormControl}
            sx={{
              width: { xs: "100%", lg: "33.3%" },
              marginBottom: "10px",
            }}
          >
            <InputLabel>First Name *</InputLabel>
            <OutlinedInput
              label="First Name"
              sx={{ width: { xs: "100%", md: "90%" } }}
              name={"firstName"}
              onChange={handleInputChange}
              value={selectedUser?.firstName ?? ""}
              inputProp={(params) => <TextField {...params} />}
            />
          </FormControl>
          <FormControl
            component={FormControl}
            sx={{
              width: { xs: "100%", lg: "33.3%" },
              marginBottom: "10px",
            }}
          >
            <InputLabel>Middle Name</InputLabel>
            <OutlinedInput
              label="Middle Name"
              sx={{ width: { xs: "100%", md: "90%" } }}
              name={"middleName"}
              onChange={handleInputChange}
              value={selectedUser?.middleName ?? ""}
              
            />
          </FormControl>
          <FormControl
            component={FormControl}
            sx={{
              width: { xs: "100%", lg: "33.3%" },
              marginBottom: "10px",
            }}
          >
            <InputLabel>Last Name *</InputLabel>
            <OutlinedInput
              label="last Name"
              sx={{ width: { xs: "100%", md: "90%" } }}
              name={"lastName"}
              onChange={handleInputChange}
              value={selectedUser?.lastName ?? ""}
              
            />
          </FormControl>

          <FormControl
            component={FormControl}
            sx={{
              width: { xs: "100%", lg: "33.3%" },
              marginBottom: "10px",
            }}
          >
            <InputLabel>Mobile Phone * (Primary No.)</InputLabel>
            <OutlinedInput
              label="Mobile Phone * (Primary No.)"
              sx={{ width: { xs: "100%", md: "90%" } }}
              name={"mobilePhone"}
              onChange={handleInputChange}
              value={
                selectedUser
                  ? selectedUser && selectedUser.mobilePhone
                  : mobilePhone
              }
              
            />
          </FormControl>

          <FormControl
            sx={{
              width: { xs: "100%", lg: "33.3%" },
              marginBottom: "10px",
            }}
          >
            <InputLabel>ID Type</InputLabel>
            <Select
              label="ID Type"
              sx={{ width: { xs: "100%", md: "90%" } }}
              name={"idType"}
              required={true}
                            value={
                                selectedUser
                                    ? selectedUser && selectedUser.idTypeCd
                                    : idTypeSearch
                            }
                            onChange={handleIdTypeChange}
                        >
                            {idTypes &&
                                idTypes.length > 0 ? (
                idTypes.map(function (idType, i) {
                  return (
                    <MenuItem key={idType.value} value={idType.value}>
                      {idType.label}
                    </MenuItem>
                  )
                }, this)
              ) : (
                <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
              )}
            </Select>
          </FormControl>

          <FormControl
            sx={{
              width: { xs: "100%", lg: "33.3%" },
              marginBottom: "10px",
            }}
          >
            <InputLabel>ID Number *</InputLabel>
            <OutlinedInput
              label="ID Number *"
              sx={{ width: { xs: "100%", md: "90%" } }}
              name={"idNo"}
              onChange={handleInputChange}
              value={
                selectedUser
                  ? selectedUser && selectedUser.idNumber
                  : idNoSearch
              }
              
            />
          </FormControl>

          <FormControl
            item
            sx={{
              width: { xs: "100%", lg: "33.3%" },
              marginBottom: "10px",
            }}
          >
            <InputLabel>Office Phone (Secondary No.)</InputLabel>
            <OutlinedInput
              label="Office Phone (Secondary No.)"
              sx={{ width: { xs: "100%", md: "90%" } }}
              name={"officePhone"}
              onChange={handleInputChange}
              value={
                selectedUser
                  ? selectedUser && selectedUser.officePhone
                  : officePhone
              }
            />
          </FormControl>

                    <FormControl
                        item
                        sx={{
                            width: {xs: "100%", lg: "33.3%"},
                            marginBottom: "10px",
                        }}
                    >
                        <InputLabel>Email</InputLabel>
                        <OutlinedInput
                            label="Email *"
              sx={{ width: { xs: "100%", md: "90%" } }}
              name={"email"}
              onChange={handleInputChange}
              value={selectedUser ? selectedUser && selectedUser.email : email}
              
            />
          </FormControl>
        </ResponsiveRow>
        <br />
        <Divider /> <br />
        {selectedUser &&
          !isNullUndefined(selectedUser.roleCode) &&
          selectedUser.roleCode === "OWN" && (
            <ResponsiveRow
              sx={{
                flexDirection: "column",
                width: "100%",
                height: "auto",
              }}
            >
              <Permit roles="BLDIR,BLDMG,REC,CS" services="HOA">
                <Typography
                  id="permissions"
                  variant="h6"
                  component="h6"
                  sx={{ marginBottom: "10px" }}
                >
                  Activate Permissions at this Property.
                </Typography>
              </Permit>
              <Alert severity="info">
                Activating permission will make an owner a committee member for
                respective granted permission. That is
                <span
                  style={{
                    padding: 5,
                    fontWeight: "bold",
                  }}
                >
                  Capital Fund Committee
                </span>
                or
                <span
                  style={{
                    padding: 5,
                    fontWeight: "bold",
                  }}
                >
                  Budget Committee
                </span>
                or
                <span
                  style={{
                    padding: 5,
                    fontWeight: "bold",
                  }}
                >
                  Both.
                </span>
              </Alert>
              <br />

              <ResponsiveRow container sx={{ height: "auto" }}>
                {/*<Grid item sx={{ width: { xs: "100%", lg: "33.3%" }, marginBottom: '10px' }}>*/}
                {/*    <InputLabel>View Only Access</InputLabel>*/}
                {/*    <Switch*/}
                {/*        checked={viewOnlyAccess}*/}
                {/*        onChange={(event) => handleSwitchToggleChanged(event, "view only access")}*/}
                {/*        inputProps={{'aria-label': 'controlled'}}*/}
                {/*    />*/}
                {/*</Grid>*/}

                <FormControl
                  item
                  component={FormControl}
                  sx={{
                    width: { xs: "100%", lg: "33.3%" },
                    marginBottom: "10px",
                  }}
                >
                  <FormLabel>Is a Capital Fund Committee Member</FormLabel>
                  <Switch
                    checked={approveCapitalFunds}
                    onChange={(event) =>
                      handleSwitchToggleChanged(event, "capital fund")
                    }
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </FormControl>

                <FormControl
                  item
                  component={FormControl}
                  sx={{
                    width: { xs: "100%", lg: "33.3%" },
                    marginBottom: "10px",
                  }}
                >
                  <FormLabel>Is a Budget Committee Member</FormLabel>
                  <Switch
                    checked={approveBudgets}
                    onChange={(event) =>
                      handleSwitchToggleChanged(event, "budget")
                    }
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </FormControl>
              </ResponsiveRow>
            </ResponsiveRow>
          )}
        {props.manageUsers && (
          <Typography id="selectRole" variant="h6" component="h6">
            Add Role
          </Typography>
        )}
        {props.manageUsers && (
          <Grid
            item
            component={FormControl}
            sx={{
              width: { xs: "100%", lg: "33.3%" },
              marginBottom: "10px",
            }}
          >
            <InputLabel>Role at this property</InputLabel>
            <Select
              defaultValue=""
              id="filterUser"
              value={
                selectedUser
                  ? selectedUser && selectedUser.roleCode
                  : selectedRole
              }
              sx={{
                width: { lg: "250px", md: "175px", xs: "100%" },
                height: 50,
              }}
              onChange={handleRoleChange}
            >
              {userRoles && userRoles.length > 0 ? (
                userRoles.map(function (role, i) {
                  return (
                    <MenuItem key={role.value} value={role.value}>
                      {role.label}
                    </MenuItem>
                  )
                }, this)
              ) : (
                <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
              )}
            </Select>
          </Grid>
        )}
        {props.customer && (
          <Typography id="selectRole" variant="h6" component="h6">
            Add Credit Limit
          </Typography>
        )}
        {props.customer && (
          <ResponsiveRow container sx={{ height: "auto" }}>
            <FormControl
              item
              sx={{
                width: { xs: "100%", lg: "33.3%" },
                marginBottom: "10px",
              }}
            >
              <InputLabel>Credit Limit *</InputLabel>
              <OutlinedInput
                label="Credit Limit *"
                sx={{ width: { xs: "100%", md: "90%" } }}
                name={"absoluteCreditLimit"}
                onChange={handleInputChange}
                value={selectedUser && selectedUser.absoluteCreditLimit}
                
              />
            </FormControl>

            {/*<Grid*/}
            {/*  item*/}
            {/*  component={FormControl}*/}
            {/*  sx={{*/}
            {/*    width: { xs: "100%", lg: "33.3%" },*/}
            {/*    marginBottom: "10px",*/}
            {/*  }}*/}
            {/*>*/}
            {/*  <InputLabel>Billing Frequency</InputLabel>*/}
            {/*  <Select*/}
            {/*    sx={{ width: { xs: "100%", md: "90%" } }}*/}
            {/*    id={"billingPeriod"}*/}
            {/*    value={selectedUser && selectedUser.billingPeriod}*/}
            {/*    onChange={(event) => handleBillingPeriodChange(event)}*/}
            {/*  >*/}
            {/*    {billingPeriods &&*/}
            {/*      billingPeriods.length > 0 &&*/}
            {/*      billingPeriods.map((billingPeriod, i) => {*/}
            {/*        return (*/}
            {/*          <MenuItem*/}
            {/*            key={billingPeriod.value}*/}
            {/*            value={billingPeriod.value}*/}
            {/*          >*/}
            {/*            {billingPeriod.label}*/}
            {/*          </MenuItem>*/}
            {/*        )*/}
            {/*      }, this)}*/}
            {/*  </Select>*/}
            {/*</Grid>*/}

            {/*{selectedUser && selectedUser.billingPeriod === "MNTH" && (*/}
            {/*  <Grid*/}
            {/*    item*/}
            {/*    component={FormControl}*/}
            {/*    sx={{*/}
            {/*      width: { xs: "100%", lg: "33.3%" },*/}
            {/*      marginBottom: "10px",*/}
            {/*    }}*/}
            {/*  >*/}
            {/*    <InputLabel>Billing Day</InputLabel>*/}
            {/*    <Select*/}
            {/*      sx={{ width: "100%" }}*/}
            {/*      name={"billingDay"}*/}
            {/*      value={selectedUser && selectedUser.billingDay}*/}
            {/*      onChange={(event) => handleBillingDayChange(event)}*/}
            {/*      defaultValue={[""]}*/}
            {/*    >*/}
            {/*      {rentDays &&*/}
            {/*        rentDays.length > 0 &&*/}
            {/*        rentDays.map(function (weekDay, i) {*/}
            {/*          return (*/}
            {/*            <MenuItem key={weekDay.value} value={weekDay.value}>*/}
            {/*              {weekDay.label}*/}
            {/*            </MenuItem>*/}
            {/*          )*/}
            {/*        }, this)}*/}
            {/*    </Select>*/}
            {/*  </Grid>*/}
            {/*)}*/}

            {/*{selectedUser &&*/}
            {/*  (selectedUser.billingPeriod === "ANU" ||*/}
            {/*    selectedUser.billingPeriod === "QT") && (*/}
            {/*    <Grid item component={FormControl} md={4} xs={12}>*/}
            {/*      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">*/}
            {/*        <DatePicker*/}
            {/*          label="Next Billing Date"*/}
            {/*          sx={{ width: { xs: "100%", md: "90%" } }}*/}
            {/*          name={"nextBillingDate"}*/}
            {/*          value={*/}
            {/*            selectedUser*/}
            {/*              ? selectedUser && dayjs(selectedUser.billingDate)*/}
            {/*              : dayjs(billingDate)*/}
            {/*          }*/}
            {/*          onChange={(newDate) =>*/}
            {/*            handleNextBillingDateChange(newDate)*/}
            {/*          }*/}
            {/*          */}
            {/*        />*/}
            {/*      </LocalizationProvider>*/}
            {/*    </Grid>*/}
            {/*  )}*/}
          </ResponsiveRow>
        )}
      </ModalContainerModule>
    </>
  )
}
