import { serviceAPI } from './service';
import {
	ACC_CAT_URL,
	ACC_SUB_CAT_URL,
	ACT_COMP_ORG_URL,
	ACT_LES_URL,
	ALL_ACCS_URL,
	ALL_UNIT_URL,
	ASS_URL,
	CHG_ACC_URL,
	CHG_CD_URL,
	COMPLEX_OPT_URL,
	COUNTRY_URL,
	CRED_ACC_URL,
	CSH_PMT_ACC_URL,
	DOW_URL,
	EMAIL_REPORT_PERIODS_URL,
	EX_ACC_URL,
	EX_ACC_VEN_URL,
	FAC_DET_URL,
	FAC_OPT_URL,
	ID_TYPE_URL,
	IN_ACC_URL,
	INSPECTION_AREAS_URL,
	INSPECTION_TYPES_URL,
	LDG_ACC_BANK_URL,
	LIST_ADMIN_PERSONS_URL,
	MAIN_SUB_SER_URL,
	MT_BILL_CHG_CD_URL,
	MT_BY_FAC_URL,
	PAY_MTH_URL,
	PRIORITY_URL,
	PROXY_FAC_FOR_USER_OPT_URL,
	PROXY_FAC_OPT_URL,
	RATE_TYPE_URL,
	RECOMMENDATIONS_URL,
	REN_PRD_URL,
	RENT_DAY_URL,
	SIGNUP_USERS_URL,
	SUB_BILL_DAY_URL,
	SUB_BILL_FQ_URL,
	SUB_SER_URL,
	SUBSCRIPTION_BANK_ACCOUNTS,
	SUBSCRIPTION_PRD_URL,
	TRF_PRD_URL,
	UNT_OPT_URL,
	USR_ROLES_URL,
	VAT_ACC_URL,
	VEN_URL,
	WITHHOLDING_TAX_ACC_URL,
	WO_FOR_QUOTATION_URL,
} from '../constants/url.constants';
import { handleErrorResponse } from '../actions/message';
import { isEmptyString } from '../util/util';

/**
 * Generic get to the list controller to obtain  a list for Selects
 * @param url
 * @returns {Promise<AxiosResponse<any>>}
 */
export const fetchOptionsGet = (url) => {
	return serviceAPI
		.get(url)
		.then((response) => {
			if (response.status === 200) {
				return response.data;
			} else {
				handleErrorResponse(response);
			}
		})
		.catch((error) => {
			handleErrorResponse(error);
		});
};

//GET REQUEST
export const getPaymentVoucherMethods = () => {
	return fetchOptionsGet(PAY_MTH_URL);
};

export const getCashPaymentAccounts = () => {
	return fetchOptionsGet(CSH_PMT_ACC_URL);
};

export const getIncomeAccounts = () => {
	return fetchOptionsGet(IN_ACC_URL);
};

export const getExpenseAccounts = () => {
	return fetchOptionsGet(EX_ACC_URL);
};

export const getUnitOptions = (facilityId) => {
	let url = UNT_OPT_URL + facilityId;
	return fetchOptionsGet(url);
};

export const getFacilityDetails = (subscriptionId) => {
	let url = FAC_DET_URL + subscriptionId;
	return fetchOptionsGet(url);
};

export const allUnitOptions = (facilityId) => {
	let url = ALL_UNIT_URL + facilityId;
	return fetchOptionsGet(url);
};

export const getVendorOptions = () => {
	return fetchOptionsGet(VEN_URL);
};

export const getSignUpUserOptions = () => {
	return fetchOptionsGet(SIGNUP_USERS_URL);
};

export const getProxyFacilityOptions = () => {
	return fetchOptionsGet(PROXY_FAC_OPT_URL);
};

export const getProxyFacilityForUserOptions = () => {
	return fetchOptionsGet(PROXY_FAC_FOR_USER_OPT_URL);
};

export const getAssetOptions = () => {
	return fetchOptionsGet(ASS_URL);
};

export const getSubscriptionBankAccounts = (subscriptionId = '') => {
	let url = '';
	if (isEmptyString(subscriptionId)) {
		url = SUBSCRIPTION_BANK_ACCOUNTS;
	} else {
		url = SUBSCRIPTION_BANK_ACCOUNTS + '?subscriptionId=' + subscriptionId;
	}
	return fetchOptionsGet(url);
};

export const getActiveComplexForOrgOptions = () => {
	return fetchOptionsGet(ACT_COMP_ORG_URL);
};

export const getPriorityOptions = () => {
	return fetchOptionsGet(PRIORITY_URL);
};

export const getPersonsWithAdminRoles = () => {
	return fetchOptionsGet(LIST_ADMIN_PERSONS_URL);
};

export const getVendorExpenseAccountOptions = (vendorId) => {
	let url = EX_ACC_VEN_URL + vendorId;
	return fetchOptionsGet(url);
};

/**
 * This is called for the unified credit modal and lists all credit
 * accounts based on the current facility's subscription.
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getCreditAccounts = () => {
	return fetchOptionsGet(CRED_ACC_URL);
};

export const getWorkOrdersForQuotation = () => {
	return fetchOptionsGet(WO_FOR_QUOTATION_URL);
};

export const getChargeAccounts = () => {
	return fetchOptionsGet(CHG_ACC_URL);
};

export const getAllAccounts = () => {
	return fetchOptionsGet(ALL_ACCS_URL);
};

export const getAllAccountCategories = () => {
	return fetchOptionsGet(ACC_CAT_URL);
};

export const getAllAccountSubCategories = (accCategoryCd) => {
	return fetchOptionsGet(
		ACC_SUB_CAT_URL + '?accountCategoryCd=' + accCategoryCd
	);
};

export const getVATAccount = () => {
	return fetchOptionsGet(VAT_ACC_URL);
};

export const getWithholdingTaxAccount = () => {
	return fetchOptionsGet(WITHHOLDING_TAX_ACC_URL);
};

export const getRentDays = () => {
	return fetchOptionsGet(RENT_DAY_URL);
};

export const getSubscriptionBillDays = () => {
	return fetchOptionsGet(SUB_BILL_DAY_URL);
};

export const getSubscriptionBillFrequency = () => {
	return fetchOptionsGet(SUB_BILL_FQ_URL);
};

export const getTransferPeriods = () => {
	return fetchOptionsGet(TRF_PRD_URL);
};

export const getDaysOfWeek = () => {
	return fetchOptionsGet(DOW_URL);
};

export const getEmailReportPeriods = () => {
	return fetchOptionsGet(EMAIL_REPORT_PERIODS_URL);
};

export const getRoles = () => {
	return fetchOptionsGet(USR_ROLES_URL);
};

export const getIdTypes = () => {
	return fetchOptionsGet(ID_TYPE_URL);
};
export const getSubscriptionPeriods = () => {
	return fetchOptionsGet(SUBSCRIPTION_PRD_URL);
};
export const getRentPeriods = () => {
	return fetchOptionsGet(REN_PRD_URL);
};

export const getChargeCodes = () => {
	return fetchOptionsGet(CHG_CD_URL);
};

export const getMetersByFacility = () => {
	return fetchOptionsGet(MT_BY_FAC_URL);
};

export const getMeterBillingChargeCodes = () => {
	return fetchOptionsGet(MT_BILL_CHG_CD_URL);
};

export const getFacilityOptions = () => {
	return fetchOptionsGet(FAC_OPT_URL);
};


export const ledgerAccForBank = (bankAccountId) => {
	let url = LDG_ACC_BANK_URL + bankAccountId;
	return serviceAPI
		.get(url)
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			handleErrorResponse(error);
		});
};

export const getComplexOptions = () => {
	return fetchOptionsGet(COMPLEX_OPT_URL);
};

export const getAllUnitOptions = (facilityId) => {
	let url = ALL_UNIT_URL + facilityId;
	return fetchOptionsGet(url);
};

export const getRateType = () => {
	return fetchOptionsGet(RATE_TYPE_URL);
};

export const getCountries = () => {
	return fetchOptionsGet(COUNTRY_URL);
};

export const getActiveLeases = (facilityId) => {
	return fetchOptionsGet(ACT_LES_URL + facilityId);
};

export const getInspectionTypes = () => {
	return fetchOptionsGet(INSPECTION_TYPES_URL);
};

export const getRecommendations = () => {
	return fetchOptionsGet(RECOMMENDATIONS_URL);
};

export const getInspectionAreas = () => {
	return fetchOptionsGet(INSPECTION_AREAS_URL);
};

export const getAllSubscriptionServices = () => {
	return fetchOptionsGet(SUB_SER_URL);
};

export const getMainSubscriptionServices = () => {
	return fetchOptionsGet(MAIN_SUB_SER_URL);
};
