/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useMemo, useState} from "react";
import {
    deleteAsset,
    getAssetCategory,
    getAssets,
    getDepreciationType,
    getMaintenanceAccount,
    getMaintenancePeriods,
} from "../services/assets.service";
import {MainContainer, TopBarContainedBrandButton, TopBarContainer,} from "../constants/component.constants";
import {GridActionsCellItem} from "@mui/x-data-grid";
import {Cancel, CloudUpload, Delete, Edit, Visibility,} from "@mui/icons-material";
import {Button, MenuItem, Select, Switch, TableCell, TableRow, TextField,} from "@mui/material";
import {getComplexOptions, getFacilityOptions, getIncomeAccounts, getRentDays,} from "../services/list.service";
import PersonOrgSearch from "../components/modals/personOrgSearch";
import AddNewUser from "../components/modals/addNewUser";
import AddNewOrganisation from "../components/modals/addNewOrganisation";
import {issueResponseMessage, issueWarnMessage} from "../actions/message";
import {getAssetMeterTypes, getMeterReadingPeriods,} from "../services/meters.service";
import {ResponsiveRow} from "../constants/layout.constants";
import Inspections from "../components/modals/inspections";
import ViewInspections from "../components/modals/viewInspections";
import AddAssetModal from "../components/modals/maintenance/assets/addAssetModal";
import DeleteAssetModal from "../components/modals/maintenance/assets/deleteAssetModal";
import Permit from "../util/permit";
import {listAssetInspection} from "../services/inspections.service";
import MuiDataGrid from "../components/modules/MuiDataGrid";

export default function Assets() {
    const [loading, setLoading] = useState(false);
    const [AddDiv, setAddDiv] = useState(false);
    const [ViewDiv] = useState(false);
    const [EditDiv, setEditDiv] = useState(false);
    const [InspectModal, setInspectModal] = useState(false);
    const [ViewModal, setViewModal] = useState(false);
    const [DeleteModal, setDeleteModal] = useState(false);
    const [AddOrganizationModal, setAddOrganizationModal] = useState(false);
    const [AddUserModal, setAddUserModal] = useState(false);
    const [CustomerModal, setCustomerModal] = useState(false);
    const [proRatedSwitch] = useState(false);
    const [meteredSwitch] = useState(false);
    const [maintenanceSwitch] = useState(false);
    const [incomeSwitch] = useState(false);
    const [assets, setAssets] = useState([]);
    const [assetSearch, setAssetSearch] = useState("");
    const [ownerSearch, setOwnerSearch] = useState("");
    const [idType, setIdType] = useState("");
    const [idNo, setIdNo] = useState("");
    const [companySearch, setCompanySearch] = useState("");
    const [items, setItems] = useState([]);
    const [meterItems, setMeterItems] = useState([]);
    const [tieredItemsTableRows, setTieredItemsTableRows] = useState("");
    const [task, setTask] = useState("");
    const [selectedAsset, setSelectedAsset] = useState(null);
    const [incomeAccounts, setIncomeAccounts] = useState([]);
    const [assetAccountOptions, setAssetAccountOptions] = useState([]);
    const [facilityOptions, setFacilityOptions] = useState([]);
    const [complexOptions, setComplexOptions] = useState([]);
    const [meterTypes, setMeterTypes] = useState([]);
    const [meterReadingPeriods, setMeterReadingPeriods] = useState([]);
    const [depreciationTypeOptions, setDepreciationTypeOptions] = useState("");
    const [maintenanceAccountOptions, setMaintenanceAccountOptions] = useState([]);
    const [maintenancePeriodOptions, setMaintenancePeriodOptions] = useState([]);
    const [rentDays, setRentDays] = useState([]);
    const [inspectionRecords, setInspectionRecords] = useState([]);

    const handleAssetModalClose = () => {
        setCustomerModal(false);
        setAddDiv(false);
        setEditDiv(false);
        setSelectedAsset(null);
        setTask("");
    };

    const reloadAssets = () => {
        setLoading(true);
        getAssets(assetSearch, ownerSearch)
            .then((assetsData) => {
                setAssets(assetsData);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        setLoading(true);
        getAssets(assetSearch, ownerSearch)
            .then((assetsData) => {
                if (assetsData.length > 0) {
                    setAssets(assetsData);
                }
            })
            .finally(() => {
                setLoading(false);
            });
        getAssetCategory().then((assetAccountOptions) =>
            setAssetAccountOptions(assetAccountOptions)
        );
        getFacilityOptions().then((facilityOptions) =>
            setFacilityOptions(facilityOptions)
        );
        getComplexOptions().then((complexOptions) =>
            setComplexOptions(complexOptions)
        );
        getDepreciationType().then((depreciationTypeOptions) =>
            setDepreciationTypeOptions(depreciationTypeOptions)
        );
        getMaintenanceAccount().then((maintenanceAccountOptions) =>
            setMaintenanceAccountOptions(maintenanceAccountOptions)
        );
        getMaintenancePeriods().then((maintenancePeriodOptions) =>
            setMaintenancePeriodOptions(maintenancePeriodOptions)
        );
        getIncomeAccounts().then((incomeAccounts) =>
            setIncomeAccounts(incomeAccounts)
        );
        getRentDays().then((rentDays) => setRentDays(rentDays));
        getMeterReadingPeriods().then((meterReadingPeriods) =>
            setMeterReadingPeriods(meterReadingPeriods)
        );
        getAssetMeterTypes().then((meterTypes) => setMeterTypes(meterTypes));
    }, [assetSearch, ownerSearch]);

    useEffect(() => {
        populateTieredItems();
    }, [meterItems]);

    function onTextChange(searchText) {
        const id = searchText.target.id;
        const value = searchText.target.value;
        if (id === "searchAssetOwner") {
            setOwnerSearch(value);
        }
        if (id === "searchAsset") {
            setAssetSearch(value);
        }
    }

    function meterItemNameChange(event, index) {
        const value = event.target.value;
        let newArr = [...items]; // copying the old items array
        newArr[index].name = value;
        setItems(newArr);
        populateTieredItems();
    }

    function handleMeterTypeChange(event, index) {
        let value = event.target.value;
        let newArr = [...items]; // copying the old items array
        newArr[index].meterTypeCode = value;
        setItems(newArr);
        populateTieredItems();
    }

    function handleMeterFreqChange(event, index) {
        let value = event.target.value;
        let newArr = [...items]; // copying the old items array
        newArr[index].readingFrequency = value;
        setItems(newArr);
        populateTieredItems();
    }

    function handleInvoicedChange(event, index) {
        // let value = event.target.value
        let newArr = [...items]; // copying the old items array
        newArr[index].invoiced = !newArr[index].invoiced;
        setItems(newArr);
        populateTieredItems();
    }


    const handlePersonOrgClose = () => {
        setCustomerModal(false);
    }


    function handlePersonOrgSelect(result) {
        let obj = selectedAsset;
        obj.owner = result;
        setSelectedAsset(obj);
        setCustomerModal(false);
    }

    function showAddNewUserForm(idNoSearch, idTypeSearch) {
        setIdType(idTypeSearch);
        setIdNo(idNoSearch);
        setCustomerModal(false);
        setAddUserModal(true);
    }

    function showAddNewOrgForm(companySearch) {
        setCompanySearch(companySearch);
        setCustomerModal(false);
        setAddOrganizationModal(true);
    }

    function handleAddUserClose() {
        setAddUserModal(false);
    }

    function handleAddOrgClose() {
        setAddOrganizationModal(false);
    }

    function confirmDelete() {
        deleteAsset(selectedAsset).then((response) => {
            issueResponseMessage(response);
            getAssets(assetSearch, ownerSearch).then((assetsData) =>
                setAssets(assetsData)
            );
            setSelectedAsset(null);
            setDeleteModal(false);
        });
    }

    function handleAddClick() {
        let data = {
            id: null,
            owner: null,
            facilityId: null,
            complexId: null,
            maintenanceDate: null,
            maintenanceDay: null,
            dateAcquired: "",
            maintenancePeriodCd: null,
            maintenanceAccountId: null,
            incomeAccountId: null,
            description: null,
            name: "",
            manufacturer: "",
            model: "",
            serialNumber: "",
            categoryCode: "",
            depreciationType: "",
            depreciationRate: "",
            prorated: proRatedSwitch,
            metered: meteredSwitch,
            maintenance: maintenanceSwitch,
            income: incomeSwitch,
            purchasePrice: "",
            currentValue: "",
            meterItems: [],
        };
        setSelectedAsset(data);
        setAddDiv(true);
    }

    function handleEditClick(selectedAsset) {
        setSelectedAsset(selectedAsset);
        setAddDiv(true);
        setEditDiv(true);
    }

    function handleDeleteClick(selectedAsset) {
        setSelectedAsset(selectedAsset);
        setDeleteModal(true);
    }

    function handleTieredItemAdd() {
        setItems((items) => [
            ...items,
            {
                name: null,
                meterTypeCode: null,
                readingFrequency: null,
                invoiced: false,
                rate: null,
                standingCharge: null,
            },
        ]);
        setMeterItems((meterItems) => [
            ...meterItems,
            {
                name: null,
                meterTypeCode: null,
                readingFrequency: null,
                invoiced: false,
                rate: null,
                standingCharge: null,
            },
        ]);
    }

    function populateTieredItems() {
        let rows = "";
        items &&
        items.length > 0 &&
        (rows = items.map(function (meterItem, i) {
            return (
                <TableRow key={i}>
                    <TableCell>
                        <TextField
                            sx={{width: "90%"}}
                            id={"chargeItemMemo" + i}
                            name={"chargeItemMemo" + i}
                            isrequired={true}
                            value={items && items[i] && items[i].name}
                            onChange={(event) => meterItemNameChange(event, i)}
                        />
                    </TableCell>
                    <TableCell>
                        <Select
                            sx={{width: "90%"}}
                            id={"type"}
                            value={items && items[i] && items[i].meterTypeCode}
                            onChange={(event) => handleMeterTypeChange(event, i)}
                        >
                            {meterTypes && meterTypes.length > 0 ? (
                                meterTypes.map(function (meter, i) {
                                    return (
                                        <MenuItem key={meter.value} value={meter.value}>
                                            {meter.label}
                                        </MenuItem>
                                    );
                                }, this)
                            ) : (
                                <MenuItem sx={{width: "100%"}}>
                                    No Results Found
                                </MenuItem>
                            )}
                        </Select>
                    </TableCell>
                    <TableCell>
                        <Select
                            sx={{width: "90%"}}
                            id={"readingPeriod"}
                            value={items && items[i] && items[i].readingFrequency}
                            onChange={(event) => handleMeterFreqChange(event, i)}
                        >
                            {meterReadingPeriods && meterReadingPeriods.length > 0 ? (
                                meterReadingPeriods.map(function (readingPeriod, i) {
                                    return (
                                        <MenuItem
                                            key={readingPeriod.value}
                                            value={readingPeriod.value}
                                        >
                                            {readingPeriod.label}
                                        </MenuItem>
                                    );
                                }, this)
                            ) : (
                                <MenuItem sx={{width: "100%"}}>
                                    No Results Found
                                </MenuItem>
                            )}
                        </Select>
                    </TableCell>
                    <TableCell>
                        <Switch
                            checked={items && items[i] && items[i].invoiced}
                            onChange={(event) => handleInvoicedChange(event, i)}
                            inputProps={{"aria-label": "controlled"}}
                        />
                    </TableCell>
                    <TableCell>
                        {items && items[i] && items[i].readingFrequency === "DAY" && (
                            <p>Today/Tomorrow</p>
                        )}
                        {items &&
                            items[i] &&
                            (items[i].readingFrequency === "MNTH" ||
                                items[i].readingFrequency === "WEEK") && <p>1</p>}
                    </TableCell>
                    <TableCell>
                        <Button
                            variant="contained"
                            type="submit"
                            sx={{marginLeft: "10px"}}
                            onClick={(event) => handleItemRemove(i)}
                            color="error"
                        >
                            <Cancel/>
                        </Button>
                    </TableCell>
                </TableRow>
            );
        }, this));
        setTieredItemsTableRows(rows);
    }

    function handleItemRemove(index) {
        let newArr = [...items]; // copying the old items array
        if (newArr.length > 1) {
            newArr.splice(index, 1);
            setItems(newArr);
            setMeterItems(items);
        } else {
            issueWarnMessage("There must be at least one credit item");
        }
    }

    function handleViewInspectionsClick(asset) {
        setSelectedAsset(asset);
        setViewModal(true);
        listAssetInspection(asset.id, new Date()).then((inspectionRecords) =>
            setInspectionRecords(inspectionRecords)
        );
    }

    function handleInspectAssetClick(asset) {
        setSelectedAsset(asset);
        setInspectModal(true);
        setTask("Inspect");
    }

    const columns = useMemo(() => [
        {headerName: "Name", field: "name", width: 200},
        {headerName: "Category", field: "categoryName", minWidth: 200, flex: 1},
        {
            headerName: "Owner",
            field: "owner",
            minWidth: 200,
            flex: 1,
            valueFormatter: (params) => {
                if (params.value !== undefined) {
                    return params.value.name;
                } else {
                    return "None Recorded";
                }
            },
        },
        {headerName: "Metered", field: "metered", minWidth: 200, flex: 1},
        {headerName: "Maintenance", field: "maintenance", minWidth: 200, flex: 1},
        {headerName: "Income", field: "income", minWidth: 200, flex: 1},
        {
            headerName: "Actions",
            field: "actions",
            minWidth: 150,
            flex: 0.75,
            type: "actions",
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<Edit/>}
                    label="Edit Asset Information"
                    onClick={() => handleEditClick(params.row)}
                    showInMenu
                />,
                <GridActionsCellItem
                    icon={<CloudUpload/>}
                    label="Add Inspection Record"
                    onClick={() => handleInspectAssetClick(params.row)}
                    showInMenu
                />,
                <GridActionsCellItem
                    icon={<Visibility/>}
                    label="View Inspection Records"
                    onClick={() => handleViewInspectionsClick(params.row)}
                    showInMenu
                />,
                <GridActionsCellItem
                    icon={<Delete/>}
                    label="Delete Asset"
                    onClick={() => handleDeleteClick(params.row)}
                    showInMenu
                />,
            ],
        },
    ]);

    return (
        <Permit
            roles="BLDIR"
            noPermittedServiceMessage={{
                title: "Access Denied",
                body: (
                    <>
                        Your current role is not permitted to view this page. <br/> Please
                        contact your system admin if you feel this is an error.
                    </>
                ),
            }}
        >
            <MainContainer>
                {!ViewDiv && (
                    <TopBarContainer
                        container
                        sx={{
                            width: {xs: "100%", lg: "80%"},
                        }}
                    >
                        <ResponsiveRow
                            sx={{
                                flexWrap: {xs: "wrap", lg: "nowrap"},
                                width: {xs: "100%", lg: "50%"},
                                padding: 0,
                            }}
                            item
                        >
                            <ResponsiveRow
                                sx={{
                                    width: {xs: "100%", lg: "80%"},
                                    flexWrap: {xs: "wrap", lg: "nowrap"},
                                    justifyContent: {xs: "center", lg: "flex-start"},
                                    padding: 0,
                                }}
                            >
                                <TextField
                                    id="searchAsset"
                                    label="Search Assets"
                                    sx={{
                                        width: {xs: "100%", md: "auto"},
                                        fontSize: "10px",
                                        margin: "3px",
                                    }}
                                    onChange={(searchText) => onTextChange(searchText)}
                                />
                                <TextField
                                    id="searchAssetOwner"
                                    label="Search Asset Owner"
                                    sx={{
                                        width: {xs: "100%", md: "auto"},
                                        fontSize: "10px",
                                        margin: "3px",
                                    }}
                                    onChange={(searchText) => onTextChange(searchText)}
                                />
                            </ResponsiveRow>
                        </ResponsiveRow>

                        <ResponsiveRow
                            item
                            sx={{
                                width: {xs: "100%", lg: "50%"},
                                textAlign: {xs: "center", lg: "end"},
                                justifyContent: {xs: "center", lg: "flex-end"},
                                padding: 0,
                            }}
                        >
                            <TopBarContainedBrandButton
                                type="submit"
                                onClick={handleAddClick}
                                sx={{
                                    width: {xs: "100%", lg: "200px"},
                                }}
                            >
                                Add an Asset
                            </TopBarContainedBrandButton>
                        </ResponsiveRow>
                    </TopBarContainer>
                )}
                <br/>

                <AddAssetModal
                    handleTieredItemAdd={handleTieredItemAdd}
                    AddDiv={AddDiv}
                    EditDiv={EditDiv}
                    setAddDiv={setAddDiv}
                    setAssets={setAssets}
                    setEditDiv={setEditDiv}
                    handleAssetModalClose={handleAssetModalClose}
                    reloadAssets={reloadAssets}
                    selectedAsset={selectedAsset}
                    setCustomerModal={setCustomerModal}
                    assetAccountOptions={assetAccountOptions}
                    assetSearch={assetSearch}
                    complexOptions={complexOptions}
                    depreciationTypeOptions={depreciationTypeOptions}
                    incomeAccounts={incomeAccounts}
                    incomeSwitch={incomeSwitch}
                    facilityOptions={facilityOptions}
                    maintenanceAccountOptions={maintenanceAccountOptions}
                    maintenancePeriodOptions={maintenancePeriodOptions}
                    ownerSearch={ownerSearch}
                    rentDays={rentDays}
                    tieredItemsTableRows={tieredItemsTableRows}
                />

                {/* <Box sx={{ height: "50vh" }}>
          <StyledDataGrid
            rows={assets}
            columns={columns}
            pageSize={rowsPerPage}
            loading={loading}
            rowsPerPageOptions={[rowsPerPage]}
            onPageChange={(page) => loadPage(page)}
            checkboxSelection
            pagination
            paginationMode="server"
            disableSelectionOnClick
            autoHeight={!isEmptyArray(assets)}
            components={{
              LoadingOverlay: LinearProgress,
              NoRowsOverlay: EmptyOverlay,
            }}
          />
        </Box> */}

                <MuiDataGrid
                    dataGridColumns={columns}
                    dataGridRows={assets}
                    serverPagination={false}
                    loading={loading}
                    height="60vh"
                    totalRows={assets.length}
                />

                {/*PERSON/ORG SEARCH MODAL*/}
                <PersonOrgSearch
                    contractType={'asset'}
                    showPersonOrgModal={CustomerModal}
                    handlePersonOrgClose={handlePersonOrgClose}
                    handlePersonOrgSelect={handlePersonOrgSelect}
                    showAddNewUserForm={showAddNewUserForm}
                    showAddNewOrgForm={showAddNewOrgForm}
                />

                {/*ADD NEW USER MODAL*/}
                <AddNewUser
                    openModal={AddUserModal}
                    closeModal={handleAddUserClose}
                    searchedIdType={idType}
                    searchedIdNo={idNo}
                    saveUser={handlePersonOrgSelect}
                    manageUsers={false}
                />

                {/*ADD NEW ORGANISATION MODAL*/}
                <AddNewOrganisation
                    openModal={AddOrganizationModal}
                    saveOrg={handlePersonOrgSelect}
                    searchedName={companySearch}
                    handleSave={true}
                    closeModal={handleAddOrgClose}
                />

                {/*INSPECT MODAL*/}
                {task === "Inspect" && (
                    <Inspections
                        openModal={InspectModal}
                        closeModal={() => setInspectModal(false)}
                        contract={selectedAsset}
                        inspectionItem={"Asset"}
                    />
                )}

                {/*DELETE MODAL*/}
                <DeleteAssetModal
                    DeleteModal={DeleteModal}
                    setDeleteModal={setDeleteModal}
                    confirmDelete={confirmDelete}
                    selectedAsset={selectedAsset}
                />

                <ViewInspections
                    openModal={ViewModal}
                    closeModal={() => {
                        setViewModal(false);
                        setInspectionRecords([]);
                    }}
                    contract={selectedAsset}
                    inspectionItem={"Asset"}
                    inspectionRecords={inspectionRecords}
                />
            </MainContainer>
        </Permit>
    );
}
