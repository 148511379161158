import {DatePicker} from "@mui/lab"
import {FormControl, InputLabel, MenuItem, Select, TextField,} from "@mui/material"
import {LocalizationProvider} from "@mui/x-date-pickers"
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import {ResponsiveRow} from "../../../../constants/layout.constants"
import {isEmptyString} from "../../../../util/util"
import ModalContainerModule from "../../../modules/modalContainer"
import dayjs from "dayjs";

/**
 *
 * REQUIRED PROPS
 *
 * handleSaveTransferFreq
 * handleCloseTransferFreqForm
 * handleSubChanged
 *
 * daysOfTheWeek
 * details
 * nextTransferDate
 * rentDays
 *
 * mobileMoneySubsList
 * mobileMoneyFacilitiesList
 *
 * transferFrequencyForm
 * transferPeriodsList
 *
 *
 * selectedSub
 * selectedFacility
 * selectedFrequency
 * selectedRentDay
 * selectedDay
 *
 * setSelectedFacility
 * setSelectedFrequency
 * setNextTransferDate
 * setSelectedRentDay
 * setSelectedDay
 * setDetails
 *
 */

export default function TransferFrequencyModal(props) {
    return (
      <ModalContainerModule
        size="xs"
        accept={props.handleSaveTransferFreq}
        cancel={props.handleCloseTransferFreqForm}
        openModal={props.transferFrequencyForm}
        modalTitle="Edit Transfer Frequency"
        acceptButtonText="Save"
      >
        <ResponsiveRow sx={{ width: "100%", height: "auto" }}>
          <FormControl
            item
            sx={{ width: { xs: "100%", lg: "50%" }, marginBottom: "10px" }}
          >
            <InputLabel id="subscriberLabel">Subscriber</InputLabel>
            <Select
              sx={{ width: { xs: "100%", lg: "90%" } }}
              id={"subscriber"}
              label="Subscriber"
              value={props.selectedSub}
              onChange={(event) => {
                props.handleSubChanged(event);
              }}
            >
              {props.mobileMoneySubsList &&
              props.mobileMoneySubsList.length > 0 ? (
                props.mobileMoneySubsList.map(function (item, i) {
                  return (
                    <MenuItem key={i} value={item.value}>
                      {item.label}
                    </MenuItem>
                  );
                }, this)
              ) : (
                <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
              )}
            </Select>
          </FormControl>

          {props.selectedSub && (
            <FormControl
              item
              sx={{ width: { xs: "100%", lg: "50%" }, marginBottom: "10px" }}
            >
              <InputLabel id="facilityLabel">Facility</InputLabel>
              <Select
                sx={{ width: { xs: "100%", lg: "90%" } }}
                id={"facility"}
                label="Facility"
                value={props.selectedFacility}
                onChange={(event) => {
                  let value = event.target.value;
                  props.setSelectedFacility(value);
                  let obj = props.details;
                  obj.facilityId = value;
                  props.setDetails(obj);
                }}
              >
                {props.mobileMoneyFacilitiesList &&
                props.mobileMoneyFacilitiesList.length > 0 ? (
                  props.mobileMoneyFacilitiesList.map(function (item, i) {
                    return (
                      <MenuItem key={i} value={item.value}>
                        {item.label}
                      </MenuItem>
                    );
                  }, this)
                ) : (
                  <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
                )}
              </Select>
            </FormControl>
          )}

          <FormControl
            item
            sx={{ width: { xs: "100%", lg: "50%" }, marginBottom: "10px" }}
          >
            <InputLabel id="transferFrequencyLabel">
              Transfer Frequency
            </InputLabel>
            <Select
              sx={{ width: { xs: "100%", lg: "90%" } }}
              id={"transferFreq"}
              label="Transfer Frequency"
              value={props.selectedFrequency}
              onChange={(event) => {
                let value = event.target.value;
                props.setSelectedFrequency(value);
                let obj = props.props.details;
                obj.transferPeriod = value;
                props.setDetails(obj);
              }}
            >
              {props.transferPeriodsList &&
              props.transferPeriodsList.length > 0 ? (
                props.transferPeriodsList.map(function (item, i) {
                  return (
                    <MenuItem key={i} value={item.value}>
                      {item.label}
                    </MenuItem>
                  );
                }, this)
              ) : (
                <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
              )}
            </Select>
          </FormControl>

          {(props.selectedFrequency === "ANU" ||
            props.selectedFrequency === "QT") && (
            <FormControl xs={4} style={{ marginBottom: 10 }}>
              <InputLabel id="nextTransferDateLabel">
                Next Transfer Date
              </InputLabel>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale="en-gb"
              >
                <DatePicker
                  sx={{ width: "90%" }}
                  id={"nextTransferDate"}
                  value={
                    isEmptyString(props.nextTransferDate)
                      ? null
                      : dayjs(props.nextTransferDate)
                  }
                  isrequired={false}
                  onChange={(newDate) => {
                    // console.log("date Value ", newDate)
                    let date = new Date(newDate);
                    props.setNextTransferDate(date);
                    let obj = props.details;
                    obj.transferDate = date;
                    props.setDetails(obj);
                  }}

                />
              </LocalizationProvider>
            </FormControl>
          )}
          {props.selectedFrequency === "MNTH" && (
            <FormControl
              sx={{ width: { xs: "100%", lg: "50%" }, marginBottom: "10px" }}
            >
              <InputLabel id="TransferDayLabel">Transfer Day</InputLabel>
              <Select
                sx={{ width: { xs: "100%", lg: "90%" } }}
                id={"transferDay"}
                label="Transfer Day"
                value={props.selectedRentDay}
                onChange={(event) => {
                  let value = event.target.value;
                  props.setSelectedRentDay(value);
                  let obj = props.details;
                  obj.transferDay = value;
                  props.setDetails(obj);
                }}
              >
                {props.rentDays && props.rentDays.length > 0 ? (
                  props.rentDays.map(function (item, i) {
                    return (
                      <MenuItem key={i} value={item.value}>
                        {item.label}
                      </MenuItem>
                    );
                  }, this)
                ) : (
                  <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
                )}
              </Select>
            </FormControl>
          )}

          {(props.selectedFrequency === "WEEK" ||
            props.selectedFrequency === "BI") && (
            <FormControl
              item
              sx={{ width: { xs: "100%", lg: "50%" }, marginBottom: "10px" }}
            >
              <InputLabel id="transferDat2Label">Transfer Day</InputLabel>
              <Select
                sx={{ width: { xs: "100%", lg: "90%" } }}
                id={"transferDay"}
                label="Transfer Day"
                value={props.selectedDay}
                onChange={(event) => {
                  let value = event.target.value;
                  props.setSelectedDay(value);
                  let obj = props.details;
                  obj.transferDay = value;
                  props.setDetails(obj);
                }}
              >
                {props.daysOfTheWeek && props.daysOfTheWeek.length > 0 ? (
                  props.daysOfTheWeek.map(function (item, i) {
                    return (
                      <MenuItem key={i} value={item.value}>
                        {item.label}
                      </MenuItem>
                    );
                  }, this)
                ) : (
                  <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
                )}
              </Select>
            </FormControl>
          )}
        </ResponsiveRow>
      </ModalContainerModule>
    );
}
