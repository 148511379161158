/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import {
    Alert,
    Grid,
    InputLabel,
    Link,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";
import {ResponsiveRow} from "../../constants/layout.constants";
import {
    DetailsColumn,
    DetailsContainer,
    DetailsLabel,
    DetailsRow,
    DetailsText,
    ModalAcceptButton,
    ModalCancelButton,
} from "../../constants/component.constants";
import {useEffect, useState} from "react";
import {formatCurrency, isArray, isEmptyString, isNullUndefined, tryParseFloat,} from "../../util/util";
import {issueResponseMessage, issueWarnMessage} from "../../actions/message";
import ContractDetail from "../cards/contractDetail";
import {getServiceRate, sendVerificationCode, verifyCode,} from "../../services/signUp.service";
import {DoneRounded, WarningAmberRounded} from "@mui/icons-material";
import ModalContainerModule from "./modalContainer";

export default function SignOnReviewDetails(props) {
    const [person, setPerson] = useState(null);
    const [isPropertyManagedByOrganization, setIsPropertyManagedByOrganization] = useState(false)
    const [organization, setOrganization] = useState(null);
    const [propertyPaymentDetails, setPropertyPaymentDetails] = useState(null);
    const [subscriptionService, setSubscriptionService] = useState(null);
    const [units, setUnits] = useState([]);
    const [utilities, setUtilities] = useState([]);
    const [leases, setLeases] = useState([]);
    const [ownerships, setOwnerships] = useState([]);
    const [meterBillings, setMeterBillings] = useState([]);
    const [isLeaseFacility, setIsLeaseFacility] = useState(false);
    const [isMeterBillingFacility, setIsMeterBillingFacility] = useState(false);
    const [isHOAFacility, setIsHOAFacility] = useState(false);
    const [isCore, setIsCore] = useState(false);
    const [isLean, setIsLean] = useState(false);
    const [isPremium, setIsPremium] = useState(false);
    const [serviceRate, setServiceRate] = useState(0);
    const [utilitiesRate, setUtilitiesRate] = useState(0);
    const [totalRent, setTotalRent] = useState(0);
    const [totalStandingCharge, setTotalStandingCharge] = useState(0);
    const [subscriptionTableArrays, setSubscriptionTableArrays] = useState([]);
    const [subscriptionTableRows, setSubscriptionTableRows] = useState("");
    const [totalCost, setTotalCost] = useState(0);
    const [minimumCost] = useState(2000.0);
    const [verifyPhoneNumberModal, setVerifyPhoneNumberModal] = useState(false);
    const [confirmationCode, setConfirmationCode] = useState("");
    const [customers, setCustomers] = useState([]);
    const [isBulkWaterFacility, setIsBulkWaterFacility] = useState(false);
    const [isMaintenanceFacility, setIsMaintenanceFacility] = useState(false);

    useEffect(() => {//this is a fix for hiding terms of service section it should be removed once we have agreed to terms of service section
        let signUp = props.signUp
        signUp.buttonIndex = "4.2"
        signUp.signUpJson.agreeToTermsOfService = true
        props.saveSignUpData(signUp)
    }, [])

    useEffect(() => {
        if (
            !isNullUndefined(props) &&
            !isNullUndefined(props.signUp) &&
            !isNullUndefined(props.signUp.person)
        ) {
            setPerson(props.signUp.person);
        }

        if (
            !isNullUndefined(props) &&
            !isNullUndefined(props.signUp) &&
            !isNullUndefined(props.signUp.signUpJson) &&
            !isNullUndefined(props.signUp.signUpJson.organization)
        ) {
            setOrganization(props.signUp.signUpJson.organization);
        }

        if (
            !isNullUndefined(props) &&
            !isNullUndefined(props.signUp) &&
            !isNullUndefined(props.signUp.signUpJson) &&
            !isNullUndefined(props.signUp.signUpJson.isPropertyManagedByOrganization)
        ) {
            setIsPropertyManagedByOrganization(
                props.signUp.signUpJson.isPropertyManagedByOrganization
            );
        }

        if (
            !isNullUndefined(props) &&
            !isNullUndefined(props.signUp) &&
            !isNullUndefined(props.signUp.signUpJson) &&
            !isNullUndefined(props.signUp.signUpJson.subscriptionService)
        ) {
            setSubscriptionService(props.signUp.signUpJson.subscriptionService);
        }

        if (
            !isNullUndefined(props) &&
            !isNullUndefined(props.signUp) &&
            !isNullUndefined(props.signUp.signUpJson) &&
            !isNullUndefined(props.signUp.signUpJson.propertyPaymentDetails)
        ) {
            setPropertyPaymentDetails(props.signUp.signUpJson.propertyPaymentDetails);
        }

        if (
            !isNullUndefined(props) &&
            !isNullUndefined(props.signUp) &&
            !isNullUndefined(props.signUp.signUpJson) &&
            !isNullUndefined(props.signUp.signUpJson.facility) &&
            !isNullUndefined(props.signUp.signUpJson.facility.units) &&
            isArray(props.signUp.signUpJson.facility.units)
        ) {
            setUnits(props.signUp.signUpJson.facility.units);
        }

        if (
            !isNullUndefined(props) &&
            !isNullUndefined(props.signUp) &&
            !isNullUndefined(props.signUp.signUpJson) &&
            !isNullUndefined(props.signUp.signUpJson.facility) &&
            isArray(props.signUp.signUpJson.utilities)
        ) {
            setUtilities(props.signUp.signUpJson.utilities);
        }

        if (
            !isNullUndefined(props) &&
            !isNullUndefined(props.signUp) &&
            !isNullUndefined(props.signUp.signUpJson) &&
            !isNullUndefined(props.signUp.signUpJson.subscriptionService) &&
            !isNullUndefined(
                props.signUp.signUpJson.subscriptionService.serviceCode
            ) &&
            !isEmptyString(props.signUp.signUpJson.subscriptionService.serviceCode)
        ) {
            if (
                props.signUp.signUpJson.subscriptionService.serviceCode === "CORE" ||
                props.signUp.signUpJson.subscriptionService.serviceCode === "LEAN" ||
                props.signUp.signUpJson.subscriptionService.serviceCode === "PREMIUM"
            ) {
                setIsLeaseFacility(true);
                setIsHOAFacility(false);
                setIsMeterBillingFacility(false);
                setIsBulkWaterFacility(false);
                setIsMaintenanceFacility(false);
                //set leases
                if (
                    !isNullUndefined(props) &&
                    !isNullUndefined(props.signUp) &&
                    !isNullUndefined(props.signUp.signUpJson) &&
                    !isNullUndefined(props.signUp.signUpJson.leases) &&
                    isArray(props.signUp.signUpJson.leases)
                ) {
                    setLeases(props.signUp.signUpJson.leases);
                }
                if (
                    props.signUp.signUpJson.subscriptionService.serviceCode === "CORE"
                ) {
                    setIsCore(true);
                }
                if (
                    props.signUp.signUpJson.subscriptionService.serviceCode === "LEAN"
                ) {
                    setIsLean(true);
                }
                if (
                    props.signUp.signUpJson.subscriptionService.serviceCode === "PREMIUM"
                ) {
                    setIsPremium(true);
                }
            }

            if (props.signUp.signUpJson.subscriptionService.serviceCode === "METER") {
                setIsMeterBillingFacility(true);
                setIsLeaseFacility(false);
                setIsHOAFacility(false);
                setIsBulkWaterFacility(false);
                setIsMaintenanceFacility(false);
                //set meter billing
                if (
                    !isNullUndefined(props) &&
                    !isNullUndefined(props.signUp) &&
                    !isNullUndefined(props.signUp.signUpJson) &&
                    !isNullUndefined(props.signUp.signUpJson.meterBillings) &&
                    isArray(props.signUp.signUpJson.meterBillings)
                ) {
                    setMeterBillings(props.signUp.signUpJson.meterBillings);
                }
            }

            if (props.signUp.signUpJson.subscriptionService.serviceCode === "HOA") {
                setIsHOAFacility(true);
                setIsMeterBillingFacility(false);
                setIsLeaseFacility(false);
                setIsBulkWaterFacility(false);
                setIsMaintenanceFacility(false);
                //set ownerships
                if (
                    !isNullUndefined(props) &&
                    !isNullUndefined(props.signUp) &&
                    !isNullUndefined(props.signUp.signUpJson) &&
                    !isNullUndefined(props.signUp.signUpJson.ownerships) &&
                    isArray(props.signUp.signUpJson.ownerships)
                ) {
                    setOwnerships(props.signUp.signUpJson.ownerships);
                }
            }
            if (props.signUp.signUpJson.subscriptionService.serviceCode === "BLWTR") {
                setIsMeterBillingFacility(false);
                setIsLeaseFacility(false);
                setIsHOAFacility(false);
                setIsBulkWaterFacility(true);
                setIsMaintenanceFacility(false);
                //set meter billing
                if (
                    !isNullUndefined(props) &&
                    !isNullUndefined(props.signUp) &&
                    !isNullUndefined(props.signUp.signUpJson) &&
                    !isNullUndefined(props.signUp.signUpJson.customers) &&
                    isArray(props.signUp.signUpJson.customers)
                ) {
                    setCustomers(props.signUp.signUpJson.customers);
                }
            }

            if (props.signUp.signUpJson.subscriptionService.serviceCode === "MAINT") {
                setIsMeterBillingFacility(false);
                setIsLeaseFacility(false);
                setIsHOAFacility(false);
                setIsBulkWaterFacility(false);
                setIsMaintenanceFacility(true);
            }
        }
    }, [props.signUp]);

    useEffect(() => {
        let totalUnits = units.length;
        let totalUtilities = utilities.length;
        if (
            !isNullUndefined(subscriptionService) &&
            !isNullUndefined(subscriptionService.serviceCode)
        ) {
            if (subscriptionService.addMpesaSupport) {
                let rent = 0;
                let standingCharge = 0;
                units &&
                units.length > 0 &&
                units.forEach(function (unit, i) {
                    rent += tryParseFloat(unit.unitProperties.rent, 0);
                });
                utilities &&
                utilities.length > 0 &&
                utilities.forEach(function (utility, i) {
                    standingCharge =
                        standingCharge + tryParseFloat(utility.standingCharge, 0);
                });
                setTotalRent(rent);
                setTotalStandingCharge(standingCharge);
            }

            if ((isCore || isLean) && subscriptionService.addUtilitiesSupport) {
                getServiceRate("METER", totalUtilities).then((response) => {
                    setUtilitiesRate(response.serviceRate.unitPrice);
                });
            }

            if (isMeterBillingFacility || isBulkWaterFacility) {
                getServiceRate(subscriptionService.serviceCode, totalUtilities).then(
                    (response) => {
                        setServiceRate(response.serviceRate.unitPrice);
                    }
                );
            }

            if (isHOAFacility || isLeaseFacility || isMaintenanceFacility) {
                getServiceRate(subscriptionService.serviceCode, totalUnits).then(
                    (response) => {
                        setServiceRate(response.serviceRate.unitPrice);
                    }
                );
            }
        }
    }, [
        units,
        utilities,
        subscriptionService,
        isLeaseFacility,
        isMaintenanceFacility,
        isMeterBillingFacility,
        isHOAFacility,
        isLean,
        isCore,
        isPremium,
    ]);

    useEffect(() => {
        let subscriptionTableArray = [];
        let totalUnits = units.length;
        let totalUtilities = utilities.length;
        let total = 0;
        if (isLeaseFacility && isPremium) {
            total = total + totalUnits * serviceRate;
            let obj = {
                serviceCode: "Premium",
                totalUnits: totalUnits,
                serviceRate: serviceRate,
                amount: formatCurrency(totalUnits * serviceRate),
            };
            subscriptionTableArray.push(obj);
        }

        if (isLeaseFacility && isCore) {
            total = total + totalUnits * serviceRate;
            let obj = {
                serviceCode: "Core",
                totalUnits: totalUnits,
                serviceRate: serviceRate,
                amount: formatCurrency(totalUnits * serviceRate),
            };
            subscriptionTableArray.push(obj);
        }

        if (isLeaseFacility && isLean) {
            total = total + totalUnits * serviceRate;
            let obj = {
                serviceCode: "Lean",
                totalUnits: totalUnits,
                serviceRate: serviceRate,
                amount: formatCurrency(totalUnits * serviceRate),
            };
            subscriptionTableArray.push(obj);
        }

        if (isHOAFacility) {
            total = total + totalUnits * serviceRate;
            let obj = {
                serviceCode: "HOA",
                totalUnits: totalUnits,
                serviceRate: serviceRate,
                amount: formatCurrency(totalUnits * serviceRate),
            };
            subscriptionTableArray.push(obj);
        }

        if (isMaintenanceFacility) {
            total = total + totalUnits * serviceRate;
            let obj = {
                serviceCode: "Maintenance",
                totalUnits: totalUnits,
                serviceRate: serviceRate,
                amount: formatCurrency(totalUnits * serviceRate),
            };
            subscriptionTableArray.push(obj);
        }

        if (isMeterBillingFacility) {
            total = total + totalUtilities * serviceRate;
            let obj = {
                serviceCode: "Meter",
                totalUnits: totalUtilities,
                serviceRate: serviceRate,
                amount: formatCurrency(totalUtilities * serviceRate),
            };
            subscriptionTableArray.push(obj);
        }

        if (isBulkWaterFacility) {
            total = total + totalUtilities * serviceRate;
            let obj = {
                serviceCode: "Bulk Water",
                totalUnits: totalUtilities,
                serviceRate: serviceRate,
                amount: formatCurrency(totalUtilities * serviceRate),
            };
            subscriptionTableArray.push(obj);
        }

        if (
            isLeaseFacility &&
            (isCore || isLean) &&
            subscriptionService &&
            subscriptionService.addUtilitiesSupport
        ) {
            total = total + totalUtilities * utilitiesRate;
            let obj = {
                serviceCode: "Meter",
                totalUnits: totalUtilities,
                serviceRate: utilitiesRate,
                amount: formatCurrency(totalUtilities * utilitiesRate),
            };
            subscriptionTableArray.push(obj);
        }

        if (propertyPaymentDetails && propertyPaymentDetails.useYourPayBill) {
            let periodicSum = totalRent + totalStandingCharge;
            let value = formatCurrency(periodicSum);
            let percentageAmount = periodicSum / 100;
            total = total + percentageAmount;
            let obj = {
                serviceCode: "Mpesa 1",
                totalUnits: "≈ KSH " + value,
                serviceRate: "1%",
                amount: formatCurrency(percentageAmount.toFixed(2)),
            };
            subscriptionTableArray.push(obj);
        }

        if (propertyPaymentDetails && propertyPaymentDetails.useSmartKodiPayBill) {
            let periodicSum = totalRent + totalStandingCharge;
            let value = formatCurrency(periodicSum);
            let percentageAmount = (2 * periodicSum) / 100;
            total = total + percentageAmount;
            let obj = {
                serviceCode: "Mpesa 2",
                totalUnits: "≈ KSH " + value,
                serviceRate: "2%",
                amount: formatCurrency(percentageAmount.toFixed(2)),
            };
            subscriptionTableArray.push(obj);
        }
        setTotalCost(total.toFixed(2));
        setSubscriptionTableArrays(subscriptionTableArray);
    }, [serviceRate]);

    useEffect(() => {
        let rows =
            subscriptionTableArrays &&
            subscriptionTableArrays.length > 0 &&
            subscriptionTableArrays.map(function (subscription, i) {
                return (
                    <TableRow
                        key={i}
                        sx={{"&:last-child td, &:last-child th": {border: 0}}}
                    >
                        <TableCell component="th" scope="row">
                            <Typography>{subscription.serviceCode}</Typography>
                        </TableCell>
                        <TableCell align="left">
                            <Typography>{subscription.totalUnits}</Typography>
                        </TableCell>
                        <TableCell align="left">
                            <Typography>{subscription.serviceRate}</Typography>
                        </TableCell>
                        <TableCell align="left">
                            <Typography>{subscription.amount}</Typography>
                        </TableCell>
                    </TableRow>
                );
            });
        setSubscriptionTableRows(rows);
    }, [subscriptionTableArrays]);

    function handleEditClick(step) {
        let signUp;
        if (!isNullUndefined(props.signUp)) {
            signUp = props.signUp;
            signUp.buttonIndex = step;
        }
        if (isNullUndefined(signUp)) {
            issueWarnMessage(`Please fill in the form.`);
        } else {
            props.saveSignUpData(signUp);
        }
    }

    /* function onCheckBoxChange() {
       let signUp = props.signUp;
       let agreeToTermsOfService = props.signUp.signUpJson.agreeToTermsOfService;
       signUp.buttonIndex = "4.2";
       signUp.signUpJson.agreeToTermsOfService = !agreeToTermsOfService;
       props.saveSignUpData(signUp);
     }*/

    function getStarted() {
        let signUp;
        const buttonIndex = "5.1";
        signUp = props.signUp;
        signUp.buttonIndex = buttonIndex;
        if (isNullUndefined(signUp)) {
            issueWarnMessage(`Please fill in the form.`);
        } else {
            props.getStarted(signUp);
        }
    }

    function verifyPhoneNumber() {
        if (
            !isNullUndefined(confirmationCode) &&
            !isEmptyString(confirmationCode)
        ) {
            props.showLoader();
            let data = {
                confirmationCode: confirmationCode,
                personId: person.id,
            };
            verifyCode(data)
                .then((response) => {
                    issueResponseMessage(response);
                    if (response.type === "success") {
                        let signUp = response.signUp;
                        signUp.buttonIndex = "4.2";
                        props.saveSignUpData(signUp);
                        closeVerifyPhoneNumberModal();
                    }
                })
                .finally(() => {
                    props.hideLoader();
                });
        } else {
            issueWarnMessage(`Confirmation code is required.`);
        }
    }

    function openVerifyPhoneNumberModal() {
        props.showLoader();
        let data = {
            mobilePhone: person.mobilePhone,
            personId: person.id,
        };
        sendVerificationCode(data)
            .then((response) => {
                if (response.type === "success") {
                    setVerifyPhoneNumberModal(true);
                }
                issueResponseMessage(response);
            })
            .finally(() => {
                props.hideLoader();
            });
    }

    function closeVerifyPhoneNumberModal() {
        setVerifyPhoneNumberModal(false);
        setConfirmationCode("");
    }

    function sendCodeAgain() {
        let data = {
            mobilePhone: person.mobilePhone,
            personId: person.id,
        };
        sendVerificationCode(data).then((response) => {
            issueResponseMessage(response);
        });
    }

    return (
        <ResponsiveRow
            sx={{
                width: "100%",
                height: "auto",
                flexDirection: "column",
                flexWrap: "nowrap",
            }}
        >
            <ResponsiveRow
                sx={{
                    justifyContent: "flex-start",
                    alignItems: "center",
                    height: "auto",
                }}
            >
                <Typography variant="h3" sx={{fontWeight: 600, marginTop: "5px"}}>
                    Review Information
                </Typography>
            </ResponsiveRow>

            <Typography
                sx={{
                    fontSize: "1.15rem",
                    fontWeight: 600,
                    textAlign: "start",
                    width: "100%",
                    margin: "5px 10px 0 10px",
                }}
            >
                Subscription Information
            </Typography>

            <ResponsiveRow
                sx={{
                    width: "100%",
                    height: "auto",
                    justifyContent: "flex-start",
                    padding: 0,
                }}
            >
                <ResponsiveRow
                    sx={{
                        flexDirection: "column",
                        backgroundColor: "#efefef",
                        margin: "10px 5px",
                        padding: "10px",
                        height: "auto",
                        width: {xs: "100%", lg: "30%"},
                        borderRadius: "20px",
                        textAlign: "start",
                        flexWrap: "nowrap",
                    }}
                >
                    <Typography variant="h6" sx={{color: "#037171"}}>
                        Subscriber Details
                    </Typography>
                    <DetailsContainer
                        sx={{
                            padding: 0,
                            width: "100%",
                            textAlign: "start",
                        }}
                    >
                        <DetailsColumn sx={{width: "100%"}}>
                            {isPropertyManagedByOrganization && (
                                <DetailsRow sx={{width: "100%"}}>
                                    <DetailsLabel>Organization Name</DetailsLabel>
                                    <DetailsText sx={{fontWeight: 400}}>
                                        {organization && organization.name}
                                    </DetailsText>
                                </DetailsRow>
                            )}
                            {isPropertyManagedByOrganization && (
                                <DetailsRow sx={{width: "100%"}}>
                                    <DetailsLabel>Address</DetailsLabel>
                                    <DetailsText sx={{fontWeight: 400}}>
                                        {organization &&
                                            organization.address &&
                                            organization.address.address1}
                                    </DetailsText>
                                </DetailsRow>
                            )}

                            <DetailsRow sx={{width: "100%"}}>
                                <DetailsLabel>First Name</DetailsLabel>
                                <DetailsText sx={{fontWeight: 400}}>
                                    {person && person.firstName}
                                </DetailsText>
                            </DetailsRow>
                            <DetailsRow sx={{width: "100%"}}>
                                <DetailsLabel>Last Name</DetailsLabel>
                                <DetailsText sx={{fontWeight: 400}}>
                                    {person && person.lastName}
                                </DetailsText>
                            </DetailsRow>
                            <DetailsRow sx={{width: "100%"}}>
                                <DetailsLabel>Email</DetailsLabel>
                                <DetailsText sx={{fontWeight: 400}}>
                                    {person && person.email}
                                </DetailsText>
                            </DetailsRow>
                            <DetailsRow sx={{width: "100%"}}>
                                <DetailsLabel>Phone Number</DetailsLabel>

                                {props &&
                                props.signUp &&
                                props.signUp.signUpJson &&
                                props.signUp.signUpJson.contactVerification &&
                                props.signUp.signUpJson.contactVerification
                                    .isPhoneNumberVerified ? (
                                    <DoneRounded
                                        sx={{
                                            color: "#58bd1f",
                                            marginLeft: "1%",
                                            fontSize: "1.5rem",
                                        }}
                                    />
                                ) : (
                                    <WarningAmberRounded
                                        sx={{
                                            color: "#8d5428",
                                            marginLeft: "1%",
                                            fontSize: "1.5rem",
                                        }}
                                    />
                                )}

                                <DetailsText sx={{fontWeight: 400}}>
                                    {person && person.mobilePhone}
                                </DetailsText>
                            </DetailsRow>
                        </DetailsColumn>
                    </DetailsContainer>
                    {props &&
                        props.signUp &&
                        props.signUp.signUpJson &&
                        props.signUp.signUpJson.contactVerification &&
                        !props.signUp.signUpJson.contactVerification
                            .isPhoneNumberVerified && (
                            <ResponsiveRow
                                sx={{
                                    justifyContent: {xs: "center", lg: "center"},
                                    flexDirection: "column",
                                    height: "auto",
                                    width: "100%",
                                }}
                            >
                                <Alert severity="warning" sx={{width: "100%"}}>
                                    Phone number needs to be verified to make sure it's you before
                                    we get started. Clicking on the 'Verify Phone Number' button
                                    will send a 6-digit verification code via SMS to this number '
                                    {person && person.mobilePhone}' which will be valid for 24
                                    hours. It will also open the form to input the sent code.
                                </Alert>
                                <br/>
                                <ModalAcceptButton
                                    onClick={openVerifyPhoneNumberModal}
                                    sx={{width: {xs: "100%", lg: "100%"}, height: "auto"}}
                                >
                                    Verify Phone Number
                                </ModalAcceptButton>
                            </ResponsiveRow>
                        )}

                    <ResponsiveRow
                        sx={{
                            justifyContent: {xs: "center", lg: "flex-end"},
                            height: "auto",
                            width: "100%",
                        }}
                    >
                        <ModalAcceptButton
                            onClick={() => handleEditClick("0.1")}
                            sx={{height: "auto", width: {xs: "100%", lg: "200px"}}}
                        >
                            Edit
                        </ModalAcceptButton>
                    </ResponsiveRow>
                </ResponsiveRow>
                <ResponsiveRow
                    sx={{
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        backgroundColor: "#efefef",
                        margin: "10px",
                        padding: "10px",
                        height: "auto",
                        width: {xs: "100%", lg: "66.6%"},
                        borderRadius: "20px",
                        textAlign: "start",
                    }}
                >
                    <Typography variant="h6" sx={{color: "#037171"}}>
                        Subscription Details
                    </Typography>
                    <TableContainer>
                        <Table sx={{minWidth: 650}} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">Service Name</TableCell>
                                    <TableCell align="left">Number of Units</TableCell>
                                    <TableCell align="left">Rate</TableCell>
                                    <TableCell align="left">Amount KSH</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>{subscriptionTableRows}</TableBody>
                        </Table>
                    </TableContainer>

                    <ResponsiveRow
                        sx={{
                            justifyContent: {xs: "center", lg: "flex-end"},
                            height: "auto",
                        }}
                    >
                        {totalCost >= minimumCost && (
                            <Typography variant="h6" sx={{color: "#037171"}}>
                                Total: ≈ KSH {formatCurrency(totalCost)}
                            </Typography>
                        )}
                        {totalCost < minimumCost && (
                            <Typography variant="h6" sx={{color: "#037171"}}>
                                Total: ≈ KSH {formatCurrency(totalCost)} is below minimum which
                                will default to: KSH {formatCurrency(minimumCost)}
                            </Typography>
                        )}
                    </ResponsiveRow>

                    <ResponsiveRow
                        sx={{
                            justifyContent: {xs: "center", lg: "flex-end"},
                            height: "auto",
                        }}
                    >
                        <ModalAcceptButton
                            onClick={() => handleEditClick("1.1")}
                            sx={{height: "auto", width: {xs: "100%", lg: "200px"}}}
                        >
                            Edit
                        </ModalAcceptButton>
                    </ResponsiveRow>
                </ResponsiveRow>

                <ResponsiveRow
                    sx={{
                        width: "100%",
                        height: "auto",
                        flexWrap: {xs: "wrap", lg: "nowrap"},
                        flexDirection: {xs: "column", lg: "row"},
                        overflowX: {xs: "hidden", lg: "auto"},
                    }}
                >
                    {isLeaseFacility &&
                        leases &&
                        leases.length > 0 &&
                        leases.map(function (lease, i) {
                            return (
                                <ContractDetail
                                    key={i}
                                    handleEditClick={handleEditClick}
                                    contractType={"Lease"}
                                    contract={lease}
                                    units={units}
                                    utilities={utilities}
                                />
                            );
                        })}

                    {isMeterBillingFacility &&
                        meterBillings &&
                        meterBillings.length > 0 &&
                        meterBillings.map(function (meterBilling, i) {
                            return (
                                <ContractDetail
                                    key={i}
                                    handleEditClick={handleEditClick}
                                    contractType={"Meter Billing"}
                                    contract={meterBilling}
                                    units={units}
                                    utilities={utilities}
                                />
                            );
                        })}

                    {isHOAFacility &&
                        ownerships &&
                        ownerships.length > 0 &&
                        ownerships.map(function (ownership, i) {
                            return (
                                <ContractDetail
                                    key={i}
                                    handleEditClick={handleEditClick}
                                    contractType={"Ownership"}
                                    contract={ownership}
                                    units={units}
                                    utilities={utilities}
                                />
                            );
                        })}
                    {isBulkWaterFacility &&
                        customers &&
                        customers.length > 0 &&
                        customers.map(function (customer, i) {
                            return (
                                <ContractDetail
                                    key={i}
                                    handleEditClick={handleEditClick}
                                    contractType={"Customer"}
                                    contract={customer}
                                    units={units}
                                    utilities={utilities}
                                />
                            );
                        })}
                </ResponsiveRow>

                <ModalContainerModule
                    size="xs"
                    accept={verifyPhoneNumber}
                    cancel={closeVerifyPhoneNumberModal}
                    openModal={verifyPhoneNumberModal}
                    modalTitle={`Validate Phone Number ${
                        person && person.mobilePhone
                    } for ${person && person.firstName + " " + person.lastName}`}
                    acceptButtonText="Verify"
                >
                    <Alert severity="info">
                        Please enter the 6-digit verification code we sent via SMS: (we want
                        to make sure it's you before we get started). If you didn't receive
                        the code click on 'send code again' or 'Change phone number'.
                    </Alert>
                    <br/>
                    <ResponsiveRow container sx={{height: "auto"}}>
                        <Grid
                            item
                            sx={{width: {xs: "100%", lg: "33.3%"}, marginBottom: "10px"}}
                        >
                            <InputLabel>Confirmation Code *</InputLabel>
                            <TextField
                                sx={{width: {xs: "100%", md: "90%"}}}
                                id={"confirmationCode"}
                                onChange={(event) => {
                                    setConfirmationCode(event.target.value);
                                }}
                                value={confirmationCode}
                            />
                        </Grid>
                        <Grid
                            item
                            sx={{width: {xs: "100%", lg: "100%"}, marginBottom: "10px"}}
                        >
                            <InputLabel> Didn't receive the code?</InputLabel>
                        </Grid>

                        <Grid
                            item
                            sx={{width: {xs: "100%", lg: "100%"}, marginBottom: "10px"}}
                        >
                            <Link component="button" variant="body2" onClick={sendCodeAgain}>
                                Send code again
                            </Link>
                        </Grid>

                        <Grid
                            item
                            sx={{width: {xs: "100%", lg: "100"}, marginBottom: "10px"}}
                        >
                            <Link
                                component="button"
                                variant="body2"
                                onClick={() => handleEditClick("0.1")}
                            >
                                Change phone number
                            </Link>
                        </Grid>
                    </ResponsiveRow>
                    <br/>
                </ModalContainerModule>

                <ResponsiveRow
                    sx={{
                        justifyContent: {xs: "center", lg: "flex-start"},
                        width: "100%",
                        height: "auto",
                    }}
                >
                    {/* <Grid>
            <Checkbox
              checked={
                props &&
                props.signUp &&
                props.signUp.signUpJson &&
                props.signUp.signUpJson.agreeToTermsOfService
              }
              onChange={onCheckBoxChange}
            />
            Agree to{" "}
            <Link
              component="button"
              variant="body2"
              onClick={() => console.log()}
            >
              Terms of service?
            </Link>
          </Grid> */}
                </ResponsiveRow>

                <ResponsiveRow
                    sx={{
                        justifyContent: {xs: "center", lg: "flex-end"},
                        width: "100%",
                        height: "auto",
                    }}
                >
                    {" "}
                    <ModalCancelButton
                        onClick={props.handleBackButtonClicked}
                        sx={{
                            width: {xs: "100%", lg: "200px"},
                        }}
                    >
                        Go Back
                    </ModalCancelButton>
                    <ModalAcceptButton
                        onClick={getStarted}
                        disabled={
                            (props &&
                                props.signUp &&
                                props.signUp.signUpJson &&
                                !props.signUp.signUpJson.agreeToTermsOfService) ||
                            (props &&
                                props.signUp &&
                                props.signUp.signUpJson &&
                                props.signUp.signUpJson.contactVerification &&
                                !props.signUp.signUpJson.contactVerification
                                    .isPhoneNumberVerified)
                        }
                        sx={{
                            width: {xs: "100%", lg: "200px"},
                        }}
                    >
                        Get Started
                    </ModalAcceptButton>
                </ResponsiveRow>
            </ResponsiveRow>
        </ResponsiveRow>
    );
}
