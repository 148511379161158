import {serviceAPI} from "./service"
import {FETCH_ORGANIZATION_URL, SAVE_ORGANIZATION_URL} from "../constants/url.constants"
import {handleErrorResponse} from "../actions/message"

export const saveOrganization = (data) => {
    return serviceAPI.post(SAVE_ORGANIZATION_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}


//GET REQUEST
export const fetchOrganization = (organizationId) => {
    return serviceAPI.get(FETCH_ORGANIZATION_URL + organizationId)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}
