import {serviceAPI} from "./service"
import {
    DOES_CLIENT_HAVE_MPESA2_FACILITIES_URL,
    MM_PROV_URL,
    MOBILE_MONEY_RECONCILIATIONS,
    RECONCILE_MOBILE_MONEY_URL,
    TOTAL_MOBILE_MONEY_RECONCILIATIONS
} from "../constants/url.constants"
import {handleErrorResponse} from "../actions/message"

export const listMobileMoneyProviders = () => {
    return serviceAPI
        .get(MM_PROV_URL)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const loadMobileMoneyReconciliations = (pageNo, rowsPerPage) => {
    let url = MOBILE_MONEY_RECONCILIATIONS + pageNo + "&rowsPerPage=" + rowsPerPage
    return serviceAPI
        .get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const totalMobileMoneyReconciliations = () => {
    return serviceAPI
        .get(TOTAL_MOBILE_MONEY_RECONCILIATIONS)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const mobileMoneyReconciliation = (data) => {
    return serviceAPI
        .post(RECONCILE_MOBILE_MONEY_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const checkIfClientHasMPESA2Facilities = () => {
    return serviceAPI
        .get(DOES_CLIENT_HAVE_MPESA2_FACILITIES_URL)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}
