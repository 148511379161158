import {Alert} from "@mui/material"
import ModalContainerModule from "../../../modules/modalContainer"

export default function DeleteAssetModal(props) {
    /**
     *
     * REQUIRED PROPS
     *
     * DeleteModal
     *
     * setDeleteModal
     *
     * confirmDelete
     * selectedAsset
     */

    return (
        <>
            <ModalContainerModule
                size="specialAssetDelete"
                accept={props.confirmDelete}
                cancel={() => props.setDeleteModal(false)}
                openModal={props.DeleteModal}
                modalTitle={`Delete ${
                    props.selectedAsset && props.selectedAsset.name
                }?`}
                acceptButtonText="Delete Asset"
            >
                <Alert severity="error" sx={{width: "100%"}}>
                    Are you sure you want to permanently delete this asset along with any
                    other associated data such as meters and documents?
                </Alert>
            </ModalContainerModule>
        </>
    )
}
