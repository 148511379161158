import {useState} from "react"
import ModalContainerModule from "../../modules/modalContainer"
import {ResponsiveRow} from "../../../constants/layout.constants"
import {Alert, Grid, InputLabel, TextField} from "@mui/material"
import {
    DetailsColumn,
    DetailsContainer,
    DetailsLabel,
    DetailsRow,
    DetailsText
} from "../../../constants/component.constants"
import NoteLogsCard from "../noteLogsCard"
import {formatDate} from "../../../util/util"

export default function ViewApplication(props) {
    const [note, setNote] = useState("")
    return (
        <ModalContainerModule
            size="xs"
            accept={props.closeViewModal}
            reject={props.closeViewModal}
            cancel={props.closeViewModal}
            openModal={props.openViewModal}
            modalTitle={"Application for " + props.applicationItem.applicant.name}
            acceptButtonText={"Approve"}
            rejectButtonText={"Reject"}
        >
            <ResponsiveRow sx={{paddingTop: 0}}>
                <DetailsContainer sx={{width: "100%"}}>
                    {/*<DetailsColumn*/}
                    {/*    sx={{*/}
                    {/*        width: {lg: "25%", md: "100%", xs: "100%"},*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*    <Box*/}
                    {/*        component="img"*/}
                    {/*        sx={{*/}
                    {/*            objectFit: "cover",*/}
                    {/*            objectPosition: "center center",*/}
                    {/*            width: "300px",*/}
                    {/*            height: "300px",*/}
                    {/*            borderRadius: "10px",*/}
                    {/*        }}*/}
                    {/*        src={GET_LISTING_IMAGE_URL + "?filename=" + (props.applicationItem.listing && props.applicationItem.listing.imageFiles && props.applicationItem.listing.imageFiles[0] && props.applicationItem.listing.imageFiles[0].systemFileName)}*/}
                    {/*        alt="listing image"*/}
                    {/*    />*/}
                    {/*</DetailsColumn>*/}

                    <DetailsColumn
                        sx={{
                            width: {lg: "50%", md: "100%", xs: "100%"},
                        }}
                    >
                        <DetailsRow>
                            <DetailsLabel>Applicant:</DetailsLabel>
                            <DetailsText>
                                {props.applicationItem.applicant.name}
                            </DetailsText>
                        </DetailsRow>
                        <DetailsRow>
                            <DetailsLabel>Listing:</DetailsLabel>
                            <DetailsText>
                                {props.applicationItem.listing.propertyName}
                            </DetailsText>
                        </DetailsRow>
                        <DetailsRow>
                            <DetailsLabel>Status:</DetailsLabel>
                            <DetailsText>
                                {props.applicationItem.status.name}
                            </DetailsText>
                        </DetailsRow>
                        <DetailsRow>
                            <DetailsLabel>Application Date:</DetailsLabel>
                            <DetailsText>
                                {formatDate(props.applicationItem.dateCreated)}
                            </DetailsText>
                        </DetailsRow>
                        <Alert severity="info">
                            Please give a reason for Approving/Rejecting this application
                        </Alert>
                        <ResponsiveRow container sx={{height: "auto"}}>
                            <Grid
                                item
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    width: "100%",
                                }}
                            >
                                <InputLabel>Reason* ({500 - (note.length)})</InputLabel>
                                <TextField
                                    sx={{width: "100%"}}
                                    id={"note"}
                                    name={"note"}
                                    isrequired
                                    value={note}
                                    onChange={(event) => setNote(event.target.value)}
                                    multiline
                                    rows={4}
                                />
                            </Grid>
                        </ResponsiveRow>
                    </DetailsColumn>

                    <DetailsColumn
                        sx={{
                            width: {lg: "50%", md: "100%", xs: "100%"},
                        }}
                    >
                        <NoteLogsCard notesMode={"ListingApplication"} contract={props.applicationItem}/>
                    </DetailsColumn>
                </DetailsContainer>
            </ResponsiveRow>
        </ModalContainerModule>
    )
}