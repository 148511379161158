import { useEffect, useState } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { ResponsiveRow } from "../../../constants/layout.constants";
import LineChartsCard from "../../charts/LineChartCard";
import { getMeterVolumeSales } from "../../../services/dashboard.service";
import { getMetersByFacility } from "../../../services/list.service";
import { isEmptyString, isNullUndefined } from "../../../util/util";
import "dayjs/locale/en-gb";
import dayjs from "dayjs";
import { YearPickerInput } from "../../inputs/datePickerInput";
import { useSelector } from "react-redux";

export default function MetersVolumesSold({ customHeight, customWidth }) {
  const [year, setYear] = useState(new Date());
  const [selectedMeter, setSelectedMeter] = useState("");
  const [volumesSoldData, setVolumesSoldData] = useState(null);
  const [meterOptions, setMeterOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const { user } = useSelector((state) => state.auth);
  useEffect(() => {
    setLoading(true);
    if (!isNullUndefined(year) && !isNullUndefined(selectedMeter)) {
      getMeterVolumeSales(year.getFullYear(), selectedMeter)
        .then((response) => {
          if (response && response.data) {
            setVolumesSoldData(response.data);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [year, selectedMeter, user.proxyFacility]);

  useEffect(() => {
    setLoading(true);
    if (isEmptyString(selectedMeter)) {
      getMetersByFacility()
        .then((data) => {
          setMeterOptions(data);
          //default to the first one
          if (data && data.length > 0) {
            setSelectedMeter(data[0].value);
          }
        })
        .finally(() => {
          if (isNullUndefined(year)) {
            getMeterVolumeSales(year.getFullYear(), selectedMeter)
              .then((response) => {
                setVolumesSoldData(response.data);
              })
              .finally(() => {
                setLoading(false);
              });
          } else {
            setLoading(false);
          }
        });
    }
    return () => {
      setVolumesSoldData(null);
      setSelectedMeter("");
      setYear();
    };
  }, []);

  return (
    <ResponsiveRow
      sx={{
        flexDirection: "column",
        flexWrap: "nowrap",

        width: { xs: "100%", lg: customWidth },
        height: { xs: "100%", lg: customHeight },

        margin: "10px",

        backgroundColor: "#fff",
        borderRadius: "20px",
      }}
    >
      <ResponsiveRow
        sx={{
          flexDirection: "row",

          width: "100%",
          height: "min-content",

          textAlign: "start",

          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography sx={{ width: "50%" }} variant="h5">
          Meters: Volumes Sold
        </Typography>
        <ResponsiveRow
          sx={{
            padding: 0,
            width: "50%",
            justifyContent: { xs: "center", lg: "flex-end" },
          }}
        >
          <FormControl
            sx={{ width: { xs: "100%", lg: "25%" }, margin: "auto 5px" }}
          >
            <YearPickerInput
              labelText="Select Year"
              componentID="metersVolumeSold"
              disableFuture={true}
              disablePast={false}
              onSelectDate={(new_value) => {
                // console.log("year Val", new_value);
                setYear(new Date(new_value));
              }}
              value={dayjs(year)}
            />
          </FormControl>
          <FormControl
            sx={{ width: { xs: "100%", lg: "25%" }, margin: "auto 15x" }}
          >
            <InputLabel>Select Meter</InputLabel>
            <Select
              sx={{ width: { xs: "100%", lg: "90%" }, padding: "0 5px" }}
              label="Select Meter"
              value={selectedMeter}
              onChange={(e) => setSelectedMeter(e.target.value)}
            >
              {meterOptions && meterOptions.length > 0 ? (
                meterOptions.map((item, i) => {
                  return (
                    <MenuItem key={i} value={item.value}>
                      {item.label}
                    </MenuItem>
                  );
                })
              ) : (
                <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
              )}
            </Select>
          </FormControl>
        </ResponsiveRow>
      </ResponsiveRow>

      <ResponsiveRow
        sx={{
          height: "80%",
          width: "100%",

          flexWrap: "nowrap",
          flexDirection: "row",

          alignItem: "center",
          justifyContent: "center",
        }}
      >
        <LineChartsCard
          currentlyLoading={loading}
          labelDataObj={{
            dataLabels: [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
              "Oct",
              "Nov",
              "Dec",
            ],

            dataset: [
              {
                datasetLabel: "Volumes Sold",
                datasetData: volumesSoldData,
              },
            ],
          }}
        />
      </ResponsiveRow>
    </ResponsiveRow>
  );
}
