import {Box, Button, FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Select,} from "@mui/material"
import {useState} from "react"

export default function ManageUsersBar() {
    const [currentView, setCurrentView] = useState("staff")
    const [searchQuery, setSearchQuery] = useState("")

    function onSearchChange(e) {
        setSearchQuery(e.target.value)
    }

    function onUserFilterChange(e) {
        setCurrentView(e.target.value)
    }

    return (
        <Grid
            container
            sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: {lg: "nowrap", md: "wrap"},
                justifyContent: "space-between",
                width: {xs: "100%", md: "80%"},
                backgroundColor: "white",
                padding: {md: "1vh", xs: "1vh"},
                borderRadius: "1vh",
                margin: "20px auto",
                boxShadow: "0 4px 5px 0 rgba(0,0,0,0.5)",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: {xl: "row", xs: "column"},
                    justifyContent: {md: "space-between", xs: "center"},
                    width: {lg: "33.3%", md: "50%", sm: "100%"},
                }}
                component="form"
            >
                <FormControl
                    fullWidth
                    sx={{
                        margin: {md: "auto 20px", xs: "10px auto "},
                        width: "100%",
                    }}
                >
                    <InputLabel id="roleSelectorLabel">
                        Search {currentView === "staff" ? "Staff" : "Clients"}
                    </InputLabel>
                    <OutlinedInput
                        id="usersSearch"
                        value={searchQuery}
                        label={`Search ${currentView}`}
                        sx={{width: "100%"}}
                        onChange={onSearchChange}
                    />
                </FormControl>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: {xl: "row", xs: "column"},
                    justifyContent: {md: "space-between", xs: "center"},
                    width: {lg: "25%", md: "50%", xs: "100%"},
                }}
                component="form"
            >
                <FormControl
                    fullWidth
                    sx={{
                        margin: {md: "auto 20px", xs: "10px auto "},
                        width: "100%",
                    }}
                >
                    <InputLabel id="userFilter">User Type Filter</InputLabel>
                    <Select
                        defaultValue={currentView === "Staff" ? "Staff" : "Clients"}
                        labelId="userFilter"
                        id="userFilter"
                        value={currentView}
                        label="User Type Filter"
                        sx={{width: "100%"}}
                        onChange={onUserFilterChange}
                    >
                        <MenuItem key={"staff"} value={"Staff"} sx={{textAlign: "start"}}>
                            Staff
                        </MenuItem>
                        <MenuItem key={"clients"} value={"Clients"}>
                            Clients
                        </MenuItem>
                    </Select>
                </FormControl>
            </Box>

            <Box
                sx={{
                    display: "flex",
                    flexDirection: {xl: "row", xs: "column"},
                    justifyContent: {md: "space-between", xs: "center"},
                    width: {lg: "25%", md: "50%", xs: "100%"},
                }}
                component="form"
            >
                <Button
                    variant="contained"
                    sx={{
                        backgroundColor: "#037171",
                        color: "#fff",
                        fontSize: "12px",
                        padding: "5px 7px",
                        margin: {xs: "10px 0", md: "0 10px"},
                        textTransform: "full-width",
                        width: "100%",
                    }}
                >
                    Add New {currentView === "Staff" ? "Staff" : "Client"}
                </Button>
                <Button
                    variant="contained"
                    sx={{
                        backgroundColor: "#E53935",
                        color: "#fff",
                        fontSize: "12px",
                        padding: "5px 7px",
                        margin: {xs: "10px 0", md: "0 10px"},
                        textTransform: "full-width",
                        width: "100%",
                    }}
                >
                    Add New {currentView === "Staff" ? "Staff" : "Client"}
                </Button>
            </Box>
        </Grid>
    )
}
