import { Alert } from "@mui/material";
import {
  DetailsColumn,
  DetailsContainer,
  DetailsLabel,
  DetailsRow,
  DetailsText,
} from "../../../../constants/component.constants";
import { ResponsiveRow } from "../../../../constants/layout.constants";
import ModalContainerModule from "../../../modules/modalContainer";
import { formatDate } from "../../../../util/util";

export default function ViewReceiptModal(props) {
  /**
   *
   * REQUIRED PROPS
   *
   * handleViewReceiptClose
   *
   * receiptToView
   * viewReceipt
   */

  return (
    <ModalContainerModule
      size="specialViewReceiptSize"
      cancel={props.handleViewReceiptClose}
      openModal={props.viewReceipt}
      modalTitle="Receipt Details"
    >
      <DetailsContainer
        container
        sx={{ width: "100%", height: "auto", marginTop: 0, padding: 0 }}
      >
        {props.receiptToView && props.receiptToView.description && (
          <ResponsiveRow
            sx={{ display: { xs: "flex", lg: "none" }, padding: 0 }}
          >
            <Alert severity="info" icon={false}>
              {props.receiptToView
                ? props.receiptToView && props.receiptToView.description
                : ""}
            </Alert>
          </ResponsiveRow>
        )}
        <DetailsColumn sx={{ width: { xs: "100%", lg: "50%" } }}>
          <DetailsRow sx={{ display: { xs: "none", lg: "flex" } }}>
            <DetailsLabel>Description:</DetailsLabel>
            <DetailsText>
              {props.receiptToView
                ? props.receiptToView && props.receiptToView.description
                : ""}
            </DetailsText>
          </DetailsRow>

          <DetailsRow>
            <DetailsLabel>Date:</DetailsLabel>
            <DetailsText>
              {props.receiptToView &&
              props.receiptToView &&
              props.receiptToView.date
                ? formatDate(props.receiptToView.date)
                : ""}
            </DetailsText>
          </DetailsRow>
          <DetailsRow>
            <DetailsLabel>Payment Method:</DetailsLabel>
            <DetailsText>
              {props.receiptToView
                ? props.receiptToView && props.receiptToView.paymentMethodName
                : ""}
            </DetailsText>
          </DetailsRow>
          {props.receiptToView &&
            props.receiptToView.paymentMethodCd &&
            props.receiptToView.paymentMethodCd === "CHQ" && (
              <DetailsRow>
                <DetailsLabel>Cheque Number:</DetailsLabel>

                <DetailsText>
                  {props.receiptToView
                    ? props.receiptToView && props.receiptToView.chequeNumber
                    : ""}
                </DetailsText>
              </DetailsRow>
            )}
          {props.receiptToView && props.receiptToView.voidedBy && (
            <DetailsRow>
              <DetailsLabel>Voided By :</DetailsLabel>

              <DetailsText>
                {props.receiptToView && props.receiptToView.voidedBy
                  ? props.receiptToView.voidedBy
                  : ""}
              </DetailsText>
            </DetailsRow>
          )}
        </DetailsColumn>
        <DetailsColumn sx={{ width: { xs: "100%", lg: "50%" } }}>
          <DetailsRow>
            <DetailsLabel>Created By:</DetailsLabel>
            <DetailsText>
              {props.receiptToView && props.receiptToView.createdBy
                ? props.receiptToView.createdBy
                : ""}
            </DetailsText>
          </DetailsRow>

          <DetailsRow>
            <DetailsLabel>Amount:</DetailsLabel>

            <DetailsText>
              {props.receiptToView
                ? props.receiptToView && props.receiptToView.amount
                : ""}
            </DetailsText>
          </DetailsRow>
          <DetailsRow>
            <DetailsLabel>Issued For:</DetailsLabel>
            <DetailsText>
              {props.receiptToView
                ? props.receiptToView && props.receiptToView.issuedFor
                : ""}
            </DetailsText>
          </DetailsRow>
          {props.receiptToView && props.receiptToView.voidDate && (
            <DetailsRow>
              <DetailsLabel>Voided On :</DetailsLabel>

              <DetailsText>
                {props.receiptToView && props.receiptToView.voidDate
                  ? formatDate(props.receiptToView.voidDate)
                  : ""}
              </DetailsText>
            </DetailsRow>
          )}
        </DetailsColumn>
      </DetailsContainer>
    </ModalContainerModule>
  );
}
