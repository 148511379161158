import {Container, Typography} from "@mui/material"
import {Box} from "@mui/system"
import {useState} from "react"
import {DatePickerInput, MonthPickerInput, YearPickerInput,} from "./inputs/datePickerInput"

export default function InputComponents() {
    const [selectedDate, setSelectedDate] = useState(new Date("21/02/2022"))

    function onSelectDate(e) {
        setSelectedDate(e.target.value)
    }

    return (
        <Container disableGutters maxWidth="xxl">
            <Box
                sx={{
                    padding: "20px 15px",
                    width: "100%",
                    border: "1px #02CBCE solid",
                    borderRadius: "10px",
                    margin: "20px 0",
                }}
            >
                <Typography
                    variant="h4"
                    textAlign={"center"}
                    sx={{margin: "20px 0", color: "#037171"}}
                >
                    Date Picker
                </Typography>
                <DatePickerInput
                    labelText="Demo Date Picker"
                    componentID="demoDate"
                    onSelectDate={onSelectDate}
                    value={selectedDate}
                />
            </Box>
            <Box
                sx={{
                    padding: "20px 15px",
                    width: "100%",
                    border: "1px #02CBCE solid",
                    borderRadius: "10px",
                    margin: "20px 0",
                }}
            >
                <Typography
                    variant="h4"
                    textAlign={"center"}
                    sx={{margin: "20px 0", color: "#037171"}}
                >
                    Month Picker
                </Typography>
                <MonthPickerInput
                    labelText="Demo Month Picker"
                    componentID="demoMonth"
                    onSelectDate={onSelectDate}
                    value={selectedDate}
                />
            </Box>
            <Box
                sx={{
                    padding: "20px 15px",
                    width: "100%",
                    border: "1px #02CBCE solid",
                    borderRadius: "10px",
                    margin: "20px 0",
                }}
            >
                <Typography
                    variant="h4"
                    textAlign={"center"}
                    sx={{margin: "20px 0", color: "#037171"}}
                >
                    Year Pickers
                </Typography>

                <YearPickerInput
                    labelText="Demo Year Picker"
                    componentID="demoYear"
                    onSelectDate={onSelectDate}
                    value={selectedDate}
                />
            </Box>
        </Container>
    )
}
