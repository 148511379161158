import {TabContext, TabPanel} from "@mui/lab"
import {Tab, Table, TableBody, TableCell, TableHead, TableRow, Tabs,} from "@mui/material"
import {
    DetailsColumn,
    DetailsContainer,
    DetailsLabel,
    DetailsRow,
    DetailsText,
} from "../../../../constants/component.constants"
import {ResponsiveRow} from "../../../../constants/layout.constants"
import ModalContainerModule from "../../../modules/modalContainer"
import {useEffect, useState} from "react"

/**
 *
 * REQUIRED PROPS
 *
 * handleAccept: func()
 * handleClose: func()
 * open: boolean
 * data: {
 *      name: string
 *      startDate: string
 *      facility: string
 *      description: string
 *      endDate: string
 *      approvalRequired: string
 *      status: string
 *      approvalThreshold: string
 *      incomeAccount: string
 *      expensesAccount: string
 *      income:[]
 *      expenses:[]
 *  }
 *
 */

export default function ViewApprovalModal(props) {
    const [approvalInfo, setApprovalInfo] = useState({
        name: "",
        startDate: "",
        facility: "",
        description: "",
        endDate: "",
        approvalRequired: "",
        status: "",
        approvalThreshold: "",
        incomeAccount: "",
        expensesAccount: "",
        income: [],
        expenses: [],
    })
    const [currentTab, setCurrentTab] = useState("income")

    function handleTabChange(event, newValue) {
        setCurrentTab(newValue)
    }

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
        }
    }

    function ApprovalTable(props) {
        return (
            <Table sx={{width: "100%", height: "auto"}} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Month</TableCell>
                        <TableCell align="right">Jan</TableCell>
                        <TableCell align="right">Feb</TableCell>
                        <TableCell align="right">Mar</TableCell>
                        <TableCell align="right">Apr</TableCell>
                        <TableCell align="right">May</TableCell>
                        <TableCell align="right">Jun</TableCell>
                        <TableCell align="right">Jul</TableCell>
                        <TableCell align="right">Aug</TableCell>
                        <TableCell align="right">Sep</TableCell>
                        <TableCell align="right">Oct</TableCell>
                        <TableCell align="right">Nov</TableCell>
                        <TableCell align="right">Dec</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow
                        key={props.data.name}
                        sx={{"&:last-child td, &:last-child th": {border: 0}}}
                    >
                        <TableCell component="th" scope="row">
                            {props.account}
                        </TableCell>
                        <TableCell align="right">{props.data[0]}</TableCell>
                        <TableCell align="right">{props.data[1]}</TableCell>
                        <TableCell align="right">{props.data[2]}</TableCell>
                        <TableCell align="right">{props.data[3]}</TableCell>
                        <TableCell align="right">{props.data[4]}</TableCell>
                        <TableCell align="right">{props.data[5]}</TableCell>
                        <TableCell align="right">{props.data[6]}</TableCell>
                        <TableCell align="right">{props.data[7]}</TableCell>
                        <TableCell align="right">{props.data[8]}</TableCell>
                        <TableCell align="right">{props.data[9]}</TableCell>
                        <TableCell align="right">{props.data[10]}</TableCell>
                        <TableCell align="right">{props.data[11]}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        )
    }

    useEffect(() => {
        if (props.data) {
            setApprovalInfo(props.data)
        }

        return () => {
            setApprovalInfo({
                name: "",
                startDate: "",
                facility: "",
                description: "",
                endDate: "",
                approvalRequired: "",
                status: "",
                approvalThreshold: "",
            })
            setCurrentTab("0")
        }
    }, [props.data])

    return (
        <ModalContainerModule
            size="sm"
            accept={props.handleAccept}
            cancel={props.handleClose}
            openModal={props.open}
            modalTitle="Budget Details"
            acceptButtonText="Approve"
        >
            <DetailsContainer sx={{width: "100%", height: "auto"}}>
                <DetailsColumn>
                    <DetailsRow>
                        <DetailsLabel>Name</DetailsLabel>
                        <DetailsText>{approvalInfo.name}</DetailsText>
                    </DetailsRow>
                    <DetailsRow>
                        <DetailsLabel>Start Date</DetailsLabel>
                        <DetailsText>{approvalInfo.startDate}</DetailsText>
                    </DetailsRow>
                    <DetailsRow>
                        <DetailsLabel>Facility</DetailsLabel>
                        <DetailsText>{approvalInfo.facility}</DetailsText>
                    </DetailsRow>
                </DetailsColumn>
                <DetailsColumn>
                    <DetailsRow>
                        <DetailsLabel>Description</DetailsLabel>
                        <DetailsText>{approvalInfo.description}</DetailsText>
                    </DetailsRow>
                    <DetailsRow>
                        <DetailsLabel>End Date</DetailsLabel>
                        <DetailsText>{approvalInfo.endDate}</DetailsText>
                    </DetailsRow>
                    <DetailsRow>
                        <DetailsLabel>Approval Required</DetailsLabel>
                        <DetailsText>{approvalInfo.approvalRequired}</DetailsText>
                    </DetailsRow>
                </DetailsColumn>
                <DetailsColumn>
                    <DetailsRow>
                        <DetailsLabel>Status</DetailsLabel>
                        <DetailsText>{approvalInfo.status}</DetailsText>
                    </DetailsRow>
                    <DetailsRow>
                        <DetailsLabel>Approval Threshold</DetailsLabel>
                        <DetailsText>{approvalInfo.approvalThreshold}</DetailsText>
                    </DetailsRow>
                </DetailsColumn>
            </DetailsContainer>
            <TabContext value={currentTab}>
                <ResponsiveRow
                    sx={{
                        width: "100%",
                        height: "auto",
                        borderBottom: 1,
                        borderColor: "divider",
                    }}
                >
                    <Tabs
                        value={currentTab}
                        onChange={handleTabChange}
                        aria-label="basic tabs example"
                    >
                        <Tab value="income" label="Income" {...a11yProps(0)} />
                        <Tab value="expenses" label="Expenses" {...a11yProps(1)} />
                    </Tabs>
                </ResponsiveRow>
                <TabPanel
                    value={"income"}
                    index={0}
                    sx={{width: "100%", height: "auto"}}
                >
                    <ApprovalTable
                        data={approvalInfo.income}
                        account={approvalInfo.incomeAccount}
                    />
                </TabPanel>
                <TabPanel
                    value={"expenses"}
                    index={1}
                    sx={{width: "100%", height: "auto"}}
                >
                    <ApprovalTable
                        data={approvalInfo.expenses}
                        account={approvalInfo.expensesAccount}
                    />
                </TabPanel>
            </TabContext>
        </ModalContainerModule>
    )
}
