import {Box, Button, FormControl, Grid,} from "@mui/material"
import {useState} from "react"
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop"
import {YearPickerInput} from "../inputs/datePickerInput"

export default function MeterBillingViewBar() {
    const [selectedYear, setSelectedYear] = useState("")

    function onSelectYear(new_value) {
        setSelectedYear(new_value)
    }

    return (
        <Grid
            container
            sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: {lg: "nowrap", md: "wrap"},
                justifyContent: {md: "space-between", xs: "space-between"},
                width: {xs: "100%", md: "80%"},
                maxHeight: {xs: "100%", md: "100px", lg: "75px"},
                backgroundColor: "white",
                padding: {md: "1vh", xs: "1vh"},
                borderRadius: "1vh",
                margin: "20px auto",
                boxShadow: "0 4px 5px 0 rgba(0,0,0,0.5)",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: {xl: "row", xs: "column"},
                    justifyContent: {md: "space-between", xs: "center"},
                    width: {lg: "33.3%", md: "50%", sm: "100%"},
                }}
                component="form"
            >
                <FormControl
                    fullWidth
                    sx={{
                        margin: {md: "auto 20px", xs: "10px auto "},
                        width: "100%",
                    }}
                >
                    <YearPickerInput
                        labelText="Select Statement Year"
                        componentID="demoYear"
                        onSelectDate={onSelectYear}
                        value={selectedYear}
                    />
                </FormControl>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: {xl: "row", xs: "column"},
                    justifyContent: {md: "space-between", xs: "center"},
                    width: {md: "50%", xs: "100%"},
                    padding: "10px",
                }}
                component="form"
            >
                <Button
                    variant="contained"
                    sx={{
                        backgroundColor: "#037171",
                        color: "#fff",
                        "&:hover": {
                            backgroundColor: "#FFF",
                            color: "#037171",
                        },
                        fontSize: "12px",
                        padding: "5px 7px",
                        margin: {xs: "10px 0", md: "5px 10px", lg: "0 10px"},
                        textTransform: "full-width",
                        width: "100%",
                    }}
                >
                    <LocalPrintshopIcon sx={{margin: "auto 5px"}}/> Print Meter Billing
                    Statement
                </Button>
                <Button
                    variant="contained"
                    sx={{
                        backgroundColor: "#037171",
                        color: "#fff",
                        "&:hover": {
                            backgroundColor: "#FFF",
                            color: "#037171",
                        },
                        fontSize: "12px",
                        padding: "5px 7px",
                        margin: {xs: "10px 0", md: "5px 10px", lg: "0 10px"},
                        textTransform: "full-width",
                        width: "100%",
                    }}
                >
                    <LocalPrintshopIcon sx={{margin: "auto 5px"}}/>
                    Print Billing
                </Button>
            </Box>
        </Grid>
    )
}
