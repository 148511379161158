import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";

import { useCallback, useEffect, useState } from "react";
import { ResponsiveRow } from "../../../constants/layout.constants";
import PieChartsCard from "../../charts/PieChartCard";
import {
  getBalanceOverdue,
  getDataForChargesAndInvoices,
} from "../../../services/dashboard.service";
import Permit from "../../../util/permit";
import ModalContainerModule from "../modalContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {formatCurrency, isEmptyString, isNullUndefined} from "../../../util/util";

import { useSelector } from "react-redux";

/**
 *
 * REQUIRED PROPS
 * customWidth: string
 * customHeight: string
 * data: [{
 *    label: string
 *    data: number
 *  }]
 */

export default function BalanceOverdueByDays({ customWidth, customHeight }) {
  const [chartData, setChartData] = useState([]);
  const [selectLeaseFilter, setSelectLeaseFilter] = useState("active");
  const [invoiceOrCharges, setInvoiceOrCharges] = useState("both");
  const [loading, setLoading] = useState(true);
  const [invoiceIds, setInvoiceIds] = useState([]);
  const [chargeIds, setChargeIds] = useState([]);
  const [outstandingBalances, setOutstandingBalances] = useState([]);
  const [showOutstandingBalancesModal, setShowOutstandingBalancesModal] =
    useState(false);

  const { user } = useSelector((state) => state.auth);
  const leaseOptions = [
    { label: "All Leases", value: "all" },
    { label: "Active Leases", value: "active" },
    { label: "Terminated Leases", value: "terminated" },
  ];

  const ownerOptions = [
    { label: "All Ownerships", value: "all" },
    { label: "Active Ownerships", value: "active" },
    { label: "Terminated Ownerships", value: "terminated" },
  ];

  const invoiceOrChargeOptions = [
    { label: "Invoices & Charges", value: "both" },
    { label: "Invoices", value: "invoices" },
    { label: "Charges", value: "charges" },
  ];

  const fetchData = useCallback(() => {
    setLoading(true);
    getBalanceOverdue(selectLeaseFilter, invoiceOrCharges)
      .then((response) => {
        if (response && response.data) {
          setInvoiceIds(response.data.invoiceIds);
          setChargeIds(response.data.chargeIds);
          setChartData(response.data);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectLeaseFilter, invoiceOrCharges, user.proxyFacility]);

  const getBalancesData = useCallback(() => {
    // console.log("CRUNCHING BALANCES !!!!!!!!!!!")
    setLoading(true);

    let sendData = {
      invoiceIds: invoiceIds,
      chargeIds: chargeIds,
    };

    getDataForChargesAndInvoices(sendData)
      .then((data) => {
        setOutstandingBalances(data);
        // console.log("outstanding balances", data)
        setShowOutstandingBalancesModal(true);
        setLoading(false);
      })
      .catch((error) => {
        // console.log("getDataForChargesAndInvoices error", error);
        setLoading(false);
      });
  }, [chargeIds, invoiceIds]);

  const handleCancelClicked = () => {
    setShowOutstandingBalancesModal(false);
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <ResponsiveRow
      sx={{
        flexDirection: "column",
        flexWrap: "nowrap",

        height: { xs: "100%", lg: customHeight },
        minWidth: { xs: "100%", lg: customWidth },
        width: "100%",

        margin: "10px",

        backgroundColor: "#fff",
        borderRadius: "20px",
      }}
    >
      <ResponsiveRow
        sx={{
          margin: "0",
          height: "10%",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h5">Balances Overdue by Days</Typography>
      </ResponsiveRow>
      <ResponsiveRow
        sx={{
          flexDirection: { xs: "column", lg: "row" },
          width: "100%",
          height: "10%",
          flexWrap: "nowrap",
          justifyContent: "flex-start",
        }}
      >
        <FormControl
          sx={{ width: { xs: "100%", lg: "50%" }, marginBottom: "10px" }}
        >
          <InputLabel>Invoices or Charge</InputLabel>
          <Select
            sx={{
              width: { xs: "100%", lg: "90%" },
              padding: "0 5px",
              textAlign: "start",
            }}
            label="Invoices or Charge"
            value={invoiceOrCharges}
            onChange={(event) => {
              setInvoiceOrCharges(event.target.value);
            }}
          >
            {invoiceOrChargeOptions && invoiceOrChargeOptions.length > 0 ? (
              invoiceOrChargeOptions.map((opt, i) => (
                <MenuItem key={i} value={opt.value}>
                  {opt.label}
                </MenuItem>
              ))
            ) : (
              <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
            )}
          </Select>
        </FormControl>
        <Permit services="HOA">
          <FormControl
            sx={{ width: { xs: "100%", lg: "50%" }, marginBottom: "10px" }}
          >
            <InputLabel>Ownership Type</InputLabel>
            <Select
              sx={{
                width: { xs: "100%", lg: "90%" },
                padding: "0 5px",
                textAlign: "start",
              }}
              label="Ownership Type"
              value={selectLeaseFilter}
              onChange={(event) => {
                console.log("event.target.value is ", event.target.value);
                setSelectLeaseFilter(event.target.value);
              }}
            >
              {ownerOptions && ownerOptions.length > 0 ? (
                ownerOptions.map((opt, i) => (
                  <MenuItem key={i} value={opt.value}>
                    {opt.label}
                  </MenuItem>
                ))
              ) : (
                <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
              )}
            </Select>
          </FormControl>
        </Permit>
        <Permit services="PREMIUM,CORE,LEAN">
          <FormControl
            sx={{ width: { xs: "100%", lg: "50%" }, marginBottom: "10px" }}
          >
            <InputLabel>Lease Type</InputLabel>
            <Select
              sx={{
                width: { xs: "100%", lg: "90%" },
                padding: "0 5px",
                textAlign: "start",
              }}
              label="Lease Type"
              value={selectLeaseFilter}
              onChange={(event) => {
                setSelectLeaseFilter(event.target.value);
              }}
            >
              {leaseOptions && leaseOptions.length > 0 ? (
                leaseOptions.map((opt, i) => (
                  <MenuItem key={i} value={opt.value}>
                    {opt.label}
                  </MenuItem>
                ))
              ) : (
                <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
              )}
            </Select>
          </FormControl>
        </Permit>
      </ResponsiveRow>

      <ResponsiveRow
        sx={{
          margin: "30px auto",
          height: "70%",
          width: "100%",
          flexWrap: "nowrap",
          flexDirection: { xs: "column", lg: "row" },
          alignItem: "flex-end",
          justifyContent: "center",
        }}
      >
        {loading !== undefined && (
          <PieChartsCard
            currentlyLoading={loading}
            labelDataObj={chartData}
            getData={getBalancesData}
            type={"balancesOverdue"}
          />
        )}
      </ResponsiveRow>

      {showOutstandingBalancesModal && (
        <ModalContainerModule
          size="sm"
          // accept={}
          cancel={handleCancelClicked}
          openModal={showOutstandingBalancesModal}
          modalTitle="Balances Overdue"
        >
          <ResponsiveRow container sx={{ height: "auto" }}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: "650" }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                      {(isNullUndefined(user.proxyFacility) || isEmptyString(user.proxyFacility) || user.proxyFacility === "null") &&
                          <TableCell>Facility</TableCell>
                      }
                    <TableCell>Unit</TableCell>
                    <TableCell>Amount&nbsp;(KES)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {outstandingBalances.map((row) => {
                    return (
                      <TableRow
                        key={row.name}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {row.name}
                        </TableCell>
                          {(isNullUndefined(user.proxyFacility) || isEmptyString(user.proxyFacility) || user.proxyFacility === "null") &&
                              <TableCell>{row.facility}</TableCell>
                          }
                        <TableCell>{row.unit}</TableCell>
                        <TableCell>{formatCurrency(row.balance)}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </ResponsiveRow>
        </ModalContainerModule>
      )}
    </ResponsiveRow>
  );
}
