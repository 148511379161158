import {Visibility} from "@mui/icons-material";
import {IconButton, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import {ResponsiveRow} from "../../../constants/layout.constants";
import ViewReminderModal from "../../modals/dashboard/buildingDirector/viewReminderModal";
import {getReminders} from "../../../services/dashboard.service";
import MuiDataGrid from "../MuiDataGrid";
import {useSelector} from "react-redux";

/**
 *
 * REQUIRED PROPS
 *
 * customWidth: string
 * customHeight: string
 * data: [{
 *    id: number
      type: string
      date: string
      description: string
 *  }]
 */

export default function Reminders({customHeight, customWidth}) {
    const [DataGridRows, setDataGridRows] = useState([]);
    const [selectedNotification, setSelectedNotification] = useState();
    const [viewNotificationModal, setViewNotificationModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const {user} = useSelector((state) => state.auth)

    function handleViewOpenModal(data) {
        setSelectedNotification(data);
        setViewNotificationModal(true);
    }

    function handleViewCloseModal() {
        setViewNotificationModal(false);
    }

    const DataGridColumns = [
        {
            field: "date",
            headerName: "Date",
            minWidth: 150,
            flex: 1.5,
            editable: true,
        },
        {
            field: "description",
            headerName: "Description",
            minWidth: 250,
            flex: 2.5,
            editable: true,
        },
        {
            field: "type",
            headerName: "Type",
            minWidth: 150,
            flex: 1.5,
        },
        {
            field: "action",
            headerName: "Action",
            sortable: false,
            width: 100,
            renderCell: (params) => (
                <ResponsiveRow
                    sx={{width: "100%", flexDirection: "row", flexWrap: "nowrap"}}
                >
                    <IconButton onClick={() => handleViewOpenModal(params.row)}>
                        <Visibility sx={{color: "#037171"}}/>
                    </IconButton>
                </ResponsiveRow>
            ),
        },
    ];

    useEffect(() => {
        getReminders()
            .then((response) => {
                if (response && response.data) {
                    setDataGridRows(response.data);
                }
            })
            .finally(() => {
                setLoading(false);
            });
        return () => setDataGridRows([]);
    }, [user.proxyFacility]);

    return (
        <ResponsiveRow
            sx={{
                flexDirection: "column",
                flexWrap: "nowrap",

                alignItems: "flex-start",

                padding: "20px",

                height: {xs: "100%", lg: customHeight},
                minWidth: {xs: "100%", lg: customWidth},
                width: "100%",

                margin: "10px",

                backgroundColor: "#fff",
                borderRadius: "20px",
            }}
        >
            <Typography
                sx={{
                    width: "100%",
                }}
                textAlign="start"
                variant="h5"
            >
                Organization Reminders
            </Typography>


            <MuiDataGrid
                alt={true}
                noSelection={true}
                dataGridColumns={DataGridColumns}
                dataGridRows={DataGridRows}
                serverPagination={false}
                loading={loading}
                height="90%"
                totalRows={DataGridRows.length}
            />

            <ViewReminderModal
                handleClose={handleViewCloseModal}
                open={viewNotificationModal}
                notificationData={selectedNotification}
            />
        </ResponsiveRow>
    );
}
