import {FormControl, InputLabel, OutlinedInput, TextField,} from "@mui/material";
import {ResponsiveRow} from "../../../../constants/layout.constants";
import ModalContainerModule from "../../../modules/modalContainer";

import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {isEmptyString} from "../../../../util/util";
import dayjs from "dayjs";

export default function FilterTransfers(props) {
    /**
     *
     * handleSearchClicked
     * handleCloseFilterFormClicked
     * filterForm
     * setRefNumber
     * refNumber
     * setDescription
     * description
     * approvalDate
     * setApprovalDate
     *
     */

    return (
        <ModalContainerModule
            size="specialTransferFilter"
            accept={props.handleSearchClicked}
            cancel={props.handleCloseFilterFormClicked}
            openModal={props.filterForm}
            modalTitle="Search"
            acceptButtonText="Search"
        >
            <ResponsiveRow
                sx={{
                    width: "100%",
                    height: "auto",
                    justifyContent: {xs: "center", lg: "space-between"},
                }}
            >
                <FormControl
                    item
                    sx={{width: {xs: "100%", lg: "50%"}, marginBottom: "10px"}}
                >
                    <InputLabel>Reference Number </InputLabel>
                    <OutlinedInput
                        label={"Reference Number "}
                        sx={{width: {xs: "100%", lg: "90%"}}}
                        id={"refNo"}
                        onChange={(event) => {
                            let value = event.target.value;
                            props.setRefNumber(value);
                        }}
                        value={props.refNumber}
                        inputProps={<TextField sx={{width: {xs: "100%", lg: "90%"}}}/>}
                    />
                </FormControl>

                <FormControl
                    item
                    sx={{width: {xs: "100%", lg: "50%"}, marginBottom: "10px"}}
                >
                    <InputLabel>Description </InputLabel>
                    <OutlinedInput
                        label={"Description"}
                        sx={{width: {xs: "100%", lg: "90%"}}}
                        id={"description"}
                        onChange={(event) => {
                            let value = event.target.value;
                            props.setDescription(value);
                        }}
                        value={props.description}
                        inputProps={<TextField sx={{width: {xs: "100%", lg: "90%"}}}/>}
                    />
                </FormControl>

                <FormControl
                    item
                    sx={{width: {xs: "100%", lg: "50%"}, marginBottom: "10px"}}
                >
                    <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale="en-gb"
                    >
                        <DatePicker
                            sx={{width: {xs: "100%", lg: "90%"}}}
                            id={"approvalDate"}
                            value={
                                isEmptyString(props.approvalDate) ? null : dayjs(props.approvalDate)
                            }
                            label="Approval Date"
                            isRequired={false}
                            onChange={(newDate) => {
                                // console.log("date Value ", newDate)
                                let date = new Date(newDate);
                                props.setApprovalDate(date);
                            }}

                        />
                    </LocalizationProvider>
                </FormControl>
            </ResponsiveRow>
        </ModalContainerModule>
    );
}
