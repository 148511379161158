import {ResponsiveRow} from "../../constants/layout.constants";
import VacantUnits from "../../components/modules/dashboard/vacantUnits";
import ExpiringLeases from "../../components/modules/dashboard/expiringLeases";
import UtilitiesIncomeVsExpenses from "../../components/modules/dashboard/utilitesIncomevsExpenses";
import UtilitiesReadVsUnread from "../../components/modules/dashboard/utilitesReadVsUnread";
import UtilitiesWorkingVsBroken from "../../components/modules/dashboard/utilitiesWorkingVsBroken";
import Permit from "../../util/permit";
import UpcomingAssetMaintenance from "../../components/modules/dashboard/upcomingAssetMaintenance";
import {CorporateFare} from "@mui/icons-material";

export default function DashboardReceptionist() {
    return (
        <Permit
            roles="REC"
            services="PREMIUM,CORE,HOA,LEAN,METER"
            noPermittedServiceMessage={{
                title: "No Facility Selected",
                body: (
                    <>
                        Please select a facility by clicking on the building icon (
                        <CorporateFare
                            sx={{
                                fontSize: "1.5rem",
                                color: "#037171",
                                display: "inline-block",
                                margin: "auto 0",
                            }}
                        />
                        ) from the top right <br/> If you are having issues, please reach
                        out to the system admin.
                    </>
                ),
            }}
        >
            <ResponsiveRow
                sx={{flexDirection: "column", width: "100%", alignItems: "center"}}
            >
                <ResponsiveRow
                    sx={{
                        width: "100%",
                        height: "auto",
                        flexWrap: "wrap",
                        flexDirection: "row",
                        justifyContent: "space-evenly",
                    }}
                >
                    <ResponsiveRow
                        sx={{
                            width: "100%",
                            height: "auto",

                            flexDirection: {xs: "column", lg: "row"},
                            flexWrap: "nowrap",

                            justifyContent: {xs: "center", lg: "space-between"},
                            alignItems: "center",

                            padding: 0,
                        }}
                    >
                        <Permit services="PREMIUM,CORE,LEAN">
                            <VacantUnits customHeight="500px" customWidth="49%"/>
                        </Permit>{" "}
                        <Permit services="PREMIUM,CORE,LEAN">
                            <ExpiringLeases customHeight="500px" customWidth="49%"/>
                        </Permit>
                    </ResponsiveRow>
                    <ResponsiveRow
                        sx={{
                            width: "100%",
                            height: "auto",

                            flexDirection: {xs: "column", lg: "row"},
                            flexWrap: "nowrap",

                            justifyContent: {xs: "center", lg: "space-between"},
                            alignItems: "center",

                            padding: 0,
                        }}
                    >
                        <Permit services="PREMIUM,CORE,HOA,LEAN,METER">
                            <UtilitiesIncomeVsExpenses
                                customWidth="49%"
                                customHeight="500px"
                            />
                        </Permit>
                        <Permit services="PREMIUM,CORE,HOA,LEAN,METER">
                            <UtilitiesReadVsUnread customWidth="49%" customHeight="500px"/>
                        </Permit>
                    </ResponsiveRow>
                    <ResponsiveRow
                        sx={{
                            width: "100%",
                            height: "auto",

                            flexDirection: {xs: "column", lg: "row"},
                            flexWrap: "nowrap",

                            justifyContent: {xs: "center", lg: "space-between"},
                            alignItems: "center",

                            padding: 0,
                        }}
                    >
                        <Permit services="PREMIUM,CORE,HOA,LEAN,METER">
                            <UtilitiesWorkingVsBroken
                                customWidth="49%"
                                customHeight="500px"
                            />
                        </Permit>{" "}
                        <Permit services="PREMIUM,CORE,HOA,LEAN,METER">
                            <UpcomingAssetMaintenance
                                customWidth="49%"
                                customHeight="500px"
                            />
                        </Permit>
                    </ResponsiveRow>
                </ResponsiveRow>
            </ResponsiveRow>
        </Permit>
    );
}
