import React from 'react'
import ModalContainerModule from '../modules/modalContainer'
import { FormControl, InputLabel, OutlinedInput, Typography } from '@mui/material'
import { ResponsiveRow } from '../../constants/layout.constants'
import FileUpload from 'react-material-file-upload'
import { bytesToMegabytes, isEmptyArray, isEmptyString } from '../../util/util'
import { issueWarnMessage, setMessage } from '../../actions/message'
import { createSupportTicket } from '../../services/support.service'
import Grid from "@mui/material/Grid";
import { useDispatch } from 'react-redux'

export default function HelpAndSupportForm(props) {
  const [subject, setSubject] = React.useState("")
  const [description, setDescription] = React.useState("")
  const [files, setFiles] = React.useState([])
  const MAX_FILE_UPLOAD_MB = 10

  const dispatch = useDispatch();

  const handleCreateTicketClicked = () => {
    if (!validateForm()) {
      return;
    }

    const formData = new FormData();

    files.forEach((file) => {
      formData.append("listingFiles[]", file);
    });

    formData.append("subject", subject);
    formData.append("description", description);

      createSupportTicket(formData)
        .then((response) => {
          dispatch(
            setMessage({
                type: response.type,
                message: response.message,
            })
          )
          setSubject("");
          setDescription("");
          setFiles([]);
          props.closeModal();
        })
  };

  function validateForm() {
    if (
      isEmptyString(subject)
    ) {
      issueWarnMessage("Subject is required.")
      return false
    }
    if (
      isEmptyString(description)
    ) {
      issueWarnMessage("Description is required.")
      return false
    }

    if(!isEmptyArray(files)){
      for (let i = 0; i < files.length; i++) {
        let fileSizeMb = bytesToMegabytes(files[i].size);
        if (fileSizeMb > MAX_FILE_UPLOAD_MB) {
          issueWarnMessage(
            "The file selected has a size (" +
              fileSizeMb.toFixed(2) +
              " MB) which is larger than the upload limit (" +
              MAX_FILE_UPLOAD_MB +
              ") MB"
          );
          return false
        }

        if(files[i].name.length > 200){
          issueWarnMessage(
            "The file selected has a name (" +
              files[i].name +
              ") which is larger than the upload limit (200) characters"
          );
          return false
        }
      }
    }

    return true
  }

  const handleInputChange = (e) => {
    switch (e.target.name) {
      case "subject":
        setSubject(e.target.value)
        break
      case "description":
        setDescription(e.target.value)
        break
      default:
        break
    }
  }

  const handleCancelClicked = () => {
    setSubject("")
    setDescription("")
    setFiles([])
    props.closeModal()
  }

  return (
    <ModalContainerModule
        size="md"
        accept={handleCreateTicketClicked}
        cancel={handleCancelClicked}
        openModal={props.openModal}
        modalTitle={ "Contact Support" }
        acceptButtonText={ "Create Ticket" }
      >
        <Typography
          variant="h5"
          component="h6"
          sx={{ marginBottom: "20px" }}
        >
          Ticket Details
        </Typography>

        <ResponsiveRow
          container
          // sx={{ height: "auto", margin: 0, paddingTop: 0 }}
        >
          <FormControl
            component={FormControl}
            sx={{
              width: { xs: "100%", lg: "33.3%" },
              marginBottom: "10px",
            }}
          >
            <InputLabel>Subject *</InputLabel>
            <OutlinedInput
              label="Subject *"
              sx={{ width: { xs: "100%", md: "90%" } }}
              name={"subject"}
              onChange={handleInputChange}
              value={subject}
            />
          </FormControl>
          <FormControl
            component={FormControl}
            sx={{
              width: { xs: "100%", md: "66.6%" },
              marginBottom: "10px",
            }}
          >
            <InputLabel>Description * </InputLabel>
            <OutlinedInput
              label="Description *"
              sx={{ width: { xs: "100%", md: "95%" } }}
              name={"description"}
              minRows={3}
              multiline
              onChange={handleInputChange}
              value={description}

            />
          </FormControl>
          <Grid
            item
            sx={{
                width: {xs: "100%", lg: "97%"},
                marginBottom: "10px",
            }}
          >
              <FileUpload value={files} onChange={setFiles}/>
          </Grid>

          {/* upload files here */}
        </ResponsiveRow>

      </ModalContainerModule>
  )
}
