/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import {
  Alert,
  AlertTitle,
  Button,
  Checkbox,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { Cancel } from "@mui/icons-material";
import { getOwnershipTerminationAccount } from "../services/ownership.service";
import {
  getCashPaymentAccounts,
  getChargeAccounts,
  getIncomeAccounts,
  getPaymentVoucherMethods,
  getVATAccount,
} from "../services/list.service";
import {
  formatCurrency,
  getVATAmount,
  isEmptyString,
  isNullUndefined,
  tryParseFloat,
} from "../util/util";
import { getMeterBillingAccounts } from "../services/meterbilling.service";
import { getLeaseTerminationAccount } from "../services/lease.service";
import { ModalAcceptButton } from "../constants/component.constants";
import { ResponsiveRow } from "../constants/layout.constants";
import ModalContainerModule from "./modules/modalContainer";
import { issueWarnMessage } from "../actions/message";

/**
 * This is a shared Contract Termination component to apply termination to:
 * - Meter Billing Page
 * - Lease Page
 * - Ownership Page
 *
 * Required props are:
 * - showTerminateModal: This is either true or false to show or hide modal respectively
 * - handleTerminateClose: This is a function to close the modal ie set showTerminateModal to false
 * - terminationMode: This is actual page you are implementing this from. Supported modes are Lease, Ownership and Meter Billing
 * - terminateItem: This is the payload to be updated and sent back to the terminate function
 * - contract: This is the data for either selected item of 'Lease, Ownership or Meter Billing'
 * - terminateContract: This is a function which takes in terminateItem
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 * @author jmbuthia
 * @description Validation is taken care of if you need to validate again you can validate the data passed to terminate function ie terminateItem
 */
export default function ContractTermination(props) {
  const [paymentVoucherMethods, setPaymentVoucherMethods] = useState([]);
  const [cashPaymentAccounts, setCashPaymentAccounts] = useState([]);
  const [incomeAccounts, setIncomeAccounts] = useState([]);
  const [chargeAccounts, setChargeAccounts] = useState([]);
  const [vatAccount, setVATAccount] = useState(null);
  const [contractName, setContractName] = useState("");
  const [chequeNumber, setChequeNumber] = useState("");
  const [paymentMethodCode, setPaymentMethodCode] = useState("");
  const [chargeItemsTotal, setChargeItemsTotal] = useState(0);
  const [items, setItems] = useState([]);
  const [chargeItems, setChargeItems] = useState([]);
  const [chargeItemsTableRows, setChargeItemsTableRows] = useState("");
  const [cashAccountId, setCashAccountId] = useState("");
  const [kenyaVatTax, setKenyaVatTax] = useState(0.16);
  const [totalDeposits, setTotalDeposits] = useState(0);
  const [currentBalance, setCurrentBalance] = useState(0);
  const [bankRefNumber, setBankRefNumber] = useState("");

  useEffect(() => {
    if (
      !isNullUndefined(props.contract) &&
      !isNullUndefined(props.contract.id)
    ) {
      if (props.terminationMode === "Ownership") {
        getOwnershipTerminationAccount(props.contract.id).then((response) => {
          setKenyaVatTax(response.kenyaVatTax);
          setCurrentBalance(response.titleBalance);
          setTotalDeposits(0);
        });
        setContractName("for " + props.contract.ownerNames);
      }

      if (props.terminationMode === "Meter Billing") {
        getMeterBillingAccounts(props.contract.id).then((response) => {
          setKenyaVatTax(response.kenyaVatTax);
          setCurrentBalance(response.meterBillingBalance);
          setTotalDeposits(response.totalDeposits);
        });
        setContractName("for " + props.contract.customer.name);
      }

      if (props.terminationMode === "Lease") {
        getLeaseTerminationAccount(props.contract.id).then((response) => {
          setKenyaVatTax(response.kenyaVatTax);
          setCurrentBalance(response.leaseBalance);
          setTotalDeposits(response.totalDeposits);
        });
        setContractName("for " + props.contract.tenant);
      }
    }

    getPaymentVoucherMethods().then((paymentVoucherMethods) =>
      setPaymentVoucherMethods(paymentVoucherMethods)
    );

    getChargeAccounts().then((chargeAccounts) =>
      setChargeAccounts(chargeAccounts)
    );

    getVATAccount().then((vatAccount) => setVATAccount(vatAccount));

    getCashPaymentAccounts().then((cashPaymentAccounts) =>
      setCashPaymentAccounts(cashPaymentAccounts)
    );

    getIncomeAccounts().then((incomeAccounts) =>
      setIncomeAccounts(incomeAccounts)
    );
  }, [props.terminationMode, props.contract]);

  useEffect(() => {
    populateChargeItems();
    populateTerminateChargeItems();
  }, [chargeItems]);

  function onTextChange(event) {
    const value = event.target.value;
    const id = event.target.id;
    if (id === "chequeNumber") {
      setChequeNumber(value);
    } else if (id === "bankRefNo") {
      setBankRefNumber(value);
    }
  }

  function chargeAmountChange(event, index) {
    const value = event.target.value;
    let newArr = [...items]; // copying the old items array
    newArr[index].amount = value;
    setItems(newArr);
    populateChargeItems();
  }

  function terminateAmountChange(event, index) {
    const value = event.target.value;
    let newArr = [...items]; // copying the old items array
    newArr[index].amount = value;
    if (newArr[index].vatTaxed) {
      let amount = tryParseFloat(value, -1);
      if (amount > 0) {
        let total = amount + amount * kenyaVatTax;
        newArr[index].total = total.toFixed(2);
      }
    } else {
      newArr[index].total = value;
    }
    setItems(newArr);
    populateTerminateChargeItems();
  }

  function findAndRemoveChargeVAT(detailItem) {
    let vatAmount = getVATAmount(detailItem.amount);
    for (let i = 0; i < items.length; i++) {
      if (
        items[i].amount === vatAmount &&
        items[i].notes.includes(detailItem.notes)
      ) {
        handleChargeItemRemove(i);
      }
    }
    populateChargeItems();
  }

  function handleChargeCheckboxToggle(event, index) {
    let newArr = [...items]; // copying the old items array
    let detailItem = newArr[index];
    if (isNullUndefined(detailItem.amount) || detailItem.amount === "0") {
      issueWarnMessage("You must enter an amount before applying VAT");
    } else {
      if (event.target.checked) {
        let vatNotes = "VAT (16%) - " + detailItem.notes;
        let vatAmount = getVATAmount(detailItem.amount);
        detailItem.applyVAT = true;
        setItems(newArr);
        setItems((items) => [
          ...items,
          {
            accountId: vatAccount.id,
            notes: vatNotes,
            amount: vatAmount,
            applyVAT: false,
            displayVATRadio: false,
          },
        ]);
        setChargeItems((chargeItems) => [
          ...chargeItems,
          {
            accountId: vatAccount.id,
            notes: vatNotes,
            amount: vatAmount,
            applyVAT: false,
            displayVATRadio: false,
          },
        ]);
      } else {
        detailItem.applyVAT = false;
        setItems(newArr);
        setChargeItems(newArr);
        findAndRemoveChargeVAT(detailItem);
      }
    }
  }

  function handleTerminateChargeCheckboxToggle(event, index) {
    let newArr = [...items]; // copying the old items array
    let detailItem = newArr[index];
    if (isNullUndefined(detailItem.amount) || detailItem.amount === "0") {
      issueWarnMessage("You must enter an amount before applying VAT");
    } else {
      if (event.target.checked) {
        let amount = tryParseFloat(detailItem.amount, -1);
        if (amount > 0) {
          let total = amount + amount * kenyaVatTax;
          detailItem.total = total.toFixed(2);
          detailItem.vatTaxed = true;
          setItems(newArr);
          setChargeItems(newArr);
        }
      } else {
        let amount = tryParseFloat(detailItem.amount, -1);
        if (amount > 0) {
          detailItem.total = amount;
          detailItem.vatTaxed = false;
          setItems(newArr);
          setChargeItems(newArr);
        }
      }
    }
  }

  function chargeItemMemoChange(event, index) {
    const value = event.target.value;
    let newArr = [...items]; // copying the old items array
    newArr[index].notes = value;
    setItems(newArr);
    populateChargeItems();
  }

  function terminateItemMemoChange(event, index) {
    const value = event.target.value;
    let newArr = [...items]; // copying the old items array
    newArr[index].memo = value;
    setItems(newArr);
    populateTerminateChargeItems();
  }

  function handleChargeItemRemove(index) {
    let newArr = [...items]; // copying the old items array
    if (newArr.length > 1) {
      findAndRemoveChargeVAT(items[index]);
      newArr.splice(index, 1);
      setItems(newArr);
      setChargeItems(items);
    } else {
      issueWarnMessage("There must be at least one charge");
    }
  }

  function handleTerminateChargeItemRemove(index) {
    let newArr = [...items]; // copying the old items array
    newArr.splice(index, 1);
    setItems(newArr);
    setChargeItems(items);
    populateTerminateChargeItems();
  }

  function handleTerminateChargeItemAdd() {
    const charge = {
      creditAccCode: "",
      memo: "",
      amount: 0,
      vatTaxed: false,
      total: 0, // readOnly
    };
    setItems((items) => [...items, charge]);
    setChargeItems((chargeItems) => [...chargeItems, charge]);
  }

  function handlePaymentMethodChange(event) {
    let value = event.target.value;
    setPaymentMethodCode(value);
    if (value === "CSH") {
      setChequeNumber("");
      setBankRefNumber("");
    }
    if (value === "CHQ") {
      setCashAccountId("");
      setBankRefNumber("");
    }
    if (value === "RTGS") {
      setCashAccountId("");
      setChequeNumber("");
    }
  }

  function handleCashPaymentAccountChange(event) {
    let value = event.target.value;
    setCashAccountId(value);
  }

  function populateChargeItems() {
    let rows = "";
    items &&
      items.length > 0 &&
      (rows = items.map(function (chargeItem, i) {
        return (
          <TableRow key={i}>
            <TableCell>
              <Select
                sx={{ width: "90%" }}
                id={"chargeAccount" + i}
                name={"chargeAccount" + i}
                isrequired={true}
                disabled={items && items[i] && !items[i].displayVATRadio}
                value={items && items[i] && items[i].accountId}
                onChange={(event) => handleChargeAccountChange(event, i)}
              >
                {chargeAccounts && chargeAccounts.length > 0 ? (
                  chargeAccounts.map(function (chargeAccount, i) {
                    return (
                      <MenuItem key={i} value={chargeAccount.value}>
                        {chargeAccount.label}
                      </MenuItem>
                    );
                  }, this)
                ) : (
                  <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
                )}
              </Select>
            </TableCell>
            <TableCell>
              <TextField
                sx={{ width: "90%" }}
                id={"chargeItemMemo" + i}
                name={"chargeItemMemo" + i}
                isrequired={true}
                disabled={items && items[i] && !items[i].displayVATRadio}
                value={items && items[i] && items[i].notes}
                onChange={(event) => chargeItemMemoChange(event, i)}
              />
            </TableCell>
            <TableCell>
              <TextField
                sx={{ width: "90%" }}
                id={"chargeAmount" + i}
                name={"chargeAmount" + i}
                isrequired={true}
                disabled={items && items[i] && !items[i].displayVATRadio}
                value={items && items[i] && items[i].amount}
                onChange={(event) => chargeAmountChange(event, i)}
              />
            </TableCell>
            <TableCell>
              {items && items[i] && items[i].displayVATRadio && (
                <Checkbox
                  checked={items && items[i] && items[i].applyVAT}
                  onChange={(event) => handleChargeCheckboxToggle(event, i)}
                  inputProps={{ "aria-label": "controlled" }}
                />
              )}
            </TableCell>
            <TableCell>
              {items && items[i] && items[i].displayVATRadio && (
                <Button
                  variant="contained"
                  type="submit"
                  sx={{ marginLeft: "10px" }}
                  onClick={() => handleChargeItemRemove(i)}
                  color="error"
                >
                  <Cancel />
                </Button>
              )}
            </TableCell>
          </TableRow>
        );
      }, this));
    setChargeItemsTableRows(rows);
  }

  function populateTerminateChargeItems() {
    let rows = "";
    let total = 0;
    items &&
      items.length > 0 &&
      (rows = items.map(function (chargeItem, i) {
        let amount = tryParseFloat(chargeItem.total, -1);
        if (amount > 0) {
          total = total + amount;
        }
        return (
          <TableRow key={i}>
            <TableCell>
              <Select
                sx={{ width: "90%" }}
                id={"incomeAccount" + i}
                name={"incomeAccount" + i}
                isrequired={true}
                value={items && items[i] && items[i].creditAccCode}
                onChange={(event) =>
                  handleTerminateChargeAccountChange(event, i)
                }
              >
                {incomeAccounts && incomeAccounts.length > 0 ? (
                  incomeAccounts.map(function (incomeAccount, i) {
                    return (
                      <MenuItem key={i} value={incomeAccount.value}>
                        {incomeAccount.label}
                      </MenuItem>
                    );
                  }, this)
                ) : (
                  <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
                )}
              </Select>
            </TableCell>
            <TableCell>
              <TextField
                sx={{ width: "90%" }}
                id={"terminateItemMemo" + i}
                name={"terminateItemMemo" + i}
                isrequired={true}
                value={items && items[i] && items[i].memo}
                onChange={(event) => terminateItemMemoChange(event, i)}
              />
            </TableCell>
            <TableCell>
              <TextField
                sx={{ width: "90%" }}
                id={"terminateAmount" + i}
                name={"terminateAmount" + i}
                isrequired={true}
                value={items && items[i] && items[i].amount}
                onChange={(event) => terminateAmountChange(event, i)}
              />
            </TableCell>
            <TableCell>
              <Checkbox
                checked={items && items[i] && items[i].vatTaxed}
                onChange={(event) =>
                  handleTerminateChargeCheckboxToggle(event, i)
                }
                inputProps={{ "aria-label": "controlled" }}
              />
            </TableCell>
            <TableCell>
              <TextField
                sx={{ width: "90%" }}
                id={"terminateTotalAmount" + i}
                name={"terminateTotalAmount" + i}
                isrequired={true}
                value={items && items[i] && items[i].total}
                disabled={items && items.length > 0}
              />
            </TableCell>
            <TableCell>
              <Button
                variant="contained"
                type="submit"
                sx={{ marginLeft: "10px" }}
                onClick={() => handleTerminateChargeItemRemove(i)}
                color="error"
              >
                <Cancel />
              </Button>
            </TableCell>
          </TableRow>
        );
      }, this));
    setChargeItemsTableRows(rows);
    setChargeItemsTotal(total);
  }

  function handleChargeAccountChange(event, index) {
    let value = event.target.value;
    let newArr = [...items]; // copying the old items array
    newArr[index].accountId = value;
    setItems(newArr);
    populateChargeItems();
  }

  function handleTerminateChargeAccountChange(event, index) {
    let value = event.target.value;
    let newArr = [...items]; // copying the old items array
    newArr[index].creditAccCode = value;
    setItems(newArr);
    populateTerminateChargeItems();
  }

  function isChargeObjectValid(i, charge) {
    let pos = i + 1;
    if (isNullUndefined(charge)) {
      return "Charge " + pos + " could not be validated. Refresh the page";
    }
    if (isEmptyString(charge.creditAccCode)) {
      return "Charge " + pos + " must have an income account selected";
    }
    if (isEmptyString(charge.memo)) {
      return "Charge " + pos + " must have its memo filled";
    }
    /*if (!isNumeric(charge.amount)) {
                    return "Charge " + pos + " must have a valid number for the amount"
                }*/
    if (charge.amount <= 0) {
      return "Charge " + pos + " should have an amount greater than 0";
    }
    return "yes"; // return same type, different value
  }

  function validateTerminateForm(data) {
    if (currentBalance + chargeItemsTotal - totalDeposits < 0) {
      // validation only for negative final balance i.e. inverse of what is shown
      if (isEmptyString(data.paymentMethod)) {
        issueWarnMessage(
          "Cannot submit. Ensure you select a payment method for the voucher when final balance is less than KSH 0."
        );
        return false;
      }
      if (data.paymentMethod === "CSH" && isEmptyString(data.cashAccountId)) {
        issueWarnMessage(
          "Cannot submit. Select a cash account for the payment voucher to be issued."
        );
        return false;
      }
      if (data.paymentMethod === "CHQ" && isEmptyString(data.chequeNumber)) {
        issueWarnMessage(
          "Cannot submit. Enter a cheque number for the payment voucher."
        );
        return false;
      }
      if (data.paymentMethod === "RTGS" && isEmptyString(data.bankRefNumber)) {
        issueWarnMessage(
          "Cannot submit. Enter a Bank Reference Number for the payment voucher."
        );
        return false;
      }
    }
    for (let i = 0; i < data.charges.length; i++) {
      let result = isChargeObjectValid(i, data.charges[i]);
      if (result !== "yes") {
        issueWarnMessage(
          "Cannot submit. There was a problem with one of the charges."
        );
        return false;
      }
    }
    return true;
  }

  function terminateContract() {
    let terminateData = props.terminateItem;
    terminateData.paymentMethod = paymentMethodCode;
    terminateData.cashAccountId = cashAccountId;
    terminateData.chequeNumber = chequeNumber;
    terminateData.bankRefNumber = bankRefNumber;
    terminateData.charges = items;
    let isFormValid = validateTerminateForm(terminateData);
    if (isFormValid === true) {
      props.terminateContract(terminateData);
    }
  }

  function cancelTerminationProcess() {
    props.handleTerminateClose();
    setItems([]); //Clear items
    setChargeItems([]); //Clear items
    setPaymentMethodCode("");
    setChequeNumber("");
    setCashAccountId("");
    setBankRefNumber("");
  }

  return (
    <>
      <ModalContainerModule
        size="md"
        accept={terminateContract}
        cancel={cancelTerminationProcess}
        openModal={props.showTerminateModal}
        modalTitle={`Terminate ${props.terminationMode} ${contractName}`}
        acceptButtonText={`Terminate ${props.terminationMode}`}
      >
        <ResponsiveRow
          sx={{
            width: "100%",
            flexDirection: "column",
            flexWrap: "nowrap",
            height: "auto",
          }}
          container
        >
          <Grid
            item
            sx={{ width: "100%", height: "auto", marginBottom: "10px" }}
          >
            <Alert severity="error">
              <AlertTitle>
                Review the {props.terminationMode} deposit amounts and any
                balances/prepayments on the {props.terminationMode}.
              </AlertTitle>
              You can then add charges to be taken out of the accounts before
              finalizing and terminating the {props.terminationMode}.
              <p>
                {props.terminationMode}{" "}
                {currentBalance > 0 ? "Balance" : "Prepayment"}: KSH{" "}
                {formatCurrency(Math.abs(currentBalance))}
              </p>
              <p>
                Total {props.terminationMode} Deposits: KSH{" "}
                {formatCurrency(Math.abs(totalDeposits))}
              </p>
            </Alert>
          </Grid>
          <Divider />

          <ResponsiveRow sx={{ height: "auto" }} item>
            <Typography id="modal-modal-title" variant="h6" component="h1">
              Charges
            </Typography>

            <TableContainer style={{ maxHeight: 300 }}>
              <Table
                sx={{ minWidth: 20 }}
                aria-label="simple table"
                stickyHeader
              >
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ width: "30%" }}>Income Account</TableCell>
                    <TableCell sx={{ width: "30%" }}>Memo</TableCell>
                    <TableCell sx={{ width: "20%" }}>Amount</TableCell>
                    <TableCell sx={{ width: "5%" }}>
                      VAT{" "}
                      {!isNaN(kenyaVatTax)
                        ? "(" + (kenyaVatTax * 100).toFixed(2) + "%)"
                        : ""}
                    </TableCell>
                    <TableCell sx={{ width: "20%" }}>Total</TableCell>
                    <TableCell sx={{ width: "50%" }}>Remove</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{chargeItemsTableRows}</TableBody>
              </Table>
            </TableContainer>
          </ResponsiveRow>
          <ResponsiveRow
            sx={{
              justifyContent: { xs: "center", lg: "flex-end" },
              height: "auto",
            }}
          >
            <ModalAcceptButton
              // type="submit"
              onClick={handleTerminateChargeItemAdd}
              sx={{
                width: { xs: "100%", lg: "200px" },
              }}
            >
              Add row{" "}
            </ModalAcceptButton>
          </ResponsiveRow>
          <ResponsiveRow
            sx={{
              width: "100%",
              height: "auto",

              flexDirection: "row",
              flexWrap: "nowrap",

              padding: 0,
            }}
          >
            <Alert
              severity="info"
              sx={{
                width: "100%",
                textAlign: "end",
                height: "auto",
                marginTop: "10px",
              }}
            >
              {" "}
              Total: {formatCurrency(chargeItemsTotal.toFixed(2))}
            </Alert>
          </ResponsiveRow>
        </ResponsiveRow>

        {(currentBalance + chargeItemsTotal - totalDeposits > 0 ||
          currentBalance + chargeItemsTotal - totalDeposits < 0) && (
          <ResponsiveRow
            container
            sx={{ width: "100%", height: "auto", flexDirection: "column" }}
          >
            {currentBalance + chargeItemsTotal - totalDeposits < 0 && (
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h1"
                sx={{ marginBottom: "10px" }}
              >
                Payment Voucher Details
              </Typography>
            )}

            <ResponsiveRow
              sx={{
                width: "100%",
                flexDirection: "row",
                flexWrap: "nowrap",
                height: "auto",

                padding: 0,
              }}
            >
              {currentBalance + chargeItemsTotal - totalDeposits > 0 ? (
                <Alert
                  severity="error"
                  sx={{
                    width: "100%",
                  }}
                >
                  After charging the customer there is a balance of KES{" "}
                  {formatCurrency(
                    (currentBalance + chargeItemsTotal - totalDeposits).toFixed(
                      2
                    )
                  )}{" "}
                  due. A final invoice will be issued to collect this amount.
                </Alert>
              ) : (
                <Alert
                  severity="info"
                  sx={{
                    width: "100%",
                  }}
                >
                  After charging the customer there is a balance of KES{" "}
                  {formatCurrency(
                    Math.abs(currentBalance + chargeItemsTotal - totalDeposits)
                  )}{" "}
                  that will be paid back.
                </Alert>
              )}
            </ResponsiveRow>
            <br />
            <ResponsiveRow
              sx={{
                width: "100%",
                height: "auto",
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              {currentBalance + chargeItemsTotal - totalDeposits < 0 && (
                <FormControl sx={{ width: { xs: "100%", lg: "50%" } }}>
                  <InputLabel>Payment Voucher Issued From:</InputLabel>
                  <Select
                    label="Payment Voucher Issued From:"
                    sx={{ width: { xs: "100%", lg: "90%" } }}
                    id={"paymentMethodCode"}
                    name={"paymentMethodCode"}
                    value={paymentMethodCode}
                    isrequired={
                      currentBalance + chargeItemsTotal - totalDeposits < 0
                    }
                    onChange={(event) => handlePaymentMethodChange(event)}
                  >
                    {paymentVoucherMethods &&
                    paymentVoucherMethods.length > 0 ? (
                      paymentVoucherMethods.map(function (paymentMethod, i) {
                        return (
                          <MenuItem key={i} value={paymentMethod.value}>
                            {paymentMethod.label}
                          </MenuItem>
                        );
                      }, this)
                    ) : (
                      <MenuItem sx={{ width: "100%" }}>
                        No Results Found
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              )}
              {currentBalance + chargeItemsTotal - totalDeposits < 0 &&
                paymentMethodCode === "CSH" && (
                  <FormControl
                    sx={{
                      width: { xs: "100%", lg: "50%" },
                      marginBottom: "10px",
                    }}
                  >
                    <InputLabel>Cash Account:</InputLabel>
                    <Select
                      label="Cash Account"
                      sx={{ width: { xs: "100%", lg: "90%" } }}
                      id={"cashAccountId"}
                      name={"cashAccountId"}
                      value={cashAccountId}
                      isrequired={paymentMethodCode === "CSH"}
                      onChange={(event) =>
                        handleCashPaymentAccountChange(event)
                      }
                    >
                      {cashPaymentAccounts && cashPaymentAccounts.length > 0 ? (
                        cashPaymentAccounts.map(function (
                          cashPaymentAccount,
                          i
                        ) {
                          return (
                            <MenuItem key={i} value={cashPaymentAccount.value}>
                              {cashPaymentAccount.label}
                            </MenuItem>
                          );
                        },
                        this)
                      ) : (
                        <MenuItem sx={{ width: "100%" }}>
                          No Results Found
                        </MenuItem>
                      )}
                    </Select>
                  </FormControl>
                )}
              {currentBalance + chargeItemsTotal - totalDeposits < 0 &&
                paymentMethodCode === "CHQ" && (
                  <FormControl
                    sx={{
                      width: { xs: "100%", lg: "50%" },
                      marginBottom: "10px",
                    }}
                  >
                    <InputLabel>Cheque No</InputLabel>
                    <OutlinedInput
                      label="Cheque No"
                      sx={{ width: { xs: "100%", lg: "90%" } }}
                      id={"chequeNumber"}
                      name={"chequeNumber"}
                      isrequired={paymentMethodCode === "CHQ"}
                      value={chequeNumber}
                      onChange={(event) => onTextChange(event)}
                    />
                  </FormControl>
                )}
              {currentBalance + chargeItemsTotal - totalDeposits < 0 &&
                paymentMethodCode === "RTGS" && (
                  <FormControl
                    sx={{
                      width: { xs: "100%", lg: "50%" },
                      marginBottom: "10px",
                    }}
                  >
                    <InputLabel>Bank Reference Number</InputLabel>
                    <OutlinedInput
                      label="Bank Reference Number"
                      sx={{ width: { xs: "100%", lg: "90%" } }}
                      id={"bankRefNo"}
                      name={"BankRefNo"}
                      isrequired={paymentMethodCode === "RTGS"}
                      value={bankRefNumber}
                      onChange={(event) => onTextChange(event)}
                    />
                  </FormControl>
                )}
            </ResponsiveRow>
          </ResponsiveRow>
        )}

        {(currentBalance + chargeItemsTotal - totalDeposits > 0 ||
          currentBalance + chargeItemsTotal - totalDeposits < 0) && <br />}
      </ModalContainerModule>
    </>
  );
}
