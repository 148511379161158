/* eslint-disable no-unused-vars */
// customers page for the bulk water subscription
import {
    AddContainer,
    InnerModal,
    MainContainer,
    ModalAcceptButton,
    ModalCancelButton,
    TopBarContainedBrandButton,
    TopBarContainer
} from "../constants/component.constants"
import Grid from "@mui/material/Grid"
import {Divider, InputLabel, MenuItem, Paper, Select, TextField, Typography,} from "@mui/material"
import {useCallback, useEffect, useState} from "react"
import {formatCurrency, isEmptyString, isNullUndefined,} from "../util/util"
import {GridActionsCellItem} from "@mui/x-data-grid"
import {Edit, LocalAtm, Visibility} from "@mui/icons-material"
import PersonOrgSearch from "../components/modals/personOrgSearch"
import AddNewUser from "../components/modals/addNewUser"
import AddNewOrganisation from "../components/modals/addNewOrganisation"
import {
    getCustomers,
    getFacilityCustomers,
    getPendingTransactions,
    saveCustomer,
    saveFacilityCustomer,
} from "../services/customers.service"
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider"
import {DatePicker} from "@mui/x-date-pickers/DatePicker"
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs"
import "dayjs/locale/en-gb"
import {getMeterBillingPeriods, saveMeterBillingPayment,} from "../services/meterbilling.service"
import {getIdTypes} from "../services/list.service"
import ReceivePayment from "../components/modals/receivePayment"
import {printCustomerSalesStatement} from "../services/print.service"
import {issueResponseMessage} from "../actions/message"
import Permit from "../util/permit"
import CreditNote from "../components/modals/creditNote"
import DebitNote from "../components/modals/debitNote"
import dayjs from "dayjs"
import MuiDataGrid from "../components/modules/MuiDataGrid"
import {useSelector} from "react-redux";
import CustomerView from "./customerView";

export default function Customers() {
    const user = useSelector((state) => state.auth.user);
    const [AddDiv, setAddDiv] = useState(false)
    const [ViewDiv, setViewDiv] = useState(false)
    const [CustomerModal, setCustomerModal] = useState(false)
    const [PaymentModal, setPaymentModal] = useState(false)
    const [AddOrganizationModal, setAddOrganizationModal] = useState(false)
    const [AddUserModal, setAddUserModal] = useState(false)
    const [loading, setLoading] = useState(true)
    const [rows, setRows] = useState([])
    const [facilityClients, setFacilityClients] = useState([])
    const [pendingTransactions, setPendingTransactions] = useState([])
    const [billingPeriods, setBillingPeriods] = useState([])
    const [idTypes, setIdTypes] = useState([])
    const [selectedCustomer, setSelectedCustomer] = useState(null)
    const [statementYear, setStatementYear] = useState(new Date())
    const [task, setTask] = useState("")
    const [creditModal, setCreditModal] = useState(false)
    const [chargeModal, setChargeModal] = useState(false)
    const [voidItem, setVoidItem] = useState(null)
    const [companySearch, setCompanySearch] = useState("");

    const handleClose = () => {
        setSelectedCustomer(null)
        setAddDiv(false)
    }

    useEffect(() => {
        setLoading(true)
        if(user.services.includes("MAINT")){
            getFacilityCustomers().then((rows) => {
                setFacilityClients(rows)
            })
        } else {
            getCustomers().then((rows) => setRows(rows))
            getMeterBillingPeriods().then((billingPeriods) =>
                setBillingPeriods(billingPeriods)
            )
        }
        getIdTypes().then((idTypes) => setIdTypes(idTypes))
        setLoading(false)
    }, [user])

    useEffect(() => {
        if (
            !isNullUndefined(selectedCustomer) &&
            !isEmptyString(selectedCustomer.billingId)
        ) {
            setLoading(true)
            getPendingTransactions(selectedCustomer.billingId).then(
                (pendingTransactions) => setPendingTransactions(pendingTransactions)
            )
            setLoading(false)
        }
    }, [selectedCustomer])

    const addCustomer = useCallback((data) => {
        let sendData = {
            selectedCustomer: data,
        }
        saveCustomer(sendData).then((response) => {
            issueResponseMessage(response)
            if (!isNullUndefined(response)) {
                getCustomers().then((rows) => setRows(rows))
            }
            setAddUserModal(false)
            setAddOrganizationModal(false)
        })
    }, [])

    const addFacilityCustomer = useCallback((data) => {
        let sendData = {
            selectedCustomer: data,
        }
        saveFacilityCustomer(sendData).then((response) => {
            issueResponseMessage(response)
            if (!isNullUndefined(response)) {
                getFacilityCustomers().then((rows) => setFacilityClients(rows))
            }
            setAddUserModal(false)
            setAddOrganizationModal(false)
        })
    }, [])

    function savePayment(data) {
        data.meterBillingId = selectedCustomer.billingId
        saveMeterBillingPayment(data).then((response) => {
            issueResponseMessage(response)
            if (!isNullUndefined(response)) {
                getCustomers().then((rows) => setRows(rows))
                setPaymentModal(false)
            }
        })
    }

    function handleViewClick(customer) {
        setSelectedCustomer(customer)
        setViewDiv(true)
    }

    function handleViewClient(customer) {
        setSelectedCustomer(customer)
        setViewDiv(true)
    }

    function handleEditClick(customer) {
        setTask("Edit")
        setSelectedCustomer(customer)
        if (customer.type === "Individual") {
            setAddUserModal(true)
        } else if (customer.type === "Company") {
            setAddOrganizationModal(true)
        }
    }

    function handlePaymentClick(customer) {
        setSelectedCustomer(customer)
        setPaymentModal(true)
    }

    function handlePersonOrgSelect(result) {
        setSelectedCustomer(result)
        setTask("Add")
        if (result.type === "Individual") {
            setAddUserModal(true)
        } else {
            setAddOrganizationModal(true)
        }
        setCustomerModal(false)
    }

    function handleBillingPeriodChange(event) {
        let value = event.target.value
        let obj = selectedCustomer
        obj.billingPeriod = value
        setSelectedCustomer(obj)
    }

    function handleNextBillingDateChange(value) {
        let date = new Date(value)
        let obj = selectedCustomer
        obj.billingDate = date
        setSelectedCustomer(obj)
    }

    function handleIdTypeChange(event) {
        let obj = selectedCustomer
        obj.idTypeCd = event.target.value
        setSelectedCustomer(obj)
    }

    function handleStatementYearChange(value) {
        let date = new Date(value)
        setStatementYear(date)
    }

    function handlePrintCustomerStatement() {
        printCustomerSalesStatement(
            selectedCustomer.billingId,
            statementYear.getFullYear()
        )
    }

    function handleNewIndividual(idNoSearch, idTypeSearch) {
        setTask("Add")
        setSelectedCustomer((prevState) => ({
            ...prevState,
            type: "Individual",
            organization: null,
            idNumber: idNoSearch,
            idTypeCd: idTypeSearch,
        }))
        setCustomerModal(false)
        setAddUserModal(true)
    }

    function handleNewCompany(company) {
        setCompanySearch(company);
        setTask("Add")
        setSelectedCustomer((prevState) => ({
            ...prevState,
            id: null,
            name: company,
            description: "",
            taxId: "",
            address: {
                id: "",
                address1: "",
                address2: "",
                countryCd: "",
                city: "",
            },
            contact: {
                id: "",
                firstName: "",
                middleName: "",
                lastName: "",
                mobilePhone: "",
                officePhone: "",
                email: "",
                credentialId: "",
                idNumber: "",
                idTypeCd: "",
            },
            absoluteCreditLimit: "",
            billingPeriod: "",
            billingDay: "",
            type: "Company",
        }))
        setCustomerModal(false)
        setAddOrganizationModal(true)
    }

    const columns = [
        {
            field: "billingCode",
            headerName: "Billing Code",
            minWidth: 200,
            flex: 2,
        },
        {field: "name", headerName: "Account Holder", minWidth: 400, flex: 4},
        {
            field: "billingBalance",
            headerName: "Account Balance",
            minWidth: 200,
            flex: 2,
            valueFormatter: (row) => {
                return formatCurrency(row.value)
            },
        },
        {
            field: "availableCreditLimit",
            headerName: "Available Credit Limit",
            minWidth: 200,
            flex: 2,
            valueFormatter: (row) => {
                return formatCurrency(row.value)
            },
        },
        {
            field: "absoluteCreditLimit",
            headerName: "Absolute Credit Limit",
            minWidth: 300,
            flex: 3,
            valueFormatter: (row) => {
                return formatCurrency(row.value)
            },
        },
        {
            field: "action",
            headerName: "Actions",
            width: 100,
            type: "actions",
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<Visibility/>}
                    label="View"
                    onClick={() => handleViewClick(params.row)}
                    showInMenu
                />,
                <GridActionsCellItem
                    icon={<Edit/>}
                    label="Edit"
                    onClick={() => handleEditClick(params.row)}
                    showInMenu
                />,
                <GridActionsCellItem
                    icon={<LocalAtm/>}
                    label="Receive Payment"
                    onClick={() => handlePaymentClick(params.row)}
                    showInMenu
                />,
            ],
        },
    ]

    const columns1 =  [
        {
            field: "name",
            headerName: "First Name",
            minWidth: 200,
            flex: 2,
        },
        {
            field: "email",
            headerName: "Email",
            minWidth: 200,
            flex: 2,
            valueGetter: ({ row }) => {
                if (!isNullUndefined(row.email)) {
                    return row.email
                }
                else if(!isNullUndefined(row.contact)){
                    if(!isNullUndefined(row.contact && row.contact.email)){
                        return row.contact && row.contact.email
                    }
                }else{
                    return ""
                }
            }
        },
        {
            field: "mobilePhone",
            headerName: "Phone Number",
            minWidth: 200,
            flex: 2,
            valueGetter: ({ row }) => {
                if (!isNullUndefined(row.mobilePhone)) {
                    return row.mobilePhone
                }
                else if(!isNullUndefined(row.contact)){
                    if(!isNullUndefined(row.contact && row.contact.mobilePhone)){
                        return row.contact && row.contact.mobilePhone
                    }
                }else{
                    return ""
                }
            }
        },
        {
            field: "action",
            headerName: "Actions",
            width: 100,
            type: "actions",
            getActions: (params) => {
                let arr = []
                if (user.proxyRole === "BLDIR"){
                    arr.push(
                        <GridActionsCellItem
                            icon={<Edit/>}
                            label="Edit"
                            onClick={() => handleEditClick(params.row)}
                            showInMenu
                        />
                    )
                }
                if (user.proxyRole === "BLDIR" || user.proxyRole === "BLDMG" || user.proxyRole === "CS"){
                    arr.push(
                        <GridActionsCellItem
                            icon={<Visibility/>}
                            label="View"
                            onClick={() => handleViewClient(params.row)}
                            showInMenu
                        />
                    )
                }
                return arr
            }
        }
    ]

    function handleVoidClick(item) {
        if (item.type === "Charge" || item.type === "Invoice") {
            setCreditModal(true)
            //set the id of the transaction being voided
            setVoidItem(item)
        } else if (item.type === "Receipt" || item.type === "Credit") {
            setChargeModal(true)
            setVoidItem(item)
        }
    }

    const handleCreditClose = () => {
        setVoidItem(null)
        setCreditModal(false)
    }

    const handleChargeClose = () => {
        setVoidItem(null)
        setChargeModal(false)
    }

    return (
        <Permit
            roles="BLDIR,BLDMG,CS"
            services="BLWTR,MAINT"
            noPermittedServiceMessage={{
                title: "Access Denied",
                body: (
                    <>
                        Your current role and/or facility is not permitted to view this
                        page. <br/> Please contact your system admin if you feel this is
                        an error.
                    </>
                ),
            }}
        >
            <MainContainer>
                {!ViewDiv && (
                    <TopBarContainer container width={{xs: "100%", lg: "80%"}}>
                        <Grid item sx={{width: {xs: "100%", lg: "50%"}}}>
                            <Typography
                                variant="h5"
                                sx={{
                                    margin: "auto 0",
                                    textAlign: {xs: "center", lg: "start"},
                                }}
                            >
                                Customers Page
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            sx={{width: {xs: "100%", lg: "50%"}, textAlign: "end"}}
                        >
                            <Permit roles={"BLDIR"}>
                                <TopBarContainedBrandButton
                                    variant="contained"
                                    type="submit"
                                    onClick={() => setCustomerModal(true)}
                                    sx={{
                                        width: {xs: "100%", md: "200px"},
                                    }}
                                >
                                    Add Customer
                                </TopBarContainedBrandButton>
                            </Permit>
                        </Grid>
                    </TopBarContainer>
                )}
                <br/>

                {!ViewDiv && (!user.services.includes("MAINT")) && (
                    <MuiDataGrid
                        noSelection={true}
                        dataGridColumns={columns}
                        dataGridRows={rows}
                        serverPagination={false}
                        loading={loading}
                        height="60vh"
                        totalRows={rows.length}
                    />
                )}

                {AddDiv && (
                    <AddContainer>
                        <Paper
                            variant=""
                            sx={{
                                height: "auto",
                                margin: "auto",
                                padding: "20px",
                                textAlign: "left",
                            }}
                        >
                            <Typography id="modal-modal-title" variant="h3" component="h2">
                                Customer Details
                            </Typography>
                            <Divider/>
                            <br/>

                            <Paper
                                variant=""
                                sx={{
                                    height: "auto",
                                    margin: "auto",
                                    padding: "10px",
                                    textAlign: "left",
                                }}
                            >
                                <Typography
                                    sx={{marginBottom: "5px"}}
                                    id="modal-modal-title"
                                    variant="h5"
                                    component="h4"
                                >
                                    Billing Details
                                </Typography>
                                <InnerModal
                                    container
                                    sx={{
                                        display: "flex",
                                        flexDirection: {xs: "column", lg: "row"},
                                    }}
                                >
                                    <Grid
                                        item
                                        sx={{
                                            width: {xs: "100%", lg: "33.3%"},
                                            marginBottom: "10px",
                                        }}
                                    >
                                        <InputLabel>First Name *</InputLabel>
                                        <TextField
                                            id="name"
                                            sx={{
                                                width: {xs: "100%", md: "90%"},
                                                fontSize: "10px",
                                            }}
                                            onChange={(event) => {
                                            }}
                                            value={selectedCustomer && selectedCustomer.firstName}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        sx={{
                                            width: {xs: "100%", lg: "33.3%"},
                                            marginBottom: "10px",
                                        }}
                                    >
                                        <InputLabel>Middle Name</InputLabel>
                                        <TextField
                                            id="name"
                                            sx={{
                                                width: {xs: "100%", md: "90%"},
                                                fontSize: "10px",
                                            }}
                                            onChange={(event) => {
                                            }}
                                            value={selectedCustomer && selectedCustomer.middleName}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        sx={{
                                            width: {xs: "100%", lg: "33.3%"},
                                            marginBottom: "10px",
                                        }}
                                    >
                                        <InputLabel>Last Name *</InputLabel>
                                        <TextField
                                            id="name"
                                            sx={{
                                                width: {xs: "100%", md: "90%"},
                                                fontSize: "10px",
                                            }}
                                            onChange={(event) => {
                                            }}
                                            value={selectedCustomer && selectedCustomer.lastName}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        sx={{
                                            width: {xs: "100%", lg: "33.3%"},
                                            marginBottom: "10px",
                                        }}
                                    >
                                        <InputLabel>Mobile Phone Number *</InputLabel>
                                        <TextField
                                            id="name"
                                            sx={{
                                                width: {xs: "100%", md: "90%"},
                                                fontSize: "10px",
                                            }}
                                            onChange={(event) => {
                                            }}
                                            value={selectedCustomer && selectedCustomer.mobilePhone}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <InputLabel>ID Type</InputLabel>
                                        <Select
                                            sx={{width: {xs: "100%", md: "90%"}}}
                                            id={"idType"}
                                            value={selectedCustomer && selectedCustomer.idTypeCd}
                                            onChange={(event) => handleIdTypeChange(event)}
                                        >
                                            {idTypes && idTypes.length > 0 ? (
                                                idTypes.map(function (idType, i) {
                                                    return (
                                                        <MenuItem key={idType.value} value={idType.value}>
                                                            {idType.label}
                                                        </MenuItem>
                                                    );
                                                }, this)
                                            ) : (
                                                <MenuItem sx={{width: "100%"}}>
                                                    No Results Found
                                                </MenuItem>
                                            )}
                                        </Select>
                                    </Grid>
                                    <Grid
                                        item
                                        sx={{
                                            width: {xs: "100%", lg: "33.3%"},
                                            marginBottom: "10px",
                                        }}
                                    >
                                        <InputLabel>ID Number *</InputLabel>
                                        <TextField
                                            id="name"
                                            sx={{
                                                width: {xs: "100%", md: "90%"},
                                                fontSize: "10px",
                                            }}
                                            onChange={(event) => {
                                            }}
                                            value={selectedCustomer && selectedCustomer.idNumber}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        sx={{
                                            width: {xs: "100%", lg: "33.3%"},
                                            marginBottom: "10px",
                                        }}
                                    >
                                        <InputLabel>Email</InputLabel>
                                        <TextField
                                            id="name"
                                            sx={{
                                                width: {xs: "100%", md: "90%"},
                                                fontSize: "10px",
                                            }}
                                            onChange={(event) => {
                                            }}
                                            value={selectedCustomer && selectedCustomer.email}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        sx={{
                                            width: {xs: "100%", lg: "33.3%"},
                                            marginBottom: "10px",
                                        }}
                                    >
                                        <InputLabel>Secondary Phone Number</InputLabel>
                                        <TextField
                                            id="name"
                                            sx={{
                                                width: {xs: "100%", md: "90%"},
                                                fontSize: "10px",
                                            }}
                                            onChange={(event) => {
                                            }}
                                            value={selectedCustomer && selectedCustomer.officePhone}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        sx={{
                                            width: {xs: "100%", lg: "33.3%"},
                                            marginBottom: "10px",
                                        }}
                                    >
                                        <InputLabel>Credit Limit *</InputLabel>
                                        <TextField
                                            id="name"
                                            sx={{
                                                width: {xs: "100%", md: "90%"},
                                                fontSize: "10px",
                                            }}
                                            onChange={(event) => {
                                            }}
                                            value={
                                                selectedCustomer &&
                                                selectedCustomer.absoluteCreditLimit
                                            }
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        sx={{
                                            width: {xs: "100%", lg: "33.3%"},
                                            marginBottom: "10px",
                                        }}
                                    >
                                        <InputLabel>Billing Frequency</InputLabel>
                                        <Select
                                            sx={{width: {xs: "100%", md: "90%"}}}
                                            id={"billingPeriod"}
                                            value={
                                                selectedCustomer && selectedCustomer.billingPeriod
                                            }
                                            onChange={(event) => handleBillingPeriodChange(event)}
                                        >
                                            {billingPeriods && billingPeriods.length > 0 ? (
                                                billingPeriods.map(function (billingPeriod, i) {
                                                    return (
                                                        <MenuItem
                                                            key={billingPeriod.value}
                                                            value={billingPeriod.value}
                                                        >
                                                            {billingPeriod.label}
                                                        </MenuItem>
                                                    );
                                                }, this)
                                            ) : (
                                                <MenuItem sx={{width: "100%"}}>
                                                    No Results Found
                                                </MenuItem>
                                            )}
                                        </Select>
                                    </Grid>
                                    {selectedCustomer &&
                                        (selectedCustomer.billingPeriod === "ANU" ||
                                            selectedCustomer.billingPeriod === "QT") && (
                                            <Grid item md={4} xs={12}>
                                                <LocalizationProvider
                                                    dateAdapter={AdapterDayjs}
                                                    adapterLocale="en-gb"
                                                >
                                                    <DatePicker
                                                        label="Next Billing Date"
                                                        sx={{width: {xs: "100%", md: "90%"}}}
                                                        id={"nextBillingDate"}
                                                        value={
                                                            selectedCustomer &&
                                                            dayjs(selectedCustomer.billingDate)
                                                        }
                                                        onChange={(newDate) =>
                                                            handleNextBillingDateChange(newDate)
                                                        }
                                                    />
                                                </LocalizationProvider>
                                            </Grid>
                                        )}
                                </InnerModal>
                            </Paper>
                            <br/>

                            {/*FOOTER WITH BUTTONS*/}
                            <Paper
                                variant=""
                                sx={{
                                    height: "auto",
                                    margin: "auto",
                                    padding: "10px",
                                    alignItems: "left",
                                    display: "flex",
                                    flexDirection: {xs: "column", lg: "row"},
                                    justifyContent: {xs: "center", lg: "flex-end"},
                                }}
                            >
                                <ModalAcceptButton
                                    type="submit"
                                    onClick={addCustomer}
                                    sx={{width: {xs: "100%", lg: "200px"}}}
                                >
                                    Create
                                </ModalAcceptButton>
                                <ModalCancelButton
                                    type="submit"
                                    onClick={handleClose}
                                    sx={{
                                        width: {xs: "100%", lg: "200px"},
                                    }}
                                >
                                    Cancel
                                </ModalCancelButton>
                            </Paper>
                        </Paper>
                        <br/>
                    </AddContainer>
                )}

                {ViewDiv && (
                    <CustomerView
                        selectedCustomer={selectedCustomer}
                        pendingTransactions={pendingTransactions}
                        statementYear={statementYear}
                        handleVoidClick={handleVoidClick}
                        setViewDiv={setViewDiv}
                        handlePrintCustomerStatement={handlePrintCustomerStatement}
                        handleStatementYearChange={handleStatementYearChange}
                    />
                )}

                <PersonOrgSearch
                    contractType={'customer'}
                    closeModal={() => setCustomerModal(false)}
                    showPersonOrgModal={CustomerModal}
                    handlePersonOrgClose={() => setCustomerModal(false)}
                    handlePersonOrgSelect={handlePersonOrgSelect}
                    showAddNewUserForm={handleNewIndividual}
                    showAddNewOrgForm={handleNewCompany}
                />
                {(task === "Edit" || task === "Add") && (
                    <AddNewUser
                        openModal={AddUserModal}
                        closeModal={() => {
                            setAddUserModal(false);
                            setSelectedCustomer(null);
                            setTask("");
                        }}
                        saveUser={(user.services.includes("MAINT")) ? addFacilityCustomer : addCustomer}
                        selectedPerson={selectedCustomer}
                        manageUsers={false}
                        customer={(!user.services.includes("MAINT"))}
                        task={task}
                    />
                )}
                {(task === "Edit" || task === "Add") && (
                    <AddNewOrganisation
                        openModal={AddOrganizationModal}
                        closeModal={() => {
                            setAddOrganizationModal(false);
                            setSelectedCustomer(null);
                            setTask("");
                        }}
                        selectedOrg={selectedCustomer}
                        handleSave={true}
                        customer={(!user.services.includes("MAINT"))}
                        task={task}
                        saveOrg={(user.services.includes("MAINT")) ? addFacilityCustomer : addCustomer}
                        searchedName={companySearch}
                    />
                )}
                <ReceivePayment
                    showPaymentModal={PaymentModal}
                    handlePaymentClose={() => setPaymentModal(false)}
                    paymentMode={"Customer"}
                    contract={selectedCustomer}
                    savePayment={savePayment}
                />

                {!ViewDiv && (user.services.includes("MAINT")) &&
                    <MuiDataGrid
                        noSelection={true}
                        dataGridColumns={columns1}
                        dataGridRows={facilityClients}
                        serverPagination={false}
                        loading={loading}
                        height="60vh"
                        totalRows={facilityClients.length}
                    />

                }

                {/*CREDIT MODAL*/}
                {creditModal && (
                    <CreditNote
                        open={creditModal}
                        onClose={handleCreditClose}
                        voiditem={voidItem}
                        meterbillingid={selectedCustomer && selectedCustomer.billingId}
                    />
                )}

                {/*CHARGE MODAL*/}
                {chargeModal && (
                    <DebitNote
                        open={chargeModal}
                        onClose={handleChargeClose}
                        voiditem={voidItem}
                        meterbillingid={selectedCustomer && selectedCustomer.billingId}
                    />
                )}
            </MainContainer>
        </Permit>
    );
}
