/* eslint-disable react-hooks/exhaustive-deps */
import {
  Alert,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  bytesToMegabytes,
  getIdRefNumber,
  isEmptyString,
  isNullUndefined,
  isValidDate,
  tryParseInt,
} from "../../util/util";
import { ResponsiveRow } from "../../constants/layout.constants";
import {
  getCountries,
  getRentPeriods,
  getSubscriptionBillDays,
} from "../../services/list.service";
import {
  ModalAcceptButton,
  ModalCancelButton,
} from "../../constants/component.constants";
import { issueWarnMessage } from "../../actions/message";
import { FileUpload } from "../inputs/fileUpload";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
import { countryBanksList } from "../../services/bank.service";
import { Download, Upload } from "@mui/icons-material";
import { getSignUpTemplate } from "../../services/signUp.service";
import dayjs from "dayjs";

export default function PropertyDetails(props) {
  const [address1, setAddress1] = useState("");
  const [city, setCity] = useState("");
  const [countryCd, setCountry] = useState("");
  const [countryOptions, setCountryOptions] = useState([]);
  const [facility, setFacility] = useState(null);
  const [facilityName, setFacilityName] = useState("");
  const [mainSubscriptionServiceOptions] = useState([
    { label: "Core", value: "CORE" },
    { label: "Home Owners Association", value: "HOA" },
    { label: "Lean", value: "LEAN" },
    { label: "Utility", value: "METER" },
    { label: "Premium", value: "PREMIUM" },
    { label: "Bulk Water Sales", value: "BLWTR" },
    { label: "Maintenance", value: "MAINT" },
  ]);
  const [lrNumber, setLrNumber] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [subscriptionService, setSubscriptionService] = useState(null);
  const [serviceCode, setServiceCode] = useState("");
  const [addMpesaSupport, setAddMpesaSupport] = useState(false);
  const [addUtilitiesSupport, setAddUtilitiesSupport] = useState(false);
  const [addBankPaymentSupport, setAddBankPaymentSupport] = useState(false);
  const [propertyUpload, setPropertyUpload] = useState(null);
  const [transferDays, setTransferDays] = useState([]);
  const [transferPeriods, setTransferPeriods] = useState([]);
  const [useUploadExcel, setUseUploadExcel] = useState(false);
  const [uploadSuccessful, setUploadSuccessful] = useState(false);
  const [enterDetailsManually, setEnterDetailsManually] = useState(false);
  const [propertyPaymentDetails, setPropertyPaymentDetails] = useState(null);
  const [useSmartKodiPayBill, setUseSmartKodiPayBill] = useState(false);
  const [useYourPayBill, setUseYourPayBill] = useState(false);
  const [mpesaPaybill, setMpesaPaybill] = useState("");
  const [banksList, setBanksList] = useState([]);
  const [refBankId, setRefBankId] = useState("");
  const [bankAccountNo, setBankAccountNo] = useState("");
  const [bankBranchName, setBankBranchName] = useState("");
  const [bankAccountName, setBankAccountName] = useState("");
  const [transferFrequencyCd, setTransferFrequencyCd] = useState("");
  const [transferDay, setTransferDay] = useState("");
  const [transferDate, setTransferDate] = useState("");
  const [file, setFile] = useState(null);
  const MAX_FILE_UPLOAD_MB = 10;

  useEffect(() => {
    let facilityData = {
      id: getIdRefNumber(),
      description: "",
      name: "",
      statusCd: "ACT",
      lrNumber: "",
      latitude: "",
      longitude: "",
      complexId: null,
      subscription: null,
      facilityPayInfo: null,
      parentOrganizationId: null,
      localCode: "",
      facilityPropertiesId: null,
      addressId: "",
      dateCreated: "",
      address: {
        city: "",
        countryCd: "",
        address2: "",
        id: getIdRefNumber(),
        address1: "",
      },
      plusCode: "",
    };

    let subscriptionServiceData = {
      serviceCode: "",
      addMpesaSupport: false,
      addUtilitiesSupport: false,
    };

    let propertyUploadData = {
      useUploadExcel: false,
      uploadSuccessful: false,
      enterDetailsManually: false,
    };

    let propertyPaymentDetailsData = {
      transferDay: "",
      transferDate: "",
      useSmartKodiPayBill: false,
      useYourPayBill: false,
      transferFrequencyCd: "",
      mpesaPaybill: "",
      addBankPaymentSupport: false,
      refBankId: "",
      bankAccountNo: "",
      bankAccountName: "",
      bankBranchName: "",
    };

    if (
      !isNullUndefined(props) &&
      !isNullUndefined(props.signUp) &&
      !isNullUndefined(props.signUp.signUpJson) &&
      !isNullUndefined(props.signUp.signUpJson.facility)
    ) {
      setFacility(props.signUp.signUpJson.facility);
    } else {
      setFacility(facilityData);
    }

    if (
      !isNullUndefined(props) &&
      !isNullUndefined(props.signUp) &&
      !isNullUndefined(props.signUp.signUpJson) &&
      !isNullUndefined(props.signUp.signUpJson.subscriptionService)
    ) {
      setSubscriptionService(props.signUp.signUpJson.subscriptionService);
    } else {
      setSubscriptionService(subscriptionServiceData);
    }

    if (
      !isNullUndefined(props) &&
      !isNullUndefined(props.signUp) &&
      !isNullUndefined(props.signUp.signUpJson) &&
      !isNullUndefined(props.signUp.signUpJson.propertyUpload)
    ) {
      setPropertyUpload(props.signUp.signUpJson.propertyUpload);
      if (
        !isNullUndefined(props.signUp.signUpJson.propertyUpload) &&
        !isNullUndefined(
          props.signUp.signUpJson.propertyUpload.uploadSuccessful
        )
      ) {
        setUploadSuccessful(
          props.signUp.signUpJson.propertyUpload.uploadSuccessful
        );
      }
    } else {
      setPropertyUpload(propertyUploadData);
    }

    if (
      !isNullUndefined(props) &&
      !isNullUndefined(props.signUp) &&
      !isNullUndefined(props.signUp.signUpJson) &&
      !isNullUndefined(props.signUp.signUpJson.propertyPaymentDetails)
    ) {
      setPropertyPaymentDetails(props.signUp.signUpJson.propertyPaymentDetails);
    } else {
      setPropertyPaymentDetails(propertyPaymentDetailsData);
    }

    getCountries().then((countryOptions) => setCountryOptions(countryOptions));

    getSubscriptionBillDays().then((rentDays) => setTransferDays(rentDays));

    getRentPeriods().then((transferPeriod) =>
      setTransferPeriods(transferPeriod)
    );

    countryBanksList().then((data) => setBanksList(data));
  }, []);

  useEffect(() => {
    let facilityData = {
      id: getIdRefNumber(),
      description: "",
      name: "",
      statusCd: "ACT",
      lrNumber: "",
      latitude: "",
      longitude: "",
      complexId: null,
      subscription: null,
      facilityPayInfo: null,
      parentOrganizationId: null,
      localCode: "",
      facilityPropertiesId: null,
      addressId: "",
      dateCreated: "",
      address: {
        city: "",
        countryCd: "",
        address2: "",
        id: getIdRefNumber(),
        address1: "",
      },
      plusCode: "",
    };

    let subscriptionServiceData = {
      serviceCode: "",
      addMpesaSupport: false,
      addUtilitiesSupport: false,
    };

    let propertyUploadData = {
      useUploadExcel: false,
      uploadSuccessful: false,
      enterDetailsManually: false,
    };

    let propertyPaymentDetailsData = {
      transferDay: "",
      transferDate: "",
      useSmartKodiPayBill: false,
      useYourPayBill: false,
      transferFrequencyCd: "",
      mpesaPaybill: "",
      addBankPaymentSupport: false,
      refBankId: "",
      bankAccountNo: "",
      bankAccountName: "",
      bankBranchName: "",
    };

    if (isNullUndefined(props.signUp)) {
      setFacility(facilityData);
      setSubscriptionService(subscriptionServiceData);
      setPropertyUpload(propertyUploadData);
      setPropertyPaymentDetails(propertyPaymentDetailsData);
    } else {
      if (
        !isNullUndefined(props) &&
        !isNullUndefined(props.signUp) &&
        !isNullUndefined(props.signUp.signUpJson) &&
        !isNullUndefined(props.signUp.signUpJson.facility)
      ) {
        setFacility(props.signUp.signUpJson.facility);
      } else {
        setFacility(facilityData);
      }

      if (
        !isNullUndefined(props) &&
        !isNullUndefined(props.signUp) &&
        !isNullUndefined(props.signUp.signUpJson) &&
        !isNullUndefined(props.signUp.signUpJson.subscriptionService)
      ) {
        setSubscriptionService(props.signUp.signUpJson.subscriptionService);
      } else {
        setSubscriptionService(subscriptionServiceData);
      }

      if (
        !isNullUndefined(props) &&
        !isNullUndefined(props.signUp) &&
        !isNullUndefined(props.signUp.signUpJson) &&
        !isNullUndefined(props.signUp.signUpJson.propertyUpload)
      ) {
        setPropertyUpload(props.signUp.signUpJson.propertyUpload);
        if (
          !isNullUndefined(props.signUp.signUpJson.propertyUpload) &&
          !isNullUndefined(
            props.signUp.signUpJson.propertyUpload.uploadSuccessful
          )
        ) {
          setUploadSuccessful(
            props.signUp.signUpJson.propertyUpload.uploadSuccessful
          );
        }
      } else {
        setPropertyUpload(propertyUploadData);
      }

      if (
        !isNullUndefined(props) &&
        !isNullUndefined(props.signUp) &&
        !isNullUndefined(props.signUp.signUpJson) &&
        !isNullUndefined(props.signUp.signUpJson.propertyPaymentDetails)
      ) {
        setPropertyPaymentDetails(
          props.signUp.signUpJson.propertyPaymentDetails
        );
      } else {
        setPropertyPaymentDetails(propertyPaymentDetailsData);
      }
    }
  }, [props.signUp]);

  function uploadFileChanged(event) {
    let file = event.target.files[0];
    setFile(file);
  }

  function uploadDocument() {
    let signUp;
    const buttonIndex = "1.1";
    signUp = props.signUp;
    signUp.buttonIndex = buttonIndex;
    signUp.signUpJson.facility = facility;
    signUp.signUpJson.subscriptionService = subscriptionService;
    signUp.signUpJson.propertyUpload = propertyUpload;
    signUp.signUpJson.propertyPaymentDetails = propertyPaymentDetails;
    if (isNullUndefined(signUp)) {
      issueWarnMessage(`Please fill in the form.`);
    } else {
      if (validateUploadTemplate(signUp)) {
        let formData = new FormData();
        formData.append("upload", file);
        formData.append("signUp", JSON.stringify(signUp));
        props.uploadTemplateDocument(formData);
      }
    }
  }

  function onRadioChange(radio) {
    const value = radio.target.value;
    if (radio) {
      if (value === "useYourPayBill") {
        setUseYourPayBill(true);
        setUseSmartKodiPayBill(false);
        let obj = propertyPaymentDetails;
        obj.useYourPayBill = true;
        obj.useSmartKodiPayBill = false;
      }
      if (value === "useSmartKodiPayBill") {
        setUseSmartKodiPayBill(true);
        setUseYourPayBill(false);
        let obj = propertyPaymentDetails;
        obj.useYourPayBill = false;
        obj.useSmartKodiPayBill = true;
      }

      if (value === "useUploadExcel") {
        setUseUploadExcel(true);
        setEnterDetailsManually(false);
        let obj = propertyUpload;
        obj.enterDetailsManually = false;
        obj.useUploadExcel = true;
      }

      if (value === "enterDetailsManually") {
        setUseUploadExcel(false);
        setEnterDetailsManually(true);
        let obj = propertyUpload;
        obj.enterDetailsManually = true;
        obj.useUploadExcel = false;
      }
    }
  }

  function saveAndFinishLater() {
    let signUp;
    const buttonIndex = "1.1";
    signUp = props.signUp;
    signUp.buttonIndex = buttonIndex;
    signUp.signUpJson.facility = facility;
    signUp.signUpJson.subscriptionService = subscriptionService;
    signUp.signUpJson.propertyUpload = propertyUpload;
    signUp.signUpJson.propertyPaymentDetails = propertyPaymentDetails;
    if (isNullUndefined(signUp)) {
      issueWarnMessage(`Please fill in the form.`);
    } else {
      if (validateSaveAndFinishLater(signUp)) {
        props.saveSignUpData(signUp);
      }
    }
  }

  function validateSaveAndFinishLater(signUp) {
    //console.log("validateSaveAndFinishLater signUp: ", signUp)
    // Validate
    if (isNullUndefined(signUp)) {
      issueWarnMessage("Could not determine signUp.");
      return false;
    }

    if (isNullUndefined(signUp.signUpJson.facility)) {
      issueWarnMessage("Could not determine facility.");
      return false;
    }

    if (
      isNullUndefined(signUp.signUpJson.facility.name) ||
      isEmptyString(signUp.signUpJson.facility.name)
    ) {
      issueWarnMessage("Could not determine property name.");
      return false;
    }

    if (isNullUndefined(signUp.signUpJson.facility.address)) {
      issueWarnMessage("Could not determine property address.");
      return false;
    }

    if (
      isNullUndefined(signUp.signUpJson.facility.address.address1) ||
      isEmptyString(signUp.signUpJson.facility.address.address1)
    ) {
      issueWarnMessage("Could not determine property address1.");
      return false;
    }

    if (
      isNullUndefined(signUp.signUpJson.facility.address.city) ||
      isEmptyString(signUp.signUpJson.facility.address.city)
    ) {
      issueWarnMessage("Could not determine property city.");
      return false;
    }

    if (
      isNullUndefined(signUp.signUpJson.facility.address.countryCd) ||
      isEmptyString(signUp.signUpJson.facility.address.countryCd)
    ) {
      issueWarnMessage("Could not determine property country.");
      return false;
    }

    return true;
  }

  function validateSaveAndContinue(signUp) {
    // Validate
    if (validateSaveAndFinishLater(signUp)) {
      if (isNullUndefined(signUp.signUpJson.subscriptionService)) {
        issueWarnMessage("Could not determine Property subscription Service.");
        return false;
      }

      if (
        isNullUndefined(signUp.signUpJson.subscriptionService.serviceCode) ||
        isEmptyString(signUp.signUpJson.subscriptionService.serviceCode)
      ) {
        issueWarnMessage("Could not determine Property Service.");
        return false;
      }

      if (
        !isNullUndefined(signUp.signUpJson.subscriptionService) &&
        !isNullUndefined(
          signUp.signUpJson.subscriptionService.addMpesaSupport
        ) &&
        signUp.signUpJson.subscriptionService.addMpesaSupport
      ) {
        if (isNullUndefined(signUp.signUpJson.propertyPaymentDetails)) {
          issueWarnMessage("Could not determine Property Payment Details.");
          return false;
        }

        if (
          (signUp.signUpJson.propertyPaymentDetails.useYourPayBill &&
            signUp.signUpJson.propertyPaymentDetails.useSmartKodiPayBill) ||
          (!signUp.signUpJson.propertyPaymentDetails.useYourPayBill &&
            !signUp.signUpJson.propertyPaymentDetails.useSmartKodiPayBill)
        ) {
          issueWarnMessage(
            "For Mpesa support you need to select either to use SmartKodi PayBill or Our PayBill."
          );
          return false;
        }

        if (
          !isNullUndefined(
            signUp.signUpJson.propertyPaymentDetails.useYourPayBill
          ) &&
          signUp.signUpJson.propertyPaymentDetails.useYourPayBill
        ) {
          if (
            isNullUndefined(
              signUp.signUpJson.propertyPaymentDetails.mpesaPaybill
            ) ||
            isEmptyString(signUp.signUpJson.propertyPaymentDetails.mpesaPaybill)
          ) {
            issueWarnMessage("Could not determine Property's Mpesa Paybill.");
            return false;
          }
        }

        if (
          !isNullUndefined(
            signUp.signUpJson.propertyPaymentDetails.useSmartKodiPayBill
          ) &&
          signUp.signUpJson.propertyPaymentDetails.useSmartKodiPayBill
        ) {
          if (
            isNullUndefined(
              signUp.signUpJson.propertyPaymentDetails.transferFrequencyCd
            ) ||
            isEmptyString(
              signUp.signUpJson.propertyPaymentDetails.transferFrequencyCd
            )
          ) {
            issueWarnMessage(
              "Could not determine Property's Mpesa transfer frequency."
            );
            return false;
          }

          if (
            signUp.signUpJson.propertyPaymentDetails.transferFrequencyCd ===
            "MNTH"
          ) {
            if (
              isNullUndefined(
                signUp.signUpJson.propertyPaymentDetails.transferDay
              ) ||
              isEmptyString(
                signUp.signUpJson.propertyPaymentDetails.transferDay
              )
            ) {
              issueWarnMessage(
                "Could not determine Property's Mpesa transfer day."
              );
              return false;
            }
            if (
              !isNullUndefined(
                signUp.signUpJson.propertyPaymentDetails.transferDay
              ) ||
              !isEmptyString(
                signUp.signUpJson.propertyPaymentDetails.transferDay
              )
            ) {
              let day = tryParseInt(
                signUp.signUpJson.propertyPaymentDetails.transferDay,
                0
              );
              if (day < 1 || day > 31) {
                issueWarnMessage(
                  "Could not determine Property's Mpesa transfer day."
                );
                return false;
              }
            }
          }

          if (
            signUp.signUpJson.propertyPaymentDetails.transferFrequencyCd ===
              "QT" ||
            signUp.signUpJson.propertyPaymentDetails.transferFrequencyCd ===
              "ANU"
          ) {
            if (
              isNullUndefined(
                signUp.signUpJson.propertyPaymentDetails.transferDate
              ) ||
              isEmptyString(
                signUp.signUpJson.propertyPaymentDetails.transferDate
              )
            ) {
              issueWarnMessage(
                "Could not determine Property's Mpesa transfer date."
              );
              return false;
            }
            if (
              !isNullUndefined(
                signUp.signUpJson.propertyPaymentDetails.transferDate
              ) ||
              !isEmptyString(
                signUp.signUpJson.propertyPaymentDetails.transferDate
              )
            ) {
              let tDate = new Date(
                signUp.signUpJson.propertyPaymentDetails.transferDate
              );
              if (!isValidDate(tDate)) {
                issueWarnMessage(
                  "Could not determine Property's Mpesa transfer date."
                );
                return false;
              }
            }
          }
        }
      }

      if (
        !isNullUndefined(signUp.signUpJson.propertyPaymentDetails) &&
        signUp.signUpJson.propertyPaymentDetails.addBankPaymentSupport
      ) {
        if (
          isNullUndefined(signUp.signUpJson.propertyPaymentDetails.refBankId) ||
          isEmptyString(signUp.signUpJson.propertyPaymentDetails.refBankId)
        ) {
          issueWarnMessage("Could not determine Property bank Payment.");
          return false;
        }

        if (
          isNullUndefined(
            signUp.signUpJson.propertyPaymentDetails.bankAccountNo
          ) ||
          isEmptyString(signUp.signUpJson.propertyPaymentDetails.bankAccountNo)
        ) {
          issueWarnMessage(
            "Could not determine Property Payment bank Account Number."
          );
          return false;
        }

        if (
          isNullUndefined(
            signUp.signUpJson.propertyPaymentDetails.bankAccountName
          ) ||
          isEmptyString(
            signUp.signUpJson.propertyPaymentDetails.bankAccountName
          )
        ) {
          issueWarnMessage(
            "Could not determine Property Payment bank Account Name."
          );
          return false;
        }

        if (
          isNullUndefined(
            signUp.signUpJson.propertyPaymentDetails.bankBranchName
          ) ||
          isEmptyString(signUp.signUpJson.propertyPaymentDetails.bankBranchName)
        ) {
          issueWarnMessage(
            "Could not determine Property Payment bank branch Name."
          );
          return false;
        }
      }

      if (isNullUndefined(signUp.signUpJson.propertyUpload)) {
        issueWarnMessage("Could not determine Property Upload.");
        return false;
      }

      if (
        isNullUndefined(signUp.signUpJson.propertyUpload.useUploadExcel) ||
        isNullUndefined(signUp.signUpJson.propertyUpload.enterDetailsManually)
      ) {
        issueWarnMessage(
          "Please select if you will use template or enter details manually."
        );
        return false;
      }

      if (
        signUp.signUpJson.propertyUpload.useUploadExcel &&
        (!signUp.signUpJson.propertyUpload.uploadSuccessful ||
          !uploadSuccessful)
      ) {
        issueWarnMessage("Please upload completed template.");
        return false;
      }
      return true;
    }
  }

  function saveAndContinue() {
    let signUp;
    const buttonIndex = "1.2";
    signUp = props.signUp;
    signUp.buttonIndex = buttonIndex;
    signUp.signUpJson.facility = facility;
    signUp.signUpJson.subscriptionService = subscriptionService;
    signUp.signUpJson.propertyUpload = propertyUpload;
    signUp.signUpJson.propertyPaymentDetails = propertyPaymentDetails;
    if (isNullUndefined(signUp)) {
      issueWarnMessage(`Please fill in the form.`);
    } else {
      if (validateSaveAndContinue(signUp)) {
        props.saveSignUpData(signUp);
      }
    }
  }

  function validateUploadTemplate(signUp) {
    //console.log("validateSaveAndContinue signUp: ", signUp)
    // Validate
    if (validateSaveAndFinishLater(signUp)) {
      if (isNullUndefined(signUp.signUpJson.subscriptionService)) {
        issueWarnMessage("Could not determine Property subscription Service.");
        return false;
      }

      if (
        isNullUndefined(signUp.signUpJson.subscriptionService.serviceCode) ||
        isEmptyString(signUp.signUpJson.subscriptionService.serviceCode)
      ) {
        issueWarnMessage("Could not determine Property Service.");
        return false;
      }

      if (isNullUndefined(signUp.signUpJson.propertyUpload)) {
        issueWarnMessage("Could not determine Property Upload.");
        return false;
      }

      if (
        isNullUndefined(signUp.signUpJson.propertyUpload.useUploadExcel) ||
        isNullUndefined(signUp.signUpJson.propertyUpload.enterDetailsManually)
      ) {
        issueWarnMessage(
          "Please select if you will use template or enter details manually."
        );
        return false;
      }

      if (!signUp.signUpJson.propertyUpload.useUploadExcel) {
        issueWarnMessage(
          "Please select upload use Upload Excel to upload completed template."
        );
        return false;
      }

      if (isNullUndefined(file)) {
        issueWarnMessage("No file selected for upload!");
        return false;
      }
      // console.log("file", file)

      let fileSizeMb = bytesToMegabytes(file.size);
      if (fileSizeMb > MAX_FILE_UPLOAD_MB) {
        issueWarnMessage(
          "The file selected has a size (" +
            fileSizeMb.toFixed(2) +
            " MB) which is larger than the upload limit (" +
            MAX_FILE_UPLOAD_MB +
            ") MB"
        );
        return false;
      }

      return true;
    }
  }

  function onCheckBoxChange(field) {
    if (field === "Utility") {
      let addUtility = addUtilitiesSupport;
      subscriptionService.addUtilitiesSupport = !addUtility;
      setAddUtilitiesSupport(!addUtility);
    }
    if (field === "Mpesa") {
      let addMpesa = addMpesaSupport;
      subscriptionService.addMpesaSupport = !addMpesa;
      setAddMpesaSupport(!addMpesa);
    }
    if (field === "Bank") {
      let addBank = addBankPaymentSupport;
      setAddBankPaymentSupport(!addBank);
      propertyPaymentDetails.addBankPaymentSupport = !addBank;
    }
  }

  function handleCountryChange(event) {
    let value = event.target.value;
    setCountry(value);
    facility.address.countryCd = value;
  }

  function handleTransferDateChange(value) {
    let date = new Date(value);
    propertyPaymentDetails.transferDate = date;
    setTransferDate(date);
  }

  function handleTransferDayChange(event) {
    let value = event.target.value;
    propertyPaymentDetails.transferDay = value;
    setTransferDay(value);
  }

  function handleTransferFrequencyChange(event) {
    let value = event.target.value;
    propertyPaymentDetails.transferFrequencyCd = value;
    setTransferFrequencyCd(value);
  }

  function handleServiceChange(event) {
    let value = event.target.value;
    setServiceCode(value);
    subscriptionService.serviceCode = value;
    if (value === "BLWTR") {
      setUseUploadExcel(false);
      setEnterDetailsManually(true);
      let obj = propertyUpload;
      obj.enterDetailsManually = true;
      obj.useUploadExcel = false;
    }
  }

  function handleBankChange(event) {
    let value = event.target.value;
    setRefBankId(value);
    propertyPaymentDetails.refBankId = value;
  }

  return (
    <ResponsiveRow
      container
      sx={{ height: "auto", width: "100%", textAlight: "start" }}
    >
      {/* PROPERTY FORM */}
      {/*<Typography
					sx={{ marginBottom: "5px" }}
					id="addUser"
					variant="h6"
					component="h6"
				>
					Property Details
				</Typography>*/}
      <Alert severity="info" sx={{ width: "100%" }}>
        Fill in the property details. These are the details of the building,
        (i.e. address and location).
      </Alert>
      <ResponsiveRow
        sx={{ width: "100%", height: "auto", textAlign: "start" }}
        container
      >
        <FormControl
          item
          sx={{ width: { xs: "100%", lg: "33.3%" }, marginBottom: "10px" }}
        >
          <InputLabel>Property Name</InputLabel>
          <OutlinedInput
            sx={{ width: { xs: "100%", lg: "90%" } }}
            label="Property Name"
            required
            onChange={(event) => {
              const value = event.target.value;
              setFacilityName(value);
              let obj = facility;
              obj.name = value;
            }}
            value={facility ? facility && facility.name : facilityName}
          />
        </FormControl>
        <FormControl
          item
          sx={{ width: { xs: "100%", lg: "33.3%" }, marginBottom: "10px" }}
        >
          <InputLabel>Address</InputLabel>
          <OutlinedInput
            sx={{ width: { xs: "100%", lg: "90%" } }}
            label="Address"
            required
            onChange={(event) => {
              const value = event.target.value;
              setAddress1(value);
              let obj = facility;
              obj.address.address1 = value;
            }}
            value={
              facility
                ? facility && facility.address && facility.address.address1
                : address1
            }
          />
        </FormControl>
        {/*<Grid
							item
							sx={{ width: { xs: "100%", lg: "33.3%" }, marginBottom: "10px" }}
						>
							<TextField sx={{ width: "90%" }} label="Address2" />
						</Grid>*/}
        <FormControl
          item
          sx={{ width: { xs: "100%", lg: "33.3%" }, marginBottom: "10px" }}
        >
          <InputLabel>City</InputLabel>
          <OutlinedInput
            sx={{ width: { xs: "100%", lg: "90%" } }}
            label="City"
            required
            onChange={(event) => {
              const value = event.target.value;
              setCity(value);
              facility.address.city = value;
            }}
            value={
              facility
                ? facility && facility.address && facility.address.city
                : city
            }
          />
        </FormControl>
        <Grid
          item
          sx={{ width: { xs: "100%", lg: "33.3%" }, marginBottom: "10px" }}
        >
          <FormControl
            fullWidth
            sx={{
              margin: { md: "auto 0px", xs: "10px auto " },
              width: "100%",
            }}
          >
            <InputLabel id="Country">Country *</InputLabel>
            <Select
              defaultValue={null}
              labelId="Country"
              id="country"
              required={true}
              label="Country *"
              sx={{ width: { xs: "100%", md: "90%" } }}
              value={
                facility
                  ? facility && facility.address && facility.address.countryCd
                  : countryCd
              }
              onChange={(event) => handleCountryChange(event)}
            >
              {countryOptions && countryOptions.length > 0 ? (
                countryOptions.map(function (country, i) {
                  return (
                    <MenuItem key={country.value} value={country.value}>
                      {country.label}
                    </MenuItem>
                  );
                }, this)
              ) : (
                <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
              )}
            </Select>
          </FormControl>
        </Grid>
        <FormControl
          item
          sx={{ width: { xs: "100%", lg: "33.3%" }, marginBottom: "10px" }}
        >
          <InputLabel>LR Number</InputLabel>
          <OutlinedInput
            sx={{ width: { xs: "100%", lg: "90%" } }}
            label="LR Number"
            onChange={(event) => {
              const value = event.target.value;
              setLrNumber(value);
              facility.lrNumber = value;
            }}
            value={facility ? facility && facility.lrNumber : lrNumber}
          />
        </FormControl>

        <Grid
          item
          sx={{ width: { xs: "100%", lg: "33.3%" }, marginBottom: "10px" }}
        >
          <FormControl sx={{ width: "50%", float: "left" }}>
            <InputLabel>Latitude</InputLabel>
            <OutlinedInput
              sx={{ width: "80%" }}
              label="Latitude"
              onChange={(event) => {
                const value = event.target.value;
                setLatitude(value);
                facility.latitude = value;
              }}
              value={facility ? facility && facility.latitude : latitude}
            />
          </FormControl>

          <FormControl sx={{ width: "50%", float: "right" }}>
            <InputLabel>Longitude</InputLabel>
            <OutlinedInput
              sx={{ width: "80%" }}
              label="Longitude"
              onChange={(event) => {
                const value = event.target.value;
                setLongitude(value);
                facility.longitude = value;
              }}
              value={facility ? facility && facility.longitude : longitude}
            />
          </FormControl>
        </Grid>
      </ResponsiveRow>
      <ResponsiveRow sx={{ width: "100%", height: "auto" }} container>
        <ResponsiveRow
          item
          sx={{
            flexDirection: "column",

            width: { xs: "100%", lg: "30%" },
            height: "auto",

            marginBottom: "10px",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Alert
            severity="info"
            sx={{ width: "100%", textAlign: "start", marginBottom: "10px" }}
          >
            Please select subscription service for your property.
          </Alert>
          <FormControl
            fullWidth
            sx={{
              margin: { md: "10px 0px", xs: "10px auto " },
              width: "100%",
            }}
          >
            <InputLabel id="Service">Subscription *</InputLabel>
            <Select
              defaultValue={null}
              labelId="Subscription *"
              id="service"
              required={true}
              label="Subscription *"
              sx={{ width: { xs: "100%", md: "90%" } }}
              value={
                subscriptionService
                  ? subscriptionService && subscriptionService.serviceCode
                  : serviceCode
              }
              onChange={(event) => handleServiceChange(event)}
            >
              {mainSubscriptionServiceOptions &&
              mainSubscriptionServiceOptions.length > 0 ? (
                mainSubscriptionServiceOptions.map(function (ss, i) {
                  return (
                    <MenuItem key={ss.value} value={ss.value}>
                      {ss.label}
                    </MenuItem>
                  );
                }, this)
              ) : (
                <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
              )}
            </Select>
          </FormControl>
        </ResponsiveRow>
        <ResponsiveRow
          sx={{
            flexDirection: "column",
            flexWrap: "nowrap",

            width: "70%",
            height: "auto",

            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Alert
            severity="info"
            sx={{ width: "100%", textAlign: "start", marginBottom: "10px" }}
          >
            Please add an integration configuration for payments.
          </Alert>
          <ResponsiveRow
            sx={{
              flexDirection: "row",
              flexWrap: "nowrap",

              width: "100%",
              height: "auto",
            }}
          >
            {((subscriptionService && subscriptionService.serviceCode) ||
              serviceCode) &&
              subscriptionService &&
              (subscriptionService.serviceCode === "CORE" ||
                subscriptionService.serviceCode === "LEAN" ||
                serviceCode === "CORE" ||
                serviceCode === "LEAN") && (
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          subscriptionService
                            ? subscriptionService.addUtilitiesSupport
                            : addUtilitiesSupport
                        }
                        onChange={() => onCheckBoxChange("Utility")}
                      />
                    }
                    label="Add Utilities Support"
                  />
                </FormGroup>
              )}

            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      subscriptionService
                        ? subscriptionService.addMpesaSupport
                        : addMpesaSupport
                    }
                    onChange={() => onCheckBoxChange("Mpesa")}
                  />
                }
                label="Add M-Pesa Integration"
              />
            </FormGroup>

            {((subscriptionService &&
              subscriptionService.serviceCode &&
              !isEmptyString(subscriptionService.serviceCode)) ||
              !isEmptyString(serviceCode)) && (
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        propertyPaymentDetails
                          ? propertyPaymentDetails.addBankPaymentSupport
                          : addBankPaymentSupport
                      }
                      onChange={() => onCheckBoxChange("Bank")}
                    />
                  }
                  label="Add Bank Payment Integration"
                />
              </FormGroup>
            )}
          </ResponsiveRow>
          <ResponsiveRow
            sx={{ width: "100%", height: "auto", flexDirection: "column" }}
            container
          >
            {((subscriptionService && subscriptionService.addMpesaSupport) ||
              addMpesaSupport) && (
              <ResponsiveRow
                item
                sx={{
                  width: "100%",
                  height: "auto",
                }}
              >
                <RadioGroup
                  row
                  sx={{ width: "100%" }}
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="use-radio-group"
                >
                  <FormControlLabel
                    name="radio"
                    control={
                      <Radio
                        checked={
                          propertyPaymentDetails
                            ? propertyPaymentDetails.useSmartKodiPayBill
                            : useSmartKodiPayBill
                        }
                        value="useSmartKodiPayBill"
                        onClick={onRadioChange}
                      />
                    }
                    label="Use a SmartKodi PayBill"
                  />
                  <FormControlLabel
                    name="radio"
                    control={
                      <Radio
                        checked={
                          propertyPaymentDetails
                            ? propertyPaymentDetails.useYourPayBill
                            : useYourPayBill
                        }
                        value="useYourPayBill"
                        onClick={onRadioChange}
                      />
                    }
                    label="Use Your PayBill"
                  />
                </RadioGroup>
              </ResponsiveRow>
            )}
            {((subscriptionService && subscriptionService.addMpesaSupport) ||
              addMpesaSupport) &&
              ((propertyPaymentDetails &&
                propertyPaymentDetails.useSmartKodiPayBill) ||
                useSmartKodiPayBill) && (
                <ResponsiveRow
                  sx={{
                    width: "100%",
                    height: "auto",
                    textAlign: "start",
                    // flexDirection: "column",
                  }}
                >
                  <ResponsiveRow
                    item
                    sx={{
                      flexDirection: "row",
                      width: { xs: "100%", lg: "100%" },
                      height: "auto",
                      marginBottom: "10px",
                    }}
                  >
                    <Alert
                      severity="info"
                      sx={{ width: "100%", marginBottom: "20px" }}
                    >
                      Please select the frequency and day/date that we will be
                      transferring amount we have on our paybill to your
                      account.
                    </Alert>

                    <FormControl
                      fullWidth
                      sx={{
                        margin: { md: "auto 0px", xs: "10px auto " },
                        width: { xs: "100%", lg: "33.3%" },
                      }}
                    >
                      <InputLabel id="TransferFrequency">
                        Transfer Frequency *
                      </InputLabel>
                      <Select
                        defaultValue={null}
                        labelId="TransferFrequency"
                        id="transferFrequency"
                        required={true}
                        label="Transfer Frequency *"
                        sx={{ width: { xs: "100%", md: "90%" } }}
                        value={
                          propertyPaymentDetails
                            ? propertyPaymentDetails &&
                              propertyPaymentDetails.transferFrequencyCd
                            : transferFrequencyCd
                        }
                        onChange={(event) =>
                          handleTransferFrequencyChange(event)
                        }
                      >
                        {transferPeriods && transferPeriods.length > 0 ? (
                          transferPeriods.map(function (period, i) {
                            return (
                              <MenuItem key={period.value} value={period.value}>
                                {period.label}
                              </MenuItem>
                            );
                          }, this)
                        ) : (
                          <MenuItem sx={{ width: "100%" }}>
                            No Results Found
                          </MenuItem>
                        )}
                      </Select>
                    </FormControl>
                    {((propertyPaymentDetails &&
                      propertyPaymentDetails.transferFrequencyCd === "MNTH") ||
                      transferFrequencyCd === "MNTH") && (
                      <FormControl
                        fullWidth
                        sx={{
                          margin: { md: "auto 0px", xs: "10px auto " },
                          width: { xs: "100%", lg: "33.3%" },
                        }}
                      >
                        <InputLabel id="TransferDay">Transfer Day *</InputLabel>
                        <Select
                          defaultValue={null}
                          labelId="TransferDay"
                          id="transferDay"
                          required={true}
                          label="Transfer Day *"
                          sx={{ width: { xs: "100%", md: "90%" } }}
                          value={
                            propertyPaymentDetails
                              ? propertyPaymentDetails &&
                                propertyPaymentDetails.transferDay
                              : transferDay
                          }
                          onChange={(event) => handleTransferDayChange(event)}
                        >
                          {transferDays && transferDays.length > 0 ? (
                            transferDays.map(function (day, i) {
                              return (
                                <MenuItem key={day.value} value={day.value}>
                                  {day.label}
                                </MenuItem>
                              );
                            }, this)
                          ) : (
                            <MenuItem sx={{ width: "100%" }}>
                              No Results Found
                            </MenuItem>
                          )}
                        </Select>
                      </FormControl>
                    )}

                    {((propertyPaymentDetails &&
                      propertyPaymentDetails.transferFrequencyCd === "QT") ||
                      (propertyPaymentDetails &&
                        propertyPaymentDetails.transferFrequencyCd === "ANU") ||
                      transferFrequencyCd === "QT" ||
                      transferFrequencyCd === "ANU") && (
                      <FormControl
                        fullWidth
                        sx={{
                          margin: { md: "auto 0px", xs: "10px auto " },
                          width: { xs: "100%", lg: "33.3%" },
                        }}
                      >
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          adapterLocale="en-gb"
                        >
                          <DatePicker
                            sx={{ width: { xs: "100%", lg: "90%" } }}
                            id={"transferDate"}
                            label="Transfer Date *"
                            value={
                              propertyPaymentDetails
                                ? propertyPaymentDetails &&
                                  dayjs(propertyPaymentDetails.transferDate)
                                : dayjs(transferDate)
                            }
                            onChange={(newDate) =>
                              handleTransferDateChange(newDate)
                            }
                            
                          />
                        </LocalizationProvider>
                      </FormControl>
                    )}
                  </ResponsiveRow>
                </ResponsiveRow>
              )}
            {((subscriptionService && subscriptionService.addMpesaSupport) ||
              addMpesaSupport) &&
              ((propertyPaymentDetails &&
                propertyPaymentDetails.useYourPayBill) ||
                useYourPayBill) && (
                <ResponsiveRow
                  sx={{
                    width: "100%",
                    height: "auto",

                    flexDirection: "column",
                    flexWrap: "nowrap",
                  }}
                >
                  <Alert
                    severity="info"
                    sx={{ width: "100%", marginBottom: "20px" }}
                  >
                    Please fill in the details for your paybill.
                  </Alert>

                  <FormControl sx={{ width: { xs: "100%", lg: "50%" } }}>
                    <TextField
                      sx={{ width: "90%" }}
                      label="M-Pesa Paybill"
                      required
                      onChange={(event) => {
                        const value = event.target.value;
                        setMpesaPaybill(value);
                        let obj = propertyPaymentDetails;
                        obj.mpesaPaybill = value;
                      }}
                      value={
                        propertyPaymentDetails
                          ? propertyPaymentDetails &&
                            propertyPaymentDetails.mpesaPaybill
                          : mpesaPaybill
                      }
                    />
                  </FormControl>
                </ResponsiveRow>
              )}
            {((subscriptionService &&
              subscriptionService.serviceCode &&
              !isEmptyString(subscriptionService.serviceCode)) ||
              !isEmptyString(serviceCode)) && (
              <ResponsiveRow sx={{ width: "100%", height: "auto" }}>
                {((((subscriptionService &&
                  subscriptionService.serviceCode &&
                  !isEmptyString(subscriptionService.serviceCode)) ||
                  !isEmptyString(serviceCode)) &&
                  propertyPaymentDetails &&
                  propertyPaymentDetails.addBankPaymentSupport) ||
                  addBankPaymentSupport) && (
                  <ResponsiveRow
                    sx={{
                      width: "100%",
                      height: "auto",
                      textAlign: "start",
                      padding: 0,
                    }}
                  >
                    <Grid
                      item
                      sx={{
                        width: { xs: "100%", lg: "100%" },
                        marginBottom: "10px",
                      }}
                    >
                      <Alert
                        severity="info"
                        sx={{ width: "100%", margin: "10px auto" }}
                      >
                        Please fill in the details for your Bank. This payment
                        information will be included in the invoices sent to
                        clients
                      </Alert>
                    </Grid>
                    <Grid
                      item
                      sx={{
                        width: { xs: "100%", lg: "33.3%" },
                        marginBottom: "10px",
                      }}
                    >
                      <FormControl
                        fullWidth
                        sx={{
                          margin: { md: "auto 0px", xs: "10px auto " },
                          width: "100%",
                        }}
                      >
                        <InputLabel id="Bank">Bank *</InputLabel>
                        <Select
                          defaultValue={null}
                          labelId="Bank"
                          id="bank"
                          required={true}
                          label="Bank *"
                          sx={{ width: { xs: "100%", md: "90%" } }}
                          value={
                            propertyPaymentDetails
                              ? propertyPaymentDetails.refBankId
                              : refBankId
                          }
                          onChange={(event) => handleBankChange(event)}
                        >
                          {banksList && banksList.length > 0 ? (
                            banksList.map(function (b, i) {
                              return (
                                <MenuItem key={b.value} value={b.value}>
                                  {b.label}
                                </MenuItem>
                              );
                            }, this)
                          ) : (
                            <MenuItem sx={{ width: "100%" }}>
                              No Results Found
                            </MenuItem>
                          )}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid
                      item
                      sx={{
                        width: { xs: "100%", lg: "33.3%" },
                        marginBottom: "10px",
                      }}
                    >
                      <TextField
                        sx={{ width: { xs: "100%", lg: "90%" } }}
                        label="Branch Name"
                        required
                        onChange={(event) => {
                          const value = event.target.value;
                          setBankBranchName(value);
                          propertyPaymentDetails.bankBranchName = value;
                        }}
                        value={
                          propertyPaymentDetails
                            ? propertyPaymentDetails &&
                              propertyPaymentDetails.bankBranchName
                            : bankBranchName
                        }
                      />
                    </Grid>

                    <Grid
                      item
                      sx={{
                        width: { xs: "100%", lg: "33.3%" },
                        marginBottom: "10px",
                      }}
                    >
                      <TextField
                        sx={{ width: { xs: "100%", lg: "90%" } }}
                        label="Account Name"
                        required
                        onChange={(event) => {
                          const value = event.target.value;
                          setBankAccountName(value);
                          propertyPaymentDetails.bankAccountName = value;
                        }}
                        value={
                          propertyPaymentDetails
                            ? propertyPaymentDetails &&
                              propertyPaymentDetails.bankAccountName
                            : bankAccountName
                        }
                      />
                    </Grid>

                    <Grid
                      item
                      sx={{
                        width: { xs: "100%", lg: "33.3%" },
                        marginBottom: "10px",
                      }}
                    >
                      <TextField
                        sx={{ width: { xs: "100%", lg: "90%" } }}
                        label="Account Number"
                        required
                        onChange={(event) => {
                          const value = event.target.value;
                          setBankAccountNo(value);
                          propertyPaymentDetails.bankAccountNo = value;
                        }}
                        value={
                          propertyPaymentDetails
                            ? propertyPaymentDetails &&
                              propertyPaymentDetails.bankAccountNo
                            : bankAccountNo
                        }
                      />
                    </Grid>
                  </ResponsiveRow>
                )}
              </ResponsiveRow>
            )}{" "}
          </ResponsiveRow>{" "}
        </ResponsiveRow>
      </ResponsiveRow>{" "}
      {/* upload .xlsx dialog */}
      {((subscriptionService && subscriptionService.serviceCode) ||
        serviceCode) &&
        (subscriptionService.serviceCode !== "BLWTR" ||
          serviceCode !== "BLWTR") && (
          <ResponsiveRow sx={{ width: "100%", height: "auto" }} container>
            <Grid
              item
              sx={{
                width: { xs: "100%", lg: "100%" },
                marginBottom: "10px",
              }}
            >
              <Alert severity="info" sx={{ width: "100%" }}>
                Please select how you will create property's units/meters
                together with your clients.
              </Alert>
            </Grid>
            <Grid
              item
              sx={{
                width: { xs: "100%", lg: "100%" },
                marginBottom: "10px",
              }}
            >
              <RadioGroup
                row
                sx={{ width: "100%" }}
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="use-radio-group"
              >
                <FormControlLabel
                  name="radio"
                  control={
                    <Radio
                      checked={
                        propertyUpload
                          ? propertyUpload &&
                            propertyUpload.enterDetailsManually
                          : enterDetailsManually
                      }
                      value="enterDetailsManually"
                      onClick={onRadioChange}
                    />
                  }
                  label="I will Enter Units/Meters Details Manually"
                />
                <FormControlLabel
                  name="radio"
                  control={
                    <Radio
                      checked={
                        propertyUpload
                          ? propertyUpload && propertyUpload.useUploadExcel
                          : useUploadExcel
                      }
                      value="useUploadExcel"
                      onClick={onRadioChange}
                    />
                  }
                  label="I will Use Upload Template (xlsx)"
                />
              </RadioGroup>
            </Grid>
          </ResponsiveRow>
        )}
      {((subscriptionService && subscriptionService.serviceCode) ||
        serviceCode) &&
        ((propertyUpload && propertyUpload.useUploadExcel) ||
          useUploadExcel) && (
          <ResponsiveRow
            sx={{
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              width: "100%",
              height: "auto",
              marginTop: "10px",
              margin: { xs: "auto", lg: "auto 20px" },
              padding: { xs: "5px", lg: "20px" },
              borderRadius: "10px",
              backgroundColor: "#efefef",
            }}
          >
            <ResponsiveRow
              sx={{
                width: { xs: "100%", lg: "100%" },
                marginBottom: "10px",
                justifyContent: { xs: "center", lg: "space-between" },
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  marginBottom: "20px",
                  padding: "10px",
                  float: "left",
                }}
              >
                Upload Details Template (XLSX)
              </Typography>
              <ModalAcceptButton
                onClick={() =>
                  getSignUpTemplate(
                    subscriptionService
                      ? subscriptionService.serviceCode
                      : serviceCode
                  )
                }
                sx={{ width: { xs: "100%", lg: "250px" }, height: "50px" }}
              >
                <Download sx={{ margin: "auto 5px" }} />
                Download Template
              </ModalAcceptButton>
            </ResponsiveRow>

            <ResponsiveRow
              sx={{
                justifyContent: { xs: "center", lg: "flex-start" },
                alignItems: "center",
                flexWrap: { xs: "wrap", lg: "nowrap" },
                padding: 0,
                width: "100%",
              }}
            >
              {/*<FileUpload/>*/}
              <FileUpload
                id={"fileInput"}
                bg_color="#fff"
                width="400px"
                //   label={"Upload Document (Max 10MB)"}
                currentFile={file}
                onChange={uploadFileChanged}
                accept=".xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              />

              <ModalAcceptButton
                sx={{
                  width: { xs: "100%", lg: "max-content" },
                }}
                onClick={uploadDocument}
              >
                <Upload sx={{ margin: "auto 5px" }} />
                Upload Completed Template
              </ModalAcceptButton>
            </ResponsiveRow>
          </ResponsiveRow>
        )}
      <ResponsiveRow
        sx={{
          width: "100%",
          height: "auto",
          justifyContent: { xs: "center", lg: "flex-end" },
        }}
      >
        <ModalCancelButton
          onClick={props.handleBackButtonClicked}
          sx={{
            width: { xs: "100%", lg: "200px" },
          }}
        >
          Go Back
        </ModalCancelButton>
        <ModalAcceptButton
          onClick={saveAndFinishLater}
          sx={{
            width: { xs: "100%", lg: "200px" },
          }}
        >
          Save & Finish Later
        </ModalAcceptButton>
        <ModalAcceptButton
          onClick={saveAndContinue}
          sx={{
            width: { xs: "100%", lg: "200px" },
          }}
        >
          Save & Continue
        </ModalAcceptButton>
      </ResponsiveRow>
    </ResponsiveRow>
  );
}
