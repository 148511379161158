import {serviceAPI, serviceAPIDownloadFile} from "./service"
import {
    DELETE_MPESA_DOC,
    DOW_URL,
    DOWNLOAD_MOBILE_MONEY_CLIENTS,
    EDIT_TRANSFER_FREQUENCY,
    EXPORT_MOBILE_MONEY_CLIENTS,
    FACILITIES_BY_SUBSCRIPTION_URL,
    LIST_MOBILE_MONEY_CLIENTS,
    MOBILE_MONEY_SUB_LIST,
    TOTAL_MOBILE_MONEY_CLIENTS,
    TRF_PRD_URL,
    UPLOAD_MOBILE_MONEY_DOCUMENTS_URL,
    VIEW_MOBILE_MONEY_URL
} from "../constants/url.constants"
import {handleErrorResponse} from "../actions/message"

export const listMobileMoneyClients = (facilityName, paybill, pageNo) => {
    let url = LIST_MOBILE_MONEY_CLIENTS + facilityName + "&paybill=" + paybill + "&pageNo=" + pageNo
    return serviceAPI
        .get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const totalMobileMoneyClients = (facilityName, paybill) => {
    let url = TOTAL_MOBILE_MONEY_CLIENTS + facilityName + "&paybill=" + paybill
    return serviceAPI
        .get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getMobileMoneySubscribersList = () => {
    return serviceAPI
        .get(MOBILE_MONEY_SUB_LIST)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getFacilitiesForSub = (subscriberId) => {
    let url = FACILITIES_BY_SUBSCRIPTION_URL + subscriberId
    return serviceAPI
        .get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const transferPeriods = () => {
    return serviceAPI
        .get(TRF_PRD_URL)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getDaysOfTheWeek = () => {
    return serviceAPI
        .get(DOW_URL)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const editTransferFrequency = (data) => {
    return serviceAPI
        .post(EDIT_TRANSFER_FREQUENCY, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const viewMobileMoneyClient = (facilityId) => {
    let url = VIEW_MOBILE_MONEY_URL + facilityId
    return serviceAPI
        .get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const uploadMpesaDocument = (data, facilityId) => {
    let url = UPLOAD_MOBILE_MONEY_DOCUMENTS_URL + facilityId
    return serviceAPI
        .post(url, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const exportMobileMoneyClients = () => {
    return serviceAPIDownloadFile.get(EXPORT_MOBILE_MONEY_CLIENTS)
}

export const downloadSelectedMobileMoneyClients = (data) => {
    return serviceAPI
        .post(DOWNLOAD_MOBILE_MONEY_CLIENTS, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const deleteMpesaDoc = (data, facilityId) => {
    let url = DELETE_MPESA_DOC + facilityId
    return serviceAPI
        .post(url, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}
