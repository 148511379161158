import {serviceAPI} from "./service"
import {
    BULK_UPLOAD_VENDORS,
    DELETE_VENDOR,
    FETCH_VENDORS,
    LOAD_VENDORS,
    SAVE_VENDOR,
    TOTAL_VENDORS,
    VEN_CAT_URL
} from "../constants/url.constants"
import {handleErrorResponse} from "../actions/message"


export const loadVendors = (name, pageNo, rowsPerPage) => {
    let url = LOAD_VENDORS + name + "&pageNo=" + pageNo + "&rowsPerPage=" + rowsPerPage
    return serviceAPI
        .get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const totalVendors = (name) => {
    let url = TOTAL_VENDORS + name
    return serviceAPI
        .get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const listVendorCategories = () => {
    return serviceAPI
        .get(VEN_CAT_URL)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const saveVendor = (data) => {
    return serviceAPI
        .post(SAVE_VENDOR, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const deleteVendor = (data) => {
    return serviceAPI
        .post(DELETE_VENDOR, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const fetchVendor = (personId) => {
    let url = FETCH_VENDORS + personId
    return serviceAPI
        .get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const bulkUploadVendors = (data) => {
    return serviceAPI
        .post(BULK_UPLOAD_VENDORS, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}
