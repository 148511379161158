import {
    APPROVE_PAYMENT_URL,
    DELETE_BILL_DOCUMENT,
    DELETE_BILL_REMINDER_URL,
    DELETE_BILL_URL,
    DELETE_PAYMENT_DOCUMENT,
    FETCH_BILL_URL,
    FETCH_PAYMENT_URL,
    LIST_BILL_REMINDERS_URL,
    LIST_BILLS_URL,
    LIST_PAYMENTS_URL,
    REJECT_PAYMENT_URL,
    SAVE_BILL_REMINDER_URL,
    SAVE_BILL_URL,
    SAVE_PAYMENT_URL,
    TOTAL_BILLS_URL,
    TOTAL_PAYMENTS_URL,
    UNPAID_AND_PARTIAL_BILLS_URL,
    UPLOAD_BILL_DOCUMENT,
    UPLOAD_PAYMENT_DOCUMENT
} from "../constants/url.constants"
import {serviceAPI, serviceAPIUpload} from "./service"
import {handleErrorResponse} from "../actions/message"

//GET REQUEST

//BILLS FUNCTIONS
export const getBills = (data) => {
    return serviceAPI.post(LIST_BILLS_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getTotalBills = (data) => {
    return serviceAPI.post(TOTAL_BILLS_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const fetchBill = (billId) => {
    let url = FETCH_BILL_URL + billId
    return serviceAPI.get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

//PAYMENTS FUNCTIONS
export const getPayments = (data) => {
    return serviceAPI.post(LIST_PAYMENTS_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getTotalPayments = (data) => {
    return serviceAPI.post(TOTAL_PAYMENTS_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const fetchPayment = (paymentId) => {
    let url = FETCH_PAYMENT_URL + paymentId
    return serviceAPI.get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const unpaidAndPartialVendorBills = (vendorId) => {
    let url = UNPAID_AND_PARTIAL_BILLS_URL + vendorId
    return serviceAPI.get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

//BILL REMINDERS FUNCTIONS
export const getBillReminders = (data) => {
    return serviceAPI.post(LIST_BILL_REMINDERS_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}


//POST REQUEST

//BILLS FUNCTIONS
export function deleteBillData(data) {
    return serviceAPI
        .post(DELETE_BILL_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export function uploadBillDocument(data, billId) {
    let url = UPLOAD_BILL_DOCUMENT + billId
    return serviceAPI
        .post(url, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export function deleteBillDocument(data) {
    return serviceAPI
        .post(DELETE_BILL_DOCUMENT, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export function saveBillData(data) {
    return serviceAPI
        .post(SAVE_BILL_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}


//PAYMENTS FUNCTIONS
export function uploadPaymentDocument(data, paymentId) {
    let url = UPLOAD_PAYMENT_DOCUMENT + paymentId
    return serviceAPI
        .post(url, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export function deletePaymentDocument(data) {
    return serviceAPI
        .post(DELETE_PAYMENT_DOCUMENT, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export function approvePayment(data) {
    return serviceAPI
        .post(APPROVE_PAYMENT_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export function rejectPayment(data) {
    return serviceAPI
        .post(REJECT_PAYMENT_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export function savePaymentData(data) {
    return serviceAPIUpload
        .post(SAVE_PAYMENT_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}


//BILL REMINDERS FUNCTIONS
export function saveBillReminderData(data) {
    return serviceAPI
        .post(SAVE_BILL_REMINDER_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export function deleteBillReminderData(data) {
    return serviceAPI
        .post(DELETE_BILL_REMINDER_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}
