import {serviceAPI} from "./service"
import {
    FAC_USR_URL,
    GET_RECEIVED_MESSAGES_URL,
    GET_SENT_MESSAGES_URL,
    MARK_AS_READ_URL,
    SEND_MESSAGE_URL
} from "../constants/url.constants"
import {handleErrorResponse} from "../actions/message"

export const getFacilityUsers = () => {
    return serviceAPI.get(FAC_USR_URL)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getSentMessages = (pageNo, data) => {
    let url = GET_SENT_MESSAGES_URL + pageNo
    return serviceAPI.post(url, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getReceivedMessages = (pageNo, data) => {
    let url = GET_RECEIVED_MESSAGES_URL + pageNo
    return serviceAPI.post(url, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const sendMessage = (data) => {
    return serviceAPI.post(SEND_MESSAGE_URL, data)
        .then((response) => {
            // console.log(response.data)
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const markAsRead = (data) => {
    return serviceAPI.post(MARK_AS_READ_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}
