import {Typography} from "@mui/material"
import {ResponsiveRow} from "../../../constants/layout.constants"
import AppointmentItem from "./appointmentItem"
import {useEffect, useState} from "react"

export default function AppointmentViewer(props) {
    const [month, setMonth] = useState("")

    useEffect(() => {
        switch (props.month) {
            case 0:
                setMonth("January")
                break

            case 1:
                setMonth("February")
                break

            case 2:
                setMonth("March")
                break

            case 3:
                setMonth("April")
                break

            case 4:
                setMonth("May")
                break

            case 5:
                setMonth("June")
                break

            case 6:
                setMonth("July")
                break

            case 7:
                setMonth("August")
                break

            case 8:
                setMonth("September")
                break

            case 9:
                setMonth("October")
                break

            case 10:
                setMonth("November")
                break

            case 11:
                setMonth("December")
                break

            default:
        }

        return () => {
            setMonth("")
        }
    }, [props.month])

    return (
        <ResponsiveRow
            sx={{
                flexDirection: "column",
                flexWrap: "nowrap",
                width: {xs: "100%", lg: "29%"},
                height: "70vh",

                backgroundColor: "#fff",
                borderRadius: "20px ",

                padding: {xS: "20px", lg: "20px 40px"},
                // margin: "auto",
            }}
        >
            <Typography
                variant="h4"
                sx={{
                    width: {xs: "90%", lg: "100%"},
                    textAlign: "start",
                    margin: "20px auto",
                }}
            >
                {props.day} {month}'s Schedule
            </Typography>

            <ResponsiveRow
                sx={{
                    flexDirection: "column",
                    flexWrap: "nowrap",

                    alignItems: "flex-start",
                    justifyContent: "flex-start",

                    height: "90%",
                    width: "100%",
                    overflowY: "auto",
                }}
            >
                {props.appointments && props.appointments.length > 0 && props.appointments.map((item, i) => {
                    return (
                        <AppointmentItem key={i} appointment={item}/>
                    )
                })}
            </ResponsiveRow>
        </ResponsiveRow>
    )
}
