import {useEffect, useState} from "react"
import {FormControl, Typography} from "@mui/material"
import {ResponsiveRow} from "../../../constants/layout.constants"
import LineChartsCard from "../../charts/LineChartCard"
import {getBulkMeterSales} from "../../../services/dashboard.service"
import "dayjs/locale/en-gb"
import dayjs from "dayjs"
import {YearPickerInput} from "../../inputs/datePickerInput"
import {useSelector} from "react-redux";

/**
 *
 * REQUIRED PROPS
 *
 * customWidth: string
 * customHeight: string
 * data: {
 *    cashSalesIncomeData: []
 *    mobileMoneyIncomeData: []
 *    creditMoneyIncomeData: []
 *  }
 *
 */

export default function MetersBulkSales({customHeight, customWidth}) {
    const [year, setYear] = useState(new Date());
    const [bulkSalesData, setBulkSalesData] = useState({
        cashSaleIncome: null,
        mobileMoneySaleIncome: null,
        creditSaleIncome: null,
    });
    const [loading, setLoading] = useState(false);
    const {user} = useSelector((state) => state.auth)
    useEffect(() => {
        setLoading(true);
        getBulkMeterSales(year.getFullYear())
            .then((response) => {
                if (response && response.data) {
                    setBulkSalesData(response.data);
                }
            })
            .finally(() => {
                setLoading(false);
            });
        return () => {
            setBulkSalesData({
                cashSaleIncome: null,
                mobileMoneySaleIncome: null,
                creditSaleIncome: null,
            });
        };
    }, [year, user.proxyFacility]);

    return (
        <ResponsiveRow
            sx={{
                flexDirection: "column",
                flexWrap: "nowrap",

                height: {xs: "100%", lg: customHeight},
                minWidth: {xs: "100%", lg: customWidth},
                width: "100%",

                margin: "10px",

                backgroundColor: "#fff",
                borderRadius: "20px",
            }}
        >
            <ResponsiveRow
                sx={{
                    flexDirection: "row",

                    width: "100%",
                    height: "auto",

                    textAlign: "start",

                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
                <Typography sx={{width: "75%"}} variant="h5">
                    Meters: Bulk Sales
                </Typography>
                <FormControl sx={{width: {xs: "100%", lg: "25%"}}}>
                    <YearPickerInput
                        labelText="Select Year"
                        componentID="bulkMeterSales"
                        disableFuture={true}
                        disablePast={false}
                        onSelectDate={(new_value) => {
                            // console.log("year Val", new_value);
                            setYear(new Date(new_value))
                        }}
                        value={dayjs(year)}
                    />
                </FormControl>
            </ResponsiveRow>

            <ResponsiveRow
                sx={{
                    height: "80%",
                    width: "100%",

                    flexWrap: "nowrap",
                    flexDirection: "row",

                    alignItem: "center",
                    justifyContent: "center",
                }}
            >
                <LineChartsCard
                    currentlyLoading={loading}
                    labelDataObj={{
                        dataLabels: [
                            "Jan",
                            "Feb",
                            "Mar",
                            "Apr",
                            "May",
                            "Jun",
                            "Jul",
                            "Aug",
                            "Sep",
                            "Oct",
                            "Nov",
                            "Dec",
                        ],

                        dataset: [
                            {
                                datasetLabel: "Cash Sales Income",
                                datasetData: bulkSalesData.cashSaleIncome,
                            },
                            {
                                datasetLabel: "Mobile Money Sales Income",
                                datasetData: bulkSalesData.mobileMoneySaleIncome,
                            },
                            {
                                datasetLabel: "Credit Sales Income",
                                datasetData: bulkSalesData.creditSaleIncome,
                            },
                        ],
                    }}
                />
            </ResponsiveRow>
        </ResponsiveRow>
    );
}
