import {
    LIST_TOTAL_USERS,
    LIST_USERS,
    REMOVE_USER,
    SAVE_NEW_CONTACT_PERSON,
    SAVE_USER,
    USR_ADMIN_ROLES,
} from "../constants/url.constants"
import {serviceAPI} from "./service"
import {handleErrorResponse} from "../actions/message"

export const getUserRoles = () => {
    return serviceAPI
        .get(USR_ADMIN_ROLES)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const listUsers = (name, role, pageNo, limit) => {
    let url = LIST_USERS + "?name=" + name + "&role=" + role + "&pageNo=" + pageNo + "&limit=" + limit
    return serviceAPI
        .get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const listTotalUsers = (name, role) => {
    let url = LIST_TOTAL_USERS + "?name=" + name + "&role=" + role
    return serviceAPI
        .get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const saveUser = (data) => {
    return serviceAPI.post(SAVE_USER, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const saveNewContactPerson = (data) => {
    return serviceAPI.post(SAVE_NEW_CONTACT_PERSON, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const removeUser = (data) => {
    return serviceAPI
        .post(REMOVE_USER, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}
