import {
    APPROVE_CREDIT_NOTE_URL,
    APPROVE_DEBIT_NOTE_URL,
    APPROVE_DEPOSIT_CHARGE_URL,
    APPROVE_PAYMENT_URL,
    BULK_UPLOAD_MAINTENANCE_FACILITIES_URL,
    CHARGE_ITEMS,
    CREDIT_ITEMS, DELETE_VACATE_NOTICE_DOCUMENT,
    DOWNLOAD_FILE_DOCID_URL,
    DOWNLOAD_FILE_FILENAME_URL,
    GET_CREDIT_NOTE_URL,
    GET_DEBIT_NOTE_URL,
    GET_LEASE_CHARGE_URL, GET_LEASE_TERMINATE_REQUEST_URL,
    GET_PAYMENT_URL, GET_VACATE_NOTICE_URL,
    GET_WORK_ORDER_DETAIL_URL,
    GET_WORK_ORDER_URL,
    PERSON_ORG_SEARCH_URL,
    PERSON_SEARCH_URL,
    PROPERTY_BULK_UPLOAD_TEMPLATE_URL,
    REJECT_CREDIT_NOTE_URL,
    REJECT_DEBIT_NOTE_URL,
    REJECT_DEPOSIT_CHARGE_URL,
    REJECT_PAYMENT_URL,
    SAVE_CREDIT_NOTE_URL,
    SAVE_DEBIT_NOTE_URL, UPLOAD_VACATE_NOTICE_DOCUMENT
} from "../constants/url.constants"
import {serviceAPI, serviceAPIDownloadFile, upload} from "./service"
import {handleErrorResponse} from "../actions/message"

//POST REQUEST
export const saveCreditNote = (data) => {
    return serviceAPI.post(SAVE_CREDIT_NOTE_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const saveDebitNote = (data) => {
    return serviceAPI.post(SAVE_DEBIT_NOTE_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const personOrgSearch = (data) => {
    return serviceAPI.post(PERSON_ORG_SEARCH_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const personSearch = (data) => {
    return serviceAPI.post(PERSON_SEARCH_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const downloadByFileName = (fileName) => {
    return serviceAPIDownloadFile.get(DOWNLOAD_FILE_FILENAME_URL + fileName)
}


export const downloadByDocumentId = (documentId) => {
    return serviceAPIDownloadFile.get(DOWNLOAD_FILE_DOCID_URL + documentId)
}

export const downloadBulkUploadTemplate = (subscriptionType) => {
    return serviceAPIDownloadFile.get(PROPERTY_BULK_UPLOAD_TEMPLATE_URL + subscriptionType)
}

/**
 * List items to be credited from an invoice or charge.
 * Mostly used for void transactions.
 * @param type
 * @param itemId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const creditItemsList = (type, itemId) => {
    let url = CREDIT_ITEMS + "?itemType=" + type + "&itemId=" + itemId
    return serviceAPI
        .get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}
/**
 * List items to be charged from a receipt or credit.
 * Mostly used in voiding transactions.
 * @param type
 * @param itemId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const chargeItemList = (type, itemId) => {
    let url = CHARGE_ITEMS + "?itemType=" + type + "&itemId=" + itemId
    return serviceAPI
        .get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getPayment = (paymentId) => {
    let url = GET_PAYMENT_URL + paymentId
    return serviceAPI
        .get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getVacateNotice = (vacateNoticeId) => {
    let url = GET_VACATE_NOTICE_URL + vacateNoticeId
    return serviceAPI
        .get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getDebitNote = (debitNoteId) => {
    let url = GET_DEBIT_NOTE_URL + debitNoteId
    return serviceAPI
        .get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getCreditNote = (creditNoteId) => {
    let url = GET_CREDIT_NOTE_URL + creditNoteId
    return serviceAPI
        .get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getWorkOrder = (workOrderId) => {
    let url = GET_WORK_ORDER_URL + workOrderId
    return serviceAPI
        .get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getLeaseTerminateRequest = (leaseTerminateRequestId) => {
    let url = GET_LEASE_TERMINATE_REQUEST_URL + leaseTerminateRequestId
    return serviceAPI
        .get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getWorkOrderDetail = (workOrderDetailId) => {
    let url = GET_WORK_ORDER_DETAIL_URL + workOrderDetailId
    return serviceAPI
        .get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export function uploadVacateNoticeDocument(data, vacateNoticeId) {
    let url = UPLOAD_VACATE_NOTICE_DOCUMENT + vacateNoticeId
    return serviceAPI
        .post(url, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export function deleteVacateNoteDocument(data) {
    return serviceAPI
        .post(DELETE_VACATE_NOTICE_DOCUMENT, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getDeposit = (depositId) => {
    let url = GET_LEASE_CHARGE_URL + depositId
    return serviceAPI
        .get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const approveDebitNote = (data) => {
    return serviceAPI.post(APPROVE_DEBIT_NOTE_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const rejectDebitNote = (data) => {
    return serviceAPI.post(REJECT_DEBIT_NOTE_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const approveCreditNote = (data) => {
    return serviceAPI.post(APPROVE_CREDIT_NOTE_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const rejectCreditNote = (data) => {
    return serviceAPI.post(REJECT_CREDIT_NOTE_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}


export const approvePayment = (data) => {
    return serviceAPI.post(APPROVE_PAYMENT_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const rejectPayment = (data) => {
    return serviceAPI.post(REJECT_PAYMENT_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const approveDepositCharge = (data) => {
    return serviceAPI.post(APPROVE_DEPOSIT_CHARGE_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const rejectDepositCharge = (data) => {
    return serviceAPI.post(REJECT_DEPOSIT_CHARGE_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const bulkUploadMaintenanceFacilities = (data) => {
    return upload(BULK_UPLOAD_MAINTENANCE_FACILITIES_URL, data)
}
