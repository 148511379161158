import {serviceAPI, serviceAPIDownloadFile} from "./service"
import {
    EXPORT_ACCOUNT_HOLDERS,
    EXPORT_TENANTS,
    LIST_TENANTS,
    SAVE_TENANT,
    TOTAL_TENANTS
} from "../constants/url.constants"
import {handleErrorResponse} from "../actions/message"

export const loadTenants = (name, pageNo, rowsPerPage) => {
    let url = LIST_TENANTS + name + "&pageNo=" + pageNo + "&rowsPerPage=" + rowsPerPage
    return serviceAPI
        .get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const totalTenants = (name) => {
    let url = TOTAL_TENANTS + name
    return serviceAPI
        .get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const exportTenants = (tenantType) => {
    return serviceAPIDownloadFile.get(EXPORT_TENANTS + tenantType)
}

export const exportAccountHolders = () => {
    return serviceAPIDownloadFile.get(EXPORT_ACCOUNT_HOLDERS)
}

export const saveTenant = (data) => {
    return serviceAPI
        .post(SAVE_TENANT, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}
