import {useEffect} from "react"
import {Container} from "@mui/material"
import {useDispatch, useSelector} from "react-redux"
import {makeStyles} from "@mui/styles"
import {clearMessage} from "../../actions/message"

// Using Inline Styling
const useStyles = makeStyles((theme) => ({
    navbar: {
        backgroundColor: "#037171",
        minHeight: "6vmin",
        fontSize: `calc(10px + 2vmin)`,
        color: "white",
    },
    menuButton: {
        color: "white!important",
    },
    subMenu: {
        "& .MuiPaper-root": {
            backgroundColor: "#037171",
            color: "white",
        },
    },
    subMenuLink: {
        color: "white",
        textDecoration: "none",
    },
    secureContainer: {
        minHeight: "92.5vmin",
    },
    unsecureContainer: {
        minHeight: "100vmin",
        width: "100vw",
        margin: 0,
        padding: 0,
    },
}))

export default function PublicLayout(props) {
    const {children} = props
    const classes = useStyles()
    const appMessage = useSelector((state) => state.message)

    const dispatch = useDispatch()

    useEffect(() => {
        //clear the message after 5 seconds after it is displayed
        if (appMessage.display) {
            setTimeout(() => {
                // After 3 seconds set the show value to false
                dispatch(clearMessage())
            }, 5000)
        }
    }, [appMessage, dispatch])

    return (
        <>
            <Container
                className={classes.unsecureContainer}
                maxWidth="100%"
                disableGutters={true}
            >
                {/* {appMessage.display && (
          <Alert severity={appMessage.type}>
            <AlertTitle>{titleCase(appMessage.type)}</AlertTitle>
            {appMessage.message}
          </Alert>
        )} */}
                {children}
            </Container>
        </>
    )
}
