/* eslint-disable default-case */
/* eslint-disable react-hooks/exhaustive-deps */
import {Close} from "@mui/icons-material"
import {CircularProgress, IconButton, Typography} from "@mui/material"
import {Box} from "@mui/system"
import {ModalContainer, ModalInnerContainer, ResponsiveRow,} from "../../../constants/layout.constants"
import ListingGalleryImageItem from "../../cards/listings/listingGalleryImageItem"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import {useEffect, useState} from "react"
import {GET_LISTING_IMAGE_URL} from "../../../constants/url.constants";

export default function ListingGalleryViewer(props) {
    /**
     *  REQUIRED PROPS
     * open
     * closeModal
     *
     * listingName
     * listingImages
     * listingDescription
     * listingPrice
     *
     */

    const [currentImage, setCurrentImage] = useState()
    const [listingImages, setListingImages] = useState()

    useEffect(() => {
        if (currentImage === undefined) {
            setListingImages(props.listingImages)
            setCurrentImage(props.listingImages[0])
        }
    }, [])

    function handleImageChange(selectedImage) {
        const updatedImages = listingImages.map((image, index) => {
            image.isCurrent = false

            return image
        })

        selectedImage.isCurrent = true

        setListingImages(updatedImages)
        setCurrentImage(selectedImage)
    }

    function handleImageSwitch(action) {
        const limit = listingImages.length - 1
        let currentIndex = listingImages.indexOf(currentImage)

        switch (action) {
            case "next":
                if (currentIndex === limit) {
                    setCurrentImage(listingImages[0])
                } else setCurrentImage(listingImages[++currentIndex])

                break

            case "prev":
                if (currentIndex === 0) {
                    setCurrentImage(listingImages[limit])
                } else {
                    setCurrentImage(listingImages[--currentIndex])
                }
                break
        }

        const updatedImages = listingImages.map((image, index) => {
            image.isCurrent = false

            return image
        })

        setListingImages(updatedImages)
    }

    return (
        <ModalContainer
            open={props.open}
            onClose={props.closeModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{"& :focus": {border: "0 solid white"}}}
        >
            {currentImage !== undefined && listingImages !== undefined ? (
                <ModalInnerContainer
                    sx={{
                        height: {xs: "50vh", lg: "80vh"},
                        width: {xs: "50vw", lg: "90vw"},
                        overflow: "hidden",
                        margin: 0,
                        padding: 0,
                    }}
                >
                    <ResponsiveRow
                        sx={{
                            flexDirection: "row",
                            width: "100%",
                            padding: 0,
                            margin: 0,
                            height: "100%",
                        }}
                    >
                        <ResponsiveRow
                            sx={{
                                width: {xs: "100%", lg: "60%"},
                                height: "100%",
                                padding: 0,
                                margin: 0,
                                flexDirection: "column",
                                flexWrap: "nowrap",
                                position: "relative",
                            }}
                        >
                            <Box
                                component="img"
                                src={GET_LISTING_IMAGE_URL + "?filename=" + (currentImage.systemFileName)}
                                alt="selected image"
                                sx={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover",
                                    objectPosition: "center center",
                                    position: "relative",
                                    zIndex: 4,
                                }}
                            />
                            <ResponsiveRow
                                sx={{
                                    position: "fixed",
                                    zIndex: 5,

                                    bottom: 0,

                                    width: "60%",
                                    height: "auto",

                                    justifyContent: {xs: "center", lg: "flex-end"},
                                }}
                            >
                                <IconButton
                                    onClick={() => handleImageSwitch("prev")}
                                    sx={{
                                        borderRadius: " 50%",
                                        backgroundColor: "rgba(0,0,0,0.25)",
                                        margin: "auto 10px",
                                        "&:hover": {
                                            backgroundColor: "rgba(0,0,0,0.4)",
                                        },
                                    }}
                                >
                                    <ChevronLeftIcon sx={{color: "#fff", fontSize: "2.5rem"}}/>
                                </IconButton>
                                <IconButton
                                    onClick={() => handleImageSwitch("next")}
                                    sx={{
                                        borderRadius: " 50%",
                                        backgroundColor: "rgba(0,0,0,0.25)",
                                        margin: "auto 10px",
                                        "&:hover": {
                                            backgroundColor: "rgba(0,0,0,0.4)",
                                        },
                                    }}
                                >
                                    <ChevronRightIcon
                                        sx={{color: "#fff", fontSize: "2.5rem"}}
                                    />
                                </IconButton>
                            </ResponsiveRow>
                        </ResponsiveRow>
                        <ResponsiveRow
                            sx={{
                                width: {xs: "100%", lg: "40%"},
                                height: "100%",
                                padding: "20px",
                                margin: 0,
                                flexDirection: "column",
                                flexWrap: "nowrap",
                            }}
                        >
                            <ResponsiveRow
                                sx={{
                                    width: "100%",
                                    height: "25px",
                                    justifyContent: "flex-end",
                                }}
                            >
                                <IconButton onClick={props.closeModal}>
                                    <Close sx={{color: "red"}}/>
                                </IconButton>
                            </ResponsiveRow>
                            <Typography
                                variant="h1"
                                sx={{margin: "20px 0"}}
                                fontWeight={800}
                            >
                                {`${props.listingName} Gallery`}
                            </Typography>{" "}
                            <Typography
                                variant="h5"
                                color="primary"
                                sx={{margin: "10px 0"}}
                                fontWeight={800}
                            >
                                {`${props.listingPrice}`} KSH
                            </Typography>
                            <Typography variant="body2">
                                {props.listingDescription}
                            </Typography>
                            <Typography
                                variant="h4"
                                sx={{color: "#037171", margin: "20px 0"}}
                                fontWeight={800}
                            >
                                Gallery Images:
                            </Typography>
                            <ResponsiveRow
                                sx={{
                                    padding: "5px",
                                    width: "100%",
                                    height: "auto",
                                    flexWrap: "wrap",
                                    justifyContent: "center",
                                }}
                            >
                                {listingImages &&
                                    listingImages.map((image, index) => (
                                        <ListingGalleryImageItem
                                            key={index}
                                            data={image}
                                            viewImage={() => handleImageChange(image)}
                                        />
                                    ))}
                            </ResponsiveRow>
                        </ResponsiveRow>
                    </ResponsiveRow>
                </ModalInnerContainer>
            ) : (
                <CircularProgress/>
            )}
        </ModalContainer>
    )
}
