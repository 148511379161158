import {useTheme} from "@mui/material/styles"
import Box from "@mui/material/Box"
import OutlinedInput from "@mui/material/OutlinedInput"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Select from "@mui/material/Select"
import Chip from "@mui/material/Chip"
import {useMemo, useState} from "react"

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
}

const names = [
    "Location",
    "Number of Rooms",
    "Number of Baths",
    "Square Feet",
    "Listing Type",
]

function getStyles(name, parameter, theme) {
    return {
        fontWeight:
            parameter.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    }
}

export default function AutoAddSelect(props) {
    const theme = useTheme()
    const [parameter, setParameter] = useState([])

    useMemo(() => {
        props.loadSelectedParams(parameter)
    }, [parameter, props])

    const handleChange = (event) => {
        const {
            target: {value},
        } = event
        setParameter(
            // On autofill we get a stringified value.
            typeof value === "string" ? value.split(",") : value
        )
    }

    return (
        <FormControl sx={{m: 1, width: "100%"}}>
            <InputLabel id="auto-add-parameters-label">
                Select Auto Add Parameters
            </InputLabel>
            <Select
                id="auto-add-parameters"
                multiple
                value={parameter}
                onChange={handleChange}
                input={
                    <OutlinedInput
                        id="select-multiple-chip"
                        label="Select Auto Add Parameters"
                    />
                }
                renderValue={(selected) => (
                    <Box sx={{display: "flex", flexWrap: "wrap", gap: 0.5}}>
                        {selected.map((value, i) => (
                            <Chip key={i} label={value}/>
                        ))}
                    </Box>
                )}
                MenuProps={MenuProps}
            >
                {names.map((name, i) => {
                    return <MenuItem
                        key={i}
                        value={name}
                        style={getStyles(name, parameter, theme)}
                    >
                        {name}
                    </MenuItem>
                })}
            </Select>
        </FormControl>
    )
}
