import { serviceAPI, serviceAPIDownloadFile } from './service';
import { handleErrorResponse } from '../actions/message';

export const downloadReport = (url, data) => {
	return serviceAPIDownloadFile.post(url, data).then((response) => response);
};

export const exportReport = (url, data) => {
	return serviceAPI
		.post(url, data)
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			handleErrorResponse(error);
		});
};
