import {Alert, FormControl, InputLabel, OutlinedInput} from "@mui/material"
import {ResponsiveRow} from "../../../../constants/layout.constants"
import ModalContainerModule from "../../../modules/modalContainer"

export default function AddMeterBillingNote(props) {
    /**
     *
     * REQUIRED PROPS
     *
     * NoteModal
     *
     * onTextChange
     *
     * setNoteModal
     * setNote
     *
     * note
     * saveNote
     * selectedMeterbilling
     *
     */

    return (
        <ModalContainerModule
            size="specialAddMeterBilling"
            accept={props.saveNote}
            cancel={() => {
                props.setNoteModal(false)
                props.setNote("")
            }}
            openModal={props.NoteModal}
            modalTitle={` Add Note To Meter Billing
              ${
                props.selectedMeterbilling &&
                props.selectedMeterbilling.billingCode
            } for
              ${
                props.selectedMeterbilling &&
                props.selectedMeterbilling.customerName
            }`}
            acceptButtonText="Save Note"
        >
            <Alert sx={{width: "100%"}} severity="info">
                Add note to meter billing will log important notes/information
                pertaining this meter billing. All the notes added on this meter billing
                will be displayed chronologically, from newest first to oldest. Notes
                will be limited to 500 words.
            </Alert>
            <br/>
            <ResponsiveRow sx={{width: "100%", height: "auto"}} container>
                <FormControl sx={{width: "100%"}}>
                    <InputLabel>Note (500)</InputLabel>
                    <OutlinedInput
                        sx={{width: "100%"}}
                        id={"note"}
                        name={"note"}
                        value={props.note}
                        multiline
                        rows={5}
                        isrequired
                        onChange={(event) => props.onTextChange(event)}
                    />
                </FormControl>
            </ResponsiveRow>
        </ModalContainerModule>
    )
}
