import React from "react";
import { isNullUndefined } from "../../util/util";
import { MobileNavItem } from "../../constants/component.constants";
import { NavLink } from "react-router-dom";
import Permit from "../../util/permit";

export default function MobileChildlessMenuItem({ item, closeDrawer }) {
  return isNullUndefined(item.roles) && isNullUndefined(item.services) ? (
    <>
      <MobileNavItem
        id={item.id}
        component={NavLink}
        onClick={closeDrawer}
        to={item.href}
        sx={{ textDecoration: "none" }}
      >
        {item.name}
      </MobileNavItem>
    </>
  ) : (
    <>
      <Permit
        roles={item.roles}
        services={item.services}
        permissions={item.permissions}
        key={item.id}
      >
        <MobileNavItem
          component={NavLink}
          onClick={closeDrawer}
          to={item.href}
          id={item.id}
          sx={{ textDecoration: "none" }}
        >
          {item.name}
        </MobileNavItem>
      </Permit>
    </>
  );
}
