import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { ModalAcceptButton } from "../../constants/component.constants";
import {
  isArray,
  isEmptyArray,
  isEmptyString,
  isNullUndefined,
} from "../../util/util";
import { FileUpload } from "../inputs/fileUpload";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/en-gb";
import { useEffect, useState } from "react";
import {
  getInspectionAreas,
  getInspectionTypes,
  getRecommendations,
} from "../../services/list.service";
import { useDispatch } from "react-redux";
import { setMessage } from "../../actions/message";
import { uploadInspections } from "../../services/inspections.service";
import { ResponsiveRow } from "../../constants/layout.constants";
import { Cancel } from "@mui/icons-material";
import ModalContainerModule from "../modules/modalContainer";
import dayjs from "dayjs";

/**
 Required props are:
 - openModal: True or false to show or hide modal respectively.
 - closeModal: Function to close the modal ie set showTerminateModal to false.
 - contract: This is the data for either selected item.
 - inspectionItem: Either a 'Complex, Property, Unit, Asset'
 */

export default function Inspections(props) {
  const [inspectionTypes, setInspectionTypes] = useState([]);
  const [inspectionAreas, setInspectionAreas] = useState([]);
  const [recommendationOptions, setRecommendations] = useState([]);
  const [items, setItems] = useState([]);
  const [selectedInspection, setSelectedInspection] = useState(null);
  const [inspectionDetailItems, setInspectionDetailItems] = useState([]);
  const [bulkFile, setBulkFile] = useState(null);
  const [bulkFileProgress] = useState(0);
  const [inspectionDate, setInspectionDate] = useState("");
  const [nextInspectionDate, setNextInspectionDate] = useState(null);
  const [inspectionDetailsTableRows, setInspectionDetailsTableRows] =
    useState("");
  const [inspectionComment, setInspectionComment] = useState("");
  const [, setRecommend] = useState("");
  const [inspectType, setInspectType] = useState("");
  const [inspectionArea, setInspectArea] = useState("");
  const [, setSpecificArea] = useState("");
  const [, setAssetId] = useState("");
  const [, setFacilityId] = useState("");
  const [, setComplexId] = useState("");
  const [, setUnitId] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    let data = {
      id: "",
      inspectionDate: "",
      inspectionType: "",
      comment: "",
      nextInspectionDate: null,
      facilityId: "",
      complexId: "",
      assetId: "",
      unitId: "",
    };
    setSelectedInspection(data);

    getInspectionTypes().then((inspectionTypes) =>
      setInspectionTypes(inspectionTypes)
    );

    getRecommendations().then((recom) => setRecommendations(recom));

    getInspectionAreas().then((area) => setInspectionAreas(area));
  }, [props]);

  useEffect(() => {
    if (props.inspectionItem === "Edit") {
      setSelectedInspection(props.contract);
      setItems(props.contract.details);
      setInspectionDetailItems(props.contract.details);
      // console.log("data", selectedInspection)
      // console.log("items", items)
      populateInspectionDetails();
    }
  }, [recommendationOptions, inspectionAreas]);

  useEffect(() => {
    populateInspectionDetails();
  }, [inspectionDetailItems]);

  const handleInspectionDateChange = (value) => {
    let date = new Date(value);
    let obj = selectedInspection;
    obj.inspectionDate = value;
    setSelectedInspection(obj);
    setInspectionDate(date);
  };

  const handleNextInspectionDateChange = (value) => {
    let date = new Date(value);
    let obj = selectedInspection;
    obj.nextInspectionDate = value;
    setSelectedInspection(obj);
    setNextInspectionDate(date);
  };

  const handleInspectionTypeChange = (event) => {
    let value = event.target.value;
    let obj = selectedInspection;
    obj.inspectionType = value;
    setSelectedInspection(obj);
    setInspectType(value);
  };

  const handleInspectionAreaChange = (event, index) => {
    let value = event.target.value;
    let newArr = [...items]; // copying the old items array
    newArr[index].inspectionArea = value;
    setInspectionDetailItems(newArr);
    populateInspectionDetails();
  };

  const handleSpecificAreaChange = (event, index) => {
    let value = event.target.value;
    let newArr = [...items]; // copying the old items array
    newArr[index].specificArea = value;
    setInspectionDetailItems(newArr);
    populateInspectionDetails();
  };

  const handleCommentChange = (event, index) => {
    let value = event.target.value;
    let newArr = [...items]; // copying the old items array
    newArr[index].comment = value;
    setInspectionDetailItems(newArr);
    populateInspectionDetails();
  };

  const handleRecommendationChange = (event, index) => {
    let value = event.target.value;
    let newArr = [...items]; // copying the old items array
    newArr[index].recommendations = value;
    setInspectionDetailItems(newArr);
    populateInspectionDetails();
  };

  const handleUploadChange = (event, index) => {
    let value = event.target.files[0];
    console.log("event.target.files[0] ", event.target.files[0]);
    let newArr = [...items]; // copying the old items array
    newArr[index].upload = value;
    setInspectionDetailItems(newArr);
    populateInspectionDetails();
  };

  const populateInspectionDetails = () => {
    let rows = "";
    items &&
      items.length > 0 &&
      (rows = items.map((meterItem, i) => {
        return (
          <TableRow key={i}>
            <TableCell>
              <Select
                sx={{ width: { xs: "100%", lg: "90%" } }}
                id={"inspectionArea"}
                value={items && items[i] && items[i].inspectionArea}
                onChange={(event) => handleInspectionAreaChange(event, i)}
                defaultValue={[""]}
              >
                {inspectionAreas && inspectionAreas.length > 0 ? (
                  inspectionAreas.map((inspArea) => {
                    return (
                      <MenuItem key={inspArea.value} value={inspArea.value}>
                        {inspArea.label}
                      </MenuItem>
                    );
                  }, this)
                ) : (
                  <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
                )}
              </Select>
            </TableCell>
            {items[i].inspectionArea === "RM" && (
              <TableCell>
                <TextField
                  id="specificArea"
                  value={items && items[i] && items[i].specificArea}
                  sx={{
                    width: "90%",
                    fontSize: "10px",
                  }}
                  onChange={(event) => handleSpecificAreaChange(event, i)}
                />
              </TableCell>
            )}
            <TableCell>
              <TextField
                id="comment"
                value={items && items[i] && items[i].comment}
                sx={{
                  width: "90%",
                  fontSize: "10px",
                }}
                onChange={(event) => handleCommentChange(event, i)}
              />
            </TableCell>
            <TableCell>
              <Select
                sx={{ width: { xs: "100%", lg: "90%" } }}
                id={"recommendations"}
                value={items && items[i] && items[i].recommendations}
                onChange={(event) => handleRecommendationChange(event, i)}
                defaultValue={[""]}
              >
                {recommendationOptions && recommendationOptions.length > 0 ? (
                  recommendationOptions.map((recom) => {
                    return (
                      <MenuItem key={recom.value} value={recom.value}>
                        {recom.label}
                      </MenuItem>
                    );
                  }, this)
                ) : (
                  <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
                )}
              </Select>
            </TableCell>
            <TableCell>
              <FileUpload
                width="90%"
                id={"bulkFileInput"}
                label={"Attach File"}
                currentFile={bulkFile}
                progress={bulkFileProgress}
                onChange={(event) => handleUploadChange(event, i)}
                value={items && items[i] && items[i].upload}
                accept={
                  ".xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                }
              />
            </TableCell>
            <TableCell>
              <Button
                variant="contained"
                sx={{ marginLeft: "10px" }}
                onClick={() => handleItemRemove(i)}
                color="error"
              >
                <Cancel />
              </Button>
            </TableCell>
          </TableRow>
        );
      }, this));
    setInspectionDetailsTableRows(rows);
  };

  const handleInspectionItemAdd = () => {
    setItems((items) => [
      ...items,
      {
        inspectionArea: null,
        specificArea: null,
        comment: null,
        recommendations: null,
        upload: null,
      },
    ]);
    setInspectionDetailItems((inspectionDetailItems) => [
      ...inspectionDetailItems,
      {
        inspectionArea: null,
        specificArea: null,
        comment: null,
        recommendations: null,
        upload: null,
      },
    ]);
  };

  const handleItemRemove = (index) => {
    let newArr = [...items]; // copying the old items array
    if (newArr.length > 1) {
      newArr.splice(index, 1);
      setItems(newArr);
      setInspectionDetailItems(items);
    } else {
      dispatch(
        setMessage({
          type: "error",
          message: "There must be at least one inspection detail item",
        })
      );
    }
  };

  const clearData = () => {
    setUnitId("");
    setAssetId("");
    setFacilityId("");
    setComplexId("");
    setBulkFile(null);
    setInspectType("");
    setInspectArea("");
    setInspectionDate(null);
    setNextInspectionDate(null);
    setInspectionComment("");
    setSpecificArea("");
    setRecommend("");
    setItems([]);
    setInspectionDetailItems([]);
    setSelectedInspection(null);
  };

  const validateForm = () => {
    let data = selectedInspection;
    if (isNullUndefined(data)) {
      dispatch(
        setMessage({
          type: "warning",
          message: "Could not determine Meter to save",
        })
      );
      return false;
    }
    if (isEmptyString(data.inspectionType)) {
      dispatch(
        setMessage({
          type: "warning",
          message: "Must select an inspection type!!",
        })
      );
      return false;
    }
    if (isEmptyString(data.inspectionDate)) {
      dispatch(
        setMessage({
          type: "warning",
          message: "Must select an inspection date!!",
        })
      );
      return false;
    }
    if (isEmptyString(data.comment)) {
      dispatch(
        setMessage({
          type: "warning",
          message: "Inspection must have a comment!!",
        })
      );
      return false;
    }
    if (isEmptyArray(items)) {
      dispatch(
        setMessage({
          type: "warning",
          message: "Must be at least 1 inspection Detail!!",
        })
      );
      return false;
    }
    if (isArray(items)) {
      for (let detail of items) {
        if (isNullUndefined(detail.comment)) {
          dispatch(
            setMessage({
              type: "warning",
              message: "A detail must have a comment.",
            })
          );
          return false;
        }
        if (isNullUndefined(detail.recommendations)) {
          dispatch(
            setMessage({
              type: "warning",
              message: "A detail must have a recommendation.",
            })
          );
          return false;
        }
        if (isNullUndefined(detail.upload)) {
          dispatch(
            setMessage({
              type: "warning",
              message: "A detail must have a file attached.",
            })
          );
          return false;
        }
      }
    }
    setSelectedInspection(data);
    return true;
  };

  const addInspection = () => {
    if (inspectionDate > new Date()) {
      dispatch(
        setMessage({
          type: "warning",
          message: "The Inspection date cannot be greater than today",
        })
      );
      return false;
    }

    if (props.inspectionItem === "Asset") {
      selectedInspection.assetId = props.contract.id;
      setAssetId(props.contract.id);
    }
    if (props.inspectionItem === "Property") {
      selectedInspection.facilityId = props.contract.id;
      setFacilityId(props.contract.id);
    }
    if (props.inspectionItem === "Complex") {
      selectedInspection.complexId = props.contract.id;
      setComplexId(props.contract.id);
    }
    if (props.inspectionItem === "Unit") {
      selectedInspection.unitId = props.contract.id;
      setUnitId(props.contract.id);
    }
    if (props.inspectionItem === "Work Order") {
      selectedInspection.workOrderId = props.contract.id;
    }

    const finalData = new FormData();
    let data = {
      inspectionNode: selectedInspection,
      detailArray: items,
    };

    finalData.append("data", JSON.stringify(data));

    items.forEach((item) => {
      finalData.append("docs", item.upload);
    });

    if (validateForm()) {
      uploadInspections(finalData)
        .then((response) => {
          let type = response.status;
          if (type === "warn") {
            type = "warning";
          }
          dispatch(
            setMessage({
              type: type,
              message: response.message,
            })
          );
          props.closeModal();
        })
        .catch((error) => {
          console.log("addInspection: ", error);
        })
        .finally(() => {
          clearData();
          props.closeModal();
        });
    }
  };

  return (
    <ModalContainerModule
      size="specialComplexInspections"
      accept={addInspection}
      cancel={() => {
        props.closeModal();
        clearData();
      }}
      openModal={props.openModal}
      modalTitle={
        props.inspectionItem === "Edit"
          ? "Edit Inspection Record"
          : props.inspectionItem === "Work Order"
          ? "Add Inspection Record for Work Order"
          : "Add Inspection Record for " + props.contract.name
      }
      acceptButtonText="Upload"
    >
      <ResponsiveRow container sx={{ height: "auto", marginBottom: 0 }}>
        <FormControl
          item
          sx={{ width: { xs: "100%", lg: "50%" }, marginBottom: "10px" }}
        >
          <InputLabel>Inspection Type</InputLabel>
          <Select
            label="Inspection Type"
            sx={{ width: { xs: "100%", lg: "90%" } }}
            id={"inspectionType"}
            value={
              selectedInspection
                ? selectedInspection.inspectionType
                : inspectType
            }
            onChange={(event) => handleInspectionTypeChange(event)}
            defaultValue={[""]}
          >
            {inspectionTypes && inspectionTypes.length > 0 ? (
              inspectionTypes.map((inspType) => {
                return (
                  <MenuItem key={inspType.value} value={inspType.value}>
                    {inspType.label}
                  </MenuItem>
                );
              }, this)
            ) : (
              <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
            )}
          </Select>
        </FormControl>
        <FormControl
          item
          sx={{ width: { xs: "100%", lg: "50%" }, marginBottom: "10px" }}
        >
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale="en-gb"
          >
            <DatePicker
              label="Inspection Date *"
              sx={{
                width: { xs: "100%", md: "90%" },
              }}
              id={"readingDate"}
              value={
                selectedInspection
                  ? dayjs(selectedInspection.inspectionDate)
                  : dayjs(inspectionDate)
              }
              onChange={(newDate) => handleInspectionDateChange(newDate)}

            />
          </LocalizationProvider>
        </FormControl>
        {selectedInspection &&
          selectedInspection.inspectionType === "ROUTN" && (
            <FormControl sx={{ width: { xs: "100%", lg: "50%" } }}>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale="en-gb"
              >
                <DatePicker
                  label="Next Inspection Date"
                  sx={{
                    width: { xs: "100%", md: "90%" },
                  }}
                  id={"readingDate"}
                  value={
                    selectedInspection
                      ? dayjs(selectedInspection.nextInspectionDate)
                      : dayjs(nextInspectionDate)
                  }
                  onChange={(newDate) =>
                    handleNextInspectionDateChange(newDate)
                  }

                />
              </LocalizationProvider>
            </FormControl>
          )}
        <FormControl sx={{ width: { xs: "100%", lg: "50%" } }}>
          <InputLabel>Comment (300)</InputLabel>
          <OutlinedInput
            label="Comment (300)"
            id="meterReadingAmount"
            multiline
            rows={4}
            value={
              selectedInspection
                ? selectedInspection.comment
                : inspectionComment
            }
            sx={{
              width: { xs: "100%", lg: "90%" },
            }}
            onChange={(event) => {
              const value = event.target.value;
              setInspectionComment(value);
              selectedInspection.comment = value;
            }}
            inputProps={<TextField sx={{ width: { xs: "100%", lg: "90%" } }} />}
          />
        </FormControl>
      </ResponsiveRow>
      <ResponsiveRow sx={{ height: "auto" }}>
        <Typography
          sx={{ marginBottom: "5px" }}
          id="modal-modal-title"
          variant="h5"
          component="h4"
        >
          Add Inspection Details
        </Typography>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: "20%" }}>Inspection Area</TableCell>
                {inspectionArea === "RM" && (
                  <TableCell sx={{ width: "15%" }}>Specific Area</TableCell>
                )}
                <TableCell sx={{ width: "20%" }}>Comments</TableCell>
                <TableCell sx={{ width: "20%" }}>Recommendation</TableCell>
                <TableCell sx={{ width: "15%" }}>Image Upload</TableCell>
                <TableCell sx={{ width: "10%" }}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{inspectionDetailsTableRows}</TableBody>
          </Table>
        </TableContainer>
        <ResponsiveRow
          item
          sx={{
            justifyContent: { xs: "center", lg: "flex-end" },
            height: "auto",
          }}
        >
          <ModalAcceptButton
            onClick={() => handleInspectionItemAdd()}
            sx={{ width: { xs: "100%", lg: "200px" }, height: "auto" }}
          >
            {" "}
            Add row{" "}
          </ModalAcceptButton>
        </ResponsiveRow>
      </ResponsiveRow>
    </ModalContainerModule>
  );
  //   <Modal
  //     open={props.openModal}
  //     onClose={props.closeModal}
  //     aria-labelledby="modal-modal-title"
  //     aria-describedby="modal-modal-description"
  //   >
  //     <Box sx={style}>
  //       <Paper
  //         elevation="0"
  //         variant=""
  //         sx={{ height: "auto", margin: "auto", padding: "10px" }}
  //       >
  //         <InnerModal container>
  //           <Typography id="modal-modal-title" variant="h3" component="h2">
  //             {props.inspectionItem === "Edit" ? "Edit Inspection Record" : ("Add Inspection Record for " + props.contract.name)}
  //           </Typography>
  //         </InnerModal>
  //       </Paper>
  //       <Divider />
  //       <br />

  //       {appMessage.display && (
  //         <Alert severity={appMessage.type}>
  //           <AlertTitle>{titleCase(appMessage.type)}</AlertTitle>
  //           {appMessage.message}
  //         </Alert>
  //       )}

  //       <Paper
  //         variant=""
  //         sx={{ height: "auto", margin: "auto", padding: "10px" }}
  //       >

  //       </Paper>

  //       {/*FOOTER WITH BUTTONS*/}
  //       <ResponsiveRow
  //         sx={{
  //           justifyContent: { xs: "center", lg: "flex-start" },
  //         }}
  //       >
  //         <ModalAcceptButton
  //             onClick={addInspection}
  //             sx={{ width: { xs: "100%", lg: "200px" } }}
  //         >
  //           Upload
  //         </ModalAcceptButton>
  //         <ModalCancelButton
  //           onClick={() => {
  //             props.closeModal()
  //             clearData()
  //           }}
  //           sx={{ width: { xs: "100%", lg: "200px" } }}
  //         >
  //           Cancel
  //         </ModalCancelButton>
  //       </ResponsiveRow>
  //     </Box>
  //   </Modal>
  // )
}
