import {Box} from "@mui/system"
import {GET_LISTING_IMAGE_URL} from "../../../constants/url.constants";


export default function ListingGalleryImageItem({data, viewImage}) {
    return (
        <Box
            component="img"
            sx={{
                width: "250px",
                height: "150px",

                borderRadius: "10px",
                margin: "5px",

                objectFit: "cover",
                objectPosition: "center center",

                border: data.isCurrent === true ? "3px solid #02CBCE" : "",
            }}
            src={GET_LISTING_IMAGE_URL + "?filename=" + (data.systemFileName)}
            alt="image thumbnail"
            onClick={(data) => viewImage(data)}
        />
    )
}
