import { Edit } from "@mui/icons-material";
import { printLease, printLeaseStatement } from "../../services/print.service";
import {
  TopBarContainedBrandButton,
  TopBarContainedWarningButton,
  TopBarContainer,
} from "../../constants/component.constants";
import { ResponsiveRow } from "../../constants/layout.constants";
import SplitButton from "../splitButton";
import dayjs from "dayjs";
import Permit from "../../util/permit";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
import { isEmptyString } from "../../util/util";
import { FormControl } from "@mui/material";

export default function LeaseViewBar(props) {
  function handlePrintLeaseStatement() {
    printLeaseStatement(
      props.selectedLease.id,
      dayjs(props.statementYear).year()
    );
  }

  function handlePrintLease() {
    printLease(props.selectedLease.id);
  }

  const printButtons = [
    {
      label: `PRINT ACTIONS`,
      roles: false,
      services: false,
    },
    {
      action: handlePrintLeaseStatement,
      label: "Print Lease Statements",
      roles: false,
      services: false,
    },
    {
      action: handlePrintLease,
      label: "Print Lease Details",
      roles: false,
      services: false,
    },
  ];

  return (
    <TopBarContainer
      container
      sx={{
        flexDirection: { xs: "column", lg: "row" },
        flexWrap: "nowrap",
        width: { xs: "100%", lg: "80%" },
        boxShadow: "0 4px 5px 0 rgba(0,0,0,0.5)",
        padding: { xs: "auto", lg: "10px" },
      }}
    >
      <ResponsiveRow
        sx={{
          width: { xs: "100%", slg: "20%" },
        }}
      >
        <FormControl
          item
          sx={{
            marginBottom: "10px",
            width: { xs: "100%", lg: "30%" },
          }}
        >
          {" "}
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale="en-gb"
          >
            <DatePicker
              sx={{ width: { xs: "100%", lg: "90%" } }}
              id={"statementYear"}
              value={
                isEmptyString(props.statementYear)
                  ? null
                  : dayjs(props.statementYear)
              }
              label="Statement Year"
              isRequired={false}
              views={["year"]}
              onChange={(newDate) => {
                // console.log("date Value ", newDate)
                let date = new Date(newDate);
                props.handleStatementYearChange(date);
              }}
            />
          </LocalizationProvider>
        </FormControl>

        <ResponsiveRow
          item
          sx={{
            justifyContent: { xs: "center", lg: "flex-end" },
            padding: 0,
            width: { xs: "100%", lg: "70%" },
          }}
        >
          {props.selectedLease.statusCd !== "DRF" && (
            <Permit roles={"BLDIR,BLDMG,CS"}>
              <TopBarContainedBrandButton
                onClick={props.handleUnitSwapClick}
                sx={{
                  width: { xs: "100%", lg: "250px" },
                  padding: { xs: "10px 15px", lg: "0 5px" },
                }}
              >
                <Edit sx={{ margin: "auto 5px" }} />
                Add/Swap/Delete Lease Units
              </TopBarContainedBrandButton>
            </Permit>
          )}
          <SplitButton buttons={printButtons} />
          <TopBarContainedWarningButton
            variant="contained"
            type="submit"
            onClick={() => props.setTask("")}
            sx={{
              width: { xs: "100%", lg: "150px" },
              padding: { xs: "10px 15px", lg: "0 5px" },
            }}
          >
            Close
          </TopBarContainedWarningButton>
        </ResponsiveRow>
      </ResponsiveRow>
    </TopBarContainer>
  );
}
