import { FormControl, InputLabel, OutlinedInput } from "@mui/material";
import ModalContainerModule from "../../../modules/modalContainer";

export default function AddNoteModal(props) {
  /**
   *
   * REQUIRED PROPS
   *
   * handleNoteClose
   *
   * onTextChange
   * noteModal
   * note
   * saveNote
   * selectedOwnership
   *
   *
   */
  return (
    <ModalContainerModule
      size="specialAddNoteSize"
      accept={props.saveNote}
      cancel={props.handleNoteClose}
      openModal={props.noteModal}
      modalTitle={`Add Note To Ownership ${
        props && props.selectedOwnership && props.selectedOwnership.billingCode
      } for ${
        props && props.selectedOwnership && props.selectedOwnership.ownerNames
      }`}
      acceptButtonText="Save Note"
    >
      <FormControl item sx={{ width: "100%" }}>
        <InputLabel>Note (500)</InputLabel>
        <OutlinedInput
          label="Note (500)"
          sx={{ width: "100%" }}
          id={"note"}
          name={"note"}
          multiline
          rows={5}
          isRequired
          value={props.note}
          onChange={(event) => props.onTextChange(event)}
        />
      </FormControl>
    </ModalContainerModule>
  );
}
