import { TabContext, TabPanel } from "@mui/lab";
import {
  Box,
  Tab,
  Tabs,
} from "@mui/material";
import React, { useCallback, useState } from "react";

import Permit from "../util/permit";
import JournalEntries from "./journalEntries";
import Accounts from "./accounts";

const recordsTabs = ["Journal Entries", "Chart of Accounts"];

export default function JournalV2() {
  const [currentTab, setCurrentTab] = useState("Invoices");

  function handleTabChange(newTab) {
    setCurrentTab(newTab);
  }

  const populateTabs = useCallback(
    function () {
      switch (currentTab) {
        case "Journal Entries":
          return (
            <JournalEntries />
          );

        case "Chart of Accounts":
          return (
            <Accounts />
          );

        default:
          return setCurrentTab("Journal Entries");
      }
    },
    [currentTab]
  );

  return (
    <Permit
      roles="BLDIR,ACC"
      services="PREMIUM,HOA,MAINT"
      noPermittedServiceMessage={{
        title: "Access Denied",
        body: (
          <>
            Your current role and/or facility is not permitted to view this
            page. <br /> Please contact your system admin if you feel this is an
            error.
          </>
        ),
      }}
    >
      <TabContext value={currentTab}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",

            width: "100%",

            overflowX: "auto",
          }}
        >
          <Tabs
            value={currentTab}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="basic tabs example"
            sx={{ marginTop: "20px" }}
          >
            {recordsTabs.map((tab) => {
              return (
                <Tab
                  key={tab}
                  label={tab}
                  id={tab}
                  value={tab}
                  aria-controls={`tabPanel-${currentTab}`}
                  onClick={() => handleTabChange(tab)}
                />
              );
            })}
          </Tabs>
        </Box>
        {recordsTabs.map((tab) => {
          return (
            <TabPanel
              key={tab}
              value={tab}
              index={tab}
              sx={{ width: "100%", padding: 0, margin: "5px 0 0 0" }}
            >
              {populateTabs(tab)}
            </TabPanel>
          );
        })}
      </TabContext>
    </Permit>
  );
}
