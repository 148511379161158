import {Alert, AlertTitle, FormControl, InputLabel, OutlinedInput, TextField,} from "@mui/material"
import {ResponsiveRow} from "../../../../constants/layout.constants"
import ModalContainerModule from "../../../modules/modalContainer"

export default function MeterBillingSuspendModal(props) {
    /**
     *
     * REQUIRED PROPS
     *
     * SuspendModal
     *
     * setSuspendModal
     * onTextChange
     *
     * selectedMeterbilling
     * suspendBilling
     * suspendReason
     *
     */
    return (
        <ModalContainerModule
            size="specialSuspendMeterBillingModal"
            accept={props.suspendBilling}
            cancel={() => props.setSuspendModal(false)}
            openModal={props.SuspendModal}
            modalTitle="Suspend Meter Billing"
            acceptButtonText="Suspend"
        >
            <ResponsiveRow sx={{width: "100%", height: "auto"}} container>
                <Alert sx={{width: "100%", marginBottom: "10px"}} severity="warning">
                    <AlertTitle>
                        Suspend Meter Billing{" "}
                        {props.selectedMeterbilling &&
                            props.selectedMeterbilling.billingCode}{" "}
                        for{" "}
                        {props.selectedMeterbilling &&
                            props.selectedMeterbilling.customerName}
                    </AlertTitle>
                    When you suspend a meter billing, the customer shall no longer be
                    invoiced for utilities. To resume invoicing, you can Re-Activate the
                    meter billing. The customer shall still be able to make payments
                    against this meter billing
                    <p>
                        Current Balance:
                        {props.selectedMeterbilling &&
                            props.selectedMeterbilling.meterBillingBalance}
                    </p>
                </Alert>

                <FormControl sx={{width: "100%"}}>
                    <InputLabel>Reason for Suspension</InputLabel>
                    <OutlinedInput
                        sx={{width: "100%"}}
                        id={"suspendReason"}
                        value={props.suspendReason}
                        isrequired
                        multiline
                        rows={4}
                        onChange={(searchText) => props.onTextChange(searchText)}
                        
                    />
                </FormControl>
            </ResponsiveRow>
        </ModalContainerModule>
    )
}
