import {Alert, FormControl, InputLabel, OutlinedInput, TextField,} from "@mui/material"
import {ResponsiveRow} from "../../../../constants/layout.constants"
import ModalContainerModule from "../../../modules/modalContainer"

export default function AddTerminatedNoteModal(props) {
    /**
     *
     * REQUIRED PROPS
     *
     * saveNote
     *
     * handleNoteClose
     * onTextChange
     *
     * selectedLease
     * note
     * noteModal
     *
     */
    return (
        <ModalContainerModule
            size="specialTerminatedAddNote"
            accept={props.saveNote}
            cancel={props.handleNoteClose}
            openModal={props.noteModal}
            modalTitle={` Add Note To Lease ${
                props.selectedLease && props.selectedLease.billingCode
            } for ${props.selectedLease && props.selectedLease.tenantName}`}
            acceptButtonText="Save Note"
        >
            <Alert severity="info" sx={{width: "100%"}}>
                Add note to lease will log important notes/information pertaining this
                lease. All the notes added on this lease will be displayed
                chronologically, from newest first to oldest. Notes will be limited to
                500 words.
            </Alert>
            <br/>
            <ResponsiveRow container>
                <FormControl sx={{width: "100%"}}>
                    <InputLabel>Note (500)</InputLabel>
                    <OutlinedInput
                        sx={{width: "100%"}}
                        id={"note"}
                        name={"note"}
                        isrequired
                        multiline
                        rows={5}
                        value={props.note}
                        onChange={(event) => props.onTextChange(event)}
                        inputProps={<TextField sx={{width: {xs: "100%", lg: "90%"}}}/>}
                    />
                </FormControl>
            </ResponsiveRow>
        </ModalContainerModule>
    )
}
