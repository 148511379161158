import {
    ACTIVATE_BILLING_URL,
    BULK_FACILITY_HOA_URL,
    BULK_FACILITY_METER_BILLINGS_URL,
    BULK_FACILITY_URL,
    DELETE_BILLING_DOCUMENT_URL,
    DELETE_DRAFT_URL,
    DOWNLOAD_METER_BILLINGS_URL,
    EXPORT_METER_BILLINGS_URL,
    LIST_METER_BILLINGS_URL,
    LIST_NOTES_URL,
    LIST_TERMINATED_METER_BILLINGS_URL,
    METER_BILLING_ACCOUNT_URL,
    METER_BILLING_FINANCIALS_URL,
    METER_BILLING_PAYMENT_METHOD_URL,
    METER_BILLING_PAYMENT_PERIODS_URL,
    MT_BY_FAC_URL,
    MT_BY_MT_BILL_URL,
    OUTSTANDING_METER_BILLING_INVOICES_CHARGES_URL,
    PROP_MGR_URL,
    RESEND_LAST_METER_BILLING_INVOICE_URL,
    RESEND_LAST_METER_BILLING_RECEIPT_URL,
    RESEND_METER_BILLING_STATEMENT_URL,
    SAVE_METER_BILLING_NOTE_URL,
    SAVE_METER_BILLING_PAYMENT_URL,
    SAVE_METER_BILLING_URL,
    SUSPEND_METER_BILLING_URL,
    TERMINATE_METER_BILLING_URL,
    TOTAL_METER_BILLINGS_URL,
    UPDATE_METER_BILLING_URL,
    UPLOAD_BILLING_DOCUMENT_URL,
} from "../constants/url.constants"
import {serviceAPI, serviceAPIDownloadFile, upload} from "./service"
import {handleErrorResponse} from "../actions/message"

//GET REQUESTS
export const getMeterBilling = (searchReference, customer, pageNo, rowsPerPage) => {
    let url = LIST_METER_BILLINGS_URL + searchReference + "&customer=" + customer + "&pageNo=" + pageNo + "&rowsPerPage=" + rowsPerPage
    return serviceAPI.get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getTotalMeterBillings = (searchReference, customer) => {
    let url = TOTAL_METER_BILLINGS_URL + searchReference + "&customer=" + customer
    return serviceAPI.get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getTerminatedMeterBillings = (searchReference, customer) => {
    let url = LIST_TERMINATED_METER_BILLINGS_URL + searchReference + "&customer=" + customer
    return serviceAPI.get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getMeterBillingPaymentMethods = () => {
    return serviceAPI.get(METER_BILLING_PAYMENT_METHOD_URL)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getMeterBillingPeriods = () => {
    return serviceAPI.get(METER_BILLING_PAYMENT_PERIODS_URL)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getPropertyManagers = () => {
    return serviceAPI.get(PROP_MGR_URL)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}


export const getOutstandingMeterBillingInvoicesCharges = (meterBillingId) => {
    let url = OUTSTANDING_METER_BILLING_INVOICES_CHARGES_URL + meterBillingId
    return serviceAPI.get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getMetersByFacility = (facilityId) => {
    let url = MT_BY_FAC_URL + '?facilityId=' + facilityId
    return serviceAPI.get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getMetersByMeterBilling = (meterBillingId) => {
    let url = MT_BY_MT_BILL_URL + meterBillingId
    return serviceAPI.get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getMeterBillingNotes = (meterBillingId, pageNo) => {
    let url = LIST_NOTES_URL + meterBillingId + "&pageNo=" + pageNo
    return serviceAPI.get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getMeterBillingFinancials = (meterBillingId, pageNo, bulkWater) => {
    let url = METER_BILLING_FINANCIALS_URL + meterBillingId + "&pageNo=" + pageNo + "&bulkWater=" + bulkWater
    return serviceAPI.get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getMeterBillingAccounts = (meterBillingId) => {
    let url = METER_BILLING_ACCOUNT_URL + meterBillingId
    return serviceAPI.get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const exportMeterBillings = () => {
    return serviceAPIDownloadFile.get(EXPORT_METER_BILLINGS_URL)
}

//POST REQUESTS
export const saveNewMeterBilling = (data) => {
    return serviceAPI.post(SAVE_METER_BILLING_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const updateMeterBilling = (data) => {
    return serviceAPI.post(UPDATE_METER_BILLING_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const saveMeterBillingPayment = (data) => {
    return serviceAPI.post(SAVE_METER_BILLING_PAYMENT_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const resendLastMeterBillingInvoice = (data) => {
    return serviceAPI.post(RESEND_LAST_METER_BILLING_INVOICE_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const resendLastMeterBillingReceipt = (data) => {
    return serviceAPI.post(RESEND_LAST_METER_BILLING_RECEIPT_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const resendMeterBillingStatement = (data) => {
    return serviceAPI.post(RESEND_METER_BILLING_STATEMENT_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const saveMeterBillingNote = (data) => {
    return serviceAPI.post(SAVE_METER_BILLING_NOTE_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const bulkFacility = (data) => {
    return serviceAPI.post(BULK_FACILITY_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const bulkFacilityHOA = (data) => {
    return serviceAPI.post(BULK_FACILITY_HOA_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const bulkFacilityMeterBilling = (data) => {
    return upload(BULK_FACILITY_METER_BILLINGS_URL, data)
}

export const uploadBillingDocument = (meterBillingId, data) => {
    return serviceAPI.post(UPLOAD_BILLING_DOCUMENT_URL + meterBillingId, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const deleteBillingDocument = (data, meterBillingId) => {
    return serviceAPI.post(DELETE_BILLING_DOCUMENT_URL + meterBillingId, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const activateMeterBilling = (data) => {
    return serviceAPI.post(ACTIVATE_BILLING_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const suspendMeterBilling = (data) => {
    return serviceAPI.post(SUSPEND_METER_BILLING_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const terminateMeterBilling = (data) => {
    return serviceAPI.post(TERMINATE_METER_BILLING_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const downloadMeterBillings = (data) => {
    return serviceAPI.post(DOWNLOAD_METER_BILLINGS_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const deleteDraftMeterBilling = (data) => {
    return serviceAPI.post(DELETE_DRAFT_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

