import { handleErrorResponse } from '../actions/message';
import { LIST_PETTY_CASH_ENTRIES_URL, REVERSE_TOPUP_URL, SAVE_PETTY_CASH_URL } from '../constants/url.constants';
import { serviceAPI } from './service';

export const listPettyCashEntries = () => {
	return serviceAPI
		.get(LIST_PETTY_CASH_ENTRIES_URL)
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			handleErrorResponse(error);
		});
};


export const savePettyCashEntry = (data) => {return serviceAPI
	.post(SAVE_PETTY_CASH_URL, data)
	.then((response) => {
		return response.data;
	})
	.catch((error) => {
		handleErrorResponse(error);
	});
}


export const reverseTopUp = (transactionId) => {
	return serviceAPI
		.get(REVERSE_TOPUP_URL + transactionId)
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			handleErrorResponse(error);
		});
};