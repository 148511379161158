import { useEffect, useMemo, useState } from 'react';
import {
	Button,
	FormControl,
	InputLabel,
	MenuItem,
	OutlinedInput,
	Select,
	TableCell,
	TableRow,
	TextField,
} from '@mui/material';
import { GridActionsCellItem } from '@mui/x-data-grid';
import {
	getBankAccountTransactionDetailsNotReconciledBefore,
	getReconciliationStartDate,
	getTotalReconciliations,
	listReconciledTransactionDetails,
	loadReconciliationsList,
	saveReconciliationService,
} from '../services/reconciliation.service';
import { Cancel, Visibility } from '@mui/icons-material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/en-gb';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import {
	formatCurrency,
	formatDate,
	isEmptyString,
	isNullUndefined,
	isValidDate,
	tryParseFloat,
} from '../util/util';
import {issueResponseMessage, issueWarnMessage} from '../actions/message';
import { printReconciliation } from '../services/print.service';
import { useSelector } from 'react-redux';
import {
	MainContainer,
	TopBarContainedBrandButton,
	TopBarContainer,
} from '../constants/component.constants';
import { ResponsiveRow } from '../constants/layout.constants';
import CreateReconciliationModal from '../components/modals/accounting/reconcilation/createReconciliationModal';
import ViewReconcilationModal from '../components/modals/accounting/reconcilation/viewReconcilationModal';
import Permit from '../util/permit';
import dayjs from 'dayjs';
import MuiDataGrid from '../components/modules/MuiDataGrid';
import { ledgerAccForBank } from '../services/list.service';
import { orgBankAccounts } from '../services/bank.service';

export default function Reconciliation() {
  const user = useSelector((state) => state.auth.user);
  const [accountName, setAccountName] = useState("");
  const [reconciledBy, setReconciledBy] = useState("");
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  });
  const [reconciliationList, setReconciliationList] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);
  const [totalReconciliations, setTotalReconciliations] = useState(0);
  const [reconciliationForm, setReconciliationForm] = useState(false);
  const [orgBankAccountsList, setOrgBankAccountsList] = useState([]);
  const [selectedBankAcc, setSelectedBankAcc] = useState("");
  const [selectedReconciliation, setSelectedReconciliation] = useState(null);
  const [itemApplyToOptions, setItemApplyToOptions] = useState([]);
  const [isNewReconciliation, setIsNewReconciliation] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [statementDate, setStatementDate] = useState(new Date());
  const [begBalance, setBegBalance] = useState("");
  const [endBalance, setEndBalance] = useState("");
  const [itemTypeOptions, setItemTypeOptions] = useState([]);
  const [items, setItems] = useState([]);
  const [reconciliationView, setReconciliationView] = useState(false);
  const [reconciledTransactionDetails, setReconciledTransactionDetails] = useState([]);
  const [reconciledTransactionDetailsTotal, setReconciledTransactionDetailsTotal] = useState([]);
  const [loading, setLoading] = useState(true);
	const [step, setStep] = useState(1);
	const [unreconciledTransactions, setUnreconciledTransactions] = useState([]);
  
	const reconBalance = useMemo(() => {
		let tempReconBalance = 0;
    selectionModel.forEach(id => {
			const transaction = unreconciledTransactions.find(t => t.id === id);
      tempReconBalance += parseFloat(transaction.amount);
    });

		items.forEach(item => {
			if (item.type === "charge") {
				tempReconBalance -= parseFloat(item.amount);
			} else if (item.type === "interest") {
				tempReconBalance += parseFloat(item.amount);
			}
		});

		tempReconBalance += parseFloat(begBalance);
		return tempReconBalance;
	}, [selectionModel, begBalance, items, unreconciledTransactions])

	const balanceDiff = useMemo(() => {
    return parseFloat(endBalance) - parseFloat(reconBalance);
  }, [endBalance, reconBalance])
  
	useEffect(() => {
    setLoading(true);
    orgBankAccounts()
      .then((data) => setOrgBankAccountsList(data))
      .finally(() => {setLoading(false)});
	}, []);

  useEffect(() => {
		if(!reconciliationForm) {
			setLoading(true);
			loadReconciliationsList(accountName, reconciledBy, paginationModel.page)
				.then((data) => setReconciliationList(data))
			getTotalReconciliations(accountName, reconciledBy)
				.then((data) => setTotalReconciliations(data.totalReconciliations))
				.finally(() => {
					setLoading(false);
				});
		}
  }, [accountName, paginationModel.page, reconciledBy, user.proxyFacility, reconciliationForm]);

  function handleViewClicked(item) {
    listReconciledTransactionDetails(item.id, paginationModel.page)
      .then((data) => {
        setReconciledTransactionDetails(data);
        setReconciledTransactionDetailsTotal(data.length);
      })
    setSelectedReconciliation(item);
    setReconciliationView(true);
  }

  function handlePrintReconciliation() {
    printReconciliation(selectedReconciliation.id)
      .then((response) => {
				setReconciliationView(false);
        issueResponseMessage(response)
      })
  }

	function handleCloseView() {
		setSelectedReconciliation(null);
		setReconciliationView(false);
		setSelectionModel([]);
		setReconciledTransactionDetails([]);
	}

  const columns = [
    {
      headerName: "Bank Account",
      field: "account",
      minWidth: 150,
      fleX: 1.5,
      renderCell: (params) => params.value.name,
    },
    {
      headerName: "Ledger Account(s)",
      field: "ledgerAccounts",
      minWidth: 150,
      flex: 1.5,
      renderCell: (params) => params.value.names,
    },
    { headerName: "Start Date", field: "startDate", width: 120 },
    { headerName: "Statement Date", field: "statementDate", width: 120 },
    { headerName: "Beginning Balance", field: "begBalance", width: 140 },
    { headerName: "End Balance", field: "endBalance", width: 140 },
    { headerName: "Recon Balance", field: "reconBalance", width: 140 },
    { headerName: "Balance Diff", field: "balanceDiff", width: 139 },
    {
      headerName: "Reconciled By",
      field: "reconciledBy",
      minWidth: 150,
      flex: 1.5,
      renderCell: (params) => params.value.name,
    },
    {
      headerName: "Actions",
      field: "actions",
      width: 100,
      type: "actions",
      getActions: (params) => {
        let arr = [];
        arr.push(
          <GridActionsCellItem
            icon={<Visibility />}
            onClick={() => handleViewClicked(params.row)}
            label="View"
            showInMenu
          />
        );

				return arr;
			},
		},
	];

	const bankTransactionColumns = [
		{
			headerName: 'Date',
			field: 'date',
			minWidth: 100,
			flex: 1,
			valueFormatter: (row) => {
				return formatDate(row.value);
			},
		},
		{ headerName: 'Type', field: 'type', minWidth: 140, flex: 1 },
		{ headerName: 'Payment Method', field: 'paymentMethod', minWidth: 100, flex: 1 },
		{ headerName: 'Cheque No/ Bank Ref No.', field: 'chequeNumber', minWidth: 150, flex: 1.1 },
    { headerName: 'Ref/Applied To', field: 'referenceNumber', minWidth: 150, flex: 1 },
		{ headerName: 'Description', field: 'description', minWidth: 200, flex: 1.5 },
		{
			headerName: 'Debit Amount',
			field: 'debitAmount',
			minWidth: 120,
			flex: 1,
			valueFormatter: (row) => {
				return formatCurrency(row.value);
			},
		},
		{
			headerName: 'Credit Amount',
			field: 'creditAmount',
			minWidth: 120,
			flex: 1,
			valueFormatter: (row) => {
				return formatCurrency(row.value);
			},
		},
	];

	const reconciledTransactionDetailsColumns = [
		{
			headerName: 'Date',
			field: 'entryDate',
			width: 200,
			valueFormatter: (row) => {
				return formatDate(row.value);
			},
		},
		{ headerName: 'Type', field: 'type', minWidth: 200, flex: 1 },
		{ headerName: 'Details', field: 'description', minWidth: 574, flex: 2.8 },
		{
			headerName: 'Debit Amount',
			field: 'debitAmount',
			minWidth: 200,
			flex: 1,
			valueFormatter: (row) => {
				return formatCurrency(row.value);
			},
		},
		{
			headerName: 'Credit Amount',
			field: 'creditAmount',
			minWidth: 200,
			flex: 1,
			valueFormatter: (row) => {
				return formatCurrency(row.value);
			},
		},
	];

	function handleBankAccountChanged(event) {
		let value = event.target.value;
		let obj = selectedReconciliation;
		setSelectedBankAcc(value);
		obj.accountId = value;
		setSelectedReconciliation(obj);

    ledgerAccForBank(value)
      .then((data) => setItemApplyToOptions(data))
    getReconciliationStartDate(value)
      .then((data) => {
        let tmp = selectedReconciliation;
        tmp.startDate = new Date(data.startDate);
        setSelectedReconciliation(tmp);
        setIsNewReconciliation(data.isNewReconciliation);
				setStartDate(new Date(data.startDate));
      })
  }

	function handleAddReconciliationClicked() {
		//Add the Item Type Options we will choose from.
		itemTypeOptions.push({ label: 'Bank Charge', value: 'charge' });
		itemTypeOptions.push({ label: 'Interest', value: 'interest' });
		setItemTypeOptions(itemTypeOptions);
		setSelectionModel([]) // clear all selected

    let data = {
      accountId: null,
      startDate: new Date(),
      statementDate: new Date(),
      begBalance: null,
      endBalance: null,
      reconBalance: 0,
      balanceDiff: 0,
      bankTransactionSet: new Set(),
      items: [],
    };
    setItems(data.items);
    setSelectedReconciliation(data);
    setReconciliationForm(true);
  }

  function handleCloseReconciliationClicked() {
		setSelectedBankAcc("");
    setEndBalance("");
    setBegBalance("");
    setStartDate(new Date());
    setStatementDate(new Date());
    setItems([]);
    setSelectedReconciliation(null);
    setItemTypeOptions([]);
    setSelectionModel([]);
		setStep(1);
		setReconciliationForm(false);
  }

  function isReconciliationFormValid() {
    if (isNullUndefined(selectedReconciliation)) {
      issueWarnMessage("Could not determine Item to reconcile. Try to refresh the page or contact support.")
      return false;
    }
    if (!isValidDate(selectedReconciliation.statementDate)) {
      issueWarnMessage("Statement date entered is not a valid date.")
      return false;
    }
    if (!isValidDate(selectedReconciliation.startDate)) {
      issueWarnMessage("Start date entered is not a valid date.")
      return false;
    }
    if (
      isNullUndefined(selectedReconciliation.accountId) ||
      isEmptyString(selectedReconciliation.accountId)
    ) {
      issueWarnMessage("Select a bank account before proceeding with reconciliation.")
      return false;
    }
    if (selectedReconciliation.startDate > selectedReconciliation.statementDate) {
      issueWarnMessage("Start Date cannot be greater than Statement Date.")
      return false;
    }
    let begBalance = tryParseFloat(selectedReconciliation.begBalance, -1);
    if (begBalance < 1) {
      issueWarnMessage("Invalid beginning balance. The beginning balance entered must be more than KSH 0")
      return false;
    }
    let endBalance = tryParseFloat(selectedReconciliation.endBalance, -1);
    if (endBalance < 1) {
      issueWarnMessage("Invalid ending balance. The ending balance entered must be more than KSH 0")
      return false;
    }
    if (!isNullUndefined(items) && items.length > 0) {

      for(let i=0; i < items.length; i++) {
        let chargeInterestItem = items[i];
        if ( chargeInterestItem.itemDate > selectedReconciliation.statementDate) {
          issueWarnMessage("Charge item Date cannot be greater than Statement Date.");
          return false;
        }
        let amount = tryParseFloat(chargeInterestItem.amount, -1);
        if (
          amount < 1 ||
          isEmptyString(chargeInterestItem.amount) ||
          isNullUndefined(chargeInterestItem.amount)
        ) {
          issueWarnMessage("Enter valid amount value.");
          return false;
        }
        if (!isValidDate(chargeInterestItem.itemDate)) {
          issueWarnMessage("Charge item invalid date selected.");
          return false;
        }

        if (
          isNullUndefined(chargeInterestItem.type) ||
          isEmptyString(chargeInterestItem.type)
        ) {
          issueWarnMessage("Please select a charge type");
          return false;
        }
      };
    }

		return true;
	}

  function handleProceedClicked() {
    if ((isReconciliationFormValid() === true) && step === 1) {
      getBankAccountTransactionDetailsNotReconciledBefore(
        selectedBankAcc,
        startDate,
        statementDate
      )
        .then((data) => {
					setUnreconciledTransactions(data)
					setStep(2)
        })
    }
  }

	function addChargeOrInterestItem() {
		let itemApplyTo = '';
		let data = {};
		if (itemApplyToOptions.length === 1) {
			itemApplyTo = itemApplyToOptions[0].value;
			data = {
				id: null,
				type: null,
				itemDate: selectedReconciliation.statementDate,
				applyTo: itemApplyTo,
				amount: null,
			};
		} else {
			data = {
				id: null,
				type: null,
				itemDate: selectedReconciliation.statementDate,
				applyTo: null,
				amount: null,
			};
		}

		setItems((values) => [...values, data]);
	}

	function chargeInterestItemDelete(index) {
		let itemsArray = [...items]; // copying the old items array
		itemsArray.splice(index, 1);
		setItems(itemsArray);
	}

	function itemTypeChanged(event, index) {
		let value = event.target.value;
		let itemsArray = [...items]; // copying the old items array
		itemsArray[index].type = value;
		setItems(itemsArray);
	}

	function accountToApplyToChanged(event, index) {
		let value = event.target.value;
		let itemsArray = [...items]; // copying the old items array
		itemsArray[index].applyTo = value;
		setItems(itemsArray);
	}

	function interestChargeAmountChanged(event, index) {
		let value = event.target.value;
		let itemsArray = [...items]; // copying the old items array
		itemsArray[index].amount = value;
		setItems(itemsArray);
	}

  function interestChargeDateChanged(dateValue, index) {
    let date = new Date(dateValue);
    let itemsArray = [...items]; // copying the old items array
    itemsArray[index].itemDate = date;
    setItems(itemsArray);
  }

	let chargeInterestItems = [];
	let showItemApplyToOptions = true;
	if (itemApplyToOptions.length === 1) {
		showItemApplyToOptions = false;
	} else {
		items &&
			items.length > 0 &&
			(chargeInterestItems = items.map(function (chargeInterestItem, i) {
				return (
					<TableRow key={i}>
						<TableCell>
							<Select
								sx={{ width: '90%' }}
								id={'type' + i}
								placeholder="Select type Interest/Charge"
								value={chargeInterestItem.type}
								onChange={(event) => itemTypeChanged(event, i)}
							>
								{itemTypeOptions && itemTypeOptions.length > 0 ? (
									itemTypeOptions.map(function (item, i) {
										return (
											<MenuItem key={i} value={item.value}>
												{item.label}
											</MenuItem>
										);
									}, this)
								) : (
									<MenuItem sx={{ width: '100%' }}>No Results Found</MenuItem>
								)}
							</Select>
						</TableCell>

						{showItemApplyToOptions && !isEmptyString(selectedBankAcc) && (
							<TableCell>
								<Select
									sx={{ width: '90%' }}
									id={'applyTo' + i}
									placeholder="Account To Apply"
									value={chargeInterestItem.applyTo}
									onChange={(event) => accountToApplyToChanged(event, i)}
								>
									{itemApplyToOptions && itemApplyToOptions.length > 0 ? (
										itemApplyToOptions.map(function (item, i) {
											return (
												<MenuItem key={item.value} value={item.value}>
													{item.label}
												</MenuItem>
											);
										}, this)
									) : (
										<MenuItem sx={{ width: '100%' }}>No Results Found</MenuItem>
									)}
								</Select>
							</TableCell>
						)}

						<TableCell>
							<LocalizationProvider
								dateAdapter={AdapterDayjs}
								adapterLocale="en-gb"
							>
								<DatePicker
									sx={{ width: '90%' }}
									id={'date'}
									value={dayjs(chargeInterestItem.itemDate)}
									onChange={(newDate) => interestChargeDateChanged(newDate, i)}
								/>
							</LocalizationProvider>
						</TableCell>

						<TableCell>
							<TextField
								placeholder={'Amount'}
								sx={{ width: '90%' }}
								id={'amount'}
								onChange={(event) => {
									interestChargeAmountChanged(event, i);
								}}
								value={chargeInterestItem.amount}
							/>
						</TableCell>

						<TableCell>
							<Button
								variant="contained"
								type="button"
								sx={{ marginLeft: '10px' }}
								onClick={() => chargeInterestItemDelete(i)}
								color="error"
							>
								<Cancel />
							</Button>
						</TableCell>
					</TableRow>
				);
			}));
	}

  function saveReconciliation() {
    if (selectionModel.length < 1) {
      issueWarnMessage("You must reconcile at least one bank transaction to save this reconciliation.")
      return false;
    }

    let tmp = selectedReconciliation;
    tmp.items = items;
		tmp.reconBalance = reconBalance;
		tmp.balanceDiff = balanceDiff
		
		tmp.bankTransactionSet = selectionModel
			.map((id) => unreconciledTransactions.find((item) => item.id === id)?.transactionId)

    setSelectedReconciliation(tmp);

    if (isReconciliationFormValid()) {
      saveReconciliationService(selectedReconciliation)
        .then((response) => {
          issueResponseMessage(response)
          handleCloseReconciliationClicked();
        })
    }
  }

	return (
		<Permit
			roles="BLDIR,ACC,BLDMG,CS"
			noPermittedServiceMessage={{
				title: 'Access Denied',
				body: (
					<>
						Your current role is not permitted to view this page. <br /> Please
						contact your system admin if you feel this is an error.
					</>
				),
			}}
		>
			<MainContainer>
				<TopBarContainer
					container
					sx={{
						justifyContent: { xs: 'center', lg: 'space-between' },
						width: { xs: '100%', md: '80%' },
						padding: '0 10px',
						boxShadow: '0 4px 5px 0 rgba(0,0,0,0.5)',
					}}
				>
					<ResponsiveRow
						sx={{
							flexWrap: { xs: 'wrap', lg: 'nowrap' },
							justifyContent: { md: 'flex-start', xs: 'space-around' },
							alignItems: 'center',
							width: { lg: '50%', sm: '100%' },
							height: 'auto',
						}}
					>
						<FormControl
							sx={{
								marginBottom: '10px',
								width: { xs: '100%', lh: '50%' },
							}}
						>
							<InputLabel id="bankAcc">Bank Account Name</InputLabel>
							<OutlinedInput
								id="searchBAnkAccNamed"
								value={accountName}
								label={`Bank Account Name`}
								sx={{ width: { xs: '100%', lg: '90%' } }}
								onChange={(event) => {
									let value = event.target.value;
									setAccountName(value);
								}}
							/>
						</FormControl>

						<FormControl
							sx={{
								marginBottom: '10px',
								width: '100%',
							}}
						>
							<InputLabel id="reconciledBy">Reconciled By</InputLabel>
							<OutlinedInput
								id="reconciledBy"
								value={reconciledBy}
								label={`Reconciled By`}
								sx={{ width: '100%' }}
								onChange={(event) => {
									let value = event.target.value;
									setReconciledBy(value);
								}}
							/>
						</FormControl>
					</ResponsiveRow>

					<ResponsiveRow
						sx={{
							justifyContent: { md: 'flex-end', xs: 'center' },
							width: { md: '50%', xs: '100%' },
							height: 'auto',
						}}
					>
						<TopBarContainedBrandButton
							variant="contained"
							sx={{
								width: { xs: '100%', lg: '250px' },
								height: '100%',
							}}
							// type="submit"
							onClick={handleAddReconciliationClicked}
						>
							Add Reconciliation Item
						</TopBarContainedBrandButton>
					</ResponsiveRow>
				</TopBarContainer>

        <br />

				<MuiDataGrid
					dataGridColumns={columns}
					dataGridRows={reconciliationList}
					serverPagination={true}
					handlePageLoad={setPaginationModel}
					handleSelectedRows={setSelectionModel}
					currentSelectionModel={selectionModel}
					loading={loading}
					height="60vh"
					totalRows={totalReconciliations}
				/>

				<CreateReconciliationModal
					reconciliationForm={reconciliationForm}
					handleBankAccountChanged={handleBankAccountChanged}
					handleCloseReconciliationClicked={handleCloseReconciliationClicked}
					handleProceedClicked={handleProceedClicked}
					setSelectedReconciliation={setSelectedReconciliation}
					setStartDate={setStartDate}
					setStatementDate={setStatementDate}
					addChargeOrInterestItem={addChargeOrInterestItem}
					chargeInterestItems={chargeInterestItems}
					setBegBalance={setBegBalance}
					begBalance={begBalance}
					setEndBalance={setEndBalance}
					endBalance={endBalance}
					isNewReconciliation={isNewReconciliation}
					orgBankAccountsList={orgBankAccountsList}
					selectedBankAcc={selectedBankAcc}
					selectedReconciliation={selectedReconciliation}
					showItemApplyToOptions={showItemApplyToOptions}
					startDate={startDate}
					statementDate={statementDate}
					step={step}
					setStep={setStep}
					loading={loading}
					selectedRows={selectionModel}
					setSelectedRows={setSelectionModel}
					setPaginationModel={setPaginationModel}
					bankTransactionRows={unreconciledTransactions}
					bankTransactionColumns={bankTransactionColumns}
					saveReconciliation={saveReconciliation}
					balanceDiff={balanceDiff}
					reconBalance={reconBalance}
				/>

        {reconciliationView && (
          <ViewReconcilationModal
            handlePrintReconciliation={handlePrintReconciliation}
            handleCloseView={handleCloseView}
            setSelectionModel={setSelectionModel}
            loading={loading}
            loadPage={setPaginationModel}
            reconciliationView={reconciliationView}
            reconciledTransactionDetails={reconciledTransactionDetails}
            reconciledTransactionDetailsColumns={reconciledTransactionDetailsColumns}
            reconciledTransactionDetailsTotal={reconciledTransactionDetailsTotal}
            rowsPerPage={paginationModel.pageSize}
            selectedReconciliation={selectedReconciliation}
            selectionModel={selectionModel}
          />
        )}
      </MainContainer>
    </Permit>
  );
}
