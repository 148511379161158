import {
	GoogleMap,
	InfoWindowF,
	LoadScript,
	MarkerF
} from '@react-google-maps/api';
import Bed from "@mui/icons-material/AirlineSeatFlat"
import Bath from "@mui/icons-material/Bathtub"
import { Card, CardContent, Paper, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { ResponsiveRow } from '../constants/layout.constants';
import { Straighten } from '@mui/icons-material';
import { isNullUndefined } from '../util/util';
import location from "../images/listings/location.svg"

/**
 * Generic map component for use across the app
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export const SmartKodiMap = (props) => {
	const {listingCollection, setFilteredListings, setIsLocationFilterEnabled, setIsLoading, view} = props
	const [map, setMap] = useState(null);

	const handleIdle = useCallback(() => {
		if (map) {
			// filter listings based on the newBounds
			if(view === "listings") {
				const bounds = map.getBounds()
				if(isNullUndefined(bounds)) return
				const filteredListings = listingCollection?.filter(listing => {
					const lat = parseFloat(listing.latitude)
					const lng = parseFloat(listing.longitude)
					const cord = {lat, lng}
					return bounds.contains(cord);
				})
	
				setFilteredListings(filteredListings)
				setIsLocationFilterEnabled(true)
			}
			if(setIsLoading) setIsLoading(false)
		}
	}, [listingCollection, map, setFilteredListings, setIsLoading, setIsLocationFilterEnabled, view]);

	const onLoad = useCallback((mapInstance) => {
		setMap(mapInstance);
	}, []);

	useEffect(() => {
		if (map) {
			const listener = map.addListener('idle', handleIdle);
			return () => {
				if (listener) {
					listener.remove();
				}
			};
		}
	}, [map, handleIdle]);
	
	return (
		<Paper
			sx={{
				width: '100%',
				height: 'auto',
				marginTop: '1vh',
				padding: '1%',
				textAlign: 'left',
				backgroundColor: 'transparent',
				boxShadow: 0,
			}}
		>
			<LoadScript googleMapsApiKey="AIzaSyAi5vlmbshza2_JkvFmF7w2EHmP0Ez12GY">
				<GoogleMap
					mapContainerStyle={props.containerStyle}
					center={props.center}
					options={{
						restriction: {
							latLngBounds: props?.bounds ?? {
								north: 0,
								south: 0,
								west: 0,
								east: 0,
							},
							strictBounds: false,
						},
					}}
					zoom={props?.zoom ?? 11}
					onLoad={onLoad}
				>
					{props?.children ?? null}
				</GoogleMap>
			</LoadScript>
		</Paper>
	);
};

export const ListingMarker = (props) => {
	const {latitude, longitude, propertyName, description, baths, beds, sqft, price} = props.listing
	const [infoOpen, setInfoOpen] = useState(false)
	const [target] = useState(props.target)
	const [customIcon, setCustomIcon] = useState(null)
	const handleMarkerClick = () => {
		setInfoOpen(!infoOpen)
	}

	// 

	useEffect(() => {
		if(target) {
			setCustomIcon(location)
		}
	}, [target])

	return (
		<MarkerF
			position={{
				lat: parseFloat(latitude),
				lng: parseFloat(longitude)
			}}
			onClick={handleMarkerClick}
			icon={{
				url: customIcon,
				scaledSize: new window.google.maps.Size(30, 50)
			}}
		>
			{infoOpen && (
				<InfoWindowF>
					<Card sx={{ minWidth: 275 }}>
						<CardContent>
							<Typography variant="h4" component="div">
								{propertyName}
							</Typography>
							<Typography>
								{description}
							</Typography>
							<Typography>
								<strong>Price: </strong> Ksh {price}
							</Typography>
							<Typography sx={{fontSize: "1rem"}}>
								<Straighten sx={{fontSize: "1.2rem"}}/> {sqft} Sq. Ft.
							</Typography>
							<ResponsiveRow
								sx={{
									flexWrap: "nowrap",
									width: "80%",
									padding: 0,
								}}
							>
								<Typography
									variant="body1"
									sx={{margin: "0 10px 0 0", fontSize: "1rem"}}
								>
									<Bed sx={{fontSize: "1.2rem"}}/> {beds}
								</Typography>
								<Typography
									variant="body1"
									sx={{margin: "0 10px 0 0", fontSize: "1rem"}}
								>
									<Bath sx={{fontSize: "1.2rem"}}/> {baths}
								</Typography>
							</ResponsiveRow>
						</CardContent>
					</Card>
				</InfoWindowF>
			)}
		</MarkerF>
	)
}

export const PropertyMarker = (props) => {
	const [isOpen, setIsOpen] = useState(false);
	const handleMarkerClick = () => {
		setIsOpen(!isOpen);
	};
	return (
		<MarkerF
			position={{
				lat: props.facility.latitude,
				lng: props.facility.longitude,
			}}
			onClick={handleMarkerClick}
		>
			{isOpen && (
				<InfoWindowF>
					<Card sx={{ minWidth: 275 }}>
						<CardContent>
							<Typography variant="h5" component="div">
								{props.facility.name}
							</Typography>
							<Typography sx={{ mb: 1.5 }} color="text.secondary">
								<strong>Services : </strong> {props.facility.services}
								<br />
								<strong>Subscriber : </strong> {props.facility.subscriber}
								<br />
								<strong># Units : </strong> {props?.facility?.units ?? 0}
							</Typography>
						</CardContent>
					</Card>
				</InfoWindowF>
			)}
		</MarkerF>
	);
};

export const createMarkers = (setMarkers, listings, listing=null) => {
	let updatedMarkers = listings?.map((listItem) => {
		if(!isNullUndefined(listItem.longitude) &&
			!isNullUndefined(listItem.latitude)){

			const target = listing ? listItem.id === listing.id : false
			return (
				<ListingMarker
					key={listItem.id}
					lat={listItem.latitude}
					long={listItem.longitude}
					listing={listItem}
					target={target}
				/>
			)
		}
		return null
	})
	if(updatedMarkers.length > 0){
		setMarkers(updatedMarkers)
	}
}