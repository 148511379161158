import {useEffect} from "react"
import {Alert, AlertTitle, Container} from "@mui/material"
import {useDispatch, useSelector} from "react-redux"
import {makeStyles} from "@mui/styles"
import {titleCase} from "../../util/util"
import {clearMessage} from "../../actions/message"
import ListingDashboardNavbar from "../bars/listingDashboardNavbar"
import ListingNavbar from "../bars/listingNavbar"

// Using Inline Styling
const useStyles = makeStyles((theme) => ({
    navbar: {
        backgroundColor: "#037171",
        minHeight: "6vmin",
        fontSize: `calc(10px + 2vmin)`,
        color: "white",
    },
    menuButton: {
        color: "white!important",
    },
    subMenu: {
        "& .MuiPaper-root": {
            backgroundColor: "#037171",
            color: "white",
        },
    },
    subMenuLink: {
        color: "white",
        textDecoration: "none",
    },
    secureContainer: {
        minHeight: "92.5vmin",
    },
    unsecureContainer: {
        minHeight: "100vmin",
        width: "100vw",
        margin: "auto",
        padding: 0,
        zIndex: 10,
    },
}))

export default function ListingsLayout(props) {
    const {children} = props
    const {isLoggedIn, loginType} = useSelector((state) => state.auth)
    const classes = useStyles()
    const appMessage = useSelector((state) => state.message)

    const dispatch = useDispatch()

    useEffect(() => {
        //clear the message after 5 seconds after it is displayed
        if (appMessage.display) {
            setTimeout(() => {
                // After 3 seconds set the show value to false
                dispatch(clearMessage())
            }, 5000)
        }
    }, [appMessage, dispatch])

    return (
        <>
            {isLoggedIn && loginType !== "main" ? (
                <ListingDashboardNavbar/>
            ) : (
                <ListingNavbar/>
            )}
            <Container
                className={classes.unsecureContainer}
                maxWidth="100%"
                disableGutters={true}
                sx={{position: "relative", zIndex: 0}}
            >
                {appMessage.display && (
                    <Alert
                        sx={{
                            position: "fixed",
                            top: "100px",
                            width: "100%",
                            borderRadius: 0,
                            zIndex: 3,
                        }}
                        severity={appMessage.type}
                        elevation={4}
                    >
                        <AlertTitle>{titleCase(appMessage.type)}</AlertTitle>
                        {appMessage.message}
                    </Alert>
                )}
                {children}
            </Container>
        </>
    )
}
