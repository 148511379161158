/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-lone-blocks */
import {useEffect, useState} from "react";
import {Alert, Box, Button, InputLabel, MenuItem, Paper, Select, TextField, Typography,} from "@mui/material";
import Grid from "@mui/material/Grid";
import {GridActionsCellItem} from "@mui/x-data-grid";
import {Cancel, Delete, Edit, ThumbUp, Visibility} from "@mui/icons-material";
import {
    approveBudget,
    deleteBudget,
    getBudget,
    listBudgets,
    rejectBudget,
    saveBudget,
} from "../services/budgets.service";
import TabContext from "@mui/lab/TabContext/TabContext";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel/TabPanel";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell/TableCell";
import TableBody from "@mui/material/TableBody";
import {formatCurrency, formatDate, isArray, isEmptyArray, isEmptyString, isNullUndefined,} from "../util/util";
import {issueErrorMessage, issueResponseMessage, issueWarnMessage,} from "../actions/message";
import ConfirmationModal from "../components/modals/confirmation";
import {getExpenseAccounts, getFacilityOptions, getIncomeAccounts,} from "../services/list.service";
import {useSelector} from "react-redux";
import {ModalContainer, ModalInnerContainer, ResponsiveRow,} from "../constants/layout.constants";
import {
    MainContainer,
    ModalAcceptButton,
    ModalCancelButton,
    TopBarContainedBrandButton,
    TopBarContainer,
} from "../constants/component.constants";
import CreateBudgeModal from "../components/modals/homes/budgeting/createBudgeModal";
import Permit from "../util/permit";
import MuiDataGrid from "../components/modules/MuiDataGrid";

export default function Budget() {
    const user = useSelector((state) => state.auth.user);
    const [budgetList, setBudgetList] = useState([]);
    const [selectedBudget, setSelectedBudget] = useState(null);
    const [task, setTask] = useState("");
    const [view, setView] = useState("");
    const [value, setValue] = useState("income");
    const [actionNotes, setActionNotes] = useState("");
    const [showApproval, setShowApproval] = useState(false);
    const [selectedBudgetExpense, setSelectedBudgetExpense] = useState([]);
    const [incomeTotal, setIncomeTotal] = useState(0);
    const [expenseTotal, setExpenseTotal] = useState(0);
    const [selectedBudgetIncome, setSelectedBudgetIncome] = useState([]);
    const [budgetForm, setBudgetForm] = useState(false);
    const [viewBudget, setViewBudget] = useState(false);
    const [budgetApproval, setBudgetApproval] = useState(false);
    const [titleText, setTitleText] = useState("");
    const [severityText, setSeverityText] = useState("");
    const [bodyText, setBodyText] = useState("");
    const [positiveText, setPositiveText] = useState("");
    const [negativeText, setNegativeText] = useState("");
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [facilityOptions, setFacilityOptions] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [approvalRequired, setApprovalRequired] = useState(false);
    const [approvalThreshold, setApprovalThreshold] = useState("");
    const [incomeAccountsList, setIncomeAccountsList] = useState([]);
    const [expenseAccountsList, setExpenseAccountsList] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        listBudgets().then((data) => setBudgetList(data));
        getFacilityOptions().then((facilityOptions) =>
            setFacilityOptions(facilityOptions)
        );
        getIncomeAccounts().then((accOptions) => setIncomeAccountsList(accOptions));
        getExpenseAccounts().then((accOptions) =>
            setExpenseAccountsList(accOptions)
        );
    }, [selectedBudget]);

    useEffect(() => {
        setLoading(true);
        listBudgets()
            .then((data) => setBudgetList(data))
            .finally(() => {
                setLoading(false);
            });
    }, [user.proxyFacility]);

    function actionNotesChanged(event) {
        let value = event.target.value;
        setActionNotes(value);
    }

    function onCheckboxChange(event) {
        const checked = event.target.checked;
        const value = event.target.value;
        if (event) {
            if (value === "0" && checked === true) {
                setApprovalRequired(true);
                let obj = selectedBudget;
                obj.approvalRequired = true;
                setSelectedBudget(obj);
            }
            if (value === "0" && checked === false) {
                setApprovalRequired(false);
                let obj = selectedBudget;
                obj.approvalRequired = false;
                setSelectedBudget(obj);
            }
        }
    }

    function handleApproveBudget() {
        setBudgetApproval(true);
        setSelectedBudget(selectedBudget);
        let title = "Confirm Approve Budget";
        let body = "Are you sure you want to approve this budget?";
        setTitleText(title);
        setTask("Approve");
        setSeverityText("warning");
        setBodyText(body);
        setPositiveText("Approve Budget");
        setNegativeText("Cancel");
    }

    function handleRejectBudget() {
        setBudgetApproval(true);
        setSelectedBudget(selectedBudget);
        let title = "Confirm Reject Budget";
        let body = "Are you sure you want to permanently reject this budget?";
        setTitleText(title);
        setTask("Reject");
        setSeverityText("warning");
        setBodyText(body);
        setPositiveText("Reject Budget");
        setNegativeText("Cancel");
    }

    function handleDeleteBudget(item) {
        setBudgetApproval(true);
        setSelectedBudget(item);
        let title = "Confirm Delete Budget";
        let body = "Are you sure you want to permanently delete this budget?";
        setTitleText(title);
        setTask("Delete");
        setSeverityText("warning");
        setBodyText(body);
        setPositiveText("Delete Budget");
        setNegativeText("Cancel");
    }

    function handleCloseApproveBudgetModal() {
        setBudgetApproval(false);
        setTitleText("");
        setTask("");
        setSeverityText("");
        setBodyText("");
        setPositiveText("");
        setNegativeText("");
    }

    function positiveAction() {
        if (task === "Approve") {
            approveBudgetClicked();
        } else if (task === "Reject") {
            rejectBudgetClicked();
        } else {
            deleteBudgetClicked();
        }
    }

    function approveBudgetClicked() {
        if (isEmptyString(actionNotes) || isNullUndefined(actionNotes)) {
            issueWarnMessage("Reason for action is required.");
            return;
        }

        let sendData = {
            id: selectedBudget.id,
            facilityId: selectedBudget.facilityId,
            actionNotes: actionNotes,
        };
        approveBudget(sendData).then((response) => {
            listBudgets().then((data) => setBudgetList(data));
            handleCloseClick();
            handleCloseApproveBudgetModal();
        });
    }

    function rejectBudgetClicked() {
        if (isEmptyString(actionNotes) || isNullUndefined(actionNotes)) {
            issueErrorMessage("Reason for action is required.");
            return;
        }
        let sendData = {
            id: selectedBudget.id,
            facilityId: selectedBudget.facilityId,
            actionNotes: actionNotes,
        };
        rejectBudget(sendData).then((response) => {
            issueResponseMessage(response);
            listBudgets().then((data) => setBudgetList(data));
            handleCloseClick();
            handleCloseApproveBudgetModal();
        });
    }

    function deleteBudgetClicked() {
        if (
            isNullUndefined(selectedBudget) ||
            isNullUndefined(selectedBudget.id) ||
            isEmptyString(selectedBudget.id)
        ) {
            issueWarnMessage(
                "Could not determine Budget ID for deletion. Try refresh the page or contact support."
            );
            return;
        }

        let sendData = {
            budgetId: selectedBudget.id,
            statusCd: selectedBudget.statusCd,
        };
        deleteBudget(sendData).then((response) => {
            issueResponseMessage(response);
            listBudgets().then((data) => setBudgetList(data));
            handleCloseClick();
            handleCloseApproveBudgetModal();
        });
    }

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    function handleCloseClick() {
        setViewBudget(false);
        setTask("");
        setSelectedBudget(null);
        setShowApproval(false);
        setActionNotes("");
        setSelectedBudgetExpense([]);
        setSelectedBudgetIncome([]);
        setIncomeTotal(0);
        setExpenseTotal(0);
    }

    function incomeAccountChanged(event, index) {
        let value = event.target.value;
        let accName = event.target.label;
        let itemsArray = [...selectedBudgetIncome]; // copying the old items array
        itemsArray[index].accountId = value;
        itemsArray[index].account.id = value;
        itemsArray[index].account.name = accName;
        setSelectedBudgetIncome(itemsArray);
    }

    function expenseAccountChanged(event, index) {
        let value = event.target.value;
        let accName = event.target.label;
        let itemsArray = [...selectedBudgetExpense]; // copying the old items array
        itemsArray[index].accountId = value;
        itemsArray[index].account.id = value;
        itemsArray[index].account.name = accName;
        setSelectedBudgetExpense(itemsArray);
    }

    function monthIncomeAmountAccountChanged(event, index, monthName) {
        let value = event.target.value;
        let itemsArray = [...selectedBudgetIncome]; // copying the old items array
        if (monthName === "month1") {
            itemsArray[index].month1 = value;
        }
        if (monthName === "month2") {
            itemsArray[index].month2 = value;
        }
        if (monthName === "month3") {
            itemsArray[index].month3 = value;
        }
        if (monthName === "month4") {
            itemsArray[index].month4 = value;
        }
        if (monthName === "month5") {
            itemsArray[index].month5 = value;
        }
        if (monthName === "month6") {
            itemsArray[index].month6 = value;
        }
        if (monthName === "month7") {
            itemsArray[index].month7 = value;
        }
        if (monthName === "month8") {
            itemsArray[index].month8 = value;
        }
        if (monthName === "month9") {
            itemsArray[index].month9 = value;
        }
        if (monthName === "month10") {
            itemsArray[index].month10 = value;
        }
        if (monthName === "month11") {
            itemsArray[index].month11 = value;
        }
        if (monthName === "month12") {
            itemsArray[index].month12 = value;
        }
        setSelectedBudgetIncome(itemsArray);
        recalculateIncomeTotal(index);
    }

    function monthExpenseAmountAccountChanged(event, index, monthName) {
        let value = event.target.value;
        let itemsArray = [...selectedBudgetExpense]; // copying the old items array
        if (monthName === "month1") {
            itemsArray[index].month1 = value;
        }
        if (monthName === "month2") {
            itemsArray[index].month2 = value;
        }
        if (monthName === "month3") {
            itemsArray[index].month3 = value;
        }
        if (monthName === "month4") {
            itemsArray[index].month4 = value;
        }
        if (monthName === "month5") {
            itemsArray[index].month5 = value;
        }
        if (monthName === "month6") {
            itemsArray[index].month6 = value;
        }
        if (monthName === "month7") {
            itemsArray[index].month7 = value;
        }
        if (monthName === "month8") {
            itemsArray[index].month8 = value;
        }
        if (monthName === "month9") {
            itemsArray[index].month9 = value;
        }
        if (monthName === "month10") {
            itemsArray[index].month10 = value;
        }
        if (monthName === "month11") {
            itemsArray[index].month11 = value;
        }
        if (monthName === "month12") {
            itemsArray[index].month12 = value;
        }
        setSelectedBudgetExpense(itemsArray);
        recalculateExpenseTotal(index);
    }

    function recalculateIncomeTotal(index) {
        let itemsArray = [...selectedBudgetIncome];
        itemsArray[index] = recalculateLineItemTotal(itemsArray[index]);
        setIncomeTotal(recalculateGroupTotal(itemsArray));
        setSelectedBudgetIncome(itemsArray);
    }

    function recalculateExpenseTotal(index) {
        let itemsArray = [...selectedBudgetExpense];
        itemsArray[index] = recalculateLineItemTotal(itemsArray[index]);
        setExpenseTotal(recalculateGroupTotal(itemsArray));
        setSelectedBudgetExpense(itemsArray);
    }

    function recalculateLineItemTotal(lineItem) {
        let dataItem = lineItem;
        dataItem.total = 0;
        dataItem.total = dataItem.total + parseFloat(lineItem.month1);
        dataItem.total = dataItem.total + parseFloat(lineItem.month2);
        dataItem.total = dataItem.total + parseFloat(lineItem.month3);
        dataItem.total = dataItem.total + parseFloat(lineItem.month4);
        dataItem.total = dataItem.total + parseFloat(lineItem.month5);
        dataItem.total = dataItem.total + parseFloat(lineItem.month6);
        dataItem.total = dataItem.total + parseFloat(lineItem.month7);
        dataItem.total = dataItem.total + parseFloat(lineItem.month8);
        dataItem.total = dataItem.total + parseFloat(lineItem.month9);
        dataItem.total = dataItem.total + parseFloat(lineItem.month10);
        dataItem.total = dataItem.total + parseFloat(lineItem.month11);
        dataItem.total = dataItem.total + parseFloat(lineItem.month12);
        return dataItem;
    }

    function recalculateGroupTotal(tableItems) {
        let newTotal = 0;
        for (let item of tableItems) {
            newTotal = newTotal + parseFloat(item.total);
        }
        return newTotal;
    }

    function addIncomeItem() {
        let data = {
            id: null,
            account: {
                id: null,
                name: "",
            },
            accountId: null,
            month1: 0,
            month2: 0,
            month3: 0,
            month4: 0,
            month5: 0,
            month6: 0,
            month7: 0,
            month8: 0,
            month9: 0,
            month10: 0,
            month11: 0,
            month12: 0,
            total: 0,
        };
        setSelectedBudgetIncome((items) => [...items, data]);
    }

    function addExpenseItem() {
        let data = {
            id: null,
            account: {
                id: null,
                name: "",
            },
            accountId: null,
            month1: 0,
            month2: 0,
            month3: 0,
            month4: 0,
            month5: 0,
            month6: 0,
            month7: 0,
            month8: 0,
            month9: 0,
            month10: 0,
            month11: 0,
            month12: 0,
            total: 0,
        };
        setSelectedBudgetExpense((items) => [...items, data]);
    }

    function removeIncomeItem(index) {
        let newArray = [...selectedBudgetIncome]; // copying the old items array
        if (newArray.length >= 1) {
            newArray.splice(index, 1);
            setSelectedBudgetIncome(newArray);
        }
    }

    function removeExpenseItem(index) {
        let newArray = [...selectedBudgetExpense]; // copying the old items array
        if (newArray.length >= 1) {
            newArray.splice(index, 1);
            setSelectedBudgetExpense(newArray);
        }
    }

    function validateFormData() {
        if (isNullUndefined(selectedBudget) || isEmptyString(selectedBudget)) {
            issueWarnMessage("No budget selected!");
            return false;
        }

        if (
            isNullUndefined(selectedBudget.name) ||
            isEmptyString(selectedBudget.name)
        ) {
            issueWarnMessage("Please provide budget name!");
            return false;
        }

        if (
            isNullUndefined(selectedBudget.facilityId) ||
            isEmptyString(selectedBudget.facilityId)
        ) {
            issueWarnMessage("Please select a facility!");
            return false;
        }

        if (
            isNullUndefined(selectedBudget.description) ||
            isEmptyString(selectedBudget.description)
        ) {
            issueWarnMessage("Please provide a description for the budget!");
            return false;
        }

        if (
            isNullUndefined(selectedBudget.startDate) ||
            isEmptyString(selectedBudget.startDate)
        ) {
            issueWarnMessage("Please provide a start date for the budget!");
            return false;
        }

        if (
            isNullUndefined(selectedBudget.endDate) ||
            isEmptyString(selectedBudget.endDate)
        ) {
            issueWarnMessage("Please provide a end date for the budget!");
            return false;
        }

        if (selectedBudget.approvalRequired) {
            if (isNullUndefined(selectedBudget.approvalThreshold)) {
                issueWarnMessage("Please provide approval threshold for the budget!");
                return false;
            }
        }

        if (isArray(selectedBudget.income)) {
            for (let item of selectedBudget.income) {
                if (isEmptyString(item.accountId) || isNullUndefined(item.accountId)) {
                    issueWarnMessage("Please select an account for the income budget!");
                    return false;
                }
            }
        }

        if (isArray(selectedBudget.expense)) {
            for (let item of selectedBudget.expense) {
                if (isEmptyString(item.accountId) || isNullUndefined(item.accountId)) {
                    issueWarnMessage("Please select an account for the expense budget!");
                    return false;
                }
            }
        }

        return true;
    }

    function handleSaveBudget() {
        let tmp = selectedBudget;
        tmp.income = selectedBudgetIncome;
        tmp.expense = selectedBudgetExpense;
        tmp.incomeTotal = incomeTotal;
        tmp.expenseTotal = expenseTotal;
        setSelectedBudget(tmp);
        let sendData = tmp;
        if (validateFormData()) {
            saveBudget(sendData).then((response) => {
                listBudgets().then((data) => setBudgetList(data));
                issueResponseMessage(response);
                handleCloseBudgetForm();
            });
        }
    }

    let incomeItems = [];
    selectedBudgetIncome &&
    !isEmptyArray(selectedBudgetIncome) &&
    selectedBudgetIncome.length > 0 &&
    (incomeItems = selectedBudgetIncome.map(function (item, i) {
        return (
          <TableRow key={i} sx={{ width: "100%" }}>
            <TableCell
              style={{
                padding: "0px",
                paddingBottom: "8px",
                paddingTop: "8px",
              }}
            >
              {view === "Editable" ? (
                <Select
                  sx={{ width: "90%", fontSize: "15px" }}
                  id={"creditAccount" + i}
                  // disabled={task === "View"}
                  value={item && item.account && item.account.id}
                  onChange={(event) => incomeAccountChanged(event, i)}
                >
                  {incomeAccountsList && incomeAccountsList.length > 0 ? (
                    incomeAccountsList.map(function (item, i) {
                      return (
                        <MenuItem
                          key={item.value}
                          value={item.value}
                          label={item.label}
                        >
                          {item.label}
                        </MenuItem>
                      );
                    }, this)
                  ) : (
                    <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
                  )}
                </Select>
              ) : (
                item && item.account && item.account.name
              )}
            </TableCell>
            <TableCell
              style={{
                padding: "0px",
                paddingBottom: "8px",
                paddingTop: "8px",
              }}
            >
              {view === "Editable" ? (
                <TextField
                  size={"small"}
                  sx={{
                    width: "90%",
                    fontSize: "5px",
                    "& .MuiTextField-root": {
                      width: "300px",
                    },
                  }}
                  id={"month1"}
                  onChange={(event) =>
                    monthIncomeAmountAccountChanged(event, i, "month1")
                  }
                  value={item && item.month1}
                />
              ) : (
                item && formatCurrency(item.month1)
              )}
            </TableCell>
            <TableCell
              style={{
                padding: "0px",
                paddingBottom: "8px",
                paddingTop: "8px",
              }}
            >
              {view === "Editable" ? (
                <TextField
                  size={"small"}
                  sx={{ width: "90%", fontSize: "5px" }}
                  id={"month2"}
                  onChange={(event) =>
                    monthIncomeAmountAccountChanged(event, i, "month2")
                  }
                  value={item && item.month2}
                />
              ) : (
                item && formatCurrency(item.month2)
              )}
            </TableCell>
            <TableCell
              style={{
                padding: "0px",
                paddingBottom: "8px",
                paddingTop: "8px",
              }}
            >
              {view === "Editable" ? (
                <TextField
                  size={"small"}
                  sx={{ width: "90%", fontSize: "5px" }}
                  id={"month3"}
                  onChange={(event) =>
                    monthIncomeAmountAccountChanged(event, i, "month3")
                  }
                  value={item && item.month3}
                />
              ) : (
                item && formatCurrency(item.month3)
              )}
            </TableCell>
            <TableCell
              style={{
                padding: "0px",
                paddingBottom: "8px",
                paddingTop: "8px",
              }}
            >
              {view === "Editable" ? (
                <TextField
                  size={"small"}
                  sx={{ width: "90%" }}
                  id={"month4"}
                  onChange={(event) =>
                    monthIncomeAmountAccountChanged(event, i, "month4")
                  }
                  value={item && item.month4}
                />
              ) : (
                item && formatCurrency(item.month4)
              )}
            </TableCell>
            <TableCell
              style={{
                padding: "0px",
                paddingBottom: "8px",
                paddingTop: "8px",
              }}
            >
              {view === "Editable" ? (
                <TextField
                  size={"small"}
                  sx={{ width: "90%" }}
                  id={"month5"}
                  onChange={(event) =>
                    monthIncomeAmountAccountChanged(event, i, "month5")
                  }
                  value={item && item.month5}
                />
              ) : (
                item && formatCurrency(item.month5)
              )}
            </TableCell>
            <TableCell
              style={{
                padding: "0px",
                paddingBottom: "8px",
                paddingTop: "8px",
              }}
            >
              {view === "Editable" ? (
                <TextField
                  size={"small"}
                  sx={{ width: "90%" }}
                  id={"month6"}
                  onChange={(event) =>
                    monthIncomeAmountAccountChanged(event, i, "month6")
                  }
                  value={item && item.month6}
                />
              ) : (
                item && formatCurrency(item.month6)
              )}
            </TableCell>
            <TableCell
              style={{
                padding: "0px",
                paddingBottom: "8px",
                paddingTop: "8px",
              }}
            >
              {view === "Editable" ? (
                <TextField
                  size={"small"}
                  sx={{ width: "90%" }}
                  id={"month7"}
                  onChange={(event) =>
                    monthIncomeAmountAccountChanged(event, i, "month7")
                  }
                  value={item && item.month7}
                />
              ) : (
                item && formatCurrency(item.month7)
              )}
            </TableCell>
            <TableCell
              style={{
                padding: "0px",
                paddingBottom: "8px",
                paddingTop: "8px",
              }}
            >
              {view === "Editable" ? (
                <TextField
                  size={"small"}
                  sx={{ width: "90%" }}
                  id={"month8"}
                  onChange={(event) =>
                    monthIncomeAmountAccountChanged(event, i, "month8")
                  }
                  value={item && item.month8}
                />
              ) : (
                item && formatCurrency(item.month8)
              )}
            </TableCell>
            <TableCell
              style={{
                padding: "0px",
                paddingBottom: "8px",
                paddingTop: "8px",
              }}
            >
              {view === "Editable" ? (
                <TextField
                  size={"small"}
                  sx={{ width: "90%" }}
                  id={"month9"}
                  onChange={(event) =>
                    monthIncomeAmountAccountChanged(event, i, "month9")
                  }
                  value={item && item.month9}
                />
              ) : (
                item && formatCurrency(item.month9)
              )}
            </TableCell>
            <TableCell
              style={{
                padding: "0px",
                paddingBottom: "8px",
                paddingTop: "8px",
              }}
            >
              {view === "Editable" ? (
                <TextField
                  size={"small"}
                  sx={{ width: "90%" }}
                  id={"month10"}
                  onChange={(event) =>
                    monthIncomeAmountAccountChanged(event, i, "month10")
                  }
                  value={item && item.month10}
                />
              ) : (
                item && formatCurrency(item.month10)
              )}
            </TableCell>
            <TableCell
              style={{
                padding: "0px",
                paddingBottom: "8px",
                paddingTop: "8px",
              }}
            >
              {view === "Editable" ? (
                <TextField
                  size={"small"}
                  sx={{ width: "90%" }}
                  id={"month11"}
                  onChange={(event) =>
                    monthIncomeAmountAccountChanged(event, i, "month11")
                  }
                  value={item && item.month11}
                />
              ) : (
                item && formatCurrency(item.month11)
              )}
            </TableCell>
            <TableCell
              style={{
                padding: "0px",
                paddingBottom: "8px",
                paddingTop: "8px",
              }}
            >
              {view === "Editable" ? (
                <TextField
                  size={"small"}
                  sx={{ width: "90%" }}
                  id={"month12"}
                  onChange={(event) =>
                    monthIncomeAmountAccountChanged(event, i, "month12")
                  }
                  value={item && item.month12}
                />
              ) : (
                item && formatCurrency(item.month12)
              )}
            </TableCell>
            <TableCell
              style={{
                padding: "0px",
                paddingBottom: "8px",
                paddingTop: "8px",
              }}
            >
              {view === "Editable" ? (
                <TextField
                  size={"small"}
                  placeholder={"month1"}
                  sx={{ width: "90%" }}
                  id={"total"}
                  disabled={true}
                  value={item && item.total}
                />
              ) : (
                item && formatCurrency(item.total)
              )}
            </TableCell>
            {view === "Editable" && (
              <TableCell
                style={{
                  padding: "0px",
                  paddingBottom: "8px",
                  paddingTop: "8px",
                }}
              >
                <Button
                  variant="contained"
                  type="submit"
                  sx={{ marginLeft: "10px" }}
                  onClick={() => removeIncomeItem(i)}
                  color="error"
                >
                  <Cancel />
                </Button>
              </TableCell>
            )}
          </TableRow>
        );
    }, this));

    let expenseItems = [];
    selectedBudgetExpense &&
    !isEmptyArray(selectedBudgetExpense) &&
    selectedBudgetExpense.length > 0 &&
    (expenseItems = selectedBudgetExpense.map(function (item, i) {
        return (
          <TableRow key={i} sx={{ width: "100%" }}>
            <TableCell
              style={{
                padding: "0px",
                paddingBottom: "8px",
                paddingTop: "8px",
              }}
            >
              {view === "Editable" ? (
                <Select
                  sx={{ width: "90%", fontSize: "15px" }}
                  id={"creditAccount" + i}
                  // disabled={task === "View"}
                  value={item && item.account && item.account.id}
                  onChange={(event) => expenseAccountChanged(event, i)}
                >
                  {expenseAccountsList && expenseAccountsList.length > 0 ? (
                    expenseAccountsList.map(function (item, i) {
                      return (
                        <MenuItem
                          key={item.value}
                          value={item.value}
                          label={item.label}
                        >
                          {item.label}
                        </MenuItem>
                      );
                    }, this)
                  ) : (
                    <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
                  )}
                </Select>
              ) : (
                item && item.account && item.account.name
              )}
            </TableCell>
            <TableCell
              style={{
                padding: "0px",
                paddingBottom: "8px",
                paddingTop: "8px",
              }}
            >
              {view === "Editable" ? (
                <TextField
                  size={"small"}
                  sx={{
                    width: "90%",
                    fontSize: "5px",
                    "& .MuiTextField-root": {
                      width: "300px",
                    },
                  }}
                  id={"month1"}
                  onChange={(event) =>
                    monthExpenseAmountAccountChanged(event, i, "month1")
                  }
                  value={item && item.month1}
                />
              ) : (
                item && formatCurrency(item.month1)
              )}
            </TableCell>
            <TableCell
              style={{
                padding: "0px",
                paddingBottom: "8px",
                paddingTop: "8px",
              }}
            >
              {view === "Editable" ? (
                <TextField
                  size={"small"}
                  sx={{ width: "90%", fontSize: "5px" }}
                  id={"month2"}
                  onChange={(event) =>
                    monthExpenseAmountAccountChanged(event, i, "month2")
                  }
                  value={item && item.month2}
                />
              ) : (
                item && formatCurrency(item.month2)
              )}
            </TableCell>
            <TableCell
              style={{
                padding: "0px",
                paddingBottom: "8px",
                paddingTop: "8px",
              }}
            >
              {view === "Editable" ? (
                <TextField
                  size={"small"}
                  sx={{ width: "90%", fontSize: "5px" }}
                  id={"month3"}
                  onChange={(event) =>
                    monthExpenseAmountAccountChanged(event, i, "month3")
                  }
                  value={item && item.month3}
                />
              ) : (
                item && formatCurrency(item.month3)
              )}
            </TableCell>
            <TableCell
              style={{
                padding: "0px",
                paddingBottom: "8px",
                paddingTop: "8px",
              }}
            >
              {view === "Editable" ? (
                <TextField
                  size={"small"}
                  sx={{ width: "90%" }}
                  id={"month4"}
                  onChange={(event) =>
                    monthExpenseAmountAccountChanged(event, i, "month4")
                  }
                  value={item && item.month4}
                />
              ) : (
                item && formatCurrency(item.month4)
              )}
            </TableCell>
            <TableCell
              style={{
                padding: "0px",
                paddingBottom: "8px",
                paddingTop: "8px",
              }}
            >
              {view === "Editable" ? (
                <TextField
                  size={"small"}
                  sx={{ width: "90%" }}
                  id={"month5"}
                  onChange={(event) =>
                    monthExpenseAmountAccountChanged(event, i, "month5")
                  }
                  value={item && item.month5}
                />
              ) : (
                item && formatCurrency(item.month5)
              )}
            </TableCell>
            <TableCell
              style={{
                padding: "0px",
                paddingBottom: "8px",
                paddingTop: "8px",
              }}
            >
              {view === "Editable" ? (
                <TextField
                  size={"small"}
                  sx={{ width: "90%" }}
                  id={"month6"}
                  onChange={(event) =>
                    monthExpenseAmountAccountChanged(event, i, "month6")
                  }
                  value={item && item.month6}
                />
              ) : (
                item && formatCurrency(item.month6)
              )}
            </TableCell>
            <TableCell
              style={{
                padding: "0px",
                paddingBottom: "8px",
                paddingTop: "8px",
              }}
            >
              {view === "Editable" ? (
                <TextField
                  size={"small"}
                  sx={{ width: "90%" }}
                  id={"month7"}
                  onChange={(event) =>
                    monthExpenseAmountAccountChanged(event, i, "month7")
                  }
                  value={item && item.month7}
                />
              ) : (
                item && formatCurrency(item.month7)
              )}
            </TableCell>
            <TableCell
              style={{
                padding: "0px",
                paddingBottom: "8px",
                paddingTop: "8px",
              }}
            >
              {view === "Editable" ? (
                <TextField
                  size={"small"}
                  sx={{ width: "90%" }}
                  id={"month8"}
                  onChange={(event) =>
                    monthExpenseAmountAccountChanged(event, i, "month8")
                  }
                  value={item && item.month8}
                />
              ) : (
                item && formatCurrency(item.month8)
              )}
            </TableCell>
            <TableCell
              style={{
                padding: "0px",
                paddingBottom: "8px",
                paddingTop: "8px",
              }}
            >
              {view === "Editable" ? (
                <TextField
                  size={"small"}
                  sx={{ width: "90%" }}
                  id={"month9"}
                  onChange={(event) =>
                    monthExpenseAmountAccountChanged(event, i, "month9")
                  }
                  value={item && item.month9}
                />
              ) : (
                item && formatCurrency(item.month9)
              )}
            </TableCell>
            <TableCell
              style={{
                padding: "0px",
                paddingBottom: "8px",
                paddingTop: "8px",
              }}
            >
              {view === "Editable" ? (
                <TextField
                  size={"small"}
                  sx={{ width: "90%" }}
                  id={"month10"}
                  onChange={(event) =>
                    monthExpenseAmountAccountChanged(event, i, "month10")
                  }
                  value={item && item.month10}
                />
              ) : (
                item && formatCurrency(item.month10)
              )}
            </TableCell>
            <TableCell
              style={{
                padding: "0px",
                paddingBottom: "8px",
                paddingTop: "8px",
              }}
            >
              {view === "Editable" ? (
                <TextField
                  size={"small"}
                  sx={{ width: "90%" }}
                  id={"month11"}
                  onChange={(event) =>
                    monthExpenseAmountAccountChanged(event, i, "month11")
                  }
                  value={item && item.month11}
                />
              ) : (
                item && formatCurrency(item.month11)
              )}
            </TableCell>
            <TableCell
              style={{
                padding: "0px",
                paddingBottom: "8px",
                paddingTop: "8px",
              }}
            >
              {view === "Editable" ? (
                <TextField
                  size={"small"}
                  sx={{ width: "90%" }}
                  id={"month12"}
                  onChange={(event) =>
                    monthExpenseAmountAccountChanged(event, i, "month12")
                  }
                  value={item && item.month12}
                />
              ) : (
                item && formatCurrency(item.month12)
              )}
            </TableCell>
            <TableCell
              style={{
                padding: "0px",
                paddingBottom: "8px",
                paddingTop: "8px",
              }}
            >
              {view === "Editable" ? (
                <TextField
                  size={"small"}
                  placeholder={"month1"}
                  sx={{ width: "90%" }}
                  id={"total"}
                  disabled={true}
                  value={item && item.total}
                />
              ) : (
                item && formatCurrency(item.total)
              )}
            </TableCell>
            {view === "Editable" && (
              <TableCell
                style={{
                  padding: "0px",
                  paddingBottom: "8px",
                  paddingTop: "8px",
                }}
              >
                <Button
                  variant="contained"
                  type="submit"
                  sx={{ marginLeft: "10px" }}
                  onClick={() => removeExpenseItem(i)}
                  color="error"
                >
                  <Cancel />
                </Button>
              </TableCell>
            )}
          </TableRow>
        );
    }, this));

    function DetailsTabPanel() {
        return (
            <ResponsiveRow
                sx={{
                    height: "auto",
                    width: "100%",
                    padding: 0,
                    marginTop: "20px",
                }}
            >
                <TabContext value={value}>
                    <TabList onChange={handleTabChange}>
                        <Tab label="Income" value="income"/>
                        <Tab label="Expense" value="expense"/>
                    </TabList>
                    <TabPanel
                        value="income"
                        sx={{width: "100%", boxShadow: 0, margin: 0}}
                    >
                        <TableContainer component={"div"}>
                            <Table>
                                <TableHead>
                                    <TableRow sx={{width: "100%"}}>
                                        <TableCell>Month/Income Account</TableCell>
                                        <TableCell>1</TableCell>
                                        <TableCell>2</TableCell>
                                        <TableCell>3</TableCell>
                                        <TableCell>4</TableCell>
                                        <TableCell>5</TableCell>
                                        <TableCell>6</TableCell>
                                        <TableCell>7</TableCell>
                                        <TableCell>8</TableCell>
                                        <TableCell>9</TableCell>
                                        <TableCell>10</TableCell>
                                        <TableCell>11</TableCell>
                                        <TableCell>12</TableCell>
                                        <TableCell>Total</TableCell>
                                        {view === "Editable" && <TableCell>Actions</TableCell>}
                                    </TableRow>
                                </TableHead>
                                <TableBody>{incomeItems}</TableBody>
                            </Table>
                        </TableContainer>
                        <Alert severity="info" sx={{width: "100%", margin: "10px 0"}}>
                            Income Total:{" "}
                            {task === "Edit" || task === "Create"
                                ? incomeTotal
                                : selectedBudget && selectedBudget.incomeTotal}
                        </Alert>
                        <ResponsiveRow
                            sx={{
                                width: "100%",
                                height: "auto",
                                padding: 0,
                                justifyContent: {xs: "center", lg: "flex-end"},
                                alignItems: "center",
                            }}
                        >
                            {view === "Editable" && (
                                <ModalAcceptButton
                                    variant="contained"
                                    type="submit"
                                    onClick={addIncomeItem}
                                    sx={{
                                        width: {xs: "100%", lg: "200px"},
                                    }}
                                >
                                    Add Row
                                </ModalAcceptButton>
                            )}
                        </ResponsiveRow>
                    </TabPanel>
                    <TabPanel
                        value="expense"
                        sx={{width: "100%", boxShadow: 0, margin: 0}}
                    >
                        <TableContainer component={"div"}>
                            <Table>
                                <TableHead>
                                    <TableRow sx={{width: "100%"}}>
                                        <TableCell>Month/Expense Account</TableCell>
                                        <TableCell>1</TableCell>
                                        <TableCell>2</TableCell>
                                        <TableCell>3</TableCell>
                                        <TableCell>4</TableCell>
                                        <TableCell>5</TableCell>
                                        <TableCell>6</TableCell>
                                        <TableCell>7</TableCell>
                                        <TableCell>8</TableCell>
                                        <TableCell>9</TableCell>
                                        <TableCell>10</TableCell>
                                        <TableCell>11</TableCell>
                                        <TableCell>12</TableCell>
                                        <TableCell>Total</TableCell>
                                        {view === "Editable" && <TableCell>Actions</TableCell>}
                                    </TableRow>
                                </TableHead>
                                <TableBody>{expenseItems}</TableBody>
                            </Table>
                        </TableContainer>
                        <Alert severity="info" sx={{width: "100%", marginTop: "20px"}}>
                            Expense Total:{" "}
                            {task === "Edit" || task === "Create"
                                ? expenseTotal
                                : selectedBudget && selectedBudget.expenseTotal}
                        </Alert>
                        <ResponsiveRow
                            sx={{
                                width: "100%",
                                height: "auto",
                                justifyContent: {xs: "center", lg: "flex-end"},
                                alignItems: "center",
                            }}
                        >
                            {view === "Editable" && (
                                <ModalAcceptButton
                                    type="submit"
                                    onClick={addExpenseItem}
                                    sx={{
                                        width: {xs: "100%", lg: "200px"},
                                    }}
                                >
                                    Add Row
                                </ModalAcceptButton>
                            )}
                        </ResponsiveRow>
                    </TabPanel>
                </TabContext>
            </ResponsiveRow>
        );
    }

    function fetchBudgetItem(item, view) {
        getBudget(item.id).then((data) => {
            setSelectedBudget(data);
            setSelectedBudgetIncome(data.income);
            setSelectedBudgetExpense(data.expense);
            setIncomeTotal(data.incomeTotal);
            setExpenseTotal(data.expenseTotal);
            if (view === "View") {
                setViewBudget(true);
            }
            if (view === "Edit") {
                setBudgetForm(true);
            }
        });
    }

    function handleViewClicked(item) {
        setTask("View");
        fetchBudgetItem(item, "View");
    }

    function handleEditClicked(item) {
        setTask("Edit");
        setView("Editable");
        fetchBudgetItem(item, "Edit");
    }

    function handleCloseBudgetForm() {
        setTask("");
        setView("");
        setBudgetForm(false);
        setSelectedBudget(null);
        setSelectedBudgetExpense([]);
        setSelectedBudgetIncome([]);
        setName("");
        setDescription("");
        setStartDate(null);
        setEndDate(null);
        setIncomeTotal(0);
        setExpenseTotal(0);
        setValue("income");
    }

    function handleAddClicked() {
        setTask("Create");
        setView("Editable");
        setBudgetForm(true);
        let facilityId = null;
        if (user.proxyFacility != null) {
            facilityId = user.proxyFacility;
        }
        let income = {
            id: null,
            account: {
                id: null,
                name: "",
            },
            accountId: null,
            month1: 0,
            month2: 0,
            month3: 0,
            month4: 0,
            month5: 0,
            month6: 0,
            month7: 0,
            month8: 0,
            month9: 0,
            month10: 0,
            month11: 0,
            month12: 0,
            total: 0,
        };

        let expense = {
            id: null,
            account: {
                id: null,
                name: "",
            },
            accountId: null,
            month1: 0,
            month2: 0,
            month3: 0,
            month4: 0,
            month5: 0,
            month6: 0,
            month7: 0,
            month8: 0,
            month9: 0,
            month10: 0,
            month11: 0,
            month12: 0,
            total: 0,
        };
        let data = {
            id: null,
            name: null,
            description: null,
            approvalRequired: null,
            approvalThreshold: null,
            facilityId: facilityId,
            startDate: null,
            endDate: null,
            expense: [income],
            income: [expense],
            expenseTotal: 0,
            incomeTotal: 0,
        };
        setSelectedBudget(data);
    }

    const columns = [
        {headerName: "Name", field: "name", minWidth: 250, flex: 2.5},
        {headerName: "Status", field: "statusName", minWidth: 200, flex: 2},
        {headerName: "Facility", field: "facilityName", minWidth: 150, flex: 1.5},
        {
            headerName: "Date Created",
            field: "ukDateCreated",
            minWidth: 130,
            flex: 1.3,
        },
        {
            headerName: "Start Date",
            field: "ukStartDate",
            minWidth: 150,
            flex: 1.5,
        },
        {headerName: "End Date", field: "ukEndDate", minWidth: 160, fleX: 1.6},
        {
            headerName: "Expense Total",
            field: "expenseTotal",
            minWidth: 150,
            flex: 1.5,
        },
        {headerName: "Income Total", field: "incomeTotal", width: 100},
        {
            headerName: "Actions",
            field: "actions",
            minWidth: 130,
            flex: 1.3,
            type: "actions",
            getActions: (params) => {
                let arr = [];
                arr.push(
                    <GridActionsCellItem
                        icon={<Visibility/>}
                        onClick={() => handleViewClicked(params.row)}
                        label="View"
                        showInMenu
                    />
                );

                {
                    params.row.status.code === "DRF" &&
                    arr.push(
                        <GridActionsCellItem
                            icon={<Edit/>}
                            onClick={() => {
                                handleEditClicked(params.row);
                            }}
                            label="Edit"
                            showInMenu
                        />
                    );
                }

                {
                    params.row.status.code === "DRF" &&
                    arr.push(
                        <GridActionsCellItem
                            icon={<ThumbUp/>}
                            onClick={() => {
                                setShowApproval(true);
                                handleViewClicked(params.row);
                            }}
                            label="Approve/Reject"
                            showInMenu
                        />
                    );
                }

                {
                    params.row.status.code === "DRF" &&
                    arr.push(
                        <GridActionsCellItem
                            icon={<Delete/>}
                            onClick={() => {
                                setShowApproval(true);
                                handleDeleteBudget(params.row);
                            }}
                            label="Delete"
                            showInMenu
                        />
                    );
                }

                return arr;
            },
        },
    ];

    return (
        <Permit
            roles="BLDIR,BLDMG,ACC,CS"
            noPermittedServiceMessage={{
                title: "Access Denied",
                body: (
                    <>
                        Your current role is not permitted to view this page. <br/> Please
                        contact your system admin if you feel this is an error.
                    </>
                ),
            }}
        >
            <MainContainer
                sx={{minHeight: {xs: "100%", lg: "75vh"}, paddingBottom: "85px"}}
            >
                <TopBarContainer
                    container
                    sx={{
                        width: {xs: "100%", md: "80%"},
                        alignItems: "center",
                        marginBottom: "40px",
                    }}
                >
                    <ResponsiveRow
                        item
                        sx={{
                            justifyContent: {xs: "center", lg: "flex-start"},
                            width: {lg: "50%", xs: "100%"},
                            padding: 0,
                        }}
                    >
                        <Typography
                            variant="h5"
                            sx={{textAlign: {xs: "center", lg: "start"}}}
                        >
                            Budgets
                        </Typography>
                    </ResponsiveRow>
                    <ResponsiveRow
                        sx={{
                            justifyContent: {md: "flex-end", xs: "center"},
                            width: {md: "50%", xs: "100%"},
                            padding: 0,
                        }}
                    >
                        <TopBarContainedBrandButton
                            sx={{
                                width: {xs: "100%", lg: "200px"},
                                padding: "auto,",
                            }}
                            // type='submit'
                            onClick={handleAddClicked}
                        >
                            Add Budget
                        </TopBarContainedBrandButton>
                    </ResponsiveRow>
                </TopBarContainer>
                {viewBudget && (
                    <ModalContainer
                        open={viewBudget}
                        onClose={() => setViewBudget(false)}
                    >
                        <ModalInnerContainer container>
                            <Paper
                                variant=""
                                sx={{
                                    height: "auto",
                                    margin: "auto",
                                    padding: "20px",
                                    textAlign: "left",
                                }}
                            >
                                <Box sx={{padding: "20px"}}>
                                    {/*<Typography id="viewBudget" variant="h6" component="h6">*/}
                                    Budget Details
                                    {/*</Typography>*/}
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        color="error"
                                        onClick={handleCloseClick}
                                        sx={{
                                            marginLeft: "10px",
                                            "&:hover": {
                                                backgroundColor: "#FFF",
                                                color: "#E53935",
                                            },
                                            float: "right",
                                        }}
                                    >
                                        Close
                                    </Button>
                                </Box>
                                {/*<br />*/}

                                {showApproval && (
                                    <Paper
                                        variant=""
                                        sx={{
                                            height: "auto",
                                            margin: "auto",
                                            padding: "20px",
                                            textAlign: "left",
                                        }}
                                    >
                                        <ResponsiveRow sx={{width: "100%", height: "auto"}}>
                                            <Typography
                                                id="budgetApproval"
                                                variant="h6"
                                                component="h6"
                                            >
                                                Approve/Reject Budget
                                            </Typography>
                                            <br/>

                                            <ResponsiveRow container>
                                                <Grid
                                                    itemsx={{
                                                        width: {xs: "100%", lg: "33.3%"},
                                                        marginBottom: "10px",
                                                    }}
                                                >
                                                    <InputLabel>
                                                        Please give a reason for Approving/Rejecting this
                                                        budget
                                                    </InputLabel>
                                                    <br/>
                                                    <TextField
                                                        id="description"
                                                        sx={{width: "50%"}}
                                                        label={
                                                            "Reason* (" + (500 - actionNotes.length) + ")"
                                                        }
                                                        multiline
                                                        rows={4}
                                                        value={actionNotes}
                                                        required={true}
                                                        onChange={actionNotesChanged}
                                                    />
                                                </Grid>
                                            </ResponsiveRow>
                                            <Alert severity="warning">
                                                This action cannot be undone.
                                            </Alert>

                                            <ResponsiveRow
                                                sx={{
                                                    width: "100%",
                                                    justifyContent: {xs: "center", lg: "flex-end"},
                                                }}
                                            >
                                                <ModalAcceptButton
                                                    variant="contained"
                                                    //type="submit"
                                                    onClick={handleApproveBudget}
                                                    sx={{
                                                        width: {xs: "100%", lg: "200px"},
                                                    }}
                                                >
                                                    Approve
                                                </ModalAcceptButton>
                                                <ModalCancelButton
                                                    variant="contained"
                                                    type="submit"
                                                    color="error"
                                                    onClick={handleRejectBudget}
                                                    sx={{
                                                        width: {xs: "100%", lg: "200px"},
                                                    }}
                                                >
                                                    Reject
                                                </ModalCancelButton>
                                            </ResponsiveRow>
                                        </ResponsiveRow>
                                    </Paper>
                                )}
                                <br/>

                                <Paper
                                    variant=""
                                    sx={{
                                        height: "auto",
                                        margin: "auto",
                                        padding: "20px",
                                        textAlign: "left",
                                    }}
                                >
                                    <ResponsiveRow container>
                                        <Grid
                                            item
                                            sx={{
                                                marginBottom: "10px",
                                                width: {lg: "33.3%", xs: "100%"},
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    flexWrap: "nowrap",
                                                }}
                                            >
                                                <InputLabel>Name:</InputLabel>
                                                <Typography
                                                    sx={{
                                                        marginLeft: 2,
                                                        fontSize: "15px",
                                                        color: "#037171",
                                                        textAlign: "left",
                                                    }}
                                                >
                                                    {selectedBudget.name}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid
                                            item
                                            sx={{
                                                marginBottom: "10px",
                                                width: {lg: "33.3%", xs: "100%"},
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    flexWrap: "nowrap",
                                                }}
                                            >
                                                <InputLabel>Description:</InputLabel>
                                                <Typography
                                                    sx={{
                                                        marginLeft: 2,
                                                        fontSize: "15px",
                                                        color: "#037171",
                                                        textAlign: "left",
                                                    }}
                                                >
                                                    {selectedBudget.description}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid
                                            item
                                            sx={{
                                                marginBottom: "10px",
                                                width: {lg: "33.3%", xs: "100%"},
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    flexWrap: "nowrap",
                                                }}
                                            >
                                                <InputLabel>Status:</InputLabel>
                                                <Typography
                                                    sx={{
                                                        marginLeft: 2,
                                                        fontSize: "15px",
                                                        color: "#037171",
                                                        textAlign: "left",
                                                    }}
                                                >
                                                    {selectedBudget.status && selectedBudget.status.name}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid
                                            item
                                            sx={{
                                                marginBottom: "10px",
                                                width: {lg: "33.3%", xs: "100%"},
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    flexWrap: "nowrap",
                                                }}
                                            >
                                                <InputLabel>Start Date:</InputLabel>
                                                <Typography
                                                    sx={{
                                                        marginLeft: 2,
                                                        fontSize: "15px",
                                                        color: "#037171",
                                                        textAlign: "left",
                                                    }}
                                                >
                                                    {formatDate(selectedBudget.startDate)}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid
                                            item
                                            sx={{
                                                marginBottom: "10px",
                                                width: {lg: "33.3%", xs: "100%"},
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    flexWrap: "nowrap",
                                                }}
                                            >
                                                <InputLabel>End Date:</InputLabel>
                                                <Typography
                                                    sx={{
                                                        marginLeft: 2,
                                                        fontSize: "15px",
                                                        color: "#037171",
                                                        textAlign: "left",
                                                    }}
                                                >
                                                    {formatDate(selectedBudget.endDate)}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid
                                            item
                                            sx={{
                                                marginBottom: "10px",
                                                width: {lg: "33.3%", xs: "100%"},
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    flexWrap: "nowrap",
                                                }}
                                            >
                                                <InputLabel>Facility:</InputLabel>
                                                <Typography
                                                    sx={{
                                                        marginLeft: 2,
                                                        fontSize: "15px",
                                                        color: "#037171",
                                                        textAlign: "left",
                                                    }}
                                                >
                                                    {selectedBudget.facility.name}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid
                                            item
                                            sx={{
                                                marginBottom: "10px",
                                                width: {lg: "33.3%", xs: "100%"},
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    flexWrap: "nowrap",
                                                }}
                                            >
                                                <InputLabel>Approval Required?:</InputLabel>
                                                <Typography
                                                    sx={{
                                                        marginLeft: 2,
                                                        fontSize: "15px",
                                                        color: "#037171",
                                                        textAlign: "left",
                                                    }}
                                                >
                                                    {selectedBudget.approvalRequired === true
                                                        ? "Yes"
                                                        : "No"}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        {selectedBudget.approvalRequired === true && (
                                            <Grid
                                                item
                                                sx={{
                                                    marginBottom: "10px",
                                                    width: {lg: "33.3%", xs: "100%"},
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        flexWrap: "nowrap",
                                                    }}
                                                >
                                                    <InputLabel>Approval Threshold:</InputLabel>
                                                    <Typography
                                                        sx={{
                                                            marginLeft: 2,
                                                            fontSize: "15px",
                                                            color: "#037171",
                                                            textAlign: "left",
                                                        }}
                                                    >
                                                        {selectedBudget.approvalThreshold + " %"}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        )}
                                    </ResponsiveRow>
                                </Paper>
                                <br/>

                                <DetailsTabPanel/>
                            </Paper>
                        </ModalInnerContainer>
                    </ModalContainer>
                )}
                {/* <StyledDataGrid
          sx={{
            height: { xs: "100%", lg: 650 },
          }}
          autoHeight
          rows={budgetList}
          columns={columns}
          loading={loading}
          pagination
          components={{
            LoadingOverlay: LinearProgress,
            NoRowsOverlay: EmptyOverlay,
          }}
        /> */}

                <MuiDataGrid
                    dataGridColumns={columns}
                    dataGridRows={budgetList}
                    serverPagination={false}
                    loading={loading}
                    height="60vh"
                    totalRows={budgetList.length}
                />

                {budgetApproval && (
                    <ConfirmationModal
                        showConfirmationModal={budgetApproval}
                        handleConfirmationClose={handleCloseApproveBudgetModal}
                        title={titleText}
                        severity={severityText}
                        body={bodyText}
                        positiveText={positiveText}
                        negativeText={negativeText}
                        positiveAction={positiveAction}
                        negativeAction={handleCloseApproveBudgetModal}
                    />
                )}
                {budgetForm && (
                    <CreateBudgeModal
                        handleSaveBudget={handleSaveBudget}
                        handleCloseBudgetForm={handleCloseBudgetForm}
                        budgetForm={budgetForm}
                        task={task}
                        onCheckboxChange={onCheckboxChange}
                        setApprovalThreshold={setApprovalThreshold}
                        setDescription={setDescription}
                        setName={setName}
                        setSelectedBudget={setSelectedBudget}
                        setStartDate={setStartDate}
                        setEndDate={setEndDate}
                        approvalRequired={approvalRequired}
                        approvalThreshold={approvalThreshold}
                        description={description}
                        endDate={endDate}
                        name={name}
                        selectedBudget={selectedBudget}
                        startDate={startDate}
                        facilityOptions={facilityOptions}
                        handleTabChange={handleTabChange}
                        view={view}
                        incomeItems={incomeItems}
                        expenseItems={expenseItems}
                        incomeTotal={incomeTotal}
                        expenseTotal={expenseTotal}
                        addIncomeItem={addIncomeItem}
                        addExpenseItem={addExpenseItem}
                        value={value}
                    />
                )}
            </MainContainer>
        </Permit>
    );
}
