/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import {Typography} from "@mui/material"
import {
    formatDateTime,
    isEmptyString,
    isNullUndefined, sortJSONArray
} from "../../../util/util"
import { ResponsiveRow } from "../../../constants/layout.constants"
import {
    getTotalSubscriptionLogs,
    listSubscriptionLogs
} from "../../../services/subscription.service";
import MuiDataGrid from "../../modules/MuiDataGrid";

export default function SubscriptionLog(props) {
    const [subscriptionLogs, setSubscriptionLogs] = useState([])
    const [totalSubscriptionLogs, setTotalSubscriptionLogs] = useState(0)
    const [loading, setLoading] = useState(true)
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 50,
        page: 0,
    })

  useEffect(() => {
      if(!isNullUndefined(props) && !isNullUndefined(props.subscription) &&
          !isNullUndefined(props.subscription.id) && !isEmptyString(props.subscription.id)){
          setLoading(true)
          getTotalSubscriptionLogs(props.subscription.id).then((response) => {
              setTotalSubscriptionLogs(response.totalSubscriptionLogs)
          })
          listSubscriptionLogs(
              props.subscription.id,
              paginationModel.page,
              paginationModel.pageSize
          )
              .then((response) => {
                  if (!isNullUndefined(response) && !isNullUndefined(response.data)) {
                      setSubscriptionLogs(
                          response.data.sort(sortJSONArray("effectiveDate", false))
                      );
                  }
              })
              .finally(() => {
                  setLoading(false);
              });

      }
  }, [
      props,
    paginationModel.page,
    paginationModel.pageSize
  ]);



  const columns = [
      {
          headerName: "Date",
          field: "effectiveDate",
          width: "220",
          valueFormatter: (params) => formatDateTime(params.value),
      },
      { headerName: "Status", field: "status", width: "100",
          valueFormatter: (params) => {
              return params.value.name
          }, },
    { headerName: "Note", field: "notes", width: "450" },
      { headerName: "Edited By", field: "createdByName", width: "200" }
  ]

  return (
    <>
      <ResponsiveRow
        sx={{
          flexDirection: "column",
          flexWrap: "nowrap",
          height: props.height ? props.height : "100%",
          width: { xs: "100%", lg: props.width ? props.width : "100%" },
          margin: "auto",
          padding: "20px 30px",
          backgroundColor: "#fff",
          borderRadius: "10px",
        }}
      >
        <Typography
          variant="h5"
          color="primary.dark"
          sx={{
            display: "flex",
            flexDirection: { xs: "column", lg: "row" },
            textAlign: "start",
          }}
        >
          Subscription Logs{" "}
        </Typography>
        <MuiDataGrid
            dataGridColumns={columns}
            dataGridRows={subscriptionLogs}
            noSelection={true}
            loading={loading}
            height="46vh"
            totalRows={totalSubscriptionLogs}
            handlePageLoad={setPaginationModel}
            serverPagination={true}
        />
      </ResponsiveRow>

    </>
  )
}
