import {Alert, AlertTitle, Button, Divider, Grid, InputLabel, Modal, Paper, TextField, Typography,} from "@mui/material"

import {issueWarnMessage, setMessage} from "../../actions/message"
import {isEmptyString, isNullUndefined, titleCase} from "../../util/util"
import {useState} from "react"
import {completeWorkOrder} from "../../services/workOrder.service"
import {
    InnerModal,
    TopBarContainedBrandButton,
    TopBarContainedCancelButton,
    TopBarContainer,
} from "../../constants/component.constants"
import {useDispatch, useSelector} from "react-redux"
import Permit from "../../util/permit"
import {printWorkOrder} from "../../services/print.service"
import {ModalInnerContainer, ResponsiveRow} from "../../constants/layout.constants"
import SplitButton from "../splitButton"

export default function WorkOrderViewBar(props) {
    const [completeReason, setCompleteReason] = useState("")
    const [completeModal, setCompleteModal] = useState(false)
    const appMessage = useSelector((state) => state.message)
    const dispatch = useDispatch()

    function handleCompleteClose() {
        setCompleteReason("") //Clear reason
        setCompleteModal(false)
    }

    function handleCompleteClick() {
        setCompleteReason("") //Clear reason
        setCompleteModal(true)
    }

    function onTextChange(event) {
        const id = event.target.id
        const value = event.target.value
        if (id === "completeReason") {
            setCompleteReason(value)
        }
    }

    function completeWorkOrderAction() {
        if (
            isNullUndefined(props.selectedWorkOrder) ||
            isNullUndefined(props.selectedWorkOrder.id) ||
            isEmptyString(props.selectedWorkOrder.id)
        ) {
            
            issueWarnMessage("Couldn't determine the identifier for marking the work order as complete. Try refresh the page.")
            return
        }
        let id = props.selectedWorkOrder.id
        if (isNullUndefined(completeReason) || isEmptyString(completeReason)) {
            dispatch(
                setMessage({
                    type: "warning",
                    message:
                        "A final reason must be provided when marking the work order as complete",
                })
            )
            return
        }
        let sendData = {
            id: id,
            completeReason: completeReason,
        }

        // All fields valid, approve
        // console.log('sendData ', sendData)
        // this.props.closeAndHide()
        completeWorkOrder(sendData, id)
            .then((response) => {
                dispatch(
                    setMessage({
                        type: response.status,
                        message: response.message,
                    })
                )
                props.reloadWorkOrders()
            })
            .catch((error) => {
                console.log("completeWorkOrder: ", error)
            })
    }

    function handlePrintWorkOrder() {
        printWorkOrder(props.selectedWorkOrder.id)
    }

    const workOrderButtons = [
        {
            label: "WORK ORDER ACTIONS",
            roles: false,
            services: false,
        },
        {
            action: props.handleAddVendorClick,
            label: "Add Vendor",
            roles: false,
            services: false,
        },
        {
            action: handlePrintWorkOrder,
            label: "Print Work Order",
            roles: false,
            services: false,
        },
    ]

    return (
        <TopBarContainer sx={{width: {xs: "100%", lg: "80%"}}} container>
            <ResponsiveRow
                sx={{
                    justifyContent: {md: "flex-start", xs: "center"},
                    alignItems: "center",
                    width: {lg: "30%", sm: "100%"},
                }}
                component="form"
            >
                <Typography variant="h5" sx={{fontWeight: 700}}>
                    Work Order {props.selectedWorkOrder.refNumber}
                </Typography>
            </ResponsiveRow>
            <ResponsiveRow
                sx={{
                    justifyContent: {md: "flex-end", xs: "center"},
                    flexWrap: {xs: "wrap", lg: "nowrap"},
                    width: {md: "70%", xs: "100%"},
                    padding: 0,
                }}
            >
                {props.selectedWorkOrder.status.code === "INP" && (
                    <Permit roles={"SYSAD,BLDIR,BLDMG,CS"}>
                        <TopBarContainedBrandButton
                            variant="contained"
                            type="submit"
                            onClick={handleCompleteClick}
                            sx={{
                                width: {xs: "100%", lg: "200px"},
                            }}
                        >
                            Complete
                        </TopBarContainedBrandButton>
                    </Permit>
                )}

                <Permit roles={"SYSAD,BLDIR,BLDMG,CS"}>
                    <SplitButton buttons={workOrderButtons}/>
                </Permit>

                <TopBarContainedCancelButton
                    variant="contained"
                    onClick={props.handleViewClose}
                    sx={{
                        width: {xs: "100%", lg: "200px"},
                    }}
                >
                    {" "}
                    Close
                </TopBarContainedCancelButton>
            </ResponsiveRow>
            {/*COMPLETE MODAL*/}
            <Modal
                open={completeModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <ModalInnerContainer>
                    <Typography id="modal-modal-title" variant="h3" component="h2">
                        Complete Work Order
                    </Typography>
                    <Divider/>
                    <br/>
                    {appMessage.display && (
                        <Alert severity={appMessage.type}>
                            <AlertTitle>{titleCase(appMessage.type)}</AlertTitle>
                            {appMessage.message}
                        </Alert>
                    )}
                    <Paper
                        variant="outlined"
                        sx={{height: "auto", margin: "auto", padding: "10px"}}
                    >
                        <Alert severity="info">
                            <p>
                                Are you sure you want mark work order{" "}
                                {props.selectedWorkOrder.refNumber} as complete?
                            </p>
                            Please provide a final note before marking this work order as
                            complete.
                        </Alert>
                        <br/>
                        <InnerModal container>
                            <Grid item xs={12}>
                                <InputLabel>Completion note* (500)</InputLabel>
                                <TextField
                                    sx={{width: "100%"}}
                                    id={"completeReason"}
                                    placeholder={
                                        "Give a reason why the work order is being marked as complete? Has all the work been completed?"
                                    }
                                    isrequired
                                    multiline
                                    rows={4}
                                    messageRequired={"Completion note is required"}
                                    value={completeReason}
                                    onChange={(event) => onTextChange(event)}
                                />
                            </Grid>
                        </InnerModal>
                        <br/>
                        <Alert severity="warning">This action cannot be undone</Alert>
                        <br/>
                    </Paper>
                    <br/>

                    {/*FOOTER WITH BUTTONS*/}
                    <Paper
                        variant="outlined"
                        sx={{height: "auto", margin: "auto", padding: "10px"}}
                    >
                        <Permit roles={"SYSAD,BLDIR,BLDMG,CS"}>
                            <Button
                                variant="contained"
                                type="submit"
                                onClick={completeWorkOrderAction}
                                sx={{backgroundColor: "#037171"}}
                            >
                                {" "}
                                Mark as Complete{" "}
                            </Button>
                        </Permit>

                        <Button
                            variant="contained"
                            type="submit"
                            onClick={handleCompleteClose}
                            sx={{marginLeft: "10px"}}
                            color="error"
                        >
                            {" "}
                            Cancel{" "}
                        </Button>
                    </Paper>
                </ModalInnerContainer>
            </Modal>
        </TopBarContainer>
    )
}
