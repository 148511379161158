import {Grid, Modal} from "@mui/material";
import {Box, styled} from "@mui/system";

export const ResponsiveRow = styled(Grid)((theme) => ({
    display: "flex",
    flexDirection: {xs: "column", lg: "row"},
    justifyContent: {xs: "center", lg: "space-between"},
    flexWrap: "wrap",
    height: "100%",
    padding: "10px",
    width: "100%",
}));

export const ModalContainer = styled(Modal)((theme) => ({
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    justifyContent: "center",
    maxHeight: {xs: "50vh", lg: "70vh"},
    overflowY: "auto",
    maxWidth: "100%",
}));

export const ModalInnerContainer = styled(Box)((theme) => ({
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    maxHeight: {xs: "50vh", lg: "70vh"},
    overflowY: "auto",
    margin: "auto",
    backgroundColor: "#fff",

    borderRadius: "10px",
    padding: "30px",
}));
