import {Alert, Grid, InputLabel, TextField} from "@mui/material"
import ModalContainerModule from "../../../modules/modalContainer"
import {ResponsiveRow} from "../../../../constants/layout.constants"
import {isEmptyArray, isNullUndefined} from "../../../../util/util"
import {setMessage} from "../../../../actions/message"
import {applyIncrement} from "../../../../services/lease.service"
import {useState} from "react"
import {useDispatch} from "react-redux";

export default function IncrementRentModal(props) {

    const [increment, setIncrement] = useState(null)
    const dispatch = useDispatch()

    function handleChange(e) {
        switch (e.target.name) {
            case "increment":
                setIncrement(e.target.value)
                break
            default:
                break
        }
    }

    function incrementRent() {
        if (isNullUndefined(increment)) {
            dispatch(
                setMessage({
                    type: "error",
                    message:
                        "Increment is required",
                })
            )
            return
        } else if (increment <= 0) {
            dispatch(
                setMessage({
                    type: "error",
                    message:
                        "Increment must be greater than 0",
                })
            )
            return
        }
        let incrementArray = Array.from(props.selectedLeaseIds)
        if (isEmptyArray(incrementArray)) {
            dispatch(
                setMessage({
                    type: "error",
                    message:
                        "You must select at lease one lease to increment",
                })
            )
            return
        }
        let sendData = {
            incrementSet: incrementArray,
            increment: increment
        }
        // console.log("sending ", sendData)

        applyIncrement(sendData)
            .then((response) => {
                if (!isNullUndefined(response)) {
                    dispatch(
                        setMessage({
                            type: response.type,
                            message: response.message,
                        })
                    )
                    props.handleIncrementRentClose()
                }
            })
    }

    return (
        <ModalContainerModule
            size="xs"
            accept={incrementRent}
            cancel={props.handleIncrementRentClose}
            openModal={props.showIncrementRentModal}
            // closeModal
            modalTitle={`Increment rent for ${
                props.selectedLeaseIds && props.selectedLeaseIds.length
            } leases`}
            acceptButtonText={"Apply Increment"}
        >
            <ResponsiveRow container>
                <Grid
                    item
                    sx={{width: "100%", height: "auto", marginBottom: "10px"}}
                >
                    <Alert severity="info">
                        Enter a percentage amount to increment the rent by.
                    </Alert>
                </Grid>
                <Grid
                    item
                    sx={{
                        width: {xs: "100%", lg: "33.3%"},
                        marginBottom: "10px",
                    }}
                >
                    <InputLabel>Increment Percentage (%)</InputLabel>
                    <TextField
                        placeholder={"Increment Percentage"}
                        sx={{width: "90%"}}
                        name="increment"
                        id="increment"
                        autoFocus={true}
                        required
                        type="number"
                        helperText={"Increment Percentage is required"}
                        onChange={handleChange}
                        value={increment}
                    />
                </Grid>
                <Grid
                    item
                    sx={{
                        width: {xs: "100%", lg: "33.3%"},
                        marginBottom: "10px",
                    }}
                ></Grid>
            </ResponsiveRow>
        </ModalContainerModule>
    )
}