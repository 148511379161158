import React, { useCallback, useEffect, useState } from "react";
import GenerateReport from "../modals/generateReport";
import {
  MobileNavItem,
  MobileNavParentText,
  MobileNavSubItem,
  MobileNavSubMenu,
} from "../../constants/component.constants";
import Permit from "../../util/permit";
import { Collapse, Typography } from "@mui/material";
import { ResponsiveRow } from "../../constants/layout.constants";
import { isEmptyArray, isNullUndefined } from "../../util/util";
import { useSelector } from "react-redux";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import kodi_theme from "../../themes/kodiThemeProvider";

export default function MobileReportsMenuItem({ reports, closeDrawer }) {
  const user = useSelector((state) => state.auth.user);

  const [reportsModal, setReportsModal] = useState(false);
  const [reportType, setReportType] = useState("");
  const [reportDescription, setReportDescription] = useState();
  const [open, setOpen] = useState(false);

  const [maintenanceAndWorkOrders, setMaintenanceAndWorkOrders] = useState({
    category: "Maintenance and Work Orders",
    children: [],
  });
  const [financialAnalysisAndTracking, setFinancialAnalysisAndTracking] =
    useState({ category: "Financial Analysis and Tracking", children: [] });
  const [vendorAndExpenseReports, setVendorAndExpenseReports] = useState({
    category: "Vendor and Expense Reports",
    children: [],
  });
  const [propertyAndFacilityReports, setPropertyAndFacilityReports] = useState({
    category: "Property and Facility Reports",
    children: [],
  });
  const [financialReports, setFinancialReports] = useState({
    category: "Financial Reports",
    children: [],
  });

  const toggleMenu = () => {
    setOpen(!open);
    //  setOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const processReport = (item) => {
    setOpen(!open);
    setReportsModal(true);
    setReportType(item.selector);
    setReportDescription(item.description);
  };

  const reportsCategorizer = useCallback(
    () => {
      const maintenanceAndWorkOrdersTemp = maintenanceAndWorkOrders;
      const financialAnalysisAndTrackingTemp = financialAnalysisAndTracking;
      const vendorAndExpenseReportsTemp = vendorAndExpenseReports;
      const propertyAndFacilityReportsTemp = propertyAndFacilityReports;
      const financialReportsTemp = financialReports;

      if (!isNullUndefined(reports) && reports.children.length > 0) {
        maintenanceAndWorkOrdersTemp.children = [];
        financialAnalysisAndTrackingTemp.children = [];
        vendorAndExpenseReportsTemp.children = [];
        propertyAndFacilityReportsTemp.children = [];
        financialReportsTemp.children = [];

        reports.children.forEach((item) => {
          switch (item.category) {
            case "Maintenance and Work Orders":
              maintenanceAndWorkOrders.children.push(item);

              setMaintenanceAndWorkOrders(maintenanceAndWorkOrdersTemp);
              break;
            case "Financial Analysis and Tracking":
              financialAnalysisAndTracking.children.push(item);

              setFinancialAnalysisAndTracking({
                ...financialAnalysisAndTrackingTemp,
              });
              break;
            case "Vendor and Expense Reports":
              vendorAndExpenseReports.children.push(item);

              setVendorAndExpenseReports(vendorAndExpenseReportsTemp);
              break;
            case "Property and Facility Reports":
              propertyAndFacilityReports.children.push(item);

              setPropertyAndFacilityReports({
                ...propertyAndFacilityReportsTemp,
              });
              break;
            case "Financial Reports":
              financialReports.children.push(item);

              setFinancialReports(financialReportsTemp);
              break;

            default:
              break;
          }
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user]
  );

  useEffect(() => {
    reportsCategorizer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportsCategorizer]);

  return (
    reports && (
      <Permit services="PREMIUM,HOA,METER,BLWTR,MAINT">
        <MobileNavItem id="dropDownTrigger" onClick={toggleMenu}>
          <MobileNavParentText
            primary={reports.name}
            primaryTypographyProps={{ color: "#fff" }}
          />
          {open ? <ExpandLess /> : <ExpandMore />}
        </MobileNavItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <MobileNavSubMenu
            component="div"
            sx={{
              backgroundColor: "white",
              borderRadius: "10px",
            }}
          >
            {!isEmptyArray(maintenanceAndWorkOrders.children) && (
              <ResponsiveRow
                sx={{ flexDirection: "column", flexWrap: "nowrap" }}
              >
                <Typography
                  variant="body2"
                  color={kodi_theme.palette.common.black}
                  fontWeight={600}
                  textAlign="start"
                >
                  Maintenance and Work Orders
                </Typography>
                {maintenanceAndWorkOrders.children.map((item, i) =>
                  item.roles === false && item.services === false ? (
                    <MobileNavSubItem
                      key={i}
                      onClick={() => {
                        processReport(item);
                      }}
                    >
                      {item.name}
                    </MobileNavSubItem>
                  ) : (
                    <Permit
                      key={i}
                      roles={item.roles}
                      services={item.services}
                      permissions={item.permissions}
                    >
                      <MobileNavSubItem
                        onClick={() => {
                          processReport(item);
                        }}
                      >
                        {item.name}
                      </MobileNavSubItem>
                    </Permit>
                  )
                )}
              </ResponsiveRow>
            )}

            {!isEmptyArray(financialAnalysisAndTracking.children) && (
              <ResponsiveRow
                sx={{ flexDirection: "column", flexWrap: "nowrap" }}
              >
                <Typography
                  variant="body2"
                  color={kodi_theme.palette.common.black}
                  fontWeight={600}
                  textAlign="start"
                >
                  Financial Analysis and Tracking
                </Typography>
                {financialAnalysisAndTracking.children.map((item, i) =>
                  item.roles === false && item.services === false ? (
                    <MobileNavSubItem
                      key={i}
                      onClick={() => {
                        processReport(item);
                      }}
                    >
                      {item.name}
                    </MobileNavSubItem>
                  ) : (
                    <Permit
                      key={i}
                      roles={item.roles}
                      services={item.services}
                      permissions={item.permissions}
                    >
                      <MobileNavSubItem
                        onClick={() => {
                          processReport(item);
                        }}
                      >
                        {item.name}
                      </MobileNavSubItem>
                    </Permit>
                  )
                )}
              </ResponsiveRow>
            )}

            {!isEmptyArray(vendorAndExpenseReports.children) && (
              <ResponsiveRow
                sx={{ flexDirection: "column", flexWrap: "nowrap" }}
              >
                <Typography
                  variant="body2"
                  color={kodi_theme.palette.common.black}
                  fontWeight={600}
                  textAlign="start"
                >
                  Vendor and Expense Reports
                </Typography>
                {vendorAndExpenseReports.children.map((item, i) =>
                  item.roles === false && item.services === false ? (
                    <MobileNavSubItem
                      key={i}
                      onClick={() => {
                        processReport(item);
                      }}
                    >
                      {item.name}
                    </MobileNavSubItem>
                  ) : (
                    <Permit
                      key={i}
                      roles={item.roles}
                      services={item.services}
                      permissions={item.permissions}
                    >
                      <MobileNavSubItem
                        onClick={() => {
                          processReport(item);
                        }}
                      >
                        {item.name}
                      </MobileNavSubItem>
                    </Permit>
                  )
                )}
              </ResponsiveRow>
            )}

            {!isEmptyArray(propertyAndFacilityReports.children) && (
              <ResponsiveRow
                sx={{ flexDirection: "column", flexWrap: "nowrap" }}
              >
                <Typography
                  variant="body2"
                  color={kodi_theme.palette.common.black}
                  fontWeight={600}
                  textAlign="start"
                >
                  Property and Facility Reports
                </Typography>
                {propertyAndFacilityReports.children.map((item, i) =>
                  item.roles === false && item.services === false ? (
                    <MobileNavSubItem
                      key={i}
                      onClick={() => {
                        processReport(item);
                      }}
                    >
                      {item.name}
                    </MobileNavSubItem>
                  ) : (
                    <Permit
                      key={i}
                      roles={item.roles}
                      services={item.services}
                      permissions={item.permissions}
                    >
                      <MobileNavSubItem
                        onClick={() => {
                          processReport(item);
                        }}
                      >
                        {item.name}
                      </MobileNavSubItem>
                    </Permit>
                  )
                )}
              </ResponsiveRow>
            )}

            {!isEmptyArray(financialReports.children) && (
              <ResponsiveRow
                sx={{ flexDirection: "column", flexWrap: "nowrap" }}
              >
                <Typography
                  variant="body2"
                  color={kodi_theme.palette.common.black}
                  fontWeight={600}
                  textAlign="start"
                >
                  Financial Reports
                </Typography>
                {financialReports.children.map((item, i) =>
                  item.roles === false && item.services === false ? (
                    <MobileNavSubItem
                      key={i}
                      onClick={() => {
                        processReport(item);
                      }}
                    >
                      {item.name}
                    </MobileNavSubItem>
                  ) : (
                    <Permit
                      key={i}
                      roles={item.roles}
                      services={item.services}
                      permissions={item.permissions}
                    >
                      <MobileNavSubItem
                        onClick={() => {
                          processReport(item);
                        }}
                      >
                        {item.name}
                      </MobileNavSubItem>
                    </Permit>
                  )
                )}
              </ResponsiveRow>
            )}
          </MobileNavSubMenu>
        </Collapse>

        <GenerateReport
          showModal={reportsModal}
          closeModal={() => {
            setReportsModal(false);
            setReportType("");
            closeDrawer();
          }}
          report={reportType}
          description={reportDescription}
        />
      </Permit>
    )
  );
}
