import {Alert, FormControl, InputLabel, MenuItem, Select,} from "@mui/material"
import {ResponsiveRow} from "../../../../constants/layout.constants"
import {FileUpload} from "../../../inputs/fileUpload"
import ModalContainerModule from "../../../modules/modalContainer"

export default function UploadReconciliationModal(props) {
    /**
     * REQUIRED PROPS:
     *
     * handleMobileMoneyReconciliation
     * handleCancelClicked
     * reconciliationForm
     * selectedMobileMoneyProvider
     * setSelectedMobileMoneyProvider
     * mobileMoneyProvidersList
     * bulkFile
     * bulkFileProgress
     * fileValueChanged
     *  *
     */

    return (
      <ModalContainerModule
        size="xs"
        accept={props.handleMobileMoneyReconciliation}
        cancel={props.handleCancelClicked}
        openModal={props.reconciliationForm}
        modalTitle="Mobile Money Reconciliation"
        acceptButtonText="Reconcile"
      >
        <ResponsiveRow sx={{ height: "auto", width: "100%" }}>
          <Alert severity="info" sx={{ width: "100%", marginBottom: "20px" }}>
            Please ensure you upload a xlsx, spreadsheetml or ms-excel document
          </Alert>
          <FormControl
            item
            sx={{ width: { xs: "100%", lg: "33.3%" }, marginBottom: "10px" }}
          >
            <InputLabel id="mobileMobeyProviderLabel">
              Mobile Money Provider*
            </InputLabel>
            <Select
              sx={{ width: { xs: "100%", lg: "90%" } }}
              id={"mpesaReconciliation"}
              label="Mobile Money Provider*"
              value={props.selectedMobileMoneyProvider}
              onChange={(event) => {
                let value = event.target.value;
                props.setSelectedMobileMoneyProvider(value);
              }}
            >
              {props.mobileMoneyProvidersList &&
              props.mobileMoneyProvidersList.length > 0 ? (
                props.mobileMoneyProvidersList.map(function (item, i) {
                  return (
                    <MenuItem key={i} value={item.value}>
                      {item.label}
                    </MenuItem>
                  );
                }, this)
              ) : (
                <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
              )}
            </Select>
          </FormControl>

          <FormControl
            item
            sx={{ width: { xs: "100%", lg: "33.3%" }, marginBottom: "10px" }}
          >
            <FileUpload
              id={"bulkFileInput"}
              label={"Attach Mobile Money Excel File"}
              currentFile={props.bulkFile}
              progress={props.bulkFileProgress}
              onChange={props.fileValueChanged}
              accept={
                ".xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              }
            />
          </FormControl>
        </ResponsiveRow>
      </ModalContainerModule>
    );
}
