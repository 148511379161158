import {Grid, MenuItem, TextField, Typography} from "@mui/material"
import {ModalAcceptButton, ModalCancelButton,} from "../../../constants/component.constants"
import {ModalContainer, ModalInnerContainer, ResponsiveRow,} from "../../../constants/layout.constants"

export default function FilterResults(props) {
    return (
        <ModalContainer open={props.openModal} onClose={props.closeModal}>
            <ModalInnerContainer
                sx={{maxHeight: {xs: "50vh", lg: "70vh"}, width: "60%"}}
            >
                <Typography variant="h4" sx={{marginBottom: "20px"}}>
                    Filter Listings
                </Typography>
                <ResponsiveRow
                    sx={{
                        width: "100%",
                        justifyContent: {xs: "center", lg: "space-between"},
                        alignItems: "center",
                    }}
                >
                    <Grid
                        container
                        sx={{width: {xs: "100%", lg: "100%"}, marginBottom: "10px"}}
                    >
                        <Grid
                            item
                            sx={{width: {xs: "100%", lg: "33.3%"}, marginBottom: "10px"}}
                        >
                            <TextField
                                variant="outlined"
                                label="Property Type"
                                select
                                sx={{width: "90%"}}
                                value={props.selectedPropertyType}
                                onChange={(event) => props.onPropertyTypeChange(event)}
                            >
                                {
                                    props.propertyTypes && props.propertyTypes.length > 0 && props.propertyTypes.map((propertyType, i) => {
                                        return (
                                            <MenuItem key={i} value={propertyType.value}>{propertyType.label}</MenuItem>
                                        )
                                    })
                                }
                            </TextField>
                        </Grid>

                        <Grid
                            item
                            sx={{width: {xs: "100%", lg: "33.3%"}, marginBottom: "10px"}}
                        >
                            <TextField
                                variant="outlined"
                                label="Listing Type"
                                select
                                sx={{width: "90%"}}
                                value={props.selectedListingType}
                                onChange={(event) => props.onListingTypeChange(event)}
                            >
                                {
                                    props.listingTypes && props.listingTypes.length > 0 && props.listingTypes.map((l, i) => {
                                        return (
                                            <MenuItem key={i} value={l.value}>{l.label}</MenuItem>
                                        )
                                    })
                                }
                            </TextField>
                        </Grid>

                        {props.selectedPropertyType !== "OFF" &&
                            <Grid
                                item
                                sx={{width: {xs: "100%", lg: "33.3%"}, marginBottom: "10px"}}
                            >
                                <TextField
                                    variant="outlined"
                                    label="Number of Beds"
                                    select
                                    sx={{width: "90%"}}
                                    value={props.noOfBeds}
                                    onChange={(event) => props.noOfBedsChanged(event)}
                                >
                                    <MenuItem value="1">1</MenuItem>
                                    <MenuItem value="2">2</MenuItem>
                                    <MenuItem value="3">3</MenuItem>
                                    <MenuItem value="4">4</MenuItem>
                                    <MenuItem value="fivePlus">5+</MenuItem>
                                </TextField>
                            </Grid>
                        }
                    </Grid>

                    <Grid
                        container
                        sx={{width: {xs: "100%", lg: "100%"}, marginBottom: "10px"}}
                    >
                        {props.selectedPropertyType !== "OFF" &&
                            <Grid
                                item
                                sx={{width: {xs: "100%", lg: "33.3%"}, marginBottom: "10px"}}
                            >
                                <TextField
                                    variant="outlined"
                                    label="Number of Baths"
                                    select
                                    sx={{width: "90%"}}
                                    value={props.noOfBaths}
                                    onChange={(event) => props.noOfBathsChanged(event)}
                                >
                                    <MenuItem value="1">1</MenuItem>
                                    <MenuItem value="2">2</MenuItem>
                                    <MenuItem value="3">3</MenuItem>
                                    <MenuItem value="4">4</MenuItem>
                                    <MenuItem value="fivePlus">5+</MenuItem>
                                </TextField>
                            </Grid>
                        }

                        <ResponsiveRow
                            item
                            sx={{
                                flexDirection: "column",
                                width: {xs: "100%", lg: "33.3%"},
                                marginBottom: "10px",
                            }}
                        >
                            <Typography variant="h6">Property Dimensions</Typography>
                            <ResponsiveRow
                                sx={{justifyContent: {xs: "center", lg: "space-between"}}}
                            >
                                <TextField
                                    variant="outlined"
                                    label="Min SqFt"
                                    sx={{width: "45%"}}
                                    type="number"
                                    value={props.sqftMin}
                                    onChange={(event) => props.sqftMinChanged(event)}
                                />
                                <TextField
                                    variant="outlined"
                                    label="Max SqFt"
                                    sx={{width: "45%"}}
                                    type="number"
                                    value={props.sqftMax}
                                    onChange={(event) => props.sqftMaxChanged(event)}
                                />
                            </ResponsiveRow>
                        </ResponsiveRow>
                        <ResponsiveRow
                            item
                            sx={{
                                flexDirection: "column",
                                width: {xs: "100%", lg: "33.3%"},
                                marginBottom: "10px",
                            }}
                        >
                            <Typography variant="h6">Budget</Typography>
                            <ResponsiveRow
                                sx={{justifyContent: {xs: "center", lg: "space-between"}}}
                            >
                                <TextField
                                    variant="outlined"
                                    label="Min Price"
                                    sx={{width: "45%"}}
                                    type="number"
                                    value={props.priceMin}
                                    onChange={(event) => props.priceMinChanged(event)}
                                />
                                <TextField
                                    variant="outlined"
                                    label="Max Price"
                                    sx={{width: "45%"}}
                                    type="number"
                                    value={props.priceMax}
                                    onChange={(event) => props.priceMaxChanged(event)}
                                />
                            </ResponsiveRow>
                        </ResponsiveRow>
                    </Grid>
                </ResponsiveRow>
                <ResponsiveRow
                    sx={{justifyContent: {xs: "center", lg: "flex-end"}}}
                >
                    <ModalAcceptButton
                        sx={{width: {xs: "100%", lg: "200px"}}}
                        onClick={props.applyFilterClicked}
                    >
                        Apply Filters
                    </ModalAcceptButton>
                    <ModalCancelButton
                        onClick={props.clearFilter}
                        sx={{width: {xs: "100%", lg: "200px"}}}
                    >
                        Clear Filter
                    </ModalCancelButton>
                    <ModalCancelButton
                        onClick={() => {
                            props.closeModal()
                            // props.clearFilter()
                        }}
                        sx={{width: {xs: "100%", lg: "200px"}}}
                    >
                        Close
                    </ModalCancelButton>
                </ResponsiveRow>
            </ModalInnerContainer>
        </ModalContainer>
    )
}
