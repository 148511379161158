/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from "react";
import Grid from "@mui/material/Grid/index";
import {
  Box,
  Button,
  Divider,
  InputLabel,
  Paper,
  styled,
  Typography,
} from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { Print, Visibility } from "@mui/icons-material";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import {
  getFacilityAmount,
  listBankAccounts,
  listMobileMoneyTransfers,
  printTransferDetails,
  saveFundsTransfer,
  totalMobileMoneyTransfers,
  viewTransfer,
} from "../../services/mobileMoneyTransfer.service";

import { isEmptyString, isNullUndefined } from "../../util/util";
import { issueResponseMessage, issueWarnMessage } from "../../actions/message";
import { useSelector } from "react-redux";
import {
  getCashPaymentAccounts,
  getPaymentVoucherMethods,
} from "../../services/list.service";
import { DocumentStore } from "../../components/documentStore";
import {
  TopBarContainedBrandButton,
  TopBarContainer,
} from "../../constants/component.constants";
import { ResponsiveRow } from "../../constants/layout.constants";
import TransferDetailsModal from "../../components/modals/mobileMoney/transferFunds/transferDetailsModal";
import FilterTransfers from "../../components/modals/mobileMoney/transferFunds/filterTransfers";
import MuiDataGrid from "../../components/modules/MuiDataGrid";

const FormContainer = styled(Grid)(({ theme: kodi_theme }) => ({
  margin: "auto",
  flexDirection: "row",
  align: "left",
  alignItems: "left",
}));

const InnerModal = styled(Grid)(({ theme: kodi_theme }) => ({
  margin: "auto",
  flexDirection: "row",
  alignItems: "center",
}));

export default function MobileMoneyTransfer() {
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageCount: 25,
  });
  const [task, setTask] = useState("");
  const [filterForm, setFilterForm] = useState(false);
  const [transferForm, setTransferForm] = useState(false);
  const [transfersList, setTransfersList] = useState([]);
  const [totalTransfers, setTotalTransfers] = useState(0);
  const [payee, setPayee] = useState("");
  const [refNumber, setRefNumber] = useState("");
  const [description, setDescription] = useState("");
  const [approvalDate, setApprovalDate] = useState("");
  const [bankAccountsList, setBankAccountsList] = useState([]);
  const [selectedBankAcc, setSelectedBankAcc] = useState("");
  const user = useSelector((state) => state.auth.user);
  const [facilityAmount, setFacilityAmount] = useState("");
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");
  const [chequeNo, setChequeNo] = useState("");
  const [cashAccounts, setCashAccounts] = useState([]);
  const [selectedCashAccount, setSelectedCashAccount] = useState("");
  const [memo, setMemo] = useState("");
  const [bulkFile, setBulkFile] = useState(null);
  const [bulkFileProgress, setBulkFileProgress] = useState(0);
  const [selectedTransfer, setSelectedTransfer] = useState(null);
  const [viewForm, setViewForm] = useState(false);
  const [loading, setLoading] = useState(true);

  const [bankRefNumber, setBankRefNumber] = useState("");

  const fetchPageData = useCallback(() => {
    listMobileMoneyTransfers(
      payee,
      refNumber,
      description,
      approvalDate,
      paginationModel.page
    ).then((data) => setTransfersList(data));
    totalMobileMoneyTransfers(payee, refNumber, description, approvalDate)
      .then((data) => setTotalTransfers(data.totalTransfers))
      .finally(() => {
        if (totalTransfers !== undefined) setLoading(false);
      });
    listBankAccounts().then((data) => setBankAccountsList(data));
    getPaymentVoucherMethods().then((data) => setPaymentMethods(data));
  }, [payee, refNumber, description, approvalDate, paginationModel.page]);

  const refreshProxiedFacility = useCallback(() => {
    setLoading(true);
    listMobileMoneyTransfers(
      payee,
      refNumber,
      description,
      approvalDate,
      paginationModel.page
    ).then((data) => setTransfersList(data));
    totalMobileMoneyTransfers(payee, refNumber, description, approvalDate)
      .then((data) => setTotalTransfers(data.totalTransfers))
      .finally(() => {
        if (totalTransfers !== undefined) setLoading(false);
      });
  }, [user.proxyFacility]);

  useEffect(() => {
    fetchPageData();
    return () => {
      setTotalTransfers(0);
    };
  }, [fetchPageData]);

  useEffect(() => {
    refreshProxiedFacility();
    return () => {
      setTotalTransfers(0);
    };
  }, [refreshProxiedFacility]);

  // console.log("transfers List", transfersList);

  function handleViewTransferClicked(item) {
    // console.log("view item", item)
    setTask("View");
    viewTransfer(item.id)
      .then((data) => {
        setSelectedTransfer(data);
        setViewForm(true);
        console.log("data is:", data);
      })
      .catch((error) => {
        console.log("view transfer error", error);
      });
  }

  function handleCloseViewClicked() {
    setViewForm(false);
    setBulkFile(null);
    setTask("");
  }

  function handlePrintTransferDetails(item) {
    if (task === "View") {
      printTransferDetails(selectedTransfer.id);
    } else {
      printTransferDetails(item.id);
    }
  }

  const columns = [
    { headerName: "Facility", field: "facilityName", minWidth: 150, flex: 1.5 },
    {
      headerName: "Reference No.",
      field: "referenceNumber",
      minWidth: 125,
      flex: 1.25,
    },
    { headerName: "Transfer Date", field: "date", minWidth: 120, flex: 1.2 },
    {
      headerName: "Status",
      field: "status",
      minWidth: 110,
      flex: 1.1,
      renderCell: (params) => params.value.name,
    },
    {
      headerName: "Approved By",
      field: "approvedBy",
      minWidth: 150,
      flex: 1.5,
    },
    {
      headerName: "Approved Date",
      field: "approvedOn",
      minwidth: 150,
      flex: 1.5,
    },
    {
      headerName: "Description",
      field: "description",
      minWidth: 225,
      flex: 2.25,
    },
    {
      headerName: "Payment Method",
      field: "paymentMethod",
      minWidth: 150,
      flex: 1.5,
      renderCell: (params) => params.value.name,
    },
    { headerName: "Amount", field: "amount", minWidth: 100, fleX: 1 },
    {
      headerName: "Actions",
      field: "actions",
      minWidth: 100,
      flex: 1,
      type: "actions",
      getActions: (params) => {
        // console.log("Params", params.row)
        let arr = [];
        arr.push(
          <GridActionsCellItem
            icon={<Visibility />}
            onClick={() => handleViewTransferClicked(params.row)}
            label="View"
            showInMenu
          />
        );

        arr.push(
          <GridActionsCellItem
            icon={<Print />}
            onClick={() => handlePrintTransferDetails(params.row)}
            label="Print"
            showInMenu
          />
        );

        return arr;
      },
    },
  ];

  function handleFilterClicked() {
    setFilterForm(true);
  }

  function handleCloseFilterFormClicked() {
    setFilterForm(false);
    setPayee("");
    setRefNumber("");
    setDescription("");
    setApprovalDate("");
  }

  function handleSearchClicked() {
    listMobileMoneyTransfers(
      payee,
      refNumber,
      description,
      approvalDate,
      paginationModel.page
    )
      .then((data) => setTransfersList(data))
      .catch((error) => {
        console.log("error listing mobile money transfers", error);
      });
    setFilterForm(false);
    console.log("sendData", approvalDate);
  }

  function handleTransferFundsClicked() {
    if (
      isEmptyString(user.proxyFacility) ||
      isNullUndefined(user.proxyFacility)
    ) {
      issueWarnMessage("Must proxy to a facility to do a transfer!");
    } else {
      getFacilityAmount().then((res) => {
        if (res.mpesaSub === "MPESA1") {
          issueWarnMessage(
            "Facilities with MPESA1 subscription do not need Funds Transfers"
          );
        } else if (res.mpesaSub === "MPESA2") {
          const data = {
            id: null,
            chequeNumber: "",
            bankId: null,
            date: null,
            paymentMethodCd: "",
            description: "",
            amount: res.facilityAmount,
            cashAccount: null,
          };
          setFacilityAmount(res.facilityAmount);
          setTransferForm(true);
          setTask("Create");
          setSelectedTransfer(data);
        }
      });
      getCashPaymentAccounts().then((data) => setCashAccounts(data));
    }
  }

  function handleCloseTransferFundsClicked() {
    setTransferForm(false);
    setTask("");
    setSelectedBankAcc("");
    setFacilityAmount("");
    setSelectedPaymentMethod("");
    setSelectedCashAccount("");
    setChequeNo("");
    setMemo("");
    setBulkFileProgress(0);
    setSelectedTransfer(null);
    setBulkFile(null);
    setBankRefNumber("");
  }

  function fileValueChanged(event) {
    // console.log("event.target.files[0] ", event.target.files[0]);
    setBulkFile(event.target.files[0]);
  }

  function validateForm() {
    // console.log("sendData", selectedTransfer);
    if (
      isNullUndefined(selectedTransfer.bankId) ||
      isEmptyString(selectedTransfer.bankId)
    ) {
      issueWarnMessage("Could not determine the Organization's bank account!");
      return false;
    }

    if (
      isNullUndefined(selectedTransfer.paymentMethodCd) ||
      isEmptyString(selectedTransfer.paymentMethodCd)
    ) {
      issueWarnMessage("You must select a payment method for this transfer.");
      return false;
    } else {
      if (
        selectedTransfer.paymentMethodCd === "CSH" &&
        (isNullUndefined(selectedTransfer.cashAccount) ||
          isEmptyString(selectedTransfer.cashAccount))
      ) {
        issueWarnMessage(
          "You must select a cash account for the cash transfer."
        );
        return false;
      } else if (
        selectedTransfer.paymentMethodCd === "CHQ" &&
        (isNullUndefined(selectedTransfer.chequeNumber) ||
          isEmptyString(selectedTransfer.chequeNumber))
      ) {
        issueWarnMessage(
          "You must indicate the cheque number for cheque transfer."
        );
        return false;
      } else if (
        selectedTransfer.paymentMethodCd === "RTGS" &&
        (isNullUndefined(selectedTransfer.bankRefNumber) ||
          isEmptyString(selectedTransfer.bankRefNumber))
      ) {
        issueWarnMessage(
          "You must indicate the Bank Reference Number for cheque transfer."
        );
        return false;
      }
    }

    if (
      isNullUndefined(selectedTransfer.description) ||
      isEmptyString(selectedTransfer.description)
    ) {
      issueWarnMessage("The transfer must include a description.");
      return false;
    }

    return true;
  }

  function saveTransfer() {
    let uploadData = new FormData();
    uploadData.append("file", bulkFile);
    uploadData.append("selectedTransfer", JSON.stringify(selectedTransfer));

    if (validateForm()) {
      saveFundsTransfer(uploadData).then((response) => {
        issueResponseMessage(response);
        listMobileMoneyTransfers(
          payee,
          refNumber,
          description,
          approvalDate,
          paginationModel.page
        ).then((data) => setTransfersList(data));
        totalMobileMoneyTransfers(
          payee,
          refNumber,
          description,
          approvalDate
        ).then((data) => setTotalTransfers(data.totalTransfers));
        handleCloseTransferFundsClicked();
      });
    }
  }

  return (
    <ResponsiveRow
      sx={{
        flexDirection: "column",
        flexWrap: "nowrap",
        width: "100%",
        height: "auto",
        justifyContent: "flex-start",
        alignItems: "flex-start",
      }}
    >
      {!viewForm && (
        <TopBarContainer
          container
          sx={{
            width: { xs: "100%", lg: "80%" },
            height: "auto",
          }}
        >
          <ResponsiveRow
            item
            sx={{
              justifyContent: { xs: "center", lg: "flex-start" },
              width: { xs: "100%", lg: "50%" },
              padding: 0,
              marginBottom: "10px",
            }}
          >
            <Typography
              sx={{ textAlign: "left" }}
              id="mobileMoneyReconciliation"
              variant="h5"
            >
              Mobile Money Transfers
            </Typography>
          </ResponsiveRow>

          <ResponsiveRow
            item
            sx={{
              justifyContent: { xs: "center", lg: "flex-end" },
              width: { xs: "100%", lg: "50%" },
              padding: 0,
            }}
          >
            <TopBarContainedBrandButton
              variant="contained"
              onClick={handleFilterClicked}
              sx={{
                width: { xs: "100%", lg: "200px" },
                height: "50px",
                marginBottom: "10px",
              }}
            >
              Filter Transfers
            </TopBarContainedBrandButton>

            <TopBarContainedBrandButton
              variant="contained"
              onClick={handleTransferFundsClicked}
              sx={{
                width: { xs: "100%", lg: "200px" },
                height: "50px",
                marginBottom: "10px",
              }}
            >
              Transfer Funds
            </TopBarContainedBrandButton>
          </ResponsiveRow>
        </TopBarContainer>
      )}
      <br />

      {!viewForm && (
        <MuiDataGrid
          dataGridColumns={columns}
          dataGridRows={transfersList}
          serverPagination={true}
          handlePageLoad={setPaginationModel}
          loading={loading}
          height="60vh"
          totalRows={totalTransfers !== undefined ? totalTransfers : 0}
        />
      )}

      <FilterTransfers
        handleSearchClicked={handleSearchClicked}
        handleCloseFilterFormClicked={handleCloseFilterFormClicked}
        filterForm={filterForm}
        setRefNumber={setRefNumber}
        refNumber={refNumber}
        setDescription={setDescription}
        description={description}
        approvalDate={approvalDate}
        setApprovalDate={setApprovalDate}
      />
      <TransferDetailsModal
        handleCloseTransferFundsClicked={handleCloseTransferFundsClicked}
        bankAccountsList={bankAccountsList}
        bulkFile={bulkFile}
        bulkFileProgress={bulkFileProgress}
        cashAccounts={cashAccounts}
        chequeNo={chequeNo}
        facilityAmount={facilityAmount}
        fileValueChanged={fileValueChanged}
        memo={memo}
        paymentMethods={paymentMethods}
        saveTransfer={saveTransfer}
        selectedBankAcc={selectedBankAcc}
        selectedPaymentMethod={selectedPaymentMethod}
        selectedTransfer={selectedTransfer}
        task={task}
        transferForm={transferForm}
        setSelectedBankAcc={setSelectedBankAcc}
        setSelectedTransfer={setSelectedTransfer}
        setFacilityAmount={setFacilityAmount}
        setSelectedPaymentMethod={setSelectedPaymentMethod}
        setChequeNo={setChequeNo}
        selectedCashAccount={selectedCashAccount}
        setSelectedCashAccount={setSelectedCashAccount}
        setMemo={setMemo}
        setBankRefNumber={setBankRefNumber}
        bankRefNumber={bankRefNumber}
      />

      {viewForm && (
        <FormContainer>
          <Paper
            // variant="outlined"
            sx={{
              height: "auto",
              margin: "auto",
              padding: "40px",
              textAlign: "left",
              border: 0,
              boxShadow: "none",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Typography
                id="viewTransfer"
                width={"100%"}
                variant="h6"
                component="h6"
              >
                Transfer Details
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  justifyContent: "flex-end",
                  marginBottom: "20px",
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#E53935",
                    color: "#fff",
                    "&:hover": {
                      backgroundColor: "#FFF",
                      color: "#E53935",
                    },

                    fontSize: "12px",
                    padding: "5px 7px",
                    margin: { xs: "10px 0", md: "5px 10px", lg: "0 10px" },
                    textTransform: "full-width",
                    width: "10rem",
                  }}
                  type="submit"
                  onClick={handleCloseViewClicked}
                >
                  Close
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#037171",
                    color: "#fff",
                    "&:hover": {
                      backgroundColor: "#FFF",
                      color: "#037171",
                    },
                    fontSize: "12px",
                    padding: "10px auto",
                    margin: { xs: "10px 0", md: "5px 10px", lg: "0 5px" },
                    textAlign: "center",
                    textTransform: "full-width",
                    width: "10rem",
                  }}
                  type="button"
                  target="_blank"
                  onClick={handlePrintTransferDetails}
                  // href={
                  // "/kodi/print/printClientPaymentVoucher?clientPaymentVoucherId=" + selectedTransfer.id}
                >
                  <LocalPrintshopIcon sx={{ margin: "auto 5px" }} /> Print
                </Button>
              </Box>
            </Box>
            <Divider />
            <br />
            <Paper
              //   variant="outlined"
              sx={{
                height: "auto",
                margin: "auto",
                padding: "10px",
                textAlign: "left",
                boxShadow: "none",
              }}
            >
              <InnerModal container>
                <Grid item xs={4} style={{ marginBottom: 10 }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "nowrap",
                    }}
                  >
                    <InputLabel sx={{ fontSize: ".9rem", fontWeight: 700 }}>
                      Date:
                    </InputLabel>
                    <Typography
                      sx={{
                        marginLeft: 2,
                        fontSize: "14px",
                        color: "#037171",
                        textAlign: "left",
                      }}
                    >
                      {selectedTransfer && selectedTransfer.date}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={4} style={{ marginBottom: 10 }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "nowrap",
                    }}
                  >
                    <InputLabel sx={{ fontSize: ".9rem", fontWeight: 700 }}>
                      Paid To:
                    </InputLabel>
                    <Typography
                      sx={{
                        marginLeft: 2,
                        fontSize: "14px",
                        color: "#037171",
                        textAlign: "left",
                      }}
                    >
                      {selectedTransfer && selectedTransfer.orgName}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={4} style={{ marginBottom: 10 }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "nowrap",
                    }}
                  >
                    <InputLabel sx={{ fontSize: ".9rem", fontWeight: 700 }}>
                      Reference Number:
                    </InputLabel>
                    <Typography
                      sx={{
                        marginLeft: 2,
                        fontSize: "14px",
                        color: "#037171",
                        textAlign: "left",
                      }}
                    >
                      {selectedTransfer && selectedTransfer.referenceNumber}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={4} style={{ marginBottom: 10 }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "nowrap",
                    }}
                  >
                    <InputLabel sx={{ fontSize: ".9rem", fontWeight: 700 }}>
                      Applied To:
                    </InputLabel>
                    <Typography
                      sx={{
                        marginLeft: 2,
                        fontSize: "14px",
                        color: "#037171",
                        textAlign: "left",
                      }}
                    >
                      {selectedTransfer && selectedTransfer.appliedTo}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={8} style={{ marginBottom: 10 }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "nowrap",
                    }}
                  >
                    <InputLabel sx={{ fontSize: ".9rem", fontWeight: 700 }}>
                      Memo:
                    </InputLabel>
                    <Typography
                      sx={{
                        marginLeft: 2,
                        fontSize: "14px",
                        color: "#037171",
                        textAlign: "left",
                      }}
                    >
                      {selectedTransfer && selectedTransfer.description}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={4} style={{ marginBottom: 10 }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "nowrap",
                    }}
                  >
                    <InputLabel sx={{ fontSize: ".9rem", fontWeight: 700 }}>
                      Payment Method:
                    </InputLabel>
                    <Typography
                      sx={{
                        marginLeft: 2,
                        fontSize: "14px",
                        color: "#037171",
                        textAlign: "left",
                      }}
                    >
                      {selectedTransfer &&
                        selectedTransfer.paymentMethod &&
                        selectedTransfer.paymentMethod.name}
                    </Typography>
                  </Box>
                </Grid>

                {selectedTransfer &&
                  selectedTransfer.paymentMethod &&
                  selectedTransfer.paymentMethod.code === "CHQ" && (
                    <Grid item xs={4} style={{ marginBottom: 10 }}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "nowrap",
                        }}
                      >
                        <InputLabel sx={{ fontSize: ".9rem", fontWeight: 700 }}>
                          Cheque Number:
                        </InputLabel>
                        <Typography
                          sx={{
                            marginLeft: 2,
                            fontSize: "14px",
                            color: "#037171",
                            textAlign: "left",
                          }}
                        >
                          {selectedTransfer && selectedTransfer.chequeNumber}
                        </Typography>
                      </Box>
                    </Grid>
                  )}

                <Grid item xs={4} style={{ marginBottom: 10 }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "nowrap",
                    }}
                  >
                    <InputLabel sx={{ fontSize: ".9rem", fontWeight: 700 }}>
                      Paid From:
                    </InputLabel>
                    <Typography
                      sx={{
                        marginLeft: 2,
                        fontSize: "14px",
                        color: "#037171",
                        textAlign: "left",
                      }}
                    >
                      {selectedTransfer &&
                        selectedTransfer.paymentAccount &&
                        selectedTransfer.paymentAccount.name}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={4} style={{ marginBottom: 10 }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "nowrap",
                    }}
                  >
                    <InputLabel sx={{ fontSize: ".9rem", fontWeight: 700 }}>
                      Applied To:
                    </InputLabel>
                    <Typography
                      sx={{
                        marginLeft: 2,
                        fontSize: "14px",
                        color: "#037171",
                        textAlign: "left",
                      }}
                    >
                      {selectedTransfer && selectedTransfer.appliedTo}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={4} style={{ marginBottom: 10 }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "nowrap",
                    }}
                  >
                    <InputLabel sx={{ fontSize: ".9rem", fontWeight: 700 }}>
                      Status:
                    </InputLabel>
                    <Typography
                      sx={{
                        marginLeft: 2,
                        fontSize: "14px",
                        color: "#037171",
                        textAlign: "left",
                      }}
                    >
                      {selectedTransfer &&
                        selectedTransfer.status &&
                        selectedTransfer.status.name}
                    </Typography>
                  </Box>
                </Grid>

                {selectedTransfer && selectedTransfer.approvedBy && (
                  <Grid item xs={4} style={{ marginBottom: 10 }}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "nowrap",
                      }}
                    >
                      <InputLabel sx={{ fontSize: ".9rem", fontWeight: 700 }}>
                        Approved By:
                      </InputLabel>
                      <Typography
                        sx={{
                          marginLeft: 2,
                          fontSize: "14px",
                          color: "#037171",
                          textAlign: "left",
                        }}
                      >
                        {selectedTransfer && selectedTransfer.approvedBy}
                      </Typography>
                    </Box>
                  </Grid>
                )}

                {selectedTransfer && selectedTransfer.approvedOn && (
                  <Grid item xs={4} style={{ marginBottom: 10 }}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "nowrap",
                      }}
                    >
                      <InputLabel sx={{ fontSize: ".9rem", fontWeight: 700 }}>
                        Approved On:
                      </InputLabel>
                      <Typography
                        sx={{
                          marginLeft: 2,
                          fontSize: "14px",
                          color: "#037171",
                          textAlign: "left",
                        }}
                      >
                        {selectedTransfer && selectedTransfer.approvedOn}
                      </Typography>
                    </Box>
                  </Grid>
                )}

                <Grid item xs={4} style={{ marginBottom: 10 }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "nowrap",
                    }}
                  >
                    <InputLabel sx={{ fontSize: ".9rem", fontWeight: 700 }}>
                      Transfer Total:
                    </InputLabel>
                    <Typography
                      sx={{
                        marginLeft: 2,
                        fontSize: "14px",
                        color: "#037171",
                        textAlign: "left",
                      }}
                    >
                      {selectedTransfer && selectedTransfer.amount}
                    </Typography>
                  </Box>
                </Grid>
              </InnerModal>

              <Grid container flexDirection={"row"}>
                <Grid item xs={5}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      flexWrap: "nowrap",
                      width: "100%",
                      padding: { xs: "0", lg: "10px" },
                      alignItems: "flex-end",
                    }}
                  >
                    <DocumentStore
                      files={[
                        ...selectedTransfer.imageFiles,
                        ...selectedTransfer.otherDocuments,
                      ]}
                      item={selectedTransfer}
                      type={"Mobile Money Transfer"}
                      closeView={handleCloseViewClicked}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Paper>
          </Paper>
        </FormContainer>
      )}
    </ResponsiveRow>
  );
}
