import ModalContainerModule from "../../../modules/modalContainer"

export default function ViewVacantUnit(props) {
    /**
     *
     * REQUIRED PROPS
     *
     * handleAccept: func()
     * handleClose: func()
     * open: boolean
     */

    return (
        <ModalContainerModule
            size="sm"
            accept={props.handleAccept}
            cancel={props.handleClose}
            openModal={props.open}
            modalTitle="Vacant Unit Information"
            acceptButtonText="OK"
        ></ModalContainerModule>
    )
}
