import {Box} from "@mui/system"

import {ResponsiveRow} from "../../../constants/layout.constants"
import {Typography} from "@mui/material"

import {ModalAcceptButton, ModalCancelButton,} from "../../../constants/component.constants"

import {Add, Remove} from "@mui/icons-material"
import {useEffect, useState} from "react"

export default function MyListFavourModalItem(props) {
    const [currentList, updateCurrentList] = useState([])
    const [addedToList, setAdded] = useState(false)

    useEffect(() => {
        const current_instance = props.item.name

        currentList.includes(current_instance) ? setAdded(true) : setAdded(false)
    }, [currentList, props.item.name])

    useEffect(() => {
        return () => {
            updateCurrentList([])
            setAdded(false)
        }
    }, [])

    return (
        <ResponsiveRow
            sx={{
                width: {xs: "100%", lg: "450px"},
                height: "auto",
                borderRadius: "20px",
                alignItems: "flex-start",
                padding: 0,
                margin: "10px 10px",
                backgroundColor: "#f7f7f7",
            }}
        >
            <ResponsiveRow
                sx={{
                    flexDirection: "column",
                    flexWrap: "nowrap",
                    width: "100%",
                    height: "100%",
                    textAlign: "start",
                    padding: {xs: "10px", lg: "10px 20px"},
                }}
            >
                <ResponsiveRow
                    sx={{
                        flexDirection: {xs: "column-reverse", lg: "row"},
                        width: "100%",
                        alignItems: "center",
                        flexWrap: {xs: "wrap", lg: "nowrap"},
                        justifyContent: "space-between",
                        padding: 0,
                    }}
                >
                    <ResponsiveRow
                        sx={{
                            width: "100%",
                            padding: 0,
                            height: "auto",
                            marginTop: {xs: "10px", lg: "auto"},
                        }}
                    >
                        <Typography
                            variant="h5"
                            sx={{
                                color: "#000",
                                fontWeight: 900,
                                marginBottom: "5px",
                                width: "100%",
                            }}
                        >
                            {props.item.label}
                        </Typography>
                        <Typography
                            variant="body2"
                            sx={{
                                color: "#037171",
                                fontWeight: 900,
                                marginBottom: "5px",
                                width: "100%",
                            }}
                        >
                            {props.item.locations}
                        </Typography>
                        <Typography
                            variant="body2"
                            sx={{
                                color: "#5f5f5f",
                                fontWeight: 900,
                                fontSize: "0.8rem",
                                marginBottom: "5px",
                                width: "100%",
                            }}
                        >
                            {props.item.price}
                        </Typography>

                        {!addedToList ? (
                            <ModalAcceptButton
                                onClick={() => {
                                    const updatedList = props.addToFavourites(props.item)
                                    updateCurrentList(updatedList)
                                }}
                                sx={{
                                    width: {xs: "100%", lg: "50px"},
                                    height: "35px",
                                    marginLeft: 0,
                                }}
                            >
                                <Add/>
                            </ModalAcceptButton>
                        ) : (
                            <ModalCancelButton
                                onClick={() => {
                                    const updatedList = props.removeFromFavourites(props.item)
                                    updateCurrentList(updatedList)
                                }}
                                sx={{
                                    width: {xs: "100%", lg: "50px"},
                                    height: "35px",
                                    marginLeft: 0,
                                }}
                            >
                                <Remove/>
                            </ModalCancelButton>
                        )}
                    </ResponsiveRow>
                    <ResponsiveRow
                        sx={{
                            flexWrap: "nowrap",
                            width: {xs: "100%", lg: "50%"},
                            height: "auto",
                            justifyContent: "flex-end",
                            alignItems: "center",
                            padding: {xs: 0, lg: "10px"},
                        }}
                    >
                        <Box
                            component="img"
                            sx={{
                                height: {xs: "100%", lg: "100px"},
                                width: {xs: "100%", lg: "100px"},
                                objectFit: "cover",
                                objectPosition: "center center",
                                borderRadius: "20px",
                            }}
                            src="https://images.unsplash.com/photo-1600047509807-ba8f99d2cdde?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=992&q=80"
                            alt="property"
                        />
                    </ResponsiveRow>
                </ResponsiveRow>
            </ResponsiveRow>
        </ResponsiveRow>
    )
}
