import { Box, Grid, Typography } from "@mui/material";
import {
  DetailsColumn,
  DetailsContainer,
  DetailsLabel,
  DetailsRow,
  DetailsText,
} from "../../constants/component.constants";
import {
  formatCurrency,
  isEmptyArray,
  isEmptyString,
  isNullUndefined,
} from "../../util/util";
import { DocumentStore } from "../documentStore";
import ModalContainerModule from "../modules/modalContainer";
import MuiDataGrid from "../modules/MuiDataGrid";
import { ResponsiveRow } from "../../constants/layout.constants";

export default function ViewBill(props) {
  let dataGridRows = [];
  if (
    !isNullUndefined(props) &&
    !isNullUndefined(props.selectedBill) &&
    !isNullUndefined(props.withholdingTaxAccountId) &&
    !isEmptyString(props.withholdingTaxAccountId) &&
    !isNullUndefined(props.selectedBill.billItems) &&
    !isEmptyArray(props.selectedBill.billItems)
  ) {
    dataGridRows = props.selectedBill.billItems.filter(
      (x) => x.accountId !== props.withholdingTaxAccountId
    );
  }

  const COLUMNS = [
    {
      field: "accountName",
      headerName: "Expense Account",
      minWidth: 500,
      flex: 1.25,
    },
    {
      field: "notes",
      headerName: "Memo",
      minWidth: 640,
      flex: 1.6,
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 400,
      valueFormatter: (row) => {
        return formatCurrency(row.value);
      },
    },
  ];

  return (
    <ModalContainerModule
      size="md"
      cancel={props.handleClose}
      openModal={props.openModal}
      modalTitle={`View Bill ${
        props.selectedBill ? "#" + props.selectedBill.referenceNumber : ""
      }`}
      cancelText="Close"
    >
      <Typography
        id="modal-modal-description"
        sx={{ fontWeight: 700, marginBottom: "0", padding: "0 0" }}
      >
        Bill Details
      </Typography>

      <ResponsiveRow
        container
        sx={{
          flexDirection: { xs: "column", lg: "row" },
          justifyContent: { xs: "center", lg: "center" },
          alignItems: "flex-start",
          width: "100%",
          height: { xs: "auto", lg: "auto" },
          margin: 0,
          paddingTop: 0,
        }}
      >
        <DetailsContainer
          item
          sx={{
            width: { xs: "100%", lg: "30%" },
            height: { xs: "auto", lg: "300px" },
            padding: { xs: 0, lg: "10px" },
            margin: "5px auto",
          }}
        >
          <DetailsColumn
            sx={{
              width: { xs: "100%", lg: "450px" },
            }}
          >
            <DetailsRow>
              <DetailsLabel id="bill-payee-label">Paid To:</DetailsLabel>
              <DetailsText>
                {props.selectedBill &&
                  props.selectedBill.payee &&
                  props.selectedBill.payee.name}
              </DetailsText>
            </DetailsRow>
            <DetailsRow>
              <DetailsLabel>Amount:</DetailsLabel>
              <DetailsText>
                {props.selectedBill && props.selectedBill.amount}
              </DetailsText>
            </DetailsRow>
            <DetailsRow>
              <DetailsLabel>Paid:</DetailsLabel>
              <DetailsText sx={{ fontSize: "1rem", width: "100%" }}>
                {props.selectedBill && props.selectedBill.paidSum}
              </DetailsText>
            </DetailsRow>
          </DetailsColumn>
          <DetailsColumn
            sx={{
              width: { xs: "100%", lg: "450px" },
            }}
          >
            <DetailsRow>
              <DetailsLabel id="bill-payee-label">Due Date:</DetailsLabel>
              <DetailsText>
                {props.selectedBill && props.selectedBill.dueDate}
              </DetailsText>
            </DetailsRow>
            <DetailsRow>
              <DetailsLabel>Costed To:</DetailsLabel>

              <DetailsText>
                {props.selectedBill && props.selectedBill.costedTo}
              </DetailsText>
            </DetailsRow>
            <DetailsRow>
              <DetailsLabel>Pending:</DetailsLabel>
              <DetailsText sx={{ fontSize: "1rem", width: "100%" }}>
                {props.selectedBill && props.selectedBill.pendingSum}
              </DetailsText>
            </DetailsRow>
          </DetailsColumn>
          <DetailsColumn
            sx={{
              width: { xs: "100%", lg: "450px" },
            }}
          >
            <DetailsRow>
              <DetailsLabel id="bill-payee-label">Memo:</DetailsLabel>
              <DetailsText>
                {props.selectedBill && props.selectedBill.description}
              </DetailsText>
            </DetailsRow>
            <DetailsRow>
              <DetailsLabel>Outstanding:</DetailsLabel>

              <DetailsText>
                {props.selectedBill && props.selectedBill.outstandingBalance}
              </DetailsText>
            </DetailsRow>
            <DetailsRow>
              <DetailsLabel>Status:</DetailsLabel>
              <DetailsText>
                {props.selectedBill &&
                  props.selectedBill.status &&
                  props.selectedBill.status.name}
              </DetailsText>
            </DetailsRow>
            <DetailsRow>
              <DetailsLabel>Vendor Ref No.:</DetailsLabel>
              <DetailsText>
                {props.selectedBill &&
                    props.selectedBill.vendorRefNo}
              </DetailsText>
            </DetailsRow>
            {
                props.selectedBill && props.selectedBill.hasWithholdingTax &&
                <DetailsRow>
                  <DetailsLabel>Withholding Tax:</DetailsLabel>
                  <DetailsText>
                    {props.selectedBill && props.selectedBill.withholdingTaxItem && props.selectedBill.withholdingTaxItem.amount}
                  </DetailsText>
                </DetailsRow>
            }
          </DetailsColumn>
        </DetailsContainer>

        <Grid
          item
          sx={{
            display: "flex",
            flexDirection: { xs: "column", lg: "row" },
            justifyContent: { xs: "center", lg: "flex-start" },
            alignItems: "flex-start",
            height: "auto",
            padding: "0",
            margin: 0,
            width: { xs: "100%", lg: "70%" },
          }}
        >
          {props.selectedBill && (
            <DocumentStore
              files={[
                ...props.selectedBill.imageFiles,
                ...props.selectedBill.otherDocuments,
              ]}
              item={props.selectedBill}
              type={"Bill"}
              disableShadow
              height="100%"
              maxWidth="100%"
            />
          )}
        </Grid>
      </ResponsiveRow>

      <Typography id="modal-modal-description" sx={{ mt: 2, fontWeight: 700 }}>
        Bill Items
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", lg: "row" },
          maxHeight: "30vh",
          justifyContent: { xs: "space-between", lg: "center" },
          padding: "10px",
          width: "100%",
        }}
      >
        <MuiDataGrid
          alt={true}
          loading={false}
          noBoxShadow={true}
          dataGridColumns={COLUMNS}
          noSelection={true}
          dataGridRows={dataGridRows}
          totalRows={dataGridRows.length}
          height="300px"
        />
      </Box>
    </ModalContainerModule>
  );
}
