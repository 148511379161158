import {Grid, MenuItem, TextField, Typography} from "@mui/material"
import {useEffect, useState} from "react"

import ApplicationItem from "../../../components/cards/listings/applicationItem"
import {ResponsiveRow} from "../../../constants/layout.constants"
import {listApplications} from "../../../services/listingsServices/listing.service"
import ViewApplication from "../../../components/cards/listings/viewApplication"

export default function ListingApplications() {
    const [applications, setApplications] = useState([])
    const [applicationToView, setApplicationToView] = useState(null)
    const [viewModal, setViewModal] = useState(false)
    const [listing, setListing] = useState('')
    const [sortBy, setSortBy] = useState('')
    const [selectedStatus, setSelectedStatus] = useState('')

    useEffect(() => {
        listApplications(listing, selectedStatus)
            .then((data) => {
                setApplications(data)
                console.log("Applications are: ", data)
            })
            .catch((error) => {
                console.log("listApplications error", error)
            })

    }, [listing, selectedStatus])

    function viewApplicationItem(item) {
        setViewModal(true)
        setApplicationToView(item)
    }

    function handleCancel() {
        setViewModal(false)
        setApplicationToView(null)
    }

    function handleSortBy(event) {
        let value = event.target.value
        setSortBy(value)
        (value === "listingAsc" || value === "listingDesc" || value === "appNameAsc" || value === "appNameDesc") ? sortByListingName(value) : sortByDate(value)
    }

    function sortByListingName(value) {
        if (value === "listingAsc") {
            applications.sort((a, b) => {
                if (a.listing.propertyName < b.listing.propertyName) {
                    return -1
                }
                if (a.listing.propertyName > b.listing.propertyName) {
                    return 1
                }
                return 0
            })
        } else if (value === "listingDesc") {
            applications.sort((a, b) => {
                if (b.listing.propertyName < a.listing.propertyName) {
                    return -1
                }
                if (b.listing.propertyName > a.listing.propertyName) {
                    return 1
                }
                return 0
            })
        } else if (value === "appNameAsc") {
            applications.sort((a, b) => {
                if (a.applicant.name.toLowerCase() < b.applicant.name.toLowerCase()) {
                    return -1
                }
                if (a.applicant.name.toLowerCase() > b.applicant.name.toLowerCase()) {
                    return 1
                }
                return 0
            })
        } else if (value === "appNameDesc") {
            applications.sort((a, b) => {
                if (b.applicant.name.toLowerCase() < a.applicant.name.toLowerCase()) {
                    return -1
                }
                if (b.applicant.name.toLowerCase() > a.applicant.name.toLowerCase()) {
                    return 1
                }
                return 0
            })
        }
    }

    function sortByDate(value) {
        applications.sort((a, b) => {
            let x = new Date(a.dateCreated),
                y = new Date(b.dateCreated)
            return value === "dateAsc" ? x - y : y - x
        })
    }

    function filterByStatus(event) {
        let value = event.target.value
        value === "PEN" ? setSelectedStatus("PEN") : value === "APP" ? setSelectedStatus("APP") : value === "REJ" ? setSelectedStatus("REJ") : setSelectedStatus("NEW")
    }

    return (
        <ResponsiveRow
            sx={{flexDirection: "column", width: "100%", alignItems: "center"}}
        >
            <ResponsiveRow
                sx={{
                    width: {xs: "100%", lg: "80%"},
                    marginTop: "100px",
                    padding: "10px auto",
                }}
            >
                <Typography variant="h4" sx={{width: "100%", textAlign: "start"}}>
                    Listing Applications
                </Typography>
            </ResponsiveRow>
            <ResponsiveRow
                sx={{
                    marginTop: "10px",
                    width: {xs: "100%", lg: "80%"},
                    padding: 0,
                    justifyContent: {xs: "flex-start", lg: "space-between"},
                    alignItems: "center",
                    flexWrap: {xs: "wrap", lg: "nowrap"},
                }}
            >
                <Grid
                    item
                    sx={{
                        width: {xs: "100%", lg: "50%"},
                        textAlign: "start",
                    }}
                >
                    <TextField
                        variant="outlined"
                        sx={{
                            width: {xs: "100%", lg: "400px"},
                            backgroundColor: "#fff",
                            borderRadius: "10px",
                            textAlign: "start",
                            margin: "5px 0",
                        }}
                        label="Search By Listing Name"
                        value={listing}
                        onChange={(event) => {
                            let value = event.target.value
                            setListing(value)
                        }}
                    />
                </Grid>

                <Grid
                    item
                    sx={{
                        flexDisplay: "row",
                        justifyContent: {xs: "center", lg: "flex-end"},
                        width: {xs: "100%", lg: "50%"},
                        textAlign: "end",
                    }}
                >
                    <TextField
                        variant="outlined"
                        sx={{
                            width: {xs: "100%", lg: "200px"},
                            backgroundColor: "#fff",
                            borderRadius: "10px",
                            textAlign: "start",
                            margin: "5px 0",
                        }}
                        label="Sort By"
                        select
                        value={sortBy}
                        onChange={handleSortBy}
                    >
                        <MenuItem value={"appNameAsc"}>Applicant's Name (Asc)</MenuItem>
                        <MenuItem value={"appNameDesc"}>Applicant's Name (Desc)</MenuItem>
                        <MenuItem value={"listingAsc"}>Listing Name (Asc)</MenuItem>
                        <MenuItem value={"listingDesc"}>Listing Name (Desc)</MenuItem>
                        <MenuItem value={"dateAsc"}>Date (Asc)</MenuItem>
                        <MenuItem value={"dateDesc"}>Date (Desc)</MenuItem>
                    </TextField>

                    <TextField
                        variant="outlined"
                        sx={{
                            width: {xs: "100%", lg: "200px"},
                            backgroundColor: "#fff",
                            borderRadius: "10px",
                            textAlign: "start",
                            margin: {xs: "5px 0", lg: "5px"},
                        }}
                        label="Filter By"
                        select
                        value={selectedStatus}
                        onChange={(event) => filterByStatus(event)}
                    >
                        <MenuItem value={"NEW"}>Not Opened</MenuItem>
                        <MenuItem value={"PEN"}>Pending</MenuItem>
                        <MenuItem value={"APP"}>Approved</MenuItem>
                        <MenuItem value={"REJ"}>Rejected</MenuItem>
                    </TextField>
                </Grid>
            </ResponsiveRow>
            <ResponsiveRow
                sx={{
                    justifyContent: "center",
                    alignItems: "center",
                    height: "fit-content",
                    width: {xs: "100%", lg: "80%"},
                    padding: "0",
                    marginTop: "20px",
                }}
            >
                {applications && applications.length > 0 && applications.map((item, i) => {
                    return (
                        <ApplicationItem
                            key={i}
                            applicationItem={item}
                            viewApplicationItem={viewApplicationItem}
                        />
                    )
                })
                }
            </ResponsiveRow>

            {viewModal &&
                <ViewApplication
                    applicationItem={applicationToView}
                    openViewModal={viewModal}
                    closeViewModal={handleCancel}
                />
            }
        </ResponsiveRow>
    )
}
