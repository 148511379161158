import { Box, Grid, Typography } from "@mui/material";
import nav_logo from "../images/main-logo.png";
import { ResponsiveRow } from "../constants/layout.constants";
import { ModalAcceptButton } from "../constants/component.constants";
import SignOnPropertyDetails from "../components/modules/signOnPropertyDetails";

export default function SelfSignOn() {
  return (
    <Grid
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        width: "100%",
      }}
      container
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          height: "80px",
          backgroundColor: "#fff",
          justifyContent: "flex-start",
          alignItems: "center",
          boxShadow: "0 4px 12px 0 rgba(0,0,0,0.25)",
        }}
      >
        <Box
          component="img"
          src={nav_logo}
          alt=""
          sx={{ margin: "10px 20px", width: "150px", height: "auto" }}
        />
      </Box>
      <Grid
        sx={{
          margin: "10px 40px",
          display: "flex",
          flexDirection: "column",
          textAlign: "start",
        }}
      >
        <Typography
          sx={{
            marginTop: "20px",
            fontSize: "2rem",
            fontWeight: 700,
            color: "#037171",
          }}
        >
          Self Sign On
        </Typography>
        <Box
          sx={{
            padding: "20px",
            display: "flex",
            flexDirection: "column",
            textAlign: "start",
          }}
        >
          <Typography
            variant="h5"
            sx={{ marginBottom: "10px", fontWeight: 700 }}
          >
            Welcome to Smart Kodi and thank you for choosing to start managing
            your property with us.
          </Typography>

          <Typography variant="body1">
            Property managers and owners, please fill in the following forms
            required to start working with us. Feel free to take as much time as
            you need. Every step completed will be saved and stored for you to
            resume when ready.
          </Typography>
        </Box>
      </Grid>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          width: "100%",
          marginBottom: "40px",
        }}
      >
        <ResponsiveRow
          container
          sx={{
            flexDirection: "column",
            backgroundColor: "#fff",
            width: { xs: "90%", lg: "80%" },
            // minHeight: { xs: "100%", lg: "700px" },
            height: "100%",
            marginTop: "20px",
            borderRadius: "20px",
            padding: "10px",
          }}
        >
          <SignOnPropertyDetails />
          <ResponsiveRow
            sx={{
              justifyContent: { xs: "center", lg: "flex-end" },
              alignItems: "flex-end",
              width: "100%",
              height: "100%",
            }}
          >
            <ModalAcceptButton sx={{ width: { xs: "100%", lg: "200px" } }}>
              Save & Finish Later
            </ModalAcceptButton>{" "}
            <ModalAcceptButton sx={{ width: { xs: "100%", lg: "200px" } }}>
              Save & Continue
            </ModalAcceptButton>
          </ResponsiveRow>
        </ResponsiveRow>
      </Box>
    </Grid>
  );
}
