import {Grid, MenuItem, TextField, Typography} from "@mui/material"
import {useEffect, useState} from "react"
import {ModalAcceptButton, ModalCancelButton,} from "../../../constants/component.constants"
import {ModalContainer, ModalInnerContainer, ResponsiveRow,} from "../../../constants/layout.constants"
import {isEmptyString, isNullUndefined} from "../../../util/util"

import {setMessage} from "../../../actions/message"
import {getIdTypes} from "../../../services/list.service"
import {saveListingApplication} from "../../../services/listingsServices/listing.service"
import {useDispatch} from "react-redux";

export default function ListingApplicationModal(props) {
    const [firstName, setFirstName] = useState("")
    const [middleName, setMiddleName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [phoneNo, setPhoneNo] = useState("")
    const [officeTelNo, setOfficeTelNo] = useState("")
    const [idNo, setIdNo] = useState("")
    const [idTypes, setIdTypes] = useState([])
    const [idType, setIdType] = useState("")
    const [note, setNote] = useState("")
    const dispatch = useDispatch()

    useEffect(() => {
        getIdTypes()
            .then((idTypes) => setIdTypes(idTypes))
            .catch((error) => {
                // console.log("getIdTypes Error: ", error)
            })
    }, [])

    const validateFormData = () => {
        if (isEmptyString(firstName) || isNullUndefined(firstName)) {
            dispatch(
                setMessage({
                    type: "warning",
                    message: "First Name is required.",
                })
            )
            return false
        }

        if (isEmptyString(lastName) || isNullUndefined(lastName)) {
            dispatch(
                setMessage({
                    type: "warning",
                    message: "Last Name is required.",
                })
            )
            return false
        }

        if (isEmptyString(email) || isNullUndefined(email)) {
            dispatch(
                setMessage({
                    type: "warning",
                    message: "Email is required.",
                })
            )
            return false
        }

        if (isEmptyString(phoneNo) || isNullUndefined(phoneNo)) {
            dispatch(
                setMessage({
                    type: "warning",
                    message: "Phone Number is required.",
                })
            )
            return false
        }

        if (isEmptyString(idNo) || isNullUndefined(idNo)) {
            dispatch(
                setMessage({
                    type: "warning",
                    message: "ID Number is required.",
                })
            )
            return false
        }

        if (isEmptyString(idType) || isNullUndefined(idType)) {
            dispatch(
                setMessage({
                    type: "warning",
                    message: "ID Type is required.",
                })
            )
            return false
        }

        if (isEmptyString(note) || isNullUndefined(note)) {
            dispatch(
                setMessage({
                    type: "warning",
                    message: "A brief description for the application is required.",
                })
            )
            return false
        }

        return true
    }

    const handleSubmit = () => {
        let sendData = {
            id: null,
            listingId: props.listing.id,
            firstName: firstName,
            middleName: middleName,
            lastName: lastName,
            email: email,
            phoneNumber: phoneNo,
            officeTelNo: officeTelNo,
            idNo: idNo,
            idType: idType,
            note: note
        }
        // console.log("sendData", sendData)
        validateFormData() && saveListingApplication(sendData)
            .then((response) => {
                dispatch(
                    setMessage({
                        type: response.type,
                        message: response.message,
                    })
                )
                closeModal()
            })
            .catch((error) => {
                // console.log("saveListingApplication error ", error)
            })

    }

    const closeModal = () => {
        props.closeModal()
        setFirstName('')
        setMiddleName('')
        setLastName('')
        setEmail('')
        setPhoneNo('')
        setOfficeTelNo('')
        setIdType('')
        setIdNo('')
        setNote('')
    }

    return (
        <ModalContainer open={props.openModal} onClose={props.closeModal}>
            <ModalInnerContainer
                sx={{
                    maxHeight: {xs: "50hv", lg: "70vh"},
                    width: {xs: "80%", lg: "70%"},
                }}
            >
                <Typography variant="body1" sx={{color: "#037171", fontWeight: 600}}>
                    Start Application
                </Typography>
                <Typography
                    variant="h2"
                    sx={{marginBottom: "20px", marginTop: "10px", fontWeight: 900}}
                >
                    {props.listing.propertyName}
                </Typography>

                <ResponsiveRow
                    sx={{
                        width: "100%",
                        justifyContent: {xs: "center", lg: "space-between"},
                        marginTop: "20px",
                    }}
                >
                    <Grid
                        item
                        sx={{width: {xs: "100%", lg: "33.3%"}, marginBottom: "10px"}}
                    >
                        <TextField
                            variant="outlined"
                            sx={{
                                width: {xs: "100%", lg: "90%"},
                                backgroundColor: "#efefef",
                                borderRadius: "10px",
                            }}
                            label="First Name"
                            value={firstName}
                            onChange={(event) => {
                                let value = event.target.value
                                setFirstName(value)
                            }}
                        />
                    </Grid>{" "}
                    <Grid
                        item
                        sx={{width: {xs: "100%", lg: "33.3%"}, marginBottom: "10px"}}
                    >
                        <TextField
                            variant="outlined"
                            sx={{
                                width: {xs: "100%", lg: "90%"},
                                backgroundColor: "#efefef",
                                borderRadius: "10px",
                            }}
                            label="Middle Name (Optional)"
                            value={middleName}
                            onChange={(event) => {
                                let value = event.target.value
                                setMiddleName(value)
                            }}
                        />
                    </Grid>{" "}
                    <Grid
                        item
                        sx={{width: {xs: "100%", lg: "33.3%"}, marginBottom: "10px"}}
                    >
                        <TextField
                            variant="outlined"
                            sx={{
                                width: {xs: "100%", lg: "90%"},
                                backgroundColor: "#efefef",
                                borderRadius: "10px",
                            }}
                            label="Last Name"
                            value={lastName}
                            onChange={(event) => {
                                let value = event.target.value
                                setLastName(value)
                            }}
                        />
                    </Grid>{" "}
                    <Grid
                        item
                        sx={{width: {xs: "100%", lg: "33.3%"}, marginBottom: "10px"}}
                    >
                        <TextField
                            variant="outlined"
                            sx={{
                                width: {xs: "100%", lg: "90%"},
                                backgroundColor: "#efefef",
                                borderRadius: "10px",
                            }}
                            label="Email"
                            type="email"
                            value={email}
                            onChange={(event) => {
                                let value = event.target.value
                                setEmail(value)
                            }}
                        />
                    </Grid>{" "}
                    <Grid
                        item
                        sx={{width: {xs: "100%", lg: "33.3%"}, marginBottom: "10px"}}
                    >
                        <TextField
                            variant="outlined"
                            sx={{
                                width: {xs: "100%", lg: "90%"},
                                backgroundColor: "#efefef",
                                borderRadius: "10px",
                            }}
                            label="Phone Number"
                            type="tel"
                            value={phoneNo}
                            onChange={(event) => {
                                let value = event.target.value
                                setPhoneNo(value)
                            }}
                        />
                    </Grid>{" "}
                    <Grid
                        item
                        sx={{width: {xs: "100%", lg: "33.3%"}, marginBottom: "10px"}}
                    >
                        <TextField
                            variant="outlined"
                            sx={{
                                width: {xs: "100%", lg: "90%"},
                                backgroundColor: "#efefef",
                                borderRadius: "10px",
                            }}
                            label="Office Tel (Optional)"
                            type="tel"
                            value={officeTelNo}
                            onChange={(event) => {
                                let value = event.target.value
                                setOfficeTelNo(value)
                            }}
                        />
                    </Grid>{" "}
                    <Grid
                        item
                        sx={{width: {xs: "100%", lg: "33.3%"}, marginBottom: "10px"}}
                    >
                        <TextField
                            variant="outlined"
                            sx={{
                                width: {xs: "100%", lg: "90%"},
                                backgroundColor: "#efefef",
                                borderRadius: "10px",
                            }}
                            select
                            label="ID Type"
                            value={idType}
                            onChange={(event) => {
                                let value = event.target.value
                                setIdType(value)
                            }}
                        >
                            {idTypes && idTypes.length > 0 && idTypes.map(function (idType, i) {
                                return (
                                    <MenuItem key={i} value={idType.value}>
                                        {idType.label}
                                    </MenuItem>
                                )
                            }, this)}
                        </TextField>
                    </Grid>{" "}
                    <Grid
                        item
                        sx={{width: {xs: "100%", lg: "33.3%"}, marginBottom: "10px"}}
                    >
                        <TextField
                            variant="outlined"
                            sx={{
                                width: {xs: "100%", lg: "90%"},
                                backgroundColor: "#efefef",
                                borderRadius: "10px",
                            }}
                            label="ID Number"
                            value={idNo}
                            onChange={(event) => {
                                let value = event.target.value
                                setIdNo(value)
                            }}
                        />
                    </Grid>{" "}
                    <Grid
                        item
                        sx={{width: {xs: "100%", lg: "33.3%"}, marginBottom: "10px"}}
                    >
                        <TextField
                            variant="outlined"
                            sx={{
                                width: {xs: "100%", lg: "90%"},
                                backgroundColor: "#efefef",
                                borderRadius: "10px",
                            }}
                            label="Listing"
                            disabled
                            value={props.listing.propertyName}
                        />
                    </Grid>{" "}
                    <Grid
                        item
                        sx={{width: {xs: "100%", lg: "66.6%"}, marginBottom: "10px"}}
                    >
                        <TextField
                            variant="outlined"
                            sx={{
                                width: "100%",
                                backgroundColor: "#efefef",
                                borderRadius: "10px",
                            }}
                            label="Notes"
                            multiline
                            rows="5"
                            value={note}
                            onChange={(event) => {
                                let value = event.target.value
                                setNote(value)
                            }}
                        />
                    </Grid>
                </ResponsiveRow>
                <ResponsiveRow
                    sx={{
                        width: "100%",
                        justifyContent: {xs: "center", lg: "flex-end"},
                    }}
                >
                    <ModalAcceptButton onClick={handleSubmit} sx={{width: {xs: "100%", lg: "200px"}}}>
                        Submit
                    </ModalAcceptButton>
                    <ModalCancelButton
                        onClick={closeModal}
                        sx={{width: {xs: "100%", lg: "200px"}}}
                    >
                        Cancel
                    </ModalCancelButton>
                </ResponsiveRow>
            </ModalInnerContainer>
        </ModalContainer>
    )
}
