/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import {
  Alert,
  AlertTitle,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import {
  formatCurrency,
  getRefNumber,
  isAfterToday,
  isEmptyArray,
  isEmptyString,
  isNullUndefined,
  isNumeric,
  isValidDate,
  tryParseFloat,
} from "../../util/util";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/en-gb";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { getChargeAccounts, getVATAccount } from "../../services/list.service";
import { Cancel } from "@mui/icons-material";
import { chargeItemList, saveDebitNote } from "../../services/shared.service";
import { issueResponseMessage, issueWarnMessage } from "../../actions/message";
import { ResponsiveRow } from "../../constants/layout.constants";
import ModalContainerModule from "../modules/modalContainer";
import { ModalAcceptButton } from "../../constants/component.constants";
import dayjs from "dayjs";
import { saveClientDebitNote } from "../../services/subscription.service";

export default function DebitNote(props) {
  const [debitNoteDate, setDebitNoteDate] = useState(new Date());
  const [debitNoteMemo, setDebitNoteMemo] = useState(null);
  const [items, setItems] = useState([]);
  const [debitAccounts, setDebitAccounts] = useState([]);
  const [debitItemsRows, setDebitItemsRows] = useState(null);
  const [debitItemsTotal, setDebitItemsTotal] = useState(0);
  const [vatAccount, setVATAccount] = useState(null);
  const [leaseIds, setLeaseIds] = useState([]); //used when issuing a charge to multiple leases
  const [ownershipIds, setOwnershipIds] = useState([]); //used when issuing a charge to multiple ownerships
  const detailItemsHash = useRef({});
  // Remove Security Deposit Liability - Current Liability,
  // Utility Deposit Liability - Current Liability,
  // Utility Deposit Account - Current Asset (Cash) and
  // Security Deposit Account - Current Asset (Cash) as they are not required since deposit is handled on JE, termination and receiving payment
  // Adding, Reducing or increasing deposit should be done on lease edit/create
  const accountsToExclude = [
    "Utility Deposit Account - Current Asset (Cash)",
    "Security Deposit Account - Current Asset (Cash)",
    "Security Deposit Liability - Current Liability",
    "Utility Deposit Liability - Current Liability",
  ];
  useEffect(() => {
    if (!isNullUndefined(props.leaseIds)) {
      setLeaseIds(props.leaseIds);
    }
    if (!isNullUndefined(props.ownershipIds)) {
      setOwnershipIds(props.ownershipIds);
    }
    if (isNullUndefined(props.subscriptionId)) {
      getChargeAccounts().then((debitAccounts) => {
        setDebitAccounts(debitAccounts.filter((d) => !accountsToExclude.includes(d.label)))
      });

      getVATAccount().then((vatAccount) => setVATAccount(vatAccount));
    }
    if (!isNullUndefined(props.voiditem)) {
      //get the full details of the invoice or charge being voided
      chargeItemList(props.voiditem.type, props.voiditem.id).then((data) => {
        setItems(data);
      });
    } else {
      if (items.length === 0) {
        handleChargeItemAdd();
      }
    }
  }, [props]);

  useEffect(() => {
    let total = 0;
    let rows =
      items &&
      items.length > 0 &&
      items.map(function (creditItem, i) {
        let amount = 0;
        if (isNullUndefined(props.subscriptionId)) {
          amount = tryParseFloat(creditItem.amount, -1);
        } else {
          amount = tryParseFloat(creditItem.total, -1);
        }
        if (amount > 0) {
          total = total + amount;
        }
        return (
          <TableRow key={i}>
            {isNullUndefined(props.subscriptionId) && (
              <TableCell>
                <Select
                  sx={{ width: "90%" }}
                  id={"accountId" + i}
                  name={"accountId" + i}
                  isrequired={true}
                  disabled={
                    props.voiditem ||
                    (items && items[i] && !items[i].displayVATRadio)
                  }
                  value={items && items[i] && items[i].accountId}
                  onChange={(event) => accountChanged(event, i)}
                >
                  {debitAccounts && debitAccounts.length > 0 ? (
                    debitAccounts.map((debitAccount, i) => {
                      return (
                        <MenuItem
                          key={debitAccount.value}
                          value={debitAccount.value}
                        >
                          {debitAccount.label}
                        </MenuItem>
                      );
                    })
                  ) : (
                    <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
                  )}
                </Select>
              </TableCell>
            )}

            <TableCell>
              <TextField
                sx={{ width: "90%" }}
                id={"notes" + i}
                name={"notes" + i}
                isrequired={true}
                disabled={
                  props.voiditem ||
                  (items && items[i] && !items[i].displayVATRadio)
                }
                value={items && items[i] && items[i].notes}
                onChange={(event) => notesChanged(event, i)}
              />
            </TableCell>
            <TableCell>
              <TextField
                sx={{ width: "90%" }}
                id={"amount" + i}
                name={"amount" + i}
                isrequired={true}
                disabled={
                  props.voiditem ||
                  (items && items[i] && !items[i].displayVATRadio)
                }
                value={items && items[i] && items[i].amount}
                onChange={(event) => amountChanged(event, i)}
              />
            </TableCell>

            {!isNullUndefined(props.subscriptionId) && (
              <TableCell>
                <TextField
                  sx={{ width: "90%" }}
                  id={"quantity" + i}
                  name={"quantity" + i}
                  isrequired={true}
                  disabled={
                    !isNullUndefined(props.voiditem) ||
                    (items && items[i] && !items[i].displayVATRadio)
                  }
                  type={"number"}
                  value={items && items[i] && items[i].quantity}
                  onChange={(event) => quantityChanged(event, i)}
                />
              </TableCell>
            )}

            {!isNullUndefined(props.subscriptionId) && (
              <TableCell>
                <TextField
                  sx={{ width: "90%" }}
                  id={"total" + i}
                  name={"total" + i}
                  isrequired={true}
                  disabled={true}
                  value={items && items[i] && items[i].total}
                />
              </TableCell>
            )}

            <TableCell>
              {items && items[i] && items[i].displayVATRadio && (
                <Checkbox
                  checked={items && items[i] && items[i].applyVAT}
                  onChange={(event) => applyVATToggle(event, i)}
                  inputProps={{ "aria-label": "controlled" }}
                />
              )}
            </TableCell>
            <TableCell>
              {items && items[i] && items[i].displayVATRadio && (
                <Button
                  variant="contained"
                  type="submit"
                  sx={{ marginLeft: "10px" }}
                  onClick={(event) => handleDebitItemRemove(i)}
                  color="error"
                >
                  <Cancel />
                </Button>
              )}
            </TableCell>
          </TableRow>
        );
      });
    setDebitItemsRows(rows);
    setDebitItemsTotal(total);
  }, [items, props, debitAccounts]);

  function handleChargeItemAdd() {
    if (isNullUndefined(props.subscriptionId)) {
      setItems([
        ...items,
        {
          accountId: "",
          amount: null,
          notes: null,
          applyVAT: false,
          displayVATRadio: true,
        },
      ]);
    } else {
      setItems([
        ...items,
        {
          amount: null,
          notes: null,
          quantity: null,
          total: null,
          applyVAT: false,
          displayVATRadio: true,
        },
      ]);
    }
  }

  function validateDebitForm(note) {
    if (isNullUndefined(note)) {
      issueWarnMessage(
        "Something went wrong with charge data, please refresh the page"
      );
      return false;
    }

    if (!isValidDate(note.date)) {
      issueWarnMessage("Select a valid charge date");
      return false;
    }
    if (isAfterToday(note.date)) {
      issueWarnMessage("The charge date cannot be in the future");
      return false;
    }
    if (
      !isNullUndefined(note.memo) &&
      !isEmptyString(note.memo) &&
      note.memo.length > 400
    ) {
      issueWarnMessage("The charge memo cannot be greater than 400 characters");
      return false;
    }
    if (isEmptyArray(note.items)) {
      issueWarnMessage("Select a valid debit note date");
      return false;
    }

    if (isNullUndefined(props.voiditem) && note?.memo?.toLowerCase().includes("charge to void")) {
      issueWarnMessage("'Charge to void' is a reserved memo. Please use the correct voiding procedure.");
      return false;
    }

    for (let i = 0; i < note.items.length; i++) {
      let hasValidItem = isValidChargeItem(i + 1, note.items[i]);
      if (hasValidItem !== true) {
        return;
      }
    }
    return true;
  }

  function isValidChargeItem(pos, item) {
    if (
      isNullUndefined(props.subscriptionId) &&
      isEmptyString(item.accountId)
    ) {
      issueWarnMessage("Charge item " + pos + " must have an account selected");
      return false;
    }
    if (isNullUndefined(item.notes) || isEmptyString(item.notes)) {
      issueWarnMessage("Charge item " + pos + " must have a note");
      return false;
    }
    if (item.notes.length > 400) {
      issueWarnMessage(
        "Charge Item " + pos + " note must be less than 400 characters"
      );
      return false;
    }

    let amount = tryParseFloat(item.amount, -1);
    if (!(isNumeric(amount) && amount > 0)) {
      issueWarnMessage(
        "Charge item " +
          pos +
          " must have a valid amount (greater than 0) entered"
      );
      return false;
    }

    if (!isNullUndefined(props.subscriptionId)) {
      let quantity = tryParseFloat(item.quantity, -1);
      if (!(isNumeric(quantity) && quantity > 0)) {
        issueWarnMessage(
          "Charge item " +
            pos +
            " must have a valid quantity (greater than 0) entered"
        );
        return false;
      }
    }
    return true;
  }

  function submit() {
    let refNum = getRefNumber();

    const prependedMemo = props.voiditem
      ? "Charge to void " +
        props.voiditem.type +
        " #" +
        props.voiditem.referenceNumber +
        (debitNoteMemo ? ": " + debitNoteMemo : "")
      : debitNoteMemo;

    let data = {
      id: null,
      referenceNumber: refNum,
      date: debitNoteDate,
      memo: prependedMemo,
      titleId: props.ownershipid,
      subscriptionId: props.subscriptionId,
      leaseId: props.leaseid,
      meterBillingId: props.meterbillingid,
      isVoid: !isNullUndefined(props.voiditem),
      leaseIds: leaseIds,
      titleIds: ownershipIds,
      isBulkCharges: props?.bulkChargeAction ?? false,
      items: items,
    };

    if (validateDebitForm(data)) {
      if (isNullUndefined(props.subscriptionId)) {
        saveDebitNote(data).then((response) => {
          issueResponseMessage(response);
          props.onClose();
        });
      } else {
        saveClientDebitNote(data).then((response) => {
          issueResponseMessage(response);
          props.onClose();
        });
      }
    }
  }

  function amountChanged(event, index) {
    let newArr = [...items];
    newArr[index].amount = event.target.value;
    if (!isNullUndefined(props.subscriptionId)) {
      newArr[index].total = newArr[index].amount * newArr[index].quantity;
    }
    newArr = findAndUpdateDetailItemVAT(newArr[index], index, newArr);
    setItems(newArr);
  }

  function quantityChanged(event, index) {
    let newArr = [...items];
    newArr[index].quantity = event.target.value;
    if (!isNullUndefined(props.subscriptionId)) {
      newArr[index].total = newArr[index].amount * newArr[index].quantity;
    }
    newArr = findAndUpdateDetailItemVAT(newArr[index], index, newArr);
    setItems(newArr);
  }

  const findAndUpdateDetailItemVAT = (detailItem, index, items) => {
    let vatAmount = getVATAmount(detailItem.amount);
    let vatItemIndex = (detailItemsHash.current)[index];
    let vatItem = items[vatItemIndex];
    
    if (vatItem) {
      vatItem.notes = "VAT (16%) - " + detailItem.notes
      vatItem.amount = vatAmount
      items[vatItemIndex] = vatItem
    }
    return items
  };

  function accountChanged(event, index) {
    let newArr = [...items];
    newArr[index].accountId = event.target.value;
    setItems(newArr);
  }

  const handleDebitItemRemove = (index) => {
    if (items.length >= 1) {
      if((detailItemsHash.current)[index]) {
        setItems(
          items.filter(
            (item, i) =>
              i !== (detailItemsHash.current)[index] && i !== index
          )
        );
        const newHash = { ...detailItemsHash.current };
        delete newHash[index];
        Object.entries(newHash).forEach(([key, value]) => {
          let newKey = key
          let newValue = key
          if (parseInt(key) > index || parseInt(value) > index) {
            delete newHash[key];
          }
          if (parseInt(key) > index || parseInt(value) > index) {
            newKey = Math.max((parseInt(key) - 2), 0)
          }
          if(parseInt(value) > index) {
            newValue = Math.max((parseInt(value) - 2), 0);
          }
          newHash[newKey] = newValue;
        });

        detailItemsHash.current = newHash
      } else {
        setItems(items.filter((item, i) => i !== index));
        const newHash = { ...detailItemsHash.current };
        delete newHash[index];
        Object.entries(newHash).forEach(([key, value]) => {
          let newKey = key
          let newValue = key
          if (parseInt(key) > index || parseInt(value) > index) {
            delete newHash[key];
          }
          if (parseInt(key) > index) {
            newKey = Math.max((parseInt(key) - 1), 0)
          }
          if(parseInt(value) > index) {
            newValue = Math.max((parseInt(value) - 1), 0);
          }
          newHash[newKey] = newValue;
        });
        detailItemsHash.current = newHash
      }
    } else {
      issueWarnMessage("There must be at least one charge");
    }
  };

  function notesChanged(event, index) {
    let newArr = [...items];
    newArr[index].notes = event.target.value;
    newArr = findAndUpdateDetailItemVAT(newArr[index], index, newArr);
    setItems(newArr);
  }

  function getVATAmount(amount) {
    if (isNaN(amount)) {
      issueWarnMessage("Only numeric amounts are allowed for VAT calculation");
    } else {
      return (amount * 0.16).toFixed(2);
    }
  }

  function findAndRemoveChargeVAT(index) {
    let vatItemIndex = (detailItemsHash.current)[index];
    if(vatItemIndex) {
      setItems(items.filter((item, i) => i !== vatItemIndex));
      const newHash = { ...detailItemsHash.current };
      delete newHash[index];
      Object.entries(newHash).forEach(([key, value]) => {
        if (parseInt(key) > index) {
          newHash[parseInt(key) - 1] = value - 1;
          delete newHash[key];
        }
      });

      detailItemsHash.current = newHash
    }
  }

  function applyVATToggle(event, index) {
    let detailItem = [...items][index];
    if (isNullUndefined(props.subscriptionId)) {
      if (isNullUndefined(detailItem.amount) || detailItem.amount === "0") {
        issueWarnMessage("You must enter an amount before applying VAT");
        return;
      }
    } else {
      if (isNullUndefined(detailItem.total) || detailItem.total === "0") {
        issueWarnMessage(
          "You must enter an amount and quantity before applying VAT"
        );
        return;
      }
    }

    if (event.target.checked) {
      detailItem.applyVAT = true;
      const updatedItems = [...items];
      if (isNullUndefined(props.subscriptionId)) {
        updatedItems.push(
          {
            accountId: vatAccount.id,
            notes: "VAT (16%) - " + detailItem.notes,
            amount: getVATAmount(detailItem.amount),
            applyVAT: false,
            displayVATRadio: false,
          },
        );
      } else {
        updatedItems.push({
            notes: "VAT (16%) - " + detailItem.notes,
            amount: getVATAmount(detailItem.total),
            quantity: 1,
            total: getVATAmount(detailItem.total),
            applyVAT: false,
            displayVATRadio: false,
          },
        );
      }
      detailItemsHash.current[index] = updatedItems.length - 1
      setItems(updatedItems);
    } else {
      findAndRemoveChargeVAT(index);
      detailItem.applyVAT = false;
    }
  }

  return (
    <>
      <ModalContainerModule
        // size="specialCreateChargeModal"
        size="md"
        accept={submit}
        cancel={props.onClose}
        openModal={props.open}
        modalTitle="Create a Charge"
        acceptButtonText="Save"
      >
        <ResponsiveRow
          container
          sx={{
            justifyContent: { xs: "center", lg: "space-between" },
            height: "auto",
          }}
        >
          <FormControl sx={{ width: { xs: "100%", lg: "50%" } }}>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale="en-gb"
            >
              <DatePicker
                label="Charge Date"
                id={"debitNoteDate"}
                name={"debitNoteDate"}
                sx={{ width: { xs: "100%", lg: "90%" } }}
                disabled={!isNullUndefined(props.voiditem)}
                value={dayjs(debitNoteDate)}
                isrequired={true}
                onChange={(newDate) => setDebitNoteDate(new Date(newDate))}
              />
            </LocalizationProvider>
          </FormControl>
          <FormControl sx={{ width: { xs: "100%", lg: "50%" } }}>
            <InputLabel>{props.voiditem? "Void Reason" : "Charge Memo *"}</InputLabel>
            <OutlinedInput
              label={props.voiditem ? "Void Reason" : "Charge Memo"}
              sx={{ width: { xs: "100%", lg: "90%" } }}
              id={"debitNoteMemo"}
              name={"debitNoteMemo"}
              isrequired={true}
              value={debitNoteMemo || ""}
              onChange={(event) => setDebitNoteMemo(event.target.value)}
            />
          </FormControl>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h6"
            sx={{ paddingTop: "20px" }}
          >
            Charge Items (required *)
          </Typography>

          <TableContainer style={{ maxHeight: 350 }}>
            <Table sx={{ minWidth: 20 }} aria-label="simple table" stickyHeader>
              <TableHead>
                <TableRow>
                  {isNullUndefined(props.subscriptionId) && (
                    <TableCell sx={{ width: "30%" }}>Account *</TableCell>
                  )}
                  <TableCell sx={{ width: "30%" }}>Memo</TableCell>
                  <TableCell sx={{ width: "20%" }}>Amount *</TableCell>
                  {!isNullUndefined(props.subscriptionId) && (
                    <TableCell sx={{ width: "20%" }}>Quantity *</TableCell>
                  )}
                  {!isNullUndefined(props.subscriptionId) && (
                    <TableCell sx={{ width: "20%" }}>Total</TableCell>
                  )}
                  <TableCell sx={{ width: "10%" }}>Apply VAT</TableCell>
                  <TableCell sx={{ width: "10%" }}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{debitItemsRows}</TableBody>
            </Table>
          </TableContainer>
          <ResponsiveRow
            item
            sx={{
              justifyContent: { xs: "center", lg: "flex-end" },
              width: "100%",
              height: "auto",
            }}
          >
            <ModalAcceptButton
              disabled={!isNullUndefined(props.voiditem)}
              //   type="submit"
              onClick={handleChargeItemAdd}
              sx={{ width: { xs: "100%", md: "200px" } }}
            >
              Add row
            </ModalAcceptButton>
          </ResponsiveRow>
          <Alert severity="info" sx={{ width: "100%" }}>
            <AlertTitle>
              Charge Total: {formatCurrency(debitItemsTotal)}
            </AlertTitle>
          </Alert>
        </ResponsiveRow>
      </ModalContainerModule>
    </>
  );
}
