import { Typography } from '@mui/material';
import {
	DetailsContainer,
	DetailsLabel,
	DetailsRow,
	DetailsText,
} from '../../../../constants/component.constants';
import { ResponsiveRow } from '../../../../constants/layout.constants';
import ModalContainerModule from '../../../modules/modalContainer';
import { formatCurrency, formatDate } from '../../../../util/util';

const ViewPettyCash = (props) => {
	return (
		<ModalContainerModule
			size="sm"
			cancelText="Close"
			cancel={() => props.close()}
			openModal={props.open}
			modalTitle="Petty Cash Details"
		>
			<ResponsiveRow
				sx={{
					height: 'auto',
					width: '40%',
				}}
			>
				<DetailsContainer sx={{ marginTop: 0, paddingTop: 0 }} container>
					<ResponsiveRow
						sx={{
							display: { xs: 'flex', lg: 'none' },
							flexDirection: 'column',
							width: '100%',
							padding: 0,
							marginBottom: '15px',
						}}
					>
						<Typography
							sx={{ width: '100%', marginBottom: '10px' }}
							variant="h6"
						>
							{' '}
							Description:{' '}
						</Typography>
						<Typography sx={{ width: '100%' }}>
							{props.pettyCash?.description ?? ''}
						</Typography>
					</ResponsiveRow>

					<DetailsRow sx={{ display: { xs: 'none', lg: 'flex' } }}>
						<DetailsLabel> Description: </DetailsLabel>
						<DetailsText>{props.pettyCash?.description ?? ''}</DetailsText>
					</DetailsRow>
					<DetailsRow>
						<DetailsLabel> Entry Date: </DetailsLabel>
						<DetailsText>{formatDate(props.pettyCash?.entryDate)}</DetailsText>
					</DetailsRow>
					<DetailsRow>
						<DetailsLabel> Posting Date: </DetailsLabel>
						<DetailsText>
							{formatDate(props.pettyCash?.postingDate)}
						</DetailsText>
					</DetailsRow>
					<DetailsRow>
						<DetailsLabel> Applied to: </DetailsLabel>
						<DetailsText>{props.pettyCash?.appliedTo}</DetailsText>
					</DetailsRow>
					<DetailsRow>
						<DetailsLabel> Created By: </DetailsLabel>
						<DetailsText>{props.pettyCash?.createdBy}</DetailsText>
					</DetailsRow>
					<DetailsRow>
						<DetailsLabel> Amount: </DetailsLabel>
						<DetailsText>
							{formatCurrency(props.pettyCash?.totalAmount)}
						</DetailsText>
					</DetailsRow>
				</DetailsContainer>
			</ResponsiveRow>
		</ModalContainerModule>
	);
};

export default ViewPettyCash;
