/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { Grid, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import MessageBody from "../components/modules/messageBody";
import MessagesTab from "../components/modules/messagesTab";
import MessageCompose from "../components/modules/messageCompose";
import {
  getFacilityUsers,
  getReceivedMessages,
  getSentMessages,
} from "../services/messages.service";
import {
  ModalContainer,
  ModalInnerContainer,
} from "../constants/layout.constants";
import { isNullUndefined } from "../util/util";
import { useSelector } from "react-redux";

export default function Messages() {
  const [messages, setMessages] = useState([]);
  const [selectedMessage, setSelectedMessage] = useState();
  const [search, setSearch] = useState("");
  const [currentTab, setCurrentTab] = useState(0);
  const [pageNo, setPageNo] = useState(0);
  const [inbox, setInbox] = useState([]);
  const [outbox, setOutbox] = useState([]);
  const [facilityUsersOptions, setFacilityUsersOptions] = useState([]);
  const [facilityOwners, setFacilityOwners] = useState([]);
  const [isHoaFacility, setIsHoaFacility] = useState(false);
  const [viewCompose, setViewCompose] = useState({
    status: false,
    newMessage: null,
    message: {},
  });

  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    getReceivedMessages(pageNo).then((inbox) => setInbox(inbox.messages));

    getSentMessages(pageNo).then((outbox) => setOutbox(outbox.messages));

    getFacilityUsers().then((facilityUsers) => {
      let options = facilityUsers.facilityTenants.concat(
        facilityUsers.facilityUsers
      );

      //Removes duplicate objects in the array based on the unique identifier for the object.
      function removeDuplicateObjects(arr, value) {
        const result = arr.filter(
          (item, index, val) =>
            index === val.findIndex((t) => t[value] === item[value])
        );

        return result;
      }

      const uniqueArray = removeDuplicateObjects(options, "value");
      if (isNullUndefined(facilityUsers.hoaFacility)) {
        setFacilityUsersOptions(uniqueArray);
      } else {
        setFacilityUsersOptions(facilityUsers.facilityTenants);
      }

      if (!isNullUndefined(facilityUsers.hoaFacility)) {
        setIsHoaFacility(true);
        setFacilityOwners(facilityUsers.facilityOwners);
      }
    });
  }, [pageNo, user.proxyFacility]);

  useEffect(() => {
    switch (currentTab) {
      case 0:
        setMessages(inbox);
        break;
      case 1:
        setMessages(outbox);
        break;

      default:
        setMessages(inbox);
        setCurrentTab(0);
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab]);

  useEffect(() => {
    getReceivedMessages(pageNo).then((inbox) => setInbox(inbox.messages));

    getSentMessages(pageNo).then((outbox) => setOutbox(outbox.messages));
  }, [pageNo, user.proxyFacility]);

  function handleMessageSelect(newSelect) {
    setSelectedMessage(newSelect);
    setViewCompose({ message: newSelect });
  }

  function handleSearch(e) {
    let searchQuery = e.target.value;
    let searchResults = [];

    if (searchQuery !== "") {
      for (let i = 0; i < messages.length; i++) {
        let senderName = messages[i].senderName.toLowerCase();

        if (senderName.includes(searchQuery.toLowerCase())) {
          searchResults.push(messages[i]);
        }
      }
      setSearch(searchQuery);
      setMessages(searchResults);
    } else {
      setSearch(searchQuery);
      setMessages(messages);
    }
  }

  function handleKeyPress(e) {
    if (e.code === "Backspace") {
      setSearch("");
      currentTab === 0 ? setMessages(inbox) : setMessages(outbox);
    }
  }

  function changeTabs(newTab) {
    setCurrentTab(newTab);
  }

  return (
    <Grid
      container
      sx={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
        width: "100%",
        height: "75vh",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          backgroundColor: "#fff",
          margin: "10px",
          borderRadius: "10px",
          padding: "0px 10px",
          width: { xs: "100%", lg: "33.33%" },
        }}
      >
        <Box
          sx={{
            marginTop: "20px",
            marginBottom: "10px",
            padding: "20px",
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "flex-start",
          }}
        >
          <TextField
            size="small"
            fullwidth
            sx={{ width: "100%" }}
            label="Search Messages"
            value={search}
            onChange={handleSearch}
            onKeyDown={handleKeyPress}
          />
        </Box>
        <MessagesTab
          inbox={inbox}
          outbox={outbox}
          currentTab={currentTab}
          changeTabs={changeTabs}
          selectMessage={handleMessageSelect}
          composeMessage={() =>
            setViewCompose({ status: true, newMessage: true, message: {} })
          }
        />
      </Box>
      <Box
        sx={{
          display: {
            xs: "none",
            lg: "flex",
          },
          flexDirection: "column",
          height: "100%",
          justifyContent: "center",
          margin: "10px",
          borderRadius: "10px",
          width: { xs: "100%", lg: "66.67%" },
        }}
      >
        {viewCompose.status ? (
          <MessageCompose
            unsetView={() =>
              setViewCompose({ status: false, newMessage: false, message: {} })
            }
            message={viewCompose.message ? viewCompose.message : {}}
            newMessage={viewCompose.newMessage}
            inbox={inbox}
            outbox={outbox}
            setOutBox={setOutbox}
            recipients={facilityUsersOptions}
            owners={facilityOwners}
            isHoaFacility={isHoaFacility}
          />
        ) : selectedMessage ? (
          <MessageBody
            viewCompose={viewCompose}
            updateCompose={setViewCompose}
            message={selectedMessage}
            composeMessage={() =>
              setViewCompose({
                status: true,
                newMessage: false,
                message: selectedMessage,
              })
            }
          />
        ) : (
          <Box
            sx={{
              display: { xs: "none", lg: "inherit" },
            }}
          >
            <Typography
              sx={{
                width: "100%",
                color: "rgba(0,0,0,0.5)",
                alignText: "center",
                fontSize: "1rem",
                fontWeight: 800,
              }}
            >
              Select a chat on the sidebar to view it here
            </Typography>
          </Box>
        )}
      </Box>

      <ModalContainer
        open={selectedMessage}
        onClose={() => setSelectedMessage()}
        sx={{ display: { xs: "inherit", lg: "none" } }}
      >
        <ModalInnerContainer sx={{ padding: 0 }}>
          <MessageBody
            viewCompose={viewCompose}
            updateCompose={setViewCompose}
            message={selectedMessage}
            composeMessage={() =>
              setViewCompose({
                status: true,
                newMessage: false,
                message: selectedMessage,
              })
            }
          />
        </ModalInnerContainer>
      </ModalContainer>
      <ModalContainer
        open={viewCompose.status}
        onClose={() => setSelectedMessage()}
        sx={{ display: { xs: "inherit", lg: "none" } }}
      >
        <ModalInnerContainer sx={{ padding: 0 }}>
          <MessageCompose
            unsetView={() =>
              setViewCompose({ status: false, newMessage: false, message: {} })
            }
            message={viewCompose.message ? viewCompose.message : {}}
            newMessage={viewCompose.newMessage}
            inbox={inbox}
            outbox={outbox}
            setOutBox={setOutbox}
            recipients={facilityUsersOptions}
          />
        </ModalInnerContainer>
      </ModalContainer>
    </Grid>
  );
}
