import { Visibility } from "@mui/icons-material";
import { IconButton, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { ResponsiveRow } from "../../../../constants/layout.constants";
import ViewPendingPaymentInfo from "../../../modals/dashboard/accountant/viewPendingPaymentInfo";
import { getPaymentsAwaitingApproval } from "../../../../services/dashboard.service";
import { formatDate } from "../../../../util/util";
import MuiDataGrid from "../../MuiDataGrid";
import "dayjs/locale/en-gb"
import dayjs from "dayjs"

/**
 *
 * REQUIRED PROPS
 *  customHeight: string
 *  customWidth: string
 *  data: {
 *    id: number
 *    rent: number
 *    name: string
 *    description: string
 *  }
 */

export default function PaymentsAwaitingApproval({
  customHeight,
  customWidth,
}) {
  const [dataGridRows, setDataGridRows] = useState([])
  const [loading, setLoading] = useState(true)
  const [viewUnitModal, setViewUnitModal] = useState(false)
  const [selectedPayment, setSelectedPayment] = useState(null)

  function handleViewAccept() {
    handleCloseViewModal()
  }

  function handleOpenViewModal(item) {
    setSelectedPayment(item)
    setViewUnitModal(true)
  }

  function handleCloseViewModal() {
    setViewUnitModal(false)
    setSelectedPayment(null)
  }

  const DataGridColumns = [
    {
      field: "paidTo",
      headerName: "Payee",
      minWidth: 150,
      flex: 1.5,
    },
    {
      headerName: "Created Date",
      field: "createdOn",
      minWidth: 120,
      flex: 1.2,
      valueFormatter: (row) => {
        return formatDate(dayjs(row.value))
      },
    },
    {
      field: "description",
      headerName: "Description",
      width: 100,
    },
    {
      field: "amount",
      headerName: "Amount",
      type: "number",
      minWidth: 100,
      flex: 1,
    },
    { field: "status", headerName: "Status", minWidth: 100, flex: 1 },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      type: "number",
      minWidth: 70,
      fleX: 0.7,
      renderCell: (params) => (
        <ResponsiveRow
          sx={{
            width: "100%",
            flexDirection: "row",
            flexWrap: "nowrap",
            justifyContent: "flex-end",
            padding: "10px 0",
          }}
        >
          <IconButton onClick={() => handleOpenViewModal(params.row)}>
            <Visibility sx={{ color: "#037171" }} />
          </IconButton>
        </ResponsiveRow>
      ),
    },
  ]

  useEffect(() => {
    getPaymentsAwaitingApproval()
      .then((response) => {
        if (response && response.data) {
          setDataGridRows(response.data)
        }
      })
      .finally(() => {
        setLoading(false)
      })
    return () => setDataGridRows([])
  }, [])

  return (
    <ResponsiveRow
      sx={{
        flexDirection: "column",
        flexWrap: "nowrap",
        alignItems: "flex-start",
        padding: "0",
        height: { xs: "100%", lg: customHeight },
        minWidth: { xs: "100%", lg: customWidth },
        width: "100%",
        margin: "10px",
        backgroundColor: "#fff",
        borderRadius: "20px",
      }}
    >
      <Typography
        sx={{
          width: "100%",
          padding: "20px 20px 0 20px",
          textAlign: "start",
        }}
        variant="h5"
      >
        Payments: Awaiting Approval
      </Typography>
      <ResponsiveRow
        sx={{ width: "100%", boxShadow: 0, padding: "10px", margin: 0 }}
      >

        <MuiDataGrid
          alt={true}
          noSelection={true}
          serverPagination={false}
          dataGridColumns={DataGridColumns}
          dataGridRows={dataGridRows}
          loading={loading}
          height="90%"
          totalRows={dataGridRows ? dataGridRows.length : 0}
        />
      </ResponsiveRow>
      <ViewPendingPaymentInfo
        handleAccept={handleViewAccept}
        handleClose={handleCloseViewModal}
        openModal={viewUnitModal}
        selectedPayment={selectedPayment}
      />
    </ResponsiveRow>
  )
}
