/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import {
    Backdrop,
    Button,
    CircularProgress,
    FormControl,
    Grid,
    InputLabel,
    OutlinedInput,
    Typography,
} from "@mui/material"
import {useEffect, useState} from "react"

import {createComplex, getComplexes, getFacilityComplexOrganisation, updateComplex,} from "../services/complex.service"
import {MainContainer, TopBarContainedBrandButton, TopBarContainer,} from "../constants/component.constants"
import {getCountries} from "../services/list.service"
import {useSelector} from "react-redux"
import {isEmptyArray, isEmptyString, isNullUndefined} from "../util/util"
import Inspections from "../components/modals/inspections"
import ViewInspections from "../components/modals/viewInspections"
import AddComplexModal from "../components/modals/rentals/complex/addComplexModal"
import {ResponsiveRow} from "../constants/layout.constants"
import ComplexInfoCard from "../components/cards/complexInfoCard"
import Permit from "../util/permit"
import {issueResponseMessage, issueWarnMessage} from "../actions/message"
import {listComplexInspection} from "../services/inspections.service";

export default function Complex() {
    // eslint-disable-next-line no-unused-vars
    const [loading, setLoading] = useState(true)
    const [complexes, setComplexes] = useState([])
    const [InspectModal, setInspectModal] = useState(false)
    const [ViewModal, setViewModal] = useState(false)
    const [EditDiv, setEditDiv] = useState(false)
    const [AddDiv, setAddDiv] = useState(false)
    const [name, setName] = useState("")
    const [description, setDescription] = useState("")
    const [latitude, setLatitude] = useState("")
    const [longitude, setLongitude] = useState("")
    const [lrNumber, setLrNumber] = useState("")
    const [address1, setAddress1] = useState("")
    const [address2, setAddress2] = useState("")
    const [city, setCity] = useState("")
    const [countryCd, setCountryCd] = useState("")
    const [contract, setContract] = useState("")
    const [task, setTask] = useState("")
    const [complexProperties, setcomplexProperties] = useState([])
    const [selectedComplex, setselectedComplex] = useState(null)
    const [countryOptions, setCountryOptions] = useState([])
    const [associatedPropertyOptions, setAssociatedPropertyOptions] = useState(
        []
    )
    const user = useSelector((state) => state.auth.user)
    const [inspectionRecords, setInspectionRecords] = useState([])

    useEffect(() => {
        setLoading(true)
        let complexId = ""
        let facilityId = null
        if (user.proxyFacility != null) {
            facilityId = user.proxyFacility
        }
        getComplexes().then((complexes) => setComplexes(complexes))
            .finally(() => {
                setLoading(false)
            })

        getCountries().then((countryOptions) => setCountryOptions(countryOptions))

        getFacilityComplexOrganisation(complexId)
            .then((associatedPropertyOptions) => setAssociatedPropertyOptions(associatedPropertyOptions))

    }, [])

    useEffect(() => {
        setselectedComplex(selectedComplex)
    }, [selectedComplex])

    function handleCountryChange(event) {
        let value = event.target.value
        setCountryCd(value)
        let obj = selectedComplex
        obj.address.countryCd = value
        setselectedComplex(obj)
    }

    function handleAssociatedPropertyChange(event) {
        // let name = event.target.value
        // console.log("Name", name)

        let complexProperties = selectedComplex.complexProperties
        let alreadyExistsObject = complexProperties.find((p) => {
            return p.value === event.target.value
        })
        if (!isNullUndefined(alreadyExistsObject)) {
            // already exists
            issueWarnMessage("Selected property has already been added")
            return false
        }

        associatedPropertyOptions.forEach((property) => {
            if (event.target.value === property.value) {
                complexProperties.push(property)
                setcomplexProperties(complexProperties)
                let obj = selectedComplex
                obj.complexProperties = complexProperties
                setselectedComplex(obj)
            }
        })
    }

    function removeComplexProperty(event, property) {
        let complexProperties = selectedComplex.complexProperties
        let alreadyExistsPropertyIndex = complexProperties.findIndex((p) => {
            return p.value === property.value
        })
        if (
            alreadyExistsPropertyIndex > -1 &&
            alreadyExistsPropertyIndex < complexProperties.length
        ) {
            complexProperties.splice(alreadyExistsPropertyIndex, 1)
            let obj = selectedComplex
            obj.complexProperties = complexProperties
            setselectedComplex(obj)
            setcomplexProperties(obj)
        }
    }

    function handleAddClick() {
        setAddDiv(true)
        let data = {
            name: "",
            description: "",
            latitude: "",
            longitude: "",
            lrNumber: "",
            address: {
                address1: "",
                address2: "",
                city: "",
                countryCd: "",
                fullAddress1: "",
                id: "",
            },
            complexProperties: [],
        }
        setselectedComplex(data)
    }

    function handleSearch(e) {
        // const search = e.target.value
        // function findTitle(complex, value) {
        //   return complex.name == value
        // }
        // complexes.find()
    }

    function handleEditClick(selectedComplex) {
        setselectedComplex(selectedComplex)
        setAddDiv(true)
        setEditDiv(true)
    }

    function handleViewInspectionsClick(complex) {
        setselectedComplex(complex)
        setViewModal(true)
        setContract(complex)
        listComplexInspection(complex.id, new Date())
            .then((inspectionRecords) => setInspectionRecords(inspectionRecords))
            .catch((error) => {
                console.log("listInspectionRecordsError", error)
            })
    }

    function handleInspectComplexClick(complex) {
        setselectedComplex(complex)
        setInspectModal(true)
        setContract(complex)
        setTask("Inspect")
    }

    function saveComplex() {
        if (validateComplexForm()) {
            createComplex(selectedComplex)
                .then((response) => {
                    issueResponseMessage(response)
                    setAddDiv(false)
                    setselectedComplex(null)
                    if (!isNullUndefined(response.complex)) {
                        getComplexes()
                            .then((complexes) => {
                                setComplexes(complexes)
                            })
                    }
                })
        }
    }

    function editComplex() {
        let obj = selectedComplex
        obj.editAddress = true
        setselectedComplex(obj)

        if (validateComplexForm()) {
            updateComplex(selectedComplex)
                .then((response) => {
                    issueResponseMessage(response)
                    setAddDiv(false)
                    setEditDiv(false)
                    setselectedComplex(null)
                    if (!isNullUndefined(response.complex)) {
                        getComplexes()
                            .then((complexes) => {
                                setComplexes(complexes)
                            })
                    }
                })
        }
    }

    function handleAddModalClose() {
        setAddDiv(false)
    }

    function validateComplexForm() {
        // console.log("sendData:", selectedComplex)
        let data = selectedComplex
        if (isNullUndefined(data)) {
            issueWarnMessage("Could not determine Complex to save")
            return false
        }
        if (isEmptyString(data.name)) {
            issueWarnMessage("The complex must have a name!!")
            return false
        }
        if (isEmptyString(data.address.address1)) {
            issueWarnMessage("The complex must have an address!!")
            return false
        }
        if (isEmptyString(data.address.countryCd)) {
            issueWarnMessage("Please select a country")
            return false
        }
        if (isEmptyArray(data.complexProperties)) {
            issueWarnMessage("Please select at least one associated property")
            return false
        }

        setselectedComplex(data)
        return true
    }

    return (
        <Permit
            roles="BLDIR,BLDMG,CS"
            noPermittedServiceMessage={{
                title: "Access Denied",
                body: (
                    <>
                        Your current role is not permitted to view this page. <br/> Please
                        contact your system admin if you feel this is an error.
                    </>
                ),
            }}
        >
            <MainContainer>
                {AddDiv && (
                    <AddComplexModal
                        handleAddModalClose={handleAddModalClose}
                        handleAssociatedPropertyChange={handleAssociatedPropertyChange}
                        handleCountryChange={handleCountryChange}
                        AddDiv={AddDiv}
                        EditDiv={EditDiv}
                        associatedPropertyOptions={associatedPropertyOptions}
                        setAddDiv={setAddDiv}
                        setEditDiv={setEditDiv}
                        saveComplex={saveComplex}
                        editComplex={editComplex}
                        selectedComplex={selectedComplex}
                        removeComplexProperty={removeComplexProperty}
                        address1={address1}
                        address2={address2}
                        city={city}
                        countryCd={countryCd}
                        complexProperties={complexProperties}
                        countryOptions={countryOptions}
                        description={description}
                        latitude={latitude}
                        longitude={longitude}
                        lrNumber={lrNumber}
                        name={name}
                        setAddress1={setAddress1}
                        setAddress2={setAddress2}
                        setCity={setCity}
                        setDescription={setDescription}
                        setLatitude={setLatitude}
                        setLongitude={setLongitude}
                        setLrNumber={setLrNumber}
                        setName={setName}
                        setselectedComplex={setselectedComplex}
                        setcomplexProperties={setcomplexProperties}
                    />
                )}

                <Grid container sx={{display: "flex", flexDirection: "column"}}>
                    <TopBarContainer
                        container
                        sx={{
                            width: {xs: "100%", lg: "80%"},
                            boxShadow: "0 4px 5px 0 rgba(0,0,0,0.5)",
                            justifyContent: {md: "space-between", xs: "center"},
                        }}
                    >
                        <FormControl
                            sx={{
                                width: {lg: "33.3%", md: "50%", sm: "100%"},
                            }}
                        >
                            <InputLabel>Search Complexes</InputLabel>
                            <OutlinedInput
                                id="outlined-basic"
                                label="Search Complexes"
                                variant="outlined"
                                sx={{width: {xs: "100%", lg: "90%"}}}
                                onChange={handleSearch}
                            />
                        </FormControl>

                        <TopBarContainedBrandButton
                            sx={{
                                width: {xs: "100%", md: "150px", lg: "200px"},
                                height: "50px",
                                textAlign: "end",
                            }}
                            onClick={handleAddClick}
                        >
                            Add Complex
                        </TopBarContainedBrandButton>
                    </TopBarContainer>

                    <ResponsiveRow
                        sx={{
                            justifyContent: "center",
                            width: "100%",
                        }}
                    >
                        {complexes &&
                            complexes.length > 0 &&
                            complexes.map(function (complex, i) {
                                return (
                                    <ComplexInfoCard
                                        complex={complex}
                                        handleEditClick={handleEditClick}
                                        handleInspectComplexClick={handleInspectComplexClick}
                                        handleViewInspectionsClick={handleViewInspectionsClick}
                                    />
                                )
                            }, this)}
                        {isEmptyArray(complexes) && !loading && (
                            <ResponsiveRow
                                sx={{
                                    flexDirection: "column",
                                    height: "50vh",
                                    width: "100%",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <Typography
                                    sx={{
                                        color: "#C6C5C5",
                                        fontSize: "1.1rem",
                                        fontWeight: 700,
                                        width: "100%",
                                        textAlign: "center",
                                    }}
                                >
                                    No Complexes to Display
                                </Typography>
                            </ResponsiveRow>
                        )}
                        {isEmptyArray(complexes) && loading && (
                            <ResponsiveRow
                                component={Backdrop}
                                sx={{
                                    flexDirection: "column",
                                    color: "#fff",
                                }}
                                open={loading}
                            >
                                <CircularProgress color="inherit"/>{" "}
                                <Typography
                                    variant="h3"
                                    color="white"
                                    fontWeight={700}
                                    sx={{marginTop: "20px"}}
                                >
                                    LoadingData
                                </Typography>
                            </ResponsiveRow>
                        )}
                    </ResponsiveRow>
                    <ResponsiveRow
                        sx={{
                            justifyContent: "center",
                            flexWrap: "nowrap",
                            width: "100%",
                            margin: "20px auto",
                            padding: 0,
                        }}
                    >
                        {complexes.length > 12 && (
                            <Button
                                variant="contained"
                                sx={{
                                    backgroundColor: "#fff",
                                    color: "#037171",
                                    "&:hover": {
                                        backgroundColor: "#037171",
                                        color: "#fff",
                                    },
                                    fontSize: "12px",
                                    padding: "10px 7px",
                                    margin: {xs: "10px 0", md: "5px auto", lg: "0 "},
                                    textTransform: "full-width",
                                    width: "100%",
                                }}
                            >
                                Load More
                            </Button>
                        )}
                    </ResponsiveRow>
                </Grid>

                {/*INSPECT MODAL*/}
                {InspectModal &&
                    <Inspections
                        openModal={InspectModal}
                        closeModal={() => setInspectModal(false)}
                        contract={contract}
                        inspectionItem={"Complex"}
                    />
                }

                {ViewModal &&
                    <ViewInspections
                        openModal={ViewModal}
                        closeModal={() => {
                            setViewModal(false);
                            setInspectionRecords([])
                        }}
                        contract={contract}
                        inspectionItem={"Complex"}
                        inspectionRecords={inspectionRecords}
                    />
                }
            </MainContainer>
        </Permit>
    )
}
