import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import { Add, Cached } from "@mui/icons-material";
import { ResponsiveRow } from "../../../../constants/layout.constants";
import { isNullUndefined } from "../../../../util/util";
import ModalContainerModule from "../../../modules/modalContainer";
import VendorExpenseItems from "./vendorExpenseItems";
import { useEffect, useState } from "react";
import AddVendorContactPerson from "./addVendorContactPeson";
import AddNewUser from "../../addNewUser";

export default function AddCompanyVendorModal(props) {
  /**
   *
   * REQUIRED PROPS
   *
   * vendorDetailForm
   *
   * saveVendorClicked
   *
   * handleCancelClicked
   * handleAddItems
   *
   * setPersonSearchModal
   * categoryAccountItems
   * countriesList
   *
   */

    const [individualSearch, setIndividualSearch] = useState(false);
    const [addNewContactPersonForm, setAddNewContactPersonForm] = useState(false);
    const [address1, setAddress1] = useState("");
    const [address2, setAddress2] = useState("");
    const [city, setCity] = useState("");
    const [contact, setContact] = useState({ name: "" });
    const [country, setCountry] = useState("");
    const [organizationName, setOrganizationName] = useState("");
    const [organizationDescription, setOrganizationDescription] = useState("");
    const [formStage, setFormStage] = useState(1);
    const [taxId, setTaxId] = useState("");

  function toggleStage() {
    formStage === 1 ? setFormStage(2) : setFormStage(1);
  }
    function handleAddContactPerson(contact) {
        setContact(contact);
        let obj = props.selectedVendor;
        obj.contact = contact;
        props.setSelectedVendor(obj);
        setIndividualSearch(false)
    }

    function showAddNewContactPersonForm(){
        setAddNewContactPersonForm(true)
    }

    function hideAddNewContactPersonForm(){
        setAddNewContactPersonForm(false)
    }

  useEffect(() => {
    return () => {
      setFormStage(1);
    };
  }, []);

  return (
    <>
      <ModalContainerModule
        size="specialAddVendorDetails"
        accept={formStage === 1 ? toggleStage : props.saveVendorClicked}
        cancel2={toggleStage}
        cancel={props.handleCancelClicked}
        handleCancel2={toggleStage}
        openModal={props.vendorDetailForm}
        modalTitle="Vendor's Details"
        acceptButtonText={formStage === 1 ? "Next" : "Save"}
        cancel2Text="back"
        cancelDisabled2={formStage !== 2}
      >
        {formStage === 1 ? (
          <ResponsiveRow
            sx={{
              width: "100%",
              height: "auto",

              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{ marginBottom: "5px", width: "100%" }}
              id="vendors"
              variant="h6"
              component="h6"
              Expense
              Categories
            >
              Vendor Information
            </Typography>
            <FormControl
              item
              sx={{
                width: { xs: "100%", lg: "33.3%" },
                marginBottom: "10px",
              }}
            >
              <InputLabel sx={{ fontSize: ".9rem" }}>Name *</InputLabel>
              <OutlinedInput
                label={"Organization Name"}
                sx={{ width: "90%" }}
                id={"organizationName"}
                onChange={(event) => {
                  let value = event.target.value;
                  setOrganizationName(value);
                  let obj = props.selectedVendor;
                  obj.name = value;
                  props.setSelectedVendor(obj);
                }}
                value={
                  props.selectedVendor
                    ? props.selectedVendor.name
                    : organizationName
                }
              />
            </FormControl>

            <FormControl
              item
              sx={{
                width: { xs: "100%", lg: "33.3%" },
                marginBottom: "10px",
              }}
            >
              <InputLabel sx={{ fontSize: ".9rem" }}>Address 1 *</InputLabel>
              <OutlinedInput
                label={"Address 1"}
                sx={{ width: "90%" }}
                id={"address1"}
                onChange={(event) => {
                  let value = event.target.value;
                  setAddress1(value);
                  let obj = props.selectedVendor;
                  obj.address.address1 = value;
                  props.setSelectedVendor(obj);
                }}
                value={
                  props.selectedVendor
                    ? props.selectedVendor.address.address1
                    : address1
                }
              />
            </FormControl>

            <FormControl
              item
              sx={{
                width: { xs: "100%", lg: "33.3%" },
                marginBottom: "10px",
              }}
            >
              <InputLabel sx={{ fontSize: ".9rem" }}>Address 2 </InputLabel>
              <OutlinedInput
                label={"Address 2"}
                sx={{ width: "90%" }}
                id={"address2"}
                onChange={(event) => {
                  let value = event.target.value;
                  setAddress2(value);
                  let obj = props.selectedVendor;
                  obj.address.address2 = value;
                  props.setSelectedVendor(obj);
                }}
                value={
                  props.selectedVendor
                    ? props.selectedVendor.address.address2
                    : address2
                }
              />
            </FormControl>

            <FormControl
              item
              sx={{
                width: { xs: "100%", lg: "33.3%" },
                marginBottom: "10px",
              }}
            >
              <InputLabel sx={{ fontSize: ".9rem" }}>City *</InputLabel>
              <OutlinedInput
                label={"City"}
                sx={{ width: "90%" }}
                id={"city"}
                onChange={(event) => {
                  let value = event.target.value;
                  setCity(value);
                  let obj = props.selectedVendor;
                  obj.address.city = value;
                  props.setSelectedVendor(obj);
                }}
                value={
                  props.selectedVendor
                    ? props.selectedVendor.address.city
                    : city
                }
              />
            </FormControl>

            <FormControl
              item
              sx={{
                width: { xs: "100%", lg: "33.3%" },
                marginBottom: "10px",
              }}
            >
              <InputLabel sx={{ fontSize: ".9rem" }}>Country *</InputLabel>
              <Select
                label="Country *"
                sx={{ width: "90%" }}
                id={"country"}
                value={
                  props.selectedVendor
                    ? props.selectedVendor.address.countryCd
                    : country
                }
                onChange={(event) => {
                  let value = event.target.value;
                  setCountry(value);
                  let obj = props.selectedVendor;
                  obj.address.countryCd = value;
                  props.setSelectedVendor(obj);
                }}
              >
                {props.countriesList && props.countriesList.length > 0 ? (
                  props.countriesList.map(function (idType, i) {
                    return (
                      <MenuItem key={i} value={idType.value}>
                        {idType.label}
                      </MenuItem>
                    );
                  }, this)
                ) : (
                  <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
                )}
              </Select>
            </FormControl>

            <FormControl
              item
              sx={{
                width: { xs: "100%", lg: "33.3%" },
                marginBottom: "10px",
              }}
            >
              <InputLabel sx={{ fontSize: ".9rem" }}>Tax ID </InputLabel>
              <OutlinedInput
                label={"Tax ID"}
                sx={{ width: "90%" }}
                id={"taxId"}
                onChange={(event) => {
                  let value = event.target.value;
                  setTaxId(value);
                  let obj = props.selectedVendor;
                  obj.taxId = value;
                  props.setSelectedVendor(obj);
                }}
                value={
                  props.selectedVendor ? props.selectedVendor.taxId : taxId
                }
              />
            </FormControl>
            <FormControl
              item
              sx={{
                width: { xs: "100%", lg: "33.3%" },
                height: "60px",
                marginBottom: "10px",
                display: "flex",
                flexDirection: "row",
                flexWrap: "nowrap",
                alignItems: "center",
              }}
            >
              {props.selectedVendor &&
                isNullUndefined(props.selectedVendor.contact.name) && (
                  <InputLabel sx={{ fontSize: ".9rem" }}>
                    Organization Contact Person
                  </InputLabel>
                )}
              <OutlinedInput
                label={
                  props.selectedVendor &&
                  isNullUndefined(props.selectedVendor.contact.name)
                    ? "Organization Contact Person"
                    : ""
                }
                sx={{ width: "80%" }}
                // disabled={true}
                readOnly
                // onChange={() => {}}
                value={
                  props.selectedVendor
                    ? props.selectedVendor.contact.name
                    : contact.name
                }
              />
              <IconButton
                onClick={() => setIndividualSearch(true)}
                sx={{
                  width: "40px",
                  height: "40px",
                  margin: "0 10px",
                  padding: "10px",

                  backgroundColor: "#037171",
                }}
              >
                {props.selectedVendor &&
                isNullUndefined(props.selectedVendor.contact.name) ? (
                  <Add sx={{ color: "white" }} />
                ) : (
                  <Cached sx={{ color: "white" }} />
                )}
              </IconButton>
            </FormControl>
            <FormControl
              item
              sx={{
                width: { xs: "100%", lg: "66.6%" },
                marginBottom: "10px",
              }}
            >
              <InputLabel sx={{ fontSize: ".9rem" }}>Description</InputLabel>
              <OutlinedInput
                id="description"
                sx={{ width: "95%" }}
                label="Description"
                multiline
                rows={5}
                value={
                  props.selectedVendor
                    ? props.selectedVendor.description
                    : organizationDescription
                }
                onChange={(event) => {
                  let value = event.target.value;
                  setOrganizationDescription(value);
                  let obj = props.selectedVendor;
                  obj.description = value;
                  props.setSelectedVendor(obj);
                }}
              />
            </FormControl>
          </ResponsiveRow>
        ) : (
          <VendorExpenseItems
            categoryAccountItems={props.categoryAccountItems}
            handleAddItems={props.handleAddItems}
          />
        )}
      </ModalContainerModule>
      <AddVendorContactPerson
        setIndividualSearch={setIndividualSearch}
        individualSearch={individualSearch}
        showAddNewContactPersonForm={showAddNewContactPersonForm}
        setContactPerson={(value) => {
          setContact(value);
          let obj = props.selectedVendor;
          obj.contact = value;
          props.setSelectedVendor(obj);
        }}
      />

        {/*ADD NEW VENDOR CONTACT PERSON MODAL*/}
        <AddNewUser
            openModal={addNewContactPersonForm}
            closeModal={hideAddNewContactPersonForm}
            saveUser={handleAddContactPerson}
            manageUsers={false}
        />
    </>
  );
}
