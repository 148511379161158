import Grid from "@mui/material/Grid"
import loginImage from "../images/login-image.png"
import {Alert, Box, FormControl, InputLabel, OutlinedInput, Paper, Typography} from "@mui/material"
import LoadingButton from "@mui/lab/LoadingButton"
import {useEffect, useState} from "react"
import {isEmptyString, isNullUndefined} from "../util/util"
import {issueWarnMessage} from "../actions/message"
import {confirmFacebookDelete} from "../services/passwords.service"
import {useLocation} from "react-router";

export default function ConfirmFacebookDelete() {
    const [confirmationCode, setConfirmationCode] = useState("")
    const [message, setMessage] = useState("")
    const param = new URLSearchParams(useLocation().search)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        let confirmation_code = param.get('confirmation_code')
        if (!isNullUndefined(confirmation_code) && !isEmptyString(confirmation_code)) {
            setConfirmationCode(confirmation_code)
            confirmFacebookDelete(confirmation_code).then(response => {
                if(!isNullUndefined(response) && !isNullUndefined(response.message) && !isEmptyString(response.message)){
                    setMessage(response.message)
                }
            })
        }
    }, [param])

    /**
     * FORM SUBMIT: onSubmit
     * This is the form submit function that takes in the event
     * and reads the values of the states
     */

    const onSubmit = (e) => {
        e.preventDefault()
        if (isEmptyString(confirmationCode)) {
            issueWarnMessage("Please input the confirmationCode")
            return false
        }
        setLoading(true)
        confirmFacebookDelete(confirmationCode).then(response => {
            if(!isNullUndefined(response) && !isNullUndefined(response.message) && !isEmptyString(response.message)){
                setMessage(response.message)
            }
        }).finally(() => setLoading(false))
    }
    const onChange = (e) => {
        setMessage("")
        setConfirmationCode(e.target.value)
    }

    return (
        <Grid
            sx={{
                height: "100vh",
                width: "100wv",
                margin: "0",
            }}
            container
        >
            <Grid
                item
                xs={false}
                sm={false}
                md={5}
                lg={8}
                sx={{
                    backgroundImage: `url(${loginImage})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    width: "100%",
                }}
            />
            <Grid item sm={12} md={7} lg={4}>
                <Paper
                    sx={{
                        height: "100vh",
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "space-between",
                        justifyContent: "center",
                        margin: "auto",
                    }}
                >
                    <Box sx={{width: "100%", margin: "10px auto 10px auto"}}>
                        <img
                            src={require("../images/kodi-logo.png")}
                            alt=""
                            width={"30%"}
                        />
                    </Box>

                    <Box sx={{width: "80%", margin: "15px auto"}}>
                        <Typography
                            variant="h2"
                            color="primary.dark"
                            sx={{fontWeight: 700}}
                        >
                            Confirm Facebook data Deletion
                        </Typography>
                    </Box>

                    <Box sx={{width: "80%", margin: "10px auto"}}>
                        {
                            !isEmptyString(message) &&
                            <Typography variant="body2">
                            <Alert severity="warning" sx={{marginLeft:'10%', marginRight:'10%'}}>
                                {message}
                            </Alert>
                        </Typography>
                        }
                        <Typography variant="body2">
                            Please input the confirmation code to check/re-check the status.
                        </Typography>
                    </Box>

                    <Box sx={{width: "80%", margin: "20px auto"}}>
                        <form onSubmit={onSubmit}>
                            <FormControl sx={{width: "80%", marginBottom: "20px"}}>
                                <InputLabel htmlFor="userEmail">
                                    <Typography
                                        variant="body2"
                                        sx={{fontWeight: "medium"}}
                                        color="primary.light"
                                    >
                                        Enter Confirmation Code
                                    </Typography>
                                </InputLabel>
                                <OutlinedInput
                                    id="confirmationCode"
                                    label="Enter Confirmation Code"
                                    type="number"
                                    required
                                    aria-describedby="email-helper-text"
                                    color="secondary"
                                    onChange={onChange}
                                    value={confirmationCode}
                                />
                            </FormControl>{" "}
                            <LoadingButton
                                variant="contained"
                                type="submit"
                                loading={loading}
                                onClick={onSubmit}
                                sx={{
                                    width: "80%",
                                    backgroundColor: "#037171",
                                    margin: "10px auto",
                                }}
                            >
                                Confirm
                            </LoadingButton>
                        </form>
                    </Box>
                </Paper>
            </Grid>
        </Grid>
    )
}
