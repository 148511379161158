import {
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import { ResponsiveRow } from "../../../../constants/layout.constants";
import { isEmptyArray } from "../../../../util/util";
import ModalContainerModule from "../../../modules/modalContainer";

export default function AddAccountModal(props) {
  /**
   *
   * REQUIRED PROPS
   *
   * MenuProps
   *
   * handleCloseClicked
   * handleChange
   * handleSaveClicked
   *
   * setSelectedBankAcc
   * selectedBankId
   * setSelectedBankId
   * setAccNo
   * setAccName
   * setBic
   * setBranchName
   * setBankCode
   * setBranchCode
   * setMobilePhone
   * setDescription
   *
   * allOrgBankAcc
   * accName
   * accNo
   * accountIds
   * bankAccForm
   * bankCode
   * banksList
   * bic
   * branchName
   * branchCode
   * description
   * getStyles
   * mobilePhone
   * selectedBankAcc
   * theme
   *
   */

  return (
    <ModalContainerModule
      size="sm"
      accept={props.handleSaveClicked}
      cancel={props.handleCloseClicked}
      openModal={props.bankAccForm}
      modalTitle="Bank's Details"
      acceptButtonText=" Save"
    >
      <ResponsiveRow container sx={{ height: "auto", gap: "10px" }}>
        <FormControl
          item
          sx={{
            width: { xs: "100%", lg: "32%" },
          }}
        >
          <InputLabel>Bank *</InputLabel>
          <Select
            label="Bank *"
            id={"bank"}
            value={
              props.selectedBankAcc
                ? props.selectedBankAcc.bankId
                : props.selectedBankId
            }
            onChange={(event) => {
              let value = event.target.value;
              props.setSelectedBankId(value);
              let obj = props.selectedBankAcc;
              obj.bankId = value;
              props.setSelectedBankAcc(obj);
            }}
          >
            {props.banksList && props.banksList.length > 0 ? (
              props.banksList.map(function (idType, i) {
                return (
                  <MenuItem key={i} value={idType.value}>
                    {idType.label}
                  </MenuItem>
                );
              }, this)
            ) : (
              <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
            )}
          </Select>
        </FormControl>

        <FormControl
          item
          sx={{
            width: { xs: "100%", lg: "32%" },
          }}
        >
          <InputLabel>
            Account Number * (
            {props.selectedBankAcc && props.selectedBankAcc.accountNumber
              ? 30 - props.selectedBankAcc.accountNumber.length
              : 30}
            )
          </InputLabel>
          <OutlinedInput
            label="Account Number *"
            placeholder={"Account Number"}
            id={"accNumber"}
            onChange={(event) => {
              let value = event.target.value;
              props.setAccNo(value);
              let obj = props.selectedBankAcc;
              obj.accountNumber = value;
              props.setSelectedBankAcc(obj);
            }}
            value={
              props.selectedBankAcc
                ? props.selectedBankAcc.accountNumber
                : props.accNo
            }
          />
        </FormControl>

        <FormControl
          item
          sx={{
            width: { xs: "100%", lg: "32%" },
          }}
        >
          <InputLabel>Account Name (as per Bank Records)* </InputLabel>
          <OutlinedInput
            label="Account Name (as per Bank Records)*"
            placeholder={"Account Name"}
            id={"accName"}
            onChange={(event) => {
              let value = event.target.value;
              props.setAccName(value);
              let obj = props.selectedBankAcc;
              obj.name = value;
              props.setSelectedBankAcc(obj);
            }}
            value={
              props.selectedBankAcc ? props.selectedBankAcc.name : props.accName
            }
          />
        </FormControl>

        <FormControl
          item
          sx={{
            width: { xs: "100%", lg: "32%" },
          }}
        >
          <InputLabel>
            Bank Identifier Code (BIC or SWIFT Code) * (
            {props.selectedBankAcc && props.selectedBankAcc.bic
              ? 30 - props.selectedBankAcc.bic.length
              : 30}
            )
          </InputLabel>
          <OutlinedInput
            label={`   Bank Identifier Code (BIC or SWIFT Code) * (
            ${
              props.selectedBankAcc && props.selectedBankAcc.bic
                ? 30 - props.selectedBankAcc.bic.length
                : 30
            }
            )`}
            placeholder={"Bank Identifier Code "}
            id={"idCode"}
            onChange={(event) => {
              let value = event.target.value;
              props.setBic(value);
              let obj = props.selectedBankAcc;
              obj.bic = value;
              props.setSelectedBankAcc(obj);
            }}
            value={
              props.selectedBankAcc ? props.selectedBankAcc.bic : props.bic
            }
          />
        </FormControl>

        <FormControl
          item
          sx={{
            width: { xs: "100%", lg: "32%" },
          }}
        >
          <InputLabel>Bank Branch Name </InputLabel>
          <OutlinedInput
            label="Bank Branch Name"
            placeholder={"Bank Branch Name"}
            id={"branchName"}
            onChange={(event) => {
              let value = event.target.value;
              props.setBranchName(value);
              let obj = props.selectedBankAcc;
              obj.branchName = value;
              props.setSelectedBankAcc(obj);
            }}
            value={
              props.selectedBankAcc
                ? props.selectedBankAcc.branchName
                : props.branchName
            }
          />
        </FormControl>

        <FormControl
          item
          sx={{
            width: { xs: "100%", lg: "32%" },
          }}
        >
          <InputLabel>
            Bank Code (
            {props.selectedBankAcc && props.selectedBankAcc.bankCode
              ? 5 - props.selectedBankAcc.bankCode.length
              : 5}
            )
          </InputLabel>
          <OutlinedInput
            label={`Bank Code (
            ${
              props.selectedBankAcc && props.selectedBankAcc.bankCode
                ? 5 - props.selectedBankAcc.bankCode.length
                : 5
            }
            )`}
            inputProps={{
              maxLength: 5,
            }}
            placeholder={"Bank Code"}
            id={"bankCode"}
            onChange={(event) => {
              let value = event.target.value;
              props.setBankCode(value);
              let obj = props.selectedBankAcc;
              obj.bankCode = value;
              props.setSelectedBankAcc(obj);
            }}
            value={
              props.selectedBankAcc
                ? props.selectedBankAcc.bankCode
                : props.bankCode
            }
          />
        </FormControl>

        <FormControl
          item
          sx={{
            width: { xs: "100%", lg: "32%" },
          }}
        >
          <InputLabel>
            Branch Code (
            {props.selectedBankAcc && props.selectedBankAcc.branchCode
              ? 10 - props.selectedBankAcc.branchCode.length
              : 10}
            )
          </InputLabel>
          <OutlinedInput
            label={` Branch Code (
            ${
              props.selectedBankAcc && props.selectedBankAcc.branchCode
                ? 10 - props.selectedBankAcc.branchCode.length
                : 10
            }
            )`}
            placeholder={"Branch Code"}
            id={"branchCode"}
            onChange={(event) => {
              let value = event.target.value;
              // for(let i=branchCode.length i>=0 && i<10 i++){
              props.setBranchCode(value);
              let obj = props.selectedBankAcc;
              obj.branchCode = value;
              props.setSelectedBankAcc(obj);
              // }
            }}
            value={
              props.selectedBankAcc
                ? props.selectedBankAcc.branchCode
                : props.branchCode
            }
          />
        </FormControl>

        <FormControl
          item
          sx={{
            width: { xs: "100%", lg: "32%" },
          }}
        >
          <InputLabel id="multiple-accounts-label">
            Ledger Accounts linked to this Bank Account
          </InputLabel>
          <Select
            label="Ledger Accounts linked to this Bank Account"
            id="select-accounts"
            multiple
            value={
              !isEmptyArray(props.selectedBankAcc)
                ? props.selectedBankAcc.accountIds
                : props.accountIds
            }
            // value={accountIds}
            onChange={props.handleChange}
            MenuProps={props.MenuProps}
          >
            {props.allOrgBankAcc && props.allOrgBankAcc.length > 0 ? (
              props.allOrgBankAcc.map(function (idType, i) {
                return (
                  <MenuItem
                    key={i}
                    value={idType.value}
                    style={props.getStyles(
                      idType.value,
                      props.accountIds,
                      props.theme
                    )}
                  >
                    {idType.label}
                  </MenuItem>
                );
              }, this)
            ) : (
              <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
            )}
          </Select>
        </FormControl>

        <FormControl
          item
          sx={{
            width: { xs: "100%", lg: "32%" },
          }}
        >
          <InputLabel>Account Mobile Number (for PesaLink)</InputLabel>
          <OutlinedInput
            label="Account Mobile Number (for PesaLink)"
            placeholder={"Mobile Phone"}
            id={"mobilePhone"}
            onChange={(event) => {
              let value = event.target.value;
              props.setMobilePhone(value);
              let obj = props.selectedBankAcc;
              obj.mobileNumber = value;
              props.setSelectedBankAcc(obj);
            }}
            value={
              props.selectedBankAcc
                ? props.selectedBankAcc.mobileNumber
                : props.mobilePhone
            }
          />
        </FormControl>

        <FormControl
          item
          sx={{
            width: { xs: "100%", lg: "66.6%" },
          }}
        >
          <InputLabel>
            Account Description (
            {props.selectedBankAcc && props.selectedBankAcc.description
              ? 200 - props.selectedBankAcc.description.length
              : 200}
            )
          </InputLabel>
          <OutlinedInput
            multiline
            rows={4}
            label={` Account Description (
            ${
              props.selectedBankAcc && props.selectedBankAcc.description
                ? 200 - props.selectedBankAcc.description.length
                : 200
            }
            )`}
            placeholder={"Description"}
            id={"description"}
            onChange={(event) => {
              let value = event.target.value;
              props.setDescription(value);
              let obj = props.selectedBankAcc;
              obj.description = value;
              props.setSelectedBankAcc(obj);
            }}
            value={
              props.selectedBankAcc
                ? props.selectedBankAcc.description
                : props.description
            }
          />
        </FormControl>
      </ResponsiveRow>
    </ModalContainerModule>
  );
}
