import {serviceAPI, serviceAPIDownloadFile, upload} from "./service"
import {
    DELETE_UNIT_URL,
    EDIT_UNITS_URL,
    EXPORT_PROPERTIES_URL,
    FETCH_FACILITY_NAME_URL,
    FETCH_FACILITY_URL,
    GET_PROPERTY_FILE_URL,
    LIST_FACILITYUNITS_URL,
    SAVE_PROPERTY_URL,
    SAVE_UNIT_URL,
    UNIT_TYPE_URL
} from "../constants/url.constants"
import {handleErrorResponse} from "../actions/message"

export const getFacility = (facilityId, subscription = false) => {
    let url = FETCH_FACILITY_URL + facilityId + "&subscription=" + subscription
    return serviceAPI.get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getFacilityName = (facilityId) => {
    let url = FETCH_FACILITY_NAME_URL + facilityId
    return serviceAPI.get(url)
        .then((response) => {
            return response.data.facilityName

        }).catch(error => {
            handleErrorResponse(error)
        })
}


export const getFacilityUnits = (facilityId, pageNo, loadAll) => {
    let url = LIST_FACILITYUNITS_URL + facilityId + "&pageNo=" + pageNo + "&loadAllUnits=" + loadAll
    return serviceAPI.get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getUnitTypes = () => {
    return serviceAPI.get(UNIT_TYPE_URL)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const saveProperty = (data) => {
    return upload(SAVE_PROPERTY_URL, data)
}

export const saveUnit = (data) => {
    return serviceAPI.post(SAVE_UNIT_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const editUnits = (data) => {
    return serviceAPI.post(EDIT_UNITS_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const deleteUnit = (data) => {
    return serviceAPI.post(DELETE_UNIT_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const exportProperties = () => {
    return serviceAPIDownloadFile.get(EXPORT_PROPERTIES_URL)
}

export const getPropertyFile = (filename) => {
    let url = GET_PROPERTY_FILE_URL + filename
    return serviceAPI.get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}
