import {Typography} from "@mui/material";
import {ResponsiveRow} from "../../../constants/layout.constants";
import ViewVacantUnit from "../../modals/dashboard/buildingDirector/viewVacantUnit";
import {useEffect, useState} from "react";
import {getVacancies} from "../../../services/dashboard.service";
import MuiDataGrid from "../MuiDataGrid";
import {useSelector} from "react-redux";
import {isEmptyString, isNullUndefined} from "../../../util/util";

/**
 *
 * REQUIRED PROPS
 *  customHeight: string
 *  customWidth: string
 *  data: {
 *    id: number
 *    rent: number
 *    name: string
 *    description: string
 *  }
 */

export default function VacantUnits({customHeight, customWidth}) {
    const [dataGridRows, setDataGridRows] = useState([]);
    const [viewUnitModal, setViewUnitModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const {user} = useSelector((state) => state.auth)

    function handleViewAccept() {
        handleCloseViewModal();
    }

    function handleCloseViewModal() {
        setViewUnitModal(false);
    }

    const dataGridColumns = [
        {
            field: "content",
            headerName: "Unit Name",
            width: 200,
            editable: false,
            valueGetter: (params) => params.row.content,
        },
        {
            field: "rent",
            headerName: "Rent",
            type: "number",
            width: 250,
            valueGetter: (params) => params.row.rent,
        },
    ];

    const subscriptionLevelDataColumns = [
        {
            field: "unitName",
            headerName: "Unit Name",
            width: 180,
            editable: false,
            valueGetter: (params) => params.row.unitName,
        },
        {
            field: "facility",
            headerName: "Facility",
            width: 200,
            editable: false,
            valueGetter: (params) => params.row.facility,
        },
        {
            field: "rent",
            headerName: "Rent",
            type: "number",
            width: 150,
            valueGetter: (params) => params.row.rent,
        },
    ];

    useEffect(() => {
        setLoading(true);
        getVacancies()
            .then((response) => {
                if (response && response.data) {
                    setDataGridRows(response && response.data);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [user.proxyFacility]);

    return (
        <ResponsiveRow
            sx={{
                flexDirection: "column",
                flexWrap: "nowrap",

                alignItems: "flex-start",
                justifyContent: "flex-start",

                margin: "10px",
                padding: "20px",

                height: {xs: "100%", lg: customHeight},
                minWidth: {xs: "100%", lg: customWidth},
                width: "100%",

                backgroundColor: "#fff",
                borderRadius: "20px",

                overflow: "hidden",
            }}
        >
            <Typography
                sx={{
                    width: "100%",
                }}
                textAlign="start"
                variant="h5"
            >
                Vacant Units
            </Typography>

            <MuiDataGrid
                alt={true}
                noSelection={true}
                dataGridColumns={(!isNullUndefined(user.proxyFacility) && !isEmptyString(user.proxyFacility) && user.proxyFacility !== "null") ? dataGridColumns : subscriptionLevelDataColumns}
                dataGridRows={dataGridRows}
                serverPagination={false}
                loading={loading}
                height={"90%"}
                totalRows={dataGridRows.length}
            />
            <ViewVacantUnit
                handleAccept={handleViewAccept}
                handleClose={handleCloseViewModal}
                open={viewUnitModal}
            />
        </ResponsiveRow>
    );
}
