import {
    Accordion,
    AccordionDetails,
    AccordionSummary, Grid, Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FaqLoader from "../helpers/faq_loader";
import { Box } from "@mui/system";
import { Fragment, useEffect, useMemo, useRef, useState } from "react";
import {isEmptyArray, isNullUndefined} from "../util/util";
import { ModalAcceptButton } from "../constants/component.constants";
import HelpAndSupportForm from "../components/modals/helpAndSupportForm";
import MUIAutoCompleteForm from "../components/inputs/MUIautoCompleteForm";

export default function Faq() {
  const [displayedFAQ, setDisplayedFAQ] = useState([]);
  const [faqQuestions, setFaqQuestions] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [openSupportTicketModal, setOpenSupportTicketModal] = useState(false);

  const questRef = useRef([]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const FAQ = useMemo(() => new FaqLoader(), []);

  useEffect(() => {
    setDisplayedFAQ(FAQ.faqData);

    const faqQuestionsArray = FAQ.mapQuestionsToArray();
    setFaqQuestions(faqQuestionsArray);

    for (let i = 0; i < faqQuestions.length; i++) {
      questRef.current[i] = `question${i} `;
    }

    return () => {
      setDisplayedFAQ([]);
      setFaqQuestions([]);
    };
  }, [FAQ, faqQuestions.length]);

    function questionSelected(e) {
        if (!isNullUndefined(e) && !isNullUndefined(e.label)) {
            const questionText = e.label
            let questionIndex;
            for (let i = 0; i < faqQuestions.length; i++) {
                if (faqQuestions[i].label === questionText) {
                    questionIndex = i;
                }
            }

            const selectedSection = faqQuestions[questionIndex];
            if (!isNullUndefined(selectedSection) && !isNullUndefined(selectedSection.section) && (expanded !== selectedSection.section)) {
                setExpanded(selectedSection.section);
                setTimeout(() => {
                    function findPos(obj) {
                        let curtop = 0;
                        if (!isNullUndefined(obj) && !isNullUndefined(obj.offsetParent) && !isNullUndefined(obj.offsetTop)) {
                            do {
                                curtop += obj.offsetTop;
                            } while ((obj = obj.offsetParent));
                            return [curtop];
                        }
                    }

                    window.scrollTo({
                        left: 0,
                        top: findPos(document.getElementById(`question${questionIndex}`)),
                        behavior: "smooth",
                    });
                }, 1000);
            }
        } else {
            setExpanded(false);
        }
    }

    function closeSupportTicketModal() {
    setOpenSupportTicketModal(false);
  }

  function openSupportTicketModalFunc() {
    setOpenSupportTicketModal(true);
  }

  return (
    <Grid
      container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        padding: { xs: 0, lg: "20px " },
        margin: "10px auto",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          borderRadius: "10px",
          width: "80%",
          padding: "20px",
          margin: "20px auto",
          backgroundColor: "#fff",
          boxShadow: "0 4px 5px 0 rgba(0,0,0,0.5)",
        }}
      >
          <MUIAutoCompleteForm
              itemsData={faqQuestions}
              label="Search FAQ Questions"
              updateSelected={questionSelected}
              currentlySelected={""}
              menuId="faxComboBox"
              width="50%"
              disableClearable={false}
              emptyMessage="No FAQ Questions to show."
          />
        <ModalAcceptButton
          sx={{p: "10px", fontSize: "1rem"}}
          onClick={openSupportTicketModalFunc}
        >
          Contact Support
        </ModalAcceptButton>

      </Box>
      {!isEmptyArray(displayedFAQ) && <Box sx={{ backgroundColor: "#fff", borderRadius: "20px"}}>
        <Typography sx={{ fontSize: {sm: "1.5rem", lg: "2rem"}, fontWeight: "600", padding: "20px" }}>Frequently Asked Questions(FAQ)</Typography>
        {displayedFAQ.map((faq) => {
          return (
            <Accordion
              key={displayedFAQ.indexOf(faq)}
              expanded={expanded === faq.name}
              onChange={handleChange(faq.name)}
              sx={{ width: "100%", textAlign: "start", padding: "20px 10px" }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id={`${faq.name}-header`}
              >
                <Typography
                  sx={{
                    width: "100%",
                    alignText: "start",
                    fontSize: "18px",
                    flexShrink: 0,
                    fontWeight: 600,
                  }}
                >
                  {faq.text}
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ width: "100%" }}>
                {faq.data.map((question) => {
                  return (
                    question && (
                      <Box
                        key={question.questionID}
                        id={`question${question.questionID}`}
                      >
                        <Typography
                          component="div"
                          sx={{
                            color: "#037171",
                            fontWeight: 600,
                            marginTop: "20px",
                          }}
                        >
                          {question.question}
                        </Typography>
                        {question.hasDescription ? (
                          <Typography
                            sx={{
                              fontWeight: 500,
                              marginTop: "20px",
                              padding: "5px 10px",
                            }}
                          >
                            {question.answer}
                          </Typography>
                        ) : null}
                        {question.hasSteps &&
                          question.steps.map((step) => {
                            return typeof step == "string" ? (
                              <Typography
                                key={question.steps.indexOf(step)}
                                sx={{
                                  fontSize: "1rem",
                                  lineHeight: "30px",

                                  padding: "0px 10px",
                                  // width: "80%",
                                }}
                              >
                                {step}
                              </Typography>
                            ) : (
                              step()
                            );
                          })}
                        {question.hasSubAnswers &&
                          question.subAnswers.map((subAnswer) => {
                            return (
                              <Fragment
                                key={question.subAnswers.indexOf(subAnswer)}
                              >
                                <Typography
                                  sx={{
                                    fontSize: "1rem",
                                    color: "#037171",
                                    padding: "0px 10px",
                                    fontWeight: 600,
                                    marginBottom: "15px",
                                    marginTop: "10px",
                                  }}
                                >
                                  {subAnswer.header}
                                </Typography>
                                {subAnswer.steps.map((step) => {
                                  return typeof step == "string" ? (
                                    <Typography
                                      key={subAnswer.steps.indexOf(step)}
                                      sx={{
                                        fontSize: "1rem",
                                        lineHeight: "30px",
                                        padding: "0 15px",
                                      }}
                                    >
                                      {step}
                                    </Typography>
                                  ) : (
                                    step()
                                  );
                                })}
                              </Fragment>
                            );
                          })}
                      </Box>
                    )
                  );
                })}
              </AccordionDetails>
            </Accordion>
          );
        })}
      </Box>}

      {/* Support ticket modal */}

      <HelpAndSupportForm closeModal={closeSupportTicketModal} openModal={openSupportTicketModal} />
    </Grid>
  );
}
