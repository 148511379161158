import * as React from 'react';
import {Autocomplete, TextField, Typography} from "@mui/material";

/**
 * PROPS
 * 
 * 
 * options
 * title
 * placeholder
 * selectedOptions
 * isRequired
 * disabled
 * onSelect
 * limitTags - optional // sets the numnber of tags to be shown when not in focus
 */

export default function MUIAutoCompleteMultiSelectForm(props) {

  return (
    <Autocomplete
      multiple
      id={props?.title}
      value={props?.selectedOptions}
      onChange={(event, newValue) => props.onSelect(event, newValue)}
      options={props.options}
      getOptionLabel={(option) => option.label}
      limitTags={props?.limitTags}
      renderOption={(props, option) => {
        return (
          <Typography
              whiteSpace={"nowrap"}
              textOverflow="ellipsis"
              sx={{
                  width: "100%",
                  height: "40px",

                  fontSize: "0.95rem",
                  padding: "auto 0",
                  borderRadius: "10px",
              }}
              {...props}
              key={option.value}
          >
              {option.label}
          </Typography>
        );
      }}
      // style={{ width: props.width ?? 500 }}
      sx={props?.sx}
      renderInput={(params) => (
        <TextField {...params} label={props.label} placeholder={props?.placeholder} />
      )}
    />
  );
}

