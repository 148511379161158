import {serviceAPI} from "../service"
import {LISTINGS_SIGNUP_URL} from "../../constants/url.constants"
import {handleErrorResponse} from "../../actions/message"


export const listingsSignUp = (data) => {
    return serviceAPI.post(LISTINGS_SIGNUP_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

