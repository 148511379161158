import {serviceAPI, serviceAPIDownloadFile} from "./service"
import {
    ALL_PAY_MTH_URL,
    DOWNLOAD_RECEIPTS,
    EXPORT_RECEIPTS,
    LEASE_RECEIPTS,
    LIST_RECEIPTS,
    OWNERSHIP_RECEIPTS,
    TOTAL_RECEIPTS
} from "../constants/url.constants"
import {handleErrorResponse} from "../actions/message"

export const listAllPaymentMethods = () => {
    return serviceAPI
        .get(ALL_PAY_MTH_URL)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const listReceipts = (data) => {
    return serviceAPI
        .post(LIST_RECEIPTS, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const loadTotalReceipts = (data) => {
    return serviceAPI
        .post(TOTAL_RECEIPTS, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export function downloadReceipts(data) {
    return serviceAPI
        .post(DOWNLOAD_RECEIPTS, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export function exportReceipts(data) {
    return serviceAPIDownloadFile
        .post(EXPORT_RECEIPTS, data)
        .then((response) => {
            return Promise.resolve()
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const loadReceiptOwnership = (receiptId) => {
    let url = OWNERSHIP_RECEIPTS + "?receiptId=" + receiptId
    return serviceAPI
        .get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const loadReceiptLease = (receiptId) => {
    let url = LEASE_RECEIPTS + "?receiptId=" + receiptId
    return serviceAPI
        .get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}
