import {
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import { useEffect, useState } from "react";
import { ResponsiveRow } from "../../../../constants/layout.constants";
import ModalContainerModule from "../../../modules/modalContainer";
import {
  isEmptyObject,
  isEmptyString,
  isNullUndefined,
} from "../../../../util/util";
import { getUnitTypes, saveUnit } from "../../../../services/property.service";
import {
  issueResponseMessage,
  issueWarnMessage,
} from "../../../../actions/message";

/**
 *
 * REQUIRED PROPS
 *
 * handleSaveChanges
 * setEditUnitModal
 * editUnitModal
 * unit
 *
 */

export default function UnitModal(props) {
  const [loading, setLoading] = useState(true);
  const [id, setId] = useState(null);
  const [unitPropertyId, setUnitPropertyId] = useState(null);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [type, setType] = useState("");
  const [sqft, setSqft] = useState(0);
  const [rentPerSqft, setRentPerSqft] = useState(0);
  const [rent, setRent] = useState(0);
  const [beds, setBeds] = useState(0);
  const [baths, setBaths] = useState(0);
  const [unitTypeOptions, setUnitTypeOptions] = useState([]);

  useEffect(() => {
    getUnitTypes().then((unitTypeOptions) =>
      setUnitTypeOptions(unitTypeOptions)
    );
    setLoading(false);
  }, []);

  useEffect(() => {
    if (!isEmptyObject(props.unit)) {
      setId(props.unit.id);
      setUnitPropertyId(props.unit.unitPropertyId);
      setName(props.unit.name);
      setDescription(props.unit.description);
      setType(props.unit.type.id);
      setSqft(props.unit.sqft);
      setRentPerSqft(props.unit.rentPerSqft);
      setRent(props.unit.rent);
      setBeds(props.unit.beds);
      setBaths(props.unit.baths);
    }
    setLoading(false);
  }, [props.unit]);

  const submit = () => {
    if (isEmptyString(name)) {
      issueWarnMessage("The unit must have a name!!");
      return;
    }
    if (isEmptyString(type)) {
      issueWarnMessage("Unit must have an type!!");
      return;
    }
    const sendData = {
      id: props.unit.id ? props.unit.id : null,
      name: name,
      unitPropertyId: unitPropertyId,
      description: description,
      type: type,
      sqft: sqft,
      rentPerSqft: rentPerSqft,
      rent: rent,
      beds: beds,
      baths: baths,
    };
    saveUnit(sendData).then((response) => {
      issueResponseMessage(response);
      props.setUnitModal(false);
    });
  };

  return (
    <ModalContainerModule
      size="specialEditPropertyUnit"
      accept={submit}
      cancel={() => props.setUnitModal(false)}
      openModal={props.unitModal}
      modalTitle={
        isNullUndefined(props.unit.id)
          ? "Add Unit"
          : `Edit Unit: ${props.unit.name}`
      }
      acceptButtonText={isNullUndefined(props.unit.id) ? "Save" : "Update"}
    >
      <ResponsiveRow
        sx={{ width: "100%", height: "auto", alignItems: "center" }}
      >
        <FormControl
          sx={{ width: { xs: "100%", lg: "33.3%" }, marginBottom: "10px" }}
        >
          <InputLabel>Name</InputLabel>
          <OutlinedInput
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            sx={{ width: { xs: "100%", lg: "90%" } }}
          />
        </FormControl>
        <FormControl
          sx={{ width: { xs: "100%", lg: "33.3%" }, marginBottom: "10px" }}
        >
          <InputLabel>Description</InputLabel>
          <OutlinedInput
            label="Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            sx={{ width: { xs: "100%", lg: "90%" } }}
          />
        </FormControl>
        <FormControl
          sx={{ width: { xs: "100%", lg: "33.3%" }, marginBottom: "10px" }}
        >
          <InputLabel>Type *</InputLabel>
          <Select
            label="Type *"
            sx={{ width: { xs: "100%", md: "90%" } }}
            id={"type"}
            value={type}
            onChange={(event) => setType(event.target.value)}
          >
            {unitTypeOptions && unitTypeOptions.length > 0 ? (
              unitTypeOptions.map(function (unitType, i) {
                return (
                  <MenuItem key={i} value={unitType.value}>
                    {unitType.label}
                  </MenuItem>
                );
              }, this)
            ) : (
              <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
            )}
          </Select>
        </FormControl>
        <FormControl
          sx={{ width: { xs: "100%", lg: "33.3%" }, marginBottom: "10px" }}
        >
          <InputLabel>Square Feet</InputLabel>
          <OutlinedInput
            label="Square Feet"
            type="number"
            value={sqft}
            onChange={(e) => setSqft(e.target.value)}
            sx={{ width: { xs: "100%", lg: "90%" } }}
            inputProps={{ min: 0 }}
          />
        </FormControl>
        <FormControl
          sx={{ width: { xs: "100%", lg: "33.3%" }, marginBottom: "10px" }}
        >
          <InputLabel>Rent</InputLabel>
          <OutlinedInput
            label="Rent"
            type="number"
            value={rent}
            onChange={(e) => setRent(e.target.value)}
            sx={{ width: { xs: "100%", lg: "90%" } }}
            inputProps={{ min: 0 }}
          />
        </FormControl>
        <FormControl
          sx={{ width: { xs: "100%", lg: "33.3%" }, marginBottom: "10px" }}
        >
          <InputLabel>Rent per Sq Ft</InputLabel>
          <OutlinedInput
            label="Rent per Sq Ft"
            type="number"
            value={rentPerSqft}
            onChange={(e) => setRentPerSqft(e.target.value)}
            sx={{ width: { xs: "100%", lg: "90%" } }}
            inputProps={{ min: 0 }}
          />
        </FormControl>
        <FormControl
          sx={{ width: { xs: "100%", lg: "33.3%" }, marginBottom: "10px" }}
        >
          <InputLabel>Beds</InputLabel>
          <OutlinedInput
            label="Beds"
            type="number"
            value={beds}
            onChange={(e) => setBeds(e.target.value)}
            sx={{ width: { xs: "100%", lg: "90%" } }}
            inputProps={{ min: 0 }}
          />
        </FormControl>
        <FormControl
          sx={{ width: { xs: "100%", lg: "33.3%" }, marginBottom: "10px" }}
        >
          <InputLabel>Baths</InputLabel>
          <OutlinedInput
            label="Baths"
            type="number"
            value={baths}
            onChange={(e) => setBaths(e.target.value)}
            sx={{ width: { xs: "100%", lg: "90%" } }}
            inputProps={{ min: 0 }}
          />
        </FormControl>
      </ResponsiveRow>
    </ModalContainerModule>
  );
}
