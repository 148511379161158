import { useEffect, useState } from "react";
import { Box } from "@mui/system";
import {
  AccessTime,
  PendingActions,
  RunningWithErrors,
} from "@mui/icons-material";

import { Typography } from "@mui/material";
import { ResponsiveRow } from "../../../../constants/layout.constants";
import { getBillsStats } from "../../../../services/dashboard.service";

/**
 *
 * REQUIRED PROPS:
 * customWidth: number
 * customHeight: number
 *  data: {
 *    billDue: number
 *    overdueBills: number
 *    pendingInvoices: number
 *    overdueInvoices: number
 *  }
 *
 */

export default function BillsQuadrant({ customWidth, customHeight }) {
  const [billsQuadrantData, setBillsQuadrantData] = useState({
    billsDue: 0,
    overdueBills: 0,
    pendingInvoices: 0,
    overdueInvoices: 0,
  });
  const [, setLoading] = useState(true);

  useEffect(() => {
    getBillsStats()
      .then((response) => {
        if (response && response.data) {
          setBillsQuadrantData(response.data);
        }
      })
      .finally(() => {
        setLoading(false);
      });

    return () => {
      setBillsQuadrantData({
        billsDue: 0,
        overdueBills: 0,
        pendingInvoices: 0,
        overdueInvoices: 0,
      });
    };
  }, []);

  return (
    <ResponsiveRow
      sx={{
        height: { xs: "100%", lg: customHeight },
        minWidth: { xs: "100%", lg: customWidth },
        width: "100%",

        margin: "10px",

        justifyContent: "space-between",
        alignItems: "center",

        flexWrap: "wrap",

        overflowY: "hidden",
      }}
    >
      <ResponsiveRow
        sx={{
          textTransform: "capitalize",
          width: "49%",
          height: "40%",

          position: "relative",
          overflow: "hidden",

          justifyContent: "flex-start",
          backgroundColor: "#fff",
          borderRadius: "20px",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            zIndex: "8",
            left: 10,
            top: 0,

            height: "100%",
            width: "auto",
          }}
        >
          <AccessTime
            sx={{
              margin: 0,
              padding: 0,
              height: "100%",
              width: "100%",
              color: "rgba(237,108,2,.085)",
            }}
          />
        </Box>
        <ResponsiveRow
          sx={{
            position: "relative",
            zIndex: "10",
            flexDirection: "column-reverse",
            flexWrap: "nowrap",

            justifyContent: "center",

            width: "100%",
            height: "100%",
            padding: "20px 10px",
            margin: "auto auto 10px auto",
          }}
        >
          <Typography
            variant="h1"
            sx={{
              textAlign: "end",
              width: "100%",
              height: "auto",
            }}
            color="#037171"
            fontWeight={900}
          >
            {billsQuadrantData && billsQuadrantData.billsDue}
          </Typography>
          <Typography
            variant="h4"
            sx={{
              textAlign: "end",
              height: "65px",
              width: "100%",
            }}
            fontWeight={800}
          >
            Bills Due in 30 Days
          </Typography>
        </ResponsiveRow>
      </ResponsiveRow>

      <ResponsiveRow
        sx={{
          textTransform: "capitalize",
          width: "49%",
          height: "40%",

          position: "relative",
          overflow: "hidden",

          justifyContent: "flex-start",
          alignItems: "flex-end",

          backgroundColor: "#fff",
          borderRadius: "20px",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            zIndex: "8",
            left: 10,
            top: 0,

            height: "100%",
            width: "auto",
          }}
        >
          <RunningWithErrors
            sx={{
              margin: 0,
              padding: 0,
              height: "100%",
              width: "100%",
              color: "rgba(211,47,47, .085)",
            }}
          />
        </Box>
        <ResponsiveRow
          sx={{
            position: "relative",
            zIndex: "10",
            flexDirection: "column-reverse",
            flexWrap: "nowrap",

            justifyContent: "center",

            width: "100%",
            height: "100%",
            padding: "20px 10px",
            margin: "auto auto 10px auto",
          }}
        >
          <Typography
            variant="h1"
            sx={{
              textAlign: "end",
              width: "100%",
              height: "auto",
              padding: "auto 20px",
            }}
            color="#037171"
            fontWeight={900}
          >
            {billsQuadrantData && billsQuadrantData.overdueBills}
          </Typography>
          <Typography
            variant="h4"
            sx={{
              textAlign: "end",
              height: "65px",
              width: "100%",
            }}
            fontWeight={800}
          >
            Overdue Bills
          </Typography>
        </ResponsiveRow>
      </ResponsiveRow>

      <ResponsiveRow
        sx={{
          textTransform: "capitalize",
          width: "49%",
          height: "40%",

          position: "relative",
          overflow: "hidden",

          justifyContent: "flex-start",
          backgroundColor: "#fff",
          borderRadius: "20px",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            zIndex: "8",
            left: 10,
            top: 0,

            height: "100%",
            width: "auto",
          }}
        >
          <PendingActions
            sx={{
              margin: 0,
              padding: 0,
              height: "100%",
              width: "100%",
              color: "rgba(237,108,2,.085)",
            }}
          />
        </Box>
        <ResponsiveRow
          sx={{
            position: "relative",
            zIndex: "10",
            flexDirection: "column-reverse",
            flexWrap: "nowrap",

            justifyContent: "center",

            width: "100%",
            height: "100%",
            padding: "20px 10px",
            margin: "auto auto 10px auto",
          }}
        >
          <Typography
            variant="h1"
            sx={{
              textAlign: "end",
              width: "100%",
              height: "auto",
              padding: "auto 20px",
            }}
            color="#037171"
            fontWeight={900}
          >
            {billsQuadrantData && billsQuadrantData.pendingInvoices}
          </Typography>
          <Typography
            variant="h4"
            sx={{
              textAlign: "end",
              height: "65px",
              width: "100%",
            }}
            fontWeight={800}
          >
            Pending Invoices
          </Typography>
        </ResponsiveRow>
      </ResponsiveRow>

      <ResponsiveRow
        sx={{
          textTransform: "capitalize",
          width: "49%",
          height: "40%",

          position: "relative",
          overflow: "hidden",

          justifyContent: "flex-start",
          backgroundColor: "#fff",
          borderRadius: "20px",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            zIndex: "8",
            left: 10,
            top: 0,

            height: "100%",
            width: "auto",
          }}
        >
          <RunningWithErrors
            sx={{
              margin: 0,
              padding: 0,
              height: "100%",
              width: "100%",
              color: "rgba(211,47,47, .085)",
            }}
          />
        </Box>
        <ResponsiveRow
          sx={{
            position: "relative",
            zIndex: "10",
            flexDirection: "column-reverse",
            flexWrap: "nowrap",

            justifyContent: "center",

            width: "100%",
            height: "100%",
            padding: "20px 10px",
            margin: "auto auto 10px auto",
          }}
        >
          <Typography
            variant="h1"
            sx={{
              textAlign: "end",
              width: "100%",
              height: "auto",
              padding: "auto 20px",
            }}
            color="#037171"
            fontWeight={900}
          >
            {billsQuadrantData && billsQuadrantData.overdueInvoices}
          </Typography>
          <Typography
            variant="h4"
            sx={{
              textAlign: "end",
              height: "65px",
              width: "100%",
            }}
            fontWeight={800}
          >
            Overdue Invoices
          </Typography>
        </ResponsiveRow>
      </ResponsiveRow>
    </ResponsiveRow>
  );
}
