import {Grid, Typography} from "@mui/material";
import {Box} from "@mui/system";

export default function Unauthorized({message}) {
    return (
        <Grid
            container
            sx={{
                backgroundColor: "#efefef",
                width: "100%",
                height: "100vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                paddingTop: "10%",
                alignItems: "center",
                paddingBottom: "20px",
            }}
        >
            <Box sx={{width: "100%", margin: "10px auto 10px auto"}}>
                <img src={require("../images/kodi-logo.png")} alt="" width={"150px"}/>
            </Box>

            {/* <Box sx={{ width: "80%", margin: "5px auto" }}>
        <Typography
          color="primary.dark"
          sx={{ fontSize: "7rem", fontWeight: 700 }}
        >
          404
        </Typography>
      </Box> */}
            <Box sx={{width: "80%", margin: "15px auto"}}>
                {message === true ? (
                    <>
                        <Typography
                            variant="h2"
                            sx={{fontWeight: 900, marginBottom: "20px", color: "#037171"}}
                        >
                            Unauthorized Request
                        </Typography>
                        <Typography variant="body" fontWeight={600}>
                            You are not authorized to view the page you are trying to access.{" "}
                            <br/>
                            Contact your system administrator on further steps
                        </Typography>
                    </>
                ) : (
                    <>
                        <Typography
                            variant="h2"
                            sx={{fontWeight: 900, marginBottom: "20px", color: "#037171"}}
                        >
                            {message.title}
                        </Typography>
                        <Typography variant="body" fontWeight={600}>
                            {message.body}
                        </Typography>
                    </>
                )}
            </Box>
        </Grid>
    );
}
