/* eslint-disable react-hooks/exhaustive-deps */
import {
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Switch,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  getAllUnitOptions,
  getAssetOptions,
  getFacilityOptions,
  getRentDays,
  getRentPeriods,
  getVendorOptions,
} from "../../services/list.service";
import {
  isEmptyString,
  isNullUndefined,
  isValidDate,
  tryParseFloat,
} from "../../util/util";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { ResponsiveRow } from "../../constants/layout.constants";
import ModalContainerModule from "../modules/modalContainer";
import "dayjs/locale/en-gb";

export default function CreateBillReminder(props) {
  const [isCreate, setIsCreate] = useState(true);
  const [vendorOptions, setVendorOptions] = useState([]);
  const [billingPeriods, setBillingPeriods] = useState([]);
  const [facilityOptions, setFacilityOptions] = useState([]);
  const [unitOptions, setUnitOptions] = useState([]);
  const [assetOptions, setAssetOptions] = useState([]);
  const [rentDays, setRentDays] = useState([]);
  const [vendorId, setVendorId] = useState("");
  const [description, setDescription] = useState("");
  const [billingFrequency, setBillingFrequency] = useState("");
  const [billingDate, setBillingDate] = useState("");
  const [billingDay, setBillingDay] = useState("");
  const [isAsset, setIsAsset] = useState(false);
  const [applyVAT, setApplyVAT] = useState(false);
  const [recurringBill, setRecurringBill] = useState(false);
  const [facilityId, setFacilityId] = useState("");
  const [unitId, setUnitId] = useState("");
  const [amount, setAmount] = useState("");
  const [asset, setAsset] = useState("");
  const [completedForm, setCompletedForm] = useState(false);
  const [selectedReminder, setSelectedReminder] = useState(
    props.selectedReminder
  );

  useEffect(() => {
    let tmp = props.selectedReminder;
    if (props.task === "Edit") {
      if (!isNullUndefined(props.selectedReminder.vendorId)) {
        setVendorId(props.selectedReminder.vendorId);
      }
      if (!isNullUndefined(props.selectedReminder.vendorPersonId)) {
        let id = props.selectedReminder.vendorPersonId;
        id = id + "_P";
        setVendorId(id);
        tmp.vendorId = id;
      }
      if (!isNullUndefined(props.selectedReminder.vendorOrgId)) {
        let id = props.selectedReminder.vendorPersonId;
        id = id + "_O";
        setVendorId(id);
        tmp.vendorId = id;
      }
      if (!isNullUndefined(props.selectedReminder.billDate)) {
        let dateYear = new Date(props.selectedReminder.billDate);
        if (isValidDate(dateYear)) {
          setBillingDate(dateYear);
          tmp.billDate = dateYear;
        }
      }
      setBillingDay(props.selectedReminder.billDay);
      setAmount(props.selectedReminder.amount);
      setRecurringBill(props.selectedReminder.recurring);
      setDescription(props.selectedReminder.description);
      setBillingFrequency(props.selectedReminder.billingFrequency);
      setApplyVAT(props.selectedReminder.applyVAT);
      setFacilityId(props.selectedReminder.facilityId);
      setAsset(props.selectedReminder.assetId);
      setUnitId(props.selectedReminder.unitId);
      setIsCreate(false);
    } else {
      setIsCreate(true);
    }
    getVendorOptions().then((vendorOptions) => {
      setVendorOptions(vendorOptions);
    });

    getRentPeriods().then((billingPeriods) =>
      setBillingPeriods(billingPeriods)
    );

    getRentDays().then((rentDays) => setRentDays(rentDays));

    getFacilityOptions().then((facilityOptions) =>
      setFacilityOptions(facilityOptions)
    );

    getAssetOptions().then((assetOptions) => {
      setAssetOptions(assetOptions);
    });

    if (!isNullUndefined(props.selectedReminder.facilityId)) {
      setIsAsset(false);
      setFacilityId(props.selectedReminder.facilityId);
      getAllUnitOptions(props.selectedReminder.facilityId).then(
        (unitOptions) => {
          setUnitOptions(unitOptions);
        }
      );
    } else {
      setIsAsset(true);
    }
    setSelectedReminder(tmp);
  }, [props.selectedReminder]);

  useEffect(() => {
    if (isAsset) {
      setFacilityId("");
      setUnitId("");
    } else {
      setAsset("");
    }
  }, [isAsset]);

  useEffect(() => {
    let tmp = selectedReminder;
    tmp.vendorId = vendorId;
    tmp.amount = tryParseFloat(amount, -1);
    tmp.recurring = recurringBill;
    tmp.billDay = billingDay;
    tmp.billDate = billingDate;
    tmp.billingFrequency = billingFrequency;
    tmp.description = description;
    tmp.facilityId = facilityId;
    tmp.assetId = asset;
    tmp.unitId = unitId;
    tmp.applyVAT = applyVAT;
    setSelectedReminder(tmp);
    if (
      selectedReminder.amount > 0 &&
      !isEmptyString(selectedReminder.vendorId) &&
      !isEmptyString(selectedReminder.description)
    ) {
      if (recurringBill && !isEmptyString(selectedReminder.billingFrequency)) {
        setCompletedForm(false);
        if (
          (selectedReminder.billingFrequency === "QT" ||
            selectedReminder.billingFrequency === "ANU") &&
          isValidDate(billingDate)
        ) {
          if (isAsset && !isEmptyString(asset)) {
            setCompletedForm(true);
          } else if (
            !isAsset &&
            !isEmptyString(facilityId) &&
            !isEmptyString(unitId)
          ) {
            setCompletedForm(true);
          } else {
            setCompletedForm(false);
          }
        }
        if (
          selectedReminder.billingFrequency === "MNTH" &&
          !isEmptyString(billingDay)
        ) {
          if (isAsset && !isEmptyString(asset)) {
            setCompletedForm(true);
          } else if (
            !isAsset &&
            !isEmptyString(facilityId) &&
            !isEmptyString(unitId)
          ) {
            setCompletedForm(true);
          } else {
            setCompletedForm(false);
          }
        }
      } else {
        if (isValidDate(billingDate)) {
          if (isAsset && !isEmptyString(asset)) {
            setCompletedForm(true);
          } else if (
            !isAsset &&
            !isEmptyString(facilityId) &&
            !isEmptyString(unitId)
          ) {
            setCompletedForm(true);
          } else {
            setCompletedForm(false);
          }
        } else {
          setCompletedForm(false);
        }
      }
    }
  }, [
    vendorId,
    amount,
    recurringBill,
    billingDay,
    billingDate,
    billingFrequency,
    description,
    facilityId,
    asset,
    unitId,
    applyVAT,
  ]);

  function handleSave() {
    let reminder = selectedReminder;
    if (isValidDate(reminder.billDate)) {
      let date = new Date(reminder.billDate).toISOString();
      reminder.billDate = date;
    }
    if (!recurringBill) {
      reminder.billingFrequency = null;
      reminder.billDay = null;
    }
    if (isAsset) {
      reminder.facilityId = null;
      reminder.unitId = null;
    } else {
      reminder.assetId = null;
    }
    props.saveReminder(reminder);
  }

  function handleCancel() {
    props.handleClose();
  }

  function handleBillingFrequencyChange(value) {
    setBillingDay("");
    setBillingDate("");
    setBillingFrequency(value);
  }

  function handleFacilityChange(facilityId) {
    if (!isNullUndefined(facilityId)) {
      setFacilityId(facilityId);
      getAllUnitOptions(facilityId).then((unitOptions) => {
        setUnitOptions(unitOptions);
      });
    }
  }

  function renderPropertyForm() {
    return (
      <ResponsiveRow
        sx={{
          height: "auto",
          width: "100%",
          flexDirection: "row",
          flexWrap: { xs: "wrap", lg: "nowrap" },
        }}
      >
        <FormControl
          sx={{
            width: { xs: "100%", lg: "33.3%" },
            height: "auto",
            marginBottom: "20px",
          }}
        >
          <InputLabel id="reminder-payee-label">Property</InputLabel>
          <Select
            labelId="bill-property-label"
            id="bill-property"
            value={facilityId}
            label="Property"
            onChange={(e) => handleFacilityChange(e.target.value)}
            sx={{ width: { xs: "100%", lg: "90%" } }}
          >
            <MenuItem value={""} disabled>
              Select Facility
            </MenuItem>
            {facilityOptions && facilityOptions.length > 0 ? (
              facilityOptions.map(function (facility, i) {
                return (
                  <MenuItem key={facility.value} value={facility.value}>
                    {facility.label}
                  </MenuItem>
                );
              }, this)
            ) : (
              <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
            )}
          </Select>
        </FormControl>
        <FormControl
          sx={{
            width: { xs: "100%", lg: "33.3%" },
            height: "auto",
            marginBottom: "20px",
          }}
        >
          <InputLabel id="reminder-payee-label">Unit</InputLabel>
          <Select
            labelId="bill-unit"
            id="bill-unit"
            value={unitId}
            label="Unit"
            onChange={(e) => setUnitId(e.target.value)}
            sx={{ width: { xs: "100%", lg: "90%" } }}
          >
            <MenuItem disabled value={""}>
              Select Unit
            </MenuItem>
            {unitOptions && unitOptions.length > 0 ? (
              unitOptions.map(function (unit, i) {
                return (
                  <MenuItem key={unit.value} value={unit.value}>
                    {unit.label}
                  </MenuItem>
                );
              }, this)
            ) : (
              <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
            )}
            {unitOptions && unitOptions.length < 1 && (
              <MenuItem disabled value={""}>
                No results found
              </MenuItem>
            )}
          </Select>
        </FormControl>
      </ResponsiveRow>
    );
  }

  function renderAssetForm() {
    return (
      <ResponsiveRow
        sx={{
          height: "auto",
          width: "100%",
          flexDirection: "row",
          flexWrap: { xs: "wrap", lg: "nowrap" },
        }}
      >
        <FormControl
          sx={{
            width: { xs: "100%", lg: "50%" },
            height: "auto",
            margin: "auto 10px",
            padding: "auto 10px",
          }}
        >
          <InputLabel id="bill-asset-label">Asset</InputLabel>
          <Select
            labelId="bill-asset-label"
            id="bill-asset"
            value={asset}
            label="Asset"
            onChange={(e) => setAsset(e.target.value)}
          >
            <MenuItem value={""} disabled>
              Select Asset
            </MenuItem>
            {/* {assetOptions && assetOptions.length < 1 && (
                            <MenuItem disabled value={""}>
                                No results found
                            </MenuItem>
                        )} */}

            {assetOptions && assetOptions.length > 0 ? (
              assetOptions.map(function (asset, i) {
                return (
                  <MenuItem key={asset.value} value={asset.value}>
                    {asset.label}
                  </MenuItem>
                );
              }, this)
            ) : (
              <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
            )}
          </Select>
        </FormControl>
      </ResponsiveRow>
    );
  }

  return (
    <ModalContainerModule
      size="md"
      accept={handleSave}
      cancel={handleCancel}
      openModal={props.openModal}
      modalTitle={isCreate ? "Create New Bill Reminder" : "Edit Bill Reminder"}
      acceptDisabled={!completedForm}
      acceptButtonText="Save"
    >
      <Typography variant="h4" sx={{ marginBottom: "10px", fontWeight: 700 }}>
        Reminder Details
      </Typography>
      <ResponsiveRow
        sx={{
          width: "100%",
          height: "auto",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          flexDirection: "row",
        }}
      >
        <FormControl
          sx={{
            width: { xs: "100%", lg: "33.3%" },
            height: "auto",
            marginBottom: "20px",
          }}
        >
          <InputLabel id="reminder-payee-label">Pay To</InputLabel>
          <Select
            labelId="reminder-payee-label"
            id="reminder-payee"
            value={vendorId}
            label="Pay To"
            onChange={(e) => setVendorId(e.target.value)}
            sx={{ width: { xs: "100%", lg: "90%" } }}
          >
            {vendorOptions && vendorOptions.length > 0 ? (
              vendorOptions.map(function (vendor, i) {
                return (
                  <MenuItem key={vendor.value} value={vendor.value}>
                    {vendor.label}
                  </MenuItem>
                );
              }, this)
            ) : (
              <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
            )}
          </Select>
        </FormControl>

        {recurringBill &&
          (billingFrequency === "ANU" || billingFrequency === "QT") && (
            <FormControl
              sx={{
                width: { xs: "100%", lg: "33.3%" },
                marginBottom: "20px",
              }}
            >
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale="en-gb"
              >
                <DatePicker
                  sx={{ width: { xs: "100%", lg: "90%" } }}
                  id={"billingDate1"}
                  onChange={(newDate) => setBillingDate(newDate)}
                />
              </LocalizationProvider>
            </FormControl>
          )}
        {recurringBill && billingFrequency === "MNTH" && (
          <FormControl
            sx={{
              width: { xs: "100%", lg: "33.3%" },
              marginBottom: "20px",
            }}
          >
            <InputLabel id="reminder-billing-day-label">Billing Day</InputLabel>
            <Select
              labelId="reminder-billing-day-label"
              id="reminder-billing-day"
              value={billingDay}
              label="Billing Day"
              onChange={(e) => setBillingDay(e.target.value)}
              sx={{ width: { xs: "100%", lg: "90%" } }}
            >
              {rentDays && rentDays.length > 0 ? (
                rentDays.map(function (weekDay, i) {
                  return (
                    <MenuItem key={weekDay.value} value={weekDay.value}>
                      {weekDay.label}
                    </MenuItem>
                  );
                }, this)
              ) : (
                <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
              )}
            </Select>
          </FormControl>
        )}
        <FormControl
          sx={{
            width: { xs: "100%", lg: "33.3%" },
            height: "auto",
            marginBottom: "20px",
          }}
        >
          <InputLabel id="payment-amount-label">Amount</InputLabel>
          <OutlinedInput
            labelId="payment-amount-label"
            id="payment-amount"
            type="number"
            min={0}
            value={amount}
            label="Amount"
            onChange={(e) => {
              e.target.value > 0 ? setAmount(e.target.value) : setAmount(0);
            }}
            sx={{ width: { xs: "100%", lg: "90%" } }}
          />
        </FormControl>

        {!recurringBill && (
          <FormControl
            sx={{
              width: { xs: "100%", lg: "33.3%" },
              marginBottom: "20px",
            }}
          >
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale="en-gb"
            >
              <DatePicker
                label="Billing Date"
                sx={{ width: { xs: "100%", lg: "90%" } }}
                id={"billingDate"}
                onChange={(newDate) => setBillingDate(new Date(newDate))}
              />
            </LocalizationProvider>
          </FormControl>
        )}
        {recurringBill && (
          <FormControl
            sx={{
              width: { xs: "100%", lg: "33.3%" },
              marginBottom: "5px",
            }}
          >
            <InputLabel id="reminder-billing-frequency-label">
              Billing Frequency
            </InputLabel>
            <Select
              labelId="reminder-billing-frequency-label"
              id="reminder-billing-frequency"
              value={billingFrequency}
              label="Pay To"
              onChange={(e) => handleBillingFrequencyChange(e.target.value)}
              sx={{ width: { xs: "100%", lg: "90%" } }}
            >
              {billingPeriods && billingPeriods.length > 0 ? (
                billingPeriods.map(function (billingPeriod, i) {
                  return (
                    <MenuItem
                      key={billingPeriod.value}
                      value={billingPeriod.value}
                    >
                      {billingPeriod.label}
                    </MenuItem>
                  );
                }, this)
              ) : (
                <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
              )}
            </Select>
          </FormControl>
        )}
        <ResponsiveRow
          sx={{
            flexWrap: "nowrap",
            alignItems: "center",
            justifyContent: { xs: "space-between", lg: "flex-start" },
            width: { xs: "100%", lg: "33.3%" },
          }}
        >
          <FormControlLabel
            control={<Switch checked={applyVAT} />}
            onChange={() => {
              setApplyVAT(!applyVAT);
            }}
            label="Apply VAT"
            sx={{ margin: "auto 5px" }}
          />
          <FormControlLabel
            control={<Switch checked={recurringBill} />}
            onChange={() => {
              setRecurringBill(!recurringBill);
            }}
            label="Recurring Bill"
            sx={{ margin: "auto 5px" }}
          />
        </ResponsiveRow>
        <FormControl
          sx={{
            width: { xs: "100%", lg: "66.6%" },
            height: "auto",
            marginBottom: "20px",
          }}
        >
          <InputLabel id="bill-memo-label">Memo</InputLabel>
          <OutlinedInput
            labelId="bill-memo-label"
            id="bill-memo"
            value={description}
            label="Memo"
            onChange={(e) => setDescription(e.target.value)}
            sx={{ width: { xs: "100%", lg: "95%" } }}
            multiline
            rows={4}
          />
        </FormControl>
      </ResponsiveRow>
      <Typography variant="h4" sx={{ marginBottom: "0px", fontWeight: 700 }}>
        Bill To
      </Typography>
      <ResponsiveRow
        sx={{
          flexDirection: "row",
          flexWrap: "nowrap",

          alignItems: "flex-start",
          justifyContent: { xs: "space-between", lg: "flex-start" },

          width: "100%",
          height: "auto",

          padding: 0,
        }}
      >
        <ResponsiveRow
          sx={{
            width: { xs: "100%", lg: "50%" },
            height: "auto",
            marginBottom: "0px",

            flexWrap: "nowrap",
          }}
        >
          <Typography sx={{ margin: "auto 2px" }}>Property / Unit</Typography>
          <FormControlLabel
            control={<Switch checked={isAsset} />}
            onChange={() => {
              setIsAsset(!isAsset);
            }}
            label=""
            sx={{ margin: "auto 5px" }}
          />

          <Typography sx={{ margin: "auto 2px" }}>Asset</Typography>
        </ResponsiveRow>
      </ResponsiveRow>
      {isAsset ? renderAssetForm() : renderPropertyForm()}
    </ModalContainerModule>
  );
}
