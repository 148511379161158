import {FormControl, InputLabel, OutlinedInput, TextField} from "@mui/material"
// import React from "react"
import {TopBarContainedBrandButton, TopBarContainer,} from "../../constants/component.constants"
import {ResponsiveRow} from "../../constants/layout.constants"

export default function QuotationTopBar(props) {

    return (
        <TopBarContainer
            container
            sx={{
                justifyContent: {xs: "center", lg: "space-between"},
                alignItem: "center",
                width: {xs: "100%", lg: "80%"},
                height: "auto",
                // padding: "10px",
                boxShadow: "0 4px 5px 0 rgba(0,0,0,0.5)",
                marginBottom: "40px",
            }}
        >
            <ResponsiveRow
                sx={{
                    flexWrap: {xs: "wrap", lg: "nowrap"},
                    justifyContent: {lg: "flex-start", xs: "space-around"},
                    alignItems: "center",
                    width: {lg: "50%", xs: "100%"},
                    height: "auto",
                    padding: 0,
                }}
            >
                <FormControl
                    sx={{
                        marginBottom: "10px",
                        width: {xs: "100%", lh: "50%"},
                    }}
                >
                    <InputLabel id="workOrderReferenceNo">Search Work Order Reference No</InputLabel>
                    <OutlinedInput
                        label={"Work Order Reference"}
                        sx={{width: {xs: "100%", lg: "90%"}}}
                        id={"reference"}
                        onChange={(event) => props.setWorkOrderReferenceNo(event.target.value)}
                        value={props.workOrderReferenceNo}
                        
                    />
                </FormControl>
            </ResponsiveRow>

            <ResponsiveRow
                sx={{
                    justifyContent: {lg: "flex-end", xs: "center"},
                    alignItems: "center",
                    width: {lg: "50%", xs: "100%"},
                    height: "auto",
                    padding: 0,
                }}
            >
                <TopBarContainedBrandButton
                    variant="contained"
                    sx={{
                        width: {xs: "100%", lg: "250px"},
                        height: "50px",
                    }}
                    // type="submit"
                    onClick={props.handleCreateQuotation}
                >
                    Create Quotation
                </TopBarContainedBrandButton>
            </ResponsiveRow>
        </TopBarContainer>
    )
}
