import {CircularProgress, Typography} from "@mui/material";

import {ResponsiveRow} from "../../constants/layout.constants";
import {isNullUndefined} from "../../util/util";

/**
 *
 * REQUIRED PROPS
 *
 *  message: string
 *
 */

export default function LoadingOverlay({message}) {
    return (
        <ResponsiveRow
            sx={{
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                width: "100%",
                margin: "auto",
            }}
        >
            <CircularProgress sx={{margin: "10px auto"}}/>

            <Typography color="black" fontWeight={700}>
                {!isNullUndefined(message) ? message : "Loading Data, please wait"}
            </Typography>
        </ResponsiveRow>
    );
}
