import ModalContainerModule from "../../../modules/modalContainer";

import { ResponsiveRow } from "../../../../constants/layout.constants";
import {
  DetailsColumn,
  DetailsContainer,
  DetailsLabel,
  DetailsRow,
  DetailsText,
} from "../../../../constants/component.constants";
import { DocumentStore } from "../../../documentStore";

/**
 *
 * REQUIRED PROPS
 *
 * voucher
 *
 */
export default function ViewKRAPaymentModal(props) {
  return (
    <ModalContainerModule
      size="md"
      cancel={props.handleCloseView}
      openModal={props.openModal}
      modalTitle={"KRA Payment Details: " + props?.voucher?.referenceNumber}
    >
      <ResponsiveRow
        container
        sx={{ paddingTop: 0, margin: "0 auto", height: "auto", width: "100%" }}
      >
        <DetailsContainer
          sx={{ width: "100%", height: "auto", margin: "0 auto" }}
        >
          <DetailsColumn sx={{ width: { xs: "100%", lg: "20%" } }}>
            <DetailsRow
              sx={{
                flexDirection: "column",
                flexWrap: "nowrap",
                width: "100%",
              }}
            >
              <DetailsLabel sx={{ width: "100%" }}>Tax Type:</DetailsLabel>
              <DetailsText sx={{ width: "100%" }}>
                {props?.voucher?.description?.includes("VAT")
                  ? "VAT"
                  : props?.voucher?.description?.includes("(WTH)")
                  ? "Withholding"
                  : props?.voucher?.taxType}
              </DetailsText>
            </DetailsRow>
            <DetailsRow
              sx={{
                flexDirection: "column",
                flexWrap: "nowrap",
                width: "100%",
              }}
            >
              <DetailsLabel sx={{ width: "100%" }}>Amount:</DetailsLabel>
              <DetailsText sx={{ width: "100%" }}>
                {props?.voucher?.amount} KES
              </DetailsText>
            </DetailsRow>
            <DetailsRow
              sx={{
                flexDirection: "column",
                flexWrap: "nowrap",
                width: "100%",
              }}
            >
              <DetailsLabel sx={{ width: "100%" }}>Paid to:</DetailsLabel>
              <DetailsText sx={{ width: "100%" }}>
                {props?.voucher?.paidTo}
              </DetailsText>
            </DetailsRow>
            <DetailsRow
              sx={{
                flexDirection: "column",
                flexWrap: "nowrap",
                width: "100%",
              }}
            >
              <DetailsLabel sx={{ width: "100%" }}>Applied to:</DetailsLabel>
              <DetailsText sx={{ width: "100%" }}>
                {props?.voucher?.appliedTo}
              </DetailsText>
            </DetailsRow>
          </DetailsColumn>
          <DetailsColumn sx={{ width: { xs: "100%", lg: "20%" } }}>
            <DetailsRow
              sx={{
                flexDirection: "column",
                flexWrap: "nowrap",
                width: "100%",
              }}
            >
              <DetailsLabel sx={{ width: "100%" }}>Voucher Date:</DetailsLabel>
              <DetailsText sx={{ width: "100%" }}>
                {props?.voucher?.date}
              </DetailsText>
            </DetailsRow>
            <DetailsRow
              sx={{
                flexDirection: "column",
                flexWrap: "nowrap",
                width: "100%",
              }}
            >
              <DetailsLabel sx={{ width: "100%" }}>Status:</DetailsLabel>
              <DetailsText sx={{ width: "100%" }}>
                {props?.voucher?.status}
              </DetailsText>
            </DetailsRow>
            <DetailsRow
              sx={{
                flexDirection: "column",
                flexWrap: "nowrap",
                width: "100%",
              }}
            >
              <DetailsLabel sx={{ width: "100%" }}>Description:</DetailsLabel>
              <DetailsText sx={{ width: "100%" }}>
                {props?.voucher?.description}
              </DetailsText>
            </DetailsRow>
          </DetailsColumn>
          <DetailsColumn sx={{ width: { xs: "100%", lg: "60%" } }}>
            {props?.voucher?.imageFiles && (
              <DocumentStore
                height="300px"
                files={[
                  ...props.voucher.imageFiles,
                  ...props.voucher.otherDocuments,
                ]}
                item={props?.voucher}
                type={"Payment"}
                horizontalLayout={true}
              />
            )}
          </DetailsColumn>
        </DetailsContainer>
      </ResponsiveRow>
    </ModalContainerModule>
  );
}
