import {FormControl, InputLabel, MenuItem, OutlinedInput, Select, TextField,} from "@mui/material";
import {ResponsiveRow} from "../../../../constants/layout.constants";
import {FileUpload} from "../../../inputs/fileUpload";
import ModalContainerModule from "../../../modules/modalContainer";

export default function TansferDetailsModal(props) {
    /**
     *
     * handleCloseTransferFundsClicked
     *
     * bankAccountsList
     * bulkFile
     * bulkFileProgress
     * cashAccounts
     * chequeNo
     * facilityAmount
     * fileValueChanged
     * memo
     * paymentMethods
     * saveTransfer
     * selectedBankAcc
     * selectedPaymentMethod
     * selectedTransfer
     * task
     * transferForm
     *
     * setSelectedBankAcc
     * setSelectedTransfer
     * setFacilityAmount
     * setSelectedPaymentMethod
     * setChequeNo
     * selectedCashAccount
     * setSelectedCashAccount
     * setMemo
     *
     */

    return (
      <ModalContainerModule
        size="specialTransferDetails"
        accept={props.saveTransfer}
        cancel={props.handleCloseTransferFundsClicked}
        openModal={props.transferForm && props.task === "Create"}
        modalTitle="Transfer Details"
        acceptButtonText="Save"
      >
        <ResponsiveRow
          sx={{
            width: "100%",
            height: "auto",
            justifyContent: { xs: "center", lg: "flex-start" },
          }}
        >
          <FormControl
            item
            sx={{ width: { xs: "100%", lg: "33.3%" }, marginBottom: "10px" }}
          >
            <InputLabel>Transfer To</InputLabel>
            <Select
              label="Transfer To"
              sx={{ width: { xs: "100%", lg: "90%" } }}
              id={"transferTo"}
              value={props.selectedBankAcc}
              onChange={(event) => {
                let value = event.target.value;
                props.setSelectedBankAcc(value);
                let obj = props.selectedTransfer;
                obj.bankId = value;
                props.setSelectedTransfer(obj);
              }}
            >
              {props.bankAccountsList && props.bankAccountsList.length > 0 ? (
                props.bankAccountsList.map(function (item, i) {
                  return (
                    <MenuItem key={i} value={item.value}>
                      {item.label}
                    </MenuItem>
                  );
                }, this)
              ) : (
                <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
              )}
            </Select>
          </FormControl>

          <FormControl
            item
            sx={{ width: { xs: "100%", lg: "33.3%" }, marginBottom: "10px" }}
          >
            <InputLabel>Amount </InputLabel>
            <OutlinedInput
              label="Amount"
              placeholder={"Amount"}
              sx={{ width: { xs: "100%", lg: "90%" } }}
              id={"amount"}
              disabled
              onChange={(event) => {
                let value = event.target.value;
                props.setFacilityAmount(value);
                let obj = props.selectedTransfer;
                obj.amount = value;
                props.setSelectedTransfer(obj);
              }}
              value={props.facilityAmount}

            />
          </FormControl>

          <FormControl
            item
            sx={{ width: { xs: "100%", lg: "33.3%" }, marginBottom: "10px" }}
          >
            <InputLabel>Payment Method</InputLabel>
            <Select
              label="Payment Method"
              sx={{ width: { xs: "100%", lg: "90%" } }}
              id={"paymentMethod"}
              value={props.selectedPaymentMethod}
              onChange={(event) => {
                let value = event.target.value;
                props.setSelectedPaymentMethod(value);
                let obj = props.selectedTransfer;
                obj.paymentMethodCd = value;
                props.setSelectedTransfer(obj);
              }}
            >
              {props.paymentMethods && props.paymentMethods.length > 0 ? (
                props.paymentMethods.map(function (item, i) {
                  return (
                    <MenuItem key={i} value={item.value}>
                      {item.label}
                    </MenuItem>
                  );
                }, this)
              ) : (
                <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
              )}
            </Select>
          </FormControl>

          {props.selectedPaymentMethod === "CHQ" && (
            <FormControl
              item
              sx={{ width: { xs: "100%", lg: "33.3%" }, marginBottom: "10px" }}
            >
              <InputLabel>Cheque No </InputLabel>
              <OutlinedInput
                label="Cheque No"
                placeholder={"Cheque No"}
                sx={{ width: { xs: "100%", lg: "90%" } }}
                id={"chequeNo"}
                onChange={(event) => {
                  let value = event.target.value;
                  props.setChequeNo(value);
                  let obj = props.selectedTransfer;
                  obj.chequeNumber = value;
                  props.setSelectedTransfer(obj);
                }}
                value={props.chequeNo}

              />
            </FormControl>
          )}
            {props.selectedPaymentMethod === "RTGS" && (
                <FormControl
                    item
                    sx={{ width: { xs: "100%", lg: "33.3%" }, marginBottom: "10px" }}
                >
                    <InputLabel>Bank Reference Number </InputLabel>
                    <OutlinedInput
                        label="Bank Reference Number"
                        placeholder={"Bank Reference Number"}
                        sx={{ width: { xs: "100%", lg: "90%" } }}
                        id={"bankRefNumber"}
                        onChange={(event) => {
                            let value = event.target.value;
                            props.setBankRefNumber(value);
                            let obj = props.selectedTransfer;
                            obj.bankRefNumber = value;
                            props.setSelectedTransfer(obj);
                        }}
                        value={props.bankRefNumber}

                    />
                </FormControl>
            )}
          {props.selectedPaymentMethod === "CSH" && (
            <FormControl
              item
              sx={{ width: { xs: "100%", lg: "33.3%" }, marginBottom: "10px" }}
            >
              <InputLabel>Cash Account</InputLabel>
              <Select
                label="Cash Account"
                sx={{ width: { xs: "100%", lg: "90%" } }}
                id={"cashAcc"}
                value={props.selectedCashAccount}
                onChange={(event) => {
                  let value = event.target.value;
                  props.setSelectedCashAccount(value);
                  let obj = props.selectedTransfer;
                  obj.cashAccount = value;
                  props.setSelectedTransfer(obj);
                }}

              >
                {props.cashAccounts && props.cashAccounts.length > 0 ? (
                  props.cashAccounts.map(function (item, i) {
                    return (
                      <MenuItem key={i} value={item.value}>
                        {item.label}
                      </MenuItem>
                    );
                  }, this)
                ) : (
                  <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
                )}
              </Select>
            </FormControl>
          )}
          <FormControl
            item
            sx={{ width: { xs: "100%", lg: "33.3%" }, marginBottom: "10px" }}
          >
            <FileUpload
              id={"bulkFileInput"}
              label={"Attach Documents"}
              currentFile={props.bulkFile}
              progress={props.bulkFileProgress}
              onChange={props.fileValueChanged}
              accept={
                ".xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              }
            />
          </FormControl>
          <FormControl
            item
            sx={{ width: { xs: "100%", lg: "66.6%" }, marginBottom: "10px" }}
          >
            <InputLabel>Memo </InputLabel>
            <OutlinedInput
              label="Memo "
              multiline
              rows={4}
              placeholder={"Memo"}
              sx={{ width: { xs: "100%", lg: "95%" } }}
              id={"memo"}
              onChange={(event) => {
                let value = event.target.value;
                props.setMemo(value);
                let obj = props.selectedTransfer;
                obj.description = value;
                props.setSelectedTransfer(obj);
              }}
              value={props.memo}

            />
          </FormControl>
        </ResponsiveRow>
      </ModalContainerModule>
    );
}
