/* eslint-disable no-array-constructor */
import {Checkbox, Divider, FormControl, FormLabel, MenuItem, Select, TextField, Typography,} from "@mui/material"
import {ResponsiveRow} from "../../../constants/layout.constants"
import AutoAddSelect from "../../cards/listings/component.autoAddSelect"
import MyListFavourModalItem from "../../cards/listings/myListFavourModalItem"
import ListingModalContainerModule from "../../modules/listingModalContainer"
import {useMemo, useState} from "react"

const favourites = [
    {
        name: "favourite-1",
        label: "Favourite One",
        locations: "Westlands, Nairobi",
        price: "KES 150,000",
    },
    {
        name: "favourite-2",
        label: "Favourite Two",
        locations: "Westlands, Nairobi",
        price: "KES 150,000",
    },
    {
        name: "favourite-3",
        label: "Favourite Three",
        locations: "Westlands, Nairobi",
        price: "KES 150,000",
    },
    {
        name: "favourite-4",
        label: "Favourite Four",
        locations: "Westlands, Nairobi",
        price: "KES 150,000",
    },
    {
        name: "favourite-5",
        label: "Favourite Five",
        locations: "Westlands, Nairobi",
        price: "KES 150,000",
    },
    {
        name: "favourite-6",
        label: "Favourite Six",
        locations: "Westlands, Nairobi",
        price: "KES 150,000",
    },
    {
        name: "favourite-7",
        label: "Favourite Seven",
        locations: "Westlands, Nairobi",
        price: "KES 150,000",
    },
    {
        name: "favourite-8",
        label: "Favourite Eight",
        locations: "Westlands, Nairobi",
        price: "KES 150,000",
    },
]

export default function AddListingList(props) {
    const [isSmartList, setIsSmartList] = useState(false)
    const [smartParams, setSmartParams] = useState([])
    const [addedFavourites, addFavourites] = useState([])

    const [location, setLocation] = useState()
    const [rooms, setRooms] = useState(1)
    const [baths, setBaths] = useState(1)
    const [squareFeet, setSquareFeet] = useState(100)
    const [listingType, setListingType] = useState("unselected")

    function handleChange() {
        setIsSmartList(!isSmartList)
    }

    function addToFavourites(item) {
        const updated_favourites = addedFavourites || []

        updated_favourites.push(item.name)
        addFavourites(updated_favourites)
        return addedFavourites
    }

    function removeFavourites(item) {
        const item_index = addedFavourites.indexOf(item.name)

        const updated_favourites = addedFavourites.splice(item_index, 1)

        addFavourites(updated_favourites)

        return addedFavourites
    }

    function handleAccept() {
    }

    function handleClose() {
        props.closeModal()
    }

    function handleSmartChange(e) {
        const inputName = e.target.name

        switch (inputName) {
            case "location":
                setLocation(e.target.value)
                break

            case "rooms":
                setRooms(e.target.value)
                break

            case "baths":
                setBaths(e.target.value)
                break

            case "squareFeet":
                setSquareFeet(e.target.value)
                break

            case "listingType":
                setListingType(e.target.value)
                break

            default:
                break
        }
    }

    useMemo(() => {
        if (!isSmartList) {
            setSmartParams([])
        }
    }, [isSmartList])

    return (
        <ListingModalContainerModule
            size="specialAddListing"
            accept={handleAccept}
            cancel={handleClose}
            openModal={props.openModal}
            modalTitle="New List"
            modalSubTitle="Create Custom List"
            acceptButtonText="Submit"
        >
            <ResponsiveRow
                sx={{flexDirection: "column", textStart: "start", height: "auto"}}
            >
                <Typography variant="body2">
                    Please fill in the form below to create a new personal list of
                    listings
                </Typography>
                <ResponsiveRow sx={{width: "100%", height: "auto"}}>
                    <ResponsiveRow
                        sx={{
                            flexDirection: "column",
                            width: {xs: "100%", lg: "33.3%"},
                            height: "auto",
                        }}
                    >
                        <FormControl sx={{width: "100%"}}>
                            <FormLabel>List Name</FormLabel>
                            <TextField
                                variant="outlined"
                                sx={{
                                    "&.MuiTextField-root": {
                                        backgroundColor: "#f5f5f5",
                                        borderRadius: "10px",
                                    },
                                }}
                            />
                        </FormControl>
                        <ResponsiveRow
                            component={FormControl}
                            sx={{
                                width: "100%",
                                height: "auto",
                                alignItems: "center",
                                justifyContent: "flex-start",
                                color: "#000",
                                padding: 0,
                            }}
                        >
                            <Checkbox
                                sx={{color: "#037171"}}
                                value={isSmartList}
                                onChange={handleChange}
                            />
                            <FormLabel
                                sx={{color: "#000"}}
                                id="demo-radio-buttons-group-label"
                            >
                                Smart List
                            </FormLabel>
                            <ResponsiveRow sx={{width: "100%", padding: 0}}>
                                {isSmartList && (
                                    <AutoAddSelect loadSelectedParams={setSmartParams}/>
                                )}
                            </ResponsiveRow>
                        </ResponsiveRow>
                    </ResponsiveRow>
                    <ResponsiveRow
                        sx={{
                            flexDirection: "column",
                            width: {xs: "100%", lg: "66.6%"},
                            height: "auto",
                        }}
                    >
                        <FormControl sx={{width: "100%"}}>
                            <FormLabel>List Description</FormLabel>
                            <TextField
                                variant="outlined"
                                multiline
                                rows={7}
                                sx={{
                                    "&.MuiTextField-root": {
                                        backgroundColor: "#f5f5f5",
                                        borderRadius: "10px",
                                    },
                                }}
                            />
                        </FormControl>
                    </ResponsiveRow>
                </ResponsiveRow>
                {(smartParams.length > 0 && smartParams.includes("Location")) ||
                (smartParams.length > 0 && smartParams.includes("Number of Rooms")) ||
                (smartParams.length > 0 && smartParams.includes("Number of Baths")) ||
                (smartParams.length > 0 && smartParams.includes("Square Feet")) ||
                (smartParams.length > 0 && smartParams.includes("Listing Type")) ? (
                    <ResponsiveRow sx={{width: "100%", height: "auto"}}>
                        <Divider sx={{width: "100%", marginBottom: "20px"}}/>

                        <Typography
                            variant="h5"
                            color="primary.dark"
                            sx={{width: "100%", marginBottom: "10px"}}
                        >
                            Set Auto Add Parameters
                        </Typography>
                        {smartParams.length > 0 && smartParams.includes("Location") && (
                            <FormControl sx={{width: {xs: "100%", lg: "33.3%"}}}>
                                <FormLabel>Location</FormLabel>
                                <TextField
                                    type="text"
                                    variant="outlined"
                                    value={location}
                                    name="location"
                                    onChange={handleSmartChange}
                                    sx={{
                                        width: "90%",
                                        "&.MuiTextField-root": {
                                            backgroundColor: "#f5f5f5",
                                            borderRadius: "10px",
                                        },
                                    }}
                                />
                            </FormControl>
                        )}
                        {smartParams.length > 0 &&
                            smartParams.includes("Number of Rooms") && (
                                <FormControl sx={{width: {xs: "100%", lg: "33.3%"}}}>
                                    <FormLabel>Number of Rooms</FormLabel>
                                    <TextField
                                        type="number"
                                        InputProps={{inputProps: {min: 1}}}
                                        variant="outlined"
                                        value={rooms}
                                        name="rooms"
                                        onChange={handleSmartChange}
                                        sx={{
                                            width: "90%",
                                            "&.MuiTextField-root": {
                                                backgroundColor: "#f5f5f5",
                                                borderRadius: "10px",
                                            },
                                        }}
                                    />
                                </FormControl>
                            )}
                        {smartParams.length > 0 &&
                            smartParams.includes("Number of Baths") && (
                                <FormControl sx={{width: {xs: "100%", lg: "33.3%"}}}>
                                    <FormLabel>Number of Baths</FormLabel>
                                    <TextField
                                        type="number"
                                        InputProps={{inputProps: {min: 1}}}
                                        variant="outlined"
                                        value={baths}
                                        name="baths"
                                        onChange={handleSmartChange}
                                        sx={{
                                            width: "90%",
                                            "&.MuiTextField-root": {
                                                backgroundColor: "#f5f5f5",
                                                borderRadius: "10px",
                                            },
                                        }}
                                    />
                                </FormControl>
                            )}
                        {smartParams.length > 0 && smartParams.includes("Square Feet") && (
                            <FormControl sx={{width: {xs: "100%", lg: "33.3%"}}}>
                                <FormLabel>Square Feet</FormLabel>
                                <TextField
                                    type="number"
                                    InputProps={{inputProps: {min: 100}}}
                                    variant="outlined"
                                    value={squareFeet}
                                    name="squareFeet"
                                    onChange={handleSmartChange}
                                    sx={{
                                        width: "90%",
                                        "&.MuiTextField-root": {
                                            backgroundColor: "#f5f5f5",
                                            borderRadius: "10px",
                                        },
                                    }}
                                />
                            </FormControl>
                        )}
                        {smartParams.length > 0 && smartParams.includes("Listing Type") && (
                            <FormControl sx={{width: {xs: "100%", lg: "33.3%"}}}>
                                <FormLabel>Listing Type</FormLabel>
                                <Select
                                    variant="outlined"
                                    onChange={handleSmartChange}
                                    value={listingType}
                                    name="listingType"
                                    sx={{
                                        width: "90%",
                                        "&.MuiTextField-root": {
                                            backgroundColor: "#f5f5f5",
                                            borderRadius: "10px",
                                        },
                                    }}
                                >
                                    <MenuItem disabled value="unselected">
                                        --Select Listing Type--
                                    </MenuItem>
                                    <MenuItem value="residential">Residential</MenuItem>
                                    <MenuItem value="commercial">Commercial</MenuItem>
                                </Select>
                            </FormControl>
                        )}
                    </ResponsiveRow>
                ) : (
                    <ResponsiveRow>
                        <Divider sx={{width: "100%", marginBottom: "20px"}}/>

                        <Typography
                            variant="h5"
                            color="primary.dark"
                            sx={{width: "100%", marginBottom: "10px"}}
                        >
                            Manually Select Favourites
                        </Typography>
                        <ResponsiveRow sx={{width: "100%", height: "auto"}}>
                            {favourites.map((item, index) => (
                                <MyListFavourModalItem
                                    key={index}
                                    addToFavourites={addToFavourites}
                                    removeFromFavourites={removeFavourites}
                                    item={item}
                                    currentList={addedFavourites}
                                />
                            ))}
                        </ResponsiveRow>
                    </ResponsiveRow>
                )}
            </ResponsiveRow>
        </ListingModalContainerModule>
    )
}
