import {Typography} from "@mui/material"
import {
    DetailsColumn,
    DetailsContainer,
    DetailsLabel,
    DetailsRow,
    DetailsText,
    ModalAcceptButton,
} from "../../constants/component.constants"
import {ResponsiveRow} from "../../constants/layout.constants"

export default function ComplexInfoCard(props) {
    /**
     *
     *  REQUIRED PROPS
     *
     *  complex
     *  handleEditClick
     *  handleInspectComplexClick
     *  handleViewInspectionsClick
     *
     */
    return (
        <ResponsiveRow
            container
            sx={{
                flexDirection: "column",
                flexWrap: "nowrap",
                alignItems: "center",
                justifyContent: "center",
                alignText: "start",
                backgroundColor: "#fff",
                width: {xs: "100%", lg: "450px"},
                height: {xs: "auto", lg: "250px"},
                margin: {xs: "10px", lg: "20px"},
                borderRadius: "10px",
                boxShadow: "0 4px 5px 0 rgba(0,0,0,0.5)",
            }}
        >
            <ResponsiveRow
                sx={{
                    justifyContent: "flex-start",
                    textAlign: "start",
                    width: "100%",
                    padding: "10px",
                    paddingBottom: 0,
                }}
            >
                <Typography
                    variant="h1"
                    sx={{
                        fontSize: "1.3em",
                        fontWeight: 900,
                        alignText: "start",
                        width: "100%",
                        color: "#037171",
                    }}
                >
                    {props.complex.name}
                </Typography>
            </ResponsiveRow>
            <DetailsContainer sx={{marginTop: "0", padding: 0, width: "100%"}}>
                <DetailsColumn
                    sx={{
                        marginTop: 0,
                        width: "100%",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                    }}
                >
                    <DetailsRow
                        sx={{
                            width: "100%",
                            justifyContent: "space-between",
                            textAlign: "start",
                            // padding: "5px 10px ",
                        }}
                    >
                        <DetailsLabel>Complex Address</DetailsLabel>
                        <DetailsText>{props.complex.fullAddress}</DetailsText>
                    </DetailsRow>
                    <DetailsRow
                        sx={{
                            width: "100%",
                            justifyContent: "space-between",
                            textAlign: "start",
                            // padding: "5px 10px ",
                        }}
                    >
                        <DetailsLabel>LR Number</DetailsLabel>
                        <DetailsText>{props.complex.lrNumber}</DetailsText>
                    </DetailsRow>
                    <DetailsRow
                        sx={{
                            width: "100%",
                            justifyContent: "space-between",
                            textAlign: "start",
                            // padding: "5px 10px ",
                        }}
                    >
                        <DetailsLabel>Location</DetailsLabel>
                        <DetailsText>
                            {props.complex.address.city}, {props.complex.address.countryCd}
                        </DetailsText>
                    </DetailsRow>
                    <ResponsiveRow
                        sx={{
                            width: "100%",
                            justifyContent: {
                                xs: "center",
                                md: "flex-start",
                                lg: "flex-end",
                            },
                            marginTop: "20px",
                            padding: " 0 ",
                        }}
                    >
                        <ModalAcceptButton
                            variant="contained"
                            sx={{
                                fontSize: "12px",
                                padding: "10px",
                                width: {xs: "100%", md: "140px", lg: "auto"},
                            }}
                            onClick={() => props.handleEditClick(props.complex)}
                        >
                            Edit
                        </ModalAcceptButton>
                        <ModalAcceptButton
                            variant="contained"
                            sx={{
                                fontSize: "12px",
                                padding: "10px",
                                width: {xs: "100%", md: "140px", lg: "auto"},
                            }}
                            onClick={() => props.handleInspectComplexClick(props.complex)}
                        >
                            Add Inspection
                        </ModalAcceptButton>
                        <ModalAcceptButton
                            variant="contained"
                            sx={{
                                fontSize: "12px",
                                padding: "10px",
                                width: {xs: "100%", md: "140px", lg: "auto"},
                            }}
                            onClick={() => props.handleViewInspectionsClick(props.complex)}
                        >
                            View Inspections
                        </ModalAcceptButton>
                    </ResponsiveRow>
                </DetailsColumn>
            </DetailsContainer>
        </ResponsiveRow>
    )
}
