/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useMemo, useState} from "react"
import {isEmptyString, isNullUndefined} from "../util/util"
import {MainContainer} from "../constants/component.constants"
import {useLocation} from "react-router"
import {rebuildLeaseHistory, rebuildOwnershipHistory} from "../services/repair.service";
import {testCreditTransfer, testTransactionQuery} from "../services/test.service";

export default function Test() {
    const [responseMessage, setResponseMessage] = useState("Processing!!! ... ")

    function useQuery() {
        const {search} = useLocation()
        return useMemo(() => new URLSearchParams(search), [search])
    }

    const routeParams = useQuery()
    let url = window.location.href
    let isTestCreditTransfer = url.indexOf("testCreditTransfer") > 1
    let istestTransactionQuery = url.indexOf("testTransactionQuery") > 1
    let reference = routeParams.get("reference")

    useEffect(() => {
        if (!isNullUndefined(reference) && !isEmptyString(reference)) {
            if (isTestCreditTransfer) {
                testCreditTransfer(reference).then((response) => {
                    setResponseMessage(response)
                })
            } else if (istestTransactionQuery) {
                testTransactionQuery(reference).then((response) => {
                    setResponseMessage(response)
                })
            }
        } else {
            setResponseMessage("No reference code specified")
        }
    }, [])

    return (
        <MainContainer>
            {JSON.stringify(responseMessage)}
        </MainContainer>
    )
}
