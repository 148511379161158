import {
    PRINT_BILL_URL,
    PRINT_CAPITAL_FUNDS,
    PRINT_CHARGE_URL,
    PRINT_CLIENT_CHARGE_URL,
    PRINT_CLIENT_CREDIT_URL,
    PRINT_CLIENT_INVOICE_URL,
    PRINT_CLIENT_RECEIPT_URL,
    PRINT_CLIENT_STATEMENT_URL,
    PRINT_CREDIT_URL,
    PRINT_CUSTOMER_SALES_STATEMENT_URL,
    PRINT_INVOICE,
    PRINT_LEASE_STATEMENT_URL,
    PRINT_LEASE_URL,
    PRINT_METER_BILLING_STATEMENT_URL,
    PRINT_METER_BILLING_URL,
    PRINT_OWNERSHIP_STATEMENT_URL,
    PRINT_OWNERSHIP_URL,
    PRINT_PAYMENT_VOUCHER_URL,
    PRINT_RECEIPT_URL,
    PRINT_RECONCILIATION_URL,
    PRINT_WORK_ORDER_LPO_DOCUMENT_URL,
    PRINT_WORK_ORDER_URL,
} from "../constants/url.constants"
import {serviceAPIDownloadFile} from "./service"

//GET REQUEST
export const printLeaseStatement = (leaseId, year) => {
    let url = PRINT_LEASE_STATEMENT_URL + leaseId + "&year=" + year
    return serviceAPIDownloadFile.get(url)
}

export const printLease = (leaseId) => {
    let url = PRINT_LEASE_URL + leaseId
    return serviceAPIDownloadFile.get(url)
}

export const printClientStatement = (subscriptionId, year) => {
    let url = PRINT_CLIENT_STATEMENT_URL + subscriptionId + "&year=" + year
    serviceAPIDownloadFile.get(url).then(r => Promise.resolve())
}

export const printCapitalFunds = (capitalFundId) => {
    return serviceAPIDownloadFile.get(PRINT_CAPITAL_FUNDS + capitalFundId)
}

export const printOwnershipStatement = (titleId, year) => {
    let url = PRINT_OWNERSHIP_STATEMENT_URL + titleId + "&year=" + year
    return serviceAPIDownloadFile.get(url)
}

export const printOwnership = (titleId) => {
    let url = PRINT_OWNERSHIP_URL + titleId
    return serviceAPIDownloadFile.get(url)
}

export const printLPO = (workOrderId, vendorId) => {
    return serviceAPIDownloadFile.get(PRINT_WORK_ORDER_LPO_DOCUMENT_URL + workOrderId + "&vendorId=" + vendorId)
}

export const printWorkOrder = (workOrderId) => {
    return serviceAPIDownloadFile.get(PRINT_WORK_ORDER_URL + workOrderId)
}

export const printInvoice = (invoiceId) => {
    return serviceAPIDownloadFile.get(PRINT_INVOICE + invoiceId)
}

export const printClientInvoice = (invoiceId) => {
    return serviceAPIDownloadFile.get(PRINT_CLIENT_INVOICE_URL + invoiceId)
}

export const printClientReceipt = (receiptId) => {
    return serviceAPIDownloadFile.get(PRINT_CLIENT_RECEIPT_URL + receiptId)
}

export const printReceipt = (receiptId) => {
    return serviceAPIDownloadFile.get(PRINT_RECEIPT_URL + receiptId)
}

export const printCredit = (creditId) => {
    return serviceAPIDownloadFile.get(PRINT_CREDIT_URL + creditId)
}

export const printClientCredit = (creditId) => {
    return serviceAPIDownloadFile.get(PRINT_CLIENT_CREDIT_URL + creditId)
}

export const printClientCharge = (chargeId) => {
    return serviceAPIDownloadFile.get(PRINT_CLIENT_CHARGE_URL + chargeId)
}

export const printCharge = (chargeId) => {
    return serviceAPIDownloadFile.get(PRINT_CHARGE_URL + chargeId)
}

export const printBill = (billId) => {
    let url = PRINT_BILL_URL + billId
    return serviceAPIDownloadFile.get(url)
}

export const printPaymentVoucher = (paymentVoucherId) => {
    let url = PRINT_PAYMENT_VOUCHER_URL + paymentVoucherId
    return serviceAPIDownloadFile.get(url)
}

export const printReconciliation = (reconciliationId) => {
    return serviceAPIDownloadFile.get(PRINT_RECONCILIATION_URL + reconciliationId)
}

export const printMeterBillingStatement = (meterBillingId, year) => {
    let url = PRINT_METER_BILLING_STATEMENT_URL + meterBillingId + "&year=" + year
    return serviceAPIDownloadFile.get(url)
}

export const printMeterBilling = (meterBillingId) => {
    let url = PRINT_METER_BILLING_URL + meterBillingId
    return serviceAPIDownloadFile.get(url)
}

export const printCustomerSalesStatement = (billingId, year) => {
    let url = PRINT_CUSTOMER_SALES_STATEMENT_URL + billingId + "&year=" + year
    return serviceAPIDownloadFile.get(url)
}
