import {Alert} from "@mui/material"
import {ResponsiveRow} from "../../../../constants/layout.constants"
import {formatDateTime} from "../../../../util/util"
import ModalContainerModule from "../../../modules/modalContainer"

export default function RepairMeterModal(props) {
    /**
     *
     * REQUIRED PROPS
     *
     * meterFixed
     * RepairModal
     *
     * setRepairModal
     * setselectedMeter
     *
     * selectedMeter
     */
    return (
        <ModalContainerModule
            size="xs"
            accept={props.meterFixed}
            cancel={() => {
                props.setRepairModal(false)
            }}
            openModal={props.RepairModal}
            modalTitle="Broken Meter Details"
            acceptButtonText="Meter Fixed"
        >
            <ResponsiveRow sx={{justifyContent: "100%"}}>
                <Alert severity="info">
                    This meter was reported broken at{" "}
                    {props.selectedMeter &&
                        formatDateTime(props.selectedMeter.brokenTime)}{" "}
                    by {props.selectedMeter && props.selectedMeter.reportedBy}
                </Alert>
            </ResponsiveRow>
        </ModalContainerModule>
    )
}
