import axios from 'axios';
import {
	FETCH_FACEBOOK_USER_URL,
	FETCH_GOOGLE_USER_URL,
	GET_ACCESS_TOKEN_URL,
	LISTINGS_LOGIN_URL,
	LISTINGS_LOGOUT_URL,
	LOGIN_URL,
	LOGOUT_URL,
	PROXY_URL,
	SIGNUP_URL,
} from '../constants/url.constants';
import { serviceAPI, serviceAPIJSON } from './service';
import { handleErrorResponse } from '../actions/message';
import { addMinutes, isNullUndefined } from '../util/util';
import { store } from '../store';
import { logout } from '../actions/auth';
import history from '../helpers/history';
import { LOGIN_SUCCESS } from '../actions/type';

class AuthService {
	login(username, password) {
		// console.log('posting login to ', LOGIN_URL)
		return serviceAPI
			.post(LOGIN_URL, { username, password })
			.then((response) => {
				if (response.status === 200) {
					let user = response.data.user;
					//bind this to the user for future requests
					user.accessToken = response.data.accessToken;
					user.refreshToken = window.btoa(response.data.refreshToken);
					// user.expiresAt = response.data.expiresAt
					// the above code is deprecated as there is no way to determine
					// the browser timezone on the server, so set this to 60 mins by default
					user.expiresAt = addMinutes(new Date(), 60);
					user.refreshTokenExpiresAt = addMinutes(new Date(), 60 * 24 * 30); // Expires in a month time
					localStorage.setItem('user', JSON.stringify(user));
				}
				return response.data;
			})
			.catch((error) => {
				handleErrorResponse(error);
			});
	}

	getAccessToken() {
		let user = JSON.parse(localStorage.getItem('user'));
		let refreshToken = '';
		if (!isNullUndefined(user) && !isNullUndefined(user.refreshToken)) {
			refreshToken = window.atob(user.refreshToken);
			return serviceAPI
				.post(GET_ACCESS_TOKEN_URL, { refreshToken })
				.then((response) => {
					if (response.status === 200) {
						user.accessToken = response.data.accessToken;
						user.expiresAt = addMinutes(new Date(), 60);
						localStorage.setItem('user', JSON.stringify(user));
						
						store.dispatch({
							type: LOGIN_SUCCESS,
							payload: { user: user, loginType: 'main' },
						});
					}
					return response.data;
				})
				.catch((error) => {
					store.dispatch(logout());
					history.push('/');
					handleErrorResponse(error);
				});
		}
	}

	listingsLogin(username, password) {
		// console.log('posting login')
		return axios
			.post(LISTINGS_LOGIN_URL, { username, password })
			.then((response) => {
				if (response.status === 200) {
					// console.log("response", response.data.user)
					let user = response.data.user;
					//bind this to the user for future requests
					user.accessToken = response.data.accessToken;
					localStorage.setItem('user', JSON.stringify(user));
				}
				return response.data;
			})
			.catch((error) => {
				handleErrorResponse(error);
			});
	}

	fetchFacebookUser(id) {
		return axios
			.post(FETCH_FACEBOOK_USER_URL, { id })
			.then((response) => {
				if (response.status === 200) {
					// console.log("response", response)
					let user = response.data.user;
					//bind this to the user for future requests
					user.accessToken = response.data.accessToken;
					localStorage.setItem('user', JSON.stringify(user));
				}
				return response.data;
			})
			.catch((error) => {
				handleErrorResponse(error);
			});
	}

	fetchGoogleUser(id) {
		return axios
			.post(FETCH_GOOGLE_USER_URL, { id })
			.then((response) => {
				if (response.status === 200) {
					// console.log("response", response)
					let user = response.data.user;
					//bind this to the user for future requests
					user.accessToken = response.data.accessToken;
					localStorage.setItem('user', JSON.stringify(user));
				}
				return response.data;
			})
			.catch((error) => {
				handleErrorResponse(error);
			});
	}

	listingsLogout() {
		return serviceAPI
			.get(LISTINGS_LOGOUT_URL)
			.then((response) => {
				if (response.status === 200) {
					localStorage.clear();
				}
				return response.data;
			})
			.catch((error) => {
				handleErrorResponse(error);
			});
	}

	logout() {
		// console.log('logout function in auth.service.js')
		// console.trace()
		return serviceAPI
			.get(LOGOUT_URL)
			.then((response) => {
				if (response.status === 200) {
					localStorage.clear();
				}
				return response.data;
			})
			.catch((error) => {
				handleErrorResponse(error);
			});
	}

	register(username, email, password) {
		return axios.post(SIGNUP_URL, {
			username,
			email,
			password,
		});
	}

	executeProxy(params) {
		return serviceAPIJSON
			.post(PROXY_URL, params)
			.then((response) => {
				if (response.status === 200) {
					let proxyUser = response.data;
					let localUser = JSON.parse(localStorage.getItem('user'));
					localUser.proxyRole = proxyUser.proxyRole;
					localUser.proxyFacility = proxyUser.proxyFacility;
					localUser.proxySubscription = proxyUser.proxySubscription;
					localUser.services = proxyUser.services;
					localUser.roles = proxyUser.roles;
					localUser.permissions = proxyUser.permissions;
					localUser.isProxy = proxyUser.isProxy;
					localStorage.setItem('user', JSON.stringify(localUser));
					return proxyUser;
				}
			})
			.catch((error) => {
				handleErrorResponse(error);
			});
	}

	clearProxy() {
		console.log('CALLING THE CLEAR PROXY');
		let localUser = JSON.parse(localStorage.getItem('user'));
		localUser.proxyRole = '';
		localUser.proxyFacility = '';
		localUser.proxySubscription = '';
		localUser.isProxy = false;
		localUser.services = [];
		// localUser.permissions = []
		localStorage.setItem('user', JSON.stringify(localUser));
	}
}

export const emptyUser = {
	proxyRole: '',
	proxySubscription: '',
	proxyFacility: '',
	accessToken: '',
	isProxy: false,
	permissions: [],
	roles: [],
	services: [],
	userId: '',
};
const authService = new AuthService();
export default authService;
