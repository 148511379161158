import { FormControl, OutlinedInput } from "@mui/material";
import { useState } from "react";
import PropTypes from "prop-types";
import { ResponsiveRow } from "../../constants/layout.constants";

FileUpload.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  accept: PropTypes.string,
  // progress:PropTypes.number
};

//TODO: Add in support for multiple files
// and implement progress

//TODO: validation for filetype based on accept
// value passed in

/**
 * Utility file upload component for now progress is turned off
 * @param props
 * @constructor
 */
export function FileUpload(props) {
  const [currentFile] = useState(props.currentFile);
  // const [progress] = useState(props.progress)

  return (
    <ResponsiveRow
      component={FormControl}
      sx={{ width: "100%", padding: 0, height: "auto" }}
    >
      {/* <InputLabel
                id="file-upload-label"
                sx={{marginTop: '-6vh'}}
            ></InputLabel> */}
      {currentFile && <div className="file-name">{currentFile.name}</div>}
      {!currentFile && (
        <OutlinedInput
          labelId="file-upload-label"
          type={"file"}
          title={props.label}
          name={props.id}
          id={props.id}
          onChange={props.onChange}
          accept={props.accept}
          label={props.label}
          sx={{
            backgroundColor: props.bg_color ? props.bg_color : "transparent",
            fontSize: "14px",
            width: { xs: "100%", lg: props.width ? props.width : "90%" },
          }}
        />
      )}
    </ResponsiveRow>
  );
}
