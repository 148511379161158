/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import { Line } from "react-chartjs-2";
import LoadingOverlay from "../overlays/loadingOverlay";
import EmptyOverlay from "../overlays/emptyOverlay";

export default function LineChartsCard({ labelDataObj, currentlyLoading }) {
  const [chartDataLabels, setChartDataLabels] = useState(["", "", "", ""]);
  const [chartData, setChartData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isEmpty, setIsEmpty] = useState(true);
  const chartRef = useRef();

  ChartJS.defaults.backgroundColor = "rgb(255,255,255)";
  ChartJS.defaults.color = "#000";
  ChartJS.defaults.fontWeight = "700";
  ChartJS.defaults.borderColor = "#cfcfcf";

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  const dynamicColors = () => {
    return (
      "rgb(" +
      Math.floor(Math.random() * 255) +
      "," +
      Math.floor(Math.random() * 255) +
      "," +
      Math.floor(Math.random() * 255) +
      ")"
    );
  };

  useEffect(() => {
    if (labelDataObj) {
      setChartDataLabels(labelDataObj.dataLabels);
      setChartData([]);
    }
    let tempData = [];
    tempData =
      labelDataObj &&
      labelDataObj.dataset.map((datasetItem, index) => {
        const data = datasetItem.datasetData;
        let dataColor = dynamicColors();
        return {
          lineTension: 0.2,
          label: datasetItem.datasetLabel,
          data,
          backgroundColor: dataColor,
          borderColor: dataColor,
          borderWidth: 2,
        };
      });
    setIsEmpty(tempData.length === 0);
    setChartData(tempData);
  }, [labelDataObj]);

  useEffect(() => {
    setIsLoading(currentlyLoading);
  }, [currentlyLoading]);

  return isLoading ? (
    <LoadingOverlay />
  ) : isEmpty ? (
    <EmptyOverlay />
  ) : (
    <Line
      ref={chartRef}
      data={{
        type: "line",
        labels: chartDataLabels,
        datasets: chartData,
      }}
      options={{
        responsive: true,
        maintainAspectRatio: false,

        color: "#000",
        elements: {
          point: {
            radius: 0,
            hitRadius: 30,
            hoverRadius: 10,
          },
        },
        scales: {
          x: {
            padding: "20px",
            grid: {
              display: false,
            },
          },
          y: {
            padding: "20px",
            ticks: {
              // Include a dollar sign in the ticks
              callback: function (value, index, ticks) {
                if (value > 999) {
                  const textValue = value.toString();
                  const textLength = textValue.length;

                  return textValue.substring(0, textLength - 3) + "K";
                } else {
                  return value;
                }
              },
            },
            grid: {
              color: "#efefef",
            },
          },
        },
        plugins: {
          legend: {
            fullSize: false,
            position: "bottom",
            align: "end",
            labels: {
              color: "rgb(0,0,0)",
              fontWeight: 700,
            },
          },
        },
      }}
    />
  );
}
