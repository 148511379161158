// import React from "react"
import {useRef, useState} from "react"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import ClickAwayListener from "@mui/material/ClickAwayListener"
import Grow from "@mui/material/Grow"
import Paper from "@mui/material/Paper"
import Popper from "@mui/material/Popper"
import MenuItem from "@mui/material/MenuItem"
import MenuList from "@mui/material/MenuList"
import {TopBarContainedBrandButton, TopBarContainedBrandGroupButton,} from "../constants/component.constants"
import Permit from "../util/permit"

export default function SplitButton({buttons, width, height}) {
    const [open, setOpen] = useState(false)

    const anchorRef = useRef(null)

    const handleActionClick = (option) => {
        setOpen(false)

        option.action()
    }

    const handleToggle = (event) => {
        setOpen((prevOpen) => !prevOpen)
    }

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return
        }

        setOpen(false)
    }

    return (
        buttons && (
            <>
                <TopBarContainedBrandGroupButton
                    sx={{
                        padding: 0,
                        height: height ? height : "50px",
                        width: {xs: "100%", lg: width ? width : "200px"},
                    }}
                    ref={anchorRef}
                    aria-label="split button"
                >
                    <TopBarContainedBrandButton
                        sx={{
                            padding: "5px 10px",
                            margin: 0,

                            display: "flex",
                            flexWrap: "nowrap",
                            justifyContent: "space-between",
                            height: "auto",

                            width: {xs: "100%", lg: "200px"},
                            "&:hover": {
                                backgroundColor: "#037171",
                                color: "#fff",
                            },
                        }}
                        onClick={handleToggle}
                    >
                        {buttons[0].label}
                        <ArrowDropDownIcon/>
                    </TopBarContainedBrandButton>
                </TopBarContainedBrandGroupButton>
                <Popper
                    sx={{
                        zIndex: 1,
                        minWidth: {xs: "auto", lg: "200px"},
                        width: {xs: "80%", lg: "auto"},
                    }}
                    open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    transition
                    disablePortal
                >
                    {({TransitionProps, placement}) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin:
                                    placement === "bottom" ? "center top" : "center bottom",
                            }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList id="split-button-menu" autoFocusItem>
                                        {buttons &&
                                            buttons.map((option, index) =>
                                                option.roles === false && option.services === false ? (
                                                    <MenuItem
                                                        sx={{
                                                            "&:disabled": {
                                                                backgroundColor: "#037171",
                                                                color: "#fff",
                                                                fontWeight: 900,
                                                            },
                                                        }}
                                                        key={index}
                                                        disabled={index === 0 || option.disabled}
                                                        onClick={() => handleActionClick(option, index)}
                                                    >
                                                        {option.label}
                                                    </MenuItem>
                                                ) : (
                                                    <Permit
                                                        key={index}
                                                        roles={
                                                            option.roles !== false && option.roles !== ""
                                                                ? option.roles
                                                                : null
                                                        }
                                                        services={
                                                            option.services !== false &&
                                                            option.services !== ""
                                                                ? option.services
                                                                : null
                                                        }
                                                    >
                                                        <MenuItem
                                                            onClick={() => handleActionClick(option, index)}
                                                            disabled={option.disabled}
                                                        >
                                                            {option.label}
                                                        </MenuItem>
                                                    </Permit>
                                                )
                                            )}
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </>
        )
    )
}
