import {serviceAPI} from "./service"
import {handleErrorResponse} from "../actions/message"
import { CREATE_SUPPORT_TICKET } from "../constants/url.constants"

export const createSupportTicket = (data) => {
  return serviceAPI
    .post(CREATE_SUPPORT_TICKET, data)
    .then((response) => {
      return response.data
    }).catch(error => {
      handleErrorResponse(error)
    })
}
