import {Typography} from "@mui/material";
import {useEffect, useState} from "react";
import {ResponsiveRow} from "../../../constants/layout.constants";
import {getSuspendedLeases} from "../../../services/dashboard.service";
import MuiDataGrid from "../MuiDataGrid";
import {useSelector} from "react-redux";

export default function SuspendedLeases({customHeight, customWidth}) {
    const [dataGridRows, setDataGridRows] = useState([]);
    const [loading, setLoading] = useState(true);
    const {user} = useSelector((state) => state.auth)

    const DataGridColumns = [
        {
            field: "contracteeName",
            headerName: "Tenant Name",
            minWidth: 200,
            flex: 1,
            type: "string",
        },
        {
            field: "facilityName",
            headerName: "Facility",
            minWidth: 200,
            flex: 1,
            type: "string",
        },
        {
            field: "unitNames",
            headerName: "Unit(s)",
            width: 200,
            type: "string",
        },
        {
            field: "billingCode",
            headerName: "Billing Code",
            minWidth: 150,
            flex: 0.75,
            type: "string",
        },
        {
            field: "leaseType",
            headerName: "Lease Type",
            type: "string",
            minWidth: 150,
            flex: 0.75,
        },
    ];

    useEffect(() => {
        setLoading(true);
        getSuspendedLeases()
            .then((response) => {
                if (response) {
                    console.log("response?.leases",response?.leases)
                    setDataGridRows(response?.leases);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [user]);

    return (
        <ResponsiveRow
            sx={{
                flexDirection: "column",
                flexWrap: "nowrap",

                alignItems: "flex-start",

                padding: "20px",

                height: {xs: "100%", lg: customHeight},
                minWidth: {xs: "100%", lg: customWidth},
                width: "100%",

                margin: "10px",

                backgroundColor: "#fff",
                borderRadius: "20px",
            }}
        >
            <Typography
                sx={{
                    width: "100%",
                }}
                textAlign="start"
                variant="h5"
            >
                Suspended Leases
            </Typography>

            <MuiDataGrid
                alt={true}
                dataGridColumns={DataGridColumns}
                dataGridRows={dataGridRows}
                serverPagination={false}
                loading={loading}
                height="90%"
                totalRows={dataGridRows.length}
                noSelection={true}
            />
        </ResponsiveRow>
    );
}
