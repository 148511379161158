import {
  DetailsCardContainer,
  DetailsColumn,
  DetailsContainer,
  DetailsLabel,
  DetailsRow,
  DetailsText,
} from "../../../constants/component.constants";
import { Typography } from "@mui/material";
import { formatCurrency } from "../../../util/util";

export default function SubscriberCard(props) {
  return (
    <DetailsCardContainer container spacing={1}>
      <Typography
        textAlign="start"
        fontWeight={800}
        variant="h5"
        color="primary.dark"
        sx={{
          width: "100%",
        }}
      >
        Subscriber Details
      </Typography>

      <DetailsContainer sx={{ width: "100%", height: "auto", padding: 0 }}>
        <DetailsColumn
          sx={{
            width: { lg: "50%", md: "50%", xs: "100%" },
            height: "auto",
          }}
        >
          <DetailsRow>
            <DetailsLabel>Name</DetailsLabel>
            <DetailsText>{props.subscription?.subscriberName}</DetailsText>
          </DetailsRow>

          {props.subscription?.subscriber?.type === "Individual" && (
            <DetailsRow>
              <DetailsLabel>Email</DetailsLabel>
              <DetailsText>{props.subscription.subscriber.email}</DetailsText>
            </DetailsRow>
          )}
          {props.subscription?.subscriber?.type === "Individual" && (
            <DetailsRow>
              <DetailsLabel>Phone</DetailsLabel>
              <DetailsText>
                {props.subscription.subscriber.mobilePhone}
              </DetailsText>
            </DetailsRow>
          )}
          {props.subscription?.subscriber?.type === "Company" && (
            <DetailsRow>
              <DetailsLabel>Address</DetailsLabel>
              <DetailsText>
                {props.subscription.subscriber.address?.fullAddress1}
              </DetailsText>
            </DetailsRow>
          )}
          {props.subscription?.subscriber?.type === "Company" && (
            <DetailsRow>
              <DetailsLabel>Contact Name</DetailsLabel>
              <DetailsText>
                {props.subscription.subscriber.contact.name}
              </DetailsText>
            </DetailsRow>
          )}
          {props.subscription?.subscriber?.type === "Company" && (
            <DetailsRow>
              <DetailsLabel>Contact Email</DetailsLabel>
              <DetailsText
                sx={{
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  maxWidth: "100%",
                }}
              >
                {props.subscription.subscriber.contact?.email}
              </DetailsText>
            </DetailsRow>
          )}
          {props.subscription?.subscriber?.type === "Company" && (
            <DetailsRow>
              <DetailsLabel>Contact Phone</DetailsLabel>
              <DetailsText>
                {props.subscription.subscriber.contact?.mobilePhone}
              </DetailsText>
            </DetailsRow>
          )}
        </DetailsColumn>

        <DetailsColumn
          sx={{
            width: { lg: "50%", md: "50%", xs: "100%" },
            height: "auto",
          }}
        >
          <DetailsRow>
            <DetailsLabel>Billing Code</DetailsLabel>
            <DetailsText>{props.subscription?.billingCode}</DetailsText>
          </DetailsRow>
          <DetailsRow>
            <DetailsLabel>Status</DetailsLabel>
            <DetailsText>{props.subscription?.status?.name}</DetailsText>
          </DetailsRow>
          <DetailsRow>
            <DetailsLabel>Billing Frequency</DetailsLabel>
            <DetailsText>
              {props.subscription?.billFrequencyObject?.name}
            </DetailsText>
          </DetailsRow>
          <DetailsRow>
            <DetailsLabel>Balance</DetailsLabel>
            <DetailsText>
              {props.subscription?.balance &&
                formatCurrency(props.subscription.balance)}
            </DetailsText>
          </DetailsRow>
        </DetailsColumn>
      </DetailsContainer>
    </DetailsCardContainer>
  );
}
