/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useRef, useState } from "react";
import { ArcElement, Chart as ChartJS, Tooltip } from "chart.js";
import { Pie } from "react-chartjs-2";
import LoadingOverlay from "../overlays/loadingOverlay";
import EmptyOverlay from "../overlays/emptyOverlay";
import { isEmptyObject, isNullUndefined } from "../../util/util";

export default function PieChartsCard(props) {
  const [chartDataPoints, setChartDataPoints] = useState([]);
  const [chartDataLabels, setChartDataLabels] = useState([]);

  const [isEmpty, setIsEmpty] = useState(true);
  const chartRef = useRef();

  ChartJS.register(ArcElement, Tooltip);

  const loadData = useCallback(() => {
    let dataPoints = [];
    let dataLabels = [];

    // let labelDataObj = props.labelDataObj

    // console.log("props before delete", props)

    if (!isNullUndefined(props.type) && props.type === "balancesOverdue") {
      delete props.labelDataObj.invoiceIds;
      delete props.labelDataObj.chargeIds;
    }
    // console.log("props after delete", props)

    if (!isEmptyObject(props.labelDataObj)) {
      Object.keys(props.labelDataObj).forEach(function (key) {
        dataPoints.push(props.labelDataObj[key]);
        dataLabels.push(key);
      });
      if (dataPoints.length > 0 && dataLabels.length > 0) {
        setChartDataPoints(dataPoints);
        setChartDataLabels(dataLabels);
        setIsEmpty(false);
      }
    } else {
      setIsEmpty(true);
    }
  }, [props]);

  const getDataOnClick = () => {
    if (!isNullUndefined(props.getData)) {
      props.getData();
    } else {
      console.log("");
    }
  };

  useEffect(() => {
    loadData();
    return () => {
      setChartDataPoints([]);
      setChartDataLabels([]);
      setIsEmpty(true);
    };
  }, [loadData, props]);

  return props.currentlyLoading === true ? (
    <LoadingOverlay />
  ) : props.currentlyLoading === false && isEmpty === true ? (
    <EmptyOverlay />
  ) : (
    <Pie
      ref={chartRef}
      data={{
        type: "pie",
        labels: chartDataLabels,
        datasets: [
          {
            data: chartDataPoints,
            backgroundColor: [
              "rgba(3,113,113, 1)",
              "rgba(3,113,113, 0.5)",
              "rgba(0,159,147, 1)",
              "rgba(0,159,147, 0.5)",
            ],
            borderColor: [
              "rgba(3,113,113, 1)",
              "rgba(3,113,113, 0.5)",
              "rgba(0,159,147, 1)",
              "rgba(0,159,147, 0.5)",
            ],
            borderWidth: 1,
          },
        ],
      }}
      options={{
        onClick: () => {
          getDataOnClick();
        },
        circumference: 360,
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            fullSize: false,
            position: "right",
            align: "end",
            labels: {
              color: "rgb(0,0,0)",
              fontWeight: 700,
            },
          },
        },
      }}
    />
  );
}
