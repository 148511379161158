import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography,} from "@mui/material"
import {
    DetailsColumn,
    DetailsContainer,
    DetailsLabel,
    DetailsRow,
    DetailsText,
} from "../../../../constants/component.constants"
import {ResponsiveRow} from "../../../../constants/layout.constants"
import {formatCurrency, formatDate} from "../../../../util/util"
import ModalContainerModule from "../../../modules/modalContainer"

export default function ViewJournalModal(props) {
    /**
     *
     * REQUIRED PROPS
     *
     * ViewDiv
     * setViewDiv
     *
     * selectedEntry
     *
     */

    return (
        <ModalContainerModule
            size="md"
            cancel={() => props.setViewDiv(false)}
            openModal={props.ViewDiv}
            modalTitle="Journal Entry Details"
        >
            <ResponsiveRow
                sx={{
                    height: "auto",
                    width: "100%",
                }}
            >
                <DetailsContainer sx={{marginTop: 0, paddingTop: 0}} container>
                    <DetailsColumn
                        width={{
                            xs: "100%",
                            lg: "50%",
                            marginTop: 0,
                            paddingTop: 0,
                        }}
                    >
                        <Typography
                            sx={{
                                marginBottom: "20px",
                                marginTop: 0,
                                fontSize: "18px",
                                color: "#037171",
                            }}
                            fontWeight={700}
                            id="modal-modal-title"
                        >
                            Entry Information
                        </Typography>
                        <ResponsiveRow
                            sx={{
                                display: {xs: "flex", lg: "none"},
                                flexDirection: "column",
                                width: "100%",
                                padding: 0,
                                marginBottom: "15px",
                            }}
                        >
                            <Typography
                                sx={{width: "100%", marginBottom: "10px"}}
                                variant="h6"
                            >
                                {" "}
                                Description:{" "}
                            </Typography>
                            <Typography sx={{width: "100%"}}>
                                {props.selectedEntry && props.selectedEntry.description}
                            </Typography>
                        </ResponsiveRow>
                        <DetailsRow sx={{display: {xs: "none", lg: "flex"}}}>
                            <DetailsLabel> Description: </DetailsLabel>
                            <DetailsText>
                                {props.selectedEntry && props.selectedEntry.description}
                            </DetailsText>
                        </DetailsRow>
                        <DetailsRow>
                            <DetailsLabel> Entry Date: </DetailsLabel>
                            <DetailsText>
                                {formatDate(
                                    props.selectedEntry && props.selectedEntry.entryDate
                                )}
                            </DetailsText>
                        </DetailsRow>
                        <DetailsRow>
                            <DetailsLabel> Posting Date: </DetailsLabel>
                            <DetailsText>
                                {formatDate(
                                    props.selectedEntry && props.selectedEntry.postingDate
                                )}
                            </DetailsText>
                        </DetailsRow>
                        <DetailsRow>
                            <DetailsLabel> Status: </DetailsLabel>
                            <DetailsText>
                                {props.selectedEntry && props.selectedEntry.status}
                            </DetailsText>
                        </DetailsRow>
                    </DetailsColumn>
                    <DetailsColumn
                        item
                        sx={{
                            width: {xs: "100%", lg: "50%"},
                            marginTop: "0",
                        }}
                    >
                        <Typography
                            sx={{
                                marginBottom: "20px",
                                marginTop: 0,
                                fontSize: "18px",
                                color: "#037171",
                            }}
                            fontWeight={700}
                        >
                            Property Information
                        </Typography>
                        <DetailsRow>
                            <DetailsLabel> Applied to: </DetailsLabel>
                            <DetailsText>
                                {props.selectedEntry && props.selectedEntry.appliedTo}
                            </DetailsText>
                        </DetailsRow>
                        <DetailsRow>
                            <DetailsLabel> Entered By: </DetailsLabel>
                            <DetailsText>
                                {props.selectedEntry.systemGenerated
                                    ? "System Generated"
                                    : props.selectedEntry && props.selectedEntry.enteredBy}
                            </DetailsText>
                        </DetailsRow>
                        <DetailsRow>
                            <DetailsLabel> Amount: </DetailsLabel>
                            <DetailsText>
                                {formatCurrency(
                                    props.selectedEntry && props.selectedEntry.totalAmount
                                )}
                            </DetailsText>
                        </DetailsRow>
                    </DetailsColumn>
                </DetailsContainer>
            </ResponsiveRow>

            <ResponsiveRow container sx={{height: "auto"}}>
                <TableContainer>
                    <Table sx={{minWidth: 650}} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{width: "20%"}}>Debit Account</TableCell>
                                <TableCell sx={{width: "20%"}}>Credit Account</TableCell>
                                <TableCell sx={{width: "50%"}}>Description</TableCell>
                                <TableCell sx={{width: "10%"}}>Amount</TableCell>
                            </TableRow>
                        </TableHead>
                        {props.selectedEntry &&
                            props.selectedEntry.transactionDetailItems.length > 0 &&
                            props.selectedEntry.transactionDetailItems.map(function (
                                    item,
                                    i
                                ) {
                                    return (
                                        <TableBody>
                                            <TableCell sx={{width: "20%"}}>
                                                {item.debitAccountName}
                                            </TableCell>
                                            <TableCell sx={{width: "20%"}}>
                                                {item.creditAccountName}
                                            </TableCell>
                                            <TableCell sx={{width: "50%"}}>{item.notes}</TableCell>
                                            <TableCell sx={{width: "10%"}}>
                                                {formatCurrency(item.amount)}
                                            </TableCell>
                                        </TableBody>
                                    )
                                },
                                this)}
                    </Table>
                </TableContainer>
            </ResponsiveRow>
        </ModalContainerModule>
    )
}
