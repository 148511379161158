import {
    TEST_CREDIT_TRANSFER_URL, TEST_TRANSACTION_QUERY_URL
} from "../constants/url.constants"
import {serviceAPI} from "./service"
import {handleErrorResponse} from "../actions/message"

//GET REQUEST

export const testCreditTransfer = (reference) => {
    let url = TEST_CREDIT_TRANSFER_URL + reference
    return serviceAPI.get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const testTransactionQuery = (reference) => {
    let url = TEST_TRANSACTION_QUERY_URL + reference
    return serviceAPI.get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

//POST REQUEST
