/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from "react";
import {Typography} from "@mui/material";
import Grid from "@mui/material/Grid";
import {GridActionsCellItem} from "@mui/x-data-grid";
import {Delete, Edit} from "@mui/icons-material";
import {
    allOrgBankAccountsList,
    countryBanksList,
    deleteBankAccount,
    getLinkedFacilities,
    loadBankAccs,
    saveBankAccountDetails,
    totalBankAccs,
} from "../services/bank.service";
import {useTheme} from "@mui/material/styles";
import {isArray, isEmptyArray, isEmptyString, isNullUndefined,} from "../util/util";
import {issueResponseMessage, issueWarnMessage} from "../actions/message";
import ConfirmationModal from "../components/modals/confirmation";
import {MainContainer, TopBarContainedBrandButton, TopBarContainer,} from "../constants/component.constants";

import {useSelector} from "react-redux";
import AddAccountModal from "../components/modals/accounting/bank accounts/addAccountModal";
import Permit from "../util/permit";
import MuiDataGrid from "../components/modules/MuiDataGrid";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function Bank() {
    const user = useSelector((state) => state.auth.user);
    const [pageNo, setPageNo] = useState(0);
    const [bankAccounts, setBankAccounts] = useState([]);
    const [task, setTask] = useState("");
    const [totalBankAccounts, setTotalBankAccounts] = useState(0);
    const [selectedBankAcc, setSelectedBankAcc] = useState(null);
    const [bankAccForm, setBankAccForm] = useState(false);
    const [banksList, setBanksList] = useState([]);
    const [selectedBankId, setSelectedBankId] = useState("");
    const [branchName, setBranchName] = useState("");
    const [accName, setAccName] = useState("");
    const [accNo, setAccNo] = useState("");
    const [bankCode, setBankCode] = useState("");
    const [branchCode, setBranchCode] = useState("");
    const [bic, setBic] = useState("");
    const [allOrgBankAcc, setAllOrgBankAcc] = useState([]);
    const [accountIds, setAccountIds] = useState([]);
    const theme = useTheme();
    const [mobilePhone, setMobilePhone] = useState("");
    const [description, setDescription] = useState("");
    const [deleteBankAccModal, setDeleteBankAccModal] = useState(false);
    const [titleText, setTitleText] = useState("");
    const [severityText, setSeverityText] = useState("");
    const [bodyText, setBodyText] = useState("");
    const [positiveText, setPositiveText] = useState("");
    const [negativeText, setNegativeText] = useState("");
    const [linkedFacilities, setLinkedFacilities] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        loadBankAccs(pageNo).then((data) => setBankAccounts(data));
        totalBankAccs().then((data) => setTotalBankAccounts(data.total));
        countryBanksList().then((data) => setBanksList(data));
        allOrgBankAccountsList().then((data) => setAllOrgBankAcc(data));
    }, [pageNo]);

    useEffect(() => {
        setLoading(true);
        loadBankAccs(pageNo).then((data) => setBankAccounts(data));
        totalBankAccs()
            .then((data) => setTotalBankAccounts(data.total))
            .finally(() => {
                setLoading(false);
            });
    }, [user.proxyFacility]);

    function loadPage(page) {
        setPageNo(page);
    }

    function handleCloseDeleteBankAccModal() {
        setDeleteBankAccModal(false);
        setSelectedBankAcc(null);
        setTitleText("");
        setTask("");
        setSeverityText("");
        setBodyText("");
        setPositiveText("");
        setNegativeText("");
    }

    function handleEditClicked(item) {
        setTask("Edit");
        setSelectedBankAcc(item);
        setBankAccForm(true);
        setAccountIds(item.accountIds);
    }

    function handleDeleteItemClicked(item) {
        getLinkedFacilities(item.id).then((data) => {
            setLinkedFacilities(data);
        });
        setDeleteBankAccModal(true);
        setSelectedBankAcc(item);
        let title = "Confirm Delete Bank Account";
        let body =
            "Are you sure you want to delete the bank account " + item.name + "?";
        setTitleText(title);
        setTask("Delete");
        setSeverityText("warning");
        setBodyText(body);
        setPositiveText("Delete Bank Account");
        setNegativeText("Cancel");
    }

    function positiveAction() {
        if (task === "Delete") {
            deleteSelectedBankAcc();
        }
    }

    function deleteSelectedBankAcc() {
        if (
            isNullUndefined(selectedBankAcc) ||
            isNullUndefined(selectedBankAcc.id) ||
            isEmptyString(selectedBankAcc.id)
        ) {
            issueWarnMessage(
                "Could not determine Bank Account ID for deletion. Try to refresh the page or contact support."
            );
            return;
        }

        deleteBankAccount(selectedBankAcc).then((response) => {
            issueResponseMessage(response);
            loadBankAccs(pageNo).then((data) => setBankAccounts(data));
            totalBankAccs().then((data) => setTotalBankAccounts(data.total));
            handleCloseDeleteBankAccModal();
        });
    }

    const columns = [
        {headerName: "Account Name", field: "name", minWidth: 300, flex: 3},
        {headerName: "Bank", field: "bankName", minWidth: 274, flex: 2.74},
        {
            headerName: "Account Number",
            field: "accountNumber",
            minWidth: 250,
            flex: 2.5,
        },
        {
            headerName: "Pesalink Phone",
            field: "mobileNumber",
            minWidth: 150,
            flex: 1.5,
        },
        {
            headerName: "Linked Ledger Accounts",
            field: "accountNames",
            minWidth: 350,
            flex: 3.5,
        },
        {
            headerName: "Actions",
            field: "actions",
            width: "100",
            type: "actions",
            getActions: (params) => {
                // console.log("Params", params.row)
                let arr = [];
                arr.push(
                    <GridActionsCellItem
                        icon={<Edit/>}
                        onClick={() => handleEditClicked(params.row)}
                        label="Edit"
                        showInMenu
                    />
                );

                arr.push(
                    <GridActionsCellItem
                        icon={<Delete/>}
                        onClick={() => handleDeleteItemClicked(params.row)}
                        label="Delete"
                        showInMenu
                    />
                );

                return arr;
            },
        },
    ];

    function handleAddBankAccClicked() {
        setTask("Create");
        let data = {
            id: null,
            name: "",
            branchName: "",
            branchCode: "",
            description: "",
            bankId: "",
            bic: "",
            bankCode: "",
            accountNumber: "",
            mobileNumber: "",
            bankName: "",
            accountNames: "",
            accountIds: [],
        };
        setSelectedBankAcc(data);
        setBankAccForm(true);
    }

    const handleChange = (event) => {
        const {
            target: {value},
        } = event;
        setAccountIds(
            // On autofill we get a stringified value.
            typeof value === "string" ? value.split(",") : value
        );
    };

    function getStyles(value, accountIds, theme) {
        return {
            fontWeight:
                accountIds.indexOf(value) === -1
                    ? theme.typography.fontWeightRegular
                    : theme.typography.fontWeightMedium,
        };
    }

    function handleCloseClicked() {
        setBankAccForm(false);
        setTask("");
        setSelectedBankAcc(null);
        setSelectedBankId("");
        setBranchName("");
        setAccName("");
        setAccNo("");
        setBankCode("");
        setBranchCode("");
        setBic("");
        setAccountIds([]);
        setMobilePhone("");
        setDescription("");
    }

    function validateForm(sendData) {
        if (sendData == null) {
            issueWarnMessage(
                "Could not find the form details. Try refresh the page or contact support."
            );
            return false;
        }

        if (isNullUndefined(sendData.bankId) || isEmptyString(sendData.bankId)) {
            issueWarnMessage("Must select a Bank.");
            return false;
        }

        if (
            !isEmptyString(sendData.branchName) &&
            sendData.branchName.length > 50
        ) {
            issueWarnMessage("Branch can only be a maximum of 50 characters.");
            return false;
        }

        if (
            !isEmptyString(sendData.branchCode) &&
            sendData.branchCode.length > 10
        ) {
            issueWarnMessage("Branch Code can only be a maximum of 10 characters");
            return false;
        }

        if (isEmptyString(sendData.name)) {
            issueWarnMessage("Account Name is required");
            return false;
        }
        if (sendData.name.length > 50) {
            issueWarnMessage("Account Name cannot be more than 50 characters");
            return false;
        }

        if (isEmptyString(sendData.accountNumber)) {
            issueWarnMessage("Account Number is required");
            return false;
        }
        if (sendData.accountNumber.length > 30) {
            issueWarnMessage("Account Number cannot be more than 30 characters");
            return false;
        }

        if (
            !isEmptyString(sendData.mobileNumber) &&
            sendData.mobileNumber.length > 45
        ) {
            issueWarnMessage("Mobile number can only be a maximum of 45 characters");
            return false;
        }

        if (
            !isEmptyString(sendData.description) &&
            sendData.description.length > 200
        ) {
            issueWarnMessage("Description can only be a maximum of 200 characters");
            return false;
        }

        if (!isEmptyString(sendData.bic) && sendData.bic.length > 30) {
            issueWarnMessage(
                "Bank Identifier Code (BIC) can only be a maximum of 30 characters"
            );
            return false;
        }

        if (!isEmptyString(sendData.bankCode) && sendData.bankCode.length > 5) {
            issueWarnMessage("Bank Code can only be a maximum of 5 characters");
            return false;
        }

        if (!isArray(sendData.accountIds)) {
            issueWarnMessage(
                "Could not determine linked ledger account. Try refresh the page or contact support."
            );
            return false;
        }
        if (isEmptyArray(sendData.accountIds)) {
            issueWarnMessage("You must link the bank account with a ledger account.");
            return false;
        }

        return true;
    }

    function handleSaveClicked() {
        let obj = selectedBankAcc;
        obj.bankId = obj.bankId + ""; // MAKE SURE it is always a string
        obj.accountIds = accountIds;
        setSelectedBankAcc(obj);

        if (validateForm(selectedBankAcc)) {
            saveBankAccountDetails(selectedBankAcc).then((response) => {
                issueResponseMessage(response);
                loadBankAccs(pageNo).then((data) => setBankAccounts(data));
                totalBankAccs().then((data) => setTotalBankAccounts(data.total));
                handleCloseClicked();
            });
        }
    }

    return (
        <Permit
            roles="BLDIR"
            noPermittedServiceMessage={{
                title: "Access Denied",
                body: (
                    <>
                        Your current role is not permitted to view this page. <br/> Please
                        contact your system admin if you feel this is an error.
                    </>
                ),
            }}
        >
            <MainContainer>
                <TopBarContainer
                    container
                    sx={{
                        width: {xs: "100%", lg: "80%"},
                    }}
                >
                    <Grid item sx={{width: {xs: "100%", lg: "50%"}}}>
                        <Typography
                            sx={{textAlign: {xs: "center", lg: "left"}}}
                            id="bankAccounts"
                            variant="h6"
                            component="h6"
                        >
                            Bank Accounts
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        sx={{
                            width: {xs: "100%", lg: "50%"},
                            textAlign: {xs: "center", lg: "end"},
                        }}
                    >
                        <TopBarContainedBrandButton
                            onClick={handleAddBankAccClicked}
                            sx={{
                                width: {xs: "100%", lg: "200px"},
                            }}
                        >
                            Add a bank account
                        </TopBarContainedBrandButton>
                    </Grid>
                </TopBarContainer>
                <br/>

                {/* <StyledDataGrid
          autoHeight
          rows={bankAccounts}
          columns={columns}
          pageSize={rowsPerPage}
          loading={loading}
          rowsPerPageOptions={[rowsPerPage]}
          checkboxSelection
          pagination
          rowCount={totalBankAccounts}
          onPageChange={(page) => loadPage(page)}
          paginationMode="server"
          disableSelectionOnClick
          onSelectionModelChange={setSelectionModel}
          selectionModel={selectionModel}
          components={{
            LoadingOverlay: LinearProgress,
            NoRowsOverlay: EmptyOverlay,
          }}
        /> */}

                <MuiDataGrid
                    noSelection={true}
                    dataGridColumns={columns}
                    dataGridRows={bankAccounts}
                    serverPagination={false}
                    loading={loading}
                    height="60vh"
                    totalRows={totalBankAccounts}
                />

                {bankAccForm && (task === "Create" || task === "Edit") && (
                    <AddAccountModal
                        MenuProps={MenuProps}
                        handleCloseClicked={handleCloseClicked}
                        handleChange={handleChange}
                        handleSaveClicked={handleSaveClicked}
                        setSelectedBankAcc={setSelectedBankAcc}
                        selectedBankId={selectedBankId}
                        setSelectedBankId={setSelectedBankId}
                        setAccNo={setAccNo}
                        setAccName={setAccName}
                        setBic={setBic}
                        setBranchName={setBranchName}
                        setBankCode={setBankCode}
                        setBranchCode={setBranchCode}
                        setMobilePhone={setMobilePhone}
                        setDescription={setDescription}
                        allOrgBankAcc={allOrgBankAcc}
                        accName={accName}
                        accNo={accNo}
                        accountIds={accountIds}
                        bankAccForm={bankAccForm}
                        bankCode={bankCode}
                        banksList={banksList}
                        bic={bic}
                        branchName={branchName}
                        branchCode={branchCode}
                        description={description}
                        getStyles={getStyles}
                        mobilePhone={mobilePhone}
                        selectedBankAcc={selectedBankAcc}
                        theme={theme}
                    />
                )}
                <ConfirmationModal
                    showConfirmationModal={deleteBankAccModal}
                    handleConfirmationClose={handleCloseDeleteBankAccModal}
                    title={titleText}
                    severity={severityText}
                    body={bodyText}
                    positiveText={positiveText}
                    negativeText={negativeText}
                    positiveAction={positiveAction}
                    negativeAction={handleCloseDeleteBankAccModal}
                    deleteBankAcc={true}
                    linkedFacilities={linkedFacilities}
                />
            </MainContainer>
        </Permit>
    );
}
