import Grid from "@mui/material/Grid"
import loginImage from "../images/login-image.png"
import {Alert, Box, FormControl, InputLabel, OutlinedInput, Paper, Typography} from "@mui/material"
import LoadingButton from "@mui/lab/LoadingButton"
import {useEffect, useState} from "react"
import {useNavigate} from "react-router-dom"
import {isEmptyObject, isEmptyString, isNullUndefined} from "../util/util"
import {handleErrorResponse, issueResponseMessage, issueWarnMessage} from "../actions/message"
import {changePassword, resetPassword} from "../services/passwords.service"
import {useLocation} from "react-router";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import kodi_theme from "../themes/kodiThemeProvider";

export default function ChangePassword() {
    /*
 * STATES SECTION:
 * This is where all the pages state will be defined.
 * Namely:
 *  - Remember Me Radio Button => isRemembered
 *  - Email Address Input => emailAddr
 *  - Password Input => password
 * They use the useState hook and mainly keep track of the form inputs.
 * They can also be used for form validation, if needed, through their setState callbacks
 */

    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
    const [user, setUser] = useState(null)
    const param = new URLSearchParams(useLocation().search)
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()


    function togglePassWord() {
        setPasswordVisible(!passwordVisible);
    }

    function toggleConfirmPassword() {
        setConfirmPasswordVisible(!confirmPasswordVisible);
    }

    useEffect(() => {
        let token = param.get('token')
        if (isNullUndefined(token) || isEmptyString(token)) {
            navigate("/dashboard")
        } else {
            if (isNullUndefined(user)) {
                changePassword(token)
                    .then(user => {
                        setUser(user)
                    })
                    .catch((error) => {
                        handleErrorResponse(error)
                        console.log('Error: ', error)
                        setUser({})
                    })
            }
        }
    }, [param])

    /**
     * FORM SUBMIT: onSubmit
     * This is the form submit function that takes in the event
     * and reads the values of the states
     */

    const onSubmit = (e) => {
        e.preventDefault()
        if (isEmptyString(password)) {
            issueWarnMessage("Please input the new password")
            return false
        }
        if (isEmptyString(confirmPassword)) {
            issueWarnMessage("Please confirm the new password")
            return false
        }
        if (password !== confirmPassword) {
            issueWarnMessage("Please ensure the 2 passwords match!")
            return false
        }

        let sendData = {
            token: param.get('token'),
            password: password,
            confirmPassword: confirmPassword
        }
        resetPassword(sendData)
            .then(response => {
                issueResponseMessage(response)
                setLoading(false)
                setPassword("")
                setConfirmPassword("")
                navigate("/")
            })
        setLoading(true)
    }
    return (
        <Grid
            sx={{
                height: "100vh",
                width: "100wv",
                margin: "0",
            }}
            container
        >
            <Grid
                item
                xs={false}
                sm={false}
                md={5}
                lg={8}
                sx={{
                    backgroundImage: `url(${loginImage})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    width: "100%",
                }}
            />
            <Grid item sm={12} md={7} lg={4}>
                <Paper
                    sx={{
                        height: "100vh",
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "space-between",
                        justifyContent: "center",
                        margin: "auto",
                    }}
                >
                    <Box sx={{width: "100%", margin: "10px auto 10px auto"}}>
                        <img
                            src={require("../images/kodi-logo.png")}
                            alt=""
                            width={"30%"}
                        />
                    </Box>

                    <Box sx={{width: "80%", margin: "15px auto"}}>
                        <Typography
                            variant="h2"
                            color="primary.dark"
                            sx={{fontWeight: 700}}
                        >
                            Reset Password
                        </Typography>
                    </Box>
                    {isEmptyObject(user) ? (
                        <Alert severity="error"
                        sx={{marginLeft:'10%', marginRight:'10%'}}>
                            This link appears to have expired. Please click <a href = "/forgotPassword">here</a> to have a new link sent to your email
                        </Alert>
                    ):(
                        <>
                            <Box sx={{width: "80%", margin: "10px auto"}}>
                                <Typography variant="body2">
                                    Welcome <b>{user?.firstName ?? ''} {user?.lastName ?? ''}</b>.
                                </Typography>
                                <Typography variant="body2">
                                    Please input your new password to gain access to your account.
                                </Typography>
                            </Box>

                            <Box sx={{width: "80%", margin: "20px auto"}}>
                                <form onSubmit={onSubmit}>
                                    <FormControl sx={{width: "80%", marginBottom: "20px"}}>
                                        <InputLabel htmlFor="userEmail">
                                            <Typography
                                                variant="body2"
                                                sx={{fontWeight: "medium"}}
                                                color="primary.light"
                                            >
                                                New Password
                                            </Typography>
                                        </InputLabel>
                                        <OutlinedInput
                                            id="password"
                                            label="Enter New Password"
                                            type={passwordVisible ? "text" : "password"}
                                            required
                                            aria-describedby="email-helper-text"
                                            color="secondary"
                                            onChange={(event) => setPassword(event.target.value)}
                                            value={password}
                                            endAdornment={
                                                passwordVisible ? (
                                                    <VisibilityOff
                                                        onClick={togglePassWord}
                                                        sx={{ color: kodi_theme.palette.primary.dark }}
                                                    />
                                                ) : (
                                                    <Visibility
                                                        onClick={togglePassWord}
                                                        sx={{ color: kodi_theme.palette.primary.dark }}
                                                    />
                                                )
                                            }
                                        />
                                    </FormControl>{" "}
                                    <FormControl sx={{width: "80%"}}>
                                        <InputLabel htmlFor="userEmail">
                                            <Typography
                                                variant="body2"
                                                sx={{fontWeight: "medium"}}
                                                color="primary.light"
                                            >
                                                Confirm New Password
                                            </Typography>
                                        </InputLabel>
                                        <OutlinedInput
                                            id="password2"
                                            label="Confirm Password"
                                            type={confirmPasswordVisible ? "text" : "password"}
                                            required
                                            aria-describedby="email-helper-text"
                                            color="secondary"
                                            onChange={(event) => setConfirmPassword(event.target.value)}
                                            value={confirmPassword}
                                            endAdornment={
                                                confirmPasswordVisible ? (

                                                    <VisibilityOff
                                                        onClick={toggleConfirmPassword}
                                                        sx={{ color: kodi_theme.palette.primary.dark }}
                                                    />
                                                ) : (
                                                    <Visibility
                                                        onClick={toggleConfirmPassword}
                                                        sx={{ color: kodi_theme.palette.primary.dark }}
                                                    />
                                                )
                                            }
                                        />
                                    </FormControl>
                                    <LoadingButton
                                        variant="contained"
                                        type="submit"
                                        loading={loading}
                                        onClick={onSubmit}
                                        sx={{
                                            width: "80%",
                                            backgroundColor: "#037171",
                                            margin: "10px auto",
                                        }}
                                    >
                                        Reset Password
                                    </LoadingButton>
                                </form>
                            </Box>
                        </>
                    )}
                </Paper>
            </Grid>
        </Grid>
    )
}
