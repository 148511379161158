import {serviceAPI, serviceAPIDownloadFile} from "./service"
import {
    DELETE_CAPITAL_FUND,
    DOWNLOAD_CAPITAL_FUNDS,
    EXPORT_CAPITAL_FUNDS, LIST_CAPITAL_FUND_OWNERSHIPS,
    LIST_CAPITAL_FUNDS,
    SAVE_CAPITAL_FUND,
    TOTAL_CAPITAL_FUNDS,
    UPDATE_CAPITAL_FUND
} from "../constants/url.constants"
import {handleErrorResponse} from "../actions/message"

export const listCapitalFunds = (status, capitalFundName, pageNo, rowsPerPage) => {
    let url = LIST_CAPITAL_FUNDS + "?statusCode=" + status + "&capitalFundName=" + capitalFundName + "&pageNo=" + pageNo + "&rowsPerPage=" + rowsPerPage
    return serviceAPI
        .get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const loadTotalCapitalFunds = (status, capitalFundName) => {
    let url = TOTAL_CAPITAL_FUNDS + "?statusCode=" + status + "&capitalFundName=" + capitalFundName
    return serviceAPI
        .get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const listCapitalFundOwnerships = (facilityId) => {
    let url = LIST_CAPITAL_FUND_OWNERSHIPS + "?facilityId=" + facilityId
    return serviceAPI
        .get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const exportCapitalFunds = () => {
    return serviceAPIDownloadFile.get(EXPORT_CAPITAL_FUNDS)
}

export function downloadCapitalFunds(data) {
    return serviceAPI
        .post(DOWNLOAD_CAPITAL_FUNDS, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export function saveCapitalFund(data) {
    return serviceAPI
        .post(SAVE_CAPITAL_FUND, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export function updateCapitalFund(data) {
    return serviceAPI
        .post(UPDATE_CAPITAL_FUND, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export function deleteCapitalFund(data) {
    return serviceAPI
        .post(DELETE_CAPITAL_FUND, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}






