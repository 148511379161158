import {Typography} from "@mui/material"
import {Box} from "@mui/system"
import {ResponsiveRow} from "../../../constants/layout.constants"

import listingHero from "../../../images/listings/listing_hero_background.png"
import ListingSearchWidget from "./listingSearchWidget"

export default function LandingHero() {
    return (
        <ResponsiveRow
            container
            sx={{
                alignItems: "center",
                padding: 0,
                height: {xs: "100%", lg: "80vh"},
                position: "relative",
                marginTop: "100px",
            }}
        >
            <Box
                component="img"
                src={listingHero}
                sx={{
                    objectFit: "cover",
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                    zIndex: 2,
                }}
            />
            <ResponsiveRow
                container
                sx={{
                    position: "relative",
                    flexDirection: {xs: "column", lg: "row"},
                    flexWrap: "nowrap",
                    zIndex: 2,
                    justifyContent: "center",
                    alignItem: "center",
                    padding: "40px",
                    height: {xs: "100%", lg: "80vh"},
                }}
            >
                <ResponsiveRow
                    item
                    sx={{
                        flexDirection: "column",
                        flexWrap: "nowrap",
                        width: {xs: "100%", lg: "50%"},
                        textAlign: "start",
                        justifyContent: "center",
                        height: "100%",
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: {xs: "2.2rem", lg: "4.5rem"},
                            fontWeight: "800",
                            lineHeight: "112%",
                            color: "#fff",
                            marginBottom: "10px",
                        }}
                    >
                        Find the <span style={{color: "#00B9AE"}}>perfect home</span> for
                        your dreams
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: {xs: "1rem", lg: "1.2rem"},
                            fontWeight: 600,
                            color: "#fefefe",
                            marginBottom: "10px",
                            padding: {xs: 0, lg: "20px"},
                        }}
                    >
                        We are here to help you minimize the struggle of finding a place.
                        Smart Kodi Listings help you find, and assets potential homes even
                        before you book a viewing.
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: {xs: "1rem", lg: "1.2rem"},
                            fontWeight: 600,
                            color: "#fefefe",
                            marginBottom: "10px",
                            padding: {xs: 0, lg: "20px"},
                        }}
                    >
                        Of which we will also ensure is hustle free on the platform. All you
                        need to do it fill in the form o the right to start.
                    </Typography>
                </ResponsiveRow>
                <ResponsiveRow
                    item
                    sx={{
                        width: {xs: "100%", lg: "50%"},
                        textAlign: "start",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <ListingSearchWidget/>
                </ResponsiveRow>
            </ResponsiveRow>
        </ResponsiveRow>
    )
}
