import {serviceAPI, serviceAPIDownloadFile} from "../service"
import {
    CANCEL_TOUR_BOOKING_URL,
    DELETE_LISTING_DOCUMENT_URL,
    DELETE_LISTING_URL,
    GET_APPLICATIONS_NOTE_URL,
    GET_APPLICATIONS_URL, USER_APPOINTMENT_BY_LISTING_URL,
    GET_APPOINTMENTS_URL,
    GET_FAVORITE_LISTINGS_URL,
    GET_LISTING_IMAGE_URL,
    GET_LISTINGS_BY_PERSON_ID_URL,
    GET_LISTINGS_SECURE_URL,
    GET_LISTINGS_URL,
    GET_TOTAL_LISTINGS_URL, APPOINTMENTS_BY_LISTING_URL,
    SAVE_APPOINTMENT_URL,
    SAVE_FAVORITE_LISTING_URL,
    SAVE_LISTINGS_APPLICATION_URL,
    SAVE_LISTINGS_URL,
    SOCIAL_REGISTRATION_URL,
    UPLOAD_LISTING_DOCUMENT_URL
} from "../../constants/url.constants"
import {handleErrorResponse} from "../../actions/message"

export const saveListingData = (data) => {
    return serviceAPI.post(SAVE_LISTINGS_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getListingsUnsecure = (propertyType, listingType, name, noOfBeds, noOfBaths, sqftMin, sqftMax, priceMin, priceMax, pageNo, rowsPerPage) => {
    let url = GET_LISTINGS_URL + "?propertyTypeCd=" + propertyType + "&listingTypeCd=" + listingType + "&propertyName=" + name +
        "&noOfBeds=" + noOfBeds + "&noOfBaths=" + noOfBaths + "&sqftMin=" + sqftMin + "&sqftMax=" + sqftMax + "&priceMin=" + priceMin + "&priceMax=" + priceMax +
        "&pageNo=" + pageNo + "&rowsPerPage=" + rowsPerPage
    return serviceAPI
        .get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getListingsSecure = (propertyType, listingType, name, noOfBeds, noOfBaths, sqftMin, sqftMax, priceMin, priceMax, pageNo, rowsPerPage) => {
    let url = GET_LISTINGS_SECURE_URL + "?propertyTypeCd=" + propertyType + "&listingTypeCd=" + listingType + "&propertyName=" + name +
        "&noOfBeds=" + noOfBeds + "&noOfBaths=" + noOfBaths + "&sqftMin=" + sqftMin + "&sqftMax=" + sqftMax + "&priceMin=" + priceMin + "&priceMax=" + priceMax +
        "&pageNo=" + pageNo + "&rowsPerPage=" + rowsPerPage
    return serviceAPI
        .get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getListingsByPersonId = (propertyType, listingType, name, pageNo, rowsPerPage) => {
    let url = GET_LISTINGS_BY_PERSON_ID_URL + "?propertyTypeCd=" + propertyType + "&listingTypeCd=" + listingType + "&propertyName=" + name +
        "&pageNo=" + pageNo + "&rowsPerPage=" + rowsPerPage
    return serviceAPI
        .get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getTotalListings = (propertyType, listingType, name) => {
    let url = GET_TOTAL_LISTINGS_URL + "?propertyTypeCd=" + propertyType + "&listingTypeCd=" + listingType + "&propertyName=" + name
    return serviceAPI
        .get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getListingImage = (filename) => {
    return serviceAPIDownloadFile.get(GET_LISTING_IMAGE_URL + "?filename=" + filename)
}

export const saveListingApplication = (data) => {
    return serviceAPI.post(SAVE_LISTINGS_APPLICATION_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const listApplications = (listing, status) => {
    let url = GET_APPLICATIONS_URL + "?listingName=" + listing + "&status=" + status
    return serviceAPI
        .get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getListingApplicationNotes = (applicationId, pageNo) => {
    return serviceAPI
        .get(GET_APPLICATIONS_NOTE_URL + "?applicationId=" + applicationId + "&pageNo=" + pageNo)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const saveFavoriteListing = (data) => {
    return serviceAPI.post(SAVE_FAVORITE_LISTING_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getPersonFavoritesList = () => {
    return serviceAPI
        .get(GET_FAVORITE_LISTINGS_URL)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const handleSocialRegistration = (data) => {
    return serviceAPI
        .post(SOCIAL_REGISTRATION_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const uploadListingDocument = (listingId, data) => {
    return serviceAPI
        .post(UPLOAD_LISTING_DOCUMENT_URL + listingId, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const deleteListingDocument = (listingId, data) => {
    return serviceAPI
        .post(DELETE_LISTING_DOCUMENT_URL + listingId, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const deleteListing = (listingId) => {
    return serviceAPI
        .post(DELETE_LISTING_URL + listingId)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const saveAppointment = (data) => {
    return serviceAPI.post(SAVE_APPOINTMENT_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const listAppointments = (data) => {
    let date = data.toISOString();
    let url = GET_APPOINTMENTS_URL + date
    return serviceAPI
        .get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getAppointmentForListing = (listingId) => {
    const url = USER_APPOINTMENT_BY_LISTING_URL + listingId
    return serviceAPI
        .get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const listAppointmentsForListing = (listingId) => {
    const url = APPOINTMENTS_BY_LISTING_URL + listingId
    return serviceAPI
        .get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const cancelTourBooking = (bookingId) => {
    const url = CANCEL_TOUR_BOOKING_URL + bookingId
    return serviceAPI
        .post(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}