import {
    ASS_CAT_CD_URL,
    DELETE_ASSET_URL,
    DEP_TYPE_URL,
    LIST_ASSETS_URL,
    MAINT_ACC_URL,
    MAINT_PRD_URL,
    SAVE_ASSETS_URL
} from "../constants/url.constants"
import {serviceAPI} from "./service"
import {handleErrorResponse} from "../actions/message"

//GET URLS
export const getAssets = (name, owner) => {
    let url = LIST_ASSETS_URL + name + "&owner=" + owner
    return serviceAPI.get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getAssetCategory = () => {
    return serviceAPI.get(ASS_CAT_CD_URL)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getDepreciationType = () => {
    return serviceAPI.get(DEP_TYPE_URL)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getMaintenanceAccount = () => {
    return serviceAPI.get(MAINT_ACC_URL)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getMaintenancePeriods = () => {
    return serviceAPI.get(MAINT_PRD_URL)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

//POST URLS
export const saveAsset = (data) => {
    return serviceAPI.post(SAVE_ASSETS_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const deleteAsset = (data) => {
    return serviceAPI.post(DELETE_ASSET_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}
