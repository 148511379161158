import React, { useEffect, useState } from "react";
import { ResponsiveRow } from "../../constants/layout.constants";
import { StyledDataGrid } from "../../constants/component.constants";
import { LinearProgress } from "@mui/material";
import EmptyOverlay from "../overlays/emptyOverlay";
import kodi_theme from "../../themes/kodiThemeProvider";

const AltStyle = {
  boxShadow: 0,
  borderColor: "transparent",
  "& .MuiDataGrid-columnHeaders": {
    borderRadius: 0,
    backgroundColor: "#fff",
    color: "#037171",
  },
  "& .MuiDataGrid-columnHeader": {
    backgroundColor: "#fff",
    borderColor: "#037171",
    color: "#037171",
  },
  "& .MuiDataGrid-columnHeaderCheckbox": {
    borderColor: "#037171",
  },
  "& .MuiDataGrid-menuIcon": {
    visibility: "visible",
    width: "auto",

    svg: {
      color: kodi_theme.palette.primary.dark,
    },
  },
};

export default function MuiDataGrid({
  alt = false | true,
  dataGridColumns = [],
  dataGridRows = [],
  currentSelectionModel = [],
  handleSelectedRows = (newSelection) => {},
  handlePageLoad = (paginationModel) => {},
  getRowClassName = (params) => {},
  height = "auto",
  loading = false | true,
  noBoxShadow = false | true,
  noSelection = false | true,
  serverPagination = false | true,
  totalRows = 0,
}) {
  const [rows, setRows] = useState([]);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 50,
    page: 0,
  });

  useEffect(() => {
    if (dataGridRows !== undefined && dataGridRows.length > 0) {
      setRows(dataGridRows);
    } else {
      setRows([]);
    }

    return () => {
      handleSelectedRows([]);
      setRowSelectionModel([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataGridRows]);

  useEffect(() => {
    if (currentSelectionModel.length > 0 && rowSelectionModel.length === 0) {
      setRowSelectionModel([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSelectionModel]);

  return (
    <ResponsiveRow
      sx={{ width: "100%", height: height, padding: 0, margin: 0 }}
    >
      <StyledDataGrid
        sx={[
          alt === true
            ? AltStyle
            : noBoxShadow === true
            ? {
                "& .MuiDataGrid-menuIcon": {
                  visibility: "visible",
                  width: "auto",

                  svg: {
                    color: kodi_theme.palette.common.white,
                  },
                },

                boxShadow: 0,
                
              }
            : {
                "& .MuiDataGrid-menuIcon": {
                  visibility: "visible",
                  width: "auto",

                  svg: {
                    color: kodi_theme.palette.common.white,
                  },
                },
              },
        ]}
        columns={dataGridColumns}
        rows={rows}
        checkboxSelection={noSelection !== true}
        disableRowSelectionOnClick
        onRowSelectionModelChange={(newRowSelectionModel) => {
          setRowSelectionModel(newRowSelectionModel);
          handleSelectedRows(newRowSelectionModel);
        }}
        loading={loading}
        //this is deprecated, any collection using this componenet MUST have an id attribute
        // this is necessary for selection matching (e.g. displaying a total based on selected rows)
        // getRowId={isNullUndefined(customRowID)? ()=>{ return getIdRefNumber()}: customRowID}
        pagination
        getRowClassName={getRowClassName}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: paginationModel.pageSize,
              pageNo: paginationModel.page,
            },
          },
        }}
        paginationModel={paginationModel}
        onPaginationModelChange={(model) => {
          setPaginationModel(model);
          handlePageLoad(model);
        }}
        rowSelectionModel={
          currentSelectionModel.length !== 0 ? rowSelectionModel : []
        }
        rowCount={totalRows}
        paginationMode={serverPagination === true ? "server" : "client"}
        slots={{
          loadingOverlay: LinearProgress,
          noRowsOverlay: EmptyOverlay,
          noResultsOverlay: EmptyOverlay,
        }}
        pageSizeOptions={[50, 75, 100]}
      />
    </ResponsiveRow>
  );
}
