import React, { useCallback, useEffect, useState } from 'react'
import Permit from '../util/permit';
import { TabContext, TabPanel } from '@mui/lab';
import { Box, Tab, Tabs } from '@mui/material';
import Bank from './bank';
import Reconciliation from './reconciliation';

function Banking() {
  const [currentTab, setCurrentTab] = useState("Bank Accounts");
  const recordsTabs = ["Bank Accounts", "Reconciliation"];

  function handleTabChange(newTab) {
    setCurrentTab(newTab);
  }

  const populateTabs = useCallback(() => {
    switch (currentTab) {
      case "Bank Accounts":
        return <Bank />;
      case "Reconciliation":
        return <Reconciliation />;
      default:
        return <Bank />;
    }
  }, [currentTab]);

  useEffect(() => {
    populateTabs();
  }, [populateTabs]);

  return (
    <Permit
      roles="BLDIR,ACC"
      services="PREMIUM,HOA,MAINT"
      noPermittedServiceMessage={{
        title: "Access Denied",
        body: (
          <>
            Your current role and/or facility is not permitted to view this
            page. <br /> Please contact your system admin if you feel this is an
            error.
          </>
        ),
      }}
    >
      <TabContext value={currentTab}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",

            width: "100%",

            overflowX: "auto",
          }}
        >
          <Tabs
            value={currentTab}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="basic tabs example"
            sx={{ marginTop: "20px" }}
          >
            {recordsTabs.map((tab) => {
              return (
                <Tab
                  key={tab}
                  label={tab}
                  id={tab}
                  value={tab}
                  aria-controls={`tabPanel-${currentTab}`}
                  onClick={() => handleTabChange(tab)}
                />
              );
            })}
          </Tabs>
        </Box>
        {recordsTabs.map((tab) => {
          return (
            <TabPanel
              key={tab}
              value={tab}
              index={tab}
              sx={{ width: "100%", padding: 0, margin: "5px 0 0 0" }}
            >
              {populateTabs(tab)}
            </TabPanel>
          );
        })}
      </TabContext>
    </Permit>
  );
}

export default Banking