import {Typography} from "@mui/material";
import {useEffect, useState} from "react";
import {ResponsiveRow} from "../../../constants/layout.constants";
import {getUpcomingAssetMaintenance} from "../../../services/dashboard.service";
import MuiDataGrid from "../MuiDataGrid";
import {useSelector} from "react-redux";

/**
 *
 * REQUIRED PROPS
 *  customHeight: string
 *  customWidth: string
 *  data: {
 *    id: number
 *    type: string
 *    makeModel: string
 *    date: string
 *  }
 *
 */

export default function UpcomingAssetMaintenance({
                                                     customHeight,
                                                     customWidth,
                                                 }) {
    const [dataGridRows, setDataGridRows] = useState([])
    const [loading, setLoading] = useState(true)
    const {user} = useSelector((state) => state.auth)
    const DataGridColumns = [
        {
            field: "content",
            headerName: "Details",
            minWidth: 475,
            flex: 3.75,
        },
        {
            field: "date",
            headerName: "Date",
            width: 200,
        },
    ];

    useEffect(() => {
        getUpcomingAssetMaintenance()
            .then((response) => {
                if (response && response.data) {
                    setDataGridRows(response.data);
                }
            })
            .finally(() => {
                setLoading(false);
            });
        return () => setDataGridRows([]);
    }, [user.proxyFacility]);

    return (
        <ResponsiveRow
            sx={{
                flexDirection: "column",
                flexWrap: "nowrap",
                alignItems: "flex-start",
                padding: "20px",
                height: {xs: "100%", lg: customHeight},
                minWidth: {xs: "100%", lg: customWidth},
                width: "100%",
                margin: "10px",
                backgroundColor: "#fff",
                borderRadius: "20px",
            }}
        >
            <Typography
                sx={{
                    width: "100%",
                }}
                textAlign="start"
                variant="h5"
            >
                Assets: Upcoming Maintenance
            </Typography>

            <MuiDataGrid
                alt={true}
                noSelection={true}
                dataGridColumns={DataGridColumns}
                dataGridRows={dataGridRows}
                serverPagination={false}
                loading={loading}
                height="90%"
                totalRows={dataGridRows.length}
            />
        </ResponsiveRow>
    );
}
