import {
	LIST_ENTRIES_URL,
	REVERSE_ENTRY_URL,
	SAVE_ENTRIES_URL,
} from '../constants/url.constants';
import { serviceAPI } from './service';
import { handleErrorResponse } from '../actions/message';

export const listJournalEntries = (enteredBy) => {
	let url = LIST_ENTRIES_URL + enteredBy;
	return serviceAPI
		.get(url)
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			handleErrorResponse(error);
		});
};

export const saveJournalEntry = (data) => {
	return serviceAPI
		.post(SAVE_ENTRIES_URL, data)
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			handleErrorResponse(error);
		});
};

export const reverseJournalEntry = (transactionId) => {
	return serviceAPI
		.get(REVERSE_ENTRY_URL + transactionId)
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			handleErrorResponse(error);
		});
};
