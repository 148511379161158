import React from "react";
import ModalContainerModule from "../../modules/modalContainer";
import { Alert, FormControlLabel, Switch } from "@mui/material";

export default function ConfirmInactiveModal(props) {
	const {
		open,
		onClose,
		onConfirm,
		sendTenantStatements,
		onSendTenantStatementsChange,
	} = props;

	return (
		<ModalContainerModule
			size="deleteProperty"
			accept={onConfirm}
			cancel={onClose}
			openModal={open}
			modalTitle="Confirm Setting Account as Inactive"
			acceptButtonText="Confirm"
		>
			<Alert
				severity="warning"
				sx={{ width: "100%", height: "auto", marginBottom: "20px" }}
			>
				Are you sure you want to set this account as inactive?
			</Alert>
			<FormControlLabel
				control={
					<Switch
						checked={sendTenantStatements}
						onChange={onSendTenantStatementsChange}
						inputProps={{ "aria-label": "controlled" }}
					/>
				}
				label="Send tenant statements"
			/>
		</ModalContainerModule>
	);
}
