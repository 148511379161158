import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import { DataGrid } from "@mui/x-data-grid";
import {
  Button,
  ButtonGroup,
  Card,
  List,
  ListItemButton,
  ListItemText,
  MenuItem,
  Paper,
  SwipeableDrawer,
  Typography,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Box } from "@mui/system";
import kodi_theme from "../themes/kodiThemeProvider";

export const InnerModal = styled(Grid)(({ theme: kodi_theme }) => ({
  margin: "auto",
  flexDirection: "row",
  alignItems: "center",
}));

export const MainContainer = styled(Grid)(({ theme: kodi_theme }) => ({
  width: "100%",
  minHeight: { xs: "100%", lg: "75vh" },
  margin: "auto",
  paddingBottom: "20px",
  marginTop: "30px",
  flexDirection: "row",
  flexWrap: "nowrap",
  justifyContent: "center",
  alignItems: "center",
}));

export const SearchContainer = styled(Grid)(({ theme: kodi_theme }) => ({
  margin: "auto",
  height: "12%",
  width: { xs: "100%", lg: "80%" },
  backgroundColor: "#fff",
  flexDirection: "row",
  alignItems: "center",
  display: { xs: "none", sm: "none", md: "flex", lg: "flex", xl: "flex" },
  boxShadow: "0 0px 12px rgba(0,0,0,0.35)",
}));

export const AddContainer = styled(Grid)(({ theme: kodi_theme }) => ({
  margin: "auto",
  flexDirection: "row",
  align: "left",
  alignItems: "left",
}));

export const ColumnContainer = styled(Grid)(({ theme: kodi_theme }) => ({
  margin: "auto",
  flexDirection: "column",
  align: "left",
  alignItems: "left",
}));

export const StyledDataGrid = styled(DataGrid)(({ theme: kodi_theme }) => ({
  // marginTop: "4vh",
  backgroundColor: "white",
  border: "none",
  boxShadow: "4px 12px 12px #aaa",
  headerClassName: "kodi_theme.palette.primary.main",
  "& .MuiDataGrid-columnHeaders": {
    "& .MuiDataGrid-checkboxInput": {
      color: "#fff",
    },
    backgroundColor: "#037171",
    color: "#fff",
    fontSize: "16px",
    fontWeight: 800,
    borderRadius: "10px 10px 0 0",
    maxWidth: "100%",
  },
  "& .MuiDataGrid-columnHeader, .MuiDataGrid-columnsContainer": {
    backgroundColor: "#037171",
    color: "#fff",
    height: "100%",
  },
  "&.MuiDataGrid-root .MuiDataGrid-sortIcon, .MuiDataGrid-root .MuiDataGrid-filterIcon":
    {
      color: "#fff",
    },
  "& .MuiDataGrid-row": {
    "&:nth-of-type(2n)": {
      backgroundColor: "rgba(235,235,235,0.7)",
    },
  },
  "& .MuiDataGrid-columnHeaderCheckbox": {
    padding: "10px",
  },
  "& .suspended": {
    // border: "1px solid #9f3a38",
    background: "#fff6f6",
    color: "#9f3a38",
  },
}));

export const FormContainer = styled(Grid)(({ theme: kodi_theme }) => ({
  margin: "auto",
  flexDirection: "row",
  align: "left",
  alignItems: "left",
}));

export const UserItem = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: "20px",
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export const AdminContainer = styled(Grid)(({ theme: kodi_theme }) => ({
  marginTop: "4vh",
  marginBottom: "auto",
  marginLeft: "auto",
  marginRight: "auto",
  height: "12%",
  width: "95%",
  backgroundColor: "#fff",
}));

export const AdminReportCard = styled(Card)((theme) => ({
  "& .MuiCard-root": {
    display: "flex",
    flexDirection: "column",

    justifyContent: "flex-start",
    alignItems: "flex-start",

    backgroundColor: kodi_theme.palette.grey[300],
  },
  "& .MuiCardHeader-title": {
    color: kodi_theme.palette.common.black,
    fontSize: "1rem",

    textAlign: "start",

    width: "100%",
    height: "auto",
  },
  "& .MuiCardHeader-subheader": {
    color: kodi_theme.palette.primary.dark,
    fontSize: ".9rem",

    textAlign: "start",
    marginTop: "5px",

    width: "100%",
    height: "auto",
  },
}));

export const DetailsCardContainer = styled(Grid)((theme) => ({
  padding: "20px",
  width: { xs: "100%", md: "90%" },
  backgroundColor: "#fff",
  borderRadius: "10px",
  minHeight: "240px",
  margin: "20px auto",
  boxShadow: "0 4px 12px 0 rgba(0,0,0,0.25)",
}));

export const DetailsContainer = styled(Box)((theme) => ({
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  alignItems: "flex-start",
  justifyContent: { xs: "center", lg: "flex-start" },
  marginTop: "20px",

  height: "100%",
  padding: "10px",
}));

// Details card elements
export const DetailsColumn = styled(Box)((theme) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  // width: { xs: "100%", lg: "450px" },y
  padding: "10px",
}));

export const DetailsRow = styled(Box)((theme) => ({
  display: "flex",
  flexDirection: { xs: "column", lg: "row" },
  flexWrap: "nowrap",

  justifyContent: "flex-start",
  alignItems: "flex-start",

  width: "100%",
  height: "auto",

  padding: "5px 10px",
  margin: { xs: "10px 0", lg: "5px 10px" },
  textAlign: "start",
}));

export const DetailsLabel = styled(Typography)((theme) => ({
  fontWeight: "700",
  fontSize: "1rem",

  minWidth: "40%",

  height: "auto",
  textAlign: "start",
}));

export const DetailsText = styled(Typography)((theme) => ({
  fontSize: "1rem",
  paddingLeft: "10px",

  textAlign: { xs: "end", lg: "start" },
  minWidth: { xs: "100%", lg: "50%" },

  height: "auto",
}));

export const TopBarContainer = styled(Grid)((theme) => ({
  width: { xs: "100%", lg: "80%" },
  display: "flex",
  flexDirection: "row",
  margin: "auto",
  alignItems: "center",
  justifyContent: { xs: "center", lg: "space-between" },
  padding: "10px 20px",
  borderRadius: "10px",
  boxShadow: "4px 4px 12px #aaa",
  backgroundColor: "#fff",
}));

/*
-
STYLED BUTTONS
-
*/

export const TopBarContainedBrandGroupButton = styled(ButtonGroup)((theme) => ({
  backgroundColor: "transparent",
  border: 0,
  boxShadow: 0,
  color: "#fff",
  "&:hover": {
    backgroundColor: "transparent",
    color: "#037171",
  },
  fontSize: ".8rem",
  padding: 0,
  margin: "5px",
  // textTransform: "full-width",
  width: { xs: "100%", lg: "200px" },
  height: "50px",
  // textAlign: "end",
  "&:disabled": {
    backgroundColor: "#dfdfdf",
    color: "#afafaf",
  },
}));

export const TopBarContainedBrandButton = styled(Button)((theme) => ({
  backgroundColor: "#037171",
  color: "#fff",
  "&:hover": {
    backgroundColor: "#FFF",
    color: "#037171",
  },
  fontSize: ".8rem",
  padding: "15px",
  margin: "5px ",
  // textTransform: "full-width",
  width: { xs: "100%", lg: "200px" },
  height: "50px",
  // textAlign: "end",
  "&:disabled": {
    backgroundColor: "#dfdfdf",
    color: "#afafaf",
  },
}));

export const TopBarContainedOutlinedCancelButton = styled(Button)((theme) => ({
  backgroundColor: "white",
  color: "red",
  border: "1px red solid",
  "&:hover": {
    backgroundColor: "#FFF",
    color: "red",
  },
  fontSize: ".8rem",
  padding: "15px",
  margin: "5px ",
  // textTransform: "full-width",
  width: { xs: "100%", lg: "200px" },
  height: "50px",
  // textAlign: "end",
  "&:disabled": {
    backgroundColor: "#dfdfdf",
    color: "#afafaf",
  },
}));

export const TopBarContainedCancelButton = styled(Button)((theme) => ({
  backgroundColor: "red",
  color: "#fff",
  "&:hover": {
    backgroundColor: "#FFF",
    color: "red",
  },
  fontSize: ".8rem",
  padding: "15px",
  margin: "5px ",
  // textTransform: "full-width",
  width: { xs: "100%", lg: "200px" },
  height: "50px",
  // textAlign: "end",
  "&:disabled": {
    backgroundColor: "#dfdfdf",
    color: "#afafaf",
  },
}));

export const TopBarContainedWarningButton = styled(Button)((theme) => ({
  backgroundColor: "red",
  color: "#fff",
  "&:hover": {
    backgroundColor: "#FFF",
    color: "red",
  },
  fontSize: ".8rem",
  padding: "15px",
  margin: "5px ",
  alignItems: "center",
  "&:disabled": {
    backgroundColor: "#dfdfdf",
    color: "#afafaf",
  },
}));

export const ModalCancelButton = styled(Button)((theme) => ({
  backgroundColor: "red",
  color: "#fff",
  "&:hover": { backgroundColor: "#fff", color: "red" },
  margin: "3px",
  width: { xs: "100%", lg: "200px" },
  padding: "7px 3px",
  "&:disabled": {
    backgroundColor: "#efefef",
    color: "#afafaf",
  },
}));

export const ModalAcceptButton = styled(LoadingButton)((theme) => ({
  backgroundColor: "#037171",
  color: "#fff",
  "&:hover": { backgroundColor: "#fff", color: "#037171" },
  margin: "3px",
  width: { xs: "100%", lg: "200px" },
  padding: "7px 3px",
  "&:disabled": {
    backgroundColor: "#efefef",
    color: "#afafaf",
  },
}));

export const ModalWarningButton = styled(Button)((theme) => ({
  backgroundColor: "#037171",
  color: "#fff",
  "&:hover": { backgroundColor: "#fff", color: "#037171" },
  margin: "3px",
  width: { xs: "100%", lg: "200px" },
  padding: "7px 3px",
  "&:disabled": {
    backgroundColor: "#efefef",
    color: "#afafaf",
  },
}));

/**
 *
 * DATAGRID LOADING OVERLAY
 *
 */

export const StyledGridOverlay = styled("Box")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "80%",
  width: "100%",
}));

/**
 *
 * DNavigation Items
 *
 */

export const NavSubMenu = styled("Menu")(({ theme }) => ({
  borderRadius: "0 0 5px 5px",
  // padding: "5px",
  margin: 0,
  boxShadow: 0,
}));

export const NavMenuItem = styled(MenuItem)(({ theme }) => ({
  color: "white",
  fontFamily: "Poppins",
  textAlign: "start",
  fontSize: "1rem",
  fontWeight: 800,

  borderRadius: "5px",
  border: 0,

  backgroundColor: "transparent",

  textDecoration: "none",
  margin: "auto 0",
  padding: "auto 2px",
  width: "auto",

  "&:hover": {
    color: "#009f93",
    backgroundColor: "transparent",
  },
}));
export const NavMenuChildItem = styled(MenuItem)(({ theme }) => ({
  // color: "#037171",
  color: "white",
  fontFamily: "Poppins",
  textAlign: "start",
  fontSize: ".9rem",
  fontWeight: 800,

  border: 0,
  backgroundColor: "transparent",
  borderRadius: "5px",

  textDecoration: "none",
  margin: "0",
  padding: "10px 0",
  transitionDuration: "500ms",

  "&:hover": {
    color: "#009f93",
    backgroundColor: "#efefef",
    padding: "10px 5px",
    transitionDuration: "500ms",
  },
}));

export const MobileNavBar = styled(SwipeableDrawer)(({ theme }) => ({
  "& .MuiDrawer-paperAnchorLeft": {
    backgroundColor: "#037171",
    width: "75vw",
    height: "100vh",

    color: "white",
    fontSize: "1rem",
    fontWeight: 800,

    overflowY: "hidden",

    padding: "20px",
  },
}));

export const MobileNavSubMenu = styled(List)(({ theme }) => ({
  backgroundColor: "#efefef",
  borderRadius: "10px",

  padding: "10px 20px",

  color: "#037171",
  fontWeight: 800,
  fontSize: ".9rem",

  maxHeight: "30vh",
  overflowY: "auto",

  transitionDuration: "700ms",
  transitionProperty: "height",
}));

export const MobileNavItem = styled(ListItemButton)(({ theme }) => ({
  "& .MuiListItemButton-root": {
    padding: "10px 10px",

    "&:hover": {
      backgroundColor: "#efefef",
      color: "#009f93",
    },
  },
}));

export const MobileNavSubItem = styled(ListItemButton)(({ theme }) => ({
  "& .MuiListItemButton-root": {
    padding: "5px",
    color: "#037171",

    "&:hover": {
      backgroundColor: "#efefef",
      color: "#009f93",
    },
  },
}));

export const MobileNavParentText = styled(ListItemText)(({ theme }) => ({
  "& .MuiListItemText-root": { color: "#fff" },
}));
