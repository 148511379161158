import {
    CLIENT_FINANCIALS_URL,
    CLT_PMT_MTH_URL,
    OUTSTANDING_SUBSCRIPTION_INVOICES_CHARGES_URL
} from "../constants/url.constants"
import {serviceAPI} from "./service"
import {handleErrorResponse} from "../actions/message"

//GET REQUEST
export const getClientPaymentMethods = () => {
    return serviceAPI.get(CLT_PMT_MTH_URL)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getOutstandingSubscriptionInvoicesCharges = (subscriptionId) => {
    let url = OUTSTANDING_SUBSCRIPTION_INVOICES_CHARGES_URL + subscriptionId
    return serviceAPI.get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getClientFinancials = (clientId, pageNo) => {
    let url = CLIENT_FINANCIALS_URL + clientId + "&pageNo=" + pageNo
    return serviceAPI.get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}


//POST REQUEST
