import {
    APPROVE_QUOTATION_URL,
    DELETE_QUOTATION_DOC_URL,
    DELETE_QUOTATION_URL,
    FETCH_QUOTATION_ITEM_URL,
    FETCH_QUOTATION_URL,
    GENERATE_WORK_ORDER_DETAILS_URL,
    LIST_ALL_QUOTATIONS_URL,
    LIST_QUOTATION_ITEMS_TO_GEN_WO_DETAILS_URL,
    LIST_QUOTATIONS_URL,
    REJECT_QUOTATION_URL,
    SAVE_QUOTATION_URL,
    TOTAL_QUOTATIONS_URL,
    UPLOAD_QUOTATION_DOCUMENT
} from "../constants/url.constants"
import {serviceAPI} from "./service"
import {isNullUndefined, isValidDate} from "../util/util"
import {handleErrorResponse} from "../actions/message"

//GET REQUESTS

export const getQuotations = (statusCd, workOrderReferenceNo, description, addedOn, pageNo, rowsPerPage) => {
    let dateAdded = addedOn
    if (!isNullUndefined(addedOn) && isValidDate(addedOn)) {
        let date = new Date(addedOn)
        dateAdded = date.toISOString()
    }
    let url = LIST_QUOTATIONS_URL + statusCd + "&workOrderReferenceNo=" + workOrderReferenceNo +
        "&description=" + description + "&addedOn=" + dateAdded + "&pageNo=" + pageNo + "&rowsPerPage=" + rowsPerPage
    return serviceAPI.get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getAllQuotations = (statusCd, workOrderReferenceNo, description, addedOn) => {
    let dateAdded = addedOn
    if (!isNullUndefined(addedOn) && isValidDate(addedOn)) {
        let date = new Date(addedOn)
        dateAdded = date.toISOString()
    }
    let url = LIST_ALL_QUOTATIONS_URL + statusCd + "&workOrderReferenceNo=" + workOrderReferenceNo +
        "&description=" + description + "&addedOn=" + dateAdded
    return serviceAPI.get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getTotalQuotations = (statusCd, workOrderReferenceNo, description, addedOn) => {
    let dateAdded = addedOn
    if (!isNullUndefined(addedOn) && isValidDate(addedOn)) {
        let date = new Date(addedOn)
        dateAdded = date.toISOString()
    }
    let url = TOTAL_QUOTATIONS_URL + statusCd + "&workOrderReferenceNo=" + workOrderReferenceNo +
        "&description=" + description + "&addedOn=" + dateAdded
    return serviceAPI.get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const fetchQuotation = (quotationId) => {
    let url = FETCH_QUOTATION_URL + quotationId
    return serviceAPI.get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const fetchQuotationItem = (quotationItemId) => {
    let url = FETCH_QUOTATION_ITEM_URL + quotationItemId
    return serviceAPI.get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getQuotationItemsToGenWoDetails = (quotationId) => {
    let url = LIST_QUOTATION_ITEMS_TO_GEN_WO_DETAILS_URL + quotationId
    return serviceAPI.get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const deleteQuotationDoc = (documentId) => {
    let url = DELETE_QUOTATION_DOC_URL + documentId
    return serviceAPI.get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}


//POST REQUESTS
export const saveQuotationData = (data) => {
    return serviceAPI.post(SAVE_QUOTATION_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export function approveQuotationData(data) {
    return serviceAPI
        .post(APPROVE_QUOTATION_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export function uploadQuotationDocument(data, id) {
    let url = UPLOAD_QUOTATION_DOCUMENT + id
    return serviceAPI
        .post(url, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}


export function rejectQuotationData(data) {
    return serviceAPI
        .post(REJECT_QUOTATION_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}


export function deleteQuotationData(data) {
    return serviceAPI
        .post(DELETE_QUOTATION_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export function generateWorkOrderDetailsData(data) {
    return serviceAPI
        .post(GENERATE_WORK_ORDER_DETAILS_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

