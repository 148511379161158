/* eslint-disable react-hooks/exhaustive-deps */
import {Alert, AlertTitle} from "@mui/material"
import {ResponsiveRow} from "../../constants/layout.constants"
import {ModalAcceptButton} from "../../constants/component.constants"
import {Login} from "@mui/icons-material"

export default function CompletedDetails(props) {
    function loginPage() {
        window.location.href = "/"
    }

    return (
        <ResponsiveRow container sx={{width: "100%", height: "auto"}}>
            <ResponsiveRow
                sx={{
                    height: "auto",
                    width: "100%",
                }}
            >
                {
                    <Alert
                        sx={{marginBottom: "5px", textAlign: "start"}}
                        id="addUser"
                        severity="success"
                    >
                        <AlertTitle sx={{color: "#0f0f0f", textAlign: "start"}}>
                            Congratulations{" "}
                            {props &&
                                props.signUp &&
                                props.signUp.person &&
                                props.signUp.person.firstName + " "}
                            {props &&
                                props.signUp &&
                                props.signUp.person &&
                                props.signUp.person.lastName}
                        </AlertTitle>
                        Your sign up process is now completed and being worked on to ensure
                        everything is correctly set up. You will receive notifications or
                        direct call from one of our representative with
                        instructions/training on how to proceed.
                        <br/> <br/>
                        The remaining step is to login and enjoy our services.
                        <br/>{" "}
                        <Alert
                            severity="warning"
                            icon={<Login/>}
                            sx={{marginTop: "20px", width: "100%"}}
                        >
                            You can click the login button to navigate to the login page.
                        </Alert>
                    </Alert>
                }
            </ResponsiveRow>
            <ResponsiveRow
                sx={{
                    height: "auto",
                    width: "100%",
                    justifyContent: {xs: "center", lg: "flex-end"},
                }}
            >
                <ModalAcceptButton
                    onClick={loginPage}
                    sx={{
                        width: {xs: "100%", lg: "200px"},
                    }}
                >
                    Login
                </ModalAcceptButton>
            </ResponsiveRow>
        </ResponsiveRow>
    )
}
