import {
    Box,
    ClickAwayListener,
    Divider,
    Grid,
    Grow,
    IconButton,
    Link,
    ListItemIcon,
    ListItemText,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    Typography,
} from "@mui/material"
import PersonIcon from "@mui/icons-material/Person";
import {useDispatch} from "react-redux"
import {useNavigate} from "react-router-dom"
import {ResponsiveRow} from "../../constants/layout.constants"
import Menu from "@mui/icons-material/Menu"
import main_logo from "../../images/main-logo.png"
import {listingsLogout} from "../../actions/auth"
import {useRef, useState} from "react"

export default function ListingDashboardNavbar() {
    const [openSubMenu, setOpenSubMenu] = useState(false)
    const [mobileNav, setMobileNav] = useState(false)
    const anchorRef = useRef(null)

    const navigator = useNavigate()
    const dispatch = useDispatch()

    function handleClose() {
        setOpenSubMenu(false)
    }

    function handleNavigate() {
        navigator("/listings/dashboard/ManageListings")
    }

    function handleNavigateMyList() {
        navigator("/listings/dashboard/my-lists")
    }

    function handleLogout() {
        dispatch(listingsLogout())
        navigator("/listings/signIn")
    }

    const navigateToProfile = () => {
        navigator("/listings/profile");
      };

    return (
        <Grid sx={{position: "relative", zIndex: 5}}>
            <ResponsiveRow
                sx={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    backgroundColor: "#fff",
                    flexDirection: "row",
                    padding: "20px 10px",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    height: "100px",
                }}
            >
                <Link
                    href="/listings"
                    sx={{
                        margin: "auto 20px",
                        color: "#fff",
                        fontWeight: 700,
                        textDecoration: "none",
                    }}
                >
                    <Box
                        sx={{maxWidth: "auto", objectFit: "contain", height: "50px"}}
                        component="img"
                        src={main_logo}
                        alt="main logo"
                    />
                </Link>

                <Box sx={{display: {xs: "none", lg: "flex"}}}>
                    <Link
                        href="/listings/explore"
                        sx={{
                            margin: "auto 20px",
                            color: "#037171",
                            fontWeight: 700,
                            textDecoration: "none",
                        }}
                    >
                        Explore
                    </Link>
                    <Link
                        href="/listings/dashboard/favorites"
                        sx={{
                            margin: "auto 20px",
                            color: "#037171",
                            fontWeight: 700,
                            textDecoration: "none",
                        }}
                    >
                        Favourites
                    </Link>
                    <Link
                        href="/listings/dashboard/appointments"
                        sx={{
                            margin: "auto 20px",
                            color: "#037171",
                            fontWeight: 700,
                            textDecoration: "none",
                        }}
                    >
                        Appointments
                    </Link>
                    <Link
                        href="/listings/dashboard/applications"
                        sx={{
                            margin: "auto 20px",
                            color: "#037171",
                            fontWeight: 700,
                            textDecoration: "none",
                        }}
                    >
                        Applications
                    </Link>
                    <IconButton
                        sx={{
                            margin: "auto 20px",
                            color: "#037171",
                            fontWeight: 700,
                            textDecoration: "none",
                        }}
                        ref={anchorRef}
                        onClick={() => setOpenSubMenu(true)}
                    >
                        <Box
                            sx={{
                                width: "50px",
                                height: "50px",
                                borderRadius: "50%",
                                objectFit: "cover",
                                objectPosition: "center",
                            }}
                            component="img"
                            src="https://images.pexels.com/photos/4255415/pexels-photo-4255415.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"
                            alt="user avatar"
                        />
                    </IconButton>
                    <Popper
                        open={openSubMenu}
                        anchorEl={anchorRef.current}
                        role={undefined}
                        placement="bottom-start"
                        transition
                        disablePortal
                    >
                        {({TransitionProps, placement}) => (
                            <Grow
                                {...TransitionProps}
                                style={{
                                    transformOrigin:
                                        placement === "bottom-start"
                                            ? "center top"
                                            : "center bottom",
                                }}
                            >
                                <Paper>
                                    <ClickAwayListener onClickAway={handleClose}>
                                        <MenuList
                                            autoFocusItem={openSubMenu}
                                            id="composition-menu"
                                            aria-labelledby="composition-button"
                                        >
                                            <MenuItem
                                                sx={{
                                                    color: "#037171",
                                                    fontWeight: 600,
                                                    textAlign: "end",
                                                    width: "100%",
                                                }}
                                                onClick={handleNavigate}
                                            >
                                                My Listings
                                            </MenuItem>
                                            {/*:TODO : Story moved to backlog*/}
                                            {/*<MenuItem*/}
                                            {/*    sx={{*/}
                                            {/*        color: "#037171",*/}
                                            {/*        fontWeight: 600,*/}
                                            {/*        textAlign: "end",*/}
                                            {/*        width: "100%",*/}
                                            {/*    }}*/}
                                            {/*    onClick={handleNavigateMyList}*/}
                                            {/*>*/}
                                            {/*    My Lists*/}
                                            {/*</MenuItem>*/}
                                            <MenuItem key="profile" onClick={navigateToProfile}>
                                                <ListItemIcon>
                                                    <PersonIcon fontSize="small" sx={{ color: "#037171" }} />
                                                </ListItemIcon>
                                                <ListItemText sx={{ color: "#037171" }}>
                                                    Profile
                                                </ListItemText>
                                            </MenuItem>
                                            <MenuItem
                                                sx={{
                                                    color: "red",
                                                    fontWeight: 600,
                                                    textAlign: "end",
                                                    width: "100%",
                                                }}
                                                onClick={handleLogout}
                                            >
                                                Logout
                                            </MenuItem>
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </Box>
                <Box sx={{display: {xs: "flex", lg: "none"}}}>
                    <IconButton
                        sx={{width: "100px"}}
                        onClick={() => setMobileNav(!mobileNav)}
                    >
                        <Menu
                            sx={{
                                color: "#037171",
                                fontSize: "1.8rem",
                            }}
                        />
                    </IconButton>
                </Box>
                <Paper
                    sx={{
                        width: "50%",
                        margin: "0 0 0 45%",
                        display: mobileNav ? "flex" : "none",
                        textAlign: "start",
                    }}
                >
                    <MenuList sx={{width: "100%"}}>
                        <MenuItem sx={{width: "100%", textAlign: "start"}}>
                            <ListItemText sx={{width: "100%"}}>
                                <Link
                                    href="/listings/explore"
                                    sx={{
                                        width: "100%",
                                        color: "#037171",
                                        fontWeight: 700,
                                        textAlign: "start",
                                        textDecoration: "none",
                                    }}
                                >
                                    Explore
                                </Link>
                            </ListItemText>
                        </MenuItem>
                        <MenuItem sx={{width: "100%"}}>
                            <ListItemText sx={{width: "100%"}}>
                                <Link
                                    href="/listings/dashboard/favorites"
                                    sx={{
                                        width: "100%",
                                        color: "#037171",
                                        fontWeight: 700,
                                        textAlign: "start",
                                        textDecoration: "none",
                                    }}
                                >
                                    Favourites
                                </Link>
                            </ListItemText>
                        </MenuItem>
                        <MenuItem sx={{width: "100%"}}>
                            <ListItemText sx={{width: "100%"}}>
                                <Link
                                    href="/listings/dashboard/appointments"
                                    sx={{
                                        width: "100%",
                                        color: "#037171",
                                        fontWeight: 700,
                                        textAlign: "start",
                                        textDecoration: "none",
                                    }}
                                >
                                    Appointments
                                </Link>
                            </ListItemText>
                        </MenuItem>
                        <MenuItem sx={{width: "100%"}}>
                            <ListItemText sx={{width: "100%"}}>
                                <Link
                                    href="/listings/dashboard/applications"
                                    sx={{
                                        width: "100%",
                                        color: "#037171",
                                        fontWeight: 700,
                                        textAlign: "start",
                                        textDecoration: "none",
                                    }}
                                >
                                    Applications
                                </Link>
                            </ListItemText>
                        </MenuItem>
                        <Divider sx={{width: "75%", margin: "auto"}}/>
                        <MenuItem sx={{width: "100%"}}>
                            <ListItemText sx={{width: "100%"}}>
                                <Link
                                    href="/listings/dashboard/manageListings"
                                    sx={{
                                        width: "100%",
                                        color: "#037171",
                                        fontWeight: 700,
                                        textAlign: "start",
                                        textDecoration: "none",
                                    }}
                                >
                                    Manage Listings
                                </Link>
                            </ListItemText>
                        </MenuItem>
                        <MenuItem sx={{width: "100%"}}>
                            <ListItemText sx={{width: "100%"}}>
                                <Typography
                                    onClick={handleLogout}
                                    sx={{
                                        width: "100%",
                                        color: "red",
                                        fontWeight: 700,
                                        textAlign: "start",
                                    }}
                                >
                                    Log Out
                                </Typography>
                            </ListItemText>
                        </MenuItem>
                    </MenuList>
                </Paper>
            </ResponsiveRow>
        </Grid>
    )
}
