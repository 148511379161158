/* eslint-disable react-hooks/exhaustive-deps */
import {Divider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography,} from "@mui/material"
import {ModalAcceptButton} from "../../constants/component.constants"
import {ResponsiveRow} from "../../constants/layout.constants"
import {isArray, isEmptyArray, tryParseFloat} from "../../util/util"
import {useEffect, useState} from "react"

export default function ContractDetail(props) {
    const [chargeItems, setChargeItems] = useState([])
    const [chargesTableRows, setChargesTableRows] = useState("")
    const [chargeItemsTotal, setChargeItemsTotal] = useState(0)

    useEffect(() => {
        populateChargeItems()
    }, [chargeItems])

    useEffect(() => {
        if (props.contractType === "Lease") {
            let leaseCharges = props.contract.leaseCharges
            if (isArray(leaseCharges) && !isEmptyArray(leaseCharges)) {
                setChargeItems(leaseCharges)
            }
        }
        if (props.contractType === "Meter Billing") {
            let billingChargeItems = props.contract.billingChargeItems
            if (isArray(billingChargeItems) && !isEmptyArray(billingChargeItems)) {
                setChargeItems(billingChargeItems)
            }
        }
        if (props.contractType === "Ownership") {
            let ownershipCharges = props.contract.ownershipCharges
            if (isArray(ownershipCharges) && !isEmptyArray(ownershipCharges)) {
                setChargeItems(ownershipCharges)
            }
        }
        if (props.contractType === "Customer") {
            setChargeItems([{charge: {name: "Credit Limit"}, amount: props.contract.absoluteCreditLimit}])
        }
    }, [props.contractType, props.contract])

    function populateChargeItems() {
        let rows = ""
        let total = 0
        chargeItems &&
        chargeItems.length > 0 &&
        (rows = chargeItems.map(function (charge, i) {
            let amount = tryParseFloat(charge.amount, -1)
            if (amount > 0) {
                total = total + amount
            }
            return (
                <TableRow
                    key={i}
                    sx={{"&:last-child td, &:last-child th": {border: 0}}}
                >
                    <TableCell component="th" scope="row">
                        <Typography variant="body2">{charge.charge.name}</Typography>
                    </TableCell>

                    {
                        props &&
                        props.contractType &&
                        props.contractType !== "Customer" &&
                        <TableCell align="left">
                            <Typography variant="body2">
                                {props &&
                                    props.contractType &&
                                    props.contractType === "Lease" &&
                                    props.units &&
                                    props.units.filter((u) => u.id === charge.unitId)[0].name}
                                {props &&
                                    props.contractType &&
                                    props.contractType === "Ownership" &&
                                    props.contract.unitName}
                                {props &&
                                    props.contractType &&
                                    props.contractType === "Meter Billing" &&
                                    props.utilities &&
                                    props.utilities.filter((u) => u.id === charge.meterId)[0]
                                        .name}
                            </Typography>
                        </TableCell>
                    }

                    <TableCell align="left">
                        <Typography variant="body2">{charge.amount}</Typography>
                    </TableCell>
                </TableRow>
            )
        }, this))
        setChargesTableRows(rows)
        setChargeItemsTotal(total)
    }

    return (
        <ResponsiveRow
            sx={{
                flexDirection: "column",
                justifyContent: "center",
                minHeight: "350px",
                height: "auto",
                width: {xs: "100%", lg: "450px"},
                backgroundColor: "#efefef",
                borderRadius: "20px",
                textAlign: "start",
                padding: "20px",
                margin: "10px",
            }}
        >
            <Typography
                variant="h6"
                sx={{fontWeight: 600, color: "#037171", width: "100%"}}
            >
                {props &&
                    props.contractType &&
                    props.contractType === "Lease" &&
                    props.contract &&
                    props.contract.tenantName}
                {props &&
                    props.contractType &&
                    props.contractType === "Meter Billing" &&
                    props.contract &&
                    props.contract.customerName}
                {props &&
                    props.contractType &&
                    props.contractType === "Ownership" &&
                    props.contract &&
                    props.contract.ownerNames}
                {props &&
                    props.contractType &&
                    props.contractType === "Customer" &&
                    props.contract &&
                    props.contract.name}
            </Typography>
            <Typography variant="body2" sx={{width: "100%"}}>
                {props &&
                    props.contractType &&
                    props.contractType === "Lease" &&
                    props.contract &&
                    props.contract.unitNames}

                {props &&
                    props.contractType &&
                    props.contractType === "Ownership" &&
                    props.contract &&
                    props.contract.unitName}

                {props &&
                    props.contractType &&
                    props.contractType === "Meter Billing" &&
                    props.contract &&
                    props.contract.meterNames}
            </Typography>
            {
                props &&
                props.contractType &&
                props.contractType !== "Customer" &&
                <Typography variant="body2" sx={{marginTop: "20px", fontWeight: 600}}>
                    Billing Code: {" "}
                    {props && props.contract && props.contract.billingCode}
                </Typography>
            }{" "}
            <Typography
                variant="body2"
                sx={{fontWeight: 600, marginTop: "10px", marginBottom: "5px"}}
            >
                Charges Total: {chargeItemsTotal}
            </Typography>
            <Divider/>
            <TableContainer sx={{width: "100%"}}>
                <Table sx={{minWidth: "auto"}} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">Type</TableCell>
                            {props &&
                                props.contractType &&
                                (props.contractType === "Lease" ||
                                    props.contractType === "Ownership") && (
                                    <TableCell align="left">Unit</TableCell>
                                )}
                            {props &&
                                props.contractType &&
                                props.contractType === "Meter Billing" && (
                                    <TableCell align="left">Meter</TableCell>
                                )}
                            <TableCell align="left">Amount</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>{chargesTableRows}</TableBody>
                </Table>
                <ResponsiveRow
                    sx={{
                        justifyContent: {xs: "center", lg: "flex-end"},
                        height: "auto",
                        width: "100%",
                    }}
                >
                    <ModalAcceptButton
                        onClick={() => props.handleEditClick("4.1")}
                        sx={{height: "auto", width: {xs: "100%", lg: "200px"}}}
                    >
                        Edit
                    </ModalAcceptButton>
                </ResponsiveRow>
            </TableContainer>
        </ResponsiveRow>
    )
}
