import {Alert, AlertTitle, FormControl, InputLabel, MenuItem, OutlinedInput, Select, Typography,} from "@mui/material";
// import React, {useEffect, useState} from "react";
import {useEffect, useState} from "react";
import {ModalAcceptButton, ModalCancelButton,} from "../../../../constants/component.constants";
import {ResponsiveRow} from "../../../../constants/layout.constants";
import {getIdTypes} from "../../../../services/list.service";
import {personSearch} from "../../../../services/shared.service";
import {isEmptyArray} from "../../../../util/util";
import ModalContainerModule from "../../../modules/modalContainer";

/**
 *
 * REQUIRED PROPS
 *
 * setAddPersonModal
 * setPersonSearchModal
 * setSelectedVendor
 *
 */

export default function ChangeVendorContactPerson(props) {
    const [searchType, setSearchType] = useState("");
    const [foundUser, setFoundUser] = useState([]);
    const [noFoundUser, setNoFoundUser] = useState(false);
    const [idTypeOptions, setIdTypeOptions] = useState([]);

    const [idTypeSearch, setIdTypeSearch] = useState("");
    const [idNoSearch, setIdNoSearch] = useState("");

    function handleAddNewUserClicked() {
        setSearchType("Individual");
        props.setAddPersonModal(true);
        props.setPersonSearchModal(false);
    }

    function handleModalClose() {
        setFoundUser([]);
        props.setPersonSearchModal(false);
        // setSearchBy("")
        setIdTypeSearch("");
        setIdNoSearch("");
        setNoFoundUser(false);
    }

    function searchPerson() {
        let data = {
            searchType: "Individual",
            idType: idTypeSearch,
            idNumber: idNoSearch,
        };
        personSearch(data)
            .then((response) => {
                setFoundUser(response);
                setNoFoundUser(false);
                if (isEmptyArray(response)) {
                    setNoFoundUser(true);
                    setFoundUser([]);
                }
            })
            .catch((error) => {
                console.log("PersonSearch Error: ", error);
            });
    }

    useEffect(() => {
        getIdTypes()
            .then((idTypes) => setIdTypeOptions(idTypes))
            .catch((error) => {
                console.log("getIdTypes Error: ", error);
            });
    }, []);

    return (
      <>
        <ModalContainerModule
          size="specialReplaceVendorContact"
          cancel={handleModalClose}
          openModal={props.openModal}
          modalTitle="Replace Vendor Contact Person"
          acceptButtonText="Replace"
        >
          <ResponsiveRow
            sx={{ width: "100%", height: "100%", flexDirection: "column" }}
          >
            <Alert severity="info">
              Use this form to search for an existing individual to add as a
              user. If no matches are found, you can create a new individual as
              a user.
            </Alert>
            <ResponsiveRow sx={{ width: "100%", height: "100%" }}>
              <FormControl
                sx={{ width: { xs: "100%", lg: "33.3%" } }}
                container
              >
                <InputLabel>ID Number</InputLabel>
                <OutlinedInput
                  label="ID Number"
                  sx={{ width: "90%" }}
                  id={"idNoSearch"}
                  value={idNoSearch}
                  onChange={(event) => {
                    let value = event.target.value;
                    setIdNoSearch(value);
                  }}
                />
              </FormControl>
              <FormControl sx={{ width: { xs: "100%", lg: "33.3%" } }}>
                <InputLabel>ID Type</InputLabel>
                <Select
                  label="ID Type"
                  sx={{ width: "90%" }}
                  id={"idTypeSearch"}
                  value={idTypeSearch}
                  onChange={(event) => {
                    let value = event.target.value;
                    setIdTypeSearch(value);
                  }}
                >
                  {idTypeOptions && idTypeOptions.length > 0 ? (
                    idTypeOptions.map(function (idType, i) {
                      return (
                        <MenuItem key={idType.value} value={idType.value}>
                          {idType.label}
                        </MenuItem>
                      );
                    }, this)
                  ) : (
                    <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
                  )}
                </Select>
              </FormControl>
              <ResponsiveRow
                sx={{
                  width: "100%",
                  height: "auto",
                  justifyContent: { Xs: "center", lg: "flex-end" },
                  alignSelf: "flex-end",
                }}
              >
                <ModalAcceptButton
                  // type="submit"
                  onClick={searchPerson}
                  sx={{
                    width: { xs: "100%", lg: "200px" },
                  }}
                >
                  Search
                </ModalAcceptButton>
              </ResponsiveRow>
              <ResponsiveRow sx={{ flexDirection: "column" }}>
                <Typography color="primary" variant="h5">
                  Found Users
                </Typography>
                {foundUser && foundUser.length > 0 && (
                  <Typography variant="body2">Tap to select</Typography>
                )}
                {foundUser &&
                  foundUser.length > 0 &&
                  foundUser.map(function (user, i) {
                    return (
                      <ResponsiveRow
                        key={user.id}
                        sx={{ width: "100%", height: "auto" }}
                      >
                        <ResponsiveRow
                          item
                          onClick={() => props.handlePersonSelect(user)}
                          sx={{
                            width: "100%",
                            padding: "20px",
                            "&:hover": {
                              backgroundColor: "#efefef",
                              borderRadius: "10px",
                            },
                          }}
                        >
                          {user.name}
                        </ResponsiveRow>
                      </ResponsiveRow>
                    );
                  }, this)}

                {noFoundUser && (
                  <Alert
                    sx={{
                      width: "100%",
                      overflow: "hidden",
                      marginTop: "20px",
                      padding: "20x 0 0 0",
                    }}
                    severity="error"
                  >
                    <AlertTitle color="error">No Individuals found</AlertTitle>
                    <Typography sx={{ width: "100%" }}>
                      There were no individuals found with that ID number. Would
                      you like to create a new individuals?
                    </Typography>
                    <ResponsiveRow
                      sx={{
                        width: "100%",
                        justifyContent: "flex-end",
                        height: "auto",
                      }}
                    >
                      <ResponsiveRow sx={{ width: "100%" }}>
                        <ModalAcceptButton
                          onClick={props.handleAddNewUserClicked}
                          sx={{ width: { xs: "100%", lg: "100px" } }}
                        >
                          Yes
                        </ModalAcceptButton>
                        <ModalCancelButton
                          onClick={props.handleModalClose}
                          sx={{ width: { xs: "100%", lg: "100px" } }}
                        >
                          No
                        </ModalCancelButton>
                      </ResponsiveRow>
                    </ResponsiveRow>
                  </Alert>
                )}
              </ResponsiveRow>
            </ResponsiveRow>
          </ResponsiveRow>
        </ModalContainerModule>
      </>
    );
}
