import PropTypes from 'prop-types'
import {Box, Button} from "@mui/material"
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import {formatDateTime, isEmptyString, isNullUndefined} from "./util"
import {printWorkOrder} from "../services/print.service"
import {memo, useEffect, useRef, useState} from "react"

function isOverflown(element) {
    return (
        element.scrollHeight > element.clientHeight ||
        element.scrollWidth > element.clientWidth
    )
}

const GridCellExpand = memo(function GridCellExpand(props) {
    const {width, value, params} = props
    const wrapper = useRef(null)
    const cellDiv = useRef(null)
    const cellValue = useRef(null)
    const [anchorEl, setAnchorEl] = useState(null)
    const [showFullCell, setShowFullCell] = useState(false)
    const [showPopper, setShowPopper] = useState(false)
    const handleMouseEnter = () => {
        const isCurrentlyOverflown = isOverflown(cellValue.current)
        setShowPopper(isCurrentlyOverflown)
        setAnchorEl(cellDiv.current)
        setShowFullCell(true)
    }

    const handleMouseLeave = () => {
        setShowFullCell(false)
    }

    useEffect(() => {
        if (!showFullCell) {
            return undefined
        }

        function handleKeyDown(nativeEvent) {
            // IE11, Edge (prior to using Bink?) use 'Esc'
            if (nativeEvent.key === 'Escape' || nativeEvent.key === 'Esc') {
                setShowFullCell(false)
            }
        }

        document.addEventListener('keydown', handleKeyDown)

        return () => {
            document.removeEventListener('keydown', handleKeyDown)
        }
    }, [setShowFullCell, showFullCell])

    let outPutValue = value
    if (params.field === "payee") {
        outPutValue = value.name
    }
    if (params.field === "approvedOn") {
        outPutValue = ""
        if (!isNullUndefined(value) && !isEmptyString(value)) {
            outPutValue = formatDateTime(value)
        }
    }
    if (params.field === "workOrder") {
        outPutValue = populateOutput()
    }

    function populateOutput() {
        const onClick = () => {
            printWorkOrder(params.value.id)
        }

        if (!isNullUndefined(params.value)) {
            if (isNullUndefined(params.value.id) || isEmptyString(params.value.id)) {
                return ""
            } else {
                return <div> {params.value.description}<Button onClick={onClick}>#{params.value.refNumber}</Button>
                </div>
            }
        }
    }

    return (
        <Box
            ref={wrapper}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            sx={{
                alignItems: 'center',
                lineHeight: '24px',
                width: 1,
                height: 1,
                position: 'relative',
                display: 'flex',
            }}
        >
            <Box
                ref={cellDiv}
                sx={{
                    height: 1,
                    width,
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                }}
            />
            <Box
                ref={cellValue}
                sx={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}
            >
                {outPutValue}
            </Box>
            {showPopper && (
                <Popper
                    open={showFullCell && anchorEl !== null}
                    anchorEl={anchorEl}
                    style={{width, marginLeft: -17}}
                >
                    <Paper
                        elevation={1}
                        style={{minHeight: wrapper.current.offsetHeight - 3}}
                    >
                        <Typography variant="body2" style={{padding: 8}}>
                            {outPutValue}
                        </Typography>
                    </Paper>
                </Popper>
            )}
        </Box>
    )
})

GridCellExpand.propTypes = {
    value: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
}

export function renderCellExpand(params) {
    return (
        <GridCellExpand value={params.value || ''} width={params.colDef.computedWidth} params={params}/>
    )
}

renderCellExpand.propTypes = {
    /**
     * The column of the row that the current cell belongs to.
     */
    colDef: PropTypes.object.isRequired,
    /**
     * The cell value, but if the column has valueGetter, use getValue.
     */
    value: PropTypes.string.isRequired,
}