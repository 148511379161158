import { Alert, AlertTitle } from "@mui/material";

import ModalContainerModule from "../modalContainer";

export default function DeleteUserModal(props) {
  return (
    <ModalContainerModule
      size="deleteProperty"
      accept={props.handleDeleteUserClicked}
      cancel={props.handleCloseDeleteUserModal}
      openModal={props.deleteUserModal}
      modalTitle="Confirm Remove User"
      acceptButtonText="Yes"
    >
      <Alert sx={{ width: "100%" }} severity="error">
        <AlertTitle>Confirm Delete</AlertTitle>
        Are you sure you want to remove{" "}
        {props.userToDelete && props.userToDelete.firstName}{" "}
        {props.userToDelete && props.userToDelete.lastName}?
      </Alert>
    </ModalContainerModule>
  );
}
