import ModalContainerModule from "../../../modules/modalContainer";
import {ResponsiveRow} from "../../../../constants/layout.constants";
import {Typography} from "@mui/material";
import {formatDate, isNullUndefined} from "../../../../util/util";
import {
    DetailsColumn,
    DetailsContainer,
    DetailsLabel,
    DetailsRow,
    DetailsText,
} from "../../../../constants/component.constants";
import {DocumentStore} from "../../../documentStore";

export default function ViewVacateNotice(props) {
    let daysLeftText = ""
    let days = props?.selectedVacateNotice?.daysToVacate
    if (isNullUndefined(days)) {
        daysLeftText =  "∞"
    } else if (days < 0) {
        daysLeftText =  "Vacated"
    } else if (days >= 365) {
        daysLeftText = "1+ year"
    } else if (days >= 180) {
        daysLeftText = "6+ months"
    }else if (days >= 90) {
        daysLeftText = "3+ months"
    }else if (days >= 30) {
        daysLeftText = "1+ month"
    }else daysLeftText = days.toFixed(0) < 2 ? days.toFixed(0) + " day" : days.toFixed(0) + " days"

    return (
        <ModalContainerModule
            size="xs"
            cancel={props.handleClose}
            openModal={props.openModal}
            canceltDisabled={false}
            modalTitle="Vacate Notice"
            cancelText="Cancel"
        >
            <ResponsiveRow sx={{height: "auto", padding: 0}}>
                <Typography
                    id="modal-modal-description"
                    sx={{mt: 2, fontWeight: 700, width: "100%", height: "auto"}}
                >
                    {props.selectedVacateNotice && props.selectedVacateNotice.issuedByName}
                </Typography>
                <Typography
                    id="modal-modal-description"
                    sx={{
                        mt: 0,
                        color: "#037171",
                        fontSize: ".9rem",
                        width: "100%",
                        height: "auto",
                    }}
                >
                    {props.selectedVacateNotice
                        ? props.selectedVacateNotice && props.selectedVacateNotice.reason
                        : ""}
                </Typography>
            </ResponsiveRow>
            <ResponsiveRow
                container
                sx={{
                    flexWrap: "nowrap",
                    alignItem: "flex-start",
                    justifyContent: "center",
                    width: "100%",
                    height: "100%",
                }}
            >
                <DetailsContainer sx={{width: {xs: "100%", lg: "100%"}}}>
                    <DetailsColumn sx={{width: "100%"}}>
                        <DetailsRow>
                            <DetailsLabel id="issuedOn-label">Issued On:</DetailsLabel>
                            <DetailsText>
                                {props.selectedVacateNotice && props.selectedVacateNotice.issuedOn ? formatDate(props.selectedVacateNotice.issuedOn) : "--"}
                            </DetailsText>
                        </DetailsRow>
                        <DetailsRow>
                            <DetailsLabel id="vacateOn-label">Vacate On:</DetailsLabel>
                            <DetailsText>
                                {props.selectedVacateNotice && props.selectedVacateNotice.vacateOn ? formatDate(props.selectedVacateNotice.vacateOn) : "--"}
                            </DetailsText>
                        </DetailsRow>
                        <DetailsRow>
                            <DetailsLabel>Days left</DetailsLabel>
                            <DetailsText>
                                {daysLeftText}
                            </DetailsText>
                        </DetailsRow>
                    </DetailsColumn>
                    <DetailsColumn
                        sx={{
                            width: "100%",
                        }}
                    >
                        <DetailsRow>
                            <DetailsLabel id="tenantName-label">Tenant Name</DetailsLabel>
                            <DetailsText>
                                {props.selectedVacateNotice && props.selectedVacateNotice.lease && props.selectedVacateNotice.lease.tenantName }
                            </DetailsText>
                        </DetailsRow>
                        <DetailsRow>
                            <DetailsLabel>Unit Names</DetailsLabel>

                            <DetailsText>
                                {props.selectedVacateNotice &&
                                    props.selectedVacateNotice.lease && props.selectedVacateNotice.lease.unitNames}
                            </DetailsText>
                        </DetailsRow>
                        <DetailsRow>
                            <DetailsLabel>Applied To</DetailsLabel>
                            <DetailsText>
                                {props.selectedVacateNotice
                                    ? props.selectedVacateNotice.appliedTo
                                    : ""}
                            </DetailsText>
                        </DetailsRow>
                    </DetailsColumn>

                    <DetailsColumn
                        sx={{
                            width: "100%",
                        }}
                    >
                        <DetailsRow>
                            <DetailsLabel id="tenantName-label">Issued By</DetailsLabel>
                            <DetailsText>
                                {props.selectedVacateNotice && props.selectedVacateNotice.issuedByName }
                            </DetailsText>
                        </DetailsRow>
                        <DetailsRow>
                            <DetailsLabel>Reason</DetailsLabel>

                            <DetailsText>
                                {props.selectedVacateNotice &&
                                    props.selectedVacateNotice.reason}
                            </DetailsText>
                        </DetailsRow>
                        <DetailsRow>
                            <DetailsLabel>Billing Code</DetailsLabel>
                            <DetailsText>
                                {props.selectedVacateNotice && props.selectedVacateNotice.lease && props.selectedVacateNotice.lease.billingCode}
                            </DetailsText>
                        </DetailsRow>
                        <DetailsRow>
                            <DetailsLabel>Lease Effective Date</DetailsLabel>
                            <DetailsText>
                                {props.selectedVacateNotice && props.selectedVacateNotice.lease &&
                                    props.selectedVacateNotice.lease.effectiveDate &&
                                    formatDate(props.selectedVacateNotice.lease.effectiveDate)}
                            </DetailsText>
                        </DetailsRow>
                    </DetailsColumn>
                </DetailsContainer>
                <ResponsiveRow
                    sx={{
                        width: "100%",
                        boxShadow: 0,
                        padding: "10px",
                        margin: 0,
                        alignItems: "flex-start",
                    }}
                >
                    <DocumentStore
                        height="500px"
                        maxWidth="100%"
                        files={[
                            ...props.selectedVacateNotice.imageFiles,
                            ...props.selectedVacateNotice.otherDocuments,
                        ]}
                        item={props.selectedVacateNotice}
                        type={"Vacate Notice"}
                    />
                </ResponsiveRow>
            </ResponsiveRow>
        </ModalContainerModule>
    );
}
