import {useEffect, useState} from "react";
import {NavLink} from "react-router-dom";
import {useDispatch} from "react-redux";
import {AppBar, Box, Container, Grid, IconButton, Menu, Toolbar, Typography,} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import history from "../../helpers/history";
import {clearMessage} from "../../actions/message";
import {Login} from "@mui/icons-material";

export default function SignUpBar() {
    const [responsiveAnchorElNav, setResponsiveAnchorElNav] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        history.listen((location) => {
            dispatch(clearMessage()); // clear message when changing location
        });
    }, [dispatch]);

    const openResponsiveNav = Boolean(responsiveAnchorElNav);
    const attachResponsiveNavAnchorEL = (e) => {
        setResponsiveAnchorElNav(e.currentTarget);
    };

    const detachResponsiveNavAnchor = () => {
        setResponsiveAnchorElNav(null);
    };

    return (
        <AppBar position="static" elevation={5}>
            <Container maxWidth="xl">
                <Toolbar
                    component="div"
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "nowrap",
                        justifyContent: "flex-start",
                        padding: 0,
                        margin: "auto, 0",
                    }}
                    disableGutters
                >
                    {/*Web NAV*/}
                    <Box sx={{flexGrow: 1, display: {xs: "flex", md: "none"}}}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="responsive-menu-appbar"
                            aria-haspopup="true"
                            onClick={attachResponsiveNavAnchorEL}
                            color="inherit"
                        >
                            <MenuIcon/>
                        </IconButton>
                        <Menu
                            id="responsive-menu-appbar"
                            anchorEl={responsiveAnchorElNav}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "center",
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "center",
                            }}
                            open={openResponsiveNav}
                            onClose={detachResponsiveNavAnchor}
                            sx={{
                                display: "block",
                                justifyContent: "flex-start",
                            }}
                        ></Menu>
                    </Box>

                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{flexGrow: 1, display: {xs: "none", md: "flex"}}}
                    >
                        <img
                            src={require("../../images/nav-logo.png")}
                            width="120px"
                            alt=""
                        />
                    </Typography>

                    {/*WEB NAV*/}
                    <Box
                        sx={{
                            flexGrow: 1,
                            display: {xs: "none", md: "flex"},
                            justifyContent: "flex-start",
                        }}
                    >
                        <Grid
                            container
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "nowrap",
                            }}
                        ></Grid>
                    </Box>

                    <Box
                        sx={{
                            flexGrow: 1,
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "nowrap",
                            flexFrow: 1,
                            justifyContent: "flex-end",
                        }}
                    >
                        <IconButton
                            id="faqIconButton"
                            sx={{backgroundColor: "#037171"}}
                            aria-label=""
                            aria-haspopup="true"
                        >
                            <NavLink
                                style={{
                                    display: "flex",
                                    flexDirection: "row",

                                    alignItems: "center",
                                    textDecoration: "none",
                                }}
                                to="/"
                            >
                                <Typography
                                    sx={{
                                        fontSize: ".8rem",
                                        fontWeight: 900,
                                        textDecoration: "none",
                                        color: "white",

                                        margin: "auto 5px",
                                        height: "auto",
                                    }}
                                >
                                    Back to Login
                                </Typography>
                                <Login
                                    sx={{
                                        backgroundColor: "#037171",
                                        color: "#fff",
                                        margin: ".25rem",
                                    }}
                                />
                            </NavLink>
                        </IconButton>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}
