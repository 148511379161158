import {Divider, Grid, InputLabel, MenuItem, Select, TextField, Typography,} from "@mui/material";
import {useEffect, useState} from "react";
import {ResponsiveRow} from "../../../constants/layout.constants";
import ModalContainerModule from "../modalContainer";
import {isNullUndefined} from "../../../util/util";

/**
 *
 * REQUIRED PROPS
 *
 * showAddNewUserForm
 *
 * handleSaveUserClicked
 * handleAddUserClose
 * handleRoleChange
 *
 * selectedRole
 * selectedUser
 *
 * task
 * setFirstName
 * setMiddleName
 * setLastName
 * setMobilePhone
 * setOfficePhone
 * setEmail
 * setIdNo
 *
 * firstName
 * middleName
 * lastName
 * mobilePhone
 * officePhone
 * email
 * idTypeSearch
 * idTypes
 * idNoSearch
 * userRoles
 *
 */

export default function EditUserModal(props) {
    const [firstName, setFirstName] = useState("");
    const [middleName, setMiddleName] = useState("");
    const [lastName, setLastName] = useState("");
    const [mobilePhone, setMobilePhone] = useState("");
    const [officePhone, setOfficePhone] = useState("");
    const [email, setEmail] = useState("");
    const [idNoSearch, setIdNo] = useState("");

    console.log('calling the EditUserModal')

    function handleChange(e) {
        switch (e.target.name) {
            case "firstName":
                setFirstName(e.target.value);
                break;
            case "middleName":
                setMiddleName(e.target.value);
                break;
            case "lastName":
                setLastName(e.target.value);
                break;
            case "mobilePhone":
                setMobilePhone(e.target.value);
                break;
            case "officePhone":
                setOfficePhone(e.target.value);
                break;
            case "email":
                setEmail(e.target.value);
                break;
            case "idNo":
                setIdNo(e.target.value);
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        if (!isNullUndefined(props.selectedUser)) {
            setFirstName(props.selectedUser.firstName);
            setMiddleName(props.selectedUser.middleName);
            setLastName(props.selectedUser.lastName);
            setEmail(props.selectedUser.email);
            setMobilePhone(props.selectedUser.mobilePhone);
            setOfficePhone(props.selectedUser.officePhone);
            setIdNo(props.selectedUser.idNumber);
        }
    }, [props.selectedUser]);

    function handleSubmit() {
        let obj = props.selectedUser;
        obj.firstName = firstName;
        obj.middleName = middleName;
        obj.lastName = lastName;
        obj.mobilePhone = mobilePhone;
        obj.officePhone = officePhone;
        obj.email = email;
        obj.idNumber = idNoSearch;
        props.setSelectedUser(obj);
        props.handleSaveUserClicked();
    }

    function handleAddUserClose() {
        setFirstName("");
        setMiddleName("");
        setLastName("");
        setEmail("");
        setMobilePhone("");
        setOfficePhone("");
        setIdNo("");
        props.handleAddUserClose();
    }

    return (
      <ModalContainerModule
        size={props.isCompanyContact === true ? "specialEditUser" : "md"}
        accept={handleSubmit}
        cancel={handleAddUserClose}
        openModal={props.showAddNewUserForm}
        modalTitle={
          props.task === "Create"
            ? "Add User"
            : props.task === "Edit"
            ? "Edit User Details"
            : "View User Details"
        }
        acceptButtonText={props.task === "Create" ? "Create" : "Update"}
      >
        <ResponsiveRow sx={{ height: "auto" }}>
          <Typography
            sx={{ marginBottom: "5px" }}
            id="addUser"
            variant="h5"
            component="h5"
          >
            User's Details
          </Typography>
          <ResponsiveRow container>
            <Grid
              item
              sx={{
                width: { xs: "100%", lg: "33.3%" },
                marginBottom: "10px",
              }}
            >
              <InputLabel>First Name *</InputLabel>
              <TextField
                placeholder={"First Name"}
                sx={{ width: "90%" }}
                name="firstName"
                id="firstName"
                required={true}
                            autoFocus={true}
                            onChange={handleChange}
                            value={firstName}
                        />
                    </Grid>
                    <Grid
                        item
                        sx={{
                            width: {xs: "100%", lg: "33.3%"},
                            marginBottom: "10px",
                        }}
                    >
                        <InputLabel>Middle Name</InputLabel>
                        <TextField
                            placeholder={"Middle Name"}
                            sx={{width: "90%"}}
                            name={"middleName"}
                            id={"middleName"}
                            onChange={handleChange}
                            value={middleName}
                        />
                    </Grid>
                    <Grid
                        item
                        sx={{
                            width: {xs: "100%", lg: "33.3%"},
                            marginBottom: "10px",
                        }}
                    >
                        <InputLabel>Last Name *</InputLabel>
                        <TextField
                            placeholder={"Last Name"}
                            sx={{width: "90%"}}
                            name={"lastName"}
                            id={"lastName"}
                            required={true}
                onChange={handleChange}
                value={lastName}
              />
            </Grid>

            <Grid
              item
              sx={{
                width: { xs: "100%", lg: "33.3%" },
                marginBottom: "10px",
              }}
            >
              <InputLabel>Mobile Phone * (Primary Number)</InputLabel>
              <TextField
                placeholder={"phone No."}
                sx={{ width: "90%" }}
                name={"mobilePhone"}
                id={"mobilePhone"}
                required={true}
                            onChange={handleChange}
                            value={mobilePhone}
                        />
                    </Grid>

            <Grid
              item
              sx={{
                width: { xs: "100%", lg: "33.3%" },
                marginBottom: "10px",
              }}
            >
              <InputLabel>Office Phone (Secondary Number)</InputLabel>
              <TextField
                placeholder={"Office phone No."}
                sx={{ width: "90%" }}
                name={"officePhone"}
                id={"officePhone"}
                onChange={handleChange}
                value={officePhone}
              />
            </Grid>

            <Grid
              item
              sx={{
                width: { xs: "100%", lg: "33.3%" },
                marginBottom: "10px",
              }}
            >
              <InputLabel>Email *</InputLabel>
              <TextField
                placeholder={"Email"}
                sx={{ width: "90%" }}
                name={"email"}
                required={true}
                            id={"email"}
                            onChange={handleChange}
                            value={email}
                        />
                    </Grid>

            <Grid
              item
              sx={{
                width: { xs: "100%", lg: "33.3%" },
                marginBottom: "10px",
              }}
            >
              <InputLabel>ID Type</InputLabel>
              <Select
                sx={{ width: "90%" }}
                id={"idType"}
                value={props.selectedUser?.idTypeCd ?? props.idTypeSearch}
                onChange={(event) => props.handleIdTypeChange(event)}
              >
                {props.idTypes && props.idTypes.length > 0 ? (
                  props.idTypes.map(function (idType, i) {
                    return (
                      <MenuItem key={i} value={idType.value}>
                        {idType.label}
                      </MenuItem>
                    );
                  }, this)
                ) : (
                  <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
                )}
              </Select>
            </Grid>

            <Grid
              item
              sx={{
                width: { xs: "100%", lg: "33.3%" },
                marginBottom: "10px",
              }}
            >
              <InputLabel>ID Number *</InputLabel>
              <TextField
                placeholder={"ID No."}
                sx={{ width: "90%" }}
                name={"idNo"}
                required={true}
                            id={"idNo"}
                            onChange={handleChange}
                            value={idNoSearch}
                        />
                    </Grid>

          </ResponsiveRow>

          <Divider />
          <ResponsiveRow
            sx={{
              display: props.isCompanyContact ? "none" : "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              id="selectRole"
              variant="h5"
              component="h5"
              sx={{ marginBottom: "15px" }}
            >
              Add Role
            </Typography>
            <Grid item sx={{ width: { xs: "100%", lg: "50%" } }}>
              <InputLabel>Role at this property</InputLabel>
              <Select
                defaultValue=""
                id="filterUser"
                value={
                  props.selectedUser
                    ? props.selectedUser && props.selectedUser.roleCode
                    : props.selectedRole
                }
                sx={{
                  width: { xs: "100%", lg: "90%" },
                }}
                onChange={props.handleRoleChange}
              >
                {props.userRoles && props.userRoles.length > 0 ? (
                  props.userRoles.map(function (role, i) {
                    return (
                      <MenuItem key={i} value={role.value}>
                        {role.label}
                      </MenuItem>
                    );
                  }, this)
                ) : (
                  <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
                )}
              </Select>
            </Grid>
          </ResponsiveRow>
        </ResponsiveRow>
      </ModalContainerModule>
    );
}
