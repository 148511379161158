import {serviceAPI} from "./service"
import {EXPORT_CREDIT_NOTES, GET_TOTAL_CREDIT_NOTES, LIST_CREDIT_NOTES} from "../constants/url.constants"
import {isEmptyString, isNullUndefined, isValidDate} from "../util/util"
import {handleErrorResponse} from "../actions/message"


export const getCreditNotes = (referenceNo, dateCreatedFrom, dateCreatedTo, dateApprovedFrom, dateApprovedTo, status, creditTo, pageNo) => {
    let dateCreatedFromValue = ''
    let dateCreatedToValue = ''
    let dateApprovedFromValue = ''
    let dateApprovedToValue = ''
    if (!isNullUndefined(dateCreatedFrom) && isValidDate(dateCreatedFrom) && (!isNullUndefined(dateCreatedTo) || !isEmptyString(dateCreatedTo))) {
        let date = new Date(dateCreatedFrom)
        dateCreatedFromValue = date.toISOString()
    }
    if (!isNullUndefined(dateCreatedTo) && isValidDate(dateCreatedTo) && (!isNullUndefined(dateCreatedFrom) || !isEmptyString(dateCreatedFrom))) {
        let date = new Date(dateCreatedTo)
        dateCreatedToValue = date.toISOString()
    }
    if (!isNullUndefined(dateApprovedFrom) && isValidDate(dateApprovedFrom) && (!isNullUndefined(dateApprovedTo) || !isEmptyString(dateApprovedTo))) {
        let date = new Date(dateApprovedFrom)
        dateApprovedFromValue = date.toISOString()
    }
    if (!isNullUndefined(dateApprovedTo) && isValidDate(dateApprovedTo) && (!isNullUndefined(dateApprovedFrom) || !isEmptyString(dateApprovedFrom))) {
        let date = new Date(dateApprovedTo)
        dateApprovedToValue = date.toISOString()
    }
    let url = LIST_CREDIT_NOTES + "?referenceNo=" + referenceNo + "&dateCreatedFrom=" + dateCreatedFromValue + "&dateCreatedTo=" + dateCreatedToValue + "&dateApprovedFrom=" + dateApprovedFromValue + "&dateApprovedTo=" + dateApprovedToValue + "&status=" + status + "&creditTo=" + creditTo + "&pageNo=" + pageNo
    return serviceAPI
        .get(url)
        .then((response) => {
            return response.data
        })
        .catch(error => {
            handleErrorResponse(error)
        })
}

export const getTotalCreditNotes = (referenceNo, dateCreatedFrom, dateCreatedTo, dateApprovedFrom, dateApprovedTo, status, creditTo) => {
    let dateCreatedFromValue = ''
    let dateCreatedToValue = ''
    let dateApprovedFromValue = ''
    let dateApprovedToValue = ''
    if (!isNullUndefined(dateCreatedFrom) && isValidDate(dateCreatedFrom) && (!isNullUndefined(dateCreatedTo) || !isEmptyString(dateCreatedTo))) {
        let date = new Date(dateCreatedFrom)
        dateCreatedFromValue = date.toISOString()
    }
    if (!isNullUndefined(dateCreatedTo) && isValidDate(dateCreatedTo) && (!isNullUndefined(dateCreatedFrom) || !isEmptyString(dateCreatedFrom))) {
        let date = new Date(dateCreatedTo)
        dateCreatedToValue = date.toISOString()
    }
    if (!isNullUndefined(dateApprovedFrom) && isValidDate(dateApprovedFrom) && (!isNullUndefined(dateApprovedTo) || !isEmptyString(dateApprovedTo))) {
        let date = new Date(dateApprovedFrom)
        dateApprovedFromValue = date.toISOString()
    }
    if (!isNullUndefined(dateApprovedTo) && isValidDate(dateApprovedTo) && (!isNullUndefined(dateApprovedFrom) || !isEmptyString(dateApprovedFrom))) {
        let date = new Date(dateApprovedTo)
        dateApprovedToValue = date.toISOString()
    }
    let url = GET_TOTAL_CREDIT_NOTES + "?referenceNo=" + referenceNo + "&dateCreatedFrom=" + dateCreatedFromValue + "&dateCreatedTo=" + dateCreatedToValue + "&dateApprovedFrom=" + dateApprovedFromValue + "&dateApprovedTo=" + dateApprovedToValue + "&status=" + status + "&creditTo=" + creditTo
    return serviceAPI
        .get(url)
        .then((response) => {
            return response.data
        })
        .catch(error => {
            handleErrorResponse(error)
        })
}

export const exportCreditNotes = (data) => {
    return serviceAPI
        .post(EXPORT_CREDIT_NOTES, data)
        .then((response) => {
            return response.data
        })
        .catch(error => {
            handleErrorResponse(error)
        })
}