/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from "react"
import {IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField,} from "@mui/material"
import {getIdRefNumber, isArray, isEmptyString, isNullUndefined, isNumeric, tryParseFloat,} from "../../util/util"

import {Cancel} from "@mui/icons-material"
import {setMessage} from "../../actions/message"
import {ResponsiveRow} from "../../constants/layout.constants"
import ModalContainerModule from "../modules/modalContainer"
import {ModalAcceptButton} from "../../constants/component.constants"
import {useDispatch} from "react-redux";

export default function PriceRate(props) {
    const [tieredRates, setTieredRates] = useState([])
    const [tieredRatesRows, setTieredRatesRows] = useState("")
    const dispatch = useDispatch()

    useEffect(() => {
        if (props && props.selectedUtility && isArray(props.selectedUtility.rates)) {
            setTieredRates(props.selectedUtility.rates)
        }
    }, [])

    useEffect(() => {
        tieredRates.sort((a, b) => a.min - b.min)
        populateTieredRates()
    }, [tieredRates])

    function handlePriceRateAdd() {
        tieredRates.sort((a, b) => a.min - b.min)
        setTieredRates([
            ...tieredRates,
            {
                id: getIdRefNumber(),
                meterId: props.selectedUtility.id,
                rateTypeCode: "TIERD",
                amount: 0,
                min: tieredRates.length < 1 ? 0 : parseInt(tieredRates[tieredRates.length - 1].max + 1),
                max: tieredRates.length < 1 ? 100 : parseInt(tieredRates[tieredRates.length - 1].max + 100),
                effectiveDate: new Date(),
                expirationDate: null
            },
        ])
    }

    function populateTieredRates() {
        let rows =
            tieredRates &&
            tieredRates.length > 0 &&
            tieredRates.map(function (rate, i) {
                return (
                    <TableRow
                        key={i}
                        sx={{"&:last-child td, &:last-child th": {border: 0}}}
                    >
                        <TableCell component="th" scope="row" sx={{width: "30%"}}>
                            <TextField
                                variant="outlined"
                                placeholder="Minimum"
                                id={"min" + i}
                                name={"min" + i}
                                isrequired={true}
                                sx={{
                                    backgroundColor: "#efefef",
                                    borderRadius: "10px",
                                    minWidth: "100px",
                                }}
                                value={tieredRates && tieredRates[i] && tieredRates[i].min}
                                onChange={(event) => onTieredTextChanged(event, i)}
                            />
                        </TableCell>

                        <TableCell component="th" scope="row" sx={{width: "30%"}}>
                            <TextField
                                variant="outlined"
                                placeholder="Maximum"
                                id={"max" + i}
                                name={"max" + i}
                                isrequired={true}
                                sx={{
                                    backgroundColor: "#efefef",
                                    borderRadius: "10px",
                                    minWidth: "100px",
                                }}
                                value={tieredRates && tieredRates[i] && tieredRates[i].max}
                                onChange={(event) => onTieredTextChanged(event, i)}
                            />
                        </TableCell>

                        <TableCell component="th" scope="row" sx={{width: "30%"}}>
                            <TextField
                                variant="outlined"
                                placeholder="Amount"
                                id={"amount" + i}
                                name={"amount" + i}
                                isrequired={true}
                                sx={{
                                    backgroundColor: "#efefef",
                                    borderRadius: "10px",
                                    minWidth: "100px",
                                }}
                                value={tieredRates && tieredRates[i] && tieredRates[i].amount}
                                onChange={(event) => onTieredTextChanged(event, i)}
                            />
                        </TableCell>

                        {
                            tieredRates &&
                            tieredRates.length > 1 &&
                            <TableCell align="left">
                                <IconButton
                                    onClick={() => handlePriceRateDelete(i)}
                                    color="error"
                                    sx={{
                                        backgroundColor: "#fff",
                                        borderRadius: "10px",
                                        width: "100%",
                                        height: "100%",
                                    }}
                                >
                                    <Cancel
                                        sx={{
                                            fontSize: "2.5rem",
                                        }}
                                    />
                                </IconButton>
                            </TableCell>
                        }
                    </TableRow>
                )
            })
        setTieredRatesRows(rows)
    }

    function onTieredTextChanged(event, index) {
        let newArr = [...tieredRates]
        let fieldName = event.target.name
        let value = event.target.value

        if (fieldName === "min" + index) {
            if (!isNullUndefined(value)) {
                try {
                    if (isNumeric(value)) {  // passed in an actual number instead of str so can still be returned as right
                        newArr[index].min = parseInt(value, 0)
                    } else {
                        if (value.length === 0) {
                            newArr[index].min = ""
                        }
                        dispatch(
                            setMessage({
                                type: "warning",
                                message: "Only numbers are allowed",
                            })
                        )
                    }
                } catch (e) {
                    dispatch(
                        setMessage({
                            type: "warning",
                            message: e,
                        })
                    )
                }
            }
        }
        if (fieldName === "amount" + index) {
            if (!isNullUndefined(value)) {
                try {
                    if (isNumeric(value)) {  // passed in an actual number instead of str so can still be returned as right
                        newArr[index].amount = parseInt(value, 0)
                    } else {
                        if (value.length === 0) {
                            newArr[index].amount = ""
                        }
                        dispatch(
                            setMessage({
                                type: "warning",
                                message: "Only numbers are allowed",
                            })
                        )
                    }
                } catch (e) {
                    dispatch(
                        setMessage({
                            type: "warning",
                            message: e,
                        })
                    )
                }
            }
        }

        if (fieldName === "max" + index) {
            if (!isNullUndefined(value)) {
                try {
                    if (isNumeric(value)) {  // passed in an actual number instead of str so can still be returned as right
                        newArr[index].max = parseInt(value, 0)
                    } else {
                        if (value.length === 0) {
                            newArr[index].max = ""
                        }
                        dispatch(
                            setMessage({
                                type: "warning",
                                message: "Only numbers are allowed",
                            })
                        )
                    }
                } catch (e) {
                    dispatch(
                        setMessage({
                            type: "warning",
                            message: e,
                        })
                    )
                }
            }
        }
        setTieredRates(newArr)
    }

    function handlePriceRateDelete(index) {
        let tmp = tieredRates
        let lastIndex = tmp.length - 1
        tmp.splice(index, 1)
        if (index === lastIndex) {
            tmp[lastIndex - 1].max = 1000000000
        } else {
            tmp[0].min = 0
        }
        setTieredRates(tmp)
        populateTieredRates()
    }

    function validatePriceRate(utility) {
        //console.log("validatePriceRate utility ", utility)
        let invalidData = false
        if (isNullUndefined(utility.rates) || utility.rates.length <= 0) {
            dispatch(
                setMessage({
                    type: "warning",
                    message: "Must add price rates for TIERED rate type.",
                })
            )
            return false
        } else if (utility.rates[utility.rates.length - 1].max !== 1000000000) {
            dispatch(
                setMessage({
                    type: "warning",
                    message: "Max Quantity for the last tier must be equal to 1,000,000,000.",
                })
            )
            return false
        } else {
            utility.rates.forEach((rate, index) => {
                let theIndex = index + 1
                let minQuantity = !isNullUndefined(rate.min) ? rate.min.toString() : ""
                if (!isEmptyString(minQuantity)) {
                    let min = rate.min
                    if (min < 0) {
                        dispatch(
                            setMessage({
                                type: "warning",
                                message: "The value entered for min number of units for price rate " + theIndex + " is invalid",
                            })
                        )
                        invalidData = true
                    }
                } else {
                    dispatch(
                        setMessage({
                            type: "warning",
                            message: "Invalid value entered for min units for price rate " + theIndex,
                        })
                    )
                    invalidData = true
                }

                let maxQuantity = !isNullUndefined(rate.max) ? rate.max.toString() : ""
                if (!isEmptyString(maxQuantity)) {
                    let max = rate.max
                    if (max <= 0) {
                        dispatch(
                            setMessage({
                                type: "warning",
                                message: "The value entered for max number of units for price rate " + theIndex + " is invalid",
                            })
                        )
                        invalidData = true
                    }
                } else {
                    dispatch(
                        setMessage({
                            type: "warning",
                            message: "Invalid value entered for max units for price rate " + theIndex,
                        })
                    )
                    invalidData = true
                }

                let rateAmount = !isNullUndefined(rate.amount) ? rate.amount.toString() : ""
                if (!isEmptyString(rateAmount)) {
                    let amount = tryParseFloat(rate.amount, -1)
                    if (amount < 0) {
                        dispatch(
                            setMessage({
                                type: "warning",
                                message: "The value entered for amount for price rate " + theIndex + " is invalid",
                            })
                        )
                        invalidData = true
                    }
                } else {
                    dispatch(
                        setMessage({
                            type: "warning",
                            message: "Invalid value entered for amount for price rate " + theIndex,
                        })
                    )
                    invalidData = true
                }

                if (index > 0) {
                    if (rate.min < utility.rates[index - 1].max) {
                        dispatch(
                            setMessage({
                                type: "warning",
                                message: "A minimum price rate cannot be lesser than the maximum price rate of the previous tier!",
                            })
                        )
                        invalidData = true
                    }
                }
            })
        }

        if (invalidData) return false
        return true
    }

    function submit() {
        let utility = props.selectedUtility
        utility.rates = tieredRates
        if (validatePriceRate(utility)) {
            props.saveTier(utility)
        }
    }

    return (
        <>
            <ModalContainerModule
                size="md"
                accept={submit}
                cancel={props.onClose}
                openModal={props.open}
                modalTitle={"Tiered Rates for meter " + props.selectedUtility.name}
                acceptButtonText="Save"
            >
                <ResponsiveRow
                    container
                    sx={{
                        justifyContent: {xs: "center", lg: "space-between"},
                        height: "auto",
                    }}
                >
                    <TableContainer style={{maxHeight: 350}}>
                        <Table sx={{minWidth: 20}} aria-label="simple table" stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{width: "30%"}}>Minimum Units</TableCell>
                                    <TableCell sx={{width: "30%"}}>Maximum Units</TableCell>
                                    <TableCell sx={{width: "30%"}}>Charge per Unit</TableCell>
                                    <TableCell sx={{width: "10%"}}></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>{tieredRatesRows}</TableBody>
                        </Table>
                    </TableContainer>
                    <ResponsiveRow
                        item
                        sx={{
                            justifyContent: {xs: "center", lg: "flex-end"},
                            width: "100%",
                            height: "auto",
                        }}
                    >
                        <ModalAcceptButton
                            onClick={handlePriceRateAdd}
                            sx={{width: {xs: "100%", md: "200px"}}}
                        >
                            Add row
                        </ModalAcceptButton>
                    </ResponsiveRow>
                </ResponsiveRow>
            </ModalContainerModule>
        </>
    )
}
