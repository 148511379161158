import {useEffect, useMemo, useState} from "react";
import {useSelector} from "react-redux";
import {Alert, AlertTitle, Grid, Typography} from "@mui/material";
import {formatCurrency, isEmptyString, isNullUndefined, titleCase,} from "../../../../util/util";
import {issueWarnMessage} from "../../../../actions/message";
import {ResponsiveRow} from "../../../../constants/layout.constants";
import ModalContainerModule from "../../../modules/modalContainer";
import {getQuotationItemsToGenWoDetails} from "../../../../services/quotation.service";
import MuiDataGrid from "../../../modules/MuiDataGrid";
import kodi_theme from "../../../../themes/kodiThemeProvider";

export default function GenerateWorkOrderDetailsModal(props) {
    const appMessage = useSelector((state) => state.message);
    const [total, setTotal] = useState(0.0);
    const [quotationItemIds, setQuotationItemIds] = useState([]);
    const [quotationItems, setQuotationItems] = useState([]);
    const [selectionModel, setSelectionModel] = useState([]);

    useEffect(() => {
        if (
            !isNullUndefined(props.selectedQuotation) &&
            !isNullUndefined(props.selectedQuotation.id) &&
            !isEmptyString(props.selectedQuotation.id)
        ) {
            getQuotationItemsToGenWoDetails(props.selectedQuotation.id).then(
                (quotationItems) => {
                    setQuotationItems(quotationItems);
                }
            );
        }
    }, [props.selectedQuotation]);

    useEffect(() => {
        let total = 0;
        quotationItems &&
        quotationItems.length > 0 &&
        quotationItems.forEach(function (item, i) {
            if (selectionModel.includes(item.id)) {
                total = total + parseFloat(item.total);
            }
        });

        setTotal(total);
        setQuotationItemIds(selectionModel);
    }, [selectionModel]);

    function valid() {
        if (quotationItemIds.length === 0) {
            issueWarnMessage("No items to generate work order details.");
            return false;
        }
        if (
            isNullUndefined(props.selectedQuotation) ||
            isNullUndefined(props.selectedQuotation.id) ||
            isEmptyString(props.selectedQuotation.id)
        ) {
            issueWarnMessage(
                "Couldn't determine the identifier for quotation. Try refresh the page."
            );
            return false;
        }
        return true;
    }

    function generateWorkOrderDetails() {
        if (valid()) {
            //All fields valid, post
            setSelectionModel([]);
            setQuotationItemIds([]);
            props.handleGenerateWorkOrderDetails(quotationItemIds);
        }
    }

    const columns = useMemo(() => {
        let columnArray = [];
        columnArray.push({
            field: "memo",
            headerName: "Memo",
            minWidth: 350,
            flex: 1.75,
        });
        columnArray.push({
            field: "unitPrice",
            headerName: "Unit Price",
            width: 200,
            valueFormatter: (row) => {
                if (!isNullUndefined(row.value)) {
                    return formatCurrency(row.value);
                }
            },
        });
        columnArray.push({
            field: "quantity",
            headerName: "Quantity",
            minWidth: 130,
            flex: 0.65,
        });
        columnArray.push({
            field: "subTotal",
            headerName: "Sub Total",
            mimWidth: 200,
            flex: 1,
            valueFormatter: (row) => {
                if (!isNullUndefined(row.value)) {
                    return formatCurrency(row.value);
                }
            },
        });
        columnArray.push({
            field: "applyVAT",
            headerName: "Tax (%)",
            minWidth: 150,
            flex: 0.75,
            valueFormatter: (row) => {
                if (!isNullUndefined(row.value)) {
                    return row.value ? "16" : "0";
                }
            },
        });
        columnArray.push({
            field: "otherCharges",
            headerName: "Additional Charges",
            minWidth: 200,
            flex: 1,
            valueFormatter: (row) => {
                if (!isNullUndefined(row.value)) {
                    return formatCurrency(row.value);
                } else {
                    return "0";
                }
            },
        });
        columnArray.push({
            field: "total",
            headerName: "Total",
            minWidth: 200,
            flex: 1,
            valueFormatter: (row) => {
                if (!isNullUndefined(row.value)) {
                    return formatCurrency(row.value);
                }
            },
        });
        return columnArray;
    });

    return (
        <ModalContainerModule
            size="specialWorkOrderCreateBill"
            accept={generateWorkOrderDetails}
            cancel={props.closeModal}
            openModal={props.openModal}
            acceptDisabled={quotationItems && quotationItems.length < 1}
            modalTitle={`Generate work order details for Quotation "${
                props && props.selectedQuotation && props.selectedQuotation.description
            }"`}
            acceptButtonText="Submit"
        >
            <ResponsiveRow
                sx={{
                    height: "auto",
                    padding: "0",
                    textAlign: "left",
                    overflowY: "auto",
                }}
            >
                {props &&
                    props.selectedQuotation &&
                    !isNullUndefined(props.selectedQuotation.workOrder) && (
                        <Typography
                            sx={{marginBottom: "5px"}}
                            id="modal-modal-title"
                            variant="h6"
                            component="h4"
                        >
                            This is a quotation for work order{" "}
                            <span style={{color: kodi_theme.palette.primary.dark}}>
                {props &&
                    props.selectedQuotation &&
                    props.selectedQuotation.workOrder &&
                    props.selectedQuotation.workOrder.description}
                                .
              </span>{" "}
                            The vendor is{" "}
                            <span style={{color: kodi_theme.palette.primary.dark}}>
                {" "}
                                "
                                {props &&
                                    props.selectedQuotation &&
                                    props.selectedQuotation.vendor &&
                                    props.selectedQuotation.vendor.name}
                                ".
              </span>
                        </Typography>
                    )}
                {props &&
                    props.selectedQuotation &&
                    !isNullUndefined(props.selectedQuotation.workOrder) && (
                        <ResponsiveRow container sx={{width: "100%", height: "auto"}}>
                            <Alert severity="info" sx={{width: "100%"}}>
                                <AlertTitle>
                                    Select the items you wish to generate work order details for.
                                </AlertTitle>
                                The generated work order details can be viewed under work order
                                page and it will be pending awaiting for approval.
                            </Alert>
                        </ResponsiveRow>
                    )}

                <br/>

                {appMessage.display && (
                    <Alert sx={{width: "100%"}} severity={appMessage.type}>
                        <AlertTitle>{titleCase(appMessage.type)}</AlertTitle>
                        {appMessage.message}
                    </Alert>
                )}

                {quotationItems && quotationItems.length > 0 && (
                    <ResponsiveRow
                        sx={{
                            height: "fit-content",
                            width: "100%",

                            flexDirection: "column",
                            padding: 0,
                        }}
                    >
                        <Grid
                            container
                            spacing={1}
                            sx={{
                                padding: "10px",
                                width: {xs: "100%", md: "100%"},
                                backgroundColor: "#fff",
                                borderRadius: "10px",
                                minHeight: "240px",
                                margin: "0 auto",
                                boxShadow: "none",
                                height: "fit-content",
                            }}
                        >
                            {/* <DataGrid
                              rows={quotationItems}
                              columns={columns}
                              pageSize={6}
                              rowsPerPageOptions={[6]}
                              disableSelectionOnClick
                              checkboxSelection
                              onSelectionModelChange={setSelectionModel}
                              selectionModel={selectionModel}
                              autoHeight
                              sx={{
                                width: "100%",
                                backgroundColor: "#fff",
                                "& .MuiDataGrid-root": {
                                  padding: "20px 10px",
                                  borderWidth: "#fff",
                                },
                                "& .MuiDataGrid-columnHeaders": {
                                  "& .MuiDataGrid-checkboxInput": {
                                    color: "#fff",
                                  },
                                  backgroundColor: "#037171",
                                  color: "#fff",
                                  fontSize: "16px",
                                  fontWeight: 800,
                                  borderRadius: "10px 10px 0 0",
                                },
                              }}
                          /> */}
                            <MuiDataGrid
                                dataGridColumns={columns}
                                dataGridRows={quotationItems}
                                handleSelectedRows={setSelectionModel}
                                loading={false}
                                noBoxShadow={true}
                                alt={true}
                                height="325px"
                            />
                        </Grid>
                    </ResponsiveRow>
                )}

                <Alert severity="success" sx={{width: "100%"}}>
                    <AlertTitle>
                        Total Selected: {formatCurrency(total.toFixed(2))}
                    </AlertTitle>
                </Alert>

                {quotationItems && quotationItems.length < 1 && (
                    <Alert severity="warning">
                        <AlertTitle>
                            {props &&
                            props.selectedQuotation &&
                            isNullUndefined(props.selectedQuotation.workOrder)
                                ? "This quotation is not linked to a work order."
                                : "This Quotation does not have any items to generate work order details. All items have already generated work order details."}
                        </AlertTitle>
                    </Alert>
                )}
            </ResponsiveRow>
        </ModalContainerModule>
    );
}
