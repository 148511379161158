import {Typography} from "@mui/material"
import {
    DetailsCardContainer,
    DetailsColumn,
    DetailsContainer,
    DetailsLabel,
    DetailsRow,
    DetailsText,
} from "../../../constants/component.constants"
import ModalContainerModule from "../modalContainer"

export default function ViewUserModal(props) {
    return (
        <ModalContainerModule
            size="xs"
            cancel={props.handleViewUserClose}
            openModal={props.viewUser}
            modalTitle={`${props.userToView.firstName} ${props.userToView.lastName}`}
        >
            <Typography
                id="addUserForm"
                variant="h5"
                component="h5"
                sx={{marginBottom: 0, paddingBottom: 0, color: "#037171"}}
            >
                User's Details
            </Typography>
            <DetailsCardContainer
                sx={{
                    boxShadow: 0,
                    width: "100%",
                    height: "auto",
                    padding: 0,
                    margin: 0,
                }}
            >
                <DetailsContainer container sx={{marginTop: 0}}>
                    <DetailsColumn sx={{width: {xs: "100%", lg: "50%"}}}>
                        <DetailsRow item>
                            <DetailsLabel>First Name: </DetailsLabel>
                            <DetailsText>
                                {props.userToView
                                    ? props.userToView && props.userToView.firstName
                                    : ""}
                            </DetailsText>
                        </DetailsRow>
                        <DetailsRow item>
                            <DetailsLabel>Middle Name: </DetailsLabel>
                            <DetailsText>
                                {props.userToView
                                    ? props.userToView && props.userToView.middleName
                                    : ""}{" "}
                            </DetailsText>
                        </DetailsRow>
                        <DetailsRow item>
                            <DetailsLabel>Last Name: </DetailsLabel>
                            <DetailsText>
                                {props.userToView
                                    ? props.userToView && props.userToView.lastName
                                    : ""}
                            </DetailsText>
                        </DetailsRow>

                        <DetailsRow item>
                            <DetailsLabel>Mobile Phone (Primary Number): </DetailsLabel>
                            <DetailsText>
                                {props.userToView
                                    ? props.userToView && props.userToView.mobilePhone
                                    : ""}
                            </DetailsText>
                        </DetailsRow>

                        <DetailsRow item>
                            <DetailsLabel>Office Phone (Secondary Number): </DetailsLabel>
                            <DetailsText>
                                {props.userToView
                                    ? props.userToView && props.userToView.officePhone
                                    : ""}
                            </DetailsText>
                        </DetailsRow>
                    </DetailsColumn>
                    <DetailsColumn sx={{width: {xs: "100%", lg: "50%"}}}>
                        <DetailsRow item>
                            <DetailsLabel>Email:</DetailsLabel>
                            <DetailsText>
                                {props.userToView
                                    ? props.userToView && props.userToView.email
                                    : ""}
                            </DetailsText>
                        </DetailsRow>

                        <DetailsRow item>
                            <DetailsLabel>ID Type: </DetailsLabel>
                            <DetailsText>
                                {props.userToView
                                    ? props.userToView && props.userToView.idTypeCd
                                    : ""}
                            </DetailsText>
                        </DetailsRow>

                        <DetailsRow item>
                            <DetailsLabel>ID Number: </DetailsLabel>
                            <DetailsText>
                                {props.userToView
                                    ? props.userToView && props.userToView.idNumber
                                    : ""}
                            </DetailsText>
                        </DetailsRow>

                        <DetailsRow item>
                            <DetailsLabel>Username: </DetailsLabel>
                            <DetailsText>
                                {props.userToView
                                    ? props.userToView && props.userToView.username
                                    : ""}
                            </DetailsText>
                        </DetailsRow>

                        <DetailsRow item>
                            <DetailsLabel>Role at this property: </DetailsLabel>
                            <DetailsText>
                                {props.userToView
                                    ? props.userToView && props.userToView.roleName
                                    : ""}
                            </DetailsText>
                        </DetailsRow>
                    </DetailsColumn>
                </DetailsContainer>
            </DetailsCardContainer>
        </ModalContainerModule>
    )
}
