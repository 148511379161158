import React from "react";
import {ResponsiveRow} from "../../../../constants/layout.constants";
import {Alert, AlertTitle, Divider, Typography} from "@mui/material";
import {titleCase} from "../../../../util/util";
import {useSelector} from "react-redux";
import ModalContainerModule from "../../../modules/modalContainer";

export default function ActivateArchivedMeterModal(props) {
    const appMessage = useSelector((state) => state.message);

    return (
        <ModalContainerModule
            size="specialConfirmationModal"
            accept={props.activateSelectedMeter}
            cancel={() => {
                props.setselectedMeter(null);
                props.setArchiveModal(false);
            }}
            openModal={props.isOpen}
            modalTitle={"Activate Archived Meter"}
            acceptButtonText={"Activate"}
        >
            <Alert severity="info" sx={{height: "100%", width: "100%"}}>
                <AlertTitle id="modal-modal-title" variant="h6" component="h6">
                    Confirm Meter Activation
                </AlertTitle>
                <Divider/>

                {appMessage.display && (
                    <Alert severity={appMessage.type}>
                        <AlertTitle>{titleCase(appMessage.type)}</AlertTitle>
                        {appMessage.message}
                    </Alert>
                )}
                <ResponsiveRow
                    sx={{
                        height: "auto",
                        width: "100%",
                    }}
                >
                    <Typography sx={{fontSize: "1rem"}}>
                        Are you sure you wish to reactivate Meter{" "}
                        {props.selectedMeter && props.selectedMeter.name}?
                    </Typography>
                </ResponsiveRow>
                <br/>
            </Alert>
        </ModalContainerModule>
    );
}
