import {serviceAPI} from "./service"
import {LIST_BILLING_SERVICES, SAVE_BILLING_SERVICES,} from "../constants/url.constants"
import {handleErrorResponse} from "../actions/message"

export const loadBillingServices = () => {
    return serviceAPI
        .get(LIST_BILLING_SERVICES)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const saveBillingServices = (data) => {
    return serviceAPI
        .post(SAVE_BILLING_SERVICES, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}