import {Alert, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography,} from "@mui/material"
import ModalContainerModule from "../../../components/modules/modalContainer"
import {ModalAcceptButton} from "../../../constants/component.constants"
import {ResponsiveRow} from "../../../constants/layout.constants"

export default function BulkEditOwnershipsModal(props) {
    /**
     *
     * REQUIRED PROPS
     *
     * handleBulkEditOwnershipClose
     * handleOwnershipChargeItemAdd
     *
     * bulkEditOwnershipClick
     * chargeItemsTotal
     * ownershipChargesTableRows
     * task
     * unitDetailTableRows
     *
     *
     */

    return (
        <ModalContainerModule
            size="md"
            accept={props.bulkEditOwnershipClick}
            cancel={props.handleBulkEditOwnershipClose}
            openModal={props.task === "BulkEdit"}
            modalTitle="Bulk Edit Ownerships"
            acceptButtonText="Save"
        >
            <ResponsiveRow sx={{width: "100%", height: "auto"}}>
                <Typography
                    sx={{marginBottom: "5px", width: "100%"}}
                    id="modal-modal-title"
                    variant="h5"
                    component="h4"
                >
                    Units Details
                </Typography>
                <ResponsiveRow container>
                    <TableContainer style={{maxHeight: 300}}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{width: "50%"}}>Unit Name</TableCell>
                                    <TableCell sx={{width: "50%"}}>Owner Name</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>{props.unitDetailTableRows}</TableBody>
                        </Table>
                    </TableContainer>
                </ResponsiveRow>
            </ResponsiveRow>
            <br/>
            <ResponsiveRow sx={{height: "auto"}}>
                <Typography
                    sx={{marginBottom: "5px"}}
                    id="modal-modal-title"
                    variant="h5"
                    component="h4"
                >
                    Ownership Charges
                </Typography>
                <TableContainer>
                    <Table sx={{minWidth: 650}} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{width: "15%"}}>Charge Type</TableCell>
                                <TableCell sx={{width: "20%"}}>Amount</TableCell>
                                <TableCell sx={{width: "20%"}}>Billing Period</TableCell>
                                <TableCell sx={{width: "20%"}}>Invoice Day/Date</TableCell>
                                <TableCell sx={{width: "10%"}}>Taxed?</TableCell>
                                <TableCell sx={{width: "10%"}}>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>{props.ownershipChargesTableRows}</TableBody>
                    </Table>
                </TableContainer>
                <ResponsiveRow container>
                    <Alert sx={{width: "100%"}} severity="info">
                        Total: {props.chargeItemsTotal}
                    </Alert>
                </ResponsiveRow>
                <ResponsiveRow
                    sx={{
                        justifyContent: {xs: "center", lg: "flex-end"},
                    }}
                >
                    <ModalAcceptButton
                        onClick={() => props.handleOwnershipChargeItemAdd()}
                        sx={{width: {xs: "100%", lg: "200px"}}}
                    >
                        Add Row
                    </ModalAcceptButton>
                </ResponsiveRow>
            </ResponsiveRow>
        </ModalContainerModule>
    )
}
