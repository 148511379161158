import { ResponsiveRow } from '../constants/layout.constants';
import {
	DetailsColumn,
	DetailsContainer,
	DetailsLabel,
	DetailsRow,
	DetailsText,
} from '../constants/component.constants';
import { formatDate, isEmptyArray } from '../util/util';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import ModalContainerModule from '../components/modules/modalContainer';
import { useSelector } from 'react-redux';
import { printCharge } from '../services/print.service';

export default function DebitNoteView(props) {
	const { user } = useSelector((state) => state.auth);
	let rows = [];
	let debitNoteItems = props.chargeItem?.items;
	debitNoteItems &&
		!isEmptyArray(debitNoteItems) &&
		(rows = debitNoteItems.map((item, i) => {
			return (
				<TableRow>
					<TableCell>{item.account && item.account.name}</TableCell>
					<TableCell>{item.amount}</TableCell>
					<TableCell>{item.notes}</TableCell>
				</TableRow>
			);
		}));

	const handlePrint = () => {
		printCharge(props.chargeItem?.id);
	};

	return (
		<ModalContainerModule
			size="sm"
			accept={user.proxyRole === 'BLDIR' ? props.approveDebitNote : false}
			reject={user.proxyRole === 'BLDIR' ? props.rejectDebitNote : false}
			cancel={props.closeChargeModal}
			openModal={props.openChargeModal}
			modalTitle={'Review Charge'}
			acceptButtonText={'Approve'}
			rejectButtonText={'Reject'}
			acceptDisabled={user.proxyRole === 'BLDIR' ? false : true}
			printAction={handlePrint}
		>
			<ResponsiveRow sx={{ paddingTop: 0 , height: "auto"}}>
				<DetailsContainer sx={{ width: '100%' }}>
					<DetailsColumn
						sx={{
							width: { lg: '50%', md: '100%', xs: '100%' },
						}}
					>
						<DetailsRow>
							<DetailsLabel>Charged To:</DetailsLabel>
							<DetailsText>{props.chargeItem?.tenant?.name}</DetailsText>
						</DetailsRow>
						<DetailsRow>
							<DetailsLabel>Memo:</DetailsLabel>
							<DetailsText>{props.chargeItem?.memo}</DetailsText>
						</DetailsRow>
						<DetailsRow>
							<DetailsLabel>Created By:</DetailsLabel>
							<DetailsText>{props.chargeItem?.createdBy?.name}</DetailsText>
						</DetailsRow>
						<DetailsRow>
							<DetailsLabel>Applied To:</DetailsLabel>
							<DetailsText>
								{props.chargeItem?.appliedTo?.replace(/[()]/g, '')}
							</DetailsText>
						</DetailsRow>
						<DetailsRow>
							<DetailsLabel>Reference Number:</DetailsLabel>
							<DetailsText>{props.chargeItem?.referenceNumber}</DetailsText>
						</DetailsRow>
						<DetailsRow>
							<DetailsLabel>Status:</DetailsLabel>
							<DetailsText>{props.chargeItem?.status?.name}</DetailsText>
						</DetailsRow>
					</DetailsColumn>
					<DetailsColumn
						sx={{
							width: { lg: '50%', md: '100%', xs: '100%' },
						}}
					>
						<DetailsRow>
							<DetailsLabel>Amount:</DetailsLabel>
							<DetailsText>{props.chargeItem?.amount}</DetailsText>
						</DetailsRow>
						<DetailsRow>
							<DetailsLabel>Date:</DetailsLabel>
							<DetailsText>
								{props.chargeItem && formatDate(props.chargeItem.date)}
							</DetailsText>
						</DetailsRow>
						<DetailsRow>
							<DetailsLabel>Date Created:</DetailsLabel>
							<DetailsText>
								{props.chargeItem &&
									props.chargeItem.dateCreated &&
									formatDate(props.chargeItem.dateCreated)}
							</DetailsText>
						</DetailsRow>
						<DetailsRow>
							<DetailsLabel>Approved by:</DetailsLabel>
							<DetailsText>
								{props.chargeItem &&
									props.chargeItem.approvedBy &&
									props.chargeItem.approvedBy.name}
							</DetailsText>
						</DetailsRow>
						<DetailsRow>
							<DetailsLabel>Date Approved:</DetailsLabel>
							<DetailsText>{props.chargeItem?.dateApproved}</DetailsText>
						</DetailsRow>
					</DetailsColumn>
				</DetailsContainer>

				<TableContainer component={Paper}>
					<Table aria-label="simple table">
						<TableHead>
							<TableRow>
								<TableCell>Account Charged</TableCell>
								<TableCell>Amount</TableCell>
								<TableCell>Notes</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>{rows}</TableBody>
					</Table>
				</TableContainer>
			</ResponsiveRow>
		</ModalContainerModule>
	);
}
