import {useEffect, useState} from "react";
import {FormControl, TextField, Typography} from "@mui/material";
import {ResponsiveRow} from "../../../constants/layout.constants";
import LineChartsCard from "../../charts/LineChartCard";
import {getBudgetVsExpenses} from "../../../services/dashboard.service";
import {isNullUndefined} from "../../../util/util";

import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/en-gb";
import DateFnsUtils from "@date-io/date-fns";
import dayjs from "dayjs";
import {useSelector} from "react-redux";

/**
 *
 * REQUIRED PROPS
 *
 * customWidth: string
 * customHeight: string
 * data: {
 *    budgetData: []
 *    expensesData: []
 *  }
 *
 */

export default function BudgetVsExpenses({customHeight, customWidth}) {
    const [year, setYear] = useState(new Date());
    const [loading, setLoading] = useState(false);
    const [budgetData, setBudgetData] = useState({
        budgetIncome: null,
        budgetExpense: null,
    });
    const {user} = useSelector((state) => state.auth)
    useEffect(() => {
        setLoading(true);
        getBudgetVsExpenses(year.getFullYear())
            .then((response) => {
                if (response && response.data) {
                    setBudgetData(response.data);
                }
            })
            .finally(() => {
                setLoading(false);
            });
        return () => {
            setBudgetData({
                budgetIncome: null,
                budgetExpense: null,
            });
        };
    }, [year, user.proxyFacility]);

    return (
        <ResponsiveRow
            sx={{
                flexDirection: "column",
                flexWrap: "nowrap",

                height: {xs: "100%", lg: customHeight},
                minWidth: {xs: "100%", lg: customWidth},
                width: "100%",

                margin: "10px",

                backgroundColor: "#fff",
                borderRadius: "20px",
            }}
        >
            <ResponsiveRow
                sx={{
                    flexDirection: "row",

                    width: "100%",
                    height: "auto",

                    textAlign: "start",

                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
                <Typography sx={{width: "75%"}} variant="h5">
                    Home Owners: Budget vs Expenses
                </Typography>
                <FormControl sx={{width: {xs: "100%", lg: "25%"}}}>
                    <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale="en-gb"
                    >
                        <LocalizationProvider utils={DateFnsUtils}>
                            <DatePicker
                                id="homeOWnerBudgetVsExpenses"
                                label="Select Year"
                                sx={{
                                    backgroundColor: "#fff",
                                    width: {xs: "100%", lg: "90%"},
                                    margin: "auto",
                                    borderRadius: "10px",
                                }}
                                openTo="year"
                                disableFuture
                                views={["year"]}
                                minDate="2015"
                                maxDate={dayjs()}
                                value={dayjs(year)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={(new_value) => setYear(new_value)}
                            />
                        </LocalizationProvider>
                    </LocalizationProvider>
                </FormControl>
            </ResponsiveRow>

            {isNullUndefined(budgetData.budgetExpense) &&
            isNullUndefined(budgetData.budgetIncome) ? (
                <ResponsiveRow
                    sx={{
                        margin: "0",

                        height: "100%",
                        width: "100%",

                        flexWrap: "nowrap",
                        flexDirection: "row",

                        alignItem: "center",
                        justifyContent: "center",
                    }}
                >
                    <Typography
                        variant="body2"
                        sx={{width: "100%", height: "auto", margin: "auto"}}
                    >
                        No data
                    </Typography>
                </ResponsiveRow>
            ) : (
                <ResponsiveRow
                    sx={{
                        height: "80%",
                        width: "100%",

                        flexWrap: "nowrap",
                        flexDirection: "row",

                        alignItem: "center",
                        justifyContent: "center",
                    }}
                >
                    <LineChartsCard
                        currentlyLoading={loading}
                        labelDataObj={{
                            dataLabels: [
                                "Jan",
                                "Feb",
                                "Mar",
                                "Apr",
                                "May",
                                "Jun",
                                "Jul",
                                "Aug",
                                "Sep",
                                "Oct",
                                "Nov",
                                "Dec",
                            ],

                            dataset: [
                                {
                                    datasetLabel: "Income",
                                    datasetData: budgetData.budgetIncome,
                                },
                                {
                                    datasetLabel: "Expenses",
                                    datasetData: budgetData.budgetExpense,
                                },
                            ],
                        }}
                    />
                </ResponsiveRow>
            )}
        </ResponsiveRow>
    );
}
