import { handleErrorResponse } from "../actions/message";
import {
  GET_KRA_PAYMENT_VOUCHERS,
  GET_TAXES_TOTALS,
  SAVE_KRA_PAYMENT_VOUCHER
} from "../constants/url.constants";
import { serviceAPI } from "./service";

export const getTaxesTotals = () => {
  return serviceAPI
    .get(GET_TAXES_TOTALS)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      handleErrorResponse(error);
    });
};

export const getKRAPaymentVouchers = () => {
  return serviceAPI
    .get(GET_KRA_PAYMENT_VOUCHERS)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      handleErrorResponse(error);
    });
};

export const saveKraPaymentVoucher = (newPayment) => {
  return serviceAPI
    .post(SAVE_KRA_PAYMENT_VOUCHER, newPayment)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      handleErrorResponse(error);
    });
}
