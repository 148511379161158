import {
  Alert,
  AlertTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import {
  ModalAcceptButton,
  ModalCancelButton,
} from "../../constants/component.constants";
import { ResponsiveRow } from "../../constants/layout.constants";
import ModalContainerModule from "../modules/modalContainer";

export default function IndividualSearchModal(props) {
  /**
   *
   * REQUIRED PROPS
   *
   * showAddNewUserForm
   *
   * handleIdTypeChange
   * handleIndividualSearchClose
   * handlePersonOrgSelect
   *
   * foundUser
   * idNoSearch
   * individual
   * idTypes
   * idTypeSearch
   * noFoundUser
   * onTextChange
   * searchPersonOrg
   * userModal
   *
   */
  return (
    <ModalContainerModule
      size="xs"
      cancel={props.handleIndividualSearchClose}
      openModal={props.userModal}
      modalTitle="Individual Search"
    >
      <Alert severity="info" sx={{ width: "100%" }}>
        Use this form to search for an existing individual to add as a user. If
        no matches are found, you can create a new individual as a user.
      </Alert>
      <ResponsiveRow sx={{ height: "auto", alignItems: "center" }}>
        <ResponsiveRow container sx={{ height: "auto" }}>
          {props.individual ? (
            <FormControl sx={{ width: { xs: "100%", lg: "50%" } }}>
              <InputLabel ID="idSearchLabel">ID Number</InputLabel>
              <OutlinedInput
                sx={{ width: { xs: "100%", lg: "90%" } }}
                id="idNoSearch"
                label="ID Number"
                value={props.idNoSearch}
                onChange={(searchText) => props.onTextChange(searchText)}

              />
            </FormControl>
          ) : (
            ""
          )}
          {props.individual ? (
            <FormControl
              item
              sx={{ width: { xs: "100%", lg: "50%" }, height: "auto" }}
            >
              <InputLabel if="idTypeLabel">ID Type</InputLabel>
              <Select
                sx={{ width: { xs: "100%", lg: "90%" } }}
                id="idTypeSearch"
                label="ID Type"
                value={props.idTypeSearch}
                onChange={(event) => props.handleIdTypeChange(event)}

              >
                {props.idTypes && props.idTypes.length > 0 ? (
                  props.idTypes.map(function (idType, i) {
                    return (
                      <MenuItem key={idType.value} value={idType.value}>
                        {idType.label}
                      </MenuItem>
                    );
                  }, this)
                ) : (
                  <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
                )}
              </Select>
            </FormControl>
          ) : (
            ""
          )}
          <ResponsiveRow
            item
            sx={{
              justifyContent: { xs: "center", lg: "flex-end" },
              height: "auto",
            }}
          >
            <ModalAcceptButton
              variant="contained"
              type="submit"
              onClick={props.searchPersonOrg}
              sx={{
                width: { xs: "100%", lg: "150px" },
              }}
            >
              Search
            </ModalAcceptButton>
          </ResponsiveRow>
        </ResponsiveRow>
        <ResponsiveRow
          sx={{
            width: "100%",
            flexDirection: "column",
            height: "auto",
            padding: { xs: 0, lg: "10px" },
          }}
        >
          <Typography color="primary" variant="h5">
            Found Users
          </Typography>
          {props.foundUser && props.foundUser.length > 0 && (
            <Typography variant="body2">Tap to select</Typography>
          )}
          {props.foundUser &&
            props.foundUser.length > 0 &&
            props.foundUser.map(function (user, i) {
              return (
                <ResponsiveRow
                  key={user.id}
                  sx={{ width: "100%", height: "auto" }}
                >
                  <Grid
                    item
                    onClick={() => props.handlePersonOrgSelect(user)}
                    sx={{
                      width: "100%",
                      padding: "20px",
                      "&:hover": {
                        backgroundColor: "#efefef",
                        borderRadius: "10px",
                      },
                    }}
                  >
                    {user.name}
                  </Grid>
                </ResponsiveRow>
              );
            }, this)}
          {/* </ResponsiveRow> */}

          {props.noFoundUser && (
            // <ResponsiveRow sx={{ width: "100%", height: "auto" }}>
            <Alert
              sx={{
                width: "100%",
                overflow: "hidden",
                marginTop: "20px",
                padding: "20x 0 0 0",
              }}
              severity="error"
            >
              <AlertTitle color="error">No Individuals found</AlertTitle>
              <Typography sx={{ width: "100%" }}>
                There were no individuals found with that ID number. Would you
                like to create a new individuals?
              </Typography>
              <ResponsiveRow
                sx={{
                  width: "100%",
                  justifyContent: "flex-end",
                  height: "auto",
                }}
              >
                <ResponsiveRow sx={{ width: "100%" }}>
                  <ModalAcceptButton
                    onClick={props.showAddNewUserForm}
                    sx={{ width: { xs: "100%", lg: "100px" } }}
                  >
                    Yes
                  </ModalAcceptButton>
                  <ModalCancelButton
                    onClick={props.handleIndividualSearchClose}
                    sx={{ width: { xs: "100%", lg: "100px" } }}
                  >
                    No
                  </ModalCancelButton>
                </ResponsiveRow>
              </ResponsiveRow>
            </Alert>
          )}
        </ResponsiveRow>
      </ResponsiveRow>
    </ModalContainerModule>
  );
}
