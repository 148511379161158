import {
    Alert,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    TextField,
} from "@mui/material";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {ResponsiveRow} from "../../../../constants/layout.constants";
import ModalContainerModule from "../../../modules/modalContainer";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/en-gb";
import TabContext from "@mui/lab/TabContext/TabContext";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel/TabPanel";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell/TableCell";
import TableBody from "@mui/material/TableBody";
import {ModalAcceptButton} from "../../../../constants/component.constants";
import dayjs from "dayjs";
import {adjustForTimezone} from "../../../../util/util";

export default function CreateBudgeModal(props) {
    /**
     *
     * REQUIRED PROPS
     *
     * DetailsTabPanel
     *
     * handleSaveBudget
     * handleCloseBudgetForm
     * budgetForm
     * task
     *
     * onCheckboxChange
     * setApprovalThreshold
     * setDescription
     * setName
     * setSelectedBudget
     * setStartDate
     * setEndDate
     *
     * approvalRequired
     * approvalThreshold
     * description
     * endDate
     * name
     * selectedBudget
     * startDate
     *
     */

    return (
      <ModalContainerModule
        size="md"
        accept={props.handleSaveBudget}
        cancel={props.handleCloseBudgetForm}
        openModal={props.budgetForm}
        modalTitle="Create Budget"
        acceptButtonText={props.task === "Create" ? "Save" : "Update"}
      >
        <ResponsiveRow container sx={{ height: "auto", width: "100%" }}>
          <FormControl
            item
            sx={{
              marginBottom: "10px",
              width: { lg: "33.3%", xs: "100%" },
            }}
          >
            <InputLabel id>Name *</InputLabel>
            <OutlinedInput
              placeholder={"Name"}
              label="Name *"
              sx={{ width: { xs: "100%", lg: "90%" } }}
              id={"name"}
              required={true}
              onChange={(event) => {
                const value = event.target.value;
                props.setName(value);
                let obj = props.selectedBudget;
                obj.name = value;
                props.setSelectedBudget(obj);
              }}
              value={
                props.selectedBudget ? props.selectedBudget.name : props.name
              }
              
            />
          </FormControl>

          <FormControl
            item
            sx={{
              marginBottom: "10px",
              width: { lg: "33.3%", xs: "100%" },
            }}
          >
            <InputLabel id="facilityLabel">Facility</InputLabel>
            <Select
              sx={{ width: { xs: "100%", lg: "90%" } }}
              label="Facility"
              id={"facility"}
              value={
                props.selectedBudget
                  ? props.selectedBudget.facilityId
                  : props.selectedFacility
              }
              onChange={(event) => {
                let value = event.target.value;
                props.setSelectedFacility(value);
                let obj = props.selectedBudget;
                obj.facilityId = value;
                props.setSelectedBudget(obj);
              }}
            >
              {props.facilityOptions && props.facilityOptions.length > 0 ? (
                props.facilityOptions.map(function (item, i) {
                  return (
                    <MenuItem key={i} value={item.value}>
                      {item.label}
                    </MenuItem>
                  );
                }, this)
              ) : (
                <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
              )}
            </Select>
          </FormControl>

          <FormControl
            item
            sx={{
              marginBottom: "10px",
              width: { lg: "33.3%", xs: "100%" },
            }}
          >
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale="en-gb"
            >
              <DatePicker
                label="Start Date"
                sx={{ width: { xs: "100%", lg: "90%" } }}
                id={"startDate"}
                value={
                  props.selectedBudget
                    ? dayjs(props.selectedBudget.startDate)
                    : dayjs(props.startDate)
                }
                onChange={(d) => {
                  let date = dayjs(adjustForTimezone(new Date(d)));
                  props.setStartDate(date);
                  let obj = props.selectedBudget;
                  obj.startDate = date;
                  props.setSelectedBudget(obj);
                }}

              />
            </LocalizationProvider>
          </FormControl>

          <FormControl
            item
            sx={{
              marginBottom: "10px",
              width: { lg: "33.3%", xs: "100%" },
            }}
          >
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale="en-gb"
            >
              <DatePicker
                label="End Date"
                sx={{ width: { xs: "100%", lg: "90%" } }}
                id={"endDate"}
                value={
                  props.selectedBudget
                    ? dayjs(props.selectedBudget.endDate)
                    : dayjs(props.endDate)
                }
                onChange={(d) => {
                  let date = dayjs(adjustForTimezone(new Date(d)));
                  props.setEndDate(date);
                  let obj = props.selectedBudget;
                  obj.endDate = date;
                  props.setSelectedBudget(obj);
                }}

              />
            </LocalizationProvider>
          </FormControl>

          <FormControl
            item
            sx={{
              marginBottom: "10px",
              width: { lg: "33.3%", xs: "100%" },
            }}
          >
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    value={"0"}
                    checked={
                      props.selectedBudget
                        ? props.selectedBudget.approvalRequired
                        : props.approvalRequired
                    }
                    onChange={props.onCheckboxChange}
                  />
                }
                label="Approval Required?"
              />
            </FormGroup>
            {props.approvalRequired && (
              <Grid
                item
                sx={{
                  marginTop: "20px",
                  width: "90%",
                }}
              >
                <TextField
                  placeholder={"Approval Threshold"}
                  sx={{ width: "100%" }}
                  id={"approvalThreshold"}
                  label="Approval Threshold (%)"
                  required={true}
                  onChange={(event) => {
                    const value = event.target.value;
                    props.setApprovalThreshold(value);
                    let obj = props.selectedBudget;
                    obj.approvalThreshold = value;
                    props.setSelectedBudget(obj);
                  }}
                  value={
                    props.selectedBudget
                      ? props.selectedBudget.approvalThreshold
                      : props.approvalThreshold
                  }
                />
              </Grid>
            )}
          </FormControl>
          <FormControl
            item
            sx={{
              marginBottom: "10px",
              width: { lg: "33.3%", xs: "100%" },
            }}
          >
            <InputLabel>Description</InputLabel>
            <OutlinedInput
              id="description"
              sx={{ width: { xs: "100%", lg: "90%" } }}
              label={"Description"}
              multiline
              rows={4}
              value={
                props.selectedBudget
                  ? props.selectedBudget.description
                  : props.description
              }
              required={true}
              onChange={(event) => {
                const value = event.target.value;
                props.setDescription(value);
                let obj = props.selectedBudget;
                obj.description = value;
                props.setSelectedBudget(obj);
              }}
            />
          </FormControl>

          <br />
        </ResponsiveRow>
        <ResponsiveRow
          sx={{
            height: "auto",
            width: "100%",
            padding: 0,
            marginTop: "20px",
          }}
        >
          <TabContext value={props.value}>
            <TabList onChange={props.handleTabChange}>
              <Tab label="Income" value="income" />
              <Tab label="Expense" value="expense" />
            </TabList>
            <TabPanel
              value="income"
              sx={{ width: "100%", boxShadow: 0, margin: 0 }}
            >
              <TableContainer component={"div"}>
                <Table>
                  <TableHead>
                    <TableRow sx={{ width: "100%" }}>
                      <TableCell>Month/Income Account</TableCell>
                      <TableCell>1</TableCell>
                      <TableCell>2</TableCell>
                      <TableCell>3</TableCell>
                      <TableCell>4</TableCell>
                      <TableCell>5</TableCell>
                      <TableCell>6</TableCell>
                      <TableCell>7</TableCell>
                      <TableCell>8</TableCell>
                      <TableCell>9</TableCell>
                      <TableCell>10</TableCell>
                      <TableCell>11</TableCell>
                      <TableCell>12</TableCell>
                      <TableCell>Total</TableCell>
                      {props.view === "Editable" && (
                        <TableCell>Actions</TableCell>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>{props.incomeItems}</TableBody>
                </Table>
              </TableContainer>
              <Alert severity="info" sx={{ width: "100%", margin: "10px 0" }}>
                Income Total:{" "}
                {props.task === "Edit" || props.task === "Create"
                  ? props.incomeTotal
                  : props.selectedBudget && props.selectedBudget.incomeTotal}
              </Alert>
              <ResponsiveRow
                sx={{
                  width: "100%",
                  height: "auto",
                  padding: 0,
                  justifyContent: { xs: "center", lg: "flex-end" },
                  alignItems: "center",
                }}
              >
                {props.view === "Editable" && (
                  <ModalAcceptButton
                    variant="contained"
                    type="submit"
                    onClick={props.addIncomeItem}
                    sx={{
                      width: { xs: "100%", lg: "200px" },
                    }}
                  >
                    Add Row
                  </ModalAcceptButton>
                )}
              </ResponsiveRow>
            </TabPanel>
            <TabPanel
              value="expense"
              sx={{ width: "100%", boxShadow: 0, margin: 0 }}
            >
              <TableContainer component={"div"}>
                <Table>
                  <TableHead>
                    <TableRow sx={{ width: "100%" }}>
                      <TableCell>Month/Expense Account</TableCell>
                      <TableCell>1</TableCell>
                      <TableCell>2</TableCell>
                      <TableCell>3</TableCell>
                      <TableCell>4</TableCell>
                      <TableCell>5</TableCell>
                      <TableCell>6</TableCell>
                      <TableCell>7</TableCell>
                      <TableCell>8</TableCell>
                      <TableCell>9</TableCell>
                      <TableCell>10</TableCell>
                      <TableCell>11</TableCell>
                      <TableCell>12</TableCell>
                      <TableCell>Total</TableCell>
                      {props.view === "Editable" && (
                        <TableCell>Actions</TableCell>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>{props.expenseItems}</TableBody>
                </Table>
              </TableContainer>
              <Alert severity="info" sx={{ width: "100%", marginTop: "20px" }}>
                Expense Total:{" "}
                {props.task === "Edit" || props.task === "Create"
                  ? props.expenseTotal
                  : props.selectedBudget && props.selectedBudget.expenseTotal}
              </Alert>
              <ResponsiveRow
                sx={{
                  width: "100%",
                  height: "auto",
                  justifyContent: { xs: "center", lg: "flex-end" },
                  alignItems: "center",
                }}
              >
                {props.view === "Editable" && (
                  <ModalAcceptButton
                    type="submit"
                    onClick={props.addExpenseItem}
                    sx={{
                      width: { xs: "100%", lg: "200px" },
                    }}
                  >
                    Add Row
                  </ModalAcceptButton>
                )}
              </ResponsiveRow>
            </TabPanel>
          </TabContext>
        </ResponsiveRow>
      </ModalContainerModule>
    );
}
