import {useCallback, useEffect, useState} from "react"
import PropTypes from "prop-types"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"

import {formatDate} from "../../util/util"
import {TopBarContainedBrandButton} from "../../constants/component.constants"

export default function MessagesTab(props) {
    const [value, setValue] = useState(props.currentTab)
    const [tabMessages, setTabMessages] = useState([])

    const handleChange = useCallback(
        (event, newValue) => {
            props.changeTabs(newValue)
            setValue(newValue)
        },
        [props]
    )

    function createMessage() {
        props.composeMessage()
    }

    useEffect(() => {
        if (props.currentTab === 0) {
            setTabMessages(props.inbox)
        } else {
            setTabMessages(props.outbox)
        }

        return () => {
            setTabMessages([])
        }
    }, [handleChange, props.currentTab, props.inbox, props.outbox])

    function handleSelect(message) {
        props.selectMessage(message)
    }

    function TabPanel(props) {
        const {children, value, index, ...other} = props

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box
                        sx={{
                            width: "100%",
                            textAlign: "start",
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <Typography
                            sx={{
                                marginTop: "20px",
                                padding: "0px 20px",
                                fontSize: "1.2rem",
                                fontWeight: 800,
                                color: "#037171",
                            }}
                        >
                            {tabMessages === undefined ? 0 : tabMessages.length} Messages
                            {/* inbox === index 0 */}
                            {/*{value ? (outbox.length === 0 ? 0 : tabMessages.length) : (inbox.length === 0 ? 0 : tabMessages.length)} Messages*/}
                        </Typography>
                        <hr/>

                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                width: "100%",
                                height: "50vh",
                                overflowY: "auto",
                                padding: "10px 0",
                                marginBottom: "20px",
                            }}
                        >
                            {tabMessages &&
                                tabMessages.map((msg) => {
                                    return (
                                        <Box
                                            key={msg.id}
                                            sx={{
                                                width: "100%",
                                                padding: "10px 20px",
                                                "&:hover": {backgroundColor: "#efefef"},
                                            }}
                                            onClick={() => handleSelect(msg)}
                                        >
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "center",
                                                    flexWrap: "nowrap",
                                                    justifyContent: "space-between",
                                                    width: "100%",
                                                }}
                                            >
                                                <Typography sx={{fontSize: "1rem", fontWeight: 700}}>
                                                    {msg.senderName}
                                                </Typography>
                                                <Typography sx={{fontSize: ".9rem", fontWeight: 500}}>
                                                    {formatDate(msg.sentAt)}
                                                </Typography>
                                            </Box>
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "center",
                                                    flexWrap: "nowrap",
                                                    justifyContent: "flexStart",
                                                    margin: "5px 10px",
                                                }}
                                            >
                                                <Typography sx={{fontSize: "1.00rem"}}>
                                                    {msg.subject}{" "}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    )
                                })}
                        </Box>
                    </Box>
                )}
            </div>
        )
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    }

    function a11yProps(tabName) {
        return {
            id: `simple-tab-${tabName}`,
            "aria-controls": `${tabName}-tabpanel`,
        }
    }

    return (
        <Box sx={{width: "100%", margin: "0 auto"}}>
            <Box sx={{display: "flex", flexDirection: "column"}}>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        width: "100%",
                        borderBottom: 1,
                        borderColor: "divider",
                    }}
                >
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="Message Tabs"
                        sx={{padding: "10px"}}
                    >
                        <Tab label="Inbox" {...a11yProps("inbox")} />
                        <Tab label="Sent" {...a11yProps("outbox")} />
                    </Tabs>
                    <TopBarContainedBrandButton
                        sx={{
                            maxWidth: {xs: "100%", lg: "200px"},
                            textAlign: "center",
                            textWrap: "nowrap",
                        }}
                        onClick={createMessage}
                    >
                        New Message
                    </TopBarContainedBrandButton>
                </Box>
                <TabPanel
                    sx={{
                        width: "100%",
                        display: "flex",
                        fleDirection: "row",
                        justifyContent: "center",
                    }}
                    value={value}
                    index={0}
                >
                    Inbox
                </TabPanel>
                <TabPanel
                    sx={{
                        width: "100%",
                        display: "flex",
                        fleDirection: "row",
                        justifyContent: "center",
                    }}
                    value={value}
                    index={1}
                >
                    Outbox
                </TabPanel>
            </Box>
        </Box>
    )
}
