/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from "react";
import { GridActionsCellItem } from "@mui/x-data-grid";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from "@mui/material";
import { Visibility } from "@mui/icons-material";
import {
  adjustForTimezone,
  formatDate,
  formatDateTime,
  isEmptyArray,
  isEmptyString,
  isNullUndefined,
  isValidDate,
  sortAlphaNum,
} from "../../util/util";
import "dayjs/locale/en-gb";
import Permit from "../../util/permit";
import {
  TopBarContainedBrandButton,
  TopBarContainedCancelButton,
  TopBarContainer,
} from "../../constants/component.constants";
import SignUpAnalysisView from "./signUpAnalysisView";
import { renderCellExpand } from "../../util/renderCellExpand";
import { ResponsiveRow } from "../../constants/layout.constants";
import MuiDataGrid from "../../components/modules/MuiDataGrid";
import {
  exportSignUps,
  getSignUps,
  getTotalSignUps,
} from "../../services/signUp.service";
import { getSignUpUserOptions } from "../../services/list.service";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

export default function SignUpAnalysis() {
  const [signUps, setSignUps] = useState([]);
  const [loading, setLoading] = useState(true);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 0,
  });
  const [totalSignUps, setTotalSignUps] = useState(0);
  const [selectedSignUp, setSelectedSignUp] = useState(null);
  const [task, setTask] = useState("");
  const [personIds, setPersonIds] = useState([]);
  const [personIdOptions, setPersonIdOptions] = useState([]);
  const [dateCreated, setDateCreated] = useState("");
  let disableSearch = isEmptyString(dateCreated) && isEmptyArray(personIds);

  useEffect(() => {
    setLoading(true);
    getSignUpUserOptions().then((response) => {
      setPersonIdOptions(response);
    });
    let searchObject = {
      personIds: personIds,
      dateCreated: "",
      pageNo: paginationModel.page,
      rowsPerPage: paginationModel.pageSize,
    };
    if (!isNullUndefined(dateCreated) && isValidDate(dateCreated)) {
      searchObject.dateCreated = adjustForTimezone(new Date(dateCreated));
    }

    getTotalSignUps(searchObject).then((response) => {
      setTotalSignUps(response.totalSignUps);
    });

    getSignUps(searchObject)
      .then((response) => {
        setSignUps(response);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [personIds, dateCreated, paginationModel.page, paginationModel.pageSize]);

  function handlePersonIdsChange(event) {
    setPersonIds(event.target.value);
  }

  function handleClearSearch() {
    setDateCreated("");
    setPersonIds([]);
  }

  function exportSignUpData() {
    exportSignUps();
  }

  function handleDateCreatedChange(dateString) {
    let date = new Date(dateString);
    setDateCreated(date);
  }

  function handleViewClick(selectedSignUp) {
    setSelectedSignUp(selectedSignUp);
    setTask("View");
  }

  const columns = useMemo(() => [
    {
      headerName: "Name",
      field: "personName",
      minWidth: 213,
      flex: 2.13,
      renderCell: renderCellExpand,
      sortComparator: sortAlphaNum,
    },
    {
      headerName: "Email",
      field: "personEmail",
      minWidth: 183,
      flex: 1.83,
      renderCell: renderCellExpand,
      sortComparator: sortAlphaNum,
    },
    {
      headerName: "Mobile Phone",
      field: "personPhone",
      minWidth: 133,
      flex: 1.33,
      renderCell: renderCellExpand,
      sortComparator: sortAlphaNum,
    },
    {
      headerName: "Current Step",
      field: "signUpJson",
      minWidth: 90,
      flex: 0.9,
      valueFormatter: (row) => {
        switch (row.value.currentStep) {
          case 0:
            return "Personal Details";
          case 1:
            return "Property Details";
          case 2:
            return "Units Details";
          case 3:
            return "Utilities Details";
          case 4:
            return "Resident Details";
          case 5:
            return "Review Details";
          case 6:
            return "Completed";
          default:
            return "UNKNOWN";
        }
      },
    },
    {
      headerName: "Date Created",
      field: "dateCreated",
      minWidth: 135,
      fleX: 1.35,
      valueFormatter: (row) => {
        return formatDate(row.value);
      },
    },
    {
      headerName: "Last Modified",
      field: "lastModified",
      minWidth: 110,
      flex: 1.1,
      valueFormatter: (row) => {
        return formatDateTime(row.value);
      },
    },
    {
      headerName: "Actions",
      field: "actions",
      width: 100,
      type: "actions",
      getActions: (params) => {
        let arr = [];
        arr.push(
          <GridActionsCellItem
            icon={<Visibility />}
            onClick={() => handleViewClick(params.row)}
            label="View"
            showInMenu
          />
        );
        return arr;
      },
    },
  ]);

  return (
    <Permit
      roles="SYSAD,CS"
      noPermittedServiceMessage={{
        title: "Access Denied",
        body: (
          <>
            Your current role and/or facility is not permitted to view this
            page. <br /> Please contact your system admin if you feel this is an
            error.
          </>
        ),
      }}
    >
      <ResponsiveRow
        sx={{
          flexDirection: "column",
          flexWrap: "nowrap",

          width: "100%",
          height: "auto",

          justifyContent: "flex-start",
          alignItems: "flex-start",

          padding: 0,
        }}
      >
        {task === "" && (
          <TopBarContainer
            sx={{
              width: { xs: "100%", lg: "80%" },
            }}
            container
          >
            <ResponsiveRow
              item
              sx={{
                flexDirection: { xs: "column", lg: "row" },
                flexWrap: "nowrap",
                width: { xs: "100%", lg: "40%" },
                textAlign: { xs: "center", lg: "start" },
              }}
            >
              <FormControl
                sx={{
                  width: { xs: "100%", lg: "100%" },
                  marginBottom: "10px",
                }}
              >
                <InputLabel>Search Person(s)</InputLabel>
                <Select
                  label="Search Person(s)"
                  multiple
                  sx={{ width: { xs: "100%", lg: "90%" } }}
                  id={"persons"}
                  value={personIds}
                  onChange={(event) => handlePersonIdsChange(event)}
                >
                  {personIdOptions && personIdOptions.length > 0 ? (
                    personIdOptions.map(function (person, i) {
                      return (
                        <MenuItem key={i} value={person.value}>
                          {person.label}
                        </MenuItem>
                      );
                    }, this)
                  ) : (
                    <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
                  )}
                </Select>
              </FormControl>
              <FormControl
                sx={{
                  width: { xs: "100%", lg: "100%" },
                  marginBottom: "10px",
                }}
              >
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale="en-gb"
                >
                  <DatePicker
                    label="Search Date Created"
                    sx={{ width: { xs: "100%", lg: "90%" } }}
                    value={
                      isEmptyString(dateCreated) ? null : dayjs(dateCreated)
                    }
                    onChange={(newDate) => handleDateCreatedChange(newDate)}
                  />
                </LocalizationProvider>
              </FormControl>
            </ResponsiveRow>

            <ResponsiveRow
              sx={{
                width: { xs: "100%", lg: "60%" },
                padding: 0,
                justifyContent: { xs: "center", lg: "flex-end" },
                flexWrap: "nowrap",
              }}
              item
            >
              {!disableSearch && (
                <Permit roles="SYSAD,CS">
                  <TopBarContainedCancelButton
                    onClick={handleClearSearch}
                    sx={{ width: { xs: "100%", lg: "150px" } }}
                  >
                    Clear Search{" "}
                  </TopBarContainedCancelButton>
                </Permit>
              )}
              <Permit roles="SYSAD,CS">
                <TopBarContainedBrandButton
                  onClick={exportSignUpData}
                  sx={{ width: { xs: "100%", lg: "150px" } }}
                >
                  Export SignUps{" "}
                </TopBarContainedBrandButton>
              </Permit>
            </ResponsiveRow>
          </TopBarContainer>
        )}
        <br />

        {task === "View" && (
          <SignUpAnalysisView
            selectedSignUp={selectedSignUp}
            setTask={setTask}
          />
        )}

        {task === "" && (
          <MuiDataGrid
            dataGridColumns={columns}
            dataGridRows={signUps}
            loading={loading}
            height="60vh"
            totalRows={totalSignUps}
            noSelection={true}
            getRowClassName={(params) => {
              return params.row.signUpJson.currentStep === 6 ? "suspended" : "";
            }}
            handlePageLoad={setPaginationModel}
            serverPagination={true}
          />
        )}
      </ResponsiveRow>
    </Permit>
  );
}
