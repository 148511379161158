/* eslint-disable react-hooks/exhaustive-deps */
import { ResponsiveRow } from "../constants/layout.constants";
import { useEffect, useState } from "react";
import {
  ModalAcceptButton,
  ModalCancelButton,
  TopBarContainedBrandButton,
  TopBarContainedCancelButton,
  TopBarContainer,
} from "../constants/component.constants";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";

import { formatDate, isEmptyString, isNullUndefined } from "../util/util";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Delete, Print } from "@mui/icons-material";
import { GridActionsCellItem } from "@mui/x-data-grid";
import {
  exportCreditNotes,
  getCreditNotes,
  getTotalCreditNotes,
} from "../services/creditNotes.service";
import { renderCellExpand } from "../util/renderCellExpand";
import { useSelector } from "react-redux";
import { printCredit } from "../services/print.service";
import { issueResponseMessage, issueWarnMessage } from "../actions/message";
import ExportDocumentsModal from "../components/modals/exportDocuments";
import DebitNote from "../components/modals/debitNote";
import MuiDataGrid from "../components/modules/MuiDataGrid";
import dayjs from "dayjs";

export default function CreditNotes() {
  const user = useSelector((state) => state.auth.user);
  // const [filterCreditNotes, setFilterCreditNotes] = useState(true)
  const [referenceNumber, setReferenceNumber] = useState("");
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [creditTo, setCreditTo] = useState("");
  const [creditDateFrom, setCreditDateFrom] = useState("");
  const [creditDateTo, setCreditDateTo] = useState("");
  const [dateApprovedFrom, setDateApprovedFrom] = useState("");
  const [dateApprovedTo, setDateApprovedTo] = useState("");
  const [task, setTask] = useState("");
  const [selectionModel, setSelectionModel] = useState([]);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 50,
  });
  const [loading, setLoading] = useState(true);
  const [creditNotesList, setCreditNotesList] = useState([]);
  const [total, setTotal] = useState(0);
  const [downloadCreditNotesModal, setDownloadCreditNotesModal] =
    useState(false);
  const [titleText, setTitleText] = useState("");
  const [severityText, setSeverityText] = useState("");
  const [bodyText, setBodyText] = useState("");
  const [chargeModal, setChargeModal] = useState(false);
  const [voiditem, setVoiditem] = useState(null);
  const [leaseId, setLeaseId] = useState("");
  const [titleId, setTitleId] = useState("");
  const [meterBillingId, setMeterBillingId] = useState("");

  useEffect(() => {
    setLoading(true);
    getCreditNotes(
      referenceNumber,
      creditDateFrom,
      creditDateTo,
      dateApprovedFrom,
      dateApprovedTo,
      selectedStatus,
      creditTo,
      paginationModel.page
    )
      .then((data) => {
        setCreditNotesList(data);
      })
      .finally(() => {
        setLoading(false);
      });
    getTotalCreditNotes(
      referenceNumber,
      creditDateFrom,
      creditDateTo,
      dateApprovedFrom,
      dateApprovedTo,
      selectedStatus,
      creditTo,
      paginationModel.page
    ).then((data) => {
      setTotal(data.totalCreditNotes);
    });
  }, [
    referenceNumber,
    creditDateFrom,
    creditDateTo,
    dateApprovedFrom,
    dateApprovedTo,
    selectedStatus,
    creditTo,
    paginationModel.page,
  ]);

  useEffect(() => {
    setLoading(true);
    getCreditNotes(
      referenceNumber,
      creditDateFrom,
      creditDateTo,
      dateApprovedFrom,
      dateApprovedTo,
      selectedStatus,
      creditTo,
      paginationModel.page
    )
      .then((data) => {
        setCreditNotesList(data);
      })
      .finally(() => {
        setLoading(false);
      });
    getTotalCreditNotes(
      referenceNumber,
      creditDateFrom,
      creditDateTo,
      dateApprovedFrom,
      dateApprovedTo,
      selectedStatus,
      creditTo,
      paginationModel.page
    ).then((data) => {
      setTotal(data.totalCreditNotes);
    });
  }, [user.proxyFacility]);

  const selectOptions = [
    { label: "Approved", value: "APP" },
    { label: "Rejected", value: "REJ" },
    { label: "Voided", value: "VOID" },
  ];

  function handleSearchClicked() {
    setTask("Filter");
  }

  function handleClearFilterClicked() {
    setTask("");
    setReferenceNumber("");
    setSelectedStatus("");
    setCreditTo("");
    setCreditDateFrom("");
    setCreditDateTo("");
    setDateApprovedFrom("");
    setDateApprovedTo("");
    setSelectionModel([]);
  }

  function handleExportCreditNotes() {
    setDownloadCreditNotesModal(true);
    let title =
      selectionModel.length > 1
        ? "Download " + selectionModel.length + " Credit Notes"
        : "Download Credit Note";
    let body =
      "Note: Processing the selected credit notes may take some time." +
      " Therefore, the exported data will be emailed to you when it is finished being packaged." +
      " Please ensure the email set for your account is valid, working and that you have access to it in order to receive the export data.";
    setTitleText(title);
    setSeverityText("info");
    setBodyText(body);
  }

  function handleCloseDownloadCreditNotesModal() {
    setDownloadCreditNotesModal(false);
    setSelectionModel([]);
    setTitleText("");
    setTask("");
    setSeverityText("");
    setBodyText("");
    setTask("Filter");
  }

  function exportSelectedCreditNotes() {
    if (isNullUndefined(selectionModel) || selectionModel.size < 1) {
      issueWarnMessage(
        "Could not determine the download items. Please refresh the page and try again."
      );
      return;
    }
    let sendData = {
      creditNoteIds: selectionModel,
    };

    exportCreditNotes(sendData).then((response) => {
      issueResponseMessage(response);
      handleCloseDownloadCreditNotesModal();
    });
  }

  function handleVoidClicked(item) {
    let tmp = item;
    tmp.type = "Credit";
    setVoiditem(tmp);
    setChargeModal(true);
    if (!isEmptyString(item.leaseId)) setLeaseId(item.leaseId);
    if (!isEmptyString(item.titleId)) setTitleId(item.titleId);
    if (!isEmptyString(item.meterBillingId))
      setMeterBillingId(item.meterBillingId);
  }

  const handleChargeClose = () => {
    setVoiditem(null);
    setChargeModal(false);
  };

  const columns = [
    {
      headerName: "Reference No.",
      field: "referenceNumber",
      minWidth: 145,
      flex: 1.45,
    },
    { headerName: "Amount", field: "amount", minWidth: 130, flex: 1.3 },
    {
      headerName: "Date Created",
      field: "dateCreated",
      minWidth: 160,
      flex: 1.6,
      valueFormatter: (params) => {
        return formatDate(params.value);
      },
    },
    {
      headerName: "Date Approved",
      field: "dateApproved",
      minWidth: 160,
      flex: 1.6,
      valueFormatter: (params) => {
        return formatDate(params.value);
      },
    },
    {
      headerName: "Credited To",
      field: "tenant",
      minWidth: 200,
      flex: 2,
      valueFormatter: (params) => {
        return params.value.name;
      },
    },
    {
      headerName: "Description",
      field: "memo",
      minWidth: 350,
      flex: 3.5,
      renderCell: renderCellExpand,
    },
    {
      headerName: "Status",
      field: "status",
      minWidth: 150,
      flex: 1.5,
      valueFormatter: (params) => {
        return params.value.name;
      },
    },
    {
      headerName: "Actions",
      field: "actions",
      width: 100,
      type: "actions",
      getActions: (params) => {
        let arr = [];
        arr.push(
          <GridActionsCellItem
            icon={<Print />}
            onClick={() => {
              printCredit(params.id).then((response) => {
                issueResponseMessage(response);
              });
            }}
            label="Print Credit Note"
            showInMenu
          />
        );
        params.row.status.code !== "VOID" &&
          arr.push(
            <GridActionsCellItem
              icon={<Delete />}
              onClick={() => handleVoidClicked(params.row)}
              label="Void Credit Note"
              showInMenu
            />
          );
        return arr;
      },
    },
  ];

  return (
    <ResponsiveRow
      sx={{
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "auto",
      }}
    >
      <TopBarContainer
        sx={{
          margin: "20px auto 20px auto",
          padding: 0,
          boxShadow: "4px 4px 12px #aaa",
          borderRadius: "10px",
          backgroundColor: "white",
          width: { xs: "100%", lg: "90%" },
          minHeight: "80px",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: { xs: "column", lg: "row" },
          flexWrap: "nowrap",
        }}
      >
        <Typography
          variant="h5"
          sx={{
            width: { xs: "100%", lg: "25%" },
            textAlign: { xs: "center", lg: "start" },
            margin: { xs: "10px auto", lg: "auto 20px" },
          }}
        >
          Credit Notes
        </Typography>
        <ResponsiveRow
          sx={{
            width: { xs: "100%", lg: "75%" },
            height: "auto",

            justifyContent: { xs: "center", lg: "flex-end" },
            padding: { xs: task === "Filter" ? "10px" : 0, lg: "10px" },
          }}
        >
          {task === "Filter" && (
            <>
              <TopBarContainedBrandButton
                disabled={selectionModel.length < 1}
                onClick={handleExportCreditNotes}
                sx={{
                  minWidth: "200px",
                  width: { xs: "100%", lg: "auto" },
                  height: "50px",
                }}
              >
                {selectionModel.length > 1
                  ? "Download " + selectionModel.length + " Credit Notes"
                  : "Download Credit Note"}
              </TopBarContainedBrandButton>
              <TopBarContainedCancelButton
                onClick={handleClearFilterClicked}
                sx={{
                  width: { xs: "100%", lg: "200px" },
                  height: "50px",
                }}
              >
                Clear Search
              </TopBarContainedCancelButton>
            </>
          )}
        </ResponsiveRow>
      </TopBarContainer>

      {task !== "Filter" && (
        <ResponsiveRow
          sx={{
            width: { xs: "100%", lg: "80%" },
            height: "auto",
            margin: "auto",
            padding: { xs: "10px", lg: "30px 20px" },
            backgroundColor: "white",
            boxShadow: "4px 4px 12px #aaa",
            borderRadius: "10px",
          }}
          container
        >
          <Typography
            variant="h6"
            color="#037171"
            sx={{
              width: "100%",
              marginBottom: "20px",
              textAlign: "start",
              padding: "0 10px",
            }}
          >
            Credit Notes Search Form
          </Typography>
          <FormControl
            item
            sx={{
              width: { xs: "100%", md: "33.3%" },
              marginBottom: "10px",
            }}
          >
            <TextField
              label="Reference Number"
              placeholder={"Reference Number"}
              sx={{ width: "90%" }}
              id={"referenceNumber"}
              onChange={(event) => {
                const value = event.target.value;
                setReferenceNumber(value);
              }}
              value={!isEmptyString(referenceNumber) ? referenceNumber : ""}
            />
          </FormControl>

          <FormControl
            item
            sx={{
              width: { xs: "100%", md: "33.3%" },
              marginBottom: "10px",
            }}
          >
            <InputLabel>Status</InputLabel>
            <Select
              label="Status"
              sx={{ width: "90%" }}
              id={"status"}
              value={selectedStatus}
              onChange={(event) => {
                let value = event.target.value;
                setSelectedStatus(value);
              }}
            >
              {selectOptions && selectOptions.length > 0 ? (
                selectOptions.map(function (status, i) {
                  return (
                    <MenuItem key={status.value} value={status.value}>
                      {status.label}
                    </MenuItem>
                  );
                }, this)
              ) : (
                <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
              )}
            </Select>
          </FormControl>

          <FormControl
            item
            sx={{
              width: { xs: "100%", md: "33.3%" },
              marginBottom: "10px",
            }}
          >
            <TextField
              label="Credit To"
              placeholder={"Credit To"}
              sx={{ width: "90%" }}
              id={"creditTo"}
              onChange={(event) => {
                const value = event.target.value;
                setCreditTo(value);
              }}
              value={!isEmptyString(creditTo) ? creditTo : ""}
            />
          </FormControl>

          <FormControl
            item
            sx={{
              width: { xs: "100%", md: "33.3%" },
              marginBottom: "10px",
            }}
          >
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale="en-gb"
            >
              <DatePicker
                label="Date Created From"
                sx={{ width: "90%" }}
                id={"dateCreatedFrom"}
                value={
                  isEmptyString(creditDateFrom) ? null : dayjs(creditDateFrom)
                }
                isrequired={false}
                onChange={(newDate) => {
                  // console.log("date Value ", newDate);
                  let date = new Date(newDate);
                  setCreditDateFrom(date);
                }}
              />
            </LocalizationProvider>
          </FormControl>

          <FormControl
            item
            sx={{
              width: { xs: "100%", md: "33.3%" },
              marginBottom: "10px",
            }}
          >
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale="en-gb"
            >
              <DatePicker
                label="Date Created To"
                sx={{ width: "90%" }}
                id={"dateCreatedTo"}
                value={isEmptyString(creditDateTo) ? null : dayjs(creditDateTo)}
                isrequired={false}
                onChange={(newDate) => {
                  // console.log("date Value ", event);
                  let date = new Date(newDate);
                  setCreditDateTo(date);
                }}
              />
            </LocalizationProvider>
          </FormControl>

          <FormControl
            item
            sx={{
              width: { xs: "100%", md: "33.3%" },
              marginBottom: "10px",
            }}
          >
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale="en-gb"
            >
              <DatePicker
                label="Date Approved From"
                sx={{ width: "90%" }}
                id={"dateApprovedFrom"}
                value={
                  isEmptyString(dateApprovedFrom)
                    ? null
                    : dayjs(dateApprovedFrom)
                }
                isrequired={false}
                onChange={(newDate) => {
                  // console.log("date Value ", newDate);
                  let date = new Date(newDate);
                  setDateApprovedFrom(date);
                }}
              />
            </LocalizationProvider>
          </FormControl>

          <FormControl
            item
            sx={{
              width: { xs: "100%", md: "33.3%" },
              marginBottom: "10px",
            }}
          >
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale="en-gb"
            >
              <DatePicker
                label="Date Approved To"
                sx={{ width: "90%" }}
                id={"dateApprovedTo"}
                value={
                  isEmptyString(dateApprovedTo) ? null : dayjs(dateApprovedTo)
                }
                isrequired={false}
                onChange={(newDate) => {
                  // console.log("date Value ", newDate);
                  let date = new Date(newDate);
                  setDateApprovedTo(date);
                }}
              />
            </LocalizationProvider>
          </FormControl>

          <ResponsiveRow
            item
            sx={{
              justifyContent: { xs: "center", md: "flex-end" },
              width: "100%",
              height: "auto",
            }}
          >
            <ModalAcceptButton
              onClick={handleSearchClicked}
              sx={{ width: { xs: "100%", lg: "200px" } }}
            >
              Search
            </ModalAcceptButton>
            <ModalCancelButton
              onClick={handleClearFilterClicked}
              sx={{
                width: { xs: "100%", lg: "200px" },
              }}
            >
              Clear
            </ModalCancelButton>
          </ResponsiveRow>
        </ResponsiveRow>
      )}

      {task === "Filter" && (
        <MuiDataGrid
          dataGridColumns={columns}
          dataGridRows={creditNotesList}
          serverPagination={true}
          handlePageLoad={setPaginationModel}
          loading={loading}
          height="60vh"
          totalRows={total}
          currentSelectionModel={selectionModel}
          handleSelectedRows={setSelectionModel}
        />
      )}

      {/*Download/Export Document via Email... Shared Modal*/}
      {downloadCreditNotesModal && (
        <ExportDocumentsModal
          showExportDocumentsModal={downloadCreditNotesModal}
          handleExportDocumentsClose={handleCloseDownloadCreditNotesModal}
          title={titleText}
          severity={severityText}
          body={bodyText}
          handleExportDocuments={exportSelectedCreditNotes}
          handleCancel={handleCloseDownloadCreditNotesModal}
        />
      )}

      {/*Shared debit note component*/}
      {chargeModal && (
        <DebitNote
          open={chargeModal}
          onClose={handleChargeClose}
          voiditem={voiditem}
          leaseid={leaseId}
          titleId={titleId}
          meterBillingId={meterBillingId}
        />
      )}
    </ResponsiveRow>
  );
}
