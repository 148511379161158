import {
    Alert,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from "@mui/material"
import {InnerModal, ModalAcceptButton, ModalCancelButton,} from "../../../../constants/component.constants"
import ModalContainerModule from "../../../modules/modalContainer"

export default function TerminatedContactModal(props) {
    /**
     *
     * REQUIRED PROPS
     *
     * contactModal
     * handleContactClear
     * handleContactClose
     * handleIdTypeChange
     * handlePersonOrgSelect
     * onTextChange
     *
     * contactTableRows
     * foundTenant
     * idNoSearch
     * idTypes
     * idTypeSearch
     * searchPersonOrg
     * selectedLease
     *
     */

    return (
      <ModalContainerModule
        size="sm"
        openModal={props.contactModal}
        cancel={props.handleContactClose}
        modalTitle={`Lease (${
          props.selectedLease && props.selectedLease.billingCode
        }) Contacts`}
      >
        <InnerModal container>
          <Grid item xs={6}>
            <Paper
              variant="outlined"
              sx={{
                height: "auto",
                margin: "auto",
                padding: "10px",
                minHeight: "300px",
              }}
            >
              <Typography
                sx={{ marginBottom: "5px" }}
                id="modal-modal-title"
                variant="h5"
                component="h4"
              >
                Contacts
              </Typography>
              <TableContainer style={{ maxHeight: 300 }}>
                <Table
                  sx={{ minWidth: 20 }}
                  aria-label="simple table"
                  stickyHeader
                >
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ width: "30%" }}>Name</TableCell>
                      <TableCell sx={{ width: "30%" }}>Phone Number</TableCell>
                      <TableCell sx={{ width: "20%" }}>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>{props.contactTableRows}</TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper
              variant="outlined"
              sx={{
                height: "auto",
                margin: "10px",
                padding: "10px",
                minHeight: "300px",
              }}
            >
              <Typography
                sx={{ marginBottom: "5px" }}
                id="modal-modal-title"
                variant="h5"
                component="h4"
              >
                Add New Contacts
              </Typography>
              <Alert severity="info">
                Use this form to search for an existing individual to add as a
                contact.
              </Alert>
              <Paper
                variant="outlined"
                sx={{ height: "auto", margin: "auto", padding: "10px" }}
              >
                <InnerModal container>
                  <Grid item xs={6}>
                    <InputLabel>ID Number</InputLabel>
                    <TextField
                      sx={{ width: "90%" }}
                      id={"idNoSearch"}
                      value={props.idNoSearch}
                      onChange={(searchText) => props.onTextChange(searchText)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <InputLabel>ID Type</InputLabel>
                    <Select
                      sx={{ width: "90%" }}
                      id={"idTypeSearch"}
                      value={props.idTypeSearch}
                      onChange={(event) => props.handleIdTypeChange(event)}
                    >
                      {props.idTypes && props.idTypes.length > 0 ? (
                        props.idTypes.map(function (idType, i) {
                          return (
                            <MenuItem key={i} value={idType.value}>
                              {idType.label}
                            </MenuItem>
                          );
                        }, this)
                      ) : (
                        <MenuItem sx={{ width: "100%" }}>
                          No Results Found
                        </MenuItem>
                      )}
                    </Select>
                  </Grid>
                  <Grid item xs={12}></Grid>
                  <br />
                  <Grid item xs={6}></Grid>
                  <Grid item xs={6}>
                    <ModalAcceptButton
                      variant="contained"
                      type="submit"
                      onClick={props.searchPersonOrg}
                      sx={{ width: { xs: "100%", lg: "200px" } }}
                    >
                      {" "}
                      Search{" "}
                    </ModalAcceptButton>
                    <ModalCancelButton
                      variant="contained"
                      type="submit"
                      onClick={props.handleContactClear}
                      sx={{ width: { xs: "100%", lg: "200px" } }}
                      color="error"
                    >
                      {" "}
                      Clear{" "}
                    </ModalCancelButton>
                  </Grid>
                </InnerModal>
                {props.foundTenant && props.foundTenant.length > 0 && (
                  <TableContainer>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Name</TableCell>
                          <TableCell>Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {props.foundTenant &&
                          props.foundTenant.length > 0 &&
                          props.foundTenant.map(function (tenant, i) {
                            return (
                              <TableRow key={i}>
                                <TableCell>{tenant.name}</TableCell>
                                <TableCell>
                                  <ModalAcceptButton
                                    variant="contained"
                                    type="submit"
                                    onClick={() =>
                                      props.handlePersonOrgSelect(tenant)
                                    }
                                    sx={{ width: { xs: "100%", lg: "200px" } }}
                                  >
                                    {" "}
                                    Add{" "}
                                  </ModalAcceptButton>
                                </TableCell>
                              </TableRow>
                            );
                          }, this)}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </Paper>
            </Paper>
          </Grid>
        </InnerModal>
      </ModalContainerModule>
    );
}
