import { LocalPrintshop } from "@mui/icons-material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  DetailsColumn,
  DetailsContainer,
  DetailsLabel,
  DetailsRow,
  DetailsText,
  ModalAcceptButton,
} from "../../../../constants/component.constants";
import { ResponsiveRow } from "../../../../constants/layout.constants";
import {
  formatCurrency,
  formatDate,
  isNullUndefined,
} from "../../../../util/util";
import ModalContainerModule from "../../../modules/modalContainer";

export default function ViewCapitalFundModal(props) {
  /**
   *
   * REQUIRED PROPS
   *
   * handlePrintCapitalFund
   *
   * setView
   * setTask
   *
   * capitalFundsApprovals
   * selectedCapitalFund
   * view
   *
   */
  return (
    <ModalContainerModule
      size="md"
      cancel={() => props.setView(false)}
      openModal={props.view}
      modalTitle="View Capital Fund"
    >
      <ResponsiveRow
        sx={{
          height: "auto",
          width: "100%",
        }}
      >
        <Typography
          id="viewCapitalFund"
          width={"70%"}
          variant="h5"
          component="h6"
        >
          Capital Fund Details
        </Typography>
        <ResponsiveRow
            sx={{
              width: "30%",
              height: "auto",
              justifyContent: "flex-end",
            }}
        >
          <ModalAcceptButton
              sx={{
                width: { xs: "100%", lg: "200px" },
                height: "auto",
              }}
              onClick={props.handlePrintCapitalFund}
          >
            <LocalPrintshop sx={{ margin: "auto 5px" }} /> Print Capital Fund
          </ModalAcceptButton>
        </ResponsiveRow>

        <DetailsContainer sx={{ width: "100%", height: "auto", marginTop: 0 }}>
          <DetailsColumn
            sx={{
              width: { xs: "100%", lg: "33.3%" },
              height: "auto",
              margin: "0 auto 20px auto",
            }}
          >
            <DetailsRow>
              <DetailsLabel> Property:</DetailsLabel>
              <DetailsText> {props.selectedCapitalFund.facilityName}</DetailsText>
            </DetailsRow>
            <DetailsRow>
              <DetailsLabel> Name:</DetailsLabel>
              <DetailsText> {props.selectedCapitalFund.name}</DetailsText>
            </DetailsRow>
            <DetailsRow>
              <DetailsLabel> Date Created:</DetailsLabel>
              <DetailsText> {formatDate(props.selectedCapitalFund.dateCreated)}</DetailsText>
            </DetailsRow>
            <DetailsRow>
              <DetailsLabel> Created By:</DetailsLabel>
              <DetailsText> {props.selectedCapitalFund.createdBy.name}</DetailsText>
            </DetailsRow>
          </DetailsColumn>
          <DetailsColumn
            sx={{
              width: { xs: "100%", lg: "33.3%" },
              height: "auto",
              margin: "0 auto",
            }}
          >
            <DetailsRow>
              <DetailsLabel> Amount:</DetailsLabel>
              <DetailsText>
                {formatCurrency(props.selectedCapitalFund.amount.toFixed(2))}
              </DetailsText>
            </DetailsRow>
            <DetailsRow>
              <DetailsLabel> Status:</DetailsLabel>
              <DetailsText>{props.selectedCapitalFund.status.name}</DetailsText>
            </DetailsRow>
            <DetailsRow>
              <DetailsLabel> Due Date:</DetailsLabel>
              <DetailsText> {formatDate(props.selectedCapitalFund.dueDate)}</DetailsText>
            </DetailsRow>
            <DetailsRow>
              <DetailsLabel>Disbursed:</DetailsLabel>
              <DetailsText> {props.selectedCapitalFund.disbursed ? "YES" : "NO"}</DetailsText>
            </DetailsRow>
          </DetailsColumn>
          <DetailsColumn
            sx={{
              width: { xs: "100%", lg: "33.3%" },
              height: "auto",
              margin: "0 auto",
            }}
          >
            <DetailsRow>
              <DetailsLabel>Approval Threshold:</DetailsLabel>
              <DetailsText>
                {" "}
                {props.selectedCapitalFund.approvalRequired
                  ? props.selectedCapitalFund.approvalThresholdPercentage + "%"
                  : "N/A"}
              </DetailsText>
            </DetailsRow>
            <DetailsRow>
              <DetailsLabel> Approval Required:</DetailsLabel>
              <DetailsText>
                {" "}
                {props.selectedCapitalFund.approvalRequired ? "YES" : "NO"}
              </DetailsText>
            </DetailsRow>
            <DetailsRow>
              <DetailsLabel> Distributed Evenly:</DetailsLabel>
              <DetailsText>
                {props.selectedCapitalFund.distributeEvenly ? "YES" : "NO"}
              </DetailsText>
            </DetailsRow>
            <DetailsRow>
              <DetailsLabel> Description:</DetailsLabel>
              <DetailsText>
                {" "}
                {isNullUndefined(props.selectedCapitalFund.description)
                  ? ""
                  : props.selectedCapitalFund.description}
              </DetailsText>
            </DetailsRow>
          </DetailsColumn>
        </DetailsContainer>

        <ResponsiveRow
          sx={{
            width: "100%",
            height: "auto",
            padding: "10px 20px",
            textAlign: "left",
            boxShadow: 0,
            borderRadius: "10px",
            backgroundColor: "#efefef",
          }}
        >
          <Typography
            id="viewCapitalFund"
            variant="h6"
            sx={{ marginBottom: "20px" }}
            component="h6"
          >
            Capital Fund Distribution to Home Owners
          </Typography>
          <TableContainer
            sx={{
              maxHeight: 350,
              backgroundColor: "#fff",
            }}
          >
            <Table
              sx={{
                minWidth: 20,
                maxHeight: 350,
              }}
              aria-label="simple table"
              stickyHeader={true}
            >
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: "25%" }}>Owner</TableCell>
                  <TableCell sx={{ width: "25%" }}>Unit</TableCell>
                  <TableCell sx={{ width: "25%" }}>Status</TableCell>
                  <TableCell sx={{ width: "25%" }}>Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{props.capitalFundsApprovals}</TableBody>
            </Table>
          </TableContainer>
        </ResponsiveRow>
      </ResponsiveRow>
    </ModalContainerModule>
  );
}
