import {IconButton, Menu, MenuItem, Typography} from "@mui/material";
import {ResponsiveRow} from "../../../../constants/layout.constants";

import {MoreVert} from "@mui/icons-material";
import ViewApprovalModal from "../../../modals/dashboard/owner/viewApprovalModal";
import {useEffect, useState} from "react";
import {getHomeOwnerPendingApprovals} from "../../../../services/dashboard.service";

import MuiDataGrid from "../../MuiDataGrid";

/**
 *
 * REQUIRED PROPS
 *
 *  customHeight: string
 *  customWidth: string
 *  data:{
 *      id: number
 *      type: string
 *      note: string
 *      date: string
 *      amount: string
 *      approvalDetails: {
 *        name: string
 *        startDate: string
 *        facility: string
 *        description: string
 *        endDate: string
 *        approvalRequired: string
 *        status: string
 *        approvalThreshold: string
 *        incomeAccount: string
 *        expensesAccount: string
 *        income:[]
 *        expenses:[]
 *      }
 *
 */

export default function HomeOwnerPendingApproval({
                                                     customHeight,
                                                     customWidth,
                                                 }) {
    const [dataGridRows, setDataGridRows] = useState([]);
    const [loading, setLoading] = useState(true);
    const [viewApprovalModal, setViewApprovalModal] = useState(false);
    const [viewApprovalData] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    function handleReviewApproval(data) {
        setViewApprovalModal(true);

        setAnchorEl(null);
    }

    function handleApproveRequest(data) {
        setAnchorEl(null);
    }

    useEffect(() => {
        getHomeOwnerPendingApprovals()
            .then((response) => {
                if (response && response.data) {
                    setDataGridRows(response.data);
                }
            })
            .finally(() => {
                setLoading(false);
            });

        return () => {
            setDataGridRows([]);
        };
    }, []);

    const DataGridColumns = [
        {
            field: "type",
            headerName: "Type",
            width: 100,
        },
        {
            field: "description",
            headerName: "Description",
            minWidth: 150,
            flex: 1.5,
        },
        {
            field: "date",
            headerName: "Date",
            minWidth: 100,
            flex: 1,
        },
        {
            field: "status",
            headerName: "Status",
            minWidth: 100,
            flex: 1,
        },
        {
            field: "amount",
            headerName: "Amount",
            type: "number",
            minWidth: 100,
            flex: 1,
        },
        {
            field: "actions",
            minWidth: 70,
            flex: 0.7,
            headerName: "Actions",
            renderCell: (params) => {
                return (
                    <>
                        <IconButton
                            sx={{margin: "auto"}}
                            onClick={(event) => {
                                setAnchorEl(event.currentTarget);
                            }}
                        >
                            <MoreVert sx={{color: "darkgrey"}}/>
                        </IconButton>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={() => setAnchorEl(null)}
                            MenuListProps={{
                                "aria-labelledby": "basic-button",
                            }}
                        >
                            <MenuItem onClick={() => handleReviewApproval(params.row)}>
                                Review
                            </MenuItem>
                            <MenuItem onClick={() => handleApproveRequest(params.row)}>
                                Approve
                            </MenuItem>
                        </Menu>
                    </>
                );
            },
            width: 100,
        },
    ];

    return (
        <ResponsiveRow
            sx={{
                flexDirection: "column",
                flexWrap: "nowrap",

                alignItems: "flex-start",

                padding: "20px",

                height: {xs: "100%", lg: customHeight},
                width: {xs: "100%", lg: customWidth},

                marginBottom: "10px",

                backgroundColor: "#fff",
                borderRadius: "20px",
            }}
        >
            <Typography
                sx={{
                    width: "100%",
                }}
                textAlign="start"
                variant="h5"
            >
                Home Owners Pending Approvals
            </Typography>

            <MuiDataGrid
                alt={true}
                noSelection={true}
                dataGridColumns={DataGridColumns}
                dataGridRows={dataGridRows}
                serverPagination={false}
                loading={loading}
                height="90%"
                totalRows={dataGridRows.length}
            />

            <ViewApprovalModal
                handleAccept={handleApproveRequest}
                handleClose={() => setViewApprovalModal(false)}
                open={viewApprovalModal}
                data={viewApprovalData}
            />
        </ResponsiveRow>
    );
}
