/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from "react";
import { Box, Tab, Tabs } from "@mui/material";
import { TabContext, TabPanel } from "@mui/lab";
import BillTab from "../components/modules/billTab";
import PaymentsTab from "../components/modules/paymentsTab";
import BillRemindersTab from "../components/modules/billRemindersTab";
import Permit from "../util/permit";
import { ResponsiveRow } from "../constants/layout.constants";

//bills and payments shall be one page with both
export default function BillsPayments() {
  const tabs = [
    { id: 0, name: "Bills", value: "bills" },
    { id: 1, name: "Payments", value: "payments" },
    { id: 2, name: "Bills Reminders", value: "bills_reminders" },
  ];

  const [value, setValue] = useState("bills");

  function populateTab(currentTab) {
    switch (currentTab) {
      case 0:
        return <BillTab />;
      case 1:
        return <PaymentsTab />;
      case 2:
        return <BillRemindersTab />;
      default:
        return <BillTab />;
    }
  }

  function handleChange(e) {}

  useEffect(() => {
    setValue(tabs[0].value);
  }, []);

  return (
    <Permit
      roles="BLDIR,ACC,BLDMG,CS"
      services="PREMIUM,HOA,MAINT"
      noPermittedServiceMessage={{
        title: "Access Denied",
        body: (
          <>
            Your current role and/or facility is not permitted to view this
            page. <br /> Please contact your system admin if you feel this is an
            error.
          </>
        ),
      }}
    >
      <ResponsiveRow
        container
        sx={{
          flexDirection: "column",
          flexWrap: "nowrap",

          width: "100%",
          height: "100%",

          backgroundColor: "#fff",
          borderRadius: "20px",
          boxShadow: "0 4px 5px 0 rgba(0,0,0,0.3)",

          padding: { xs: 0, lg: "20px" },
          overflowX: "auto",
        }}
      >
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              sx={{ marginTop: "20px" }}
              variant="scrollable"
              scrollButtons="auto"
            >
              {tabs.map((tab) => {
                return (
                  <Tab
                    key={tab.id}
                    label={tab.name}
                    id={tab.id}
                    value={tab.value}
                    aria-controls={`tabPanel-${value}`}
                    onClick={() => setValue(tab.value)}
                  />
                );
              })}
            </Tabs>
          </Box>
          {tabs.map((tab) => {
            return (
              <TabPanel
                key={tab.id}
                value={tab.value}
                index={tab.id}
                sx={{ padding: "10px" }}
              >
                {populateTab(tab.id)}
              </TabPanel>
            );
          })}
        </TabContext>
      </ResponsiveRow>
    </Permit>
  );
}
