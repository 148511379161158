import {Autocomplete, FormControl, TextField, Typography,} from "@mui/material";
import React, {useEffect, useMemo, useState} from "react";
import {ResponsiveRow} from "../../constants/layout.constants";
import {isNullUndefined} from "../../util/util";

export default function MUIAutoCompleteForm({
                                                itemsData,
                                                label,
                                                updateSelected = (newValue) => {
                                                },
                                                menuId,
                                                maxListHeight,
                                                currentlySelected,
                                                width,
                                                disableClearable = false,
                                                emptyMessage = "No options to show",
                                            }) {
    const [selectedItem, setSelectedItem] = useState("");
    const [itemList, setItemList] = useState([]);
    const [open, setOpen] = useState(false);

    useMemo(() => {
        if (open === true) {
            setItemList(itemsData);
        } else {
            setItemList([]);
        }
    }, [open, itemsData]);

    useEffect(() => {
        if (currentlySelected.value === "--") {
            setSelectedItem({labe: "", value: ""});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentlySelected]);

    useEffect(() => {
        if(!isNullUndefined(currentlySelected) && !isNullUndefined(currentlySelected.value) &&
            !isNullUndefined(selectedItem) && !isNullUndefined(selectedItem.value)){
            if (currentlySelected.value !== "--" && selectedItem.value === "") {
                setSelectedItem(currentlySelected);
            }
        }
    }, [currentlySelected, selectedItem]);

    return (
        <FormControl sx={{width: {xs: "100%", lg: width}, height: "auto"}}>
            <Autocomplete
                open={open}
                onOpen={() => {
                    setOpen(true);
                }}
                onClose={() => {
                    setOpen(false);
                }}
                value={selectedItem}
                onChange={(event, newValue) => {
                    setSelectedItem(newValue);
                    updateSelected(newValue);
                }}
                disablePortal
                id={menuId}
                options={itemList}
                noOptionsText={emptyMessage}
                getOptionLabel={(option) => option.label || ""}
                sx={{
                    width: "90%",
                    height: "auto",

                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                }}
                PaperComponent={(props) => (
                    <ResponsiveRow
                        style={{
                            flexDirection: "column",
                            flexWrap: "nowrap",

                            backgroundColor: "white",

                            width: "100%",
                            height: "100%",
                            maxHeight: maxListHeight ? maxListHeight : "150px",

                            padding: "auto 0",
                            borderRadius: "0 0 10px 10px",
                        }}
                        {...props}
                    >
                        {props.children}
                    </ResponsiveRow>
                )}
                slot={{
                    paper: {
                        fullWidth: true,
                    },
                }}
                renderOption={(props, option) => {
                    return (
                        <Typography
                            whiteSpace={"nowrap"}
                            textOverflow="ellipsis"
                            sx={{
                                width: "100%",
                                height: "40px",

                                fontSize: "0.95rem",
                                padding: "auto 0",
                                borderRadius: "10px",
                            }}
                            {...props}
                            key={option.value}
                        >
                            {option.label}
                        </Typography>
                    );
                }}
                filterOptions={(options, state) => {
                    return options.filter((option) =>
                        String(option.label)
                            .toLowerCase()
                            .trim()
                            .includes(state.inputValue.toLowerCase().trim())
                    );
                }}
                renderInput={(params) => <TextField {...params} label={label}/>}
                disableClearable={disableClearable}
            />
        </FormControl>
    );
}
