import {Grid, Typography} from "@mui/material"
import MyListItem from "../../../components/cards/listings/myListItem"
import AddListingList from "../../../components/modals/listings/addListingList"
import {ModalAcceptButton} from "../../../constants/component.constants"
import {ResponsiveRow} from "../../../constants/layout.constants"
import {useState} from "react"

export default function ListingMyList() {
    const [createListModal, setCreateListModal] = useState(false)

    return (
        <ResponsiveRow
            sx={{flexDirection: "column", width: "100%", alignItems: "center"}}
        >
            <ResponsiveRow
                sx={{
                    width: {xs: "100%", lg: "80%"},
                    height: "auto",
                    marginTop: "100px",
                    padding: "10px auto",
                    justifyContent: {xs: "center", lg: "space-between"},
                    alignItems: "center",
                    flexWrap: {xs: "wrap", lg: "nowrap"},
                }}
            >
                <Grid item sx={{width: {xs: "100%", lg: "50%"}, height: "auto"}}>
                    <Typography
                        variant="h1"
                        fontWeight={800}
                        sx={{width: "100%", textAlign: {xs: "center", lg: "start"}}}
                    >
                        My Lists
                    </Typography>
                </Grid>
                <ResponsiveRow
                    item
                    sx={{
                        justifyContent: {xs: "center", lg: "flex-end"},
                        width: {xs: "100%", lg: "50%"},
                        height: "auto",
                        padding: 0,
                        margin: {xs: "10px auto", lg: "auto"},
                    }}
                >
                    <ModalAcceptButton
                        onClick={() => {
                            setCreateListModal(true)
                        }}
                        sx={{
                            width: {xs: "100%", lg: "200px"},
                            height: {xs: "auto", lg: "50px"},
                        }}
                    >
                        Create new List
                    </ModalAcceptButton>
                </ResponsiveRow>
            </ResponsiveRow>
            <ResponsiveRow
                sx={{
                    flexDirection: "row",
                    width: "80%",
                    justifyContent: "center",
                }}
            >
                <MyListItem/>
                <MyListItem/>
                <MyListItem/>
                <MyListItem/>
                <MyListItem/>
                <MyListItem/>
                <MyListItem/>
                <MyListItem/>
                <MyListItem/>
            </ResponsiveRow>
            <AddListingList
                openModal={createListModal}
                closeModal={() => {
                    setCreateListModal(false)
                }}
            />
        </ResponsiveRow>
    )
}
