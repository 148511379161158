/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useMemo, useState} from "react";
import {Done} from "@mui/icons-material";
import {Button} from "@mui/material";
import {
    activateMeter,
    getArchivedMeters,
    getMeterReadings,
    invoiceReading
} from "../services/meters.service";
import {isEmptyString, isNullUndefined} from "../util/util";
import {useSelector} from "react-redux";
import {issueResponseMessage} from "../actions/message";
import {MainContainer} from "../constants/component.constants";
import Permit from "../util/permit";
import ActivateArchivedMeterModal from "../components/modals/rentals/meters/activateArchivedMeterModal";
import MuiDataGrid from "../components/modules/MuiDataGrid";
import ViewMeterReadingsModal from "../components/modals/rentals/meters/viewMeterReadingsModal";

export default function ArchivedMeters() {
    const [rows, setRows] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [paginationModel] = useState({
        page: 0,
        pageSize: 50,
    });
    const [loading, setLoading] = useState(true);
    const [meterName] = useState([]);
    const [ReadingsModal, setReadingsModal] = useState(false);
    const [ArchiveModal, setArchiveModal] = useState(false);
    const [selectedMeter, setselectedMeter] = useState(null);
    const user = useSelector((state) => state.auth.user);
    const [meterReadings, setMeterReadings] = useState([]);

    useEffect(() => {
        setLoading(true);
        getArchivedMeters(meterName, paginationModel.page, paginationModel.pageSize)
            .then((meters) => {
                setRows(meters);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [meterName, paginationModel.page, paginationModel.pageSize]);

    function handleReadingsClick(selectedMeter) {
        setselectedMeter(selectedMeter);
        if (!isNullUndefined(selectedMeter) && !isEmptyString(selectedMeter.id)) {
            getMeterReadings(selectedMeter.id).then((readings) =>{
                    setMeterReadings(readings)
                setReadingsModal(true);
                }
            );
        }
    }

    function handleActivateClick(selectedMeter) {
        setselectedMeter(selectedMeter);
        setArchiveModal(true);
    }

    function activateSelectedMeter() {
        setLoading(true);
        activateMeter(selectedMeter.id)
            .then((response) => {
           issueResponseMessage(response)
                getArchivedMeters(meterName, paginationModel.page, paginationModel.pageSize)
                    .then((meters) => setRows(meters))
                setArchiveModal(false);
            })
            .finally(() => setLoading(false));
    }

    function invoice(reading) {
        let data = {
            meterReadingId: reading.id,
        };
        invoiceReading(data).then((response) => {
            issueResponseMessage(response);
            if (!isNullUndefined(response.reading)) {
                getArchivedMeters(meterName, paginationModel.page, paginationModel.pageSize)
                    .then((meters) => setRows(meters))
            }
        });
    }

    const main_datagrid_columns = useMemo(
        () => [
            // { headerName: "ID", field: "name", width: "150" },
            {headerName: "Meter Number", field: "name", minWidth: 150, flex: 1.5},
            {headerName: "Type", field: "meterType", minWidth: 184, flex: 1.84},
            {
                headerName: "Read Frequency",
                field: "readingFrequencyName",
                minWidth: 120,
                flex: 1.2,
            },
            {headerName: "Invoiced", field: "invoiced", width: 100}, //taxable ? "Yes" : "No"
            {
                headerName: "Attached to",
                field: "attachedTo",
                minWidth: 250,
                flex: 2.5,
            },
            {
                headerName: "Rate Type",
                field: "rateTypeName",
                minWidth: 150,
                flex: 1.5,
            },
            {headerName: "Rate", field: "rate", minWidth: 70, flex: 0.7},
            {
                headerName: "Standing Charge",
                field: "standingCharge",
                minWidth: 150,
                fleX: 1.5,
            },
            {
                headerName: "Readings",
                field: "readings",
                minWidth: 150,
                flex: 1.5,
                renderCell: (params) => (
                    <strong>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={() => handleReadingsClick(params.row)}
                        >
                            Readings ({params.row.readingCount})
                        </Button>
                    </strong>
                ),
            },
            {
                headerName: "Activate",
                minWidth: 100,
                flex: 1,
                renderCell: (params) => (
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        disabled={user.proxyRole !== "BLDIR"}
                        onClick={() => handleActivateClick(params.row)}
                    >
                        <Done/>
                    </Button>
                ),
            },
            // eslint-disable-next-line react-hooks/exhaustive-deps
        ],
        []
    );

    return (
        <Permit roles="BLDIR,BLDMG,CS" noPermittedServiceMessage={true}>
            <MainContainer>
                <MuiDataGrid
                    dataGridColumns={main_datagrid_columns}
                    dataGridRows={rows}
                    serverPagination={false}
                    loading={loading}
                    height="70vh"
                    totalRows={rows.length}
                />

                {/*READINGS MODAL*/}
                <ViewMeterReadingsModal
                    ReadingsModal={ReadingsModal}
                    user={user}
                    setConfirmDelete={setReadingsModal}
                    setReadingsModal={setReadingsModal}
                    invoice={invoice}
                    meterReadings={meterReadings}
                    selectedMeter={selectedMeter}
                />


                {/*ARCHIVE MODAL*/}
                <ActivateArchivedMeterModal
                    isOpen={ArchiveModal}
                    activateSelectedMeter={() => activateSelectedMeter()}
                    setselectedMeter={setselectedMeter}
                    setArchiveModal={setArchiveModal}
                    selectedMeter={selectedMeter}
                />
            </MainContainer>
        </Permit>
    );
}
