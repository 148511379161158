/* eslint-disable react-hooks/exhaustive-deps */
import {
    CategoryScale,
    Chart as ChartJS,
    Filler,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
} from "chart.js"
import {Line} from "react-chartjs-2"
import {useEffect, useRef, useState} from "react"

import EmptyOverlay from "../overlays/emptyOverlay"
import LoadingOverlay from "../overlays/loadingOverlay"

export default function AreaChartsCard({
                                           dataObj,
                                           labelObj,
                                           height,
                                           width,
                                           currentlyLoading,
                                       }) {
    const [isLoading, setIsLoading] = useState(false)
    const [emptyState, setEmptyState] = useState(true)
    const [chartDataLabels, setChartDataLabels] = useState(["", "", "", ""])
    const [chartDataPoints, setChartDataPoints] = useState([0, 0, 0, 0])
    const [chartDataPoints2, setChartDataPoints2] = useState([0, 0, 0, 0])
    const [chartDataPoints3, setChartDataPoints3] = useState([0, 0, 0, 0])
    const [chartDataPoints4, setChartDataPoints4] = useState([0, 0, 0, 0])

    const [chartDatasetLabel, setChartDatasetLabel] = useState("Dataset Label 1")
    const [chartDatasetLabel2, setChartDatasetLabel2] =
        useState("Dataset Label 2")
    const [chartDatasetLabel3, setChartDatasetLabel3] =
        useState("Dataset Label 3")
    const [chartDatasetLabel4, setChartDatasetLabel4] =
        useState("Dataset Label 4")

    const chartRef = useRef()

    const canvasElement = document.createElement("canvas")
    const crx = canvasElement.getContext("2d")

    const gradient1 = crx.createLinearGradient(0, 0, 0, 400)
    gradient1.addColorStop(0, "rgba(3,113,113, 1)")
    gradient1.addColorStop(1, "rgba(255,255,255,.45)")

    const gradient2 = crx.createLinearGradient(0, 0, 0, 400)
    gradient2.addColorStop(0, "rgba(31, 127, 128, 1)")
    gradient2.addColorStop(1, "rgba(255,255,255,.45)")

    const gradient3 = crx.createLinearGradient(0, 0, 0, 400)
    gradient3.addColorStop(0, "rgba(114, 168, 170, 1)")
    gradient3.addColorStop(1, "rgba(255,255,255,0.45)")

    const gradient4 = crx.createLinearGradient(0, 0, 0, 400)
    gradient4.addColorStop(0, "rgba(180, 206, 208, 1)")
    gradient4.addColorStop(1, "rgba(255,255,255,.45)")

    ChartJS.defaults.backgroundColor = "rgb(255,255,255)"
    ChartJS.defaults.color = "#000"
    ChartJS.defaults.fontWeight = "700"
    ChartJS.defaults.borderColor = "rgba(255,255,2551)"

    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Filler,
        Legend
    )

    useEffect(() => {
        if (dataObj && dataObj.dataset.length > 0) {
            for (let i = 0; i < dataObj.dataset.length; i++) {
                let dataPoints = []
                const datasetItem = dataObj.dataset[i]
                datasetItem.datasetData.forEach((dataItem) => {
                    dataPoints.push(dataItem.data)
                })

                if (dataPoints.length > 0) {
                    setChartDataLabels(dataObj.dataLabels)

                    switch (i) {
                        case 0:
                            setChartDatasetLabel(datasetItem.datasetLabel)
                            setChartDataPoints(dataPoints)
                            break
                        case 1:
                            setChartDatasetLabel2(datasetItem.datasetLabel)
                            setChartDataPoints2(dataPoints)
                            break
                        case 2:
                            setChartDatasetLabel3(datasetItem.datasetLabel)
                            setChartDataPoints3(dataPoints)
                            break
                        case 3:
                            setChartDatasetLabel4(datasetItem.datasetLabel)
                            setChartDataPoints4(dataPoints)
                            break

                        default:
                            break
                    }

                    setEmptyState(false)
                }
            }
        }

        return () => {
            setChartDataPoints([])
            setChartDataLabels([])
        }
    }, [])

    useEffect(() => {
        setIsLoading(currentlyLoading)
    }, [currentlyLoading])

    return isLoading ? (
        <LoadingOverlay/>
    ) : emptyState ? (
        <EmptyOverlay/>
    ) : (
        <Line
            ref={chartRef}
            data={{
                type: "line",
                labels: chartDataLabels,
                datasets: [
                    {
                        lineTension: 0.25,
                        label: chartDatasetLabel4,
                        data: chartDataPoints4,
                        backgroundColor: "rgba(114, 168, 170, .5)",
                        borderColor: "rgba(114, 168, 170, .5)",
                        borderWidth: 2,
                    },
                    {
                        lineTension: 0.25,
                        label: chartDatasetLabel3,
                        data: chartDataPoints3,
                        backgroundColor: "rgba(3,113,113, .4)",
                        borderColor: "rgba(3,113,113, .4)",
                        borderWidth: 2,
                    },
                    {
                        lineTension: 0.25,
                        label: chartDatasetLabel2,
                        data: chartDataPoints2,
                        backgroundColor: "rgba(114, 168, 170, 1)",
                        borderColor: "rgba(114, 168, 170, 1)",
                        borderWidth: 2,
                    },
                    {
                        lineTension: 0.25,
                        label: chartDatasetLabel,
                        data: chartDataPoints,
                        backgroundColor: "rgba(3, 113, 113, 1)",
                        borderColor: "rgba(3, 113, 113, 1)",
                        borderWidth: 2,
                    },
                ],
            }}
            options={{
                responsive: true,
                color: "#000",
                scales: {
                    x: {
                        padding: "20px",
                    },
                    y: {
                        padding: "20px",
                    },
                },
                plugins: {
                    legend: {
                        position: "bottom",
                        labels: {
                            color: "rgb(0,0,0)",
                            fontWeight: 700,
                        },
                    },
                },
            }}
        />
    )
}
