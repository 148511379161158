import { Typography } from "@mui/material";
import {
  ModalAcceptButton,
  ModalCancelButton,
} from "../../constants/component.constants";
import { ResponsiveRow } from "../../constants/layout.constants";

export default function BillingRemindersTabBar(props) {
  return (
    <ResponsiveRow
      sx={{
        alignItems: "center",
        justifyContent: "center",

        width: "100%",
        height: "auto",
      }}
    >
      <Typography
        variant="h4"
        fontWeight={600}
        color="primary.dark"
        sx={{
          width: { xs: "100%", lg: "25%" },
          textAlign: { xs: "center", lg: "start" },
        }}
      >
        Bill Reminders
      </Typography>
      <ResponsiveRow
        sx={{
          width: { xs: "100%", lg: "75%" },
          height: "auto",
          justifyContent: "flex-end",
        }}
      >
        {props.expanded ? (
          <ModalCancelButton
            onClick={() => props.handleCloseSearch()}
            sx={{
              width: { xs: "100%", lg: "200px" },
            }}
          >
            Close Search
          </ModalCancelButton>
        ) : props.searchResult ? (
          <ModalCancelButton
            onClick={() => props.handleCloseSearch()}
            sx={{
              width: { xs: "100%", lg: "200px" },
            }}
          >
            Clear Search
          </ModalCancelButton>
        ) : (
          <ModalAcceptButton
            onClick={() => props.handleExpandClick()}
            sx={{
              width: { xs: "100%", lg: "200px" },
            }}
          >
            Search
          </ModalAcceptButton>
        )}
        <ModalAcceptButton
          onClick={props.handleAddReminderClick}
          sx={{
            width: { xs: "100%", lg: "250px" },
          }}
        >
          Add Bill Reminder
        </ModalAcceptButton>
      </ResponsiveRow>
    </ResponsiveRow>
  );
}
