import {Grid, Typography} from "@mui/material"
import {Box} from "@mui/system"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import {useEffect, useState} from "react"
import {NavLink} from "react-router-dom"

export default function StatsCard(props) {
    const [stats, setStats] = useState()
    useEffect(() => {
        const tmp = Object.entries(props.data).map(([key, value]) => {
            return (
                <Grid item key={key}
                      sx={{
                          width: {lg: "30%", md: "50%", xs: "100%"},
                          display: "flex",
                          flexDirection: {md: "column", xs: "row-reverse"},
                          alignItems: "flex-start",
                          justifyContent: {xs: "space-between"},
                      }}
                >
                    <Typography
                        sx={{fontWeight: 700, fontSize: "14px", color: value.color}}
                    >
                        {value.count}
                    </Typography>
                    <Typography
                        sx={{fontSize: "12px", fontWeight: 600, textAlign: "center"}}
                    >
                        {key}
                    </Typography>
                </Grid>
            )
        })
        setStats(tmp)
    }, [props.data])

    return (
        <Grid
            container
            sx={{
                maxWidth: "350px",
                height: "fit-content",
                display: "flex",
                flexDirection: "column",
                backgroundColor: "#fff",
                padding: "20px",
                borderRadius: "10px",
                margin: "20px auto",
                boxShadow: "0 4px 12px 0 rgba(0,0,0,0.25)",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: "18px",
                            fontWeight: 800,
                            textAlign: "start",
                            color: "#037171",
                        }}
                    >
                        {props.title}
                    </Typography>
                    {/*<Typography sx={{ fontSize: "14px", fontWeight: 700 }}>*/}
                    {/*  {props.subTitle}*/}
                    {/*</Typography>*/}
                </Box>
                <NavLink
                    to={props.link}
                    style={{
                        textDecoration: "none",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        flexWrap: "nowrap",
                        fontSize: "12px",
                        color: "#037171",
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: "14px",
                            textAlign: "start",
                            margin: "auto",
                            color: "#037171",
                        }}
                    >
                        See all
                    </Typography>
                    <ChevronRightIcon/>
                </NavLink>
            </Box>

            <Grid container
                  sx={{
                      width: "100%",
                      margin: "10px 0 0 0",
                      backgroundColor: "#EFEFEF",
                      borderRadius: "5px",
                      display: "flex",
                      flexDirection: {xs: "column", md: "row"},
                      justifyContent: "space-evenly",
                      padding: "20px",
                  }}>
                {stats}
            </Grid>
        </Grid>
    )
}
