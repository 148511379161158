import {Typography} from "@mui/material";
import {ResponsiveRow} from "../../constants/layout.constants";
import emptyIllustration from "../../images/states/emptyDatagrid.svg";
import {Box} from "@mui/system";

export default function EmptyOverlay() {
    return (
        // <StyledGridOverlay>
        <ResponsiveRow
            sx={{
                flexDirection: "column",
                flexWrap: "nowrap",

                justifyContent: "center",
                alignItems: "center",

                height: "100%",
                width: "100%",

                margin: "auto",
            }}
        >
            <Box
                component="img"
                src={emptyIllustration}
                alt="empty illustration"
                sx={{
                    width: "100px",
                    height: "100px",

                    objectFit: "contain",
                    objectPosition: "center center",
                }}
            />
            <ResponsiveRow
                sx={{
                    width: "100%",
                    height: "auto",
                    justifyContent: "center",
                    textAlign: "center",
                }}
            >
                <Typography color="#cfcfcf" fontWeight={700}>
                    No Data Found
                </Typography>
            </ResponsiveRow>
        </ResponsiveRow>
        // </StyledGridOverlay>
    );
}
