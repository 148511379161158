/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from "react";
import {
  Alert,
  AlertTitle,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { ResponsiveRow } from "../../constants/layout.constants";
import {
  InnerModal,
  ModalAcceptButton,
  ModalCancelButton,
  TopBarContainedBrandButton,
  TopBarContainer,
} from "../../constants/component.constants";
import {
  adjustForTimezone,
  formatCurrency,
  formatDate,
  getBillingRefNumber,
  getDayStart,
  getIdRefNumber,
  getLeaseRefNumber,
  getTitleRefNumber,
  isArray,
  isEmptyArray,
  isEmptyString,
  isNullUndefined,
  isValidDate,
  tryParseFloat,
  tryParseInt,
} from "../../util/util";
import { issueSuccessMessage, issueWarnMessage } from "../../actions/message";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Add, Cancel, Clear, Edit } from "@mui/icons-material";
import {
  getLeaseChargeCodes,
  getLeaseTerms,
  getLeaseTypes,
} from "../../services/lease.service";
import { GridActionsCellItem } from "@mui/x-data-grid";
import {
  getMeterBillingChargeCodes,
  getRentDays,
  getRentPeriods,
} from "../../services/list.service";
import PersonOrgSearch from "../modals/personOrgSearch";
import AddNewUser from "../modals/addNewUser";
import AddNewOrganisation from "../modals/addNewOrganisation";
import { fetchPerson } from "../../services/workOrder.service";
import { fetchOrganization } from "../../services/organisation.service";
import { renderCellExpand } from "../../util/renderCellExpand";
import CreateOwnershipModal from "../modals/homes/ownerships/createOwnershipModal";
import { getOwnershipChargeCodes } from "../../services/ownership.service";
import dayjs from "dayjs";
import MuiDataGrid from "./MuiDataGrid";
import ModalContainerModule from "./modalContainer";
import kodi_theme from "../../themes/kodiThemeProvider";

export default function SignOnResidentDetails(props) {
  const [utilities, setUtilities] = useState([]);
  const [task, setTask] = useState("");
  const [rentPeriodCode, setRentPeriodCode] = useState("");
  const [leaseTypeCd, setLeaseTypeCd] = useState("");
  const [rentDate, setRentDate] = useState(null);
  const [rentDay, setRentDay] = useState(null);
  const [leaseTerm, setLeaseTerm] = useState("");
  const [incrementRent, setIncrementRent] = useState(false);
  const [rentIncrementAmount, setRentIncrementAmount] = useState("");
  const [rentIncrementPercentage, setRentIncrementPercentage] = useState("");
  const [incrementPeriod, setIncrementPeriod] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [balance, setBalance] = useState(0);
  const [balanceNotes, setBalanceNotes] = useState("");
  const [selectedUnit, setSelectedUnit] = useState("");
  const [tenantOccupied, setTenantOccupied] = useState(false);
  const [isHomeOwnerSelect, setIsHomeOwnerSelect] = useState(false);
  const [homeOwnersTableRows, setHomeOwnersTableRows] = useState("");
  const [ownershipChargesTableRows, setOwnershipChargesTableRows] =
    useState("");
  const [facilityOptions, setFacilityOptions] = useState([]);
  const [homeOwners, setHomeOwners] = useState([]);
  const [invoicedOwnerIndex, setInvoicedOwnerIndex] = useState(0);
  const [ownershipChargeCodes, setOwnershipChargeCodes] = useState([]);
  const [expirationDate, setExpirationDate] = useState(null);
  const [incrementType, setIncrementType] = useState("");
  const [billingPeriods, setBillingPeriods] = useState([]);
  const [rentDays, setRentDays] = useState([]);
  const [leaseChargeCodes, setLeaseChargeCodes] = useState([]);
  const [unitOptions, setUnitOptions] = useState([]);
  const [leases, setLeases] = useState([]);
  const [ownerships, setOwnerships] = useState([]);
  const [meterBillings, setMeterBillings] = useState([]);
  const [selectedLease, setSelectedLease] = useState(null);
  const [selectedMeterBilling, setSelectedMeterBilling] = useState(null);
  const [selectedOwnership, setSelectedOwnership] = useState(null);
  const [leaseTermOptions, setLeaseTermOptions] = useState([]);
  const [leaseTypeOptions, setLeaseTypeOptions] = useState([]);
  const [tenantModal, setTenantModal] = useState(false);
  const [selectedUnitIds, setSelectedUnitIds] = useState([]);
  const [isTenantSelect, setIsTenantSelect] = useState(false);
  const [isCustomerSelect, setIsCustomerSelect] = useState(false);
  const [leaseChargesTableRows, setLeaseChargesTableRows] = useState("");
  const [chargeItemsTotal, setChargeItemsTotal] = useState(0);
  const [chargeItems, setChargeItems] = useState([]);
  const MAX_INCREMENT_PERIOD = 10;
  const [chargeUnitsOptions, setChargeUnitsOptions] = useState([]);
  const [addOrganizationModal, setAddOrganizationModal] = useState(false);
  const [addUserModal, setAddUserModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [facilityId, setFacilityId] = useState("");
  const [isLeaseFacility, setIsLeaseFacility] = useState(false);
  const [isMeterBillingFacility, setIsMeterBillingFacility] = useState(false);
  const [isHOAFacility, setIsHOAFacility] = useState(false);
  const [isMaintenanceFacility, setIsMaintenanceFacility] = useState(false);
  const [units, setUnits] = useState([]);
  const [unitStatuses, setUnitStatuses] = useState([]);
  const [selectedPerson, setSelectedPerson] = useState(null);
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const [billingItemsTableRows, setBillingItemsTableRows] = useState("");
  const [meterBillingChargeCodes, setMeterBillingChargeCodes] = useState([]);
  const [meterOptions, setMeterOptions] = useState([]);
  const [selectedMeterIds, setSelectedMeterIds] = useState([]);
  const [chargeMetersOptions, setChargeMetersOptions] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [isBulkWaterFacility, setIsBulkWaterFacility] = useState(false);

  const handleAddLeaseClose = () => {
    setSelectedLease(null);
    setIncrementRent(false);
    setChargeItemsTotal(0);
    setChargeItems([]);
    setStartDate(null);
    setExpirationDate(null);
    setLeaseTerm("");
    setRentPeriodCode("");
    setLeaseTypeCd("");
    setRentDate(null);
    setRentDay("");
    setIncrementPeriod(0);
    setIncrementType("");
    setChargeUnitsOptions([]);
    setSelectedUnitIds([]);
    setTask("");
  };

  const handleAddBillingClose = () => {
    setSelectedMeterBilling(null);
    setChargeItems([]); //Clear items
    setChargeItemsTotal(0);
    setStartDate(null);
    setExpirationDate(null);
    setRentPeriodCode("");
    setRentDate(null);
    setRentDay("");
    setChargeMetersOptions([]);
    setSelectedMeterIds([]);
    setTask("");
  };

  useEffect(() => {
    if (isLeaseFacility) {
      populateLeaseChargeItems();
    }

    if (isMeterBillingFacility) {
      populateMeterBillingItems();
    }

    if (isHOAFacility) {
      populateHomeOwnerChargeItems();
    }
  }, [chargeItems]);

  useEffect(() => {
    populateHomeOwnerItems();
  }, [homeOwners]);

  useEffect(() => {
    setLoading(true);
    getRentPeriods().then((billingPeriods) =>
      setBillingPeriods(billingPeriods)
    );

    getMeterBillingChargeCodes().then((chargeCodes) =>
      setMeterBillingChargeCodes(chargeCodes)
    );

    getOwnershipChargeCodes().then((ownershipChargeCodes) =>
      setOwnershipChargeCodes(ownershipChargeCodes)
    );

    getRentDays().then((rentDays) => setRentDays(rentDays));

    getLeaseChargeCodes().then((leaseChargeCodes) =>
      setLeaseChargeCodes(leaseChargeCodes)
    );

    getLeaseTerms().then((leaseTermOptions) =>
      setLeaseTermOptions(leaseTermOptions)
    );

    getLeaseTypes()
      .then((leaseTypeOptions) => setLeaseTypeOptions(leaseTypeOptions))
      .finally(() => {
        setLoading(false);
      });

    if (
      !isNullUndefined(props) &&
      !isNullUndefined(props.signUp) &&
      !isNullUndefined(props.signUp.signUpJson) &&
      !isNullUndefined(props.signUp.signUpJson.facility)
    ) {
      setFacilityId(props.signUp.signUpJson.facility.id);
      let tmpOpt = [];
      tmpOpt.push({
        label: props.signUp.signUpJson.facility.name,
        value: props.signUp.signUpJson.facility.id,
      });
      setFacilityOptions(tmpOpt);
      if (
        !isNullUndefined(props.signUp.signUpJson.facility.units) &&
        isArray(props.signUp.signUpJson.facility.units)
      ) {
        setUnits(props.signUp.signUpJson.facility.units);
      }
      if (isArray(props.signUp.signUpJson.utilities)) {
        setUtilities(props.signUp.signUpJson.utilities);
      }
    }
    if (
      !isNullUndefined(props) &&
      !isNullUndefined(props.signUp) &&
      !isNullUndefined(props.signUp.signUpJson) &&
      !isNullUndefined(props.signUp.signUpJson.subscriptionService) &&
      !isNullUndefined(
        props.signUp.signUpJson.subscriptionService.serviceCode
      ) &&
      !isEmptyString(props.signUp.signUpJson.subscriptionService.serviceCode)
    ) {
      if (
        props.signUp.signUpJson.subscriptionService.serviceCode === "CORE" ||
        props.signUp.signUpJson.subscriptionService.serviceCode === "LEAN" ||
        props.signUp.signUpJson.subscriptionService.serviceCode === "PREMIUM"
      ) {
        setIsLeaseFacility(true);
        setIsHOAFacility(false);
        setIsMeterBillingFacility(false);
        setIsBulkWaterFacility(false);
        setIsMaintenanceFacility(false);
        //set leases
        if (
          !isNullUndefined(props) &&
          !isNullUndefined(props.signUp) &&
          !isNullUndefined(props.signUp.signUpJson) &&
          !isNullUndefined(props.signUp.signUpJson.leases) &&
          isArray(props.signUp.signUpJson.leases)
        ) {
          let tmp = [];
          for (let lease of props.signUp.signUpJson.leases) {
            let tmpLease = lease;
            let unitIds = [];
            let unitNameArray = [];
            for (let charge of lease.leaseCharges) {
              unitIds.push(charge.unitId);
            }
            unitIds = [...new Set(unitIds)];
            for (let id of unitIds) {
              let unit = units.filter((u) => u.id === id);
              if (unit.length > 0) {
                unitNameArray.push(unit[0].name);
              }
            }
            unitNameArray = [...new Set(unitNameArray)];
            let unitNames = "";
            if (unitNameArray.length > 1) {
              unitNames = unitNameArray.join(",");
            } else {
              unitNames = unitNameArray[0];
            }
            let tenantName = "";
            let tenant = lease.tenant;
            if (tenant.type === "Individual") {
              tenantName = tenant.firstName + " " + tenant.lastName;
            } else {
              tenantName = tenant.name;
            }
            tmpLease.tenantName = tenantName;
            tmpLease.unitNames = unitNames;
            tmpLease.unitIds = unitIds;
            tmp.push(tmpLease);
          }
          setLeases(tmp);
        }
        //set unit statuses
        if (
          !isNullUndefined(props) &&
          !isNullUndefined(props.signUp) &&
          !isNullUndefined(props.signUp.signUpJson) &&
          !isNullUndefined(props.signUp.signUpJson.unitStatuses) &&
          isArray(props.signUp.signUpJson.unitStatuses)
        ) {
          setUnitStatuses(props.signUp.signUpJson.unitStatuses);
        }
      }

      if (props.signUp.signUpJson.subscriptionService.serviceCode === "METER") {
        setIsMeterBillingFacility(true);
        setIsLeaseFacility(false);
        setIsHOAFacility(false);
        setIsBulkWaterFacility(false);
        setIsMaintenanceFacility(false);
        //set meter billing
        if (
          !isNullUndefined(props) &&
          !isNullUndefined(props.signUp) &&
          !isNullUndefined(props.signUp.signUpJson) &&
          !isNullUndefined(props.signUp.signUpJson.meterBillings) &&
          isArray(props.signUp.signUpJson.meterBillings)
        ) {
          setMeterBillings(props.signUp.signUpJson.meterBillings);
        }
      }

      if (props.signUp.signUpJson.subscriptionService.serviceCode === "MAINT") {
        setIsMeterBillingFacility(false);
        setIsLeaseFacility(false);
        setIsHOAFacility(false);
        setIsBulkWaterFacility(false);
        setIsMaintenanceFacility(true);
        let signUp;
        let buttonIndex = "4.2";
        let displayMessage = "Please proceed to the Review section.";
        if (props.signUp.signUpJson.currentStep === 5) {
          buttonIndex = "3.1";
          displayMessage = "Please go back to the utilities section.";
        }
        signUp = props.signUp;
        signUp.buttonIndex = buttonIndex;
        issueWarnMessage(displayMessage);
        props.saveSignUpData(signUp);
      }

      if (props.signUp.signUpJson.subscriptionService.serviceCode === "HOA") {
        setIsHOAFacility(true);
        setIsMeterBillingFacility(false);
        setIsLeaseFacility(false);
        setIsBulkWaterFacility(false);
        setIsMaintenanceFacility(false);
        //set ownerships
        if (
          !isNullUndefined(props) &&
          !isNullUndefined(props.signUp) &&
          !isNullUndefined(props.signUp.signUpJson) &&
          !isNullUndefined(props.signUp.signUpJson.ownerships) &&
          isArray(props.signUp.signUpJson.ownerships)
        ) {
          let units = props.signUp.signUpJson.facility.units;
          let tmp = [];
          for (let ownership of props.signUp.signUpJson.ownerships) {
            let tmpOwnership = ownership;
            let unitName = "";
            let unit = units.filter((u) => u.id === tmpOwnership.unitId);
            if (unit.length > 0) {
              unitName = unit[0].name;
            }
            let taxable = "No";
            tmpOwnership.periodicCharge = formatCurrency(0);
            for (let charge of ownership.ownershipCharges) {
              if (charge.taxable) {
                taxable = "Yes";
              }
              if (charge.charge.code === "SERCH") {
                tmpOwnership.periodicCharge = formatCurrency(charge.amount);
              }
            }

            let tenantName = "";
            if (!isNullUndefined(ownership.tenant)) {
              let tenant = ownership.tenant;
              if (!isNullUndefined(tenant.type)) {
                if (tenant.type === "Individual") {
                  tenantName = tenant.firstName + " " + tenant.lastName;
                } else {
                  tenantName = tenant.name;
                }
              }
            }

            let invoicedHomeOwner = "";
            if (!isNullUndefined(ownership.invoicedOwner)) {
              let invoicedOwner = ownership.invoicedOwner;
              if (!isNullUndefined(invoicedOwner.type)) {
                if (invoicedOwner.type === "Individual") {
                  invoicedHomeOwner =
                    invoicedOwner.firstName + " " + invoicedOwner.lastName;
                } else {
                  invoicedHomeOwner = invoicedOwner.name;
                }
              }
            }

            let ownerName = invoicedHomeOwner;
            if (!isNullUndefined(tenantName) && !isEmptyString(tenantName)) {
              ownerName = ownerName + "/" + tenantName;
            }

            if (
              !isNullUndefined(ownership.titleOwners) &&
              isArray(ownership.titleOwners)
            ) {
              let ownerNameArray = [];
              for (let owner of ownership.titleOwners) {
                ownerNameArray.push(owner.name);
              }
              ownerNameArray = [...new Set(ownerNameArray)];
              let ownerNames = "";
              if (ownerNameArray.length > 1) {
                ownerNames = ownerNameArray.join(",");
              } else {
                ownerNames = ownerNameArray[0];
              }
              if (
                ownership.isTenantOccupied &&
                !isNullUndefined(ownership.tenant) &&
                !isNullUndefined(ownership.tenant.name)
              ) {
                ownerNames = ownerNames + "/" + ownership.tenant.name;
              }

              tmpOwnership.ownerNames = ownerNames;
            }

            tmpOwnership.ownerName = ownerName;
            tmpOwnership.unitName = unitName;
            tmpOwnership.taxableText = taxable;
            tmp.push(tmpOwnership);
          }
          setOwnerships(tmp);
        }
        //set unit statuses
        if (
          !isNullUndefined(props) &&
          !isNullUndefined(props.signUp) &&
          !isNullUndefined(props.signUp.signUpJson) &&
          !isNullUndefined(props.signUp.signUpJson.unitStatuses) &&
          isArray(props.signUp.signUpJson.unitStatuses)
        ) {
          setUnitStatuses(props.signUp.signUpJson.unitStatuses);
        }
      }

      if (props.signUp.signUpJson.subscriptionService.serviceCode === "BLWTR") {
        setIsMeterBillingFacility(false);
        setIsLeaseFacility(false);
        setIsHOAFacility(false);
        setIsBulkWaterFacility(true);
        setIsMaintenanceFacility(false);
        //set customer
        if (
          !isNullUndefined(props) &&
          !isNullUndefined(props.signUp) &&
          !isNullUndefined(props.signUp.signUpJson) &&
          !isNullUndefined(props.signUp.signUpJson.customers) &&
          isArray(props.signUp.signUpJson.customers)
        ) {
          setCustomers(props.signUp.signUpJson.customers);
        }
      }
    }
  }, []);

  useEffect(() => {
    if (
      !isNullUndefined(props) &&
      !isNullUndefined(props.signUp) &&
      !isNullUndefined(props.signUp.signUpJson) &&
      !isNullUndefined(props.signUp.signUpJson.facility)
    ) {
      setFacilityId(props.signUp.signUpJson.facility.id);
      if (
        !isNullUndefined(props.signUp.signUpJson.facility.units) &&
        isArray(props.signUp.signUpJson.facility.units)
      ) {
        setUnits(props.signUp.signUpJson.facility.units);
      }
      if (isArray(props.signUp.signUpJson.utilities)) {
        setUtilities(props.signUp.signUpJson.utilities);
      }
    }
    if (
      !isNullUndefined(props) &&
      !isNullUndefined(props.signUp) &&
      !isNullUndefined(props.signUp.signUpJson) &&
      !isNullUndefined(props.signUp.signUpJson.subscriptionService) &&
      !isNullUndefined(
        props.signUp.signUpJson.subscriptionService.serviceCode
      ) &&
      !isEmptyString(props.signUp.signUpJson.subscriptionService.serviceCode)
    ) {
      if (
        props.signUp.signUpJson.subscriptionService.serviceCode === "CORE" ||
        props.signUp.signUpJson.subscriptionService.serviceCode === "LEAN" ||
        props.signUp.signUpJson.subscriptionService.serviceCode === "PREMIUM"
      ) {
        setIsLeaseFacility(true);
        setIsHOAFacility(false);
        setIsMeterBillingFacility(false);
        setIsBulkWaterFacility(false);
        setIsMaintenanceFacility(false);
        //set leases
        if (
          !isNullUndefined(props) &&
          !isNullUndefined(props.signUp) &&
          !isNullUndefined(props.signUp.signUpJson) &&
          !isNullUndefined(props.signUp.signUpJson.leases) &&
          isArray(props.signUp.signUpJson.leases)
        ) {
          let units = props.signUp.signUpJson.facility.units;
          let tmp = [];
          for (let lease of props.signUp.signUpJson.leases) {
            let tmpLease = lease;
            let unitIds = [];
            let unitNameArray = [];
            for (let charge of lease.leaseCharges) {
              unitIds.push(charge.unitId);
            }
            unitIds = [...new Set(unitIds)];
            for (let id of unitIds) {
              let unit = units.filter((u) => u.id === id);
              if (unit.length > 0) {
                unitNameArray.push(unit[0].name);
              }
            }
            unitNameArray = [...new Set(unitNameArray)];
            let unitNames = "";
            if (unitNameArray.length > 1) {
              unitNames = unitNameArray.join(",");
            } else {
              unitNames = unitNameArray[0];
            }
            let tenantName = "";
            let tenant = lease.tenant;
            if (tenant.type === "Individual") {
              tenantName = tenant.firstName + " " + tenant.lastName;
            } else {
              tenantName = tenant.name;
            }
            tmpLease.tenantName = tenantName;
            tmpLease.unitNames = unitNames;
            tmpLease.unitIds = unitIds;
            tmp.push(tmpLease);
          }
          setLeases(tmp);
        }
        //set unit statuses
        if (
          !isNullUndefined(props) &&
          !isNullUndefined(props.signUp) &&
          !isNullUndefined(props.signUp.signUpJson) &&
          !isNullUndefined(props.signUp.signUpJson.unitStatuses) &&
          isArray(props.signUp.signUpJson.unitStatuses)
        ) {
          setUnitStatuses(props.signUp.signUpJson.unitStatuses);
        }
      }

      if (props.signUp.signUpJson.subscriptionService.serviceCode === "METER") {
        setIsMeterBillingFacility(true);
        setIsLeaseFacility(false);
        setIsHOAFacility(false);
        setIsBulkWaterFacility(false);
        setIsMaintenanceFacility(false);
        //set meter billing
        if (
          !isNullUndefined(props) &&
          !isNullUndefined(props.signUp) &&
          !isNullUndefined(props.signUp.signUpJson) &&
          !isNullUndefined(props.signUp.signUpJson.meterBillings) &&
          isArray(props.signUp.signUpJson.meterBillings)
        ) {
          let utilities = props.signUp.signUpJson.utilities;
          let tmp = [];
          for (let meterBilling of props.signUp.signUpJson.meterBillings) {
            let tmpMeterBilling = meterBilling;
            let taxable = false;
            let meterIds = [];
            let meterNameArray = [];
            for (let charge of meterBilling.billingChargeItems) {
              meterIds.push(charge.meterId);
              if (charge.charge.code === "UTSCH") {
                taxable = true;
              }
            }
            meterIds = [...new Set(meterIds)];
            for (let id of meterIds) {
              let meter = utilities.filter((u) => u.id === id);
              if (meter.length > 0) {
                meterNameArray.push(meter[0].name);
              }
            }
            meterNameArray = [...new Set(meterNameArray)];
            let meterNames = "";
            if (meterNameArray.length > 1) {
              meterNames = meterNameArray.join(",");
            } else {
              meterNames = meterNameArray[0];
            }
            let customerName = "";
            let customer = meterBilling.customer;
            if (customer.type === "Individual") {
              customerName = customer.firstName + " " + customer.lastName;
            } else {
              customerName = customer.name;
            }

            if (taxable) {
              tmpMeterBilling.taxable = "Yes";
            } else {
              tmpMeterBilling.taxable = "No";
            }
            tmpMeterBilling.customerName = customerName;
            tmpMeterBilling.meterNames = meterNames;
            tmpMeterBilling.meterIds = meterIds;
            tmp.push(tmpMeterBilling);
          }
          setMeterBillings(tmp);
        }
      }

      if (props.signUp.signUpJson.subscriptionService.serviceCode === "HOA") {
        setIsHOAFacility(true);
        setIsMeterBillingFacility(false);
        setIsLeaseFacility(false);
        setIsBulkWaterFacility(false);
        setIsMaintenanceFacility(false);
        //set ownerships
        if (
          !isNullUndefined(props) &&
          !isNullUndefined(props.signUp) &&
          !isNullUndefined(props.signUp.signUpJson) &&
          !isNullUndefined(props.signUp.signUpJson.ownerships) &&
          isArray(props.signUp.signUpJson.ownerships)
        ) {
          let units = props.signUp.signUpJson.facility.units;
          let tmp = [];
          for (let ownership of props.signUp.signUpJson.ownerships) {
            let tmpOwnership = ownership;
            let unitName = "";
            let unit = units.filter((u) => u.id === tmpOwnership.unitId);
            if (unit.length > 0) {
              unitName = unit[0].name;
            }
            let taxable = "No";
            tmpOwnership.periodicCharge = formatCurrency(0);
            for (let charge of ownership.ownershipCharges) {
              if (charge.taxable) {
                taxable = "Yes";
              }

              if (charge.charge.code === "SERCH") {
                tmpOwnership.periodicCharge = formatCurrency(charge.amount);
              }
            }
            let tenantName = "";
            if (!isNullUndefined(ownership.tenant)) {
              let tenant = ownership.tenant;
              if (!isNullUndefined(tenant.type)) {
                if (tenant.type === "Individual") {
                  tenantName = tenant.firstName + " " + tenant.lastName;
                } else {
                  tenantName = tenant.name;
                }
              }
            }

            let invoicedHomeOwner = "";
            if (!isNullUndefined(ownership.invoicedOwner)) {
              let invoicedOwner = ownership.invoicedOwner;
              if (!isNullUndefined(invoicedOwner.type)) {
                if (invoicedOwner.type === "Individual") {
                  invoicedHomeOwner =
                    invoicedOwner.firstName + " " + invoicedOwner.lastName;
                } else {
                  invoicedHomeOwner = invoicedOwner.name;
                }
              }
            }

            let ownerName = invoicedHomeOwner;
            if (!isNullUndefined(tenantName) && !isEmptyString(tenantName)) {
              ownerName = ownerName + "/" + tenantName;
            }
            if (
              !isNullUndefined(ownership.titleOwners) &&
              isArray(ownership.titleOwners)
            ) {
              let ownerNameArray = [];
              for (let owner of ownership.titleOwners) {
                ownerNameArray.push(owner.name);
              }
              ownerNameArray = [...new Set(ownerNameArray)];
              let ownerNames = "";
              if (ownerNameArray.length > 1) {
                ownerNames = ownerNameArray.join(",");
              } else {
                ownerNames = ownerNameArray[0];
              }
              if (
                ownership.isTenantOccupied &&
                !isNullUndefined(ownership.tenant) &&
                !isNullUndefined(ownership.tenant.name)
              ) {
                ownerNames = ownerNames + "/" + ownership.tenant.name;
              }

              tmpOwnership.ownerNames = ownerNames;
            }
            tmpOwnership.ownerName = ownerName;
            tmpOwnership.unitName = unitName;
            tmpOwnership.taxableText = taxable;
            tmp.push(tmpOwnership);
          }
          setOwnerships(tmp);
        }
        //set unit statuses
        if (
          !isNullUndefined(props) &&
          !isNullUndefined(props.signUp) &&
          !isNullUndefined(props.signUp.signUpJson) &&
          !isNullUndefined(props.signUp.signUpJson.unitStatuses) &&
          isArray(props.signUp.signUpJson.unitStatuses)
        ) {
          setUnitStatuses(props.signUp.signUpJson.unitStatuses);
        }
      }

      if (props.signUp.signUpJson.subscriptionService.serviceCode === "BLWTR") {
        setIsMeterBillingFacility(false);
        setIsLeaseFacility(false);
        setIsHOAFacility(false);
        setIsBulkWaterFacility(true);
        setIsMaintenanceFacility(false);
        //set customer
        if (
          !isNullUndefined(props) &&
          !isNullUndefined(props.signUp) &&
          !isNullUndefined(props.signUp.signUpJson) &&
          !isNullUndefined(props.signUp.signUpJson.customers) &&
          isArray(props.signUp.signUpJson.customers)
        ) {
          setCustomers(props.signUp.signUpJson.customers);
        }
      }

      if (props.signUp.signUpJson.subscriptionService.serviceCode === "MAINT") {
        setIsMeterBillingFacility(false);
        setIsLeaseFacility(false);
        setIsHOAFacility(false);
        setIsBulkWaterFacility(false);
        setIsMaintenanceFacility(true);
      }
    }
  }, [props.signUp]);

  useEffect(() => {
    let unitOp = [];

    units &&
      units.length > 0 &&
      units.forEach(function (unit, i) {
        let unitsWithStatuses = unitStatuses.filter(
          (us, i) => unit.id === us.unitId
        );
        if (unitsWithStatuses.length < 1) {
          unitOp.push({ label: unit.name, value: unit.id });
        } else {
          let avUnits = unitsWithStatuses.filter(
            (us, i) => us.statusCd !== "LSD" && us.statusCd !== "OWD"
          );
          if (avUnits.length > 0) {
            unitOp.push({ label: unit.name, value: unit.id });
          }
        }
      }, this);

    setUnitOptions(unitOp);
  }, [units, unitStatuses]);

  useEffect(() => {
    let unitOp = [];

    units &&
      units.length > 0 &&
      units.forEach(function (unit, i) {
        let unitsWithStatuses = unitStatuses.filter(
          (us, i) => unit.id === us.unitId
        );
        if (unitsWithStatuses.length < 1) {
          unitOp.push({ label: unit.name, value: unit.id });
        } else {
          let avUnits = unitsWithStatuses.filter(
            (us, i) => us.statusCd !== "LSD"
          );
          if (avUnits.length > 0) {
            unitOp.push({ label: unit.name, value: unit.id });
          }
        }
      }, this);

    if (task === "Edit") {
      units &&
        units.length > 0 &&
        units.forEach(function (unit, i) {
          let leaseUnits = selectedLease.unitIds.filter(
            (id, i) => id === unit.id
          );
          if (leaseUnits.length > 0) {
            unitOp.push({ label: unit.name, value: unit.id });
          }
        }, this);
    }
    setUnitOptions(unitOp);
  }, [selectedLease]);

  useEffect(() => {
    let meterOp = [];
    let leasedMeterIds = [];
    meterBillings &&
      meterBillings.length > 0 &&
      meterBillings.forEach(function (meterBilling, i) {
        if (
          !isNullUndefined(meterBilling.billingChargeItems) &&
          isArray(meterBilling.billingChargeItems) &&
          meterBilling.billingChargeItems.length > 0
        ) {
          meterBilling.billingChargeItems.forEach(function (billingChargeItem) {
            leasedMeterIds.push(billingChargeItem.meterId);
          }, this);
        }
      }, this);
    leasedMeterIds = [...new Set(leasedMeterIds)];

    utilities &&
      utilities.length > 0 &&
      utilities.forEach(function (utility) {
        let metersLeased = leasedMeterIds.filter((id) => utility.id === id);
        if (metersLeased.length < 1 && utility.invoiced) {
          meterOp.push({ label: utility.name, value: utility.id });
        }
      }, this);

    if (task === "Edit") {
      utilities &&
        utilities.length > 0 &&
        utilities.forEach(function (utility) {
          let meterBillingMeters = selectedMeterBilling.meterIds.filter(
            (id) => id === utility.id
          );
          if (meterBillingMeters.length > 0) {
            meterOp.push({ label: utility.name, value: utility.id });
          }
        }, this);
    }
    setMeterOptions(meterOp);
  }, [selectedMeterBilling]);

  useEffect(() => {
    let unitOp = [];
    units &&
      units.length > 0 &&
      units.forEach(function (unit) {
        let unitsWithStatuses = unitStatuses.filter(
          (us) => unit.id === us.unitId
        );
        if (unitsWithStatuses.length < 1) {
          unitOp.push({ label: unit.name, value: unit.id });
        } else {
          let avUnits = unitsWithStatuses.filter((us) => us.statusCd !== "OWD");
          if (avUnits.length > 0) {
            unitOp.push({ label: unit.name, value: unit.id });
          }
        }
      }, this);

    if (task === "Edit") {
      units &&
        units.length > 0 &&
        units.forEach(function (unit) {
          if (
            selectedOwnership != null &&
            selectedOwnership.unitId === unit.id
          ) {
            unitOp.push({ label: unit.name, value: unit.id });
          }
        }, this);
    }
    setUnitOptions(unitOp);
  }, [selectedOwnership]);

  function handleBillingMeterChange(event, index) {
    let value = event.target.value;
    let newArr = [...chargeItems]; // copying the old items array
    newArr[index].meterId = value;
    setChargeItems(newArr);
    populateMeterBillingItems();
  }

  function handleBillingAccountChange(event, index) {
    let value = event.target.value;
    let chargeName = meterBillingChargeCodes.filter(
      (charge) => charge.value === value
    )[0].label;
    let newArr = [...chargeItems]; // copying the old items array
    newArr[index].charge.code = value;
    newArr[index].charge.name = chargeName;
    setChargeItems(newArr);
    populateMeterBillingItems();
  }

  function populateMeterBillingItems() {
    let rows = "";
    let total = 0;
    let today = new Date();
    chargeItems &&
      chargeItems.length > 0 &&
      (rows = chargeItems.map(function (billingItem, i) {
        let amount = tryParseFloat(billingItem.amount, -1);
        if (amount > 0) {
          total = total + amount;
        }
        return (
          <TableRow key={i}>
            <TableCell>
              <Select
                sx={{ width: "90%" }}
                id={"meter"}
                disabled={false}
                value={chargeItems && chargeItems[i] && chargeItems[i].meterId}
                onChange={(event) => handleBillingMeterChange(event, i)}
                defaultValue={[""]}
              >
                {chargeMetersOptions && chargeMetersOptions.length > 0 ? (
                  chargeMetersOptions.map(function (meterOption) {
                    // let disabled = false
                    for (let chargeValue of chargeItems) {
                      if (chargeValue.meterId === meterOption.value) {
                        // disabled = true
                      }
                    }
                    return (
                      <MenuItem
                        key={meterOption.value}
                        value={meterOption.value}
                      >
                        {meterOption.label}
                      </MenuItem>
                    );
                  }, this)
                ) : (
                  <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
                )}
              </Select>
            </TableCell>

            <TableCell>
              <Select
                sx={{ width: "90%" }}
                id={"billingAccount" + i}
                name={"billingAccount" + i}
                isrequired={true}
                value={
                  chargeItems && chargeItems[i] && chargeItems[i].charge.code
                }
                onChange={(event) => handleBillingAccountChange(event, i)}
              >
                {meterBillingChargeCodes &&
                meterBillingChargeCodes.length > 0 ? (
                  meterBillingChargeCodes.map(function (chargeCode) {
                    let disabled = false;
                    for (let chargeValue of chargeItems) {
                      if (
                        billingItem.meterId === chargeValue.meterId &&
                        chargeValue.charge.code === chargeCode.value
                      ) {
                        disabled = true;
                      }
                    }
                    return (
                      <MenuItem
                        key={chargeCode.value}
                        disabled={disabled}
                        value={chargeCode.value}
                      >
                        {chargeCode.label}
                      </MenuItem>
                    );
                  }, this)
                ) : (
                  <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
                )}
              </Select>
            </TableCell>
            <TableCell>
              <TextField
                sx={{ width: "90%" }}
                id={"billingItemAmount" + i}
                name={"billingItemAmount" + i}
                isrequired={true}
                // disabled={items && items[i] && !items[i].displayVATRadio}
                value={chargeItems && chargeItems[i] && chargeItems[i].amount}
                onChange={(event) => billingAmountChange(event, i)}
              />
            </TableCell>
            <TableCell>
              <TextField
                id={"date" + i}
                disabled
                placeholder={today.toString()}
                value={
                  chargeItems &&
                  chargeItems[i] &&
                  chargeItems[i].effectiveDate &&
                  formatDate(chargeItems[i].effectiveDate)
                }
              />
            </TableCell>
            <TableCell>
              {chargeItems &&
                chargeItems[i] &&
                chargeItems[i].charge &&
                chargeItems[i].charge.code === "UTSCH" && (
                  <Checkbox
                    checked={
                      chargeItems && chargeItems[i] && chargeItems[i].taxable
                    }
                    onChange={(event) => handleBillingCheckboxToggle(event, i)}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                )}
            </TableCell>
            <TableCell>
              {chargeItems && chargeItems.length > 1 && (
                <Button
                  variant="contained"
                  type="submit"
                  sx={{ marginLeft: "10px" }}
                  onClick={(event) => handleBillingItemRemove(i)}
                  color="error"
                >
                  <Cancel />
                </Button>
              )}
            </TableCell>
          </TableRow>
        );
      }, this));
    setBillingItemsTableRows(rows);
    setChargeItemsTotal(total);
  }

  function billingAmountChange(event, index) {
    const value = event.target.value;
    let newArr = [...chargeItems]; // copying the old items array
    newArr[index].amount = value;
    setChargeItems(newArr);
    populateMeterBillingItems();
  }

  function handleBillingCheckboxToggle(event, index) {
    let newArr = [...chargeItems]; // copying the old items array
    newArr[index].taxable = event.target.checked;
    setChargeItems(newArr);
    populateMeterBillingItems();
  }

  function handleBillingItemRemove(index) {
    let newArr = [...chargeItems]; // copying the old items array
    if (newArr.length > 1) {
      newArr.splice(index, 1);
      setChargeItems(newArr);
    } else {
      issueWarnMessage("There must be at least one credit item.");
    }
  }

  function handleBillingItemAdd() {
    setChargeItems((items) => [
      ...chargeItems,
      {
        id: getIdRefNumber(),
        meterId: "",
        charge: { code: "", name: "" },
        amount: null,
        statusCd: "ACT",
        effectiveDate: new Date(),
        taxable: false,
        description: "",
      },
    ]);
  }

  function populateLeaseChargeItems() {
    let rows = "";
    let total = 0;
    chargeItems &&
      chargeItems.length > 0 &&
      (rows = chargeItems.map(function (charge, i) {
        let amount = tryParseFloat(charge.amount, -1);
        if (amount > 0) {
          total = total + amount;
        }
        return (
          <TableRow key={i}>
            <TableCell>
              <Select
                sx={{ width: "90%" }}
                id={"unit"}
                value={charge.unitId}
                disabled={task === "ActiveLeaseEdit"}
                onChange={(event) => handleChargeUnitChange(event, i)}
              >
                {chargeUnitsOptions && chargeUnitsOptions.length > 0 ? (
                  chargeUnitsOptions.map(function (unit, i) {
                    return (
                      <MenuItem key={unit.value} value={unit.value}>
                        {unit.label}
                      </MenuItem>
                    );
                  }, this)
                ) : (
                  <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
                )}
              </Select>
            </TableCell>
            <TableCell>
              <Select
                sx={{ width: "90%" }}
                id={"chargeCode" + i}
                value={charge.charge.code}
                onChange={(event) => handleChargeCodeChange(event, i)}
              >
                {leaseChargeCodes && leaseChargeCodes.length > 0 ? (
                  leaseChargeCodes.map(function (chargeCode, i) {
                    let disabled = false;
                    for (let chargeValue of chargeItems) {
                      if (
                        charge.unitId === chargeValue.unitId &&
                        chargeValue.charge.code === chargeCode.value
                      ) {
                        disabled = true;
                      }
                    }
                    return (
                      <MenuItem
                        key={chargeCode.value}
                        disabled={disabled}
                        value={chargeCode.value}
                      >
                        {chargeCode.label}
                      </MenuItem>
                    );
                  }, this)
                ) : (
                  <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
                )}
              </Select>
            </TableCell>
            <TableCell>
              <TextField
                sx={{ width: "90%" }}
                id={"chargeAmount" + i}
                name={"chargeAmount" + i}
                isrequired={true}
                disabled={false}
                value={charge.amount}
                onChange={(event) => leaseChargeAmountChange(event, i)}
              />
            </TableCell>
            <TableCell>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale="en-gb"
              >
                <DatePicker
                  sx={{ width: "90%" }}
                  id={"chargeStartDate"}
                  value={dayjs(charge.effectiveDate)}
                  disabled={true}
                  onChange={(newDate) =>
                    handleChargeStartDateChange(newDate, i)
                  }
                />
              </LocalizationProvider>
            </TableCell>
            <TableCell>
              {(charge.charge.code === "SERCH" ||
                charge.charge.code === "REN") && (
                <Switch
                  checked={charge.taxable}
                  onChange={(event) => handleChargeTaxableToggle(event, i)}
                  inputProps={{ "aria-label": "controlled" }}
                />
              )}
              {(charge.charge.code === "LFEE" ||
                charge.charge.code === "MISC") && (
                <TextField
                  sx={{ width: "90%" }}
                  id={"chargeNote" + i}
                  name={"chargeNote" + i}
                  isrequired={true}
                  disabled={false}
                  value={charge.note}
                  onChange={(event) => leaseChargeNoteChange(event, i)}
                />
              )}
            </TableCell>
            <TableCell>
              <Button
                variant="contained"
                type="submit"
                sx={{ marginLeft: "10px" }}
                onClick={(event) => handleLeaseChargeItemRemove(i)}
                color="error"
              >
                <Cancel />
              </Button>
            </TableCell>
          </TableRow>
        );
      }, this));
    setLeaseChargesTableRows(rows);
    setChargeItemsTotal(total);
  }

  function handleChargeCodeChange(event, index) {
    let value = event.target.value;
    let newArr = [...chargeItems]; // copying the old items array
    if (!isHOAFacility) {
      let chargeName = leaseChargeCodes.filter(
        (charge, i) => charge.value === value
      )[0].label;
      newArr[index].charge.code = value;
      newArr[index].charge.name = chargeName;
      setChargeItems(newArr);
    } else {
      let name;
      if (value === "CAPF") {
        name = "Capital Fund";
      } else if (value === "SERCH") {
        name = "Service Charge";
      } else if (value === "ELCUT") {
        name = "Utility - Electricity";
      } else if (value === "WTUT") {
        name = "Utility - Water";
      }
      newArr[index].charge.code = value;
      newArr[index].charge.name = name;
      newArr[index].chargeCode = value;
      setChargeItems(newArr);
      populateHomeOwnerChargeItems();
    }
  }

  function handleHomeOwnerChargeItemRemove(index) {
    let newArr = [...chargeItems]; // copying the old items array
    newArr.splice(index, 1);
    setChargeItems(newArr);
    populateHomeOwnerChargeItems();
  }

  function handleInvoiceDayChange(event, index) {
    let value = event.target.value;
    let newArr = [...chargeItems]; // copying the old items array
    newArr[index].invoiceDay = value;
    setChargeItems(newArr);
    populateHomeOwnerChargeItems();
  }

  function handleInvoiceDateChange(value, index) {
    let date = new Date(value);
    let newArr = [...chargeItems]; // copying the old items array
    newArr[index].invoiceDate = date;
    setChargeItems(newArr);
    populateHomeOwnerChargeItems();
  }

  function ownershipChargeAmountChange(event, index) {
    let value = event.target.value;
    let newArr = [...chargeItems]; // copying the old items array
    newArr[index].amount = value;
    setChargeItems(newArr);
    populateHomeOwnerChargeItems();
  }

  function handleChargeBillingPeriodChange(event, index) {
    let value = event.target.value;
    let newArr = [...chargeItems]; // copying the old items array
    newArr[index].billingPeriod = value;
    setChargeItems(newArr);
    populateHomeOwnerChargeItems();
  }

  function handleChargeUnitChange(event, index) {
    let value = event.target.value;
    let newArr = [...chargeItems]; // copying the old items array
    newArr[index].unitId = value;
    setChargeItems(newArr);
  }

  function leaseChargeAmountChange(event, index) {
    let value = event.target.value;
    let newArr = [...chargeItems]; // copying the old items array
    newArr[index].amount = value;
    setChargeItems(newArr);
  }

  function handleChargeStartDateChange(value, index) {
    let date = new Date(value);
    let newArr = [...chargeItems]; // copying the old items array
    newArr[index].effectiveDate = date;
    setChargeItems(newArr);
  }

  function handleChargeTaxableToggle(event, index) {
    let value = event.target.checked;
    let newArr = [...chargeItems]; // copying the old items array
    newArr[index].taxable = value;
    setChargeItems(newArr);
  }

  function leaseChargeNoteChange(event, index) {
    let value = event.target.value;
    let newArr = [...chargeItems]; // copying the old items array
    newArr[index].note = value;
    setChargeItems(newArr);
  }

  function handleLeaseChargeItemRemove(index) {
    let newArr = [...chargeItems]; // copying the old items array
    newArr.splice(index, 1);
    setChargeItems(newArr);
  }

  function saveMeterBilling() {
    let obj = selectedMeterBilling;
    obj.billingChargeItems = chargeItems;
    if (obj.billingChargeItems.length < 1) {
      issueWarnMessage(
        "You must add at least one meter billing charge to save a meter billing."
      );
      return;
    }

    if (validateMeterBilling(obj)) {
      let message = "Meter Billing added successfully";
      if (task === "Create") {
        setMeterBillings((meterBillings) => [
          ...meterBillings,
          selectedMeterBilling,
        ]);
      }
      if (task === "Edit") {
        setMeterBillings((meterBillings) => [
          ...meterBillings.filter(
            (meterBilling, i) => meterBilling.id !== selectedMeterBilling.id
          ),
          selectedMeterBilling,
        ]);
        message = "Meter Billing updated successfully";
      }

      handleAddBillingClose();
      setRentDay("");
      setRentDate("");
      issueSuccessMessage(message);
    }
  }

  function validateMeterBilling(sendData) {
    if (isNullUndefined(sendData)) {
      issueWarnMessage("Could not determine Meter Billing to save.");
      return false;
    }
    let startDate = new Date(sendData.effectiveDate);
    if (!isValidDate(startDate)) {
      issueWarnMessage("Start date is not a valid date!!");
      return false;
    }
    sendData.effectiveDate = startDate;
    if (isNullUndefined(sendData.customer.id)) {
      issueWarnMessage("A customer must be selected!!");
      return false;
    }
    if (
      isNullUndefined(sendData.meterIds) ||
      !isArray(sendData.meterIds) ||
      sendData.meterIds.length < 1
    ) {
      issueWarnMessage("Meters must be selected!!");
      return false;
    }

    let meterNameArray = [];
    for (let id of sendData.meterIds) {
      let meter = utilities.filter((m) => m.id === id);
      if (meter.length > 0) {
        meterNameArray.push(meter[0].name);
      }
    }
    meterNameArray = [...new Set(meterNameArray)];
    let meterNames = "";
    if (meterNameArray.length > 1) {
      meterNames = meterNameArray.join(",");
    } else {
      meterNames = meterNameArray[0];
    }
    sendData.meterNames = meterNames;

    if (isEmptyString(sendData.billingFrequency)) {
      issueWarnMessage("Billing frequency must be selected!!");
      return false;
    }
    if (sendData.billingFrequency === "MNTH") {
      if (isEmptyString(sendData.billingDay)) {
        issueWarnMessage("Billing day must be selected!!");
        return false;
      }
    }
    if (
      sendData.billingFrequency === "ANU" ||
      sendData.billingFrequency === "QT"
    ) {
      if (isEmptyString(sendData.billingDate)) {
        issueWarnMessage("Next billing date must be selected!!");
        return false;
      }
      let billingDate = new Date(sendData.billingDate);
      if (!isValidDate(billingDate)) {
        issueWarnMessage("Billing date is not a valid date!!");
        return false;
      }
      sendData.billingDate = billingDate;
    }
    if (sendData.billingChargeItems.length < 1) {
      issueWarnMessage(
        "You must add at least one charge to save the meter billing."
      );
      return false;
    }
    if (isArray(sendData.billingChargeItems)) {
      for (let charge of sendData.billingChargeItems) {
        if (isEmptyString(charge.charge.code)) {
          issueWarnMessage(
            "Could not determine the charge type for one of the charges."
          );
          return false;
        }

        if (charge.charge.code === "UTSCH") {
          sendData.taxable = "Yes";
        }

        if (isNullUndefined(charge.amount)) {
          issueWarnMessage("A charge must have an amount.");
          return false;
        }
        let amount = tryParseFloat(charge.amount.toString(), -1);
        if (amount < 0) {
          let message =
            "Amount '" +
            charge.amount +
            "' for charge " +
            charge.charge.name +
            " is invalid. Cannot be below 0";
          if (
            !isNullUndefined(charge.charge) &&
            !isEmptyString(charge.charge.name)
          ) {
            message =
              "Amount for charge " + charge.charge.name + " cannot be below 0";
          }
          issueWarnMessage(message);
          return false;
        }
      }
    }
    setSelectedMeterBilling(sendData);
    return true;
  }

  function saveLease() {
    let obj = selectedLease;
    obj.leaseCharges = chargeItems;
    setSelectedLease(obj);

    if (selectedLease.leaseCharges.length < 1) {
      issueWarnMessage(
        "You must add at least one lease charge to save a lease."
      );
      return;
    }

    let incrementPeriod = tryParseInt(selectedLease.incrementPeriod, -1);
    if (incrementPeriod > MAX_INCREMENT_PERIOD) {
      issueWarnMessage(
        "Increment period is currently limited to a maximum of " +
          MAX_INCREMENT_PERIOD +
          " years. Reduce the increment period value"
      );
      return;
    }

    let sendData = selectedLease;
    sendData.incrementType = incrementType; // increment type is set as first level key in state, so need to override params of the selected lease object to send
    //console.log("sendData",sendData)
    if (validateLease(sendData)) {
      let unitStatusTmp = [...unitStatuses];
      let message = "Lease added successfully";
      if (task === "Create") {
        setLeases((leases) => [...leases, sendData]);
      }
      if (task === "Edit") {
        setLeases((leases) => [
          ...leases.filter((lease, i) => lease.id !== sendData.id),
          sendData,
        ]);
        message = "Lease updated successfully";
      }

      for (let charge of sendData.leaseCharges) {
        let unitStatus = {
          id: getIdRefNumber(),
          unitId: charge.unitId,
          effectiveDate: new Date(),
          statusCd: "LSD",
          expirationDate: null,
        };
        unitStatusTmp = unitStatusTmp.filter(
          (status, i) => status.unitId !== charge.unitId
        );
        unitStatusTmp.push(unitStatus);
      }

      setUnitStatuses(unitStatusTmp);
      handleAddLeaseClose();
      setRentDay("");
      setRentDate("");
      issueSuccessMessage(message);
    }
  }

  const handleAddOwnershipClose = () => {
    setSelectedOwnership(null);
    setTenantOccupied(false);
    setChargeItemsTotal(0);
    setChargeItems([]);
    setHomeOwners([]);
    setSelectedUnit("");
    setStartDate(null);
    setEndDate(null);
    setBalance(0);
    setBalanceNotes("");
    setTask("");
  };

  function handleEndDateChange(value) {
    let date = new Date(value);
    let obj = selectedOwnership;
    obj.endDate = date;
    setEndDate(date);
    setSelectedOwnership(obj);
  }

  function handleOwnershipChargeItemAdd() {
    let charge = {
      id: getIdRefNumber(),
      amount: "",
      charge: { code: "", name: "" },
      chargeCode: "",
      taxable: false,
      invoiceDate: "",
      billingPeriod: "",
      invoiceDay: "",
      unitId: "",
    };
    setChargeItems((chargeItems) => [...chargeItems, charge]);
  }

  function handleTenantSwitchToggle(event) {
    let obj = selectedOwnership;
    obj.tenantOccupied = event.target.checked;
    setSelectedOwnership(obj);
    setTenantOccupied(event.target.checked);
  }

  function handleBalanceChange(event) {
    let value = event.target.value;
    let obj = selectedOwnership;
    obj.balance = value;
    setSelectedOwnership(obj);
    setBalance(value);
  }

  function handleBalanceNotesChange(event) {
    let value = event.target.value;
    let obj = selectedOwnership;
    obj.balanceNotes = value;
    setSelectedOwnership(obj);
    setBalanceNotes(value);
  }

  function saveOwnership() {
    let obj = selectedOwnership;
    obj.ownershipCharges = chargeItems;
    obj.titleOwners = [...homeOwners];
    obj.serviceChargeBalance = balance;
    obj.serviceChargeBalanceMemo = balanceNotes;
    obj.owner = homeOwners[invoicedOwnerIndex];
    setSelectedOwnership(obj);

    if (validateSaveOwnershipForm()) {
      let sendData = selectedOwnership;
      let unitStatusTmp = [...unitStatuses];
      let message = "Ownership added successfully";
      if (task === "Create") {
        setOwnerships((ownerships) => [...ownerships, sendData]);
      }
      if (task === "Edit") {
        setOwnerships((ownerships) => [
          ...ownerships.filter((ownership) => ownership.id !== sendData.id),
          sendData,
        ]);
        message = "Ownership updated successfully";
      }
      for (let charge of sendData.ownershipCharges) {
        let unitStatus = {
          id: getIdRefNumber(),
          unitId: sendData.unitId,
          effectiveDate: new Date(),
          statusCd: "OWD",
          expirationDate: null,
        };
        unitStatusTmp = unitStatusTmp.filter(
          (status) => status.unitId !== sendData.unitId
        );
        unitStatusTmp.push(unitStatus);
      }
      setUnitStatuses(() => unitStatusTmp);
      handleAddOwnershipClose();
      setRentDay("");
      setRentDate("");
      issueSuccessMessage(message);
    }
  }

  function validateSaveOwnershipForm() {
    //console.log("validateSaveOwnershipForm selectedOwnership",selectedOwnership)
    let sendData = selectedOwnership;
    if (isNullUndefined(sendData)) {
      issueWarnMessage("Could not determine ownership to save.");
      return false;
    }

    let balance = tryParseFloat(
      sendData.serviceChargeBalance.toString(),
      "error"
    );
    if (balance === "error") {
      issueWarnMessage(
        "Incoming balance should be a value and not '" +
          sendData.serviceChargeBalance.toString() +
          "'"
      );
      return false;
    }
    sendData.serviceChargeBalance = balance;
    let titleStartDate = new Date(sendData.startDate);
    if (!isValidDate(titleStartDate)) {
      issueWarnMessage("Ownership start date is not a valid date!!");
      return false;
    }

    if (isNullUndefined(sendData.unitId)) {
      issueWarnMessage("Ownership unit must be selected!!");
      return false;
    }

    if (!isNullUndefined(sendData.endDate) && isValidDate(sendData.endDate)) {
      if (sendData.endDate <= getDayStart(new Date())) {
        issueWarnMessage("Ownership end date cannot be before today.");
        return false;
      }
      if (sendData.endDate <= sendData.startDate) {
        issueWarnMessage(
          "Ownership end date cannot be before the ownership start date."
        );
        return false;
      }
      sendData.endDate = adjustForTimezone(selectedOwnership.endDate);
    }

    if (
      tenantOccupied &&
      (isNullUndefined(sendData.tenant) ||
        isNullUndefined(sendData.tenant.name) ||
        isEmptyString(sendData.tenant.name))
    ) {
      issueWarnMessage("If ownership have a tenant, it must be selected.");
      return false;
    }

    if (sendData.ownershipCharges.length < 1) {
      issueWarnMessage(
        "You must add at least one ownership charge to save an ownership."
      );
      return false;
    }
    if (isArray(sendData.ownershipCharges)) {
      let periodicCharge = 0;
      let taxableText = "No";
      for (let charge of sendData.ownershipCharges) {
        if (
          isEmptyString(charge.charge.code) ||
          isEmptyString(charge.charge.name)
        ) {
          issueWarnMessage(
            "Could not determine the charge type for one of the ownership charges."
          );
          return false;
        }
        let amount = tryParseFloat(charge.amount.toString(), -1);
        if (amount < 0) {
          let message =
            "Amount '" +
            charge.amount +
            "' for charge " +
            charge.charge.name +
            " is invalid. Cannot be below 0";
          if (
            !isNullUndefined(charge.charge) &&
            !isEmptyString(charge.charge.name)
          ) {
            message =
              "Amount for charge " + charge.charge.name + " cannot be below 0";
          }
          issueWarnMessage(message);
          return false;
        }
        if (charge.charge.code === "SERCH") {
          periodicCharge += amount;
        }

        if (charge.taxable) {
          taxableText = "Yes";
        }

        if (isNullUndefined(charge.billingPeriod)) {
          issueWarnMessage(
            "Could not determine the billing period for charge: " +
              charge.charge.name
          );
          return false;
        }

        if (
          (charge.billingPeriod === "WEEK" ||
            charge.billingPeriod === "MNTH") &&
          (charge.invoiceDay === 0 || isNaN(charge.invoiceDay))
        ) {
          issueWarnMessage(
            "Could not determine the invoice day for charge: " +
              charge.charge.name
          );
          return false;
        }
        charge.invoiceDay = parseInt(charge.invoiceDay);
        if (
          (charge.billingPeriod === "QT" || charge.billingPeriod === "ANU") &&
          (!isValidDate(new Date(charge.invoiceDate)) ||
            isNullUndefined(charge.invoiceDate))
        ) {
          issueWarnMessage(
            "Could not determine the ownership invoice date for charge: " +
              charge.charge.name
          );
          return false;
        }
      }
      sendData.periodicCharge = periodicCharge;
      sendData.taxableText = taxableText;
    }
    let invoicedOwner = sendData.titleOwners.filter(function (titleOwner) {
      return titleOwner.invoiced;
    });
    if (invoicedOwner.length > 1) {
      issueWarnMessage("Ownership cannot have more than one invoiced owner.");
      return false;
    } else if (invoicedOwner.length <= 0) {
      issueWarnMessage("Ownership must have an invoiced owner.");
      return false;
    }

    let unitName = "";
    let unit = units.filter((u) => u.id === sendData.unitId);
    if (unit.length > 0) {
      unitName = unit[0].name;
      sendData.unitName = unitName;
    }

    let ownerNameArray = [];
    for (let owner of sendData.titleOwners) {
      ownerNameArray.push(owner.name);
    }
    ownerNameArray = [...new Set(ownerNameArray)];
    let ownerNames = "";
    if (ownerNameArray.length > 1) {
      ownerNames = ownerNameArray.join(",");
    } else {
      ownerNames = ownerNameArray[0];
    }
    sendData.isTenantOccupied = tenantOccupied;
    if (
      sendData.isTenantOccupied &&
      !isNullUndefined(sendData.tenant) &&
      !isNullUndefined(sendData.tenant.name)
    ) {
      ownerNames = ownerNames + "/" + sendData.tenant.name;
    }
    sendData.ownerNames = ownerNames;

    setSelectedOwnership(sendData);
    return true;
  }

  function validateLease(sendData) {
    //console.log("validateLease sendData: ", sendData)
    // Validate
    if (isNullUndefined(sendData)) {
      issueWarnMessage("Could not determine lease.");
      return false;
    }
    if (isNullUndefined(sendData.billingPeriod)) {
      issueWarnMessage("Could not determine lease rent frequency.");
      return false;
    }
    if (
      (sendData.billingPeriod === "WEEK" ||
        sendData.billingPeriod === "MNTH") &&
      (isNullUndefined(sendData.rentDay) || isNaN(sendData.rentDay))
    ) {
      issueWarnMessage("Could not determine lease rent day.");
      return false;
    }
    sendData.rentDay = parseInt(sendData.rentDay);
    if (sendData.billingPeriod === "ANU") {
      let rentDate = new Date(sendData.rentDate);
      if (!isValidDate(rentDate)) {
        issueWarnMessage("Could not determine lease rent date.");
        return false;
      }
    }
    if (sendData.billingPeriod === "QTR" || sendData.billingPeriod === "QT") {
      let rentDate = new Date(sendData.rentDate);
      if (!isValidDate(rentDate)) {
        issueWarnMessage("Could not determine lease rent date.");
        return false;
      }
    }
    let incrementPeriod = tryParseInt(selectedLease.incrementPeriod, -1);
    if (incrementPeriod > MAX_INCREMENT_PERIOD) {
      issueWarnMessage(
        "Increment period is currently limited to a maximum of " +
          MAX_INCREMENT_PERIOD +
          " years. Reduce the increment period value."
      );
      return false;
    }
    let rentIncrementPercentage = !isNullUndefined(
      sendData.rentIncrementPercentage
    )
      ? tryParseFloat(sendData.rentIncrementPercentage.toString(), -1)
      : -1;
    let rentIncrementAmount = !isNullUndefined(sendData.rentIncrementAmount)
      ? tryParseFloat(sendData.rentIncrementAmount.toString(), -1)
      : -1;
    if (incrementPeriod > 0) {
      // when 0 means rent increment not being used
      if (
        isNullUndefined(sendData.incrementType) ||
        isEmptyString(sendData.incrementType)
      ) {
        issueWarnMessage("No increment type selected.");
        return false;
      }
      if (
        sendData.incrementType === "Percentage" &&
        (isNullUndefined(sendData.rentIncrementPercentage) ||
          isNaN(sendData.rentIncrementPercentage))
      ) {
        issueWarnMessage(
          "Rent percentage increment is required when incrementing rent by a percentage."
        );
        return false;
      }

      if (
        (rentIncrementPercentage < 0 || rentIncrementPercentage > 100) &&
        sendData.incrementType === "Percentage"
      ) {
        issueWarnMessage(
          "Increment percentage can only be between 0% and 100%."
        );
        return false;
      }
      if (
        sendData.incrementType === "Amount" &&
        (isNullUndefined(sendData.rentIncrementAmount) ||
          isNaN(sendData.rentIncrementAmount))
      ) {
        issueWarnMessage(
          "Increment rent amount is required when incrementing rent by an amount."
        );
        return false;
      }
      if (rentIncrementAmount < 0 && sendData.incrementType === "Amount") {
        issueWarnMessage("Increment amount can only be between 0% and 100%.");
        return false;
      }
    }

    sendData.rentIncrementPercentage = tryParseInt(rentIncrementPercentage, 0);
    sendData.rentIncrementAmount = tryParseInt(rentIncrementAmount, 0);

    let leaseEffective = new Date(sendData.effectiveDate);
    if (!isValidDate(leaseEffective)) {
      issueWarnMessage("Cannot proceed validating the lease start date.");
      return false;
    }
    sendData.effectiveDate = adjustForTimezone(leaseEffective);

    if (
      isNullUndefined(sendData.leaseTerms) ||
      isEmptyString(sendData.leaseTerms) ||
      sendData.leaseTerms === null
    ) {
      issueWarnMessage("Please provide a Lease Term.");
      return false;
    }
    let expirationDate = new Date(sendData.expirationDate);
    if (sendData.leaseTerms === "FXD" && !isValidDate(expirationDate)) {
      issueWarnMessage("Cannot proceed validating the lease End date.");
      return false;
    }

    if (
      !isNullUndefined(sendData.expirationDate) &&
      isValidDate(expirationDate)
    ) {
      if (expirationDate <= getDayStart(new Date())) {
        issueWarnMessage("Lease expiration date cannot be before today.");
        return false;
      }
      if (expirationDate <= sendData.effectiveDate) {
        issueWarnMessage(
          "Lease expiration date cannot be before lease start date."
        );
        return false;
      }
      sendData.expirationDate = adjustForTimezone(expirationDate);
    }

    if (isArray(sendData.leaseCharges)) {
      for (let charge of sendData.leaseCharges) {
        let id = charge.id;
        let unitId = charge.unitId;
        if (
          isNullUndefined(id) ||
          isNullUndefined(unitId) ||
          isEmptyString(id) ||
          isEmptyString(unitId)
        ) {
          if (isNullUndefined(unitId) || isEmptyString(unitId)) {
            issueWarnMessage("Unit is required and should be selected.");
          } else {
            issueWarnMessage(
              "Lease charge not initialized or id is null. Kindly reload the page."
            );
          }
          return false;
        } else {
          let amount = tryParseFloat(charge.amount.toString(), -1);
          if (amount < 1) {
            let message =
              "Amount '" + charge.amount + "' is invalid. Cannot be below 0";
            if (
              !isNullUndefined(charge.charge) &&
              !isEmptyString(charge.charge.name)
            ) {
              message =
                "Amount for charge " +
                charge.charge.name +
                " cannot be below 0";
            }
            issueWarnMessage(message);
            return false;
          }
        }
      }
    }

    let unitNameArray = [];
    for (let id of sendData.unitIds) {
      let unit = units.filter((u) => u.id === id);
      if (unit.length > 0) {
        unitNameArray.push(unit[0].name);
      }
    }
    unitNameArray = [...new Set(unitNameArray)];
    let unitNames = "";
    if (unitNameArray.length > 1) {
      unitNames = unitNameArray.join(",");
    } else {
      unitNames = unitNameArray[0];
    }
    sendData.unitNames = unitNames;

    if (sendData.tenant.id === null && sendData.tenant.name === null) {
      issueWarnMessage("Lease must have at least one tenant.");
      return false;
    }

    if (
      isNullUndefined(sendData.leaseTypeCd) ||
      isEmptyString(sendData.leaseTypeCd)
    ) {
      issueWarnMessage("Please provide a Lease Type.");
      return false;
    }

    setSelectedLease(sendData);
    return true;
  }

  function handleLeaseChargeItemAdd() {
    let charge = {
      id: getIdRefNumber(),
      unitId: "",
      amount: "",
      charge: { code: "", name: "" },
      taxable: false,
      effectiveDate: new Date(),
      note: "",
    };
    setChargeItems((chargeItems) => [...chargeItems, charge]);
  }

  function handleIncrementChange(event) {
    let tmp = selectedLease;
    if (incrementType === "Amount") {
      tmp.rentIncrementAmount = event.target.value;
      tmp.rentIncrementPercentage = 0;
      setRentIncrementAmount(event.target.value);
      setRentIncrementPercentage(0);
    } else {
      tmp.rentIncrementAmount = 0;
      tmp.rentIncrementPercentage = event.target.value;
      setRentIncrementAmount(0);
      setRentIncrementPercentage(event.target.value);
    }
    setSelectedLease(tmp);
  }

  function onRadioChange(radio) {
    const value = radio.target.value;
    if (radio) {
      if (value === "Percentage") {
        setIncrementType(value);
      }
      if (value === "Amount") {
        setIncrementType(value);
      }
    }
  }

  function handleIncrementRentSwitchToggle(event) {
    setIncrementRent(event.target.checked);
  }

  function handleIncrementPeriodChange(event) {
    let text = event.target.value;
    let tmp = selectedLease;
    let value = tryParseInt(text, -1);
    if (value < 0) {
      tmp.incrementPeriod = 0;
      setIncrementPeriod(0);
    } else {
      tmp.incrementPeriod = value;
      setIncrementPeriod(value);
    }
    setSelectedLease(tmp);
  }

  function handleUnitChange(event) {
    let value = event.target.value;
    if (isHOAFacility) {
      let obj = selectedOwnership;
      obj.unitId = value;
      setSelectedOwnership(obj);
      setSelectedUnit(value);
      populateHomeOwnerChargeItems();
    }
    if (isLeaseFacility) {
      let obj = selectedLease;
      obj.unitIds = value;
      setSelectedUnitIds(value);
      setSelectedLease(obj);
      let currentItems = [...chargeItems];
      let options = [];
      for (let id of value) {
        for (let unit of unitOptions) {
          if (unit.value === id) {
            options.push(unit);
          }
        }
      }

      setChargeUnitsOptions(options);
      let charges = [];
      for (let opt of options) {
        for (let itm of currentItems) {
          if (opt.value === itm.unitId) {
            charges.push(itm);
          }
        }
      }

      for (let opt of options) {
        let filteredItems = charges.filter(
          (item, i) => item.unitId === opt.value
        );
        if (filteredItems < 1) {
          let filteredUnits = units.filter((unit, i) => unit.id === opt.value);
          if (filteredUnits.length > 0) {
            let unitRent = tryParseFloat(
              filteredUnits[0].unitProperties.rent,
              0
            );
            charges.push({
              id: getIdRefNumber(),
              unit: { id: filteredUnits[0].id, name: filteredUnits[0].name },
              unitId: filteredUnits[0].id,
              amount: unitRent,
              charge: { code: "REN", name: "Rent" },
              taxable: false,
              effectiveDate: new Date(),
              note: "",
            });
          }
        }
      }
      currentItems = charges;
      setChargeItems(currentItems);
    }
  }

  function populateHomeOwnerChargeItems() {
    let rows = "";
    let total = 0;
    chargeItems &&
      chargeItems.length > 0 &&
      (rows = chargeItems.map(function (charge, i) {
        let amount = tryParseFloat(charge.amount, -1);
        if (amount > 0) {
          total = total + amount;
        }
        return (
          <TableRow key={i}>
            {task !== "BulkEdit" && (
              <TableCell>
                {task !== "Edit" && (
                  <Select
                    sx={{ width: "90%" }}
                    id={"unit"}
                    value={selectedUnit}
                    disabled={true}
                    onChange={(event) => handleUnitChange(event)}
                  >
                    {unitOptions && unitOptions.length > 0 ? (
                      unitOptions.map(function (unit, i) {
                        return (
                          <MenuItem key={unit.value} value={unit.value}>
                            {unit.label}
                          </MenuItem>
                        );
                      }, this)
                    ) : (
                      <MenuItem sx={{ width: "100%" }}>
                        No Results Found
                      </MenuItem>
                    )}
                  </Select>
                )}
                {task === "Edit" && selectedOwnership.unit.name}
              </TableCell>
            )}
            <TableCell>
              <Select
                sx={{ width: "90%" }}
                id={"chargeCode" + i}
                value={charge.charge.code}
                onChange={(event) => handleChargeCodeChange(event, i)}
              >
                {ownershipChargeCodes && ownershipChargeCodes.length > 0 ? (
                  ownershipChargeCodes.map(function (chargeCode, i) {
                    let disabled = false;
                    for (let chargeValue of chargeItems) {
                      if (chargeValue.charge.code === chargeCode.value) {
                        disabled = true;
                      }
                    }
                    return (
                      <MenuItem
                        key={chargeCode.value}
                        disabled={disabled}
                        value={chargeCode.value}
                      >
                        {chargeCode.label}
                      </MenuItem>
                    );
                  }, this)
                ) : (
                  <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
                )}
              </Select>
            </TableCell>
            <TableCell>
              <TextField
                sx={{ width: "90%" }}
                id={"chargeAmount" + i}
                name={"chargeAmount" + i}
                isrequired={true}
                disabled={false}
                value={charge.amount}
                onChange={(event) => ownershipChargeAmountChange(event, i)}
              />
            </TableCell>
            <TableCell>
              <Select
                sx={{ width: "90%" }}
                id={"billingPeriodCode"}
                value={charge.billingPeriod}
                onChange={(event) => handleChargeBillingPeriodChange(event, i)}
              >
                {billingPeriods && billingPeriods.length > 0 ? (
                  billingPeriods.map(function (billingPeriod, i) {
                    return (
                      <MenuItem
                        key={billingPeriod.value}
                        value={billingPeriod.value}
                      >
                        {billingPeriod.label}
                      </MenuItem>
                    );
                  }, this)
                ) : (
                  <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
                )}
              </Select>
            </TableCell>
            <TableCell>
              {charge.billingPeriod === "MNTH" && (
                <Select
                  sx={{ width: "90%" }}
                  id={"invoiceDay" + i}
                  value={charge.invoiceDay}
                  onChange={(event) => handleInvoiceDayChange(event, i)}
                >
                  {rentDays && rentDays.length > 0 ? (
                    rentDays.map(function (day, i) {
                      return (
                        <MenuItem key={day.value} value={day.value}>
                          {day.label}
                        </MenuItem>
                      );
                    }, this)
                  ) : (
                    <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
                  )}
                </Select>
              )}
              {(charge.billingPeriod === "QT" ||
                charge.billingPeriod === "ANU") && (
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale="en-gb"
                >
                  <DatePicker
                    sx={{ width: "90%" }}
                    id={"invoiceDate"}
                    value={dayjs(charge.invoiceDate)}
                    isrequired={true}
                    disabled={false}
                    onChange={(newDate) => handleInvoiceDateChange(newDate, i)}
                  />
                </LocalizationProvider>
              )}
            </TableCell>
            <TableCell>
              {
                <Switch
                  checked={charge.taxable}
                  onChange={(event) => handleChargeTaxableToggle(event, i)}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
            </TableCell>
            <TableCell>
              <Button
                variant="contained"
                type="submit"
                sx={{ marginLeft: "10px" }}
                onClick={(event) => handleHomeOwnerChargeItemRemove(i)}
                color="error"
              >
                <Cancel />
              </Button>
            </TableCell>
          </TableRow>
        );
      }, this));
    setOwnershipChargesTableRows(rows);
    setChargeItemsTotal(total);
  }

  function handleMeterChange(event) {
    let value = event.target.value;
    let obj = selectedMeterBilling;
    obj.meterIds = value;
    setSelectedMeterIds(value);
    setSelectedMeterBilling(obj);

    let currentItems = [...chargeItems];
    let options = [];
    for (let id of value) {
      for (let meter of meterOptions) {
        if (meter.value === id) {
          options.push(meter);
        }
      }
    }

    setChargeMetersOptions(options);
    let charges = [];
    for (let opt of options) {
      for (let itm of currentItems) {
        if (opt.value === itm.meterId) {
          charges.push(itm);
        }
      }
    }

    for (let opt of options) {
      let filteredItems = charges.filter(
        (item, i) => item.meterId === opt.value
      );
      if (filteredItems < 1) {
        let filteredMeters = utilities.filter(
          (utility, i) => utility.id === opt.value
        );
        if (filteredMeters.length > 0) {
          let utilityStandingCharge = tryParseFloat(
            filteredMeters[0].standingCharge,
            0
          );
          charges.push({
            id: getIdRefNumber(),
            meterId: filteredMeters[0].id,
            amount: utilityStandingCharge,
            charge: { code: "UTSCH", name: "Utility - Standing Charge" },
            statusCd: "ACT",
            taxable: false,
            effectiveDate: new Date(),
            description: "",
          });
        }
      }
    }
    currentItems = charges;
    setChargeItems(currentItems);
  }

  function populateUnitIds() {
    return (
      <Select
        labelId="demo-multiple-chip-label"
        multiple
        label="Units(s)"
        sx={{ width: { xs: "100%", lg: "80%" } }}
        id={"units"}
        isrequired={true}
        value={selectedUnitIds}
        onChange={(event) => handleUnitChange(event)}
      >
        {unitOptions && unitOptions.length > 0 ? (
          unitOptions.map(function (unit, i) {
            return (
              <MenuItem key={unit.value} value={unit.value}>
                {unit.label}
              </MenuItem>
            );
          }, this)
        ) : (
          <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
        )}
      </Select>
    );
  }

  function populateMeterIds() {
    return (
      <Select
        labelId="demo-multiple-chip-label"
        multiple
        sx={{ width: { xs: "100%", lg: "80%" } }}
        id={"meters"}
        isrequired={true}
        value={selectedMeterIds}
        onChange={(event) => handleMeterChange(event)}
      >
        {meterOptions && meterOptions.length > 0 ? (
          meterOptions.map(function (utility, i) {
            return (
              <MenuItem key={utility.value} value={utility.value}>
                {utility.label}
              </MenuItem>
            );
          }, this)
        ) : (
          <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
        )}

        {meterOptions && meterOptions.length < 1 && (
          <MenuItem key={"Nothing to show"} value={""} disabled>
            No meter is available to select
          </MenuItem>
        )}
      </Select>
    );
  }

  function handleStartDateChange(value) {
    let date = new Date(value);
    setStartDate(date);
    if (isLeaseFacility) {
      let obj = selectedLease;
      obj.effectiveDate = date;
      setSelectedLease(obj);
    }
    if (isMeterBillingFacility) {
      let obj = selectedMeterBilling;
      obj.effectiveDate = date;
      setSelectedMeterBilling(obj);
    }

    if (isHOAFacility) {
      let obj = selectedOwnership;
      obj.startDate = date;
      setSelectedOwnership(obj);
    }
  }

  function handleExpirationDateChange(value) {
    let date = new Date(value);
    let obj = selectedLease;
    obj.expirationDate = date;
    setExpirationDate(date);
    setSelectedLease(obj);
  }

  function leaseTermChanged(event) {
    let value = event.target.value;
    let obj = selectedLease;
    obj.leaseTerms = value;
    if (obj.leaseTerms === "WILL") {
      obj.expirationDate = null;
    }
    setSelectedLease(obj);
    setLeaseTerm(value);
  }

  function leaseTypeChanged(event) {
    let value = event.target.value;
    let obj = selectedLease;
    obj.leaseTypeCd = value;
    setSelectedLease(obj);
    setLeaseTypeCd(value);
  }

  function handleRentPeriodChange(event) {
    let value = event.target.value;
    if (isLeaseFacility) {
      let obj = selectedLease;
      obj.billingPeriod = value;
      setSelectedLease(obj);
    }
    if (isMeterBillingFacility) {
      let obj = selectedMeterBilling;
      obj.billingFrequency = value;
      setSelectedMeterBilling(obj);
    }
    setRentDay("");
    setRentDate("");
    setRentPeriodCode(value);
  }

  function handleRentDayChange(event) {
    let value = event.target.value;
    if (isLeaseFacility) {
      let obj = selectedLease;
      obj.rentDay = value;
      obj.rentDate = "";
      setSelectedLease(obj);
    }
    if (isMeterBillingFacility) {
      let obj = selectedMeterBilling;
      obj.billingDay = value;
      obj.billingDate = "";
      setSelectedMeterBilling(obj);
    }
    setRentDay(value);
    setRentDate("");
  }

  function handleNextRentDateChange(value) {
    let date = new Date(value);
    if (isLeaseFacility) {
      let obj = selectedLease;
      obj.rentDate = date;
      obj.rentDay = "";
      setSelectedLease(obj);
    }
    if (isMeterBillingFacility) {
      let obj = selectedMeterBilling;
      obj.billingDate = date;
      obj.billingDay = "";
      setSelectedMeterBilling(obj);
    }
    setRentDate(date);
    setRentDay("");
  }

  function saveAndFinishLater() {
    let signUp;
    const buttonIndex = "4.1";
    signUp = props.signUp;
    signUp.buttonIndex = buttonIndex;
    if (isLeaseFacility) {
      signUp.signUpJson.leases = leases;
      signUp.signUpJson.unitStatuses = unitStatuses;
    }
    if (isHOAFacility) {
      signUp.signUpJson.ownerships = ownerships;
      signUp.signUpJson.unitStatuses = unitStatuses;
    }
    if (isMeterBillingFacility) {
      signUp.signUpJson.meterBillings = meterBillings;
    }
    if (isBulkWaterFacility) {
      signUp.signUpJson.customers = customers;
    }
    if (isNullUndefined(signUp)) {
      issueWarnMessage(`Please fill in the form.`);
    } else {
      if (validateSaveAndFinishLater(signUp)) {
        props.saveSignUpData(signUp);
      }
    }
  }

  function validateSaveAndFinishLater(signUp) {
    // Validate
    if (isNullUndefined(signUp)) {
      issueWarnMessage("Could not determine signUp.");
      return false;
    }

    if (isNullUndefined(signUp.signUpJson.facility)) {
      issueWarnMessage("Could not determine facility.");
      return false;
    }

    if (isLeaseFacility) {
      if (
        isNullUndefined(signUp.signUpJson.leases) ||
        !isArray(signUp.signUpJson.leases) ||
        isEmptyArray(signUp.signUpJson.leases)
      ) {
        issueWarnMessage("Could not determine leases.");
        return false;
      }

      if (
        isArray(signUp.signUpJson.leases) &&
        signUp.signUpJson.leases.length < 1
      ) {
        issueWarnMessage("There should be at least one lease.");
        return false;
      }

      if (
        isNullUndefined(signUp.signUpJson.facility.units) ||
        !isArray(signUp.signUpJson.facility.units) ||
        isEmptyArray(signUp.signUpJson.facility.units)
      ) {
        issueWarnMessage("Could not determine units.");
        return false;
      }

      if (
        isArray(signUp.signUpJson.facility.units) &&
        signUp.signUpJson.facility.units.length < 1
      ) {
        issueWarnMessage("There should be at least one unit.");
        return false;
      }

      if (
        isNullUndefined(signUp.signUpJson.unitStatuses) ||
        !isArray(signUp.signUpJson.unitStatuses) ||
        isEmptyArray(signUp.signUpJson.unitStatuses)
      ) {
        issueWarnMessage("Could not determine unitStatuses.");
        return false;
      }

      if (
        isArray(signUp.signUpJson.unitStatuses) &&
        signUp.signUpJson.utilities.unitStatuses < 1
      ) {
        issueWarnMessage("There should be at least one unitStatuses");
        return false;
      }

      if (isArray(signUp.signUpJson.leases)) {
        for (let lease of signUp.signUpJson.leases) {
          if (lease.leaseCharges.length < 1) {
            let message = "There is lease without charges.";
            issueWarnMessage(message);
            return false;
          }
        }
      }
    }

    if (isMeterBillingFacility) {
      if (
        isNullUndefined(signUp.signUpJson.meterBillings) ||
        !isArray(signUp.signUpJson.meterBillings) ||
        isEmptyArray(signUp.signUpJson.meterBillings)
      ) {
        issueWarnMessage("Could not determine meter billings.");
        return false;
      }

      if (
        isArray(signUp.signUpJson.meterBillings) &&
        signUp.signUpJson.meterBillings.length < 1
      ) {
        issueWarnMessage("There should be at least one meter billing.");
        return false;
      }

      if (
        isNullUndefined(signUp.signUpJson.utilities) ||
        !isArray(signUp.signUpJson.utilities) ||
        isEmptyArray(signUp.signUpJson.utilities)
      ) {
        issueWarnMessage("Could not determine meter.");
        return false;
      }

      if (
        isArray(signUp.signUpJson.utilities) &&
        signUp.signUpJson.utilities.length < 1
      ) {
        issueWarnMessage("There should be at least one meter.");
        return false;
      }

      if (isArray(signUp.signUpJson.meterBillings)) {
        for (let meterBilling of signUp.signUpJson.meterBillings) {
          if (meterBilling.billingChargeItems.length < 1) {
            let message =
              "There is meter billing without charges. You can edit to add billing charges or delete it.";
            issueWarnMessage(message);
            return false;
          }
        }
      }
    }

    if (isHOAFacility) {
      if (
        isNullUndefined(signUp.signUpJson.ownerships) ||
        !isArray(signUp.signUpJson.ownerships) ||
        isEmptyArray(signUp.signUpJson.ownerships)
      ) {
        issueWarnMessage("Could not determine ownerships.");
        return false;
      }

      if (
        isArray(signUp.signUpJson.ownerships) &&
        signUp.signUpJson.ownerships.length < 1
      ) {
        issueWarnMessage("There should be at least one ownership.");
        return false;
      }

      if (
        isNullUndefined(signUp.signUpJson.facility.units) ||
        !isArray(signUp.signUpJson.facility.units) ||
        isEmptyArray(signUp.signUpJson.facility.units)
      ) {
        issueWarnMessage("Could not determine units.");
        return false;
      }

      if (
        isArray(signUp.signUpJson.facility.units) &&
        signUp.signUpJson.facility.units.length < 1
      ) {
        issueWarnMessage("There should be at least one unit.");
        return false;
      }

      if (
        isNullUndefined(signUp.signUpJson.unitStatuses) ||
        !isArray(signUp.signUpJson.unitStatuses) ||
        isEmptyArray(signUp.signUpJson.unitStatuses)
      ) {
        issueWarnMessage("Could not determine unitStatuses.");
        return false;
      }

      if (
        isArray(signUp.signUpJson.unitStatuses) &&
        signUp.signUpJson.utilities.unitStatuses < 1
      ) {
        issueWarnMessage("There should be at least one unitStatuses.");
        return false;
      }

      if (isArray(signUp.signUpJson.ownerships)) {
        for (let ownership of signUp.signUpJson.ownerships) {
          if (ownership.ownershipCharges.length < 1) {
            let message = "There is ownership without charges.";
            issueWarnMessage(message);
            return false;
          }
        }
      }
    }

    if (isBulkWaterFacility) {
      if (
        isNullUndefined(signUp.signUpJson.customers) ||
        !isArray(signUp.signUpJson.customers) ||
        isEmptyArray(signUp.signUpJson.customers)
      ) {
        issueWarnMessage("Could not determine customers.");
        return false;
      }

      if (
        isArray(signUp.signUpJson.customers) &&
        signUp.signUpJson.customers.length < 1
      ) {
        issueWarnMessage("There should be at least one customer.");
        return false;
      }

      if (
        isNullUndefined(signUp.signUpJson.utilities) ||
        !isArray(signUp.signUpJson.utilities) ||
        isEmptyArray(signUp.signUpJson.utilities)
      ) {
        issueWarnMessage("Could not determine meter.");
        return false;
      }

      if (
        isArray(signUp.signUpJson.utilities) &&
        signUp.signUpJson.utilities.length < 1
      ) {
        issueWarnMessage("There should be at least one meter.");
        return false;
      }

      if (isArray(signUp.signUpJson.customers)) {
        for (let customer of signUp.signUpJson.customers) {
          if (customer.absoluteCreditLimit < 0) {
            let message =
              "There is customer without credit limit. You can edit to add credit limit or delete it.";
            issueWarnMessage(message);
            return false;
          }
        }
      }
    }

    return true;
  }

  function validateSaveAndContinue(signUp) {
    // Validate
    if (validateSaveAndFinishLater(signUp)) {
      if (isLeaseFacility) {
        if (
          signUp.signUpJson.unitStatuses.length <
          signUp.signUpJson.leases.length
        ) {
          issueWarnMessage("There should be leased unitStatuses.");
          return false;
        }
      }
      if (isHOAFacility) {
        if (
          signUp.signUpJson.unitStatuses.length <
          signUp.signUpJson.ownerships.length
        ) {
          issueWarnMessage("There should be owned unitStatuses.");
          return false;
        }

        if (isArray(signUp.signUpJson.ownerships)) {
          for (let ownership of signUp.signUpJson.ownerships) {
            if (
              !isNullUndefined(ownership.titleOwners) &&
              isArray(ownership.titleOwners)
            ) {
              let hasInvoicedOwner = false;
              for (let owner of ownership.titleOwners) {
                if (owner.invoiced) {
                  hasInvoicedOwner = true;
                }
              }
              if (!hasInvoicedOwner) {
                let message =
                  "There is no invoiced home owner on this ownership, billing code: " +
                  ownership.billingCode;
                issueWarnMessage(message);
                return false;
              }
            } else {
              let message =
                "There is no home owners on this ownership, billing code: " +
                ownership.billingCode;
              issueWarnMessage(message);
              return false;
            }
          }
        }
      }
      return true;
    }
  }

  function saveAndContinue() {
    let signUp;
    const buttonIndex = "4.2";
    signUp = props.signUp;
    signUp.buttonIndex = buttonIndex;
    if (isLeaseFacility) {
      signUp.signUpJson.leases = leases;
      signUp.signUpJson.unitStatuses = unitStatuses;
    }
    if (isHOAFacility) {
      signUp.signUpJson.ownerships = ownerships;
      signUp.signUpJson.unitStatuses = unitStatuses;
    }
    if (isMeterBillingFacility) {
      signUp.signUpJson.meterBillings = meterBillings;
    }
    if (isBulkWaterFacility) {
      signUp.signUpJson.customers = customers;
    }
    if (isNullUndefined(signUp)) {
      issueWarnMessage(`Please fill in the form.`);
    } else {
      if (validateSaveAndContinue(signUp)) {
        props.saveSignUpData(signUp);
      }
    }
  }

  function handleAddLeaseClick() {
    let refNumber = getLeaseRefNumber();
    let data = {
      id: getIdRefNumber(),
      effectiveDate: "",
      expirationDate: null,
      tenant: {
        id: null,
        name: null,
      },
      facilityId: facilityId,
      unitIds: [],
      statusCd: "DRF",
      billingCode: refNumber,
      leaseTypeCd: null,
      securityDeposit: "0.00",
      rentBalance: "0.00",
      utilityDeposit: "0.00",
      rentBalanceMemo: "",
      leaseCharges: [],
      incrementPeriod: 0, // default to increment after 1 year
    };
    setSelectedLease(data);
    setTask("Create");
  }

  function handleAddOwnershipClick() {
    let refNumber = getTitleRefNumber();
    let data = {
      id: getIdRefNumber(),
      titleNo: "",
      startDate: "",
      balance: 0.0,
      balanceNotes: "",
      endDate: null,
      statusCd: "ACT",
      owner: {
        id: null,
        name: null,
      },
      tenant: {
        id: null,
        name: null,
      },
      titleOwners: [],
      facilityId: facilityId,
      unitId: null,
      billingCode: refNumber,
      titleChargeItems: [],
      tenantOccupied: false,
    };
    setSelectedOwnership(data);
    setTask("Create");
  }

  function handleAddMeterBillingClick() {
    let data = {
      id: getIdRefNumber(),
      effectiveDate: new Date(),
      customer: {
        id: null,
        firstName: null,
        name: null,
      },
      statusCd: "DRF",
      billingFrequency: "",
      billingDay: "",
      billingDate: "",
      meterIds: [],
      billingCode: getBillingRefNumber(),
      billingChargeItems: [],
      facilityId: facilityId,
      meterNames: "",
      meterBillingBalanceMemo: "",
      utilityDeposit: "0.00",
      meterBillingBalance: "0.00",
      taxable: "No",
    };
    setSelectedMeterBilling(data);
    setTask("Create");
  }

  function handleAddCustomerClick() {
    setTenantModal(true);
    setIsCustomerSelect(true);
  }

  const leaseColumns = useMemo(
    () => [
      { headerName: "Billing Code", field: "billingCode", width: "150" },
      {
        headerName: "Unit(s)",
        field: "unitNames",
        width: "345",
        renderCell: renderCellExpand,
      },
      {
        headerName: "Start Date",
        field: "effectiveDate",
        width: "150",
        valueFormatter: (row) => {
          return formatDate(new Date(row.value));
        },
      },
      {
        headerName: "Rent Frequency",
        field: "billingPeriod",
        width: "195",
        valueFormatter: (row) => {
          if (row.value === "WEEK") {
            return "Weekly";
          } else if (row.value === "MNTH") {
            return "Monthly";
          } else if (row.value === "ANU") {
            return "Annually";
          } else if (row.value === "QT") {
            return "Quarterly";
          } else return "N/A";
        },
      },

      {
        headerName: "Type",
        field: "leaseTypeCd",
        width: "130",
        valueFormatter: (row) => {
          if (row.value === "COM") {
            return "Commercial";
          } else if (row.value === "RES") {
            return "Residential";
          }
        },
      },
      {
        headerName: "Tenant",
        field: "tenantName",
        width: "280",
        renderCell: renderCellExpand,
      },
      {
        headerName: "Actions",
        field: "actions",
        width: "110",
        type: "actions",
        getActions: (params) => {
          let arr = [];
          arr.push(
            <GridActionsCellItem
              icon={<Edit />}
              label="Edit"
              onClick={() => handleLeaseEditClick(params.row)}
              showInMenu
            />
          );

          arr.push(
            <GridActionsCellItem
              icon={<Clear />}
              label="Delete"
              onClick={() => handleDeleteLeaseClick(params.row)}
              showInMenu
            />
          );
          return arr;
        },
      },
    ]
    //     [],
  );

  const ownershipColumns = useMemo(
    () => [
      { headerName: "Billing Code", field: "billingCode", width: "200" },
      { headerName: "Unit", field: "unitName", width: "200" },
      { headerName: "Taxable", field: "taxableText", width: "150" },
      { headerName: "Periodic Charge", field: "periodicCharge", width: "170" },
      { headerName: "Owner(s)/Tenant", field: "ownerNames", width: "450" },
      { headerName: "Balance", field: "serviceChargeBalance", width: "150" },
      {
        headerName: "Actions",
        field: "actions",
        width: "120",
        type: "actions",
        getActions: (params) => {
          let arr = [];
          arr.push(
            <GridActionsCellItem
              icon={<Edit />}
              label="Edit"
              onClick={() => handleOwnershipEditClick(params.row)}
              showInMenu
            />
          );
          arr.push(
            <GridActionsCellItem
              icon={<Clear />}
              label="Delete"
              onClick={() => handleDeleteOwnershipClick(params.row)}
              showInMenu
            />
          );
          return arr;
        },
      },
    ]
    //     [],
  );

  const meterBillingColumns = useMemo(() => [
    { headerName: "Billing Code", field: "billingCode", width: "150" },
    { headerName: "Meter(s)", field: "meterNames", width: "350" },
    {
      headerName: "Billing Frequency",
      field: "billingFrequency",
      width: "180",
      valueFormatter: (row) => {
        if (row.value === "MNTH") {
          return "Monthly";
        } else if (row.value === "ANU") {
          return "Annually";
        } else if (row.value === "QT") {
          return "Quarterly";
        } else return "N/A";
      },
    },
    { headerName: "Taxable", field: "taxable", width: "100" },
    { headerName: "Account Holder", field: "customerName", width: "370" },
    { headerName: "Balance", field: "meterBillingBalance", width: "170" },
    {
      headerName: "Actions",
      field: "actions",
      width: "100",
      type: "actions",
      getActions: (params) => {
        let arr = [];
        arr.push(
          <GridActionsCellItem
            icon={<Edit />}
            label="Edit"
            onClick={() => handleMeterBillingEditClick(params.row)}
            showInMenu
          />
        );
        arr.push(
          <GridActionsCellItem
            icon={<Clear />}
            label="Delete"
            onClick={() => handleDeleteMeterBillingClick(params.row)}
            showInMenu
          />
        );
        return arr;
      },
    },
  ]);

  const customerColumns = useMemo(() => [
    {
      headerName: "Account Holder",
      field: "name",
      width: "450",
      renderCell: renderCellExpand,
    },
    {
      headerName: "Billing Frequency",
      field: "billingPeriod",
      width: "220",
      valueFormatter: (row) => {
        if (row.value === "MNTH") {
          return "Monthly";
        } else if (row.value === "ANU") {
          return "Annually";
        } else if (row.value === "QT") {
          return "Quarterly";
        } else return "N/A";
      },
    },
    {
      headerName: "Billing Day",
      field: "billingDay",
      width: "180",
      valueFormatter: (row) => {
        if (!isNullUndefined(row.value) && !isEmptyString(row.value)) {
          return row.value;
        } else {
          return "N/A";
        }
      },
    },
    {
      headerName: "Billing Date",
      field: "billingDate",
      width: "200",
      valueFormatter: (row) => {
        if (!isNullUndefined(row.value) && !isEmptyString(row.value)) {
          return formatDate(new Date(row.value));
        } else {
          return "N/A";
        }
      },
    },
    {
      headerName: "Credit Limit",
      field: "absoluteCreditLimit",
      width: "250",
      valueFormatter: (row) => {
        return formatCurrency(row.value);
      },
    },
    {
      headerName: "Actions",
      field: "actions",
      width: "100",
      type: "actions",
      getActions: (params) => {
        let arr = [];
        arr.push(
          <GridActionsCellItem
            icon={<Edit />}
            label="Edit"
            onClick={() => handleCustomerEditClick(params.row)}
            showInMenu
          />
        );
        arr.push(
          <GridActionsCellItem
            icon={<Clear />}
            label="Delete"
            onClick={() => handleDeleteCustomerClick(params.row)}
            showInMenu
          />
        );
        return arr;
      },
    },
  ]);

  function handleDeleteLeaseClick(selectedLease) {
    setSelectedLease(selectedLease);
    let tmp = [...leases];
    let unitStatusTmp = [...unitStatuses];
    for (let charge of selectedLease.leaseCharges) {
      let unitStatus = {
        id: getIdRefNumber(),
        unitId: charge.unitId,
        effectiveDate: new Date(),
        statusCd: "AV",
        expirationDate: null,
      };
      unitStatusTmp = unitStatusTmp.filter(
        (status, i) => status.unitId !== charge.unitId
      );
      unitStatusTmp.push(unitStatus);
    }
    tmp = tmp.filter((lease, i) => lease.id !== selectedLease.id);
    setUnitStatuses(unitStatusTmp);
    setLeases(tmp);
    issueSuccessMessage("Lease deleted.");
  }

  function handleLeaseEditClick(leaseToEdit) {
    if (!isNullUndefined(leaseToEdit.id)) {
      let unitIds = [];
      for (let charge of leaseToEdit.leaseCharges) {
        unitIds.push(charge.unitId);
      }
      unitIds = [...new Set(unitIds)];
      leaseToEdit.unitIds = unitIds;
      setSelectedUnitIds(leaseToEdit.unitIds);
      let tenant = leaseToEdit.tenant;
      let tenantName = "";
      if (tenant.type === "Individual") {
        tenantName = tenant.firstName + " " + tenant.lastName;
      } else {
        tenantName = tenant.name;
      }
      leaseToEdit.tenant.name = tenantName;
      setSelectedLease(leaseToEdit);
      setLeaseTerm(leaseToEdit.leaseTerms);
      setStartDate(new Date(leaseToEdit.effectiveDate));
      setLeaseTypeCd(leaseToEdit.leaseTypeCd);
      setRentPeriodCode(leaseToEdit.billingPeriod);
      if (!isNullUndefined(leaseToEdit.expirationDate)) {
        setExpirationDate(new Date(leaseToEdit.expirationDate));
      }
      if (!isNullUndefined(leaseToEdit.rentDate)) {
        setRentDate(new Date(leaseToEdit.rentDate));
      }
      if (!isNullUndefined(leaseToEdit.rentDay)) {
        setRentDay(leaseToEdit.rentDay);
      }
      if (
        !isNullUndefined(leaseToEdit.incrementPeriod) &&
        leaseToEdit.incrementPeriod > 0
      ) {
        setIncrementRent(true);
        setIncrementPeriod(leaseToEdit.incrementPeriod);
        setIncrementType(leaseToEdit.incrementType);
      }

      if (
        isArray(leaseToEdit.leaseCharges) &&
        leaseToEdit.leaseCharges.length > 0
      ) {
        let opt = [];
        for (let item of leaseToEdit.leaseCharges) {
          let unit = units.filter((u) => u.id === item.unitId);
          if (unit.length > 0) {
            let optExist = opt.filter((o) => o.value === unit[0].id).length > 0;
            if (!optExist) {
              opt.push({
                label: unit[0].name,
                value: unit[0].id,
              });
            }
          }
        }
        setChargeUnitsOptions(opt);
        setChargeItems(leaseToEdit.leaseCharges);
      }

      setTask("Edit");
    }
  }

  function handleDeleteMeterBillingClick(selectedMeterBilling) {
    setSelectedMeterBilling(selectedMeterBilling);
    let tmp = [...meterBillings];
    tmp = tmp.filter(
      (meterBilling, i) => meterBilling.id !== selectedMeterBilling.id
    );
    setMeterBillings(tmp);
    issueSuccessMessage("Meter Billing deleted.");
  }

  function handleDeleteCustomerClick(selectedCustomer) {
    let tmp = [...customers];
    tmp = tmp.filter((customer, i) => customer.id !== selectedCustomer.id);
    setCustomers(tmp);
    issueSuccessMessage("Customer deleted.");
  }

  function handleMeterBillingEditClick(meterBillingToEdit) {
    if (!isNullUndefined(meterBillingToEdit.id)) {
      let meterIds = [];
      for (let charge of meterBillingToEdit.billingChargeItems) {
        meterIds.push(charge.meterId);
      }
      meterIds = [...new Set(meterIds)];
      meterBillingToEdit.meterIds = meterIds;
      setSelectedMeterIds(meterBillingToEdit.meterIds);
      let customer = meterBillingToEdit.customer;
      let customerName = "";
      if (customer.type === "Individual") {
        customerName = customer.firstName + " " + customer.lastName;
      } else {
        customerName = customer.name;
      }
      meterBillingToEdit.customer.name = customerName;
      setSelectedMeterBilling(meterBillingToEdit);
      setStartDate(new Date(meterBillingToEdit.effectiveDate));
      setRentPeriodCode(meterBillingToEdit.billingFrequency);
      if (!isNullUndefined(meterBillingToEdit.expirationDate)) {
        setExpirationDate(new Date(meterBillingToEdit.expirationDate));
      }
      if (!isNullUndefined(meterBillingToEdit.billingDate)) {
        setRentDate(new Date(meterBillingToEdit.billingDate));
      }
      if (!isNullUndefined(meterBillingToEdit.billingDay)) {
        setRentDay(meterBillingToEdit.billingDay);
      }

      if (
        isArray(meterBillingToEdit.billingChargeItems) &&
        meterBillingToEdit.billingChargeItems.length > 0
      ) {
        let opt = [];
        for (let item of meterBillingToEdit.billingChargeItems) {
          let meter = utilities.filter((u) => u.id === item.meterId);
          if (meter.length > 0) {
            let optExist =
              opt.filter((o) => o.value === meter[0].id).length > 0;
            if (!optExist) {
              opt.push({
                label: meter[0].name,
                value: meter[0].id,
              });
            }
          }
        }
        setChargeMetersOptions(opt);
        setChargeItems(meterBillingToEdit.billingChargeItems);
      }

      setTask("Edit");
    }
  }

  function handleCustomerEditClick(customerToEdit) {
    if (!isNullUndefined(customerToEdit.id)) {
      setIsCustomerSelect(true);
      if (customerToEdit.type === "Individual") {
        setSelectedPerson(customerToEdit);
        setAddUserModal(true);
      } else {
        setSelectedOrganization(customerToEdit);
        setAddOrganizationModal(true);
      }
    }
  }

  function handleDeleteOwnershipClick(selectedOwnership) {
    setSelectedOwnership(selectedOwnership);
    let tmp = [...ownerships];
    let unitStatusTmp = [...unitStatuses];
    let unitStatus = {
      id: getIdRefNumber(),
      unitId: selectedOwnership.unitId,
      effectiveDate: new Date(),
      statusCd: "AV",
      expirationDate: null,
    };
    unitStatusTmp = unitStatusTmp.filter(
      (status, i) => status.unitId !== selectedOwnership.unitId
    );
    unitStatusTmp.push(unitStatus);
    tmp = tmp.filter((ownership, i) => ownership.id !== selectedOwnership.id);
    setUnitStatuses(unitStatusTmp);
    setOwnerships(tmp);
    issueSuccessMessage("Ownership deleted.");
  }

  function handleOwnershipEditClick(ownershipToEdit) {
    if (!isNullUndefined(ownershipToEdit.id)) {
      if (ownershipToEdit.isTenantOccupied) {
        let tenant = ownershipToEdit.tenant;
        let tenantName = "";
        if (tenant.type === "Individual") {
          tenantName = tenant.firstName + " " + tenant.lastName;
        } else {
          tenantName = tenant.name;
        }
        ownershipToEdit.tenant.name = tenantName;
      }
      let ownershipCharges = [];
      for (let item of ownershipToEdit.ownershipCharges) {
        let option = item;
        option.unitId = ownershipToEdit.unitId;
        ownershipCharges.push(option);
      }
      ownershipToEdit.unit = {
        name: ownershipToEdit.unitName,
        id: ownershipToEdit.unitId,
      };
      ownershipToEdit.titleChargeItems = ownershipCharges;
      ownershipToEdit.ownershipCharges = ownershipCharges;
      ownershipToEdit.endDate = null;
      if (isNullUndefined(ownershipToEdit.statusCd)) {
        ownershipToEdit.statusCd = "ACT";
      }
      if (!isNullUndefined(ownershipToEdit.endDate)) {
        setEndDate(new Date(ownershipToEdit.endDate));
        ownershipToEdit.endDate = new Date(ownershipToEdit.endDate);
      }
      setSelectedOwnership(ownershipToEdit);
      setTenantOccupied(ownershipToEdit.isTenantOccupied);
      setStartDate(new Date(ownershipToEdit.startDate));
      setSelectedUnit(ownershipToEdit.unitId);
      setHomeOwners(ownershipToEdit.titleOwners);
      setBalanceNotes(ownershipToEdit.serviceChargeBalanceMemo);
      setBalance(ownershipToEdit.serviceChargeBalance);
      if (isArray(ownershipCharges) && ownershipCharges.length > 0) {
        let opt = [];
        for (let item of ownershipCharges) {
          let unit = units.filter((u) => u.id === item.unitId);
          if (unit.length > 0) {
            let optExist = opt.filter((o) => o.value === unit[0].id).length > 0;
            if (!optExist) {
              opt.push({
                label: unit[0].name,
                value: unit[0].id,
              });
            }
          }
        }
        setChargeUnitsOptions(opt);
        setChargeItems(ownershipCharges);
      }
      setTask("Edit");
    }
  }

  const handlePersonOrgClose = () => {
    setIsTenantSelect(false);
    setTenantModal(false);
  };

  function showAddNewUserForm(idNoSearch, idTypeSearch) {
    let personData = {
      id: getIdRefNumber(),
      firstName: "",
      middleName: "",
      lastName: "",
      name: "",
      mobilePhone: "",
      officePhone: null,
      email: "",
      credentialId: null,
      statusCd: "ACT",
      dateCreated: "",
      idNumber: idNoSearch,
      idTypeCd: idTypeSearch,
      photoFile: null,
      employeeBadge: null,
    };
    setSelectedPerson(personData);
    setAddUserModal(true);
  }

  function showAddNewOrgForm(OrgName) {
    let personData = {
      id: getIdRefNumber(),
      firstName: "",
      middleName: "",
      lastName: "",
      name: "",
      mobilePhone: "",
      officePhone: null,
      email: "",
      credentialId: null,
      statusCd: "ACT",
      dateCreated: "",
      idNumber: "",
      idTypeCd: "",
      photoFile: null,
      employeeBadge: null,
    };
    let organizationData = {
      id: getIdRefNumber(),
      name: OrgName,
      description: "",
      taxId: "",
      address: {
        address1: "",
        address2: "",
        countryCd: "",
        city: "",
      },
      contact: personData,
    };
    setSelectedOrganization(organizationData);
    setAddOrganizationModal(true);
  }

  function populateHomeOwnerItems() {
    let rows = "";
    homeOwners &&
      homeOwners.length > 0 &&
      (rows = homeOwners.map(function (homeOwner, i) {
        let phone = homeOwner.mobilePhone;
        if (
          homeOwner.type === "Company" &&
          (!isNullUndefined(homeOwner.organizationContacts) ||
            !isNullUndefined(homeOwner.contact))
        ) {
          if (!isEmptyArray(homeOwner.organizationContacts)) {
            phone = homeOwner.organizationContacts[0].mobilePhone;
          }
          if (!isNullUndefined(homeOwner.contact)) {
            phone = homeOwner.contact.mobilePhone;
          }
        }
        return (
          <TableRow key={i}>
            <TableCell>{homeOwner.name}</TableCell>
            <TableCell>{homeOwner.type}</TableCell>
            <TableCell>{phone}</TableCell>
            <TableCell>
              {
                <Switch
                  checked={i === invoicedOwnerIndex}
                  onChange={(event) => handleInvoicedHomeownerToggle(event, i)}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
            </TableCell>
            <TableCell>
              <Button
                variant="contained"
                type="submit"
                sx={{ marginLeft: "10px" }}
                onClick={(event) => handleHomeOwnerItemRemove(i)}
                color="error"
              >
                <Cancel />
              </Button>
            </TableCell>
          </TableRow>
        );
      }, this));
    setHomeOwnersTableRows(rows);
  }

  function handleHomeOwnerItemRemove(index) {
    let newArr = [...homeOwners]; // copying the old items array
    if (newArr.length > 1) {
      newArr.splice(index, 1);
      let obj = selectedOwnership;
      obj.titleOwners = newArr;
      setHomeOwners(newArr);
      setSelectedOwnership(obj);
    } else {
      // NOTIFY THE MESSAGE OF NOT REMOVING ALL THE CHARGE ITEMS
      issueWarnMessage("There must be at least one home owner.");
    }
  }

  function handleInvoicedHomeownerToggle(event, index) {
    if (event.target.checked) {
      let newArr = [...homeOwners]; // copying the old items array
      let updatedItems = newArr.map(function (owner, num) {
        if (num === index) {
          owner.invoiced = !owner.invoiced;
          owner.invoicedOwnerIndex = index;
        } else {
          owner.invoiced = false;
          owner.invoicedOwnerIndex = index;
        }
        return owner;
      });
      let obj = selectedOwnership;
      obj.titleOwners = updatedItems;
      setHomeOwners(updatedItems);
      setInvoicedOwnerIndex(index);
      setSelectedOwnership(obj);
    }
  }

  function handlePersonOrgSelect(result) {
    let type = result.type;
    if (isTenantSelect) {
      if (type === "Individual") {
        let id = result.id.replace("_P", "");
        if (isHOAFacility) {
          fetchPerson(id).then((person) => {
            let obj = selectedOwnership;
            let name = person.firstName + " " + person.lastName;
            person.name = name;
            obj.tenant = person;
            obj.tenantName = name;
            obj.personId = id;
            obj.isOrganization = false;
            obj.organizationId = null;
            setSelectedOwnership(obj);
            setTenantModal(false);
            setIsTenantSelect(false);
            setSelectedPerson(null);
          });
        }
        if (isLeaseFacility) {
          fetchPerson(id).then((person) => {
            let obj = selectedLease;
            let name = person.firstName + " " + person.lastName;
            person.name = name;
            obj.tenant = person;
            obj.tenantName = name;
            obj.personId = id;
            obj.isOrganization = false;
            obj.organizationId = null;
            setSelectedLease(obj);
            setTenantModal(false);
            setIsTenantSelect(false);
            setSelectedPerson(null);
          });
        }
      }
      if (type === "Company") {
        let id = result.id.replace("_O", "");
        if (isLeaseFacility) {
          fetchOrganization(id).then((organization) => {
            let obj = selectedLease;
            obj.tenant = organization;
            obj.tenantName = organization.name;
            obj.personId = null;
            obj.isOrganization = true;
            obj.organizationId = id;
            setSelectedLease(obj);
            setTenantModal(false);
            setIsTenantSelect(false);
            setSelectedOrganization(null);
          });
        }
        if (isHOAFacility) {
          fetchOrganization(id).then((organization) => {
            let obj = selectedOwnership;
            obj.tenant = organization;
            obj.tenantName = organization.name;
            obj.personId = null;
            obj.isOrganization = true;
            obj.organizationId = id;
            setSelectedOwnership(obj);
            setTenantModal(false);
            setIsTenantSelect(false);
            setSelectedOrganization(null);
          });
        }
      }
    }

    if (isCustomerSelect && !isBulkWaterFacility) {
      if (type === "Individual") {
        let id = result.id.replace("_P", "");
        fetchPerson(id).then((person) => {
          let obj = selectedMeterBilling;
          let name = person.firstName + " " + person.lastName;
          person.name = name;
          obj.customer = person;
          obj.customerName = name;
          obj.personId = id;
          obj.isOrganization = false;
          obj.organizationId = null;
          setSelectedMeterBilling(obj);
          setTenantModal(false);
          setIsTenantSelect(false);
          setIsCustomerSelect(false);
          setSelectedPerson(null);
        });
      }
      if (type === "Company") {
        let id = result.id.replace("_O", "");
        fetchOrganization(id).then((organization) => {
          let obj = selectedMeterBilling;
          obj.customer = organization;
          obj.customerName = organization.name;
          obj.personId = null;
          obj.isOrganization = true;
          obj.organizationId = id;
          setSelectedMeterBilling(obj);
          setTenantModal(false);
          setIsTenantSelect(false);
          setIsCustomerSelect(false);
          setSelectedOrganization(null);
        });
      }
    }

    if (isCustomerSelect && isBulkWaterFacility) {
      if (type === "Individual") {
        let id = result.id.replace("_P", "");
        fetchPerson(id).then((person) => {
          person.name = person.firstName + " " + person.lastName;
          setSelectedPerson(person);
          setAddUserModal(true);
        });
      }
      if (type === "Company") {
        let id = result.id.replace("_O", "");
        fetchOrganization(id).then((organization) => {
          setSelectedOrganization(organization);
          setAddOrganizationModal(true);
        });
      }
    }

    if (isHomeOwnerSelect) {
      let invoiced = false;
      if (homeOwners.length < 1) {
        invoiced = true;
      }
      let owner = result;
      if (type === "Individual") {
        let id = result.id.replace("_P", "");
        fetchPerson(id).then((person) => {
          person.name = person.firstName + " " + person.lastName;
          person.isOrganization = false;
          person.organizationId = null;
          owner = person;
          owner.invoiced = invoiced;
          owner.invoicedOwnerIndex = invoicedOwnerIndex;
          setHomeOwners((homeOwners) => [...homeOwners, owner]);
          let obj = selectedOwnership;
          obj.titleOwners.push(owner);
          setSelectedOwnership(obj);
        });
      }
      if (type === "Company") {
        let id = result.id.replace("_O", "");
        fetchOrganization(id).then((organization) => {
          organization.personId = null;
          organization.isOrganization = true;
          organization.organizationId = id;
          owner = organization;
          owner.invoiced = invoiced;
          owner.invoicedOwnerIndex = invoicedOwnerIndex;
          setHomeOwners((homeOwners) => [...homeOwners, owner]);
          let obj = selectedOwnership;
          obj.titleOwners.push(owner);
          setSelectedOwnership(obj);
        });
      }

      setTenantModal(false);
      setIsHomeOwnerSelect(false);
      setIsTenantSelect(false);
    }
  }

  function handleSaveUser(person) {
    if (isLeaseFacility) {
      let obj = selectedLease;
      let name = person.firstName + " " + person.lastName;
      person.name = name;
      person.type = "Individual";
      obj.tenant = person;
      obj.tenantName = name;
      obj.personId = person.id;
      obj.isOrganization = false;
      obj.organizationId = null;
      setSelectedLease(obj);
    }
    if (isHOAFacility) {
      let obj = selectedOwnership;
      if (isHomeOwnerSelect) {
        let invoiced = false;
        if (homeOwners.length < 1) {
          invoiced = true;
        }
        person.name = person.firstName + " " + person.lastName;
        person.type = "Individual";
        let owner = person;
        owner.invoiced = invoiced;
        owner.invoicedOwnerIndex = invoicedOwnerIndex;
        setHomeOwners((homeOwners) => [...homeOwners, owner]);
        obj.titleOwners.push(owner);
        setSelectedOwnership(obj);
        setTenantModal(false);
        setIsHomeOwnerSelect(false);
        setIsTenantSelect(false);
      } else {
        let name = person.firstName + " " + person.lastName;
        person.name = name;
        person.type = "Individual";
        obj.tenant = person;
        obj.tenantName = name;
        obj.personId = person.id;
        obj.isOrganization = false;
        obj.organizationId = null;
      }
      setSelectedOwnership(obj);
    }
    if (isMeterBillingFacility) {
      let obj = selectedMeterBilling;
      let name = person.firstName + " " + person.lastName;
      person.name = name;
      person.type = "Individual";
      obj.customer = person;
      obj.customerName = name;
      obj.personId = person.id;
      obj.isOrganization = false;
      obj.organizationId = null;
      setSelectedMeterBilling(obj);
    }
    if (isBulkWaterFacility) {
      person.name = person.firstName + " " + person.lastName;
      person.type = "Individual";
      let isEdit =
        customers.filter((customer, i) => customer.id === person.id).length > 0;
      if (isEdit) {
        setCustomers((customers) => [
          ...customers.filter((customer, i) => customer.id !== person.id),
          person,
        ]);
      } else {
        setCustomers((customers) => [...customers, person]);
      }
    }
    setTenantModal(false);
    setIsTenantSelect(false);
    setIsCustomerSelect(false);
    setSelectedPerson(null);
  }

  function handleSaveOrganization(organization) {
    if (isLeaseFacility) {
      let obj = selectedLease;
      organization.type = "Company";
      obj.tenant = organization;
      obj.tenantName = organization.name;
      obj.personId = null;
      obj.isOrganization = true;
      obj.organizationId = organization.id;
      setSelectedLease(obj);
    }
    if (isHOAFacility) {
      let obj = selectedOwnership;
      organization.type = "Company";
      if (isHomeOwnerSelect) {
        let invoiced = false;
        if (homeOwners.length < 1) {
          invoiced = true;
        }
        let owner = organization;
        owner.invoiced = invoiced;
        owner.invoicedOwnerIndex = invoicedOwnerIndex;
        setHomeOwners((homeOwners) => [...homeOwners, owner]);
        obj.titleOwners.push(owner);
        setSelectedOwnership(obj);
        setTenantModal(false);
        setIsHomeOwnerSelect(false);
        setIsTenantSelect(false);
        //setIsContactSelect(false)
        //setSearchBy("")
        //setIdType("")
        //setIdNo("")
        //setCompanySearch("")
        //setFoundTenant([])
      } else {
        obj.tenant = organization;
        obj.tenantName = organization.name;
        obj.personId = null;
        obj.isOrganization = true;
        obj.organizationId = organization.id;
      }
      setSelectedOwnership(obj);
    }
    if (isMeterBillingFacility) {
      let obj = selectedMeterBilling;
      organization.type = "Company";
      obj.customer = organization;
      obj.customerName = organization.name;
      obj.personId = null;
      obj.isOrganization = true;
      obj.organizationId = organization.id;
      setSelectedMeterBilling(obj);
    }
    if (isBulkWaterFacility) {
      organization.type = "Company";
      let isEdit =
        customers.filter((customer, i) => customer.id === organization.id)
          .length > 0;
      if (isEdit) {
        setCustomers((customers) => [
          ...customers.filter((customer, i) => customer.id !== organization.id),
          organization,
        ]);
      } else {
        setCustomers((customers) => [...customers, organization]);
      }
    }
    setTenantModal(false);
    setIsTenantSelect(false);
    setIsCustomerSelect(false);
    setSelectedOrganization(null);
  }

  function handleAddUserClose() {
    setAddUserModal(false);
    setSelectedPerson(null);
    setIsCustomerSelect(false);
  }

  function handleAddOrgClose() {
    setAddOrganizationModal(false);
    setSelectedOrganization(null);
    setIsCustomerSelect(false);
  }

  return (
    <ResponsiveRow
      sx={{
        flexDirection: "column",
        width: "100%",
        flexWrap: "nowrap",
        padding: "5px",
      }}
    >
      {task === "" && (
        <TopBarContainer
          sx={{
            width: { xs: "100%", lg: "100%" },
            boxShadow: 0,
            padding: 0,
            marginY: 0,
          }}
          container
        >
          <ResponsiveRow
            sx={{
              justifyContent: { xs: "center", lg: "flex-start" },
              width: { xs: "100%", lg: "50%" },
            }}
          >
            <Typography
              sx={{ fontSize: "1.15rem", fontWeight: 600, marginTop: "5px" }}
            >
              {isLeaseFacility && "Lease Details"}
              {isHOAFacility && "Ownership Details"}
              {isMeterBillingFacility && "Meter Billing Details"}
              {isBulkWaterFacility && "Bulk Water Details"}
            </Typography>
          </ResponsiveRow>

          <ResponsiveRow
            sx={{
              justifyContent: { xs: "center", lg: "flex-end" },
              width: { xs: "100%", lg: "50%" },
            }}
            item
          >
            {isLeaseFacility && (
              <TopBarContainedBrandButton
                sx={{ width: { x: "100%", lg: "200px" } }}
                onClick={handleAddLeaseClick}
              >
                Add
              </TopBarContainedBrandButton>
            )}
            {isHOAFacility && (
              <TopBarContainedBrandButton
                sx={{ width: { x: "100%", lg: "200px" } }}
                onClick={handleAddOwnershipClick}
              >
                Add
              </TopBarContainedBrandButton>
            )}
            {isMeterBillingFacility && (
              <TopBarContainedBrandButton
                sx={{ width: { x: "100%", lg: "200px" } }}
                onClick={handleAddMeterBillingClick}
              >
                Add
              </TopBarContainedBrandButton>
            )}
            {isBulkWaterFacility && (
              <TopBarContainedBrandButton
                sx={{ width: { x: "100%", lg: "200px" } }}
                onClick={handleAddCustomerClick}
              >
                Add
              </TopBarContainedBrandButton>
            )}
          </ResponsiveRow>
        </TopBarContainer>
      )}

      {/*PERSON/ORG SEARCH MODAL*/}
      <PersonOrgSearch
        contractType={"signup"}
        showPersonOrgModal={tenantModal}
        handlePersonOrgClose={handlePersonOrgClose}
        handlePersonOrgSelect={handlePersonOrgSelect}
        showAddNewUserForm={showAddNewUserForm}
        showAddNewOrgForm={showAddNewOrgForm}
      />

      {/*ADD NEW USER MODAL*/}
      {!isNullUndefined(selectedPerson) && (
        <AddNewUser
          openModal={addUserModal}
          selectedPerson={selectedPerson}
          closeModal={handleAddUserClose}
          saveUser={handleSaveUser}
          manageUsers={false}
          customer={isCustomerSelect}
        />
      )}

      {/*ADD NEW ORGANISATION MODAL*/}
      {!isNullUndefined(selectedOrganization) && (
        <AddNewOrganisation
          selectedOrg={selectedOrganization}
          handleSave={true}
          saveOrg={handleSaveOrganization}
          openModal={addOrganizationModal}
          closeModal={handleAddOrgClose}
          customer={isCustomerSelect}
        />
      )}

      {isLeaseFacility && task === "" && (
        <ResponsiveRow
          sx={{
            justifyContent: "flex-start",
            alignItems: "center",
            height: "auto",
            width: "100%",

            boxShadow: 0,
            padding: 0,
          }}
        >
          {/* <StyledDataGrid
            rows={leases}
            columns={leaseColumns}
            loading={loading}
            disableSelectionOnClicksx={{
              boxShadow: 0,
            }}
            autoHeight
          /> */}
          <MuiDataGrid
            noBoxShadow={true}
            noSelection={true}
            dataGridColumns={leaseColumns}
            dataGridRows={leases}
            loading={loading}
            height="300px"
          />
        </ResponsiveRow>
      )}

      {isMeterBillingFacility && task === "" && (
        <ResponsiveRow
          sx={{
            justifyContent: "flex-start",
            alignItems: "center",
            height: "5px",
            width: "100%",
            padding: 0,
          }}
        >
          {/* <StyledDataGrid
            rows={meterBillings}
            columns={meterBillingColumns}
            loading={loading}
            disableSelectionOnClick
            autoHeight
            sx={{
              boxShadow: 0,
            }}
          /> */}
          <MuiDataGrid
            noBoxShadow={true}
            noSelection={true}
            dataGridColumns={meterBillingColumns}
            dataGridRows={meterBillings}
            loading={loading}
            height="300px"
          />{" "}
        </ResponsiveRow>
      )}

      {isBulkWaterFacility && task === "" && (
        <ResponsiveRow
          sx={{
            justifyContent: "flex-start",
            alignItems: "center",
            height: "auto",
            width: "100%",
          }}
        >
          {/* <StyledDataGrid
            rows={customers}
            columns={customerColumns}
            loading={loading}
            disableSelectionOnClick
            autoHeight
            sx={{
              boxShadow: 0,
            }}
          /> */}

          <MuiDataGrid
            noBoxShadow={true}
            noSelection={true}
            dataGridColumns={customerColumns}
            dataGridRows={customers}
            loading={loading}
            height="300px"
          />
        </ResponsiveRow>
      )}

      {isHOAFacility && task === "" && (
        <ResponsiveRow
          sx={{
            justifyContent: "flex-start",
            alignItems: "center",
            height: "auto",
            width: "100%",
            boxShadow: 0,
          }}
        >
          {/* <StyledDataGrid
            rows={ownerships}
            columns={ownershipColumns}
            loading={loading}
            disableSelectionOnClick
            sx={{
              boxShadow: 0,
            }}
            autoHeight
          /> */}

          <MuiDataGrid
            noBoxShadow={true}
            noSelection={true}
            dataGridColumns={ownershipColumns}
            dataGridRows={ownerships}
            loading={loading}
            height="300px"
          />
        </ResponsiveRow>
      )}

      {isLeaseFacility && (task === "Create" || task === "Edit") && (
        <ModalContainerModule
          size="md"
          accept={saveLease}
          cancel={handleAddLeaseClose}
          openModal={task === "Create" || task === "Edit"}
          modalTitle={`Lease ${selectedLease && selectedLease.billingCode}`}
          acceptButtonText={task === "Create" ? "Create" : "Update"}
        >
          <ResponsiveRow
            sx={{
              flexDirection: "column",
              flexWrap: "nowrap",

              height: "auto",
              width: "100%",

              margin: "auto",
              padding: "10px",
            }}
          >
            <Typography
              sx={{ marginBottom: "5px" }}
              id="modal-modal-title"
              variant="h5"
              component="h4"
            >
              Lease Details
            </Typography>

            <ResponsiveRow
              container
              sx={{
                justifyContent: { xs: "center", lg: "space-between" },
              }}
            >
              <FormControl
                sx={{
                  width: { xs: "100%", lg: "33.3%" },
                  height: "auto",
                  marginBottom: "10px",
                }}
              >
                <InputLabel>Lease Terms</InputLabel>
                <Select
                  label="Lease Terms"
                  sx={{ width: { xs: "100%", lg: "80%" } }}
                  id={"leaseTerms"}
                  value={leaseTerm}
                  onChange={(event) => leaseTermChanged(event)}
                >
                  {leaseTermOptions && leaseTermOptions.length > 0 ? (
                    leaseTermOptions.map(function (leaseTerm, i) {
                      return (
                        <MenuItem key={leaseTerm.value} value={leaseTerm.value}>
                          {leaseTerm.label}
                        </MenuItem>
                      );
                    }, this)
                  ) : (
                    <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
                  )}
                </Select>
              </FormControl>
              <FormControl
                item
                sx={{
                  width: { xs: "100%", lg: "33.3%" },
                  height: "auto",
                  marginBottom: "10px",
                }}
              >
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale="en-gb"
                >
                  <DatePicker
                    sx={{ width: { xs: "100%", lg: "80%" } }}
                    id={"startDate"}
                    value={dayjs(startDate)}
                    isrequired
                    disabled={task === "ActiveLeaseEdit"}
                    onChange={(newDate) => handleStartDateChange(newDate)}
                  />
                </LocalizationProvider>
              </FormControl>

              {selectedLease && selectedLease.leaseTerms === "FXD" && (
                <FormControl
                  item
                  sx={{
                    width: { xs: "100%", lg: "33.3%" },
                    height: "auto",
                    marginBottom: "10px",
                  }}
                >
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale="en-gb"
                  >
                    <DatePicker
                      sx={{ width: { xs: "100%", lg: "80%" } }}
                      id={"expirationDate"}
                      value={dayjs(expirationDate)}
                      isrequired
                      disabled={false}
                      onChange={(newDate) =>
                        handleExpirationDateChange(newDate)
                      }
                    />
                  </LocalizationProvider>
                </FormControl>
              )}

              <FormControl
                sx={{
                  width: { xs: "100%", lg: "33.3%" },
                  height: "auto",
                  marginBottom: "10px",
                }}
              >
                <InputLabel>Lease Type</InputLabel>
                <Select
                  sx={{ width: { xs: "100%", lg: "80%" } }}
                  id={"leaseType"}
                  label="Lease Type"
                  value={leaseTypeCd}
                  disabled={task === "ActiveLeaseEdit"}
                  onChange={(event) => leaseTypeChanged(event)}
                >
                  {leaseTypeOptions && leaseTypeOptions.length > 0 ? (
                    leaseTypeOptions.map(function (leaseType, i) {
                      return (
                        <MenuItem key={leaseType.value} value={leaseType.value}>
                          {leaseType.label}
                        </MenuItem>
                      );
                    }, this)
                  ) : (
                    <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
                  )}
                </Select>
              </FormControl>
              {task !== "ActiveLeaseEdit" && (
                <ResponsiveRow
                  sx={{
                    flexDirection: "row",
                    flexWrap: "nowrap",

                    justifyContent: "flex-start",

                    width: { xs: "100%", lg: "33.3%" },
                    height: "auto",

                    padding: 0,
                    marginBottom: "10px",
                  }}
                >
                  <FormControl sx={{ width: "70%", height: "auto" }} item>
                    <InputLabel>Tenant</InputLabel>
                    <OutlinedInput
                      label="Tenant"
                      sx={{ width: { xs: "100%", lg: "100%" } }}
                      id={"tenant"}
                      value={
                        selectedLease &&
                        selectedLease.tenant &&
                        selectedLease.tenant.name
                      }
                      disabled
                    />
                  </FormControl>{" "}
                  <IconButton
                    color="primary"
                    aria-label="add Tenant"
                    // title={"Add Tenant"}
                    sx={{
                      width: "40px",
                      height: "40px",
                      marginLeft: "2%",
                      marginTop: "2%",
                      backgroundColor: "#037171",

                      ":hover": {
                        backgroundColor: kodi_theme.palette.primary.dark,
                        "#icon": {
                          color: kodi_theme.palette.common.white,
                        },
                      },
                    }}
                    onClick={() => {
                      setTenantModal(true);
                      setIsTenantSelect(true);
                    }}
                  >
                    <Add sx={{ color: "#fff" }} />
                  </IconButton>
                </ResponsiveRow>
              )}
              <FormControl
                item
                sx={{
                  width: { xs: "100%", lg: "33.3%" },
                  height: "auto",
                  marginBottom: "10px",
                }}
              >
                <InputLabel>Rent Frequency</InputLabel>
                <Select
                  sx={{ width: { xs: "100%", lg: "80%" } }}
                  id={"billingPeriod"}
                  label="Rent Frequency"
                  value={rentPeriodCode}
                  onChange={(event) => handleRentPeriodChange(event)}
                >
                  {billingPeriods && billingPeriods.length > 0 ? (
                    billingPeriods.map(function (billingPeriod, i) {
                      return (
                        <MenuItem
                          key={billingPeriod.value}
                          value={billingPeriod.value}
                        >
                          {billingPeriod.label}
                        </MenuItem>
                      );
                    }, this)
                  ) : (
                    <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
                  )}
                </Select>
              </FormControl>

              {selectedLease && selectedLease.billingPeriod === "MNTH" && (
                <FormControl
                  sx={{
                    width: { xs: "100%", lg: "33.3%" },
                    height: "auto",
                    marginBottom: "10px",
                  }}
                >
                  <InputLabel>Rent Due Day</InputLabel>
                  <Select
                    sx={{ width: { xs: "100%", lg: "80%" } }}
                    id={"billingDay"}
                    value={rentDay}
                    label="Rent Due Day"
                    onChange={(event) => handleRentDayChange(event)}
                    defaultValue={[""]}
                  >
                    {rentDays && rentDays.length > 0 ? (
                      rentDays.map(function (weekDay, i) {
                        return (
                          <MenuItem key={weekDay.value} value={weekDay.value}>
                            {weekDay.label}
                          </MenuItem>
                        );
                      }, this)
                    ) : (
                      <MenuItem sx={{ width: "100%" }}>
                        No Results Found
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              )}
              {selectedLease &&
                (rentPeriodCode === "ANU" || rentPeriodCode === "QT") && (
                  <FormControl
                    sx={{
                      width: { xs: "100%", lg: "33.3%" },
                      height: "auto",
                      marginBottom: "10px",
                    }}
                  >
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale="en-gb"
                    >
                      <DatePicker
                        sx={{ width: { xs: "100%", lg: "80%" } }}
                        id={"nextRentDate"}
                        value={dayjs(rentDate)}
                        onChange={(newDate) =>
                          handleNextRentDateChange(newDate)
                        }
                      />
                    </LocalizationProvider>
                  </FormControl>
                )}

              {task !== "ActiveLeaseEdit" && (
                <FormControl
                  sx={{
                    width: { xs: "100%", lg: "33.3%" },
                    height: "auto",
                    marginBottom: "10px",
                  }}
                >
                  <InputLabel>Unit(s)</InputLabel>
                  {populateUnitIds()}
                </FormControl>
              )}
              {task === "ActiveLeaseEdit" && (
                <FormControl
                  sx={{
                    width: { xs: "100%", lg: "33.3%" },
                    height: "auto",
                    marginBottom: "10px",
                  }}
                >
                  <InputLabel>Unit(s)</InputLabel>
                  <OutlinedInput
                    sx={{ width: { xs: "100%", lg: "33.3%" } }}
                    label="Unit(s)"
                    value={selectedLease && selectedLease.unitNames}
                    disabled={true}
                  />
                </FormControl>
              )}
              <ResponsiveRow
                sx={{
                  flexDirection: {
                    xs: "column",
                    lg: "row",
                    width: "100%",
                  },
                }}
              >
                <FormControl item xs={{ width: "100%" }}>
                  <FormLabel>Increment Rent?</FormLabel>
                  <Switch
                    checked={incrementRent}
                    onChange={(event) => handleIncrementRentSwitchToggle(event)}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </FormControl>
              </ResponsiveRow>
              <ResponsiveRow
                sx={{
                  display: "flex",
                  flexDirection: "row",

                  width: "100%",
                  height: "auto",

                  padding: 0,
                }}
              >
                {incrementRent && (
                  <FormControl
                    item
                    sx={{
                      width: { xs: "100%", lg: "33.3%" },
                      height: "auto",
                      marginBottom: "10px",
                    }}
                  >
                    <InputLabel>Increment Period (years)</InputLabel>
                    <OutlinedInput
                      sx={{ width: { xs: "100%", lg: "80%" } }}
                      id={"incrementPeriod"}
                      label="Increment Period (years)"
                      value={incrementPeriod}
                      disabled={false}
                      onChange={(event) => handleIncrementPeriodChange(event)}
                    />
                  </FormControl>
                )}
                {incrementRent && (
                  <FormControl
                    item
                    sx={{ width: { xs: "100%", lg: "33.3%" }, height: "auto" }}
                  >
                    <FormLabel
                    // style={{ marginLeft: "20%" }}
                    >
                      Increment Type
                    </FormLabel>
                    <div
                      className="ui toggle checkbox"
                      style={{ marginTop: "5px" }}
                    >
                      <RadioGroup
                        row
                        sx={{ width: "100%" }}
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="use-radio-group"
                      >
                        <FormControlLabel
                          name="radio"
                          control={
                            <Radio
                              checked={incrementType === "Percentage"}
                              value="Percentage"
                              onClick={onRadioChange}
                            />
                          }
                          label="Percentage"
                        />
                        <FormControlLabel
                          name="radio"
                          control={
                            <Radio
                              checked={incrementType === "Amount"}
                              value="Amount"
                              onClick={onRadioChange}
                            />
                          }
                          label="Amount"
                        />
                      </RadioGroup>
                    </div>
                    <br />
                  </FormControl>
                )}
                {incrementRent && incrementType === "Percentage" && (
                  <FormControl
                    item
                    sx={{
                      width: { xs: "100%", lg: "33.3%" },
                      height: "auto",
                      marinBottom: "10px",
                    }}
                  >
                    <InputLabel>Rent Increment (%)</InputLabel>
                    <OutlinedInput
                      sx={{ width: { xs: "100%", lg: "80%" } }}
                      label="Rent Increment"
                      id={"incrementPercentage"}
                      value={rentIncrementPercentage}
                      disabled={false}
                      onChange={(event) => handleIncrementChange(event)}
                    />
                  </FormControl>
                )}
                {incrementRent && incrementType === "Amount" && (
                  <FormControl
                    item
                    sx={{
                      width: { xs: "100%", lg: "33.3%" },
                      height: "auto",
                      marinBottom: "10px",
                    }}
                  >
                    <InputLabel>Rent Increment Amount</InputLabel>
                    <OutlinedInput
                      sx={{ width: { xs: "100%", lg: "80%" } }}
                      id={"incrementAmount"}
                      label="Rent Increment Amount"
                      value={rentIncrementAmount}
                      disabled={false}
                      onChange={(event) => handleIncrementChange(event)}
                    />
                  </FormControl>
                )}
              </ResponsiveRow>
            </ResponsiveRow>
          </ResponsiveRow>
          <br />
          <ResponsiveRow
            sx={{
              flexDirection: "column",
              flexWrap: "nowrap",

              height: "auto",
              width: "100%",

              margin: "auto",
              padding: "10px",
            }}
          >
            <Typography
              sx={{ marginBottom: "5px" }}
              id="modal-modal-title"
              variant="h5"
              component="h4"
            >
              Lease Charges
            </Typography>
            <TableContainer>
              <Table
                sx={{ minWidth: 650, marginBottom: "10px" }}
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ width: "15%" }}>Unit</TableCell>
                    <TableCell sx={{ width: "15%" }}>Charge Type</TableCell>
                    <TableCell sx={{ width: "20%" }}>Amount</TableCell>
                    <TableCell sx={{ width: "20%" }}>Start Date</TableCell>
                    <TableCell sx={{ width: "10%" }}>Taxed?|Note</TableCell>
                    <TableCell sx={{ width: "10%" }}>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{leaseChargesTableRows}</TableBody>
                <InnerModal container>Total: {chargeItemsTotal}</InnerModal>
              </Table>
            </TableContainer>

            <Grid
              item
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              <ModalAcceptButton
                //   type="submit"
                onClick={() => handleLeaseChargeItemAdd()}
                sx={{ width: { xs: "100%", lg: "200px" } }}
              >
                Add Row
              </ModalAcceptButton>
            </Grid>
          </ResponsiveRow>
          <br />
        </ModalContainerModule>
      )}

      {isHOAFacility && (task === "Create" || task === "Edit") && (
        <CreateOwnershipModal
          task={task}
          saveOwnership={saveOwnership}
          updateOwnership={saveOwnership}
          handleAddOwnershipClose={handleAddOwnershipClose}
          handleEndDateChange={handleEndDateChange}
          handleOwnershipChargeItemAdd={handleOwnershipChargeItemAdd}
          handleStartDateChange={handleStartDateChange}
          handleTenantSwitchToggle={handleTenantSwitchToggle}
          handleUnitChange={handleUnitChange}
          handleBalanceChange={handleBalanceChange}
          handleBalanceNotesChange={handleBalanceNotesChange}
          setIsTenantSelect={setIsTenantSelect}
          setIsHomeOwnerSelect={setIsHomeOwnerSelect}
          setTenantModal={setTenantModal}
          chargeItemsTotal={chargeItemsTotal}
          endDate={endDate}
          balance={balance}
          balanceNotes={balanceNotes}
          homeOwnersTableRows={homeOwnersTableRows}
          facilityOptions={facilityOptions}
          ownershipChargesTableRows={ownershipChargesTableRows}
          selectedOwnership={selectedOwnership}
          startDate={startDate}
          selectedUnit={selectedUnit}
          tenantOccupied={tenantOccupied}
          unitOptions={unitOptions}
        />
      )}

      {isMeterBillingFacility && (task === "Create" || task === "Edit") && (
        <ModalContainerModule
          size="md"
          accept={task === "Create" ? saveMeterBilling : saveMeterBilling}
          cancel={handleAddBillingClose}
          openModal={task === "Create" || task === "Edit"}
          modalTitle={`Billing ${
            selectedMeterBilling && selectedMeterBilling.billingCode
          }`}
          acceptButtonText={task === "Create" ? "Create" : "Update"}
        >
          <ResponsiveRow
            sx={{
              flexDirection: "column",
              flexWrap: "nowrap",

              width: "100%",
              height: "auto",

              padding: "10px",
            }}
          >
            <ResponsiveRow
              sx={{
                flexDirection: "column",
                flexWrap: "nowrap",

                width: "100%",
                height: "auto",

                margin: "auto",
                padding: "10px",
              }}
            >
              <Typography
                sx={{ marginBottom: "5px" }}
                id="modal-modal-title"
                variant="h5"
                component="h4"
              >
                Billing Details
              </Typography>

              <ResponsiveRow
                container
                sx={{
                  justifyContent: { xs: "center", lg: "space-between" },
                }}
              >
                {task === "Create" ? (
                  <FormControl
                    item
                    sx={{
                      width: { xs: "100%", lg: "33.3%" },
                      height: "auto",
                      marginBottom: "10px",
                    }}
                  >
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale="en-gb"
                    >
                      <DatePicker
                        sx={{ width: { xs: "100%", md: "90%" } }}
                        label="Start date"
                        id={"effectiveDate"}
                        value={dayjs(startDate)}
                        onChange={(newDate) => handleStartDateChange(newDate)}
                      />
                    </LocalizationProvider>
                  </FormControl>
                ) : (
                  <FormControl
                    item
                    sx={{
                      width: { xs: "100%", lg: "33.3%" },
                      height: "auto",
                      marginBottom: "10px",
                    }}
                  >
                    <InputLabel>Start date</InputLabel>
                    <OutlinedInput
                      sx={{ width: { xs: "100%", md: "90%" } }}
                      label="Start Date"
                      value={
                        selectedMeterBilling &&
                        selectedMeterBilling.effectiveDate &&
                        formatDate(selectedMeterBilling.effectiveDate)
                      }
                      disabled
                    />
                  </FormControl>
                )}

                <FormControl
                  sx={{
                    width: { xs: "100%", lg: "33.3%" },
                    height: "auto",

                    marginBottom: "10px",
                  }}
                >
                  <InputLabel>Customer</InputLabel>
                  <OutlinedInput
                    sx={{ width: { xs: "100%", md: "90%" } }}
                    id={"customer"}
                    label="Customer"
                    value={
                      selectedMeterBilling &&
                      selectedMeterBilling.customer &&
                      selectedMeterBilling.customer.name
                    }
                    disabled
                  />
                </FormControl>

                {task === "Create" && (
                  <ResponsiveRow
                    item
                    sx={{
                      width: "100%",
                      height: "auto",

                      display: "flex",
                      flexDirection: "row",

                      justifyContent: { xs: "center", lg: "flex-end" },
                      alignItems: "center",
                    }}
                  >
                    <ModalAcceptButton
                      variant="contained"
                      onClick={() => {
                        setTenantModal(true);
                        setIsCustomerSelect(true);
                      }}
                      sx={{
                        backgroundColor: "#037171",
                        width: { xs: "100%", lg: "200px" },
                      }}
                    >
                      Add Customer
                    </ModalAcceptButton>
                  </ResponsiveRow>
                )}
                <FormControl
                  sx={{
                    width: { xs: "100%", lg: "33.3%" },
                    height: "auto",

                    marginBottom: "10px",
                  }}
                >
                  <InputLabel>Meter/Meters</InputLabel>
                  {populateMeterIds()}
                </FormControl>
                <FormControl
                  sx={{
                    width: { xs: "100%", lg: "33.3%" },
                    height: "auto",
                    marginBottom: "10px",
                  }}
                >
                  <InputLabel>Billing Frequency</InputLabel>
                  <Select
                    sx={{ width: { xs: "100%", md: "90%" } }}
                    label="Billing Frequency"
                    id={"billingPeriod"}
                    value={rentPeriodCode}
                    onChange={(event) => handleRentPeriodChange(event)}
                  >
                    {billingPeriods && billingPeriods.length > 0 ? (
                      billingPeriods.map(function (billingPeriod, i) {
                        return (
                          <MenuItem
                            key={billingPeriod.value}
                            value={billingPeriod.value}
                          >
                            {billingPeriod.label}
                          </MenuItem>
                        );
                      }, this)
                    ) : (
                      <MenuItem sx={{ width: "100%" }}>
                        No Results Found
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
                {rentPeriodCode === "MNTH" && (
                  <FormControl
                    sx={{
                      width: { xs: "100%", lg: "33.3%" },
                      height: "auto",
                      marginBottom: "10px",
                    }}
                  >
                    <InputLabel>Billing Due Day</InputLabel>
                    <Select
                      sx={{ width: { xs: "100%", md: "90%" } }}
                      id={"billingDay"}
                      label="Billing Due Day"
                      value={rentDay}
                      onChange={(event) => handleRentDayChange(event)}
                      defaultValue={[""]}
                    >
                      {rentDays && rentDays.length > 0 ? (
                        rentDays.map(function (weekDay, i) {
                          return (
                            <MenuItem key={weekDay.value} value={weekDay.value}>
                              {weekDay.label}
                            </MenuItem>
                          );
                        }, this)
                      ) : (
                        <MenuItem sx={{ width: "100%" }}>
                          No Results Found
                        </MenuItem>
                      )}
                    </Select>
                  </FormControl>
                )}
                {(rentPeriodCode === "ANU" || rentPeriodCode === "QT") && (
                  <FormControl
                    sx={{
                      width: { xs: "100%", lg: "33.3%" },
                      height: "auto",
                      marginBottom: "10px",
                    }}
                  >
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale="en-gb"
                    >
                      <DatePicker
                        sx={{ width: { xs: "100%", md: "90%" } }}
                        id={"nextBillingDate"}
                        value={dayjs(rentDate)}
                        onChange={(newDate) =>
                          handleNextRentDateChange(newDate)
                        }
                      />
                    </LocalizationProvider>
                  </FormControl>
                )}
              </ResponsiveRow>
            </ResponsiveRow>
            <br />

            <ResponsiveRow
              sx={{
                flexDirection: "column",
                flexWrap: "nowrap",

                width: "100%",
                height: "auto",

                margin: "auto",
                padding: "10px",
              }}
            >
              <Typography
                sx={{ marginBottom: "5px" }}
                id="modal-modal-title"
                variant="h5"
                component="h4"
              >
                Billing Charges
              </Typography>
              <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ width: "10%" }}>Meter</TableCell>
                      <TableCell sx={{ width: "30%" }}>Charge Type</TableCell>
                      <TableCell sx={{ width: "20%" }}>Amount</TableCell>
                      <TableCell sx={{ width: "30%" }}>Start Date</TableCell>
                      <TableCell sx={{ width: "10%" }}>Taxed?</TableCell>
                      <TableCell sx={{ width: "10%" }}>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>{billingItemsTableRows}</TableBody>
                </Table>
              </TableContainer>
              <InnerModal
                container
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", lg: "row" },
                  justifyContent: { xs: "center", lg: "flex-end" },
                  width: "100%",
                }}
              >
                <ResponsiveRow
                  item
                  sx={{
                    width: "100%",
                    height: "auto",

                    marginBottom: "10px",

                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <ModalAcceptButton
                    variant="contained"
                    type="submit"
                    onClick={() => handleBillingItemAdd()}
                    sx={{
                      backgroundColor: "#037171",
                      width: { xs: "100%", lg: "200px" },
                    }}
                  >
                    Add row
                  </ModalAcceptButton>
                </ResponsiveRow>
                <ResponsiveRow item sx={{ width: "100%", height: "auto" }}>
                  <Alert severity="info" sx={{ width: "100%" }}>
                    <AlertTitle>Total: {chargeItemsTotal}</AlertTitle>
                  </Alert>
                </ResponsiveRow>
              </InnerModal>
            </ResponsiveRow>
            <br />
          </ResponsiveRow>
        </ModalContainerModule>
      )}

      <ResponsiveRow
        sx={{
          justifyContent: { xs: "center", lg: "flex-end" },
          width: "100%",
          height: "40px",
        }}
      >
        <ModalCancelButton
          onClick={props.handleBackButtonClicked}
          sx={{
            width: { xs: "100%", lg: "200px" },
          }}
        >
          Go Back
        </ModalCancelButton>
        <ModalAcceptButton
          onClick={saveAndFinishLater}
          sx={{
            width: { xs: "100%", lg: "200px" },
          }}
        >
          Save & Finish Later
        </ModalAcceptButton>

        <ModalAcceptButton
          onClick={saveAndContinue}
          sx={{
            width: { xs: "100%", lg: "200px" },
          }}
        >
          Save & Continue
        </ModalAcceptButton>
      </ResponsiveRow>
    </ResponsiveRow>
  );
}
