import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import {
  Alert,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  getOutstandingTitleInvoicesCharges,
  getOwnershipPaymentMethods,
} from "../../services/ownership.service";
import {
  adjustForTimezone,
  formatCurrency,
  formatDate,
  isEmptyString,
  isNullUndefined,
  isValidDate,
  tryParseFloat,
} from "../../util/util";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/en-gb";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { issueResponseMessage, issueWarnMessage } from "../../actions/message";
import {
  getMeterBillingPaymentMethods,
  getOutstandingMeterBillingInvoicesCharges,
} from "../../services/meterbilling.service";
import {
  getLeasePaymentMethods,
  getMpesaPaymentByCode,
  getOutstandingLeaseInvoicesCharges,
} from "../../services/lease.service";
import {
  getClientPaymentMethods,
  getOutstandingSubscriptionInvoicesCharges,
} from "../../services/client.service";
import { Search } from "@mui/icons-material";
import { ModalAcceptButton } from "../../constants/component.constants";
import { ResponsiveRow } from "../../constants/layout.constants";
import ModalContainerModule from "../modules/modalContainer";
import { getFacility } from "../../services/property.service";
import dayjs from "dayjs";
import {useSelector} from "react-redux";

/**
 * This is a shared reception payment component to apply payment to:
 * - Meter Billing Page
 * - Lease Page
 * - Ownership Page
 * - Customer Page
 * - Client payment Page
 * - This also apply to their respective terminated pages.
 *
 * Required props are:
 * - showPaymentModal: This is either true or false to show or hide modal respectively
 * - handlePaymentClose: This is a function to close the modal ie set showPaymentModal to false
 * - paymentMode: This is actual page you are implementing this from. Supported modes are Lease, Ownership, Customer, Meter Billing and Client
 * - paymentItem: This is the payload to be updated and sent back to the save function
 * - contract: This is the data for either selected item of 'Lease, Ownership, Meter Billing, Customer or Client'
 * - savePayment: This is a function which takes in paymentItem
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 * @author jmbuthia
 *  @description Validation is taken care of if you need to validate again you can validate the data passed to save function ie paymentItem
 */
export default function ReceivePayment(props) {
  const user = useSelector((state) => state.auth.user);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [outstandingInvoicesCharges, setOutstandingInvoicesCharges] = useState(
    []
  );
  const [invoiceOrChargeOptions, setInvoiceOrChargeOptions] = useState([]);
  const [selectedFacility, setSelectedFacility] = useState(null);
  const [paymentNote, setPaymentNote] = useState("");
  const [paymentAmount, setPaymentAmount] = useState("");
  const [contractName, setContractName] = useState("");
  const [paymentDate, setPaymentDate] = useState(null);
  const [paymentChequeNumber, setPaymentChequeNumber] = useState("");
  const [bankRefNumber, setBankRefNumber] = useState("");
  const [invoiceOrChargeNumber, setInvoiceOrChargeNumber] = useState("");
  const [confirmationCode, setConfirmationCode] = useState("");
  const [paymentMethodCode, setPaymentMethodCode] = useState("");
  let isMPesa = paymentMethodCode === "MPES";
  useEffect(() => {
    if (props.paymentMode === "Ownership") {
      getOwnershipPaymentMethods().then((paymentMethods) =>
        setPaymentMethods(paymentMethods)
      );
    }

    if (
      props.paymentMode === "Meter Billing" ||
      props.paymentMode === "Customer" || props.paymentMode === "maintenanceClient"
    ) {
      getMeterBillingPaymentMethods().then((paymentMethods) =>
        setPaymentMethods(paymentMethods)
      );
    }

    if (props.paymentMode === "Lease") {
      getLeasePaymentMethods().then((paymentMethods) =>
        setPaymentMethods(paymentMethods)
      );
    }

    if (props.paymentMode === "Client") {
      getClientPaymentMethods().then((paymentMethods) =>
        setPaymentMethods(paymentMethods)
      );
    }
  }, [props.paymentMode]);

  useEffect(() => {
    if (
      !isNullUndefined(props.contract) &&
      !isNullUndefined(props.contract.id)
    ) {
      if (props.paymentMode === "Ownership") {
        getFacility(props.contract.facilityId, 0, false).then((facility) => {
          setSelectedFacility(facility);
        });
        getOutstandingTitleInvoicesCharges(props.contract.id).then(
          (outstandingInvoicesCharges) => {
            let tmp = [];
            outstandingInvoicesCharges.forEach(function (outstanding) {
              if (
                (outstanding.type === "Invoice" ||
                  outstanding.type === "Charge") &&
                outstanding.outstandingBalance > 0
              ) {
                let option = {
                  value: outstanding.referenceNumber,
                  label:
                    outstanding.referenceNumber +
                    " - " +
                    outstanding.outstandingBalance,
                };
                tmp.push(option);
              }
            });
            setOutstandingInvoicesCharges(outstandingInvoicesCharges);
            setInvoiceOrChargeOptions(tmp);
          }
        );
        setContractName("for " + props.contract.ownerNames);
      }

      if (props.paymentMode === "Meter Billing") {
        getOutstandingMeterBillingInvoicesCharges(props.contract.id).then(
          (outstandingInvoicesCharges) =>
            setOutstandingInvoicesCharges(outstandingInvoicesCharges)
        );
        setContractName("for " + props.contract.customer.name);
      }

      if (props.paymentMode === "Customer") {
        getOutstandingMeterBillingInvoicesCharges(
          props.contract.billingId
        ).then((outstandingInvoicesCharges) =>
          setOutstandingInvoicesCharges(outstandingInvoicesCharges)
        );
        setContractName("for " + props.contract.name);
      }

      if (props.paymentMode === "Lease") {
        getOutstandingLeaseInvoicesCharges(props.contract.id).then(
          (outstandingInvoicesCharges) =>
            setOutstandingInvoicesCharges(outstandingInvoicesCharges)
        );
        if (props.contract.statusCd === "TER") {
          setContractName("for " + props.contract.tenant.name);
        } else {
          setContractName("for " + props.contract.tenant);
        }
      }

      if (props.paymentMode === "Client") {
        getOutstandingSubscriptionInvoicesCharges(props.contract.id).then(
          (data) => setOutstandingInvoicesCharges(data.outstandingItems)
        );
        setContractName("for " + props.contract.name);
      }
    }
  }, [props.contract, props.paymentMode]);

  function onTextChange(event) {
    const id = event.target.id;
    const value = event.target.value;
    if (id === "paymentNote") {
      setPaymentNote(value);
    }
    if (id === "paymentAmount") {
      setPaymentAmount(value);
    }
    if (id === "paymentChequeNumber") {
      setPaymentChequeNumber(value);
    }
    if (id === "invoiceOrChargeNumber") {
      setInvoiceOrChargeNumber(value);
    }
    if (id === "bankRefNumber") {
      setBankRefNumber(value);
    }
    if (id === "confirmationCode") {
      setConfirmationCode(value);
    }
  }

  function fetchPaymentByCode() {
    if (
      !isNullUndefined(confirmationCode) &&
      !isNullUndefined(props.contract.id)
    ) {
      getMpesaPaymentByCode(confirmationCode, props.contract.id).then(
        (response) => {
          issueResponseMessage(response);
        }
      );
    }
  }

  function savePayment() {
    if (validatePaymentForm()) {
      props.savePayment({
        date: paymentDate,
        note: paymentNote,
        amount: paymentAmount,
        chequeNumber: paymentChequeNumber,
        invoiceOrChargeNumber: invoiceOrChargeNumber,
        bankRefNumber: bankRefNumber,
        paymentMethodCode: paymentMethodCode,
      });
      setPaymentNote("");
      setPaymentAmount("");
      setPaymentChequeNumber("");
      setInvoiceOrChargeNumber("");
      setBankRefNumber("");
      setConfirmationCode("");
      setPaymentDate(null);
      setPaymentMethodCode("");
    }
  }

  function handlePaymentDateChange(value) {
    let date = new Date(value);
    setPaymentDate(adjustForTimezone(date));
  }

  function handlePaymentMethodChange(event) {
    let value = event.target.value;
    setPaymentMethodCode(value);
  }

  function handleInvoiceOrChargeChange(event) {
    let value = event.target.value;
    setInvoiceOrChargeNumber(value);
  }

  function validatePaymentForm() {
    if (!isValidDate(paymentDate)) {
      issueWarnMessage("Select a valid payment date");
      return false;
    }

    //If Maintenance Subscription Facility, Do not allow overpayments.
    if(user.services.includes("MAINT") &&
        (paymentAmount > props.selectedInvoice?.amount)
    ){
      issueWarnMessage("Payment Amount must not exceed the invoice amount!");
      return false;
    }
    if (paymentDate > dayjs(new Date())) {
      issueWarnMessage("Payment date should not be more than today");
      return false;
    }
    if (isEmptyString(paymentMethodCode)) {
      issueWarnMessage("Must select a payment method");
      return false;
    }
    if (
      !isNullUndefined(paymentMethodCode) &&
      !isEmptyString(paymentMethodCode) &&
      paymentMethodCode === "CHQ" &&
      (isNullUndefined(paymentChequeNumber) ||
        isEmptyString(paymentChequeNumber))
    ) {
      issueWarnMessage("Must input a chequeNumber");
      return false;
    }
    if (
      !isNullUndefined(paymentMethodCode) &&
      !isEmptyString(paymentMethodCode) &&
      paymentMethodCode === "CHQ" &&
      !isNullUndefined(paymentChequeNumber) &&
      !isEmptyString(paymentChequeNumber) &&
      paymentChequeNumber.length > 10
    ) {
      issueWarnMessage("Cheque Number can't exceed 10 characters");
      return false;
    }
    if (
      !isNullUndefined(paymentMethodCode) &&
      !isEmptyString(paymentMethodCode) &&
      paymentMethodCode === "RTGS" &&
      (isNullUndefined(bankRefNumber) || isEmptyString(bankRefNumber))
    ) {
      issueWarnMessage("Must input a bank reference Number");
      return false;
    }
    if (
      !isNullUndefined(paymentMethodCode) &&
      !isEmptyString(paymentMethodCode) &&
      paymentMethodCode === "RTGS" &&
      !isNullUndefined(bankRefNumber) &&
      !isEmptyString(bankRefNumber) &&
      bankRefNumber.length > 45
    ) {
      issueWarnMessage("Bank ref Number can't exceed 16 characters");
      return false;
    }
    let amount = tryParseFloat(paymentAmount, -1);
    if (amount < 1) {
      issueWarnMessage(
        "Invalid Amount. The amount entered must be more than KSH 0"
      );
      return false;
    }
    return true;
  }

  return (
    <>
      <ModalContainerModule
        size={props.paymentMode === "maintenanceClient" ? "small" : "md"}
        accept={!isMPesa ? savePayment : false}
        cancel={props.handlePaymentClose}
        openModal={props.showPaymentModal}
        modalTitle={`Accept Payment ${contractName}`}
        acceptButtonText="Apply Payment"
      >
        {/*RECEIVE PAYMENT FORM*/}

        {isMPesa && (
          <Alert severity="info">
            Mpesa payments can be receipted by entering the confirmation code
            below to search for and retrieve the payment.
          </Alert>
        )}
        <ResponsiveRow
          container
          sx={{
            display: "flex",
            flexDirection: { xs: "column", lg: "row" },
            justifyContent: { xs: "center", lg: "space-between" },
            height: "auto",
            marginBottom: "20px",
          }}
        >
          {!isMPesa && (
            <FormControl
              item
              sx={{ width: { xs: "100%", lg: "50%" }, marginBottom: "10px" }}
            >
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale="en-gb"
              >
                <DatePicker
                  label="Date"
                  id={"paymentDate"}
                  value={dayjs(paymentDate)}
                  onChange={(date) => handlePaymentDateChange(date)}
                  sx={{ width: { xs: "100%", lg: "90%" } }}
                />
              </LocalizationProvider>
            </FormControl>
          )}
          {/* &nbsp&nbsp */}
          <FormControl
            sx={{ width: { xs: "100%", lg: "50%" }, marginBottom: "10px" }}
          >
            <InputLabel>Payment Method</InputLabel>
            <Select
              label="Payment Method"
              sx={{ width: { xs: "100%", lg: "90%" } }}
              id={"paymentMethodCode"}
              name={"paymentMethodCode"}
              value={paymentMethodCode}
              onChange={(event) => handlePaymentMethodChange(event)}
            >
              {paymentMethods && paymentMethods.length > 0 ? (
                paymentMethods.map(function (paymentMethod, i) {
                  return (
                    <MenuItem
                      key={paymentMethod.value}
                      value={paymentMethod.value}
                    >
                      {paymentMethod.label}
                    </MenuItem>
                  );
                }, this)
              ) : (
                <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
              )}
            </Select>
          </FormControl>
          {paymentMethodCode && paymentMethodCode === "CHQ" && (
            <FormControl
              item
              sx={{ width: { xs: "100%", lg: "50%" }, marginBottom: "10px" }}
            >
              <InputLabel>Cheque Number</InputLabel>
              <OutlinedInput
                label="Cheque Number"
                sx={{ width: { xs: "100%", lg: "90%" } }}
                id={"paymentChequeNumber"}
                name={"paymentChequeNumber"}
                value={paymentChequeNumber}
                onChange={(event) => onTextChange(event)}
              />
            </FormControl>
          )}
          {paymentMethodCode && paymentMethodCode === "RTGS" && (
            <FormControl
              item
              sx={{ width: { xs: "100%", lg: "50%" }, marginBottom: "10px" }}
            >
              <InputLabel>Bank Ref Number</InputLabel>
              <OutlinedInput
                label="Bank Ref Number"
                sx={{ width: { xs: "100%", lg: "90%" } }}
                id={"bankRefNumber"}
                name={"bankRefNumber"}
                value={bankRefNumber}
                onChange={(event) => onTextChange(event)}
              />
            </FormControl>
          )}
          {selectedFacility &&
            selectedFacility.facilityPayInfo &&
            selectedFacility.facilityPayInfo.payInvoice && (
              <FormControl
                sx={{ width: { xs: "100%", lg: "50%" }, marginBottom: "10px" }}
              >
                <InputLabel>Pay Invoice/Charge</InputLabel>
                <Select
                  label="Pay Invoice/Charge"
                  sx={{ width: { xs: "100%", lg: "90%" } }}
                  id={"invoiceOrChargeNumber"}
                  name={"invoiceOrChargeNumber"}
                  value={invoiceOrChargeNumber}
                  onChange={(event) => handleInvoiceOrChargeChange(event)}
                >
                  {invoiceOrChargeOptions &&
                  invoiceOrChargeOptions.length > 0 ? (
                    invoiceOrChargeOptions.map(function (invoiceOrCharge, i) {
                      return (
                        <MenuItem
                          key={invoiceOrCharge.value}
                          value={invoiceOrCharge.value}
                        >
                          {invoiceOrCharge.label}
                        </MenuItem>
                      );
                    }, this)
                  ) : (
                    <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
                  )}
                </Select>
              </FormControl>
            )}
          {!isMPesa && (
            <FormControl
              item
              sx={{ width: { xs: "100%", lg: "50%" }, marginBottom: "10px" }}
            >
              <InputLabel>Amount</InputLabel>
              <OutlinedInput
                label="Amount"
                sx={{ width: { xs: "100%", lg: "90%" } }}
                id={"paymentAmount"}
                name={"paymentAmount"}
                value={paymentAmount}
                onChange={(event) => onTextChange(event)}
              />
            </FormControl>
          )}
          {!isMPesa && (
            <FormControl
              item
              sx={{ width: { xs: "100%", lg: "50%" }, marginBottom: "10px" }}
            >
              <InputLabel>Note</InputLabel>
              <OutlinedInput
                label="Note"
                sx={{ width: { xs: "100%", lg: "90%" } }}
                id={"paymentNote"}
                name={"paymentNote"}
                value={paymentNote}
                onChange={(event) => onTextChange(event)}
              />
            </FormControl>
          )}
          {isMPesa && (
            <FormControl
              item
              sx={{ width: { xs: "100%", lg: "50%" }, marginBottom: "10px" }}
            >
              <InputLabel>M-PESA Confirmation Code</InputLabel>
              <OutlinedInput
                label="M-PESA Confirmation Code"
                sx={{ width: { xs: "100%", lg: "90%" } }}
                id={"confirmationCode"}
                name={"confirmationCode"}
                value={confirmationCode}
                onChange={(event) => onTextChange(event)}
              />
            </FormControl>
          )}
          {isMPesa && (
            <ModalAcceptButton
              // type="submit"
              style={{ width: { xs: "100%", md: "200px" } }}
              onClick={fetchPaymentByCode}
            >
              <Search />
            </ModalAcceptButton>
          )}
        </ResponsiveRow>

        {props.paymentMode !== "maintenanceClient" &&
        <ResponsiveRow container sx={{ height: "auto" }}>
          <Grid item xs={12}>
            <Typography variant="h5">
              Outstanding Invoices/Charges (Unpaid & Partial)
            </Typography>
            <TableContainer style={{ maxHeight: 350 }}>
              <Table
                sx={{ minWidth: 350 }}
                aria-label="simple table"
                stickyHeader
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Ref No.</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Due date</TableCell>
                    <TableCell>Invoicee</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Amount</TableCell>
                    <TableCell>Outstanding Balance</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {outstandingInvoicesCharges &&
                    outstandingInvoicesCharges.length > 0 &&
                    outstandingInvoicesCharges.map(function (outstanding, i) {
                      return (
                        <TableRow key={outstanding.id}>
                          <TableCell>{outstanding.referenceNumber}</TableCell>
                          <TableCell>{formatDate(outstanding.date)}</TableCell>
                          <TableCell>
                            {formatDate(outstanding.dueDate)}
                          </TableCell>
                          <TableCell>{outstanding.invoicee}</TableCell>
                          <TableCell>{outstanding.description}</TableCell>
                          <TableCell>{outstanding.status}</TableCell>
                          <TableCell>
                            {formatCurrency(outstanding.amount)}
                          </TableCell>
                          <TableCell>
                            {formatCurrency(outstanding.outstandingBalance)}
                          </TableCell>
                        </TableRow>
                      );
                    }, this)}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </ResponsiveRow>
        }
      </ModalContainerModule>
    </>
  );
}
