import {
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    FormGroup,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material"
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import {DatePicker} from '@mui/x-date-pickers/DatePicker'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {ResponsiveRow} from "../../../../constants/layout.constants"
import ModalContainerModule from "../../../modules/modalContainer"
import {adjustForTimezone, formatCurrency, isEmptyString, isNullUndefined} from "../../../../util/util"
import {ModalAcceptButton} from "../../../../constants/component.constants"
import dayjs from "dayjs";

export default function CreateCapitalFund(props) {
    /**
     *
     * REQUIRED PROPS
     *
     * handleItemAdd
     * handleCancelClicked
     * handleSaveClicked
     *
     * capitalFundForm
     * task
     *
     * onCheckboxChange
     * setApprovalThreshold
     * setDescription
     * setDueDate
     * setSelectedCapitalFund
     * setSelectedFacility
     * setName
     *
     * amount
     * amountChanged
     * approvalRequired
     * approvalThreshold
     * approvalTotal
     * capitalFundContributionTableRows
     * capitalFundOwnerships
     * capitalFundContributionList
     * description
     * distributeEvenly
     * dueDate
     * facilityOptions
     * name
     * selectedCapitalFund
     * selectedFacility
     *
     */
    let selectedCapitalFund = props.selectedCapitalFund
    let acceptDisabled = isNullUndefined(selectedCapitalFund) || selectedCapitalFund.capitalFundApprovals.length < 1 ||
        selectedCapitalFund.amount < 1 || selectedCapitalFund.dueDate < dayjs(new Date()) ||
        isNullUndefined(selectedCapitalFund.name) || isEmptyString(selectedCapitalFund.name) ||
        isNullUndefined(selectedCapitalFund.description) || isEmptyString(selectedCapitalFund.description) ||
        ((!isNullUndefined(selectedCapitalFund.approvalRequired) && selectedCapitalFund.approvalRequired) &&
            (isNullUndefined(selectedCapitalFund.approvalThresholdPercentage) || selectedCapitalFund.approvalThresholdPercentage < 1))

    return (
      <ModalContainerModule
        size="md"
        accept={props.handleSaveClicked}
        cancel={props.handleCancelClicked}
        openModal={props.capitalFundForm}
        acceptDisabled={acceptDisabled}
        modalTitle={props.task === "Create" ? "Add new Capital Fund" : "Update Capital Fund"}
        acceptButtonText={props.task === "Create" ? "Save" : "Update"}
      >
        <ResponsiveRow
          sx={{
            height: "auto",
          }}
        >
          <Typography id="addCapFundForm" variant="h6" component="h6">
            Capital Fund Details
          </Typography>
          <Divider />
          <br />

          <ResponsiveRow container sx={{ height: "auto" }}>
            <FormControl
              item
              sx={{
                width: { xs: "100%", lg: "33.3%" },
                marginBottom: "10px",
              }}
            >
              <InputLabel>Name *</InputLabel>
              <OutlinedInput
                label="Name *"
                placeholder={"Name"}
                sx={{ width: "90%" }}
                id={"name"}
                required={true}
                onChange={(event) => {
                  const value = event.target.value;
                  props.setName(value);
                  let obj = props.selectedCapitalFund;
                  obj.name = value;
                  props.setSelectedCapitalFund(obj);
                }}
                value={
                  props.selectedCapitalFund
                    ? props.selectedCapitalFund.name
                    : props.name
                }
              />
            </FormControl>

            <FormControl
              item
              sx={{
                width: { xs: "100%", lg: "33.3%" },
                marginBottom: "10px",
              }}
            >
              <InputLabel>Client</InputLabel>
              <Select
                label="Client"
                sx={{ width: "90%" }}
                id={"client"}
                value={
                  props.selectedCapitalFund
                    ? props.selectedCapitalFund.facilityId
                    : props.selectedFacility
                }
                onChange={(event) => {
                  let value = event.target.value;
                  props.setSelectedFacility(value);
                  let obj = props.selectedCapitalFund;
                  obj.facilityId = value;
                  props.setSelectedCapitalFund(obj);
                }}
              >
                {props.facilityOptions && props.facilityOptions.length > 0 ? (
                  props.facilityOptions.map(function (item, i) {
                    return (
                      <MenuItem key={i} value={item.value}>
                        {item.label}
                      </MenuItem>
                    );
                  }, this)
                ) : (
                  <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
                )}
              </Select>
            </FormControl>

            <FormControl
              item
              sx={{
                width: { xs: "100%", lg: "33.3%" },
                marginBottom: "10px",
              }}
            >
              <InputLabel>Amount</InputLabel>
              <OutlinedInput
                label="Amount"
                placeholder={"Amount"}
                sx={{ width: "90%" }}
                id={"amount"}
                disabled={!props.distributeEvenly}
                onChange={(event) => {
                  props.amountChanged(event.target.value)
                }}
                value={
                  props.selectedCapitalFund
                    ? props.selectedCapitalFund.amount
                    : props.amount
                }
              />
            </FormControl>
            <FormControl
              item
              sx={{
                width: { xs: "100%", lg: "33.3%" },
                marginBottom: "10px",
              }}
            >
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale="en-gb"
              >
                <DatePicker
                  label="Due Date *"
                  sx={{ width: { xs: "100%", lg: "90%" } }}
                  id={"dueDate"}
                  value={
                    props.selectedCapitalFund
                      ? props.selectedCapitalFund.dueDate &&
                        dayjs(props.selectedCapitalFund.dueDate)
                      : dayjs(props.dueDate)
                  }
                  isRequired={true}
                  onChange={(dateValue) => {
                    let date = dayjs(adjustForTimezone(new Date(dateValue)));
                    props.setDueDate(date);
                    let obj = props.selectedCapitalFund;
                    obj.dueDate = date;
                    props.setSelectedCapitalFund(obj);
                  }}

                />
              </LocalizationProvider>
            </FormControl>
            <FormControl
              item
              sx={{
                width: { xs: "100%", lg: "33.3%" },
                marginBottom: "10px",
              }}
            >
              <FormGroup row sx={{ width: { xs: "100%", lg: "90%" } }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={"distributeEvenly"}
                      checked={props.distributeEvenly}
                      onChange={props.onCheckboxChange}
                    />
                  }
                  label="Distribute Evenly?"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      value={"approvalRequired"}
                      checked={props.approvalRequired}
                      onChange={props.onCheckboxChange}
                    />
                  }
                  label="Approval Required?"
                />
              </FormGroup>
            </FormControl>
            {props.approvalRequired && (
              <FormControl
                item
                sx={{
                  width: { xs: "100%", lg: "33.3%" },
                  marginBottom: "10px",
                }}
              >
                <InputLabel>Approval Threshold (%) *</InputLabel>
                <OutlinedInput
                  label="Approval Threshold (%)"
                  placeholder={"Approval Threshold"}
                  sx={{ width: { xs: "100%", lg: "90%" } }}
                  id={"approvalThreshold"}
                  onChange={(event) => {
                    const value = event.target.value;
                    props.setApprovalThreshold(value);
                    let obj = props.selectedCapitalFund;
                    obj.approvalThresholdPercentage = value;
                    props.setSelectedCapitalFund(obj);
                  }}
                  value={
                    props.selectedCapitalFund
                      ? props.selectedCapitalFund.approvalThresholdPercentage
                      : props.approvalThreshold
                  }
                />
              </FormControl>
            )}

            <FormControl
              item
              sx={{
                width: "100%",
                marginBottom: "10px",
              }}
            >
              <InputLabel>Description</InputLabel>
              <OutlinedInput
                label="Description"
                id="description"
                sx={{ width: "95%" }}
                placeholder="Description"
                multiline
                rows={4}
                value={
                  props.selectedCapitalFund
                    ? props.selectedCapitalFund.description
                    : props.description
                }
                onChange={(event) => {
                  let value = event.target.value;
                  props.setDescription(value);
                  let obj = props.selectedCapitalFund;
                  obj.description = value;
                  props.setSelectedCapitalFund(obj);
                }}

              />
            </FormControl>

            <Typography id="distributeCapFundForm" variant="h6" component="h6">
              Capital Fund Distributions

                {
                    props.task === "Create" &&
                    <FormControl
                    item
                    sx={{
                        width: { xs: "100%", lg: "100%" },
                        marginBottom: "10px",
                    }}
                >
                    <FormGroup row sx={{ width: { xs: "100%", lg: "100%" } }}>
                        {
                            props.capitalFundContributionList.length < props.capitalFundOwnerships.length &&
                            <FormControlLabel
                            control={
                                <Checkbox
                                    value={"includeAll"}
                                    checked={props.includeAll}
                                    onChange={props.onCheckboxChange}
                                />
                            }
                            label="Include all Home owners?"
                        />
                        }
                        {
                            props.capitalFundContributionList.length > 0 &&
                            <FormControlLabel
                            control={
                                <Checkbox
                                    value={"clearAll"}
                                    checked={props.clearAll}
                                    onChange={props.onCheckboxChange}
                                />
                            }
                            label="Clear all Distribution List?"
                        />
                        }
                    </FormGroup>
                </FormControl>
            }
            </Typography>
            <Divider />
            <br />
            <TableContainer
              style={{
                maxHeight: 350,
                backgroundColor: "#fff",
                borderRadius: "10px",
                overflowX: "auto",
              }}
            >
              <Table
                sx={{ width: { xs: "100%", lg: "100%" } }}
                aria-label="simple table"
                stickyHeader
                au
              >
                <TableHead>
                  <TableRow>
                      <TableCell sx={{ width: { xs: "auto", lg: "30%" } }}>
                          Unit
                      </TableCell>
                      <TableCell sx={{ width: { xs: "auto", lg: "30%" } }}>
                          Owner
                      </TableCell>
                      <TableCell sx={{ width: { xs: "auto", lg: "30%" } }}>
                          Amount
                      </TableCell>
                      <TableCell sx={{ width: { xs: "auto", lg: "10%" } }}>
                          Action
                      </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{props.capitalFundContributionTableRows}</TableBody>
              </Table>
            </TableContainer>

            <ResponsiveRow
              sx={{
                width: "100%",
                height: "auto",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="h6" component="h6">
                Total: {formatCurrency(props.approvalTotal.toFixed(2))}
              </Typography>
              {
                  props.capitalFundContributionList.length < props.capitalFundOwnerships.length && (
                <ModalAcceptButton
                  variant="contained"
                  type="submit"
                  onClick={props.handleItemAdd}
                  sx={{
                    width: {
                      xs: "100%",
                      lg: "200px",
                    },
                  }}
                >
                  Add Row
                </ModalAcceptButton>
              )}
            </ResponsiveRow>
          </ResponsiveRow>
        </ResponsiveRow>
      </ModalContainerModule>
    );
}
