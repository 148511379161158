import {Typography} from "@mui/material";
import {DetailsColumn, DetailsContainer, DetailsLabel, DetailsText,} from "../../constants/component.constants";
import {ResponsiveRow} from "../../constants/layout.constants";
import {formatDate, isNullUndefined} from "../../util/util";

export default function OwnershipChargesCard(props) {
    /**
     *
     * REQUIRED PROPS
     *
     * selectedOwnership
     *
     */

    const {width} = props;

    return (
        <ResponsiveRow
            item
            sx={{
                flexDirection: "column",
                flexWrap: "nowrap",

                justifyContent: "flex-start",

                width: {xs: "100%", lg: width ? width : "420px"},
                height: {xs: "100%", lg: "500px"},

                backgroundColor: "#fff",
                borderRadius: "10px",
                boxShadow: "0 4px 12px 0 rgba(0,0,0,0.25)",

                margin: { xs: "10px 0", lg: "10px" },
                padding: 0,
            }}
        >
            <ResponsiveRow
                sx={{
                    flexDirection: "row",

                    justifyContent: "flex-start",
                    alignItems: "flex-start",

                    flexWrap: "nowrap",

                    width: "100%",
                    height: "50px",
                    padding: "20px",
                }}
            >
                <Typography
                    sx={{marginBottom: "5px"}}
                    id="modal-modal-title"
                    variant="h5"
                    color="#037171"
                    component="h4"
                >
                    Ownership Charges
                </Typography>
            </ResponsiveRow>

            <ResponsiveRow
                sx={{
                    backgroundColor: "#EFEFEF",
                    borderRadius: "10px",

                    height: "450px",
                    width: "100%",

                    padding: "0 10px",
                    margin: "10px 0 0 0",

                    flexDirection: "column",
                    flexWrap: "nowrap",

                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <DetailsContainer
                    sx={{
                        width: "100%",
                        height: "70px",
                        marginTop: 0,
                        marginBottom: 0,
                    }}
                >
                    <DetailsColumn sx={{width: "25%"}}>
                        <DetailsLabel
                            sx={{
                                fontSize: "12px",
                                textAlign: "start",
                            }}
                        >
                            Charge Type
                        </DetailsLabel>
                    </DetailsColumn>
                    <DetailsColumn sx={{width: "25%"}}>
                        <DetailsLabel
                            sx={{
                                fontSize: "12px",
                                textAlign: "start",
                            }}
                        >
                            Amount
                        </DetailsLabel>
                    </DetailsColumn>
                    <DetailsColumn sx={{width: "25%"}}>
                        <DetailsLabel
                            sx={{
                                fontSize: "12px",
                                textAlign: "start",
                            }}
                        >
                            Billing Period
                        </DetailsLabel>
                    </DetailsColumn>
                    <DetailsColumn sx={{width: "25%"}}>
                        <DetailsLabel
                            sx={{
                                fontSize: "12px",
                                textAlign: "start",
                            }}
                        >
                            Invoice Day/Date
                        </DetailsLabel>
                    </DetailsColumn>
                </DetailsContainer>

                <ResponsiveRow
                    container
                    sx={{
                        flexDirection: "column",
                        width: "100%",
                        marginTop: 0,
                        padding: 0,
                        alignItems: "flex-start",
                    }}
                >
                    {props.selectedOwnership &&
                        props.selectedOwnership.titleChargeItems.length > 0 &&
                        props.selectedOwnership.titleChargeItems.map(function (
                                chargeItem,
                                i
                            ) {
                                let invoiceDateOrDay;
                                if (!isNullUndefined(chargeItem.invoiceDate)) {
                                    invoiceDateOrDay = formatDate(chargeItem.invoiceDate);
                                }
                                if (!isNullUndefined(chargeItem.invoiceDay)) {
                                    invoiceDateOrDay = chargeItem.invoiceDay;
                                }
                                return (
                                    <DetailsContainer
                                        sx={{
                                            width: "100%",
                                            height: "65px",
                                            backgroundColor: "#ffffff",
                                            borderRadius: "10px",
                                            alignItems: "center",
                                            justifyContent: "space-evenly",
                                            marginTop: "10px",
                                        }}
                                    >
                                        <DetailsColumn sx={{width: "30%", padding: 0}}>
                                            <DetailsText
                                                sx={{
                                                    fontSize: "12px",
                                                    width: "100%",
                                                    fontWeight: 600,
                                                    color: "#037171",
                                                }}
                                            >
                                                {chargeItem.charge.name}
                                            </DetailsText>
                                        </DetailsColumn>
                                        <DetailsColumn sx={{width: "20%"}}>
                                            <DetailsText sx={{fontSize: "12px"}}>
                                                {chargeItem.amount}
                                            </DetailsText>
                                        </DetailsColumn>
                                        <DetailsColumn sx={{width: "25%"}}>
                                            <DetailsText sx={{fontSize: "12px"}}>
                                                {chargeItem.billingFrequency}
                                            </DetailsText>
                                        </DetailsColumn>
                                        <DetailsColumn sx={{width: "25%"}}>
                                            <DetailsText sx={{fontSize: "12px"}}>
                                                {invoiceDateOrDay}
                                            </DetailsText>
                                        </DetailsColumn>
                                    </DetailsContainer>
                                );
                            },
                            this)}
                </ResponsiveRow>
            </ResponsiveRow>
        </ResponsiveRow>
    );
}
