/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { ResponsiveRow } from "../../constants/layout.constants";
import {
  ModalAcceptButton,
  ModalCancelButton,
} from "../../constants/component.constants";
import { Cancel, Preview } from "@mui/icons-material";
import {
  getIdRefNumber,
  isArray,
  isEmptyArray,
  isEmptyString,
  isNullUndefined,
  isNumeric,
  tryParseFloat,
} from "../../util/util";
import { issueWarnMessage } from "../../actions/message";
import {
  getMeterReadingPeriods,
  getMeterTypes,
} from "../../services/meters.service";
import { getRateType } from "../../services/list.service";
import PriceRate from "../modals/priceRate";

export default function SignOnUtilityDetails(props) {
  const [addReadingFrequency, setAddReadingFrequency] = useState(false);
  const [readingFrequencyCd, setReadingFrequencyCd] = useState("");
  const [addMeterType, setAddMeterType] = useState(false);
  const [meterType, setMeterType] = useState("");
  const [addRateTypeCode, setAddRateTypeCode] = useState(false);
  const [rateTypeCode, setRateTypeCode] = useState("");
  const [addStandingCharge, setAddStandingCharge] = useState(false);
  const [standingCharge, setStandingCharge] = useState("");
  const [addRate, setAddRate] = useState(false);
  const [rate, setRate] = useState("");
  const [utilities, setUtilities] = useState([]);
  const [meterBillings, setMeterBillings] = useState([]);
  const [utilitiesRows, setUtilitiesRows] = useState("");
  const [meterTypes, setMeterTypes] = useState([]);
  const [facilityId, setFacilityId] = useState("");
  const [rateTypeOptions, setRateTypeOptions] = useState([]);
  const [units, setUnits] = useState([]);
  const [showUnits, setShowUnits] = useState(false);
  const [meterReadingPeriods, setMeterReadingPeriods] = useState([]);
  const [tieredRates, setTieredRates] = useState([]);
  const [tieredRatesRows, setTieredRatesRows] = useState("");
  const [priceRateModal, setPriceRateModal] = useState(false);
  const [selectedUtility, setSelectedUtility] = useState(null);
  const [customers, setCustomers] = useState([]);

  useEffect(() => {
    getRateType().then((rateTypeOptions) =>
      setRateTypeOptions(rateTypeOptions)
    );

    if (
      !isNullUndefined(props) &&
      !isNullUndefined(props.signUp) &&
      !isNullUndefined(props.signUp.signUpJson) &&
      !isNullUndefined(props.signUp.signUpJson.subscriptionService) &&
      !isNullUndefined(
        props.signUp.signUpJson.subscriptionService.serviceCode
      ) &&
      props.signUp.signUpJson.subscriptionService.serviceCode === "BLWTR"
    ) {
      setMeterTypes([
        { label: "Retail Water Sales - Property", value: "RTL" },
        { label: "Bulk Water Sales - Property", value: "BLK" },
      ]);
      setReadingFrequencyCd("DAY");
    } else {
      getMeterTypes().then((meterTypes) => setMeterTypes(meterTypes));
    }

    getMeterReadingPeriods().then((meterReadingPeriods) =>
      setMeterReadingPeriods(meterReadingPeriods)
    );

    if (
      !isNullUndefined(props) &&
      !isNullUndefined(props.signUp) &&
      !isNullUndefined(props.signUp.signUpJson) &&
      !isNullUndefined(props.signUp.signUpJson.facility)
    ) {
      setFacilityId(props.signUp.signUpJson.facility.id);
      if (
        !isNullUndefined(props) &&
        !isNullUndefined(props.signUp) &&
        !isNullUndefined(props.signUp.signUpJson) &&
        !isNullUndefined(props.signUp.signUpJson.facility) &&
        !isNullUndefined(props.signUp.signUpJson.facility.units) &&
        isArray(props.signUp.signUpJson.facility.units)
      ) {
        setUnits(props.signUp.signUpJson.facility.units);
      }
      if (
        !isNullUndefined(props) &&
        !isNullUndefined(props.signUp) &&
        !isNullUndefined(props.signUp.signUpJson) &&
        !isNullUndefined(props.signUp.signUpJson.facility) &&
        isArray(props.signUp.signUpJson.utilities)
      ) {
        setUtilities(props.signUp.signUpJson.utilities);
      }
    }

    if (
      !isNullUndefined(props) &&
      !isNullUndefined(props.signUp) &&
      !isNullUndefined(props.signUp.signUpJson) &&
      !isNullUndefined(props.signUp.signUpJson.subscriptionService) &&
      !isNullUndefined(
        props.signUp.signUpJson.subscriptionService.serviceCode
      ) &&
      !isEmptyString(props.signUp.signUpJson.subscriptionService.serviceCode)
    ) {
      if (
        (props.signUp.signUpJson.subscriptionService.serviceCode === "CORE" ||
          props.signUp.signUpJson.subscriptionService.serviceCode === "LEAN") &&
        !isNullUndefined(
          props.signUp.signUpJson.subscriptionService.addUtilitiesSupport
        ) &&
        !props.signUp.signUpJson.subscriptionService.addUtilitiesSupport
      ) {
        let signUp;
        let buttonIndex = "3.2";
        let displayMessage = "Please proceed to the Resident section.";
        if (props.signUp.signUpJson.currentStep === 4) {
          buttonIndex = "2.1";
          displayMessage = "Please go back to the units section.";
        }
        signUp = props.signUp;
        signUp.buttonIndex = buttonIndex;
        issueWarnMessage(displayMessage);
        props.saveSignUpData(signUp);
      }

      if (
        (props.signUp.signUpJson.subscriptionService.serviceCode === "CORE" ||
          props.signUp.signUpJson.subscriptionService.serviceCode === "LEAN") &&
        !isNullUndefined(
          props.signUp.signUpJson.subscriptionService.addUtilitiesSupport
        ) &&
        props.signUp.signUpJson.subscriptionService.addUtilitiesSupport
      ) {
        setShowUnits(true);
      }

      if (
        props.signUp.signUpJson.subscriptionService.serviceCode === "PREMIUM"
      ) {
        setShowUnits(true);
      }

      if (props.signUp.signUpJson.subscriptionService.serviceCode === "METER") {
        //set meter billing
        if (
          !isNullUndefined(props) &&
          !isNullUndefined(props.signUp) &&
          !isNullUndefined(props.signUp.signUpJson) &&
          !isNullUndefined(props.signUp.signUpJson.meterBillings) &&
          isArray(props.signUp.signUpJson.meterBillings)
        ) {
          setMeterBillings(props.signUp.signUpJson.meterBillings);
        }
      }

      if (props.signUp.signUpJson.subscriptionService.serviceCode === "BLWTR") {
        setMeterTypes([]);
        //set meter billing
        if (
          !isNullUndefined(props) &&
          !isNullUndefined(props.signUp) &&
          !isNullUndefined(props.signUp.signUpJson) &&
          !isNullUndefined(props.signUp.signUpJson.customers) &&
          isArray(props.signUp.signUpJson.customers)
        ) {
          setCustomers(props.signUp.signUpJson.customers);
        }
        setMeterTypes([
          { label: "Retail Water Sales - Property", value: "RTL" },
          { label: "Bulk Water Sales - Property", value: "BLK" },
        ]);
        setReadingFrequencyCd("DAY");
      }
    }
  }, []);

  useEffect(() => {
    if (
      !isNullUndefined(props) &&
      !isNullUndefined(props.signUp) &&
      !isNullUndefined(props.signUp.signUpJson) &&
      !isNullUndefined(props.signUp.signUpJson.facility)
    ) {
      setFacilityId(props.signUp.signUpJson.facility.id);
      if (
        !isNullUndefined(props) &&
        !isNullUndefined(props.signUp) &&
        !isNullUndefined(props.signUp.signUpJson) &&
        !isNullUndefined(props.signUp.signUpJson.facility) &&
        !isNullUndefined(props.signUp.signUpJson.facility.units) &&
        isArray(props.signUp.signUpJson.facility.units)
      ) {
        setUnits(props.signUp.signUpJson.facility.units);
      }
      if (
        !isNullUndefined(props) &&
        !isNullUndefined(props.signUp) &&
        !isNullUndefined(props.signUp.signUpJson) &&
        !isNullUndefined(props.signUp.signUpJson.facility) &&
        isArray(props.signUp.signUpJson.utilities)
      ) {
        setUtilities(props.signUp.signUpJson.utilities);
      }
    }

    if (
      !isNullUndefined(props) &&
      !isNullUndefined(props.signUp) &&
      !isNullUndefined(props.signUp.signUpJson) &&
      !isNullUndefined(props.signUp.signUpJson.subscriptionService) &&
      !isNullUndefined(
        props.signUp.signUpJson.subscriptionService.serviceCode
      ) &&
      !isEmptyString(props.signUp.signUpJson.subscriptionService.serviceCode)
    ) {
      if (
        (props.signUp.signUpJson.subscriptionService.serviceCode === "CORE" ||
          props.signUp.signUpJson.subscriptionService.serviceCode === "LEAN") &&
        !isNullUndefined(
          props.signUp.signUpJson.subscriptionService.addUtilitiesSupport
        ) &&
        props.signUp.signUpJson.subscriptionService.addUtilitiesSupport
      ) {
        setShowUnits(true);
      }

      if (
        props.signUp.signUpJson.subscriptionService.serviceCode === "PREMIUM" ||
        props.signUp.signUpJson.subscriptionService.serviceCode === "HOA"
      ) {
        setShowUnits(true);
      }

      if (props.signUp.signUpJson.subscriptionService.serviceCode === "METER") {
        //set meter billing
        if (
          !isNullUndefined(props) &&
          !isNullUndefined(props.signUp) &&
          !isNullUndefined(props.signUp.signUpJson) &&
          !isNullUndefined(props.signUp.signUpJson.meterBillings) &&
          isArray(props.signUp.signUpJson.meterBillings)
        ) {
          setMeterBillings(props.signUp.signUpJson.meterBillings);
        }
      }

      if (props.signUp.signUpJson.subscriptionService.serviceCode === "BLWTR") {
        setMeterTypes([]);
        //set meter billing
        if (
          !isNullUndefined(props) &&
          !isNullUndefined(props.signUp) &&
          !isNullUndefined(props.signUp.signUpJson) &&
          !isNullUndefined(props.signUp.signUpJson.customers) &&
          isArray(props.signUp.signUpJson.customers)
        ) {
          setCustomers(props.signUp.signUpJson.customers);
        }
        setMeterTypes([
          { label: "Retail Water Sales - Property", value: "RTL" },
          { label: "Bulk Water Sales - Property", value: "BLK" },
        ]);
        setReadingFrequencyCd("DAY");
      }
    }
  }, [props.signUp]);

  useEffect(() => {
    populateUtilities();
  }, [
    utilities,
    addMeterType,
    addRateTypeCode,
    addReadingFrequency,
    addStandingCharge,
    addRate,
    rateTypeOptions,
    meterTypes,
    meterReadingPeriods,
  ]);

  useEffect(() => {
    tieredRates.sort((a, b) => a.min - b.min);
    populateTieredRates();
    if (addRateTypeCode && rateTypeCode === "TIERD" && tieredRates.length > 0) {
      let tmpUtilities = [];
      utilities &&
        utilities.length > 0 &&
        utilities.forEach(function (utility, i) {
          let tmpUtility = utility;
          let rates = [];
          tieredRates &&
            tieredRates.length > 0 &&
            tieredRates.forEach(function (rate, i) {
              rates.push({
                id: getIdRefNumber(),
                meterId: tmpUtility.id,
                rateTypeCode: "TIERD",
                amount: rate.amount,
                min: rate.min,
                max: rate.max,
                effectiveDate: new Date(),
                expirationDate: null,
              });
            });
          tmpUtility.rates = rates;
          tmpUtilities.push(tmpUtility);
        });
      setUtilities(tmpUtilities);
      populateUtilities();
    }
  }, [tieredRates]);

  function populateUtilities() {
    let meterNameWidth,
      meterTypeWidth = "13%";
    if (props.signUp.signUpJson.subscriptionService.serviceCode === "BLWTR") {
      meterNameWidth = "30%";
      meterTypeWidth = "20%";
    }

    if (props.signUp.signUpJson.subscriptionService.serviceCode === "MAINT") {
      meterNameWidth = "45%";
      meterTypeWidth = "45%";
    }

    let rows =
      utilities &&
      utilities.length > 0 &&
      utilities.map(function (utility, i) {
        return (
          <TableRow
            key={i}
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            <TableCell
              component="th"
              scope="row"
              sx={{ width: meterNameWidth }}
            >
              <TextField
                variant="outlined"
                placeholder="Meter Name"
                id={"name" + i}
                name={"name" + i}
                isrequired={true}
                sx={{
                  backgroundColor: "#efefef",
                  borderRadius: "10px",
                  minWidth: "100px",
                }}
                value={utilities && utilities[i] && utilities[i].name}
                onChange={(event) => onTextChanged(event, i)}
              />
            </TableCell>

            {!addMeterType && (
              <TableCell align="left" sx={{ width: meterTypeWidth }}>
                <Select
                  sx={{ width: { xs: "100%", md: "90%" } }}
                  id={"meterTypeCode" + i}
                  name={"meterTypeCode" + i}
                  value={
                    utilities && utilities[i] && utilities[i].meterTypeCode
                  }
                  onChange={(event) => handleUtilitiesSelectChange(event, i)}
                >
                  {meterTypes && meterTypes.length > 0 ? (
                    meterTypes.map(function (meterType, i) {
                      return (
                        <MenuItem key={meterType.value} value={meterType.value}>
                          {meterType.label}
                        </MenuItem>
                      );
                    }, this)
                  ) : (
                    <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
                  )}
                </Select>
              </TableCell>
            )}

            {showUnits &&
              props.signUp.signUpJson.subscriptionService.serviceCode !==
                "MAINT" && (
                <TableCell align="left" sx={{ width: "13%" }}>
                  <Select
                    sx={{ width: { xs: "100%", md: "90%" } }}
                    id={"unitId" + i}
                    name={"unitId" + i}
                    value={utilities && utilities[i] && utilities[i].unitId}
                    onChange={(event) => handleUtilitiesSelectChange(event, i)}
                  >
                    {units && units.length > 0 ? (
                      units.map(function (unit, i) {
                        return (
                          <MenuItem key={unit.id} value={unit.id}>
                            {unit.name}
                          </MenuItem>
                        );
                      }, this)
                    ) : (
                      <MenuItem sx={{ width: "100%" }}>
                        No Results Found
                      </MenuItem>
                    )}
                  </Select>
                </TableCell>
              )}

            {!addReadingFrequency &&
              props.signUp.signUpJson.subscriptionService.serviceCode !==
                "MAINT" && (
                <TableCell
                  align="left"
                  sx={{
                    width:
                      props.signUp.signUpJson.subscriptionService
                        .serviceCode !== "BLWTR"
                        ? "13%"
                        : "20%",
                  }}
                >
                  <Select
                    sx={{ width: { xs: "100%", md: "90%" } }}
                    id={"readingFrequencyCd" + i}
                    name={"readingFrequencyCd" + i}
                    disabled={
                      props.signUp.signUpJson.subscriptionService
                        .serviceCode === "BLWTR"
                    }
                    value={
                      utilities &&
                      utilities[i] &&
                      utilities[i].readingFrequency &&
                      utilities[i].readingFrequency.code
                    }
                    onChange={(event) => handleUtilitiesSelectChange(event, i)}
                  >
                    {meterReadingPeriods && meterReadingPeriods.length > 0 ? (
                      meterReadingPeriods.map(function (period, i) {
                        return (
                          <MenuItem key={period.value} value={period.value}>
                            {period.label}
                          </MenuItem>
                        );
                      }, this)
                    ) : (
                      <MenuItem sx={{ width: "100%" }}>
                        No Results Found
                      </MenuItem>
                    )}
                  </Select>
                </TableCell>
              )}

            {!addRateTypeCode &&
              props.signUp.signUpJson.subscriptionService.serviceCode !==
                "BLWTR" &&
              props.signUp.signUpJson.subscriptionService.serviceCode !==
                "MAINT" && (
                <TableCell align="left" sx={{ width: "13%" }}>
                  <Select
                    sx={{ width: { xs: "100%", md: "90%" } }}
                    id={"rateTypeCode" + i}
                    name={"rateTypeCode" + i}
                    value={
                      (utilities &&
                        utilities[i] &&
                        utilities[i].rateTypeCode) ||
                      "FIX"
                    }
                    onChange={(event) => handleUtilitiesSelectChange(event, i)}
                  >
                    {rateTypeOptions && rateTypeOptions.length > 0 ? (
                      rateTypeOptions.map(function (rateType, i) {
                        return (
                          <MenuItem key={rateType.value} value={rateType.value}>
                            {rateType.label}
                          </MenuItem>
                        );
                      }, this)
                    ) : (
                      <MenuItem sx={{ width: "100%" }}>
                        No Results Found
                      </MenuItem>
                    )}
                  </Select>
                </TableCell>
              )}

            {!addRate &&
              props.signUp.signUpJson.subscriptionService.serviceCode !==
                "MAINT" && (
                <TableCell align="left">
                  <TextField
                    variant="outlined"
                    placeholder="Rate"
                    id={"rate" + i}
                    name={"rate" + i}
                    isrequired={true}
                    sx={{
                      backgroundColor: "#efefef",
                      borderRadius: "10px",
                      minWidth: "100px",
                    }}
                    value={utilities && utilities[i] && utilities[i].rate}
                    onChange={(event) => onTextChanged(event, i)}
                  />
                </TableCell>
              )}
            {!addStandingCharge &&
              props.signUp.signUpJson.subscriptionService.serviceCode !==
                "BLWTR" &&
              props.signUp.signUpJson.subscriptionService.serviceCode !==
                "MAINT" && (
                <TableCell align="left">
                  <TextField
                    variant="outlined"
                    placeholder="Standing Charge"
                    id={"standingCharge" + i}
                    name={"standingCharge" + i}
                    isrequired={true}
                    sx={{
                      backgroundColor: "#efefef",
                      borderRadius: "10px",
                      minWidth: "100px",
                    }}
                    value={
                      utilities && utilities[i] && utilities[i].standingCharge
                    }
                    onChange={(event) => onTextChanged(event, i)}
                  />
                </TableCell>
              )}
            {props.signUp.signUpJson.subscriptionService.serviceCode &&
              ["PREMIUM", "HOA", "METER"].includes(props.signUp.signUpJson.subscriptionService.serviceCode) && (
                <TableCell align="left">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          utilities && utilities[i] && utilities[i].invoiced
                        }
                        onChange={(event) => invoicedToggle(event, i)}
                        color="primary"
                      />
                    }
                  />
                </TableCell>
              )}
            {props.signUp.signUpJson.subscriptionService.serviceCode &&
              ["PREMIUM", "HOA", "METER"].includes(props.signUp.signUpJson.subscriptionService.serviceCode) && (
                <TableCell align="left">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          utilities && utilities[i] && utilities[i].isMeterTaxed
                        }
                        onChange={(event) => isMeterTaxedToggle(event, i)}
                        color="primary"
                      />
                    }
                  />
                </TableCell>
              )}
            {props.signUp.signUpJson.subscriptionService.serviceCode !==
              "BLWTR" &&
              props.signUp.signUpJson.subscriptionService.serviceCode !==
                "MAINT" && (
                <TableCell align="left">
                  {utilities &&
                    utilities[i] &&
                    utilities[i].rateTypeCode === "TIERD" && (
                      <IconButton
                        onClick={() => handleEditPriceRates(i)}
                        color="primary"
                        sx={{
                          backgroundColor: "#fff",
                          borderRadius: "10px",
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        <Preview
                          sx={{
                            fontSize: "2.5rem",
                          }}
                        />
                      </IconButton>
                    )}
                </TableCell>
              )}
            <TableCell align="left">
              <IconButton
                onClick={() => handleUtilityRemove(i)}
                color="error"
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "10px",
                  width: "100%",
                  height: "100%",
                }}
              >
                <Cancel
                  sx={{
                    fontSize: "2.5rem",
                  }}
                />
              </IconButton>
            </TableCell>
          </TableRow>
        );
      });
    setUtilitiesRows(rows);
  }

  function populateTieredRates() {
    let rows =
      tieredRates &&
      tieredRates.length > 0 &&
      tieredRates.map(function (rate, i) {
        return (
          <TableRow
            key={i}
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            <TableCell component="th" scope="row" sx={{ width: "30%" }}>
              <TextField
                variant="outlined"
                placeholder="Minimum"
                id={"min" + i}
                name={"min" + i}
                isrequired={true}
                sx={{
                  backgroundColor: "#efefef",
                  borderRadius: "10px",
                  minWidth: "100px",
                }}
                value={tieredRates && tieredRates[i] && tieredRates[i].min}
                onChange={(event) => onTieredTextChanged(event, i)}
              />
            </TableCell>

            <TableCell component="th" scope="row" sx={{ width: "30%" }}>
              <TextField
                variant="outlined"
                placeholder="Maximum"
                id={"max" + i}
                name={"max" + i}
                isrequired={true}
                sx={{
                  backgroundColor: "#efefef",
                  borderRadius: "10px",
                  minWidth: "100px",
                }}
                value={tieredRates && tieredRates[i] && tieredRates[i].max}
                onChange={(event) => onTieredTextChanged(event, i)}
              />
            </TableCell>

            <TableCell component="th" scope="row" sx={{ width: "30%" }}>
              <TextField
                variant="outlined"
                placeholder="Amount"
                id={"amount" + i}
                name={"amount" + i}
                isrequired={true}
                sx={{
                  backgroundColor: "#efefef",
                  borderRadius: "10px",
                  minWidth: "100px",
                }}
                value={tieredRates && tieredRates[i] && tieredRates[i].amount}
                onChange={(event) => onTieredTextChanged(event, i)}
              />
            </TableCell>

            {tieredRates && tieredRates.length > 1 && (
              <TableCell align="left">
                <IconButton
                  onClick={() => handlePriceRateDelete(i)}
                  color="error"
                  sx={{
                    backgroundColor: "#fff",
                    borderRadius: "10px",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <Cancel
                    sx={{
                      fontSize: "2.5rem",
                    }}
                  />
                </IconButton>
              </TableCell>
            )}
          </TableRow>
        );
      });
    setTieredRatesRows(rows);
  }

  function handleUtilityAdd() {
    let mType,
      rFreq,
      std,
      mRateTypeCode,
      rateA = "";
    let rates = [];
    let meterId = getIdRefNumber();
    if (addRateTypeCode) {
      mRateTypeCode = rateTypeCode;
      if (mRateTypeCode === "TIERD" && tieredRates.length > 0) {
        tieredRates &&
          tieredRates.length > 0 &&
          tieredRates.forEach(function (rate, i) {
            rates.push({
              id: getIdRefNumber(),
              meterId: meterId,
              rateTypeCode: "TIERD",
              amount: rate.amount,
              min: rate.min,
              max: rate.max,
              effectiveDate: new Date(),
              expirationDate: null,
            });
          });
      }
    }

    if (addMeterType) {
      mType = meterType;
    }
    if (
      addReadingFrequency ||
      props.signUp.signUpJson.subscriptionService.serviceCode === "BLWTR"
    ) {
      rFreq = readingFrequencyCd;
    }
    if (addStandingCharge) {
      std = standingCharge;
    }
    if (addRate) {
      rateA = rate;
    }

    setUtilities([
      ...utilities,
      {
        id: meterId,
        name: "",
        meterTypeCode: mType,
        statusCode: "ACT",
        facilityId: facilityId,
        rateTypeCode: mRateTypeCode,
        unitId: "",
        readingFrequency: {
          code: rFreq,
          name: "",
        },
        standingCharge: std,
        rate: rateA,
        invoiced: false,
        broken: false,
        brokenTime: null,
        unitName: null,
        reportedBy: null,
        complexId: null,
        rates: rates,
        isMeterTaxed: false
      },
    ]);
  }

  function handlePriceRateAdd() {
    tieredRates.sort((a, b) => a.min - b.min);
    setTieredRates([
      ...tieredRates,
      {
        amount: 0,
        min:
          tieredRates.length < 1
            ? 0
            : parseInt(tieredRates[tieredRates.length - 1].max + 1),
        max:
          tieredRates.length < 1
            ? 100
            : parseInt(tieredRates[tieredRates.length - 1].max + 100),
      },
    ]);
  }

  function handlePriceRateDelete(index) {
    let tmp = tieredRates;
    let lastIndex = tmp.length - 1;
    tmp.splice(index, 1);
    if (index === lastIndex) {
      tmp[lastIndex - 1].max = 1000000000;
    } else {
      tmp[0].min = 0;
    }
    setTieredRates(tmp);
    populateTieredRates();

    if (addRateTypeCode && rateTypeCode === "TIERD" && tieredRates.length > 0) {
      let tmpUtilities = [];
      utilities &&
        utilities.length > 0 &&
        utilities.forEach(function (utility, i) {
          let tmpUtility = utility;
          let rates = [];
          tieredRates &&
            tieredRates.length > 0 &&
            tieredRates.forEach(function (rate, i) {
              rates.push({
                id: getIdRefNumber(),
                meterId: tmpUtility.id,
                rateTypeCode: "TIERD",
                amount: rate.amount,
                min: rate.min,
                max: rate.max,
                effectiveDate: new Date(),
                expirationDate: null,
              });
            });
          tmpUtility.rates = rates;
          tmpUtilities.push(tmpUtility);
        });
      setUtilities(tmpUtilities);
      populateUtilities();
    }
  }

  function handleUtilityRemove(index) {
    let meterToRemove = utilities.filter((u, i) => i === index)[0];
    if (isArray(meterBillings) && meterBillings.length > 0) {
      let meterBillingsTmp = [];
      meterBillings.forEach(function (meterBilling, i) {
        let billing = meterBilling;
        let billingChargeItems = meterBilling.billingChargeItems.filter(
          (c) => c.meterId !== meterToRemove.id
        );
        billing.billingChargeItems = billingChargeItems;
        let meterIds = [];
        let meterNameArray = [];
        let meterNames = "";

        for (let charge of billingChargeItems) {
          meterIds.push(charge.meterId);
        }
        meterIds = [...new Set(meterIds)];
        for (let id of meterIds) {
          let meter = utilities.filter((u) => u.id === id);
          if (meter.length > 0) {
            meterNameArray.push(meter[0].name);
          }
        }
        meterNameArray = [...new Set(meterNameArray)];
        if (meterNameArray.length > 1) {
          meterNames = meterNameArray.join(",");
        } else {
          meterNames = meterNameArray[0];
        }
        billing.meterNames = meterNames;
        billing.meterIds = meterIds;
        meterBillingsTmp.push(billing);
      });
      setMeterBillings(meterBillingsTmp);
    }
    setUtilities(utilities.filter((utility, i) => i !== index));
  }

  function handleEditPriceRates(index) {
    let utility = utilities.filter((utility, i) => i === index)[0];
    setPriceRateModal(true);
    setSelectedUtility(utility);
  }

  function handleHeadersSelectChange(event) {
    let value = event.target.value;
    let fieldName = event.target.name;
    let newArr = [...utilities];
    if (fieldName === "meterTypeCode") {
      setMeterType(value);
      if (addMeterType) {
        newArr &&
          newArr.length > 0 &&
          newArr.forEach(function (utility, i) {
            newArr[i].meterTypeCode = value;
          });
      }
    }
    if (fieldName === "readingFrequencyCd") {
      setReadingFrequencyCd(value);
      if (addReadingFrequency) {
        newArr &&
          newArr.length > 0 &&
          newArr.forEach(function (utility, i) {
            newArr[i].readingFrequency.code = value;
          });
      }
    }
    if (fieldName === "rateTypeCode") {
      setRateTypeCode(value);
      if (addRateTypeCode) {
        newArr &&
          newArr.length > 0 &&
          newArr.forEach(function (utility, i) {
            newArr[i].rateTypeCode = value;
          });
      }
    }
    setUtilities(newArr);
  }

  function onHeadersTextChanged(event) {
    let fieldName = event.target.name;
    let value = event.target.value;
    let newArr = [...utilities];
    if (fieldName === "standingCharge") {
      setStandingCharge(value);
      if (addStandingCharge) {
        newArr &&
          newArr.length > 0 &&
          newArr.forEach(function (utility, i) {
            newArr[i].standingCharge = value;
          });
      }
    }
    if (fieldName === "rate") {
      setRate(value);
      if (addRate) {
        newArr &&
          newArr.length > 0 &&
          newArr.forEach(function (utility, i) {
            newArr[i].rate = value;
          });
      }
    }
    setUtilities(newArr);
  }

  function handleUtilitiesSelectChange(event, index) {
    let value = event.target.value;
    let fieldName = event.target.name;
    let newArr = [...utilities];
    if (fieldName === "meterTypeCode" + index) {
      newArr[index].meterTypeCode = value;
    }
    if (fieldName === "unitId" + index) {
      newArr[index].unitId = value;
    }
    if (fieldName === "readingFrequencyCd" + index) {
      newArr[index].readingFrequency.code = value;
    }
    if (fieldName === "rateTypeCode" + index) {
      newArr[index].rateTypeCode = value;
    }
    setUtilities(newArr);
  }

  function onTextChanged(event, index) {
    let newArr = [...utilities];
    let fieldName = event.target.name;
    let value = event.target.value;

    if (fieldName === "name" + index) {
      newArr[index].name = value;
    }
    if (fieldName === "standingCharge" + index) {
      newArr[index].standingCharge = value;
    }

    if (fieldName === "rate" + index) {
      newArr[index].rate = value;
    }
    setUtilities(newArr);
  }

  function onTieredTextChanged(event, index) {
    let newArr = [...tieredRates];
    let fieldName = event.target.name;
    let value = event.target.value;

    if (fieldName === "min" + index) {
      if (!isNullUndefined(value)) {
        try {
          if (isNumeric(value)) {
            // passed in an actual number instead of str so can still be returned as right
            newArr[index].min = parseInt(value, 0);
          } else {
            if (value.length === 0) {
              newArr[index].min = "";
            }
            issueWarnMessage("Only numbers are allowed.");
          }
        } catch (e) {
          issueWarnMessage(e);
        }
      }
    }
    if (fieldName === "amount" + index) {
      if (!isNullUndefined(value)) {
        try {
          if (isNumeric(value)) {
            // passed in an actual number instead of str so can still be returned as right
            newArr[index].amount = parseInt(value, 0);
          } else {
            if (value.length === 0) {
              newArr[index].amount = "";
            }
            issueWarnMessage("Only numbers are allowed.");
          }
        } catch (e) {
          issueWarnMessage(e);
        }
      }
    }

    if (fieldName === "max" + index) {
      if (!isNullUndefined(value)) {
        try {
          if (isNumeric(value)) {
            // passed in an actual number instead of str so can still be returned as right
            newArr[index].max = parseInt(value, 0);
          } else {
            if (value.length === 0) {
              newArr[index].max = "";
            }
            issueWarnMessage("Only numbers are allowed.");
          }
        } catch (e) {
          issueWarnMessage(e);
        }
      }
    }
    setTieredRates(newArr);
  }

  function saveTier(utility) {
    let newArr = [...utilities];
    let index = newArr.findIndex((x) => x.id === utility.id);
    newArr[index] = utility;
    setUtilities(newArr);
    handlePriceRateClose();
  }

  function handlePriceRateClose() {
    setPriceRateModal(false);
    setSelectedUtility(null);
  }

  function invoicedToggle(event, index) {
    let newArr = [...utilities];
    newArr[index].invoiced = event.target.checked;
    setUtilities(newArr);
  }

  function isMeterTaxedToggle(event, index) {
    if(utilities[index].invoiced) {
      let newArr = [...utilities];
      newArr[index].isMeterTaxed = event.target.checked;
      setUtilities(newArr);
    } else {
      issueWarnMessage("Only an invoiced meter can be taxed!")
    }
  }

  function saveAndFinishLater() {
    let signUp;
    const buttonIndex = "3.1";
    signUp = props.signUp;
    signUp.buttonIndex = buttonIndex;
    signUp.signUpJson.utilities = utilities;
    signUp.signUpJson.meterBillings = meterBillings;
    signUp.signUpJson.customers = customers;
    if (isNullUndefined(signUp)) {
      issueWarnMessage("Please fill in the form.");
    } else {
      if (validateSaveAndFinishLater(signUp)) {
        props.saveSignUpData(signUp);
      }
    }
  }

  function validateSaveAndFinishLater(signUp) {
    // console.log("validateSaveAndFinishLater signUp: ", signUp)
    // Validate
    if (isNullUndefined(signUp)) {
      issueWarnMessage("Could not determine signUp.");
      return false;
    }

    if (isNullUndefined(signUp.signUpJson.facility)) {
      issueWarnMessage("Could not determine facility.");
      return false;
    }

    if (
      showUnits &&
      (isNullUndefined(signUp.signUpJson.facility.units) ||
        !isArray(signUp.signUpJson.facility.units) ||
        isEmptyArray(signUp.signUpJson.facility.units))
    ) {
      issueWarnMessage("Could not determine units.");
      return false;
    }

    if (
      showUnits &&
      isArray(signUp.signUpJson.facility.units) &&
      signUp.signUpJson.facility.units.length < 1
    ) {
      issueWarnMessage("There should be at least one unit.");
      return false;
    }

    if (
      isNullUndefined(signUp.signUpJson.utilities) ||
      !isArray(signUp.signUpJson.utilities) ||
      isEmptyArray(signUp.signUpJson.utilities)
    ) {
      issueWarnMessage("Could not determine utilities.");
      return false;
    }

    if (
      isArray(signUp.signUpJson.utilities) &&
      signUp.signUpJson.utilities.length < 1
    ) {
      issueWarnMessage("There should be at least one utilities.");
      return false;
    }

    if (isArray(signUp.signUpJson.utilities)) {
      for (let utility of signUp.signUpJson.utilities) {
        if (isNullUndefined(utility.name) || isEmptyString(utility.name)) {
          issueWarnMessage("Utility name is invalid. Cannot be blank.");
          return false;
        }

        if (
          isNullUndefined(utility.meterTypeCode) ||
          isEmptyString(utility.meterTypeCode)
        ) {
          issueWarnMessage("meter Type is invalid. Cannot be blank.");
          return false;
        }

        if (
          props.signUp.signUpJson.subscriptionService.serviceCode !== "MAINT" &&
          (isNullUndefined(utility.readingFrequency) ||
            isNullUndefined(utility.readingFrequency.code) ||
            isEmptyString(utility.readingFrequency.code))
        ) {
          issueWarnMessage("Frequency is invalid. Cannot be blank.");
          return false;
        }

        if (
          isNullUndefined(utility.facilityId) ||
          isEmptyString(utility.facilityId)
        ) {
          issueWarnMessage("Facility id is invalid. Cannot be blank.");
          return false;
        }

        if (
          signUp.signUpJson.utilities.filter(
            (utilityFilter, i) => utility.name === utilityFilter.name
          ).length > 1
        ) {
          issueWarnMessage("There is duplicated utility name.");
          return false;
        }
      }
    }
    return true;
  }

  function validateSaveAndContinue(signUp) {
    // Validate
    if (validateSaveAndFinishLater(signUp)) {
      if (isArray(signUp.signUpJson.utilities)) {
        for (let utility of signUp.signUpJson.utilities) {
          let standingCharge = tryParseFloat(utility.standingCharge, -1);
          let rate = tryParseFloat(utility.rate, -1);
          if (
            !isNullUndefined(utility.standingCharge) &&
            !isEmptyString(utility.standingCharge) &&
            standingCharge < 0
          ) {
            issueWarnMessage(
              "Utility  standing charge  is invalid. Should be a number."
            );
            return false;
          }

          if (
            !isNullUndefined(utility.rate) &&
            !isEmptyString(utility.rate) &&
            rate < 0
          ) {
            issueWarnMessage("Utility rate is invalid. Should be a number.");
            return false;
          }

          if (
            props.signUp.signUpJson.subscriptionService.serviceCode ===
              "BLWTR" &&
            (isNullUndefined(utility.rate) ||
              isEmptyString(utility.rate) ||
              rate < 0)
          ) {
            issueWarnMessage("Utility rate is invalid. Should be a number");
            return false;
          }

          if (utility.rateTypeCode === "TIERD") {
            let invalidData = false;
            if (isNullUndefined(utility.rates) || utility.rates.length <= 0) {
              issueWarnMessage("Must add price rates for TIERED rate type.");
              return false;
            } else if (
              utility.rates[utility.rates.length - 1].max !== 1000000000
            ) {
              issueWarnMessage(
                "Max Quantity for the last tier must be equal to 1,000,000,000."
              );
              return false;
            } else {
              utility.rates.forEach((rate, index) => {
                let theIndex = index + 1;
                let minQuantity = !isNullUndefined(rate.min)
                  ? rate.min.toString()
                  : "";
                if (!isEmptyString(minQuantity)) {
                  let min = rate.min;
                  if (min < 0) {
                    let message =
                      "The value entered for min number of units for price rate " +
                      theIndex +
                      " is invalid";
                    issueWarnMessage(message);
                    invalidData = true;
                  }
                } else {
                  let message =
                    "Invalid value entered for min units for price rate " +
                    theIndex;
                  issueWarnMessage(message);
                  invalidData = true;
                }

                let maxQuantity = !isNullUndefined(rate.max)
                  ? rate.max.toString()
                  : "";
                if (!isEmptyString(maxQuantity)) {
                  let max = rate.max;
                  if (max <= 0) {
                    let message =
                      "The value entered for max number of units for price rate " +
                      theIndex +
                      " is invalid";
                    issueWarnMessage(message);
                    invalidData = true;
                  }
                } else {
                  let message =
                    "Invalid value entered for max units for price rate " +
                    theIndex;
                  issueWarnMessage(message);
                  invalidData = true;
                }

                let rateAmount = !isNullUndefined(rate.amount)
                  ? rate.amount.toString()
                  : "";
                if (!isEmptyString(rateAmount)) {
                  let amount = tryParseFloat(rate.amount, -1);
                  if (amount < 0) {
                    let message =
                      "The value entered for amount for price rate " +
                      theIndex +
                      " is invalid";
                    issueWarnMessage(message);
                    invalidData = true;
                  }
                } else {
                  let message =
                    "Invalid value entered for amount for price rate " +
                    theIndex;
                  issueWarnMessage(message);
                  invalidData = true;
                }

                if (index > 0) {
                  if (rate.min < utility.rates[index - 1].max) {
                    let message =
                      "A minimum price rate cannot be lesser than the maximum price rate of the previous tier!";
                    issueWarnMessage(message);
                    invalidData = true;
                  }
                }
              });
            }

            if (invalidData) return false;
          }
        }
      }
      return true;
    }
  }

  function saveAndContinue() {
    let signUp;
    const buttonIndex = "3.2";
    signUp = props.signUp;
    signUp.buttonIndex = buttonIndex;
    signUp.signUpJson.utilities = utilities;
    signUp.signUpJson.meterBillings = meterBillings;
    signUp.signUpJson.customers = customers;
    if (isNullUndefined(signUp)) {
      issueWarnMessage("Please fill in the form.");
    } else {
      if (validateSaveAndContinue(signUp)) {
        props.saveSignUpData(signUp);
      }
    }
  }

  return (
    <ResponsiveRow
      sx={{
        flexDirection: "column",
        flexWrap: "nowrap",
        width: "100%",
        height: "auto",
      }}
    >
      <ResponsiveRow
        sx={{
          textAlign: "start",
          justifyContent: "flex-start",
          alignItems: "center",
          padding: "10px",
          width: "100%",
          height: "auto",
          overflowX: "hidden",
        }}
      >
        <Typography
          sx={{
            fontSize: "1.15rem",
            fontWeight: 600,
            marginTop: "5px",
            width: "100%",
          }}
        >
          Utility Details
        </Typography>
      </ResponsiveRow>

      <ResponsiveRow
        sx={{
          height: "auto",
          padding: "20px",
        }}
      >
        {props.signUp.signUpJson.subscriptionService.serviceCode !==
          "MAINT" && (
          <Grid
            item
            sx={{
              width: { xs: "100%", lg: "20%" },
              textAlign: "start",
              marginBottom: "10px",
            }}
          >
            <FormControlLabel
              sx={{ fontSize: "1.2rem", fontWeight: 600 }}
              label="Reading Frequency"
              control={
                <Checkbox
                  value={addReadingFrequency}
                  checked={addReadingFrequency}
                  onChange={() => setAddReadingFrequency(!addReadingFrequency)}
                  color="primary"
                />
              }
            />
            {addReadingFrequency && (
              <Select
                sx={{ width: { xs: "100%", md: "90%" } }}
                id={"readingFrequencyCd"}
                name={"readingFrequencyCd"}
                value={readingFrequencyCd}
                disabled={
                  !addReadingFrequency ||
                  props.signUp.signUpJson.subscriptionService.serviceCode ===
                    "BLWTR"
                }
                onChange={(event) => handleHeadersSelectChange(event)}
              >
                {meterReadingPeriods && meterReadingPeriods.length > 0 ? (
                  meterReadingPeriods.map(function (period, i) {
                    return (
                      <MenuItem key={period.value} value={period.value}>
                        {period.label}
                      </MenuItem>
                    );
                  }, this)
                ) : (
                  <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
                )}
              </Select>
            )}
          </Grid>
        )}
        <Grid
          item
          sx={{
            width: { xs: "100%", lg: "20%" },
            textAlign: "start",
            marginBottom: "10px",
          }}
        >
          <FormControlLabel
            sx={{ fontSize: "1.2rem", fontWeight: 600 }}
            label="Meter Type"
            control={
              <Checkbox
                value={addMeterType}
                checked={addMeterType}
                onChange={() => setAddMeterType(!addMeterType)}
                color="primary"
              />
            }
          />
          {addMeterType && (
            <Select
              sx={{ width: { xs: "100%", md: "90%" } }}
              id={"meterTypeCode"}
              name={"meterTypeCode"}
              disabled={!addMeterType}
              value={meterType}
              onChange={(event) => handleHeadersSelectChange(event)}
            >
              {meterTypes && meterTypes.length > 0 ? (
                meterTypes.map(function (meterType, i) {
                  return (
                    <MenuItem key={meterType.value} value={meterType.value}>
                      {meterType.label}
                    </MenuItem>
                  );
                }, this)
              ) : (
                <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
              )}
            </Select>
          )}
        </Grid>
        {props.signUp.signUpJson.subscriptionService.serviceCode !== "BLWTR" &&
          props.signUp.signUpJson.subscriptionService.serviceCode !==
            "MAINT" && (
            <Grid
              item
              sx={{
                width: { xs: "100%", lg: "20%" },
                textAlign: "start",
                marginBottom: "10px",
              }}
            >
              <FormControlLabel
                sx={{ fontSize: "1.2rem", fontWeight: 600 }}
                label="Rate Type"
                control={
                  <Checkbox
                    value={addRateTypeCode}
                    checked={addRateTypeCode}
                    onChange={() => setAddRateTypeCode(!addRateTypeCode)}
                    color="primary"
                  />
                }
              />
              {addRateTypeCode && (
                <Select
                  sx={{ width: { xs: "100%", md: "90%" } }}
                  id={"rateTypeCode"}
                  name={"rateTypeCode"}
                  disabled={!addRateTypeCode}
                  value={rateTypeCode}
                  onChange={(event) => handleHeadersSelectChange(event)}
                >
                  {rateTypeOptions && rateTypeOptions.length > 0 ? (
                    rateTypeOptions.map(function (rateType, i) {
                      return (
                        <MenuItem key={rateType.value} value={rateType.value}>
                          {rateType.label}
                        </MenuItem>
                      );
                    }, this)
                  ) : (
                    <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
                  )}
                </Select>
              )}
            </Grid>
          )}

        {props.signUp.signUpJson.subscriptionService.serviceCode !== "BLWTR" &&
          props.signUp.signUpJson.subscriptionService.serviceCode !==
            "MAINT" && (
            <Grid
              item
              sx={{
                width: { xs: "100%", lg: "20%" },
                textAlign: "start",
                marginBottom: "10px",
              }}
            >
              <FormControlLabel
                sx={{ fontSize: "1.2rem", fontWeight: 600 }}
                label="Standing charge"
                control={
                  <Checkbox
                    value={addStandingCharge}
                    checked={addStandingCharge}
                    onChange={() => setAddStandingCharge(!addStandingCharge)}
                    color="primary"
                  />
                }
              />
              {addStandingCharge && (
                <TextField
                  sx={{ width: "90%" }}
                  disabled={!addStandingCharge}
                  value={standingCharge}
                  name={"standingCharge"}
                  onChange={(event) => onHeadersTextChanged(event)}
                  label="Amount Ksh"
                />
              )}
            </Grid>
          )}
        {props.signUp.signUpJson.subscriptionService.serviceCode !==
          "MAINT" && (
          <Grid
            item
            sx={{
              width: { xs: "100%", lg: "20%" },
              textAlign: "start",
              marginBottom: "10px",
            }}
          >
            <FormControlLabel
              sx={{ fontSize: "1.2rem", fontWeight: 600 }}
              label="Rate"
              control={
                <Checkbox
                  value={addRate}
                  checked={addRate}
                  onChange={() => setAddRate(!addRate)}
                  color="primary"
                />
              }
            />
            {addRate && (
              <TextField
                sx={{ width: "90%" }}
                disabled={!addRate}
                value={rate}
                name={"rate"}
                onChange={(event) => onHeadersTextChanged(event)}
                label="Amount Ksh"
              />
            )}
          </Grid>
        )}
      </ResponsiveRow>

      {addRateTypeCode && rateTypeCode === "TIERD" && (
        <ResponsiveRow
          sx={{
            justifyContent: "flex-start",
            alignItems: "flex-start",
            height: "auto",
            width: "100%",

            borderRadius: "10px",
          }}
        >
          <Typography
            sx={{ fontSize: "1.15rem", fontWeight: 600, marginTop: "5px" }}
          >
            Tiered Rates Table
          </Typography>
          <TableContainer sx={{ width: "100%" }}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Minimum Units</TableCell>
                  <TableCell align="left">Maximum Units</TableCell>
                  <TableCell align="left">Charge per Unit</TableCell>
                  <TableCell align="left"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{tieredRatesRows}</TableBody>
            </Table>
          </TableContainer>
          <ResponsiveRow
            sx={{
              justifyContent: { xs: "center", lg: "flex-end" },
              height: "100%",
            }}
          >
            <ModalAcceptButton
              onClick={handlePriceRateAdd}
              sx={{
                width: { xs: "100%", lg: "200px" },
                height: "fit-content",
              }}
            >
              Add Tier
            </ModalAcceptButton>
          </ResponsiveRow>
        </ResponsiveRow>
      )}

      <ResponsiveRow
        sx={{
          justifyContent: "flex-start",
          alignItems: "flex-start",
          height: "auto",
          width: "100%",
        }}
      >
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                {!addMeterType && <TableCell align="left">Type</TableCell>}
                {showUnits &&
                  props.signUp.signUpJson.subscriptionService.serviceCode !==
                    "MAINT" && <TableCell align="left">Unit</TableCell>}
                {!addReadingFrequency &&
                  props.signUp.signUpJson.subscriptionService.serviceCode !==
                    "MAINT" && <TableCell align="left">Frequency</TableCell>}
                {!addRateTypeCode &&
                  props.signUp.signUpJson.subscriptionService.serviceCode !==
                    "BLWTR" &&
                  props.signUp.signUpJson.subscriptionService.serviceCode !==
                    "MAINT" && <TableCell align="left">Rate Type</TableCell>}
                {!addRate &&
                  props.signUp.signUpJson.subscriptionService.serviceCode !==
                    "MAINT" && <TableCell align="left">Rate</TableCell>}
                {!addStandingCharge &&
                  props.signUp.signUpJson.subscriptionService.serviceCode !==
                    "BLWTR" &&
                  props.signUp.signUpJson.subscriptionService.serviceCode !==
                    "MAINT" && (
                    <TableCell align="left">Standing Charge</TableCell>
                  )}
                {
                   props.signUp.signUpJson.subscriptionService.serviceCode &&
                   ["PREMIUM", "HOA", "METER"].includes(props.signUp.signUpJson.subscriptionService.serviceCode) ? (
                     <TableCell align="left">Taxed</TableCell>
                   ) : null
                }
                {
                  props.signUp.signUpJson.subscriptionService.serviceCode &&
                  ["PREMIUM", "HOA", "METER"].includes(props.signUp.signUpJson.subscriptionService.serviceCode) ? (
                    <TableCell align="left">Taxed</TableCell>
                  ) : null
                }
                {props.signUp.signUpJson.subscriptionService.serviceCode !==
                  "BLWTR" &&
                  props.signUp.signUpJson.subscriptionService.serviceCode !==
                    "MAINT" && <TableCell align="left">Tiered Rates</TableCell>}
                <TableCell align="left"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{utilitiesRows}</TableBody>
          </Table>
        </TableContainer>
        <ResponsiveRow
          sx={{
            justifyContent: { xs: "center", lg: "flex-end" },
            height: "auto",
          }}
        >
          <ModalAcceptButton
            onClick={handleUtilityAdd}
            sx={{ width: { xs: "100%", lg: "200px" }, height: "fit-content" }}
          >
            Add Meter
          </ModalAcceptButton>
        </ResponsiveRow>
      </ResponsiveRow>

      <ResponsiveRow
        sx={{
          justifyContent: { xs: "center", lg: "flex-end" },
          width: "100%",
          height: "auto",
        }}
      >
        <ModalCancelButton
          onClick={props.handleBackButtonClicked}
          sx={{
            width: { xs: "100%", lg: "200px" },
          }}
        >
          Go Back
        </ModalCancelButton>
        <ModalAcceptButton
          onClick={saveAndFinishLater}
          sx={{
            width: { xs: "100%", lg: "200px" },
            height: "50px",
          }}
        >
          Save & Finish Later
        </ModalAcceptButton>

        <ModalAcceptButton
          onClick={saveAndContinue}
          sx={{
            width: { xs: "100%", lg: "200px" },
            height: "50px",
          }}
        >
          Save & Continue
        </ModalAcceptButton>
      </ResponsiveRow>

      {/*CREATE TIERED PRICING MODAL*/}
      {priceRateModal && (
        <PriceRate
          open={priceRateModal}
          onClose={handlePriceRateClose}
          selectedUtility={selectedUtility}
          saveTier={saveTier}
        />
      )}
    </ResponsiveRow>
  );
}
