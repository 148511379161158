import axios from 'axios';
import { store } from '../store';
import {
	handleErrorResponse,
	issueInfoMessage,
	issueResponseMessage,
} from '../actions/message';
import authService from './auth.service';
import { exportReport } from './reports.service';

/**
 * basic axios request with application level interceptors for GET
 * @type {AxiosInstance}
 */
const serviceAPI = axios.create();
serviceAPI.interceptors.request.use(
	(config) => {
		const user = store.getState().auth.user;
		if (user) {
			//config.headers.Authorization = `Bearer ${token}`
			config.headers = {
				proxystate:
					user.proxyRole +
					', ' +
					user.proxySubscription +
					', ' +
					user.proxyFacility,
				Authorization: `Bearer ${user.accessToken}`,
			};
		} else {
			delete serviceAPI.defaults.headers.common.Authorization;
		}
		return config;
	},
	(error) => Promise.reject(error)
);
serviceAPI.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => {
		// console.log('############# error ', error)
		if (
			error &&
			error.response &&
			error.response.status &&
			error.response.status === 401
		) {
			authService.getAccessToken();
		} else if (
			error &&
			error.response &&
			error.response.status &&
			error.response.status === 400
		) {
			//handle a BadRequest response
			handleErrorResponse(error.response);
			return error.response;
			// Promise.reject(error.response)
		} else {
			return error;
		}
	}
);

/**
 * basic axios request with application level interceptors for POST
 * @type {AxiosInstance}
 */
const serviceAPIJSON = axios.create();
serviceAPIJSON.interceptors.request.use(
	(config) => {
		const user = store.getState().auth.user;
		if (user) {
			config.headers = {
				Accept: 'application/json charset=utf-8',
				'Content-type': 'application/json charset=utf-8',
				proxystate:
					user.proxyRole +
					', ' +
					user.proxySubscription +
					', ' +
					user.proxyFacility,
				Authorization: `Bearer ${user.accessToken}`,
			};
		} else {
			config.headers = {
				Accept: 'application/json charset=utf-8',
				'Content-type': 'application/json charset=utf-8',
			};
		}
		return config;
	},
	(error) => Promise.reject(error)
);
serviceAPIJSON.interceptors.response.use(
	(response) => {
		return response;
	},
	function (error) {
		if (
			error &&
			error.response &&
			error.response.status &&
			error.response.status === 401
		) {
			authService.getAccessToken();
		} else if (
			error &&
			error.response &&
			error.response.status &&
			error.response.status === 400
		) {
			//handle a BadRequest response
			return error.response;
		} else {
			return error;
		}
	}
);

/**
 * basic axios request with application level interceptors for file download
 * @type {AxiosInstance}
 */
const serviceAPIDownloadFile = axios.create();
serviceAPIDownloadFile.interceptors.request.use(
	(config) => {
		const user = store.getState().auth.user;
		if (user) {
			config.headers = {
				proxystate:
					user.proxyRole +
					', ' +
					user.proxySubscription +
					', ' +
					user.proxyFacility,
				Authorization: `Bearer ${user.accessToken}`,
			};
			config.responseType = 'blob';
		} else {
			//delete serviceAPI.defaults.headers.common.Authorization
			config.responseType = 'blob';
		}
		return config;
	},
	(error) => {
		if (
			error &&
			error.response &&
			error.response.status &&
			error.response.status === 401
		) {
			authService.getAccessToken();
		} else if (
			error &&
			error.response &&
			error.response.status &&
			error.response.status === 400
		) {
			//handle a BadRequest response
			return error.response;
		} else {
			return error;
		}
	}
);
serviceAPIDownloadFile.interceptors.response.use(
	(response) => {
		//Handle blob response here
		//extract the file name from the header
		let filename = response.headers['content-disposition']
			.split('filename=')[1]
			.replaceAll('"', '');
		let extension = filename.split('.')[1].toLowerCase();
		const url = window.URL.createObjectURL(response.data);
		const link = document.createElement('a');
		link.href = url;
		if (
			extension === 'pdf' ||
			extension === 'jpeg' ||
			extension === 'png' ||
			extension === 'jpg'
		) {
			link.target = '_blank';
		} else {
			link.setAttribute('download', filename);
		}
		document.body.appendChild(link);
		link.click();
	},
	function (error) {
		if (
			error &&
			error.response &&
			error.response.status &&
			error.response.status === 401
		) {
			authService.getAccessToken();
		} else if (
			error &&
			error.response &&
			error.response.status &&
			error.response.status === 400
		) {
			//handle a BadRequest response
			handleErrorResponse(error.response);
			// return error.response
		} else {
			console.log('file download error ', JSON.stringify(error));
			if (
				error &&
				error.code &&
				(error.code === 'ERR_NETWORK' || error.code === 'ERR_BAD_RESPONSE')
			) {
				// This is a timeout response, and we can trigger function to export and send the documents via email
				let data = JSON.parse(error.config.data);
				let url = error.config.url;
				data.emailDoc = true;
				issueInfoMessage(
					'We detected that the report is taking a while to complete. ' +
						'The report will be emailed to you once its fully processed.'
				);
				exportReport(url, data).then((response) => {
					issueResponseMessage(response);
				});
			} else {
				handleErrorResponse(error);
			}
			// return error
		}
	}
);

/**
 * basic axios request with application level interceptors for upload POST
 * @type {AxiosInstance}
 */
const serviceAPIUpload = axios.create({
	headers: {
		'Content-type': 'multipart/form-data',
	},
});
serviceAPIUpload.interceptors.request.use(
	(config) => {
		const user = store.getState().auth.user;
		if (user) {
			config.headers = {
				proxystate:
					user.proxyRole +
					', ' +
					user.proxySubscription +
					', ' +
					user.proxyFacility,
				Authorization: `Bearer ${user.accessToken}`,
			};
		}
		return config;
	},
	(error) => Promise.reject(error)
);
serviceAPIUpload.interceptors.response.use(
	(response) => {
		return response;
	},
	function (error) {
		if (
			error &&
			error.response &&
			error.response.status &&
			error.response.status === 401
		) {
			authService.getAccessToken();
		} else if (
			error &&
			error.response &&
			error.response.status &&
			error.response.status === 400
		) {
			//handle a BadRequest response
			return error.response;
		} else {
			return error;
		}
		// return Promise.reject(error.response)
	}
);

export const upload = (url, formData) => {
	return serviceAPIUpload.post(url, formData);
};

export { serviceAPI, serviceAPIJSON, serviceAPIUpload, serviceAPIDownloadFile };
