import {
    DELETE_INSPECTION_DOCUMENT_URL,
    GET_INSPECTION_FILE_URL,
    LIST_ASSET_INSPECTIONS_URL,
    LIST_COMPLEX_INSPECTIONS_URL,
    LIST_PROPERTY_INSPECTIONS_URL,
    LIST_UNIT_INSPECTIONS_URL,
    LIST_WORK_ORDER_INSPECTIONS_URL,
    UPLOAD_INSPECTION_URL
} from "../constants/url.constants"
import {serviceAPI, serviceAPIDownloadFile} from "./service"
import {handleErrorResponse} from "../actions/message"

export const listAssetInspection = (assetId, year) => {
    let url = LIST_ASSET_INSPECTIONS_URL + assetId + "&year=" + year
    return serviceAPI.get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const listPropertyInspection = (facilityId, year) => {
    let url = LIST_PROPERTY_INSPECTIONS_URL + facilityId + "&year=" + year
    return serviceAPI.get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const listComplexInspection = (complexId, year) => {
    let url = LIST_COMPLEX_INSPECTIONS_URL + complexId + "&year=" + year
    return serviceAPI.get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const listUnitInspection = (unitId, year) => {
    let url = LIST_UNIT_INSPECTIONS_URL + unitId + "&year=" + year
    return serviceAPI.get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const listWorkOrderInspection = (workOrderId, year) => {
    let url = LIST_WORK_ORDER_INSPECTIONS_URL + workOrderId + "&year=" + year
    return serviceAPI.get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getFile = (filename) => {
    return serviceAPIDownloadFile.get(GET_INSPECTION_FILE_URL + filename)
}

export const uploadInspections = (data) => {
    return serviceAPI.post(UPLOAD_INSPECTION_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const deleteInspectionDocument = (data) => {
    return serviceAPI.post(DELETE_INSPECTION_DOCUMENT_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}
