import {MenuItem, TextField, Typography} from "@mui/material"
import {ModalAcceptButton} from "../../../constants/component.constants"
import {ResponsiveRow} from "../../../constants/layout.constants"
import FilterResults from "../../modals/listings/filterResults"
import {useState} from "react"

export default function ListingSearchWidget() {
    const [openFilerModal, setOpenFilterModal] = useState(false)

    function handleFilterModalClose() {
        setOpenFilterModal(false)
    }

    return (
        <ResponsiveRow
            sx={{
                flexDirection: "column",
                backgroundColor: "#fff",
                borderRadius: "20px",
                maxHeight: {xs: "auto", lg: "450px"},
                width: {xs: "100%", lg: "80%"},
                padding: 0,
            }}
        >
            <ResponsiveRow
                sx={{
                    alignItems: "center",
                    height: "20%",
                    width: "100%",
                    padding: "10px",
                }}
            >
                <Typography
                    variant="h4"
                    sx={{
                        padding: "10px",
                        color: "#037171",
                    }}
                >
                    Start your search here:
                </Typography>
            </ResponsiveRow>
            <ResponsiveRow
                sx={{
                    flexDirection: "column",
                    flexWrap: "nowrap",
                    alignItems: {xs: "center", lg: "flex-start"},
                    backgroundColor: "#efefef",
                    borderRadius: "20px",
                    width: "100%",
                    maxHeight: "80%",
                    margin: 0,
                    padding: "10px",
                }}
            >
                <TextField
                    variant="outlined"
                    sx={{
                        width: "80%",
                        borderRadius: "5px 10px",
                        backgroundColor: "#fff",
                        margin: "10px",
                    }}
                    label="Location"
                />
                <TextField
                    variant="outlined"
                    sx={{
                        width: "80%",
                        borderRadius: "5px 10px",
                        backgroundColor: "#fff",
                        margin: "10px",
                    }}
                    label="Budget"
                />
                <TextField
                    variant="outlined"
                    sx={{
                        width: "80%",
                        borderRadius: "5px 10px",
                        backgroundColor: "#fff",
                        margin: "10px",
                    }}
                    label="Property Type"
                    select
                >
                    <MenuItem value={"residential"}>Residential Properties</MenuItem>
                    <MenuItem value={"corporate"}>Corporate Properties</MenuItem>
                </TextField>
                <ResponsiveRow
                    sx={{
                        padding: 0,
                        width: "100%",
                        justifyContent: {xs: "center", lg: "flex-end"},
                    }}
                >
                    <ModalAcceptButton
                        sx={{
                            height: "min-content",
                            width: {xs: "200px", lg: "auto"},
                            padding: "10px",
                            backgroundColor: "#009F93",
                        }}
                        onClick={() => {
                            setOpenFilterModal(true)
                        }}
                    >
                        Refine Search
                    </ModalAcceptButton>
                    <FilterResults
                        openModal={openFilerModal}
                        closeModal={handleFilterModalClose}
                    />
                </ResponsiveRow>
                <ResponsiveRow
                    sx={{
                        padding: 0,
                        width: "100%",
                        justifyContent: {xs: "center", lg: "flex-end"},
                    }}
                >
                    <ModalAcceptButton
                        sx={{
                            height: "min-content",
                            padding: "10px",
                            width: "200px",
                        }}
                    >
                        Show Found Listings
                    </ModalAcceptButton>
                </ResponsiveRow>
            </ResponsiveRow>
        </ResponsiveRow>
    )
}
