import {
    CHANGE_NOTIFICATION_URL,
    FETCH_SUBSCRIBER_URL,
    GENERATE_ACCOUNTS_URL,
    GET_LOGGED_IN_USER_EMAIL_NOTIFICATIONS_URL,
    GET_LOGGED_IN_USER_NOTIFICATIONS_URL,
    GET_LOGGED_IN_USER_URL,
    UPDATE_EMAIL_REPORT_NOTIFICATION_URL,
    UPDATE_USER_URL,
    UPLOAD_ORG_LOGO_URL,
} from "../constants/url.constants"
import {serviceAPI} from "./service"
import {handleErrorResponse} from "../actions/message"

export const fetchSubscriber = (fetchSubscriber) => {
    return serviceAPI.get(FETCH_SUBSCRIBER_URL + fetchSubscriber)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

//POST REQUEST
export const getLoggedInUser = () => {
    return serviceAPI.post(GET_LOGGED_IN_USER_URL)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getLoggedInUserNotifications = () => {
    return serviceAPI.post(GET_LOGGED_IN_USER_NOTIFICATIONS_URL)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getLoggedInUserEmailNotifications = () => {
    return serviceAPI.post(GET_LOGGED_IN_USER_EMAIL_NOTIFICATIONS_URL)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const updateUserData = (data) => {
    return serviceAPI.post(UPDATE_USER_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const uploadOrgLogo = (data, id) => {
    return serviceAPI.post(UPLOAD_ORG_LOGO_URL + id, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const changeNotification = (data) => {
    return serviceAPI.post(CHANGE_NOTIFICATION_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const updateEmailNotification = (data) => {
    return serviceAPI.post(UPDATE_EMAIL_REPORT_NOTIFICATION_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const generateAccounts = (data) => {
    return serviceAPI.post(GENERATE_ACCOUNTS_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

