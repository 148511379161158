import {
  Alert,
  AlertTitle,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { FileUpload } from "../inputs/fileUpload";
import {
  adjustForTimezone,
  formatCurrency,
  isEmptyString,
  isNullUndefined,
  isValidDate,
  titleCase,
  tryParseFloat,
} from "../../util/util";
import {
  getCashPaymentAccounts,
  getPaymentVoucherMethods,
  getVendorOptions,
} from "../../services/list.service";
import { useSelector } from "react-redux";
import { unpaidAndPartialVendorBills } from "../../services/billAndPayment.service";
import { issueWarnMessage } from "../../actions/message";
import ModalContainerModule from "../modules/modalContainer";
import { ResponsiveRow } from "../../constants/layout.constants";
import MuiDataGrid from "../modules/MuiDataGrid";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/en-gb";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

export default function CreatePayment(props) {
  // const paymentAmountRef = useRef(null)
  const appMessage = useSelector((state) => state.message);
  const [vendorOptions, setVendorOptions] = useState([]);
  const [selectedPayment, setSelectedPayment] = useState(props.selectedPayment);
  const [vendorId, setVendorId] = useState("");
  const [date, setDate] = useState(null);
  const [chequeNumber, setChequeNumber] = useState("");
  const [memo, setMemo] = useState("");
  const [amount, setAmount] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [cashAccount, setCashAccount] = useState("");
  const [bulkFile, setBulkFile] = useState(null);
  const [bulkFileProgress] = useState(0);
  const [completedForm, setCompletedForm] = useState(false);
  const [vendorBills, setVendorBills] = useState([]);
  const [selectedVendorBills, setSelectedVendorBills] = useState([]);
  const [vendorTotalOutstanding, setVendorTotalOutstanding] = useState(0);
  const [cashPaymentAccounts, setCashPaymentAccounts] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [amountReadOnly, setAmountReadOnly] = useState(false);
  const [loading, setLoading] = useState(false);
  const [bankRefNumber, setBankRefNumber] = useState("");

  useEffect(() => {
    if (
      !isEmptyString(vendorId) &&
      isValidDate(date) &&
      !isEmptyString(memo) &&
      tryParseFloat(amount, -1) > 0 &&
      !isEmptyString(paymentMethod) &&
      ((paymentMethod === "CSH" && !isEmptyString(cashAccount)) ||
        (paymentMethod === "CHQ" && !isEmptyString(chequeNumber)) ||
        (paymentMethod === "RTGS" && !isEmptyString(bankRefNumber)))
    ) {
      setCompletedForm(true);
    } else {
      setCompletedForm(false);
    }
  }, [
    vendorId,
    date,
    memo,
    amount,
    paymentMethod,
    cashAccount,
    chequeNumber,
    bankRefNumber,
  ]);

  useEffect(() => {
    setSelectedPayment(props.selectedPayment);
    getVendorOptions().then((vendorOptions) => {
      setVendorOptions(vendorOptions);
    });

    getCashPaymentAccounts().then((cashPaymentAccounts) =>
      setCashPaymentAccounts(cashPaymentAccounts)
    );

    getPaymentVoucherMethods().then((paymentVoucherMethods) =>
      setPaymentMethods(paymentVoucherMethods)
    );
  }, [props.selectedPayment]);

  function fileValueChanged(event) {
    setBulkFile(event.target.files[0]);
  }

  const columns = [
    {
      field: "dueDate",
      headerName: "Due Date",
      width: 100,
    },
    {
      field: "description",
      headerName: "Description",
      minWidth: 200,
      flex: 1.5,
    },
    {
      field: "costedTo",
      headerName: "Costed To",
      minWidth: 150,
      flex: 1.5,
    },
    {
      field: "amount",
      headerName: "Amount",
      minWidth: 75,
      flex: 1,
      valueFormatter: (params) => formatCurrency(params.value),
    },
    {
      field: "paid",
      headerName: "Paid",
      minWidth: 75,
      flex: 1,
      valueFormatter: (params) => formatCurrency(params.value),
    },
    {
      field: "pending",
      headerName: "Pending",
      minWidth: 75,
      flex: 1,
      valueFormatter: (params) => formatCurrency(params.value),
    },
    {
      field: "balance",
      headerName: "Balance",
      minWidth: 75,
      flex: 1,
      valueFormatter: (params) => formatCurrency(params.value),
    },
  ];

  const handleBillClick = (value) => {
    if (value.length !== 0) {
      let total = vendorBills
        .filter((vb) => value.includes(vb.id))
        .reduce((tot, vb) => tot + vb.balance, 0);
      setAmount(total);
      setSelectedVendorBills(value);
    } else {
      //set amount to 0 when selection is cleared
      clearBillSelection();
    }
    setAmountReadOnly(value.length !== 0);
  };

  const clearBillSelection = () => {
    setSelectedVendorBills([]);
    setAmount(0);
  };

  const handleSave = () => {
    //do some client side validation
    if (vendorTotalOutstanding === 0) {
      issueWarnMessage("There is no outstanding amount for this vendor");
      return;
    }
    if (isEmptyString(date) || !isValidDate(date)) {
      issueWarnMessage("You must select a date for the payment.");
      return;
    }

    if (isNullUndefined(amount)) {
      issueWarnMessage("You must enter an amount for the payment");
      return;
    }

    if (amount < 1) {
      issueWarnMessage(
        "You must enter an amount greater than zero for the payment"
      );
      return;
    }

    if (isEmptyString(memo)) {
      issueWarnMessage("The payment voucher must include a description.");
      return;
    }

    let payAmount = tryParseFloat(amount, 0);
    if (payAmount <= 0) {
      issueWarnMessage("Payment amount must be greater than 0.00");
      return;
    }
    if (payAmount > vendorTotalOutstanding) {
      issueWarnMessage(
        "Payment amount shouldn't be greater than the vendor total outstanding bills"
      );
      return;
    }

    if (isEmptyString(vendorId)) {
      issueWarnMessage("A vendor must be selected for payment.");
      return;
    }

    if (isEmptyString(paymentMethod)) {
      issueWarnMessage("You must select a payment method for this payment.");
      return;
    } else {
      //secondary validation
      if (paymentMethod === "CSH" && isEmptyString(cashAccount)) {
        issueWarnMessage(
          "You must select a cash account for the cash payments."
        );
        return;
      } else if (paymentMethod === "CHQ" && isEmptyString(chequeNumber)) {
        issueWarnMessage(
          "You must indicate the cheque number for cheque payments."
        );
        return;
      } else if (paymentMethod === "CHQ" && chequeNumber.length > 10) {
        issueWarnMessage("Cheque Number can't exceed 10 characters.");
        return;
      }
    }
    if (isEmptyString(paymentMethod)) {
      issueWarnMessage("You must select a payment method for this payment.");
      return;
    } else {
      //secondary validation
      if (paymentMethod === "CSH" && isEmptyString(cashAccount)) {
        issueWarnMessage(
          "You must select a cash account for the cash payments."
        );
        return;
      } else if (paymentMethod === "CHQ" && isEmptyString(chequeNumber)) {
        issueWarnMessage(
          "You must indicate the cheque number for cheque payments."
        );
        return;
      } else if (paymentMethod === "CHQ" && chequeNumber.length > 10) {
        issueWarnMessage("Cheque Number can't exceed 10 characters.");
        return;
      } else if (paymentMethod === "RTGS" && isEmptyString(bankRefNumber)) {
        issueWarnMessage(
          "You must indicate the Bank Reference Number for RTGS payments."
        );
        return;
      } else if (paymentMethod === "RTGS" && bankRefNumber.length > 45) {
        issueWarnMessage("Cheque Number can't exceed 45 characters.");
        return;
      }
    }

    let tmp = selectedPayment;
    tmp.vendorId = vendorId;
    tmp.date = date;
    tmp.paymentMethodCd = paymentMethod;
    if (paymentMethod === "CSH") {
      tmp.cashAccount = cashAccount;
      tmp.chequeNumber = null;
      tmp.bankRefNumber = null;
    }
    if (paymentMethod === "CHQ") {
      tmp.chequeNumber = chequeNumber;
      tmp.cashAccount = null;
      tmp.bankRefNumber = null;
    }
    if (paymentMethod === "RTGS") {
      tmp.bankRefNumber = bankRefNumber;
      tmp.chequeNumber = null;
      tmp.cashAccount = null;
    }
    tmp.description = memo;
    tmp.amount = payAmount;
    tmp.billsSelected = Array.from(selectedVendorBills);

    setSelectedPayment(tmp);

    let uploadData = new FormData();
    uploadData.append("file", bulkFile);
    uploadData.append("selectedPayment", JSON.stringify(selectedPayment));
    props.savePayment(uploadData);
  };

  function handleCancel() {
    props.handleClose();
  }

  function handleVendorChange(value) {
    setLoading(true);
    setVendorId(value);
    unpaidAndPartialVendorBills(value)
      .then((bills) => {
        setVendorBills(bills.vendorBills);
        setVendorTotalOutstanding(bills.totalOutstanding);
      })
      .finally(() => {
        clearBillSelection();
        setLoading(false);
      });
  }

  return (
    <ModalContainerModule
      size="specialMakePaymentModal" //modal size presets (xs, sm, md)
      accept={handleSave} //accept function
      cancel={handleCancel} //cancel function
      openModal={props.openModal} //open modal function
      modalTitle="Make Payment" //modal title
      acceptButtonText="Save" // special accept button text
      acceptDisabled={!completedForm}
    >
      <ResponsiveRow
        sx={{
          width: "100%",
          height: "100%",

          flexDirection: { xs: "column", lg: "row" },
          flexWrap: "nowrap",

          justifyContent: { xs: "flex-start", lg: "space-between" },
          alignItems: "flex-start",

          padding: 0,
          margin: 0,
        }}
      >
        <ResponsiveRow
          sx={{
            flexDirection: "column",
            flexWrap: "nowrap",

            alignItems: "flex-start",
            justifyContent: "flex-start",

            padding: "0 10px 10px 10px",
            margin: "0",

            height: "auto",
            width: { xs: "100%", lg: "39%" },

            backgroundColor: "#fff",
            borderRadius: "20px",
          }}
        >
          <Typography
            id="modal-modal-description"
            fontWeight={700}
            sx={{ marginBottom: "20px" }}
          >
            Payment Details
          </Typography>
          {appMessage.display && (
            <Alert severity={appMessage.type}>
              <AlertTitle>{titleCase(appMessage.type)}</AlertTitle>
              {appMessage.message}
            </Alert>
          )}
          <ResponsiveRow
            sx={{
              flexDirection: { xs: "column", lg: "row" },

              justifyContent: { xs: "space-between", lg: "center" },
              alignItems: "flex-start",

              width: "100%",
              height: "auto",

              padding: "0",
            }}
          >
            <FormControl
              sx={{
                width: { xs: "100%", lg: "33.3%" },
                height: "auto",
                marginBottom: "10px",
              }}
            >
              <InputLabel id="payment-payee-label">Pay To</InputLabel>
              <Select
                labelId="payment-payee-label"
                id="payment-payee"
                value={vendorId}
                label="Pay To"
                onChange={(e) => handleVendorChange(e.target.value)}
              >
                {vendorOptions && vendorOptions.length > 0 ? (
                  vendorOptions.map(function (vendor, i) {
                    return (
                      <MenuItem key={vendor.value} value={vendor.value}>
                        {vendor.label}
                      </MenuItem>
                    );
                  }, this)
                ) : (
                  <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
                )}
              </Select>
            </FormControl>
            <FormControl
              sx={{
                width: { xs: "100%", lg: "33.3%" },
                height: "auto",
                marginBottom: "10px",
              }}
            >
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale="en-gb"
              >
                <DatePicker
                  id={props.componentID}
                  label={props.labelText}
                  sx={{
                    margin: "auto",
                    width: {
                      xs: "100%",
                      lg: props.width ? props.width : "90%",
                    },
                    backgroundColor: "#fff",
                    borderRadius: "10px",
                  }}
                  openTo="day"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputFormat="dd/MMM/yyyy"
                  disableFuture={true}
                  onChange={(new_value) => setDate(adjustForTimezone(new Date(new_value)))}
                />
              </LocalizationProvider>
            </FormControl>
            <FormControl
              sx={{
                width: { xs: "100%", lg: "33.3%" },
                height: "auto",
                marginBottom: "10px",
              }}
            >
              {!amountReadOnly && (
                <InputLabel id="payment-amount-label" shrink>
                  Amount
                </InputLabel>
              )}
              <OutlinedInput
                id="payment-amount"
                label={!amountReadOnly && "Amount"}
                value={amount}
                type={"number"}
                readOnly={amountReadOnly}
                disabled={amountReadOnly}
                onChange={(e) => {
                  if (e.target.value === "" && amount !== 0) {
                    setAmount(0);
                  } else {
                    setAmount(e.target.value.replace(/^0+/, "")); //remove leading 0s
                  }
                }}
              />
            </FormControl>
          </ResponsiveRow>

          <ResponsiveRow
            sx={{
              flexDirection: { xs: "column", lg: "row" },
              alignItems: "flex-start",
              justifyContent: { xs: "space-between", lg: "center" },
              width: "100%",
              height: "auto",
              padding: 0,
            }}
          >
            <FormControl
              sx={{
                width: { xs: "100%", lg: "50%" },
                height: "auto",
                marginBottom: "10px",
              }}
            >
              <InputLabel id="payment-method-label">Payment Method</InputLabel>
              <Select
                labelId="payment-method-label"
                id="payment-method"
                value={paymentMethod}
                label="Payment Method"
                onChange={(e) => {
                  setPaymentMethod(e.target.value);
                }}
                sx={{ width: { xs: "100%", lg: "95%" }, height: "auto" }}
              >
                {paymentMethods && paymentMethods.length > 0 ? (
                  paymentMethods.map(function (paymentMethod, i) {
                    return (
                      <MenuItem
                        key={paymentMethod.value}
                        value={paymentMethod.value}
                      >
                        {paymentMethod.label}
                      </MenuItem>
                    );
                  }, this)
                ) : (
                  <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
                )}
              </Select>
            </FormControl>
            {paymentMethod === "CSH" && (
              <FormControl
                sx={{
                  width: { xs: "100%", lg: "50%" },
                  marginBottom: "10px",
                }}
              >
                <InputLabel id="cash-account-label">Cash Account</InputLabel>
                <Select
                  labelId="cash-account-label"
                  id="cash-account"
                  value={cashAccount}
                  label="Cash Account"
                  onChange={(e) => {
                    setCashAccount(e.target.value);
                    setChequeNumber("");
                    setBankRefNumber("");
                  }}
                  sx={{ width: { xs: "100%", lg: "95%" }, height: "auto" }}
                >
                  {cashPaymentAccounts && cashPaymentAccounts.length > 0 ? (
                    cashPaymentAccounts.map(function (cashPaymentAccount, i) {
                      return (
                        <MenuItem
                          key={cashPaymentAccount.value}
                          value={cashPaymentAccount.value}
                        >
                          {cashPaymentAccount.label}
                        </MenuItem>
                      );
                    }, this)
                  ) : (
                    <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
                  )}
                </Select>
              </FormControl>
            )}
            {paymentMethod === "CHQ" && (
              <FormControl
                sx={{
                  width: { xs: "100%", lg: "33.3%" },
                  height: "auto",
                  marginBottom: "10px",
                }}
              >
                <InputLabel id="cheque-label">Cheque Number</InputLabel>
                <OutlinedInput
                  labelId="cheque-label"
                  id="cheque"
                  value={chequeNumber}
                  label="Cheque Number"
                  onChange={(e) => {
                    setChequeNumber(e.target.value);
                    setCashAccount("");
                    setBankRefNumber("");
                  }}
                  sx={{ width: { xs: "100%", lg: "95%" }, height: "auto" }}
                />
              </FormControl>
            )}
            {paymentMethod === "RTGS" && (
              <FormControl
                sx={{
                  width: { xs: "100%", lg: "33.3%" },
                  padding: "auto 10px",
                  margin: { xs: "10px auto", lg: "auto 10px" },
                }}
              >
                <InputLabel id="cheque-label">Bank Ref Number</InputLabel>
                <OutlinedInput
                  labelId="bank-ref-label"
                  id="bankRefNo"
                  value={bankRefNumber}
                  label="Bank Ref Number"
                  onChange={(e) => {
                    setBankRefNumber(e.target.value);
                    setCashAccount("");
                    setChequeNumber("");
                  }}
                />
              </FormControl>
            )}
            <FormControl
              sx={{
                width: { xs: "100%", lg: "50%" },
                height: "auto",
                marginBottom: "10px",
              }}
            >
              <FileUpload
                id={"bulkFileInput"}
                label="Payment Attachment"
                currentFile={bulkFile}
                progress={bulkFileProgress}
                onChange={fileValueChanged}
                accept={
                  ".xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                }
                width="100%"
              />
            </FormControl>
          </ResponsiveRow>
          <ResponsiveRow
            sx={{
              flexDirection: { xs: "column", lg: "row" },
              alignItems: "flex-start",
              justifyContent: { xs: "space-between", lg: "center" },
              width: "100%",
              height: "auto",
              padding: 0,
            }}
          >
            <FormControl
              sx={{
                width: "100%",
                height: "auto",
                marginBottom: 0,
              }}
            >
              <InputLabel id="payment-memo-label">Memo</InputLabel>
              <OutlinedInput
                labelId="payment-memo-label"
                id="payment-memo"
                value={memo}
                label="Memo"
                onChange={(e) => {
                  setMemo(e.target.value);
                }}
                multiline
                rows={3}
              />
            </FormControl>
          </ResponsiveRow>
        </ResponsiveRow>
        <ResponsiveRow
          sx={{
            flexDirection: "column",
            flexWrap: "nowrap",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            padding: 0,
            margin: "auto 10px",
            height: "auto",
            width: { xs: "100%", lg: "59%" },
            backgroundColor: "#fff",
            borderRadius: "20px",
          }}
        >
          <Typography id="modal-modal-description" fontWeight={700}>
            Unpaid and Partially Paid Bills
          </Typography>
          <Typography
            id="modal-modal-description"
            variant="body2"
            color="primary.dark"
            sx={{ marginBottom: "20px" }}
          >
            {!isNullUndefined(vendorId) &&
            !isEmptyString(vendorId) &&
            vendorBills.length > 0
              ? "Bills Total Outstanding: " +
                formatCurrency(vendorTotalOutstanding)
              : ""}
            {!isNullUndefined(vendorId) &&
            !isEmptyString(vendorId) &&
            vendorBills.length < 1
              ? "No Outstanding Bills found for this Vendor"
              : ""}
            {isNullUndefined(vendorId) || isEmptyString(vendorId)
              ? "Select Vendor to see Pending Bills"
              : ""}
          </Typography>
          <ResponsiveRow
            sx={{
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: { xs: "space-between", lg: "flex-start" },
              width: "100%",
              height: "40vh",
              padding: 0,
            }}
          >
            {vendorBills && vendorBills.length > 0 && (
              <MuiDataGrid
                dataGridColumns={columns}
                dataGridRows={vendorBills}
                loading={loading}
                height="40vh"
                noBoxShadow
                totalRows={vendorBills?.length ?? 0}
                currentSelectionModel={selectedVendorBills}
                handleSelectedRows={handleBillClick}
                serverPagination={false}
              />
            )}
          </ResponsiveRow>
        </ResponsiveRow>
      </ResponsiveRow>
    </ModalContainerModule>
  );
}
