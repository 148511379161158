import {Container} from "@mui/system"
import {ResponsiveRow} from "../constants/layout.constants"
import AreaChartsCard from "./charts/AreaChartCard"
import HorizontalBarChartCard from "./charts/HorizontalBarChartCard"
import LineChartsCard from "./charts/LineChartCard"
import PieChartsCard from "./charts/PieChartCard"
import VerticalBarChartsCard from "./charts/VerticalBarChartCard"
import StackedBarChartCard from "./charts/StackedBarChartCard"

export default function ChartsComponents() {

    const barChartData = {
        dataLabels: ["1-7", "8-14", "14-30", "30-60"],
        dataset: [
            {
                datasetLabel: "Epren Buruburu Blk 1",
                datasetData: [120342, 122345, 33321, 122134],
            },
            {
                datasetLabel: "Epren Buruburu Blk 2",
                datasetData: [12090, 122890, 3200, 90],
            },
            {
                datasetLabel: "Epren Buruburu Blk 3",
                datasetData: [68097, 77634, 66548, 33215],
            },
            {
                datasetLabel: "Epren Buruburu Blk 4",
                datasetData: [0, 12334, 44532, 65447, 88900],
            },
        ],
    }

    const areaChartData = {
        dataLabels: ["One", "Two", "Three", "Four", "Five"],
        dataset: [
            {
                datasetLabel: "Epren Buruburu Blk 1",
                datasetData: [
                    {data: 0},
                    {data: 120342},
                    {data: 122345},
                    {data: 33321},
                    {data: 122134}
                    ,]

            },
            {
                datasetLabel: "Epren Buruburu Blk 2",
                datasetData:
                    [
                        {data: 0},
                        {data: 12090},
                        {data: 122890},
                        {data: 3200},
                        {data: 90}
                    ]

            },
            {
                datasetLabel: "Epren Buruburu Blk 3",
                datasetData:
                    [
                        {data: 0},
                        {data: 68097},
                        {data: 77634},
                        {data: 66548},
                        {data: 33215}
                    ]
            },
            {
                datasetLabel: "Epren Buruburu Blk 4",
                datasetData:
                    [
                        {data: 0},
                        {data: 12334},
                        {data: 44532},
                        {data: 65447},
                        {data: 88900},
                    ]
            },
        ],
    }

    return (
        <Container disableGutters maxWidth="xxl">
            <ResponsiveRow
                sx={{
                    flexDirection: "column",

                    alignItems: "center",
                    justifyContent: "center",

                    padding: "20px",
                    width: "100%",
                    height: "auto",
                    border: "1px #02CBCE solid",
                    borderRadius: "10px",
                    margin: "20px auto",
                }}
            >
                <PieChartsCard
                    labelDataObj={{"7 Days": 651212, "30 Days": 673121.5, "60 Days": 408950.5, "90 Days": 2019542.84}}
                    labelObj={"Overdue by"}
                    height="50vh"
                    width="50vw"
                />
            </ResponsiveRow>
            <ResponsiveRow
                sx={{
                    flexDirection: "column",

                    alignItems: "center",
                    justifyContent: "center",

                    padding: "20px",
                    width: "100%",
                    height: "100%",
                    border: "1px #02CBCE solid",
                    borderRadius: "10px",
                    margin: "20px auto",
                }}
            >
                <LineChartsCard
                    currentlyLoading={false}
                    labelDataObj={{
                        dataLabels: [
                            "Jan",
                            "Feb",
                            "Mar",
                            "Apr",
                            "May",
                            "Jun",
                            "Jul",
                            "Aug",
                            "Sep",
                            "Oct",
                            "Nov",
                            "Dec",
                        ],
                        dataset: [
                            {
                                datasetLabel: "Actual Income",
                                datasetData: {
                                    "Jan": 10,
                                    "Feb": 20,
                                    "Mar": 30,
                                    "Apr": 30,
                                    "May": 40,
                                    "Jun": 50,
                                    "Jul": 60,
                                    "Aug": 70,
                                    "Sep": 80,
                                    "Oct": 90,
                                    "Nov": 100,
                                    "Dec": 110,
                                },
                            },
                            {
                                datasetLabel: "Actual Expense",
                                datasetData: {
                                    "Jan": 112,
                                    "Feb": 122,
                                    "Mar": 132,
                                    "Apr": 132,
                                    "May": 142,
                                    "Jun": 152,
                                    "Jul": 162,
                                    "Aug": 172,
                                    "Sep": 182,
                                    "Oct": 192,
                                    "Nov": 202,
                                    "Dec": 212,
                                },
                            },
                            {
                                datasetLabel: "Expected Income",
                                datasetData: {
                                    "Jan": 216,
                                    "Feb": 226,
                                    "Mar": 236,
                                    "Apr": 236,
                                    "May": 246,
                                    "Jun": 256,
                                    "Jul": 266,
                                    "Aug": 276,
                                    "Sep": 286,
                                    "Oct": 296,
                                    "Nov": 306,
                                    "Dec": 316,
                                },
                            },
                            {
                                datasetLabel: "Expected Expense",
                                datasetData: {
                                    "Jan": 318,
                                    "Feb": 328,
                                    "Mar": 338,
                                    "Apr": 338,
                                    "May": 348,
                                    "Jun": 358,
                                    "Jul": 368,
                                    "Aug": 378,
                                    "Sep": 388,
                                    "Oct": 398,
                                    "Nov": 408,
                                    "Dec": 418,
                                },
                            },
                        ]
                    }}
                    height="auto"
                    width="50vw"
                />
            </ResponsiveRow>
            <ResponsiveRow
                sx={{
                    flexDirection: "column",

                    alignItems: "center",
                    justifyContent: "center",

                    padding: "20px",
                    width: "100%",
                    height: "100%",
                    border: "1px #02CBCE solid",
                    borderRadius: "10px",
                    margin: "20px auto",
                }}
            >
                <AreaChartsCard
                    dataObj={areaChartData}
                    labelObj={"Overdue by"}
                    height="auto"
                    width="50vw"
                    currentlyLoading={false}
                />
            </ResponsiveRow>{" "}
            <ResponsiveRow
                sx={{
                    flexDirection: "column",

                    alignItems: "center",
                    justifyContent: "center",

                    padding: "20px",
                    width: "100%",
                    height: "100%",
                    border: "1px #02CBCE solid",
                    borderRadius: "10px",
                    margin: "20px auto",
                }}
            >
                <VerticalBarChartsCard
                    dataObj={barChartData}
                    labelObj={"Overdue by"}
                    height="auto"
                    width="50vw"
                />
            </ResponsiveRow>
            <ResponsiveRow
                sx={{
                    flexDirection: "column",

                    alignItems: "center",
                    justifyContent: "center",

                    padding: "20px",
                    width: "100%",
                    height: "100%",
                    border: "1px #02CBCE solid",
                    borderRadius: "10px",
                    margin: "20px auto",
                }}
            >
                <HorizontalBarChartCard
                    dataObj={barChartData}
                    labelObj={"Overdue by"}
                    height="auto"
                    width="50vw"
                />
            </ResponsiveRow>
            <ResponsiveRow
                sx={{
                    flexDirection: "column",

                    alignItems: "center",
                    justifyContent: "center",

                    padding: "20px",
                    width: "100%",
                    height: "100%",
                    border: "1px #02CBCE solid",
                    borderRadius: "10px",
                    margin: "20px auto",
                }}
            >
                <StackedBarChartCard
                    dataObj={barChartData}
                    labelObj={"Overdue by"}
                    height="auto"
                    width="50vw"
                />
            </ResponsiveRow>
        </Container>
    )
}
