import {useEffect, useState} from "react"
import {Alert, AlertTitle, Divider, Typography} from "@mui/material"
import {ModalAcceptButton, ModalCancelButton,} from "../../constants/component.constants"
import {ModalContainer, ModalInnerContainer, ResponsiveRow,} from "../../constants/layout.constants"
import {titleCase} from "../../util/util"
import {useSelector} from "react-redux"

/**
 * REQUIRED PROPS
 *    size='' //modal size presets (xs, sm, md)
 *    accept={} //accept function
 *    cancel={} //cancel function
 *    openModal={} //open modal function
 *    modalTitle='' //modal title
 *    modalSubTitle //modal subtitle
 *    acceptButtonText='' // special accept button text
 *    acceptDisabled={}
 *    acceptDisabled2={}
 *    cancelDisabled={}
 *    cancelDisabled2={}
 *    hideCancel
 */

export default function ListingModalContainerModule(props) {
    const [buttonLoading, setButtonLoading] = useState(false)
    const [rejectButtonLoading, setRejectButtonLoading] = useState(false)
    const [buttonLoading2, setButtonLoading2] = useState(false)
    const [modalSize, setModalSize] = useState(["80vh", "90vw"])

    const appMessage = useSelector((state) => state.message)

    useEffect(() => {
        switch (props.size) {
            case "xs":
                setModalSize([
                    {height: "auto", width: "90vw"},
                    {height: "auto", width: "90vw"},
                ])
                break

            case "sm":
                setModalSize([
                    {height: "60vh", width: "90vw"},
                    {height: "60vh", width: "90vw"},
                ])
                break

            case "md":
                setModalSize([
                    {height: "70vh", width: "90vw"},
                    {height: "70vh", width: "90vw"},
                ])
                break

            case "specialAddListing":
                setModalSize([
                    {height: "70vh", width: "90vw"},
                    {height: "70vh", width: "90vw"},
                ])
                break

            case "specialListingCreateAccount":
                setModalSize([
                    {height: "80vh", width: "70vw"},
                    {height: "60vh", width: "90vw"},
                ])
                break

            default:
                setModalSize([
                    {height: "80vh", width: "90vw"},
                    {height: "80vh", width: "90vw"},
                ])
                break
        }
    }, [props.size])

    function handleAccept() {
        setButtonLoading(true)

        setTimeout(() => {
            props.accept()
            setButtonLoading(false)
        }, 1000)
    }

    function handleReject() {
        setRejectButtonLoading(true)

        setTimeout(() => {
            props.reject()
            setRejectButtonLoading(false)
        }, 1000)
    }

    function handleAccept2() {
        setButtonLoading2(true)

        setTimeout(() => {
            props.accept()
            setButtonLoading2(false)
        }, 1000)
    }

    function handleCancel() {
        props.cancel()
    }

    return (
        <ModalContainer
            open={props.openModal}
            onClose={handleCancel}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <ModalInnerContainer
                sx={{
                    height: {xs: modalSize[1].height, lg: modalSize[0].height},
                    width: {xs: modalSize[1].width, lg: modalSize[0].width},
                    overflow: "hidden",
                }}
            >
                <Typography
                    id="modalSubTitle"
                    color="primary.dark"
                    sx={{
                        // marginBottom: "10px",
                        fontSize: {xs: "1rem", lg: "1rem"},
                        fontWeight: 800,
                        marginBottom: "10px",
                    }}
                >
                    {props.modalSubTitle}
                </Typography>
                <Typography
                    id="modalTitle"
                    variant="h1"
                    sx={{
                        fontWeight: 800,
                    }}
                >
                    {props.modalTitle}
                </Typography>
                <Divider/>
                <br/>
                {appMessage.display && (
                    <Alert severity={appMessage.type}>
                        <AlertTitle>{titleCase(appMessage.type)}</AlertTitle>
                        {appMessage.message}
                    </Alert>
                )}
                {/*
        {props.modalSubtitle && (
          <Typography
            sx={{ marginBottom: "5px" }}
            id="addUser"
            variant="h6"
            component="h6"
          >
            {props.modalSubtitle}
          </Typography>
        )} */}
                <ResponsiveRow
                    sx={{
                        maxHeight: {
                            xs: "70%",
                            lg:
                                props.accept || props.reject || props.accept2 || props.cancel2
                                    ? "73%"
                                    : "90%",
                        },
                        height: {
                            xs: props.size === "xs" ? "fit-content" : "65%",
                            lg:
                                props.accept || props.reject || props.accept2 || props.cancel2
                                    ? "70%"
                                    : "90%",
                        },
                        overflowY: "auto",
                        padding: 0,
                        margin: "0 auto",
                        alignItems: "flex-start",
                    }}
                >
                    {props.children}
                </ResponsiveRow>
                {props.hideCancel !== true && (
                    <ResponsiveRow
                        sx={{
                            justifyContent: {xs: "center", lg: "flex-end"},
                            alignItems: "center",
                            height: "20%",
                            padding: "20px auto",
                        }}
                    >
                        {props.accept && (
                            <ModalAcceptButton
                                onClick={handleAccept}
                                sx={{
                                    width: {xs: "100%", lg: "200px"},
                                    height: "40px",
                                }}
                                loading={buttonLoading}
                                disabled={props.acceptDisabled && props.acceptDisabled === true}
                            >
                                {buttonLoading ? "" : props.acceptButtonText}
                            </ModalAcceptButton>
                        )}
                        {props.reject && (
                            <ModalAcceptButton
                                onClick={handleReject}
                                sx={{
                                    width: {xs: "100%", lg: "200px"},
                                    height: "40px",
                                }}
                                loading={rejectButtonLoading}
                                disabled={props.acceptDisabled && props.acceptDisabled === true}
                            >
                                {rejectButtonLoading ? "" : props.rejectButtonText}
                            </ModalAcceptButton>
                        )}
                        {props.accept2 && props.accept2 !== null && (
                            <ModalAcceptButton
                                onClick={handleAccept2}
                                sx={{
                                    width: {xs: "100%", lg: "200px"},
                                    height: "40px",
                                }}
                                loading={buttonLoading2}
                                disabled={
                                    props.acceptDisabled2 && props.acceptDisabled2 === true
                                }
                            >
                                {buttonLoading2 ? "" : props.acceptButtonText2}
                            </ModalAcceptButton>
                        )}

                        {props.cancel2 && (
                            <ModalCancelButton
                                onClick={props.handleCancel2}
                                sx={{
                                    width: {xs: "100%", lg: "200px"},
                                    height: "40px",
                                }}
                                disabled={
                                    props.cancelDisabled2 && props.cancelDisabled2 === true
                                }
                            >
                                {props.cancel2Text}
                            </ModalCancelButton>
                        )}
                        {props.hideCancel !== true && (
                            <ModalCancelButton
                                onClick={handleCancel}
                                sx={{
                                    width: {xs: "100%", lg: "200px"},
                                    height: "40px",
                                }}
                                disabled={
                                    props.canceltDisabled && props.cancelDisabled === true
                                }
                            >
                                {props.cancelText ? props.cancelText : "Cancel"}
                            </ModalCancelButton>
                        )}
                    </ResponsiveRow>
                )}
            </ModalInnerContainer>
        </ModalContainer>
    )
}
