import ModalContainerModule from "../components/modules/modalContainer"
import {ResponsiveRow} from "../constants/layout.constants"
import {DetailsColumn, DetailsContainer, DetailsLabel, DetailsRow, DetailsText} from "../constants/component.constants"
import {adjustToTimezone, formatCurrency} from "../util/util"
import {useSelector} from "react-redux";

export default function LeaseDepositChargeReview(props) {
    const {user} = useSelector((state) => state.auth);
    return (
        <>
            <ModalContainerModule
                size="xs"
                accept={user.proxyRole === "BLDIR" ? props.approveDepositCharge : false}
                reject={user.proxyRole === "BLDIR" ? props.rejectDepositCharge : false}
                cancel={props.closeDepositChargeModal}
                openModal={props.openDepositChargeModal}
                modalTitle={"Review Lease Deposit Charge Details"}
                acceptButtonText={"Approve"}
                rejectButtonText={"Reject"}
                acceptDisabled={user.proxyRole === "BLDIR" ? false : true}
            >
                <ResponsiveRow sx={{paddingTop: 0}}>
                    <DetailsContainer sx={{width: "100%"}}>
                        <DetailsColumn
                            sx={{
                                width: {lg: "33.3%", md: "100%", xs: "100%"},
                            }}
                        >
                            <DetailsRow>
                                <DetailsLabel>Created by:</DetailsLabel>
                                <DetailsText>
                                    {props.depositItem && props.depositItem.createdBy}
                                </DetailsText>
                            </DetailsRow>
                            <DetailsRow>
                                <DetailsLabel>Effective Date:</DetailsLabel>
                                <DetailsText>
                                    {props.depositItem && props.depositItem.effectiveDate && adjustToTimezone(props.depositItem.effectiveDate)}
                                </DetailsText>
                            </DetailsRow>
                            <DetailsRow>
                                <DetailsLabel>Applied To:</DetailsLabel>
                                <DetailsText>
                                    {props.depositItem && props.depositItem.appliedTo}
                                </DetailsText>
                            </DetailsRow>
                        </DetailsColumn>
                        <DetailsColumn
                            sx={{
                                width: {lg: "33.3%", md: "100%", xs: "100%"},
                            }}
                        >
                            <DetailsRow>
                                <DetailsLabel>Amount:</DetailsLabel>
                                <DetailsText>
                                    {props.depositItem && props.depositItem.amount && formatCurrency(props.depositItem.amount)}
                                </DetailsText>
                            </DetailsRow>
                            <DetailsRow>
                                <DetailsLabel>Note:</DetailsLabel>
                                <DetailsText>
                                    {props.depositItem && props.depositItem.note}
                                </DetailsText>
                            </DetailsRow>
                            <DetailsRow>
                                <DetailsLabel>Status:</DetailsLabel>
                                <DetailsText>
                                    {props.depositItem && props.depositItem.status && props.depositItem.status.name}
                                </DetailsText>
                            </DetailsRow>
                        </DetailsColumn>
                        <DetailsColumn
                            sx={{
                                width: {lg: "33.3%", md: "100%", xs: "100%"},
                            }}
                        >
                            <DetailsRow>
                                <DetailsLabel>Unit:</DetailsLabel>
                                <DetailsText>
                                    {props.depositItem && props.depositItem.unitName}
                                </DetailsText>
                            </DetailsRow>
                            <DetailsRow>
                                <DetailsLabel>Charge Type:</DetailsLabel>
                                <DetailsText>
                                    {props.depositItem && props.depositItem.charge && props.depositItem.charge.name}
                                </DetailsText>
                            </DetailsRow>
                            <DetailsRow>
                                <DetailsLabel>Taxable:</DetailsLabel>
                                <DetailsText>
                                    {props.depositItem && props.depositItem.taxable && props.depositItem.taxable === true ? "Yes" : "No"}
                                </DetailsText>
                            </DetailsRow>
                        </DetailsColumn>
                    </DetailsContainer>
                </ResponsiveRow>
            </ModalContainerModule>
        </>
    )
}
