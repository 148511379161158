import {Grid, Link, MenuItem, TextField, Typography} from "@mui/material"
import {ResponsiveRow} from "../../../constants/layout.constants"
import InputAdornment from "@mui/material/InputAdornment"
import {Visibility, VisibilityOff} from "@mui/icons-material"
import {ModalAcceptButton} from "../../../constants/component.constants"
import {useEffect, useState} from "react"
import {getIdTypes} from "../../../services/list.service"
import {isEmptyObject, isEmptyString, isNullUndefined,} from "../../../util/util"
import {setMessage} from "../../../actions/message"
import {listingsSignUp} from "../../../services/listingsServices/signUp.service"
import Box from "@mui/material/Box"
import {useNavigate} from "react-router-dom"
import {useDispatch} from "react-redux";

/**
 * Register Form Shared Component.
 * by: rcheruiyot 12th Oct 2022
 * @returns {*}
 * @constructor
 */

export default function RegisterForm(props) {
    const [idTypes, setIdTypes] = useState([])
    const [selectedIdType, setSelectedIdType] = useState("")
    const [idNumber, setIdNumber] = useState("")
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [phoneNo, setPhoneNo] = useState("")
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [showPassword, setShowPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)
    const navigator = useNavigate()
    const dispatch = useDispatch()
    useEffect(() => {
        getIdTypes()
            .then((idTypes) => setIdTypes(idTypes))
            .catch((error) => {
                console.log("getIdTypes Error: ", error)
            })
    }, [])

    function handleIdTypeChange(event) {
        let value = event.target.value
        setSelectedIdType(value)
    }

    function handleTextChanged(event) {
        let value = event.target.value
        let id = event.target.id

        if (id === "firstName") {
            setFirstName(value)
        }
        if (id === "lastName") {
            setLastName(value)
        }
        if (id === "idNumber") {
            setIdNumber(value)
        }
    }

    function handleEmailChanged(event) {
        let value = event.target.value
        setEmail(value)
    }

    function handlePhoneNumberChanged(event) {
        let value = event.target.value
        setPhoneNo(value)
    }

    function handlePasswordChanged(event) {
        let value = event.target.value
        let id = event.target.id
        id === "password" ? setPassword(value) : setConfirmPassword(value)
    }

    function handleShowPassword() {
        setShowPassword(!showPassword)
    }

    function handleShowConfirmPassword() {
        setShowConfirmPassword(!showConfirmPassword)
    }

    const formValidation = (sendData) => {
        if (isNullUndefined(sendData) || isEmptyObject(sendData)) {
            dispatch(
                setMessage({
                    type: "warning",
                    message:
                        "Please fill in the required fields and register. No data found.",
                })
            )
            return false
        }
        if (isEmptyString(sendData.firstName)) {
            dispatch(
                setMessage({type: "warning", message: "First name is required."})
            )
            return false
        }
        if (isEmptyString(sendData.lastName)) {
            dispatch(
                setMessage({type: "warning", message: "Last name is required."})
            )
            return false
        }
        if (isEmptyString(sendData.phoneNumber)) {
            dispatch(
                setMessage({type: "warning", message: "Phone Number is required."})
            )
            return false
        }
        if (isEmptyString(sendData.email)) {
            dispatch(
                setMessage({type: "warning", message: "Email is required."})
            )
            return false
        }
        if (isEmptyString(sendData.idNumber)) {
            dispatch(
                setMessage({type: "warning", message: "ID Number is required."})
            )
            return false
        }
        if (isEmptyString(sendData.idTypeCd)) {
            dispatch(
                setMessage({type: "warning", message: "Please select an ID type."})
            )
            return false
        }
        if (isEmptyString(sendData.password)) {
            dispatch(
                setMessage({type: "warning", message: "Please input password."})
            )
            return false
        } else {
            if (sendData.password.length < 8) {
                dispatch(
                    setMessage({
                        type: "warning",
                        message: "Your password cannot be less than 8 characters long.",
                    })
                )
                return false
            }
        }
        if (isEmptyString(confirmPassword)) {
            dispatch(
                setMessage({
                    type: "warning",
                    message: "Please confirm your password.",
                })
            )
            return false
        }

        if (password !== confirmPassword) {
            dispatch(
                setMessage({
                    type: "warning",
                    message: "Password and confirm password do not match!",
                })
            )
            return false
        }

        return true
    }

    function handleCreateAccountClicked() {
        let data = {
            id: null,
            firstName: firstName,
            lastName: lastName,
            phoneNumber: phoneNo,
            email: email,
            idNumber: idNumber,
            idTypeCd: selectedIdType,
            password: password,
        }

        console.log("sendData", data)
        formValidation(data) && //first validate form.
        listingsSignUp(data)
            .then((response) => {
                console.log("response", response)
                dispatch(
                    setMessage({
                        type: response.type,
                        message: response.message,
                    })
                )
                if (response.type === "success") {
                    navigator("/listings/signIn")
                }
            })
            .catch((error) => {
                console.log("signUp error: ", error)
            })
    }

    return (
        <ResponsiveRow
            sx={{
                height: "auto",
                width: {xs: "90%", lg: props.width ? props.width : "50%"},
                backgroundColor: "#fff",
                borderRadius: "20px",

                flexDirection: "column",
                justifyContent: props.width ? "flex-start" : "center",
                flexWrap: {xs: "wrap", lg: "nowrap"},

                marginTop: props.width ? 0 : "100px",
                padding: {xs: "20px 10px", lg: props.width ? "10px 0" : "40px"},
                position: "relative",
                zIndex: 5,
            }}
        >
            {!props.width && (
                <Typography
                    sx={{
                        fontSize: {xs: "1.4rem", lg: "2rem"},
                        color: "#037171",
                        fontWeight: 900,
                        textAlign: "center",
                        marginBottom: 0,
                    }}
                >
                    Welcome to Kodi Listings
                </Typography>
            )}
            <Box
                sx={{
                    padding: {xs: "10px", lg: props.width ? "10px 0" : "20px 40px"},
                    textAlign: "center",
                    marginTop: 0,
                }}
            >
                {!props.width && (
                    <Typography variant="h6">
                        Please fill in the form below to start your listings journey with us
                    </Typography>
                )}
                <ResponsiveRow
                    sx={{
                        width: "100%",
                        justifyContent: "center",
                    }}
                >
                    <Grid item sx={{width: "50%", marginBottom: "10px"}}>
                        <TextField
                            variant="outlined"
                            sx={{
                                width: "90%",
                                backgroundColor: "#efefef",
                                borderRadius: "10px",
                            }}
                            label="First Name"
                            id={"firstName"}
                            required
                            onChange={(event) => handleTextChanged(event)}
                        />
                    </Grid>{" "}
                    <Grid item sx={{width: "50%", marginBottom: "10px"}}>
                        <TextField
                            variant="outlined"
                            sx={{
                                width: "90%",
                                backgroundColor: "#efefef",
                                borderRadius: "10px",
                            }}
                            label="Last Name"
                            id={"lastName"}
                            onChange={(event) => handleTextChanged(event)}
                        />
                    </Grid>
                    <Grid item sx={{width: "100%", marginBottom: "10px"}}>
                        <TextField
                            variant="outlined"
                            sx={{
                                width: "95%",
                                backgroundColor: "#efefef",
                                borderRadius: "10px",
                            }}
                            label="Email"
                            onChange={(event) => handleEmailChanged(event)}
                        />
                    </Grid>
                    <Grid item sx={{width: "100%", marginBottom: "10px"}}>
                        <TextField
                            variant="outlined"
                            sx={{
                                width: "95%",
                                backgroundColor: "#efefef",
                                borderRadius: "10px",
                            }}
                            label="Phone Number"
                            onChange={(event) => handlePhoneNumberChanged(event)}
                        />
                    </Grid>
                    <Grid item sx={{width: "50%", marginBottom: "10px"}}>
                        <TextField
                            variant="outlined"
                            sx={{
                                width: "90%",
                                backgroundColor: "#efefef",
                                borderRadius: "10px",
                            }}
                            label="ID Type"
                            select
                            onChange={(event) => handleIdTypeChange(event)}
                        >
                            {idTypes &&
                                idTypes.length > 0 &&
                                idTypes.map(function (idType, i) {
                                    return (
                                        <MenuItem key={idType.value} value={idType.value}>
                                            {idType.label}
                                        </MenuItem>
                                    )
                                }, this)}
                        </TextField>
                    </Grid>
                    <Grid item sx={{width: "50%", marginBottom: "10px"}}>
                        <TextField
                            variant="outlined"
                            sx={{
                                width: "90%",
                                backgroundColor: "#efefef",
                                borderRadius: "10px",
                            }}
                            label="ID Number"
                            id={"idNumber"}
                            onChange={(event) => handleTextChanged(event)}
                        />
                    </Grid>
                    <Grid
                        item
                        sx={{width: {xs: 0, lg: "100%"}, marginBottom: "10px"}}
                    ></Grid>
                    <Grid item sx={{width: "50%", marginBottom: "10px"}}>
                        <TextField
                            variant="outlined"
                            sx={{
                                width: "90%",
                                backgroundColor: "#efefef",
                                borderRadius: "10px",
                            }}
                            label="Password"
                            type={showPassword ? "text" : "password"}
                            id={"password"}
                            onChange={(event) => handlePasswordChanged(event)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {showPassword ? (
                                            <Visibility onClick={handleShowPassword}/>
                                        ) : (
                                            <VisibilityOff onClick={handleShowPassword}/>
                                        )}
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item sx={{width: "50%", marginBottom: "10px"}}>
                        <TextField
                            variant="outlined"
                            sx={{
                                width: "90%",
                                backgroundColor: "#efefef",
                                borderRadius: "10px",
                            }}
                            label="Confirm Password"
                            type={showConfirmPassword ? "text" : "password"}
                            id={"confirmPassword"}
                            onChange={(event) => handlePasswordChanged(event)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {showConfirmPassword ? (
                                            <Visibility onClick={handleShowConfirmPassword}/>
                                        ) : (
                                            <VisibilityOff onClick={handleShowConfirmPassword}/>
                                        )}
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid
                        item
                        sx={{width: {xs: 0, lg: "33.3%"}, marginBottom: "10px"}}
                    ></Grid>
                    <ResponsiveRow
                        sx={{
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                        }}
                    >
                        <ModalAcceptButton
                            sx={{
                                width: props.width ? "50%" : "100%",
                                height: "50px",
                            }}
                            onClick={handleCreateAccountClicked}
                        >
                            Create Account
                        </ModalAcceptButton>
                    </ResponsiveRow>
                </ResponsiveRow>

                <hr/>
                <ResponsiveRow>
                    <Box sx={{textAlign: "start", width: "50%"}}>
                        {" "}
                        <Typography>Already have an account? </Typography>
                        <Link
                            href="/listings/signIn"
                            sx={{textDecoration: "none", fontWeight: 800}}
                        >
                            Click here to login in
                        </Link>
                    </Box>
                    <Box sx={{textAlign: "end", width: "50%"}}>
                        <Typography>Not ready to start an account? </Typography>
                        <Link
                            href="/listings"
                            sx={{textDecoration: "none", fontWeight: 800}}
                        >
                            Click here to go back home
                        </Link>
                    </Box>
                </ResponsiveRow>
            </Box>
        </ResponsiveRow>
    )
}
