import React, { useState } from "react";
import {
  NavMenuChildItem,
  NavMenuItem,
} from "../../constants/component.constants";
import { Menu } from "@mui/material";
import kodi_theme from "../../themes/kodiThemeProvider";
import Permit from "../../util/permit";
import { NavLink } from "react-router-dom";
import { ResponsiveRow } from "../../constants/layout.constants";

export default function DesktopParentMenuItem({ item }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const isOpen = Boolean(anchorEl);

  function openMenu(e) {
    setAnchorEl(e.currentTarget);
  }

  /**
   * Closes the dropdown menu for the parent item.
   * If the link provided is the same as the current path, reload the page.
   * @param {string} link - the link to compare with the current path. If null or undefined, do nothing.
   */
  function closeMenu(link) {
    if (link === window.location.pathname) {
      window.location.reload();
    }
    setAnchorEl(null);
  }

  return item.roles !== null && item.services !== null ? (
    <>
      <Permit
        roles={item.roles}
        services={item.services}
        permissions={item.permissions}
        key={item.id}
      >
        <NavMenuItem
          id="dropDownTrigger"
          aria-controls={isOpen ? "dropDownMenu" : undefined}
          aria-haspopup="true"
          aria-expanded={isOpen ? "true" : undefined}
          onClick={openMenu}
          sx={{
            color: "#fff",
            fontWeight: "bold",
            textDecoration: "none",
          }}
        >
          {item.name}
        </NavMenuItem>

        <Menu
          id="dropDownMenu"
          anchorEl={anchorEl}
          open={isOpen}
          onClose={closeMenu}
          MenuListProps={{
            "aria-labelledby": "dropDownTrigger",
          }}
          sx={{
            minWidth: "150px",
            "& .MuiMenu-paper": {
              padding: "10px",
              borderRadius: "0 0 10px 10px",
              backgroundColor: kodi_theme.palette.primary.dark,
              boxShadow: 0,
            },
          }}
        >
          <ResponsiveRow
            sx={{
              display: "flex",
              flexDirection: "column",
              flexWrap: "nowrap",
            }}
          >
            {item.children &&
              item.children.map((child) =>
                child.roles !== false && child.services !== false ? (
                  <Permit
                    key={child.id}
                    roles={child.roles}
                    services={child.services}
                    permissions={child.permissions}
                  >
                    <NavMenuChildItem
                      onClick={() => closeMenu(child.href)}
                      component={NavLink}
                      to={child.href}
                    >
                      {child.name}
                    </NavMenuChildItem>
                  </Permit>
                ) : (
                  <NavMenuChildItem
                    key={child.id}
                    onClick={() => closeMenu(child.href)}
                    component={NavLink}
                    to={child.href}
                  >
                    {child.name}
                  </NavMenuChildItem>
                )
              )}
          </ResponsiveRow>
        </Menu>
      </Permit>
    </>
  ) : (
    <>
      <NavMenuItem
        id="dropDownTrigger"
        aria-controls={isOpen ? "dropDownMenu" : undefined}
        aria-haspopup="true"
        aria-expanded={isOpen ? "true" : undefined}
        onClick={openMenu}
        sx={{
          color: "#fff",
          fontWeight: "bold",
          textDecoration: "none",
        }}
      >
        {item.name}
      </NavMenuItem>

      <Menu
        id="dropDownMenu"
        anchorEl={anchorEl}
        open={isOpen}
        onClose={closeMenu}
        MenuListProps={{
          "aria-labelledby": "dropDownTrigger",
        }}
        sx={{
          display: "flex",
          flexDirection: "column",
          minWidth: "150px",
          "& .MuiMenu-paper": {
            padding: "10px",
            borderRadius: "0 0 10px 10px",
            backgroundColor: kodi_theme.palette.primary.dark,
            boxShadow: 0,
          },
        }}
      >
        {item.children &&
          item.children.map((child) =>
            child.roles !== false || child.services !== false ? (
              <Permit
                key={item.id}
                roles={item.roles}
                services={item.services}
                permissions={item.permissions}
              >
                <NavMenuChildItem
                  key={child.id}
                  onClick={() => closeMenu(child.href)}
                  component={NavLink}
                  to={child.href}
                >
                  {child.name}
                </NavMenuChildItem>
              </Permit>
            ) : (
              <NavMenuChildItem
                key={child.id}
                onClick={() => closeMenu(child.href)}
                component={NavLink}
                to={child.href}
              >
                {child.name}
              </NavMenuChildItem>
            )
          )}
      </Menu>
    </>
  );
}
