import {Box, Grid, Typography} from "@mui/material";
import WorkOrderViewBar from "../../components/bars/workOrderViewBar";
import WorkOrderInfoCard from "../../components/cards/workOrderInformationCard";
import WorkOrderNotesWideCard from "../../components/cards/workOrderNotesWideCard";
import WorkOrderVendorDetailCard from "../../components/cards/workOrderVendorDetailCard";
import WorkOrderAddCharge from "../../components/modals/workOrderAddCharge";
import AddWorkOrderVendor from "../../components/modals/addWorkOrderVendor";
import {useState} from "react";
import {DocumentStore} from "../../components/documentStore";

export default function WorkOrderView(props) {
    const [showAddChargeModal, setShowAddChargeModal] = useState(false);
    const [showAddVendorModal, setShowAddVendorModal] = useState(false);
    const [selectedVendor, setSelectedVendor] = useState(null);

    const handleChargeClose = () => {
        setShowAddChargeModal(false);
        setSelectedVendor(null);
    };

    const handleAddVendorClose = () => {
        setShowAddVendorModal(false);
    };

    function handleAddChargeClick(vendor) {
        setShowAddChargeModal(true);
        setSelectedVendor(vendor);
    }

    function handleAddVendorClick() {
        setShowAddVendorModal(true);
    }

    return (
        <Grid container>
            <WorkOrderViewBar
                selectedWorkOrder={props.selectedWorkOrder}
                handleViewClose={props.handleViewClose}
                reloadWorkOrders={props.reloadWorkOrders}
                handleAddVendorClick={handleAddVendorClick}
            />
            <Grid item xs={12}>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        flexWrap: "nowrap",
                        width: "100%",
                        padding: {xs: "0", lg: "10px"},
                        alignItems: "flex-end",
                    }}
                >
                    <WorkOrderInfoCard selectedWorkOrder={props.selectedWorkOrder}/>
                </Box>
            </Grid>
            <Grid item sx={{width: {xs: "100%", lg: "66.6%"}}}>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        flexWrap: "nowrap",
                        width: "100%",
                        padding: {xs: "0", lg: "10px"},
                        alignItems: "flex-end",
                    }}
                >
                    <WorkOrderNotesWideCard
                        selectedWorkOrder={props.selectedWorkOrder}
                        height="500px"
                    />
                </Box>
            </Grid>
            <Grid item sx={{width: {xs: "100%", lg: "33.3%"}}}>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        flexWrap: "nowrap",
                        width: "100%",
                        padding: {xs: "0", lg: "10px"},
                        alignItems: "flex-end",
                    }}
                >
                    <DocumentStore
                        files={[
                            ...props.selectedWorkOrder.imageFiles,
                            ...props.selectedWorkOrder.otherDocuments,
                        ]}
                        item={props.selectedWorkOrder}
                        type={"Work Order"}
                        closeView={props.handleViewClose}
                        height={"500px"}
                    />
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        padding: {xs: "10px", lg: "20px 30px"},
                    }}
                >
                    <Typography
                        sx={{fontSize: "20px", textAlign: "left", fontWeight: 800}}
                    >
                        Work Order Details
                    </Typography>
                    {props.selectedWorkOrder &&
                        props.selectedWorkOrder.vendors.length < 1 && (
                            <Typography
                                sx={{
                                    color: "#C6C5C5",
                                    fontSize: "15px",
                                    fontWeight: 700,
                                    width: "100%",
                                    textAlign: "center",
                                }}
                            >
                                There are no details for this Work Order
                            </Typography>
                        )}
                    {props.selectedWorkOrder &&
                        props.selectedWorkOrder.vendors.length > 0 &&
                        props.selectedWorkOrder.vendors.map(function (vendor, i) {
                            return (
                                <WorkOrderVendorDetailCard
                                    key={i}
                                    selectedWorkOrder={props.selectedWorkOrder}
                                    refreshDetails={props.refreshDetails}
                                    vendor={vendor}
                                    handleAddChargeClick={handleAddChargeClick}
                                />
                            );
                        }, this)}
                </Box>
            </Grid>

            {/*ADD CHARGE MODAL*/}
            {showAddChargeModal && (
                <WorkOrderAddCharge
                    showAddChargeModal={showAddChargeModal}
                    handleChargeClose={handleChargeClose}
                    selectedWorkOrder={props.selectedWorkOrder}
                    refreshDetails={props.refreshDetails}
                    vendor={selectedVendor}
                />
            )}

            {/*ADD VENDOR MODAL*/}
            {showAddVendorModal && (
                <AddWorkOrderVendor
                    showAddVendorModal={showAddVendorModal}
                    handleAddVendorClose={handleAddVendorClose}
                    selectedWorkOrder={props.selectedWorkOrder}
                    refreshDetails={props.refreshDetails}
                />
            )}
        </Grid>
    );
}
