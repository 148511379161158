import {Grid, MenuItem, TextField, Typography} from "@mui/material"
import moment from "moment"
import {CalendarDatePickerInput, YearPickerInput,} from "../../../components/inputs/datePickerInput"
import AppointmentCalendar from "../../../components/modals/listings/appointmentCalendar"
import AppointmentViewer from "../../../components/modals/listings/appointmentViewer"
import {ResponsiveRow} from "../../../constants/layout.constants"
import {useEffect, useState} from "react"
import {listAppointments} from "../../../services/listingsServices/listing.service";
import {adjustForTimezone} from "../../../util/util";

export default function ListingAppointments() {
    const [selectedMonth, setSelectedMonth] = useState(
        moment().month()
    )
    const [selectedYear, setSelectedYear] = useState(
        moment().year()
    )
    const [selectedDay, setSelectedDay] = useState(
        moment().date()
    )
    const [dateSelection, setDateSelection] = useState(new Date())
    const [appointments, setAppointments] = useState([])

    function handleDaySelect(value) {
        setSelectedDay(parseInt(value))
    }

    function handleMonthSelect(e) {
        const date = new Date(selectedYear, e.target.value, selectedDay);
        setDateSelection(date)
        applyNewDate(date)
    }

    function handleYearSelect(value) {
        const date = new Date(value);
        setDateSelection(date)
        applyNewDate(date)
    }

    function handleDateSelect(value) {
        const date = new Date(value)
        setDateSelection(value)
        applyNewDate(date)
    }

    function applyNewDate(date) {
        const year = moment(date).year()
        const month = moment(date).month()
        const day = moment(date).date()

        setSelectedMonth(month)
        setSelectedDay(day)
        setSelectedYear(year)
    }

    useEffect(() => {
        const date = adjustForTimezone(new Date(selectedYear, selectedMonth, selectedDay));
        listAppointments(date).then((data) => {
            setAppointments(data)
        })
    }, [selectedDay, selectedMonth, selectedYear])

    return (
        <ResponsiveRow
            sx={{
                flexDirection: "column",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <ResponsiveRow
                sx={{
                    width: {xs: "100%", lg: "80%"},
                    marginTop: "100px",
                    padding: "10px auto",
                }}
            >
                <Typography variant="h4" sx={{width: "100%", textAlign: "start"}}>
                    Booked Appointments
                </Typography>
            </ResponsiveRow>
            <ResponsiveRow
                sx={{
                    marginTop: "10px",
                    width: {xs: "100%", lg: "80%"},
                    padding: 0,
                    justifyContent: {xs: "flex-start", lg: "flex-start"},
                    alignItems: "center",
                    flexWrap: "nowrap",
                }}
            >
                <Grid
                    item
                    sx={{
                        display: {xs: "none", lg: "flex"},
                        width: {xs: "33.3%", lg: "300px"},
                    }}
                >
                    <CalendarDatePickerInput
                        label="Select Calendar Date"
                        width="90%"
                        onSelectDate={handleDateSelect}
                        day={selectedDay}
                        month={selectedMonth}
                        year={selectedYear}
                    />
                </Grid>
                <Grid
                    item
                    sx={{
                        display: {xs: "none", lg: "flex"},
                        width: {xs: "33.3%", lg: "300px"},
                    }}
                >
                    <TextField
                        variant="outlined"
                        sx={{
                            width: {xs: "100%", lg: "90%"},
                            backgroundColor: "#fff",
                            borderRadius: "10px",
                            textAlign: "start",
                            margin: "5px",
                        }}
                        select
                        value={selectedMonth}
                        onChange={handleMonthSelect}
                    >
                        <MenuItem value={0}>January</MenuItem>
                        <MenuItem value={1}>February</MenuItem>
                        <MenuItem value={2}>March</MenuItem>
                        <MenuItem value={3}>April</MenuItem>
                        <MenuItem value={4}>May</MenuItem>
                        <MenuItem value={5}>June</MenuItem>
                        <MenuItem value={6}>July</MenuItem>
                        <MenuItem value={7}>August</MenuItem>
                        <MenuItem value={8}>September</MenuItem>
                        <MenuItem value={9}>October</MenuItem>
                        <MenuItem value={10}>November</MenuItem>
                        <MenuItem value={11}>December</MenuItem>
                    </TextField>
                </Grid>

                <Grid
                    item
                    sx={{
                        display: {xs: "none", lg: "flex"},
                        flexDisplay: "row",
                        width: {xs: "33.3%%", lg: "300px"},
                        // flexWrap: "nowrap",
                    }}
                >
                    <YearPickerInput
                        onSelectDate={handleYearSelect}
                        preset={Date.now()}
                        value={dateSelection}
                    />
                </Grid>
            </ResponsiveRow>
            <ResponsiveRow
                sx={{
                    justifyContent: "space-between",
                    alignItems: "center",
                    height: "fit-content",
                    width: {xs: "100%", lg: "80%"},
                    padding: "0",
                    marginTop: "20px",
                }}
            >
                <AppointmentCalendar
                    month={selectedMonth}
                    year={selectedYear}
                    selectDate={handleDaySelect}
                    day={selectedDay}
                />
                <AppointmentViewer month={selectedMonth} day={selectedDay} appointments={appointments}/>
            </ResponsiveRow>
        </ResponsiveRow>
    )
}
