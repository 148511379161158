import {
  Alert,
  AlertTitle,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { ModalAcceptButton } from "../../../../constants/component.constants";
import { ResponsiveRow } from "../../../../constants/layout.constants";
import ModalContainerModule from "../../../modules/modalContainer";
import Permit from "../../../../util/permit";
import { useEffect, useState } from "react";

export default function AddMeterModal(props) {
  /**
   *
   * REQUIRED PROPS
   *
   * AddDiv
   * bulkEdit
   * EditDiv
   * saveMeter
   *
   * handleAddMeterClose
   * handleComplexOptionChange
   * handleFacilityOptionChange
   * handleInvoiceTenantToggle
   * handleMeterTypeChange
   * handleRateTypeChange
   * handleReadingFrequencyChange
   * handleTieredItemAdd
   * handleUnitOptionChange
   *
   * setComplex
   * setComplexId
   * setFacilityId
   * setMeterName
   * setProperty
   * setRate
   * setStandingCharge
   *
   * Blwtr
   * bulkWaterMeterTypes
   * complex
   * complexId
   * complexOptions
   * facilityId
   * facilityOptions
   * invoiceTenant
   * meterName
   * meterReadingPeriods
   * meterTypeCode
   * meterTypes
   * meterTypeCode
   * property
   * rate
   * rateType
   * rateTypeOptions
   * readingPeriod
   * selectedMeter
   * standingCharge
   * tieredItemsTableRows
   * tieredItemsTotal
   * unitId
   * unitOptions
   * isMeterTaxed
   * handleMeterTaxedToggle
   *
   */

  const [attachedToProperty, setAttachedToProperty] = useState(false);


  useEffect(() => {
    props.property ? setAttachedToProperty(true) : setAttachedToProperty(false);
  }, [props]);

  return (
    <ModalContainerModule
      size={props.EditDiv ? "specialEditMeter" : "specialAddMeter"}
      accept={props.bulkEdit ? props.bulkEditMeters : props.saveMeter}
      cancel={props.handleAddMeterClose}
      openModal={props.AddDiv}
      modalTitle={props.EditDiv ? "Edit Meter" : "Add Meter"}
      acceptButtonText={
        !props.EditDiv ? "Create" : props.bulkEdit ? "Save Bulk Edit" : "Update"
      }
    >
      <ResponsiveRow
        container
        sx={{
          width: "100%",
          height: "auto",

          justifyContent: "flex-start",
          alignItems: "flex-start",
        }}
      >
        {!props.bulkEdit && (
          <FormControl
            sx={{ width: { xs: "100%", lg: "50%" }, marginBottom: "10px" }}
          >
            <InputLabel>Meter Name</InputLabel>
            <OutlinedInput
              label="Meter Name"
              id="meterName"
              sx={{
                width: { xs: "100%", md: "90%" },
                marginBottom: "10px",
              }}
              onChange={(event) => {
                const value = event.target.value;
                props.setMeterName(value);
                let obj = props.selectedMeter;
                obj.name = value;
              }}
              value={
                props.selectedMeter
                  ? props.selectedMeter && props.selectedMeter.name
                  : props.meterName
              }
              inputProps={
                <TextField
                  sx={{
                    width: { xs: "100%", md: "90%" },
                  }}
                />
              }
            />
          </FormControl>
        )}
        <Permit services={"PREMIUM,HOA,METER,BLWTR,CORE,LEAN"}>
          <FormControl
            sx={{ width: { xs: "100%", lg: "50%" }, marginBottom: "10px" }}
          >
            <InputLabel>Meter Reading Frequency</InputLabel>
            <Select
              label="Meter Reading Frequency"
              sx={{ width: { xs: "100%", md: "90%" }, marginBottom: "10px" }}
              id={"readingPeriod"}
              disabled={props.Blwtr}
              value={
                props.selectedMeter
                  ? props.selectedMeter && props.selectedMeter.readingFrequency
                  : props.readingPeriod
              }
              onChange={(event) => props.handleReadingFrequencyChange(event)}
            >
              {props.meterReadingPeriods &&
              props.meterReadingPeriods.length > 0 ? (
                props.meterReadingPeriods.map(function (readingPeriod, i) {
                  return (
                    <MenuItem key={i} value={readingPeriod.value}>
                      {readingPeriod.label}
                    </MenuItem>
                  );
                }, this)
              ) : (
                <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
              )}
            </Select>
          </FormControl>
        </Permit>
        {!props.Blwtr && !props.bulkEdit ? (
          <FormControl
            sx={{ width: { xs: "100%", lg: "50%" }, marginBottom: "10px" }}
          >
            <InputLabel>Meter Type</InputLabel>
            <Select
              sx={{ width: { xs: "100%", md: "90%" }, marginBottom: "10px" }}
              id={"type"}
              label="Meter Type"
              value={
                props.selectedMeter
                  ? props.selectedMeter && props.selectedMeter.meterTypeCode
                  : props.meterTypeCode
              }
              onChange={(event) => props.handleMeterTypeChange(event)}
            >
              {props.meterTypes && props.meterTypes.length > 0 ? (
                props.meterTypes.map(function (meter, i) {
                  return (
                    <MenuItem key={i} value={meter.value}>
                      {meter.label}
                    </MenuItem>
                  );
                }, this)
              ) : (
                <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
              )}
            </Select>
          </FormControl>
        ) : (
          !props.bulkEdit && (
            <FormControl
              sx={{ width: { xs: "100%", lg: "50%" }, marginBottom: "10px" }}
            >
              <InputLabel>Meter Type</InputLabel>
              <Select
                label="Meter Type"
                sx={{
                  width: { xs: "100%", md: "90%" },
                  marginBottom: "10px",
                }}
                id={"type"}
                value={
                  props.selectedMeter
                    ? props.selectedMeter && props.selectedMeter.meterTypeCode
                    : props.meterTypeCode
                }
                onChange={(event) => props.handleMeterTypeChange(event)}
              >
                {props.bulkWaterMeterTypes &&
                props.bulkWaterMeterTypes.length > 0 ? (
                  props.bulkWaterMeterTypes.map(function (meter, i) {
                    return (
                      <MenuItem key={i} value={meter.value}>
                        {meter.label}
                      </MenuItem>
                    );
                  }, this)
                ) : (
                  <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
                )}
              </Select>
            </FormControl>
          )
        )}
        {!props.bulkEdit && !props.Blwtr && (
          <Grid
            item
            sx={{
              width: { xs: "100%", lg: "33.3%" },
              marginBottom: "10px",
            }}
          >
            <InputLabel>Attach Meter To:</InputLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
            >
              <FormControlLabel
                value="Property"
                checked={props.property}
                control={
                  <Radio
                    onChange={() => {
                      props.setProperty(true);
                      props.setComplex(false);
                      props.setComplexId("");
                      let obj = props.selectedMeter;
                      obj.complexId = "";
                    }}
                  />
                }
                label="Property/Unit"
              />
              <FormControlLabel
                value="Complex"
                checked={props.complex}
                control={
                  <Radio
                    onChange={() => {
                      props.setComplex(true);
                      props.setProperty(false);
                      props.setFacilityId("");
                      let obj = props.selectedMeter;
                      obj.facilityId = "";
                    }}
                  />
                }
                label="Complex"
              />
            </RadioGroup>
            <br />
          </Grid>
        )}
        {attachedToProperty && !props.bulkEdit && (
          <FormControl
            sx={{ width: { xs: "100%", lg: "50%" }, marginBottom: "10px" }}
          >
            <InputLabel>Facility</InputLabel>
            <Select
              label="Facility"
              sx={{ width: { xs: "100%", md: "90%" } }}
              id={"facility"}
              value={
                props.selectedMeter
                  ? props.selectedMeter && props.selectedMeter.facilityId
                  : props.facilityId
              }
              onChange={(event) => props.handleFacilityOptionChange(event)}
            >
              {props.facilityOptions && props.facilityOptions.length > 0 ? (
                props.facilityOptions.map(function (facility, i) {
                  return (
                    <MenuItem key={i} value={facility.value}>
                      {facility.label}
                    </MenuItem>
                  );
                }, this)
              ) : (
                <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
              )}
            </Select>
          </FormControl>
        )}
        {props.complex && !props.bulkEdit && (
          <FormControl
            sx={{ width: { xs: "100%", lg: "50%" }, marginBottom: "10px" }}
          >
            <InputLabel>Complex</InputLabel>
            <Select
              label="Complex"
              sx={{ width: { xs: "100%", md: "90%" } }}
              id={"complex"}
              value={
                props.selectedMeter
                  ? props.selectedMeter && props.selectedMeter.complexId
                  : props.complexId
              }
              onChange={(event) => props.handleComplexOptionChange(event)}
            >
              {props.complexOptions && props.complexOptions.length > 0 ? (
                props.complexOptions.map(function (complex, i) {
                  return (
                    <MenuItem key={i} value={complex.value}>
                      {complex.label}
                    </MenuItem>
                  );
                }, this)
              ) : (
                <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
              )}
            </Select>
          </FormControl>
        )}
        {props.selectedMeter &&
          !props.bulkEdit &&
          (props.selectedMeter.meterTypeCode === "WU" ||
            props.selectedMeter.meterTypeCode === "EU") && (
            <FormControl
              sx={{ width: { xs: "100%", lg: "50%" }, marginBottom: "10px" }}
            >
              <InputLabel>Unit</InputLabel>
              <Select
                label="Unit"
                sx={{ width: { xs: "100%", md: "90%" } }}
                id={"type"}
                value={
                  props.selectedMeter
                    ? props.selectedMeter && props.selectedMeter.unitId
                    : props.unitId
                }
                onChange={(event) => props.handleUnitOptionChange(event)}
              >
                {props.unitOptions && props.unitOptions.length > 0 ? (
                  props.unitOptions.map(function (unit, i) {
                    return (
                      <MenuItem key={i} value={unit.value}>
                        {unit.label}
                      </MenuItem>
                    );
                  }, this)
                ) : (
                  <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
                )}
              </Select>
            </FormControl>
          )}

        <Permit services={"PREMIUM,HOA,METER,BLWTR,CORE,LEAN"}>
          {props.selectedMeter &&
            (props.selectedMeter.meterTypeCode === "WU" ||
              props.selectedMeter.meterTypeCode === "EU" ||
              props.bulkEdit) && (
              <FormControl
                sx={{ width: { xs: "100%", lg: "50%" }, marginBottom: "10px" }}
              >
                <InputLabel>Rate Type</InputLabel>
                <Select
                  label="Rate Type"
                  sx={{ width: { xs: "100%", md: "90%" } }}
                  id={"type"}
                  value={
                    props.selectedMeter
                      ? props.selectedMeter && props.selectedMeter.rateTypeCode
                      : props.rateType
                  }
                  onChange={(event) => props.handleRateTypeChange(event)}
                >
                  {props.rateTypeOptions && props.rateTypeOptions.length > 0 ? (
                    props.rateTypeOptions.map(function (rateType, i) {
                      return (
                        <MenuItem key={i} value={rateType.value}>
                          {rateType.label}
                        </MenuItem>
                      );
                    }, this)
                  ) : (
                    <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
                  )}
                </Select>
              </FormControl>
            )}

          {props.selectedMeter &&
            props.selectedMeter.rateTypeCode === "FIX" && (
              <FormControl
                sx={{ width: { xs: "100%", lg: "50%" }, marginBottom: "10px" }}
              >
                <InputLabel>
                  {props.Blwtr ? "Rate (Price per Liter)" : "Rate"}
                </InputLabel>
                <OutlinedInput
                  label={props.Blwtr ? "Rate (Price per Liter)" : "Rate"}
                  id="meterName"
                  sx={{
                    width: { xs: "100%", md: "90%" },
                  }}
                  onChange={(event) => {
                    const value = event.target.value;
                    props.setRate(value);
                    let obj = props.selectedMeter;
                    obj.rate = value;
                  }}
                  value={
                    props.selectedMeter
                      ? props.selectedMeter && props.selectedMeter.rate
                      : props.rate
                  }
                  inputProps={
                    <TextField
                      sx={{
                        width: { xs: "100%", md: "90%" },
                      }}
                    />
                  }
                />
              </FormControl>
            )}
          {props.invoiced && attachedToProperty &&
            ((props.selectedMeter && props.selectedMeter.meterTypeCode === "WU") ||
             (props.selectedMeter && props.selectedMeter.meterTypeCode === "EU")) && (
            <FormControl
              sx={{ width: { xs: "100%", lg: "50%" }, marginBottom: "10px" }}
            >
              <InputLabel>Standing Charge</InputLabel>
              <OutlinedInput
                label="Standing Charge"
                id="meterName"
                sx={{
                  width: { xs: "100%", md: "90%" },
                }}
                onChange={(event) => {
                  const value = event.target.value;
                  props.setStandingCharge(value);
                  let obj = props.selectedMeter;
                  obj.standingCharge = value;
                }}
                value={
                  props.selectedMeter
                    ? props.selectedMeter &&
                      props.selectedMeter.standingCharge
                    : props.standingCharge
                }
                inputProps={
                  <TextField
                    sx={{
                      width: { xs: "100%", md: "90%" },
                    }}
                  />
                }
              />
            </FormControl>
          )}

            {/* The permit below give a duplicate. Subject for confirmation on other Meter Types */}
          {/* <Permit services={"PREMIUM,HOA,METER,CORE,LEAN"}>
            {props.bulkEdit && (
              <FormControl
                sx={{ width: { xs: "100%", lg: "50%" }, marginBottom: "10px" }}
              >
                <InputLabel>Standing Charge</InputLabel>
                <OutlinedInput
                  label="Standing Charge"
                  id="meterName"
                  sx={{
                    width: { xs: "100%", md: "90%" },
                  }}
                  onChange={(event) => {
                    const value = event.target.value;
                    props.setStandingCharge(value);
                    let obj = props.selectedMeter;
                    obj.standingCharge = value;
                  }}
                  value={
                    props.selectedMeter
                      ? props.selectedMeter &&
                        props.selectedMeter.standingCharge
                      : props.standingCharge
                  }
                  inputProps={
                    <TextField
                      sx={{
                        width: { xs: "100%", md: "90%" },
                      }}
                    />
                  }
                />
              </FormControl>
            )}
          </Permit> */}
          {props.selectedMeter &&
            attachedToProperty &&
            (props.selectedMeter.meterTypeCode === "BLK" ||
              props.selectedMeter.meterTypeCode === "EU" ||
              props.selectedMeter.meterTypeCode === "WU" ||
              props.selectedMeter.meterTypeCode === "OTH" ||
              props.selectedMeter.meterTypeCode === "PEU" ||
              props.selectedMeter.meterTypeCode === "RTL") && (
              <Grid
                item
                sx={{ width: { xs: "100%", lg: "50%" }, marginBottom: "10px" }}
              >
                <Permit services={"HOA"}>
                  <InputLabel>Is Meter Invoiced?</InputLabel>
                </Permit>
                <Permit services={"PREMIUM,METER,CORE,LEAN"}>
                  <InputLabel>Is this meter invoiced to the unit tenant?</InputLabel>
                </Permit>
                <Switch
                  checked={
                    props.selectedMeter
                      ? props.selectedMeter.invoiced
                      : props.invoiced
                  }
                  onChange={(event) => props.handleInvoicedToggle(event)}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </Grid>
            )}
            {props.selectedMeter && props.selectedMeter.meterTypeCode === "EU" && props.invoiced && props.selectedMeter.rateTypeCode && (
              <Grid
                  item
                  sx={{ width: { xs: "100%", lg: "50%" }, marginBottom: "10px"}}
                >
                  <InputLabel>
                    Is this Meter Taxed?
                  </InputLabel>
                  <Switch
                    checked={props.isMeterTaxed}
                    onChange={props.handleMeterTaxedToggle}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </Grid>
            )}
        </Permit>
      </ResponsiveRow>

      <br />

      {props.selectedMeter && props.selectedMeter.rateTypeCode === "TIERD" && (
        <ResponsiveRow sx={{ height: "auto", margin: "auto", padding: "10px" }}>
          <Typography
            sx={{ marginBottom: "5px" }}
            id="modal-modal-title"
            variant="h5"
            component="h4"
          >
            Meter Table
          </Typography>
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: "30%" }}>Minimum Units</TableCell>
                  <TableCell sx={{ width: "30%" }}>Maximum Units</TableCell>
                  <TableCell sx={{ width: "30%" }}>Charge per Unit</TableCell>
                  <TableCell sx={{ width: "10%" }}>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{props.tieredItemsTableRows}</TableBody>
            </Table>
          </TableContainer>
          <ResponsiveRow container>
            <Alert sx={{ width: "100%" }} severity="info">
              <AlertTitle>Total: {props.tieredItemsTotal}</AlertTitle>
            </Alert>
          </ResponsiveRow>
          <ResponsiveRow
            item
            sx={{ justifyContent: { xs: "center", lg: "flex-end" } }}
          >
            <ModalAcceptButton
              //   type="submit"
              onClick={() => props.handleTieredItemAdd()}
              sx={{ width: { xs: "100%", lg: "200px" } }}
            >
              {" "}
              Add row{" "}
            </ModalAcceptButton>
          </ResponsiveRow>
        </ResponsiveRow>
      )}
    </ModalContainerModule>
  );
}
