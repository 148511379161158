import {Button, Grid, TextField, Typography} from "@mui/material"
import {Box} from "@mui/system"

import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import {NavLink} from "react-router-dom"

export default function WorkOrderLongCard() {
    return (
        <Grid
            container
            sx={{
                display: "flex",
                flexDirection: "column",
                maxWidth: "370px",
                height: "520px",
                backgroundColor: "#fff",
                borderRadius: "10px",
                margin: "20px auto",
                padding: 0,
                boxShadow: "0 4px 12px 0 rgba(0,0,0,0.25)",
            }}
        >
            <Typography
                sx={{
                    fontSize: "18px",
                    color: "#037171",
                    fontWeight: "800",
                    textAlign: "start",
                    marginBottom: "5px",
                    padding: "20px 20px 5px 20px",
                }}
            >
                Work Order Notes
            </Typography>
            <Typography
                sx={{
                    fontSize: "16px",
                    fontWeight: "700",
                    textAlign: "start",
                    marginBottom: "5px",
                    padding: "5px 25px 0px 25px",
                }}
            >
                New Note
            </Typography>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-around",
                    alignItems: "center",
                    padding: "20px",
                }}
            >
                <TextField
                    sx={{
                        backgroundColor: "#efefef",
                        borderColor: "#efefef",
                    }}
                    id="addNoteInput"
                    label="Add Note"
                    variant="outlined"
                    size="small"
                    multiline
                    rows={2}
                />
                <Button
                    sx={{
                        backgroundColor: "#037171",
                        color: "#fff",
                        "&:hover": {
                            backgroundColor: "#EFEFEF",
                            color: "#037171",
                        },
                        margin: "10px 5px",
                        width: "100%",
                    }}
                >
                    Save
                </Button>
            </Box>

            <Box
                sx={{
                    backgroundColor: "#EFEFEF",
                    borderRadius: "10px",
                    height: "50.5%",
                    minWidth: "100%",
                    padding: "auto 0",
                    margin: "5px 0 0 0",
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                        flexWrap: "nowrap",
                        width: "100%",
                        padding: "10px",
                    }}
                >
                    <Typography
                        sx={{
                            fontWeight: "bold",
                            fontSize: "14px",
                            marginBottom: "10px",
                        }}
                    >
                        Recent Documents
                    </Typography>

                    <NavLink
                        to="/"
                        style={{
                            textDecoration: "none",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            flexWrap: "nowrap",
                            fontSize: "12px",
                            color: "#037171",
                        }}
                    >
                        See all notes
                        <ChevronRightIcon sx={{margin: "auto"}}/>
                    </NavLink>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        minWidth: "100%",
                        padding: "10px",
                    }}
                >
                    <Typography
                        sx={{
                            color: "#C6C5C5",
                            fontSize: "11px",
                            fontWeight: 700,
                            width: "100%",
                            textAlign: "center",
                        }}
                    >
                        There are no documents for this work order
                    </Typography>
                </Box>
            </Box>
        </Grid>
    )
}
