import {
    Alert,
    AlertTitle,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    Typography,
} from "@mui/material";
import {useEffect, useState} from "react";
import {ModalAcceptButton, ModalCancelButton} from "../../../../constants/component.constants";
import {ResponsiveRow} from "../../../../constants/layout.constants";
import {getIdTypes} from "../../../../services/list.service";
import {personSearch} from "../../../../services/shared.service";
import {isEmptyArray} from "../../../../util/util";
import ModalContainerModule from "../../../modules/modalContainer";

const AddVendorContactPerson = (props) => {
    const [idNumber, setIdNumber] = useState();
    const [idType, setIdType] = useState();
    const [idTypes, setIdTypes] = useState([]);
    const [foundUser, setFoundUser] = useState([]);
    const [foundState, setFoundState] = useState(true);

    function handleAddContact(user) {
        props.setContactPerson(user);
        props.setIndividualSearch(false);
    }
    function handleClose() {
        setIdType(null)
        setIdNumber(null)
        setFoundUser([])
        setFoundState(true)
        props.setIndividualSearch(false);
    }

    function searchPersonOrg() {
        let data = {
            searchType: "Individual",
            idType: idType,
            idNumber: idNumber,
        };
        personSearch(data).then((response) => {
            setFoundUser(response);
            setFoundState(true);
            if (isEmptyArray(response)) {
                setFoundState(false);
                setFoundUser([]);
            }
        });
    }

    useEffect(() => {
        getIdTypes().then((idTypes) => setIdTypes(idTypes));

        return () => {
            setIdTypes([]);
        };
    }, []);

    return (
      <ModalContainerModule
        size="specialAddVendorContact"
        cancel={() => props.setIndividualSearch(false)}
        openModal={props.individualSearch}
        modalTitle="Add Vendor Contact Person"
        acceptButtonText="Add"
      >
        <Alert severity="info" sx={{ width: "100%" }}>
          Use this form to search for an existing individual to add as a user.
          {/* If no matches are found, you can create a new individual as a user. */}
        </Alert>
        <ResponsiveRow sx={{ height: "auto", alignItems: "center" }}>
          <ResponsiveRow container sx={{ height: "auto", padding: "10px 0" }}>
            <FormControl sx={{ width: { xs: "100%", lg: "50%" } }}>
              <InputLabel ID="idSearchLabel">ID Number</InputLabel>
              <OutlinedInput
                sx={{ width: { xs: "100%", lg: "90%" } }}
                id="idNoSearch"
                label="ID Number"
                value={idNumber}
                onChange={(ev) => setIdNumber(ev.target.value)}

              />
            </FormControl>

            <FormControl
              item
              sx={{ width: { xs: "100%", lg: "50%" }, height: "auto" }}
            >
              <InputLabel if="idTypeLabel">ID Type</InputLabel>
              <Select
                sx={{ width: { xs: "100%", lg: "90%" } }}
                id="idTypeSearch"
                label="ID Type"
                value={idType}
                onChange={(ev) => setIdType(ev.target.value)}

              >
                {idTypes && idTypes.length > 0 ? (
                  idTypes.map(function (idType, i) {
                    return (
                      <MenuItem key={idType.value} value={idType.value}>
                        {idType.label}
                      </MenuItem>
                    );
                  }, this)
                ) : (
                  <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
                )}
              </Select>
            </FormControl>
          </ResponsiveRow>
          <ResponsiveRow
            item
            sx={{
              justifyContent: { xs: "center", lg: "flex-end" },
              height: "auto",

              padding: 0,
            }}
          >
            <ModalAcceptButton
              variant="contained"
              type="submit"
              onClick={searchPersonOrg}
              sx={{
                width: { xs: "100%", lg: "150px" },
              }}
            >
              Search
            </ModalAcceptButton>
          </ResponsiveRow>

          <ResponsiveRow
            sx={{
              width: "100%",
              height: "auto",

              flexDirection: "column",
              justifyContent: "flex-start",

              padding: { xs: 0, lg: "10px" },
            }}
          >
            <Typography color="primary" variant="h5">
              Found Users
            </Typography>
            {foundUser && foundUser.length > 0 && (
              <Typography variant="body2">Tap to select</Typography>
            )}
            {foundUser &&
              foundUser.length > 0 &&
              foundUser.map(function (user, i) {
                return (
                  <ResponsiveRow
                    key={user.id}
                    sx={{ width: "100%", height: "auto" }}
                  >
                    <Grid
                      item
                      onClick={() => handleAddContact(user)}
                      sx={{
                        width: "100%",
                        padding: "20px",
                        "&:hover": {
                          backgroundColor: "#efefef",
                          borderRadius: "10px",
                        },
                      }}
                    >
                      {user.name}
                    </Grid>
                  </ResponsiveRow>
                );
              }, this)}

            {!foundState && (
              <Alert
                sx={{
                  width: "100%",
                  overflow: "hidden",
                  marginTop: "20px",
                  padding: "20x 0 0 0",
                }}
                severity="error"
              >
                <AlertTitle color="error">No Individuals found</AlertTitle>
                <Typography sx={{ width: "100%" }}>
                  There were no contact person found with that ID number.
                  Would you like to create a new contact person?
                </Typography>
                 <ResponsiveRow
                sx={{
                  width: "100%",
                  justifyContent: "flex-end",
                  height: "auto",
                }}
              >
                <Grid sx={{ width: "100%" }}>
                  <ModalAcceptButton
                    onClick={props.showAddNewContactPersonForm}
                    sx={{ width: { xs: "100%", lg: "100px" } }}
                  >
                    Yes
                  </ModalAcceptButton>
                  <ModalCancelButton
                    onClick={handleClose}
                    sx={{ width: { xs: "100%", lg: "100px" } }}
                  >
                    No
                  </ModalCancelButton>
                </Grid>
              </ResponsiveRow>
              </Alert>
            )}
          </ResponsiveRow>
        </ResponsiveRow>
      </ModalContainerModule>
    );
};

export default AddVendorContactPerson;
