/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";

import {
  formatDate,
  getRefNumber,
  isArray,
  isEmptyArray,
  isEmptyString,
  isNullUndefined,
  isValidDate,
} from "../util/util";
import {
  downloadReceipts,
  exportReceipts,
  listAllPaymentMethods,
  listReceipts,
  loadReceiptLease,
  loadReceiptOwnership,
  loadTotalReceipts,
} from "../services/receipts.service";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/en-gb";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { Cancel, Delete, Print, Visibility } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { getAllAccounts } from "../services/list.service";
import { chargeItemList, saveDebitNote } from "../services/shared.service";
import ExportDocumentsModal from "../components/modals/exportDocuments";
import { printReceipt } from "../services/print.service";
import { renderCellExpand } from "../util/renderCellExpand";
import {
  ModalAcceptButton,
  ModalCancelButton,
  TopBarContainedBrandButton,
  TopBarContainedCancelButton,
  TopBarContainer,
} from "../constants/component.constants";
import { ResponsiveRow } from "../constants/layout.constants";
import ViewReceiptModal from "../components/modals/accounting/receipts/viewReceiptModal";
import CreateReceiptChargeModal from "../components/modals/accounting/receipts/createReceiptChargeModal";
import { issueResponseMessage, issueWarnMessage } from "../actions/message";
import dayjs from "dayjs";
import MuiDataGrid from "../components/modules/MuiDataGrid";

export default function Receipts() {
  const user = useSelector((state) => state.auth.user);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 50,
  });
  const [filterReceipts] = useState(true);
  const [task, setTask] = useState("");
  const [receiptNumber, setReceiptNumber] = useState("");
  const [receiptTo, setReceiptTo] = useState("");
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");
  const [receiptFromDate, setReceiptFromDate] = useState("");
  const [receiptToDate, setReceiptToDate] = useState("");
  const [receiptsList, setReceiptsList] = useState([]);
  const [totalReceipts, setTotalReceipts] = useState(0);
  const [selectionModel, setSelectionModel] = useState([]);
  const [downloadReceiptsModal, setDownloadReceiptsModal] = useState(false);
  const [receiptToView, setReceiptToView] = useState(null);
  const [viewReceipt, setViewReceipt] = useState(false);
  const [debitNote, setDebitNote] = useState(null);
  const [chargeItems, setChargeItems] = useState([]);
  const [titleText, setTitleText] = useState("");
  const [severityText, setSeverityText] = useState("");
  const [bodyText, setBodyText] = useState("");
  const [showDebitModal, setShowDebitModal] = useState(false);
  const [allAccounts, setAllAccounts] = useState([]);
  const [chargeItemsTableRows, setChargeItemsTableRows] = useState("");
  const [loading, setLoading] = useState(true);
  const [showAll, setShowAll] = useState(false);
  const [rowsPerPage] = useState(300);

  useEffect(() => {
    setLoading(true);
    if (!showAll) {
      let searchObject = {
        receiptNumber: receiptNumber,
        selectedPaymentMethod: selectedPaymentMethod,
        receiptFromDate: "",
        receiptToDate: "",
        receiptTo: receiptTo,
        pageNo: paginationModel.page,
        rowsPerPage: paginationModel.pageSize,
      };

      if (!isNullUndefined(receiptFromDate) && isValidDate(receiptFromDate)) {
        searchObject.receiptFromDate = receiptFromDate;
      }
      if (!isNullUndefined(receiptToDate) && isValidDate(receiptToDate)) {
        searchObject.receiptToDate = receiptToDate;
      }

      loadTotalReceipts(searchObject).then((data) =>
        setTotalReceipts(data.totalReceipts)
      );
      listReceipts(searchObject)
        .then((data) => setReceiptsList(data))
        .finally(() => {
          setLoading(false);
        });
    }
  }, [paginationModel.page, paginationModel.pageSize]);

  useEffect(() => {
    populateChargeItems();
  }, [chargeItems]);

  useEffect(() => {
    setLoading(true);
    setShowAll(false);
    let searchObject = {
      receiptNumber: receiptNumber,
      selectedPaymentMethod: selectedPaymentMethod,
      receiptFromDate: "",
      receiptToDate: "",
      receiptTo: receiptTo,
      pageNo: paginationModel.page,
      rowsPerPage: paginationModel.pageSize,
    };

    if (!isNullUndefined(receiptFromDate) && isValidDate(receiptFromDate)) {
      searchObject.receiptFromDate = receiptFromDate;
    }
    if (!isNullUndefined(receiptToDate) && isValidDate(receiptToDate)) {
      searchObject.receiptToDate = receiptToDate;
    }
    listAllPaymentMethods().then((data) => setPaymentMethods(data));
    getAllAccounts().then((allAccounts) => setAllAccounts(allAccounts));
    loadTotalReceipts(searchObject).then((data) =>
      setTotalReceipts(data.totalReceipts)
    );
    listReceipts(searchObject)
      .then((data) => setReceiptsList(data))
      .finally(() => {
        setLoading(false);
      });
  }, [user.proxyFacility]);

  function handleClearClicked() {
    setTask("");
    setReceiptNumber("");
    setReceiptTo("");
    setSelectedPaymentMethod("");
    setReceiptFromDate("");
    setReceiptToDate("");
    setSelectionModel([]);
  }

  function handleShowAllClicked() {
    setReceiptsList([]);
    setShowAll(true);
    setLoading(true);
    let searchObject = {
      receiptNumber: receiptNumber,
      selectedPaymentMethod: selectedPaymentMethod,
      receiptFromDate: "",
      receiptToDate: "",
      receiptTo: receiptTo,
      pageNo: paginationModel.page,
      rowsPerPage: paginationModel.pageSize,
    };

    if (!isNullUndefined(receiptFromDate) && isValidDate(receiptFromDate)) {
      searchObject.receiptFromDate = receiptFromDate;
    }
    if (!isNullUndefined(receiptToDate) && isValidDate(receiptToDate)) {
      searchObject.receiptToDate = receiptToDate;
    }
    loadTotalReceipts(searchObject).then((response) => {
      setTask("Filter");
      setTotalReceipts(response.totalReceipts);
      let receipts = new Set();
      let promises = [];
      let size = Math.ceil(response.totalReceipts / rowsPerPage);
      for (let i = 0; i < size; i++) {
        searchObject.pageNo = i;
        searchObject.rowsPerPage = rowsPerPage;
        promises.push(
          listReceipts(searchObject).then((data) => {
            new Set(data).forEach(receipts.add, receipts);
            setReceiptsList([...receipts]);
          })
        );
      }

      Promise.all(promises).then(() => {
        setReceiptsList([...receipts]);
        setLoading(false);
      });
    });
  }

  function handleSearchClicked() {
    setLoading(true);
    setShowAll(false);
    let searchObject = {
      receiptNumber: receiptNumber,
      selectedPaymentMethod: selectedPaymentMethod,
      receiptFromDate: "",
      receiptToDate: "",
      receiptTo: receiptTo,
      pageNo: paginationModel.page,
      rowsPerPage: paginationModel.pageSize,
    };

    if (!isNullUndefined(receiptFromDate) && isValidDate(receiptFromDate)) {
      searchObject.receiptFromDate = receiptFromDate;
    }
    if (!isNullUndefined(receiptToDate) && isValidDate(receiptToDate)) {
      searchObject.receiptToDate = receiptToDate;
    }

    loadTotalReceipts(searchObject).then((data) =>
      setTotalReceipts(data.totalReceipts)
    );
    listReceipts(searchObject)
      .then((data) => setReceiptsList(data))
      .finally(() => {
        setLoading(false);
        setTask("Filter");
      });
  }

  function handleCloseDownloadReceiptsModal() {
    setDownloadReceiptsModal(false);
    setSelectionModel([]);
    setTitleText("");
    setTask("");
    setSeverityText("");
    setBodyText("");
    setTask("Filter");
  }

  function handleExportReceipts() {
    if (
      isNullUndefined(selectionModel) ||
      !isArray(selectionModel) ||
      isEmptyArray(selectionModel)
    ) {
      issueWarnMessage(
        "Could not determine the items to be exported. Please refresh the page and try again."
      );
      return;
    }
    let sendData = {
      selectedReceipts: selectionModel,
    };
    exportReceipts(sendData).then(() => {
      setTask("Filter");
      setSelectionModel([]);
    });
  }

  function handleDownloadReceipts() {
    setDownloadReceiptsModal(true);
    let title =
      selectionModel.length > 1
        ? "Download " + selectionModel.length + " Receipts"
        : "Download Receipt";
    let body =
      "Note: Processing the selected Receipts may take some time." +
      " Therefore, the exported data will be emailed to you when it is finished being packaged." +
      " Please ensure the email set for your account is valid, working and that you have access to it in order to receive the export data.";
    setTitleText(title);
    setSeverityText("info");
    setBodyText(body);
  }

  function positiveExportDocsAction() {
    downloadSelectedReceipts();
  }

  function downloadSelectedReceipts() {
    if (isNullUndefined(selectionModel) || selectionModel.size < 1) {
      issueWarnMessage(
        "Could not determine the download items. Please refresh the page and try again."
      );
      return;
    }

    let sendData = {
      receiptIds: selectionModel,
    };
    downloadReceipts(sendData).then((response) => {
      issueResponseMessage(response);
      handleCloseDownloadReceiptsModal();
    });
  }

  function handleViewReceiptClicked(item) {
    setReceiptToView(item);
    setViewReceipt(true);
  }

  function handleViewReceiptClose() {
    setTask("Filter");
    setReceiptToView(null);
    setViewReceipt(false);
  }

  function handleChargeItemAdd() {
    setChargeItems((chargeItems) => [
      ...chargeItems,
      {
        accountId: null,
        notes: null,
        amount: null,
        applyVAT: false,
        displayVATRadio: true,
      },
    ]);
  }

  function handleVoidClick(item) {
    setChargeItems((items) => [
      ...items,
      {
        accountId: null,
        notes: null,
        amount: null,
        applyVAT: false,
        displayVATRadio: true,
      },
    ]);
    if (user.services.includes("HOA")) {
      //HOA
      let ownership = null;
      loadReceiptOwnership(item.id).then((data) => {
        ownership = data.selectedTitle;
        if (!isNullUndefined(ownership)) {
          getOwnershipChargeItem(item, ownership);
        }
      });
    } else if (user.services.includes("BLWTR")) {
      // Bulk water
      //TODO: Implement this if needed
    } else {
      //Residential
      let lease = null;
      loadReceiptLease(item.id).then((data) => {
        lease = data.selectedLease;
        if (!isNullUndefined(lease)) {
          getLeaseCreditItem(item, lease);
        }
      });
    }
  }

  function getOwnershipChargeItem(item, ownership) {
    chargeItemList("Receipt", item.id).then((data) => {
      setChargeItems(data);
      let refNum = getRefNumber();
      let charge = {
        id: null,
        referenceNumber: refNum,
        date: new Date(),
        memo: "Charge to void Receipt #" + item.referenceNumber,
        titleId: ownership.id,
        itemId: item.id,
        isVoid: true,
        items: data,
      };
      setDebitNote(charge);
      setShowDebitModal(true);
    });
  }

  function getLeaseCreditItem(item, lease) {
    chargeItemList("Receipt", item.id).then((data) => {
      setChargeItems(data);
      let refNum = getRefNumber();
      let charge = {
        id: null,
        referenceNumber: refNum,
        date: dayjs(new Date()),
        memo: "Charge to void Receipt #" + item.referenceNumber,
        leaseId: lease.id,
        itemId: item.id,
        isVoid: true,
        items: data,
      };
      setDebitNote(charge);
      setShowDebitModal(true);
    });
  }

  function handleChargeDateChange(newDate) {
    let date = dayjs(newDate);
    let obj = debitNote;
    obj.date = date;
    setDebitNote(obj);
  }

  const handleChargeClose = () => {
    setShowDebitModal(false);
    setDebitNote(null);
    setChargeItems([]);
  };

  function validateDebitForm(debitNote) {
    if (isNullUndefined(debitNote)) {
      issueWarnMessage(
        "Something went wrong with charge data, please refresh the page"
      );
      return false;
    }

    if (!isValidDate(new Date(debitNote.date))) {
      issueWarnMessage("Select a valid charge date");
      return false;
    }
    if (
      !isNullUndefined(debitNote.memo) &&
      !isEmptyString(debitNote.memo) &&
      debitNote.memo.length > 400
    ) {
      issueWarnMessage("The charge memo cannot be greater than 400 characters");
      return false;
    }
    if (isEmptyArray(debitNote.items)) {
      issueWarnMessage("Select a valid debit note date");
      return false;
    }
    return true;
  }

  function saveDebitNoteForm() {
    if (validateDebitForm(debitNote)) {
      saveDebitNote(debitNote).then((response) => {
        issueResponseMessage(response);
        let searchObject = {
          receiptNumber: receiptNumber,
          selectedPaymentMethod: selectedPaymentMethod,
          receiptFromDate: "",
          receiptToDate: "",
          receiptTo: receiptTo,
          pageNo: paginationModel.page,
          rowsPerPage: paginationModel.pageSize,
        };

        if (!isNullUndefined(receiptFromDate) && isValidDate(receiptFromDate)) {
          searchObject.receiptFromDate = receiptFromDate;
        }
        if (!isNullUndefined(receiptToDate) && isValidDate(receiptToDate)) {
          searchObject.receiptToDate = receiptToDate;
        }
        listReceipts(searchObject).then((data) => setReceiptsList(data));
        handleChargeClose();
      });
    }
  }

  function populateChargeItems() {
    let rows = "";
    chargeItems &&
      chargeItems.length > 0 &&
      (rows = chargeItems.map(function (chargeItem, i) {
        return (
          <TableRow key={i}>
            <TableCell>
              <Select
                sx={{ width: "90%" }}
                id={"chargeAccount" + i}
                name={"chargeAccount" + i}
                isrequired={true}
                disabled={
                  chargeItems &&
                  chargeItems[i] &&
                  !chargeItems[i].displayVATRadio
                }
                value={
                  chargeItems && chargeItems[i] && chargeItems[i].accountId
                }
              >
                {allAccounts && allAccounts.length > 0 ? (
                  allAccounts.map(function (account, i) {
                    return (
                      <MenuItem key={account.value} value={account.value}>
                        {account.label}
                      </MenuItem>
                    );
                  }, this)
                ) : (
                  <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
                )}
              </Select>
            </TableCell>
            <TableCell>
              <TextField
                sx={{ width: "90%" }}
                id={"chargeItemMemo" + i}
                name={"chargeItemMemo" + i}
                isrequired={true}
                disabled={
                  chargeItems &&
                  chargeItems[i] &&
                  !chargeItems[i].displayVATRadio
                }
                value={chargeItems && chargeItems[i] && chargeItems[i].notes}
              />
            </TableCell>
            <TableCell>
              <TextField
                sx={{ width: "90%" }}
                id={"chargeAmount" + i}
                name={"chargeAmount" + i}
                isrequired={true}
                disabled={
                  chargeItems &&
                  chargeItems[i] &&
                  !chargeItems[i].displayVATRadio
                }
                value={chargeItems && chargeItems[i] && chargeItems[i].amount}
              />
            </TableCell>
            <TableCell>
              {chargeItems &&
                chargeItems[i] &&
                chargeItems[i].displayVATRadio && (
                  <Checkbox
                    checked={
                      chargeItems && chargeItems[i] && chargeItems[i].applyVAT
                    }
                    inputProps={{ "aria-label": "controlled" }}
                  />
                )}
            </TableCell>
            <TableCell>
              {chargeItems &&
                chargeItems[i] &&
                chargeItems[i].displayVATRadio && (
                  <Button
                    variant="contained"
                    type="submit"
                    sx={{ marginLeft: "10px" }}
                    color="error"
                  >
                    <Cancel />
                  </Button>
                )}
            </TableCell>
          </TableRow>
        );
      }, this));
    setChargeItemsTableRows(rows);
  }

  const columns = [
    {
      headerName: "Receipt No",
      field: "referenceNumber",
      minWidth: 115,
      flex: 1.15,
    },
    {
      headerName: "Date",
      field: "date",
      minWidth: 110,
      flex: 1.1,
      valueFormatter: (row) => {
        if (!isNullUndefined(row.value) && !isEmptyString(row.value)) {
          return formatDate(new Date(row.value));
        } else {
          return "N/A";
        }
      },
    },
    {
      headerName: "Payment Method",
      field: "paymentMethodName",
      minWidth: 120,
      flex: 1.2,
    },
    {
      headerName: "Status",
      field: "statusCd",
      minWidth: 100,
      valueFormatter: (row) => {
        if (row.value === "COMP") {
          return "Completed";
        } else if (row.value === "PEN") {
          return "Pending";
        } else if (row.value === "VOID") {
          return "Voided";
        } else return "N/A";
      },
    },
    {
      headerName: "Notes",
      field: "issuedFor",
      minWidth: 320,
      flex: 3.2,
      renderCell: renderCellExpand,
    },
    {
      headerName: "Description",
      field: "description",
      minWidth: 265,
      flex: 2.65,
      renderCell: renderCellExpand,
    },
    { headerName: "Cheque", field: "chequeNumber", minWidth: 130, flex: 1.3 },
    { headerName: "Amount", field: "amount", minWidth: 130, flex: 1.3 },
    {
      headerName: "Actions",
      field: "actions",
      width: 100,
      type: "actions",
      getActions: (params) => {
        let arr = [];
        arr.push(
          <GridActionsCellItem
            icon={<Print />}
            onClick={() => {
              printReceipt(params.id);
            }}
            label="Print Receipt"
            showInMenu
          />
        );
        arr.push(
          <GridActionsCellItem
            icon={<Visibility />}
            onClick={() => handleViewReceiptClicked(params.row)}
            label="View Receipt"
            showInMenu
          />
        );
        if (
          user.proxyRole === "BLDIR" ||
          user.proxyRole === "BLDMG" ||
          user.proxyRole === "CS" ||
          user.proxyRole === "ACC"
        ) {
          arr.push(
            <GridActionsCellItem
              icon={<Delete />}
              onClick={() => handleVoidClick(params.row)}
              label="Void Receipt"
              showInMenu
            />
          );
        }

        return arr;
      },
    },
  ];

  return (
    <ResponsiveRow
      sx={{
        flexDirection: "column",
        flexWrap: "nowrap",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        width: "100%",
        height: "auto",
        padding: 0,
      }}
    >
      {filterReceipts && (
        <TopBarContainer
          sx={{
            flexDirection: { xs: "column", lg: "row" },
            flexWrap: "nowrap",
            justifyContent: { xs: "center", lg: "space-between" },
            alignItems: "center",
            width: { xs: "100%", lg: "90%" },
            minHeight: "80px",
            margin: "10px auto",
            padding: "10px 20px",
            boxShadow: "1px 1px 15px 0 rgba(0,0,0,0.3)",
          }}
        >
          <Typography
            variant="h5"
            sx={{
              width: { xs: "100%", lg: "25%" },
              textAlign: { xs: "center", lg: "start" },
            }}
          >
            Receipts
          </Typography>
          <ResponsiveRow
            sx={{
              width: { xs: "100%", lg: "75%" },
              justifyContent: "flex-end",
              padding: 0,
            }}
          >
            {task === "Filter" && (
              <>
                <TopBarContainedBrandButton
                  disabled={selectionModel.length < 1}
                  onClick={handleDownloadReceipts}
                  sx={{
                    width: { xs: "100%", lg: "200px" },
                  }}
                >
                  {selectionModel.length > 1
                    ? "Download " + selectionModel.length + " Receipts"
                    : "Download Receipt"}
                </TopBarContainedBrandButton>
                <TopBarContainedBrandButton
                  disabled={selectionModel.length < 1}
                  onClick={handleExportReceipts}
                  sx={{
                    width: { xs: "100%", lg: "200px" },
                  }}
                >
                  Export Receipts
                </TopBarContainedBrandButton>
                <TopBarContainedCancelButton
                  onClick={handleClearClicked}
                  sx={{ width: { xs: "100%", lg: "200px" } }}
                >
                  Clear Search
                </TopBarContainedCancelButton>
              </>
            )}
          </ResponsiveRow>
        </TopBarContainer>
      )}

      {task !== "Filter" && (
        <ResponsiveRow
          sx={{
            width: { xs: "100%", lg: "80%" },
            height: "auto",
            margin: "auto",
            backgroundColor: "white",
            borderRadius: "10px",
            padding: "30px 20px",
            boxShadow: "1px 1px 15px 0 rgba(0,0,0,0.5)",
          }}
          container
        >
          <Typography
            variant="h6"
            color="#037171"
            sx={{
              width: "100%",
              marginBottom: "20px",
              textAlign: "start",
              padding: "0 10px",
            }}
          >
            Invoices Search Form
          </Typography>
          <FormControl
            item
            sx={{
              width: { xs: "100%", lg: "33.3%" },
              marginBottom: "10px",
            }}
          >
            <InputLabel>Reference Number</InputLabel>
            <OutlinedInput
              label="Reference Number"
              placeholder={"Receipt Number"}
              sx={{ width: { xs: "100%", lg: "90%" } }}
              id={"receiptNumber"}
              onChange={(event) => {
                const value = event.target.value;
                setReceiptNumber(value);
              }}
              value={!isEmptyString(receiptNumber) ? receiptNumber : ""}
            />
          </FormControl>

          <FormControl
            item
            sx={{
              width: { xs: "100%", lg: "33.3%" },
              marginBottom: "10px",
            }}
          >
            <InputLabel>Receipt to Billing Code</InputLabel>
            <OutlinedInput
              label="Receipt to Billing Code"
              placeholder={"Receipt To Billing Code"}
              sx={{ width: { xs: "100%", lg: "90%" } }}
              id={"receiptTo"}
              onChange={(event) => {
                const value = event.target.value;
                setReceiptTo(value);
              }}
              value={!isEmptyString(receiptTo) ? receiptTo : ""}
            />
          </FormControl>

          <FormControl
            item
            sx={{
              width: { xs: "100%", lg: "33.3%" },
              marginBottom: "10px",
            }}
          >
            <InputLabel>Payment Method</InputLabel>
            <Select
              label="Payment Method"
              sx={{ width: { xs: "100%", lg: "90%" } }}
              id={"paymentMethod"}
              value={selectedPaymentMethod}
              onChange={(event) => {
                let value = event.target.value;
                setSelectedPaymentMethod(value);
              }}
            >
              {paymentMethods && paymentMethods.length > 0 ? (
                paymentMethods.map(function (paymentMethods, i) {
                  return (
                    <MenuItem
                      key={paymentMethods.value}
                      value={paymentMethods.value}
                    >
                      {paymentMethods.label}
                    </MenuItem>
                  );
                }, this)
              ) : (
                <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
              )}
            </Select>
          </FormControl>

          <FormControl
            item
            sx={{
              width: { xs: "100%", lg: "33.3%" },
              marginBottom: "10px",
            }}
          >
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale="en-gb"
            >
              <DatePicker
                label="Receipt Date From"
                sx={{ width: { xs: "100%", lg: "90%" } }}
                id={"receiptFromDate"}
                value={
                  isEmptyString(receiptFromDate) ? null : dayjs(receiptFromDate)
                }
                isrequired={false}
                onChange={(newDate) => {
                  let date = new Date(newDate);
                  setReceiptFromDate(date);
                }}
              />
            </LocalizationProvider>
          </FormControl>

          <FormControl
            item
            sx={{
              width: { xs: "100%", lg: "33.3%" },
              marginBottom: "10px",
            }}
          >
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale="en-gb"
            >
              <DatePicker
                label="Receipt Date To"
                sx={{ width: { xs: "100%", lg: "90%" } }}
                id={"receiptToDate"}
                value={
                  isEmptyString(receiptToDate) ? null : dayjs(receiptToDate)
                }
                isrequired={false}
                onChange={(newDate) => {
                  let date = new Date(newDate);
                  setReceiptToDate(date);
                }}
              />
            </LocalizationProvider>
          </FormControl>

          <ResponsiveRow
            sx={{
              width: "100%",
              justifyContent: { xs: "center", lg: "flex-end" },
            }}
          >
            <ModalAcceptButton
              onClick={handleShowAllClicked}
              loading={loading}
              sx={{
                width: { xs: "100%", lg: "200px" },
              }}
            >
              Show All Receipts
            </ModalAcceptButton>{" "}
            <ModalAcceptButton
              onClick={handleSearchClicked}
              loading={loading}
              sx={{
                width: { xs: "100%", lg: "200px" },
              }}
            >
              Search
            </ModalAcceptButton>
            <ModalCancelButton
              onClick={handleClearClicked}
              sx={{
                width: { xs: "100%", lg: "200px" },
              }}
            >
              Clear
            </ModalCancelButton>
          </ResponsiveRow>
        </ResponsiveRow>
      )}

      {task === "Filter" && (
        <MuiDataGrid
          dataGridColumns={columns}
          dataGridRows={receiptsList}
          serverPagination={true}
          handleSelectedRows={setSelectionModel}
          currentSelectionModel={selectionModel}
          handlePageLoad={setPaginationModel}
          loading={loading}
          height="60vh"
          totalRows={totalReceipts}
        />
      )}

      {/*Download/Export Document via Email... Shared Modal*/}
      {downloadReceiptsModal && (
        <ExportDocumentsModal
          modalTitle="Download Receipts"
          showExportDocumentsModal={downloadReceiptsModal}
          handleExportDocumentsClose={handleCloseDownloadReceiptsModal}
          title={titleText}
          severity={severityText}
          body={bodyText}
          handleExportDocuments={positiveExportDocsAction}
          handleCancel={handleCloseDownloadReceiptsModal}
        />
      )}

      <ViewReceiptModal
        handleViewReceiptClose={handleViewReceiptClose}
        receiptToView={receiptToView}
        viewReceipt={viewReceipt}
      />

      <CreateReceiptChargeModal
        saveDebitNoteForm={saveDebitNoteForm}
        handleChargeClose={handleChargeClose}
        handleChargeDateChange={handleChargeDateChange}
        handleChargeItemAdd={handleChargeItemAdd}
        setDebitNote={setDebitNote}
        chargeItemsTableRows={chargeItemsTableRows}
        debitNote={debitNote}
        showDebitModal={showDebitModal}
      />
    </ResponsiveRow>
  );
}
