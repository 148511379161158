import {Box, Typography} from "@mui/material"
import {ModalAcceptButton, ModalCancelButton,} from "../../constants/component.constants"
import {ResponsiveRow} from "../../constants/layout.constants"
import Permit from "../../util/permit";

export default function UserItemCard(props) {
    /**
     *
     * REQUIRED PROPS
     *
     * handleViewSelectedUser
     * handleEditSelectedUser
     * handleDeleteUser
     *
     * item
     *
     */
    return (
        <Box
            item
            sx={{
                width: {xs: "100%", lg: "30%"},
                margin: "10px",
                height: "auto",
                backgroundColor: "#fff",
                padding: "20px",
                borderRadius: "10px",
            }}
        >
            <ResponsiveRow
                sx={{
                    marginTop: 0,
                    width: "100%",
                    height: "100px",
                    justifyContent: {xs: "center", lg: "space-between"},
                    alignItem: "center",
                }}
            >
                <Typography
                    id="userCard"
                    variant="h6"
                    component="h6"
                    sx={{
                        textAlign: "start",
                        width: {xs: "100%", lg: "70%"},
                    }}
                >
                    {props.item.name}
                </Typography>
                <Typography
                    sx={{
                        width: {xs: "100%", lg: "30%"},
                        fontSize: "14px",
                        textAlign: "end",
                        color: "#037171",
                    }}
                >
                    {props.item.roleName}
                </Typography>
            </ResponsiveRow>
            <ResponsiveRow sx={{padding: "2px 10px", height: "auto"}}>
                <Typography
                    sx={{
                        fontSize: ".9rem",
                        textAlign: "end",
                        fontWeight: 700,
                        color: "#037171",
                    }}
                >
                    Permissions
                </Typography>
            </ResponsiveRow>
            <ResponsiveRow sx={{height: "75px", overflowY: "hidden"}}>
                {props.item.permissions.length > 0 ? (
                    props.item.permissions.map((permission) => {
                        return (
                            <Box
                                sx={{
                                    width: "auto",
                                    backgroundColor: "#efefef",
                                    borderRadius: "20px",
                                    margin: "3px 5px",
                                    padding: "5px 7px",
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: ".75rem",

                                        minWidth: "30px",
                                        color: "#4f4f4f",
                                    }}
                                >
                                    {permission}
                                </Typography>
                            </Box>
                        )
                    })
                ) : (
                    <ResponsiveRow sx={{width: "100%", height: "auto"}}>
                        <Typography
                            sx={{
                                fontSize: ".9rem",
                                fontWeight: 700,
                                color: "#9f9f9f",
                            }}
                        >
                            None
                        </Typography>
                    </ResponsiveRow>
                )}
            </ResponsiveRow>
            <br/>
            <ResponsiveRow
                sx={{
                    justifyContent: {xs: "center", lg: "flex-end"},
                    height: "auto",
                }}
            >
                <ModalAcceptButton
                    // variant="contained"
                    // type="submit"
                    onClick={() => {
                        props.handleViewSelectedUser(props.item)
                    }}
                    sx={{
                        width: {xs: "100%", lg: "100px"},
                        height: "40px",
                    }}
                >
                    View
                </ModalAcceptButton>
                <Permit roles="BLDIR">
                    <ModalAcceptButton
                        // variant="contained"
                        // type="submit"
                        onClick={() => {
                            props.handleEditSelectedUser(props.item)
                        }}
                        sx={{width: {xs: "100%", lg: "100px"}, height: "40px"}}
                    >
                        Edit
                    </ModalAcceptButton>
                    <ModalCancelButton
                        // type="submit"

                        onClick={() => {
                            props.handleDeleteUser(props.item)
                        }}
                        sx={{width: {xs: "100%", lg: "100px"}, height: "40px"}}
                    >
                        Delete
                    </ModalCancelButton>
                </Permit>
            </ResponsiveRow>
        </Box>
    )
}
