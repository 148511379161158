import {useEffect, useState} from "react";
import {GridActionsCellItem} from "@mui/x-data-grid";
import {FormControl, InputLabel, OutlinedInput} from "@mui/material";
import {exportAccountHolders, exportTenants, loadTenants, saveTenant, totalTenants,} from "../services/tenants.service";
import {Edit} from "@mui/icons-material";
import {useSelector} from "react-redux";
import AddNewOrganisation from "../components/modals/addNewOrganisation";
import AddNewUser from "../components/modals/addNewUser";
import {issueResponseMessage} from "../actions/message";
import {isNullUndefined} from "../util/util";
import {MainContainer, TopBarContainedBrandButton, TopBarContainer,} from "../constants/component.constants";
import {ResponsiveRow} from "../constants/layout.constants";
import Permit from "../util/permit";
import MuiDataGrid from "../components/modules/MuiDataGrid";

export default function Tenants() {
    const user = useSelector((state) => state.auth.user);
    const [tenantName, setTenantName] = useState("");
    const [selectedTenant, setSelectedTenant] = useState("");
    const [tenantList, setTenantList] = useState([])
    const [total, setTotalTenants] = useState(0);
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 50,
        page: 0,
    })
    const [residentialTenant, setResidentialTenant] = useState(true);
    const [hoaTenant, setHoaTenant] = useState(false);
    const [task, setTask] = useState("");
    const [addOrganizationModal, setAddOrganizationModal] = useState(false);
    const [addUserModal, setAddUserModal] = useState(false);
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setLoading(true)
        totalTenants(tenantName)
            .then((data) => {
                setTotalTenants(data.total)
            })
        loadTenants(tenantName, paginationModel.page, paginationModel.pageSize)
            .then((data) => {
                setTenantList(data)
            }).finally(() => {
            setLoading(false)
        })
    }, [
        tenantName,
        paginationModel.page,
        paginationModel.pageSize,
        user.proxyFacility
    ])

    useEffect(() => {
        if (
            !isNullUndefined(user) &&
            !isNullUndefined(user.services) &&
            user.services.includes("METER") &&
            !user.services.includes("PREMIUM") &&
            !user.services.includes("CORE") &&
            !user.services.includes("LEAN")
        ) {
            setResidentialTenant(false);
            setHoaTenant(false);
        } else if (
            !isNullUndefined(user) &&
            !isNullUndefined(user.services) &&
            user.services.includes("HOA")
        ) {
            setResidentialTenant(false);
            setHoaTenant(true);
        } else {
            setResidentialTenant(true);
            setHoaTenant(false);
        }
    }, [user])

    function editTenantClicked(item) {
        setTask("Edit");
        setSelectedTenant(item);
        if (item.type === "Company") {
            setAddOrganizationModal(true);
        }
        if (item.type === "Individual") {
            setAddUserModal(true);
        }
    }

    function handleAddOrgClose() {
        setAddOrganizationModal(false);
        setTask("");
    }

    function handleAddUserClose() {
        setAddUserModal(false);
        setTask("");
    }

    function validateForm() {
    }

    function handleUpdateTenant() {
        if (validateForm) {
            saveTenant(selectedTenant)
                .then((response) => {
                    issueResponseMessage(response)
                    handleAddUserClose();
                    loadTenants(tenantName, paginationModel.page, paginationModel.pageSize)
                        .then((data) => {
                            setTenantList(data)
                        })
                    totalTenants(tenantName)
                        .then((data) => setTotalTenants(data.total))
                })
        }
    }

    const columns1 = [
        {headerName: "Tenant Name", field: "name", width: 200},
        {headerName: "Property", field: "property", minWidth: 200, flex: 1},
        {headerName: "Units Leased", field: "units", minWidth: 250, flex: 1.25},
        {
            headerName: "Lease Start",
            field: "leaseStart",
            minWidth: 150,
            flex: 0.75,
        },
        {headerName: "Lease End", field: "leaseEnd", minWidth: 150, flex: 0.75},
        {headerName: "Phone", field: "mobilePhone", width: 150, flex: 0.75},
        {headerName: "Email", field: "email", width: 175, flex: 0.875},
        {
            headerName: "Actions",
            field: "actions",
            width: 100,
            type: "actions",
            getActions: (params) => {
                let arr = [];
                arr.push(
                    <GridActionsCellItem
                        icon={<Edit/>}
                        onClick={() => editTenantClicked(params.row)}
                        label="Edit"
                        showInMenu
                        disabled={user.proxyRole === "REC"}
                    />
                );

                return arr;
            },
        },
    ];

    const columns2 = [
        {headerName: "Holder Name", field: "name", minWidth: 250, flex: 1.25},
        {headerName: "Property", field: "property", minWidth: 200, flex: 1},
        {headerName: "Meters Billed", field: "meters", minWidth: 250, flex: 1.25},
        {
            headerName: "Billing Start",
            field: "billingStart",
            minWidth: 150,
            flex: 0.25,
        },
        {
            headerName: "Billing End",
            field: "billingEnd",
            minWidth: 150,
            flex: 0.25,
        },
        {headerName: "Phone", field: "mobilePhone", minWidth: 150, flex: 0.25},
        {headerName: "Email", field: "email", minWidth: 175, flex: 0.875},
        {
            headerName: "Actions",
            field: "actions",
            width: 100,
            type: "actions",
            getActions: (params) => {
                let arr = [];
                arr.push(
                    <GridActionsCellItem
                        icon={<Edit/>}
                        onClick={() => editTenantClicked(params.row)}
                        label="Edit"
                        showInMenu
                        disabled={user.proxyRole === "REC"}
                    />
                );

                return arr;
            },
        },
    ];

    const columns3 = [
        {headerName: "Tenant Name", field: "name", minWidth: 250, flex: 1.75},
        {headerName: "Property", field: "property", minWidth: 200, flex: 1},
        {headerName: "Unit", field: "units", minWidth: 250, flex: 1.25},
        {
            headerName: "Ownership Start",
            field: "ownershipStart",
            minWidth: 150,
            flex: 0.75,
        },
        {
            headerName: "Ownership End",
            field: "ownershipEnd",
            minWidth: 150,
            flex: 0.75,
        },
        {headerName: "Phone", field: "mobilePhone", minWidth: 150, flex: 0.75},
        {headerName: "Email", field: "email", minWidth: 175, flex: 0.875},
        {
            headerName: "Actions",
            field: "actions",
            width: 100,
            type: "actions",
            getActions: (params) => {
                let arr = [];
                arr.push(
                    <GridActionsCellItem
                        icon={<Edit/>}
                        onClick={() => editTenantClicked(params.row)}
                        label="Edit"
                        showInMenu
                        disabled={user.proxyRole === "REC"}
                    />
                );

                return arr;
            },
        },
    ];

    function handleExportTenantsClicked(tenantType) {
        exportTenants(tenantType);
    }

    function handleExportAccountHoldersClicked() {
        exportAccountHolders();
    }

    return (
        <Permit
            roles="BLDIR,BLDMG,CS,REC"
            noPermittedServiceMessage={{
                title: "Access Denied",
                body: (
                    <>
                        Your current role is not permitted to view this page. <br/> Please
                        contact your system admin if you feel this is an error.
                    </>
                ),
            }}
        >
            <MainContainer>
                <TopBarContainer
                    container
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: {lg: "nowrap", md: "wrap"},
                        justifyContent: "space-between",
                        width: {xs: "100%", md: "80%"},
                        backgroundColor: "white",
                        padding: {md: "1vh", xs: "1vh"},
                        borderRadius: "1vh",
                        margin: "20px auto",
                        boxShadow: "0 4px 5px 0 rgba(0,0,0,0.5)",
                    }}
                >
                    <ResponsiveRow
                        sx={{
                            justifyContent: {md: "space-between", xs: "center"},
                            width: {xs: "100%", lg: "50%"},
                            padding: 0,
                        }}
                        component="form"
                    >
                        <FormControl
                            sx={{
                                margin: {md: "auto 20px", xs: "10px auto "},
                                width: "100%",
                            }}
                        >
                            <InputLabel id="vendorsLabel">
                                {residentialTenant || hoaTenant
                                    ? "Search tenant's name"
                                    : "Search name"}
                            </InputLabel>
                            <OutlinedInput
                                id="tenantNameSearch"
                                value={tenantName}
                                label={`Search tenant's name`}
                                sx={{width: {xs: "100%", lg: "90%"}}}
                                onChange={(event) => {
                                    let value = event.target.value;
                                    setTenantName(value);
                                }}
                            />
                        </FormControl>
                    </ResponsiveRow>

                    <ResponsiveRow
                        sx={{
                            justifyContent: {md: "space-between", xs: "center"},
                            width: {md: "20%", xs: "100%"},
                            padding: 0,
                        }}
                        component="form"
                    >
                        <TopBarContainedBrandButton
                            sx={{
                                width: "100%",
                                height: "50px",
                            }}
                            onClick={
                                residentialTenant
                                    ? () => handleExportTenantsClicked("residential")
                                    : hoaTenant
                                        ? () => handleExportTenantsClicked("hoa")
                                        : () => handleExportAccountHoldersClicked()
                            }
                        >
                            Export{" "}
                            {residentialTenant || hoaTenant ? "Tenants" : "Account Holders"}
                        </TopBarContainedBrandButton>
                    </ResponsiveRow>
                </TopBarContainer>

                <MuiDataGrid
                    noSelection={true}
                    dataGridColumns={
                        residentialTenant ? columns1 : hoaTenant ? columns3 : columns2
                    }
                    dataGridRows={tenantList}
                    serverPagination={true}
                    handlePageLoad={setPaginationModel}
                    loading={loading}
                    height="60vh"
                    totalRows={total}
                />

                {task === "Edit" && selectedTenant.type === "Company" && (
                    <AddNewOrganisation
                        openModal={addOrganizationModal}
                        closeModal={handleAddOrgClose}
                        task={task}
                        selectedOrg={selectedTenant}
                    />
                )}

                {task === "Edit" && selectedTenant.type === "Individual" && (
                    <AddNewUser
                        openModal={addUserModal}
                        closeModal={handleAddUserClose}
                        saveUser={handleUpdateTenant}
                        manageUsers={false}
                        task={task}
                        selectedPerson={selectedTenant}
                    />
                )}
            </MainContainer>
        </Permit>
    );
}
