import {Box, Button, Grid, Typography} from "@mui/material"

export default function PropertyBar() {
    return (
        <Grid
            container
            sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: {lg: "nowrap", md: "wrap"},
                justifyContent: {md: "space-between", xs: "space-between"},
                width: "100%",
                maxHeight: {xs: "100%", md: "fit-content", lg: "75px"},
                backgroundColor: "#fff",
                padding: {md: "1vh", xs: "1vh"},
                borderRadius: "1vh",
                margin: "20px auto",
                boxShadow: "0 4px 5px 0 rgba(0,0,0,0.5)",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: {xl: "row", xs: "column"},
                    justifyContent: {md: "space-between", xs: "center"},
                    width: {lg: "33.3%", md: "50%", sm: "100%"},
                }}
            >
                <Typography
                    sx={{
                        fontSize: "16px",
                        fontWeight: 800,
                        margin: "auto",
                        width: "100%",
                        textAlign: "start",
                        padding: "20px",
                    }}
                >
                    Property Name
                </Typography>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: {xl: "row", xs: "column"},
                    justifyContent: {md: "space-between", xs: "center"},
                    alignItems: "center",
                    width: {md: "75%", xs: "100%"},
                    padding: "10px",
                }}
            >
                <Button
                    variant="contained"
                    sx={{
                        backgroundColor: "#fff",
                        color: "#037171",
                        "&:hover": {
                            backgroundColor: "#037171",
                            color: "#fff",
                        },
                        fontSize: "12px",
                        padding: "5px 7px",
                        margin: {xs: "10px 0", md: "5px 10px", lg: "0 5px"},
                        textTransform: "full-width",
                        width: "100%",
                    }}
                >
                    Edit
                </Button>
                <Button
                    variant="contained"
                    sx={{
                        backgroundColor: "#037171",
                        color: "#fff",
                        "&:hover": {
                            backgroundColor: "#fff",
                            color: "#037171",
                        },
                        fontSize: "12px",
                        padding: "5px 7px",
                        margin: {xs: "10px 0", md: "5px 10px", lg: "0 5px"},
                        textTransform: "full-width",
                        width: "100%",
                    }}
                >
                    Export Property
                </Button>
                <Button
                    variant="contained"
                    sx={{
                        backgroundColor: "#037171",
                        color: "#fff",
                        "&:hover": {
                            backgroundColor: "#fff",
                            color: "#037171",
                        },
                        fontSize: "12px",
                        padding: "5px 7px",
                        margin: {xs: "10px 0", md: "5px 10px", lg: "0 5px"},
                        textTransform: "full-width",
                        width: "100%",
                    }}
                >
                    Bulk Upload
                </Button>
                <Button
                    variant="contained"
                    sx={{
                        backgroundColor: "#037171",
                        color: "#fff",
                        "&:hover": {
                            backgroundColor: "#fff",
                            color: "#037171",
                        },
                        fontSize: "12px",
                        padding: "5px 7px",
                        margin: {xs: "10px 0", md: "5px 10px", lg: "0 5px"},
                        textTransform: "full-width",
                        width: "100%",
                    }}
                >
                    Add New Property
                </Button>
            </Box>
        </Grid>
    )
}
