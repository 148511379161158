import {
    Alert,
    AlertTitle,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    TextField,
    Typography,
} from "@mui/material"
import {Box} from "@mui/system"
import {ResponsiveRow} from "../../../../constants/layout.constants"
import {isEmptyArray} from "../../../../util/util"

import {Delete} from "@mui/icons-material"

import ModalContainerModal from "../../../modules/modalContainer"

export default function AddComplexModal(props) {
    /**
     *
     * REQUIRED PROPS:
     *
     * handleAddModalClose
     * handleAssociatedPropertyChange
     * handleCountryChange
     *
     *
     * AddDiv
     * EditDiv
     *
     * setAddDiv
     * setEditDiv
     *
     * saveComplex
     * selectedComplex
     * removeComplexProperty
     *
     * address1
     * address2
     * city
     * countryCd
     * complexProperties
     * complexProperty
     * countryOptions
     * description
     * latitude
     * longitude
     * lrNumber
     * name
     *
     * setAddress1
     * setAddress2
     * setCity
     * setDescription
     * setLatitude
     * setLongitude
     * setLrNumber
     * setName
     * setselectedComplex
     * setcomplexProperties
     *
     *
     *
     */

    function handleCloseModal() {
        props.setselectedComplex(null)
        props.setcomplexProperties([])
        props.setAddDiv(false)
        props.setEditDiv(false)
    }

    return (
      <ModalContainerModal
        size="md"
        accept={props.EditDiv ? props.editComplex : props.saveComplex}
        cancel={handleCloseModal}
        openModal={props.AddDiv}
        modalTitle={props.EditDiv ? "Edit Complex" : "Add Complex"}
        acceptButtonText={props.EditDiv ? "Update" : "Create"}
      >
        <ResponsiveRow container>
          <FormControl
            item
            sx={{
              width: { xs: "100%", lg: "33.3%" },
              marginBottom: "10px",
            }}
          >
            <InputLabel>Complex Name *</InputLabel>
            <OutlinedInput
              label="Complex Name *"
              id="complexName"
              sx={{
                width: { xs: "100%", md: "90%" },
              }}
              onChange={(event) => {
                const value = event.target.value;
                props.setName(value);
                let obj = props.selectedComplex;
                obj.name = value;
              }}
              value={
                props.selectedComplex
                  ? props.selectedComplex && props.selectedComplex.name
                  : props.name
              }
              inputProps={
                <TextField
                  sx={{
                    width: { xs: "100%", md: "90%" },
                  }}
                />
              }
            />
          </FormControl>
          <FormControl
            item
            sx={{
              width: { xs: "100%", lg: "33.3%" },
              marginBottom: "10px",
            }}
          >
            <InputLabel>Address 1 *</InputLabel>
            <OutlinedInput
              label="Address 1 *"
              id="address1"
              sx={{
                width: { xs: "100%", md: "90%" },
              }}
              onChange={(event) => {
                const value = event.target.value;
                props.setAddress1(value);
                let obj = props.selectedComplex;
                obj.address.address1 = value;
              }}
              value={
                props.selectedComplex
                  ? props.selectedComplex &&
                    props.selectedComplex.address.address1
                  : props.address1
              }

            />
          </FormControl>
          <FormControl
            item
            sx={{
              width: { xs: "100%", lg: "33.3%" },
              marginBottom: "10px",
            }}
          >
            <InputLabel>City</InputLabel>
            <OutlinedInput
              label="City"
              id="city"
              sx={{
                width: { xs: "100%", md: "90%" },
              }}
              onChange={(event) => {
                const value = event.target.value;
                props.setCity(value);
                let obj = props.selectedComplex;
                obj.address.city = value;
              }}
              value={
                props.selectedComplex
                  ? props.selectedComplex && props.selectedComplex.address.city
                  : props.city
              }
              inputProps={
                <TextField
                  sx={{
                    width: { xs: "100%", md: "90%" },
                  }}
                />
              }
            />
          </FormControl>
          <FormControl
            item
            sx={{
              width: { xs: "100%", lg: "33.3%" },
              marginBottom: "10px",
            }}
          >
            <InputLabel>Country *</InputLabel>
            <Select
              label="Country *"
              sx={{ width: { xs: "100%", md: "90%" } }}
              id={"country"}
              value={
                props.selectedComplex
                  ? props.selectedComplex &&
                    props.selectedComplex.address.countryCd
                  : props.countryCd
              }
              onChange={(event) => props.handleCountryChange(event)}
            >
              {props.countryOptions && props.countryOptions.length > 0 ? (
                props.countryOptions.map(function (country, i) {
                  return (
                    <MenuItem key={i} value={country.value}>
                      {country.label}
                    </MenuItem>
                  );
                }, this)
              ) : (
                <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
              )}
            </Select>
          </FormControl>
          <FormControl
            item
            sx={{
              width: { xs: "100%", lg: "33.3%" },
              marginBottom: "10px",
            }}
          >
            <InputLabel>Address 2</InputLabel>
            <OutlinedInput
              label="Address 2"
              id="address2"
              sx={{
                width: { xs: "100%", md: "90%" },
              }}
              onChange={(event) => {
                const value = event.target.value;
                props.setAddress2(value);
                let obj = props.selectedComplex;
                obj.address.address2 = value;
              }}
              value={
                props.selectedComplex
                  ? props.selectedComplex &&
                    props.selectedComplex.address.address2
                  : props.address2
              }
              inputProps={
                <TextField sx={{ width: { xs: "100%", md: "90%" } }} />
              }
            />
          </FormControl>
          <FormControl
            item
            sx={{
              width: { xs: "100%", lg: "33.3%" },
              marginBottom: "10px",
            }}
          >
            <InputLabel>LR Number</InputLabel>
            <OutlinedInput
              label="LR Number"
              id="lrNumber"
              sx={{
                width: { xs: "100%", md: "90%" },
              }}
              onChange={(event) => {
                const value = event.target.value;
                props.setLrNumber(value);
                let obj = props.selectedComplex;
                obj.lrNumber = value;
              }}
              value={
                props.selectedComplex
                  ? props.selectedComplex && props.selectedComplex.lrNumber
                  : props.lrNumber
              }
              inputProps={
                <TextField sx={{ width: { xs: "100%", lg: "90%" } }} />
              }
            />
          </FormControl>
          <FormControl
            item
            sx={{
              width: { xs: "100%", lg: "66.6%" },
              marginBottom: "10px",
            }}
          >
            <InputLabel>Description</InputLabel>
            <OutlinedInput
              label="Description"
              id="description"
              multiline
              rows={4}
              sx={{
                width: { xs: "100%", lg: "90%" },
              }}
              onChange={(event) => {
                const value = event.target.value;
                props.setDescription(value);
                let obj = props.selectedComplex;
                obj.description = value;
              }}
              value={
                props.selectedComplex
                  ? props.selectedComplex && props.selectedComplex.description
                  : props.description
              }
              inputProps={
                <TextField sx={{ width: { xs: "100%", lg: "90%" } }} />
              }
            />
          </FormControl>
          <ResponsiveRow
            item
            sx={{
              width: { xs: "100%", lg: "33.3%" },
              height: "auto",
              alignItems: "center",
              padding: "10px 0",
            }}
          >
            <FormControl
              item
              sx={{
                width: "100%",
                marginBottom: "10px",
              }}
            >
              <InputLabel>Latitude</InputLabel>
              <OutlinedInput
                label="Latitude"
                id="latitude"
                sx={{
                  width: { xs: "100%", md: "90%" },
                }}
                onChange={(event) => {
                  const value = event.target.value;
                  props.setLatitude(value);
                  let obj = props.selectedComplex;
                  obj.latitude = value;
                }}
                value={
                  props.selectedComplex
                    ? props.selectedComplex && props.selectedComplex.latitude
                    : props.latitude
                }

              />
            </FormControl>
            <FormControl
              item
              sx={{
                width: "100%",
                marginBottom: "10px",
              }}
            >
              <InputLabel>Longitude</InputLabel>
              <TextField
                label="Longitude"
                id="longitude"
                sx={{
                  width: { xs: "100%", md: "90%" },
                }}
                onChange={(event) => {
                  const value = event.target.value;
                  props.setLongitude(value);
                  let obj = props.selectedComplex;
                  obj.longitude = value;
                }}
                value={
                  props.selectedComplex
                    ? props.selectedComplex && props.selectedComplex.longitude
                    : props.longitude
                }

              />
            </FormControl>
          </ResponsiveRow>
          <br />
          <Grid
            item
            sx={{
              width: "100%",
              margin: "10px 0",
            }}
          >
            <Alert severity="info">
              <AlertTitle>User Roles</AlertTitle>
              Please Note: Building directors at properties associated with the
              complex will also become building directors at allother properties
              associated with the same complex.
            </Alert>
          </Grid>
          <br />

          <FormControl
            item
            sx={{
              width: "100%",
              marginBottom: "10px",
            }}
          >
            <InputLabel>Select associated property(ies)</InputLabel>
            <Select
              label="Select associated property(ies)"
              sx={{ width: "100%" }}
              // multiple
              id={"properties"}
              value={
                props.selectedComplex
                  ? props.selectedComplex &&
                    props.selectedComplex.complexProperties
                  : props.complexProperties
              }
              onChange={(event) => props.handleAssociatedPropertyChange(event)}
            >
              {props.associatedPropertyOptions &&
              props.associatedPropertyOptions.length > 0 ? (
                props.associatedPropertyOptions.map(function (
                  associatedProperty,
                  i
                ) {
                  return (
                    <MenuItem
                      key={i}
                      value={associatedProperty.value}
                      name={associatedProperty.label}
                    >
                      {associatedProperty.label}
                    </MenuItem>
                  );
                },
                this)
              ) : (
                <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
              )}
            </Select>
          </FormControl>
          <Grid
            item
            sx={{
              width: "100%",
              height: "100px",

              marginBottom: "10px",
            }}
          >
            <Box
              sx={{
                backgroundColor: "#EFEFEF",
                borderRadius: "10px",
                height: "100%",
                width: "100%",
                padding: "10px",
                margin: "5px 0 0 0",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", lg: "row" },
                  justifyContent: "center",
                  alignItems: "center",
                  flexWrap: "wrap",
                  width: "auto",
                  height: "100%",
                  maxHeight: "200px",
                  padding: "5px",
                  backgroundColor: "#efefef",
                  overflowY: "auto",
                }}
              >
                {isEmptyArray(props.selectedComplex.complexProperties) && (
                  <Typography
                    sx={{
                      color: "#C6C5C5",
                      fontSize: "11px",
                      fontWeight: 700,
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    No Associated Properties
                  </Typography>
                )}
                <ResponsiveRow
                  sx={{
                    flexDirection: "column",
                    flexWrap: "nowrap",
                    height: "50px",
                    paddingTop: "5px",
                    justifyContent: "flex-start",
                  }}
                >
                  {props.selectedComplex.complexProperties &&
                    props.selectedComplex.complexProperties.length > 0 &&
                    props.selectedComplex.complexProperties.map(function (
                      complexProperty,
                      i
                    ) {
                      return (
                        <ResponsiveRow
                          sx={{
                            height: "auto",
                            flexWrap: "nowrap",
                            padding: "10px 0",
                            alignItems: "center",
                          }}
                        >
                          <Delete sx={{ margin: "1px", color: "red" }} />
                          <Typography
                            onClick={(event) =>
                              props.removeComplexProperty(
                                event,
                                complexProperty
                              )
                            }
                            gutterBottom
                            variant="body1"
                            component="div"
                            sx={{
                              padding: 0,
                              height: "auto",
                              margin: "auto 5px",
                            }}
                          >
                            {complexProperty.label}
                          </Typography>
                        </ResponsiveRow>
                      );
                    },
                    this)}
                </ResponsiveRow>
              </Box>
            </Box>
          </Grid>
          <br />
        </ResponsiveRow>
      </ModalContainerModal>
    );
}
