import {FormControl, TextField, Typography} from "@mui/material";
import {ResponsiveRow} from "../../../constants/layout.constants";
import {useEffect, useState} from "react";
import {getUtilitiesReadVsUnread} from "../../../services/dashboard.service";
import StackedBarChartCard from "../../charts/StackedBarChartCard";
import dayjs from "dayjs";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {useSelector} from "react-redux";

/**
 *
 * REQUIRED PROPS
 *
 * customWidth: string
 * customHeight: string
 * data: {
 *    readData: []
 *    unreadData: []
 *  }
 *
 */

export default function UtilitiesReadVsUnread({customWidth, customHeight}) {
    const [utilitiesData, setUtilitiesData] = useState({
        read: null,
        unread: null,
    });
    const [loading, setLoading] = useState(false);
    const [from, setFrom] = useState(dayjs(new Date()).subtract(1, "month"));
    const [to, setTo] = useState(dayjs(new Date()));
    const {user} = useSelector((state) => state.auth);

    useEffect(() => {
        setLoading(true);
        let sendData = {
            from: from.format('YYYY-MM-DD'),
            to: to.format('YYYY-MM-DD'),
        };
        getUtilitiesReadVsUnread(sendData)
            .then((response) => {
                if (response && response.data) {
                    setUtilitiesData(response.data);
                }
            })
            .finally(() => {
                setLoading(false);
            });

        return () => {
            setUtilitiesData({
                read: null,
                unread: null,
            });
        };
    }, [from, to, user.proxyFacility]);


    return (
        <ResponsiveRow
            sx={{
                flexDirection: "column",
                flexWrap: "nowrap",
                height: {xs: "100%", lg: customHeight},
                minWidth: {xs: "100%", lg: customWidth},
                width: "100%",
                margin: "10px",
                padding: "20px",
                backgroundColor: "#fff",
                borderRadius: "20px",
            }}
        >
            {" "}
            <Typography
                sx={{width: "100%"}}
                textAlign="start"
                marginBottom="10px"
                variant="h5"
            >
                Utilities: Read vs Unread
            </Typography>
            <ResponsiveRow
                sx={{
                    flexDirection: "row",
                    flexWrap: "nowrap",
                    height: "auto",
                    width: "100%",
                    textAlign: "start",
                    alignItems: "center",
                    justifyContent: "flex-start",
                }}
            >
                <FormControl
                    item
                    sx={{
                        width: {xs: "100%", lg: "50%"},
                        marginBottom: "10px",
                    }}
                >
                    <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale="en-gb"
                    >
                        <DatePicker
                            label="From"
                            sx={{width: {xs: "100%", lg: "90%"}}}
                            id={"from"}
                            value={dayjs(from)}
                            isrequired
                            onChange={(newValue) => {
                                setFrom(newValue)
                            }
                        }
                        />
                    </LocalizationProvider>
                </FormControl>

                <FormControl
                    item
                    sx={{
                        width: {xs: "100%", lg: "50%"},
                        marginBottom: "10px",
                    }}
                >
                    <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale="en-gb"
                    >
                        <DatePicker
                            label="To"
                            sx={{width: {xs: "100%", lg: "90%"}}}
                            id={"to"}
                            value={dayjs(to)}
                            isrequired
                            onChange={(newValue) => {
                                setTo(newValue)
                            }
                        }
                        />
                    </LocalizationProvider>
                </FormControl>
            </ResponsiveRow>
            <ResponsiveRow
                sx={{
                    height: "80%",
                    width: "100%",

                    flexWrap: "nowrap",
                    flexDirection: "row",

                    alignItem: "center",
                    justifyContent: "flex-start",
                }}
            >
                <StackedBarChartCard
                    dataObj={{
                        dataLabels: ["Water", "Electricity"],
                        dataset: [
                            {
                                datasetLabel: "Read",
                                datasetData: utilitiesData.read,
                            },
                            {
                                datasetLabel: "Unread",
                                datasetData: utilitiesData.unread,
                            },
                        ],
                    }}
                    currentlyLoading={loading}
                />
            </ResponsiveRow>
        </ResponsiveRow>
    );
}
