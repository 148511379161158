import { ResponsiveRow } from "../../constants/layout.constants";
import DesktopReportsMenuItem from "../navigation/desktopReportsMenuItem";
import DesktopChildlessMenuItem from "../navigation/desktopChildlessMenuItem";
import { isNullUndefined } from "../../util/util";
import DesktopParentMenuItem from "../navigation/desktopParentMenuItem";

export default function DesktopNavigation({ menus, reports }) {
  return (
    reports &&
    menus &&
    menus.length > 0 && (
      <ResponsiveRow
        sx={{
          display: { xs: "none", lg: "flex" },
          width: "100%",
          overflow: "hidden",
          flexWrap: "nowrap",
          justifyContent: "center",
        }}
      >
        {menus.map((item, index) =>
          isNullUndefined(item.children) ? (
            <DesktopChildlessMenuItem item={item} key={index} />
          ) : (
            <DesktopParentMenuItem item={item} key={index} />
          )
        )}

        <DesktopReportsMenuItem reports={reports} />
      </ResponsiveRow>
    )
  );
}
