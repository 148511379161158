import { ResponsiveRow } from '../constants/layout.constants';
import {
	Alert,
	Grid,
	InputLabel, MenuItem, Select,
	TextField
} from '@mui/material';
import {
	DetailsColumn,
	DetailsContainer,
	DetailsLabel,
	DetailsRow,
	DetailsText,
} from '../constants/component.constants';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import {formatDate, formatDateTime, isEmptyArray} from '../util/util';
import TableHead from '@mui/material/TableHead';
import ModalContainerModule from '../components/modules/modalContainer';
import { useSelector } from 'react-redux';
import Permit from '../util/permit';
import {useEffect, useState} from "react";
import {getChargeAccounts} from "../services/list.service";

export default function LeaseTerminationReview(props) {
	const { user } = useSelector((state) => state.auth);
	const [debitAccounts, setDebitAccounts] = useState([]);
	let rows = [];

	useEffect(() => {
		getChargeAccounts()
		.then((debitAccounts) => {
			setDebitAccounts(debitAccounts)
		})
		.catch(()=>{})
	}, [props]);

	let leaseTerminationRequestItems = props.leaseTerminationRequestItem && props.leaseTerminationRequestItem.items;
	leaseTerminationRequestItems &&
		!isEmptyArray(leaseTerminationRequestItems) &&
		(rows = leaseTerminationRequestItems.map((item, i) => {
			return (
				<TableRow>
					<TableCell>
						<Select
							sx={{ width: "90%" }}
							id={"accountId" + i}
							name={"accountId" + i}
							isrequired={true}
							disabled={true}
							value={leaseTerminationRequestItems && leaseTerminationRequestItems[i] && leaseTerminationRequestItems[i].creditAccount}
							onChange={(event) => console}
						>
							{debitAccounts && debitAccounts.length > 0 ? (
								debitAccounts.map(function (debitAccount, i) {
									return (
										<MenuItem
											key={debitAccount.value}
											value={debitAccount.value}
										>
											{debitAccount.label}
										</MenuItem>
									);
								}, this)
							) : (
								<MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
							)}
						</Select>
					</TableCell>
					<TableCell>{item.memo}</TableCell>
					<TableCell>{item.amount}</TableCell>
					<TableCell>{item.vatTaxed?"Yes":"No"}</TableCell>
				</TableRow>
			);
		}));

	return (
		<ModalContainerModule
			size="md"
			accept={user.proxyRole === 'BLDIR' ? props.approveLeaseTerminationRequest : false}
			reject={user.proxyRole === 'BLDIR' ? props.rejectLeaseTerminationRequest : false}
			cancel={props.closeLeaseTerminateRequestModal}
			openModal={props.leaseTerminationRequestItem}
			modalTitle={'Review Lease Termination Request'}
			acceptButtonText={'Approve'}
			rejectButtonText={'Reject'}
			acceptDisabled={user.proxyRole !== 'BLDIR'}
		>
			<ResponsiveRow sx={{ paddingTop: 0 }}>
				<DetailsContainer sx={{ width: '100%' }}>
					<DetailsColumn
						sx={{
							width: { lg: '50%', md: '100%', xs: '100%' },
						}}
					>
						<DetailsRow>
							<DetailsLabel>Created by:</DetailsLabel>
							<DetailsText>
								{props.leaseTerminationRequestItem &&
									props.leaseTerminationRequestItem.createdBy &&
									props.leaseTerminationRequestItem.createdBy.name}
							</DetailsText>
						</DetailsRow>
						<DetailsRow>
							<DetailsLabel>Billing Code:</DetailsLabel>
							<DetailsText>
								{props.leaseTerminationRequestItem && props.leaseTerminationRequestItem.lease &&
									props.leaseTerminationRequestItem.lease.billingCode}
							</DetailsText>
						</DetailsRow>

						<DetailsRow>
							<DetailsLabel>Tenant:</DetailsLabel>
							<DetailsText>
								{props.leaseTerminationRequestItem && props.leaseTerminationRequestItem.lease &&
									props.leaseTerminationRequestItem.lease.contracteeName}
							</DetailsText>
						</DetailsRow>
						<DetailsRow>
							<DetailsLabel>Start Date:</DetailsLabel>
							<DetailsText>
								{
									props.leaseTerminationRequestItem && props.leaseTerminationRequestItem.lease &&
									props.leaseTerminationRequestItem.lease.start && formatDate(props.leaseTerminationRequestItem.lease.start)
								}
							</DetailsText>
						</DetailsRow>
					</DetailsColumn>

					<DetailsColumn
						sx={{
							width: { lg: '50%', md: '100%', xs: '100%' },
						}}
					>
						<DetailsRow>
							<DetailsLabel>Date Created:</DetailsLabel>
							<DetailsText>
								{props.leaseTerminationRequestItem && props.leaseTerminationRequestItem.dateCreated && formatDateTime(props.leaseTerminationRequestItem.dateCreated )}
							</DetailsText>
						</DetailsRow>

						<DetailsRow>
							<DetailsLabel>Status:</DetailsLabel>
							<DetailsText>
								{props.leaseTerminationRequestItem &&
									props.leaseTerminationRequestItem.status &&
									props.leaseTerminationRequestItem.status.name}
							</DetailsText>
						</DetailsRow>
						<DetailsRow>
							<DetailsLabel>Unit(s):</DetailsLabel>
							<DetailsText>
								{props.leaseTerminationRequestItem && props.leaseTerminationRequestItem.lease && props.leaseTerminationRequestItem.lease.contractedUnit}
							</DetailsText>
						</DetailsRow>
						<DetailsRow>
							<DetailsLabel>Expiration Date:</DetailsLabel>
							<DetailsText>
								{props.leaseTerminationRequestItem  && props.leaseTerminationRequestItem.lease  &&
									props.leaseTerminationRequestItem.lease.end && formatDateTime(props.leaseTerminationRequestItem.lease.end )}
							</DetailsText>
						</DetailsRow>
					</DetailsColumn>
					<DetailsRow>
						<DetailsLabel>Description:</DetailsLabel>
						<DetailsText>
							{props.leaseTerminationRequestItem && props.leaseTerminationRequestItem.description}
						</DetailsText>
					</DetailsRow>
				</DetailsContainer>
				<Permit roles={'BLDIR'}>
					<Alert severity="info">
						Please give a reason for Approving/Rejecting this lease termination request
					</Alert>
					<ResponsiveRow container sx={{ height: 'auto' }}>
						<Grid
							item
							sx={{
								display: 'flex',
								flexDirection: 'column',
								width: '100%',
							}}
						>
							<InputLabel>Reason* ({500 - props.ltrNote.length})</InputLabel>
							<TextField
								sx={{ width: '100%' }}
								id={'note'}
								name={'note'}
								isrequired
								value={props.ltrNote}
								onChange={(event) => props.handleLTRNotesChanged(event)}
								multiline
								rows={2}
							/>
						</Grid>
					</ResponsiveRow>
				</Permit>
				<TableContainer component={Paper}>
					<Table aria-label="simple table">
						<TableHead>
							<TableRow>
								<TableCell>Account</TableCell>
								<TableCell>Memo</TableCell>
								<TableCell>Amount</TableCell>
								<TableCell>Taxed</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>{rows}</TableBody>
					</Table>
				</TableContainer>
			</ResponsiveRow>
		</ModalContainerModule>
	);
}
