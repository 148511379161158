import { useEffect, useState } from "react";
import {
  Button,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid";

import {
  loadBillingServices,
  saveBillingServices,
} from "../services/subscriptionServices.service";
import { Cancel, Edit } from "@mui/icons-material";
import { isEmptyArray, isEmptyString, isNullUndefined } from "../util/util";
import {
  TopBarContainedBrandButton,
  TopBarContainer,
} from "../constants/component.constants";
import { ResponsiveRow } from "../constants/layout.constants";
import AddSubscriptionServiceModal from "../components/modals/subscription/addSubscriptionServiceModal";
import MuiDataGrid from "../components/modules/MuiDataGrid";
import { issueResponseMessage, issueWarnMessage } from "../actions/message";

export default function SubscriptionServices() {
  const [loading, setLoading] = useState(false);
  const [billingServicesList, setBillingServicesList] = useState([]);
  const [totalBillingServices, setTotalBillingServices] = useState(0);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageCount: 25,
  });
  const [task, setTask] = useState("");
  const [selectedService, setSelectedService] = useState(null);
  const [subscriptionServiceForm, setSubscriptionServiceForm] = useState(false);
  const [serviceName, setServiceName] = useState("");
  const [description, setDescription] = useState("");
  const [serviceRates, setServiceRates] = useState([]);
  const [code, setCode] = useState("");
  const [qtyCode, setQtyCode] = useState("");

  useEffect(() => {
    setLoading(true);
    loadBillingServices()
      .then((data) => {
        setBillingServicesList(data.services);
        setTotalBillingServices(data.services.length);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [paginationModel.page]);

  const columns = [
    { headerName: "Service Name", field: "name", minWidth: 350, flex: 3.5 },
    {
      headerName: "Quantity Code",
      field: "quantityCode",
      minWidth: 350,
      flex: 3.5,
    },
    {
      headerName: "Description",
      field: "description",
      minWidth: 475,
      flex: 4.75,
    },
    {
      headerName: "Actions",
      field: "actions",
      width: 100,
      type: "actions",
      getActions: (params) => {
        let arr = [];
        arr.push(
          <GridActionsCellItem
            icon={<Edit />}
            onClick={() => handleEditClicked(params.row)}
            label="Edit"
            showInMenu
          />
        );

        return arr;
      },
    },
  ];

  const handleEditClicked = (item) => {
    setTask("Edit");
    setSelectedService(item);
    setSubscriptionServiceForm(true);
    setServiceRates(item.serviceRates);
  };

  const handleAddService = () => {
    setTask("Create");
    let item = {
      id: "",
      serviceCode: "", // code
      serviceRates: [],
      name: "",
      description: "",
      quantityCode: "",
    };
    setSelectedService(item);
    setSubscriptionServiceForm(true);
    setServiceRates(item.serviceRates);
  };

  const handleCancelClicked = () => {
    setTask("");
    setSelectedService(null);
    setSubscriptionServiceForm(false);
    setServiceRates([]);
  };

  const minQtyChanged = (event, index) => {
    let value = event.target.value;
    let itemsArray = [...serviceRates]; // copying the old items array
    itemsArray[index].minQuantity = value;
    setServiceRates(itemsArray);
  };

  const maxQtyChanged = (event, index) => {
    let value = event.target.value;
    let itemsArray = [...serviceRates]; // copying the old items array
    itemsArray[index].maxQuantity = value;
    setServiceRates(itemsArray);
  };

  const unitPriceChanged = (event, index) => {
    let value = event.target.value;
    let itemsArray = [...serviceRates]; // copying the old items array
    itemsArray[index].unitPrice = value;
    setServiceRates(itemsArray);
  };

  const vatChanged = (event, index) => {
    let value = event.target.value;
    let itemsArray = [...serviceRates]; // copying the old items array
    itemsArray[index].vat = value;
    setServiceRates(itemsArray);
  };

  const wthChanged = (event, index) => {
    let value = event.target.value;
    let itemsArray = [...serviceRates]; // copying the old items array
    itemsArray[index].wth = value;
    setServiceRates(itemsArray);
  };

  const handleItemAdd = () => {
    // let obj = selectedService
    let serviceId = selectedService.id;
    let item = {
      id: null,
      serviceId: serviceId,
      minQuantity: null,
      maxQuantity: null,
      unitPrice: null,
      vat: 0,
      wth: 0,
    };
    setServiceRates((serviceRateItems) => [...serviceRateItems, item]);
    selectedService.serviceRates.push(item);
  };

  const handleRemoveItem = (index) => {
    let itemsArray = [...serviceRates]; // copying the old items array
    itemsArray.splice(index, 1);
    setServiceRates(itemsArray);
    selectedService.serviceRates.splice(index, 1);
  };

  let rows = [];

  serviceRates &&
    serviceRates.length > 0 &&
    (rows = serviceRates.map((item, i) => {
      return (
        <TableRow key={i}>
          <TableCell>
            <TextField
              placeholder={"Min"}
              sx={{ width: "90%" }}
              id={"minimumNumber" + i}
              required={true}
              onChange={(event) => minQtyChanged(event, i)}
              value={item && item.minQuantity}
            />
          </TableCell>
          <TableCell>
            <TextField
              placeholder={"Max"}
              sx={{ width: "90%" }}
              id={"maxNumber" + i}
              required={true}
              onChange={(event) => maxQtyChanged(event, i)}
              value={item && item.maxQuantity}
            />
          </TableCell>
          <TableCell>
            <TextField
              placeholder={"Unit Price"}
              sx={{ width: "90%" }}
              id={"unitPrice" + i}
              required={true}
              defaultValue={0}
              onChange={(event) => unitPriceChanged(event, i)}
              value={item && item.unitPrice}
            />
          </TableCell>
          <TableCell>
            <TextField
              placeholder={"V.A.T"}
              sx={{ width: "90%" }}
              id={"vat" + i}
              required={true}
              onChange={(event) => vatChanged(event, i)}
              defaultValue={0}
              value={item && item.vat}
            />
          </TableCell>
          <TableCell>
            <TextField
              placeholder={"W.H.T"}
              sx={{ width: "90%" }}
              id={"wth" + i}
              required={true}
              onChange={(event) => wthChanged(event, i)}
              defaultValue={0}
              value={item && item.wth}
            />
          </TableCell>
          <TableCell>
            <Button
              variant="contained"
              type="submit"
              sx={{ marginLeft: "10px" }}
              onClick={() => handleRemoveItem(i)}
              color="error"
            >
              <Cancel />
            </Button>
          </TableCell>
        </TableRow>
      );
    }, this));

  const validateFormData = (sendData) => {
    let service = sendData;
    if (isNullUndefined(service)) {
      issueWarnMessage(
        "Something went wrong with the form. Please refresh the page or contact support"
      );
      return false;
    }

    if (isNullUndefined(service.name) || isEmptyString(service.name)) {
      issueWarnMessage("The Service name cannot be empty.");
      return false;
    }

    if (isNullUndefined(service.code) || isEmptyString(service.code)) {
      issueWarnMessage("The Service code cannot be empty.");
      return false;
    }

    if (isEmptyString(service.description)) {
      issueWarnMessage("The description cannot be empty.");
      return false;
    }

    if (!isEmptyArray(service.serviceRates)) {
      service.serviceRates.forEach((rate, index) => {
        if (
          isEmptyString(rate.minQuantity) ||
          isNullUndefined(rate.minQuantity) ||
          rate.minQuantity < 0
        ) {
          issueWarnMessage(
            "The value entered for min number of units is invalid."
          );
          return false;
        }
        if (
          isEmptyString(rate.maxQuantity) ||
          isNullUndefined(rate.maxQuantity) ||
          rate.maxQuantity < 0
        ) {
          issueWarnMessage(
            "The value entered for max number of units is invalid."
          );
          return false;
        }
        if (rate.maxQuantity < rate.minQuantity) {
          issueWarnMessage("Minimum units cannot be more than maximum units.");
          return false;
        }
        if (rate.maxQuantity === rate.minQuantity) {
          issueWarnMessage("Minimum units cannot be equal to maximum units.");
          return false;
        }
        if (
          isEmptyString(rate.unitPrice) ||
          isNullUndefined(rate.unitPrice) ||
          rate.unitPrice <= 0
        ) {
          issueWarnMessage("The value entered for unitPrice is invalid.");
          return false;
        }
        if (rate.vat < 0) {
          issueWarnMessage("Invalid value entered for VAT.");
          return false;
        }
        if (rate.wth < 0) {
          issueWarnMessage("The value entered for WTH is invalid.");
          return false;
        }
      });
    } else {
      issueWarnMessage("Service Rates cannot be empty.");
      return false;
    }

    return true;
  };

  const handleSaveSubscriptionService = () => {
    let sendData = selectedService;
    if (validateFormData(sendData)) {
      setLoading(true);
      saveBillingServices(sendData).then((response) => {
        loadBillingServices()
          .then((data) => {
            setBillingServicesList(data.services);
            setTotalBillingServices(data.services.length);
          })
          .finally(() => {
            setLoading(false);
          });
        issueResponseMessage(response);
        handleCancelClicked();
      });
    }
  };

  return (
    <ResponsiveRow
      sx={{
        flexDirection: "column",
        flexWrap: "nowrap",

        justifyContent: "flex-start",
        alignItems: "flex-start",

        width: "100%",
        height: "auto",

        padding: 0,
      }}
    >
      <TopBarContainer
        container
        sx={{
          flexDirection: { xs: "column", lg: "row" },
          flexWrap: "nowrap",

          width: { xs: "100%", lg: "80%" },
          height: "auto",

          alignItems: "center",
          justifyContent: { xs: "center", lg: "space-between" },
        }}
      >
        <Typography
          sx={{
            textAlign: { xs: "center", lg: "start" },
            width: { xs: "100%", lg: "50%" },
          }}
          id="subscriptionServices"
          variant="h6"
        >
          Subscription Services
        </Typography>

        <ResponsiveRow
          item
          sx={{
            padding: 0,
            width: { xs: "100%", lg: "50%" },
            justifyContent: { xs: "center", lg: "flex-end" },
          }}
        >
          <TopBarContainedBrandButton
            sx={{ width: { xs: "100%", lg: "200px" }, height: "60px" }}
            onClick={handleAddService}
          >
            Add Service
          </TopBarContainedBrandButton>
        </ResponsiveRow>
      </TopBarContainer>
      <br />

      <MuiDataGrid
        dataGridColumns={columns}
        dataGridRows={billingServicesList}
        serverPagination={false}
        noSelection={true}
        handlePageLoad={setPaginationModel}
        loading={loading}
        height="60vh"
        totalRows={totalBillingServices || billingServicesList.length}
      />

      {subscriptionServiceForm && (
        <AddSubscriptionServiceModal
          subscriptionServiceForm={subscriptionServiceForm}
          handleCancelClicked={handleCancelClicked}
          handleItemAdd={handleItemAdd}
          handleSaveSubscriptionService={handleSaveSubscriptionService}
          setDescription={setDescription}
          setServiceName={setServiceName}
          setSelectedService={setSelectedService}
          setQtyCode={setQtyCode}
          serviceName={serviceName}
          setCode={setCode}
          code={code}
          description={description}
          rows={rows}
          selectedService={selectedService}
          task={task}
          qtyCode={qtyCode}
        />
      )}
    </ResponsiveRow>
  );
}
