import {
  FormControl,
  InputLabel,
  OutlinedInput,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { ResponsiveRow } from "../../../../constants/layout.constants";
import ModalContainerModule from "../../../modules/modalContainer";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { isNullUndefined } from "../../../../util/util";
import dayjs from "dayjs";

export default function CreateReceiptChargeModal(props) {
  /**
   *
   * REQUIRED PROPS
   *
   * saveDebitNoteForm
   *
   * handleChargeClose
   * handleChargeDateChange
   * handleChargeItemAdd
   *
   * setDebitNote
   *
   * chargeItemsTableRows
   * debitNote
   * showDebitModal
   *
   */
  return (
    <ModalContainerModule
      size="specialReceiptCharge"
      accept={props.saveDebitNoteForm}
      cancel={props.handleChargeClose}
      openModal={props.showDebitModal}
      modalTitle="Create a Charge"
      acceptButtonText="Save"
    >
      <ResponsiveRow container sx={{ height: "auto", padding: 0 }}>
        <FormControl
          sx={{ width: { xs: "100%", lg: "50%" }, marginBottom: "10px" }}
        >
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale="en-gb"
          >
            <DatePicker
              label="Charge Date"
              id={"chargeDate"}
              value={
                !isNullUndefined(props.debitNote)
                  ? dayjs(props.debitNote.date)
                  : null
              }
              isrequired={false}
              disabled={true}
              onChange={(newDate) => props.handleChargeDateChange(newDate)}
              sx={{ width: "90%" }}
            />
          </LocalizationProvider>
        </FormControl>
        <FormControl
          sx={{ width: { xs: "100%", lg: "50%" }, marginBottom: "10px" }}
        >
          <InputLabel>Charge Memo</InputLabel>
          <OutlinedInput
            label="Charge Memo"
            sx={{ width: "90%" }}
            id={"chargeMemo"}
            name={"chargeMemo"}
            isrequired={true}
            disabled={true}
            value={
              !isNullUndefined(props.debitNote) ? props.debitNote.memo : null
            }
            onChange={(event) => {
              const value = event.target.value;
              let obj = props.debitNote;
              obj.memo = value;
              props.setDebitNote(obj);
            }}
          />
        </FormControl>
        <ResponsiveRow item sx={{ width: "100%", height: "auto" }}>
          <br />
          <Typography variant="h6">Charge Items (required *)</Typography>
          <TableContainer style={{ maxHeight: 350 }}>
            <Table sx={{ minWidth: 20 }} aria-label="simple table" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: "30%" }}>Account</TableCell>
                  <TableCell sx={{ width: "30%" }}>Memo</TableCell>
                  <TableCell sx={{ width: "20%" }}>Amount</TableCell>
                  <TableCell sx={{ width: "10%" }}>Apply VAT</TableCell>
                  <TableCell sx={{ width: "10%" }}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{props.chargeItemsTableRows}</TableBody>
            </Table>
          </TableContainer>
        </ResponsiveRow>
      </ResponsiveRow>
    </ModalContainerModule>
  );
}
