import { Typography } from "@mui/material";
import {
  ModalAcceptButton,
  ModalCancelButton,
} from "../../constants/component.constants";
import { ResponsiveRow } from "../../constants/layout.constants";

export default function BillingTabBar(props) {
  return (
    <ResponsiveRow
      sx={{
        width: "100%",
        alignItems: "center",
        margin: "auto auto 1px auto",
      }}
    >
      <Typography
        sx={{
          fontSize: "1.2rem",
          fontWeight: 600,
          color: "#037171",
          width: { xs: "100%", lg: "25%" },
          textAlign: { xs: "center", lg: "start" },
        }}
      >
        Bills
      </Typography>
      <ResponsiveRow
        sx={{ width: { xs: "100%", lg: "75%" }, justifyContent: "flex-end" }}
      >
        {props.expanded ? (
          <ModalCancelButton
            onClick={() => props.handleCloseSearch()}
            sx={{
              width: { xs: "100%", lg: "200px" },
            }}
          >
            Close Search
          </ModalCancelButton>
        ) : props.searchResult ? (
          <ModalCancelButton
            onClick={() => props.handleCloseSearch()}
            sx={{
              width: { xs: "100%", lg: "200px" },
            }}
          >
            Clear Search
          </ModalCancelButton>
        ) : (
          <ModalAcceptButton
            onClick={() => props.handleExpandClick()}
            sx={{
              width: { xs: "100%", lg: "200px" },
            }}
          >
            Search
          </ModalAcceptButton>
        )}
        <ModalAcceptButton
          onClick={props.handleAddBillClick}
          sx={{
            width: { xs: "100%", lg: "200px" },
          }}
        >
          Add Bill
        </ModalAcceptButton>
      </ResponsiveRow>
    </ResponsiveRow>
  );
}
