import ModalContainerModule from "../../../modules/modalContainer";
import MuiDataGrid from "../../../modules/MuiDataGrid";

export default function ViewWorkOrderNotes(props) {
    /**
     *
     * REQUIRED PROPS
     *
     * handleClose
     * open
     * data: {
     *       createdAt: string
     *       note: string
     *       createdBy: string
     *    }
     *
     */

    const tableColumns = [
        {field: "createdAt", headerName: "Created At", width: 300},
        {field: "note", headerName: "Note", minWidth: 500, flex: 1.5},
        {field: "createdBy", headerName: "Create By", minWidth: 200, flex: 0.7},
    ];

    return (
        <ModalContainerModule
            size="specialViewWorkOrderNotes"
            cancel={props.handleClose}
            cancelText="Close"
            openModal={props.open}
            modalTitle="Assigned Work Order Notes"
        >
            {/* <DataGrid
				sx={{
					boxShadow: 0,
					borderColor: "transparent",
					"& .MuiDataGrid-columnHeaders": {
						borderRadius: 0,
						backgroundColor: "#fff",
						color: "#037171",
					},
					"& .MuiDataGrid-columnHeader": {
						backgroundColor: "#fff",
						color: "#037171",
					},
				}}
				columns={tableColumns}
				rows={props.data}
			/> */}
            <MuiDataGrid
                noBoxShadow={true}
                noSelection={true}
                loading={false}
                dataGridColumns={tableColumns}
                dataGridRows={props.data}
                totalRows={props.data.length}
                height="345px"
            />
        </ModalContainerModule>
    );
}
