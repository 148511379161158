import {Typography} from "@mui/material"
import {Box} from "@mui/system"
import {ModalAcceptButton} from "../../../constants/component.constants"
import {ResponsiveRow} from "../../../constants/layout.constants"
import {formatDate} from "../../../util/util"
import {GET_LISTING_IMAGE_URL} from "../../../constants/url.constants";

export default function ApplicationItem(props) {
    return (
        <ResponsiveRow
            sx={{
                backgroundColor: "#fff",
                width: {xs: "90%", lg: "400px"},
                height: {xs: "auto", lg: "275px"},
                borderRadius: "20px",
                padding: "20px",
                margin: "10px",
                flexDirection: "column",
                flexWrap: "nowrap",
                alignItems: "flex-start",
            }}
        >
            <ResponsiveRow
                sx={{
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: {xs: "flex-end", lg: "flex-start"},
                    flexWrap: "nowrap",
                    height: "80px",
                    width: "100%",
                    padding: 0,
                }}
            >
                <Typography
                    sx={{
                        margin: "5px",
                        width: "75px",
                        textAlign: "center",
                        fontSize: ".75rem",
                        textTransform: "uppercase",
                        padding: "5px 7px",
                        borderRadius: "5px",
                        // color: "#00B9AE",
                        color: "#037171",
                        // backgroundColor: "#037171",
                        backgroundColor: "#efefef",
                        // color: "#efefef",
                        // color: "#fff",
                        fontWeight: 600,
                    }}
                >
                    {props.applicationItem.statusCd === "PEN" ? "New" : props.applicationItem.statusCd === "APP" ? "APPROVED" : props.applicationItem.statusCd === "REJ" ? "REJECTED" : ""}
                </Typography>
            </ResponsiveRow>
            <ResponsiveRow
                sx={{
                    flexDirection: "row",
                    alignItems: "center",
                    flexWrap: "nowrap",
                    height: "80px",
                    width: "100%",
                    padding: "20px",
                }}
            >
                <Box
                    component="img"
                    sx={{
                        objectFit: "cover",
                        objectPosition: "center center",
                        width: "75px",
                        height: "75px",
                        borderRadius: "10px",
                    }}
                    src={GET_LISTING_IMAGE_URL + "?filename=" + (props.applicationItem.listing && props.applicationItem.listing.imageFiles && props.applicationItem.listing.imageFiles[0] && props.applicationItem.listing.imageFiles[0].systemFileName)}
                    alt="listing image"
                />
                <ResponsiveRow
                    sx={{
                        flexDirection: "column",
                        flexWrap: "nowrap",
                        height: "100%",
                        alignItems: "flex-start",
                        justifyContent: "center",
                        padding: "5px 20px",
                    }}
                >
                    <Typography
                        variant="h4"
                        sx={{color: "#037171", fontWeight: 700, margin: "2px 0"}}
                    >
                        {props.applicationItem.applicant.name}
                    </Typography>
                    <Typography variant="h5" sx={{fontWeight: 600, margin: "2px 0"}}>
                        {props.applicationItem.listing.propertyName}
                    </Typography>
                    <Typography variant="body2" sx={{fontWeight: 600, margin: "2px 0"}}>
                        Application date: {formatDate(props.applicationItem.dateCreated)}
                    </Typography>
                </ResponsiveRow>
            </ResponsiveRow>
            <ResponsiveRow
                sx={{
                    width: "100%",
                    justifyContent: "center",
                    padding: "10px 10px",
                    flexWrap: "nowrap",
                }}
            >
                <Typography
                    variant="body2"
                    sx={{marginTop: "5px", width: "90%", textAlign: "start"}}
                >
                    {props.applicationItem.notes}
                </Typography>
            </ResponsiveRow>
            {props.applicationItem.statusCd === "PEN" &&
                <ResponsiveRow
                    sx={{
                        width: "100%",
                        justifyContent: {xs: "center", lg: "flex-end"},
                        padding: "0 10px",
                    }}
                >
                    <ModalAcceptButton
                        sx={{
                            width: {xs: "100%", lg: "auto"},
                            padding: "5px 10px",
                            fontSize: ".8rem",
                        }}
                        onClick={() => props.viewApplicationItem(props.applicationItem)}
                    >
                        Review Application
                    </ModalAcceptButton>
                </ResponsiveRow>
            }
        </ResponsiveRow>
    )
}
