import {Alert} from "@mui/material"
import ModalContainerModule from "../../../modules/modalContainer"

export default function ReverseJournalEntryModal(props) {
    /**
     *
     * REQUIRED PROPS
     *
     * ReverseModal
     *
     * setReverseModal
     * setSelectedEntry
     *
     * selectedEntry
     * reverseEntry
     *
     *
     */
    return (
        <ModalContainerModule
            size="specialReverseJournalEntry"
            accept={props.reverseEntry}
            cancel={() => {
                props.setReverseModal(false)
                props.setSelectedEntry(null)
            }}
            openModal={props.ReverseModal}
            modalTitle="Reverse Journal Entry"
            acceptButtonText="Reverse Entry"
        >
            <Alert severity="error" sx={{fontSize: "1rem", width: "100%"}}>
                Are you sure you wish to Reverse the Journal Entry for{" "}
                {props.selectedEntry && props.selectedEntry.appliedTo}?
            </Alert>
        </ModalContainerModule>
    )
}
