import {REBUILD_LEASE_HISTORY_URL, REBUILD_OWNERSHIP_HISTORY_URL} from "../constants/url.constants"
import {serviceAPI} from "./service"
import {handleErrorResponse} from "../actions/message"

//GET REQUEST

export const rebuildLeaseHistory = (billingCode) => {
    let url = REBUILD_LEASE_HISTORY_URL + billingCode
    return serviceAPI.get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const rebuildOwnershipHistory = (billingCode) => {
    let url = REBUILD_OWNERSHIP_HISTORY_URL + billingCode
    return serviceAPI.get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

//POST REQUEST
