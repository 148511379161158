import {serviceAPI, serviceAPIDownloadFile} from "./service"
import {
    DELETE_TRANSFER_DOCUMENTS_URL,
    FACILITY_AMOUNT_URL,
    LIST_MOBILE_MONEY_TRANSFERS,
    PRINT_TRANSFER_URL,
    SAVE_TRANSFER_URL,
    TOTAL_MOBILE_MONEY_TRANSFERS,
    TRF_ORG_BANK_ACC_URL,
    UPLOAD_TRANSFER_DOCUMENTS_URL,
    VIEW_TRANSFER_URL
} from "../constants/url.constants"
import {isEmptyString, isNullUndefined, isValidDate} from "../util/util"
import {handleErrorResponse} from "../actions/message"

export const listMobileMoneyTransfers = (payee, refNumber, description, approvalDate, pageNo) => {
    let approvalDateValue = ''
    let date = new Date(approvalDate)
    if ((!isEmptyString(approvalDate) || !isNullUndefined(approvalDate)) && isValidDate(approvalDate)) {
        approvalDateValue = date.toISOString()
    }
    let url = LIST_MOBILE_MONEY_TRANSFERS + "?payee=" + payee + "&refNumber=" + refNumber + "&description=" + description + "&approvalDate=" + approvalDateValue + "&pageNo=" + pageNo
    return serviceAPI
        .get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const totalMobileMoneyTransfers = (payee, refNumber, description, approvalDate) => {
    let approvalDateValue = ''
    let date = new Date(approvalDate)
    if ((!isEmptyString(approvalDate) || !isNullUndefined(approvalDate)) && isValidDate(approvalDate)) {
        approvalDateValue = date.toISOString()
    }
    let url = TOTAL_MOBILE_MONEY_TRANSFERS + "?payee=" + payee + "&refNumber=" + refNumber + "&description=" + description + "&approvalDate=" + approvalDateValue
    return serviceAPI
        .get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const listBankAccounts = () => {
    return serviceAPI
        .get(TRF_ORG_BANK_ACC_URL)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getFacilityAmount = () => {
    return serviceAPI
        .get(FACILITY_AMOUNT_URL)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const saveFundsTransfer = (data) => {
    return serviceAPI
        .post(SAVE_TRANSFER_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const viewTransfer = (transferId) => {
    let url = VIEW_TRANSFER_URL + transferId
    return serviceAPI
        .get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const printTransferDetails = (transferId) => {
    return serviceAPIDownloadFile.get(PRINT_TRANSFER_URL + transferId)
}

export const uploadTransferDocuments = (data, transferId) => {
    let url = UPLOAD_TRANSFER_DOCUMENTS_URL + transferId
    return serviceAPI
        .post(url, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const deleteMMTransferDoc = (data) => {
    return serviceAPI
        .post(DELETE_TRANSFER_DOCUMENTS_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}