import {useState} from "react"
import {Button, Container, Typography} from "@mui/material"
import {Box} from "@mui/system"
import AddNewOrganisation from "./modals/addNewOrganisation"
import AddNewUser from "./modals/addNewUser"
import BulkUpload from "./modals/bulkUpload"
import ConfirmationModal from "./modals/confirmation"
import CreateBill from "./modals/createBill"
import CreatePayment from "./modals/createPayment"
import CreditNote from "./modals/creditNote"
import ViewBill from "./modals/viewBill"
import ViewPayment from "./modals/viewPayment"
import CreateBillReminder from "./modals/createBillReminder"


export default function ModalsComponents() {
    const [openNewOrganization, setNewOrganization] = useState(false)
    const [openNewUSer, setNewUser] = useState(false)
    const [openBulkUpload, setBulkUpload] = useState(false)
    const [openConfirmation, setConfimation] = useState(false)
    const [openCreateBill, setCreateBill] = useState(false)
    const [openViewBill, setViewBill] = useState(false)
    const [openCreatePayment, setCreatePayment] = useState(false)
    const [openViewPayment, setViewPayment] = useState(false)
    const [openCreateReminder, setCreateReminder] = useState(false)
    const [openEditReminder, setEditReminder] = useState(false)

    const billReminder = {
        payee: "Nairobi water",
        dueDate: "10/05/2021",
        memo: "memo",
        billingFrequency: "Annually",
        billingDate: "10/05/2021",
        applyVAT: true,
        recurringBill: true,
    }

    return (
        <Container disableGutters maxWidth="xxl" sx={{padding: "10px"}}>
            <Box
                sx={{
                    padding: "20px 15px",
                    width: "100%",
                    border: "1px #02CBCE solid",
                    borderRadius: "10px",
                    margin: "20px auto",
                }}
            >
                <Typography
                    variant="h4"
                    textAlign={"center"}
                    sx={{margin: "20px 0", color: "#037171"}}
                >
                    Add New Organization
                </Typography>
                <Button
                    sx={{
                        backgroundColor: "#037171",
                        color: "#fff",
                        "&:hover": {backgroundColor: "#fff", color: "#037171"},
                    }}
                    onClick={() => setNewOrganization(true)}
                    fullWidth
                >
                    Trigger Modal
                </Button>
                <AddNewOrganisation
                    openModal={openNewOrganization}
                    closeModal={() => setNewOrganization(false)}
                />
            </Box>
            <Box
                sx={{
                    padding: "20px 15px",
                    width: "100%",
                    border: "1px #02CBCE solid",
                    borderRadius: "10px",
                    margin: "20px auto",
                }}
            >
                <Typography
                    variant="h4"
                    textAlign={"center"}
                    sx={{margin: "20px 0", color: "#037171"}}
                >
                    Add New USer
                </Typography>
                <Button
                    sx={{
                        backgroundColor: "#037171",
                        color: "#fff",
                        "&:hover": {backgroundColor: "#fff", color: "#037171"},
                    }}
                    onClick={() => setNewUser(true)}
                    fullWidth
                >
                    Trigger Modal
                </Button>
                <AddNewUser
                    openModal={openNewUSer}
                    closeModal={() => setNewUser(false)}
                />
            </Box>
            <Box
                sx={{
                    padding: "20px 15px",
                    width: "100%",
                    border: "1px #02CBCE solid",
                    borderRadius: "10px",
                    margin: "20px auto",
                }}
            >
                <Typography
                    variant="h4"
                    textAlign={"center"}
                    sx={{margin: "20px 0", color: "#037171"}}
                >
                    Bulk Upload Modal
                </Typography>
                <Button
                    sx={{
                        backgroundColor: "#037171",
                        color: "#fff",
                        "&:hover": {backgroundColor: "#fff", color: "#037171"},
                    }}
                    onClick={() => setBulkUpload(true)}
                    fullWidth
                >
                    Trigger Modal
                </Button>
                <BulkUpload
                    showBulkUploadModal={openBulkUpload}
                    handleBulkUploadClose={() => setBulkUpload(false)}
                />
            </Box>
            <Box
                sx={{
                    padding: "20px 15px",
                    width: "100%",
                    border: "1px #02CBCE solid",
                    borderRadius: "10px",
                    margin: "20px auto",
                }}
            >
                <Typography
                    variant="h4"
                    textAlign={"center"}
                    sx={{margin: "20px 0", color: "#037171"}}
                >
                    Confirmation Modal
                </Typography>
                <Button
                    sx={{
                        backgroundColor: "#037171",
                        color: "#fff",
                        "&:hover": {backgroundColor: "#fff", color: "#037171"},
                    }}
                    onClick={() => setConfimation(true)}
                    fullWidth
                >
                    Trigger Modal
                </Button>
                <ConfirmationModal
                    showConfirmationModal={openConfirmation}
                    handleConfirmationClose={() => setConfimation(false)}
                />
            </Box>
            <Box
                sx={{
                    padding: "20px 15px",
                    width: "100%",
                    border: "1px #02CBCE solid",
                    borderRadius: "10px",
                    margin: "20px auto",
                }}
            >
                <Typography
                    variant="h4"
                    textAlign={"center"}
                    sx={{margin: "20px 0", color: "#037171"}}
                >
                    Credit Note Modal
                </Typography>
                <Button
                    sx={{
                        backgroundColor: "#037171",
                        color: "#fff",
                        "&:hover": {backgroundColor: "#fff", color: "#037171"},
                    }}
                    onClick={() => setConfimation(true)}
                    fullWidth
                >
                    Trigger Modal
                </Button>
                <CreditNote
                    open={openConfirmation}
                    closeModal={() => setConfimation(false)}
                />
            </Box>
            <Box
                sx={{
                    padding: "20px 15px",
                    width: "100%",
                    border: "1px #02CBCE solid",
                    borderRadius: "10px",
                    margin: "20px auto",
                }}
            >
                <Typography
                    variant="h4"
                    textAlign={"center"}
                    sx={{margin: "20px 0", color: "#037171"}}
                >
                    Create Bill Modal
                </Typography>
                <Button
                    sx={{
                        backgroundColor: "#037171",
                        color: "#fff",
                        "&:hover": {backgroundColor: "#fff", color: "#037171"},
                    }}
                    onClick={() => setCreateBill(true)}
                    fullWidth
                >
                    Trigger Modal
                </Button>
                <CreateBill
                    openModal={openCreateBill}
                    handleClose={() => setCreateBill(false)}
                />
            </Box>
            <Box
                sx={{
                    padding: "20px 15px",
                    width: "100%",
                    border: "1px #02CBCE solid",
                    borderRadius: "10px",
                    margin: "20px auto",
                }}
            >
                <Typography
                    variant="h4"
                    textAlign={"center"}
                    sx={{margin: "20px 0", color: "#037171"}}
                >
                    View Bill Modal
                </Typography>
                <Button
                    sx={{
                        backgroundColor: "#037171",
                        color: "#fff",
                        "&:hover": {backgroundColor: "#fff", color: "#037171"},
                    }}
                    onClick={() => setViewBill(true)}
                    fullWidth
                >
                    Trigger Modal
                </Button>
                <ViewBill
                    openModal={openViewBill}
                    handleClose={() => setViewBill(false)}
                />
            </Box>
            <Box
                sx={{
                    padding: "20px 15px",
                    width: "100%",
                    border: "1px #02CBCE solid",
                    borderRadius: "10px",
                    margin: "20px auto",
                }}
            >
                <Typography
                    variant="h4"
                    textAlign={"center"}
                    sx={{margin: "20px 0", color: "#037171"}}
                >
                    Create Payment Modal
                </Typography>
                <Button
                    sx={{
                        backgroundColor: "#037171",
                        color: "#fff",
                        "&:hover": {backgroundColor: "#fff", color: "#037171"},
                    }}
                    onClick={() => setCreatePayment(true)}
                    fullWidth
                >
                    Trigger Modal
                </Button>
                <CreatePayment
                    openModal={openCreatePayment}
                    handleClose={() => setCreatePayment(false)}
                />
            </Box>
            <Box
                sx={{
                    padding: "20px 15px",
                    width: "100%",
                    border: "1px #02CBCE solid",
                    borderRadius: "10px",
                    margin: "20px auto",
                }}
            >
                <Typography
                    variant="h4"
                    textAlign={"center"}
                    sx={{margin: "20px 0", color: "#037171"}}
                >
                    View Payment Modal
                </Typography>
                <Button
                    sx={{
                        backgroundColor: "#037171",
                        color: "#fff",
                        "&:hover": {backgroundColor: "#fff", color: "#037171"},
                    }}
                    onClick={() => setCreatePayment(true)}
                    fullWidth
                >
                    Trigger Modal
                </Button>
                <ViewPayment
                    openModal={openViewPayment}
                    handleClose={() => setViewPayment(false)}
                />
            </Box>
            <Box
                sx={{
                    padding: "20px 15px",
                    width: "100%",
                    border: "1px #02CBCE solid",
                    borderRadius: "10px",
                    margin: "20px auto",
                }}
            >
                <Typography
                    variant="h4"
                    textAlign={"center"}
                    sx={{margin: "20px 0", color: "#037171"}}
                >
                    Create Bill Reminder Modal
                </Typography>
                <Button
                    sx={{
                        backgroundColor: "#037171",
                        color: "#fff",
                        "&:hover": {backgroundColor: "#fff", color: "#037171"},
                    }}
                    onClick={() => setCreateReminder(true)}
                    fullWidth
                >
                    Trigger Modal
                </Button>
                <CreateBillReminder
                    openModal={openCreateReminder}
                    handleClose={() => setCreateReminder(false)}
                />
            </Box>
            <Box
                sx={{
                    padding: "20px 15px",
                    width: "100%",
                    border: "1px #02CBCE solid",
                    borderRadius: "10px",
                    margin: "20px auto",
                }}
            >
                <Typography
                    variant="h4"
                    textAlign={"center"}
                    sx={{margin: "20px 0", color: "#037171"}}
                >
                    Edit Bill Reminder Modal
                </Typography>
                <Button
                    sx={{
                        backgroundColor: "#037171",
                        color: "#fff",
                        "&:hover": {backgroundColor: "#fff", color: "#037171"},
                    }}
                    onClick={() => setEditReminder(true)}
                    fullWidth
                >
                    Trigger Modal
                </Button>
                <CreateBillReminder
                    openModal={openEditReminder}
                    handleClose={() => setEditReminder(false)}
                    billReminder={billReminder}
                />
            </Box>
        </Container>
    )
}
