import {Checkbox, FormControlLabel, Grid, IconButton, Link, MenuItem, TextField, Typography,} from "@mui/material";
import {Box} from "@mui/system";
import {useEffect, useState} from "react";
import {ModalAcceptButton, ModalCancelButton,} from "../../../constants/component.constants";

import Facebook from "@mui/icons-material/Facebook";
import jwt_decode from "jwt-decode";

import {ModalContainer, ModalInnerContainer, ResponsiveRow,} from "../../../constants/layout.constants";

import bgImage from "../../../images/listings/SigninBg.png";
import {useNavigate} from "react-router-dom";
import FacebookLogin from "react-facebook-login";
import {fetchFacebookUser, fetchGoogleUser, listingsLogin,} from "../../../actions/auth";
import {useDispatch} from "react-redux";
import InputAdornment from "@mui/material/InputAdornment";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {isEmptyObject, isEmptyString, isNullUndefined,} from "../../../util/util";
import {setMessage} from "../../../actions/message";
import {getIdTypes} from "../../../services/list.service";
import {handleSocialRegistration} from "../../../services/listingsServices/listing.service";

export default function ListingSignIn() {
    const [remember, setRemember] = useState(false);
    const [loading, setLoading] = useState(false);
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [socialRegistrationForm, setSocialRegistrationForm] = useState(false);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [userId, setUserId] = useState("");
    const [idType, setIdType] = useState("");
    const [idNo, setIdNo] = useState("");
    const [email, setEmail] = useState("");
    const [idTypeOptions, setIdTypeOptions] = useState([]);
    const [socialLoginType, setSocialLoginType] = useState("");
    const navigator = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        /* global google */
        google.accounts.id.initialize({
            client_id:
                "224504111787-35bhn37opbklo5p42ufb958d9laf2d26.apps.googleusercontent.com",
            callback: onGoogleSignIn,
        });

        google.accounts.id.renderButton(
            document.getElementById("buttonDiv"),
            {theme: "outline", size: "large"} // customization attributes
        );

        getIdTypes().then((idTypes) => setIdTypeOptions(idTypes));
    }, []);

    function onGoogleSignIn(response) {
        /**
         * Installed jwt-decode npm package to decode credential from response.
         */
            // console.log("Encoded JWT ID Token: ", response.credential) //Decode this to get object payload
        let userObject = jwt_decode(response.credential);
        // console.log("userObject", userObject)
        if (!isEmptyObject(userObject)) {
            let name = userObject.name.split(" ");
            setFirstName(name[0]);
            setLastName(name[1]);
            setUserId(userObject.sub);
            setEmail(userObject.email);
            setSocialLoginType("google");
            fetchSocialPlatformUserAndLogin(userObject.sub, "google");
        } else {
            // console.log("GMAIL LOGIN FAILED!")
        }
    }

    function validateForm() {
        if (isEmptyString(userName)) {
            dispatch(
                setMessage({
                    type: "warning",
                    message: "Username is required.",
                })
            );
            return false;
        }
        if (isEmptyString(password)) {
            dispatch(
                setMessage({
                    type: "warning",
                    message: "Please enter password.",
                })
            );
            return false;
        }

        return true;
    }

    function handleLogin() {
        validateForm &&
        dispatch(listingsLogin(userName, password)) //validate form data and trigger listingsLogin event using dispatch
            .then(() => {
                setLoading(true);
                // console.log('completed login submit')
                setLoading(false);
                navigator("/listings");
            })
            .catch((error) => {
                setLoading(false);
                // console.log("error Logging in", error)
                dispatch(
                    setMessage({
                        type: "error",
                        message: error,
                    })
                );
            });
    }

    function clearData() {
        setSocialLoginType("");
        setSocialRegistrationForm(false);
        setFirstName("");
        setLastName("");
        setUserId("");
        setIdType("");
        setIdNo("");
    }

    const fetchSocialPlatformUserAndLogin = (userId, socialLoginType) => {
        /**
         * Send social userId to server to check if Facebook/Google Id exists in credential table
         * If social Id exists in credential table in DB, return user data, set access token and set localStore user. Successful login.
         * If social Id does not exist, display Social Register form to capture user's detail:
         *                1.First name - preloaded since we have this from social media response
         *                2.Last name - preloaded since we have this from social media response
         *                3.ID type - This is required because ID_TYPE and ID_NUMBER are required fields in PERSONS DB table.
         *                4.ID Number
         *                5.Phone number
         */
        socialLoginType === "facebook"
            ? dispatch(fetchFacebookUser(userId))
                .then(() => {
                    setLoading(true);
                    clearData();
                    setLoading(false);
                    navigator("/listings");
                })
                .catch((error) => {
                    setLoading(false);
                    setSocialRegistrationForm(true); //Display Social Register form
                })
            : dispatch(fetchGoogleUser(userId))
                .then(() => {
                    setLoading(true);
                    clearData();
                    setLoading(false);
                    navigator("/listings");
                })
                .catch((error) => {
                    setLoading(false);
                    setSocialRegistrationForm(true); //Display Social Register form
                });
    };

    const responseFacebook = (response) => {
        // console.log(response)
        if (response.accessToken) {
            let names = response.name.split(" ");
            setFirstName(names[0]);
            setLastName(names[1]);
            setSocialLoginType("facebook");
            fetchSocialPlatformUserAndLogin(response.id, "facebook");
            setUserId(response.id);
            setEmail(response.email);
        } else {
            // console.log("FACEBOOK LOGIN FAILED!")
        }
    };

    function validateSocialRegistrationForm() {
        if (isEmptyString(firstName) || isNullUndefined(firstName)) {
            dispatch(
                setMessage({
                    type: "warning",
                    message: "First Name cannot be empty!",
                })
            );
            return false;
        }
        if (isEmptyString(lastName) || isNullUndefined(lastName)) {
            dispatch(
                setMessage({
                    type: "warning",
                    message: "Last Name cannot be empty!",
                })
            );
            return false;
        }
        if (isEmptyString(idType) || isNullUndefined(idType)) {
            dispatch(
                setMessage({
                    type: "warning",
                    message: "Select an ID Type!",
                })
            );
            return false;
        }

        if (isEmptyString(idNo) || isNullUndefined(idNo)) {
            dispatch(
                setMessage({
                    type: "warning",
                    message: "ID Number cannot be empty!",
                })
            );
            return false;
        }

        return true;
    }

    function submitSocialRegistration(type) {
        /**
         * Submit Data to create person and credential entry in the DB
         *If success response is returned, log the user in (successful login)
         */
        let sendData = {
            id: userId,
            firstName: firstName,
            lastName: lastName,
            idType: idType,
            idNumber: idNo,
            email: email,
            phoneNumber: phoneNumber,
            social: type,
        };

        validateSocialRegistrationForm() &&
        handleSocialRegistration(sendData)
            .then((response) => {
                fetchSocialPlatformUserAndLogin(userId, socialLoginType);
                dispatch(
                    setMessage({
                        type: response.type,
                        message: response.message,
                    })
                );
            })
            .catch((error) => {
                console.log("handleSocialRegistration error", error);
            });
    }

    const onTextChange = (event) => {
        let value = event.target.value;
        setUserName(value);
    };

    const onPasswordChange = (event) => {
        let value = event.target.value;
        setPassword(value);
    };

    function handleShowPassword() {
        setShowPassword(!showPassword);
    }

    return (
        <ResponsiveRow
            sx={{
                position: "relative",
                height: "100vh",
                width: "100%",
                padding: 0,
                margin: 0,
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Box
                sx={{
                    width: "100%",
                    height: "100%",
                    objectFit: "fill",
                    position: "absolute",
                    top: 0,
                    left: 0,
                }}
                component="img"
                src={bgImage}
                alt="listing sign in background"
            />
            <ResponsiveRow
                sx={{
                    height: "auto",
                    width: {xs: "90%", lg: "50%"},
                    backgroundColor: "#fff",
                    borderRadius: "20px",

                    flexDirection: "column",
                    justifyContent: "flex-end",
                    alignItems: "center",

                    padding: {xs: "20px", lg: "40px 20px 20px 20px"},
                    position: "relative",
                    zIndex: 5,
                    marginTop: "50px",
                }}
            >
                <Typography
                    sx={{
                        fontSize: {xs: "1.4rem", lg: "2rem"},
                        color: "#037171",
                        fontWeight: 900,
                        textAlign: "center",
                        marginBottom: 0,
                        paddingTop: "20px",
                    }}
                >
                    Welcome to Kodi Listings
                </Typography>
                <Box
                    sx={{
                        padding: {xs: "10px", lg: "20px 40px"},
                        textAlign: "center",
                        marginTop: 0,
                    }}
                >
                    <Typography variant="h6">
                        Please use your email and password to continue
                    </Typography>
                    <ResponsiveRow
                        sx={{
                            width: "100%",
                            justifyContent: "center",
                        }}
                    >
                        <Grid item sx={{width: "100%", marginBottom: "10px"}}>
                            <TextField
                                variant="outlined"
                                sx={{
                                    width: "90%",
                                    backgroundColor: "#efefef",
                                    borderRadius: "10px",
                                }}
                                label="Email"
                                type="email"
                                onChange={(event) => onTextChange(event)}
                            />
                        </Grid>{" "}
                        <Grid item sx={{width: "100%", marginBottom: "10px"}}>
                            <TextField
                                variant="outlined"
                                sx={{
                                    width: "90%",
                                    backgroundColor: "#efefef",
                                    borderRadius: "10px",
                                }}
                                label="Password"
                                type={showPassword ? "text" : "password"}
                                onChange={(event) => onPasswordChange(event)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {showPassword ? (
                                                <Visibility onClick={handleShowPassword}/>
                                            ) : (
                                                <VisibilityOff onClick={handleShowPassword}/>
                                            )}
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <ResponsiveRow
                            item
                            sx={{
                                width: "100%",
                                marginBottom: "10px",
                                justifyContent: "center",
                            }}
                        >
                            <FormControlLabel
                                sx={{fontWeight: 800, textAlign: "start", width: "90%"}}
                                label="Remember me"
                                control={
                                    <Checkbox
                                        value={remember}
                                        checked={remember}
                                        onChange={() => setRemember(!remember)}
                                        color="primary"
                                    />
                                }
                            />
                            <ResponsiveRow
                                sx={{
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    flexWrap: {xs: "wrap", lg: "nowrap"},
                                    width: "100%",
                                }}
                            >
                                <ResponsiveRow
                                    sx={{
                                        width: {xs: "100%", lg: "50%"},
                                        justifyContent: "flex-start",
                                        alignItems: "flex-start",
                                        flexDirection: {xs: "row", lg: "column"},
                                    }}
                                >
                                    <Box
                                        sx={{
                                            textAlign: {xs: "center", lg: "start"},
                                            width: "100%",
                                        }}
                                    >
                                        {" "}
                                        <Typography
                                            sx={{
                                                textDecoration: "none",
                                                fontWeight: 600,
                                                textAlign: {xs: "end", lg: "end"},
                                                marginBottom: "10px",
                                            }}
                                        >
                                            Don't have an account?{" "}
                                        </Typography>
                                    </Box>
                                    <Link
                                        href="/listings/register"
                                        sx={{
                                            textDecoration: "none",
                                            fontWeight: 800,
                                            textAlign: "start",
                                            width: "100%",
                                        }}
                                    >
                                        Click here to create one
                                    </Link>
                                    <Link
                                        href="/listings"
                                        sx={{
                                            textDecoration: "none",
                                            fontWeight: 800,
                                            color: "#037171",
                                        }}
                                    >
                                        Go back home
                                    </Link>
                                </ResponsiveRow>
                                <ResponsiveRow
                                    sx={{
                                        justifyContent: {xs: "center", lg: "flex-end"},
                                        flexDirection: {xs: "row", lg: "column"},
                                        padding: 0,
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            textDecoration: "none",
                                            fontWeight: 600,
                                            textAlign: {xs: "center", lg: "end"},
                                            marginBottom: "10px",
                                        }}
                                    >
                                        Sign-in with socials?
                                    </Typography>
                                    <ResponsiveRow
                                        sx={{
                                            justifyContent: {xs: "center", lg: "flex-end"},
                                            padding: 0,
                                        }}
                                    >
                                        <IconButton
                                            sx={{
                                                // padding: "10px",
                                                margin: "auto 10px",
                                            }}
                                        >
                                            <FacebookLogin
                                                appId="520609229497937"
                                                autoLoad={false}
                                                fields="name,email,picture"
                                                scope="public_profile,email"
                                                textButton={""}
                                                size="medium"
                                                icon={
                                                    <Facebook
                                                        sx={{
                                                            fontSize: "1.75rem",
                                                            padding: 0,
                                                            margin: "2px",
                                                        }}
                                                    />
                                                }
                                                callback={responseFacebook}
                                            />
                                            {/*<Facebook sx={{ color: "#3B5998", fontSize: "3rem" }} />*/}
                                        </IconButton>
                                        {/*<IconButton*/}
                                        {/*    sx={{*/}
                                        {/*    backgroundColor: "#DE5246",*/}

                                        {/*    borderRadius: "0",*/}
                                        {/*    padding: { xs: "15px", lg: "20px" },*/}
                                        {/*    margin: "auto 10px ",*/}
                                        {/*  }}*/}
                                        {/*  onClick={() => {*/}
                                        {/*      onSignIn()*/}
                                        {/*  }}*/}
                                        {/*>*/}
                                        {/*  <Google*/}
                                        {/*    sx={{*/}
                                        {/*      color: "#fff",*/}
                                        {/*      fontSize: "1.75rem",*/}
                                        {/*    }}*/}
                                        {/*  />*/}
                                        {/*</IconButton>*/}

                                        <div id="buttonDiv"></div>
                                    </ResponsiveRow>
                                </ResponsiveRow>
                            </ResponsiveRow>

                            <ResponsiveRow
                                sx={{
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    width: "100%",
                                    padding: 0,
                                    marginBottom: 0,
                                    marginTop: "10px",
                                }}
                            >
                                <ModalAcceptButton
                                    sx={{
                                        width: "100%",
                                        height: "auto",
                                    }}
                                    loading={loading}
                                    onClick={handleLogin}
                                >
                                    Sign In
                                </ModalAcceptButton>
                            </ResponsiveRow>
                        </ResponsiveRow>
                    </ResponsiveRow>
                </Box>
            </ResponsiveRow>

            {socialRegistrationForm && (
                <ModalContainer
                    open={socialRegistrationForm}
                    onClose={() => setSocialRegistrationForm(false)}
                >
                    <ModalInnerContainer
                        sx={{
                            maxHeight: {xs: "50hv", lg: "70vh"},
                            width: {xs: "80%", lg: "70%"},
                        }}
                    >
                        <Box
                            sx={{
                                padding: {xs: "10px", lg: "20px 40px"},
                                textAlign: "center",
                                marginTop: 0,
                            }}
                        >
                            <Typography
                                variant="h5"
                                sx={{color: "#037171", fontWeight: 600}}
                            >
                                Complete your registration by filling in this form
                            </Typography>
                            <ResponsiveRow
                                sx={{
                                    width: "100%",
                                    justifyContent: "center",
                                }}
                            >
                                {socialLoginType === "google" && (
                                    <Grid item sx={{width: "50%", marginBottom: "10px"}}>
                                        <TextField
                                            variant="outlined"
                                            sx={{
                                                width: "90%",
                                                backgroundColor: "#efefef",
                                                borderRadius: "10px",
                                            }}
                                            label="Email *"
                                            type="text"
                                            disabled
                                            value={email}
                                            onChange={(event) => {
                                                let value = event.target.value;
                                                setEmail(value);
                                            }}
                                        />
                                    </Grid>
                                )}
                                <Grid item sx={{width: "50%", marginBottom: "10px"}}>
                                    <TextField
                                        variant="outlined"
                                        sx={{
                                            width: "90%",
                                            backgroundColor: "#efefef",
                                            borderRadius: "10px",
                                        }}
                                        label="First name *"
                                        type="text"
                                        value={firstName}
                                        onChange={(event) => {
                                            let value = event.target.value;
                                            setFirstName(value);
                                        }}
                                    />
                                </Grid>{" "}
                                <Grid item sx={{width: "50%", marginBottom: "10px"}}>
                                    <TextField
                                        variant="outlined"
                                        sx={{
                                            width: "90%",
                                            backgroundColor: "#efefef",
                                            borderRadius: "10px",
                                        }}
                                        label="Last name *"
                                        type="text"
                                        value={lastName}
                                        onChange={(event) => {
                                            let value = event.target.value;
                                            setLastName(value);
                                        }}
                                    />
                                </Grid>{" "}
                                <Grid item sx={{width: "50%", marginBottom: "10px"}}>
                                    <TextField
                                        variant="outlined"
                                        sx={{
                                            width: "90%",
                                            backgroundColor: "#efefef",
                                            borderRadius: "10px",
                                        }}
                                        label="ID Type *"
                                        select
                                        value={idType}
                                        onChange={(event) => {
                                            let value = event.target.value;
                                            setIdType(value);
                                        }}
                                    >
                                        {idTypeOptions &&
                                            idTypeOptions.length > 0 &&
                                            idTypeOptions.map(function (idType, i) {
                                                return (
                                                    <MenuItem key={idType.value} value={idType.value}>
                                                        {idType.label}
                                                    </MenuItem>
                                                );
                                            }, this)}
                                    </TextField>
                                </Grid>{" "}
                                <Grid item sx={{width: "50%", marginBottom: "10px"}}>
                                    <TextField
                                        variant="outlined"
                                        sx={{
                                            width: "90%",
                                            backgroundColor: "#efefef",
                                            borderRadius: "10px",
                                        }}
                                        label="ID Number *"
                                        type="text"
                                        onChange={(event) => {
                                            let value = event.target.value;
                                            setIdNo(value);
                                        }}
                                    />
                                </Grid>{" "}
                                <Grid item sx={{width: "50%", marginBottom: "10px"}}>
                                    <TextField
                                        variant="outlined"
                                        sx={{
                                            width: "90%",
                                            backgroundColor: "#efefef",
                                            borderRadius: "10px",
                                        }}
                                        label="Phone Number"
                                        type="text"
                                        value={phoneNumber}
                                        onChange={(event) => {
                                            let value = event.target.value;
                                            setPhoneNumber(value);
                                        }}
                                    />
                                </Grid>{" "}
                            </ResponsiveRow>
                        </Box>

                        <ResponsiveRow
                            sx={{
                                width: "100%",
                                justifyContent: {xs: "center", lg: "flex-end"},
                            }}
                        >
                            <ModalAcceptButton
                                onClick={() => {
                                    submitSocialRegistration(socialLoginType);
                                }}
                                sx={{width: {xs: "100%", lg: "200px"}}}
                            >
                                Submit
                            </ModalAcceptButton>
                            <ModalCancelButton
                                onClick={() => setSocialRegistrationForm(false)}
                                sx={{width: {xs: "100%", lg: "200px"}}}
                            >
                                Cancel
                            </ModalCancelButton>
                        </ResponsiveRow>
                    </ModalInnerContainer>
                </ModalContainer>
            )}
        </ResponsiveRow>
    );
}
