import {FormControl, Typography} from "@mui/material"
import {ResponsiveRow} from "../../../constants/layout.constants"
import {useEffect, useState} from "react"
import {getUtilityIncomeVsExpense} from "../../../services/dashboard.service"
import LineChartsCard from "../../charts/LineChartCard"
import "dayjs/locale/en-gb"
import dayjs from "dayjs"
import {YearPickerInput} from "../../inputs/datePickerInput"
import {useSelector} from "react-redux";

/**
 *
 * REQUIRED PROPS:
 *
 * customWidth: string
 * customHeight: string
 * data: {
 *    incomeData: []
 *    expensesData: []
 *  }
 *
 *
 */

export default function UtilitiesIncomeVsExpenses({
                                                      customWidth,
                                                      customHeight,
                                                  }) {
    const [incomeExpenseData, setIncomeExpenseData] = useState({
        electricityIncome: null,
        electricityExpense: null,
        waterIncome: null,
        waterExpense: null,
    });
    const [loading, setLoading] = useState(false);
    const [year, setYear] = useState(new Date());
    const {user} = useSelector((state) => state.auth)
    useEffect(() => {
        setLoading(true);
        getUtilityIncomeVsExpense(year.getFullYear())
            .then((response) => {
                if (response && response.data) {
                    setIncomeExpenseData(response.data);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [year, user.proxyFacility]);

    return (
        <ResponsiveRow
            sx={{
                flexDirection: "column",
                flexWrap: "nowrap",
                height: {xs: "100%", lg: customHeight},
                minWidth: {xs: "100%", lg: customWidth},
                width: "100%",
                margin: "10px",
                backgroundColor: "#fff",
                borderRadius: "20px",
            }}
        >
            <ResponsiveRow
                sx={{
                    flexDirection: "row",
                    width: "100%",
                    height: "auto",
                    textAlign: "start",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
                <Typography sx={{width: "75%"}} variant="h5">
                    Utilities: Income vs Expenses
                </Typography>
                <FormControl sx={{width: {xs: "100%", lg: "25%"}}}>
                    <YearPickerInput
                        componentID="utilitiesIncomeVsExpenses"
                        sx={{width: {xs: "100%", lg: "90%"}, padding: "0 5px"}}
                        labelText="Select Year"
                        disableFuture={true}
                        disablePast={false}
                        value={dayjs(year)}
                        onSelectDate={newDate => setYear(new Date(newDate))}
                    />
                </FormControl>
            </ResponsiveRow>

            <ResponsiveRow
                sx={{
                    height: "80%",
                    width: "100%",

                    flexWrap: "nowrap",
                    flexDirection: "row",

                    alignItem: "center",
                    justifyContent: "flex-start",
                }}
            >
                <LineChartsCard
                    currentlyLoading={loading}
                    labelDataObj={{
                        dataLabels: [
                            "Jan",
                            "Feb",
                            "Mar",
                            "Apr",
                            "May",
                            "Jun",
                            "Jul",
                            "Aug",
                            "Sep",
                            "Oct",
                            "Nov",
                            "Dec",
                        ],
                        dataset: [
                            {
                                datasetLabel: "Electricity Income",
                                datasetData: incomeExpenseData.electricityIncome,
                            },
                            {
                                datasetLabel: "Electricity Expense",
                                datasetData: incomeExpenseData.electricityExpense,
                            },
                            {
                                datasetLabel: "Water Income",
                                datasetData: incomeExpenseData.waterIncome,
                            },
                            {
                                datasetLabel: "Water Expense",
                                datasetData: incomeExpenseData.waterExpense,
                            },
                        ],
                    }}
                />
            </ResponsiveRow>
        </ResponsiveRow>
    );
}
