import {Container, Typography} from "@mui/material"
import {Box} from "@mui/system"
import AdminProxyBar from "./bars/adminProxyBar"
import ManageUsersBar from "./bars/manageUsersBar"
import MeterBillingViewBar from "./bars/meterBillingViewBar"
import NavigationBar from "./bars/navigationBar"
import LeaseViewBar from "./bars/leaseViewBar"
import WorkOrderViewBar from "./bars/workOrderViewBar"
import JournalEntriesBar from "./bars/journalEntriesBar"
import BillingTabBar from "./bars/billingTabBar"
import BillingPaymentsTabBar from "./bars/billingPaymentsTabBar"
import BillingRemindersTabBar from "./bars/billingRemindersTabBar"
import PropertyComplexBar from "./bars/propertyComplexBar"
import PropertyBar from "./bars/propertyBar"

export default function BarComponents() {

    return (
        <Container disableGutters maxWidth="xxl">
            <Box
                sx={{
                    padding: "20px 15px",
                    width: "100%",
                    border: "1px #02CBCE solid",
                    borderRadius: "10px",
                    margin: "20px 0",
                }}
            >
                <Typography
                    variant="h4"
                    textAlign={"center"}
                    sx={{margin: "20px 0", color: "#037171"}}
                >
                    System Admin Navigation Bar
                </Typography>
                <NavigationBar/>
            </Box>
            <Box
                sx={{
                    padding: "20px 15px",
                    width: "100%",
                    border: "1px #02CBCE solid",
                    borderRadius: "10px",
                    margin: "20px 0",
                }}
            >
                <Typography
                    variant="h4"
                    textAlign={"center"}
                    sx={{margin: "20px 0", color: "#037171"}}
                >
                    System Admin Proxy Bar
                </Typography>
                <AdminProxyBar/>
            </Box>
            <Box
                sx={{
                    padding: "20px 15px",
                    width: "100%",
                    border: "1px #02CBCE solid",
                    borderRadius: "10px",
                    margin: "20px 0",
                }}
            >
                <Typography
                    variant="h4"
                    textAlign={"center"}
                    sx={{margin: "20px 0", color: "#037171"}}
                >
                    Manage Users Bar
                </Typography>
                <ManageUsersBar/>
            </Box>
            <Box
                sx={{
                    padding: "20px 15px",
                    width: "100%",
                    border: "1px #02CBCE solid",
                    borderRadius: "10px",
                    margin: "20px 0",
                }}
            >
                <Typography
                    variant="h4"
                    textAlign={"center"}
                    sx={{margin: "20px 0", color: "#037171"}}
                >
                    Meter Billing View Bar
                </Typography>
                <MeterBillingViewBar/>
            </Box>
            <Box
                sx={{
                    padding: "20px 15px",
                    width: "100%",
                    border: "1px #02CBCE solid",
                    borderRadius: "10px",
                    margin: "20px 0",
                }}
            >
                <Typography
                    variant="h4"
                    textAlign={"center"}
                    sx={{margin: "20px 0", color: "#037171"}}
                >
                    Lease View Bar
                </Typography>
                <LeaseViewBar/>
            </Box>
            <Box
                sx={{
                    padding: "20px 15px",
                    width: "100%",
                    border: "1px #02CBCE solid",
                    borderRadius: "10px",
                    margin: "20px 0",
                }}
            >
                <Typography
                    variant="h4"
                    textAlign={"center"}
                    sx={{margin: "20px 0", color: "#037171"}}
                >
                    Work Order View Bar
                </Typography>
                <WorkOrderViewBar/>
            </Box>
            <Box
                sx={{
                    padding: "20px 15px",
                    width: "100%",
                    border: "1px #02CBCE solid",
                    borderRadius: "10px",
                    margin: "20px 0",
                }}
            >
                <Typography
                    variant="h4"
                    textAlign={"center"}
                    sx={{margin: "20px 0", color: "#037171"}}
                >
                    Journal Entries Bar
                </Typography>
                <JournalEntriesBar/>
            </Box>
            <Box
                sx={{
                    padding: "20px 15px",
                    width: "100%",
                    border: "1px #02CBCE solid",
                    borderRadius: "10px",
                    backgroundColor: "#FFF",
                    margin: "20px 0",
                }}
            >
                <Typography
                    variant="h4"
                    textAlign={"center"}
                    sx={{margin: "20px 0", color: "#037171"}}
                >
                    Bills and Payment Bars
                </Typography>
                <BillingTabBar/>
                <BillingPaymentsTabBar/>
                <BillingRemindersTabBar/>
            </Box>
            <Box
                sx={{
                    padding: "20px 15px",
                    width: "100%",
                    border: "1px #02CBCE solid",
                    borderRadius: "10px",

                    margin: "20px 0",
                }}
            >
                <Typography
                    variant="h4"
                    textAlign={"center"}
                    sx={{margin: "20px 0", color: "#037171"}}
                >
                    Property Bars
                </Typography>
                <PropertyBar/>
                <PropertyComplexBar/>
            </Box>
        </Container>
    )
}
