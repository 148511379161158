/* eslint-disable no-unused-vars */
import { Add, Cached } from "@mui/icons-material"
import {
    FormControl,
    FormControlLabel,
    IconButton,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    Switch,
    Typography,
} from "@mui/material"
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers"
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs"
import dayjs from "dayjs"
import {useEffect, useState} from "react"
import {issueErrorMessage} from "../../../actions/message"
import {ResponsiveRow} from "../../../constants/layout.constants"
import {getCountries, getIdTypes, getRentDays, getSubscriptionPeriods,} from "../../../services/list.service"
import {fetchSubscription, saveSubscription,} from "../../../services/subscription.service"
import {isEmptyArray, isEmptyObject, isEmptyString, isNullUndefined,} from "../../../util/util"
import ModalContainerModule from "../../modules/modalContainer"
import PersonOrgSearch from "../personOrgSearch"
import ContractContactModal from "../contractContactModal"
import {personOrgSearch} from "../../../services/shared.service"
import AddNewUser from "../addNewUser"
import { fetchOrganization } from "../../../services/organisation.service";
import ConfirmInactiveModal from "./confirmInactive";

export default function SubscriptionAddEdit(props) {
	const [newSubscription, setNewSubscription] = useState(false);
	const [showSearch, setShowSearch] = useState(false);
	const [showForm, setShowForm] = useState(false);
	const [showAddPerson, setShowAddPerson] = useState(false);
	const [subscription, setSubscription] = useState(props.subscription);
	const [subscriber, setSubscriber] = useState(null);
	const [idTypeOptions, setIdTypeOptions] = useState([]);
	const [countriesList, setCountriesList] = useState([]);
	const [billDays, setBillDays] = useState([]);
	const [billingPeriods, setBillingPeriods] = useState([]);

	//subscriber organization contact properties
	const [idType, setIdType] = useState("");
	const [idNo, setIdNo] = useState("");
	const [showAddNewContact, setShowAddNewContact] = useState(false);
	const [foundContact, setFoundContact] = useState([]);
	const [contactModal, setContactModal] = useState(false);
	const [isContactSelect, setIsContactSelect] = useState(false);
	const [searchBy, setSearchBy] = useState("");
	const [companySearch, setCompanySearch] = useState("");
	const [inactive, setToInactive] = useState(false);
	const [showConfirmationModal, setShowConfirmationModal] = useState(false);
	const [sendTenantStatements, setSendTenantStatements] = useState(false);

	useEffect(() => {
		if (isEmptyObject(subscription) && isEmptyObject(subscriber)) {
			//show the search is this is a new subscription
			setShowSearch(true);
			setNewSubscription(true);
			setShowForm(false);
		} else if (
			!isEmptyObject(subscriber) &&
			!isEmptyObject(subscription) &&
			isEmptyString(subscription.id)
		) {
			//show the form as this is a new subscription with the subscriber filled out
			setShowSearch(false);
			setNewSubscription(true);
			setShowForm(true);
		} else {
			//fetch the subscription and set it locally
			fetchSubscription(subscription.id).then((response) => {
				if (!isNullUndefined(response)) {
					let data = response.data;
					setSubscriber(data.subscriber);
					setSubscription(data);

					setNewSubscription(false);
					setShowForm(true);
					setShowSearch(false);
				}
			});
		}
		getIdTypes().then((data) => setIdTypeOptions(data));
		getCountries().then((data) => setCountriesList(data));
		getRentDays().then((data) => setBillDays(data));
		getSubscriptionPeriods().then((data) => setBillingPeriods(data));
	}, []);

	const handlePersonOrgClose = () => {
		setShowSearch(false);
		if (isEmptyObject(subscriber)) {
			props.closeAddEdit();
		}
	};
	const handleContactClose = () => {
		setIsContactSelect(false);
		setSearchBy("");
		setIdType("");
		setIdNo("");
		setFoundContact([]);
		setContactModal(false);
		setShowAddNewContact(false);
		setShowForm(true);
	};

	const handleContactClear = () => {
		setIdType("");
		setIdNo("");
		setFoundContact([]);
	};

	const showAddNewContactForm = (idNoSearch, idTypeSearch) => {
		setIdType(idTypeSearch);
		setIdNo(idNoSearch);
		setShowAddPerson(true);
		setIsContactSelect(true);
	};

	const onTextChange = (event) => {
		const id = event.target.id;
		const value = event.target.value;

		if (id === "idNoSearch") {
			setIdNo(value);
		}
		if (id === "companySearch") {
			setCompanySearch(value);
		}
	};

	const searchPersonOrg = () => {
		let data = {
			searchType: searchBy,
			idType: idType,
			idNumber: idNo,
			organizationName: companySearch,
		};
		personOrgSearch(data).then((response) => {
			if (!isEmptyArray(response)) {
				setShowAddNewContact(false);
			} else {
				setShowAddNewContact(true);
			}
			setFoundContact(response);
		});
	};

	const handlePersonOrgSelect = (result) => {
		if (result.type === "Company") {
			// flesh out the organization to include contact details
			fetchOrganization(result.id.replace("_O", "")).then(
				(organization) => {
					if (!isNullUndefined(organization)) {
						setSubscriber(organization);
						setShowSearch(false);
						setShowForm(true);
					}
				}
			);
		} else {
			if (!isContactSelect) {
				setSubscriber(result);
				// setSubscription({}) why is this here? knjendu 21/09/23
			} else {
				//this is location of a contact for an organization
				// so set result to the subscriber as the contact
				setSubscriber((prev) => {
					const next = { ...prev };
					next.contact = result;
					return next;
				});
			}
			setShowSearch(false);
			setShowForm(true);
		}
	};

	const showAddNewUserForm = (idNoSearch, idTypeSearch) => {
		setShowSearch(false);
		setSubscription({
			active: true,
		});
		setSubscriber({
			id: null,
			firstName: "",
			middleName: "",
			lastName: "",
			mobilePhone: null,
			officePhone: null,
			email: "",
			idNumber: idNoSearch,
			idTypeCd: idTypeSearch,
			type: "Individual",
		});

		setShowForm(true);
	};

	const showAddNewOrgForm = (companySearch) => {
		setShowSearch(false);
		setSubscription({
			active: true,
		});
		setSubscriber({
			id: null,
			name: companySearch,
			description: "",
			taxId: "",
			address: {
				address1: "",
				address2: "",
				countryCd: "",
				city: "",
			},
			contact: {
				firstName: "",
				middleName: "",
				lastName: "",
				mobilePhone: null,
				officePhone: "",
				email: "",
				credentialId: "",
				idNumber: "",
				idTypeCd: "",
			},
			type: "Company",
		});
		setShowForm(true);
	};

	const handleAddUserClose = () => {
		setShowAddPerson(false);
	};

	const cancel = () => {
		setShowSearch(false);
		setShowAddPerson(false);
		setShowForm(false);
		setSubscription(null);
		setSubscriber(null);
		props.closeAddEdit();
	};

	const handleInactiveToggle = (event) => {
		if (event.target.checked) {
			setShowConfirmationModal(true);
		} else {
			setToInactive(false);
		}
	};

	const handleConfirmInactive = () => {
		setToInactive(true);
		setShowConfirmationModal(false);
	};

	const handleCancelInactive = () => {
		setToInactive(false);
		setShowConfirmationModal(false);
	};

	const saveOrUpdateSubscription = () => {
		if (inactive) {
			//We only get in here when a subscription is no longer using SmartKodi Services and SysAdmin sets this Subscription as inactive.
			let tmp = subscription;
			tmp.statusCd = "INA";
			tmp.active = false;
			tmp.sendTenantStatements = sendTenantStatements;
			setSubscription(tmp);
		}
		// Validate the subscription
		let sendData = {
			subscriber: subscriber,
			subscription: subscription,
		};
		if (validateSubscription(sendData)) {
			saveSubscription(sendData).then((response) => {
				cancel();
			});
		}
	};

	const validateSubscription = (sendData) => {
		if (isEmptyObject(sendData.subscriber)) {
			issueErrorMessage("No subscriber details found");
			return false;
		}
		if (isEmptyObject(sendData.subscription)) {
			issueErrorMessage("No subscription details found");
			return false;
		}
		if (sendData.subscriber.type === "Company") {
			if (isEmptyString(sendData.subscriber.name)) {
				issueErrorMessage("The Organization must have a name");
				return false;
			}
			if (isEmptyString(sendData.subscriber.address)) {
				issueErrorMessage("The Organization must have a Address");
				return false;
			}
			if (isEmptyString(sendData.subscriber.address.address1)) {
				issueErrorMessage("The Organization must have a Address 1");
				return false;
			}
			if (isEmptyString(sendData.subscriber.address.city)) {
				issueErrorMessage("The Organization must have a City");
				return false;
			}
			if (isEmptyString(sendData.subscriber.address.countryCd)) {
				issueErrorMessage("The Organization must have a Country");
				return false;
			}
			if (isEmptyObject(sendData.subscriber.contact)) {
				issueErrorMessage("The Organization must have a contact");
				return false;
			}
			if (isEmptyString(sendData.subscriber.contact.firstName)) {
				issueErrorMessage("The contact must have a First Name");
				return false;
			}
			if (isEmptyString(sendData.subscriber.contact.lastName)) {
				issueErrorMessage("The contact must have a Last Name");
				return false;
			}
			if (isEmptyString(sendData.subscriber.contact.mobilePhone)) {
				issueErrorMessage("The contact must have a Mobile Phone");
				return false;
			}
			if (isEmptyString(sendData.subscriber.contact.email)) {
				issueErrorMessage("The contact must have a Email");
				return false;
			}
			if (isEmptyString(sendData.subscriber.contact.idNumber)) {
				issueErrorMessage("The contact must have a ID Number");
				return false;
			}
			if (isEmptyString(sendData.subscriber.contact.idTypeCd)) {
				issueErrorMessage("The contact must have a ID Type");
				return false;
			}
		}

		if (sendData.subscriber.type === "Individual") {
			if (isEmptyString(sendData.subscriber.firstName)) {
				issueErrorMessage("The subscriber must have a First Name");
				return false;
			}
			if (isEmptyString(sendData.subscriber.lastName)) {
				issueErrorMessage("The subscriber must have a Last Name");
				return false;
			}
			if (isEmptyString(sendData.subscriber.mobilePhone)) {
				issueErrorMessage("The subscriber must have a Mobile Phone");
				return false;
			}
			if (isEmptyString(sendData.subscriber.email)) {
				issueErrorMessage("The subscriber must have a Email");
				return false;
			}
			if (isEmptyString(sendData.subscriber.idNumber)) {
				issueErrorMessage("The subscriber must have a ID Number");
				return false;
			}
			if (isEmptyString(sendData.subscriber.idTypeCd)) {
				issueErrorMessage("The subscriber must have a ID Type");
				return false;
			}
		}

		if (isEmptyString(sendData.subscription.billFrequency)) {
			issueErrorMessage("The subscription must have a billing frequency");
			return false;
		}
		if (
			isEmptyString(sendData.subscription.billDay) &&
			isEmptyString(sendData.subscription.billDate)
		) {
			issueErrorMessage(
				"The subscription must have a Bill Date or Bill Day"
			);
			return false;
		}
		return true;
	};

	const handleSubscriberChange = (e) => {
		if (
			subscriber &&
			subscriber.address &&
			Object.keys(subscriber.address).includes(e.target.name)
		) {
			setSubscriber({
				...subscriber,
				address: {
					...subscriber.address,
					[e.target.name]: e.target.value,
				},
			});
		} else if (
			subscriber &&
			subscriber.contact &&
			Object.keys(subscriber.contact).includes(e.target.name)
		) {
			setSubscriber({
				...subscriber,
				contact: {
					...subscriber.contact,
					[e.target.name]: e.target.value,
				},
			});
		} else {
			setSubscriber({
				...subscriber,
				[e.target.name]: e.target.value,
			});
		}
	};
	return (
		<>
			{/*PERSON/ORG SEARCH MODAL*/}
			<PersonOrgSearch
				contractType={"subscription"}
				showPersonOrgModal={showSearch}
				handlePersonOrgClose={handlePersonOrgClose}
				handlePersonOrgSelect={handlePersonOrgSelect}
				showAddNewUserForm={showAddNewUserForm}
				showAddNewOrgForm={showAddNewOrgForm}
			/>
			<ContractContactModal
				handleContactClose={handleContactClose}
				handlePersonOrgSelect={handlePersonOrgSelect}
				handleContactClear={handleContactClear}
				handleIdTypeChange={(event) => {
					setIdType(event.target.value);
				}}
				searchPersonOrg={searchPersonOrg}
				contactModal={contactModal}
				showAddNewContact={showAddNewContact}
				showAddNewContactForm={showAddNewContactForm}
				foundTenant={foundContact}
				idNoSearch={idNo}
				idTypes={idTypeOptions}
				onTextChange={onTextChange}
				selectedContract={subscription}
				contractType={"Subscription"}
			/>
			{/*ADD NEW USER MODAL*/}
			<AddNewUser
				openModal={showAddPerson}
				closeModal={handleAddUserClose}
				searchedIdType={idType}
				searchedIdNo={idNo}
				saveUser={handlePersonOrgSelect}
				organizationContact={true}
				customer={false}
			/>

			<ConfirmInactiveModal
				open={showConfirmationModal}
				onClose={handleCancelInactive}
				onConfirm={handleConfirmInactive}
				sendTenantStatements={sendTenantStatements}
				onSendTenantStatementsChange={(e) =>
					setSendTenantStatements(e.target.checked)
				}
			/>

			<ModalContainerModule
				size={"lg"}
				accept={saveOrUpdateSubscription}
				cancel={cancel}
				openModal={showForm}
				modalTitle={
					newSubscription ? "Add Subscription" : "Edit Subscription"
				}
				acceptButtonText={newSubscription ? "Create" : "Update"}
			>
				{subscriber && subscriber.type === "Individual" && (
					<ResponsiveRow container sx={{ height: "auto" }}>
						<Typography
							sx={{ marginBottom: "5px", width: "100%" }}
							id="vendors"
							variant="h6"
							component="h6"
						>
							Subscriber Information
						</Typography>
						<FormControl
							sx={{
								width: { xs: "100%", lg: "33%" },
								marginBottom: "10px",
							}}
						>
							<InputLabel>First Name *</InputLabel>
							<OutlinedInput
								label={"First Name"}
								sx={{ width: "90%" }}
								id={"firstName"}
								name={"firstName"}
								required={true}
								onChange={handleSubscriberChange}
								value={subscriber.firstName}
							/>
						</FormControl>
						<FormControl
							sx={{
								width: { xs: "100%", lg: "33%" },
								marginBottom: "10px",
							}}
						>
							<InputLabel>Middle Name</InputLabel>
							<OutlinedInput
								label={"Middle Name"}
								sx={{ width: "90%" }}
								id={"middleName"}
								name={"middleName"}
								onChange={handleSubscriberChange}
								value={subscriber?.middleName}
							/>
						</FormControl>
						<FormControl
							sx={{
								width: { xs: "100%", lg: "33%" },
								marginBottom: "10px",
							}}
						>
							<InputLabel>Last Name *</InputLabel>
							<OutlinedInput
								label={"Last Name"}
								sx={{ width: "90%" }}
								id={"lastName"}
								name={"lastName"}
								onChange={handleSubscriberChange}
								required={true}
								value={subscriber?.lastName}
							/>
						</FormControl>
						<FormControl
							sx={{
								width: { xs: "100%", lg: "33%" },
								marginBottom: "10px",
							}}
						>
							<InputLabel>
								Mobile Phone * (Primary Number)
							</InputLabel>
							<OutlinedInput
								label={"Mobile Phone * (Primary Number)"}
								sx={{ width: "90%" }}
								type="number"
								required={true}
								id={"mobilePhone"}
								name={"mobilePhone"}
								onChange={handleSubscriberChange}
								value={subscriber?.mobilePhone}
							/>
						</FormControl>
						<FormControl
							sx={{
								width: { xs: "100%", lg: "33%" },
								marginBottom: "10px",
							}}
						>
							<InputLabel>Office Phone</InputLabel>
							<OutlinedInput
								label={"Office Phone"}
								sx={{ width: "90%" }}
								id={"officePhone"}
								name={"officePhone"}
								onChange={handleSubscriberChange}
								value={subscriber?.officePhone}
							/>
						</FormControl>
						<FormControl
							sx={{
								width: { xs: "100%", lg: "33%" },
								marginBottom: "10px",
							}}
						>
							<InputLabel>Email *</InputLabel>
							<OutlinedInput
								label={"Email"}
								sx={{ width: "90%" }}
								required={true}
								id={"email"}
								name={"email"}
								onChange={handleSubscriberChange}
								value={subscriber?.email}
							/>
						</FormControl>
						<FormControl
							sx={{
								width: { xs: "100%", lg: "33%" },
								marginBottom: "10px",
							}}
						>
							<InputLabel>ID Type *</InputLabel>
							<Select
								label="ID Type"
								sx={{ width: "90%" }}
								id={"idType"}
								required={true}
								value={subscriber?.idTypeCd ?? ""}
								onChange={(event) => {
									setSubscriber((prev) => {
										const next = { ...prev };
										next.idTypeCd = event.target.value;
										return next;
									});
								}}
							>
								{idTypeOptions !== undefined &&
								idTypeOptions.length > 0 ? (
									idTypeOptions.map(function (idType, i) {
										return (
											<MenuItem
												key={i}
												value={idType.value}
											>
												{idType.label}
											</MenuItem>
										);
									}, this)
								) : (
									<MenuItem sx={{ width: "100%" }}>
										No Results Found
									</MenuItem>
								)}
							</Select>
						</FormControl>
						<FormControl
							sx={{
								width: { xs: "100%", lg: "33%" },
								marginBottom: "10px",
							}}
						>
							<InputLabel>ID Number *</InputLabel>
							<OutlinedInput
								label={"ID Number"}
								type={"number"}
								sx={{ width: "90%" }}
								required={true}
								id={"idNumber"}
								onChange={(event) => {
									setSubscriber((prev) => {
										const next = { ...prev };
										next.idNumber = event.target.value;
										return next;
									});
								}}
								value={subscriber?.idNumber}
							/>
						</FormControl>
					</ResponsiveRow>
				)}
				{subscriber && subscriber.type === "Company" && (
					<ResponsiveRow container sx={{ height: "auto" }}>
						<Typography
							sx={{
								marginBottom: "5vh",
								width: "100%",
								borderBottom: "1px solid #C0C0C0",
							}}
							id="vendors"
							variant="h6"
							component="h6"
						>
							Subscriber Information
						</Typography>
						<FormControl
							sx={{
								width: { xs: "100%", lg: "33%" },
								marginBottom: "10px",
							}}
						>
							<InputLabel sx={{ fontSize: ".9rem" }}>
								Name *
							</InputLabel>
							<OutlinedInput
								label={"Organization Name"}
								sx={{ width: "90%" }}
								required={true}
								id={"organizationName"}
								onChange={(event) => {
									setSubscriber((prev) => {
										let next = { ...prev };
										next.name = event.target.value;
										return next;
									});
								}}
								value={subscriber?.name}
								//
							/>
						</FormControl>
						<FormControl
							sx={{
								width: { xs: "100%", lg: "33%" },
								marginBottom: "10px",
							}}
						>
							<InputLabel sx={{ fontSize: ".9rem" }}>
								Address 1 *
							</InputLabel>
							<OutlinedInput
								label={"Address 1"}
								sx={{ width: "90%" }}
								id={"address1"}
								required={true}
								onChange={(event) => {
									setSubscriber((prev) => {
										const next = { ...prev };
										next.address.address1 =
											event.target.value;
										return next;
									});
								}}
								value={subscriber?.address?.address1}
								//
							/>
						</FormControl>
						<FormControl
							sx={{
								width: { xs: "100%", lg: "33%" },
								marginBottom: "10px",
							}}
						>
							<InputLabel sx={{ fontSize: ".9rem" }}>
								Address 2{" "}
							</InputLabel>
							<OutlinedInput
								label={"Address 2"}
								sx={{ width: "90%" }}
								id={"address2"}
								onChange={(event) => {
									setSubscriber((prev) => {
										const next = { ...prev };
										next.address.address2 =
											event.target.value;
										return next;
									});
								}}
								value={subscriber?.address?.address2}
								//
							/>
						</FormControl>
						<FormControl
							sx={{
								width: { xs: "100%", lg: "33%" },
								marginBottom: "10px",
							}}
						>
							<InputLabel sx={{ fontSize: ".9rem" }}>
								City *
							</InputLabel>
							<OutlinedInput
								label={"City"}
								sx={{ width: "90%" }}
								id={"city"}
								required={true}
								onChange={(event) => {
									setSubscriber((prev) => {
										const next = { ...prev };
										next.address.city = event.target.value;
										return next;
									});
								}}
								value={subscriber?.address?.city}
								//
							/>
						</FormControl>
						<FormControl
							sx={{
								width: { xs: "100%", lg: "33%" },
								marginBottom: "10px",
							}}
						>
							<InputLabel sx={{ fontSize: ".9rem" }}>
								Country *
							</InputLabel>
							<Select
								label="Country *"
								sx={{ width: "90%" }}
								id={"countryCd"}
								required={true}
								onChange={(event) => {
									setSubscriber((prev) => {
										const next = { ...prev };
										next.address.countryCd =
											event.target.value;
										return next;
									});
								}}
								value={subscriber?.address?.countryCd ?? ""}
							>
								{countriesList !== undefined &&
								countriesList.length > 0 ? (
									countriesList.map(function (idType, i) {
										return (
											<MenuItem
												key={i}
												value={idType.value}
											>
												{idType.label}
											</MenuItem>
										);
									}, this)
								) : (
									<MenuItem sx={{ width: "100%" }}>
										No Results Found
									</MenuItem>
								)}
							</Select>
						</FormControl>
						<FormControl
							sx={{
								width: { xs: "100%", lg: "33%" },
								marginBottom: "10px",
							}}
						>
							<InputLabel sx={{ fontSize: ".9rem" }}>
								Tax ID{" "}
							</InputLabel>
							<OutlinedInput
								label={"Tax ID"}
								sx={{ width: "90%" }}
								id={"taxId"}
								onChange={(event) => {
									setSubscriber((prev) => {
										const next = { ...prev };
										next.taxId = event.target.value;
										return next;
									});
								}}
								value={subscriber?.taxId}
								//
							/>
						</FormControl>
						<FormControl
							sx={{
								width: { xs: "100%", lg: "33%" },
								height: "60px",
								marginBottom: "10px",
								display: "flex",
								flexDirection: "row",
								flexWrap: "nowrap",
								alignItems: "center",
							}}
						>
							{(isNullUndefined(subscriber.contact) ||
								isNullUndefined(subscriber.contact.name)) && (
								<InputLabel sx={{ fontSize: ".9rem" }}>
									Organization Contact Person
								</InputLabel>
							)}
							<OutlinedInput
								label={
									!isNullUndefined(subscriber.contact) ||
									(!isNullUndefined(
										subscriber.contact.name
									) &&
										"Organization Contact Person")
								}
								sx={{ width: "80%" }}
								readOnly
								value={subscriber?.contact?.name}
								//
							/>

							<IconButton
								onClick={() => {
									// setShowSearch(true)
									setShowForm(false);
									setContactModal(true);
									setIsContactSelect(true);
									setSearchBy("Individual");
								}}
								sx={{
									width: "40px",
									height: "40px",
									margin: "0 10px",
									padding: "10px",
									backgroundColor: "#037171",
								}}
							>
								{isNullUndefined(subscriber.contact) ||
								isNullUndefined(subscriber.contact.name) ? (
									<Add sx={{ color: "white" }} />
								) : (
									<Cached sx={{ color: "white" }} />
								)}
							</IconButton>
						</FormControl>
						<FormControl
							sx={{
								width: { xs: "100%", lg: "66.6%" },
								marginBottom: "10px",
							}}
						>
							<InputLabel sx={{ fontSize: ".9rem" }}>
								Description
							</InputLabel>
							<OutlinedInput
								id="description"
								sx={{ width: "95%" }}
								label="Description"
								multiline
								rows={5}
								onChange={(event) => {
									setSubscriber((prev) => {
										const next = { ...prev };
										next.description = event.target.value;
										return next;
									});
								}}
								value={subscriber?.description}
								//
							/>
						</FormControl>
					</ResponsiveRow>
				)}
				{subscription && (
					<ResponsiveRow container sx={{ height: "auto" }}>
						<Typography
							sx={{
								marginBottom: "5vh",
								width: "100%",
								borderBottom: "1px solid #C0C0C0",
							}}
							id="vendors"
							variant="h6"
							component="h6"
						>
							Subscription Information
						</Typography>
						<FormControl
							sx={{
								width: { xs: "100%", lg: "20%" },
								marginBottom: "10px",
							}}
						>
							<InputLabel>Billing Frequency *</InputLabel>
							<Select
								label="Billing Frequency"
								sx={{ width: { xs: "100%", lg: "90%" } }}
								id={"billFrequency"}
								required={true}
								onChange={(event) => {
									setSubscription((prev) => {
										const next = { ...prev };
										next.billFrequency = event.target.value;
										return next;
									});
								}}
								value={subscription?.billFrequency ?? ""}
							>
								{billingPeriods !== undefined &&
								billingPeriods.length > 0 ? (
									billingPeriods.map(function (
										billingPeriod,
										i
									) {
										return (
											<MenuItem
												key={i}
												value={billingPeriod.value}
											>
												{billingPeriod.label}
											</MenuItem>
										);
									},
									this)
								) : (
									<MenuItem sx={{ width: "100%" }}>
										No Results Found
									</MenuItem>
								)}
							</Select>
						</FormControl>
						;
						{subscription?.billFrequency === "MNTH" && (
							<FormControl
								item
								sx={{
									width: { xs: "100%", lg: "20%" },
									marginBottom: "10px",
								}}
							>
								<InputLabel>Billing Day</InputLabel>
								<Select
									label="Bill Day"
									sx={{
										width: { xs: "100%", lg: "90%" },
									}}
									id={"billDay"}
									value={subscription?.billDay ?? ""}
									onChange={(event) => {
										setSubscription((prev) => {
											const next = { ...prev };
											next.billDay = event.target.value;
											return next;
										});
									}}
								>
									{billDays !== undefined &&
									billDays.length > 0 ? (
										billDays.map(function (weekDay, i) {
											return (
												<MenuItem
													key={i}
													value={weekDay.value}
												>
													{weekDay.label}
												</MenuItem>
											);
										}, this)
									) : (
										<MenuItem sx={{ width: "100%" }}>
											No Results Found
										</MenuItem>
									)}
								</Select>
							</FormControl>
						)}
						{subscription.billFrequency &&
							(subscription.billFrequency === "ANU" ||
								subscription.billFrequency === "QT") && (
								<FormControl
									item
									sx={{
										width: { xs: "100%", lg: "20%" },
										marginBottom: "10px",
									}}
								>
									<LocalizationProvider
										dateAdapter={AdapterDayjs}
										adapterLocale="en-gb"
									>
										<DatePicker
											label="Next Rent Due Date"
											sx={{
												width: {
													xs: "100%",
													lg: "90%",
												},
											}}
											id={"billDate"}
											value={dayjs(
												subscription?.billDate
											)}
											onChange={(value) => {
												setSubscription((prev) => {
													const next = {
														...prev,
													};
													next.billDate = new Date(
														value
													);
													return next;
												});
											}}
										/>
									</LocalizationProvider>
								</FormControl>
							)}
						<FormControlLabel
							sx={{
								width: { xs: "100%", lg: "15%" },
								marginBottom: "10px",
							}}
							label={
								subscription?.active
									? "Suspend Subscription"
									: "Activate Subscription"
							}
							control={
								<Switch
									checked={subscription?.active}
									onChange={(event) => {
										setSubscription((prev) => {
											const next = { ...prev };
											next.active = event.target.checked;
											return next;
										});
									}}
									inputProps={{ "aria-label": "controlled" }}
								/>
							}
						/>
						;
						{subscription && subscription.active && (
							<FormControl
								item
								sx={{
									width: { xs: "100%", lg: "20%" },
									marginBottom: "10px",
								}}
							>
								<LocalizationProvider
									dateAdapter={AdapterDayjs}
									adapterLocale="en-gb"
								>
									<DatePicker
										label="Skip Automatic Suspension Upto Date"
										sx={{
											width: { xs: "100%", lg: "90%" },
										}}
										id={"skipAutoSuspendUpToDate"}
										format="DD-MM-YYYY"
										value={dayjs(
											subscription?.skipAutoSuspendUpToDate
										)}
										onChange={(value) => {
											setSubscription((prev) => {
												const next = { ...prev };
												next.skipAutoSuspendUpToDate =
													new Date(value);
												return next;
											});
										}}
									/>
								</LocalizationProvider>
							</FormControl>
						)}
						{subscription &&
							subscription.statusCd !== "INA" &&
							props.task === "Edit" && ( //This Flag is rendered only when Updating a subscription details.
								<FormControlLabel
									sx={{
										width: { xs: "100%", lg: "15%" },
										marginBottom: "10px",
									}}
									label={"Set As Inactive"}
									control={
										<Switch
											checked={inactive}
											onChange={handleInactiveToggle}
											inputProps={{
												"aria-label": "controlled",
											}}
										/>
									}
								/>
							)}
					</ResponsiveRow>
				)}
			</ModalContainerModule>
		</>
	);
}
