import {Alert, FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {isEmptyArray, isNullUndefined} from "../../util/util";
import ModalContainerModule from "../modules/modalContainer";
import { ResponsiveRow } from "../../constants/layout.constants";
import Permit from "../../util/permit";

/**
 * This is a shared Confirmation component
 *
 * Required props are:
 * - showConfirmationModal: This is either true or false to show or hide modal respectively
 * - handleConfirmationClose: This is a function to close the modal ie set showConfirmationModal to false
 * - title: This is the title to display
 * - severity: Either warning, info or error
 * - body: This is the body message
 * - positiveText: Text for positive button
 * - negativeText: Text for negative button
 * - positiveAction: Action for positive button
 * - negativeAction: Action for negative button
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 * @author jmbuthia
 *  @description Confirmation Modal
 */

export default function ConfirmationModal(props) {
  const invoiceTypeOptions = [
    { label: "Utility Charge Invoice", value: "UTL" },
    { label: "Service Charge Invoice", value: "SERCH" },
    { label: "Capital Fund Invoice", value: "CAPF" },
  ]
  return (
    <ModalContainerModule
      size="specialConfirmationModal"
      accept={props.positiveAction}
      cancel={props.negativeAction}
      openModal={props.showConfirmationModal}
      modalTitle={props.title}
      acceptDisabled={!isNullUndefined(props.invoiceTypes) && isEmptyArray(props.invoiceTypes)}
      acceptButtonText={props.positiveText}
      cancelText={props.negativeText}
    >
      <ResponsiveRow sx={{ height: "auto", width: "100%" }}>
        {/*Only appears when deleting a bank account*/}
        {!isNullUndefined(props.linkedFacilities) && (
          <p>
            Deleting this account will remove it as the payment option for the
            facilities below:
            <ul>
              {props.linkedFacilities.map((facility) => {
                return <li>{facility}</li>;
              })}
            </ul>
          </p>
        )}

        <Alert sx={{ width: "100%" }} severity={props.severity}>
          {props.body}
        </Alert>

        <Permit services="HOA">
          <ResponsiveRow sx={{ height: "auto", width: "100%" }}>
          <Alert sx={{ width: "100%" }} severity="info">
            Select types of invoices to be resend.
          </Alert>
        </ResponsiveRow>
        </Permit>
          <Permit services="HOA">
            <ResponsiveRow sx={{ height: "auto", width: "100%" }}>
          <FormControl
              item
              sx={{
                width: { xs: "100%", md: "100%" },
                marginBottom: "10px",
              }}
          >
            <InputLabel>Invoice Types</InputLabel>
            <Select
                label="Invoice Types"
                sx={{ width: { xs: "100%", lg: "90%" } }}
                id={"invoiceTypes"}
                multiple
                value={props.invoiceTypes}
                onChange={(event) => {
                  props.setInvoiceTypes(event.target.value)
                }}
            >
              {invoiceTypeOptions && invoiceTypeOptions.length > 0 ? (
                  invoiceTypeOptions.map(function (invoiceType, i) {
                    return (
                        <MenuItem
                            key={invoiceType.value}
                            value={invoiceType.value}
                        >
                          {invoiceType.label}
                        </MenuItem>
                    );
                  }, this)
              ) : (
                  <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
              )}
            </Select>
          </FormControl>
            </ResponsiveRow>
          </Permit>
      </ResponsiveRow>
    </ModalContainerModule>
  );
}
