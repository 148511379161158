//this shall also hold the transfers functionality
import {useEffect, useState} from "react";
import {Box, Typography} from "@mui/material/index";
import {
    checkIfClientHasMPESA2Facilities,
    listMobileMoneyProviders,
    loadMobileMoneyReconciliations,
    mobileMoneyReconciliation,
    totalMobileMoneyReconciliations,
} from "../../services/mobileMoneyReconciliation.service";
import {formatDate, isEmptyString, isNullUndefined} from "../../util/util";
import Link from "@mui/material/Link/index";
import {useSelector} from "react-redux";
import {MainContainer, TopBarContainedBrandButton, TopBarContainer,} from "../../constants/component.constants";
import {ResponsiveRow} from "../../constants/layout.constants";
import UploadReconciliationModal from "../../components/modals/mobileMoney/reconciliation/UploadReconciliationModal";
import {downloadByDocumentId} from "../../services/shared.service";
import GenerateReport from "../../components/modals/generateReport";
import {issueResponseMessage, issueWarnMessage} from "../../actions/message";
import MuiDataGrid from "../../components/modules/MuiDataGrid";

export default function MobileMoney() {
    const user = useSelector((state) => state.auth.user);
    const [mobileMoneyProvidersList, setMobileMoneyProvidersList] = useState([]);
    const [selectedMobileMoneyProvider, setSelectedMobileMoneyProvider] =
        useState("");
    const [bulkFile, setBulkFile] = useState(null);
    const [bulkFileProgress, setBulkFileProgress] = useState(0);
    const [mobileMoneyReconciliationsList, setMobileMoneyReconciliationsList] =
        useState([]);
    const [total, setTotal] = useState(0);
    const [paginationModel, setPaginationModel] = useState({
        pageCount: 25,
        page: 0,
    });
    const [reconciliationForm, setReconciliationForm] = useState(false);
    const [loading, setLoading] = useState(true);
    const [reportsModal, setReportsModal] = useState();
    const [reportType, setReportType] = useState();
    const [clientHasMPESA2Facilities, setClientHasMPESA2Facilities] =
        useState(false);

    useEffect(() => {
        setLoading(true);
        listMobileMoneyProviders().then((data) =>
            setMobileMoneyProvidersList(data)
        );
        loadMobileMoneyReconciliations(
            paginationModel.page | 0,
            paginationModel.pageCount | 25
        ).then((data) => setMobileMoneyReconciliationsList(data.items));
        totalMobileMoneyReconciliations()
            .then((data) => setTotal(data.total))
            .finally(() => {
                setLoading(false);
            });
        checkIfClientHasMPESA2Facilities().then((data) => {
            setClientHasMPESA2Facilities(data.clientHasMPESA2Subscriptions);
        });
    }, [paginationModel.page, paginationModel.pageCount, user.proxyFacility]);

    function fileValueChanged(event) {
        setBulkFile(event.target.files[0]);
    }

    function handleUploadReconciliationClicked() {
        setReconciliationForm(true);
    }

    function downloadClientStatementClicked() {
        setReportType("Client Mobile Money Statement");
        setReportsModal(true);
    }

    function handleCancelClicked() {
        setReconciliationForm(false);
        setSelectedMobileMoneyProvider("");
        setBulkFile(null);
        setBulkFileProgress(0);
    }

    const columns = [
        {
            headerName: "Date ",
            field: "date",
            width: 100,
            valueFormatter: (row) => {
                return formatDate(row.value);
            },
        },
        {
            headerName: "Reconciled By",
            field: "reconciledBy",
            minWidth: 250,
            flex: 2.5,
        },
        {headerName: "Paybill", field: "paybill", minWidth: 200, flex: 2},
        {
            headerName: "Mpesa Statement",
            field: "mpesaStatement",
            minWidth: 400,
            flex: 4,
            renderCell: (params) => {
                return (
                    <Link
                        target="_blank"
                        onClick={() => downloadByDocumentId(params.value.id)}
                    >
                        {params.value.fileName}
                    </Link>
                );
            },
        },
        {
            headerName: "Mpesa Reconciliation Report",
            field: "mpesaReconReport",
            minWidth: 400,
            flex: 4,
            renderCell: (params) => {
                return (
                    <Link
                        target="_blank"
                        onClick={() => downloadByDocumentId(params.value.id)}
                    >
                        {params.value.fileName}
                    </Link>
                );
            },
        },
    ];

    function validateForm() {
        if (isNullUndefined(bulkFile)) {
            issueWarnMessage(
                "Could not determine uploaded file. Please Upload a file first."
            );
            return false;
        }

        if (isEmptyString(selectedMobileMoneyProvider)) {
            issueWarnMessage("Please select a mobile money provider.");
            return false;
        }

        return true;
    }

    function handleMobileMoneyReconciliation() {
        let uploadData = new FormData();
        uploadData.append("file", bulkFile);
        uploadData.append("mmProvider", selectedMobileMoneyProvider);
        if (validateForm()) {
            mobileMoneyReconciliation(uploadData).then((response) => {
                issueResponseMessage(response);
                loadMobileMoneyReconciliations(
                    paginationModel.page | 0,
                    paginationModel.pageCount | 25
                ).then((data) => {
                    if (data.items !== undefined)
                        setMobileMoneyReconciliationsList(data.items);
                });
                totalMobileMoneyReconciliations().then((data) => setTotal(data.total));
                handleCancelClicked();
            });
        }
    }

    return (
        <MainContainer>
            <TopBarContainer
                container
                sx={{
                    width: {xs: "100%", lg: "80%"},
                    height: "auto",
                }}
            >
                <ResponsiveRow
                    item
                    sx={{
                        width: {xs: "100%", lg: "50%"},
                        justifyContent: {xs: "center", lg: "flex-start"},
                        height: "auto",
                    }}
                >
                    <Typography
                        sx={{textAlign: "left"}}
                        id="mobileMoneyReconciliation"
                        variant="h6"
                        component="h6"
                    >
                        Mobile Money Reconciliation
                    </Typography>
                </ResponsiveRow>
                <ResponsiveRow
                    item
                    sx={{
                        width: {xs: "100%", lg: "50%"},
                        justifyContent: {xs: "center", lg: "flex-end"},
                        padding: 0,
                        height: "auto",
                    }}
                >
                    <TopBarContainedBrandButton
                        variant="contained"
                        onClick={handleUploadReconciliationClicked}
                        sx={{
                            width: {xs: "100%", lg: "auto"},
                            height: "50px",
                            backgroundColor: "#037171",
                            margin: {md: "0 1vh", xs: "auto"},
                            fontSize: "12px",
                            float: "right",
                            "&:hover": {
                                backgroundColor: "#FFF",
                                color: "#037171",
                            },
                        }}
                    >
                        Upload Reconciliation
                    </TopBarContainedBrandButton>

                    {/*This Button to generate client's mobile money reconciliation statement will be displayed only if client has facilities with MPESA2 subscription service*/}
                    {clientHasMPESA2Facilities && (
                        <TopBarContainedBrandButton
                            variant="contained"
                            onClick={downloadClientStatementClicked}
                            sx={{
                                width: {xs: "100%", lg: "auto"},
                                height: "50px",
                                backgroundColor: "#037171",
                                margin: {md: "0 1vh", xs: "auto"},
                                fontSize: "12px",
                                float: "right",
                                "&:hover": {
                                    backgroundColor: "#FFF",
                                    color: "#037171",
                                },
                            }}
                        >
                            Client Reconciliation Statement
                        </TopBarContainedBrandButton>
                    )}
                </ResponsiveRow>
            </TopBarContainer>
            <br/>
            {reconciliationForm && (
                <UploadReconciliationModal
                    handleMobileMoneyReconciliation={handleMobileMoneyReconciliation}
                    handleCancelClicked={handleCancelClicked}
                    reconciliationForm={reconciliationForm}
                    selectedMobileMoneyProvider={selectedMobileMoneyProvider}
                    setSelectedMobileMoneyProvider={setSelectedMobileMoneyProvider}
                    mobileMoneyProvidersList={mobileMoneyProvidersList}
                    bulkFile={bulkFile}
                    bulkFileProgress={bulkFileProgress}
                    fileValueChanged={fileValueChanged}
                />
            )}

            <Box sx={{height: "65vh"}}>
                {/* <StyledDataGrid
          autoHeight
          rows={mobileMoneyReconciliationsList}
          columns={columns}
          loading={loading}
          pagination
          rowCount={total}
		  initialState={{
			  pagination: {
				  paginationModel: { pageSize: paginationModel.pageCount, pageNo: paginationModel.page },
			  },
		  }}
		  paginationModel={paginationModel}
		  onPaginationModelChange={setPaginationModel}
          paginationMode="server"
          components={{
            LoadingOverlay: LinearProgress,
            NoRowsOverlay: EmptyOverlay,
          }}
        /> */}

                <MuiDataGrid
                    noSelection={true}
                    dataGridColumns={columns}
                    dataGridRows={mobileMoneyReconciliationsList}
                    handlePageLoad={setPaginationModel}
                    serverPagination={true}
                    loading={loading}
                    height="60vh"
                    totalRows={total}
                />
            </Box>
            {reportsModal && (
                <GenerateReport
                    showModal={reportsModal}
                    closeModal={() => {
                        setReportsModal(false);
                        setReportType("");
                    }}
                    report={reportType}
                />
            )}
        </MainContainer>
    );
}
