import { Box } from "@mui/system";
import {
  AccessTime,
  MoveToInbox,
  RunningWithErrors,
  Settings,
  TaskAlt,
} from "@mui/icons-material";

import { ResponsiveRow } from "../../../constants/layout.constants";
import { Button, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import { getWorkOrderTile } from "../../../services/dashboard.service";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

/**
 *
 * REQUIRED PROPS:
 *  data: {
 *    requestedWorkOrders: number
 *    pendingWorkOrders: number
 *    inProgressWorkOrders: number
 *    overDueWorkOrders: number
 *    completedWorkOrders: number
 *  }
 *
 */

export default function WorkOrderStatusBar() {
  const router = useNavigate();
  const user = useSelector((state) => state.auth.user);

  const [workOrderStatsData, setWorkOrderStatsData] = useState({
    requestedWorkOrders: 0,
    pendingWorkOrders: 0,
    inProgressWorkOrders: 0,
    overDueWorkOrders: 0,
    completedWorkOrders: 0,
    totalWorkOrders: 0,
  });

  function handleViewWorkOrders(viewType) {
    switch (viewType) {
      case "RequestedOrders":
        router("/workOrders?status=requested");
        break;
      case "PendingOrders":
        router("/workOrders?status=pending");
        break;
      case "OrdersInProgress":
        router("/workOrders?status=in-progress");
        break;
      case "OverdueOrders":
        router("/workOrders?status=overdue");
        break;
      case "CompletedOrders":
        router("/workOrders?status=completed");
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    getWorkOrderTile().then((response) => {
      if (response && response.data) {
        setWorkOrderStatsData(response.data);
      }
    });

    return () => {
      setWorkOrderStatsData({
        requestedWorkOrders: 0,
        pendingWorkOrders: 0,
        inProgressWorkOrders: 0,
        overDueWorkOrders: 0,
        completedWorkOrders: 0,
        totalWorkOrders: 0,
      });
    };
  }, [user.proxyFacility]);

  return (
    <ResponsiveRow
      sx={{
        flexDirection: { xs: "column", lg: "row" },
        flexWrap: "nowrap",

        width: "100%",
        height: { xs: "auto", lg: "125px" },

        justifyContent: "space-around",

        overflowY: "hidden",
      }}
    >
      <ResponsiveRow
        component={Button}
        onClick={() => handleViewWorkOrders("RequestedOrders")}
        sx={{
          textTransform: "capitalize",
          marginBottom: { xs: "10px", lg: "auto" },
          width: { xs: "100%", lg: "19%" },
          height: "100px",

          position: "relative",
          overflow: "hidden",

          justifyContent: "flex-start",
          backgroundColor: "#fff",
          borderRadius: "20px",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            zIndex: "8",
            left: 10,
            top: 0,

            height: "100%",
            width: "auto",
          }}
        >
          <MoveToInbox
            sx={{
              margin: 0,
              padding: 0,
              height: "100%",
              width: "100%",
              color: "rgba(0, 159, 147, .1)",
            }}
          />
        </Box>
        <ResponsiveRow
          sx={{
            position: "relative",
            zIndex: "10",
            flexDirection: "column-reverse",
            flexWrap: "nowrap",

            justifyContent: "center",

            width: "100%",
            height: "100%",
            padding: "20px 10px",
            margin: "auto auto 10px auto",
          }}
        >
          <Typography
            sx={{
              fontSize: "1.75rem",
              textAlign: "end",
              width: "100%",
              height: "auto",
            }}
            color="#037171"
            fontWeight={900}
          >
            {workOrderStatsData.requestedWorkOrders}
          </Typography>
          <Typography
            sx={{
              fontSize: "1rem",
              textAlign: "start",
              height: "65px",
              width: "100%",
            }}
            fontWeight={800}
          >
            Requested Work Orders
          </Typography>
        </ResponsiveRow>
      </ResponsiveRow>

      <ResponsiveRow
        component={Button}
        onClick={() => handleViewWorkOrders("PendingOrders")}
        sx={{
          textTransform: "capitalize",
          marginBottom: { xs: "10px", lg: "auto" },

          width: { xs: "100%", lg: "19%" },
          height: "100px",

          position: "relative",
          overflow: "hidden",

          justifyContent: "flex-start",
          alignItems: "flex-end",

          backgroundColor: "#fff",
          borderRadius: "20px",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            zIndex: "8",
            left: 10,
            top: 0,

            height: "100%",
            width: "auto",
          }}
        >
          <AccessTime
            sx={{
              margin: 0,
              padding: 0,
              height: "100%",
              width: "100%",
              color: "rgba(0, 159, 147, .1)",
            }}
          />
        </Box>
        <ResponsiveRow
          sx={{
            position: "relative",
            zIndex: "10",
            flexDirection: "column-reverse",
            flexWrap: "nowrap",

            justifyContent: "center",

            width: "100%",
            height: "100%",
            padding: "20px 10px",
            margin: "auto auto 10px auto",
          }}
        >
          <Typography
            sx={{
              fontSize: "2rem",
              textAlign: "end",
              width: "100%",
              height: "auto",
            }}
            color="#037171"
            fontWeight={900}
          >
            {workOrderStatsData.pendingWorkOrders}
          </Typography>
          <Typography
            sx={{
              fontSize: "1rem",
              textAlign: "start",
              height: "65px",
              width: "100%",
            }}
            fontWeight={800}
          >
            Pending Work Orders
          </Typography>
        </ResponsiveRow>
      </ResponsiveRow>

      <ResponsiveRow
        component={Button}
        onClick={() => handleViewWorkOrders("OrdersInProgress")}
        sx={{
          textTransform: "capitalize",
          marginBottom: { xs: "10px", lg: "auto" },

          width: { xs: "100%", lg: "19%" },
          height: "100px",

          position: "relative",
          overflow: "hidden",

          justifyContent: "flex-start",
          backgroundColor: "#fff",
          borderRadius: "20px",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            zIndex: "8",
            left: 10,
            top: 0,

            height: "100%",
            width: "auto",
          }}
        >
          <Settings
            sx={{
              margin: 0,
              padding: 0,
              height: "100%",
              width: "100%",
              color: "rgba(0, 159, 147, .1)",
            }}
          />
        </Box>
        <ResponsiveRow
          sx={{
            position: "relative",
            zIndex: "10",
            flexDirection: "column-reverse",
            flexWrap: "nowrap",

            justifyContent: "center",

            width: "100%",
            height: "100%",
            padding: "20px 10px",
            margin: "auto auto 10px auto",
          }}
        >
          <Typography
            sx={{
              fontSize: "2rem",
              textAlign: "end",
              width: "100%",
              height: "auto",
            }}
            color="#037171"
            fontWeight={900}
          >
            {workOrderStatsData.inProgressWorkOrders}
          </Typography>
          <Typography
            sx={{
              fontSize: "1rem",
              textAlign: "start",
              height: "65px",
              width: "100%",
            }}
            fontWeight={800}
          >
            Work Orders in Progress
          </Typography>
        </ResponsiveRow>
      </ResponsiveRow>

      <ResponsiveRow
        component={Button}
        onClick={() => handleViewWorkOrders("OverdueOrders")}
        sx={{
          textTransform: "capitalize",
          marginBottom: { xs: "10px", lg: "auto" },

          width: { xs: "100%", lg: "19%" },
          height: "100px",

          position: "relative",
          overflow: "hidden",

          justifyContent: "flex-start",
          backgroundColor: "#fff",
          borderRadius: "20px",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            zIndex: "8",
            left: 10,
            top: 0,

            height: "100%",
            width: "auto",
          }}
        >
          <RunningWithErrors
            sx={{
              margin: 0,
              padding: 0,
              height: "100%",
              width: "100%",
              color: "rgba(0, 159, 147, .1)",
            }}
          />
        </Box>
        <ResponsiveRow
          sx={{
            position: "relative",
            zIndex: "10",
            flexDirection: "column-reverse",
            flexWrap: "nowrap",

            justifyContent: "center",

            width: "100%",
            height: "100%",
            padding: "20px 10px",
            margin: "auto auto 10px auto",
          }}
        >
          <Typography
            sx={{
              fontSize: "2rem",
              textAlign: "end",
              width: "100%",
              height: "auto",
            }}
            color="#037171"
            fontWeight={900}
          >
            {workOrderStatsData.overDueWorkOrders}
          </Typography>
          <Typography
            sx={{
              fontSize: "1rem",
              textAlign: "start",
              height: "65px",
              width: "100%",
            }}
            fontWeight={800}
          >
            Overdue Work Orders
          </Typography>
        </ResponsiveRow>
      </ResponsiveRow>

      <ResponsiveRow
        component={Button}
        onClick={() => handleViewWorkOrders("CompletedOrders")}
        sx={{
          textTransform: "capitalize",
          marginBottom: { xs: "10px", lg: "auto" },

          width: { xs: "100%", lg: "19%" },
          height: "100px",

          position: "relative",
          overflow: "hidden",

          justifyContent: "flex-start",
          backgroundColor: "#fff",
          borderRadius: "20px",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            zIndex: "8",
            left: 10,
            top: 0,

            height: "100%",
            width: "auto",
          }}
        >
          <TaskAlt
            sx={{
              margin: 0,
              padding: 0,
              height: "100%",
              width: "100%",
              color: "rgba(0, 159, 147, .1)",
            }}
          />
        </Box>
        <ResponsiveRow
          sx={{
            position: "relative",
            zIndex: "10",
            flexDirection: "column-reverse",
            flexWrap: "nowrap",

            justifyContent: "center",

            width: "100%",
            height: "100%",
            padding: "20px 10px",
            margin: "auto auto 10px auto",
          }}
        >
          <Typography
            sx={{
              fontSize: "2rem",
              textAlign: "end",
              width: "100%",
              height: "auto",
            }}
            color="#037171"
            fontWeight={900}
          >
            {workOrderStatsData.completedWorkOrders}
          </Typography>
          <Typography
            sx={{
              fontSize: "1rem",
              textAlign: "start",
              height: "65px",
              width: "100%",
            }}
            fontWeight={800}
          >
            Completed Work Orders
          </Typography>
        </ResponsiveRow>
      </ResponsiveRow>
    </ResponsiveRow>
  );
}
