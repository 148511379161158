import {useEffect, useState} from "react"
import {useSelector} from "react-redux"
import {Alert, AlertTitle, Grid, Switch, Typography} from "@mui/material"
import {formatCurrency, isEmptyString, isNullUndefined, titleCase,} from "../../util/util"
import WorkOrderVendorCharge from "./workOrderVendorCharge"
import {issueResponseMessage, issueWarnMessage} from "../../actions/message"
import {createBillPaymentData} from "../../services/workOrder.service"
import {ResponsiveRow} from "../../constants/layout.constants"
import ModalContainerModule from "../../components/modules/modalContainer"

export default function CreateBillPaymentForm(props) {
    const appMessage = useSelector((state) => state.message)
    const [totalToBill, setTotalToBill] = useState(0.0)
    const [issuePaymentVoucher, setIssuePaymentVoucher] = useState(false)
    const [itemsToBill, setItemsToBill] = useState([])
    let billDetailMessage
    if (isNullUndefined(props.selectedWorkOrder.appliedToFull)) {
        billDetailMessage = "Unable to determine what this work order pertains to"
    } else {
        billDetailMessage =
            "This Bill/Payment pertains to " +
            props.selectedWorkOrder.appliedToFull +
            "."
    }

    function issuePaymentVoucherToggle(event) {
        setIssuePaymentVoucher(event.target.checked)
    }

    function itemsToBillChanged(itemsToBill) {
        let total = 0
        itemsToBill &&
        itemsToBill.length > 0 &&
        itemsToBill.forEach(function (vendorItem, i) {
            props.selectedWorkOrder.vendors &&
            props.selectedWorkOrder.vendors.length > 0 &&
            props.selectedWorkOrder.vendors.forEach(function (vendorMain, i) {
                if (vendorItem.vendorId === vendorMain.vendorId) {
                    vendorItem.charges &&
                    vendorItem.charges.length > 0 &&
                    vendorItem.charges.forEach(function (chargeId, i) {
                        vendorMain.charges &&
                        vendorMain.charges.length > 0 &&
                        vendorMain.charges.forEach(function (charge, i) {
                            if (chargeId === charge.id) {
                                total =
                                    total + parseFloat(charge.total.replaceAll(",", ""))
                            }
                        })
                    })
                }
            })
        })

        setTotalToBill(total)
        setItemsToBill(itemsToBill)
    }

    useEffect(() => {
        if (
            !isNullUndefined(props.selectedWorkOrder) &&
            !isNullUndefined(props.selectedWorkOrder.id)
        ) {
            let items = []
            props.selectedWorkOrder.vendors &&
            props.selectedWorkOrder.vendors.length > 0 &&
            props.selectedWorkOrder.vendors.forEach(function (vendor, i) {
                items.push({
                    vendorName: vendor.vendorName,
                    vendorId: vendor.vendorId,
                    paymentMethodCd: null,
                    chequeNumber: null,
                    bankRefNumber: null,
                    cashAccount: null,
                    paymentAmount: null,
                    charges: [],
                })
            })
            setItemsToBill(items)
        }
    }, [props.selectedWorkOrder])

    function valid() {
        if (
            isNullUndefined(props.selectedWorkOrder) ||
            isNullUndefined(props.selectedWorkOrder.id) ||
            isEmptyString(props.selectedWorkOrder.id)
        ) {
            issueWarnMessage("Couldn't determine the identifier for cancelling the work order. Try refresh the page.")
            return false
        }
        if (itemsToBill.length === 0) {
            issueWarnMessage("No items to bill.")
            return false
        }

        //check each item for minimal response
        let hasCharge = false //at least one item selected
        let hasPaymentDetails = false // has payment details if a voucher is issued
        let hasPaymentAmountIssue = false // has payment details if a voucher is issued
        let hasChequeNumberLengthIssue = false // has payment details if a voucher is issued
        itemsToBill.forEach(function (vendor, i) {
            let totalVendorCharges = 0
            if (vendor.charges.length > 0) {
                hasCharge = true

                vendor.charges.forEach(function (charge, i) {
                    props.selectedWorkOrder.vendors.forEach(function (vendor1, i) {
                        if (vendor.vendorId === vendor1.vendorId) {
                            vendor1.charges.forEach(function (charge1, i) {
                                if (charge === charge1.id) {
                                    totalVendorCharges += parseFloat(
                                        charge1.total.replaceAll(",", "")
                                    )
                                }
                            })
                        }
                    })
                })
            }
            if (issuePaymentVoucher && hasCharge) {
                if (
                    vendor.paymentMethodCd != null &&
                    (vendor.chequeNumber != null || vendor.cashAccount != null || vendor.bankRefNumber != null) &&
                    vendor.paymentAmount != null
                ) {
                    hasPaymentDetails = true
                }

                if (parseFloat(vendor.paymentAmount) > totalVendorCharges) {
                    hasPaymentAmountIssue = true
                }

                if (
                    vendor.paymentMethodCd != null && vendor.paymentMethodCd === "CHQ" && vendor.chequeNumber.length > 10
                ) {
                    hasChequeNumberLengthIssue = true
                }
                if (vendor.paymentMethodCd != null && vendor.paymentMethodCd === "RTGS" && vendor.bankRefNumber.length > 45) {
                    issueWarnMessage("Bank Reference Number cannot exceed 45 characters.")
                    return false
                }
            }
        })
        if (!hasCharge) {
            issueWarnMessage("You must select at least one item.")
            return false
        }
        if (issuePaymentVoucher && !hasPaymentDetails) {
            issueWarnMessage("You must enter full payment details for at least one vendor to create a payment voucher.")
            return false
        }
        if (hasPaymentAmountIssue) {
            issueWarnMessage("Payment voucher amount can't exceed the vendor charges.")
            return false
        }
        if (hasChequeNumberLengthIssue) {
            issueWarnMessage("Cheque Number can't exceed 10 characters.")
            return false
        }
        return true
    }

    function createBillPayment() {
        if (valid()) {
            let id = props.selectedWorkOrder.id
            let sendData = {
                id: id,
                itemsToBill: itemsToBill,
                issuePaymentVoucher: issuePaymentVoucher,
            }

            //All fields valid, post
            createBillPaymentData(sendData)
                .then((response) => {
                    issueResponseMessage(response)
                    props.reloadWorkOrders()
                })
        }
    }

    return (
        <ModalContainerModule
            size="specialWorkOrderCreateBill"
            accept={createBillPayment}
            cancel={props.handleCreateBillClose}
            openModal={props.task === "CreateBillPayment"}
            modalTitle={`Create a Bill and/or a Payment Voucher for Work Order ${
                props.selectedWorkOrder && props.selectedWorkOrder.refNumber
            }`}
            acceptButtonText="Submit"
        >
            <ResponsiveRow
                sx={{
                    height: "auto",
                    padding: "0",
                    textAlign: "left",
                    overflowY: "auto",
                }}
            >
                <Typography
                    sx={{marginBottom: "5px"}}
                    id="modal-modal-title"
                    variant="h6"
                    component="h4"
                >
                    {billDetailMessage}
                </Typography>
                <ResponsiveRow container sx={{width: "100%", height: "auto"}}>
                    <Alert severity="info">
                        <AlertTitle>
                            Select the items you wish to create a bill for.
                        </AlertTitle>
                        You can upload additional documentation on the bill page. To also
                        create a payment voucher, select the 'Create Payment Voucher' option
                        below (these shall be automatically approved for Building
                        Directors).
                    </Alert>
                    <Grid item sx={{width: "100%", marginTop: "8px"}}>
                        <Alert severity="success">
                            <AlertTitle>
                                Total to Bill: {formatCurrency(totalToBill.toFixed(2))}
                            </AlertTitle>
                        </Alert>
                    </Grid>
                    <Grid item sx={{width: "100%", marginTop: "8px"}}>
                        <Switch
                            checked={issuePaymentVoucher}
                            onChange={(event) => issuePaymentVoucherToggle(event)}
                            inputProps={{"aria-label": "controlled"}}
                        />{" "}
                        Create Payment Voucher
                    </Grid>
                </ResponsiveRow>

                <br/>

                {appMessage.display && (
                    <Alert severity={appMessage.type}>
                        <AlertTitle>{titleCase(appMessage.type)}</AlertTitle>
                        {appMessage.message}
                    </Alert>
                )}

                {itemsToBill &&
                    itemsToBill.length > 0 &&
                    props.selectedWorkOrder &&
                    props.selectedWorkOrder.vendors &&
                    props.selectedWorkOrder.vendors.length > 0 &&
                    props.selectedWorkOrder.vendors.map(function (vendor, vendorIndex) {
                        if (vendor.charges && vendor.charges.length > 0) {
                            return (
                                <WorkOrderVendorCharge
                                    key={vendorIndex}
                                    vendorIndex={vendorIndex}
                                    itemsToBill={itemsToBill}
                                    itemsToBillChanged={itemsToBillChanged}
                                    selectedWorkOrder={props.selectedWorkOrder}
                                    issuePaymentVoucher={issuePaymentVoucher}
                                    vendor={vendor}
                                    disableShadow
                                />
                            )
                        } else {
                            let noBillableMessage =
                                "The vendor " +
                                vendor.vendorName +
                                " does not have any billable items on this work order. All items have already been billed and may be awaiting payment."
                            return (
                                <Alert severity="info">
                                    <AlertTitle>{noBillableMessage}</AlertTitle>
                                </Alert>
                            )
                        }
                    }, this)}
            </ResponsiveRow>
        </ModalContainerModule>
    )
}
