import {Button, TextField, Typography, capitalize} from "@mui/material"
import {ResponsiveRow} from "../../../constants/layout.constants"

import Cancel from "@mui/icons-material/Cancel"
import {ModalAcceptButton} from "../../../constants/component.constants"
import FilterResults from "../../modals/listings/filterResults"
import {useCallback, useEffect, useState} from "react";
import {setDefaults, fromAddress} from "react-geocode"
import { isEmptyString, isNullUndefined } from "../../../util/util"

export default function LandingExploreBar(props) {
    const [location, setLocation] = useState(null)
    const [searchedLocation, setSearchedLocation] = useState("")

    const getCoordinates = useCallback(async (locationName) => {
        fromAddress(locationName)
            .then(({ results }) => {
                const { lat, lng } = results[0].geometry.location;
                props.setCenter({lat, lng})
                setSearchedLocation("")
            })
            .catch(err => console.error(err));
    }, [props])

    useEffect(() => {
        setDefaults({
            key: "AIzaSyAi5vlmbshza2_JkvFmF7w2EHmP0Ez12GY"
        })

        return () => setSearchedLocation("")
    }, [])

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if(!isEmptyString(searchedLocation)){
                getCoordinates(searchedLocation)
                setLocation(searchedLocation)
            }
        }, 1000);
        return () => clearTimeout(timeoutId);
    }, [getCoordinates, searchedLocation]);

    const handleSearchBoxChange = (e) => {
        setSearchedLocation(e.target.value)
    }

    const handleCloseSearch = () => {
        props.setReLocate(true)
        setSearchedLocation("")
        setLocation(null)
    }

    return (
        <ResponsiveRow
            sx={{
                width: "100%",
                height: "fit-content",
                backgroundColor: "#fff",
                flexDirection: "column",
                top: "100px",
                right: 0,
                margin: 0,
            }}
        >
            <ResponsiveRow
                sx={{
                    flexDirection: "column",
                    width: "100%",
                    height: "auto",
                    padding: "5px 20px",
                }}
            >
                <ResponsiveRow
                    sx={{
                        flexDirection: "column",
                        width: "100%",
                        height: "auto",
                        padding: "5px 20px",
                    }}
                >
                    <ResponsiveRow
                        sx={{
                            width: "100%",
                            padding: 0,
                            justifyContent: {xs: "flex-start", lg: "space-between"},
                            flexWrap: {xS: "wrap", lg: "nowrap"},
                        }}
                    >
                        <Typography
                            variant="h4"
                            sx={{color: "#037171", width: "100%", marginBottom: "10px"}}
                        >
                            Showing listing in:
                        </Typography>
                        <ModalAcceptButton
                            sx={{
                                fontSize: ".9rem",
                                backgroundColor: "#009F93",
                                width: {xs: "100%", lg: "150px"},
                                padding: "5px",
                                height: "auto",
                            }}
                            onClick={props.handleFilterModal}
                        >
                            Filter Results
                        </ModalAcceptButton>
                        <FilterResults
                            openModal={props.openFilterModal}
                            closeModal={props.handleFilterModal}
                            noOfBedsChanged={props.noOfBedsChanged}
                            noOfBathsChanged={props.noOfBathsChanged}
                            sqftMinChanged={props.sqftMinChanged}
                            sqftMaxChanged={props.sqftMaxChanged}
                            priceMinChanged={props.priceMinChanged}
                            priceMaxChanged={props.priceMaxChanged}
                            onPropertyTypeChange={props.onPropertyTypeChange}
                            onListingTypeChange={props.onListingTypeChange}
                            listingTypes={props.listingTypes}
                            propertyTypes={props.propertyTypes}
                            noOfBeds={props.noOfBeds}
                            noOfBaths={props.noOfBaths}
                            sqftMin={props.sqftMin}
                            sqftMax={props.sqftMax}
                            priceMin={props.priceMin}
                            priceMax={props.priceMax}
                            selectedPropertyType={props.selectedPropertyType}
                            selectedListingType={props.selectedListingType}
                            applyFilterClicked={props.applyFilterClicked}
                            clearFilter={props.clearFilter}
                        />
                    </ResponsiveRow>
                </ResponsiveRow>
                { !isNullUndefined(location) && 
                    <Button
                        // onClick={() => {
                        //     navigate("/listings")
                        // }}
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            fontSize: "13px",
                            padding: "5px 7px",
                            margin: {xs: "10px auto", lg: "0 10px"},
                            backgroundColor: "#efefef",
                            color: "#afafaf",
                            borderRadius: "10px",
                            width: "max-content",
                            "&:hover": {
                                backgroundColor: "red",
                                color: "#fff",
                            },
                        }}
                    >
                        {capitalize(location)} {"  "}
                        <Cancel sx={{fontSize: "1.75rem", padding: "5px"}} onClick={handleCloseSearch} />
                    </Button>
                }
            </ResponsiveRow>
            <TextField
                variant="outlined"
                sx={{
                    width: "90%",
                    margin: "10px auto",
                    backgroundColor: "#efefef",
                    borderRadius: "10px",
                }}
                label="Search for a different location..."
                value={searchedLocation}
                onChange={handleSearchBoxChange}
            />
        </ResponsiveRow>
    )
}
