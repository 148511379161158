import {ResponsiveRow} from "../../../constants/layout.constants"
import bgImage from "../../../images/listings/SigninBg.png"
import RegisterForm from "./registerForm"
import Box from "@mui/material/Box"

export default function ListingRegister() {
    return (
        <ResponsiveRow
            sx={{
                position: "relative",
                height: "100vh",
                width: "100vw",
                padding: 0,
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Box
                sx={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    position: "absolute",
                    top: 0,
                    left: 0,
                }}
                component="img"
                src={bgImage}
                alt="listing sign in background"
            />
            <RegisterForm/> {/*Shared Component for Register form*/}
        </ResponsiveRow>
    )
}
