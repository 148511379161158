import {useSelector} from "react-redux"
import {Navigate} from "react-router-dom"

export default function PrivateListingRoute({children}) {
    const {isLoggedIn, loginType} = useSelector((state) => state.auth)
    return isLoggedIn && loginType !== "main" ? (
        children
    ) : (
        <Navigate to="/listings/signIn"/>
    )
}
