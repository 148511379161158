// import React from "react"
import {Alert, AlertTitle} from "@mui/material"
import ModalContainerModule from "../../../modules/modalContainer"

export default function DeleteQuotationModal(props) {

    return (
        <ModalContainerModule
            size="specialAssetDelete"
            accept={props.handleDeleteQuotation}
            cancel={props.closeModal}
            openModal={props.openModal}
            modalTitle={`Delete Quotation `}
            acceptButtonText="Delete"
        >
            <Alert severity="error" sx={{width: "100%", height: "100%"}}>
                <AlertTitle>Confirm Delete Quotation </AlertTitle>
                Are you sure you to delete quotation
                "{props && props.selectedQuotation && props.selectedQuotation.description}". This action is not
                reversible.
            </Alert>
        </ModalContainerModule>
    );
}
