import {
  Alert,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import { ResponsiveRow } from "../../../../constants/layout.constants";
import ModalContainerModule from "../../../modules/modalContainer";
import { isEmptyString, isNullUndefined } from "../../../../util/util";

export default function AddAccountsModal(props) {
  /**
   *
   * REQUIRED PROPS
   *
   * handleSaveAccountClicked
   * handleCloseAccForm
   * handleAccountCategoryChanged
   *
   * accountForm
   *
   * setAccountName
   * setSelectedAccount
   * setSubCategory
   *
   * accountCategoryList
   * accountName
   * accountSubCategoryList
   * selectedAccount
   * selectedCategory
   * subCategory
   *
   */

  return (
    <ModalContainerModule
      size="specialAddAccountsModal"
      accept={props.handleSaveAccountClicked}
      cancel={props.handleCloseAccForm}
      openModal={props.accountForm}
      modalTitle="Account Details"
      acceptButtonText="Save"
    >
      <ResponsiveRow
        sx={{
          flexDirection: "column",
          flexWrap: "nowrap",

          justifyContent: "flex-start",
          alignItems: "flex-start",

          width: "100%",
          height: "100%",
        }}
      >
        <Alert severity="info" sx={{ width: "100%", height: "auto" }}>
          After adding chart of account, the account added can't be
          <span style={{ padding: 5, fontWeight: "bold" }}>Edited</span>
          or
          <span style={{ padding: 5, fontWeight: "bold" }}>Deleted.</span>
        </Alert>
        <ResponsiveRow
          sx={{
            // flexDirection: { xs: "column", lg: "row" },

            justifyContent: "flex-start",
            alignItems: "flex-start",

            width: "100%",
            height: "auto",

            overflowY: "auto",
          }}
          container
        >
          <FormControl
            sx={{
              width: { xs: "100%", lg: "50%" },
              height: "auto",
              marginBottom: "10px",
            }}
          >
            <InputLabel id="accountSubCategoryLabel" sx={{ fontSize: ".9rem" }}>
              Account Name
            </InputLabel>
            <OutlinedInput
              label="Account Name"
              // placeholder={"Account Name"}
              sx={{ width: { xs: "100%", lg: "90%" } }}
              id={"accountName"}
              onChange={(event) => {
                let value = event.target.value;
                props.setAccountName(value);
                let obj = props.selectedAccount;
                obj.name = value;
                props.setSelectedAccount(obj);
              }}
              value={
                props.selectedAccount
                  ? props.selectedAccount.name
                  : props.accountName
              }
            />
          </FormControl>

          <FormControl
            sx={{
              width: { xs: "100%", lg: "50%" },
              height: "auto",
              marginBottom: "10px",
            }}
          >
            <InputLabel id="accountCategoryLabel" sx={{ fontSize: ".9rem" }}>
              Account Category
            </InputLabel>
            <Select
              label="Account Category"
              sx={{ width: { xs: "100%", lg: "90%" } }}
              id={"category"}
              labelId="accountCategoryLabel"
              value={
                props.selectedAccount
                  ? props.selectedAccount.accountCategoryCd
                  : props.selectedCategory
              }
              onChange={(event) => {
                props.handleAccountCategoryChanged(event);
              }}
            >
              {props.accountCategoryList &&
              props.accountCategoryList.length > 0 ? (
                props.accountCategoryList.map(function (item, i) {
                  return (
                    <MenuItem key={i} value={item.value}>
                      {item.label}
                    </MenuItem>
                  );
                }, this)
              ) : (
                <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
              )}
            </Select>
          </FormControl>

          {props.selectedAccount &&
            ((!isNullUndefined(props.selectedAccount.accountCategoryCd) &&
              !isEmptyString(props.selectedAccount.accountCategoryCd)) ||
              (!isNullUndefined(props.selectedAccount.selectedCategory) &&
                !isEmptyString(props.selectedAccount.selectedCategory))) && (
              <FormControl
                sx={{
                  width: { xs: "100%", lg: "50%" },
                  height: "auto",
                  marginBottom: "10px",
                }}
              >
                <InputLabel
                  id="accountSubCategoryLabel"
                  sx={{ fontSize: ".9rem" }}
                >
                  Account Sub Category
                </InputLabel>
                <Select
                  sx={{ width: { xs: "100%", lg: "90%" } }}
                  label="Account Sub Category"
                  labelId="accountSubCategoryLabel"
                  id={"subCategory"}
                  value={
                    props.selectedAccount
                      ? props.selectedAccount.accountSubCategoryCd
                      : props.subCategory
                  }
                  onChange={(event) => {
                    let value = event.target.value;
                    props.setSubCategory(value);
                    let obj = props.selectedAccount;
                    obj.accountSubCategoryCd = value;
                    props.setSelectedAccount(obj);
                  }}
                >
                  {props.accountSubCategoryList &&
                  props.accountSubCategoryList.length > 0 ? (
                    props.accountSubCategoryList.map(function (item, i) {
                      return (
                        <MenuItem key={i} value={item.value}>
                          {item.label}
                        </MenuItem>
                      );
                    }, this)
                  ) : (
                    <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
                  )}
                </Select>
              </FormControl>
            )}
        </ResponsiveRow>
      </ResponsiveRow>
    </ModalContainerModule>
  );
}
