/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from "react"
import moment from "moment"
import {ResponsiveRow} from "../../../constants/layout.constants"
import {IconButton, Typography} from "@mui/material"

export default function AppointmentCalendar(props) {
    const [firstMonthDay, setFirstMonthDay] = useState(7)
    const [monthDays, setMonthDays] = useState(28)

    let weekdays = moment.weekdaysShort()

    useEffect(() => {
        let day1 = moment()
            .month(props.month)
            .year(props.year)
            .startOf("month")
            .format("d")
        let monthdays = moment().daysInMonth()

        setFirstMonthDay(parseInt(day1))
        setMonthDays(monthdays)

        // console.log("first day", firstMonthDay)

        return () => {
            setFirstMonthDay(0)
        }
    }, [props])

    function populateWeekDays() {
        return weekdays.map((day) => {
            return (
                <ResponsiveRow
                    item
                    sx={{
                        flexDirection: "column",
                        width: {xs: "24%", lg: "14%"},
                        height: "80px",
                    }}
                >
                    <Typography
                        sx={{
                            fontWeight: 800,
                            color: day === "Sun" || day === "Sat" ? "#00B9AE" : "#000",
                        }}
                    >
                        {day}
                    </Typography>
                </ResponsiveRow>
            )
        })
    }

    function populateEmptySpaces() {
        const emptySpaces = []
        for (let i = 0; i !== firstMonthDay; i++) {
            emptySpaces.push(
                <ResponsiveRow
                    key={i}
                    item
                    sx={{
                        flexDirection: "column",
                        width: "14%",
                        height: "80px",
                    }}
                >
                    <Typography></Typography>
                </ResponsiveRow>
            )
        }

        return emptySpaces.map((space) => space)
    }

    function populateMonthDays() {
        const monthDates = []
        let dayCounter = parseInt(firstMonthDay - 1)
        let weekend = false
        let isToday = false

        for (let i = 0; i < monthDays; i++) {
            const date = i + 1

            if (dayCounter === 6) {
                dayCounter = 0
            } else {
                dayCounter++
            }

            weekend = dayCounter === 0 || dayCounter === 6

            isToday = date === props.day

            monthDates.push(
                <ResponsiveRow
                    item
                    sx={{
                        flexDirection: "column",
                        width: {xs: "24%", lg: "14%"},
                        height: "70px",
                    }}
                >
                    <IconButton
                        sx={{
                            backgroundColor: !isToday ? "#fff" : "#037171",
                            fontWeight: 800,
                            color: isToday ? "#fff" : weekend ? "#00B9AE" : "#000",
                            fontSize: "1rem",
                            textAlign: "center",
                            borderRadius: "10px",
                        }}
                        component="div"
                        onClick={() => props.selectDate(date)}
                    >
                        {date}
                    </IconButton>
                </ResponsiveRow>
            )
        }

        return monthDates.map((date) => date)
    }

    return (
        <ResponsiveRow
            sx={{
                display: {xs: "none", lg: "flex"},

                width: {xs: 0, lg: "70%"},
                height: "auto",

                backgroundColor: "#fff",
                borderRadius: "20px",
                padding: "30px 20px",
                flexDirection: "column",
                flexWrap: "nowrap",
            }}
        >
            <ResponsiveRow sx={{width: "100%", height: "auto"}}>
                {populateWeekDays()}
            </ResponsiveRow>
            <ResponsiveRow sx={{width: "100%", height: "auto"}}>
                <ResponsiveRow>
                    {populateEmptySpaces()}
                    {populateMonthDays()}
                </ResponsiveRow>
            </ResponsiveRow>
        </ResponsiveRow>
    )
}
