/* eslint-disable react-hooks/exhaustive-deps */
import {
  Alert,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  getIdRefNumber,
  isEmptyString,
  isNullUndefined,
} from "../../util/util";
import { ResponsiveRow } from "../../constants/layout.constants";
import { getCountries, getIdTypes } from "../../services/list.service";
import { ModalAcceptButton } from "../../constants/component.constants";
import { issueWarnMessage } from "../../actions/message";

const defaultSignUp = {
  id: "",
  personId: "",
  person: {
    id: null,
    firstName: "",
    middleName: null,
    lastName: "",
    mobilePhone: "",
    officePhone: null,
    email: "",
    credentialId: null,
    statusCd: "",
    dateCreated: "",
    idNumber: "",
    idTypeCd: "",
    photoFile: null,
    employeeBadge: null,
  },
  signUpJson: {
    currentStep: 0,
    isPropertyManagedByOrganization: false,
    organization: {
      id: null,
      name: "",
      description: "",
      organizationLogo: null,
      address: {
        city: "",
        countryCd: "",
        address2: null,
        id: null,
        address1: "",
      },
      statusCd: "ACT",
      addressId: null,
      taxId: "",
    },
    contactVerification: {
      isPhoneNumberVerified: false,
      phoneNumber: "",
    },
  },
};

export default function PersonalDetails(props) {
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobilePhone, setMobilePhone] = useState("");
  const [officePhone, setOfficePhone] = useState("");
  const [email, setEmail] = useState("");
  const [idNo, setIdNo] = useState("");
  const [idType, setIdType] = useState("");
  const [idTypes, setIdTypes] = useState([]);
  const [person, setPerson] = useState(null);
  const [isPropertyManagedByOrganization, setIsPropertyManagedByOrganization] =
    useState(false);
  const [companyName, setCompanyName] = useState("");
  const [description, setDescription] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [countryCd, setCountry] = useState("");
  const [taxId, setTaxId] = useState("");
  const [countryOptions, setCountryOptions] = useState([]);
  const [organization, setOrganization] = useState(null);

  useEffect(() => {
    let personData = {
      id: null,
      firstName: "",
      middleName: "",
      lastName: "",
      mobilePhone: "",
      officePhone: null,
      email: "",
      credentialId: null,
      statusCd: "ACT",
      dateCreated: "",
      idNumber: "",
      idTypeCd: "",
      photoFile: null,
      employeeBadge: null,
    };

    let organizationData = {
      id: getIdRefNumber(),
      name: "",
      description: "",
      taxId: "",
      address: {
        address1: "",
        address2: "",
        countryCd: "",
        city: "",
      },
      contactPerson: personData,
    };
    if (isNullUndefined(person)) {
      if (
        !isNullUndefined(props) &&
        !isNullUndefined(props.signUp) &&
        !isNullUndefined(props.signUp.person)
      ) {
        setPerson(props.signUp.person);
      } else {
        setPerson(personData);
      }
    }
    if (isNullUndefined(organization)) {
      if (
        !isNullUndefined(props) &&
        !isNullUndefined(props.signUp) &&
        !isNullUndefined(props.signUp.signUpJson) &&
        !isNullUndefined(props.signUp.signUpJson.organization)
      ) {
        setOrganization(props.signUp.signUpJson.organization);
      } else {
        setOrganization(organizationData);
      }
    }
    if (
      !isNullUndefined(props) &&
      !isNullUndefined(props.signUp) &&
      !isNullUndefined(props.signUp.signUpJson) &&
      !isNullUndefined(props.signUp.signUpJson.isPropertyManagedByOrganization)
    ) {
      setIsPropertyManagedByOrganization(
        props.signUp.signUpJson.isPropertyManagedByOrganization
      );
    } else {
      setIsPropertyManagedByOrganization(false);
    }

    getCountries().then((countryOptions) => setCountryOptions(countryOptions));

    getIdTypes().then((idTypes) => setIdTypes(idTypes));
  }, []);

  useEffect(() => {
    let personData = {
      id: null,
      firstName: "",
      middleName: "",
      lastName: "",
      mobilePhone: "",
      officePhone: null,
      email: "",
      credentialId: null,
      statusCd: "ACT",
      dateCreated: "",
      idNumber: "",
      idTypeCd: "",
      photoFile: null,
      employeeBadge: null,
    };

    let organizationData = {
      id: getIdRefNumber(),
      name: "",
      description: "",
      taxId: "",
      address: {
        address1: "",
        address2: "",
        countryCd: "",
        city: "",
      },
      contactPerson: personData,
    };
    if (isNullUndefined(props.signUp)) {
      setPerson(personData);
      setOrganization(organizationData);
      setIsPropertyManagedByOrganization(false);
    } else {
      if (
        !isNullUndefined(props) &&
        !isNullUndefined(props.signUp) &&
        !isNullUndefined(props.signUp.person)
      ) {
        setPerson(props.signUp.person);
      } else {
        setPerson(personData);
      }
      if (
        !isNullUndefined(props) &&
        !isNullUndefined(props.signUp) &&
        !isNullUndefined(props.signUp.signUpJson) &&
        !isNullUndefined(props.signUp.signUpJson.organization)
      ) {
        setOrganization(props.signUp.signUpJson.organization);
      } else {
        setOrganization(organizationData);
      }
      if (
        !isNullUndefined(props) &&
        !isNullUndefined(props.signUp) &&
        !isNullUndefined(props.signUp.signUpJson) &&
        !isNullUndefined(
          props.signUp.signUpJson.isPropertyManagedByOrganization
        )
      ) {
        setIsPropertyManagedByOrganization(
          props.signUp.signUpJson.isPropertyManagedByOrganization
        );
      } else {
        setIsPropertyManagedByOrganization(false);
      }
    }
  }, [props.signUp]);

  function resumeSignUp() {
    let sendData;
    if (!isNullUndefined(person)) {
      if (
        !isNullUndefined(person.mobilePhone) ||
        !isNullUndefined(person.email)
      ) {
        sendData = {
          email: person.email,
          phoneNumber: person.mobilePhone,
        };
      }
    }
    if (isNullUndefined(sendData)) {
      issueWarnMessage(
        `Email or mobile phone is required to resume to your previous state.`
      );
    } else {
      if (
        (isNullUndefined(sendData.email) || isEmptyString(sendData.email)) &&
        (isNullUndefined(sendData.phoneNumber) ||
          isEmptyString(sendData.phoneNumber))
      ) {
        issueWarnMessage(
          `Email or mobile phone is required to resume to your previous state.`
        );
      } else {
        props.fetchSignUpData(sendData);
      }
    }
  }

  function saveAndFinishLater() {
    let signUp;
    const buttonIndex = "0.1";
    if (isNullUndefined(props.signUp)) {
      signUp = defaultSignUp;
      signUp.buttonIndex = buttonIndex;
      signUp.person = person;
      signUp.signUpJson.organization = organization;
      signUp.signUpJson.isPropertyManagedByOrganization =
        isPropertyManagedByOrganization;
    } else {
      signUp = props.signUp;
      signUp.buttonIndex = buttonIndex;
      signUp.person = person;
      signUp.signUpJson.organization = organization;
      signUp.signUpJson.isPropertyManagedByOrganization =
        isPropertyManagedByOrganization;
    }
    if (isNullUndefined(signUp)) {
      issueWarnMessage(`Please fill in the form.`);
    } else {
      if (validateSaveAndFinishLater(signUp)) {
        props.saveSignUpData(signUp);
      }
    }
  }

  function validateSaveAndFinishLater(signUp) {
    //console.log("validateSaveAndFinishLater signUp: ", signUp)
    // Validate
    if (isNullUndefined(signUp)) {
      issueWarnMessage("Could not determine signUp.");
      return false;
    }
    if (isNullUndefined(signUp.person)) {
      issueWarnMessage("Could not determine person.");
      return false;
    }

    if (
      (isNullUndefined(signUp.person.mobilePhone) ||
        isEmptyString(signUp.person.mobilePhone)) &&
      (isNullUndefined(signUp.person.email) ||
        isEmptyString(signUp.person.email))
    ) {
      issueWarnMessage(
        "Could not determine person email or mobile phone number."
      );
      return false;
    }

    if (
      isNullUndefined(signUp.person.firstName) ||
      isEmptyString(signUp.person.firstName)
    ) {
      issueWarnMessage("Could not determine person first name.");
      return false;
    }

    if (
      isNullUndefined(signUp.person.lastName) ||
      isEmptyString(signUp.person.lastName)
    ) {
      issueWarnMessage("Could not determine person last name.");
      return false;
    }

    if (
      isNullUndefined(signUp.person.idTypeCd) ||
      isEmptyString(signUp.person.idTypeCd)
    ) {
      issueWarnMessage("Could not determine person id type Code.");
      return false;
    }

    if (
      isNullUndefined(signUp.person.idNumber) ||
      isEmptyString(signUp.person.idNumber)
    ) {
      issueWarnMessage("Could not determine person id number.");
      return false;
    }

    return true;
  }

  function validateSaveAndContinue(signUp) {
    //console.log("validateSaveAndContinue signUp: ", signUp)
    // Validate
    if (validateSaveAndFinishLater(signUp)) {
      if (isNullUndefined(signUp.signUpJson)) {
        issueWarnMessage("Could not determine sign up.");
        return false;
      }

      if (isNullUndefined(signUp.signUpJson.isPropertyManagedByOrganization)) {
        issueWarnMessage(
          "Could not determine if Property is Managed By an Organization."
        );
        return false;
      }

      if (signUp.signUpJson.isPropertyManagedByOrganization) {
        if (isNullUndefined(signUp.signUpJson.organization)) {
          issueWarnMessage("Could not determine person's Organization.");
          return false;
        }

        if (
          isNullUndefined(signUp.signUpJson.organization.name) ||
          isEmptyString(signUp.signUpJson.organization.name)
        ) {
          issueWarnMessage("Could not determine organization name.");
          return false;
        }

        if (isNullUndefined(signUp.signUpJson.organization.address)) {
          issueWarnMessage("Could not determine organization address.");
          return false;
        }

        if (
          isNullUndefined(signUp.signUpJson.organization.address.address1) ||
          isEmptyString(signUp.signUpJson.organization.address.address1)
        ) {
          issueWarnMessage("Could not determine organization address1.");
          return false;
        }

        if (
          isNullUndefined(signUp.signUpJson.organization.address.countryCd) ||
          isEmptyString(signUp.signUpJson.organization.address.countryCd)
        ) {
          issueWarnMessage("Could not determine organization country.");
          return false;
        }

        if (
          isNullUndefined(signUp.signUpJson.organization.address.city) ||
          isEmptyString(signUp.signUpJson.organization.address.city)
        ) {
          issueWarnMessage("Could not determine organization city.");
          return false;
        }
      }

      return true;
    }
  }

  function saveAndContinue() {
    let signUp;
    const buttonIndex = "0.2";
    if (isNullUndefined(props.signUp)) {
      signUp = defaultSignUp;
      signUp.buttonIndex = buttonIndex;
      signUp.person = person;
      signUp.signUpJson.organization = organization;
      signUp.signUpJson.isPropertyManagedByOrganization =
        isPropertyManagedByOrganization;
    } else {
      signUp = props.signUp;
      signUp.buttonIndex = buttonIndex;
      signUp.person = person;
      signUp.signUpJson.organization = organization;
      signUp.signUpJson.isPropertyManagedByOrganization =
        isPropertyManagedByOrganization;
    }
    if (isNullUndefined(signUp)) {
      issueWarnMessage(`Please fill in the form.`);
    } else {
      if (validateSaveAndContinue(signUp)) {
        props.saveSignUpData(signUp);
      }
    }
  }

  function handleIdTypeChange(event) {
    let value = event.target.value;
    let obj = person;
    setIdType(value);
    obj.idTypeCd = value;
  }

  function onCheckBoxChange() {
    setIsPropertyManagedByOrganization(!isPropertyManagedByOrganization);
  }

  function handleCountryChange(event) {
    let value = event.target.value;
    setCountry(value);
    organization.address.countryCd = value;
  }

  return (
    <ResponsiveRow
      container
      sx={{ width: "100%", height: "auto", textAlign: "start" }}
    >
      {/* CREATE PERSON FORM */}

      {/*<Typography
					sx={{ marginBottom: "5px" }}
					id="addUser"
					variant="h6"
					component="h6"
				>
					Personal Details
				</Typography>*/}

      <Alert severity="info" sx={{ width: "100%" }}>
        You can enter email address or mobile phone number and click on resume
        sign up to navigate to the step you left off.
      </Alert>
      <ResponsiveRow
        sx={{ width: "100%", height: "auto", padding: 0 }}
        container
      >
        <FormControl
          item
          sx={{ width: { xs: "100%", lg: "33.3%" }, marginBottom: "10px" }}
        >
          <InputLabel>First Name *</InputLabel>
          <OutlinedInput
            sx={{ width: { xs: "100%", md: "90%" } }}
            label="First Name *"
            id={"firstName"}
            onChange={(event) => {
              const value = event.target.value;
              setFirstName(value);
              person.firstName = value;
            }}
            value={person ? person && person.firstName : firstName}
          />
        </FormControl>
        <FormControl
          item
          sx={{ width: { xs: "100%", lg: "33.3%" }, marginBottom: "10px" }}
        >
          <InputLabel>Middle Name</InputLabel>
          <OutlinedInput
            sx={{ width: { xs: "100%", md: "90%" } }}
            label="Middle Name"
            id={"middleName"}
            onChange={(event) => {
              const value = event.target.value;
              setMiddleName(value);
              person.middleName = value;
            }}
            value={person ? person && person.middleName : middleName}
          />
        </FormControl>
        <FormControl
          item
          sx={{ width: { xs: "100%", lg: "33.3%" }, marginBottom: "10px" }}
        >
          <InputLabel>Last Name *</InputLabel>
          <OutlinedInput
            sx={{ width: { xs: "100%", md: "90%" } }}
            label="Last Name *"
            id={"lastName"}
            onChange={(event) => {
              const value = event.target.value;
              setLastName(value);
              person.lastName = value;
            }}
            value={person ? person && person.lastName : lastName}
          />
        </FormControl>

        <FormControl
          item
          sx={{ width: { xs: "100%", lg: "33.3%" }, marginBottom: "10px" }}
        >
          <InputLabel>Mobile Phone * (Primary No.)</InputLabel>
          <OutlinedInput
            sx={{ width: { xs: "100%", md: "90%" } }}
            label="Mobile Phone * (Primary No.)"
            id={"mobilePhone"}
            onChange={(event) => {
              const value = event.target.value;
              setMobilePhone(value);
              person.mobilePhone = value;
            }}
            value={person ? person && person.mobilePhone : mobilePhone}
          />
        </FormControl>

        <FormControl
          item
          sx={{ width: { xs: "100%", lg: "33.3%" }, marginBottom: "10px" }}
        >
          <InputLabel>ID Type</InputLabel>
          <Select
            sx={{ width: { xs: "100%", md: "90%" } }}
            label="ID Type"
            id={"idType"}
            value={person ? person && person.idTypeCd : idType}
            onChange={(event) => handleIdTypeChange(event)}
          >
            {idTypes && idTypes.length > 0 ? (
              idTypes.map(function (idType, i) {
                return (
                  <MenuItem key={idType.value} value={idType.value}>
                    {idType.label}
                  </MenuItem>
                );
              }, this)
            ) : (
              <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
            )}
          </Select>
        </FormControl>

        <FormControl
          item
          sx={{ width: { xs: "100%", lg: "33.3%" }, marginBottom: "10px" }}
        >
          <InputLabel>ID Number *</InputLabel>
          <OutlinedInput
            sx={{ width: { xs: "100%", md: "90%" } }}
            label="ID Number *"
            id={"idNo"}
            onChange={(event) => {
              const value = event.target.value;
              setIdNo(value);
              person.idNumber = value;
            }}
            value={person ? person && person.idNumber : idNo}
          />
        </FormControl>

        <FormControl
          item
          sx={{ width: { xs: "100%", lg: "33.3%" }, marginBottom: "10px" }}
        >
          <InputLabel>Office Phone (Secondary No.)</InputLabel>
          <OutlinedInput
            sx={{ width: { xs: "100%", md: "90%" } }}
            label="Office Phone (Secondary No.)"
            id={"officePhone"}
            onChange={(event) => {
              const value = event.target.value;
              setOfficePhone(value);
              person.officePhone = value;
            }}
            value={person ? person && person.officePhone : officePhone}
          />
        </FormControl>

        <FormControl
          item
          sx={{ width: { xs: "100%", lg: "33.3%" }, marginBottom: "10px" }}
        >
          <InputLabel>Email</InputLabel>
          <OutlinedInput
            sx={{ width: { xs: "100%", md: "90%" } }}
            label="Email"
            id={"email"}
            onChange={(event) => {
              const value = event.target.value;
              setEmail(value);
              person.email = value;
            }}
            value={person ? person && person.email : email}
          />
        </FormControl>
      </ResponsiveRow>
      <ResponsiveRow sx={{ width: "100%", height: "auto", padding: 0 }}>
        <Grid item sx={{ width: "auto", marginBottom: "10px", height: "auto" }}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isPropertyManagedByOrganization}
                  onChange={onCheckBoxChange}
                />
              }
              label="Is your property managed by an organization/Company?"
            />
          </FormGroup>
        </Grid>
      </ResponsiveRow>

      {isPropertyManagedByOrganization && (
        <ResponsiveRow
          sx={{ width: "100%", height: "auto", padding: 0 }}
          container
        >
          <FormControl
            item
            sx={{ width: { xs: "100%", lg: "33.3%" }, marginBottom: "10px" }}
          >
            <InputLabel>Company Name *</InputLabel>
            <OutlinedInput
              sx={{ width: { xs: "100%", md: "90%" } }}
              label="Company Name *"
              id={"companyName"}
              onChange={(event) => {
                const value = event.target.value;
                setCompanyName(value);
                organization.name = value;
              }}
              value={
                organization ? organization && organization.name : companyName
              }
            />
          </FormControl>

          <FormControl
            item
            sx={{ width: { xs: "100%", lg: "33.3%" }, marginBottom: "10px" }}
          >
            <InputLabel>Address 1 *</InputLabel>
            <OutlinedInput
              sx={{ width: { xs: "100%", md: "90%" } }}
              label="Address 1 *"
              id={"address1"}
              onChange={(event) => {
                const value = event.target.value;
                setAddress1(value);
                organization.address.address1 = value;
              }}
              value={
                organization
                  ? organization &&
                    organization.address &&
                    organization.address.address1
                  : address1
              }
            />
          </FormControl>

          <FormControl
            item
            sx={{ width: { xs: "100%", lg: "33.3%" }, marginBottom: "10px" }}
          >
            <InputLabel>Address 2</InputLabel>
            <OutlinedInput
              sx={{ width: { xs: "100%", md: "90%" } }}
              label="Address 2"
              id={"address2"}
              onChange={(event) => {
                const value = event.target.value;
                setAddress2(value);
                organization.address.address2 = value;
              }}
              value={
                organization
                  ? organization &&
                    organization.address &&
                    organization.address.address2
                  : address2
              }
            />
          </FormControl>

          <FormControl
            item
            sx={{ width: { xs: "100%", lg: "33.3%" }, marginBottom: "10px" }}
          >
            <InputLabel>Country *</InputLabel>
            <Select
              sx={{ width: { xs: "100%", md: "90%" } }}
              label="Country *"
              id={"country"}
              value={
                organization
                  ? organization &&
                    organization.address &&
                    organization.address.countryCd
                  : countryCd
              }
              onChange={(event) => handleCountryChange(event)}
            >
              {countryOptions && countryOptions.length > 0 ? (
                countryOptions.map(function (country, i) {
                  return (
                    <MenuItem key={country.value} value={country.value}>
                      {country.label}
                    </MenuItem>
                  );
                }, this)
              ) : (
                <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
              )}
            </Select>
          </FormControl>

          <FormControl
            item
            sx={{ width: { xs: "100%", lg: "33.3%" }, marginBottom: "10px" }}
          >
            <InputLabel>City *</InputLabel>
            <OutlinedInput
              label="City"
              placeholder={"City"}
              sx={{ width: { xs: "100%", md: "90%" } }}
              id={"city"}
              onChange={(event) => {
                const value = event.target.value;
                setCity(value);
                organization.address.city = value;
              }}
              value={
                organization
                  ? organization &&
                    organization.address &&
                    organization.address.city
                  : city
              }
            />
          </FormControl>

          <FormControl
            sx={{ width: { xs: "100%", lg: "33.3%" }, marginBottom: "10px" }}
          >
            <InputLabel>Description</InputLabel>
            <OutlinedInput
              label="Description"
              placeholder={"Description"}
              sx={{ width: { xs: "100%", md: "90%" } }}
              id={"description"}
              onChange={(event) => {
                const value = event.target.value;
                setDescription(value);
                organization.description = value;
              }}
              value={
                organization
                  ? organization && organization.description
                  : description
              }
            />
          </FormControl>

          <FormControl
            item
            sx={{ width: { xs: "100%", lg: "33.3%" }, marginBottom: "10px" }}
          >
            <InputLabel>Tax ID</InputLabel>
            <OutlinedInput
              label="Tax ID"
              placeholder={"Tax Id"}
              sx={{ width: { xs: "100%", md: "90%" } }}
              id={"taxId"}
              onChange={(event) => {
                const value = event.target.value;
                setTaxId(value);
                organization.taxId = value;
              }}
              value={organization ? organization && organization.taxId : taxId}
            />
          </FormControl>
        </ResponsiveRow>
      )}

      <ResponsiveRow
        sx={{
          justifyContent: { xs: "center", lg: "flex-end" },
          height: "auto",
          width: "100%",
          padding: 0,
        }}
      >
        <ModalAcceptButton
          onClick={resumeSignUp}
          sx={{
            width: { xs: "100%", lg: "200px" },
          }}
        >
          Resume Sign Up
        </ModalAcceptButton>

        <ModalAcceptButton
          onClick={saveAndFinishLater}
          sx={{
            width: { xs: "100%", lg: "200px" },
          }}
        >
          Save & Finish Later
        </ModalAcceptButton>

        <ModalAcceptButton
          onClick={saveAndContinue}
          sx={{
            width: { xs: "100%", lg: "200px" },
          }}
        >
          Save & Continue
        </ModalAcceptButton>
      </ResponsiveRow>
    </ResponsiveRow>
  );
}
