import {useDispatch, useSelector} from "react-redux"
import {Navigate} from "react-router-dom"
import {useCallback, useEffect, useRef} from "react"
import {logout} from "../actions/auth"
import {isNullUndefined, tryParseInt} from "../util/util"
import history from "../helpers/history";
import authService from "../services/auth.service";

export default function PrivateRoute({children}) {
    const {isLoggedIn, loginType} = useSelector((state) => state.auth)
    const dispatch = useDispatch()
    const intervalId = useRef(null)
    const user = useSelector((state) => state.auth.user)

    useEffect(() => {
        if (!isNullUndefined(user) && !isNullUndefined(user.expiresAt)) {
            intervalId.current = setInterval(handleLogoutTimer, 100)
        } else {
            logoutAction()
        }
        return () => clearInterval(intervalId.current)
    }, [user])


// this function sets the timer that logs out the user after token expires
    const handleLogoutTimer = () => {
        let localUser = JSON.parse(localStorage.getItem("user"))
        if (isNullUndefined(localUser) || isNullUndefined(localUser.expiresAt)) {
            logoutAction()
        } else {
            let expiresAt = new Date(localUser.expiresAt)
            let seconds = (expiresAt.getTime() - new Date().getTime()) / 1000
            let secToExpire = tryParseInt(seconds, -1)
            if (secToExpire <= 0) {//do logout
                logoutAction()
            } else if (secToExpire > 58 && secToExpire < 60) {
                authService.getAccessToken()
            }
        }

    }

// logs out user by clearing out auth token in localStorage and redirecting url to login page.
    const logoutAction = () => {    
        clearInterval(intervalId.current)
        dispatch(logout())
        history.push("/")
    }


    return !isLoggedIn || loginType === "listings" ? (
        <Navigate to="/"/>
    ) : (children)
}
