import ModalContainerModule from "../../../modules/modalContainer";

import {ResponsiveRow} from "../../../../constants/layout.constants";
import {Divider, Typography} from "@mui/material";
import {formatCurrency, formatDate, formatDateTime, isArray,} from "../../../../util/util";
import {
    DetailsColumn,
    DetailsContainer,
    DetailsLabel,
    DetailsRow,
    DetailsText,
} from "../../../../constants/component.constants";

import MuiDataGrid from "../../../modules/MuiDataGrid";

export default function ViewReconcilationModal(props) {
    /**
     *
     * REQUIRED PROPS
     *
     * handlePrintReconciliation
     * handleCloseView
     *
     * setSelectionModel
     *
     * isLoading
     * loadPage
     * reconciliationView
     * reconciledTransactionDetails
     * reconciledTransactionDetailsColumns
     * reconciledTransactionDetailsTotal
     * rowsPerPage
     * selectedReconciliation
     * selectionModel
     *
     */
    return (
        <ModalContainerModule
            size="md"
            accept={props.handlePrintReconciliation}
            cancel={props.handleCloseView}
            openModal={props.reconciliationView}
            modalTitle="Reconciliation Details"
            acceptButtonText={`Print Reconciliation`}
        >
            <ResponsiveRow
                container
                sx={{paddingTop: 0, margin: "0 auto", height: "auto", width: "100%"}}
            >
                <DetailsContainer
                    sx={{width: "100%", height: "auto", margin: "0 auto"}}
                >
                    <DetailsColumn sx={{width: {xs: "100%", lg: "33.3%"}}}>
                        <DetailsRow
                            sx={{
                                flexDirection: "column",
                                flexWrap: "nowrap",
                                width: "100%",
                            }}
                        >
                            <DetailsLabel sx={{width: "100%"}}> Bank Account:</DetailsLabel>
                            <DetailsText sx={{width: "100%"}}>
                                {" "}
                                {props.selectedReconciliation &&
                                    props.selectedReconciliation.account &&
                                    props.selectedReconciliation.account.name}
                            </DetailsText>
                        </DetailsRow>
                        <DetailsRow
                            sx={{
                                flexDirection: "column",
                                flexWrap: "nowrap",
                                width: "100%",
                            }}
                        >
                            <DetailsLabel sx={{width: "100%"}}>
                                {" "}
                                Ledger Account(s):
                            </DetailsLabel>
                            <DetailsText sx={{width: "100%"}}>
                                {" "}
                                {props.selectedReconciliation &&
                                    props.selectedReconciliation.ledgerAccounts &&
                                    props.selectedReconciliation.ledgerAccounts.names}
                            </DetailsText>
                        </DetailsRow>
                        <DetailsRow
                            sx={{
                                flexDirection: "column",
                                flexWrap: "nowrap",
                                width: "100%",
                            }}
                        >
                            <DetailsLabel sx={{width: "100%"}}> Start Date:</DetailsLabel>
                            <DetailsText sx={{width: "100%"}}>
                                {" "}
                                {props.selectedReconciliation &&
                                    props.selectedReconciliation.startDate &&
                                    formatDate(props.selectedReconciliation.startDate)}
                            </DetailsText>
                        </DetailsRow>
                        <DetailsRow
                            sx={{
                                flexDirection: "column",
                                flexWrap: "nowrap",
                                width: "100%",
                            }}
                        >
                            <DetailsLabel sx={{width: "100%"}}>
                                {" "}
                                Statement Date:
                            </DetailsLabel>
                            <DetailsText sx={{width: "100%"}}>
                                {props.selectedReconciliation &&
                                    props.selectedReconciliation.statementDate &&
                                    formatDate(props.selectedReconciliation.statementDate)}
                            </DetailsText>
                        </DetailsRow>
                    </DetailsColumn>
                    <DetailsColumn sx={{width: {xs: "100%", lg: "33.3%"}}}>
                        <DetailsRow
                            sx={{
                                flexDirection: "column",
                                flexWrap: "nowrap",
                                width: "100%",
                            }}
                        >
                            <DetailsLabel sx={{width: "100%"}}>
                                {" "}
                                Beginning Balance:
                            </DetailsLabel>
                            <DetailsText sx={{width: "100%"}}>
                                {" "}
                                {props.selectedReconciliation &&
                                    props.selectedReconciliation.begBalance &&
                                    formatCurrency(props.selectedReconciliation.begBalance)}
                            </DetailsText>
                        </DetailsRow>
                        <DetailsRow
                            sx={{
                                flexDirection: "column",
                                flexWrap: "nowrap",
                                width: "100%",
                            }}
                        >
                            <DetailsLabel sx={{width: "100%"}}>
                                {" "}
                                Ending Balance:
                            </DetailsLabel>
                            <DetailsText sx={{width: "100%"}}>
                                {" "}
                                {props.selectedReconciliation &&
                                    props.selectedReconciliation.endBalance &&
                                    formatCurrency(props.selectedReconciliation.endBalance)}
                            </DetailsText>
                        </DetailsRow>
                        <DetailsRow
                            sx={{
                                flexDirection: "column",
                                flexWrap: "nowrap",
                                width: "100%",
                            }}
                        >
                            <DetailsLabel sx={{width: "100%"}}>
                                {" "}
                                Reconciliation Balance:
                            </DetailsLabel>
                            <DetailsText sx={{width: "100%"}}>
                                {" "}
                                {props.selectedReconciliation &&
                                    props.selectedReconciliation.reconBalance &&
                                    formatCurrency(props.selectedReconciliation.reconBalance)}
                            </DetailsText>
                        </DetailsRow>
                    </DetailsColumn>
                    <DetailsColumn sx={{width: {xs: "100%", lg: "33.3%"}}}>
                        <DetailsRow
                            sx={{
                                flexDirection: "column",
                                flexWrap: "nowrap",
                                width: "100%",
                            }}
                        >
                            <DetailsLabel sx={{width: "100%"}}>
                                {" "}
                                Balance Difference:
                            </DetailsLabel>
                            <DetailsText sx={{width: "100%"}}>
                                {" "}
                                {props.selectedReconciliation &&
                                    props.selectedReconciliation.balanceDiff &&
                                    formatCurrency(props.selectedReconciliation.balanceDiff)}
                            </DetailsText>
                        </DetailsRow>
                        <DetailsRow
                            sx={{
                                flexDirection: "column",
                                flexWrap: "nowrap",
                                width: "100%",
                            }}
                        >
                            <DetailsLabel sx={{width: "100%"}}>
                                {" "}
                                Reconciliation Time:
                            </DetailsLabel>
                            <DetailsText sx={{width: "100%"}}>
                                {" "}
                                {props.selectedReconciliation &&
                                    props.selectedReconciliation.reconciliationTime &&
                                    formatDateTime(
                                        props.selectedReconciliation.reconciliationTime
                                    )}
                            </DetailsText>
                        </DetailsRow>
                        <DetailsRow
                            sx={{
                                flexDirection: "column",
                                flexWrap: "nowrap",
                                width: "100%",
                            }}
                        >
                            <DetailsLabel sx={{width: "100%"}}>
                                {" "}
                                Reconciled By:
                            </DetailsLabel>
                            <DetailsText sx={{width: "100%"}}>
                                {" "}
                                {props.selectedReconciliation &&
                                    props.selectedReconciliation.reconciledBy &&
                                    props.selectedReconciliation.reconciledBy.name}
                            </DetailsText>
                        </DetailsRow>
                    </DetailsColumn>
                </DetailsContainer>

                <ResponsiveRow sx={{margin: "20px auto"}}>
                    <ResponsiveRow sx={{width: "100%"}}>
                        <Typography
                            id="view"
                            width={"100%"}
                            variant="h4"
                            marginBottom="20px"
                            component="h6"
                        >
                            Reconciled Transaction History{" "}
                            {isArray(props.reconciledTransactionDetails)
                                ? `${props.reconciledTransactionDetails.length} Transactions`
                                : ""}
                        </Typography>
                    </ResponsiveRow>
                    <Divider/>

                    <MuiDataGrid
                        dataGridColumns={props.reconciledTransactionDetailsColumns}
                        dataGridRows={props.reconciledTransactionDetails}
                        serverPagination={true}
                        noBoxShadow={true}
                        noSelection={true}
                        handlePageLoad={props.loadPage}
                        loading={props.loading}
                        height="65vh"
                        totalRows={props.reconciledTransactionDetailsTotal}
                    />
                </ResponsiveRow>
            </ResponsiveRow>
        </ModalContainerModule>
    );
}
