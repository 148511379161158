import { Typography } from "@mui/material";
import {
  ModalAcceptButton,
  ModalCancelButton,
} from "../../constants/component.constants";

import { ResponsiveRow } from "../../constants/layout.constants";

export default function BillingPaymentsTabBar(props) {
  return (
    <ResponsiveRow
      sx={{
        flexWrap: "nowrap",
        flexDirection: { xs: "column", lg: "row" },

        width: "100%",
        height: "auto",

        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography
        variant="h4"
        color="primary.dark"
        fontWeight={700}
        sx={{
          width: { xs: "100%", lg: "25%" },
          textAlign: { xs: "center", lg: "start" },
        }}
      >
        Payments
      </Typography>
      <ResponsiveRow
        sx={{
          width: { xs: "100%", lg: "75%" },
          height: "auto",
          justifyContent: "flex-end",
        }}
      >
        {props.expanded ? (
          <ModalCancelButton
            onClick={() => props.handleCloseSearch()}
            sx={{
              width: { xs: "100%", lg: "200px" },
            }}
          >
            Close Search
          </ModalCancelButton>
        ) : props.searchResult ? (
          <ModalCancelButton
            onClick={() => props.handleCloseSearch()}
            sx={{
              width: { xs: "100%", lg: "200px" },
            }}
          >
            Clear Search
          </ModalCancelButton>
        ) : (
          <ModalAcceptButton
            onClick={() => props.handleExpandClick()}
            sx={{
              width: { xs: "100%", lg: "200px" },
            }}
          >
            Search
          </ModalAcceptButton>
        )}

        <ModalAcceptButton
          onClick={props.handleMakePaymentClick}
          sx={{
            width: { xs: "100%", lg: "200px" },
          }}
        >
          Make Payment
        </ModalAcceptButton>
      </ResponsiveRow>
    </ResponsiveRow>
  );
}
