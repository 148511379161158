/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
//this shall be used to manage all users (tenants,owners,clients,staff)
// in the system (depending on subscriptions and rights)
import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { isEmptyArray, isEmptyString, isNullUndefined } from "../util/util";
import {
  getUserRoles,
  listTotalUsers,
  listUsers,
  removeUser,
  saveUser,
} from "../services/users.service";
import { personSearch } from "../services/shared.service";
import { getIdTypes } from "../services/list.service";
import {
  MainContainer,
  TopBarContainedBrandButton,
  TopBarContainedCancelButton,
  TopBarContainer,
} from "../constants/component.constants";
import { useSelector } from "react-redux";
import ViewUserModal from "../components/modules/users/viewUserModal";
import DeleteUserModal from "../components/modules/users/deleteUserModal";
import EditUserModal from "../components/modules/users/editUserModal";
import { ResponsiveRow } from "../constants/layout.constants";
import IndividualSearchModal from "../components/modals/individualSearchModal";
import UserItemCard from "../components/cards/userItemCard";
import Permit from "../util/permit";
import { issueResponseMessage, issueWarnMessage } from "../actions/message";
import LoadingOverlay from "../components/overlays/loadingOverlay";

export default function Users() {
  const user = useSelector((state) => state.auth.user);
  const [limitItems, setLimitItems] = useState(9);
  const [pageNo] = useState(0);
  const [userRoles, setUserRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState("");
  const [filterByRole, setFilterByRole] = useState("");
  const [name, setName] = useState("");
  const [usersList, setUsersList] = useState([]);
  const [totalUsersList, setTotalUsersList] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [userToView, setUserToView] = useState(null);
  const [showAddUserForm, setShowAddUserForm] = useState(false);
  const [userModal, setUserModal] = useState(false);
  const [individual] = useState(true);
  const [idNoSearch, setIdNo] = useState("");
  const [idTypeSearch, setIdType] = useState("");
  const [idTypes, setIdTypes] = useState([]);
  const [searchBy] = useState("Individual");
  const [foundUser, setFoundUser] = useState([]);
  const [noFoundUser, setNoFoundUser] = useState(false);
  const [task, setTask] = useState("");
  const [viewUser, setViewUser] = useState(false);
  const [deleteUserModal, setDeleteUserModal] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    listUsers(name, filterByRole, pageNo, limitItems).then((data) => {
      setUsersList(data.users);
      setLoading(false);
    });
    listTotalUsers(name, filterByRole).then((data) =>
      setTotalUsersList(data.users)
    );
  }, [user.proxyFacility]);

  useEffect(() => {
    setLoading(true);
    getUserRoles().then((userRoles) => setUserRoles(userRoles));
    listUsers(name, filterByRole, pageNo, limitItems).then((data) => {
      setUsersList(data.users);
      setLoading(false);
    });
    listTotalUsers(name, filterByRole).then((data) =>
      setTotalUsersList(data.users)
    );
    getIdTypes().then((idTypes) => setIdTypes(idTypes));
  }, [name, filterByRole, pageNo, limitItems]);

  const handleRoleChange = (event) => {
    let value = event.target.value;
    setSelectedRole(value);
    let obj = selectedUser;
    obj.roleCode = value;
    setSelectedUser(obj);
  };

  const handleFilterRoleChange = (event) => {
    let value = event.target.value;
    setFilterByRole(value);
  };

  function onTextChange(event) {
    const id = event.target.id;
    const value = event.target.value;
    if (id === "searchUser") {
      setName(value);
    }
    if (id === "idNoSearch") {
      setIdNo(value);
      selectedUser.idNumber = value;
    }
  }

  function handleAddUserClose() {
    setShowAddUserForm(false);
    setSelectedUser(null);
    setSelectedRole("");
    setIdType("");
    setIdNo("");
    setNoFoundUser(false);
  }

  function handleViewUserClose() {
    setViewUser(false);
    setUserToView(null);
  }

  function handleIndividualSearchClose() {
    setFoundUser([]);
    setUserModal(false);
    setIdType("");
    setIdNo("");
    setNoFoundUser(false);
  }

  function searchPersonOrg() {
    let data = {
      searchType: searchBy,
      idType: idTypeSearch,
      idNumber: idNoSearch,
    };
    personSearch(data).then((response) => {
      setFoundUser(response);
      setNoFoundUser(false);
      if (isEmptyArray(response)) {
        setNoFoundUser(true);
        setFoundUser([]);
      }
    });
  }

  function showAddNewUserForm() {
    setUserModal(false);
    setShowAddUserForm(true);
    setSelectedRole("");
    setViewUser(false);
  }

  function handleAddUserClicked() {
    setTask("Create");
    setSelectedUser(null);
    setUserModal(true);
    let data = {
      id: null,
      firstName: "",
      middleName: "",
      lastName: "",
      mobilePhone: "",
      officePhone: "",
      email: "",
      credentialId: "",
      idNumber: "",
      idTypeCd: "",
      photoFile: "",
      name: "",
      organization: null,
      roleCode: "",
      roleName: "", // not really used in the form but keeping this field to maintain consistency between server json and client side json
      permissions: [],
    };
    setSelectedUser(data);
  }

  function clearFilter() {
    setName("");
    setFilterByRole("");
  }

  function handlePersonOrgSelect(result) {
    let obj = selectedUser;
    obj.id = result.id.replace("_P", "");
    obj.firstName = result.firstName;
    obj.lastName = result.lastName;
    obj.mobilePhone = result.mobilePhone;
    obj.idTypeCd = result.idType;
    obj.email = result.email;
    obj.credentialId = result.credentialId;
    setIdType(result.idType);
    obj.idNumber = result.idNumber;
    setIdNo(result.idNumber);
    setSelectedUser(obj);
    setUserModal(false);
    setIdType("");
    setIdNo("");
    setFoundUser([]);
    setShowAddUserForm(true);
    setSelectedRole("");
    setViewUser(false);
  }

  function handleIdTypeChange(event) {
    let value = event.target.value;
    setIdType(value);
    let obj = selectedUser;
    obj.idTypeCd = value;
    setSelectedUser(obj);
  }

  function validateForm(sendData) {
    if (isNullUndefined(sendData)) {
      issueWarnMessage(
        "Could not find user data, please refresh the page and try again."
      );
      return false;
    }
    if (isEmptyString(sendData.firstName) || isEmptyString(sendData.lastName)) {
      issueWarnMessage("First name and Last name are required.");
      return false;
    }
    if (
      isNullUndefined(sendData.mobilePhone) ||
      isEmptyString(sendData.mobilePhone)
    ) {
      issueWarnMessage("Mobile Phone number is required.");
      return false;
    }
    if (
      isNullUndefined(sendData.idNumber) ||
      isEmptyString(sendData.idNumber) ||
      isNullUndefined(sendData.idTypeCd) ||
      isEmptyString(sendData.idTypeCd)
    ) {
      issueWarnMessage("Must provide and ID number and ID type.");
      return false;
    }
    if (isNullUndefined(sendData.email) || isEmptyString(sendData.email)) {
      issueWarnMessage("Must provide an email.");
      return false;
    }
    if (
      isNullUndefined(sendData.roleCode) ||
      isEmptyString(sendData.roleCode)
    ) {
      issueWarnMessage("Must select a role to assign to this user.");
      return false;
    }

    return true;
  }

  function addUserClosed() {
    setSelectedUser(null);
    setTask("");
    setSelectedRole("");
    setShowAddUserForm(false);
  }

  function handleSaveUserClicked() {
    let params = selectedUser;
    if (validateForm(params)) {
      // console.log("user data", params)
      saveUser(params).then((response) => {
        setLoading(true);
        listUsers(name, filterByRole, pageNo, limitItems).then((data) => {
          setUsersList(data.users);
          setLoading(false);
        });
        listTotalUsers(name, filterByRole).then((data) =>
          setTotalUsersList(data.users)
        );
        issueResponseMessage(response);
        addUserClosed();
      });
    }
  }

  function handleDeleteUserClicked() {
    let sendData = { userId: userToDelete.id };
    removeUser(sendData).then((response) => {
      setLoading(true);
      listUsers(name, filterByRole, pageNo, limitItems).then((data) => {
        setUsersList(data.users);
        setLoading(false);
      });
      listTotalUsers(name, filterByRole).then((data) =>
        setTotalUsersList(data.users)
      );
      setDeleteUserModal(false);
      issueResponseMessage(response);
    });
  }

  function handleEditSelectedUser(item) {
    setTask("Edit");
    setSelectedUser(item);
    setShowAddUserForm(true);
  }

  function handleViewSelectedUser(item) {
    setTask("View");
    setUserToView(item);
    setViewUser(true);
  }

  function handleDeleteUser(item) {
    setTask("Delete");
    setDeleteUserModal(true);
    setUserToDelete(item);
  }

  function handleCloseDeleteUserModal() {
    setDeleteUserModal(false);
    setUserToDelete(null);
  }

  function handleLoadMore() {
    if (
      !isNullUndefined(totalUsersList) &&
      !isNullUndefined(usersList) &&
      usersList.length < totalUsersList.length
    ) {
      setLimitItems(limitItems + 9);
      setLoading(true);
      listUsers(name, filterByRole, pageNo, limitItems).then((data) => {
        setUsersList(data.users);
        setLoading(false);
      });
    }
  }

  return (
    <Permit
      roles="BLDIR,BLDMG,CS"
      services="PREMIUM,CORE,HOA,BLWTR,MAINT,METER"
      noPermittedServiceMessage={{
        title: "Access Denied",
        body: (
          <>
            Your current role and/or facility is not permitted to view this
            page. <br /> Please contact your system admin if you feel this is an
            error.
          </>
        ),
      }}
    >
      <MainContainer>
        <TopBarContainer sx={{ width: { xs: "100%", lg: "80%" } }} container>
          <Grid
            sx={{
              width: { xs: "100%", lg: "50%" },
              justifyContent: "flex-start",
            }}
          >
            <FormControl
              sx={{ width: { xs: "100%", lg: "50%" }, marginBottom: "10px" }}
            >
              <InputLabel id="searchUserLabel">Search User</InputLabel>
              <OutlinedInput
                id="searchUser"
                label="Search User"
                sx={{
                  width: { xs: "100%", lg: "90%" },
                }}
                value={name}
                onChange={(searchText) => onTextChange(searchText)}
                inputProps={
                  <TextField
                    sx={{
                      width: { xs: "100%", lg: "90%" },
                      fontSize: "10px",
                    }}
                  />
                }
              />
            </FormControl>
            <FormControl
              sx={{
                width: { xs: "100%", lg: "50%" },
              }}
            >
              <InputLabel id="roleSelect">Role</InputLabel>
              <Select
                defaultValue=""
                id="filterUser"
                value={filterByRole}
                label="Role"
                sx={{
                  width: { xs: "100%", lg: "90%" },
                }}
                onChange={handleFilterRoleChange}
              >
                {userRoles && userRoles.length > 0 ? (
                  userRoles.map(function (role, i) {
                    return (
                      <MenuItem key={role.value} value={role.value}>
                        {role.label}
                      </MenuItem>
                    );
                  }, this)
                ) : (
                  <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid>
          <ResponsiveRow
            item
            sx={{
              width: { xs: "100%", lg: "50%" },
              height: "auto",
              padding: 0,
              justifyContent: { xs: "center", lg: "flex-end" },
            }}
          >
            <TopBarContainedCancelButton
              sx={{ width: { xs: "100%", lg: "200px" }, height: "50px" }}
              onClick={clearFilter}
            >
              Clear Filters
            </TopBarContainedCancelButton>
            <Permit roles="BLDIR">
              <TopBarContainedBrandButton
                sx={{ width: { xs: "100%", lg: "200px" }, height: "50px" }}
                onClick={handleAddUserClicked}
              >
                Add User
              </TopBarContainedBrandButton>
            </Permit>
          </ResponsiveRow>
        </TopBarContainer>

        <br />

        {viewUser && (
          <ViewUserModal
            handleViewUserClose={handleViewUserClose}
            viewUser={viewUser}
            userToView={userToView}
          />
        )}

        {deleteUserModal && (
          <DeleteUserModal
            handleDeleteUserClicked={handleDeleteUserClicked}
            handleCloseDeleteUserModal={handleCloseDeleteUserModal}
            deleteUserModal={deleteUserModal}
            userToDelete={userToDelete}
          />
        )}

        <Box sx={{ flexGrow: 1 }}>
          {loading ? (
            <ResponsiveRow
              sx={{
                height: "50vh",
                width: "100%",
                flexDirection: "row",
                flexWrap: "nowrap",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoadingOverlay message="Loading users, please wait " />
            </ResponsiveRow>
          ) : isEmptyArray(usersList) ? (
            <ResponsiveRow
              sx={{
                height: "50vh",
                width: "100%",
                flexDirection: "row",
                flexWrap: "nowrap",

                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography color="black" fontWeight={700}>
                No Users found!
              </Typography>
            </ResponsiveRow>
          ) : (
            <>
              <ResponsiveRow
                container
                sx={{
                  width: "100%",
                  height: "auto",
                  justifyContent: "center",
                }}
              >
                {usersList &&
                  usersList.map((item, i) => (
                    <UserItemCard
                      key={i}
                      handleViewSelectedUser={handleViewSelectedUser}
                      handleEditSelectedUser={handleEditSelectedUser}
                      handleDeleteUser={handleDeleteUser}
                      item={item}
                    />
                  ))}
              </ResponsiveRow>
              <Grid>
                <br />
                {usersList &&
                  totalUsersList &&
                  usersList.length < totalUsersList.length && (
                    <Button
                      variant="outlined"
                      type="submit"
                      onClick={handleLoadMore}
                      sx={{ height: 27 }}
                    >
                      Load More
                    </Button>
                  )}
              </Grid>
            </>
          )}
        </Box>

        <IndividualSearchModal
          showAddNewUserForm={showAddNewUserForm}
          handleIdTypeChange={handleIdTypeChange}
          handleIndividualSearchClose={handleIndividualSearchClose}
          handlePersonOrgSelect={handlePersonOrgSelect}
          foundUser={foundUser}
          idNoSearch={idNoSearch}
          individual={individual}
          idTypes={idTypes}
          idTypeSearch={idTypeSearch}
          noFoundUser={noFoundUser}
          onTextChange={onTextChange}
          searchPersonOrg={searchPersonOrg}
          userModal={userModal}
        />

        {showAddUserForm && (
          <EditUserModal
            key={"edit-user-modal"}
            showAddNewUserForm={showAddNewUserForm}
            handleSaveUserClicked={handleSaveUserClicked}
            handleAddUserClose={handleAddUserClose}
            handleRoleChange={handleRoleChange}
            selectedRole={selectedRole}
            selectedUser={selectedUser}
            setSelectedUser={setSelectedUser}
            task={task}
            idTypeSearch={idTypeSearch}
            idTypes={idTypes}
            idNoSearch={idNoSearch}
            userRoles={userRoles}
            handleIdTypeChange={handleIdTypeChange}
          />
        )}
      </MainContainer>
    </Permit>
  );
}
