import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography,} from "@mui/material";
import {ModalAcceptButton} from "../../../../constants/component.constants";
import {ResponsiveRow} from "../../../../constants/layout.constants";

export default function VendorExpenseItems(props) {
    /**
     *
     * REQUIRED PROPS
     *
     * categoryAccountItems
     * handleAddItems
     *
     */

    return (
        <ResponsiveRow
            sx={{
                width: "100%",
                height: "95%",
                margin: 0,
                alignItems: "flex-start",
            }}
        >
            <Typography
                sx={{marginBottom: "5px"}}
                id="vendors"
                variant="h6"
                component="h6"
                Expense
                Categories
            >
                Vendor Category and Accounts
            </Typography>
            <TableContainer sx={{height: "70%"}}>
                <Table sx={{width: "100%"}} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{width: "45%"}}>Vendor Category</TableCell>
                            <TableCell sx={{width: "45%"}}>Expense Account</TableCell>
                            <TableCell sx={{width: "10%"}}>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>{props.categoryAccountItems}</TableBody>
                </Table>
            </TableContainer>
            <ResponsiveRow
                sx={{
                    justifyContent: {xs: "center", lg: "flex-end"},
                    alignItems: "flex-end",
                    height: "20%",
                }}
                container
            >
                <ModalAcceptButton
                    //   type="submit"
                    onClick={() => props.handleAddItems()}
                    sx={{
                        width: {xs: "100%", lg: "200px"},
                        height: "40px",
                        marginTop: "20px",
                    }}
                >
                    Add Row
                </ModalAcceptButton>
            </ResponsiveRow>
        </ResponsiveRow>
    );
}
