import {Typography} from "@mui/material";
import {useEffect, useState} from "react";
import {ResponsiveRow} from "../../../constants/layout.constants";
import ViewExpiringLeaseModal from "../../modals/dashboard/buildingDirector/viewExpiringLeaseModal";
import {getLeasesEnding} from "../../../services/dashboard.service";
import MuiDataGrid from "../MuiDataGrid";
import {useSelector} from "react-redux";

/**
 *
 * REQUIRED PROPS
 *
 * customWidth: string
 * customHeight: string
 * data: {
 *    id: number
 *    unit: string
 *    tenant: string
 *    date: string
 *    rent: number
 *  }
 */

export default function ExpiringLeases({customHeight, customWidth}) {
    const [dataGridRows, setDataGridRows] = useState([]);
    const [viewLeaseModal, setViewLeaseModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const {user} = useSelector((state) => state.auth)

    function handleCloseViewModal() {
        setViewLeaseModal(false);
    }

    function handleViewAcceptModal() {
        handleCloseViewModal();
    }

    const DataGridColumns = [
        {
            field: "unit",
            headerName: "Unit",
            width: 200,
        },
        {
            field: "tenant",
            headerName: "Tenant Name",
            minWidth: 200,
            flex: 1,
        },
        {
            field: "date",
            headerName: "Expiry Date",
            minWidth: 150,
            flex: 0.75,
        },
        {
            field: "rent",
            headerName: "Rent",
            type: "number",
            minWidth: 150,
            flex: 0.75,
        },
    ];

    useEffect(() => {
        getLeasesEnding()
            .then((response) => {
                if (response && response.data) {
                    setDataGridRows(response.data);
                }
            })
            .finally(() => {
                setLoading(false);
            });
        return () => {
            setDataGridRows([]);
            setViewLeaseModal(false);
        };
    }, [user.proxyFacility]);

    return (
        <ResponsiveRow
            sx={{
                flexDirection: "column",
                flexWrap: "nowrap",

                alignItems: "flex-start",

                padding: "20px",

                height: {xs: "100%", lg: customHeight},
                minWidth: {xs: "100%", lg: customWidth},
                width: "100%",

                margin: "10px",

                backgroundColor: "#fff",
                borderRadius: "20px",
            }}
        >
            <Typography
                sx={{
                    width: "100%",
                }}
                textAlign="start"
                variant="h5"
            >
                Leases Ending Soon (30 days)
            </Typography>

            <MuiDataGrid
                alt={true}
                dataGridColumns={DataGridColumns}
                dataGridRows={dataGridRows}
                serverPagination={false}
                loading={loading}
                height="90%"
                totalRows={dataGridRows.length}
            />

            <ViewExpiringLeaseModal
                handleAccept={handleViewAcceptModal}
                handleClose={handleCloseViewModal}
                open={viewLeaseModal}
            />
        </ResponsiveRow>
    );
}
