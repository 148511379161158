/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import {
  isEmptyString,
  isNullUndefined,
  tryParseFloat,
  tryParseInt,
} from "../../util/util";
import { getAllUnitOptions } from "../../services/list.service";
import { setMessage } from "../../actions/message";
import { addWorkOrderChargeData } from "../../services/workOrder.service";
import { ResponsiveRow } from "../../constants/layout.constants";
import ModalContainerModule from "../modules/modalContainer";
import { useDispatch } from "react-redux";

/**
 * This is a shared Work order add charge component to add word order charge to vendor detail
 *
 * Required props are:
 * - showAddChargeModal: This is either true or false to show or hide modal respectively
 * - selectedWorkOrder: This is the selectedWorkOrder
 * - handleChargeClose: This is a function to close the modal ie set showAddChargeModal to false
 * - vendor: This is the vendor that charge is added to
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 * @author jmbuthia
 */
export default function WorkOrderAddCharge(props) {
  const [description, setDescription] = useState("");
  const [unitId, setUnitId] = useState("");
  const [unitPrice, setUnitPrice] = useState("");
  const [subTotal, setSubTotal] = useState(0);
  const [quantity, setQuantity] = useState("");
  const [applyVAT, setApplyVAT] = useState(false);
  const [total, setTotal] = useState(0);
  const [otherCharges, setOtherCharges] = useState("");
  const [unitOptions, setUnitOptions] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      !isNullUndefined(props.selectedWorkOrder) &&
      !isNullUndefined(props.selectedWorkOrder.facilityId)
    ) {
      getAllUnitOptions(props.selectedWorkOrder.facilityId)
        .then((unitOptions) => {
          setUnitOptions(unitOptions);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  }, [props.selectedWorkOrder]);

  useEffect(() => {
    updateTotal();
  }, [otherCharges, applyVAT, quantity, unitPrice]);

  function onTextChange(event) {
    const id = event.target.id;
    const value = event.target.value;
    if (id === "unitPrice") {
      setUnitPrice(value);
    }

    if (id === "quantity") {
      setQuantity(value);
    }

    if (id === "otherCharges") {
      setOtherCharges(value);
    }

    if (id === "description") {
      if (description.length >= 300) {
        dispatch(
          setMessage({
            type: "warning",
            message: "Description should be less than 300 characters",
          })
        );
      }
      setDescription(value);
    }
  }

  function updateTotal() {
    let total =
      tryParseFloat(unitPrice, 0) *
        tryParseInt(quantity, 0) *
        (applyVAT ? 0.16 : 0) +
      tryParseFloat(otherCharges, 0) +
      tryParseFloat(unitPrice, 0) * tryParseInt(quantity, 0);
    let subTotal = tryParseFloat(unitPrice, 0) * tryParseInt(quantity, 0);
    setSubTotal(subTotal);
    setTotal(total);
  }

  function handleChargeTaxableToggle(event) {
    let value = event.target.checked;
    setApplyVAT(value);
  }

  function handleUnitChange(event) {
    let value = event.target.value;
    setUnitId(value);
  }

  function addCharge() {
    let sendData = {};

    sendData.workOrderId = props.selectedWorkOrder.id;
    sendData.vendorId = props.vendor.vendorId;

    if (isNullUndefined(description) || isEmptyString(description)) {
      dispatch(
        setMessage({
          type: "warning",
          message: "A description must be provided",
        })
      );
      return;
    }
    if (description.length > 300) {
      dispatch(
        setMessage({
          type: "warning",
          message: "Description cannot be more than 300 characters",
        })
      );
      return;
    }
    sendData.description = description;
    sendData.unitId = unitId;

    sendData.unitPrice = tryParseFloat(unitPrice, 0);
    if (sendData.unitPrice < 1) {
      dispatch(
        setMessage({
          type: "warning",
          message: "Unit price must be greater than 0",
        })
      );
      return;
    }

    sendData.quantity = tryParseInt(quantity, 0);
    if (sendData.quantity < 1) {
      dispatch(
        setMessage({
          type: "warning",
          message: "Quantity value must be greater than 0",
        })
      );
      return;
    }

    sendData.otherCharges = tryParseFloat(otherCharges, 0);
    if (sendData.otherCharges < 0) {
      dispatch(
        setMessage({
          type: "warning",
          message: "Other/Additional charges cannot be less than 0",
        })
      );
      return;
    }

    sendData.subTotal = tryParseFloat(subTotal, 0);
    sendData.total = tryParseFloat(total, 0);
    sendData.applyVAT = applyVAT;

    // console.log('sendData', sendData)
    // All fields valid
    addWorkOrderChargeData(sendData)
      .then((response) => {
        props.refreshDetails();
        dispatch(
          setMessage({
            type: response.status,
            message: response.message,
          })
        );
        props.handleChargeClose();
      })
      .catch((error) => {
        console.log("addWorkOrderChargeData: ", error);
      });
  }

  return (
    <ModalContainerModule
      size="specialWorkOrderAddCharge"
      accept={addCharge}
      cancel={props.handleChargeClose}
      openModal={props.showAddChargeModal}
      modalTitle={`Add a New Charge to work Order ${props.selectedWorkOrder.refNumber} for vendor ${props.vendor.vendorName}`}
      acceptButtonText="Add Charge"
    >
      <ResponsiveRow container sx={{ height: "auto" }}>
        <FormControl sx={{ width: "100%", margin: "auto" }}>
          <InputLabel>Description*</InputLabel>
          <OutlinedInput
            sx={{
              marginBottom: "10px",
              width: "95%",
            }}
            id="description"
            label={"Description*"}
            placeholder={
              "e.g. Describe this new charge/vendor. What will this vendor do? What is the charge covering?"
            }
            size="small"
            onChange={(event) => onTextChange(event)}
            value={description}
            multiline
            rows={4}
            
          />
        </FormControl>

        <ResponsiveRow sx={{ height: "auto" }}>
          {props.selectedWorkOrder && props.selectedWorkOrder.facilityId && (
            <FormControl
              item
              sx={{
                width: { xs: "100%", lg: "33.3%" },
                marginBottom: "10px",
              }}
            >
              <InputLabel sx={{ width: { xs: "100%", lg: "auto" } }}>
                Unit
              </InputLabel>
              <Select
                label="Unit"
                sx={{ width: { xs: "100%", lg: "90%" } }}
                id={"unitId"}
                value={unitId}
                onChange={(event) => handleUnitChange(event)}
              >
                {unitOptions && unitOptions.length > 0 ? (
                  unitOptions.map(function (unit, i) {
                    return (
                      <MenuItem key={unit.value} value={unit.value}>
                        {unit.label}
                      </MenuItem>
                    );
                  }, this)
                ) : (
                  <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
                )}
              </Select>
            </FormControl>
          )}

          <FormControl
            item
            sx={{ width: { xs: "100%", lg: "33.3%" }, marginBottom: "10px" }}
          >
            <InputLabel sx={{ width: { xs: "100%", lg: "auto" } }}>
              Unit Price
            </InputLabel>
            <OutlinedInput
              label="Unit Price"
              sx={{ width: { xs: "100%", lg: "90%" } }}
              id={"unitPrice"}
              value={unitPrice}
              onChange={(event) => onTextChange(event)}
              
            />
          </FormControl>

          <FormControl
            item
            sx={{ width: { xs: "100%", lg: "33.3%" }, marginBottom: "10px" }}
          >
            <InputLabel sx={{ width: { xs: "100%", lg: "auto" } }}>
              Quantity
            </InputLabel>
            <OutlinedInput
              label="Quantity"
              sx={{ width: { xs: "100%", lg: "90%" } }}
              id={"quantity"}
              value={quantity}
              onChange={(event) => onTextChange(event)}
              
            />
          </FormControl>

          <FormControl
            item
            sx={{ width: { xs: "100%", lg: "33.3%" }, marginBottom: "10px" }}
          >
            <InputLabel sx={{ width: { xs: "100%", lg: "auto" } }}>
              Sub-Total
            </InputLabel>
            <OutlinedInput
              label="Sub-Total"
              sx={{ width: { xs: "100%", lg: "90%" } }}
              id={"subTotal"}
              value={subTotal}
              disabled
              onChange={(event) => onTextChange(event)}
            />
          </FormControl>

          <FormControl
            item
            sx={{
              width: { xs: "100%", lg: "33.3%" },
              marginBottom: "10px",
              textAlign: "start",
            }}
          >
            <FormLabel>Tax (16% VAT)</FormLabel>
            <FormControlLabel
              control={
                <Checkbox
                  checked={applyVAT}
                  sx={{ width: "auto" }}
                  onChange={(event) => handleChargeTaxableToggle(event)}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label="Charge VAT"
              disabled={
                isNullUndefined(total) || isEmptyString(total) || total === 0
              }
            />
          </FormControl>

          <FormControl
            item
            sx={{ width: { xs: "100%", lg: "33.3%" }, marginBottom: "10px" }}
          >
            <InputLabel sx={{ width: { xs: "100%", lg: "auto" } }}>
              Additional Charges
            </InputLabel>
            <OutlinedInput
              sx={{ width: { xs: "100%", lg: "90%" } }}
              id={"otherCharges"}
              value={otherCharges}
              onChange={(event) => onTextChange(event)}
              
            />
          </FormControl>

          <FormControl
            item
            sx={{ width: { xs: "100%", lg: "33.3%" }, marginBottom: "10px" }}
          >
            <InputLabel sx={{ width: { xs: "100%", lg: "auto" } }}>
              Total
            </InputLabel>
            <OutlinedInput
              label="Total"
              sx={{ width: { xs: "100%", lg: "90%" } }}
              id={"total"}
              value={total}
              disabled
              onChange={(event) => onTextChange(event)}
              
            />
          </FormControl>
        </ResponsiveRow>
      </ResponsiveRow>
    </ModalContainerModule>
  );
}
