import React from "react";
import kodi_theme from "../../../themes/kodiThemeProvider";
import {
  DetailsColumn,
  DetailsContainer,
  DetailsLabel,
  DetailsRow,
  DetailsText,
} from "../../../constants/component.constants";
import { ResponsiveRow } from "../../../constants/layout.constants";
import { Button, Typography } from "@mui/material";
import { formatCurrency } from "../../../util/util";
import { useSelector } from "react-redux";

export default function SubscriptionPropertyCard({
  property,
  handleEditProperty,
  handleDeleteProperty,
}) {
  const user = useSelector((state) => state.auth.user);

  return (
    <ResponsiveRow
      sx={{
        flexDirection: "column",
        flexWrap: "nowrap",

        width: { xs: "100%", lg: "49%" },
        height: "auto",

        margin: { xs: "10px 0", lg: "10px 5px" },
        padding: "0",

        backgroundColor: "#fff",
        borderRadius: "10px",
      }}
    >
      <ResponsiveRow
        sx={{
          marginTop: 0,
          padding: "20px",

          backgroundColor: "#037171",
          borderRadius: "10px 10px 0 0",

          width: "100%",
          height: "auto",

          justifyContent: { xs: "center", lg: "space-between" },
          alignItem: "center",
        }}
      >
        <Typography
          id="facilityCard"
          variant="h5"
          component="h5"
          sx={{
            textAlign: "start",
            color: "#ffffff",
            // backgroundColor: '#037171',
            width: { xs: "100%", lg: "100%" },
          }}
        >
          {property.name}
        </Typography>
      </ResponsiveRow>
      <ResponsiveRow sx={{ padding: "2px 20px", height: "auto" }}>
        <Typography
          textAlign="start"
          fontWeight={800}
          color="primary.dark"
          sx={{
            width: "100%",
            margin: "10px auto 0 auto",
          }}
          variant="h6"
        >
          Services ({property.subscriptionServices?.length})
        </Typography>
      </ResponsiveRow>
      <ResponsiveRow
        sx={{
          flexDirection: "column",
          flexWrap: "nowrap",

          justifyContent: "flex-start",
          alignItems: "flex-start",

          padding: "2px 10px",
          height: "175px",

          overflowY: "auto",
          marginBottom: "10px",
        }}
      >
        <>
          {property.subscriptionServices?.length > 0 &&
            property.subscriptionServices.map((service, i) => (
              <ResponsiveRow
                sx={{
                  flexDirection: "column",
                  flexWrap: "nowrap",

                  justifyContent: "flex-start",
                  alignItems: "flex-start",

                  backgroundColor: kodi_theme.palette.grey[200],
                  borderRadius: "10px",

                  marginBottom: "5px",

                  width: "100%",
                  height: "auto",
                }}
                key={i}
              >
                <Typography
                  variant="body2"
                  textAlign="start"
                  marginY={"0px"}
                  sx={{
                    width: "auto",
                    height: "auto",

                    color: kodi_theme.palette.common.white,

                    padding: "5px 10px",
                    backgroundColor: kodi_theme.palette.primary.light,
                    borderRadius: "10px",
                  }}
                  fontWeight={700}

                  //   color="text.primary"
                >
                  {service.name}
                </Typography>
                <DetailsContainer
                  sx={{ width: "100%", padding: 0, marginTop: 0 }}
                >
                  <DetailsColumn sx={{ width: { xs: "100%", lg: "50%" } }}>
                    <DetailsRow sx={{ width: { xs: "100%", lg: "100%" } }}>
                      <DetailsLabel sx={{ fontSize: "0.9rem" }}>
                        Total Cost
                      </DetailsLabel>
                      <DetailsText sx={{ fontSize: "0.85rem" }}>
                        {formatCurrency(service.totalCost)}
                      </DetailsText>
                    </DetailsRow>
                    <DetailsRow sx={{ width: { xs: "100%", lg: "100%" } }}>
                      <DetailsLabel sx={{ fontSize: "0.9rem" }}>
                        Quantity
                      </DetailsLabel>
                      <DetailsText sx={{ fontSize: "0.85rem" }}>
                        {service.quantity}
                      </DetailsText>
                    </DetailsRow>
                  </DetailsColumn>
                  <DetailsColumn sx={{ width: { xs: "100%", lg: "50%" } }}>
                    <DetailsRow sx={{ width: { xs: "100%", lg: "100%" } }}>
                      <DetailsLabel sx={{ fontSize: "0.9rem" }}>
                        {" "}
                        Discount (%)
                      </DetailsLabel>
                      <DetailsText sx={{ fontSize: "0.85rem" }}>
                        {service.discountPercentage}
                      </DetailsText>
                    </DetailsRow>
                    <DetailsRow sx={{ width: { xs: "100%", lg: "100%" } }}>
                      <DetailsLabel
                        sx={{
                          width: { xs: "100%", lg: "50%" },
                          height: "auto",
                          fontSize: "0.85rem",
                        }}
                      >
                        Discount Amount
                      </DetailsLabel>
                      <DetailsText
                        sx={{
                          width: { xs: "100%", lg: "50%" },
                          height: "auto",
                          fontSize: "0.85rem",
                        }}
                      >
                        {formatCurrency(service.discountedCost)}
                      </DetailsText>
                    </DetailsRow>
                  </DetailsColumn>
                </DetailsContainer>
              </ResponsiveRow>
            ))}
        </>
      </ResponsiveRow>

      <ResponsiveRow
        sx={{
          width: "100%",
          height: "auto",

          justifyContent: "center",
          alignItems: "flex-start",

          padding: 0,

          overflowY: "hidden",
          overflowX: "hidden",
        }}
      >
        <Typography
          sx={{
            width: "100%",
            textAlign: "start",
            fontWeight: 800,
            color: "#037171",
            margin: "0 auto 10px auto",
            padding: "0 10px",
          }}
          variant="h5"
        >
          Users
        </Typography>
      </ResponsiveRow>
      <ResponsiveRow
        sx={{
          width: "100%",
          height: "250px",

          justifyContent: "flex-start",
          alignItems: "flex-start",
          padding: { xs: 0, lg: "2px 10px" },

          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        {property.users?.length > 0 &&
          property.users.map((user, i) => (
            <DetailsContainer
              sx={{
                width: { xs: "100%", lg: "45%" },
                height: "auto",

                marginBottom: "5px",

                padding: "5px 10px",
                margin: "5px",

                justifyContent: "space-between",

                backgroundColor: kodi_theme.palette.grey[200],
                borderRadius: "10px",
              }}
              key={i}
            >
              {" "}
              <Typography
                variant="h6"
                textAlign="start"
                sx={{
                  width: "100%",
                  height: "auto",

                  margin: "10px 10px 5px 10px",
                  color: kodi_theme.palette.primary.main,
                }}
              >
                {user.name}
              </Typography>
              <DetailsColumn
                sx={{ width: "100%", padding: "0 10px 0 10px", margin: 0 }}
              >
                <DetailsRow
                  sx={{
                    width: { xs: "100%", lg: "100%" },
                    flexDirection: "column",
                  }}
                >
                  <DetailsLabel
                    sx={{ width: "100%", height: "auto", fontSize: "0.9rem" }}
                  >
                    System Role
                  </DetailsLabel>
                  <DetailsText
                    sx={{ width: "100%", height: "auto", fontSize: "0.85rem" }}
                  >
                    {" "}
                    {user.role}
                  </DetailsText>
                </DetailsRow>
                {/* </DetailsColumn>
              <DetailsColumn sx={{ width: "50%" }}> */}
                <DetailsRow
                  sx={{
                    width: { xs: "100%", lg: "100%" },
                    flexDirection: "column",
                  }}
                >
                  <DetailsLabel
                    sx={{ width: "100%", height: "auto", fontSize: "0.9rem" }}
                  >
                    Email Address
                  </DetailsLabel>
                  <DetailsText
                    sx={{ width: "100%", height: "auto", fontSize: "0.85rem" }}
                  >
                    {user.email}
                  </DetailsText>
                </DetailsRow>
                <DetailsRow
                  sx={{
                    width: { xs: "100%", lg: "100%" },
                    flexDirection: "column",
                  }}
                >
                  <DetailsLabel
                    sx={{ width: "100%", height: "auto", fontSize: "0.9rem" }}
                  >
                    Phone Number
                  </DetailsLabel>
                  <DetailsText
                    sx={{ width: "100%", height: "auto", fontSize: "0.85rem" }}
                  >
                    {user.phone}
                  </DetailsText>
                </DetailsRow>
              </DetailsColumn>
            </DetailsContainer>
          ))}
      </ResponsiveRow>
      <ResponsiveRow
        sx={{
          width: "100%",
          height: "70px",

          justifyContent: { xs: "center", lg: "flex-end" },
          alignItem: "center",
        }}
      >
        <Button
          variant="contained"
          type="submit"
          sx={{ marginLeft: "10px", width: "150px", height: "40px" }}
          onClick={(event) => handleEditProperty(property)}
          color="primary"
        >
          Edit
        </Button>
        <Button
          variant="contained"
          type="submit"
          sx={{ marginLeft: "10px", width: "150px", height: "40px" }}
          onClick={(event) => handleDeleteProperty(property)}
          color="error"
          disabled={user && user.proxyRole === "CS"}
        >
          Delete
        </Button>
      </ResponsiveRow>
    </ResponsiveRow>
  );
}
