import {Box} from "@mui/system"
import listingHero from "../../../images/listings/listing_hero_background.png"
import {ResponsiveRow} from "../../../constants/layout.constants"
import {Typography} from "@mui/material"
import Location from "@mui/icons-material/LocationOn"
import Straighten from "@mui/icons-material/Straighten"
import {useNavigate} from "react-router-dom"

export default function RecommendedListing() {
    const navigate = useNavigate()

    return (
        <ResponsiveRow
            onClick={() => {
                navigate("/listings/view/1")
            }}
            sx={{
                width: {xs: "100%", lg: "350px"},
                height: "375px",
                borderRadius: "20px",
                alignItems: "flex-start",
                backgroundColor: "#fff",
                padding: 0,
                margin: "10px 5px",
                "&:hover": {
                    transform: "scale(1.1)",
                    transitionDuration: "ease-in-out 700ms",
                },
            }}
        >
            <Box
                component="img"
                src={listingHero}
                sx={{
                    width: "100%",
                    height: "175px",
                    objectFit: "cover",
                    borderRadius: "20px 20px 0 0",
                }}
            />
            <ResponsiveRow
                sx={{
                    flexDirection: "column",
                    flexWrap: "nowrap",
                    width: "100%",
                    height: "40%",
                    textAlign: "start",
                    padding: "10px 20px",
                }}
            >
                <Typography
                    variant="body2"
                    sx={{color: "#afafaf", fontWeight: 600, marginBottom: "5px"}}
                >
                    Ksh 30,000.00
                </Typography>

                <Typography variant="h4" sx={{fontWeight: 700, marginBottom: "10px"}}>
                    Modern Luxury Villas
                </Typography>
                <Typography
                    variant="body2"
                    sx={{marginBottom: "5px", color: "#00B9AE"}}
                >
                    <Location sx={{fontSize: "1.2rem"}}/> 24 Ita Rd, Westlands, Kenya
                </Typography>
                <Typography sx={{fontSize: "1rem", marginBottom: "10px"}}>
                    <Straighten sx={{fontSize: "1.2rem"}}/> 2000 SQ/FT
                </Typography>
            </ResponsiveRow>
        </ResponsiveRow>
    )
}
