/* eslint-disable react-hooks/exhaustive-deps */
import { ResponsiveRow } from "../constants/layout.constants";
import { useEffect, useState } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";

import { formatDate, isEmptyString, isNullUndefined } from "../util/util";
import {
  ModalAcceptButton,
  ModalCancelButton,
  TopBarContainedBrandButton,
  TopBarContainedCancelButton,
  TopBarContainer,
} from "../constants/component.constants";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/en-gb";
import { renderCellExpand } from "../util/renderCellExpand";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { Delete, Print } from "@mui/icons-material";
import { printCharge } from "../services/print.service";
import { setMessage } from "../actions/message";
import { useDispatch, useSelector } from "react-redux";
import {
  exportDebitNotes,
  getTotalDebitNotes,
  listDebitNotes,
} from "../services/debitNotes.service";
import ExportDocumentsModal from "../components/modals/exportDocuments";
import CreditNote from "../components/modals/creditNote";
import MuiDataGrid from "../components/modules/MuiDataGrid";
import dayjs from "dayjs";

export default function DebitNotes() {
  const user = useSelector((state) => state.auth.user);
  const [referenceNumber, setReferenceNumber] = useState("");
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [chargeTo, setChargeTo] = useState("");
  const [chargeDateFrom, setChargeDateFrom] = useState("");
  const [chargeDateTo, setChargeDateTo] = useState("");
  const [dateApprovedFrom, setDateApprovedFrom] = useState("");
  const [dateApprovedTo, setDateApprovedTo] = useState("");
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 50,
  });
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [task, setTask] = useState("");
  const [selectionModel, setSelectionModel] = useState([]);
  const [debitNotesList, setDebitNotesList] = useState([]);
  const [titleText, setTitleText] = useState("");
  const [severityText, setSeverityText] = useState("");
  const [bodyText, setBodyText] = useState("");
  const [downloadDebitNotesModal, setdownloadDebitNotesModal] = useState(false);
  const [voiditem, setVoiditem] = useState(null);
  const [creditModal, setCreditModal] = useState(false);
  const [leaseId, setLeaseId] = useState("");
  const [titleId, setTitleId] = useState("");
  const [meterBillingId, setMeterBillingId] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    listDebitNotes(
      referenceNumber,
      chargeDateFrom,
      chargeDateTo,
      dateApprovedFrom,
      dateApprovedTo,
      selectedStatus,
      chargeTo,
      paginationModel.page | 0,
      paginationModel.pageSize | 25
    )
      .then((data) => {
        setDebitNotesList(data);
      })
      .finally(() => {
        setLoading(false);
      });
    getTotalDebitNotes(
      referenceNumber,
      chargeDateFrom,
      chargeDateTo,
      dateApprovedFrom,
      dateApprovedTo,
      selectedStatus,
      chargeTo
    ).then((data) => {
      setTotal(data.totalDebitNotes);
    });
  }, [user.proxyFacility]);

  useEffect(() => {
    setLoading(true);
    listDebitNotes(
      referenceNumber,
      chargeDateFrom,
      chargeDateTo,
      dateApprovedFrom,
      dateApprovedTo,
      selectedStatus,
      chargeTo,
      paginationModel.page | 0,
      paginationModel.pageSize | 50
    )
      .then((data) => {
        setDebitNotesList(data);
      })
      .catch((error) => {
        console.log("error listing debitNotes", error);
      })
      .finally(() => {
        setLoading(false);
      });
    getTotalDebitNotes(
      referenceNumber,
      chargeDateFrom,
      chargeDateTo,
      dateApprovedFrom,
      dateApprovedTo,
      selectedStatus,
      chargeTo
    )
      .then((data) => {
        setTotal(data.totalDebitNotes);
      })
      .catch((error) => {
        console.log("error getting total debitNotes", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    referenceNumber,
    chargeDateFrom,
    chargeDateTo,
    dateApprovedFrom,
    dateApprovedTo,
    selectedStatus,
    chargeTo,
    paginationModel.page,
  ]);

  // console.log("debitNotes: ", debitNotesList);
  // console.log("total: ", total);

  const selectOptions = [
    { label: "Paid", value: "PAID" },
    { label: "Partial", value: "PAR" },
    { label: "Voided", value: "VOID" },
    { label: "Approved", value: "APP" },
    { label: "Pending", value: "PEN" },
    { label: "Rejected", value: "REJ" },
  ];

  function handleSearchClicked() {
    setTask("Filter");
  }

  function handleClearFilterClicked() {
    setTask("");
    setReferenceNumber("");
    setSelectedStatus("");
    setChargeTo("");
    setChargeDateFrom("");
    setChargeDateTo("");
    setDateApprovedFrom("");
    setDateApprovedTo("");
    setSelectionModel([]);
  }

  function handleExportDebitNotes() {
    setdownloadDebitNotesModal(true);
    let title =
      selectionModel.length > 1
        ? "Download " + selectionModel.length + " Debit Notes"
        : "Download Debit Note";
    let body =
      "Note: Processing the selected debit notes may take some time." +
      " Therefore, the exported data will be emailed to you when it is finished being packaged." +
      " Please ensure the email set for your account is valid, working and that you have access to it in order to receive the export data.";
    setTitleText(title);
    setSeverityText("info");
    setBodyText(body);
  }

  function handleCloseDownloadDebitNotesModal() {
    setdownloadDebitNotesModal(false);
    setSelectionModel([]);
    setTitleText("");
    setTask("");
    setSeverityText("");
    setBodyText("");
    setTask("Filter");
  }

  function exportSelectedDebitNotes() {
    if (isNullUndefined(selectionModel) || selectionModel.size < 1) {
      dispatch(
        setMessage({
          type: "warning",
          message:
            "Could not determine the download items. Please refresh the page and try again.",
        })
      );
      return;
    }
    let sendData = {
      debitNoteIds: selectionModel,
    };
    exportDebitNotes(sendData)
      .then((response) => {
        dispatch(
          setMessage({
            type: response.status,
            message: response.message,
          })
        );
        handleCloseDownloadDebitNotesModal();
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  function handleVoidClicked(item) {
    let tmp = item;
    tmp.type = "Charge";
    console.log("item to void", tmp);
    setVoiditem(tmp);
    setCreditModal(true);
    if (!isEmptyString(item.leaseId)) setLeaseId(item.leaseId);
    if (!isEmptyString(item.titleId)) setTitleId(item.titleId);
    if (!isEmptyString(item.meterBillingId))
      setMeterBillingId(item.meterBillingId);
  }

  const handleCreditClose = () => {
    setVoiditem(null);
    setCreditModal(false);
  };

  const columns = [
    {
      headerName: "Reference No.",
      field: "referenceNumber",
      minWidth: 145,
      flex: 1.45,
    },
    { headerName: "Amount", field: "amount", minWidth: 130, flex: 1.3 },
    {
      headerName: "Date Created",
      field: "dateCreated",
      minWidth: 160,
      fleX: 1.6,
      valueFormatter: (params) => {
        return formatDate(params.value);
      },
    },
    {
      headerName: "Date Approved",
      field: "dateApproved",
      minWidth: 160,
      flex: 1.6,
      valueFormatter: (params) => {
        return formatDate(params.value);
      },
    },
    {
      headerName: "Charged To (Per/Org)",
      field: "tenant",
      minWidth: 200,
      flex: 2,
      valueFormatter: (params) => {
        return params.value.name;
      },
    },
    {
      headerName: "Description",
      field: "memo",
      minWidth: 350,
      flex: 3.5,
      renderCell: renderCellExpand,
    },
    {
      headerName: "Status",
      field: "status",
      minWidth: 150,
      flex: 1.5,
      valueFormatter: (params) => {
        return params.value.name;
      },
    },
    {
      headerName: "Actions",
      field: "actions",
      width: 100,
      type: "actions",
      getActions: (params) => {
        let arr = [];
        arr.push(
          <GridActionsCellItem
            icon={<Print />}
            onClick={() => {
              printCharge(params.id)
                .then((response) => {
                  dispatch(
                    setMessage({
                      type: response.status,
                      message: response.message,
                    })
                  );
                })
                .catch((error) => {
                  console.log("error", error);
                });
            }}
            label="Print Debit Note"
            showInMenu
          />
        );
        params.row.status.code !== "VOID" &&
          arr.push(
            <GridActionsCellItem
              icon={<Delete />}
              onClick={() => handleVoidClicked(params.row)}
              label="Void Debit Note"
              showInMenu
            />
          );

        return arr;
      },
    },
  ];

  return (
    <ResponsiveRow
      sx={{
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "auto",
      }}
    >
      <TopBarContainer
        sx={{
          margin: "20px auto 20px auto",
          padding: 0,
          borderRadius: "10px",

          backgroundColor: "white",
          boxShadow: "4px 4px 12px #aaa",

          width: { xs: "100%", lg: "90%" },
          minHeight: "80px",

          justifyContent: "center",
          alignItems: "center",

          flexDirection: { xs: "column", lg: "row" },
          flexWrap: "nowrap",
        }}
      >
        <Typography
          variant="h5"
          sx={{
            padding: "20px",
            textAlign: { xs: "center", lg: "start" },

            width: { xs: "100%", lg: "25%" },
            alignItems: "flex-start",
          }}
        >
          Debit Notes
        </Typography>
        <ResponsiveRow
          sx={{
            flexWrap: "nowrap",
            justifyContent: { xs: "center", lg: "flex-end" },

            width: { xs: "100%", lg: "75%" },
            padding: task === "Filter" ? "10px" : 0,
          }}
        >
          {task === "Filter" && (
            <>
              <TopBarContainedBrandButton
                disabled={selectionModel.length < 1 ? true : false}
                onClick={handleExportDebitNotes}
                sx={{
                  width: { xs: "100%", lg: "200px" },
                  height: "50px",
                }}
              >
                {selectionModel.length > 1
                  ? "Download " + selectionModel.length + " Debit Notes"
                  : "Download Debit Note"}
              </TopBarContainedBrandButton>
              <TopBarContainedCancelButton
                onClick={handleClearFilterClicked}
                sx={{
                  width: { xs: "100%", lg: "200px" },
                  height: "50px",
                }}
              >
                Clear Search
              </TopBarContainedCancelButton>
            </>
          )}
        </ResponsiveRow>
      </TopBarContainer>

      {task !== "Filter" && (
        <ResponsiveRow
          sx={{
            width: { xs: "100%", lg: "80%" },
            height: "auto",
            margin: "auto",

            backgroundColor: "white",
            borderRadius: "10px",
            boxShadow: "4px 4px 12px #aaa",

            padding: "30px 20px",
          }}
          container
        >
          <Typography
            variant="h6"
            color="#037171"
            sx={{
              width: "100%",
              marginBottom: "20px",
              textAlign: "start",
              padding: "0 10px",
            }}
          >
            Credit Notes Search Form
          </Typography>
          <FormControl
            item
            sx={{
              width: { xs: "100%", md: "33.3%" },
              marginBottom: "10px",
            }}
          >
            <TextField
              label="Reference Number"
              placeholder={"Reference Number"}
              sx={{ width: "90%" }}
              id={"referenceNumber"}
              onChange={(event) => {
                const value = event.target.value;
                setReferenceNumber(value);
              }}
              value={!isEmptyString(referenceNumber) ? referenceNumber : ""}
            />
          </FormControl>

          <FormControl
            item
            sx={{
              width: { xs: "100%", md: "33.3%" },
              marginBottom: "10px",
            }}
          >
            <InputLabel>Status</InputLabel>
            <Select
              label="Status"
              sx={{ width: "90%" }}
              id={"status"}
              value={selectedStatus}
              onChange={(event) => {
                let value = event.target.value;
                setSelectedStatus(value);
              }}
            >
              {selectOptions && selectOptions.length > 0 ? (
                selectOptions.map(function (status, i) {
                  return (
                    <MenuItem key={status.value} value={status.value}>
                      {status.label}
                    </MenuItem>
                  );
                }, this)
              ) : (
                <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
              )}
            </Select>
          </FormControl>

          <FormControl
            item
            sx={{
              width: { xs: "100%", md: "33.3%" },
              marginBottom: "10px",
            }}
          >
            <TextField
              label="Charged To"
              placeholder={"Charged To"}
              sx={{ width: "90%" }}
              id={"chargedTo"}
              onChange={(event) => {
                const value = event.target.value;
                setChargeTo(value);
              }}
              value={!isEmptyString(chargeTo) ? chargeTo : ""}
            />
          </FormControl>

          <FormControl
            item
            sx={{
              width: { xs: "100%", md: "33.3%" },
              marginBottom: "10px",
            }}
          >
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale="en-gb"
            >
              <DatePicker
                label="Date Created From"
                sx={{ width: "90%" }}
                id={"dateCreatedFrom"}
                value={
                  isEmptyString(chargeDateFrom) ? null : dayjs(chargeDateFrom)
                }
                isrequired={false}
                onChange={(newDate) => {
                  // console.log("date Value ", newDate);
                  let date = new Date(newDate);
                  setChargeDateFrom(date);
                }}
              />
            </LocalizationProvider>
          </FormControl>

          <FormControl
            item
            sx={{
              width: { xs: "100%", md: "33.3%" },
              marginBottom: "10px",
            }}
          >
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale="en-gb"
            >
              <DatePicker
                label="Date Created To"
                sx={{ width: "90%" }}
                id={"dateCreatedTo"}
                value={isEmptyString(chargeDateTo) ? null : dayjs(chargeDateTo)}
                isrequired={false}
                onChange={(newDate) => {
                  let date = new Date(newDate);
                  setChargeDateTo(date);
                }}
              />
            </LocalizationProvider>
          </FormControl>

          <FormControl
            item
            sx={{
              width: { xs: "100%", md: "33.3%" },
              marginBottom: "10px",
            }}
          >
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale="en-gb"
            >
              <DatePicker
                label="Date Approved From"
                sx={{ width: "90%" }}
                id={"dateApprovedFrom"}
                value={
                  isEmptyString(dateApprovedFrom)
                    ? null
                    : dayjs(dateApprovedFrom)
                }
                isrequired={false}
                onChange={(newDate) => {
                  console.log("date Value ", newDate);
                  let date = new Date(newDate);
                  setDateApprovedFrom(date);
                }}
              />
            </LocalizationProvider>
          </FormControl>

          <FormControl
            item
            sx={{
              width: { xs: "100%", md: "33.3%" },
              marginBottom: "10px",
            }}
          >
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale="en-gb"
            >
              <DatePicker
                label="Date Approved To"
                sx={{ width: "90%" }}
                id={"dateApprovedTo"}
                value={
                  isEmptyString(dateApprovedTo) ? null : dayjs(dateApprovedTo)
                }
                isrequired={false}
                onChange={(newDate) => {
                  let date = new Date(newDate);
                  setDateApprovedTo(date);
                }}
              />
            </LocalizationProvider>
          </FormControl>

          <ResponsiveRow
            item
            sx={{
              justifyContent: { xs: "center", md: "flex-end" },
              width: "100%",
              height: "auto",
            }}
          >
            <ModalAcceptButton
              onClick={handleSearchClicked}
              sx={{ width: { xs: "100%", lg: "200px" } }}
            >
              Search
            </ModalAcceptButton>
            <ModalCancelButton
              onClick={handleClearFilterClicked}
              sx={{
                width: { xs: "100%", lg: "200px" },
              }}
            >
              Clear
            </ModalCancelButton>
          </ResponsiveRow>
        </ResponsiveRow>
      )}

      {task === "Filter" && (
        <MuiDataGrid
          dataGridColumns={columns}
          dataGridRows={debitNotesList}
          serverPagination={true}
          handlePageLoad={setPaginationModel}
          loading={loading}
          height="60vh"
          totalRows={total}
          currentSelectionModel={selectionModel}
          handleSelectedRows={setSelectionModel}
        />
      )}

      {/*Download/Export Document via Email... Shared Modal*/}
      {downloadDebitNotesModal && (
        <ExportDocumentsModal
          showExportDocumentsModal={downloadDebitNotesModal}
          handleExportDocumentsClose={handleCloseDownloadDebitNotesModal}
          title={titleText}
          severity={severityText}
          body={bodyText}
          handleExportDocuments={exportSelectedDebitNotes}
          handleCancel={handleCloseDownloadDebitNotesModal}
        />
      )}

      {/*Shared credit note component*/}
      {creditModal && (
        <CreditNote
          open={creditModal}
          onClose={handleCreditClose}
          voiditem={voiditem}
          leaseid={leaseId}
          titleId={titleId}
          meterBillingId={meterBillingId}
        />
      )}
    </ResponsiveRow>
  );
}
