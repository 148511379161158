import {Box, Grid, Typography} from "@mui/material"
import {formatDate, titleCase} from "../../util/util"
import {
    DetailsColumn,
    DetailsContainer,
    DetailsLabel,
    DetailsRow,
    DetailsText,
} from "../../constants/component.constants"

export default function WorkOrderInfoCard(props) {
    return (
        <Grid
            container
            spacing={1}
            sx={{
                padding: "20px",
                width: {lg: "100%", xs: "100%", md: "100%"},
                backgroundColor: "#fff",
                borderRadius: "10px",
                minHeight: "240px",
                margin: "20px auto",
                boxShadow: "0 4px 12px 0 rgba(0,0,0,0.25)",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    margin: "auto 20px",
                    width: "100%",
                    height: "fit-content",
                }}
            >
                <Typography
                    sx={{
                        fontWeight: 800,
                        fontSize: "18px",
                    }}
                >
                    Work Order Information
                </Typography>
            </Box>

            <DetailsContainer
                sx={{width: "100%", padding: {xs: "10px", lg: "10px 40px"}}}
            >
                <DetailsColumn sx={{width: {xs: "100%", lg: "50%"}}}>
                    <Typography
                        sx={{
                            fontWeight: 800,
                            fontSize: "16px",
                            textAlign: "start",
                            color: "#037171",
                            marginBottom: "20px",
                        }}
                    >
                        Current State Information
                    </Typography>

                    <DetailsRow>
                        <DetailsLabel>Priority</DetailsLabel>
                        <DetailsText>
                            {titleCase(
                                props.selectedWorkOrder && props.selectedWorkOrder.priority
                            )}
                        </DetailsText>
                    </DetailsRow>
                    <DetailsRow>
                        <DetailsLabel>Status</DetailsLabel>
                        <DetailsText>
                            {props.selectedWorkOrder && props.selectedWorkOrder.status.name}
                        </DetailsText>
                    </DetailsRow>
                    <DetailsRow>
                        <DetailsLabel>Expected Completion</DetailsLabel>
                        <DetailsText>
                            {props.selectedWorkOrder &&
                            props.selectedWorkOrder.expectedCompletion
                                ? formatDate(props.selectedWorkOrder.expectedCompletion)
                                : "N/A"}
                        </DetailsText>
                    </DetailsRow>
                </DetailsColumn>
                <DetailsColumn sx={{width: {xs: "100%", lg: "50%"}}}>
                    <Typography
                        sx={{
                            fontWeight: 800,
                            fontSize: "16px",
                            textAlign: "start",
                            color: "#037171",
                            marginBottom: "20px",
                        }}
                    >
                        Access Information
                    </Typography>
                    <DetailsRow>
                        <DetailsLabel>Applies To</DetailsLabel>
                        <DetailsText>
                            {props.selectedWorkOrder && props.selectedWorkOrder.appliedTo
                                ? props.selectedWorkOrder.appliedTo
                                : "N/A"}
                        </DetailsText>
                    </DetailsRow>
                    <DetailsRow>
                        <DetailsLabel>Requested By</DetailsLabel>
                        <DetailsText>
                            {props.selectedWorkOrder && props.selectedWorkOrder.requestedBy
                                ? props.selectedWorkOrder.requestedBy.name
                                : "N/A"}
                        </DetailsText>
                    </DetailsRow>
                    <DetailsRow>
                        <DetailsLabel>Approved By</DetailsLabel>
                        <DetailsText>
                            {props.selectedWorkOrder && props.selectedWorkOrder.approvedBy
                                ? props.selectedWorkOrder.approvedBy.name
                                : "N/A"}
                        </DetailsText>
                    </DetailsRow>
                </DetailsColumn>
            </DetailsContainer>
        </Grid>
    )
}
