import {FormControl, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import {ResponsiveRow} from "../../../constants/layout.constants";
import LineChartsCard from "../../charts/LineChartCard";
import "dayjs/locale/en-gb";
import {getIncomeVsExpense} from "../../../services/dashboard.service";
import dayjs from "dayjs";
import {YearPickerInput} from "../../inputs/datePickerInput";
import {useSelector} from "react-redux";

/**
 *
 *
 * REQUIRED PROPS
 *
 * customHeight: string
 * customWidth: string
 * data: {
 *    incomeData: []
 *    expensesData: []
 *  }
 *
 *
 */

export default function IncomeVsExpenses({customHeight, customWidth}) {
    const [incomeExpenseData, setIncomeExpenseData] = useState({
        actualIncome: null,
        actualExpense: null,
        expectedIncome: null,
        expectedExpense: null,
    });
    const [year, setYear] = useState(new Date());
    const [loading, setLoading] = useState(false);
    const {user} = useSelector((state) => state.auth)
    useEffect(() => {
        setLoading(true);
        getIncomeVsExpense(year.getFullYear())
            .then((response) => {
                if (response && response.data) {
                    setIncomeExpenseData(response.data);
                }
            }).finally(() => {
            setLoading(false);
        });
        return () => {
            setIncomeExpenseData({
                actualIncome: null,
                actualExpense: null,
                expectedIncome: null,
                expectedExpense: null,
            });
        };
    }, [year, user.proxyFacility]);

    return (
        <ResponsiveRow
            sx={{
                flexDirection: "column",
                flexWrap: "nowrap",
                height: {xs: "100%", lg: customHeight},
                mimWidth: {xs: "100%", lg: customWidth},
                width: "100%",
                margin: "10px",
                backgroundColor: "#fff",
                borderRadius: "20px",
            }}
        >
            <ResponsiveRow
                sx={{
                    flexDirection: "row",
                    width: "100%",
                    height: "auto",
                    textAlign: "start",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
                <Typography sx={{width: "75%"}} variant="h5">
                    Income vs Expenses
                </Typography>
                <FormControl sx={{width: {xs: "100%", lg: "25%"}}}>
                    <YearPickerInput
                        componentID="incomeVsExpenseDate"
                        sx={{width: {xs: "100%", lg: "90%"}, padding: "0 5px"}}
                        labelText="Select Year"
                        disableFuture={true}
                        disablePast={false}
                        value={dayjs(year)}
                        onSelectDate={newDate => setYear(new Date(newDate))}
                    />

                    {/*<LocalizationProvider*/}
                    {/*  dateAdapter={AdapterDayjs}*/}
                    {/*  adapterLocale="en-gb"*/}
                    {/*>*/}
                    {/*  <LocalizationProvider utils={DateFnsUtils}>*/}
                    {/*    <DatePicker*/}
                    {/*      id="incomeVsExpenseDate"*/}
                    {/*      label="Select Year"*/}
                    {/*      sx={{*/}
                    {/*        backgroundColor: "#fff",*/}
                    {/*        width: { xs: "100%", lg: "90%" },*/}
                    {/*        margin: "auto",*/}
                    {/*      }}*/}
                    {/*      openTo="year"*/}
                    {/*      disableFuture*/}
                    {/*      views={["year"]}*/}
                    {/*      minDate="2015"*/}
                    {/*      // maxDate={dayjs()}*/}
                    {/*      value={dayjs(year)}*/}
                    {/*      InputLabelProps={{*/}
                    {/*        shrink: true,*/}
                    {/*      }}*/}
                    {/*      onChange={(new_value) => setYear(new Date(new_value))}*/}
                    {/*      renderInput={(params) => (*/}
                    {/*        <TextField*/}
                    {/*          sx={{*/}
                    {/*            width: "90%",*/}
                    {/*            backgroundColor: "#fff",*/}
                    {/*            borderRadius: "10px",*/}
                    {/*          }}*/}
                    {/*          {...params}*/}
                    {/*          helperText={null}*/}
                    {/*        />*/}
                    {/*      )}*/}
                    {/*    />*/}
                    {/*  </LocalizationProvider>*/}
                    {/*</LocalizationProvider>*/}
                </FormControl>
            </ResponsiveRow>

            {/* {isNullUndefined(incomeExpenseData.actualIncome) &&
      isNullUndefined(incomeExpenseData.actualExpense) &&
      isNullUndefined(incomeExpenseData.expectedIncome) &&
      isNullUndefined(incomeExpenseData.expectedExpense) ? (
        <ResponsiveRow
          sx={{
            margin: "0",

          flexWrap: "nowrap",
          flexDirection: "row",

          alignItem: "center",
          justifyContent: "center",
        }}
      >
        <LineChartsCard
          dataObj={{
            dataLabels: [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
              "Oct",
              "Nov",
              "Dec",
            ],

            dataset: [
              {
                datasetLabel: "Income",
                datasetData: incomeData,
              },
              {
                datasetLabel: "Expenses",
                datasetData: expensesData,
              },
            ],
          }}
        >
          <Typography
            variant="body2"
            sx={{ width: "100%", height: "auto", margin: "auto" }}
          >
            No data
          </Typography>
        </ResponsiveRow>
      ) : ( */}
            <ResponsiveRow
                sx={{
                    height: "80%",
                    width: "100%",

                    flexWrap: "nowrap",
                    flexDirection: "row",

                    alignItem: "center",
                    justifyContent: "center",
                }}
            >
                <LineChartsCard
                    currentlyLoading={loading}
                    labelDataObj={{
                        dataLabels: [
                            "Jan",
                            "Feb",
                            "Mar",
                            "Apr",
                            "May",
                            "Jun",
                            "Jul",
                            "Aug",
                            "Sep",
                            "Oct",
                            "Nov",
                            "Dec",
                        ],
                        dataset: [
                            {
                                datasetLabel: "Actual Income",
                                datasetData: incomeExpenseData.actualIncome,
                            },
                            {
                                datasetLabel: "Actual Expense",
                                datasetData: incomeExpenseData.actualExpense,
                            },
                            {
                                datasetLabel: "Expected Income",
                                datasetData: incomeExpenseData.expectedIncome,
                            },
                            {
                                datasetLabel: "Expected Expense",
                                datasetData: incomeExpenseData.expectedExpense,
                            },
                        ],
                    }}
                />
            </ResponsiveRow>
            {/* )} */}
        </ResponsiveRow>
    );
}
