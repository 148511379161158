import {
  Alert,
  AlertTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/en-gb";
import { useCallback, useEffect, useState } from "react";
import { downloadReport, exportReport } from "../../services/reports.service";
import {
  DOWNLOAD_BALANCE_SHEET_URL,
  DOWNLOAD_BANK_ACCOUNT_CASH_BOOK_REPORT_URL,
  DOWNLOAD_BUDGET_VS_ACTUAL_REPORT_URL,
  DOWNLOAD_CLIENT_MOBILE_MONEY_RECONCILIATION_REPORT_URL,
  DOWNLOAD_COLLECTIONS_BY_CAPITAL_FUND_REPORT_URL,
  DOWNLOAD_COLLECTIONS_BY_SERVICE_CHARGE_REPORT_URL,
  DOWNLOAD_COLLECTIONS_BY_UTILITIES_REPORT_URL,
  DOWNLOAD_COLLECTIONS_REPORT_URL,
  DOWNLOAD_CUSTOMER_BALANCES_REPORT_URL,
  DOWNLOAD_CUSTOMER_OUTSTANDING_BALANCES_REPORT_URL,
  DOWNLOAD_DAILY_SALES_REPORT_URL,
  DOWNLOAD_ELECTRICITY_INCOME_VS_EXPENSE_REPORT_URL,
  DOWNLOAD_EQUIPMENT_MAINTENANCE_REPORT_URL,
  DOWNLOAD_FACILITY_REPORT_URL,
  DOWNLOAD_GENERAL_LEDGER_REPORT_URL,
  DOWNLOAD_INCOME_REPORT_URL,
  DOWNLOAD_MAINTENANCE_COST_ANALYSIS_REPORT_URL,
  DOWNLOAD_MAINTENANCE_HISTORY_REPORT_URL,
  DOWNLOAD_MAINTENANCE_REQUEST_REPORT_URL,
  DOWNLOAD_MANAGEMENT_INCOME_REPORT_URL,
  DOWNLOAD_METER_VARIANCE_REPORT_URL,
  DOWNLOAD_MOBILE_MONEY_REPORT_URL,
  DOWNLOAD_PETTY_CASH_REPORT_URL,
  DOWNLOAD_PORTFOLIO_RENT_ROLL_REPORT_URL,
  DOWNLOAD_RECONCILIATION_REPORT_URL,
  DOWNLOAD_RENT_ROLL_REPORT_URL,
  DOWNLOAD_SMARTKODI_MOBILE_MONEY_REPORT_URL,
  DOWNLOAD_VACANT_UNITS_REPORT_URL,
  DOWNLOAD_VAT_IN_REPORT_URL,
  DOWNLOAD_VAT_OUT_REPORT_URL,
  DOWNLOAD_VAT_REPORT_URL,
  DOWNLOAD_VENDOR_PERFORMANCE_REPORT_URL,
  DOWNLOAD_WATER_INCOME_VS_EXPENSE_REPORT_URL,
  DOWNLOAD_WITHHOLDING_TAX_REPORT_URL,
  DOWNLOAD_WORK_ORDER_REPORT_URL,
} from "../../constants/url.constants";
import ModalContainerModule from "../modules/modalContainer";
import { ResponsiveRow } from "../../constants/layout.constants";
import dayjs from "dayjs";
import { listApprovedBudgets } from "../../services/budgets.service";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import { dateDifference, formatDate } from "../../util/util";
import DownloadIcon from "@mui/icons-material/Download";
import { issueInfoMessage, issueWarnMessage } from "../../actions/message";
import { getLoggedInUser } from "../../services/profile.service";
import FormControlLabel from '@mui/material/FormControlLabel';

/**
 *Required props are:
 - showModal: True or false to show or hide modal respectively.
 - closeModal: Function to close the modal.
 - report: The report to be generated.
 */

export default function GenerateReport(props) {
	const [from, setFrom] = useState();
	const [to, setTo] = useState();
	const [docType, setDocType] = useState("pdf");
	// Will be set to true if we want a report with commission expected on all income - Not Rent income only.
	const [allIncome, setAllIncome] = useState(false);
	const [commissionRate, setCommissionRate] = useState("0");
	const [approvedBudgets, setApprovedBudgets] = useState([]);
	const [emailDoc, setEmailDoc] = useState(false);
	const [userEmail, setUserEmail] = useState("");
	let reportFormatOptions = [
		{ value: "pdf", label: "PDF" },
		{ value: "csv", label: "CSV" },
	];

	const getSessionUserEmail = useCallback(async function () {
		const user = await getLoggedInUser();
		return user?.email;
	}, []);

	useEffect(() => {
		async function fetchUserEmail() {
			const email = await getSessionUserEmail();
			setUserEmail(email);
		}
		fetchUserEmail();
	}, [getSessionUserEmail]);

	useEffect(() => {
		setFrom(dayjs().subtract(3, "month"));
		setTo(dayjs());
	}, []);

	useEffect(() => {
		if (
			props.report === "Rent Roll" ||
			props.report === "Client Mobile Money Statement" || 
			props.report === "Collections" ||
			props.report === "Collections By Capital Fund" ||
			props.report === "Collections By Service Charge" ||
			props.report === "Collections By Utilities"
		) {
			setDocType("csv");
		}

		if (props.report === "Budget Vs Actual") {
			listApprovedBudgets().then((data) => {
				setApprovedBudgets(data);
			});
		}
	}, [props]);

	// If the report is "Rent Roll", override the reportFormatOptions
	if (props.report === "Rent Roll" || 
		props.report === "Collections" ||
		props.report === "Collections By Capital Fund" ||
		props.report === "Collections By Service Charge" ||
		props.report === "Collections By Utilities") {
		reportFormatOptions = [{ value: "csv", label: "CSV" }];
	}

	function modalClose() {
		props.closeModal();
		let dateOffset = 24 * 60 * 60 * 1000 * 90;
		let daysAgo = dayjs() - dateOffset;
		setFrom(daysAgo);
		setTo(dayjs());
		setDocType("pdf");
		setCommissionRate("");
		setAllIncome(false);
	}

	function validateDates(data) {
		let { from, to } = data;
		if (from && to && from.isAfter(to)) {
			issueWarnMessage("From date cannot be after To date");
			return false;
		}
		const fromDateStr = from ? from.format("DD MMM YYYY") : "";
		const toDateStr = to ? to.format("DD MMM YYYY") : "";

		if (dateDifference(fromDateStr, toDateStr) > 365) {
			issueWarnMessage("Max 1 year date range");
			return false;
		}

		if (props.report === "Rent Roll" && to && to.isAfter(dayjs())) {
			issueWarnMessage("To date cannot be after today");
			return false;
		}

		if (from && from.isAfter(dayjs())) {
			issueWarnMessage("From date cannot be in the future");
			return false;
		}

		return true;
	}

	function handleStartDateChange(value) {
		setFrom(value);
	}

	function handleEndDateChange(value) {
		setTo(value);
	}

	function generateReport() {
		let data = {
			from: dayjs(from),
			to: to,
			docType: docType,
			emailDoc: false,
			download: false,
		};

		// let userEmail = getSessionUserEmail();

    let managementincomeData = {
      from: dayjs(from),
      to: to,
      docType: docType,
      commissionRate: commissionRate,
      commissionOnIncome: allIncome,
    };
    if (props.report === "Balance Sheet") {
      downloadReport(DOWNLOAD_BALANCE_SHEET_URL, data);
      props.closeModal();
    }
    if (props.report === "Budget Vs Actual") {
      downloadReport(DOWNLOAD_BUDGET_VS_ACTUAL_REPORT_URL, data);
      props.closeModal();
    }
    if (props.report === "Collections" || 
        props.report === "Collections By Capital Fund" || 
        props.report === "Collections By Service Charge" || 
        props.report === "Collections By Utilities") {
      if(validateDates(data)) {
        if(emailDoc) {
          data.emailDoc = true;
		  let message;
          let url;
          switch(props.report) {
            case "Collections":
              url = DOWNLOAD_COLLECTIONS_REPORT_URL;
			  message = `The Collections report is being exported and will be sent to your email (${userEmail}). Please wait few minutes for the email to reach your mailbox.`;
              break;
            case "Collections By Capital Fund":
              url = DOWNLOAD_COLLECTIONS_BY_CAPITAL_FUND_REPORT_URL;
			  message = `The Collection By Capital Fund report is being exported and will be sent to your email (${userEmail}). Please wait few minutes for the email to reach your mailbox.`;
              break;
            case "Collections By Service Charge":
              url = DOWNLOAD_COLLECTIONS_BY_SERVICE_CHARGE_REPORT_URL;
			  message = `The Collection By Service Charge report is being exported and will be sent to your email (${userEmail}). Please wait few minutes for the email to reach your mailbox.`;
              break;
            case "Collections By Utilities":
              url = DOWNLOAD_COLLECTIONS_BY_UTILITIES_REPORT_URL;
			  message = `The Collection By Utilities report is being exported and will be sent to your email (${userEmail}). Please wait few minutes for the email to reach your mailbox.`;
              break;
          }
          issueInfoMessage(message);
          exportReport(url, data);
        } else {
          data.download = true;
          let url;
          let message;
          switch(props.report) {
            case "Collections":
              url = DOWNLOAD_COLLECTIONS_REPORT_URL;
              message = `The Collections report is being downloaded.`;
              break;
            case "Collections By Capital Fund":
              url = DOWNLOAD_COLLECTIONS_BY_CAPITAL_FUND_REPORT_URL;
              message = `The Collection By Capital Fund report is being downloaded.`;
              break;
            case "Collections By Service Charge":
              url = DOWNLOAD_COLLECTIONS_BY_SERVICE_CHARGE_REPORT_URL;
              message = `The Collection By Service Charge report is being downloaded.`;
              break;
            case "Collections By Utilities":
              url = DOWNLOAD_COLLECTIONS_BY_UTILITIES_REPORT_URL;
              message = `The Collection By Utilities report is being downloaded.`;
              break;
          }
          issueInfoMessage(message);
          downloadReport(url, data);
        }
        props.closeModal();
      }
    }
    if (props.report === "Customer Balances") {
      downloadReport(DOWNLOAD_CUSTOMER_BALANCES_REPORT_URL, data);
      props.closeModal();
    }
    if (props.report === "Daily Sales") {
      downloadReport(DOWNLOAD_DAILY_SALES_REPORT_URL, data);
      props.closeModal();
    }
    if (props.report === "Electricity") {
      downloadReport(DOWNLOAD_ELECTRICITY_INCOME_VS_EXPENSE_REPORT_URL, data);
      props.closeModal();
    }
    if (props.report === "Facility") {
      downloadReport(DOWNLOAD_FACILITY_REPORT_URL, data);
      props.closeModal();
    }
    if (props.report === "Income") {
      downloadReport(DOWNLOAD_INCOME_REPORT_URL, data);
      props.closeModal();
    }
    if (props.report === "Petty Cash") {
      downloadReport(DOWNLOAD_PETTY_CASH_REPORT_URL, data);
      props.closeModal();
    }
    if (props.report === "Bank Account Cash Book") {
      downloadReport(DOWNLOAD_BANK_ACCOUNT_CASH_BOOK_REPORT_URL, data);
      props.closeModal();
    }
    if (props.report === "Work Order") {
      downloadReport(DOWNLOAD_WORK_ORDER_REPORT_URL, data);
      props.closeModal();
    }
    if (props.report === "Maintenance Request") {
      downloadReport(DOWNLOAD_MAINTENANCE_REQUEST_REPORT_URL, data);
      props.closeModal();
    }
    if (props.report === "Equipment Maintenance") {
      downloadReport(DOWNLOAD_EQUIPMENT_MAINTENANCE_REPORT_URL, data);
      props.closeModal();
    }
    if (props.report === "Management") {
      downloadReport(
        DOWNLOAD_MANAGEMENT_INCOME_REPORT_URL,
        managementincomeData
      );
      props.closeModal();
      modalClose();
    }
    if (props.report === "Meter Variance") {
      downloadReport(DOWNLOAD_METER_VARIANCE_REPORT_URL, data);
      props.closeModal();
    }
    if (props.report === "Mobile Money") {
      downloadReport(DOWNLOAD_MOBILE_MONEY_REPORT_URL, data);
      props.closeModal();
    }
    if (props.report === "Outstanding Balances") {
      downloadReport(DOWNLOAD_CUSTOMER_OUTSTANDING_BALANCES_REPORT_URL, data);
      props.closeModal();
    }
    if (props.report === "Portfolio") {
      downloadReport(DOWNLOAD_PORTFOLIO_RENT_ROLL_REPORT_URL, data);
      props.closeModal();
    }
    if (props.report === "Reconciliation") {
      downloadReport(DOWNLOAD_RECONCILIATION_REPORT_URL, data);
      props.closeModal();
    }
    if (props.report === "Rent Roll") {
      if(validateDates(data)) {
        downloadReport(DOWNLOAD_RENT_ROLL_REPORT_URL, data);
        props.closeModal();
      }
    }
    if (props.report === "Smartkodi Mobile Money") {
      downloadReport(DOWNLOAD_SMARTKODI_MOBILE_MONEY_REPORT_URL, data);
      props.closeModal();
    }
    if (props.report === "Vacant Units") {
      downloadReport(DOWNLOAD_VACANT_UNITS_REPORT_URL, data);
      props.closeModal();
    }
    if (props.report === "VAT") {
      downloadReport(DOWNLOAD_VAT_REPORT_URL, data);
      props.closeModal();
    }
    if (props.report === "Withholding Tax") {
      downloadReport(DOWNLOAD_WITHHOLDING_TAX_REPORT_URL, data);
      props.closeModal();
    }
    if (props.report === "VAT In") {
      downloadReport(DOWNLOAD_VAT_IN_REPORT_URL, data);
      props.closeModal();
    }
    if (props.report === "VAT Out") {
      downloadReport(DOWNLOAD_VAT_OUT_REPORT_URL, data);
      props.closeModal();
    }
    if (props.report === "Water") {
      console.log("PRINTING WATER REPORT", data);
      downloadReport(DOWNLOAD_WATER_INCOME_VS_EXPENSE_REPORT_URL, data);
      props.closeModal();
    }
    if (props.report === "Client Mobile Money Statement") {
      downloadReport(
        DOWNLOAD_CLIENT_MOBILE_MONEY_RECONCILIATION_REPORT_URL,
        data
      );
      props.closeModal();
    }
    if (props.report === "Vendor Report") {
      downloadReport(DOWNLOAD_VENDOR_PERFORMANCE_REPORT_URL, data);
      props.closeModal();
    }
    if (props.report === "Maintenance History Report") {
      downloadReport(DOWNLOAD_MAINTENANCE_HISTORY_REPORT_URL, data);
      props.closeModal();
    }
    if (props.report === "Maintenance Cost Analysis Report") {
      downloadReport(DOWNLOAD_MAINTENANCE_COST_ANALYSIS_REPORT_URL, data);
      props.closeModal();
    }
    if (props.report === "General Ledger Report") {
      downloadReport(DOWNLOAD_GENERAL_LEDGER_REPORT_URL, data);
      props.closeModal();
    }
  }

	//For management Report
	const getFromAllIncome = (event) => {
		let value = event.target.checked;
		setAllIncome(value);
	};

	const downloadBudgetReport = (item) => {
		console.log("selected Budget", item);
		let budgetReportData = {
			from: dayjs(from),
			to: to,
			docType: docType,
			budgetId: item && item.id,
		};
		downloadReport(DOWNLOAD_BUDGET_VS_ACTUAL_REPORT_URL, budgetReportData);
		props.closeModal();
	};

	return (
		<>
			{props.report === "Budget Vs Actual" ? (
				<ModalContainerModule
					size="sm"
					// accept={}
					cancel={modalClose}
					openModal={props.showModal}
					modalTitle="Approved Budgets"
				>
					<ResponsiveRow container sx={{ height: "auto" }}>
						<TableContainer component={Paper}>
							<Table
								sx={{ minWidth: 650 }}
								aria-label="simple table"
							>
								<TableHead>
									<TableRow>
										<TableCell>Name</TableCell>
										<TableCell>Date Created</TableCell>
										<TableCell>Start Date</TableCell>
										<TableCell>End Date</TableCell>
										<TableCell>Income Total</TableCell>
										<TableCell>Expense Total</TableCell>
										<TableCell>Download</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{approvedBudgets &&
										approvedBudgets.length > 0 &&
										approvedBudgets.map((row) => {
											return (
												<TableRow
													key={row.id}
													sx={{
														"&:last-child td, &:last-child th":
															{ border: 0 },
													}}
												>
													<TableCell
														component="th"
														scope="row"
													>
														{row.name}
													</TableCell>
													<TableCell>
														{formatDate(
															row.dateCreated
														)}
													</TableCell>
													<TableCell>
														{formatDate(
															row.startDate
														)}
													</TableCell>
													<TableCell>
														{formatDate(
															row.endDate
														)}
													</TableCell>
													<TableCell>
														{row.incomeTotal}
													</TableCell>
													<TableCell>
														{row.expenseTotal}
													</TableCell>
													<TableCell>
														<IconButton
															aria-label="Example"
															onClick={() => {
																downloadBudgetReport(
																	row
																);
															}}
														>
															<DownloadIcon />
														</IconButton>
													</TableCell>
												</TableRow>
											);
										})}
								</TableBody>
							</Table>
						</TableContainer>
					</ResponsiveRow>
				</ModalContainerModule>
			) : (
				<ModalContainerModule
					size="small"
					accept={generateReport}
					cancel={modalClose}
					openModal={props.showModal}
					modalTitle={`Generate ${props.report} Report`}
					acceptButtonText={props.report === "Collections" || 
						props.report === "Collections By Capital Fund" || 
						props.report === "Collections By Service Charge" || 
						props.report === "Collections By Utilities" 
						? "Download" 
						: "Generate"
					}

				>
					<ResponsiveRow
						sx={{
							width: "100%",
							height: "auto",

							alignItems: "center",
							flexDirection: { xs: "column", lg: "row" },
							flexWrap: "nowrap",
							padding: 0,
						}}
						container
					>
						<ResponsiveRow
							sx={{
								width: { xs: "100%", lg: "50%" },
								height: "auto",

								flexDirection: "column",
								flexWrap: "nowrap",

								alignItems: "center",
								justifyContent: "flex-start",

								padding: 0,
							}}
							container
						>
							<Alert
								severity="info"
								sx={{ width: "100%", height: "100%" }}
							>
								<AlertTitle>About Report</AlertTitle>
								<Typography variant="body2">
									{props.description}
								</Typography>
							</Alert>
						</ResponsiveRow>
						<ResponsiveRow
							sx={{
								width: { xs: "100%", lg: "50%" },
								height: "auto",
								alignItems: "center",
							}}
							container
						>
							{props &&
								props.report !== "Vacant Units" &&
								props.report !== "Equipment Maintenance" && (
									<ResponsiveRow
										sx={{
											width: "100%",
											flexDirection: {
												xs: "column",
												lg: "row",
											},
											flexWrap: "nowrap",
										}}
									>
										<FormControl
											item
											sx={{
												width: "100%",
												marginBottom: "10px",
											}}
										>
											<LocalizationProvider
												dateAdapter={AdapterDayjs}
												adapterLocale="en-gb"
											>
												<DatePicker
													label="Start date"
													sx={{
														width: {
															xs: "100%",
															lg: "90%",
														},
														marginRight: "10px",
													}}
													id={"startDate"}
													value={dayjs(from)}
													maxDate={dayjs().endOf(
														"day"
													)}
													views={
														props.report ===
														"Collections"
															? ["month", "year"]
															: undefined
													}
													isrequired
													onChange={(newDate) =>
														handleStartDateChange(
															newDate
														)
													}
												/>
											</LocalizationProvider>
										</FormControl>
										<FormControl
											item
											sx={{
												width: "100%",
												marginBottom: "10px",
											}}
										>
											<LocalizationProvider
												dateAdapter={AdapterDayjs}
												adapterLocale="en-gb"
											>
												<DatePicker
													label="End date"
													id={"endDate"}
													value={dayjs(to)}
													maxDate={dayjs().endOf(
														"day"
													)}
													views={
														props.report ===
														"Collections"
															? ["month", "year"]
															: undefined
													}
													sx={{
														width: {
															xs: "100%",
															lg: "90%",
														},
														marginRight: "10px",
													}}
													isrequired
													onChange={(newDate) =>
														handleEndDateChange(
															newDate
														)
													}
												/>
											</LocalizationProvider>
										</FormControl>
									</ResponsiveRow>
								)}
							{props.report === "Management" && (
								<>
									<InputLabel>All Income?</InputLabel>
									<Switch
										checked={allIncome}
										onChange={(event) =>
											getFromAllIncome(event)
										}
										inputProps={{
											"aria-label": "controlled",
										}}
									/>

									<FormControl
										item
										sx={{
											width: "100%",
											margin: "auto 10px 10px 10px",
										}}
									>
										<Typography
											sx={{
												marginTop: "20px",
												marginBottom: "20px",
											}}
										>
											If your Management Income includes
											commission on rent collected, enter
											the percentage of commission and
											press Submit.
										</Typography>
									</FormControl>

                  <FormControl
                    item
                    sx={{ width: "100%", margin: "auto 10px 10px 10px" }}
                  >
                    <InputLabel>Commission Rate</InputLabel>
                    <OutlinedInput
                      label="Commission Rate"
                      id="commission"
                      sx={{ width: { xs: "100%", lg: "90%" } }}
                      onChange={(event) => {
                        let value = event.target.value;
                        setCommissionRate(value);
                      }}
                      value={commissionRate}
                      inputProps={
                        <TextField
                          sx={{
                            width: { xs: "100%", md: "90%" },
                          }}
                        />
                      }
                    />
                  </FormControl>
                </>
              )}{" "}
              <FormControl
                item
                sx={{ width: "100%", margin: "auto 10px 10px 10px" }}
              >
                <InputLabel>Document Type</InputLabel>
                <Select
                  label="Document Type"
                  sx={{ width: { xs: "100%", lg: "90%" } }}
                  id={"documentType"}
                  disabled={props.report === "Client Mobile Money Statement"}
                  value={docType}
                  onChange={(event) => setDocType(event.target.value)}
                >
                  {reportFormatOptions && reportFormatOptions.length > 0 ? (
                    reportFormatOptions.map(function (format, i) {
						if (props.report === "Collections" && format.value === 'pdf'){
							return;
						}
                      return (
                        <MenuItem key={format.value} value={format.value}>
                          {format.label}
                        </MenuItem>
                      );
                    }, this)
                  ) : (
                    <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
                  )}
                </Select>
              </FormControl>
              {(props.report === "Collections" ||
                props.report === "Collections By Capital Fund" ||
                props.report === "Collections By Service Charge" ||
                props.report === "Collections By Utilities") && (
                <FormControl
                  item
                  sx={{ width: "100%", margin: "auto 10px 10px 10px" }}
                >
                  <FormControlLabel
                    control={
                      <Switch
                        checked={emailDoc}
                        onChange={(event) => setEmailDoc(event.target.checked)}
                        name="emailDoc"
                      />
                    }
                    label="Export to Email"
                  />
                </FormControl>
              )}
            </ResponsiveRow>
          </ResponsiveRow>
        </ModalContainerModule>
      )}
    </>
  );
}