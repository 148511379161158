import {Box} from "@mui/material";
import {ResponsiveRow} from "../../constants/layout.constants";
import Permit from "../../util/permit";
import SignUpAnalysisViewBar from "../../components/bars/signUpAnalysisViewBar";
import SignUpInfoCard from "../../components/cards/signUpInformationCard";

export default function SignUpAnalysisView(props) {
    return (
        <Permit
            roles="SYSAD,CS"
            noPermittedServiceMessage={{
                title: "Access Denied",
                body: (
                    <>
                        Your current role and/or facility is not permitted to view this
                        page. <br/> Please contact your system admin if you feel this is an
                        error.
                    </>
                ),
            }}
        >
            <ResponsiveRow
                container
                sx={{
                    flexDirection: "column",
                    width: "100%",
                    paddingBottom: "20px",
                }}
            >
                <Box
                    sx={{
                        width: "100%",

                        display: "flex",
                        flexDirection: "row",
                        margin: "10px auto 5px auto",
                    }}
                >
                    <SignUpAnalysisViewBar
                        selectedSignUp={props.selectedSignUp}
                        setTask={props.setTask}
                    />
                </Box>
                <Box
                    sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: {xs: "column", lg: "row"},
                        justifyContent: {xs: "center", lg: "space-between"},
                        margin: "10px auto 5px auto",
                    }}
                >
                    <SignUpInfoCard
                        selectedSignUp={props.selectedSignUp}
                    />
                </Box>
            </ResponsiveRow>
        </Permit>
    );
}
