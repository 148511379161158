import { Suspense, useEffect } from "react";
import { Alert, AlertTitle, Container } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import NavigationBar from "../bars/navigationBar";
import AdminProxyBar from "../bars/adminProxyBar";
import { titleCase } from "../../util/util";
import { clearMessage } from "../../actions/message";
import Permit from "../../util/permit";
import { ResponsiveRow } from "../../constants/layout.constants";

import LoadingOverlay from "../overlays/loadingOverlay";

// Using Inline Styling
const useStyles = makeStyles((theme) => ({
  navbar: {
    backgroundColor: "#037171",
    minHeight: "6vmin",
    fontSize: `calc(10px + 2vmin)`,
    color: "white",
  },
  menuButton: {
    color: "white!important",
  },
  subMenu: {
    "& .MuiPaper-root": {
      backgroundColor: "#037171",
      color: "white",
    },
  },
  subMenuLink: {
    color: "white",
    textDecoration: "none",
  },
  secureContainer: {
    minHeight: "92.5vmin",
  },
  unsecureContainer: {
    minHeight: "100vmin",
    width: "100vw",
    margin: 0,
    padding: 0,
  },
}));

export default function KodiLayout(props) {
  const { children } = props;
  const classes = useStyles();
  const appMessage = useSelector((state) => state.message);

  // const user = useSelector((state) => state.auth.user)
  const dispatch = useDispatch();

  useEffect(() => {
    //clear the message after 5 seconds after it is displayed
    if (appMessage.display) {
      setTimeout(() => {
        // After 3 seconds set the show value to false
        dispatch(clearMessage());
      }, 5000);
    }
  }, [appMessage, dispatch]);

  return (
    <ResponsiveRow
      sx={{
        position: "relative",
        maxHeight: "100vh",

        flexDirection: "column",
        flexWrap: "nowrap",

        margin: 0,
        padding: 0,

        justifyContent: "flex-start",
        alignItems: "center",

        overflowX: "hidden",
        overflowY: "hidden",
      }}
    >
      <NavigationBar />
      {appMessage.display && (
        <Alert
          sx={{
            position: "absolute",
            zIndex: 1000,

            top: 90,
            right: 20,

            minWidth: "300px",
          }}
          severity={appMessage.type}
        >
          <AlertTitle>{titleCase(appMessage.type)}</AlertTitle>
          {appMessage.message}
        </Alert>
      )}{" "}
      <Container
        className={classes.secureContainer}
        maxWidth={false}
        sx={{
          "&.MuiContainer-root": {
            height: "90%",
            width: "90%",
            margin: "auto",
            overflowY: "auto",
          },
        }}
        disableGutters={false}
      >
        <Permit permissions="sysadmin">
          <AdminProxyBar />
        </Permit>
        <Suspense
          fallback={<LoadingOverlay message="Page loading... please wait" />}
        >
          {children}
        </Suspense>
      </Container>
    </ResponsiveRow>
  );
}
