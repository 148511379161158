import {
  Box,
  Button,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import {
  DetailsColumn,
  DetailsContainer,
  DetailsLabel,
  DetailsRow,
  DetailsText,
} from "../../constants/component.constants";
import { formatDate, isEmptyString, isNullUndefined } from "../../util/util";

import { Edit } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setMessage } from "../../actions/message";
import { ResponsiveRow } from "../../constants/layout.constants";
import emptyIllustration from "../../images/states/emptyDatagrid.svg";
import {
  deleteInspectionDocument,
  getFile,
} from "../../services/inspections.service";
import { downloadByDocumentId } from "../../services/shared.service";
import kodi_theme from "../../themes/kodiThemeProvider";
import ModalContainerModule from "../modules/modalContainer";
import Inspections from "./inspections";

import { ReactComponent as Doc } from "../../images/file types/doc.svg";
import { ReactComponent as Jpg } from "../../images/file types/jpg.svg";
import { ReactComponent as Pdf } from "../../images/file types/pdf.svg";
import { ReactComponent as Png } from "../../images/file types/png.svg";
import { ReactComponent as Xlm } from "../../images/file types/xlm.svg";

const FileItem = (props) => {
  const [confirmDelete, setConfirmDelete] = useState(false);

  const download = (e) => {
    e.preventDefault();
    console.log("props ", props);
    downloadByDocumentId(props.fileItem.documentId);
  };

  const handleDeleteClicked = () => {
    setConfirmDelete(true);
  };

  const handleCancelClicked = () => {
    setConfirmDelete(false);
  };

  console.log(props.fileItem);

  return (
    <ResponsiveRow
      sx={{
        flexDirection: { xs: "column", lg: "row" },
        flexWrap: { xs: "nowrap", lg: "wrap" },

        width: { xs: "100%", lg: "200px" },
        height: "auto",

        padding: "5px",
      }}
    >
      <Grid
        item
        container
        sx={{
          flexDirection: "column",
          justifyContent: "center",

          alignItems: { xs: "center", lg: "flex-start" },

          width: { xs: "100%", lg: "auto" },
          height: "100%",
        }}
      >
        <img
          src="https://via.placeholder.com/75"
          style={{ borderRadius: "10px", height: "100%" }}
          alt=""
        />
      </Grid>
      <Grid
        container
        sx={{
          flexDirection: "column",
          alignItems: { xs: "center", lg: "flex-end" },
          maxWidth: { xs: "100%", lg: "80%" },
        }}
      >
        <Grid item>
          <Typography variant="body2" color="text.secondary" textAlign={"end"}>
            {props.fileItem.filename}
          </Typography>
        </Grid>
        <Grid
          item
          container
          sx={{ flexDirection: "row", justifyContent: "flex-end" }}
        >
          <Grid item>
            <IconButton onClick={download}>
              <DownloadIcon />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton onClick={handleDeleteClicked}>
              <DeleteIcon />
            </IconButton>
          </Grid>
        </Grid>
        {confirmDelete && (
          <Grid item>
            <Button
              variant="contained"
              type="submit"
              onClick={props.deleteFile(props.fileItem.documentId)}
              sx={{
                backgroundColor: "#037171",
                "&:hover": {
                  backgroundColor: "#FFF",
                  color: "#037171",
                },
              }}
            >
              Confirm Delete
            </Button>
            <Button
              variant="contained"
              type="submit"
              color="error"
              onClick={handleCancelClicked}
              sx={{
                marginLeft: "10px",
                "&:hover": {
                  backgroundColor: "#FFF",
                  color: "#E53935",
                },
              }}
            >
              Cancel
            </Button>
          </Grid>
        )}
      </Grid>
    </ResponsiveRow>
  );
};

/**
 Required props are:
 - openModal: True or false to show or hide modal respectively.
 - closeModal: Function to close the modal ie set showTerminateModal to false.
 - contract: This is the data for either selected item.
 - inspectionItem: Either a 'Complex, Property, Unit, Asset'
 */

export default function ViewInspections(props) {
  const [detailsModal, setDetailsModal] = useState(false);
  const [InspectModal, setInspectModal] = useState(false);
  const [inspect, setInspect] = useState("");
  const [contract, setContract] = useState("");
  const [task, setTask] = useState("");
  const [details, setDetails] = useState([]);

  const dispatch = useDispatch();

  const handleView = (record) => {
    setDetails(record.details);
    setDetailsModal(true);
  };

  const deleteDocument = (documentId) => {
    let data = {
      documentId: documentId,
    };

    deleteInspectionDocument(data)
      .then((response) => {
        console.log("response", response);
        dispatch(
          setMessage({
            type: response.status,
            message: response.message,
          })
        );
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const handleEditClick = (record) => {
    setInspectModal(true);
    setContract(record);
    setInspect("Edit");
    setTask("Edit");
  };

  useEffect(() => {});

  return (
    <div>
      <ModalContainerModule
        size="md"
        cancel={() => {
          props?.closeModal();
          setDetailsModal(false);
        }}
        openModal={props.openModal}
        modalTitle={`View Inspection Records`}
        //for ${props.contract.name}`}
      >
        <ResponsiveRow
          container
          sx={{
            width: "100%",
            height: "100%",

            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <TableContainer style={{ height: "100%" }}>
            <Table
              sx={{ minWidth: "100%" }}
              aria-label="simple table"
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  <TableCell>Inspection Type</TableCell>
                  <TableCell>Inspection Date</TableCell>
                  <TableCell>Comments</TableCell>
                  <TableCell>Next inspection Date</TableCell>
                  <TableCell>Added By</TableCell>
                  <TableCell>Inspection Details</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props?.inspectionRecords &&
                  props?.inspectionRecords.map((record, i) => {
                    return (
                      <TableRow key={i}>
                        <TableCell>{record.inspectionTypeText}</TableCell>
                        <TableCell>
                          {formatDate(record.inspectionDate)}
                        </TableCell>
                        <TableCell>{record.comment}</TableCell>
                        <TableCell>
                          {isEmptyString(record.nextInspectionDate) ||
                          isNullUndefined(record.nextInspectionDate) ||
                          record.nextInspectionDate === "None"
                            ? ""
                            : formatDate(record.nextInspectionDate)}
                        </TableCell>
                        <TableCell>{record.addedBy}</TableCell>
                        <TableCell>
                          <Button onClick={() => handleView(record)}>
                            Details ({record.details.length})
                          </Button>
                        </TableCell>
                        <TableCell>
                          <Edit onClick={() => handleEditClick(record)} />
                        </TableCell>
                      </TableRow>
                    );
                  }, this)}
              </TableBody>
            </Table>
          </TableContainer>

          {props?.inspectionRecords.length === 0 && (
            <Box
              component="img"
              src={emptyIllustration}
              alt="empty illustration"
              sx={{ width: "250px", height: "250px", margin: "auto" }}
            />
          )}
        </ResponsiveRow>
      </ModalContainerModule>

      <ModalContainerModule
        size="specialInspectionDetailsSize"
        cancel={() => setDetailsModal(false)}
        openModal={detailsModal}
        modalTitle="Inspection Details"
        acceptButtonText=""
      >
        <ResponsiveRow container>
          {details &&
            details.length > 0 &&
            details.map((detail) => {
              let fileExt = "png";

              return (
                <Grid
                  container
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    flexWrap: "nowrap",

                    alignItems: { xs: "center", lg: "flex-start" },
                    justifyContent: "center",
                    alignText: "start",

                    margin: { xs: "10px", lg: "20px" },
                    padding: { xs: "10px", lg: "10px 20px" },

                    borderRadius: "10px",
                    backgroundColor: "#efefef",

                    width: { xs: "100%", lg: "45%" },
                    height: "auto",

                    maxWidth: { xs: "100%", lg: "400px" },
                    maxHeight: { xs: "auto", lg: "300px" },
                  }}
                  key={detail.id}
                >
                  <DetailsRow
                    sx={{
                      justifyContent: "flex-start",
                      textAlign: "start",
                      width: "100%",
                      padding: "5px 10px",
                      margin: "0",
                    }}
                  >
                    <Typography
                      variant="h5"
                      sx={{
                        fontWeight: 800,
                        alignText: "start",
                        width: "100%",
                        color: "#037171",
                      }}
                    >
                      {detail.inspectionAreaText}{" "}
                      {!isNullUndefined(detail.specificAreaDetail) && " | "}{" "}
                      {detail.specificAreaDetail}
                    </Typography>
                  </DetailsRow>
                  <DetailsContainer sx={{ marginTop: "0" }}>
                    <DetailsColumn
                      sx={{
                        width: "100%",
                        alignItems: "flex-start",
                      }}
                    >
                      <DetailsRow
                        sx={{
                          width: "100%",
                          justifyContent: "flex-start",
                          textAlign: "start",
                          // padding: "5px 10px ",
                        }}
                      >
                        <DetailsLabel>Recommendation:</DetailsLabel>
                        <DetailsText sx={{ width: "100%", textAlign: "end" }}>
                          {detail.recommendationText}
                        </DetailsText>
                      </DetailsRow>
                      <DetailsRow
                        sx={{
                          width: "100%",
                          justifyContent: "flex-start",
                          textAlign: "start",
                          // padding: "5px 10px ",
                        }}
                      >
                        <DetailsLabel>Comments:</DetailsLabel>
                        <DetailsText sx={{ width: "100%", textAlign: "end" }}>
                          {detail.comment}
                        </DetailsText>
                      </DetailsRow>
                      <DetailsRow
                        sx={{
                          justifyContent: "flex-end",
                          textAlign: "start",
                          width: "100%",
                          padding: "5px 10px",
                        }}
                      >
                        {detail.imageFiles &&
                          detail.imageFiles.map((fileItem, i) => (
                            <FileItem
                              key={i}
                              fileItem={fileItem}
                              deleteFile={() =>
                                deleteDocument(fileItem.documentId)
                              }
                              mode={props?.type}
                            />
                          ))}
                        {detail.otherDocuments &&
                          detail.otherDocuments.map((fileItem, i) => (
                            <FileItem
                              key={i}
                              fileItem={fileItem}
                              deleteFile={() =>
                                deleteDocument(fileItem.documentId)
                              }
                              mode={props?.type}
                            />
                          ))}
                      </DetailsRow>
                      <Typography variant="h6" color="primary.dark">
                        Uploaded Files
                      </Typography>
                      <ResponsiveRow
                        sx={{
                          width: "100%",
                          height: "auto",

                          padding: "5px 0",

                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        {detail.files &&
                          detail.files.map((file) => {
                            const fileName = String(file.systemFileName);
                            fileExt = fileName.substring(
                              fileName.indexOf(".") + 1
                            );

                            const fileExtStyle = {
                              width: "40px",
                              height: "40px",
                              padding: 0,
                              margin: "auto 10px",
                            };

                            return (
                              <IconButton
                                target="_blank"
                                onClick={() => getFile(file.systemFileName)}
                                sx={{
                                  "&:hover": {
                                    backgroundColor:
                                      kodi_theme.palette.common.white,
                                  },
                                }}
                              >
                                {fileExt === "png" ? (
                                  <Png style={fileExtStyle} />
                                ) : fileExt === "jpg" || fileExt === "jpeg" ? (
                                  <Jpg style={fileExtStyle} />
                                ) : fileExt === "pdf" ? (
                                  <Pdf style={fileExtStyle} />
                                ) : fileExt === "xlsx" ? (
                                  <Xlm style={fileExtStyle} />
                                ) : fileExt === "docx" ? (
                                  <Doc style={fileExtStyle} />
                                ) : null}
                              </IconButton>
                            );
                          })}
                      </ResponsiveRow>
                    </DetailsColumn>
                  </DetailsContainer>
                </Grid>
              );
            }, this)}
        </ResponsiveRow>
      </ModalContainerModule>

      {task === "Edit" && (
        <Inspections
          openModal={InspectModal}
          closeModal={() => {
            setInspectModal(false);
            setTask("");
          }}
          contract={contract}
          inspectionItem={inspect}
        />
      )}
    </div>
  );
}
