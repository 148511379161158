import React from "react"
import {ResponsiveRow} from "../../constants/layout.constants"
import ModalContainerModule from "../../components/modules/modalContainer"
import {approveWorkOrderData, bulkApproveWorkOrderData, rejectWorkOrderData,} from "../../services/workOrder.service"
import {issueResponseMessage, issueWarnMessage} from "../../actions/message"
import {isEmptyString, isNullUndefined, titleCase} from "../../util/util"
import {useSelector} from "react-redux"
import {Alert, AlertTitle, FormControl, InputLabel, OutlinedInput, Typography,} from "@mui/material"

export default function ApproveRejectWorkOrderModal({
                                                        isOpen = false | true,
                                                        task,
                                                        reason = "",
                                                        selectionModel = [],
                                                        selectedWorkOrder,
                                                        reloadWorkOrders = () => {
                                                        },
                                                        handleApproveRejectClose = () => {
                                                        },
                                                        onTextChange = () => {
                                                        },
                                                    }) {
    const appMessage = useSelector((state) => state.message)

    function valid(reason) {
        if (
            task === "ApproveReject" &&
            (isNullUndefined(selectedWorkOrder) ||
                isNullUndefined(selectedWorkOrder.id) ||
                isEmptyString(selectedWorkOrder.id))
        ) {
            issueWarnMessage(
                "Couldn't determine the identifier for approving/rejecting the work order. Try refresh the page."
            )
            return false
        }
        if (isNullUndefined(reason) || isEmptyString(reason)) {
            issueWarnMessage(
                "A reason must be provided before approving/rejecting the work order"
            )
            return false
        }
        return true
    }

    function approveWorkOrder() {
        if (valid(reason)) {
            let id = selectedWorkOrder.id
            let sendData = {
                id: id,
                actionNotes: reason,
            }
            approveWorkOrderData(sendData).then((response) => {
                issueResponseMessage(response)
                reloadWorkOrders()
            })
            handleApproveRejectClose()
        }
    }

    function rejectWorkOrder() {
        if (valid(reason)) {
            let id = selectedWorkOrder.id
            let sendData = {
                id: id,
                actionNotes: reason,
            }
            rejectWorkOrderData(sendData).then((response) => {
                issueResponseMessage(response)
                reloadWorkOrders()
            })
            handleApproveRejectClose()
        }
    }

    function bulkApproveWorkOrder() {
        if (valid(reason)) {
            let sendData = {
                workOrderIds: selectionModel,
                actionNotes: reason,
            }

            bulkApproveWorkOrderData(sendData).then((response) => {
                issueResponseMessage(response)
                reloadWorkOrders()
                handleApproveRejectClose()
            })
        }
    }

    function handleAccept() {
        task === "ApproveReject" ? approveWorkOrder() : bulkApproveWorkOrder()
    }

    return (
        <ModalContainerModule
            size="xs"
            accept={handleAccept}
            reject={rejectWorkOrder}
            cancel={handleApproveRejectClose}
            openModal={isOpen}
            modalTitle="Approve / Reject Work Order"
            acceptButtonText="Approve"
            cancelText="Cancel"
            rejectButtonText={"Reject"}
        >
            {appMessage.display && (
                <Alert severity={appMessage.type}>
                    <AlertTitle>{titleCase(appMessage.type)}</AlertTitle>
                    {appMessage.message}
                </Alert>
            )}
            <ResponsiveRow
                sx={{
                    width: "100%",
                    height: "auto",
                    padding: "10px",

                    flexDirection: "column",
                    flexWrap: "nowrap",

                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                }}
            >
                <Alert severity="info" sx={{width: "100%"}}>
                    <AlertTitle>Confirm Work Order Action</AlertTitle>
                    <Typography
                        variant="body2"
                        marginBottom="20px"
                        sx={{color: "inherit"}}
                    >
                        Are you sure you want to mark{" "}
                        {!isNullUndefined(selectedWorkOrder) ? "work order" : "work orders"}{" "}
                        {!isNullUndefined(selectedWorkOrder)
                            ? selectedWorkOrder && selectedWorkOrder.refNumber
                            : ""}{" "}
                        as approved/rejected?
                    </Typography>
                    Please give a reason for Approving/Rejecting this work order
                </Alert>
                <br/>
                <Alert severity="warning" sx={{width: "100%"}}>
                    <AlertTitle sx={{fontSize: "0.8rem", color: "inherit"}}>
                        Please Note
                    </AlertTitle>
                    This action cannot be undone
                </Alert>
                <br/>
                <FormControl sx={{width: "100%"}}>
                    <InputLabel>Reason* (500)</InputLabel>
                    <OutlinedInput
                        label="Reason* (500)"
                        sx={{width: "100%"}}
                        id={"reason"}
                        placeholder={
                            "Were there problems with the vendors used? or charges listed? Does it lack enough information to be finished? Is it being cancelled because there is a similar one in progress?"
                        }
                        isrequired
                        multiline
                        rows={4}
                        messageRequired={"Approving/Rejection reason is required"}
                        value={reason}
                        onChange={(event) => onTextChange(event)}
                    />
                </FormControl>

                <br/>
            </ResponsiveRow>
        </ModalContainerModule>
    )
}
