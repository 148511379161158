import { Typography } from "@mui/material";
import { Box } from "@mui/system";

import read_a_meter from "../images/faq/read_a_meter.png";
import read_a_meter_2 from "../images/faq/read_a_meter_2.png";
import read_a_meter_3 from "../images/faq/read_a_meter_3.png";
import read_a_meter_4 from "../images/faq/read_a_meter_4.png";
import read_a_meter_5 from "../images/faq/read_a_meter_5.png";
import record_a_meter_as_broken from "../images/faq/record_a_meter_as_broken.png";
import record_a_meter_as_broken_2 from "../images/faq/record_a_meter_as_broken_2.png";

import pay_a_bill_2 from "../images/faq/pay_a_bill_2.png";

import add_a_vendor from "../images/faq/add_a_vendor.png";

import new_ui_complexes_menu from "../images/new_ui_faq/menu_select_complexes.png";
import new_ui_complexes_menu_hoa from "../images/new_ui_faq/menu_select_complexes_hoa.png";
import new_ui_properties_menu from "../images/new_ui_faq/menu_select_properties.png";
import new_ui_properties_menu_hoa from "../images/new_ui_faq/menu_select_properties_hoa.png";
import new_ui_report_menu from "../images/new_ui_faq/menu_select_report.png";
import new_ui_assets_menu from "../images/new_ui_faq/menu_select_assets.png";
import new_ui_meters_menu from "../images/new_ui_faq/menu_select_meters.png";
import new_ui_meters_menu_hoa from "../images/new_ui_faq/menu_select_meters_hoa.png";
import new_ui_leases_menu from "../images/new_ui_faq/menu_select_leases.png";
import new_ui_select_tenant_menu from "../images/new_ui_faq/menu_select_tenant.png";
import new_ui_messages_menu from "../images/new_ui_faq/menu_select_messages.png";
import new_ui_select_user_menu from "../images/new_ui_faq/menu_select_user.png";
import new_ui_select_meter_menu from "../images/new_ui_faq/meter_select_menu.png";
import new_ui_select_meter_menu_hoa from "../images/new_ui_faq/meter_select_menu_hoa.png";
import new_ui_select_invoice_menu from "../images/new_ui_faq/select_invoice_menu.png";
import new_ui_select_receipt_menu from "../images/new_ui_faq/select_receipt_menu.png";
import new_ui_bills_payment_menu from "../images/new_ui_faq/bills_payment_menu.png";
import new_ui_menu_work_order from "../images/new_ui_faq/menu_work_order.png";
import new_ui_menu_vendor from "../images/new_ui_faq/menu_vendor.png";

import new_ui_create_complexes_button from "../images/new_ui_faq/create_complex_button.png";
import new_ui_create_complexes_modal from "../images/new_ui_faq/create_complex_modal.png";
import new_ui_create_unit_button from "../images/new_ui_faq/create_unit_button.png";
import new_ui_create_unit_modal from "../images/new_ui_faq/create_unit_modal.png";
import new_ui_create_asset_modal from "../images/new_ui_faq/create_asset_modal.png";
import new_ui_create_lease_modal from "../images/new_ui_faq/create_lease_modal.png";
import new_ui_assets_edit_maintenance_modal from "../images/new_ui_faq/edit_asset_maintenance_modal.png";
import new_ui_terminate_lease_modal from "../images/new_ui_faq/terminate_lease_maintenance_modal.png";
import new_ui_meter_edit_modal from "../images/new_ui_faq/meter_edit_modal.png";
import new_ui_work_order_edit_modal from "../images/new_ui_faq/work_order_edit_modal.png";

import new_ui_edit_unit_modal from "../images/new_ui_faq/edit_unit_modal.png";
import new_ui_generate_report_modal from "../images/new_ui_faq/generate_report_modal.png";

import new_ui_meters_add_meter_readings_modal from "../images/new_ui_faq/meter_add_meter_reading_modal.png";
import new_ui_edit_lease_modal from "../images/new_ui_faq/edit_lease_modal.png";
import new_ui_page_edit_tenant_modal from "../images/new_ui_faq/edit_tenant_modal.png";
import new_ui_personOrg_search_modal from "../images/new_ui_faq/personOrg_search_modal.png";
import new_ui_personOrg_search_results_modal from "../images/new_ui_faq/personOrg_search_results_modal.png";
import new_ui_personOrg_search_no_results_modal from "../images/new_ui_faq/personOrg_search_no_results_modal.png";
import new_ui_add_user_pre_populated_modal from "../images/new_ui_faq/add_user_pre_populated_modal.png";
import new_ui_add_user_modal from "../images/new_ui_faq/add_user_modal.png";
import new_ui_edit_user_modal from "../images/new_ui_faq/edit_user_modal.png";
import new_ui_delete_user_modal from "../images/new_ui_faq/delete_user_modal.png";
import new_ui_meter_add_modal from "../images/new_ui_faq/meter_add_modal.png";
import new_ui_receipt_charge_modal from "../images/new_ui_faq/receipt_charge_modal.png";
import new_ui_lease_add_charge_modal from "../images/new_ui_faq/lease_add_charge_modal.png";
import new_ui_lease_add_credit_modal from "../images/new_ui_faq/lease_add_credit_modal.png";
import new_ui_add_bills_modal from "../images/new_ui_faq/add_bills_modal.png";
import new_ui_make_payment_modal from "../images/new_ui_faq/make_payment_modal.png";
import new_ui_vendor_add_vendor_modal from "../images/new_ui_faq/vendor_add_vendor_modal.png";
import new_ui_add_vendor_results_modal from "../images/new_ui_faq/add_vendor_results_modal.png";
import new_ui_add_vendor_no_results_modal from "../images/new_ui_faq/add_vendor_no_results_modal.png";
import new_ui_add_vendor_individual_modal from "../images/new_ui_faq/add_vendor_individual_modal.png";
import new_ui_add_vendor_company_modal from "../images/new_ui_faq/add_vendor_company_modal.png";

import new_ui_datagrids_property_units from "../images/new_ui_faq/datagrids_property_units.png";
import new_ui_datagrids_property_units_edit from "../images/new_ui_faq/datagrids_property_units_edit.png";

import new_ui_datagrids_leases_terminate from "../images/new_ui_faq/datagrids_leases_terminate.png";
import new_ui_datagrids_leases_edit from "../images/new_ui_faq/datagrids_leases_edit.png";
import new_ui_page_datagrid_tenant_edit from "../images/new_ui_faq/datagrids_tenant_edit.png";
import new_ui_datagrid_lease_view from "../images/new_ui_faq/datagrids_lease_view.png";
import new_ui_datagrid_meter_edit from "../images/new_ui_faq/datagrid_meter_edit.png";
import new_ui_datagrid_invoice from "../images/new_ui_faq/datagrid_invoice.png";
import new_ui_datagrid_receipts from "../images/new_ui_faq/datagrid_receipts.png";
import new_ui_datagrid_receipt_void from "../images/new_ui_faq/datagrid_receipt_void.png";
import new_ui_datagrid_leases_charge from "../images/new_ui_faq/datagrid_leases_charge.png";
import new_ui_datagrid_leases_credit from "../images/new_ui_faq/datagrid_leases_credit.png";
import new_ui_datagrid_work_order_edit from "../images/new_ui_faq/datagrid_work_order_edit.png";

import new_ui_page_assets from "../images/new_ui_faq/page_assets.png";
import new_ui_page_leases from "../images/new_ui_faq/page_leases.png";
import new_ui_page_tenant from "../images/new_ui_faq/page_tenant.png";
import new_ui_lease_detail_page from "../images/new_ui_faq/page_lease_view.png";
import new_ui_page_messages from "../images/new_ui_faq/page_messages.png";
import new_ui_page_user from "../images/new_ui_faq/page_user.png";
import new_ui_page_meter from "../images/new_ui_faq/page_meter.png";
import new_ui_page_invoice from "../images/new_ui_faq/page_invoice.png";
import new_ui_page_receipt from "../images/new_ui_faq/page_receipt.png";
import new_ui_page_bills_payments from "../images/new_ui_faq/page_bills_payments.png";
import new_ui_page_work_order from "../images/new_ui_faq/page_work_order.png";
import new_ui_page_vendors from "../images/new_ui_faq/page_vendors.png";

import new_ui_card_lease_document from "../images/new_ui_faq/card_lease_document.png";
import new_ui_card_user_edit from "../images/new_ui_faq/card_user_edit.png";
import new_ui_card_user_delete from "../images/new_ui_faq/card_user_delete.png";

import new_ui_form_messages_create from "../images/new_ui_faq/form_messages_create.png";
import new_ui_payments_tab from "../images/new_ui_faq/payments_tab.png";

import { ResponsiveRow } from "../constants/layout.constants";
import kodi_theme from "../themes/kodiThemeProvider";

export default class FaqLoader {
  constructor() {
    this.faqData = [
      {
        name: "property",
        text: "Property",
        data: [
          {
            hasSubAnswers: false,
            hasSteps: false,
            hasDescription: true,
            questionID: 1,
            question: "What is a Complex",
            answer:
              "A complex is a group of buildings that contain apartments or offices and are managed by the same company. In general, a group of properties.",
          },
          {
            hasSubAnswers: false,
            questionID: 2,
            question: "How do you add a complex?",
            hasDescription: true,
            answer: `This guide will walk you through the steps of how to add a complex in the SmartKodi system.`,
            hasSteps: true,
            steps: [
              `1. Once logged in, click on the Homes / Rentals item in the navigation menu`,
              `2. Click on the Complex item on the dropdown. This will take you to the Complexes page`,
              () => {
                return (
                  <ResponsiveRow
                    sx={{
                      margin: "10px auto",
                      width: { xs: "100%", lg: "90%" },
                      padding: "10px",
                    }}
                  >
                    <img
                      style={{
                        maxWidth: "100%",
                        margin: "10px 5px",
                        objectFit: "cover",
                        objectPosition: "center",
                        borderRadius: "10px",
                      }}
                      src={new_ui_complexes_menu}
                      alt="select complexes submenu"
                    />
                    <img
                      style={{
                        maxWidth: "100%",
                        margin: "10px 5px",
                        objectFit: "cover",
                        objectPosition: "center",
                        borderRadius: "10px",
                      }}
                      src={new_ui_complexes_menu_hoa}
                      alt="select complexes submenu hoa"
                    />
                  </ResponsiveRow>
                );
              },

              `3. Click the ADD COMPLEX button. This will take you to the “Create Complex” popup modal.`,
              () => {
                return (
                  <ResponsiveRow
                    sx={{
                      margin: "10px auto",
                      width: { xs: "100%", lg: "80%" },
                      padding: "10px",
                    }}
                  >
                    <img
                      style={{
                        maxWidth: "100%",
                        margin: "10px 5px",
                        objectFit: "cover",
                        objectPosition: "center",
                        borderRadius: "10px",
                      }}
                      src={new_ui_create_complexes_button}
                      alt="new ui create complexes button"
                    />
                    <img
                      style={{
                        maxWidth: "100%",
                        margin: "10px 5px",
                        objectFit: "cover",
                        objectPosition: "center",
                        borderRadius: "10px",
                      }}
                      src={new_ui_create_complexes_modal}
                      alt="new ui create complexes modal"
                    />
                  </ResponsiveRow>
                );
              },
              `4. Populate the new complex information in the fields and “Select associated property(ies)” if any, input address information, or any additional details**.`,
              `5. Click the CREATE button to add the complex, or the CLOSE button to cancel and leave the page.`,
            ],
          },
          {
            questionID: 3,
            question: "How do you add/edit/delete a unit?",
            hasSubAnswers: true,
            subAnswers: [
              {
                header: "Add Unit",
                steps: [
                  `1. Once logged in, click on the Homes / Rentals item in the navigation menu`,
                  `2. Click on the Properties item on the dropdown`,
                  () => {
                    return (
                      <ResponsiveRow
                        sx={{
                          margin: "10px auto",
                          width: { xs: "100%", lg: "90%" },
                          padding: "10px",
                        }}
                      >
                        <img
                          style={{
                            maxWidth: "100%",
                            margin: "10px 5px",
                            objectFit: "cover",
                            objectPosition: "center",
                            borderRadius: "10px",
                          }}
                          src={new_ui_properties_menu}
                          alt="select properties submenu"
                        />
                        <img
                          style={{
                            maxWidth: "100%",
                            margin: "10px 5px",
                            objectFit: "cover",
                            objectPosition: "center",
                            borderRadius: "10px",
                          }}
                          src={new_ui_properties_menu_hoa}
                          alt="select properties submenu hoa"
                        />
                      </ResponsiveRow>
                    );
                  },
                  `3. Click on the UNIT ACTIONS button`,
                  `4. Click the ADD UNIT item from the dropdown. This will take you to the “Add Unit” popup modal.`,
                  () => {
                    return (
                      <ResponsiveRow
                        sx={{
                          margin: "10px auto",
                          width: { xs: "100%", lg: "90%" },
                          padding: "10px",
                        }}
                      >
                        <img
                          style={{
                            maxWidth: "100%",
                            margin: "10px 5px",
                            objectFit: "cover",
                            objectPosition: "center",
                            borderRadius: "10px",
                          }}
                          src={new_ui_create_unit_button}
                          alt="create unit button"
                        />
                        <img
                          style={{
                            maxWidth: "100%",
                            margin: "10px 5px",
                            objectFit: "cover",
                            objectPosition: "center",
                            borderRadius: "10px",
                          }}
                          src={new_ui_create_unit_modal}
                          alt="_creat _uni _modal"
                        />
                      </ResponsiveRow>
                    );
                  },
                  `5. Populate the unit details in the fields provided.`,
                  `6. Click the CREATE button to add the complex, or the CLOSE button to cancel.`,
                ],
              },
              {
                header: "Edit Unit",
                steps: [
                  `1. Once logged in, click on the Homes / Rentals item in the navigation menu and click on the Properties item on the dropdown, as illustrated in the 'Add Unit' tutorial.`,
                  `2. Scroll to the units table at the bottom of the page.`,
                  `3. Click on the unit's ACTIONS (the three-dot icon) button of the unit you'd like to edit. Then select the EDIT UNIT item on the dropdown menu`,
                  () => {
                    return (
                      <ResponsiveRow
                        sx={{
                          margin: "10px auto",
                          width: { xs: "100%", lg: "90%" },
                          padding: "10px",
                        }}
                      >
                        <img
                          style={{
                            maxWidth: "100%",
                            margin: "10px 5px",
                            objectFit: "cover",
                            objectPosition: "center",
                            borderRadius: "10px",
                          }}
                          src={new_ui_datagrids_property_units}
                          alt="datagrids property units "
                        />
                        <img
                          style={{
                            maxWidth: "100%",
                            margin: "10px 5px",
                            objectFit: "cover",
                            objectPosition: "center",
                            borderRadius: "10px",
                          }}
                          src={new_ui_datagrids_property_units_edit}
                          alt="datagrids property units edit"
                        />
                      </ResponsiveRow>
                    );
                  },
                  `4. This will take you to the Edit Unit” popup modal.`,
                  () => {
                    return (
                      <ResponsiveRow
                        sx={{
                          margin: "10px auto",
                          width: { xs: "100%", lg: "90%" },
                          padding: "10px",
                        }}
                      >
                        <img
                          style={{
                            maxWidth: "100%",
                            margin: "10px 5px",
                            objectFit: "cover",
                            objectPosition: "center",
                            borderRadius: "10px",
                          }}
                          src={new_ui_edit_unit_modal}
                          alt="edit unit modal"
                        />
                      </ResponsiveRow>
                    );
                  },
                  `5. Edit the necessary unit details in the fields provided.`,
                  `6.Click the UPDATE button to add the complex, or the CLOSE button to cancel.`,
                ],
              },
            ],
          },
        ],
      },
      {
        name: "reports",
        text: "Reports",
        data: [
          {
            questionID: 5,
            question: "What types of reports are available?",
            hasSubAnswers: false,
            hasSteps: true,
            steps: [`1. Balance Sheet`, `2. Profit & Loss Statement`],
          },
          {
            questionID: 6,
            question: "How do I generate a report?",
            hasSubAnswers: false,
            hasSteps: true,
            steps: [
              `1. Once logged in, click on the Reports item in the navigation menu`,
              `2. Select a Report Type from the Dropdown. This will take you to the Generate Report popup modal.`,
              () => {
                return (
                  <ResponsiveRow
                    sx={{
                      margin: "10px auto",
                      width: { xs: "100%", lg: "90%" },
                      padding: "10px",
                    }}
                  >
                    <img
                      style={{
                        maxWidth: "100%",
                        margin: "10px 5px",
                        objectFit: "cover",
                        objectPosition: "center",
                        borderRadius: "10px",
                      }}
                      src={new_ui_report_menu}
                      alt="report menu"
                    />
                    <img
                      style={{
                        maxWidth: "100%",
                        margin: "10px 5px",
                        objectFit: "cover",
                        objectPosition: "center",
                        borderRadius: "10px",
                      }}
                      src={new_ui_generate_report_modal}
                      alt="generate report modal"
                    />
                  </ResponsiveRow>
                );
              },
              `3. Select the Start and End date range for selected report .`,
              `4. Select the document type or desired format either in PDF or CSV.`,
              `5. Click GENERATE button to the report or CANCEL to discard the selection.`,
            ],
          },
        ],
      },
      {
        name: "assets",
        text: "Assets",
        data: [
          {
            questionID: 7,
            question: "What types of assets can I monitor?",
            hasSubAnswers: false,
            hasSteps: true,
            steps: [
              `1. Boreholes`,
              `2. Generators`,
              `3. Lawn mowers`,
              `4. Vehicles`,
              `5. Furniture`,
              `6. Building`,
              `7. Computer Equipment`,
              `8. Desalination plant`,
              `9. Office equipment`,
              `10. Solar plant`,
              `11. Pumps`,
              `12. Meters`,
            ],
          },
          {
            questionID: 8,
            question: "How do you add an asset?",
            hasSubAnswers: false,
            hasSteps: true,
            steps: [
              `1. Once logged in, click on the Maintenance item in the navigation menu.`,
              `2. Click on the Assets item on the dropdown. This will take you to the “Assets” page.`,
              () => {
                return (
                  <ResponsiveRow
                    sx={{
                      margin: "10px auto",
                      width: { xs: "100%", lg: "90%" },
                      padding: "10px",
                    }}
                  >
                    <img
                      style={{
                        maxWidth: "100%",
                        margin: "10px 5px",
                        objectFit: "cover",
                        objectPosition: "center",
                        borderRadius: "10px",
                      }}
                      src={new_ui_assets_menu}
                      alt="select menu assets "
                    />
                    <img
                      style={{
                        maxWidth: "100%",
                        margin: "10px 5px",
                        objectFit: "cover",
                        objectPosition: "center",
                        borderRadius: "10px",
                      }}
                      src={new_ui_page_assets}
                      alt="page assets"
                    />
                  </ResponsiveRow>
                );
              },
              `3. Click the ADD AN ASSET button on the right. This will take you to the “Add an Asset” popup modal.`,
              () => {
                return (
                  <ResponsiveRow
                    sx={{
                      margin: "10px auto",
                      width: { xs: "100%", lg: "90%" },
                      padding: "10px",
                    }}
                  >
                    <img
                      style={{
                        maxWidth: "100%",
                        margin: "10px 5px",
                        objectFit: "cover",
                        objectPosition: "center",
                        borderRadius: "10px",
                      }}
                      src={new_ui_create_asset_modal}
                      alt="select menu assets "
                    />
                  </ResponsiveRow>
                );
              },
              `4. Populate the Asset details in the fields.`,
              // `5. There are a few special fields to take note of:`,
              () => {
                return (
                  <Typography
                    variant="body2"
                    sx={{
                      lineHeight: "30px",
                      margin: { xs: "10px auto", lg: "20px auto" },
                      width: "90%",
                      // color: "#222",
                      fontWeight: 500,
                      textAlign: "start",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    <strong style={{ color: kodi_theme.palette.primary.dark }}>
                      Asset category:{" "}
                    </strong>{" "}
                    <br />
                    Let's you classify a vendor for better organization. There
                    are some pre-defined types, but you can add your owner by
                    selecting Other... <br />
                    <br />
                    <strong style={{ color: kodi_theme.palette.primary.dark }}>
                      Is this a metered Asset?
                    </strong>{" "}
                    <br />
                    It's important to select this field if the Assets are
                    metered for billing and charging purposes. <br />
                    <br />
                    <strong style={{ color: kodi_theme.palette.primary.dark }}>
                      {" "}
                      Does this asset require maintenance?{" "}
                    </strong>{" "}
                    <br />
                    It's important to select this field if the Asset requires
                    periodic maintenance for better performance. Doing so will
                    allow you to select a maintenance account, maintenance
                    period, te acquired, Purchase price and Current value of the
                    asset. <br />
                    <br />
                    <strong style={{ color: kodi_theme.palette.primary.dark }}>
                      {" "}
                      Does this asset generate income?
                    </strong>{" "}
                    <br />
                    It's important to select this field if the asset brings in
                    income. Doing so will allow you to select an income account
                    and record a purchase price as well as Current value It's
                    important to select this field if the vendor is a home
                    owners association. Doing so will allow you to select a
                    management company for the association. It also makes this
                    vendor available to select as the HOA when you create a
                    property.
                  </Typography>
                );
              },
              `6. Click SAVE to add the Asset details or CANCEL to leave the“Add Asset” modal.`,
            ],
          },
          {
            questionID: 9,
            question: "How do you record an assets meter reading?",
            hasSubAnswers: false,
            hasSteps: true,
            steps: [
              `1. Once logged in, click on the Rentals / Home item in the navigation menu`,
              `2. Scroll down and click on the Meters item on the dropdown. This will take you to the 'Meters' page`,
              () => {
                return (
                  <ResponsiveRow
                    sx={{
                      margin: "10px auto",
                      width: { xs: "100%", lg: "90%" },
                      padding: "10px",
                    }}
                  >
                    <img
                      style={{
                        maxWidth: "100%",
                        margin: "10px 5px",
                        objectFit: "cover",
                        objectPosition: "center",
                        borderRadius: "10px",
                      }}
                      src={new_ui_meters_menu}
                      alt="meters menu"
                    />{" "}
                    <img
                      style={{
                        maxWidth: "100%",
                        margin: "10px 5px",
                        objectFit: "cover",
                        objectPosition: "center",
                        borderRadius: "10px",
                      }}
                      src={new_ui_meters_menu_hoa}
                      alt="meters menu hoa"
                    />
                  </ResponsiveRow>
                );
              },
              `2. Navigate to the assets table and click on the three-dots icon on the desired asset and click on the READINGS button. This will bring you to the Meter Readings popup modal`,
              `3. Click on the ADD READING button at the bottom of the modal. This will take you to the ADD METER READING popup modal`,
              () => {
                return (
                  <ResponsiveRow
                    sx={{
                      margin: "10px auto",
                      width: { xs: "100%", lg: "90%" },
                      padding: "10px",
                    }}
                  >
                    <img
                      style={{
                        maxWidth: "100%",
                        margin: "10px 5px",
                        objectFit: "cover",
                        objectPosition: "center",
                        borderRadius: "10px",
                      }}
                      src={new_ui_meters_add_meter_readings_modal}
                      alt="meters add meter reading modal"
                    />
                  </ResponsiveRow>
                );
              },
              `4. Populate the reading details in the fields provided.`,
              `5. Click the ADD READING button to add the new reading, or the CANCEL button to cancel.`,
            ],
          },
          {
            questionID: 10,
            question: "How do you set the maintenance schedule for an asset?",
            hasSubAnswers: false,
            hasSteps: true,
            steps: [
              `1. Once logged in, click on the Maintenance item in the navigation menu.`,
              `2. Click on the Assets item on the dropdown. This will take you to the “Assets” page. As shown in the Add an Asset tutorial.`,
              `3. On the desired asset row, click on the three-dot icon and click on the EDIT ASSET INFORMATION popup modal`,
              () => {
                return (
                  <ResponsiveRow
                    sx={{
                      margin: "10px auto",
                      width: { xs: "100%", lg: "90%" },
                      padding: "10px",
                    }}
                  >
                    <img
                      style={{
                        maxWidth: "100%",
                        margin: "10px 5px",
                        objectFit: "cover",
                        objectPosition: "center",
                        borderRadius: "10px",
                      }}
                      src={new_ui_assets_edit_maintenance_modal}
                      alt="assets edit maintenance modal"
                    />{" "}
                  </ResponsiveRow>
                );
              },
              `4. Scroll down to the 'Does this assets require maintenance?' switch and turn it on (green means on, grey means off)`,
              `5. Set the maintenance schedule as required.`,
              `6. Click the UPDATE button to add the save the asset's changes, or the CANCEL button to cancel.`,
            ],
          },
        ],
      },
      {
        name: "leasing",
        text: "Leasing",
        data: [
          {
            questionID: 12,
            question: "How do I create a lease?",
            hasSubAnswers: false,
            hasSteps: true,
            steps: [
              `1. Once logged in, click on the Rentals item in the navigation menu`,
              `2. Click on the Leases item on the dropdown. This will take you to the Leases page`,
              () => {
                return (
                  <ResponsiveRow
                    sx={{
                      margin: "10px auto",
                      width: { xs: "100%", lg: "90%" },
                      padding: "10px",
                    }}
                  >
                    <img
                      style={{
                        maxWidth: "100%",
                        margin: "10px 5px",
                        objectFit: "cover",
                        objectPosition: "center",
                        borderRadius: "10px",
                      }}
                      src={new_ui_leases_menu}
                      alt="select lease submenu"
                    />
                    <img
                      style={{
                        maxWidth: "100%",
                        margin: "10px 5px",
                        objectFit: "cover",
                        objectPosition: "center",
                        borderRadius: "10px",
                      }}
                      src={new_ui_page_leases}
                      alt="leases page"
                    />
                  </ResponsiveRow>
                );
              },
              `3. Click on the ADD LEASE button. This will take you to the Add Lease popup modal`,
              () => {
                return (
                  <ResponsiveRow
                    sx={{
                      margin: "10px auto",
                      width: { xs: "100%", lg: "90%" },
                      padding: "10px",
                    }}
                  >
                    <img
                      style={{
                        maxWidth: "100%",
                        margin: "10px 5px",
                        objectFit: "cover",
                        objectPosition: "center",
                        borderRadius: "10px",
                      }}
                      src={new_ui_create_lease_modal}
                      alt="select create lease modal"
                    />
                  </ResponsiveRow>
                );
              },
              `5. Populate the lease details in the fields provided.`,
              `6. Click the CREATE button to add the lease, or the CLOSE button to cancel.`,
            ],
          },
          {
            questionID: 13,
            question: "How do I terminate a lease?",
            hasSubAnswers: false,
            hasSteps: true,
            steps: [
              `1. Once logged in, click on the Rentals item in the navigation menu`,
              `2. Click on the Leases item on the dropdown. This will take to the Leases page as shown in the Add a Lease tutorial`,
              `3. On the desired lease to terminate click on the three-dots icon and click on TERMINATE item. This is take you to the Terminate Lease popup modal`,
              () => {
                return (
                  <ResponsiveRow
                    sx={{
                      margin: "10px auto",
                      width: { xs: "100%", lg: "90%" },
                      padding: "10px",
                    }}
                  >
                    <img
                      style={{
                        maxWidth: "100%",
                        margin: "10px 5px",
                        objectFit: "cover",
                        objectPosition: "center",
                        borderRadius: "10px",
                      }}
                      src={new_ui_datagrids_leases_terminate}
                      alt="datagrid lease terminate"
                    />
                    <img
                      style={{
                        maxWidth: "100%",
                        margin: "10px 5px",
                        objectFit: "cover",
                        objectPosition: "center",
                        borderRadius: "10px",
                      }}
                      src={new_ui_terminate_lease_modal}
                      alt="terminate leases modal"
                    />
                  </ResponsiveRow>
                );
              },
              `5. Settle the lease balances using in the fields provided.`,
              `6. Click the TERMINAE LEASE button to terminate the lease, or the CANCEL button to cancel.`,
            ],
          },
          {
            questionID: 14,
            question: "How do I edit a lease?",
            hasSubAnswers: false,
            hasSteps: true,
            steps: [
              `1. Once logged in, click on the Rentals item in the navigation menu`,
              `2. Click on the Leases item on the dropdown. This will take to the Leases page as shown in the ADD a Lease tutorial`,
              `3. On the lease to terminate click on the three-dots icon and click on TERMINATE item. This is take you to the Terminate Lease popup modal`,
              () => {
                return (
                  <ResponsiveRow
                    sx={{
                      margin: "10px auto",
                      width: { xs: "100%", lg: "90%" },
                      padding: "10px",
                    }}
                  >
                    <img
                      style={{
                        maxWidth: "100%",
                        margin: "10px 5px",
                        objectFit: "cover",
                        objectPosition: "center",
                        borderRadius: "10px",
                      }}
                      src={new_ui_datagrids_leases_edit}
                      alt="datagrid lease edit"
                    />
                    <img
                      style={{
                        maxWidth: "100%",
                        margin: "10px 5px",
                        objectFit: "cover",
                        objectPosition: "center",
                        borderRadius: "10px",
                      }}
                      src={new_ui_edit_lease_modal}
                      alt="edit leases modal"
                    />
                  </ResponsiveRow>
                );
              },
              `5. Update the lese information as required using the provided fields.`,
              `6. Click the EDIT LEASE button to save the changes, or the CANCEL button to cancel.`,
            ],
          },
          {
            questionID: 16,
            question: "How do I edit a tenant’s information?",
            hasSubAnswers: false,
            hasSteps: true,
            steps: [
              `1. Once logged in, click on the Rentals item in the navigation menu`,
              `2. Click on the Tenant item on the dropdown. This will take to the Tenants page `,
              () => {
                return (
                  <ResponsiveRow
                    sx={{
                      margin: "10px auto",
                      width: { xs: "100%", lg: "90%" },
                      padding: "10px",
                    }}
                  >
                    <img
                      style={{
                        maxWidth: "100%",
                        margin: "10px 5px",
                        objectFit: "cover",
                        objectPosition: "center",
                        borderRadius: "10px",
                      }}
                      src={new_ui_select_tenant_menu}
                      alt="select tenant menu"
                    />
                    <img
                      style={{
                        maxWidth: "100%",
                        margin: "10px 5px",
                        objectFit: "cover",
                        objectPosition: "center",
                        borderRadius: "10px",
                      }}
                      src={new_ui_page_tenant}
                      alt="terminate leases modal"
                    />
                  </ResponsiveRow>
                );
              },
              `3. On the desired lease to edit click on the three-dots icon and click on EDIT item. This is take you to the Edit User popup modal`,
              () => {
                return (
                  <ResponsiveRow
                    sx={{
                      margin: "10px auto",
                      width: { xs: "100%", lg: "90%" },
                      padding: "10px",
                    }}
                  >
                    <img
                      style={{
                        maxWidth: "100%",
                        margin: "10px 5px",
                        objectFit: "cover",
                        objectPosition: "center",
                        borderRadius: "10px",
                      }}
                      src={new_ui_page_datagrid_tenant_edit}
                      alt="datagrid edit tenant"
                    />
                    <img
                      style={{
                        maxWidth: "100%",
                        margin: "10px 5px",
                        objectFit: "cover",
                        objectPosition: "center",
                        borderRadius: "10px",
                      }}
                      src={new_ui_page_edit_tenant_modal}
                      alt="edit tenant modal"
                    />
                  </ResponsiveRow>
                );
              },
              `4. Click the SAVE button to update the Tenant’s details or CANCEL to discard the changes.`,
            ],
          },
          {
            questionID: 17,
            question: "How do I save a scanned lease or other lease documents?",
            hasSubAnswers: false,
            hasSteps: true,
            steps: [
              `1. Once logged in, click on the Rentals item in the navigation menu`,
              `2. Click on the Leases item on the dropdown. This will take to the Leases page as shown in the Add a Lease tutorial`,
              `3. On the desired lease click on the three-dots icon and click on VIEW item. This is take you to the Lease Details page`,
              () => {
                return (
                  <ResponsiveRow
                    sx={{
                      margin: "10px auto",
                      width: { xs: "100%", lg: "90%" },
                      padding: "10px",
                    }}
                  >
                    <img
                      style={{
                        maxWidth: "100%",
                        margin: "10px 5px",
                        objectFit: "cover",
                        objectPosition: "center",
                        borderRadius: "10px",
                      }}
                      src={new_ui_datagrid_lease_view}
                      alt="select tenant menu"
                    />
                    <img
                      style={{
                        maxWidth: "100%",
                        margin: "10px 5px",
                        objectFit: "cover",
                        objectPosition: "center",
                        borderRadius: "10px",
                      }}
                      src={new_ui_lease_detail_page}
                      alt="terminate leases modal"
                    />
                  </ResponsiveRow>
                );
              },
              `4. Scroll down to the Documents card and click on SELECT to select the desired documents. Please note there is a 10MB file limit`,
              () => {
                return (
                  <ResponsiveRow
                    sx={{
                      margin: "10px auto",
                      width: { xs: "100%", lg: "90%" },
                      padding: "10px",
                    }}
                  >
                    <img
                      style={{
                        maxWidth: "100%",
                        margin: "10px 5px",
                        objectFit: "cover",
                        objectPosition: "center",
                        borderRadius: "10px",
                      }}
                      src={new_ui_card_lease_document}
                      alt="card lease document"
                    />
                  </ResponsiveRow>
                );
              },
              `5. Once you've selected the required files, click on UPLOAD to save them. The document will appear below once successfully uploaded.`,
            ],
          },
        ],
      },
      {
        name: "messages",
        text: "Messages",
        data: [
          {
            questionID: 18,
            question: "What types of messages can I send?",
            hasSubAnswers: false,
            hasSteps: true,
            steps: [
              `1. Phone SMS`,
              `2. Tenant App message`,
              `3. Bulk messages`,
              `4. Individual messages`,
            ],
          },
          {
            questionID: 19,
            question: "How do I send an SMS to a tenants phone number?",
            hasSubAnswers: false,
            hasSteps: true,
            steps: [
              `1. Once logged in, click on the Messages item in the navigation menu`,
              `2. This will take you to the Messages page`,
              () => {
                return (
                  <ResponsiveRow
                    sx={{
                      margin: "10px auto",
                      width: { xs: "100%", lg: "90%" },
                      padding: "10px",
                    }}
                  >
                    <img
                      style={{
                        maxWidth: "100%",
                        margin: "10px 5px",
                        objectFit: "cover",
                        objectPosition: "center",
                        borderRadius: "10px",
                      }}
                      src={new_ui_messages_menu}
                      alt="select messages submenu"
                    />
                    <img
                      style={{
                        maxWidth: "100%",
                        margin: "10px 5px",
                        objectFit: "cover",
                        objectPosition: "center",
                        borderRadius: "10px",
                      }}
                      src={new_ui_page_messages}
                      alt="messages page"
                    />
                  </ResponsiveRow>
                );
              },
              `3. From the left hand-side panel, click on the NEW MESSAGE  button. This will load the New Message form.`,
              () => {
                return (
                  <ResponsiveRow
                    sx={{
                      margin: "10px auto",
                      width: { xs: "100%", lg: "90%" },
                      padding: "10px",
                    }}
                  >
                    <img
                      style={{
                        maxWidth: "100%",
                        margin: "10px 5px",
                        objectFit: "cover",
                        objectPosition: "center",
                        borderRadius: "10px",
                      }}
                      src={new_ui_form_messages_create}
                      alt="form messages create"
                    />
                  </ResponsiveRow>
                );
              },
              `4. Be sure to give the SMS a subject and write the message you wish to relay to your tenants.`,
              `   Please note: You can send a bulk message by selecting multiple recipients in the Message Recipients dropdown input.`,
              `5. Please ensure you have selected the check box, else the message shall be sent to the Tenant App.`,
              `6. Click the SEND MESSAGE button to Send out the SMS composed to the selected Tenants or Trash icon on the top right corner to discard the message`,
              `6. Click the SEND MESSAGE button to Send out the SMS composed to the selected Tenants or Trash icon on the top right corner to discard the message`,
            ],
          },
          {
            questionID: 20,
            question: "How do I send a message to the tenant app?",
            hasSubAnswers: false,
            hasSteps: true,
            steps: [
              `1. From the Menu, Click on the Messages Tab. This brings you to the Message page where you will view recently sent and received messages.`,
              `2. From the Menu column in the Message page, select button. This will prompt the “Send Message” form page.`,
              `3. From the send message form, you can either choose to send SMS to all Tenants by checking the “Select All Tenants” checkbox, or Select individual Tenants from the “To” dropdown field.`,
              `4. Avoid selecting check box, else the message shall be as an SMS to the Tenant.`,
              `5. Click the button to Send out the message composed to the selected Tenants App.`,
            ],
          },
        ],
      },
      {
        name: "systemUsers",
        text: "System Users",
        data: [
          {
            questionID: 22,
            question: "What are the user roles in the system?",
            hasSubAnswers: false,
            hasSteps: true,
            steps: [
              `Building Director`,
              `Building Manager`,
              `Accountant`,
              `Clerk`,
              `Reception`,
            ],
          },
          {
            questionID: 23,
            question: "Which permissions does each role have?",
            hasSubAnswers: true,
            hasSteps: false,
            subAnswers: [
              {
                header: "System Admin",
                steps: [
                  `Dashboard`,
                  `Manage companies`,
                  `Subscriptions`,
                  `Billing Services`,
                  `Mobile money`,
                  `Manage users`,
                  `Send messages`,
                  `Manage properties, leases, terminated leases, tenants, owners and utilities`,
                  `Manage work orders and approve`,
                  `Manage Assets`,
                  `Manage inventories`,
                  `Manage vendors`,
                  `Manage invoices`,
                  `Manage Bills and Payments`,
                  `Manage receipts`,
                  `Manage Journal entries`,
                  `Manage reconciliations`,
                  `Bank accounts and Transfers`,
                  `Chart of Accounts`,
                ],
              },
              {
                header: "Building Director",
                steps: [
                  `Dashboard`,
                  `Manage users`,
                  `Send messages`,
                  `Manage properties, leases, terminated leases, tenants, owners and utilities`,
                  `Manage work orders and approve`,
                  `Manage Assets`,
                  `Manage inventories`,
                  `Manage vendors`,
                  `Manage invoices`,
                  `Manage Bills and Payments`,
                  `Manage receipts`,
                  `Manage Journal entries`,
                  `Manage reconciliations`,
                  `Bank accounts and Transfers`,
                  `Chart of Accounts`,
                ],
              },
              {
                header: "Building Manager",
                steps: [
                  `Dashboard`,
                  `Manage users`,
                  `Send messages`,
                  `Manage properties, leases, terminated leases, tenants, owners and utilities`,
                  `Manage work orders and approve`,
                  `Manage Assets`,
                  `Manage inventories`,
                  `Manage vendors`,
                  `Manage receipts`,
                  `Manage Journal entries`,
                ],
              },
              {
                header: "Accountant",
                steps: [
                  `Dashboard`,
                  `Manage invoices`,
                  `Manage Bills and Payments`,
                  `Manage receipts`,
                  `Manage Journal entries`,
                  `Manage reconciliations`,
                  `Chart of Accounts`,
                ],
              },
              {
                header: "Clerk",
                steps: [`Record meter readings`],
              },
              {
                header: "Reception",
                steps: [`Issue receipts`],
              },
            ],
          },
          {
            questionID: 24,
            question: "How do I add a user?",
            hasSubAnswers: false,
            hasSteps: true,
            steps: [
              `1. Once logged in, click on the Manage User item in the navigation menu`,
              `2. This will take you to the Manage Users page.`,
              () => {
                return (
                  <ResponsiveRow
                    sx={{
                      margin: "10px auto",
                      width: { xs: "100%", lg: "90%" },
                      padding: "10px",
                    }}
                  >
                    <img
                      style={{
                        maxWidth: "100%",
                        margin: "10px 5px",
                        objectFit: "cover",
                        objectPosition: "center",
                        borderRadius: "10px",
                      }}
                      src={new_ui_select_user_menu}
                      alt="select user menu"
                    />
                    <img
                      style={{
                        maxWidth: "100%",
                        margin: "10px 5px",
                        objectFit: "cover",
                        objectPosition: "center",
                        borderRadius: "10px",
                      }}
                      src={new_ui_page_user}
                      alt="page user"
                    />
                  </ResponsiveRow>
                );
              },
              `3. Click on the ADD USER button. This will take you to the Individual/Organization Search popup modal.`,
              `4. Enter the new user's ID number and type to check if they already exist in the system. Click on SEARCH to execute the search`,
              () => {
                return (
                  <ResponsiveRow
                    sx={{
                      margin: "10px auto",
                      width: { xs: "100%", lg: "90%" },
                      padding: "10px",
                    }}
                  >
                    <img
                      style={{
                        maxWidth: "100%",
                        margin: "10px 5px",
                        objectFit: "cover",
                        objectPosition: "center",
                        borderRadius: "10px",
                      }}
                      src={new_ui_personOrg_search_modal}
                      alt="person org search modal"
                    />
                  </ResponsiveRow>
                );
              },
              `5. If the user exists, they're name will appear on the found users list. Clicking on their name will take you to the Add User popup modal`,
              `6. The system will preload the user's information and provide a Role dropdown input for you to select the role you'd like to assign the user.`,
              () => {
                return (
                  <ResponsiveRow
                    sx={{
                      margin: "10px auto",
                      width: { xs: "100%", lg: "90%" },
                      padding: "10px",
                    }}
                  >
                    <img
                      style={{
                        maxWidth: "100%",
                        margin: "10px 5px",
                        objectFit: "cover",
                        objectPosition: "center",
                        borderRadius: "10px",
                      }}
                      src={new_ui_personOrg_search_results_modal}
                      alt="person org search results modal"
                    />
                    <img
                      style={{
                        maxWidth: "100%",
                        margin: "10px 5px",
                        objectFit: "cover",
                        objectPosition: "center",
                        borderRadius: "10px",
                      }}
                      src={new_ui_add_user_pre_populated_modal}
                      alt="add user pre-populated modal"
                    />
                  </ResponsiveRow>
                );
              },
              `7. If the user does not exists, a no individuals found dialogue will appear asking if you'd like to create the user`,
              `8. Clicking on YES will take you to the Add User popup modal. Click on NO will close the popup modal`,
              `9. Upon clicking YES, the system will preload the user's ID number and type provided earlier. you can then populate the user details in the fields as required.`,
              () => {
                return (
                  <ResponsiveRow
                    sx={{
                      margin: "10px auto",
                      width: { xs: "100%", lg: "90%" },
                      padding: "10px",
                    }}
                  >
                    <img
                      style={{
                        maxWidth: "100%",
                        margin: "10px 5px",
                        objectFit: "cover",
                        objectPosition: "center",
                        borderRadius: "10px",
                      }}
                      src={new_ui_personOrg_search_no_results_modal}
                      alt="person org search no results modal"
                    />
                    <img
                      style={{
                        maxWidth: "100%",
                        margin: "10px 5px",
                        objectFit: "cover",
                        objectPosition: "center",
                        borderRadius: "10px",
                      }}
                      src={new_ui_add_user_modal}
                      alt="add user modal"
                    />
                  </ResponsiveRow>
                );
              },
              `10. Click CREATE to add the user or CANCEL to close the popup modal.`,
            ],
          },
          {
            questionID: 25,
            question: "How do I edit a user?",
            hasSubAnswers: false,
            hasSteps: true,
            steps: [
              `1. Once logged in, click on the Manage User item in the navigation menu`,
              `2. This will take you to the Manage Users page as shown in the Add a User tutorial`,
              `3. Click on the EDIT button of the user you'd like to edit. This will take you to the Edit User popup modal`,
              () => {
                return (
                  <ResponsiveRow
                    sx={{
                      margin: "10px auto",
                      width: { xs: "100%", lg: "90%" },
                      padding: "10px",
                    }}
                  >
                    <img
                      style={{
                        maxWidth: "100%",
                        margin: "10px 5px",
                        objectFit: "cover",
                        objectPosition: "center",
                        borderRadius: "10px",
                      }}
                      src={new_ui_card_user_edit}
                      alt="edit user card"
                    />
                    <img
                      style={{
                        maxWidth: "100%",
                        margin: "10px 5px",
                        objectFit: "cover",
                        objectPosition: "center",
                        borderRadius: "10px",
                      }}
                      src={new_ui_edit_user_modal}
                      alt="edit user modal"
                    />
                  </ResponsiveRow>
                );
              },
              `4. Edit the User’s information in the form fields.`,
              `5. Click the UPDATE button to update the user’s details or CANCEL to close the popup modal.`,
            ],
          },
          {
            questionID: 26,
            question: "How do I remove a user?",
            hasSubAnswers: false,
            hasSteps: true,
            steps: [
              `1. Once logged in, click on the Manage User item in the navigation menu`,
              `2. This will take you to the Manage Users page as shown in the Add a User tutorial`,
              `3. Click on the DELETE button of the user you'd like to delete. This will take you to the Delete Confirmation popup modal`,
              () => {
                return (
                  <ResponsiveRow
                    sx={{
                      margin: "10px auto",
                      width: { xs: "100%", lg: "90%" },
                      padding: "10px",
                    }}
                  >
                    <img
                      style={{
                        maxWidth: "100%",
                        margin: "10px 5px",
                        objectFit: "cover",
                        objectPosition: "center",
                        borderRadius: "10px",
                      }}
                      src={new_ui_card_user_delete}
                      alt="delete user card"
                    />
                    <img
                      style={{
                        maxWidth: "100%",
                        margin: "10px 5px",
                        objectFit: "cover",
                        objectPosition: "center",
                        borderRadius: "10px",
                      }}
                      src={new_ui_delete_user_modal}
                      alt="eddeleteit user modal"
                    />
                  </ResponsiveRow>
                );
              },
              `3. Click the YES button to delete the user or CANCEL to close the popup.`,
            ],
          },
        ],
      },
      {
        name: "utilities",
        text: "Utilities",
        data: [
          {
            questionID: 27,
            question: "What are the different types of meters?",
            hasSubAnswers: false,
            hasSteps: true,
            steps: [
              `1. Electricity – Unit`,
              `2. Municipal Electricity - Property`,
              `3. Municipal Water - Property`,
              `4. On-site Electricity – Property`,
              `5. Water - Unit`,
              `6. Prepaid Electricity - Unit`,
              `7. Other`,
            ],
          },
          {
            questionID: 28,
            question: "How do I add a meter?",
            hasSubAnswers: false,
            hasSteps: true,
            steps: [
              `1. Once logged in, click on the Homes / Rentals item in the navigation menu`,
              `2. Click on the Meters item on the dropdown. This will take you to the Meters page`,
              () => {
                return (
                  <ResponsiveRow
                    sx={{
                      margin: "10px auto",
                      width: { xs: "100%", lg: "90%" },
                      padding: "10px",
                    }}
                  >
                    <img
                      style={{
                        maxWidth: "100%",
                        margin: "10px 5px",
                        objectFit: "cover",
                        objectPosition: "center",
                        borderRadius: "10px",
                      }}
                      src={new_ui_select_meter_menu}
                      alt="select meter submenu"
                    />
                    <img
                      style={{
                        maxWidth: "100%",
                        margin: "10px 5px",
                        objectFit: "cover",
                        objectPosition: "center",
                        borderRadius: "10px",
                      }}
                      src={new_ui_select_meter_menu_hoa}
                      alt="select meters submenu hoa"
                    />{" "}
                  </ResponsiveRow>
                );
              },

              `3. Click on the ADD METER button. This will take you to the Add Meter popup modal`,
              () => {
                return (
                  <ResponsiveRow
                    sx={{
                      margin: "10px auto",
                      width: { xs: "100%", lg: "90%" },
                      padding: "10px",
                    }}
                  >
                    <img
                      style={{
                        maxWidth: "100%",
                        margin: "10px 5px",
                        objectFit: "cover",
                        objectPosition: "center",
                        borderRadius: "10px",
                      }}
                      src={new_ui_page_meter}
                      alt="select meters submenu hoa"
                    />{" "}
                    <img
                      style={{
                        maxWidth: "100%",
                        margin: "10px 5px",
                        objectFit: "cover",
                        objectPosition: "center",
                        borderRadius: "10px",
                      }}
                      src={new_ui_meter_add_modal}
                      alt="add smeter modal"
                    />{" "}
                  </ResponsiveRow>
                );
              },

              () => {
                return (
                  <Typography
                    variant="body2"
                    sx={{
                      lineHeight: "30px",
                      margin: "10px auto 20px auto",

                      textAlign: "start",
                      whiteSpace: "pre-wrap",

                      width: "80%",
                    }}
                  >
                    <strong>Take note of the following fields:</strong>
                    <br />
                    <span
                      style={{
                        color: kodi_theme.palette.primary.dark,
                        fontWeight: 700,
                      }}
                    >
                      Meter Reading Frequency Dropdown:
                    </span>
                    <br />
                    There are three options to choose from. You could either
                    collect meter readings{" "}
                    <strong>Daily, Weekly or Monthly</strong>. <br />
                    <br />{" "}
                    <span
                      style={{
                        color: kodi_theme.palette.primary.dark,
                        fontWeight: 700,
                      }}
                    >
                      Meter Type (Dropdown)
                    </span>
                    <br />
                    There are several types of meters. If the meter category
                    isn’t listed, select “Other” <br />
                    <br />
                    <span
                      style={{
                        color: kodi_theme.palette.primary.dark,
                        fontWeight: 700,
                      }}
                    >
                      Does this asset require maintenance?
                    </span>
                    <br />
                    It’s important to select this field if the Asset requires
                    periodic maintenance for better performance. Doing so will
                    allow you to select a maintenance account, maintenance
                    period, the acquired, purchase price and the current value
                    of the asset.
                    <br />
                    <br />
                    <span
                      style={{
                        color: kodi_theme.palette.primary.dark,
                        fontWeight: 700,
                      }}
                    >
                      {" "}
                      Attach to a property/unit{" "}
                    </span>{" "}
                    <br />- If the meter belongs to a Property/Unit, select the{" "}
                    <strong>Facility</strong> option and then select the desired
                    facility item from the dropdown field that appears. <br />-
                    If the meter belongs to a complex, select the{" "}
                    <strong>Complex</strong> option. Select the desired complex
                    item from the dropdown field that appears. <br />
                    <br />
                    <span
                      style={{
                        color: kodi_theme.palette.primary.dark,
                        fontWeight: 700,
                      }}
                    >
                      {" "}
                      Is this meter invoiced to the unit tenant?{" "}
                    </span>
                    <br />
                    It’s important to select this field if the meter charges are
                    invoiced to the tenant whose unit is attached to the
                    specific meter rate. It’s important to indicate the meter
                    rate in <strong>KES</strong>
                  </Typography>
                );
              },

              `4. Fill in the required details in the provided fields.`,
              `5. Click CREATE to add the Meter details or CANCEL to close the popup modal.`,
            ],
          },
          {
            questionID: 29,
            question: "How do I edit a meter?",
            hasSubAnswers: false,
            hasSteps: true,
            steps: [
              `1. Once logged in, click on the Homes / Rentals item in the navigation menu`,
              `2. Click on the Meters item on the dropdown. This will take you to the Meters page, as shown in the Add a Meter tutorial`,
              `3. Click on the three-dots icon of the desired meter and click on the Edit item. This will bring you to the Edit Meter popup modal`,
              () => {
                return (
                  <ResponsiveRow
                    sx={{
                      margin: "10px auto",
                      width: { xs: "100%", lg: "90%" },
                      padding: "10px",
                    }}
                  >
                    <img
                      style={{
                        maxWidth: "100%",
                        margin: "10px 5px",
                        objectFit: "cover",
                        objectPosition: "center",
                        borderRadius: "10px",
                      }}
                      src={new_ui_datagrid_meter_edit}
                      alt="select datagrid_meter_edit submenu"
                    />
                    <img
                      style={{
                        maxWidth: "100%",
                        margin: "10px 5px",
                        objectFit: "cover",
                        objectPosition: "center",
                        borderRadius: "10px",
                      }}
                      src={new_ui_meter_edit_modal}
                      alt="select meters submenu hoa"
                    />{" "}
                  </ResponsiveRow>
                );
              },
              `4. Click the UPDATE button to update the meter's details or CANCEL to close the popup modal.`,
            ],
          },
          {
            //   questionID: 30,
            //   question: "How do I assign standing charges to a meter?",
            //   hasSubAnswers: false,
            //   hasSteps: true,
            //   steps: [
            //     `1. Navigate to the Meter you would want to add a “standing charge” or Add a new meter.`,
            //     `2. From the “Edit Meter” form page, navigate to the “standing charge” field (See below image) Use the tiny arrows on the right to increase or decrease the standing charge number.`,
            //   ],
          },
        ],
      },
      {
        name: "utilitiesApp",
        text: "Utilities App",
        data: [
          {
            questionID: 31,
            question: "How do I download the “SmartKodi Meter” App?",
            hasSubAnswers: false,
            hasSteps: true,
            steps: [
              `1. Download the “PlayStore App” to your mobile phone device.`,
              `2. Search “SmartKodi Meter” on the search area.`,
              `3. Select the SmartKodi Meter app. The install page will be shown.`,
              `4. Click “Install” to download the app and follow the instructions to complete the installation process.`,
            ],
          },
          {
            questionID: 32,
            question: "How do I read a meter?",
            hasSubAnswers: false,
            hasSteps: true,
            steps: [
              `1. Log in to the SmartKodi Meter app using your username and password (See image below)`,
              () => {
                return (
                  <Box
                    sx={{
                      margin: "10px auto",
                      width: { xs: "100%", lg: "80%" },
                      padding: "10px",
                    }}
                  >
                    <img
                      style={{
                        maxWidth: "100%",
                        objectFit: "contain",
                        objectPosition: "center",
                      }}
                      src={read_a_meter}
                      alt="read a meter"
                    />
                  </Box>
                );
              },
              `2. Once logged in. A Landing page shall be presented. (See image below)`,
              () => {
                return (
                  <Box
                    sx={{
                      margin: "10px auto",
                      width: { xs: "100%", lg: "80%" },
                      padding: "10px",
                    }}
                  >
                    <img
                      style={{
                        maxWidth: "100%",
                        objectFit: "contain",
                        objectPosition: "center",
                      }}
                      src={read_a_meter_2}
                      alt="read a meter 2"
                    />
                  </Box>
                );
              },
              () => {
                return (
                  <Typography
                    sx={{
                      fontSize: "1rem",
                      lineHeight: "30px",
                      margin: { xs: "10px auto", lg: "0px 0 5px 5px" },
                      color: "#333",
                      fontWeight: 500,
                      textAlign: "start",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    {`
                To Note: There are four types of Meters that one can take readings from
                    a. Water - Cubic Meters
                    b. Electricity – Kw/Hrs
              `}
                  </Typography>
                );
              },

              `3. Select either the “Water” or “Electricity” Meter tab from the landing page and a list of all facility readings (that the user has access to) comes up.`,
              () => {
                return (
                  <Box
                    sx={{
                      margin: "10px auto",
                      width: { xs: "100%", lg: "80%" },
                      padding: "10px",
                    }}
                  >
                    <img
                      style={{
                        maxWidth: "100%",
                        objectFit: "contain",
                        objectPosition: "center",
                      }}
                      src={read_a_meter_3}
                      alt="read a meter 3"
                    />
                  </Box>
                );
              },
              `4. Search the unit. Swipe Left to reveal a “+” icon (See image below)`,
              () => {
                return (
                  <Box
                    sx={{
                      margin: "10px auto",
                      width: { xs: "100%", lg: "80%" },
                      padding: "10px",
                    }}
                  >
                    <img
                      style={{
                        maxWidth: "100%",
                        objectFit: "contain",
                        objectPosition: "center",
                      }}
                      src={read_a_meter_4}
                      alt="read a meter 4"
                    />
                  </Box>
                );
              },
              `5. Click on the “+” icon. This will take you to the “Save reading” page.`,
              `6. Type in the current meter reading.`,
              `7. Capture an image of the meter number by clicking on the “image” icon`,
              () => {
                return (
                  <Box
                    sx={{
                      margin: "10px auto",
                      width: { xs: "100%", lg: "80%" },
                      padding: "10px",
                    }}
                  >
                    <img
                      style={{
                        maxWidth: "100%",
                        objectFit: "contain",
                        objectPosition: "center",
                      }}
                      src={read_a_meter_5}
                      alt="read a meter 5"
                    />
                  </Box>
                );
              },
              `8. Click the SAVE button to record the Meter reading or CANCEL to discard the reading.`,
            ],
          },
          {
            questionID: 33,
            question: "How do I record a meter as broken?",
            hasSubAnswers: false,
            hasSteps: true,
            steps: [
              `1. Select either the “Water” or “Electricity” Meter tab from the landing page and a list of all facility readings (that the user has access to) comes up.`,
              `2. Search the unit. Swipe Right to reveal a “hammer” icon (See image below)`,
              () => {
                return (
                  <Box
                    sx={{
                      margin: "10px auto",
                      width: { xs: "100%", lg: "80%" },
                      padding: "10px",
                    }}
                  >
                    <img
                      style={{
                        maxWidth: "100%",
                        objectFit: "contain",
                        objectPosition: "center",
                      }}
                      src={record_a_meter_as_broken}
                      alt="record a meter as broken"
                    />
                  </Box>
                );
              },
              `3. Click on the “hammer” icon. A “Report Meter Broken” pop up appears asking if you’re sure you want to report it as broken. (See image below)`,
              () => {
                return (
                  <Box
                    sx={{
                      margin: "10px auto",
                      width: { xs: "100%", lg: "80%" },
                      padding: "10px",
                    }}
                  >
                    <img
                      style={{
                        maxWidth: "100%",
                        objectFit: "contain",
                        objectPosition: "center",
                      }}
                      src={record_a_meter_as_broken_2}
                      alt="record a meter as broken 2"
                    />
                  </Box>
                );
              },
              `4. Select Report to alert the Meter as broken or Cancel to discard the alert.`,
            ],
          },
        ],
      },
      {
        name: "accounting",
        text: "Accounting",
        data: [
          {
            questionID: 34,
            question: "How do I find an invoice?",
            hasSubAnswers: false,
            hasSteps: true,
            steps: [
              `1. Once logged in, click on the Accounting item in the navigation menu`,
              `2. Select a Invoice Type from the Dropdown. This will take you to the Invoices page.`,
              () => {
                return (
                  <ResponsiveRow
                    sx={{
                      margin: "10px auto",
                      width: { xs: "100%", lg: "90%" },
                      padding: "10px",
                    }}
                  >
                    <img
                      style={{
                        maxWidth: "100%",
                        margin: "10px 5px",
                        objectFit: "cover",
                        objectPosition: "center",
                        borderRadius: "10px",
                      }}
                      src={new_ui_select_invoice_menu}
                      alt="report menu"
                    />
                    <img
                      style={{
                        maxWidth: "100%",
                        margin: "10px 5px",
                        objectFit: "cover",
                        objectPosition: "center",
                        borderRadius: "10px",
                      }}
                      src={new_ui_page_invoice}
                      alt="generate report modal"
                    />
                  </ResponsiveRow>
                );
              },
              `3. If you remember the invoice details, you can fill them into the Invoice Search form and click Search button to filter by those details, or you click on the Show All Invoices button to browse the available invoices.`,
              `4. Either option will take you to the Invoices Table where you could do more on the desired invoice.`,
              () => {
                return (
                  <ResponsiveRow
                    sx={{
                      margin: "10px auto",
                      width: { xs: "100%", lg: "90%" },
                      padding: "10px",
                    }}
                  >
                    <img
                      style={{
                        maxWidth: "100%",
                        margin: "10px 5px",
                        objectFit: "cover",
                        objectPosition: "center",
                        borderRadius: "10px",
                      }}
                      src={new_ui_datagrid_invoice}
                      alt="invoice datagrid"
                    />
                  </ResponsiveRow>
                );
              },
            ],
          },
          {
            questionID: 35,
            question: "How do I find a receipt?",
            hasSubAnswers: false,
            hasSteps: true,
            steps: [
              `1. Once logged in, click on the Accounting item in the navigation menu`,
              `2. Select the Receipts item from the Dropdown. This will take you to the Receipts page.`,
              () => {
                return (
                  <ResponsiveRow
                    sx={{
                      margin: "10px auto",
                      width: { xs: "100%", lg: "90%" },
                      padding: "10px",
                    }}
                  >
                    <img
                      style={{
                        maxWidth: "100%",
                        margin: "10px 5px",
                        objectFit: "cover",
                        objectPosition: "center",
                        borderRadius: "10px",
                      }}
                      src={new_ui_select_receipt_menu}
                      alt="report menu"
                    />
                    <img
                      style={{
                        maxWidth: "100%",
                        margin: "10px 5px",
                        objectFit: "cover",
                        objectPosition: "center",
                        borderRadius: "10px",
                      }}
                      src={new_ui_page_receipt}
                      alt="generate report modal"
                    />
                  </ResponsiveRow>
                );
              },
              `3. If you remember the receipt details, you can fill them into the Receipt Search form and click Search button to filter by those details, or you click on the Show All Receipts button to browse the available receipts.`,
              `4. Either option will take you to the Receipts Table where you could do more on the desired receipts.`,
              () => {
                return (
                  <ResponsiveRow
                    sx={{
                      margin: "10px auto",
                      width: { xs: "100%", lg: "90%" },
                      padding: "10px",
                    }}
                  >
                    <img
                      style={{
                        maxWidth: "100%",
                        margin: "10px 5px",
                        objectFit: "cover",
                        objectPosition: "center",
                        borderRadius: "10px",
                      }}
                      src={new_ui_datagrid_receipts}
                      alt="invoice datagrid"
                    />
                  </ResponsiveRow>
                );
              },
            ],
          },
          {
            questionID: 36,
            question: "How do I void a receipt?",
            hasSubAnswers: false,
            hasSteps: true,
            steps: [
              `1. Once logged in, click on the Accounting item in the navigation menu`,
              `2. Select the Receipt item from the Dropdown. This will take you to the Receipts page, as shown in the Find a Receipts tutorial.`,
              `3. You can use the provided Receipt Search form to find the desired receipt or proceed to the Receipts Table by clicking the SHOW ALL RECEIPTS button`,
              () => {
                return (
                  <ResponsiveRow
                    sx={{
                      margin: "10px auto",
                      width: { xs: "100%", lg: "90%" },
                      padding: "10px",
                    }}
                  >
                    <img
                      style={{
                        maxWidth: "100%",
                        margin: "10px 5px",
                        objectFit: "cover",
                        objectPosition: "center",
                        borderRadius: "10px",
                      }}
                      src={new_ui_page_receipt}
                      alt="generate report modal"
                    />
                  </ResponsiveRow>
                );
              },
              `4. On the desired receipt, click on the three-dot icon and select the VOID RECEIPT item. This will take you to the Create Receipt Charge popup modal`,
              () => {
                return (
                  <ResponsiveRow
                    sx={{
                      margin: "10px auto",
                      width: { xs: "100%", lg: "90%" },
                      padding: "10px",
                    }}
                  >
                    <img
                      style={{
                        maxWidth: "100%",
                        margin: "10px 5px",
                        objectFit: "cover",
                        objectPosition: "center",
                        borderRadius: "10px",
                      }}
                      src={new_ui_datagrid_receipt_void}
                      alt="report menu"
                    />
                    <img
                      style={{
                        maxWidth: "100%",
                        margin: "10px 5px",
                        objectFit: "cover",
                        objectPosition: "center",
                        borderRadius: "10px",
                      }}
                      src={new_ui_receipt_charge_modal}
                      alt="generate report modal"
                    />
                  </ResponsiveRow>
                );
              },
            ],
          },
          {
            questionID: 37,
            question: "How do I issue a charge?",
            hasSubAnswers: false,
            hasSteps: true,
            steps: [
              `1. Once logged in, click on the Rentals item in the navigation menu`,
              `2. Select the Leases item from the Dropdown. This will take you to the Leases page`,
              () => {
                return (
                  <ResponsiveRow
                    sx={{
                      margin: "10px auto",
                      width: { xs: "100%", lg: "90%" },
                      padding: "10px",
                    }}
                  >
                    <img
                      style={{
                        maxWidth: "100%",
                        margin: "10px 5px",
                        objectFit: "cover",
                        objectPosition: "center",
                        borderRadius: "10px",
                      }}
                      src={new_ui_leases_menu}
                      alt="generate report modal"
                    />
                    <img
                      style={{
                        maxWidth: "100%",
                        margin: "10px 5px",
                        objectFit: "cover",
                        objectPosition: "center",
                        borderRadius: "10px",
                      }}
                      src={new_ui_page_leases}
                      alt="generate report modal"
                    />
                  </ResponsiveRow>
                );
              },
              `3. On the desired lease row, click on the three-dot icon and click on the ISSUE CHARGE item. This will take you to the Create a Charge popup modal`,
              () => {
                return (
                  <ResponsiveRow
                    sx={{
                      margin: "10px auto",
                      width: { xs: "100%", lg: "90%" },
                      padding: "10px",
                    }}
                  >
                    <img
                      style={{
                        maxWidth: "100%",
                        margin: "10px 5px",
                        objectFit: "cover",
                        objectPosition: "center",
                        borderRadius: "10px",
                      }}
                      src={new_ui_datagrid_leases_charge}
                      alt="generate report modal"
                    />
                    <img
                      style={{
                        maxWidth: "100%",
                        margin: "10px 5px",
                        objectFit: "cover",
                        objectPosition: "center",
                        borderRadius: "10px",
                      }}
                      src={new_ui_lease_add_charge_modal}
                      alt="generate report modal"
                    />
                  </ResponsiveRow>
                );
              },
              () => {
                return (
                  <Typography
                    variant="body2"
                    sx={{
                      lineHeight: "30px",
                      margin: { xs: "10px auto", lg: "20px auto" },
                      width: "90%",
                      // color: "#222",
                      fontWeight: 500,
                      textAlign: "start",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    <strong style={{ color: kodi_theme.palette.primary.dark }}>
                      Take note of the following fields:
                    </strong>{" "}
                    <br />
                    <strong>Charge Date</strong> <br />
                    The date the charge was issued. Date is auto selected based
                    on today’s date. <br />
                    <strong>Account:</strong>
                    <br />
                    Select the account the charge is issued to from the drop
                    down. Amount: Input the charge amount. Apply VAT: If the
                    charge is to be taxed, ensure you select the “Apply VAT”
                    check box.
                  </Typography>
                );
              },
              `4. Fill in the fields provided to with the necessary details.`,
              `5. Click the SAVE button to issue the charge or the Cancel button to close the popup modal.`,
            ],
          },
          {
            questionID: 38,
            question: "How do I issue a credit?",
            hasSubAnswers: false,
            hasSteps: true,
            steps: [
              `1. Once logged in, click on the Rentals item in the navigation menu`,
              `2. Select the Leases item from the Dropdown. This will take you to the Leases page as shown in the Add a Charge tutorial`,
              `3. On the desired lease row, click on the three-dot icon and click on the ISSUE CREDIT item. This will take you to the Create a Charge popup modal`,

              () => {
                return (
                  <ResponsiveRow
                    sx={{
                      margin: "10px auto",
                      width: { xs: "100%", lg: "90%" },
                      padding: "10px",
                    }}
                  >
                    <img
                      style={{
                        maxWidth: "100%",
                        margin: "10px 5px",
                        objectFit: "cover",
                        objectPosition: "center",
                        borderRadius: "10px",
                      }}
                      src={new_ui_datagrid_leases_credit}
                      alt="generate report modal"
                    />
                    <img
                      style={{
                        maxWidth: "100%",
                        margin: "10px 5px",
                        objectFit: "cover",
                        objectPosition: "center",
                        borderRadius: "10px",
                      }}
                      src={new_ui_lease_add_credit_modal}
                      alt="generate report modal"
                    />
                  </ResponsiveRow>
                );
              },
              () => {
                return (
                  <Typography
                    sx={{
                      fontSize: "1rem",
                      lineHeight: "30px",
                      margin: { xs: "10px auto", lg: "0px 0 5px 5px" },
                      color: "#333",
                      fontWeight: 500,
                      textAlign: "start",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    {`
             The date is auto selected based on today’s date.
              `}
                  </Typography>
                );
              },

              `4. Fill in the fields provided to with the necessary details`,
              `5. Click the SAVE button to issue the credit or the Cancel button to close the popup modal.`,
            ],
          },
          {
            questionID: 39,
            question: "How do I create a bill?",
            hasSubAnswers: false,
            hasSteps: true,
            steps: [
              `1. Once logged in, click on the Accounting item in the navigation menu`,
              `2. Select the Bill & Payments item from the Dropdown. This will take you to the Bills & Payments page as shown in the Add a Bill tutorial`,
              () => {
                return (
                  <ResponsiveRow
                    sx={{
                      margin: "10px auto",
                      width: { xs: "100%", lg: "90%" },
                      padding: "10px",
                    }}
                  >
                    <img
                      style={{
                        maxWidth: "100%",
                        margin: "10px 5px",
                        objectFit: "cover",
                        objectPosition: "center",
                        borderRadius: "10px",
                      }}
                      src={new_ui_bills_payment_menu}
                      alt="generate report modal"
                    />
                    <img
                      style={{
                        maxWidth: "100%",
                        margin: "10px 5px",
                        objectFit: "cover",
                        objectPosition: "center",
                        borderRadius: "10px",
                      }}
                      src={new_ui_page_bills_payments}
                      alt="generate report modal"
                    />
                  </ResponsiveRow>
                );
              },
              `3. Click on the ADD BILL button. This will take you to the Create New Bill popup modal.`,
              () => {
                return (
                  <ResponsiveRow
                    sx={{
                      margin: "10px auto",
                      width: { xs: "100%", lg: "90%" },
                      padding: "10px",
                    }}
                  >
                    <img
                      style={{
                        maxWidth: "100%",
                        margin: "10px 5px",
                        objectFit: "cover",
                        objectPosition: "center",
                        borderRadius: "10px",
                      }}
                      src={new_ui_add_bills_modal}
                      alt="generate report modal"
                    />
                  </ResponsiveRow>
                );
              },
              `4. Fill in the fields provided to with the necessary details`,
              `5. Click the SAVE button to issue the bill or the Cancel button to close the popup modal.`,
            ],
          },
          {
            question: "How do I pay a bill?",
            hasSubAnswers: false,
            hasSteps: true,
            steps: [
              `1. Once logged in, click on the Accounting item in the navigation menu`,
              `2. Select the Bill & Payments item from the Dropdown. This will take you to the Bills & Payments page as shown in the Add a Bill tutorial`,
              `3. On the top of the card, click on the Payments item. This will take you to the Payments tab view`,
              `4. Click on the Make Payment button. This will take you to the Make Payment popup modal`,
              () => {
                return (
                  <ResponsiveRow
                    sx={{
                      margin: "10px auto",
                      width: { xs: "100%", lg: "90%" },
                      padding: "10px",
                    }}
                  >
                    <img
                      style={{
                        maxWidth: "100%",
                        margin: "10px 5px",
                        objectFit: "cover",
                        objectPosition: "center",
                        borderRadius: "10px",
                      }}
                      src={new_ui_payments_tab}
                      alt="generate report modal"
                    />
                    <img
                      style={{
                        maxWidth: "100%",
                        margin: "10px 5px",
                        objectFit: "cover",
                        objectPosition: "center",
                        borderRadius: "10px",
                      }}
                      src={new_ui_make_payment_modal}
                      alt="generate report modal"
                    />
                  </ResponsiveRow>
                );
              },
              () => {
                return (
                  <Box
                    sx={{
                      margin: "10px auto",
                      width: { xs: "100%", lg: "80%" },
                      padding: "10px",
                    }}
                  >
                    <img
                      style={{
                        maxWidth: "100%",
                        objectFit: "contain",
                        objectPosition: "center",
                      }}
                      src={pay_a_bill_2}
                      alt="pay a bill 2"
                    />
                  </Box>
                );
              },
              `5. Click SAVE once you’re done populating the Payment information.`,
            ],
          },
        ],
      },
      {
        name: "maintenance",
        text: "Maintenance",
        data: [
          {
            questionID: 40,
            question: "How do I create a work order?",
            hasSubAnswers: false,
            hasSteps: true,
            steps: [
              `1. Once logged in, click on the Maintenance item in the navigation menu`,
              `2. Select the work Orders item from the Dropdown. This will take you to the Work Order page`,
              () => {
                return (
                  <ResponsiveRow
                    sx={{
                      margin: "10px auto",
                      width: { xs: "100%", lg: "90%" },
                      padding: "10px",
                    }}
                  >
                    <img
                      style={{
                        maxWidth: "100%",
                        margin: "10px 5px",
                        objectFit: "cover",
                        objectPosition: "center",
                        borderRadius: "10px",
                      }}
                      src={new_ui_menu_work_order}
                      alt="generate report modal"
                    />
                    <img
                      style={{
                        maxWidth: "100%",
                        margin: "10px 5px",
                        objectFit: "cover",
                        objectPosition: "center",
                        borderRadius: "10px",
                      }}
                      src={new_ui_page_work_order}
                      alt="generate report modal"
                    />
                  </ResponsiveRow>
                );
              },
              `3. Fill in the fields provided to with the necessary details.`,
              `4. Click the CREATE button to submit the request or Cancel button to close the popup modal.`,
              `5. Take note: Once a work order has been created, the Building director has to approve first before the actual process.`,
            ],
          },
          {
            questionID: 41,
            question: "How do I edit a work order?",
            hasSubAnswers: false,
            hasSteps: true,
            steps: [
              `1. Once logged in, click on the Maintenance item in the navigation menu`,
              `2. Select the Work Orders item from the Dropdown. This will take you to the Work Order page as shown in the Create a Work Order tutorial`,
              () => {
                return (
                  <Typography
                    variant="body2"
                    sx={{
                      lineHeight: "30px",

                      margin: { xs: "10px auto", lg: "10px auto" },
                      width: "90%",

                      textAlign: "start",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    <span
                      style={{
                        color: kodi_theme.palette.primary.dark,
                        fontWeight: 700,
                      }}
                    >
                      Take note: A work order can be edited
                    </span>
                    <br /> - in terms of adding a new charge, creating new notes
                    or uploading documents <br /> - if the it is at the
                    Requested (awaiting approval) or Pending (approved but not
                    started) stage
                  </Typography>
                );
              },
              `3. On the desired work order row, click on the three-dot icon and click on the EDIT WORK ORDER item. This will take you to the Edit Work Order popup modal`,
              () => {
                return (
                  <ResponsiveRow
                    sx={{
                      margin: "10px auto",
                      width: { xs: "100%", lg: "90%" },
                      padding: "10px",
                    }}
                  >
                    <img
                      style={{
                        maxWidth: "100%",
                        margin: "10px 5px",
                        objectFit: "cover",
                        objectPosition: "center",
                        borderRadius: "10px",
                      }}
                      src={new_ui_datagrid_work_order_edit}
                      alt="generate report modal"
                    />
                    <img
                      style={{
                        maxWidth: "100%",
                        margin: "10px 5px",
                        objectFit: "cover",
                        objectPosition: "center",
                        borderRadius: "10px",
                      }}
                      src={new_ui_work_order_edit_modal}
                      alt="generate report modal"
                    />
                  </ResponsiveRow>
                );
              },
              `4. Edit the work order request as required. Click on the UPDATE button to save the work order changes or CANCEL to close the popup modal.`,
            ],
          },
          {
            questionID: 42,
            question: "How do I add a vendor?",
            hasSubAnswers: false,
            hasSteps: true,
            steps: [
              `1. Once logged in, click on the Maintenance item in the navigation menu`,
              `2. Select the Vendors item from the Dropdown. This will take you to the Vendor page.`,
              () => {
                return (
                  <ResponsiveRow
                    sx={{
                      margin: "10px auto",
                      width: { xs: "100%", lg: "90%" },
                      padding: "10px",
                    }}
                  >
                    <img
                      style={{
                        maxWidth: "100%",
                        margin: "10px 5px",
                        objectFit: "cover",
                        objectPosition: "center",
                        borderRadius: "10px",
                      }}
                      src={new_ui_menu_vendor}
                      alt="generate report modal"
                    />
                    <img
                      style={{
                        maxWidth: "100%",
                        margin: "10px 5px",
                        objectFit: "cover",
                        objectPosition: "center",
                        borderRadius: "10px",
                      }}
                      src={new_ui_page_vendors}
                      alt="generate report modal"
                    />
                  </ResponsiveRow>
                );
              },
              `3. Click on the ADD VENDOR button. This will take you to the Search Individual/Organization popup modal.`,
              () => {
                return (
                  <Typography
                    variant="body2"
                    sx={{
                      lineHeight: "30px",

                      margin: { xs: "10px auto", lg: "10px auto" },
                      width: "90%",

                      textAlign: "start",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    <span
                      style={{
                        color: kodi_theme.palette.primary.dark,
                        fontWeight: 700,
                      }}
                    >
                      Take note: A vendor can either an individual or a
                      organization/company
                    </span>
                  </Typography>
                );
              },
              `4. Enter the new vendor's ID type and number (if the vendor is an individual) or company's name (if the vendor is a organization) to check if they already exist in the system. Click on SEARCH to execute the search`,
              () => {
                return (
                  <ResponsiveRow
                    sx={{
                      margin: "10px auto",
                      width: { xs: "100%", lg: "90%" },
                      padding: "10px",
                    }}
                  >
                    <img
                      style={{
                        maxWidth: "100%",
                        margin: "10px 5px",
                        objectFit: "cover",
                        objectPosition: "center",
                        borderRadius: "10px",
                      }}
                      src={new_ui_add_vendor_results_modal}
                      alt="generate report modal"
                    />
                    <img
                      style={{
                        maxWidth: "100%",
                        margin: "10px 5px",
                        objectFit: "cover",
                        objectPosition: "center",
                        borderRadius: "10px",
                      }}
                      src={new_ui_add_vendor_no_results_modal}
                      alt="generate report modal"
                    />
                  </ResponsiveRow>
                );
              },
              `5. If the vendor exists, they're name will appear on the found user list. Clicking on their name will take you to the Add Vendor popup modal`,
              () => {
                return (
                  <ResponsiveRow
                    sx={{
                      margin: "10px auto",
                      width: { xs: "100%", lg: "90%" },
                      padding: "10px",
                    }}
                  >
                    <img
                      style={{
                        maxWidth: "100%",
                        margin: "10px 5px",
                        objectFit: "cover",
                        objectPosition: "center",
                        borderRadius: "10px",
                      }}
                      src={new_ui_vendor_add_vendor_modal}
                      alt="generate report modal"
                    />
                  </ResponsiveRow>
                );
              },
              `4. Enter the new vendor's ID number and type to check if they already exist in the system. Click on SEARCH to execute the search.`,
              () => {
                return (
                  <Box
                    sx={{
                      margin: "10px auto",
                      width: { xs: "100%", lg: "80%" },
                      padding: "10px",
                    }}
                  >
                    <img
                      style={{
                        maxWidth: "100%",
                        objectFit: "contain",
                        objectPosition: "center",
                      }}
                      src={add_a_vendor}
                      alt="add a vendor"
                    />
                  </Box>
                );
              },
              `3. Click SAVE button to add the Vendor as company or CANCEL to discard the information.`,
              `4. If you select “Individual” you shall be required to input the persons “ID Number” and select their “ID Type” from the dropdown field in order for the system to search the individual in the system else if not found proceed to creating one.`,
              `5. Click button. This shall display the Vendor details” form as shown in the image below.`,
              () => {
                return (
                  <ResponsiveRow
                    sx={{
                      margin: "10px auto",
                      width: { xs: "100%", lg: "90%" },
                      padding: "10px",
                    }}
                  >
                    <img
                      style={{
                        maxWidth: "100%",
                        margin: "10px 5px",
                        objectFit: "cover",
                        objectPosition: "center",
                        borderRadius: "10px",
                      }}
                      src={new_ui_add_vendor_individual_modal}
                      alt="generate report modal"
                    />
                    <img
                      style={{
                        maxWidth: "100%",
                        margin: "10px 5px",
                        objectFit: "cover",
                        objectPosition: "center",
                        borderRadius: "10px",
                      }}
                      src={new_ui_add_vendor_company_modal}
                      alt="generate report modal"
                    />
                  </ResponsiveRow>
                );
              },
              `6. Populate the fields with the required details.`,
              `7. Click the SAVE button to add the Vendor or the CANCEL button to close the popup modal.`,
            ],
          },
          // {
          //   questionID: 43,
          //   question: "How do I pay a vendor?",
          //   hasSubAnswers: false,
          //   hasSteps: true,
          //   steps: [
          //     `1. Select Assets category dropdown from Menu -> Work Orders and you should be presented with the Work orders (In progress) page.`,
          //     `2. Click the View button in line with the Work order. This will display the Work order information form page.`,
          //     () => {
          //       return (
          //         <Box
          //           sx={{
          //             margin: "10px auto",
          //             width: { xs: "100%", lg: "80%" },
          //             padding: "10px",
          //           }}
          //         >
          //           <img
          //             style={{
          //               maxWidth: "100%",
          //               objectFit: "contain",
          //               objectPosition: "center",
          //             }}
          //             src={pay_a_vendor}
          //             alt="pay a vendor"
          //           />
          //         </Box>
          //       );
          //     },
          //     `3. To Add a Issue a payment to the Vendor, click on the button. An “Issue Payment Voucher to Vendor” form page will be prompted.`,
          //     () => {
          //       return (
          //         <Box
          //           sx={{
          //             margin: "10px auto",
          //             width: { xs: "100%", lg: "80%" },
          //             padding: "10px",
          //           }}
          //         >
          //           <img
          //             style={{
          //               maxWidth: "100%",
          //               objectFit: "contain",
          //               objectPosition: "center",
          //             }}
          //             src={pay_a_vendor_2}
          //             alt="pay a vendor_2"
          //           />
          //         </Box>
          //       );
          //     },
          //     `4. Select the vendor to whom the payment is to issued. Populate the rest of the information as described in the fields.`,
          //     `5. Click button to issue the payment to the vendor..`,
          //   ],
          // },
        ],
      },
    ];
  }

  mapQuestionsToArray() {
    const questionsArray = [];
    let count = 0;
    this.faqData.map((data) => {
      return data.data.forEach((question_item) => {
        count++;
        questionsArray.push({
          id: count,
          label: question_item.question,
          section: data.name,
        });
      });
    });

    return questionsArray;
  }
}
