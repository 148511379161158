import {Alert, AlertTitle, Grid, InputLabel, Paper, TextField,} from "@mui/material"
import {InnerModal} from "../../../../constants/component.constants"
import {formatCurrency} from "../../../../util/util"
import ModalContainerModule from "../../../modules/modalContainer"

export default function SuspendTerminatedLeaseModal(props) {
    /**
     *
     * REQUIRED PROPS
     *
     * suspendModal
     *
     * handleSuspendReactivateClose
     * onTextChange
     *
     * selectedLease
     * statusChangeReason
     * suspendReActivateLeaseForm
     * task
     *
     */

    return (
        <ModalContainerModule
            size="md"
            accept={props.suspendReActivateLeaseForm}
            cancel={props.handleSuspendReactivateClose}
            openModal={props.suspendModal}
            modalTitle={`${props.task} Lease`}
            acceptButtonText={`${props.task} Lease`}
        >
            <Paper
                variant="outlined"
                sx={{height: "auto", margin: "auto", padding: "10px"}}
            >
                <InnerModal container>
                    <Grid item xs={12}>
                        {props.task === "Suspend" && (
                            <Alert severity="info">
                                <AlertTitle>
                                    Suspend Lease{" "}
                                    {props.selectedLease && props.selectedLease.billingCode} for{" "}
                                    {props.selectedLease && props.selectedLease.tenant}{" "}
                                </AlertTitle>
                                When you suspend a lease, the tenant shall no longer be
                                invoiced. To resume invoicing, you can Re-Activate the lease.
                                The tenant shall still be able to make payments against this
                                lease
                                <p>
                                    Current Balance:{" "}
                                    {formatCurrency(
                                        props.selectedLease && props.selectedLease.balance
                                    )}{" "}
                                </p>
                            </Alert>
                        )}
                        {props.task === "Re-Activate" && (
                            <Alert severity="info">
                                <AlertTitle>
                                    Re-Activate Lease{" "}
                                    {props.selectedLease && props.selectedLease.billingCode} for{" "}
                                    {props.selectedLease && props.selectedLease.tenant}{" "}
                                </AlertTitle>
                                When you Re-Activate a lease, invoicing the tenant shall resume.
                                The tenant shall still be able to make payments against this
                                lease
                                <p>
                                    Current Balance:{" "}
                                    {formatCurrency(
                                        props.selectedLease && props.selectedLease.balance
                                    )}{" "}
                                </p>
                            </Alert>
                        )}
                        <br/>
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel>Reason for {props.task}</InputLabel>
                        <TextField
                            sx={{width: "100%", height: "80px"}}
                            id={"statusChangeReason"}
                            value={props.statusChangeReason}
                            isrequired
                            onChange={(searchText) => props.onTextChange(searchText)}
                        />
                    </Grid>
                </InnerModal>
            </Paper>
        </ModalContainerModule>
    )
}
