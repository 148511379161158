import {
    Alert,
    AlertTitle,
    Grid,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material"
import {InnerModal, ModalAcceptButton,} from "../../../../constants/component.constants"
import {ResponsiveRow} from "../../../../constants/layout.constants"
import ModalContainerModule from "../../../modules/modalContainer"

export default function TerminatedSwapUnitModal(props) {
    /**
     *
     * REQUIRED PROPS
     *
     * saveEditUnit
     * saveLeaseCharges
     * handleLeaseChargeClose
     * handleLeaseChargeItemAdd
     * handleUnitSwapClose
     * handleSwapUnitChange
     *
     * setShowSelectUnit
     *
     * chargeItemsTotal
     * innerTask
     * leaseChargesTableRows
     * selectedLease
     * selectedUnitId
     * showSelectUnit
     * swapUnitsTableRows
     * task
     * unitOptions
     *
     */
    return (
      <ModalContainerModule
        size="sm"
        accept={
          props.innerTask === "Lease Units"
            ? props.saveEditUnit
            : props.innerTask === "Lease Units"
            ? props.saveLeaseCharges
            : null
        }
        cancel={
          props.innerTask === "Lease Units"
            ? props.handleLeaseChargeClose
            : props.innerTask === "Lease Units"
            ? props.handleUnitSwapClose
            : null
        }
        openModal={props.task === "Unit Swap"}
        modalTitle={` Add/Swap/Delete Lease Units On Lease ${
          props.selectedLease && props.selectedLease.billingCode
        } for ${props.selectedLease && props.selectedLease.tenant.name}`}
        acceptButtonText={
          props.innerTask === "Lease Units"
            ? "Save Unit Changes"
            : props.innerTask === "Lease Units"
            ? "Save Lease Charges"
            : null
        }
      >
        <Alert severity="warning">
          <AlertTitle>
            Note: This dialog let's you add, swap or delete units on an ACTIVE
            lease.
          </AlertTitle>
          You swap a unit by deleting one unit and adding another. Please note
          that for units that have a utility meter attached you can add an
          initial reading when adding the unit or add a last reading when
          deleting the unit. To add a unit click on the add button and select
          from the list of available units in the dropdown. Then add the unit's
          lease charges e.g service charge and click on 'Save Lease Charges'. To
          delete a unit click on the red button at the end of the row for the
          particular unit. Click on 'Save Unit Changes' when you are done to
          save the changes made on the lease units.
        </Alert>
        {props.innerTask === "Lease Units" && (
          <Paper
            variant=""
            sx={{
              width: "100%",
              height: "auto",
              margin: "0",
              padding: "10px",
            }}
          >
            <Typography
              sx={{ marginBottom: "0" }}
              id="modal-modal-title"
              variant="h5"
              component="h4"
            >
              Lease Units
            </Typography>
            <ResponsiveRow
              container
              sx={{
                flexDirection: "row",
                justifyContent: { xs: "center", lg: "flex-end" },
                flexWrap: { xs: "wrap", lg: "nowrap" },
              }}
            >
              <Grid item sx={{ width: { xs: "100%", lg: "200px" } }}>
                {props.showSelectUnit && (
                  <Select
                    sx={{ width: "90%" }}
                    id={"unit"}
                    value={props.selectedUnitId}
                    onChange={(event) => props.handleSwapUnitChange(event)}
                  >
                    {props.unitOptions && props.unitOptions.length > 0 ? (
                      props.unitOptions.map(function (unit, i) {
                        return (
                          <MenuItem key={i} value={unit.value}>
                            {unit.label}
                          </MenuItem>
                        );
                      }, this)
                    ) : (
                      <MenuItem sx={{ width: "100%" }}>
                        No Results Found
                      </MenuItem>
                    )}
                  </Select>
                )}

                <ModalAcceptButton
                  variant="contained"
                  type="submit"
                  onClick={() => props.setShowSelectUnit(true)}
                  sx={{
                    width: { xs: "100%", lg: "200px" },
                  }}
                >
                  Add
                </ModalAcceptButton>
              </Grid>
            </ResponsiveRow>
            <TableContainer>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ width: "15%" }}>Unit</TableCell>
                    <TableCell sx={{ width: "15%" }}>Tenant</TableCell>
                    <TableCell sx={{ width: "20%" }}>Periodic Charge</TableCell>
                    <TableCell sx={{ width: "20%" }}>Meter Name</TableCell>
                    <TableCell sx={{ width: "15%" }}>
                      Last Meter Reading
                    </TableCell>
                    <TableCell sx={{ width: "10%" }}>Delete Unit</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{props.swapUnitsTableRows}</TableBody>
              </Table>
            </TableContainer>
          </Paper>
        )}
        {props.innerTask === "Lease Charges" && (
          <Paper
            variant=""
            sx={{ height: "auto", margin: "auto", padding: "10px" }}
          >
            <Typography
              sx={{ marginBottom: "5px" }}
              id="modal-modal-title"
              variant="h5"
              component="h4"
            >
              Lease Charges
            </Typography>
            <ResponsiveRow
              container
              sx={{
                width: "100%",
                justifyContent: { xs: "center", lg: "flex-end" },
              }}
            >
              <Grid item xs={10}>
                <ModalAcceptButton
                  variant="contained"
                  type="submit"
                  onClick={() => props.handleLeaseChargeItemAdd()}
                  sx={{ width: { xs: "100%", lg: "200px" } }}
                >
                  Add Row
                </ModalAcceptButton>
              </Grid>
            </ResponsiveRow>
            <TableContainer>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ width: "15%" }}>Unit</TableCell>
                    <TableCell sx={{ width: "15%" }}>Charge Type</TableCell>
                    <TableCell sx={{ width: "20%" }}>Amount</TableCell>
                    <TableCell sx={{ width: "20%" }}>Start Date</TableCell>
                    <TableCell sx={{ width: "10%" }}>Taxed?|Note</TableCell>
                    <TableCell sx={{ width: "10%" }}>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{props.leaseChargesTableRows}</TableBody>
                <InnerModal container>
                  Total: {props.chargeItemsTotal}
                </InnerModal>
              </Table>
            </TableContainer>
          </Paper>
        )}
      </ModalContainerModule>
    );
}
