import {
  CLEAR_PROXY,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT,
  PROXY,
  PROXY_FAC,
  PROXY_ORG,
  PROXY_ROLE,
} from "../actions/type";
import {emptyUser} from "../services/auth.service";

const user = JSON.parse(localStorage.getItem("user"));

const initialState = user
    ? {isLoggedIn: true, user}
    : {isLoggedIn: false, user: null};

function authReducer(state = initialState, action) {
    const {type, payload} = action;
    switch (type) {
        case LOGIN_SUCCESS:
            return {
                ...state,
                isLoggedIn: true,
                user: payload.user,
                loginType: payload.loginType,
            };
        case PROXY:
            return {
                ...state,
                isLoggedIn: true,
                user: {
                    ...state.user,
                    services: payload.services,
                    roles: payload.roles,
                    permissions: payload.permissions,
                    proxyRole: payload.proxyRole,
                    proxyFacility: payload.proxyFacility,
                    proxySubscription: payload.proxySubscription,
                    isProxy: payload.isProxy,
                },
            };
        case CLEAR_PROXY:
            return {
                ...state,
                isLoggedIn: true,
                user: {
                    ...state.user,
                    proxyRole: "",
                    isProxy: false,
                    proxyFacility: "",
                    proxySubscription: "",
                    services: []
                },
            };
        case PROXY_ROLE:
            return {
                ...state,
                isLoggedIn: true,
                user: {
                    ...state.user,
                    proxyRole: payload,
                },
            };
        case PROXY_FAC:
            return {
                ...state,
                isLoggedIn: true,
                user: {
                    ...state.user,
                    proxyFacility: payload,
                },
            };
        case PROXY_ORG:
            return {
                ...state,
                isLoggedIn: true,
                user: {
                    ...state.user,
                    proxySubscription: payload,
                },
            };
        case LOGIN_FAIL:
            return {
                ...state,
                isLoggedIn: false,
                user: emptyUser,
            };
        case LOGOUT:
            return {
                ...state,
                isLoggedIn: false,
                user: emptyUser,
                loginType: null,
            };
        default:
            return state;
    }
}

export default authReducer;
