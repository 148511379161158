import ModalContainerModule from "../../modules/modalContainer";
import { Alert } from "@mui/material";

export default function DeletePropertyModal(props) {
  /**
   *
   * REQUIRED PROPS
   *
   * DeleteModal
   *
   * setDeleteModal
   *
   * confirmDelete
   * selectedAsset
   */

  return (
    <>
      <ModalContainerModule
        size="deleteProperty"
        accept={props.confirmDelete}
        cancel={() => props.setDeleteModal(false)}
        openModal={props.deleteModal}
        modalTitle={`Delete ${props.selectedProperty?.name ?? ""}?`}
        acceptButtonText="Delete Property"
      >
        <Alert severity="error" sx={{ width: "100%", height: "auto" }}>
          Are you sure you want to permanently delete this property along with
          any other associated data such as meters, titles, leases and
          documents?
        </Alert>
      </ModalContainerModule>
    </>
  );
}
