import {useEffect, useMemo, useState} from "react"
import Grid from "@mui/material/Grid"
import {GridActionsCellItem} from "@mui/x-data-grid"
import {Alert, AlertTitle, Box, Divider, InputLabel, Paper, TextField, Typography} from "@mui/material"
import {Add, LocalAtm, Visibility} from "@mui/icons-material"
import {
    getTerminatedOwnerships,
    getTotalTerminatedOwnerships,
    saveOwnershipNote,
    saveOwnershipPayment
} from "../services/ownership.service"
import {formatCurrency, formatDate, isNullUndefined, titleCase, tryParseFloat} from "../util/util"
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider"
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs"
import "dayjs/locale/en-gb"
import {useSelector} from "react-redux"
import Permit from "../util/permit"
import ReceivePayment from "../components/modals/receivePayment"
import TransactionHistory from "../components/transactionHistory"
import {DatePicker} from "@mui/x-date-pickers/DatePicker"
import {
    DetailsCardContainer,
    DetailsColumn,
    DetailsContainer,
    DetailsLabel,
    DetailsRow,
    DetailsText,
    MainContainer,
    ModalAcceptButton,
    ModalCancelButton,
    TopBarContainedBrandButton,
    TopBarContainer,
} from "../constants/component.constants"
import {printOwnership, printOwnershipStatement,} from "../services/print.service"
import {ModalContainer, ModalInnerContainer, ResponsiveRow,} from "../constants/layout.constants"
import {DocumentStore} from "../components/documentStore"
import NoteLogsCard from "../components/cards/noteLogsCard"
import {issueResponseMessage} from "../actions/message"
import MuiDataGrid from "../components/modules/MuiDataGrid"
import dayjs from "dayjs"

export default function TerminatedOwnership() {
    const [terminatedOwnerships, setTerminatedOwnerships] = useState([])
    const [searchReference, setSearchReference] = useState("")
    const [searchOwner, setSearchOwner] = useState("")
    const [searchUnit, setSearchUnit] = useState("")
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 5,
    })
    const [loading, setLoading] = useState(true)
    const [totalTerminatedOwnerships, setTotalTerminatedOwnerships] = useState(0)
    const [paymentModal, setPaymentModal] = useState(false)
    const handlePaymentClose = () => setPaymentModal(false)
    const [selectedOwnership, setSelectedOwnership] = useState(null)
    const [statementYear, setStatementYear] = useState(new Date())
    const [noteModal, setNoteModal] = useState(false)
    const [note, setNote] = useState("")
    const [task, setTask] = useState("")
    const appMessage = useSelector((state) => state.message)
    const user = useSelector((state) => state.auth.user)

    const handleNoteClose = () => {
        setNote("") //Clear note
        setNoteModal(false)
    }

    useEffect(() => {
        setLoading(true)
        getTotalTerminatedOwnerships(searchReference, searchOwner, searchUnit).then(
            (ownerships) =>
                setTotalTerminatedOwnerships(ownerships.totalTerminatedOwnerships)
        )
        getTerminatedOwnerships(
            searchReference,
            searchOwner,
            searchUnit,
            paginationModel.page,
            paginationModel.pageSize
        )
            .then((terminatedOwnerships) =>
                setTerminatedOwnerships(terminatedOwnerships)
            )
            .finally(() => {
                setLoading(false)
            })
    }, [
        searchReference,
        searchOwner,
        searchUnit,
        paginationModel,
    ])

    function onTextChange(event) {
        const id = event.target.id
        const value = event.target.value
        if (id === "searchRef") {
            setSearchReference(value)
        }
        if (id === "searchUnit") {
            setSearchUnit(value)
        }
        if (id === "searchOwner") {
            setSearchOwner(value)
        }
        if (id === "note") {
            setNote(value)
        }
    }

    function handlePaymentClick(selectedOwnership) {
        setSelectedOwnership(selectedOwnership)
        setPaymentModal(true)
    }

    function handleNoteClick(selectedOwnership) {
        setSelectedOwnership(selectedOwnership)
        setNoteModal(true)
    }

    function handleStatementYearChange(value) {
        let date = new Date(value)
        setStatementYear(date)
    }

    function handleViewClick(selectedOwnership) {
        setSelectedOwnership(selectedOwnership)
        setTask("View")
    }

    function savePayment(data) {
        saveOwnershipPayment(data).then((response) => {
            issueResponseMessage(response)
            if (!isNullUndefined(response.title)) {
                getTerminatedOwnerships(
                    searchReference,
                    searchOwner,
                    searchUnit,
                    paginationModel.page,
                    paginationModel.pageSize
                ).then((ownerships) => setTerminatedOwnerships(ownerships))
                handlePaymentClose()
            }
        })
    }

    function saveNote() {
        let data = {
            titleId: selectedOwnership.id,
            note: note,
        }
        saveOwnershipNote(data).then((response) => {
            issueResponseMessage(response)
            handleNoteClose()
        })
    }

    function handlePrintOwnershipStatement() {
        printOwnershipStatement(selectedOwnership.id, statementYear.getFullYear())
    }

    function handlePrintOwnership() {
        printOwnership(selectedOwnership.id)
    }

    const columns = useMemo(
        () => [
            {
                headerName: "Billing Code",
                field: "billingCode",
                minWidth: 200,
                flex: 1,
            },
            {headerName: "Unit", field: "unitName", minWidth: 250, flex: 1.25},
            {headerName: "Taxable", field: "taxableText", minWidth: 150, flex: 1.5},
            {
                headerName: "Periodic Charge",
                field: "periodicCharge",
                minWidth: 175,
                flex: 1.25,
            },
            {headerName: "Owner(s)/Tenant", field: "ownerNames", minWidth: 350, flex: 3.75},
            {headerName: "Balance", field: "titleBalance", minWidth: 150, flex: 0.75},
            {
                headerName: "Actions",
                field: "actions",
                width: 100,
                flex: 0.5,
                type: "actions",
                getActions: (params) => {
                    let arr = []
                    arr.push(
                        <GridActionsCellItem
                            icon={<Visibility/>}
                            onClick={() => handleViewClick(params.row)}
                            label="View"
                            showInMenu
                        />
                    )
                    arr.push(
                        <GridActionsCellItem
                            icon={<Add/>}
                            label="Add Note"
                            onClick={() => handleNoteClick(params.row)}
                            showInMenu
                        />
                    )

                    if (
                        user.proxyRole === "BLDIR" ||
                        user.proxyRole === "BLDMG" ||
                        user.proxyRole === "CS" ||
                        user.proxyRole === "ACC"
                    ) {
                        let balance = tryParseFloat(params.row.titleBalance, -1)
                        if (balance > 0) {
                            arr.push(
                                <GridActionsCellItem
                                    icon={<LocalAtm/>}
                                    label="Receive Payment"
                                    onClick={() => handlePaymentClick(params.row)}
                                    showInMenu
                                />
                            )
                        }
                    }
                    return arr
                },
            },
        ]
        //     [],
    )

    return (
        <Permit
            roles="BLDIR,BLDMG,REC,CS"
            services="HOA"
            noPermittedServiceMessage={{
                title: "Access Denied",
                body: (
                    <>
                        Your current role and/or facility is not permitted to view this
                        page. <br/> Please contact your system admin if you feel this is an
                        error.
                    </>
                ),
            }}
        >
            <MainContainer>
                {task === "" && (
                    <TopBarContainer container sx={{width: {xs: "100%", lg: "80%"}}}>
                        <Typography
                            variant="h5"
                            sx={{
                                fontWeight: 800,
                                margin: "auto 0",
                                width: "30%",
                                textAlign: "start",
                                padding: "20px 10px",
                            }}
                        >
                            Terminated Ownerships
                        </Typography>
                        <ResponsiveRow
                            item
                            sx={{
                                width: {xs: "100%", lg: "70%"},
                                flexWrap: {xs: "wrap", lg: "nowrap"},
                                justifyContent: {xs: "center", lg: "flex-start"},
                                padding: 0,
                            }}
                        >
                            <TextField
                                id="searchRef"
                                label="Search Billing Code"
                                sx={{
                                    width: "80%",
                                    margin: "5px",
                                }}
                                onChange={(event) => onTextChange(event)}
                            />

                            <TextField
                                id="searchUnit"
                                label="Search Unit"
                                sx={{
                                    width: "80%",
                                    margin: "5px",
                                }}
                                onChange={(event) => onTextChange(event)}
                            />

                            <TextField
                                id="searchOwner"
                                label="Search Owner"
                                sx={{
                                    width: "80%",
                                    margin: "5px",
                                }}
                                onChange={(event) => onTextChange(event)}
                            />
                        </ResponsiveRow>
                    </TopBarContainer>
                )}
                {task === "" && <br/>}

                {task === "View" && (
                    <>
                        <TopBarContainer
                            container
                            sx={{
                                width: {xs: "100%", lg: "80%"},
                                boxShadow: "4px 4px 12px #aaa",
                            }}
                        >
                            <ResponsiveRow
                                item
                                sx={{
                                    width: {xs: "100%", lg: "50%"},
                                    justifyContent: {xs: "center", lg: "flex-start"},
                                    padding: 0,
                                }}
                            >
                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                    adapterLocale="en-gb"
                                >
                                    <DatePicker
                                        sx={{width: "90%"}}
                                        id={"statementYear"}
                                        value={dayjs(statementYear)}
                                        disableFuture={true}
                                        disablePast={false}
                                        openTo="year"
                                        views={["year"]}
                                        label="Statement Year"
                                        isrequired={true}
                                        onChange={(newDate) => handleStatementYearChange(newDate)}
                                        slotProps={{
                                            textField: {
                                                helperText: 'Year to print statement',
                                            },
                                        }}
                                    />
                                </LocalizationProvider>
                            </ResponsiveRow>
                            <ResponsiveRow
                                item
                                sx={{
                                    width: {xs: "100%", lg: "50%"},
                                    justifyContent: {xs: "center", lg: "flex-end"},
                                    padding: 0,
                                }}
                            >
                                <TopBarContainedBrandButton
                                    onClick={handlePrintOwnershipStatement}
                                >
                                    {" "}
                                    Print Ownership Statement{" "}
                                </TopBarContainedBrandButton>

                                <TopBarContainedBrandButton onClick={handlePrintOwnership}>
                                    {" "}
                                    Print Ownership{" "}
                                </TopBarContainedBrandButton>
                            </ResponsiveRow>
                        </TopBarContainer>
                        <br/>

                        <ResponsiveRow sx={{width: "100%", justifyContent: "center"}}>
                            <DetailsCardContainer>
                                <Typography
                                    id="modal-modal-title"
                                    color="#037171"
                                    variant="h3"
                                    component="h2"
                                    textAlign={"start"}
                                    sx={{padding: "20px"}}
                                >
                                    Ownership Information for{" "}
                                    {selectedOwnership && selectedOwnership.ownerNames}
                                </Typography>
                                <DetailsContainer
                                    sx={{width: {xs: "100%", lg: "70vw"}}}
                                    container
                                >
                                    <DetailsColumn sx={{width: {xs: "100%", lg: "25%"}}}>
                                        <Typography
                                            sx={{marginBottom: "20px", color: "#037171"}}
                                            id="modal-modal-title"
                                            variant="h6"
                                            component="h4"
                                        >
                                            Ownership Information
                                        </Typography>

                                        <DetailsRow>
                                            <DetailsLabel> Billing Code: </DetailsLabel>
                                            <DetailsText>
                                                {" "}
                                                {selectedOwnership && selectedOwnership.billingCode}
                                            </DetailsText>
                                        </DetailsRow>
                                        <DetailsRow>
                                            <DetailsLabel> Start Date: </DetailsLabel>
                                            <DetailsText>
                                                {" "}
                                                {formatDate(
                                                    selectedOwnership && selectedOwnership.startDate
                                                )}
                                            </DetailsText>
                                        </DetailsRow>
                                        <DetailsRow>
                                            <DetailsLabel> Periodic Charge: </DetailsLabel>
                                            <DetailsText>
                                                {formatCurrency(
                                                    selectedOwnership && selectedOwnership.periodicCharge
                                                )}
                                            </DetailsText>
                                        </DetailsRow>
                                    </DetailsColumn>
                                    <DetailsColumn sx={{width: {xs: "100%", lg: "25%"}}}>
                                        <Typography
                                            sx={{marginBottom: "20px", color: "#037171"}}
                                            id="modal-modal-title"
                                            variant="h6"
                                            component="h4"
                                        >
                                            Property Information
                                        </Typography>

                                        <DetailsRow>
                                            <DetailsLabel> Property: </DetailsLabel>
                                            <DetailsText>
                                                {" "}
                                                {selectedOwnership && selectedOwnership.facilityName}
                                            </DetailsText>
                                        </DetailsRow>
                                        <DetailsRow>
                                            <DetailsLabel>Unit: </DetailsLabel>
                                            <DetailsText>
                                                {selectedOwnership && selectedOwnership.unitName}
                                            </DetailsText>
                                        </DetailsRow>
                                        <DetailsRow>
                                            <DetailsLabel> Tenant: </DetailsLabel>
                                            <DetailsText>
                                                {" "}
                                                {selectedOwnership && selectedOwnership.tenantName}
                                            </DetailsText>
                                        </DetailsRow>
                                    </DetailsColumn>

                                    <DetailsColumn sx={{width: {xs: "100%", lg: "25%"}}}>
                                        <Typography
                                            sx={{marginBottom: "20px", color: "#037171"}}
                                            id="modal-modal-title"
                                            variant="h6"
                                            component="h4"
                                        >
                                            Status Information
                                        </Typography>

                                        <DetailsRow>
                                            <DetailsLabel>Status: </DetailsLabel>
                                            <DetailsText>
                                                {selectedOwnership && selectedOwnership.status.name}
                                            </DetailsText>
                                        </DetailsRow>
                                        <DetailsRow>
                                            <DetailsLabel>Ownership Balance : </DetailsLabel>
                                            <DetailsText>
                                                {formatCurrency(
                                                    selectedOwnership && selectedOwnership.titleBalance
                                                )}
                                            </DetailsText>
                                        </DetailsRow>
                                    </DetailsColumn>
                                    <DetailsColumn sx={{width: {xs: "100%", lg: "25%"}}}>
                                        <Typography
                                            sx={{marginBottom: "20px", color: "#037171"}}
                                            id="modal-modal-title"
                                            variant="h6"
                                            component="h4"
                                        >
                                            Other Information
                                        </Typography>
                                        <DetailsRow>
                                            <DetailsLabel>Invoiced Home Owner: </DetailsLabel>
                                            <DetailsText>
                                                {" "}
                                                {selectedOwnership &&
                                                    selectedOwnership.invoicedOwner.name}
                                            </DetailsText>
                                        </DetailsRow>

                                        {selectedOwnership && (
                                            <DetailsRow>
                                                <DetailsLabel>Other Home Owner(s):</DetailsLabel>
                                                <ResponsiveRow
                                                    sx={{
                                                        flexDirection: "column",
                                                        flexWrap: "nowrap",
                                                        height: "50px",
                                                        overflowY: "auto",
                                                        borderRadius: "10px",
                                                    }}
                                                >
                                                    {selectedOwnership.titleOwners
                                                        .filter((o) => o.invoiced !== true)
                                                        .map((owner) => {
                                                            return (
                                                                <DetailsText
                                                                    sx={{
                                                                        fontSize: "14px",
                                                                        fontWeight: 600,
                                                                        marginBottom: "4px",
                                                                        textAlign: "center",
                                                                    }}
                                                                >
                                                                    {owner.name}
                                                                </DetailsText>
                                                            )
                                                        })}
                                                </ResponsiveRow>
                                            </DetailsRow>
                                        )}
                                    </DetailsColumn>
                                </DetailsContainer>
                            </DetailsCardContainer>

                            <ResponsiveRow
                                sx={{
                                    alignItems: "flex-start",
                                    justifyContent: "space-evenly",
                                    flexWrap: {xs: "wrap", lg: "nowrap"},
                                    margin: "auto",
                                }}
                                container
                            >
                                <NoteLogsCard
                                    notesMode={"Ownership"}
                                    contract={selectedOwnership}
                                />
                                <Grid
                                    item
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        maxWidth: {xs: "100%", lg: "420px"},
                                        height: {xs: "100%", lg: "500px"},
                                        backgroundColor: "#fff",
                                        borderRadius: "10px",
                                        margin: "20px auto",
                                        padding: 0,
                                        boxShadow: "0 4px 12px 0 rgba(0,0,0,0.25)",
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "flex-start",
                                            alignItems: "flex-start",
                                            flexWrap: "nowrap",
                                            width: "100%",
                                            padding: "20px",
                                        }}
                                    >
                                        <Typography
                                            sx={{marginBottom: "5px"}}
                                            id="modal-modal-title"
                                            variant="h5"
                                            color="#037171"
                                            component="h4"
                                        >
                                            Ownership Charges
                                        </Typography>
                                    </Box>

                                    <Box
                                        sx={{
                                            backgroundColor: "#EFEFEF",
                                            borderRadius: "10px",
                                            minHeight: "415px",
                                            width: "100%",
                                            padding: "0 10px",
                                            margin: "10px 0 0 0",
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        <DetailsContainer
                                            sx={{
                                                width: "100%",
                                                height: "70px",
                                                marginTop: 0,
                                                marginBottom: 0,
                                            }}
                                        >
                                            <DetailsColumn sx={{width: "25%"}}>
                                                <DetailsLabel
                                                    sx={{
                                                        fontSize: "12px",
                                                        textAlign: "start",
                                                    }}
                                                >
                                                    Charge Type
                                                </DetailsLabel>
                                            </DetailsColumn>
                                            <DetailsColumn sx={{width: "25%"}}>
                                                <DetailsLabel
                                                    sx={{
                                                        fontSize: "12px",
                                                        textAlign: "start",
                                                    }}
                                                >
                                                    Amount
                                                </DetailsLabel>
                                            </DetailsColumn>
                                            <DetailsColumn sx={{width: "25%"}}>
                                                <DetailsLabel
                                                    sx={{
                                                        fontSize: "12px",
                                                        textAlign: "start",
                                                    }}
                                                >
                                                    Billing Period
                                                </DetailsLabel>
                                            </DetailsColumn>
                                            <DetailsColumn sx={{width: "25%"}}>
                                                <DetailsLabel
                                                    sx={{
                                                        fontSize: "12px",
                                                        textAlign: "start",
                                                    }}
                                                >
                                                    Invoice Day/Date
                                                </DetailsLabel>
                                            </DetailsColumn>
                                        </DetailsContainer>

                                        <ResponsiveRow
                                            container
                                            sx={{
                                                flexDirection: "column",
                                                width: "100%",
                                                marginTop: 0,
                                                padding: 0,
                                                alignItems: "flex-start",
                                            }}
                                        >
                                            {selectedOwnership &&
                                                selectedOwnership.titleChargeItems.length > 0 &&
                                                selectedOwnership.titleChargeItems.map(function (
                                                        chargeItem,
                                                        i
                                                    ) {
                                                        let invoiceDateOrDay
                                                        if (!isNullUndefined(chargeItem.invoiceDate)) {
                                                            invoiceDateOrDay = formatDate(
                                                                chargeItem.invoiceDate
                                                            )
                                                        }
                                                        if (!isNullUndefined(chargeItem.invoiceDay)) {
                                                            invoiceDateOrDay = chargeItem.invoiceDay
                                                        }
                                                        return (
                                                            <DetailsContainer
                                                                sx={{
                                                                    width: "100%",
                                                                    height: "65px",
                                                                    backgroundColor: "#ffffff",
                                                                    borderRadius: "10px",
                                                                    alignItems: "center",
                                                                    justifyContent: "space-evenly",
                                                                    marginTop: "10px",
                                                                }}
                                                            >
                                                                <DetailsColumn sx={{width: "30%", padding: 0}}>
                                                                    <DetailsText
                                                                        sx={{
                                                                            fontSize: "12px",
                                                                            width: "100%",
                                                                            fontWeight: 600,
                                                                            color: "#037171",
                                                                        }}
                                                                    >
                                                                        {chargeItem.charge.name}
                                                                    </DetailsText>
                                                                </DetailsColumn>
                                                                <DetailsColumn sx={{width: "20%"}}>
                                                                    <DetailsText sx={{fontSize: "12px"}}>
                                                                        {" "}
                                                                        {chargeItem.amount}
                                                                    </DetailsText>
                                                                </DetailsColumn>
                                                                <DetailsColumn sx={{width: "25%"}}>
                                                                    <DetailsText sx={{fontSize: "12px"}}>
                                                                        {" "}
                                                                        {chargeItem.billingFrequency}
                                                                    </DetailsText>
                                                                </DetailsColumn>
                                                                <DetailsColumn sx={{width: "25%"}}>
                                                                    <DetailsText sx={{fontSize: "12px"}}>
                                                                        {" "}
                                                                        {invoiceDateOrDay}
                                                                    </DetailsText>
                                                                </DetailsColumn>
                                                            </DetailsContainer>
                                                        )
                                                    },
                                                    this)}
                                        </ResponsiveRow>
                                    </Box>
                                </Grid>
                                <DocumentStore
                                    files={[
                                        ...selectedOwnership.imageFiles,
                                        ...selectedOwnership.otherDocuments,
                                    ]}
                                    item={selectedOwnership}
                                    type={"Ownership"}
                                    ehight={"500px"}
                                    // closeView={}
                                    // uploadFile={uploadFile}
                                    // deleteFile={deleteFile}
                                />
                            </ResponsiveRow>
                            <br/>

                            <ResponsiveRow sx={{width: "100%"}}>
                                {/* Shared Transaction History */}
                                <TransactionHistory
                                    transactionMode={"Ownership"}
                                    contract={selectedOwnership}
                                />
                                <br/>
                            </ResponsiveRow>

                            {/*FOOTER WITH BUTTONS*/}
                            <ResponsiveRow
                                sx={{justifyContent: {xs: "center", lg: "flex-end"}}}
                            >
                                <ModalCancelButton
                                    //   type="submit"
                                    sx={{width: {xs: "100%", lg: "200px"}}}
                                    onClick={() => setTask("")}
                                >
                                    {" "}
                                    Close{" "}
                                </ModalCancelButton>
                            </ResponsiveRow>
                        </ResponsiveRow>
                        <br/>
                    </>
                )}

                {task === "" && (
                    // <StyledDataGrid
                    //   rows={terminatedOwnerships}
                    //   columns={columns}
                    //   pageSize={rowsPerPage}
                    //   rowsPerPageOptions={[rowsPerPage]}
                    //   loading={loading}
                    //   pagination
                    //   rowCount={totalTerminatedOwnerships}
                    //   onPageChange={(page) => loadPage(page)}
                    //   paginationMode="server"
                    //   disableSelectionOnClick
                    //   autoHeight
                    //   components={{
                    //     LoadingOverlay: LinearProgress,
                    //     NoRowsOverlay: EmptyOverlay,
                    //   }}
                    // />

                    <MuiDataGrid
                        dataGridColumns={columns}
                        dataGridRows={terminatedOwnerships}
                        serverPagination={true}
                        handlePageLoad={setPaginationModel}
                        loading={loading}
                        height="60vh"
                        totalRows={totalTerminatedOwnerships}
                    />
                )}

                {/*RECEIVE PAYMENT MODAL*/}
                <ReceivePayment
                    showPaymentModal={paymentModal}
                    handlePaymentClose={handlePaymentClose}
                    paymentMode={"Ownership"}
                    contract={selectedOwnership}
                    savePayment={savePayment}
                />

                {/*ADD NOTE MODAL*/}
                <ModalContainer
                    open={noteModal}
                    onClose={handleNoteClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <ModalInnerContainer sx={{maxHeight: {xs: "50vh", lg: "70vh"}}}>
                        <Typography id="modal-modal-title" variant="h3" component="h2">
                            Add Note To Ownership{" "}
                            {selectedOwnership && selectedOwnership.billingCode} for{" "}
                            {selectedOwnership && selectedOwnership.ownerName}
                        </Typography>
                        <Divider/>
                        <br/>
                        {appMessage.display && (
                            <Alert severity={appMessage.type}>
                                <AlertTitle>{titleCase(appMessage.type)}</AlertTitle>
                                {appMessage.message}
                            </Alert>
                        )}
                        <Paper
                            variant=""
                            sx={{
                                height: "auto",
                                margin: "auto",
                                padding: "10px",
                                paddingBottom: 0,
                            }}
                        >
                            <Alert severity="info">
                                Add note to ownership will log important notes/information
                                pertaining this ownership. All the notes added on this ownership
                                will be displayed chronologically, from newest first to oldest.
                                Notes will be limited to 500 words.
                            </Alert>
                            <br/>
                            <ResponsiveRow container sx={{paddingBottom: 0}}>
                                <Grid item sx={{width: {xs: "100%", lg: "90%"}}}>
                                    <InputLabel>Note (500)</InputLabel>
                                    <TextField
                                        sx={{width: "100%"}}
                                        id={"note"}
                                        name={"note"}
                                        multiline
                                        rows={5}
                                        isrequired
                                        value={note}
                                        onChange={(event) => onTextChange(event)}
                                    />
                                </Grid>
                            </ResponsiveRow>
                            <br/>
                        </Paper>
                        <br/>

                        {/*FOOTER WITH BUTTONS*/}
                        <ResponsiveRow
                            sx={{
                                justifyContent: {xs: "center", lg: "flex-end"},
                                paddingTop: 0,
                            }}
                        >
                            <ModalAcceptButton
                                // type="submit"
                                onClick={saveNote}
                                sx={{width: {xs: "100%", lg: "200px"}}}
                            >
                                {" "}
                                Save Note{" "}
                            </ModalAcceptButton>
                            <ModalCancelButton
                                // type="submit"
                                onClick={handleNoteClose}
                                sx={{width: {xs: "100%", lg: "200px"}}}
                            >
                                {" "}
                                Cancel{" "}
                            </ModalCancelButton>
                        </ResponsiveRow>
                    </ModalInnerContainer>
                </ModalContainer>
            </MainContainer>
        </Permit>
    )
}
