/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import {
  Checkbox,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import {
  isEmptyString,
  isNullUndefined,
  tryParseFloat,
  tryParseInt,
} from "../../util/util";
import {
  getAllUnitOptions,
  getVendorOptions,
} from "../../services/list.service";
import { setMessage } from "../../actions/message";
import Delete from "@mui/icons-material/Delete";
import { addWorkOrderVendorData } from "../../services/workOrder.service";
import { ResponsiveRow } from "../../constants/layout.constants";
import ModalContainerModule from "../modules/modalContainer";
import { ModalAcceptButton } from "../../constants/component.constants";
import { useDispatch } from "react-redux";

/**
 * This is a shared Work order add Vendor together with charges component
 *
 * Required props are:
 * - showAddVendorModal: This is either true or false to show or hide modal respectively
 * - selectedWorkOrder: This is the selectedWorkOrder
 * - handleAddVendorClose: This is a function to close the modal ie set showAddVendorModal to false
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 * @author jmbuthia
 */
export default function AddWorkOrderVendor(props) {
  const [unitOptions, setUnitOptions] = useState([]);
  const [vendorOptions, setVendorOptions] = useState([]);
  const [vendorId, setVendorId] = useState("");
  const [charges, setCharges] = useState([
    {
      id: null,
      unitId: "",
      description: "",
      unitPrice: "",
      quantity: "",
      applyVAT: false,
      subTotal: "",
      otherCharges: "",
      total: "",
      status: "PEN",
    },
  ]);
  const [chargesTableRows, setChargesTableRows] = useState("");
  const dispatch = useDispatch();
  useEffect(() => {
    if (!isNullUndefined(props.selectedWorkOrder.facilityId)) {
      getAllUnitOptions(props.selectedWorkOrder.facilityId)
        .then((unitOptions) => {
          setUnitOptions(unitOptions);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }

    getVendorOptions()
      .then((vendorOptions) => {
        setVendorOptions(vendorOptions);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, [props.selectedWorkOrder]);

  useEffect(() => {
    populateChargeItems();
  }, [charges]);

  useEffect(() => {
    populateChargeItems();
  }, [unitOptions]);

  function onTextChange(event, index) {
    const id = event.target.id;
    let value = event.target.value;
    if (id === "unitPrice" + index) {
      let newArr = [...charges]; // copying the old items array
      value = tryParseFloat(event.target.value, 0);
      newArr[index].unitPrice = value;
      setCharges(newArr);
      updateTotal(index);
    }

    if (id === "quantity" + index) {
      let newArr = [...charges]; // copying the old items array
      value = tryParseInt(event.target.value, 0);
      newArr[index].quantity = value;
      setCharges(newArr);
      updateTotal(index);
    }

    if (id === "otherCharges" + index) {
      let newArr = [...charges]; // copying the old items array
      value = tryParseFloat(event.target.value, 0);
      newArr[index].otherCharges = value;
      setCharges(newArr);
      updateTotal(index);
    }

    if (id === "description" + index) {
      let newArr = [...charges]; // copying the old items array
      if (newArr[index].description.length >= 300) {
        dispatch(
          setMessage({
            type: "warning",
            message: "Memo should be less than 300 characters",
          })
        );
      }
      newArr[index].description = value;
      setCharges(newArr);
      populateChargeItems();
    }
  }

  function updateTotal(index) {
    let newArr = [...charges]; // copying the old items array
    let total =
      tryParseFloat(newArr[index].unitPrice, 0) *
        tryParseInt(newArr[index].quantity, 0) *
        (newArr[index].applyVAT ? 0.16 : 0) +
      tryParseFloat(newArr[index].otherCharges, 0) +
      tryParseFloat(newArr[index].unitPrice, 0) *
        tryParseInt(newArr[index].quantity, 0);
    let subTotal =
      tryParseFloat(newArr[index].unitPrice, 0) *
      tryParseInt(newArr[index].quantity, 0);
    newArr[index].subTotal = subTotal;
    newArr[index].total = total;
    setCharges(newArr);
    populateChargeItems();
  }

  function handleChargeTaxableToggle(event, index) {
    let value = event.target.checked;
    let newArr = [...charges]; // copying the old items array
    newArr[index].applyVAT = value;
    setCharges(newArr);
    updateTotal(index);
  }

  function handleVendorChange(event) {
    let value = event.target.value;
    setVendorId(value);
  }

  function addCharge() {
    let data = {
      id: null,
      unitId: null,
      description: "",
      unitPrice: "",
      quantity: "",
      applyVAT: false,
      subTotal: 0,
      otherCharges: 0,
      total: 0,
      status: "PEN",
    };
    let tmp = charges;
    tmp.push(data);
    setCharges(tmp);
    populateChargeItems();
  }

  function removeCharge(chargeIndex) {
    let tmp = charges;
    tmp.splice(chargeIndex, 1);
    setCharges(tmp);
    populateChargeItems();
  }

  function handleChargeUnitChange(event, index) {
    let value = event.target.value;
    let newArr = [...charges]; // copying the old items array
    newArr[index].unitId = value;
    setCharges(newArr);
    populateChargeItems();
  }

  function populateChargeItems() {
    let rows = "";
    charges &&
      charges.length > 0 &&
      (rows = charges.map(function (charge, i) {
        return (
          <TableRow key={i}>
            {props.selectedWorkOrder &&
              props.selectedWorkOrder.facilityId &&
              unitOptions &&
              unitOptions.length > 0 && (
                <TableCell>
                  <Select
                    sx={{ width: "90%" }}
                    id={"unit"}
                    value={charge.unitId}
                    onChange={(event) => handleChargeUnitChange(event, i)}
                  >
                    {unitOptions && unitOptions.length > 0 ? (
                      unitOptions.map(function (unit, i) {
                        return (
                          <MenuItem key={unit.value} value={unit.value}>
                            {unit.label}
                          </MenuItem>
                        );
                      }, this)
                    ) : (
                      <MenuItem sx={{ width: "100%" }}>
                        No Results Found
                      </MenuItem>
                    )}
                  </Select>
                </TableCell>
              )}
            <TableCell>
              <TextField
                sx={{ width: "90%" }}
                id={"description" + i}
                value={charge.description}
                onChange={(event) => onTextChange(event, i)}
              />
            </TableCell>
            <TableCell>
              <TextField
                sx={{ width: "90%" }}
                id={"unitPrice" + i}
                value={charge.unitPrice}
                onChange={(event) => onTextChange(event, i)}
              />
            </TableCell>
            <TableCell>
              <TextField
                sx={{ width: "90%" }}
                id={"quantity" + i}
                value={charge.quantity}
                onChange={(event) => onTextChange(event, i)}
              />
            </TableCell>
            <TableCell>
              <TextField
                sx={{ width: "90%" }}
                id={"subTotal"}
                value={charge.subTotal}
                disabled
                onChange={(event) => onTextChange(event, i)}
              />
            </TableCell>
            <TableCell>
              <TextField
                sx={{ width: "90%" }}
                id={"otherCharges" + i}
                value={charge.otherCharges}
                onChange={(event) => onTextChange(event, i)}
              />
            </TableCell>
            <TableCell>
              <Checkbox
                checked={charge.applyVAT}
                disabled={
                  isNullUndefined(charge.total) ||
                  isEmptyString(charge.total) ||
                  charge.total === 0
                }
                onChange={(event) => handleChargeTaxableToggle(event, i)}
                inputProps={{ "aria-label": "controlled" }}
              />
            </TableCell>
            <TableCell>
              <TextField
                sx={{ width: "90%" }}
                id={"total"}
                value={charge.total}
                disabled
                onChange={(event) => onTextChange(event, i)}
              />
            </TableCell>

            <TableCell>
              <IconButton
                onClick={() => removeCharge(i)}
                sx={{
                  marginLeft: "-25px",
                  color: "red",
                  fontSize: "5rem",
                  textAlign: "center",
                  fontWeight: 800,
                }}
              >
                <Delete />
              </IconButton>
            </TableCell>
          </TableRow>
        );
      }, this));
    setChargesTableRows(rows);
  }

  function saveDetailItem() {
    //update the total due
    let detailItem = {
      vendorId: vendorId,
      workOrderId: props.selectedWorkOrder.id,
      charges: charges,
    };
    if (
      isNullUndefined(detailItem.vendorId) ||
      isEmptyString(detailItem.vendorId)
    ) {
      dispatch(
        setMessage({
          type: "warning",
          message: "Vendor must be selected",
        })
      );
      return;
    }

    for (let i = 0; i < detailItem.charges.length; i++) {
      let row = i + 1;
      if (
        isNullUndefined(detailItem.charges[i].description) ||
        isEmptyString(detailItem.charges[i].description)
      ) {
        dispatch(
          setMessage({
            type: "warning",
            message: "A Memo must be provided on row " + row,
          })
        );
        return;
      }
      if (detailItem.charges[i].description.length > 300) {
        dispatch(
          setMessage({
            type: "warning",
            message: "Memo cannot be more than 300 characters on row " + row,
          })
        );
        return;
      }
      if (
        (!isNullUndefined(props.selectedWorkOrder.facilityId) ||
          !isEmptyString(props.selectedWorkOrder.facilityId)) &&
        (isNullUndefined(detailItem.charges[i].unitId) ||
          isEmptyString(detailItem.charges[i].unitId))
      ) {
        dispatch(
          setMessage({
            type: "warning",
            message: "Unit must be provided on row " + row,
          })
        );
        return;
      }

      if (detailItem.charges[i].unitPrice < 1) {
        dispatch(
          setMessage({
            type: "warning",
            message: "Unit price on row " + row + " must be greater than 0",
          })
        );
        return;
      }

      if (detailItem.charges[i].quantity < 1) {
        dispatch(
          setMessage({
            type: "warning",
            message: "Quantity on row " + row + " must be greater than 0",
          })
        );
        return;
      }

      if (detailItem.charges[i].otherCharges < 0) {
        dispatch(
          setMessage({
            type: "warning",
            message: "OtherCharges on row " + row + " must be 0 or greater",
          })
        );
        return;
      }
    }

    console.log("detailItem: ", detailItem);

    // All fields valid
    addWorkOrderVendorData(detailItem)
      .then((response) => {
        props.refreshDetails();
        dispatch(
          setMessage({
            type: response.status,
            message: response.message,
          })
        );
        props.handleAddVendorClose();
      })
      .catch((error) => {
        console.log("addWorkOrderVendorData: ", error);
      });
  }

  return (
    <ModalContainerModule
      size="specialWordOrderAddVendor"
      accept={saveDetailItem}
      cancel={props.handleAddVendorClose}
      openModal={props.showAddVendorModal}
      modalTitle={`Vendor Work Order Details for work Order ${props.selectedWorkOrder.refNumber}`}
      acceptButtonText="Save"
    >
      <ResponsiveRow container sx={{ height: "auto" }}>
        <FormControl sx={{ width: "90%" }}>
          <InputLabel>Vendor</InputLabel>
          <Select
            sx={{ width: "90%" }}
            id={"vendorId"}
            label="Vendor"
            value={vendorId}
            onChange={(event) => handleVendorChange(event)}
          >
            {vendorOptions && vendorOptions.length > 0 ? (
              vendorOptions.map(function (vendor, i) {
                return (
                  <MenuItem key={vendor.value} value={vendor.value}>
                    {vendor.label}
                  </MenuItem>
                );
              }, this)
            ) : (
              <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
            )}
          </Select>
        </FormControl>

        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {props.selectedWorkOrder &&
                  props.selectedWorkOrder.facilityId &&
                  unitOptions.length > 0 && (
                    <TableCell sx={{ width: "15%" }}>Unit</TableCell>
                  )}
                <TableCell sx={{ width: "15%" }}>Memo</TableCell>
                <TableCell sx={{ width: "15%" }}>Unit Price</TableCell>
                <TableCell sx={{ width: "10%" }}>Quantity</TableCell>
                <TableCell sx={{ width: "10%" }}>Sub-Total</TableCell>
                <TableCell sx={{ width: "15%" }}>Additional Charges</TableCell>
                <TableCell sx={{ width: "5%" }}>Tax (16% VAT)</TableCell>
                <TableCell sx={{ width: "15%" }}>Total</TableCell>
                <TableCell sx={{ width: "2%" }}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{chargesTableRows}</TableBody>
          </Table>
        </TableContainer>
        <ResponsiveRow
          sx={{
            justifyContent: { xs: "center", lg: "flex-end" },
            width: "100%",
          }}
        >
          <ModalAcceptButton
            variant="contained"
            type="submit"
            onClick={addCharge}
            sx={{
              backgroundColor: "#037171",
              width: { xs: "100%", lg: "200px" },
            }}
          >
            Add Row
          </ModalAcceptButton>
        </ResponsiveRow>
      </ResponsiveRow>
    </ModalContainerModule>
  );
}
