import {CLEAR_MESSAGE, SET_MESSAGE} from "../actions/type"

const initialState = {type: '', message: '', display: false}

export default function (state = initialState, action) {
    const {type, payload} = action

    switch (type) {
        case SET_MESSAGE:
            return {
                ...state,
                type: payload.type,
                message: payload.message,
                display: true
            }

        case CLEAR_MESSAGE:
            return {
                ...state,
                type: '',
                message: '',
                display: false
            }

        default:
            return state
    }
}
