import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { isEmptyString } from '../util/util';
import { issueWarnMessage } from '../actions/message';
import Grid from '@mui/material/Grid';
import {
	Box,
	Button,
	FormControl,
	InputLabel,
	OutlinedInput,
	Paper,
	Typography,
} from '@mui/material';
import loginImage from '../images/login-image.png';
import { LoadingButton } from '@mui/lab';

const DeleteProfile = () => {
	const [userEmail, setEmail] = useState('');
	const [loading, setLoading] = useState(false);

	const navigate = useNavigate();

	const onChange = (e) => {
		const userEmail = e.target.value;
		setEmail(userEmail);
	};

	const backHome = () => {
		navigate('/');
	};

	const onSubmit = (e) => {
		e.preventDefault();
		if (isEmptyString(userEmail)) {
			issueWarnMessage('Please input a username or email address');
			return false;
		}
		backHome();
	};

	return (
		<Grid
			sx={{
				height: '100vh',
				width: '100wv',
				margin: '0',
			}}
			container
		>
			<Grid
				item
				xs={false}
				sm={false}
				md={5}
				lg={8}
				sx={{
					backgroundImage: `url(${loginImage})`,
					backgroundSize: 'cover',
					backgroundRepeat: 'no-repeat',
					width: '100%',
				}}
			/>
			<Grid item sm={12} md={7} lg={4}>
				<Paper
					sx={{
						height: '100vh',
						width: '100%',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'space-between',
						justifyContent: 'center',
						margin: 'auto',
					}}
				>
					<Box sx={{ width: '80%', margin: '10px auto 10px auto' }}>
						<img
							src={require('../images/kodi-logo.png')}
							alt=""
							width={'30%'}
						/>
					</Box>

					<Box sx={{ width: '80%', margin: '15px auto' }}>
						<Typography
							variant="h2"
							color="primary.dark"
							sx={{ fontWeight: 700 }}
						>
							Delete Profile
						</Typography>
					</Box>

					<Box sx={{ width: '80%', margin: '10px auto' }}>
						<Typography variant="body2">
							To delete your profile, please enter your email below and click
							the 'Delete Profile' button. If you have an active account, you
							shall receive a secure link in your email to select the
							information you wish to delete.
						</Typography>
					</Box>

					<Box sx={{ width: '80%', margin: '20px auto' }}>
						<form onSubmit={onSubmit}>
							<FormControl sx={{ width: '80%' }}>
								<InputLabel htmlFor="userEmail">
									<Typography
										variant="body2"
										sx={{ fontWeight: 'medium' }}
										color="primary.light"
									>
										Email
									</Typography>
								</InputLabel>
								<OutlinedInput
									id="userEmail"
									type="email"
									label="Confirm Email"
									required
									aria-describedby="email-helper-text"
									color="secondary"
									onChange={onChange}
									value={userEmail}
								/>
							</FormControl>

							<LoadingButton
								variant="contained"
								type="submit"
								loading={loading}
								onClick={onSubmit}
								sx={{
									width: '80%',
									backgroundColor: '#037171',
									margin: '10px auto',
								}}
							>
								Delete Profile
							</LoadingButton>
						</form>
					</Box>
					<Box sx={{ width: '80%', margin: '20px auto' }}>
						<Typography
							variant="body2"
							color="primary.dark"
							textAlign={'center'}
							sx={{
								fontWeight: 'bold',
								border: 0,
								width: 'auto',
								padding: '10px auto',
								backgroundColor: 'transparent',
							}}
							onClick={backHome}
							// onFocus={"bold"}
							component={Button}
						>
							back to login
						</Typography>
					</Box>
				</Paper>
			</Grid>
		</Grid>
	);
};

export default DeleteProfile;
