import {Typography} from "@mui/material";
import {ResponsiveRow} from "../../../constants/layout.constants";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {getVacateNotices} from "../../../services/dashboard.service";
import Button from "@mui/material/Button";
import {
    getVacateNotice,
} from "../../../services/shared.service";
import {formatDate, isNullUndefined, sortJSONArray,} from "../../../util/util";
import {renderCellExpand} from "../../../util/renderCellExpand";
import MuiDataGrid from "../MuiDataGrid";
import {Visibility,} from "@mui/icons-material";
import ViewVacateNotice from "../../modals/dashboard/buildingDirector/viewVacateNotice";

export default function VacateNotices({customHeight, customWidth}) {
    const {user} = useSelector((state) => state.auth);
    const [vacateNotices, setVacateNotices] = useState([])
    const [loading, setLoading] = useState(true);
    const [vacateNoticeModal, setVacateNoticeModal] = useState(false);
    const [vacateNoticeItem, setVacateNoticeItem] = useState(null);

    useEffect(() => {
        setLoading(true);
        getVacateNotices()
            .then((response) => {
                if (response && response.data) {
                    setVacateNotices(response.data.sort(sortJSONArray("vacateOn", true)));
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [user.proxyFacility]);

    const handleViewClick = (vacantNoticeItem) => {
        getVacateNotice(vacantNoticeItem.id).then((data) => {
            setVacateNoticeItem(data);
            setVacateNoticeModal(true);
        });
    };

    const handleVacateNoticeCancel = () => {
        setVacateNoticeModal(false);
        setVacateNoticeItem(null);
    };

    const columns = [
        {
            headerName: "Lease",
            field: "leaseBillingCode",
            width: 150,
            renderCell: renderCellExpand,
        },
        {
            headerName: "Vacate On",
            field: "vacateOn",
            minWidth: 150,
            flex: 1,
            valueFormatter: (row) => {
                return formatDate(row.value);
            },
        },
        {
            headerName: "Days Left",
            field: "daysToVacate",
            minWidth: 150,
            flex: 1,
            valueFormatter: (row) => {
                let days = row.value
                if (isNullUndefined(row.value)) {
                    return "∞"
                }
                if (days < 0) {
                    return "Vacated"
                }
                if (days >= 365) {
                    return "1+ year"
                }
                if (days >= 180) {
                    return "6+ months"
                }
                if (days >= 90) {
                    return "3+ months"
                }
                if (days >= 30) {
                    return "1+ month"
                }
                return days.toFixed(0) < 2 ? days.toFixed(0) + " day" : days.toFixed(0) + " days"
            },
        },
        {
            headerName: "Reason",
            field: "reason",
            minWidth: 330,
            flex: 2.2,
            valueFormatter: (row) => {
                return row.value.replace(/[()]/g, "");
            },
            renderCell: renderCellExpand,
        },
        {headerName: "Issued By", field: "issuedByName", minWidth: 150, flex: 1},
        {
            headerName: "Action",
            field: "",
            minWidth: 170,
            flex: 1.14,
            renderCell: (params) => {
                return (
                    <Button
                        variant="outlined"
                        size="medium"
                        onClick={() => {
                            handleViewClick(params.row);
                        }}
                    >
                        <Visibility sx={{ margin: "auto 5px" }} />
                        View
                    </Button>
                );
            },
        },
    ];

    return (
        <ResponsiveRow
            sx={{
                flexDirection: "column",
                flexWrap: "nowrap",
                alignItems: "flex-start",
                padding: "0",
                margin: "10px",
                height: {xs: "100%", lg: customHeight},
                minWidth: {xs: "100%", lg: customWidth},
                width: "100%",
                backgroundColor: "#fff",
                borderRadius: "20px",
            }}
        >
            <Typography
                sx={{
                    width: "100%",
                    padding: "20px 20px 0 20px",
                    textAlign: "start",
                }}
                variant="h5"
            >
                Vacate Notices
            </Typography>
            <ResponsiveRow
                sx={{width: "100%", boxShadow: 0, padding: "10px", margin: 0}}
            >
                <MuiDataGrid
                    alt={true}
                    noSelection={true}
                    dataGridColumns={columns}
                    dataGridRows={vacateNotices}
                    serverPagination={false}
                    loading={loading}
                    height="90%"
                    totalRows={vacateNotices.length}
                    getRowClassName={(params) => {
                        return params.row.daysToVacate < 3
                            ? "suspended"
                            : "";
                    }}
                />
            </ResponsiveRow>

            {!isNullUndefined(vacateNoticeItem) && vacateNoticeModal &&
                <ViewVacateNotice
                openModal={vacateNoticeModal}
                handleClose={handleVacateNoticeCancel}
                selectedVacateNotice={vacateNoticeItem}
            />}
        </ResponsiveRow>
    );
}
