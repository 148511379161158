import {CLEAR_MESSAGE, SET_MESSAGE} from "./type";
import {store} from "../store";
import {isNullUndefined} from "../util/util";

export const setMessage = (message) => ({
    type: SET_MESSAGE,
    payload: message,
});

export const clearMessage = () => ({
    type: CLEAR_MESSAGE,
});

/**
 * Global function to display error messages from an axios promise
 * @param error
 */
export const handleErrorResponse = (error) => {
    if (!isNullUndefined(error)) {
        // console.log("handleError is ", error)
        if (typeof error.data === "string" || error.data instanceof String) {
            store.dispatch(setMessage({type: "error", message: error.data}));
        } else {
            if (!isNullUndefined(error.data)) {
                store.dispatch(
                    setMessage({
                        type: error.data.type,
                        message: error.data.message,
                    })
                );
            } else if (!isNullUndefined(error.message)) {
                store.dispatch(
                    setMessage({
                        type: "error",
                        message: error.message,
                    })
                );
            }
        }
    }
    //scroll smoothly to the top of the page
    window.scrollTo({
        left: 0,
        top: 0,
        behavior: "instant",
    });
};

export const handleInfoResponse = (response) => {
    store.dispatch(
        setMessage({
            type: response.type,
            message: response.message,
        })
    );
    //scroll smoothly to the top of the page
    window.scrollTo({
        left: 0,
        top: 0,
        behavior: "smooth",
    });
};

//Some backend function pass response.type and others do send response.status.
export const issueResponseMessage = (response) => {
    if (!isNullUndefined(response)) {
        const messageType = response.type || response.status || "info"; // Default type to 'info' if neither type nor status is present
        store.dispatch(
            setMessage({
                type: messageType,
                message: response.message || "An unknown error occurred", // Provide a default message
            })
        );

        // Scroll instantly to the top of the page
        window.scrollTo({
            left: 0,
            top: 0,
            behavior: "smooth",
        });
    }
};

export const issueErrorMessage = (message) => {
    store.dispatch(
        setMessage({
            type: "error",
            message: message,
        })
    );
    //scroll instantly to the top of the page
    window.scrollTo({
        left: 0,
        top: 0,
        behavior: "smooth",
    });
};

export const issueWarnMessage = (message) => {
    store.dispatch(
        setMessage({
            type: "warning",
            message: message,
        })
    );
    //scroll instantly to the top of the page
    window.scrollTo({
        left: 0,
        top: 0,
        behavior: "smooth",
    });
};

export const issueSuccessMessage = (message) => {
    store.dispatch(
        setMessage({
            type: "success",
            message: message,
        })
    );
    //scroll instantly to the top of the page
    window.scrollTo({
        left: 0,
        top: 0,
        behavior: "smooth",
    });
};

export const issueInfoMessage = (message) => {
    store.dispatch(
        setMessage({
            type: "info",
            message: message,
        })
    );
    //scroll instantly to the top of the page
    window.scrollTo({
        left: 0,
        top: 0,
        behavior: "smooth",
    });
};
