import {
    ADD_OWNERSHIP_CONTACT_URL,
    BULK_EDIT_OWNERSHIPS_URL,
    DELETE_TITLE_DOC_URL,
    DOWNLOAD_OWNERSHIPS_URL,
    EXPORT_OWNERSHIPS_URL,
    GET_OWNERSHIP_CONTACTS_URL,
    LIST_OWNERSHIPS_URL,
    LIST_TERMINATED_OWNERSHIPS_URL,
    OUTSTANDING_TITLE_INVOICES_CHARGES_URL,
    OWNERSHIP_CHARGE_CODES_URL,
    OWNERSHIP_FINANCIALS_URL,
    OWNERSHIP_NOTES_URL,
    OWNERSHIP_PAYMENT_METHOD_URL,
    OWNERSHIP_TERMINATION_ACCOUNT_URL,
    OWNERSHIP_TERMINATION_URL,
    REMOVE_OWNERSHIP_CONTACT_URL,
    RESEND_LAST_OWNERSHIP_INVOICE_URL,
    RESEND_LAST_OWNERSHIP_RECEIPT_URL,
    RESEND_OWNERSHIP_STATEMENT_URL,
    SAVE_OWNERSHIP_NOTE_URL,
    SAVE_OWNERSHIP_PAYMENT_URL,
    SAVE_OWNERSHIP_URL,
    TITLE_DETAIL_URL,
    TOTAL_OWNERSHIPS_URL,
    TOTAL_TERMINATED_OWNERSHIPS_URL,
    UPDATE_OWNERSHIP_URL,
    UPLOAD_TITLE_DOC_URL
} from "../constants/url.constants"
import {serviceAPI, serviceAPIDownloadFile} from "./service"
import {handleErrorResponse} from "../actions/message"
import {isArray, isNullUndefined} from "../util/util"

//GET REQUEST
export const exportOwnerships = () => {
    return serviceAPIDownloadFile.get(EXPORT_OWNERSHIPS_URL)
}

export const getOwnerships = (searchReference, searchOwner, searchUnit, pageNo, rowsPerPage) => {
    let url = LIST_OWNERSHIPS_URL + searchReference + "&owner=" + searchOwner + "&unit=" + searchUnit + "&pageNo=" + pageNo + "&rowsPerPage=" + rowsPerPage
    return serviceAPI.get(url)
        .then((response) => {
            if (response.status === 200 && !isNullUndefined(response.data) && isArray(response.data)) {
                return response.data
            } else {
                return []
            }
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getTotalOwnerships = (searchReference, searchOwner, searchUnit) => {
    let url = TOTAL_OWNERSHIPS_URL + searchReference + "&owner=" + searchOwner + "&unit=" + searchUnit
    return serviceAPI.get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getTerminatedOwnerships = (searchReference, searchOwner, searchUnit, pageNo, rowsPerPage) => {
    let url = LIST_TERMINATED_OWNERSHIPS_URL + searchReference + "&owner=" + searchOwner + "&unit=" + searchUnit + "&pageNo=" + pageNo + "&rowsPerPage=" + rowsPerPage
    return serviceAPI.get(url)
        .then((response) => {
            return response.data
        })
}

export const getTotalTerminatedOwnerships = (searchReference, searchOwner, searchUnit) => {
    let url = TOTAL_TERMINATED_OWNERSHIPS_URL + searchReference + "&owner=" + searchOwner + "&unit=" + searchUnit
    return serviceAPI.get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getOwnershipPaymentMethods = () => {
    return serviceAPI.get(OWNERSHIP_PAYMENT_METHOD_URL)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getOwnershipTerminationAccount = (titleId) => {
    let url = OWNERSHIP_TERMINATION_ACCOUNT_URL + titleId
    return serviceAPI.get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getTitleDetail = (titleId) => {
    let url = TITLE_DETAIL_URL + titleId
    return serviceAPI.get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getOutstandingTitleInvoicesCharges = (titleId) => {
    let url = OUTSTANDING_TITLE_INVOICES_CHARGES_URL + titleId
    return serviceAPI.get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getOwnershipContacts = (titleId) => {
    let url = GET_OWNERSHIP_CONTACTS_URL + titleId
    return serviceAPI.get(url)
        .then((response) => {
            return response.data.contacts
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getOwnershipChargeCodes = () => {
    return serviceAPI.get(OWNERSHIP_CHARGE_CODES_URL)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getOwnershipNotes = (titleId, pageNo) => {
    let url = OWNERSHIP_NOTES_URL + titleId + "&pageNo=" + pageNo
    return serviceAPI.get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getOwnershipFinancials = (titleId, pageNo) => {
    let url = OWNERSHIP_FINANCIALS_URL + titleId + "&pageNo=" + pageNo
    return serviceAPI.get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

//POST REQUEST
export const saveOwnershipPayment = (data) => {
    return serviceAPI.post(SAVE_OWNERSHIP_PAYMENT_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const saveOwnershipData = (data) => {
    return serviceAPI.post(SAVE_OWNERSHIP_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const updateOwnershipData = (data) => {
    return serviceAPI.post(UPDATE_OWNERSHIP_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const saveOwnershipNote = (data) => {
    return serviceAPI.post(SAVE_OWNERSHIP_NOTE_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const ownershipTermination = (data) => {
    return serviceAPI.post(OWNERSHIP_TERMINATION_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const downloadOwnerships = (data) => {
    return serviceAPI.post(DOWNLOAD_OWNERSHIPS_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const removeOwnershipContact = (data) => {
    return serviceAPI.post(REMOVE_OWNERSHIP_CONTACT_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const addOwnershipContact = (data) => {
    return serviceAPI.post(ADD_OWNERSHIP_CONTACT_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const bulkEditOwnerships = (data) => {
    return serviceAPI.post(BULK_EDIT_OWNERSHIPS_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const uploadTitleDoc = (titleId, data) => {
    return serviceAPI.post(UPLOAD_TITLE_DOC_URL + titleId, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const deleteTitleDoc = (titleId, data) => {
    return serviceAPI.post(DELETE_TITLE_DOC_URL + titleId, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const resendLastOwnershipInvoice = (data) => {
    return serviceAPI.post(RESEND_LAST_OWNERSHIP_INVOICE_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const resendLastOwnershipReceipt = (data) => {
    return serviceAPI.post(RESEND_LAST_OWNERSHIP_RECEIPT_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const resendOwnershipStatement = (data) => {
    return serviceAPI.post(RESEND_OWNERSHIP_STATEMENT_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}
