import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography,} from "@mui/material"
// import React, { useEffect, useState } from "react"
import {useEffect, useState} from "react";
import {ResponsiveRow} from "../../../../constants/layout.constants"
import {
    DetailsColumn,
    DetailsContainer,
    DetailsLabel,
    DetailsRow,
    DetailsText
} from "../../../../constants/component.constants"
import ModalContainerModule from "../../../modules/modalContainer"
import {formatDate, isNullUndefined, isNumeric} from "../../../../util/util"

export default function ApproveQuotationModal(props) {
    const [workOrder, setWorkOrder] = useState(null)
    const [description, setDescription] = useState("")
    const [date, setDate] = useState("")
    const [vendor, setVendor] = useState("")
    const [quotationItems, setQuotationItems] = useState([])


    const quotationItemRows = () => {
        return quotationItems.map((item, i) => {
            return (
                <TableRow key={i}>
                    <TableCell>
                        <Typography sx={{width: "100%"}}>{item.memo}</Typography>
                    </TableCell>
                    <TableCell>
                        <Typography sx={{width: "100%"}}>{item.unitPrice}</Typography>
                    </TableCell>
                    <TableCell>
                        <Typography sx={{width: "100%"}}>{item.quantity}</Typography>
                    </TableCell>
                    <TableCell>
                        <Typography sx={{width: "100%"}}>
                            {item.applyVAT ? "Yes" : "No"}
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography sx={{width: "100%"}}>
                            {item.otherCharges}
                        </Typography>
                    </TableCell>
                    <TableCell>
                        {isNumeric(item.unitPrice) && isNumeric(item.quantity)
                            ? item.unitPrice * item.quantity
                            : 0}
                    </TableCell>
                </TableRow>
            )
        }, this)
    }

    useEffect(() => {
        if (
            props.selectedQuotation &&
            !isNullUndefined(props.selectedQuotation)
        ) {
            setWorkOrder(props.selectedQuotation.workOrder)
            setDescription(props.selectedQuotation.description)
            setDate(props.selectedQuotation.addedOn)
            setQuotationItems(props.selectedQuotation.quotationItems)
            if (props.selectedQuotation &&
                !isNullUndefined(props.selectedQuotation.vendor) &&
                !isNullUndefined(props.selectedQuotation.vendor.name)
            ) {
                setVendor(props.selectedQuotation.vendor.name)
            }
        }
    }, [props])

    return (
        <ModalContainerModule
            size="specialApproveQuotationModal"
            accept={props.handleApproveQuotation}
            cancel={props.closeModal}
            reject={props.handleRejectQuotation}
            openModal={props.openModal}
            modalTitle="Approve / Reject Quotation"
            acceptButtonText="Approve"
            rejectButtonText="Reject"
        >
            <ResponsiveRow
                sx={{width: "100%", height: "auto", flexDirection: "column"}}
            >
                <Typography variant="h6" sx={{color: "#037171"}}>
                    Quotation Information
                </Typography>
                <ResponsiveRow
                    sx={{
                        flexDirection: "row",
                        width: "100%",
                        height: "auto",

                        alignItems: "flex-start",
                    }}
                >
                    <DetailsContainer
                        sx={{
                            width: "100%",
                            height: "auto",
                            marginTop: 0,
                            paddingTop: "10px",
                        }}
                    >
                        <DetailsColumn
                            sx={{width: {xs: "100%", lg: "33.3%"}, height: "auto"}}
                        >
                            <DetailsRow
                                sx={{
                                    flexDirection: "column",
                                    width: "100%",
                                    height: "auto",
                                    marginBottom: "10px",
                                }}
                            >
                                <DetailsLabel
                                    sx={{
                                        color: "#037171",
                                        fontWeight: "bold",

                                        marginBottom: "5px",
                                    }}
                                >
                                    Work Order Description
                                </DetailsLabel>
                                <DetailsText>{workOrder && workOrder.description}</DetailsText>
                            </DetailsRow>
                            <DetailsRow
                                sx={{
                                    flexDirection: "column",
                                    width: "100%",
                                    height: "auto",
                                    marginBottom: "10px",
                                }}
                            >
                                <DetailsLabel
                                    sx={{
                                        color: "#037171",
                                        fontWeight: "bold",
                                        marginBottom: "15px",
                                    }}
                                >
                                    Quotation Description
                                </DetailsLabel>
                                <DetailsText>{description}</DetailsText>
                            </DetailsRow>
                        </DetailsColumn>
                        <DetailsColumn
                            sx={{width: {xs: "100%", lg: "33.3%"}, height: "auto"}}
                        >
                            <DetailsRow
                                sx={{
                                    flexDirection: "column",
                                    width: "100%",
                                    height: "auto",
                                    marginBottom: "10px",
                                }}
                            >
                                <DetailsLabel
                                    sx={{
                                        color: "#037171",
                                        fontWeight: "bold",

                                        marginBottom: "5px",
                                    }}
                                >
                                    Quotation Added On
                                </DetailsLabel>
                                <DetailsText>{formatDate(date)}</DetailsText>
                            </DetailsRow>
                        </DetailsColumn>
                        <DetailsColumn
                            sx={{width: {xs: "100%", lg: "33.3%"}, height: "auto"}}
                        >
                            <DetailsRow
                                sx={{
                                    flexDirection: "column",
                                    width: "100%",
                                    height: "auto",
                                    marginBottom: "10px",
                                }}
                            >
                                <DetailsLabel
                                    sx={{
                                        color: "#037171",
                                        fontWeight: "bold",
                                        marginBottom: "5px",
                                    }}
                                >
                                    Vendor
                                </DetailsLabel>
                                <DetailsText>{vendor}</DetailsText>
                            </DetailsRow>
                        </DetailsColumn>
                    </DetailsContainer>
                </ResponsiveRow>

                <Typography variant="h6" sx={{color: "#037171"}}>
                    Quotation Items
                </Typography>
                <TableContainer>
                    <Table sx={{minWidth: 650}} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    sx={{width: "15%", color: "#037171", fontWeight: "bold"}}
                                >
                                    Memo
                                </TableCell>{" "}
                                <TableCell
                                    sx={{width: "10%", color: "#037171", fontWeight: "bold"}}
                                >
                                    Unit Price
                                </TableCell>
                                <TableCell
                                    sx={{width: "10%", color: "#037171", fontWeight: "bold"}}
                                >
                                    Quantity
                                </TableCell>
                                <TableCell
                                    sx={{width: "7%", color: "#037171", fontWeight: "bold"}}
                                >
                                    Taxed
                                </TableCell>
                                <TableCell
                                    sx={{width: "10%", color: "#037171", fontWeight: "bold"}}
                                >
                                    Other Charges
                                </TableCell>
                                <TableCell
                                    sx={{width: "10%", color: "#037171", fontWeight: "bold"}}
                                >
                                    Total
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>{quotationItemRows()}</TableBody>
                    </Table>
                </TableContainer>
            </ResponsiveRow>
        </ModalContainerModule>
    )
}
