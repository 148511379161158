// import {StrictMode} from 'react'
import React, {StrictMode} from "react"
import {createRoot} from "react-dom/client"
import {BrowserRouter} from "react-router-dom"
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import {Provider} from 'react-redux'
import {store} from './store'
import history from "./helpers/history"


const root = createRoot(document.getElementById("root"))
root.render(
    <StrictMode>
    <BrowserRouter location={history.location} navigator={history}>
        <Provider store={store}>
            <App/>
        </Provider>
    </BrowserRouter>
    </StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()


