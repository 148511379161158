/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from "react";
import {FormControl, InputLabel, OutlinedInput, TextField,} from "@mui/material";
import Grid from "@mui/material/Grid";
import {GridActionsCellItem} from "@mui/x-data-grid";
import {
    downloadSelectedMobileMoneyClients,
    editTransferFrequency,
    exportMobileMoneyClients,
    getDaysOfTheWeek,
    getFacilitiesForSub,
    getMobileMoneySubscribersList,
    listMobileMoneyClients,
    totalMobileMoneyClients,
    transferPeriods,
    viewMobileMoneyClient,
} from "../../services/mobileMoneySummary.service";
import {Visibility} from "@mui/icons-material";
import {formatDate, isEmptyString, isNullUndefined} from "../../util/util";
import {getRentDays} from "../../services/list.service";
import {setMessage} from "../../actions/message";
import ExportDocumentsModal from "../../components/modals/exportDocuments";
import {useDispatch, useSelector} from "react-redux";
import {MainContainer, TopBarContainedBrandButton,} from "../../constants/component.constants";
import {ResponsiveRow} from "../../constants/layout.constants";

import TransferFrequencyModal from "../../components/modals/mobileMoney/summary/transferFrequencyModal";
import ViewSummaryModal from "../../components/modals/mobileMoney/summary/viewSummaryModal";
import MuiDataGrid from "../../components/modules/MuiDataGrid";

export default function MobileMoneySummary() {
    const user = useSelector((state) => state.auth.user);
    const [facilityName, setFacilityName] = useState("");
    const [mobileMoneyClientsList, setMobileMoneyClientsList] = useState([]);
    const [paybill, setPaybill] = useState("");
    const [selectionModel, setSelectionModel] = useState([]);

    const [total, setTotal] = useState(0);
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageCount: 25,
    });
    const [transferFrequencyForm, setTransferFrequencyForm] = useState(false);
    const [mobileMoneySubsList, setMobileMoneySubsList] = useState([]);
    const [selectedSub, setSelectedSub] = useState("");
    const [selectedFrequency, setSelectedFrequency] = useState("");
    const [transferPeriodsList, setTransferPeriodsList] = useState([]);
    const [nextTransferDate, setNextTransferDate] = useState("");
    const [rentDays, setRentDays] = useState([]);
    const [selectedRentDay, setSelectedRentDay] = useState("");
    const [daysOfTheWeek, setDaysOfTheWeek] = useState([]);
    const [selectedDay, setSelectedDay] = useState("");
    const [details, setDetails] = useState(null);
    const [itemToView, setItemToView] = useState(null);
    const [viewForm, setViewForm] = useState(false);
    const [titleText, setTitleText] = useState("");
    const [severityText, setSeverityText] = useState("");
    const [bodyText, setBodyText] = useState("");
    const [downloadMobileMoneyClients, setDownloadMobileMoneyClients] =
        useState(false);
    const [mobileMoneyFacilitiesList, setMobileMoneyFacilitiesList] = useState(
        []
    );
    const [selectedFacility, setSelectedFacility] = useState("");
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();
    useEffect(() => {
        setLoading(true);
        listMobileMoneyClients(facilityName, paybill, paginationModel.page)
            .then((data) => {
                setMobileMoneyClientsList(data);
            })
            .catch((error) => {
                console.log("error while listing mobile money clients", error);
            })
            .finally(() => setLoading(false));
        totalMobileMoneyClients(facilityName, paybill)
            .then((data) => setTotal(data.total))
            .catch((error) => {
                console.log("error", error);
            });
        getMobileMoneySubscribersList()
            .then((data) => setMobileMoneySubsList(data))
            .catch((error) => {
                console.log("error", error);
            });
        transferPeriods()
            .then((data) => setTransferPeriodsList(data))
            .catch((error) => {
                console.log("error", error);
            });
        getRentDays()
            .then((data) => setRentDays(data))
            .catch((error) => {
                console.log("error", error);
            });
        getDaysOfTheWeek()
            .then((data) => setDaysOfTheWeek(data))
            .catch((error) => {
                console.log("error", error);
            });
    }, [facilityName, paybill, paginationModel.page]);

    console.log("mobile money clients list", mobileMoneyClientsList);
    console.log("total", total);

    useEffect(() => {
        setLoading(true);
        listMobileMoneyClients(facilityName, paybill, paginationModel.page)
            .then((data) => setMobileMoneyClientsList(data))
            .catch((error) => {
                console.log("error while listing mobile money clients", error);
            });
        totalMobileMoneyClients(facilityName, paybill)
            .then((data) => setTotal(data.total))
            .catch((error) => {
                console.log("error", error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [user.proxyFacility]);

    function handleExportMobileMoneyClients() {
        setDownloadMobileMoneyClients(true);
        let title =
            selectionModel.length > 1
                ? "Download " + selectionModel.length + " Mobile Money Clients"
                : "Download Mobile Money Client";
        let body =
            "Note: Processing the selected Mobile Money Clients" +
            " may take some time. Therefore, the exported data will be emailed to you when it is finished being packaged." +
            " Please ensure the email set for your account is valid, working and that you have access to it in order to receive the export data.";
        setTitleText(title);
        setSeverityText("info");
        setBodyText(body);
    }

    function positiveExportDocsAction() {
        downloadSelectedClients();
    }

    function downloadSelectedClients() {
        if (isNullUndefined(selectionModel) || selectionModel.size < 1) {
            dispatch(
                setMessage({
                    type: "warning",
                    message:
                        "Could not determine the download items. Please refresh the page and try again.",
                })
            );
            return;
        }

        let sendData = {
            facilityIds: selectionModel,
        };
        console.log("facilityIds", selectionModel);
        downloadSelectedMobileMoneyClients(sendData)
            .then((response) => {
                dispatch(
                    setMessage({
                        type: response.status,
                        message: response.message,
                    })
                );
                handleCloseDownloadClientsModal();
            })
            .catch((error) => {
                console.log("error", error);
            });
    }

    function handleCloseDownloadClientsModal() {
        setDownloadMobileMoneyClients(false);
        setSelectionModel([]);
        setTitleText("");
        // setTask("")
        setSeverityText("");
        setBodyText("");
    }

    function handleViewClicked(item) {
        viewMobileMoneyClient(item.facilityId)
            .then((data) => {
                setItemToView(data);
                setViewForm(true);
            })
            .catch((error) => {
                console.log("error", error);
            });
    }

    console.log("item to view", itemToView);

    function handleCloseView() {
        setViewForm(false);
        setItemToView(null);
    }

    const columns = [
        {
            headerName: "Facility Name",
            field: "facilityName",
            minWidth: 250,
            flex: 2.5,
        },
        {
            headerName: "Paybill Number",
            field: "paybill",
            minWidth: 150,
            flex: 1.5,
        },
        {headerName: "Discount", field: "percentageDiscount", width: 100},
        {
            headerName: "Transfer Frequency",
            field: "transferPeriod",
            minWidth: 200,
            flex: 2,
        },
        {
            headerName: "Last Transfer Date",
            field: "lastTransferDate",
            minWidth: 200,
            flex: 2,
            valueFormatter: (row) => {
                return row.value !== "N/A" ? formatDate(row.value) : "N/A";
            },
        },
        {
            headerName: "Amount Transacted",
            field: "amountTransacted",
            maxWidth: 170,
            flex: 1.7,
        },
        {headerName: "Charge", field: "percentageCharge", mixWidth: 100, flex: 1},
        {headerName: "Mpesa Fee", field: "mpesaFee", minWidth: 150, flex: 1.5},
        {
            headerName: "Actions",
            field: "actions",
            minWidth: 100,
            flex: 1,
            type: "actions",
            getActions: (params) => {
                // console.log("Params", params.row)
                let arr = [];
                arr.push(
                    <GridActionsCellItem
                        icon={<Visibility/>}
                        onClick={() => handleViewClicked(params.row)}
                        label="View"
                        showInMenu
                    />
                );
                return arr;
            },
        },
    ];

    function handleEditTransferFreq() {
        let transferDetails = {
            org: null,
            transferPeriod: null,
            transferDate: null,
            transferDay: null,
            facilityId: null,
        };
        setDetails(transferDetails);
        setTransferFrequencyForm(true);
    }

    function handleExportMobileMoneyClientsCLicked() {
        exportMobileMoneyClients()
            .then((response) => {
                dispatch(
                    setMessage({
                        type: response.status,
                        message: response.message,
                    })
                );
            })
            .catch((error) => {
                console.log("error", error);
            });
    }

    function handleCloseTransferFreqForm() {
        setTransferFrequencyForm(false);
        setSelectedDay("");
        setSelectedRentDay("");
        setSelectedFrequency("");
        setSelectedSub("");
        setSelectedFacility("");
        setDetails(null);
    }

    function handleSubChanged(event) {
        let value = event.target.value;
        setSelectedSub(value);
        let obj = details;
        obj.org = value;
        setDetails(obj);

        getFacilitiesForSub(value)
            .then((data) => setMobileMoneyFacilitiesList(data))
            .catch((error) => {
                console.log("error", error);
            });
    }

    console.log("facilities######", mobileMoneyFacilitiesList);

    function validateForm() {
        console.log("sendData", details);
        if (isNullUndefined(details.org) || isEmptyString(details.org)) {
            dispatch(
                setMessage({
                    type: "warning",
                    message: "Must select a subscriber to edit transfer frequency!",
                })
            );
            return false;
        }
        if (
            isNullUndefined(details.facilityId) ||
            isEmptyString(details.facilityId)
        ) {
            dispatch(
                setMessage({
                    type: "warning",
                    message: "Must select facility to edit transfer frequency!",
                })
            );
            return false;
        }
        if (
            isNullUndefined(details.transferPeriod) ||
            isEmptyString(details.transferPeriod)
        ) {
            dispatch(
                setMessage({
                    type: "warning",
                    message: "Must select transfer period to edit transfer frequency!",
                })
            );
            return false;
        }
        if (
            (!isNullUndefined(details.transferPeriod) ||
                !isEmptyString(details.transferPeriod)) &&
            (details.transferPeriod === "MNTH" || details.transferPeriod === "BI") &&
            isNullUndefined(details.transferDay)
        ) {
            dispatch(
                setMessage({
                    type: "warning",
                    message: "Must select transfer day to edit transfer frequency!",
                })
            );
            return false;
        }
        if (
            (!isNullUndefined(details.transferPeriod) ||
                !isEmptyString(details.transferPeriod)) &&
            (details.transferPeriod === "ANU" || details.transferPeriod === "QT") &&
            isNullUndefined(details.transferDate)
        ) {
            dispatch(
                setMessage({
                    type: "warning",
                    message: "Must select transfer date to edit transfer frequency!",
                })
            );
            return false;
        }

        return true;
    }

    function handleSaveTransferFreq() {
        if (validateForm()) {
            editTransferFrequency(details)
                .then((response) => {
                    dispatch(
                        setMessage({
                            type: response.status,
                            message: response.message,
                        })
                    );
                    handleCloseTransferFreqForm();
                })
                .catch((error) => {
                    console.log("error", error);
                });
        }
    }

    return (
        <MainContainer>
            {!viewForm && (
                <Grid
                    container
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: {lg: "nowrap", md: "wrap"},
                        justifyContent: "space-between",
                        width: {xs: "100%", md: "80%"},
                        backgroundColor: "white",
                        padding: {md: "1vh", xs: "1vh"},
                        borderRadius: "1vh",
                        margin: "20px auto",
                        boxShadow: "0 4px 5px 0 rgba(0,0,0,0.5)",
                    }}
                >
                    <ResponsiveRow
                        sx={{
                            width: {xs: "100%", md: "50%"},
                            padding: 0,
                        }}
                        component="form"
                    >
                        <FormControl
                            sx={{
                                width: {xs: "100%", lg: "50%"},
                            }}
                        >
                            <InputLabel id="searchFacilityLabel">Search Facility</InputLabel>
                            <OutlinedInput
                                variant="outlined"
                                id="facilityNameSearch"
                                value={facilityName}
                                label="Search Facility"
                                sx={{width: {xs: "100%", lg: "90%"}, marginBottom: "10px"}}
                                onChange={(event) => {
                                    let value = event.target.value;
                                    setFacilityName(value);
                                }}
                                inputProps={
                                    <TextField
                                        sx={{
                                            width: {xs: "100%", lg: "90%"},
                                            marginBottom: "10px",
                                        }}
                                    />
                                }
                            />
                        </FormControl>

                        <FormControl
                            sx={{
                                width: {xs: "100%", lg: "50%"},
                            }}
                        >
                            <InputLabel id="searchPaybillLabel">Search Paybill</InputLabel>
                            <OutlinedInput
                                variant="outlined"
                                id="paybillSearch"
                                value={paybill}
                                label="Search Paybill"
                                sx={{width: {xs: "100%", lg: "90%"}, marginBottom: "10px"}}
                                onChange={(event) => {
                                    let value = event.target.value;
                                    setPaybill(value);
                                }}
                                inputProps={
                                    <TextField
                                        sx={{
                                            width: {xs: "100%", lg: "90%"},
                                            marginBottom: "10px",
                                        }}
                                    />
                                }
                            />
                        </FormControl>
                    </ResponsiveRow>

                    <ResponsiveRow
                        sx={{
                            width: {xs: "100%", lg: "50%"},
                            justifyContent: {xs: "center", lg: "flex-end"},
                            padding: 0,
                        }}
                    >
                        <TopBarContainedBrandButton
                            variant="contained"
                            sx={{
                                width: {xs: "100%", lg: "auto"},
                                justifyContent: {xs: "center", lg: "flex-end"},
                                height: "50px",
                            }}
                            // type="submit"
                            onClick={handleEditTransferFreq}
                        >
                            Edit Transfer Frequency
                        </TopBarContainedBrandButton>
                        <TopBarContainedBrandButton
                            variant="contained"
                            sx={{
                                width: {xs: "100%", lg: "auto"},
                                justifyContent: {xs: "center", lg: "flex-end"},
                                height: "50px",
                            }}
                            // type="submit"
                            onClick={handleExportMobileMoneyClientsCLicked}
                        >
                            Export Clients
                        </TopBarContainedBrandButton>
                        <TopBarContainedBrandButton
                            variant="contained"
                            sx={{
                                width: {xs: "100%", lg: "auto"},
                                height: "50px",
                            }}
                            // type="submit"
                            onClick={handleExportMobileMoneyClients}
                            disabled={selectionModel.length < 1 ? true : false}
                        >
                            {selectionModel.length > 1
                                ? "Download " + selectionModel.length + " Clients"
                                : "Download Client"}
                        </TopBarContainedBrandButton>
                    </ResponsiveRow>
                </Grid>
            )}

            {!viewForm && (
                // <StyledDataGrid
                // 	rows={mobileMoneyClientsList}
                // 	columns={columns}
                // 	pageSize={rowsPerPage}
                // 	loading={loading}
                // 	rowsPerPageOptions={[rowsPerPage]}
                // 	checkboxSelection
                // 	pagination
                // 	rowCount={rowsPerPage}
                // 	onPageChange={(page) => loadPage(page)}
                // 	paginationMode="server"
                // 	disableSelectionOnClick
                // 	onSelectionModelChange={setSelectionModel}
                // 	selectionModel={selectionModel}
                // 	autoHeight
                // 	components={{
                // 		LoadingOverlay: LinearProgress,
                // 		NoRowsOverlay: EmptyOverlay,
                // 	}}
                // />
                <MuiDataGrid
                    dataGridColumns={columns}
                    dataGridRows={mobileMoneyClientsList}
                    serverPagination={true}
                    handlePageLoad={setPaginationModel}
                    loading={loading}
                    height="60vh"
                    totalRows={mobileMoneyClientsList.length}
                />
            )}

            <TransferFrequencyModal
                handleSaveTransferFreq={handleSaveTransferFreq}
                handleCloseTransferFreqForm={handleCloseTransferFreqForm}
                handleSubChanged={handleSubChanged}
                daysOfTheWeek={daysOfTheWeek}
                details={details}
                nextTransferDate={nextTransferDate}
                rentDays={rentDays}
                mobileMoneySubsList={mobileMoneySubsList}
                mobileMoneyFacilitiesList={mobileMoneyFacilitiesList}
                transferFrequencyForm={transferFrequencyForm}
                transferPeriodsList={transferPeriodsList}
                selectedSub={selectedSub}
                selectedFacility={selectedFacility}
                selectedFrequency={selectedFrequency}
                selectedRentDay={selectedRentDay}
                selectedDay={selectedDay}
                setSelectedFacility={setSelectedFacility}
                setSelectedFrequency={setSelectedFrequency}
                setNextTransferDate={setNextTransferDate}
                setSelectedRentDay={setSelectedRentDay}
                setSelectedDay={setSelectedDay}
                setDetails={setDetails}
            />

            {viewForm && (
                <ViewSummaryModal
                    handleCloseView={handleCloseView}
                    viewForm={viewForm}
                    itemToView={itemToView}
                />
            )}

            <ExportDocumentsModal
                showExportDocumentsModal={downloadMobileMoneyClients}
                handleExportDocumentsClose={handleCloseDownloadClientsModal}
                title={titleText}
                severity={severityText}
                body={bodyText}
                handleExportDocuments={positiveExportDocsAction}
                handleCancel={handleCloseDownloadClientsModal}
            />
        </MainContainer>
    );
}
