import {FormControl,} from "@mui/material";
import {ResponsiveRow} from "../../constants/layout.constants";
import {FileUpload} from "../inputs/fileUpload";
import ModalContainerModule from "../modules/modalContainer";
import {downloadBulkUploadTemplate} from "../../services/shared.service";
import {setMessage} from "../../actions/message";
import {useDispatch} from "react-redux";
import {ModalAcceptButton} from "../../constants/component.constants";

export default function BulkUploadVendorsModal(props) {
    const dispatch = useDispatch()

    const getTemplate = () => {
        downloadBulkUploadTemplate("Vendors")
            .then(() => {
                dispatch(
                    setMessage({
                        type: "success",
                        message: "Template downloaded",
                    })
                );
            })
            .catch((error) => {
                dispatch(
                    setMessage({
                        type: "error",
                        message: "Error occurred while getting the template!",
                    })
                );
            });
    }

    return (
        <ModalContainerModule
            size="small"
            accept={props.handleSubmitClicked}
            cancel={props.handleCancelClicked}
            openModal={props.showVendorBulkUploadModal}
            modalTitle="Bulk Upload Vendors"
            acceptButtonText="Submit"
        >
            <ResponsiveRow sx={{height: "auto", width: "100%"}}>
                <FormControl
                    item
                    sx={{width: {xs: "100%", lg: "33.3%"}, marginBottom: "10px"}}
                >
                    <FileUpload
                        id={"bulkFileInput"}
                        label={"Bulk Upload Excel File"}
                        currentFile={props.bulkFile}
                        progress={props.bulkFileProgress}
                        onChange={props.fileValueChanged}
                        accept={
                            ".xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        }
                        width="100%"
                    />
                </FormControl>
                <ResponsiveRow
                    sx={{
                        width: "100%",
                        justifyContent: {xs: "center", lg: "flex-end"},
                    }}
                >
                    <ModalAcceptButton
                        onclick={getTemplate}
                        sx={{width: {xs: "100%", lg: "200px"}}}
                    >
                        Download Template
                    </ModalAcceptButton>
                </ResponsiveRow>
            </ResponsiveRow>
        </ModalContainerModule>
    );
}
