import {ResponsiveRow} from "../constants/layout.constants"
import {DetailsColumn, DetailsContainer, DetailsLabel, DetailsRow, DetailsText} from "../constants/component.constants"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableRow from "@mui/material/TableRow"
import TableCell from "@mui/material/TableCell"
import TableBody from "@mui/material/TableBody"
import TableContainer from "@mui/material/TableContainer"
import {formatDate, isEmptyArray} from "../util/util"
import TableHead from "@mui/material/TableHead"
import ModalContainerModule from "../components/modules/modalContainer"
import {useSelector} from "react-redux";

export default function QuotationReview(props) {
    const {user} = useSelector((state) => state.auth);
    let rows = []
    let quotationItems = props.quotationItem && props.quotationItem.quotationItems
    quotationItems && !isEmptyArray(quotationItems) && (
        rows = quotationItems.map((item, i) => {
            return (
                <TableRow>
                    <TableCell>{item.memo}</TableCell>
                    <TableCell>{item.quantity}</TableCell>
                    <TableCell>{item.unitPrice}</TableCell>
                    <TableCell>{item.otherCharges}</TableCell>
                    <TableCell>{item.applyVAT ? "Yes" : "No"}</TableCell>
                    <TableCell>{item.total}</TableCell>
                </TableRow>
            )
        })
    )

    return (
        <ModalContainerModule
            size="xs"
            accept={user.proxyRole === "BLDIR" ? props.approveQuotation : false}
            reject={user.proxyRole === "BLDIR" ? props.rejectQuotation : false}
            cancel={props.closeQuotationModal}
            openModal={props.openQuotationReviewModal}
            modalTitle={"Review Quotation"}
            acceptButtonText={"Approve"}
            rejectButtonText={"Reject"}
            acceptDisabled={user.proxyRole === "BLDIR" ? false : true}
        >
            <ResponsiveRow sx={{paddingTop: 0}}>
                <DetailsContainer sx={{width: "100%"}}>
                    <DetailsColumn
                        sx={{
                            width: {lg: "33.3%", md: "100%", xs: "100%"},
                        }}
                    >
                        <DetailsRow>
                            <DetailsLabel>Added by:</DetailsLabel>
                            <DetailsText>
                                {props.quotationItem && props.quotationItem.addedBy && props.quotationItem.addedBy.name}
                            </DetailsText>
                        </DetailsRow>
                        <DetailsRow>
                            <DetailsLabel>Added On:</DetailsLabel>
                            <DetailsText>
                                {props.quotationItem && props.quotationItem.addedOn && formatDate(props.quotationItem.addedOn)}
                            </DetailsText>
                        </DetailsRow>
                        <DetailsRow>
                            <DetailsLabel>Applied To:</DetailsLabel>
                            <DetailsText>
                                {props.quotationItem && props.quotationItem.appliedTo}
                            </DetailsText>
                        </DetailsRow>
                    </DetailsColumn>
                    <DetailsColumn
                        sx={{
                            width: {lg: "33.3%", md: "100%", xs: "100%"},
                        }}
                    >
                        <DetailsRow>
                            <DetailsLabel>Description:</DetailsLabel>
                            <DetailsText>
                                {props.quotationItem && props.quotationItem.description}
                            </DetailsText>
                        </DetailsRow>
                        <DetailsRow>
                            <DetailsLabel>Status:</DetailsLabel>
                            <DetailsText>
                                {props.quotationItem && props.quotationItem.status && props.quotationItem.status.name}
                            </DetailsText>
                        </DetailsRow>
                        <DetailsRow>
                            <DetailsLabel>Total:</DetailsLabel>
                            <DetailsText>
                                {props.quotationItem && props.quotationItem.total}
                            </DetailsText>
                        </DetailsRow>
                    </DetailsColumn>
                </DetailsContainer>

                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Memo</TableCell>
                                <TableCell>Quantity</TableCell>
                                <TableCell>Price Per Unit</TableCell>
                                <TableCell>Other Charges</TableCell>
                                <TableCell>Apply VAT</TableCell>
                                <TableCell>Total</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows}
                        </TableBody>
                    </Table>
                </TableContainer>
            </ResponsiveRow>
        </ModalContainerModule>
    )
}
