import {serviceAPI} from "./service"
import {
    APPROVE_BUDGET,
    DELETE_BUDGET,
    GET_BUDGET, LIST_APPROVED_BUDGETS_URL,
    LIST_BUDGETS,
    REJECT_BUDGET,
    SAVE_BUDGET,
    TOTAL_BUDGETS
} from "../constants/url.constants"
import {handleErrorResponse} from "../actions/message"

export const listBudgets = () => {
    return serviceAPI
        .get(LIST_BUDGETS)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const loadTotalBudgets = () => {
    return serviceAPI
        .get(TOTAL_BUDGETS)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getBudget = (item) => {
    let url = GET_BUDGET + item
    return serviceAPI
        .get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const approveBudget = (data) => {
    return serviceAPI
        .post(APPROVE_BUDGET, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const rejectBudget = (data) => {
    return serviceAPI
        .post(REJECT_BUDGET, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const deleteBudget = (data) => {
    return serviceAPI
        .post(DELETE_BUDGET, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const saveBudget = (data) => {
    return serviceAPI
        .post(SAVE_BUDGET, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const listApprovedBudgets = () => {
	let url = LIST_APPROVED_BUDGETS_URL
	return serviceAPI
		.get(url)
		.then((response) => {
			return response.data
		}).catch(error => {
			handleErrorResponse(error)
		})
}
