import { Typography } from "@mui/material";
import { DocumentStore } from "../documentStore";
import {
  DetailsColumn,
  DetailsContainer,
  DetailsLabel,
  DetailsRow,
  DetailsText,
} from "../../constants/component.constants";
import ModalContainerModule from "../modules/modalContainer";
import { ResponsiveRow } from "../../constants/layout.constants";

export default function ViewPayment(props) {
  return (
    <ModalContainerModule
      size="specialPaymentsViewPayment"
      cancel={props.handleClose}
      openModal={props.openModal}
      modalTitle="View Payment"
      cancelText="Close"
    >
      <ResponsiveRow
        container
        sx={{
          alignItem: "center",
          justifyContent: "flex-start",
          flexDirection: { xs: "column", lg: "row" },
          flexWrap: "nowrap",
          width: "100%",
          height: "100%",
          padding: 0,
        }}
      >
        <ResponsiveRow
          sx={{
            flexDirection: "column",
            flexWrap: "nowrap",
            width: { xs: "100%", lg: "60%" },
            height: "auto",
            padding: 0,
          }}
        >
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2, fontWeight: 700, width: "100%", height: "auto" }}
          >
            Payment Details{" "}
            {props.selectedPayment
              ? "(" + props.selectedPayment.referenceNumber + ")"
              : ""}
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{
              mt: 0,
              color: "#037171",
              fontSize: ".9rem",
              width: "100%",
              height: "auto",
            }}
          >
            Payment Total:{" "}
            {
                props && props.selectedPayment && props.selectedPayment.amount
            }
          </Typography>

          <DetailsContainer
            item
            sx={{
              width: "100%",
              height: "auto",
              padding: { xs: 0, lg: "10px" },
            }}
          >
            <DetailsColumn
              sx={{
                width: { xs: "100%", lg: "50%" },
                height: "auto",
              }}
            >
              <DetailsRow>
                <DetailsLabel id="bill-payee-label">Date:</DetailsLabel>
                <DetailsText>
                  {props.selectedPayment
                    ? props.selectedPayment.date
                    : "10/05/2021"}
                </DetailsText>
              </DetailsRow>
              <DetailsRow>
                <DetailsLabel>Paid To</DetailsLabel>

                <DetailsText>
                  {props.selectedPayment
                    ? props.selectedPayment.paidTo
                    : "Nairobi water"}
                </DetailsText>
              </DetailsRow>
              <DetailsRow>
                <DetailsLabel>Status</DetailsLabel>
                <DetailsText>
                  {props.selectedPayment
                    ? props.selectedPayment.status
                    : "Pending"}
                </DetailsText>
              </DetailsRow>
            </DetailsColumn>
            <DetailsColumn
              sx={{
                width: { xs: "100%", lg: "50%" },
                height: "auto",
              }}
            >
              <DetailsRow>
                <DetailsLabel id="bill-payee-label">Reference</DetailsLabel>
                <DetailsText>
                  {props.selectedPayment
                    ? props.selectedPayment.referenceNumber
                    : "90FKDALE"}
                </DetailsText>
              </DetailsRow>
              <DetailsRow>
                <DetailsLabel>Paid From</DetailsLabel>

                <DetailsText>
                  {props.selectedPayment
                    ? props.selectedPayment.paymentAccount &&
                      props.selectedPayment.paymentAccount.name
                    : "Company Current Account"}
                </DetailsText>
              </DetailsRow>
              <DetailsRow>
                <DetailsLabel>Applied To</DetailsLabel>
                <DetailsText>
                  {props.selectedPayment
                    ? props.selectedPayment.appliedTo
                    : "Property: Repen Center BLK 1, Unit: F1/OFF/101"}
                </DetailsText>
              </DetailsRow>
            </DetailsColumn>
            <DetailsColumn
              sx={{
                width: { xs: "100%", lg: "50%" },
                height: "auto",
              }}
            >
              <DetailsRow>
                <DetailsLabel id="bill-payee-label">
                  Payment Method:
                </DetailsLabel>
                <DetailsText>
                  {props.selectedPayment
                    ? props.selectedPayment.paymentMethod
                    : "Cheque"}
                </DetailsText>
              </DetailsRow>
              {props.selectedPayment &&
                props.selectedPayment.paymentMethod &&
                props.selectedPayment.paymentMethod.code === "CHQ" && (
                  <DetailsRow>
                    <DetailsLabel>Cheque Number:</DetailsLabel>

                    <DetailsText>
                      {props.selectedPayment.chequeNumber &&
                        props.selectedPayment.chequeNumber}
                    </DetailsText>
                  </DetailsRow>
                )}
              <DetailsRow>
                <DetailsLabel>Memo</DetailsLabel>
                <DetailsText>
                  {props.selectedPayment
                    ? props.selectedPayment.description
                    : "water payment"}
                </DetailsText>
              </DetailsRow>
            </DetailsColumn>
          </DetailsContainer>
        </ResponsiveRow>
        <ResponsiveRow
          item
          sx={{
            justifyContent: { xs: "center", lg: "flex-end" },
            alignItems: "flex-start",

            width: { xs: "100%", lg: "33.3%" },
            height: "100%",

            padding: 0,
          }}
        >
          {props.selectedPayment && (
            /*<DocumentUploadWideCard
                                                            uploadMode={"Payment"}
                                                            contract={props.selectedPayment}
                                                        />*/
            <DocumentStore
              files={[
                ...props.selectedPayment.imageFiles,
                ...props.selectedPayment.otherDocuments,
              ]}
              item={props.selectedPayment}
              type={"Payment"}
              disableShadow
              height="500px"
              maxWidth="100%"
            />
          )}
        </ResponsiveRow>
      </ResponsiveRow>
    </ModalContainerModule>
  );
}
