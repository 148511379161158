//This shall be used to manage subscriptions available to
// clients and their rates
import { useEffect, useState } from "react";
import {
  MainContainer,
  TopBarContainedBrandButton,
  TopBarContainer,
} from "../constants/component.constants";
import { ResponsiveRow } from "../constants/layout.constants";
import { Tab, Tabs, Typography } from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid";
import {
  NearMe,
  NoteAdd,
  Payment,
  Payments,
  Update,
  Visibility,
} from "@mui/icons-material";
import {
  getTotalSubscriptions,
  listSubscriptions,
  saveSubscriptionPayment,
} from "../services/subscription.service";
import {
  formatCurrency,
  isEmptyObject,
  isEmptyString,
  isNullUndefined,
  sortJSONArray,
} from "../util/util";
import SubscriptionView from "../components/modals/subscription/viewSubscription";
import SubscriptionAddEdit from "../components/modals/subscription/addEditSubscription";
import AddNoteModal from "../components/modals/subscription/addNoteModal";
import ReceivePayment from "../components/modals/receivePayment";
import CreditNote from "../components/modals/creditNote";
import DebitNote from "../components/modals/debitNote";
import MuiDataGrid from "../components/modules/MuiDataGrid";
import Permit from "../util/permit";
import { useSelector } from "react-redux";
import kodi_theme from "../themes/kodiThemeProvider";

export default function Subscriptions() {
  const [subscriptionList, setSubscriptionList] = useState([]);
  const [subscriptionItem, setSubscriptionItem] = useState(null);
  const [loading, setLoading] = useState(true);
  const [task, setTask] = useState("");
  const [searchName] = useState("");
  const [totalSubscriptions, setTotalSubscriptions] = useState(0);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 50,
    page: 0,
  });
  const [currentTab, setCurrentTab] = useState("0");

  const user = useSelector((state) => state.auth.user);
  const columns = [
    { headerName: "Subscriber Name", field: "name", width: "300" },
    { headerName: "Contact Name", field: "contactName", width: "300" },
    { headerName: "Account Number", field: "accountNo", width: "200" },
    { headerName: "Status", field: "status", width: "200" },
    { headerName: "Bill Freq", field: "billingFreq", width: "150" },
    { headerName: "Facilities", field: "facilityCount", width: "100" },
    {
      headerName: "Days Before Suspension",
      field: "suspendedIn",
      width: "100", //Values on this column will be populated when balances are overdue for 30+ days. If already suspended it will be indicated here as well.
      valueFormatter: (row) => {
        if (isEmptyString(row.value)) {
          return "∞";
        }
        return row.value;
      },
    },
    {
      headerName: "Balance",
      field: "balance",
      width: "150",
      valueFormatter: (params) => formatCurrency(params.value),
    },
    {
      headerName: "Actions",
      field: "actions",
      width: "100",
      type: "actions",
      getActions: (params) => {
        let arr = [];
        arr.push(
          <GridActionsCellItem
            icon={<Visibility />}
            onClick={() => handleViewClicked(params.row)}
            label="View"
            showInMenu
          />
        );
        arr.push(
          <GridActionsCellItem
            icon={<NoteAdd />}
            onClick={() => handleAddNoteClicked(params.row)}
            label="Add Note"
            showInMenu
          />
        );
        arr.push(
          <GridActionsCellItem
            icon={<Payments />}
            onClick={() => handleReceivePaymentClicked(params.row)}
            label="Receive Payment"
            showInMenu
          />
        );

        if (user.roles.includes("SYSAD")) {
          arr.push(
            <GridActionsCellItem
              icon={<Payment />}
              onClick={() => handleIssueCreditClicked(params.row)}
              label="Issue Credit"
              showInMenu
            />
          );
          arr.push(
            <GridActionsCellItem
              icon={<NearMe />}
              onClick={() => handleIssueChargeClicked(params.row)}
              label="Issue Charge"
              showInMenu
            />
          );
          arr.push(
            <GridActionsCellItem
              icon={<Update />}
              onClick={() => handleUpdateClicked(params.row)}
              label="Update"
              showInMenu
            />
          );
        }
        return arr;
      },
    },
  ];

  useEffect(() => {
    if (isEmptyObject(subscriptionItem)) {
      //list only if there is not a subscription selected
      setLoading(true);
      getTotalSubscriptions(searchName).then((response) => {
        setTotalSubscriptions(response.totalSubscriptions);
      });
      listSubscriptions(
        searchName,
        paginationModel.page,
        paginationModel.pageSize
      )
        .then((response) => {
          if (!isNullUndefined(response) && !isNullUndefined(response.data)) {
            setSubscriptionList(
              response.data.sort(sortJSONArray("name", true))
            );
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [
    searchName,
    paginationModel.page,
    paginationModel.pageSize,
    subscriptionItem,
  ]);

  const handleUpdateClicked = (subscription) => {
    // console.log("subscription is", subscription)
    setSubscriptionItem(subscription);
    setTask("Edit");
  };
  const handleIssueChargeClicked = (subscription) => {
    setSubscriptionItem(subscription);
    setTask("Charge");
  };
  const handleViewClicked = (subscription) => {
    setSubscriptionItem(subscription);
    setTask("View");
  };

  const closeView = () => {
    setSubscriptionItem(null);
    setTask("");
  };

  const closeModal = () => {
    setSubscriptionItem(null);
    setTask("");
  };

  const handleAddNoteClicked = (subscription) => {
    setSubscriptionItem(subscription);
    setTask("Note");
  };
  const handleIssueCreditClicked = (subscription) => {
    setSubscriptionItem(subscription);
    setTask("Credit");
  };
  const handleReceivePaymentClicked = (subscription) => {
    setSubscriptionItem(subscription);
    setTask("Payment");
  };
  const handleAddSubscription = () => {
    setSubscriptionItem({});
    setTask("Create");
  };

  const savePayment = (paymentItem) => {
    paymentItem.subscriptionId = subscriptionItem.id;
    saveSubscriptionPayment(paymentItem).then(() => {
      closeModal();
    });
  };

  return (
    <MainContainer>
      {isEmptyString(task) && (
        <Permit roles={"SYSAD"}>
          <TopBarContainer
            container
            sx={{
              flexDirection: { xs: "column", lg: "row" },
              flexWrap: "nowrap",
              justifyContent: "space-between",
              boxShadow: "4px 4px 12px #aaa",
              width: { xs: "100%", lg: "80%" },
              height: "auto",
              marginBottom: "20px",
            }}
          >
            <ResponsiveRow
              sx={{
                width: "100%",
                padding: 0,
                alignItems: "center",
                justifyContent: { xs: "center", lg: "space-between" },
              }}
            >
              <Typography
                sx={{
                  textAlign: { xs: "center", lg: "left" },
                  width: { xs: "100%", lg: "50%" },
                }}
                id="subscriptionServices"
                variant="h6"
              >
                Subscriptions
              </Typography>

              <ResponsiveRow
                item
                sx={{
                  padding: 0,
                  width: { xs: "100%", lg: "50%" },
                  justifyContent: { xs: "center", lg: "flex-end" },
                }}
              >
                <TopBarContainedBrandButton
                  sx={{ width: { xs: "100%", lg: "200px" } }}
                  onClick={handleAddSubscription}
                >
                  Add a Subscription
                </TopBarContainedBrandButton>
              </ResponsiveRow>
            </ResponsiveRow>
          </TopBarContainer>
        </Permit>
      )}
      {isEmptyString(task) && (
        <>
          <ResponsiveRow
            sx={{
              flexWrap: "nowrap",
              borderBottom: 1,
              borderColor: "divider",

              backgroundColor: kodi_theme.palette.primary.dark,
              borderRadius: "10px 10px 0 0",

              marginBottom: "-10px",
            }}
          >
            <Tabs
              value={currentTab}
              textColor={"secondary"}
              indicatorColor="secondary"
              aria-label="Subscriptions Statuses"
            >
              <Tab
                label="All Subscriptions"
                sx={{
                  fontSize: "1rem",
                  textTransform: "capitalize",
                }}
                value={"0"}
                onClick={() => setCurrentTab("0")}
              />
              <Tab
                label="Active Subscriptions"
                value={"1"}
                sx={{
                  fontSize: "1rem",
                  textTransform: "capitalize",
                }}
                onClick={() => setCurrentTab("1")}
              />
              <Tab
                label="Suspended Subscriptions"
                value={"2"}
                sx={{
                  fontSize: "1rem",
                  textTransform: "capitalize",
                }}
                onClick={() => setCurrentTab("2")}
              />
              <Tab
                label="Inactive Subscriptions"
                sx={{
                  fontSize: "1rem",
                  textTransform: "capitalize",
                }}
                value={"3"}
                onClick={() => setCurrentTab("3")}
              />
            </Tabs>
          </ResponsiveRow>
          <MuiDataGrid
            dataGridColumns={columns}
            dataGridRows={subscriptionList.filter((item) => {
              if (currentTab === "1") {
                return item.status === "Active" ? true : false;
              }

              if (currentTab === "2") {
                return item.status === "Suspended" ? true : false;
              }

              if (currentTab === "3") {
                return item.status === "Inactive" ? true : false;
              } else {
                return true;
              }
            })}
            noSelection={true}
            loading={loading}
            height="60vh"
            getRowClassName={(params) => {
              return params.row.status === "Suspended" ||
                params.row.status === "Inactive"
                ? "suspended"
                : "";
            }}
            totalRows={totalSubscriptions}
            handlePageLoad={setPaginationModel}
            serverPagination={true}
          />
        </>
      )}
      {task === "View" && (
        <SubscriptionView
          subscription={subscriptionItem}
          closeView={closeView}
        />
      )}
      {(task === "Edit" || task === "Create") && (
        <SubscriptionAddEdit
          subscription={subscriptionItem}
          closeAddEdit={closeModal}
          task={task}
        />
      )}
      {task === "Note" && (
        <AddNoteModal
          subscription={subscriptionItem}
          noteModal={true}
          handleNoteClose={closeModal}
        />
      )}
      {task === "Payment" && (
        <ReceivePayment
          showPaymentModal={true}
          handlePaymentClose={closeModal}
          paymentMode={"Client"}
          contract={subscriptionItem}
          savePayment={savePayment}
        />
      )}
      {task === "Credit" && (
        <CreditNote
          open={true}
          onClose={closeModal}
          subscriptionId={subscriptionItem?.id}
        />
      )}
      {task === "Charge" && (
        <DebitNote
          open={true}
          onClose={closeModal}
          subscriptionId={subscriptionItem?.id}
        />
      )}
    </MainContainer>
  );
}
