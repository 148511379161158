import {Grid, IconButton, Link, ListItemText, MenuItem, MenuList, Paper,} from "@mui/material"
import {Box} from "@mui/system"
import {ResponsiveRow} from "../../constants/layout.constants"
import Menu from "@mui/icons-material/Menu"
import main_logo from "../../images/main-logo.png"
import {useState} from "react"

export default function ListingNavbar() {
    const [mobileNav, setMobileNav] = useState(false)

    return (
        <Grid sx={{position: "relative", zIndex: 105}}>
            <ResponsiveRow
                sx={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    backgroundColor: "#fff",
                    flexDirection: "row",
                    padding: "20px 10px",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    height: "100px",
                }}
            >
                <Link
                    href="/listings"
                    sx={{
                        margin: "auto 10px",
                        color: "#fff",
                        fontWeight: 700,
                        textDecoration: "none",
                    }}
                >
                    <Box
                        sx={{maxWidth: "auto", objectFit: "contain", height: "50px"}}
                        component="img"
                        src={main_logo}
                        alt="main logo"
                    />
                </Link>

                <Box sx={{display: {xs: "none", lg: "flex"}}}>
                    <Link
                        href="/listings/explore"
                        sx={{
                            margin: "auto 10px",
                            color: "#037171",
                            fontWeight: 700,
                            textDecoration: "none",
                        }}
                    >
                        Explore
                    </Link>
                    <Link
                        href="/listings/register"
                        sx={{
                            margin: "auto 10px",
                            color: "#037171",
                            fontWeight: 700,
                            textDecoration: "none",
                        }}
                    >
                        Register
                    </Link>
                    <Link
                        href="/listings/signIn"
                        sx={{
                            margin: "auto 10px",
                            color: "#037171",
                            fontWeight: 700,
                            textDecoration: "none",
                        }}
                    >
                        Sign in
                    </Link>
                </Box>
                <Box sx={{display: {xs: "flex", lg: "none"}}}>
                    <IconButton
                        sx={{width: "100px"}}
                        onClick={() => setMobileNav(!mobileNav)}
                    >
                        <Menu
                            sx={{
                                color: "#037171",
                                fontSize: "1.8rem",
                            }}
                        />
                    </IconButton>
                </Box>
                <Paper
                    sx={{
                        width: "50%",
                        margin: "0 0 0 45%",
                        display: mobileNav ? "flex" : "none",
                        textAlign: "start",
                        // position: "absolute",
                        zIndex: 107,
                    }}
                >
                    <MenuList sx={{width: "100%"}}>
                        <MenuItem sx={{width: "100%", textAlign: "start"}}>
                            <ListItemText sx={{width: "100%"}}>
                                <Link
                                    href="/listings/explore"
                                    sx={{
                                        width: "100%",
                                        color: "#037171",
                                        fontWeight: 700,
                                        textAlign: "start",
                                        textDecoration: "none",
                                    }}
                                >
                                    Explore
                                </Link>
                            </ListItemText>
                        </MenuItem>
                        <MenuItem sx={{width: "100%"}}>
                            <ListItemText sx={{width: "100%"}}>
                                <Link
                                    href="/listings/register"
                                    sx={{
                                        width: "100%",
                                        color: "#037171",
                                        fontWeight: 700,
                                        textAlign: "start",
                                        textDecoration: "none",
                                    }}
                                >
                                    Register
                                </Link>
                            </ListItemText>
                        </MenuItem>
                        <MenuItem sx={{width: "100%"}}>
                            <ListItemText sx={{width: "100%"}}>
                                <Link
                                    href="/listings/signIn"
                                    sx={{
                                        width: "100%",
                                        color: "#037171",
                                        fontWeight: 700,
                                        textAlign: "start",
                                        textDecoration: "none",
                                    }}
                                >
                                    Sign In
                                </Link>
                            </ListItemText>
                        </MenuItem>
                    </MenuList>
                </Paper>
            </ResponsiveRow>
        </Grid>
    )
}
