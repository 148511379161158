import {Grid, TextField, Typography} from "@mui/material";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {useEffect, useMemo, useState} from "react";
import {
    DetailsCardContainer,
    DetailsColumn,
    DetailsContainer,
    DetailsLabel,
    DetailsRow,
    DetailsText,
    TopBarContainedBrandButton,
    TopBarContainedCancelButton,
    TopBarContainer,
} from "../../../constants/component.constants";
import {ResponsiveRow} from "../../../constants/layout.constants";
import MeterChargesCard from "../../cards/meterChargesCard";
import NoteLogsCard from "../../cards/noteLogsCard";
import {DocumentStore} from "../../documentStore";
import TransactionHistory from "../../transactionHistory";

import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {printMeterBilling, printMeterBillingStatement,} from "../../../services/print.service";
import {formatDate} from "../../../util/util";
import dayjs from "dayjs";

export default function ViewMeterBilling(props) {
    /**
     *
     * REQUIRED PROPS
     *
     * selectedMeterBilling
     * handleClose
     * openCreditModal
     * openChargeModel
     * updateVoidItem
     */
    const [selectedMeterBilling, setSelectedMeterBilling] = useState(null);
    const [statementYear, setStatementYear] = useState(new Date());
    const [voidItem, setVoidItem] = useState(null);

    useEffect(() => {
        if (props.selectedMeterBilling) {
            setSelectedMeterBilling(props.selectedMeterBilling);
        }
        return () => setSelectedMeterBilling(null);
    }, [props]);

    useMemo(() => {
        props.updateVoidItem(voidItem);

        return () => setVoidItem(null);
    }, [props, voidItem]);

    function handleStatementYearChange(value) {
        let date = new Date(value);
        setStatementYear(date);
    }

    function handlePrintMeterBillingStatement() {
        printMeterBillingStatement(
            selectedMeterBilling.id,
            statementYear.getFullYear()
        );
    }

    function handlePrintMeterBilling() {
        printMeterBilling(selectedMeterBilling.id);
    }

    function handleVoidClick(item) {
        if (item.type === "Charge" || item.type === "Invoice") {
            props.openCreditModal(); //
            //set the id of the transaction being voided
            setVoidItem(item);
        } else if (item.type === "Receipt" || item.type === "Credit") {
            props.openChargeModel(); //
            setVoidItem(item);
        }
    }

    return (
        <>
            <TopBarContainer
                container
                sx={{
                    width: {xs: "100%", lg: "80%"},
                    boxShadow: "4px 4px 12px #aaa",
                    flexWrap: {xs: "wrap", lg: "nowrap"},
                }}
            >
                <Grid
                    item
                    sx={{width: {xs: "100%", lg: "50%"}, textAlign: "start"}}
                >
                    <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale="en-gb"
                    >
                        <DatePicker
                            sx={{width: "90%"}}
                            id={"statementYear"}
                            value={dayjs(statementYear)}
                            disableFuture={true}
                            disablePast={false}
                            openTo="year"
                            views={["year"]}
                            isrequired={true}
                            onChange={(newDate) => handleStatementYearChange(newDate)}
                            
                        />
                    </LocalizationProvider>
                </Grid>
                <ResponsiveRow
                    item
                    sx={{
                        flexWrap: {xs: "wrap", lg: "nowrap"},
                        justifyContent: {xs: "center", lg: "flex-end"},
                        padding: 0,
                    }}
                >
                    <TopBarContainedBrandButton onClick={handlePrintMeterBilling} sx={{}}>
                        Print Billing
                    </TopBarContainedBrandButton>

                    <TopBarContainedBrandButton
                        onClick={handlePrintMeterBillingStatement}
                        // sx={{
                        //   margin: 0,
                        // }}
                    >
                        Print Meter Billing Statement
                    </TopBarContainedBrandButton>

                    <TopBarContainedCancelButton
                        // type="submit"
                        sx={{width: {xs: "100%", lg: "150px"}}}
                        onClick={props.handleClose}
                    >
                        Close
                    </TopBarContainedCancelButton>
                </ResponsiveRow>
            </TopBarContainer>
            <br/>

            <Grid
                container
                variant=""
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "40px",
                    textAlign: "left",
                }}
            >
                <Typography id="modal-modal-title" variant="h4" component="h2">
                    Billing Information for{" "}
                    {props.selectedMeterBilling &&
                        props.selectedMeterBilling.customerName}
                </Typography>
                <br/>
                <DetailsCardContainer sx={{width: "100%", padding: 0}}>
                    <DetailsContainer
                        sx={{
                            justifyContent: "space-around",
                            padding: 0,
                        }}
                    >
                        <DetailsColumn container>
                            <Typography
                                sx={{marginBottom: "20px", fontSize: "20px"}}
                                id="modal-modal-title"
                                variant="h5"
                                component="h4"
                            >
                                Meter Information
                            </Typography>
                            <DetailsRow>
                                <DetailsLabel> Property: </DetailsLabel>
                                <DetailsText>
                                    {props.selectedMeterBilling &&
                                        props.selectedMeterBilling.facilityName}
                                </DetailsText>
                            </DetailsRow>
                            <DetailsRow>
                                <DetailsLabel> Meters: </DetailsLabel>
                                <DetailsText>
                                    {props.selectedMeterBilling &&
                                        props.selectedMeterBilling.meters}
                                </DetailsText>
                            </DetailsRow>
                            <DetailsRow>
                                <DetailsLabel> Customer Name: </DetailsLabel>
                                <DetailsText>
                                    {props.selectedMeterBilling &&
                                        props.selectedMeterBilling.customerName}
                                </DetailsText>
                            </DetailsRow>
                        </DetailsColumn>
                        <DetailsColumn>
                            <Typography
                                sx={{marginBottom: "20px", fontSize: "20px"}}
                                id="modal-modal-title"
                                variant="h5"
                                component="h4"
                            >
                                Status Information
                            </Typography>
                            <DetailsRow>
                                <DetailsLabel> Status: </DetailsLabel>
                                <DetailsText>
                                    {props.selectedMeterBilling &&
                                        props.selectedMeterBilling.statusCd}
                                </DetailsText>
                            </DetailsRow>
                            <DetailsRow>
                                <DetailsLabel> Effective Date: </DetailsLabel>
                                <DetailsText>
                                    {formatDate(
                                        props.selectedMeterBilling &&
                                        props.selectedMeterBilling.effectiveDate
                                    )}
                                </DetailsText>
                            </DetailsRow>
                            <DetailsRow>
                                <DetailsLabel> Expiration Date: </DetailsLabel>
                                <DetailsText>
                                    {props.selectedMeterBilling &&
                                        props.selectedMeterBilling.expirationDate}
                                </DetailsText>
                            </DetailsRow>
                        </DetailsColumn>

                        <DetailsColumn>
                            <Typography
                                sx={{marginBottom: "20px", fontSize: "20px"}}
                                id="modal-modal-title"
                                variant="h5"
                                component="h4"
                            >
                                Billing Information
                            </Typography>
                            <DetailsRow>
                                <DetailsLabel> Billing Code: </DetailsLabel>
                                <DetailsText>
                                    {props.selectedMeterBilling &&
                                        props.selectedMeterBilling.billingCode}
                                </DetailsText>
                            </DetailsRow>
                            <DetailsRow>
                                <DetailsLabel> Billing Frequency: </DetailsLabel>
                                <DetailsText>
                                    {props.selectedMeterBilling &&
                                        props.selectedMeterBilling.billingFrequency}
                                </DetailsText>
                            </DetailsRow>
                            <DetailsRow>
                                <DetailsLabel> Billing Day/Date: </DetailsLabel>
                                <DetailsText>
                                    {props.selectedMeterBilling &&
                                        (props.selectedMeterBilling.billingDay ||
                                            props.selectedMeterBilling.billingDate)}
                                </DetailsText>
                            </DetailsRow>
                        </DetailsColumn>
                    </DetailsContainer>
                </DetailsCardContainer>

                <ResponsiveRow
                    container
                    sx={{
                        justifyContent: {xs: "center", lg: "space-between"},
                        flexWrap: {xs: "wrap", lg: "nowrap"},
                        width: "100%",
                        height: "100%",
                    }}
                >
                    <Grid
                        item
                        sx={{width: {xs: "100%", lg: "33.3%"}, margin: "10px"}}
                    >
                        <NoteLogsCard
                            notesMode={"Meter Billing"}
                            contract={props.selectedMeterBilling}
                        />
                    </Grid>
                    <Grid
                        item
                        sx={{width: {xs: "100%", lg: "33.3%"}, margin: "10px"}}
                    >
                        <MeterChargesCard
                            notesMode={"Meter Billing"}
                            contract={props.selectedMeterBilling}
                        />
                    </Grid>
                    <Grid
                        item
                        sx={{width: {xs: "100%", lg: "33.3%"}, margin: "10px"}}
                    >
                        <DocumentStore
                            files={[
                                ...props.selectedMeterBilling.imageFiles,
                                ...props.selectedMeterBilling.otherDocuments,
                            ]}
                            item={props.selectedMeterBilling}
                            type={"Meter Billing"}
                            height="500px"
                            // close={}
                            // uploadFile={uploadFile}
                            // deleteFile={deleteFile}
                        />
                    </Grid>
                </ResponsiveRow>
                <br/>

                {/* Shared Transaction History */}
                <TransactionHistory
                    transactionMode={"Meter Billing"}
                    contract={props.selectedMeterBilling}
                    handleVoidClick={handleVoidClick}
                />
                <br/>
            </Grid>
            <br/>
        </>
    );
}
