import React, { useCallback, useEffect, useState } from "react";
import Permit from "../../util/permit";
import { NavMenuItem } from "../../constants/component.constants";
import { isEmptyArray, isNullUndefined } from "../../util/util";
import { Menu, Typography } from "@mui/material";
import kodi_theme from "../../themes/kodiThemeProvider";
import { ResponsiveRow } from "../../constants/layout.constants";
import GenerateReport from "../modals/generateReport";
import { useSelector } from "react-redux";

export default function DesktopReportsMenuItem({ reports }) {
  const user = useSelector((state) => state.auth.user);

  const [reportsModal, setReportsModal] = useState(false);
  const [reportType, setReportType] = useState();
  const [reportDescription, setReportDescription] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);

  const [maintenanceAndWorkOrders, setMaintenanceAndWorkOrders] = useState({
    category: "Maintenance and Work Orders",
    children: [],
  });
  const [financialAnalysisAndTracking, setFinancialAnalysisAndTracking] =
    useState({ category: "Financial Analysis and Tracking", children: [] });
  const [vendorAndExpenseReports, setVendorAndExpenseReports] = useState({
    category: "Vendor and Expense Reports",
    children: [],
  });
  const [propertyAndFacilityReports, setPropertyAndFacilityReports] = useState({
    category: "Property and Facility Reports",
    children: [],
  });
  const [financialReports, setFinancialReports] = useState({
    category: "Financial Reports",
    children: [],
  });
  const isOpen = Boolean(anchorEl);

  const openMenu = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const processReport = (item) => {
    setAnchorEl(null);
    setReportsModal(true);
    setReportType(item.selector);
    setReportDescription(item.description);
  };

  const reportsCategorizer = useCallback(
    function () {
      const maintenanceAndWorkOrdersTemp = maintenanceAndWorkOrders;
      const financialAnalysisAndTrackingTemp = financialAnalysisAndTracking;
      const vendorAndExpenseReportsTemp = vendorAndExpenseReports;
      const propertyAndFacilityReportsTemp = propertyAndFacilityReports;
      const financialReportsTemp = financialReports;

      if (!isNullUndefined(reports) && reports.children.length > 0) {
        maintenanceAndWorkOrdersTemp.children = [];
        financialAnalysisAndTrackingTemp.children = [];
        vendorAndExpenseReportsTemp.children = [];
        propertyAndFacilityReportsTemp.children = [];
        financialReportsTemp.children = [];

        reports.children.forEach((item) => {
          switch (item.category) {
            case "Maintenance and Work Orders":
              maintenanceAndWorkOrders.children.push(item);

              break;
            case "Financial Analysis and Tracking":
              financialAnalysisAndTracking.children.push(item);

              break;
            case "Vendor and Expense Reports":
              vendorAndExpenseReports.children.push(item);

              break;
            case "Property and Facility Reports":
              propertyAndFacilityReports.children.push(item);

              break;
            case "Financial Reports":
              financialReports.children.push(item);

              break;

            default:
              break;
          }

          setMaintenanceAndWorkOrders(maintenanceAndWorkOrdersTemp);
          setFinancialAnalysisAndTracking({
            ...financialAnalysisAndTrackingTemp,
          });

          setVendorAndExpenseReports(vendorAndExpenseReportsTemp);

          setPropertyAndFacilityReports({
            ...propertyAndFacilityReportsTemp,
          });

          setFinancialReports(financialReportsTemp);
        });
      }

      // console.log("maintenanceAndWorkOrdersTemp", maintenanceAndWorkOrders);
      // console.log(
      //   "financialAnalysisAndTrackingTemp",
      //   financialAnalysisAndTracking
      // );
      // console.log("vendorAndExpenseReportsTemp", vendorAndExpenseReports);
      // console.log(
      //   "propertyAndFacilityReportsTemp",
      //   propertyAndFacilityReports
      // );
      // console.log("financialReportsTemp", financialReports);
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reports, user]
  );

  useEffect(() => {
    reportsCategorizer();
  }, [reportsCategorizer]);

  return (
    reports && (
      <Permit services="PREMIUM,HOA,METER,BLWTR,CORE,LEAN,MAINT">
        <NavMenuItem
          id="dropDownTrigger"
          aria-controls={isOpen ? "dropDownMenu" : undefined}
          aria-haspopup="true"
          aria-expanded={isOpen ? "true" : undefined}
          onClick={openMenu}
          sx={{
            color: "#fff",
            fontWeight: "bold",
            textDecoration: "none",
          }}
        >
          {reports.name}
        </NavMenuItem>
        <Menu
          id="dropDownMenu"
          anchorEl={anchorEl}
          open={isOpen}
          onClose={closeMenu}
          MenuListProps={{
            "aria-labelledby": "dropDownTrigger",
          }}
          slotProps={{
            paper: {
              style: {
                backgroundColor: kodi_theme.palette.common.white,
                maxHeight: "300px",
              },
            },
          }}
          sx={{
            "& .MuiMenu-paper": {
              borderRadius: "0 0 10px 10px",
              backgroundColor: kodi_theme.palette.common.white,
              boxShadow: 0,
            },
          }}
        >
          {!isEmptyArray(financialAnalysisAndTracking.children) && (
            <ResponsiveRow
              sx={{
                display: "flex",
                flexDirection: "column",
                flexWrap: "nowrap",
              }}
            >
              <Typography
                variant="body1"
                fontWeight={600}
                color={kodi_theme.palette.common.black}
                textAlign={"start"}
                sx={{ width: "100%", marginBottom: "5px" }}
              >
                {financialAnalysisAndTracking.category}
              </Typography>
              {financialAnalysisAndTracking.children.map((item, i) =>
                item.roles === false && item.services === false ? (
                  <NavMenuItem
                    key={i}
                    onClick={() => {
                      processReport(item);
                    }}
                    sx={{ color: kodi_theme.palette.primary.dark }}
                  >
                    {item.name}
                  </NavMenuItem>
                ) : (
                  <Permit
                    key={i}
                    roles={item.roles}
                    services={item.services}
                    permissions={item.permissions}
                  >
                    <NavMenuItem
                      key={i}
                      onClick={() => {
                        processReport(item);
                      }}
                      sx={{ color: kodi_theme.palette.primary.dark }}
                    >
                      {item.name}
                    </NavMenuItem>
                  </Permit>
                )
              )}
            </ResponsiveRow>
          )}

          {!isEmptyArray(financialReports.children) && (
            <ResponsiveRow
              sx={{
                display: "flex",
                flexDirection: "column",
                flexWrap: "nowrap",
              }}
            >
              <Typography
                variant="body1"
                fontWeight={600}
                color={kodi_theme.palette.common.black}
                textAlign={"start"}
                sx={{ width: "100%", marginBottom: "5px" }}
              >
                {financialReports.category}
              </Typography>
              {financialReports.children.map((item, i) =>
                item.roles === false && item.services === false ? (
                  <NavMenuItem
                    key={i}
                    onClick={() => {
                      processReport(item);
                    }}
                    sx={{ color: kodi_theme.palette.primary.dark }}
                  >
                    {item.name}
                  </NavMenuItem>
                ) : (
                  <Permit
                    key={i}
                    roles={item.roles}
                    services={item.services}
                    permissions={item.permissions}
                  >
                    <NavMenuItem
                      key={i}
                      onClick={() => {
                        processReport(item);
                      }}
                      sx={{ color: kodi_theme.palette.primary.dark }}
                    >
                      {item.name}
                    </NavMenuItem>
                  </Permit>
                )
              )}
            </ResponsiveRow>
          )}

          {!isEmptyArray(maintenanceAndWorkOrders.children) && (
            <ResponsiveRow
              sx={{
                display: "flex",
                flexDirection: "column",
                flexWrap: "nowrap",
              }}
            >
              <Typography
                variant="body1"
                fontWeight={600}
                color={kodi_theme.palette.common.black}
                textAlign={"start"}
                sx={{ width: "100%", marginBottom: "5px" }}
              >
                {maintenanceAndWorkOrders.category}
              </Typography>
              {maintenanceAndWorkOrders.children.map((item, i) =>
                item.roles === false && item.services === false ? (
                  <NavMenuItem
                    key={i}
                    onClick={() => {
                      processReport(item);
                    }}
                    sx={{ color: kodi_theme.palette.primary.dark }}
                  >
                    {item.name}
                  </NavMenuItem>
                ) : (
                  <Permit
                    key={i}
                    roles={item.roles}
                    services={item.services}
                    permissions={item.permissions}
                  >
                    <NavMenuItem
                      key={i}
                      onClick={() => {
                        processReport(item);
                      }}
                      sx={{ color: kodi_theme.palette.primary.dark }}
                    >
                      {item.name}
                    </NavMenuItem>
                  </Permit>
                )
              )}
            </ResponsiveRow>
          )}

          {!isEmptyArray(propertyAndFacilityReports.children) && (
            <ResponsiveRow
              sx={{
                display: "flex",
                flexDirection: "column",
                flexWrap: "nowrap",
              }}
            >
              <Typography
                variant="body1"
                fontWeight={600}
                color={kodi_theme.palette.common.black}
                textAlign={"start"}
                sx={{ width: "100%", marginBottom: "5px" }}
              >
                {propertyAndFacilityReports.category}
              </Typography>
              {propertyAndFacilityReports.children.map((item, i) =>
                item.roles === false && item.services === false ? (
                  <NavMenuItem
                    key={i}
                    onClick={() => {
                      processReport(item);
                    }}
                    sx={{ color: kodi_theme.palette.primary.dark }}
                  >
                    {item.name}
                  </NavMenuItem>
                ) : (
                  <Permit
                    key={i}
                    roles={item.roles}
                    services={item.services}
                    permissions={item.permissions}
                  >
                    <NavMenuItem
                      key={i}
                      onClick={() => {
                        processReport(item);
                      }}
                      sx={{ color: kodi_theme.palette.primary.dark }}
                    >
                      {item.name}
                    </NavMenuItem>
                  </Permit>
                )
              )}
            </ResponsiveRow>
          )}

          {!isEmptyArray(vendorAndExpenseReports.children) && (
            <ResponsiveRow
              sx={{
                display: "flex",
                flexDirection: "column",
                flexWrap: "nowrap",
              }}
            >
              <Typography
                variant="body1"
                fontWeight={600}
                color={kodi_theme.palette.common.black}
                textAlign={"start"}
                sx={{ width: "100%", marginBottom: "5px" }}
              >
                {vendorAndExpenseReports.category}
              </Typography>
              {vendorAndExpenseReports.children.map((item, i) =>
                item.roles === false && item.services === false ? (
                  <NavMenuItem
                    key={i}
                    onClick={() => {
                      processReport(item);
                    }}
                    sx={{ color: kodi_theme.palette.primary.dark }}
                  >
                    {item.name}
                  </NavMenuItem>
                ) : (
                  <Permit
                    key={i}
                    roles={item.roles}
                    services={item.services}
                    permissions={item.permissions}
                  >
                    <NavMenuItem
                      key={i}
                      onClick={() => {
                        processReport(item);
                      }}
                      sx={{ color: kodi_theme.palette.primary.dark }}
                    >
                      {item.name}
                    </NavMenuItem>
                  </Permit>
                )
              )}
            </ResponsiveRow>
          )}
        </Menu>

        <GenerateReport
          showModal={reportsModal}
          closeModal={() => {
            setReportsModal(false);
            setReportType("");
          }}
          report={reportType}
          description={reportDescription}
        />
      </Permit>
    )
  );
}
