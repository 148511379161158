import {Grid} from "@mui/material"
import {useSelector} from "react-redux"
import ListingDashboardNavbar from "../../components/bars/listingDashboardNavbar"
import ListingNavbar from "../../components/bars/listingNavbar"
import LandingHero from "../../components/modules/listings/landingHero"
import ListingFeaturedListings from "../../components/modules/listings/listingFeaturedListings"

export default function ListingLanding() {
    const {isLoggedIn} = useSelector((state) => state.auth)
    return (
        <Grid sx={{backgroundColor: "#efefef"}} container>
            {isLoggedIn ? <ListingDashboardNavbar/> : <ListingNavbar/>}{" "}
            <LandingHero/>
            <ListingFeaturedListings/>
        </Grid>
    )
}
