import DashboardSysAdmin from "./dashboardSysAdmin"
import Permit from "../../util/permit"
import DashboardBuildingDirector from "./dashboardBuildingDirector"
import DashboardBuildingManager from "./dashboardBuildingManager"
import DashboardOwner from "./dashboardOwner"
import DashboardReceptionist from "./dashboardReceptionist"
import DashboardAccountant from "./dashboardAccountant"

export default function Dashboard() {
    //TODO: Each of these have nested permits that include role and hence are redundant
    //TODO: the `noPermittedServiceMessage` also needs to be added to these permit tags
    //TODO: and not in the child components
    return (
        <div>
            <Permit roles="SYSAD" proxy={false}>
                <DashboardSysAdmin/>
            </Permit>
            <Permit roles="ACC,SYSAD" proxy={true}>
                <DashboardAccountant/>
            </Permit>
            <Permit roles="BLDIR,SYSAD" proxy={true}>
                <DashboardBuildingDirector/>
            </Permit>
            <Permit roles="BLDMG,SYSAD,CS" proxy={true}>
                <DashboardBuildingManager/>
            </Permit>
            <Permit roles="OWN,SYSAD" proxy={true}>
                <DashboardOwner/>
            </Permit>
            <Permit roles="REC,SYSAD" proxy={true}>
                <DashboardReceptionist/>
            </Permit>
        </div>
    )
}
