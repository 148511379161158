import {
  Alert,
  AlertTitle,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { ResponsiveRow } from "../../../../constants/layout.constants";
import ModalContainerModule from "../../../modules/modalContainer";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ModalAcceptButton } from "../../../../constants/component.constants";
import "dayjs/locale/en-gb";
import dayjs from "dayjs";
import { isEmptyString, isNullUndefined } from "../../../../util/util";

export default function AddMeterBilling(props) {
  /**
   *
   * REQUIRED PROPS
   *
   * AddDiv
   * EditDiv
   *
   * handleBillingClose
   * handleBillingDayChange
   * handleBillingItemAdd
   * handleBillingPeriodChange
   * handleMeterBillingDateChange
   * handleMetersChange
   * handleNextBillingDateChange
   *
   * setCustomerModal
   * updateBilling
   *
   * billingItemsTableRows
   * billingItemsTotal
   * billingPeriods
   * facilityMeters
   * rentDays
   * saveMeterBilling
   * selectedMeterbilling
   *
   */

  return (
    <ModalContainerModule
      size="md"
      accept={props.EditDiv ? props.updateBilling : props.saveMeterBilling}
      cancel={props.handleBillingClose}
      openModal={props.AddDiv}
      modalTitle={`  Billing ${
        props.selectedMeterbilling && props.selectedMeterbilling.billingCode
      }`}
      acceptButtonText={!props.EditDiv ? "Create" : "Update"}
    >
      <ResponsiveRow
        sx={{
          height: "auto",
          margin: "auto",
        }}
      >
        <Typography
          sx={{ marginBottom: "5px" }}
          id="modal-modal-title"
          variant="h5"
          component="h4"
        >
          Billing Details
        </Typography>
        <ResponsiveRow
          container
          sx={{
            justifyContent: { xs: "center", lg: "flex-start" },
          }}
        >
          {!props.EditDiv ? (
            <FormControl
              item
              sx={{ width: { xs: "100%", lg: "50%" }, marginBottom: "20px" }}
            >
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale="en-gb"
              >
                <DatePicker
                  label="Start date"
                  sx={{ width: { xs: "100%", lg: "90%" } }}
                  id={"effectiveDate"}
                  value={
                    props.selectedMeterbilling &&
                    dayjs(props.selectedMeterbilling.effectiveDate)
                  }
                  onChange={(newDate) => {
                    props.handleMeterBillingDateChange(newDate);
                  }}

                />
              </LocalizationProvider>
            </FormControl>
          ) : (
            <FormControl
              item
              sx={{
                width: { xs: "100%", lg: "50%" },
                marginBottom: "20px",
              }}
            >
              <InputLabel>Start date</InputLabel>
              <OutlinedInput
                label="Start date"
                sx={{ width: { xs: "100%", md: "90%" } }}
                value={
                  props.selectedMeterbilling &&
                  props.selectedMeterbilling.effectiveDate.toString()
                }

                disabled
              />
            </FormControl>
          )}
          {!props.EditDiv && (
            <FormControl
              item
              sx={{
                width: { xs: "100%", lg: "30%" },
                marginBottom: "20px",
              }}
            >
              {props.selectedMeterbilling.customer.firstName === null && (
                <InputLabel>Customer</InputLabel>
              )}
              <OutlinedInput
                label="Customer"
                sx={{ width: { xs: "100%", md: "90%" } }}
                id={"customer"}
                value={
                  //   props.selectedMeterbilling.customer &&
                  props.selectedMeterbilling.customer
                    ? props.selectedMeterbilling.customer.name
                    : ""
                }

                disabled
              />
            </FormControl>
          )}
          {!props.EditDiv && (
            <ResponsiveRow
              item
              sx={{
                width: { xs: "100%", lg: "15%" },
                padding: 0,

                justifyContent: { xs: "center", lg: "flex-start" },
                alignItems: "center",
                margin: "0 0 10px 0",
              }}
            >
              <ModalAcceptButton
                onClick={() => props.setCustomerModal("true")}
                sx={{
                  width: { xs: "100%", lg: "90%" },
                  height: "50px",
                }}
              >
                Add Customer
              </ModalAcceptButton>
            </ResponsiveRow>
          )}
          {!props.EditDiv ? (
            <FormControl
              item
              sx={{
                width: { xs: "100%", lg: "50%" },
                marginBottom: "20px",
              }}
            >
              <InputLabel>Meter/Meters</InputLabel>
              <Select
                label="Meter /Meters"
                labelId="demo-multiple-chip-label"
                multiple
                sx={{ width: { xs: "100%", md: "90%" } }}
                id={"meter"}
                isrequired={true}
                value={
                  props.selectedMeterbilling &&
                  props.selectedMeterbilling.meterNames
                }
                disabled={props.EditDiv}
                onChange={(event) => props.handleMetersChange(event)}
                defaultValue={[]}
              >
                {props.facilityMeters && props.facilityMeters.length > 0 ? (
                  props.facilityMeters.map(function (facilityMeter, i) {
                    return (
                      <MenuItem key={i} value={facilityMeter.value}>
                        {facilityMeter.label}
                      </MenuItem>
                    );
                  }, this)
                ) : (
                  <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
                )}
              </Select>
            </FormControl>
          ) : (
            <FormControl
              item
              sx={{
                width: { xs: "100%", lg: "50%" },
                marginBottom: "20px",
              }}
            >
              <InputLabel>Meters</InputLabel>
              <OutlinedInput
                label="Meters"
                sx={{ width: { xs: "100%", md: "90%" } }}
                value={
                  props.selectedMeterbilling &&
                  props.selectedMeterbilling.meters
                }

                disabled
              />
            </FormControl>
          )}
          <FormControl
            item
            sx={{
              width: { xs: "100%", lg: "50%" },
              marginBottom: "20px",
            }}
          >
            <InputLabel>Billing Frequency</InputLabel>
            <Select
              label="Billing Frequency"
              sx={{ width: { xs: "100%", md: "90%" } }}
              id={"billingPeriod"}
              value={
                !isEmptyString(
                  props.selectedMeterbilling &&
                    props.selectedMeterbilling.billingPeriod
                )
                  ? props.selectedMeterbilling &&
                    props.selectedMeterbilling.billingPeriod
                  : props.selectedBillingPeriod
              }
              onChange={(event) => {
                props.handleBillingPeriodChange(event);
              }}
            >
              {props.billingPeriods && props.billingPeriods.length > 0 ? (
                props.billingPeriods.map(function (billingPeriod, i) {
                  return (
                    <MenuItem key={i} value={billingPeriod.value}>
                      {billingPeriod.label}
                    </MenuItem>
                  );
                }, this)
              ) : (
                <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
              )}
            </Select>
          </FormControl>
          {props.selectedMeterbilling &&
            props.selectedMeterbilling.billingPeriod === "MNTH" && (
              <FormControl
                item
                sx={{
                  width: { xs: "100%", lg: "50%" },
                  marginBottom: "20px",
                }}
              >
                <InputLabel>Billing Due Day</InputLabel>
                <Select
                  label="Billing Due Day"
                  sx={{ width: { xs: "100%", md: "90%" } }}
                  id={"billingDay"}
                  value={
                    !isEmptyString(
                      props.selectedMeterbilling &&
                        props.selectedMeterbilling.billingDay
                    ) ||
                    !isNullUndefined(
                      props.selectedMeterbilling &&
                        props.selectedMeterbilling.billingDay
                    )
                      ? props.selectedMeterbilling &&
                        props.selectedMeterbilling.billingDay
                      : props.selectedBillingDay
                  }
                  onChange={(event) => props.handleBillingDayChange(event)}
                  defaultValue={[""]}
                >
                  {props.rentDays && props.rentDays.length > 0 ? (
                    props.rentDays.map(function (weekDay, i) {
                      return (
                        <MenuItem key={i} value={weekDay.value}>
                          {weekDay.label}
                        </MenuItem>
                      );
                    }, this)
                  ) : (
                    <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
                  )}
                </Select>
              </FormControl>
            )}
          {props.selectedMeterbilling &&
            (props.selectedMeterbilling.billingPeriod === "ANU" ||
              props.selectedMeterbilling.billingPeriod === "QT") && (
              <FormControl
                item
                sx={{
                  width: { xs: "100%", lg: "50%" },
                  marginBottom: "20px",
                }}
              >
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale="en-gb"
                >
                  <DatePicker
                    label="Next Billing Date"
                    sx={{ width: { xs: "100%", lg: "90%" } }}
                    id={"nextBillingDate"}
                    value={
                      !isEmptyString(
                        props.selectedMeterbilling &&
                          props.selectedMeterbilling.billingDate
                      ) ||
                      !isNullUndefined(
                        props.selectedMeterbilling &&
                          props.selectedMeterbilling.billingDate
                      )
                        ? dayjs(
                            props.selectedMeterbilling &&
                              props.selectedMeterbilling.billingDate
                          )
                        : dayjs(props.selectedBillingDate)
                    }
                    onChange={(newDate) =>
                      props.handleNextBillingDateChange(newDate)
                    }

                  />
                </LocalizationProvider>
              </FormControl>
            )}
        </ResponsiveRow>
      </ResponsiveRow>
      <br />

      <ResponsiveRow sx={{ height: "auto", margin: "auto", padding: "10px" }}>
        <Typography
          sx={{ marginBottom: "5px" }}
          id="modal-modal-title"
          variant="h5"
          component="h4"
        >
          Billing Charges
        </Typography>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {props.EditDiv && (
                  <TableCell sx={{ width: "10%" }}>Meter</TableCell>
                )}
                <TableCell sx={{ width: "30%" }}>Charge Type</TableCell>
                <TableCell sx={{ width: "20%" }}>Amount</TableCell>
                <TableCell sx={{ width: "30%" }}>Start Date</TableCell>
                <TableCell sx={{ width: "10%" }}>Taxed?</TableCell>
                <TableCell sx={{ width: "10%" }}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{props.billingItemsTableRows}</TableBody>
          </Table>
        </TableContainer>
        <Alert severity="info" sx={{ width: "100%" }}>
          <AlertTitle>Total: {props.billingItemsTotal}</AlertTitle>
        </Alert>
        <ResponsiveRow
          container
          sx={{
            justifyContent: { xs: "center", lg: "flex-end" },
            width: "100%",
          }}
        >
          <ModalAcceptButton
            onClick={() => props.handleBillingItemAdd()}
            sx={{
              width: { xs: "100%", lg: "200px" },
            }}
          >
            Add row
          </ModalAcceptButton>
        </ResponsiveRow>
      </ResponsiveRow>
      <br />
    </ModalContainerModule>
  );
}
