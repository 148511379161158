import {useEffect, useState} from "react"
import {useSelector} from "react-redux"
import {useNavigate} from "react-router-dom"
import LoadingButton from "@mui/lab/LoadingButton"
import Grid from "@mui/material/Grid"
import {Box, Button, FormControl, InputLabel, OutlinedInput, Paper, Typography,} from "@mui/material"
import loginImage from "../images/login-image.png"
import {forgotPassword} from "../services/passwords.service"
import {issueResponseMessage, issueWarnMessage} from "../actions/message"
import {isEmptyString} from "../util/util"

export default function ForgotPassword() {
    /*
     * STATES SECTION:
     * This is where all the pages state will be defined.
     * Namely:
     *  - Remember Me Radio Button => isRemembered
     *  - Email Address Input => emailAddr
     *  - Password Input => password
     * They use the useState hook and mainly keep track of the form inputs.
     * They can also be used for form validation, if needed, through their setState callbacks
     */

    const [userEmail, setEmail] = useState("")
    const [loading, setLoading] = useState(false)
    const {isLoggedIn} = useSelector((state) => state.auth)
    const navigate = useNavigate()

    useEffect(() => {
        if (isLoggedIn) {
            navigate("/dashboard")
        }
    }, [isLoggedIn, navigate])

    const onChange = (e) => {
        const userEmail = e.target.value
        setEmail(userEmail)
    }

    const backHome = (e) => {
        navigate("/")
    }

    /**
     * FORM SUBMIT: onSubmit
     * This is the form submit function that takes in the event
     * and reads the values of the states
     */

    const onSubmit = (e) => {
        e.preventDefault()
        if (isEmptyString(userEmail)) {
            issueWarnMessage("Please input a username or email address")
            return false
        }

        setLoading(true)
        let data = {
            username: userEmail,
        }
        forgotPassword(data)
            .then((response) => {
                issueResponseMessage(response)
                setLoading(false)
                setEmail("")
            })
    }

    return (
        <Grid
            sx={{
                height: "100vh",
                width: "100wv",
                margin: "0",
            }}
            container
        >
            <Grid
                item
                xs={false}
                sm={false}
                md={5}
                lg={8}
                sx={{
                    backgroundImage: `url(${loginImage})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    width: "100%",
                }}
            />
            <Grid item sm={12} md={7} lg={4}>
                <Paper
                    sx={{
                        height: "100vh",
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "space-between",
                        justifyContent: "center",
                        margin: "auto",
                    }}
                >
                    <Box sx={{width: "80%", margin: "10px auto 10px auto"}}>
                        <img
                            src={require("../images/kodi-logo.png")}
                            alt=""
                            width={"30%"}
                        />
                    </Box>

                    <Box sx={{width: "80%", margin: "15px auto"}}>
                        <Typography
                            variant="h2"
                            color="primary.dark"
                            sx={{fontWeight: 700}}
                        >
                            Forgot Password
                        </Typography>
                    </Box>

                    <Box sx={{width: "80%", margin: "10px auto"}}>
                        <Typography variant="body2">
                            Please enter your account's email to receive a password reset link
                        </Typography>
                    </Box>

                    <Box sx={{width: "80%", margin: "20px auto"}}>
                        <form onSubmit={onSubmit}>
                            <FormControl sx={{width: "80%"}}>
                                <InputLabel htmlFor="userEmail">
                                    <Typography
                                        variant="body2"
                                        sx={{fontWeight: "medium"}}
                                        color="primary.light"
                                    >
                                        Enter Username
                                    </Typography>
                                </InputLabel>
                                <OutlinedInput
                                    id="userEmail"
                                    type="email"
                                    label="Confirm Email"
                                    required
                                    aria-describedby="email-helper-text"
                                    color="secondary"
                                    onChange={onChange}
                                    value={userEmail}
                                />
                            </FormControl>

                            <LoadingButton
                                variant="contained"
                                type="submit"
                                loading={loading}
                                onClick={onSubmit}
                                sx={{
                                    width: "80%",
                                    backgroundColor: "#037171",
                                    margin: "10px auto",
                                }}
                            >
                                Send Reset Link
                            </LoadingButton>
                        </form>
                    </Box>
                    <Box sx={{width: "80%", margin: "20px auto"}}>
                        <Typography
                            variant="body2"
                            color="primary.dark"
                            textAlign={"center"}
                            sx={{
                                fontWeight: "bold",
                                border: 0,
                                width: "auto",
                                padding: "10px auto",
                                backgroundColor: "transparent",
                            }}
                            onClick={backHome}
                            // onFocus={"bold"}
                            component={Button}
                        >
                            back to login
                        </Typography>
                    </Box>
                </Paper>
            </Grid>
        </Grid>
    )
}
