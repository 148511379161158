import { createTheme } from "@mui/material/styles";

export const kodi_theme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#009f93",
      light: "#009f93",
      dark: "#037171",
    },
    secondary: {
      main: "#e3e3e3",
      light: "#efefef",
      dark: "#e1e1e1",
      contrastText: "#009F93",
    },
    success: {
      main: "#42BA96",
    },
    warning: {
      main: "#FFAE42",
    },
    error: {
      main: "#E53935",
    },
  },
  typography: {
    fontFamily: "Poppins",
    fontWeightBold: 900,
    fontWeightMedium: 600,
    fontWeightRegular: 400,
    h1: {
      fontSize: "2rem",
      fontWeight: 800,
      color: "#000",
    },
    h2: {
      fontSize: "1.571rem",
      fontWeight: 600,
      color: "#000",
    },
    h3: {
      fontSize: "1.429rem",
      fontWeight: 600,
      color: "#000",
    },
    h4: {
      fontSize: "1.286rem",
      fontWeight: 600,
      color: "#000",
    },
    h5: {
      fontSize: "1.143rem",
      fontWeight: 600,
      color: "#000",
    },
    h6: {
      fontSize: "1rem",
      fontWeight: 600,
      color: "#000",
    },
    subtitle1: {
      fontSize: "1.143rem",
      fontWeight: 600,
      color: "#000",
    },
    body1: {
      fontSize: "1rem",
      fontWeight: 4000,
      color: "#000",
    },
    body2: {
      fontSize: "0.857rem",
      fontWeight: 400,
      color: "#000",
    },
  },
  shape: {
    borderRadius: 10,
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: "#037171",
          width: "100%",
          padding: ".5rem .75rem",
          color: "#fff",
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        root: {
          color: "#fff",
          boxShadow: "none",
        },
        paper: {
          height: "auto",
          maxHeight: "250px",

          overflowY: "auto",
          overflowX: "hidden",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          color: "#037171",
        },
      },
    },
  },
  spacing: 10,
});

export default kodi_theme;
