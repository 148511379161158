import {serviceAPI, serviceAPIDownloadFile} from "./service"
import {
    DELETE_INVOICE_DOCUMENT,
    DOWNLOAD_INVOICES,
    EXPORT_INVOICES, FACILITY_CLIENT_URL, GET_PERSON_OR_ORG_OUTSTANDING_INV,
    INVOICE_LEASE,
    INVOICE_OWNERSHIP, LIST_ALL_INVOICES,
    LIST_INVOICE_STATUS,
    LIST_INVOICES,
    LIST_TOTAL_INVOICES,
    OUTSTANDING_LEASE_INVOICES_CHARGES_URL, SAVE_INVOICE_PAYMENT,
    SAVE_INVOICES,
    TENANT_URL,
    UPLOAD_INVOICE_DOCUMENT
} from "../constants/url.constants"
import {handleErrorResponse} from "../actions/message"

export const listInvoiceStatus = () => {
    return serviceAPI
        .get(LIST_INVOICE_STATUS)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const loadInvoices = (data) => {
     return serviceAPI
        .post(LIST_INVOICES, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getTotalInvoices = (data) => {
   return serviceAPI
        .post(LIST_TOTAL_INVOICES, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const loadAllInvoices = (data) => {
    return serviceAPI
        .post(LIST_ALL_INVOICES, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const listClients = () => {
    return serviceAPI
        .get(FACILITY_CLIENT_URL)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const listTenants = (facilityId) => {
    let url = TENANT_URL + "?facilityId=" + facilityId
    return serviceAPI
        .get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export function saveInvoice(data) {
    return serviceAPI
        .post(SAVE_INVOICES, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export function downloadInvoices(data) {
    return serviceAPI
        .post(DOWNLOAD_INVOICES, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export function exportInvoices(data) {
    return serviceAPIDownloadFile
        .post(EXPORT_INVOICES, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export function uploadInvoiceDocument(data, invoiceId) {
    let url = UPLOAD_INVOICE_DOCUMENT + "?invoiceId=" + invoiceId
    return serviceAPI
        .post(url, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const loadInvoiceOwnership = (invoiceId) => {
    let url = INVOICE_OWNERSHIP + "?invoiceId=" + invoiceId
    return serviceAPI
        .get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const loadInvoiceLease = (invoiceId) => {
    let url = INVOICE_LEASE + "?invoiceId=" + invoiceId
    return serviceAPI
        .get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getOutstandingLeaseInvoicesCharges = (leaseId) => {
    let url = OUTSTANDING_LEASE_INVOICES_CHARGES_URL + leaseId
    return serviceAPI
        .get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const deleteInvoiceDocument = (data, invoiceId) => {
    let url = DELETE_INVOICE_DOCUMENT + "?invoiceId=" + invoiceId
    return serviceAPI
        .post(url, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export function saveInvoicePayment(data) {
    return serviceAPI
        .post(SAVE_INVOICE_PAYMENT, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export function getOutstandingInvoicesForPersonOrOrg(customerId) {
    return serviceAPI
        .get(GET_PERSON_OR_ORG_OUTSTANDING_INV + customerId)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}


