import React from "react";
import { ResponsiveRow } from "../../../../constants/layout.constants";
import kodi_theme from "../../../../themes/kodiThemeProvider";
import { Typography } from "@mui/material";
import {
  DetailsColumn,
  DetailsContainer,
  DetailsLabel,
  DetailsRow,
  DetailsText,
} from "../../../../constants/component.constants";
import { formatCurrency } from "../../../../util/util";

export default function PropertySubscriptionServiceItem({ data }) {
  return (
    <ResponsiveRow
      sx={{
        width: { xs: "100%", lg: "45%" },
        height: "auto",

        flexDirection: "column",
        flexWrap: "nowrap",

        justifyContent: "center",
        alignItems: "flex-start",

        backgroundColor: kodi_theme.palette.grey[200],
        borderRadius: "10px",

        margin: "5px 10px",
        padding: "10px",
      }}
    >
      <Typography
        textAlign="start"
        marginY={"0px"}
        sx={{
          width: "auto",
          height: "auto",

          // fontSize: "14px",
          backgroundColor: kodi_theme.palette.primary.dark,

          padding: "5px 10px",
          color: kodi_theme.palette.common.white,
          borderRadius: "10px",
        }}
        fontWeight={700}
        variant="caption"
        //   color="text.primary"
      >
        {data.name}
      </Typography>
      <DetailsContainer
        sx={{
          width: "100%",
          padding: 0,
          marginTop: 0,
        }}
      >
        <DetailsColumn sx={{ width: { xs: "100%", lg: "50%" } }}>
          <DetailsRow sx={{ width: { xs: "100%", lg: "100%" } }}>
            <DetailsLabel
              sx={{
                width: { xs: "100%", lg: "50%" },
                height: "auto",
                fontSize: "15px",
              }}
            >
              Total Cost
            </DetailsLabel>
            <DetailsText
              sx={{
                width: { xs: "100%", lg: "50%" },
                height: "auto",
                fontSize: "15px",
              }}
            >
              {data.totalCost ? formatCurrency(data.totalCost) : "0.00"}
            </DetailsText>
          </DetailsRow>
          <DetailsRow sx={{ width: { xs: "100%", lg: "100%" } }}>
            <DetailsLabel
              sx={{
                width: { xs: "100%", lg: "50%" },
                height: "auto",
                fontSize: "15px",
              }}
            >
              Quantity
            </DetailsLabel>
            <DetailsText
              sx={{
                width: { xs: "100%", lg: "50%" },
                height: "auto",
                fontSize: "15px",
              }}
            >
              {data.quantity ? data.quantity : "--"}
            </DetailsText>
          </DetailsRow>
        </DetailsColumn>
        <DetailsColumn sx={{ width: { xs: "100%", lg: "50%" } }}>
          <DetailsRow sx={{ width: { xs: "100%", lg: "100%" } }}>
            <DetailsLabel
              sx={{
                width: { xs: "100%", lg: "50%" },
                height: "auto",
                fontSize: "15px",
              }}
            >
              {" "}
              Discount (%)
            </DetailsLabel>
            <DetailsText
              sx={{
                width: { xs: "100%", lg: "50%" },
                fontSize: "15px",
                height: "auto",
              }}
            >
              {data.discountPercentage ? data.discountPercentage : "--"}
            </DetailsText>
          </DetailsRow>
          <DetailsRow sx={{ width: { xs: "100%", lg: "100%" } }}>
            <DetailsLabel
              sx={{
                width: { xs: "100%", lg: "50%" },
                height: "auto",
                fontSize: "15px",
              }}
            >
              Discount Amount
            </DetailsLabel>
            <DetailsText
              sx={{
                width: { xs: "100%", lg: "50%" },
                height: "auto",
                fontSize: "15px",
              }}
            >
              {data.discountedCost
                ? formatCurrency(data.discountedCost)
                : "0.00"}
            </DetailsText>
          </DetailsRow>
        </DetailsColumn>
      </DetailsContainer>
    </ResponsiveRow>
  );
}
