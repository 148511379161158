import {serviceAPI, serviceAPIDownloadFile, serviceAPIUpload} from "./service"
import {
    EXPORT_SIGNUPS_URL,
    FETCH_SIGNUP_URL,
    GET_SERVICE_RATE_URL,
    GET_SIGNUP_TEMPLATE_URL,
    GET_STARTED_URL,
    LIST_SIGNUPS_URL,
    SEND_VERIFICATION_CODE_URL,
    SIGNUP_URL,
    TOTAL_SIGNUP_URL,
    UPLOAD_TEMPLATE_URL,
    VERIFY_CODE_URL
} from "../constants/url.constants"
import {handleErrorResponse} from "../actions/message"

//GET REQUEST
export const getSignUpTemplate = (serviceCode) => {
    return serviceAPIDownloadFile.get(GET_SIGNUP_TEMPLATE_URL + serviceCode)
}

export const getServiceRate = (serviceCode, quantity) => {
    return serviceAPI.get(GET_SERVICE_RATE_URL + serviceCode + "&quantity=" + quantity)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const exportSignUps = () => {
    return serviceAPIDownloadFile.get(EXPORT_SIGNUPS_URL)
}


//POST REQUEST
export const fetchSignUp = (data) => {
    return serviceAPI.post(FETCH_SIGNUP_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getSignUps = (data) => {
    return serviceAPI.post(LIST_SIGNUPS_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getTotalSignUps = (data) => {
    return serviceAPI.post(TOTAL_SIGNUP_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const saveSignUp = (data) => {
    return serviceAPI.post(SIGNUP_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getStartedData = (data) => {
    return serviceAPI.post(GET_STARTED_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const uploadTemplate = (formData) => {
    return serviceAPIUpload.post(UPLOAD_TEMPLATE_URL, formData)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const sendVerificationCode = (data) => {
    return serviceAPI.post(SEND_VERIFICATION_CODE_URL, data)
        .then((response) => {
            return response.data;
        }).catch(error => {
            handleErrorResponse(error);
        });
};

export const verifyCode = (data) => {
    return serviceAPI.post(VERIFY_CODE_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

