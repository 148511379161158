import {useEffect, useState} from "react"
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/en-gb'
import {DatePicker} from '@mui/x-date-pickers/DatePicker'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import DateFnsUtils from "@date-io/date-fns"
import dayjs from "dayjs";

export function DatePickerInput(props) {
    const [value, setValue] = useState("")

    function onDateChange(value) {
        setValue(value)
        props.onSelectDate(value)
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
            <LocalizationProvider utils={DateFnsUtils}>
                <DatePicker
                    id={props.componentID}
                    label={props.labelText}
                    sx={{
                        margin: "auto",
                        width: props.width ? props.width : "90%",
                        backgroundColor: "#fff",
                        // margin: "auto 20px",
                        borderRadius: "10px",
                    }}
                    openTo="day"
                    value={value}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    inputFormat="dd/MMM/yyyy"
                    onChange={(new_value) => onDateChange(new_value)}

                />
            </LocalizationProvider>
        </LocalizationProvider>
    )
}

export function CalendarDatePickerInput(props) {
    const {day, month, year} = props
    const [currentMonth, setCurrentMonth] = useState("")

    const [value, setValue] = useState()

    function onDateChange(value) {
        setValue(value)
        props.onSelectDate(value)
    }

    useEffect(() => {
        switch (month) {
            case 0:
                setCurrentMonth("January")
                break

            case 1:
                setCurrentMonth("February")
                break

            case 2:
                setCurrentMonth("March")
                break

            case 3:
                setCurrentMonth("April")
                break

            case 4:
                setCurrentMonth("May")
                break

            case 5:
                setCurrentMonth("June")
                break

            case 6:
                setCurrentMonth("July")
                break

            case 7:
                setCurrentMonth("August")
                break

            case 8:
                setCurrentMonth("September")
                break

            case 9:
                setCurrentMonth("October")
                break

            case 10:
                setCurrentMonth("November")
                break

            case 11:
                setCurrentMonth("December")
                break

            default:
        }

        return () => {
            setCurrentMonth("")
        }
    }, [month])

    useEffect(() => {
        setValue(`${day} ${currentMonth} ${year}`)
    }, [currentMonth, day, value, year])

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
            <LocalizationProvider utils={DateFnsUtils}>
                <DatePicker
                    id={props.componentID}
                    label={props.labelText}
                    sx={{
                        backgroundColor: "#fff",
                        width: props.width ? props.width : "100%",
                        margin: "auto",
                        borderRadius: "10px",
                    }}
                    openTo="day"
                    value={dayjs((value))}
                    views={['day']}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    inputFormat="dd MMMM yyyy"
                    onChange={(new_value) => onDateChange(new_value)}

                />
            </LocalizationProvider>
        </LocalizationProvider>
    )
}

export function MonthPickerInput(props) {
    const [value, setValue] = useState("02")

    function onDateChange(value) {
        setValue(value)

        props.onSelectDate(value)
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
            <LocalizationProvider utils={DateFnsUtils}>
                <DatePicker
                    id={props.componentID}
                    label={props.labelText}
                    views={["month"]}
                    value={value}
                    sx={{
                        backgroundColor: "#fff",
                        margin: "auto 20px",
                        width: "90%",
                        borderRadius: "10px",
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    inputFormat="MMMM"
                    onChange={(new_value) => onDateChange(new_value)}
                />
            </LocalizationProvider>
        </LocalizationProvider>
    )
}

export function YearPickerInput(props) {
    const today = new Date()

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
            <LocalizationProvider utils={DateFnsUtils}>
                <DatePicker
                    id={props.componentID}
                    label={props.labelText}
                    views={['year']}
                    value={dayjs(props.value)}
                    sx={{
                        backgroundColor: "#fff",
                        margin: "auto 20px",
                        borderRadius: "10px",
                        width: {xs: "100%", lg: "90%"},
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    disableFuture={props.disableFuture}
                    disablePast={props.disablePast}
                    inputFormat="Y"
                    max={today.getFullYear() + 1}
                    onChange={(new_value) => props.onSelectDate(new_value)}
                />
            </LocalizationProvider>
        </LocalizationProvider>
    )
}
