import {useEffect, useState} from "react"
import ListingDashboardNavbar from "../../../components/bars/listingDashboardNavbar"
import Grid from "@mui/material/Grid"
import {
    DetailsColumn,
    DetailsContainer,
    DetailsLabel,
    DetailsRow,
    DetailsText,
    StyledDataGrid,
    TopBarContainedBrandButton,
    TopBarContainer
} from "../../../constants/component.constants"
import {GridActionsCellItem} from "@mui/x-data-grid"
import {Check, Clear, Delete, Edit, Visibility} from "@mui/icons-material"
import {
    loadFacilityUnits,
    loadListingTypes,
    loadPropertyTypes,
    loadUserFacilities,
    unitProperties,
} from "../../../services/listingsServices/list.service"
import {formatCurrency, isEmptyArray, isEmptyString, isNullUndefined,} from "../../../util/util"
import {setMessage} from "../../../actions/message"
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {
    deleteListing,
    getListingsByPersonId,
    getTotalListings,
    saveListingData
} from "../../../services/listingsServices/listing.service"
import {amber, green, red} from "@mui/material/colors"
import {DocumentStore} from "../../../components/documentStore"
import ConfirmationModal from "../../../components/modals/confirmation"
import {ModalContainer, ModalInnerContainer, ResponsiveRow,} from "../../../constants/layout.constants"
import {Chip, Divider, MenuItem, Paper, TextField} from "@mui/material"
import FileUpload from 'react-material-file-upload'
import {useDispatch, useSelector} from "react-redux";
import ModalContainerModule from "../../../components/modules/modalContainer";
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';

export default function ListingManagement() {
    const dispatch = useDispatch()
    const [listings, setListings] = useState([])
    const [rowsPerPage] = useState(12)
    const [selectedListing, setSelectedListing] = useState(null)
    const [pageNo, setPageNo] = useState(0)
    const [total, setTotalListings] = useState(0)
    const [loading, setLoading] = useState(false)
    const [selectionModel, setSelectionModel] = useState([])
    const [task, setTask] = useState('')
    const [listingForm, setListingForm] = useState(false)
    const [viewForm, setViewForm] = useState(false)
    const [propertyName, setPropertyName] = useState('')
    const [propertyTypes, setPropertyTypes] = useState([])
    const [selectedPropertyType, setSelectedPropertyType] = useState('')
    const [price, setPrice] = useState('')
    const [listingTypes, setListingTypes] = useState([])
    const [selectedListingType, setSelectedListingType] = useState('')
    const [longitude, setLongitude] = useState('')
    const [latitude, setLatitude] = useState('')
    const [sqft, setSqft] = useState('')
    const [beds, setBeds] = useState('')
    const [baths, setBaths] = useState('')
    const [description, setDescription] = useState('')
    const [filterByPropertyName, setFilterByPropertyName] = useState('')
    const [filterByPropertyType, setFilterByPropertyType] = useState('')
    const [filterByListingType, setFilterByListingType] = useState('')
    const [files, setFiles] = useState([])
    const [titleText, setTitleText] = useState("")
    const [severityText, setSeverityText] = useState("")
    const [bodyText, setBodyText] = useState("")
    const [positiveText, setPositiveText] = useState("")
    const [negativeText, setNegativeText] = useState("")
    const [deleteListingModal, setDeleteListingModal] = useState(false)
    const user = useSelector((state) => state.auth.user)
    const [unitExistsInSystem, setUnitExistsInSystem] = useState(false)
    const [userHasAdminRole, setUserHasAdminRole] = useState(false)
    const [userFacilities, setUserFacilities] = useState([])
    const [selectedFacility, setSelectedFacility] = useState(null)
    const [facilityUnits, setFacilityUnits] = useState([])
    const [selectedUnit, setSelectedUnit] = useState(null)
    const [, setSelectedUnitObject] = useState({})

    useEffect(() => {
        loadListingTypes()
            .then((data) => setListingTypes(data))
            .catch((error) => {
                console.log("loadListingTypes Error: ", error)
            })
        loadPropertyTypes()
            .then((data) => setPropertyTypes(data))
            .catch((error) => {
                console.log("loadPropertyTypes Error: ", error)
            })
        loadUserFacilities()
            .then((data) => setUserFacilities(data))
            .catch((error) => {
                console.log("loadUserFacilities Error: ", error)
            })
    }, [])

    useEffect(() => {
        let roles = user.roles
        if (roles.includes("BLDIR") || roles.includes("BLDMG") || roles.includes("CS")) {
            setUserHasAdminRole(true);
        }
    }, [user.roles])

    useEffect(() => {
        loadFacilityUnits(selectedFacility)
            .then((data) => {
                if (!isEmptyArray(data)) {
                    setFacilityUnits(data)
                } else {
                    if (!isEmptyString(selectedFacility)) {
                        dispatch(
                            setMessage({
                                type: "warning",
                                message: "There are no available units for this facility!",
                            })
                        )
                    }
                }
            })
            .catch((error) => {
                console.log("loadFacilityUnits Error: ", error)
            })
    }, [selectedFacility, dispatch])

    useEffect(() => {
        setLoading(true);
        getListingsByPersonId(
            filterByPropertyType,
            filterByListingType,
            filterByPropertyName,
            pageNo,
            rowsPerPage
        )
            .then((data) => {
                setListings(data)
                setTotalListings(data.length)
                setLoading(false);
            })
            .catch((error) => {
                console.log("getListingsByPersonId Error: ", error)
            })
    }, [
        filterByPropertyType,
        filterByListingType,
        filterByPropertyName,
        pageNo,
        rowsPerPage,
    ])

    // console.log("listing Types", listingTypes)
    // console.log("property Types", propertyTypes)
    // console.log("Files", files)

    function loadPage(page) {
        setPageNo(page)
    }

    const unitExistsCheckBox = (event) => {
        let checked = event.target.checked;
        setUnitExistsInSystem(checked);
        if (!checked) {
            setSelectedFacility(null);
            setSelectedUnit(null);
            setPropertyName("")
        }
    }

    const handleSelectedFacilityChanged = (event) => {
        let value = event.target.value
        setSelectedFacility(value)
        setFacilityUnits([]);//clear units collection to ensure it is update with facility units once a facility is selected.
        // let tmp = selectedListing
        // tmp. = value
        // setSelectedListing(tmp)
    }

    const handleSelectedUnitChanged = (event) => {
        let value = event.target.value
        setSelectedUnit(value)
        setSelectedUnitObject(event.target.value)
        let tmp = selectedListing
        let unit = facilityUnits.find(l => l.value === value)
        setPropertyName(unit.label)
        tmp.propertyName = unit.label
        tmp.unitId = value
        unitProperties(value)
            .then((data) => {
                // console.log("unitProperties", data)
                setPrice(data.rent)
                tmp.price = data.rent
                setSqft(data.squareFeet)
                tmp.sqft = data.squareFeet
                setBeds(data.numberOfBeds)
                tmp.beds = data.numberOfBeds
                setBaths(data.numberOfBaths)
                tmp.baths = data.numberOfBaths
            })
            .catch((error) => console.log("error unitProperties", error))
            .finally(() => {
                setSelectedListing(tmp)
            })
    }

    function handlePropertyTypeChange(event) {
        let value = event.target.value
        setSelectedPropertyType(value)
        let tmp = selectedListing
        tmp.propertyTypeCd = value
        setSelectedListing(tmp)
    }

    function handleListingTypeChange(event) {
        let value = event.target.value
        setSelectedListingType(value)
        let tmp = selectedListing
        tmp.listingTypeCd = value
        setSelectedListing(tmp)
    }

    function onTextChange(event) {
        let value = event.target.value
        let id = event.target.id
        let tmp = selectedListing
        // console.log("event value: ", value)

        if (id === "propertyName") {
            setPropertyName(value)
            tmp.propertyName = value
        } else if (id === "price") {
            setPrice(value)
            tmp.price = value
        } else if (id === "latitude") {
            setLatitude(value)
            tmp.latitude = value
        } else if (id === "longitude") {
            setLongitude(value)
            tmp.longitude = value
        } else if (id === "squareFeet") {
            setSqft(value)
            tmp.sqft = value
        } else if (id === "beds") {
            setBeds(value)
            tmp.beds = value
        } else if (id === "baths") {
            setBaths(value)
            tmp.baths = value
        } else if (id === "description") {
            setDescription(value)
            tmp.description = value
        }

        setSelectedListing(tmp)
    }

    const handleAddClicked = () => {
        let data = {
            id: "",
            propertyName: "",
            propertyTypeCd: "",
            listingTypeCd: "",
            price: "",
            latitude: "",
            longitude: "",
            sqft: "",
            beds: "",
            baths: "",
            unitId: "",
            description: "",
            imageFiles: [],
        }
        setTask("Create")
        setListingForm(true)
        setSelectedListing(data)
    }

    const handleCancelClicked = () => {
        setPropertyName('')
        setPrice('')
        setLatitude('')
        setLongitude('')
        setSqft('')
        setBeds('')
        setBaths('')
        setDescription('')
        setSelectedListingType('')
        setSelectedPropertyType('')
        setFiles([])
        setListingForm(false)
        setSelectedListing(null)
        setTask("")
        setUnitExistsInSystem(false)
        setSelectedFacility(null)
        setFacilityUnits([])
        setSelectedUnit(null)
    }

    const handleCloseView = () => {
        setFiles([])
        setViewForm(false)
        setSelectedListing(null)
        setTask("")
    }

    const validateFormData = (sendData) => {
        if (
            isEmptyString(sendData.propertyName) ||
            isNullUndefined(sendData.propertyName)
        ) {
            dispatch(
                setMessage({
                    type: "warning",
                    message: "Property name cannot be empty.",
                })
            )
            return false
        }
        if (
            isEmptyString(sendData.propertyTypeCd) ||
            isNullUndefined(sendData.propertyTypeCd)
        ) {
            dispatch(
                setMessage({
                    type: "warning",
                    message: "Please select a property type.",
                })
            )
            return false
        }
        if (isEmptyString(sendData.price) || isNullUndefined(sendData.price)) {
            dispatch(
                setMessage({
                    type: "warning",
                    message: "Price cannot be empty.",
                })
            )
            return false
        }
        if (
            isEmptyString(sendData.listingTypeCd) ||
            isNullUndefined(sendData.listingTypeCd)
        ) {
            dispatch(
                setMessage({
                    type: "warning",
                    message: "Please select a listing type.",
                })
            )
            return false
        }
        if (
            isEmptyString(sendData.latitude) ||
            isNullUndefined(sendData.latitude)
        ) {
            dispatch(
                setMessage({
                    type: "warning",
                    message: "Latitude cannot be empty.",
                })
            )
            return false
        }
        if (
            isEmptyString(sendData.longitude) ||
            isNullUndefined(sendData.longitude)
        ) {
            dispatch(
                setMessage({
                    type: "warning",
                    message: "Longitude cannot be empty.",
                })
            )
            return false
        }
        if (isEmptyString(sendData.sqft) || isNullUndefined(sendData.sqft)) {
            dispatch(
                setMessage({
                    type: "warning",
                    message: "Size in Square Feet cannot be empty.",
                })
            )
            return false
        }
        if (task !== "Edit" && isEmptyArray(files)) {
            dispatch(
                setMessage({
                    type: "warning",
                    message: "Please select files ie. images for the listing!",
                })
            )
            return false
        }
        // if(isEmptyString(beds)|| isNullUndefined(beds)){
        //     dispatch(
        //         setMessage({
        //             type: "warning",
        //             message:
        //                 "Please input the number of beds.",
        //         })
        //     )
        //     return false
        // }
        // if(isEmptyString(baths)|| isNullUndefined(baths)){
        //     dispatch(
        //         setMessage({
        //             type: "warning",
        //             message:
        //                 "Please input the number of baths.",
        //         })
        //     )
        //     return false
        // }

        return true
    }

    const handleSubmitClicked = () => {
        let formData = new FormData()

        for (const file of files) {
            //loop through each file in the array and append it in the formData.
            formData.append("listingFiles[]", file)
        }
        formData.append("id", selectedListing.id)
        formData.append("propertyName", selectedListing.propertyName)
        formData.append("propertyTypeCd", selectedListing.propertyTypeCd)
        formData.append("price", selectedListing.price)
        formData.append("listingTypeCd", selectedListing.listingTypeCd)
        formData.append("latitude", selectedListing.latitude)
        formData.append("longitude", selectedListing.longitude)
        formData.append("sqft", selectedListing.sqft)
        formData.append("beds", selectedListing.beds)
        formData.append("baths", selectedListing.baths)
        formData.append("description", selectedListing.description)
        formData.append("unitId", selectedListing.unitId)

        // console.log("formData",...formData)

        validateFormData(selectedListing) &&
        saveListingData(formData)
            .then((response) => {
                dispatch(
                    setMessage({
                        type: response.type,
                        message: response.message,
                    })
                )
                handleCancelClicked()
                getListingsByPersonId(
                    filterByPropertyType,
                    filterByListingType,
                    filterByPropertyName,
                    pageNo,
                    rowsPerPage
                )
                    .then((data) => setListings(data))
                    .catch((error) => {
                        console.log("getListingsByPersonId Error: ", error)
                    })
                getTotalListings(
                    filterByPropertyType,
                    filterByListingType,
                    filterByPropertyName
                )
                    .then((data) => setTotalListings(data.totalListings))
                    .catch((error) => {
                        console.log("getTotalListings Error: ", error)
                    })
            })
            .catch((error) => console.log("error saving Listing ", error))
    }

    function getChipProps(params) {
        if (!isNullUndefined(params.value)) {
            if (params.value === "ACT") {
                return {
                    icon: <Check style={{fill: green[500]}}/>,
                    label: "Active",
                    style: {
                        borderColor: green[500],
                    },
                }
            } else if (params.value === "DEL") {
                return {
                    icon: <Clear style={{fill: red[500]}}/>,
                    label: "Removed",
                    style: {
                        borderColor: amber[500],
                    },
                }
            } else {
                return "N/A"
            }
        }
    }

    const editClicked = (data) => {
        setSelectedListing(data)
        setTask("Edit")
        setListingForm(true)
    }

    const viewClicked = (data) => {
        setSelectedListing(data)
        setTask("View")
        setViewForm(true)
    }

    const deleteListingClicked = (data) => {
        let listingName = data.propertyName
        setDeleteListingModal(true)
        setSelectedListing(data)
        setTask("Delete")
        let title = "Confirm Delete Listing"
        let body = "Are you sure you want to delete listing " + listingName + "?"
        setTitleText(title)
        setSeverityText("warning")
        setBodyText(body)
        setPositiveText("Delete Listing")
        setNegativeText("Cancel")
    }

    // console.log("SelectedListing", selectedListing)

    function positiveAction() {
        if (task === "Delete") {
            handleDeleteListing()
        }
    }

    function handleDeleteListing() {
        if (
            isNullUndefined(selectedListing) ||
            isNullUndefined(selectedListing.id) ||
            isEmptyString(selectedListing.id)
        ) {
            dispatch(
                setMessage({
                    type: "warning",
                    message:
                        "Could not determine the listing to remove.",
                })
            )
            return
        }

        let sendData = selectedListing.id
        deleteListing(sendData)
            .then((response) => {
                dispatch(
                    setMessage({
                        type: response.status,
                        message: response.message,
                    })
                )
                getListingsByPersonId(filterByPropertyType, filterByListingType, filterByPropertyName, pageNo, rowsPerPage)
                    .then((data) => {
                        setListings(data)
                        setTotalListings(data.length)
                    })
                    .catch((error) => {
                        console.log("getListingsByPersonId Error: ", error)
                    })
                handleCloseDeleteListingModal()
            })
            .catch((error) => console.log("error deleting listing", error))
    }

    function handleCloseDeleteListingModal() {
        setDeleteListingModal(false)
        setSelectedListing(null)
        setTitleText("")
        setTask("")
        setSeverityText("")
        setBodyText("")
        setPositiveText("")
        setNegativeText("")
    }

    const columns = [
        {headerName: "Property Name", field: 'propertyName', width: "250"},
        {
            headerName: 'Property Type', field: 'propertyType', width: "180",
            valueFormatter: (row) => {
                return row.value.name
            },
        },
        {
            headerName: 'Price', field: 'price', width: "150",
            valueFormatter: (row) => {
                return formatCurrency(row.value)
            },
        },
        {
            headerName: 'Listing Type', field: 'listingType', width: "150",
            valueFormatter: (row) => {
                return row.value.name
            },
        },
        {headerName: 'Description', field: 'description', width: "300"},
        {
            headerName: 'Status', field: 'statusCd', width: "120",
            renderCell: (params) => {
                return (
                    <Chip variant="outlined" size="small" {...getChipProps(params)} />
                )
            },
        },
        {
            headerName: 'Location', field: 'plusCode', width: "150",
            renderCell: (params) => {
                return (
                    params.value ? (
                        <a
                            target={"_blank"}
                            rel="noreferrer"
                            title={"View on Map"}
                            href={
                                "https://www.google.com.sa/maps/search/" +
                                encodeURIComponent(params.value) +
                                "?hl=en"
                            }
                        >
                            <div
                                style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                }}
                            >
                                {params.value}
                            </div>
                        </a>
                    ) : (
                        "No Location Info"
                    )
                )
            },
        },
        {
            headerName: 'Actions', field: 'actions', width: "100", type: 'actions',
            getActions: (params) => {
                // console.log("Params", params.row)
                let arr = []
                arr.push(
                    <GridActionsCellItem
                        icon={<Edit/>}
                        onClick={() => editClicked(params.row)}
                        label="Edit"
                        showInMenu
                    />
                )

                arr.push(
                    <GridActionsCellItem
                        icon={<Visibility/>}
                        onClick={() => viewClicked(params.row)}
                        label="View"
                        showInMenu
                    />
                )

                arr.push(
                    <GridActionsCellItem
                        icon={<Delete/>}
                        onClick={() => deleteListingClicked(params.row)}
                        label="Delete"
                        showInMenu
                    />
                )

                return arr
            },
        },
    ]

    return (
        <ResponsiveRow
            sx={{flexDirection: "column", width: "100%", alignItems: "center"}}
        >
            <ListingDashboardNavbar/>

            <ResponsiveRow sx={{width: {xs: "100%", lg: "80%"}, margin: "auto"}}>
                <TopBarContainer
                    sx={{
                        marginTop: "90px",
                        width: {xs: "100%", lg: "80%"},
                    }}
                    container
                >
                    <Grid
                        item
                        sx={{
                            width: {xs: "100%", lg: "70%"},
                            textAlign: {xs: "center", lg: "start"},
                        }}
                    >
                        <TextField
                            id="searchPropertyType"
                            label="Search Property Type"
                            sx={{
                                width: {xs: "100%", lg: "25%"},
                                margin: {xs: "5px 0", lg: "0 10px 0 0"},
                                fontSize: "10px",
                                textAlign: {xs: "center", lg: "flex-start"},
                            }}
                            select
                            onChange={(event) => {
                                let value = event.target.value
                                setFilterByPropertyType(value)
                            }}
                            value={filterByPropertyType}
                        >
                            {propertyTypes &&
                                propertyTypes.length > 0 &&
                                propertyTypes.map((propertyType, i) => {
                                    return (
                                        <MenuItem key={i} value={propertyType.value}>
                                            {propertyType.label}
                                        </MenuItem>
                                    )
                                })}
                        </TextField>

                        <TextField
                            id="searchListingType"
                            label="Search Listing Type"
                            sx={{
                                width: {xs: "100%", lg: "25%"},
                                margin: {xs: "5px 0", lg: "0 10px 0 0"},
                                fontSize: "10px",
                                textAlign: {xs: "center", lg: "flex-start"},
                            }}
                            select
                            onChange={(event) => {
                                let value = event.target.value
                                setFilterByListingType(value)
                            }}
                            value={filterByListingType}
                        >
                            {listingTypes &&
                                listingTypes.length > 0 &&
                                listingTypes.map((l, i) => {
                                    return (
                                        <MenuItem key={i} value={l.value}>
                                            {l.label}
                                        </MenuItem>
                                    )
                                })}
                        </TextField>

                        <TextField
                            id="searchPropertyName"
                            label="Search Property Name"
                            sx={{
                                width: {xs: "100%", lg: "25%"},
                                margin: {xs: "5px 0", lg: "0 10px 0 0"},
                                fontSize: "10px",
                            }}
                            onChange={(event) => {
                                let value = event.target.value
                                setFilterByPropertyName(value)
                            }}
                            value={filterByPropertyName}
                        />
                    </Grid>

                    <Grid
                        sx={{
                            width: {xs: "100%", lg: "30%"},
                            display: "flex",
                            flexDirection: {xs: "column", lg: "row"},
                            justifyContent: {xs: "center", lg: "flex-end"},
                        }}
                        item
                    >
                        <TopBarContainedBrandButton
                            type="button"
                            onClick={handleAddClicked}
                        >
                            Add New Listing
                        </TopBarContainedBrandButton>
                    </Grid>
                </TopBarContainer>
            </ResponsiveRow>
            <br/>

            <ResponsiveRow sx={{width: {xs: "100%", lg: "80%"}, margin: "auto"}}>
                <StyledDataGrid
                    rows={listings}
                    columns={columns}
                    pageSize={rowsPerPage}
                    loading={loading}
                    rowCount={total}
                    rowsPerPageOptions={[rowsPerPage]}
                    onPageChange={(page) => loadPage(page)}
                    checkboxSelection
                    pagination
                    paginationMode="server"
                    disableSelectionOnClick
                    onSelectionModelChange={setSelectionModel}
                    selectionModel={selectionModel}
                    autoHeight
                />
            </ResponsiveRow>
            {listingForm && (task === "Create" || task === "Edit") &&
                <ModalContainerModule
                    size="md"
                    accept={handleSubmitClicked}
                    cancel={handleCancelClicked}
                    openModal={listingForm}
                    modalTitle="Listing Details"
                    acceptButtonText={task === "Create" ? "Save" : "Update"}
                >
                    {/*<ModalInnerContainer>*/}
                    <Paper
                        // variant="outlined"
                        sx={{
                            height: "auto",
                            margin: "auto",
                            padding: "20px",
                            textAlign: "left",
                            border: 0,
                            boxShadow: "none",
                        }}
                    >
                        <Paper
                            //   variant="outlined"
                            sx={{
                                height: "auto",
                                margin: "auto",
                                padding: "10px",
                                textAlign: "left",
                                boxShadow: "none",
                            }}
                        >
                            <ResponsiveRow container>
                                {userHasAdminRole &&
                                    <Grid
                                        item
                                        sx={{
                                            width: {xs: "100%", lg: "33.3%"},
                                            marginBottom: "10px",
                                        }}
                                    >
                                        <FormGroup>
                                            <FormControlLabel control={
                                                <Checkbox
                                                    checked={unitExistsInSystem}
                                                    onChange={(event) => unitExistsCheckBox(event)}
                                                />
                                            } label="Does the Unit/Property exist in the system?"/>
                                        </FormGroup>
                                    </Grid>
                                }
                                {unitExistsInSystem &&
                                    <Grid
                                        item
                                        sx={{
                                            width: {xs: "100%", lg: "33.3%"},
                                            marginBottom: "10px",
                                        }}
                                    >
                                        <TextField
                                            variant="outlined"
                                            sx={{
                                                width: "90%",
                                                backgroundColor: "#efefef",
                                                borderRadius: "10px",
                                            }}
                                            label="Select Facility"
                                            id={"facility"}
                                            select
                                            onChange={(event) => handleSelectedFacilityChanged(event)}
                                            value={selectedFacility}
                                        >
                                            {
                                                userFacilities && userFacilities.length > 0 && userFacilities.map((facility, i) => {
                                                    return (
                                                        <MenuItem key={i} value={facility.value}
                                                                  label={facility.label}>{facility.label}</MenuItem>
                                                    )
                                                })
                                            }
                                        </TextField>
                                    </Grid>
                                }

                                {selectedFacility && unitExistsInSystem &&
                                    <Grid
                                        item
                                        sx={{
                                            width: {xs: "100%", lg: "33.3%"},
                                            marginBottom: "10px",
                                        }}
                                    >
                                        <TextField
                                            variant="outlined"
                                            sx={{
                                                width: "90%",
                                                backgroundColor: "#efefef",
                                                borderRadius: "10px",
                                            }}
                                            label="Select Unit"
                                            id={"unit"}
                                            select
                                            onChange={(event) => handleSelectedUnitChanged(event)}
                                            value={selectedUnit}
                                        >
                                            {
                                                facilityUnits && facilityUnits.length > 0 && facilityUnits.map((unit, i) => {
                                                    return (
                                                        <MenuItem key={i} value={unit.value}
                                                                  label={unit.label}>{unit.label}</MenuItem>
                                                    )
                                                })
                                            }
                                        </TextField>
                                    </Grid>
                                }
                                <Grid
                                    item
                                    sx={{
                                        width: {xs: "100%", lg: "33.3%"},
                                        marginBottom: "10px",
                                    }}
                                >
                                    <TextField
                                        variant="outlined"
                                        sx={{
                                            width: "90%",
                                            backgroundColor: "#efefef",
                                            borderRadius: "10px",
                                        }}
                                        label="Property Name"
                                        id={"propertyName"}
                                        onChange={(event) => onTextChange(event)}
                                        value={selectedListing ? selectedListing.propertyName : propertyName}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    sx={{
                                        width: {xs: "100%", lg: "33.3%"},
                                        marginBottom: "10px",
                                    }}
                                >
                                    <TextField
                                        variant="outlined"
                                        sx={{
                                            width: "90%",
                                            backgroundColor: "#efefef",
                                            borderRadius: "10px",
                                        }}
                                        label="Property Type"
                                        id={"propertyType"}
                                        select
                                        onChange={(event) => handlePropertyTypeChange(event)}
                                        value={selectedListing ? selectedListing.propertyTypeCd : selectedPropertyType}
                                    >
                                        {
                                            propertyTypes && propertyTypes.length > 0 && propertyTypes.map((propertyType, i) => {
                                                return (
                                                    <MenuItem key={i} value={propertyType.value}
                                                              label={propertyType.label}>{propertyType.label}</MenuItem>
                                                )
                                            })
                                        }
                                    </TextField>
                                </Grid>
                                <Grid
                                    item
                                    sx={{
                                        width: {xs: "100%", lg: "33.3%"},
                                        marginBottom: "10px",
                                    }}
                                >
                                    <TextField
                                        variant="outlined"
                                        sx={{
                                            width: "90%",
                                            backgroundColor: "#efefef",
                                            borderRadius: "10px",
                                        }}
                                        label="Price"
                                        id={"price"}
                                        onChange={(event) => onTextChange(event)}
                                        value={selectedListing ? selectedListing.price : price}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    sx={{
                                        width: {xs: "100%", lg: "33.3%"},
                                        marginBottom: "10px",
                                    }}
                                >
                                    <TextField
                                        variant="outlined"
                                        sx={{
                                            width: "90%",
                                            backgroundColor: "#efefef",
                                            borderRadius: "10px",
                                        }}
                                        label="Listing Type"
                                        id={"listingType"}
                                        select
                                        onChange={(event) => handleListingTypeChange(event)}
                                        value={selectedListing ? selectedListing.listingTypeCd : selectedListingType}
                                    >
                                        {
                                            listingTypes && listingTypes.length > 0 && listingTypes.map((l, i) => {
                                                return (
                                                    <MenuItem key={i} value={l.value}
                                                              label={l.label}>{l.label}</MenuItem>
                                                )
                                            })
                                        }
                                    </TextField>
                                </Grid>
                                <Grid
                                    item
                                    sx={{
                                        width: {xs: "100%", lg: "33.3%"},
                                        marginBottom: "10px",
                                    }}
                                >
                                    <TextField
                                        variant="outlined"
                                        sx={{
                                            width: "90%",
                                            backgroundColor: "#efefef",
                                            borderRadius: "10px",
                                        }}
                                        label="Latitude"
                                        id={"latitude"}
                                        onChange={(event) => onTextChange(event)}
                                        value={selectedListing ? selectedListing.latitude : latitude}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    sx={{
                                        width: {xs: "100%", lg: "33.3%"},
                                        marginBottom: "10px",
                                    }}
                                >
                                    <TextField
                                        variant="outlined"
                                        sx={{
                                            width: "90%",
                                            backgroundColor: "#efefef",
                                            borderRadius: "10px",
                                        }}
                                        label="Longitude"
                                        id={"longitude"}
                                        onChange={(event) => onTextChange(event)}
                                        value={selectedListing ? selectedListing.longitude : longitude}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    sx={{
                                        width: {xs: "100%", lg: "33.3%"},
                                        marginBottom: "10px",
                                    }}
                                >
                                    <TextField
                                        variant="outlined"
                                        sx={{
                                            width: "90%",
                                            backgroundColor: "#efefef",
                                            borderRadius: "10px",
                                        }}
                                        label="Square Feet"
                                        id={"squareFeet"}
                                        onChange={(event) => onTextChange(event)}
                                        value={selectedListing ? selectedListing.sqft : sqft}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    sx={{
                                        width: {xs: "100%", lg: "33.3%"},
                                        marginBottom: "10px",
                                    }}
                                >
                                    <TextField
                                        variant="outlined"
                                        sx={{
                                            width: "90%",
                                            backgroundColor: "#efefef",
                                            borderRadius: "10px",
                                        }}
                                        label="Beds"
                                        id={"beds"}
                                        onChange={(event) => onTextChange(event)}
                                        value={selectedListing ? selectedListing.beds : beds}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    sx={{
                                        width: {xs: "100%", lg: "33.3%"},
                                        marginBottom: "10px",
                                    }}
                                >
                                    <TextField
                                        variant="outlined"
                                        sx={{
                                            width: "90%",
                                            backgroundColor: "#efefef",
                                            borderRadius: "10px",
                                        }}
                                        label="Baths"
                                        id={"baths"}
                                        onChange={(event) => onTextChange(event)}
                                        value={selectedListing ? selectedListing.baths : baths}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    sx={{
                                        width: {xs: "100%", lg: "55%"},
                                        marginBottom: "10px",
                                    }}
                                >
                                    <TextField
                                        variant="outlined"
                                        sx={{
                                            width: "90%",
                                            backgroundColor: "#efefef",
                                            borderRadius: "10px",
                                        }}
                                        label="Description"
                                        id={"description"}
                                        multiline
                                        rows={4}
                                        onChange={(event) => onTextChange(event)}
                                        value={selectedListing ? selectedListing.description : description}
                                    />
                                </Grid>

                                
                                <Grid
                                    item
                                    sx={{
                                        width: {xs: "100%", lg: "97%"},
                                        marginBottom: "10px",
                                    }}
                                >
                                    <FileUpload value={files} onChange={setFiles}/>
                                </Grid>
                                
                            </ResponsiveRow>
                        </Paper>
                    </Paper>
                    {/*</ModalInnerContainer>*/}
                </ModalContainerModule>
            }

            {task === "View" &&
                <ModalContainer
                    open={viewForm}
                    onClose={handleCloseView}
                    sx={{padding: "10px 20px", boxShadow: "none"}}
                >
                    <ModalInnerContainer>
                        <Paper
                            // variant="outlined"
                            sx={{
                                height: "auto",
                                margin: "auto",
                                padding: "20px",
                                textAlign: "left",
                                border: 0,
                                boxShadow: "none",
                            }}
                        >
                            <ResponsiveRow sx={{bgColor: "red", width: "100%", display: "flex", justifyContent: "space-between", alignItems: 'center'}}>
                                <Box
                                    sx={{marginBottom: "10px", fontSize: 'h4.fontSize', bgColor: "error.main"}}
                                    id="addListing"
                                    variant="h4"
                                    component="h6"
                                >
                                    Listing Details
                                </Box>
                                <CloseIcon
                                    onClick={handleCloseView}
                                    sx={{
                                        borderRadius: '50%',
                                        ':hover': {
                                          bgcolor: 'error.main',
                                          color: 'white',
                                          curson: 'pointer'
                                        },
                                      }}
                                ></CloseIcon>
                            </ResponsiveRow>
                            <Divider/>
                            <Paper
                                //   variant="outlined"
                                sx={{
                                    height: "auto",
                                    margin: "auto",
                                    padding: "10px",
                                    textAlign: "left",
                                    boxShadow: "none",
                                }}
                            >
                                <ResponsiveRow container>
                                    <DetailsContainer sx={{width: "100%"}}>
                                        <DetailsColumn
                                            sx={{
                                                width: {lg: "33.3%", md: "100%", xs: "100%"},
                                            }}
                                        >
                                            <DetailsRow>
                                                <DetailsLabel>Property Name:</DetailsLabel>
                                                <DetailsText>
                                                    {selectedListing && selectedListing.propertyName}
                                                </DetailsText>
                                            </DetailsRow>
                                            <DetailsRow>
                                                <DetailsLabel>Listing Type:</DetailsLabel>
                                                <DetailsText>
                                                    {selectedListing && selectedListing.listingTypeCd}
                                                </DetailsText>
                                            </DetailsRow>
                                            <DetailsRow>
                                                <DetailsLabel>Sqft:</DetailsLabel>
                                                <DetailsText>
                                                    {selectedListing && selectedListing.sqft}
                                                </DetailsText>
                                            </DetailsRow>
                                            <DetailsRow>
                                                <DetailsLabel>Longitude:</DetailsLabel>
                                                <DetailsText>
                                                    {selectedListing && selectedListing.longitude}
                                                </DetailsText>
                                            </DetailsRow>
                                            <DetailsRow>
                                                <DetailsLabel>Description:</DetailsLabel>
                                                <DetailsText>
                                                    {selectedListing && selectedListing.description}
                                                </DetailsText>
                                            </DetailsRow>
                                        </DetailsColumn>
                                        <DetailsColumn
                                            sx={{
                                                width: {lg: "33.3%", md: "100%", xs: "100%"},
                                            }}
                                        >
                                            <DetailsRow>
                                                <DetailsLabel>Property Type:</DetailsLabel>
                                                <DetailsText>
                                                    {selectedListing && selectedListing.propertyTypeCd}
                                                </DetailsText>
                                            </DetailsRow>
                                            <DetailsRow>
                                                <DetailsLabel>Beds:</DetailsLabel>
                                                <DetailsText>
                                                    {selectedListing && selectedListing.beds}
                                                </DetailsText>
                                            </DetailsRow>
                                            <DetailsRow>
                                                <DetailsLabel>Baths:</DetailsLabel>
                                                <DetailsText>
                                                    {selectedListing && selectedListing.baths}
                                                </DetailsText>
                                            </DetailsRow>
                                            <DetailsRow>
                                                <DetailsLabel>Latitude:</DetailsLabel>
                                                <DetailsText>
                                                    {selectedListing && selectedListing.latitude}
                                                </DetailsText>
                                            </DetailsRow>
                                            <DetailsRow>
                                                <DetailsLabel>Price:</DetailsLabel>
                                                <DetailsText>
                                                    {selectedListing && selectedListing.price}
                                                </DetailsText>
                                            </DetailsRow>
                                        </DetailsColumn>
                                        <DetailsColumn
                                            sx={{
                                                width: {lg: "33.3%", md: "100%", xs: "100%"},
                                            }}
                                        >
                                            {selectedListing &&
                                                <DocumentStore
                                                    files={[
                                                        ...selectedListing.imageFiles,
                                                        ...selectedListing.otherDocuments,
                                                    ]}
                                                    item={selectedListing}
                                                    type={"Listing"}
                                                    height="500px"
                                                />
                                            }
                                        </DetailsColumn>
                                    </DetailsContainer>
                                </ResponsiveRow>
                            </Paper>
                        </Paper>
                    </ModalInnerContainer>
                </ModalContainer>
            }

            <ConfirmationModal
                showConfirmationModal={deleteListingModal}
                handleConfirmationClose={handleCloseDeleteListingModal}
                title={titleText}
                severity={severityText}
                body={bodyText}
                positiveText={positiveText}
                negativeText={negativeText}
                positiveAction={positiveAction}
                negativeAction={handleCloseDeleteListingModal}
            />

        </ResponsiveRow>
    )
}
