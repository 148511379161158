import {
  FormControl,
  InputLabel,
  OutlinedInput,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { ModalAcceptButton } from "../../../constants/component.constants";
import { ResponsiveRow } from "../../../constants/layout.constants";
import ModalContainerModule from "../../modules/modalContainer";

export default function AddSubscriptionServiceModal(props) {
  /**
   *
   * REQUIRED PROPS
   *
   * subscriptionServiceForm
   *
   * handleCancelClicked
   * handleItemAdd
   * handleSaveSubscriptionService
   *
   * setDescription
   * setServiceName
   * setSelectedService
   * setQtyCode
   * serviceName
   * setCode
   *
   * code
   * description
   * rows
   * selectedService
   * task
   * qtyCode
   *
   */

  return (
    <ModalContainerModule
      size="sm"
      accept={props.handleSaveSubscriptionService}
      cancel={props.handleCancelClicked}
      openModal={props.subscriptionServiceForm}
      modalTitle="Update Service"
      acceptButtonText={props.task === "Edit" ? "Update" : "Save"}
    >
      <ResponsiveRow sx={{ height: "auto" }} container>
        <FormControl
          item
          sx={{ width: { xs: "100%", lg: "33.3%" }, marginBottom: "10px" }}
        >
          <InputLabel>Service Name *</InputLabel>
          <OutlinedInput
            label="Service Name *"
            placeholder={"serviceName"}
            sx={{ width: "100%" }}
            id={"serviceName"}
            required={true}
            disabled={props.task === "Edit" ? true : false}
            onChange={(event) => {
              const value = event.target.value;
              props.setServiceName(value);
              let obj = props.selectedService;
              obj.name = value;
              props.setSelectedService(obj);
            }}
            value={
              props.selectedService
                ? props.selectedService.name
                : props.serviceName
            }
          />
        </FormControl>

        <FormControl
          item
          sx={{ width: { xs: "100%", lg: "33.3%" }, marginBottom: "10px" }}
        >
          <InputLabel>Code *</InputLabel>
          <OutlinedInput
            label="Code *"
            placeholder={"serviceCode"}
            sx={{ width: "100%" }}
            id={"serviceCode"}
            required={true}
            disabled={props.task === "Edit" ? true : false}
            onChange={(event) => {
              const value = event.target.value;
              props.setCode(value);
              let obj = props.selectedService;
              obj.code = value;
              props.setSelectedService(obj);
            }}
            value={
              props.selectedService ? props.selectedService.code : props.code
            }
          />
        </FormControl>

        <FormControl
          item
          sx={{ width: { xs: "100%", lg: "33.3%" }, marginBottom: "10px" }}
        >
          <InputLabel>Quantity Code *</InputLabel>
          <OutlinedInput
            label="Quantity Code *"
            placeholder={"Quantity Code"}
            sx={{ width: "100%" }}
            id={"quantityCode"}
            required={true}
            disabled={props.task === "Edit" ? true : false}
            onChange={(event) => {
              const value = event.target.value;
              props.setQtyCode(value);
              let obj = props.selectedService;
              obj.quantityCode = value;
              props.setSelectedService(obj);
            }}
            value={
              props.selectedService
                ? props.selectedService.quantityCode
                : props.qtyCode
            }
          />
        </FormControl>

        <FormControl
          item
          sx={{ width: { xs: "100%", lg: "33.3%" }, marginBottom: "10px" }}
        >
          <InputLabel>Description *</InputLabel>
          <OutlinedInput
            label="Description *"
            placeholder={"description"}
            sx={{ width: "100%" }}
            id={"description"}
            required={true}
            onChange={(event) => {
              const value = event.target.value;
              props.setDescription(value);
              let obj = props.selectedService;
              obj.description = value;
              props.setSelectedService(obj);
            }}
            value={
              props.selectedService
                ? props.selectedService.description
                : props.description
            }
          />
        </FormControl>

        <ResponsiveRow sx={{ width: "100%" }}>
          <Typography variant="h6">Service Rates</Typography>
          <TableContainer style={{ maxHeight: 350 }}>
            <Table sx={{ minWidth: 20 }} aria-label="simple table" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: "18%" }}>Min</TableCell>
                  <TableCell sx={{ width: "18%" }}>Max</TableCell>
                  <TableCell sx={{ width: "18%" }}>Unit Price</TableCell>
                  <TableCell sx={{ width: "18%" }}>V.A.T</TableCell>
                  <TableCell sx={{ width: "18%" }}>W.T.H</TableCell>
                  <TableCell sx={{ width: "10%" }}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{props.rows}</TableBody>
            </Table>
          </TableContainer>
        </ResponsiveRow>
        <ResponsiveRow
          sx={{
            width: "100%",
            justifyContent: { xs: "center", lg: "flex-end" },
          }}
        >
          <ModalAcceptButton
            variant="contained"
            type="submit"
            onClick={props.handleItemAdd}
            sx={{
              width: { xs: "100%", lg: "200px" },
            }}
          >
            Add Row
          </ModalAcceptButton>
        </ResponsiveRow>
      </ResponsiveRow>
    </ModalContainerModule>
  );
}
