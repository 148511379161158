import {Typography} from "@mui/material";
import {
    DetailsCardContainer,
    DetailsColumn,
    DetailsContainer,
    DetailsLabel,
    DetailsRow,
    DetailsText,
} from "../../constants/component.constants";
import {formatDate, formatDateTime, isNullUndefined} from "../../util/util";

export default function SignUpInfoCard(props) {
    let currentStepName = "UNKNOWN"
    if (!isNullUndefined(props) && !isNullUndefined(props.selectedSignUp) &&
        !isNullUndefined(props.selectedSignUp.signUpJson)) {
        if (!isNullUndefined(props.selectedSignUp.signUpJson.currentStep)) {
            const currentStep = props.selectedSignUp.signUpJson.currentStep
            if (currentStep === 0) {
                currentStepName = "Personal Details"
            } else if (currentStep === 1) {
                currentStepName = "Property Details"
            } else if (currentStep === 2) {
                currentStepName = "Units Details"
            } else if (currentStep === 3) {
                currentStepName = "Utilities Details"
            } else if (currentStep === 4) {
                currentStepName = "Resident Details"
            } else if (currentStep === 5) {
                currentStepName = "Review Details"
            } else if (currentStep === 6) {
                currentStepName = "Completed"
            }
        }
    }
    return (
        <DetailsCardContainer container spacing={1}>
            <DetailsRow>
                <Typography
                    sx={{
                        fontWeight: 800,
                        fontSize: "18px",
                    }}
                >
                    SignUp Information for{" "}
                    {props.selectedSignUp && props.selectedSignUp.personName}
                </Typography>
            </DetailsRow>

            <DetailsContainer sx={{width: "100%"}}>
                <DetailsColumn
                    sx={{
                        width: {lg: "33.3%", md: "100%", xs: "100%"},
                    }}
                >
                    <Typography
                        sx={{
                            fontWeight: 800,
                            fontSize: "16px",
                            textAlign: "start",
                            color: "#037171",
                            marginBottom: "20px",
                        }}
                    >
                        Personal Information
                    </Typography>

                    <DetailsRow>
                        <DetailsLabel>Person Name</DetailsLabel>
                        <DetailsText>
                            {props.selectedSignUp && props.selectedSignUp.personName}
                        </DetailsText>
                    </DetailsRow>
                    <DetailsRow>
                        <DetailsLabel>Phone Number</DetailsLabel>
                        <DetailsText>
                            {props.selectedSignUp && props.selectedSignUp.personPhone}
                        </DetailsText>
                    </DetailsRow>
                    <DetailsRow>
                        <DetailsLabel>Email</DetailsLabel>
                        <DetailsText>
                            {props.selectedSignUp && props.selectedSignUp.personEmail}
                        </DetailsText>
                    </DetailsRow>
                    <DetailsRow>
                        <DetailsLabel>Id Number</DetailsLabel>
                        <DetailsText>
                            {props.selectedSignUp && props.selectedSignUp.person && props.selectedSignUp.person.idNumber}
                        </DetailsText>
                    </DetailsRow>
                </DetailsColumn>
                <DetailsColumn
                    sx={{
                        width: {lg: "33.3%", md: "100%", xs: "100%"},
                    }}
                >
                    <Typography
                        sx={{
                            fontWeight: 800,
                            fontSize: "16px",
                            textAlign: "start",
                            color: "#037171",
                            marginBottom: "20px",
                        }}
                    >
                        SignUp Status
                    </Typography>
                    <DetailsRow>
                        <DetailsLabel>Current Step</DetailsLabel>
                        <DetailsText>
                            {currentStepName}
                        </DetailsText>
                    </DetailsRow>
                    <DetailsRow>
                        <DetailsLabel>Date Created</DetailsLabel>
                        <DetailsText>
                            {props.selectedSignUp && props.selectedSignUp.dateCreated && formatDate(props.selectedSignUp.dateCreated)}
                        </DetailsText>
                    </DetailsRow>
                    <DetailsRow>
                        <DetailsLabel>Last Modified</DetailsLabel>
                        <DetailsText>
                            {props.selectedSignUp && props.selectedSignUp.lastModified && formatDateTime(props.selectedSignUp.lastModified)}
                        </DetailsText>
                    </DetailsRow>
                    <DetailsRow>
                        <DetailsLabel>Managed By Organization</DetailsLabel>
                        <DetailsText>
                            {props.selectedSignUp && props.selectedSignUp.signUpJson && props.selectedSignUp.signUpJson.isPropertyManagedByOrganization ? "Yes" : "No"}
                        </DetailsText>
                    </DetailsRow>
                </DetailsColumn>
                <DetailsColumn
                    sx={{
                        width: {lg: "33.3%", md: "100%", xs: "100%"},
                    }}
                >
                    <Typography
                        sx={{
                            fontWeight: 800,
                            fontSize: "16px",
                            textAlign: "start",
                            color: "#037171",
                            marginBottom: "20px",
                        }}
                    >
                        Property Information
                    </Typography>
                    <DetailsRow>
                        <DetailsLabel>Property Name</DetailsLabel>
                        <DetailsText>
                            {props.selectedSignUp && props.selectedSignUp.signUpJson &&
                                props.selectedSignUp.signUpJson.facility && props.selectedSignUp.signUpJson.facility.name}
                        </DetailsText>
                    </DetailsRow>
                    <DetailsRow>
                        <DetailsLabel>Property Address</DetailsLabel>
                        <DetailsText>
                            {props.selectedSignUp && props.selectedSignUp.signUpJson &&
                                props.selectedSignUp.signUpJson.facility && props.selectedSignUp.signUpJson.facility.address &&
                                props.selectedSignUp.signUpJson.facility.address.address1 + ", " + props.selectedSignUp.signUpJson.facility.address.city}
                        </DetailsText>
                    </DetailsRow>
                    <DetailsRow>
                        <DetailsLabel>Organization Name</DetailsLabel>
                        <DetailsText>
                            {props.selectedSignUp && props.selectedSignUp.signUpJson &&
                                props.selectedSignUp.signUpJson.organization && props.selectedSignUp.signUpJson.organization.name}
                        </DetailsText>
                    </DetailsRow>
                    <DetailsRow>
                        <DetailsLabel>Organization Address</DetailsLabel>
                        <DetailsText>
                            {props.selectedSignUp && props.selectedSignUp.signUpJson &&
                                props.selectedSignUp.signUpJson.organization && props.selectedSignUp.signUpJson.organization.address &&
                                props.selectedSignUp.signUpJson.organization.address.address1 + ", " + props.selectedSignUp.signUpJson.organization.address.city}
                        </DetailsText>
                    </DetailsRow>
                </DetailsColumn>
            </DetailsContainer>
        </DetailsCardContainer>
    );
}
