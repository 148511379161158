import {
    ADD_WORK_ORDER_CHARGE_URL,
    ADD_WORK_ORDER_VENDOR_URL,
    APPROVE_WORK_ORDER_DETAIL_URL,
    APPROVE_WORK_ORDER_URL,
    BULK_APPROVE_WORK_ORDER_URL,
    BULK_REJECT_WORK_ORDER_URL,
    COMPLETE_WORK_ORDER_URL,
    CREATE_BILL_PAYMENT_URL,
    DELETE_WORK_ORDER_DOC_URL,
    DELETE_WORK_ORDER_URL,
    FETCH_PERSON_URL,
    FETCH_WORK_ORDER_URL,
    LIST_WORK_ORDERS_URL,
    REJECT_WORK_ORDER_URL,
    REMOVE_WORK_ORDER_DETAIL_URL,
    SAVE_WORK_ORDER_NOTE_URL,
    SAVE_WORK_ORDER_URL,
    TOTAL_WORK_ORDERS_URL,
    UPLOAD_WORK_ORDER_DOCUMENT,
    WORK_ORDER_NOTES_URL
} from "../constants/url.constants"
import {serviceAPI} from "./service"
import {isNullUndefined, isValidDate} from "../util/util"
import {handleErrorResponse} from "../actions/message"

//GET REQUEST

export const getWorkOrders = (status, reference, requestedBy, description, approvedBy, dateApproved, dateRequested, pageNo, rowsPerPage) => {
    let dateApp = dateApproved
    let dateReq = dateRequested
    if (!isNullUndefined(dateApproved) && isValidDate(dateApproved)) {
        let date = new Date(dateApproved)
        dateApp = date.toISOString()
    }
    if (!isNullUndefined(dateRequested) && isValidDate(dateRequested)) {
        let date = new Date(dateRequested)
        dateReq = date.toISOString()
    }
    let url = LIST_WORK_ORDERS_URL + status + "&reference=" + reference + "&requestedBy=" + requestedBy +
        "&description=" + description + "&approvedBy=" + approvedBy +
        "&dateApproved=" + dateApp + "&dateRequested=" + dateReq + "&pageNo=" + pageNo + "&rowsPerPage=" + rowsPerPage
    return serviceAPI.get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getTotalWorkOrders = (status, reference, requestedBy, description, approvedBy, dateApproved, dateRequested) => {
    let dateApp = dateApproved
    let dateReq = dateRequested
    if (!isNullUndefined(dateApproved) && isValidDate(dateApproved)) {
        let date = new Date(dateApproved)
        dateApp = date.toISOString()
    }
    if (!isNullUndefined(dateRequested) && isValidDate(dateRequested)) {
        let date = new Date(dateRequested)
        dateReq = date.toISOString()
    }
    let url = TOTAL_WORK_ORDERS_URL + status + "&reference=" + reference + "&requestedBy=" + requestedBy +
        "&description=" + description + "&approvedBy=" + approvedBy +
        "&dateApproved=" + dateApp + "&dateRequested=" + dateReq
    return serviceAPI.get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const getWorkOrderNotes = (id) => {
    let url = WORK_ORDER_NOTES_URL + id
    return serviceAPI.get(url)
        .then((response) => {
            return response.data.notes
        })
}

export const fetchWorkOrder = (workOrderId) => {
    let url = FETCH_WORK_ORDER_URL + workOrderId
    return serviceAPI.get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const fetchPerson = (personId) => {
    let url = FETCH_PERSON_URL + personId
    return serviceAPI.get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const removeWorkOrderCharge = (workOrderDetailId) => {
    let url = REMOVE_WORK_ORDER_DETAIL_URL + workOrderDetailId
    return serviceAPI.get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const approveWorkOrderCharge = (workOrderDetailId) => {
    let url = APPROVE_WORK_ORDER_DETAIL_URL + workOrderDetailId
    return serviceAPI.get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}


//POST REQUEST
export const saveWorkOrderNote = (data) => {
    return serviceAPI.post(SAVE_WORK_ORDER_NOTE_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export function uploadWorkOrderDocument(data, id) {
    let url = UPLOAD_WORK_ORDER_DOCUMENT + id
    return serviceAPI
        .post(url, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export function completeWorkOrder(data, id) {
    return serviceAPI
        .post(COMPLETE_WORK_ORDER_URL + id, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export function rejectWorkOrderData(data) {
    return serviceAPI
        .post(REJECT_WORK_ORDER_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export function bulkRejectWorkOrderData(data) {
    return serviceAPI
        .post(BULK_REJECT_WORK_ORDER_URL, data)
        .then((response) => {
            return response.data
        })
}

export function approveWorkOrderData(data) {
    return serviceAPI
        .post(APPROVE_WORK_ORDER_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export function bulkApproveWorkOrderData(data) {
    return serviceAPI
        .post(BULK_APPROVE_WORK_ORDER_URL, data)
        .then((response) => {
            return response.data
        })
}

export function deleteWorkOrderData(data) {
    return serviceAPI
        .post(DELETE_WORK_ORDER_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export function createBillPaymentData(data) {
    return serviceAPI
        .post(CREATE_BILL_PAYMENT_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export function addWorkOrderChargeData(data) {
    return serviceAPI
        .post(ADD_WORK_ORDER_CHARGE_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export function addWorkOrderVendorData(data) {
    return serviceAPI
        .post(ADD_WORK_ORDER_VENDOR_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export function saveWorkOrderData(data) {
    return serviceAPI
        .post(SAVE_WORK_ORDER_URL, data)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}

export const deleteWorkOrderDoc = (documentId) => {
    let url = DELETE_WORK_ORDER_DOC_URL + documentId
    return serviceAPI.get(url)
        .then((response) => {
            return response.data
        }).catch(error => {
            handleErrorResponse(error)
        })
}
