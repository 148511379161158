/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { useCallback, useEffect, useState } from "react";
import {
  listJournalEntries,
  reverseJournalEntry,
  saveJournalEntry,
} from "../services/journalEntries.service";
import {
  formatDate,
  isArray,
  isEmptyArray,
  isEmptyString,
  isNullUndefined,
  tryParseFloat,
} from "../util/util";
import {
  TopBarContainedBrandButton,
  TopBarContainer,
} from "../constants/component.constants";
import { Cancel, History, Visibility } from "@mui/icons-material";
import { issueResponseMessage, issueWarnMessage } from "../actions/message";
import { useSelector } from "react-redux";
import {
  allUnitOptions,
  getActiveComplexForOrgOptions,
  getActiveLeases,
  getAllAccounts,
  getAssetOptions,
  getProxyFacilityForUserOptions,
} from "../services/list.service";
import CreateJournalEntry from "../components/modals/accounting/journalentries/createJournalEntry";
import ViewJournalModal from "../components/modals/accounting/journalentries/viewJournalModal";
import ReverseJournalEntryModal from "../components/modals/accounting/journalentries/reverseJournalEntryModal";
import { ResponsiveRow } from "../constants/layout.constants";
import Permit from "../util/permit";
import MuiDataGrid from "../components/modules/MuiDataGrid";

export default function JournalEntries() {
  const [loading, setLoading] = useState(false);
  const [entries, setEntries] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [facilityOptions, setFacilityOptions] = useState([]);
  const [unitOptions, setUnitOptions] = useState([]);
  const [complexOptions, setComplexOptions] = useState([]);
  const [assetOptions, setAssetOptions] = useState([]);
  const [leaseOptions, setLeaseOptions] = useState([]);
  const [enteredBy] = useState("");
  const [AddDiv, setAddDiv] = useState(false);
  const [ViewDiv, setViewDiv] = useState(false);
  const [ReverseModal, setReverseModal] = useState(false);
  const [selectedEntry, setSelectedEntry] = useState(null);
  const [items, setItems] = useState([]);
  const [tieredItemsTableRows, setTieredItemsTableRows] = useState("");
  const [journalItems, setJournalItems] = useState([]);
  const [date, setDate] = useState("");
  const [memo, setMemo] = useState("");
  const [property, setProperty] = useState("");
  const [unit, setUnit] = useState("");
  const [complex, setComplex] = useState("");
  const [asset, setAsset] = useState("");
  const [lease, setLease] = useState("");
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    setLoading(true);
    const promises = [
      getAllAccounts(),
      getActiveLeases(user.proxyFacility ?? ""),
      allUnitOptions(user.proxyFacility ?? ""),
      getProxyFacilityForUserOptions(),
      getActiveComplexForOrgOptions(),
      getAssetOptions(),
      listJournalEntries(enteredBy),
    ];

    Promise.all(promises).then(([accounts, leaseOptions, unitOptions, facilityOptions, complexOptions, assetOptions, entries]) => {
      setAccounts(accounts);
      setLeaseOptions(leaseOptions);
      setUnitOptions(unitOptions);
      setFacilityOptions(facilityOptions);
      setComplexOptions(complexOptions);
      setAssetOptions(assetOptions);
      setEntries(entries);
      setLoading(false);
    });

  }, [enteredBy, user.proxyFacility]);

  useEffect(() => {
    getActiveLeases(property).then((leaseOptions) => {
      setLeaseOptions(leaseOptions);
    });

    allUnitOptions(property).then((unitOptions) => {
      setUnitOptions(unitOptions);
    });
  }, [property]);

  const getRowClassName = (params) => {
    const description = params.row?.description || "";
    if (description.includes("Reversed")) {
      return "voided";
    }
    return "";
  };

  function handleItemRemove(index) {
    let newArr = [...items]; // copying the old items array
    if (newArr.length > 1) {
      newArr.splice(index, 1);
      setItems(newArr);
      setJournalItems(items);
    } else {
      issueWarnMessage("There must be at least one transaction item");
    }
  }

  function handleTieredItemAdd() {
    setItems((items) => [
      ...items,
      { debit: null, credit: null, description: null, amount: null },
    ]);
    setJournalItems((journalItems) => [
      ...journalItems,
      { debit: null, credit: null, description: null, amount: null },
    ]);
  }

  function handleDebitChange(event, index) {
    const value = event.target.value;
    let newArr = [...items]; // copying the old items array
    newArr[index].debit = value;
    setItems(newArr);
    populateTieredItems();
  }

  function handleCreditChange(event, index) {
    const value = event.target.value;
    let newArr = [...items]; // copying the old items array
    newArr[index].credit = value;
    setItems(newArr);
    populateTieredItems();
  }

  function handleDescriptionChange(event, index) {
    const value = event.target.value;
    let newArr = [...items]; // copying the old items array
    newArr[index].description = value;
    setItems(newArr);
    populateTieredItems();
  }

  function handleAmountChange(event, index) {
    const value = event.target.value;
    let newArr = [...items]; // copying the old items array
    newArr[index].amount = value;
    setItems(newArr);
    populateTieredItems();
  }

  const populateTieredItems = useCallback(() => {
    let rows = "";
    items &&
      items.length > 0 &&
      (rows = items.map(function (meterItem, i) {
        return (
          <TableRow key={i}>
            <TableCell>
              <Select
                sx={{ width: { xs: "100%", md: "90%" } }}
                id={"debit"}
                value={items && items[i] && items[i].debit}
                onChange={(event) => handleDebitChange(event, i)}
              >
                {accounts && accounts.length > 0 ? (
                  accounts.map(function (account, i) {
                    return (
                      <MenuItem key={account.value} value={account.value}>
                        {account.label}
                      </MenuItem>
                    );
                  }, this)
                ) : (
                  <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
                )}
              </Select>
            </TableCell>
            <TableCell>
              <Select
                sx={{ width: { xs: "100%", md: "90%" } }}
                id={"credit"}
                value={items && items[i] && items[i].credit}
                onChange={(event) => handleCreditChange(event, i)}
              >
                {accounts && accounts.length > 0 ? (
                  accounts.map(function (account, i) {
                    return (
                      <MenuItem key={account.value} value={account.value}>
                        {account.label}
                      </MenuItem>
                    );
                  }, this)
                ) : (
                  <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
                )}
              </Select>
            </TableCell>
            <TableCell>
              <TextField
                sx={{ width: "90%" }}
                id={"description" + i}
                name={"description" + i}
                isrequired={true}
                value={items && items[i] && items[i].description}
                onChange={(event) => handleDescriptionChange(event, i)}
              />
            </TableCell>
            <TableCell>
              <TextField
                sx={{ width: "90%" }}
                id={"amount" + i}
                name={"amount" + i}
                isrequired={true}
                value={items && items[i] && items[i].amount}
                onChange={(event) => handleAmountChange(event, i)}
              />
            </TableCell>
            <TableCell>
              <Button
                variant="contained"
                type="submit"
                sx={{ marginLeft: "10px" }}
                onClick={(event) => handleItemRemove(i)}
                color="error"
              >
                <Cancel />
              </Button>
            </TableCell>
          </TableRow>
        );
      }, this));
    setTieredItemsTableRows(rows);
  }, [items, accounts, handleDebitChange, handleCreditChange, handleDescriptionChange, handleAmountChange, handleItemRemove]);

  function handleViewClick(journalEntry) {
    setSelectedEntry(journalEntry);
    setViewDiv(true);
  }

  function handleReverseClick(journalEntry) {
    setSelectedEntry(journalEntry);
    setReverseModal(true);
  }

  function handleAddClick() {
    setAddDiv(true);
    let facilityId = "";
    if (user.proxyFacility != null) {
      facilityId = user.proxyFacility;
      setProperty(facilityId);
    }
    let data = {
      id: null,
      postingDate: "",
      description: "",
      facility: {
        id: facilityId,
      },
      unit: {
        id: null,
      },
      complex: {
        id: null,
      },
      asset: {
        id: null,
      },
      lease: {
        id: null,
      },
      title: {
        id: null,
      },
      meterBilling: {
        id: null,
      },
      meter: {
        id: null,
      },
      transactionDetailItems: [],
    };
    setSelectedEntry(data);
  }

  function closeAddModal() {
    setAddDiv(false);
    setSelectedEntry(null);
    setItems([]);
    setJournalItems([]);
  }

  function handleDateChange(value) {
    let date = new Date(value);
    setDate(date);
    let obj = selectedEntry;
    obj.postingDate = date;
    setSelectedEntry(obj);
  }

  function handlePropertyChange(event) {
    let value = event.target.value;
    setProperty(value);
    let obj = selectedEntry;
    obj.facility.id = value;
    setSelectedEntry(obj);
  }

  function handleUnitChange(event) {
    let value = event.target.value;
    setUnit(value);
    let obj = selectedEntry;
    obj.unit.id = value;
    setSelectedEntry(obj);
  }

  function handleComplexChange(event) {
    let value = event.target.value;
    setComplex(value);
    let obj = selectedEntry;
    obj.complex.id = value;
    setSelectedEntry(obj);
  }

  function handleAssetChange(event) {
    let value = event.target.value;
    setAsset(value);
    let obj = selectedEntry;
    obj.asset.id = value;
    setSelectedEntry(obj);
  }

  function handleLeaseChange(event) {
    let value = event.target.value;
    setLease(value);
    let obj = selectedEntry;
    obj.lease.id = value;
    setSelectedEntry(obj);
  }

  function reverseEntry() {
    reverseJournalEntry(selectedEntry.id).then((response) => {
      issueResponseMessage(response);
      listJournalEntries(enteredBy).then((entries) => setEntries(entries));
      setSelectedEntry(null);
      setReverseModal(false);
    });
  }

  function saveEntry() {
    if (isEmptyString(selectedEntry.postingDate)) {
      issueWarnMessage("The Journal entry must have a date!");
      return false;
    }
    if (isEmptyString(selectedEntry.description)) {
      issueWarnMessage("The Journal entry must have a description!");
      return false;
    }
    if (isEmptyArray(items)) {
      issueWarnMessage("There must be at least 1 transaction detail item!");
      return false;
    }

    if (isArray(items)) {
      for (let item of items) {
        if (isNullUndefined(item.amount) || isEmptyString(item.amount)) {
          issueWarnMessage("Amount is invalid. Must be more than 0");
          return false;
        }
        let amount = tryParseFloat(item.amount.toString(), -1);
        if (amount < 1) {
          let message =
            "Amount '" + item.amount + "' is invalid. Must be more than 0";
          issueWarnMessage(message);
          return false;
        }
        if (
          isNullUndefined(item.debit) ||
          isEmptyString(item.debit) ||
          isNullUndefined(item.credit) ||
          isEmptyString(item.credit)
        ) {
          issueWarnMessage("Debit and credit accounts are required.");
          return false;
        }
        if (
          isNullUndefined(item.description) ||
          isEmptyString(item.description)
        ) {
          issueWarnMessage("Description is required.");
          return false;
        }
      }
    }

    let obj = selectedEntry;
    obj.transactionDetailItems = items;
    setSelectedEntry(obj);

    saveJournalEntry(selectedEntry).then((response) => {
      issueResponseMessage(response);
      listJournalEntries(enteredBy).then((entries) => setEntries(entries));
      setAddDiv(false);
      setSelectedEntry(null);
      setItems([]);
      setJournalItems([]);
    });
  }

  const dataGridColumns = [
    { field: "description", headerName: "Description", minWidth: 500, flex: 5 },
    {
      field: "entryDate",
      headerName: "Entry Date",
      minWidth: 120,
      flex: 1.2,
      valueFormatter: (row) => {
        return formatDate(row.value);
      },
    },
    {
      field: "postingDate",
      headerName: "Posting Date",
      minWidth: 120,
      flex: 1.2,
      valueFormatter: (row) => {
        return formatDate(row.value);
      },
    },
    { field: "appliedTo", headerName: "Applied To", minWidth: 350, flex: 3.5 },
    { field: "totalAmount", headerName: "Amount", minWidth: 110, flex: 1.1 },
    {
      field: "createdBy",
      headerName: "Created By",
      minWidth: 275,
      flex: 2.75,
      valueFormatter: (row) => {
        if (isNullUndefined(row.value) || isEmptyString(row.value)) {
          return "System Generated";
        } else {
          return row.value;
        }
      },
    },
    {
      field: "action",
      headerName: "Actions",
      width: 100,
      type: "actions",
      getActions: (params) => {
        if (isNullUndefined(params) || isNullUndefined(params.row)) {
          return [];
        }

        const row = params.row;

        if (row?.description.includes("Reversed")) {
          return [
            <GridActionsCellItem
              icon={<Visibility />}
              label="View"
              onClick={() => handleViewClick(row)}
              showInMenu
            />
          ]
        }

        return [
          <GridActionsCellItem
            icon={<Visibility />}
            label="View"
            onClick={() => handleViewClick(row)}
            showInMenu
          />,
          <GridActionsCellItem
            icon={<History />}
            label="Reverse Entry"
            onClick={() => handleReverseClick(row)}
            showInMenu
          />,
        ]
      }
    },
  ];

  useEffect(() => {
    populateTieredItems();
  }, [journalItems, populateTieredItems]);

  return (
    <Permit
      roles="BLDIR,ACC"
      services="PREMIUM,HOA,MAINT"
      noPermittedServiceMessage={{
        title: "Access Denied",
        body: (
          <>
            Your current role and/or facility is not permitted to view this
            page. <br /> Please contact your system admin if you feel this is an
            error.
          </>
        ),
      }}
    >
      <ResponsiveRow
        sx={{
          width: "100%",
          height: "auto",

          flexWrap: "nowrap",
          flexDirection: "column",

          justifyContent: "flex-start",
          alignItems: "flex-start",

          padding: 0,
          marginTop: "30px",
          marginBottom: "80px",
        }}
      >
        {!AddDiv && !ViewDiv && (
          <TopBarContainer
            sx={{
              justifyContent: { xs: "center", lg: "space-between" },
              width: { xs: "100%", lg: "80%" },
            }}
            container
          >
            <ResponsiveRow
              item
              sx={{ width: { xs: "100%", lg: "50%" }, padding: 0 }}
            >
              <Typography
                variant="h5"
                sx={{
                  width: "100%",
                  height: "auto",

                  textAlign: { xs: "center", lg: "start" },
                }}
              >
                Journal Entries
              </Typography>
            </ResponsiveRow>
            <ResponsiveRow
              item
              sx={{
                width: { xs: "100%", lg: "50%" },
                padding: 0,
                justifyContent: { xs: "center", lg: "flex-end" },
              }}
              container
            >
              <ResponsiveRow
                item
                sx={{
                  width: { xs: "100%", lg: "50%" },
                  justifyContent: { xs: "center", lg: "flex-end" },
                }}
              >
                <TopBarContainedBrandButton
                  sx={{ width: { xs: "100%", lg: "200px" } }}
                  onClick={handleAddClick}
                >
                  Add Journal Entry
                </TopBarContainedBrandButton>
              </ResponsiveRow>
            </ResponsiveRow>
          </TopBarContainer>
        )}
        {!AddDiv && !ViewDiv && <br />}
        <CreateJournalEntry
          AddDiv={AddDiv}
          saveEntry={saveEntry}
          handleDateChange={handleDateChange}
          handleUnitChange={handleUnitChange}
          handlePropertyChange={handlePropertyChange}
          handleComplexChange={handleComplexChange}
          handleAssetChange={handleAssetChange}
          handleLeaseChange={handleLeaseChange}
          handleTieredItemAdd={handleTieredItemAdd}
          setAddDiv={setAddDiv}
          setJournalItems={setJournalItems}
          setSelectedEntry={setSelectedEntry}
          setItems={setItems}
          selectedEntry={selectedEntry}
          date={date}
          setMemo={setMemo}
          asset={asset}
          assetOptions={assetOptions}
          complex={complex}
          complexOptions={complexOptions}
          lease={lease}
          leaseOptions={leaseOptions}
          memo={memo}
          tieredItemsTableRows={tieredItemsTableRows}
          unit={unit}
          unitOptions={unitOptions}
          facilityOptions={facilityOptions}
          closeAddModal={closeAddModal}
        />
        {ViewDiv && (
          <ViewJournalModal
            ViewDiv={ViewDiv}
            setViewDiv={setViewDiv}
            selectedEntry={selectedEntry}
          />
        )}
        <MuiDataGrid
          dataGridColumns={dataGridColumns}
          noSelection={true}
          dataGridRows={entries}
          loading={loading}
          height="60vh"
          totalRows={entries.length}
          getRowClassName={getRowClassName}
        />
        <ReverseJournalEntryModal
          ReverseModal={ReverseModal}
          setReverseModal={setReverseModal}
          setSelectedEntry={setSelectedEntry}
          selectedEntry={selectedEntry}
          reverseEntry={reverseEntry}
        />
      </ResponsiveRow>
    </Permit>
  );
}
