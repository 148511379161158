import { ResponsiveRow } from "../../constants/layout.constants";
import WorkOrderStatusBar from "../../components/modules/dashboard/workOrderStatusBar";
import PendingApprovals from "../../components/modules/dashboard/pendingApprovals";
import BalanceOverdueByDays from "../../components/modules/dashboard/balanceOverdueByDays";
import VacantUnits from "../../components/modules/dashboard/vacantUnits";
import ExpiringLeases from "../../components/modules/dashboard/expiringLeases";
import Reminders from "../../components/modules/dashboard/reminders";
import IncomeVsExpenses from "../../components/modules/dashboard/incomeVsExpenses";
import AssetsIncomeVsExpenses from "../../components/modules/dashboard/assetsIncomeVsExpenses";
import UtilitiesIncomeVsExpenses from "../../components/modules/dashboard/utilitesIncomevsExpenses";
import UtilitiesReadVsUnread from "../../components/modules/dashboard/utilitesReadVsUnread";
import UtilitiesWorkingVsBroken from "../../components/modules/dashboard/utilitiesWorkingVsBroken";
import AssignedWorkOrders from "../../components/modules/dashboard/assignedWorkOrders";
import BudgetVsExpenses from "../../components/modules/dashboard/budgetVsExpenses";
import HomeOwnersDues from "../../components/modules/dashboard/homeOwnersDues";
import MetersVolumesSold from "../../components/modules/dashboard/metersVolumesSold";
import MetersBulkSales from "../../components/modules/dashboard/metersBulkSales";
import MetersRetailSales from "../../components/modules/dashboard/metersRetailSales";
import Permit from "../../util/permit";
import UpcomingAssetMaintenance from "../../components/modules/dashboard/upcomingAssetMaintenance";
import ExpensesByVendor from "../../components/modules/dashboard/expensesByVendor";
import {isEmptyString, isNullUndefined} from "../../util/util";
import {useSelector} from "react-redux";
import SuspendedLeases from "../../components/modules/dashboard/suspendedLeases";
import VacateNotices from "../../components/modules/dashboard/vacateNotices";

export default function DashboardBuildingDirector() {
    const {user} = useSelector((state) => state.auth)
    return (
    <ResponsiveRow
      sx={{
        flexWrap: "nowrap",
        flexDirection: "column",
        width: "100%",
        height: "auto",
        justifyContent: "center",
        alignItems: "center",
        padding: 0,
      }}
    >
      <ResponsiveRow
        sx={{
          width: "100%",
          height: "auto",
          flexWrap: "nowrap",
          flexDirection: "column",
          justifyContent: { xs: "center", lg: "space-evenly" },
          padding: 0,
        }}
      >
        <ResponsiveRow
          sx={{
            width: "100%",
            height: "auto",
            padding: "0",
            flexWrap: "nowrap",
          }}
        >
          <Permit
            roles="BLDIR"
            services={
              (!isNullUndefined(user.proxyFacility) && !isEmptyString(user.proxyFacility) && user.proxyFacility !== "null")
                ? "PREMIUM,HOA,BLWTR,LEAN,CORE,METER,MAINT" : null}
          >
            <WorkOrderStatusBar />
          </Permit>
        </ResponsiveRow>
        <ResponsiveRow
          sx={{
            width: "100%",
            height: "auto",
            flexDirection: { xs: "column", lg: "row" },
            flexWrap: "nowrap",
            justifyContent: { xs: "center", lg: "space-between" },
            alignItems: "center",
            padding: 0,
          }}
        >
          <Permit services={(!isNullUndefined(user.proxyFacility) && !isEmptyString(user.proxyFacility) && user.proxyFacility !== "null") ? "PREMIUM,CORE,HOA,BLWTR,METER,MAINT" : null}>
            <PendingApprovals customHeight="500px" customWidth="59%" />
          </Permit>
            {/*{(user.proxyFacility === "null" || isNullUndefined(user.proxyFacility) || isEmptyString(user.proxyFacility)) && //Currently available only at BD's subscription level dashboard*/}
            {/*<Permit>*/}
            {/*    <WorkOrderStatisticsPieChart customHeight="500px" customWidth="19%" />*/}
            {/*</Permit>*/}
            {/*}*/}
          <Permit services={(!isNullUndefined(user.proxyFacility) && !isEmptyString(user.proxyFacility) && user.proxyFacility !== "null") ? "PREMIUM,CORE,HOA,LEAN,METER" : null}>
            <BalanceOverdueByDays customWidth="39%" customHeight="500px" />
          </Permit>
        </ResponsiveRow>
        <ResponsiveRow
          sx={{
            width: "100%",
            height: "auto",
            flexDirection: { xs: "column", lg: "row" },
            flexWrap: "nowrap",
            justifyContent: { xs: "center", lg: "space-between" },
            alignItems: "center",
            padding: 0,
          }}
        >
          <Permit services={(!isNullUndefined(user.proxyFacility) && !isEmptyString(user.proxyFacility) && user.proxyFacility !== "null") ? "PREMIUM,CORE,LEAN" : null}>
            <VacantUnits customHeight="500px" customWidth="39%" />
          </Permit>
          <Permit services={(!isNullUndefined(user.proxyFacility) && !isEmptyString(user.proxyFacility) && user.proxyFacility !== "null") ? "PREMIUM,CORE,LEAN" : null}>
            <ExpiringLeases customHeight="500px" customWidth="59%" />
          </Permit>
        </ResponsiveRow>
        <ResponsiveRow
          sx={{
            width: "100%",
            height: "auto",
            flexDirection: { xs: "column", lg: "row" },
            flexWrap: "nowrap",
            justifyContent: { xs: "center", lg: "space-between" },
            alignItems: "center",
            padding: 0,
          }}
        >
          <Permit services="PREMIUM,HOA,MAINT">
            <Reminders customHeight="500px" customWidth="49%" />
          </Permit>
          <Permit services="PREMIUM,CORE,HOA,METER">
            <IncomeVsExpenses customWidth="49%" customHeight="500px" />
          </Permit>
        </ResponsiveRow>
        <ResponsiveRow
          sx={{
            width: "100%",
            height: "auto",
            flexDirection: { xs: "column", lg: "row" },
            flexWrap: "nowrap",
            justifyContent: { xs: "center", lg: "space-between" },
            alignItems: "center",
            padding: 0,
          }}
        >
          <Permit services="PREMIUM,HOA">
            <AssetsIncomeVsExpenses customWidth="49%" customHeight="500px" />
          </Permit>
          <Permit services="PREMIUM,HOA,METER">
            <UtilitiesIncomeVsExpenses customWidth="49%" customHeight="500px" />
          </Permit>
        </ResponsiveRow>
        <ResponsiveRow
          sx={{
            width: "100%",
            height: "auto",
            flexDirection: { xs: "column", lg: "row" },
            flexWrap: "nowrap",
            justifyContent: { xs: "center", lg: "space-between" },
            alignItems: "center",
            padding: 0,
          }}
        >
          <Permit services="PREMIUM,HOA,METER">
            <UtilitiesReadVsUnread customWidth="49%" customHeight="500px" />
          </Permit>
          <Permit services="PREMIUM,HOA,METER,MAINT">
            <UtilitiesWorkingVsBroken customWidth="49%" customHeight="500px" />
          </Permit>
        </ResponsiveRow>
        <ResponsiveRow
          sx={{
            width: "100%",
            height: "auto",
            flexDirection: { xs: "column", lg: "row" },
            flexWrap: "wrap",
            justifyContent: { xs: "center", lg: "space-between" },
            alignItems: "center",
            padding: 0,
          }}
        >
          <Permit services={(!isNullUndefined(user.proxyFacility) && !isEmptyString(user.proxyFacility) && user.proxyFacility !== "null") ? "PREMIUM,HOA" : null}>
            <ResponsiveRow
              sx={{
                width: "100%",
                height: "auto",
                flexDirection: { xs: "column", lg: "row" },
                flexWrap: "nowrap",
                justifyContent: { xs: "center", lg: "space-between" },
                alignItems: "center",
                padding: 0,
              }}
            >
              <AssignedWorkOrders customWidth="49%" customHeight="500px" />
              <UpcomingAssetMaintenance
                customWidth="49%"
                customHeight="500px"
              />
            </ResponsiveRow>
          </Permit>
          <Permit services="MAINT">
            <AssignedWorkOrders customWidth="99%" customHeight="500px" />
          </Permit>
          <Permit services="CORE,LEAN,METER">
            <UpcomingAssetMaintenance customWidth="99%" customHeight="500px" />
          </Permit>
        </ResponsiveRow>
        <ResponsiveRow
          sx={{
            width: "100%",
            height: "auto",
            flexDirection: { xs: "column", lg: "row" },
            flexWrap: "nowrap",
            justifyContent: { xs: "center", lg: "space-between" },
            alignItems: "center",
            padding: 0,
          }}
        >
          <Permit services="HOA">
            <BudgetVsExpenses customWidth="49%" customHeight="500px" />
          </Permit>
          <Permit services="HOA">
            <HomeOwnersDues customWidth="49%" customHeight="500px" />
          </Permit>
        </ResponsiveRow>
        <Permit services="BLWTR">
          <MetersVolumesSold customWidth="99%" customHeight="500px" />
        </Permit>
        <ResponsiveRow
          sx={{
            width: "100%",
            height: "auto",
            flexDirection: { xs: "column", lg: "row" },
            flexWrap: "nowrap",
            justifyContent: { xs: "center", lg: "space-between" },
            alignItems: "center",
            padding: 0,
          }}
        >
          <Permit services="BLWTR">
            <MetersBulkSales customWidth="49%" customHeight="500px" />
          </Permit>
          <Permit services="BLWTR">
            <MetersRetailSales customWidth="49%" customHeight="500px" />
          </Permit>
        </ResponsiveRow>
          <ResponsiveRow
              sx={{
                  width: "100%",
                  height: "auto",
                  flexDirection: { xs: "column", lg: "row" },
                  flexWrap: "nowrap",
                  justifyContent: { xs: "center", lg: "space-between" },
                  alignItems: "center",
                  padding: 0,
              }}
          >
              <Permit services={(!isNullUndefined(user.proxyFacility) && !isEmptyString(user.proxyFacility) && user.proxyFacility !== "null") ? "PREMIUM,CORE,LEAN" : null}>
                  <VacateNotices customHeight="500px" customWidth="100%" />
              </Permit>
          </ResponsiveRow>
        <ResponsiveRow
          sx={{
            width: "100%",
            height: "auto",
            flexDirection: { xs: "column", lg: "row" },
            flexWrap: "nowrap",
            justifyContent: { xs: "center", lg: "space-between" },
            alignItems: "center",
            padding: 0,
          }}
        >
          <Permit services="PREMIUM,HOA,MAINT">
            <ExpensesByVendor customHeight="500px" customWidth="100%" />
          </Permit>
        </ResponsiveRow>
          {(user.proxyFacility === "null" || isNullUndefined(user.proxyFacility) || isEmptyString(user.proxyFacility)) && //Currently available only at BD's subscription level dashboard
              <ResponsiveRow
              sx={{
                  width: "100%",
                  height: "auto",
                  flexDirection: { xs: "column", lg: "row" },
                  flexWrap: "nowrap",
                  justifyContent: { xs: "center", lg: "space-between" },
                  alignItems: "center",
                  padding: 0,
              }}
          >
              <SuspendedLeases customHeight="500px" customWidth="100%" />
          </ResponsiveRow>
          }
      </ResponsiveRow>
    </ResponsiveRow>
  );
}
