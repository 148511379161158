import {Alert, AlertTitle, FormControl, Grid, InputLabel, OutlinedInput, TextField,} from "@mui/material"
import {ResponsiveRow} from "../../../../constants/layout.constants"
import {formatCurrency} from "../../../../util/util"
import ModalContainerModule from "../../../modules/modalContainer"

export default function SuspendLeaseModal(props) {
    /***
     *
     * REQUIRED PROPS
     *
     * suspendReActivateLeaseForm
     * handleSuspendReactivateClose
     * suspendModal
     *
     * task
     * selectedLease
     * statusChangeReason
     * onTextChange
     *
     */

    return (
        <ModalContainerModule
            size="xs"
            accept={props.suspendReActivateLeaseForm}
            cancel={props.handleSuspendReactivateClose}
            openModal={props.suspendModal}
            modalTitle={`${props.task} Lease`}
            acceptButtonText={`${props.task + " "}Lease`}
        >
            <ResponsiveRow container sx={{height: "auto"}}>
                <Grid item sx={{width: "100%"}}>
                    {props.task === "Suspend" && (
                        <Alert severity="info">
                            <AlertTitle>
                                Suspend Lease
                                {props.selectedLease &&
                                    props.selectedLease.billingCode} for{" "}
                                {props.selectedLease && props.selectedLease.tenant}
                            </AlertTitle>
                            When you suspend a lease, the tenant shall no longer be invoiced.
                            To resume invoicing, you can Re-Activate the lease. The tenant
                            shall still be able to make payments against this lease
                            <p>
                                Current Balance:
                                {formatCurrency(
                                    props.selectedLease && props.selectedLease.balance
                                )}
                            </p>
                        </Alert>
                    )}
                    {props.task === "Re-Activate" && (
                        <Alert severity="info">
                            <AlertTitle>
                                Re-Activate Lease
                                {props.selectedLease && props.selectedLease.billingCode} for
                                {props.selectedLease && props.selectedLease.tenant}
                            </AlertTitle>
                            When you Re-Activate a lease, invoicing the tenant shall resume.
                            The tenant shall still be able to make payments against this lease
                            <p>
                                Current Balance:
                                {formatCurrency(
                                    props.selectedLease && props.selectedLease.balance
                                )}
                            </p>
                        </Alert>
                    )}
                    <br/>
                </Grid>
                <FormControl sx={{width: "100%"}}>
                    <InputLabel>Reason for {props.task}</InputLabel>
                    <OutlinedInput
                        label={`Reason for ${props.task}`}
                        sx={{width: "100%"}}
                        multiline
                        placeholder="Type reason here..."
                        rows={5}
                        id={"statusChangeReason"}
                        value={props.statusChangeReason}
                        isrequired
                        onChange={(searchText) => props.onTextChange(searchText)}
                        inputProps={<TextField sx={{width: "100%"}}/>}
                    />
                </FormControl>
            </ResponsiveRow>
        </ModalContainerModule>
    )
}
